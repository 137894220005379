export const USER_LOGIN = {
  CALL_API: 'USER_LOGIN_CALL_API',
  START: 'USER_LOGIN_API_START',
  SUCCESS: 'USER_LOGIN_API_SUCCESS',
  FAILED: 'USER_LOGIN_API_FAILED',
  END: 'USER_LOGIN_API_END',
};
export const CHECK_USERNAME = {
  START: 'CHECK_USERNAME_API_START',
  SUCCESS: 'CHECK_USERNAME_API_SUCCESS',
  FAILED: 'CHECK_USERNAME_API_FAILED',
  END: 'CHECK_USERNAME_API_END',
};
export const SIGN_UP = {
  CALL_API: 'SIGN_UP_CALL_API',
  START: 'SIGN_UP_API_START',
  SUCCESS: 'SIGN_UP_API_SUCCESS',
  FAILED: 'SIGN_UP_API_FAILED',
  END: 'SIGN_UP_API_END',
};
export const VERIFY_OTP = {
  CALL_API: 'VERIFY_OTP_CALL_API',
  START: 'VERIFY_OTP_API_START',
  SUCCESS: 'VERIFY_OTP_API_SUCCESS',
  FAILED: 'VERIFY_OTP_API_FAILED',
  END: 'VERIFY_OTP_API_END',
};
export const PARENT_USERNAME = {
  CALL_API: 'PARENT_USERNAME_CALL_API',
  START: 'PARENT_USERNAME_API_START',
  SUCCESS: 'PARENT_USERNAME_API_SUCCESS',
  FAILED: 'PARENT_USERNAME_API_FAILED',
  END: 'PARENT_USERNAME_API_END',
};
export const RESET_PASSWORD = {
  CALL_API: 'RESET_PASSWORD_CALL_API',
  START: 'RESET_PASSWORD_API_START',
  SUCCESS: 'RESET_PASSWORD_API_SUCCESS',
  FAILED: 'RESET_PASSWORD_API_FAILED',
  END: 'RESET_PASSWORD_API_END',
};
export const RESEND_CODE = {
  CALL_API: 'RESEND_CODE_CALL_API',
  START: 'RESEND_CODE_API_START',
  SUCCESS: 'RESEND_CODE_API_SUCCESS',
  FAILED: 'RESEND_CODE_API_FAILED',
  END: 'RESEND_CODE_API_END',
};
export const FORGOT_PASSWORD = {
  CALL_API: 'FORGOT_PASSWORD_CALL_API',
  START: 'FORGOT_PASSWORD_API_START',
  SUCCESS: 'FORGOT_PASSWORD_API_SUCCESS',
  FAILED: 'FORGOT_PASSWORD_API_FAILED',
  END: 'FORGOT_PASSWORD_API_END',
};
export const CHANGE_PASSWORD = {
  CALL_API: 'CHANGE_PASSWORD_CALL_API',
  START: 'CHANGE_PASSWORD_API_START',
  SUCCESS: 'CHANGE_PASSWORD_API_SUCCESS',
  FAILED: 'CHANGE_PASSWORD_API_FAILED',
  END: 'CHANGE_PASSWORD_API_END',
};
export const LOGOUT = {
  CALL_API: 'LOGOUT_CALL_API',
  START: 'LOGOUT_API_START',
  SUCCESS: 'LOGOUT_API_SUCCESS',
  FAILED: 'LOGOUT_API_FAILED',
  END: 'LOGOUT_API_END',
};
export const DELETE_ACCOUNT = {
  START: 'DELETE_ACCOUNT_API_START',
  SUCCESS: 'DELETE_ACCOUNT_API_SUCCESS',
  FAILED: 'DELETE_ACCOUNT_API_FAILED',
  END: 'DELETE_ACCOUNT_API_END',
};
export const CREATE_NEW_TOP = {
  CALL_API: 'CREATE_NEW_TOP_CALL_API',
  START: 'CREATE_NEW_TOP_API_START',
  SUCCESS: 'CREATE_NEW_TOP_API_SUCCESS',
  FAILED: 'CREATE_NEW_TOP_API_FAILED',
  END: 'CREATE_NEW_TOP_API_END',
};
export const UPDATE_TOP = {
  CALL_API: 'UPDATE_TOP_CALL_API',
  START: 'UPDATE_TOP_API_START',
  SUCCESS: 'UPDATE_TOP_API_SUCCESS',
  FAILED: 'UPDATE_TOP_API_FAILED',
  END: 'UPDATE_TOP_API_END',
};
export const SEARCH_TOP_LIST = {
  CALL_API: 'SEARCH_TOP_LIST_CALL_API',
  START: 'SEARCH_TOP_LIST_API_START',
  SUCCESS: 'SEARCH_TOP_LIST_API_SUCCESS',
  FAILED: 'SEARCH_TOP_LIST_API_FAILED',
  END: 'SEARCH_TOP_LIST_API_END',
};
export const RECENT_SEARCH_LIST = {
  CALL_API: 'RECENT_SEARCH_LIST_CALL_API',
  START: 'RECENT_SEARCH_LIST_API_START',
  SUCCESS: 'RECENT_SEARCH_LIST_API_SUCCESS',
  FAILED: 'RECENT_SEARCH_LIST_API_FAILED',
  END: 'RECENT_SEARCH_LIST_API_END',
};
export const TOP_DETAIL = {
  CALL_API: 'TOP_DETAIL_CALL_API',
  START: 'TOP_DETAIL_API_START',
  SUCCESS: 'TOP_DETAIL_API_SUCCESS',
  FAILED: 'TOP_DETAIL_API_FAILED',
  END: 'TOP_DETAIL_API_END',
};
export const REGISTER = {
  CALL_API: 'REGISTER_CALL_API',
  START: 'REGISTER_API_START',
  SUCCESS: 'REGISTER_API_SUCCESS',
  FAILED: 'REGISTER_API_FAILED',
  END: 'REGISTER_API_END',
};
export const CREATE_COLLECTION = {
  CALL_API: 'CREATE_COLLECTION_CALL_API',
  START: 'CREATE_COLLECTION_API_START',
  SUCCESS: 'CREATE_COLLECTION_API_SUCCESS',
  FAILED: 'CREATE_COLLECTION_API_FAILED',
  END: 'CREATE_COLLECTION_API_END',
};
export const COLLECTION_TOP_LISTS = {
  CALL_API: 'COLLECTION_TOP_LISTS_CALL_API',
  START: 'COLLECTION_TOP_LISTS_API_START',
  SUCCESS: 'COLLECTION_TOP_LISTS_API_SUCCESS',
  FAILED: 'COLLECTION_TOP_LISTS_API_FAILED',
  END: 'COLLECTION_TOP_LISTS_API_END',
};
export const USER_PROFILE_DATA = {
  CALL_API: 'USER_PROFILE_DATA_CALL_API',
  START: 'USER_PROFILE_DATA_API_START',
  SUCCESS: 'USER_PROFILE_DATA_API_SUCCESS',
  FAILED: 'USER_PROFILE_DATA_API_FAILED',
  END: 'USER_PROFILE_DATA_API_END',
};
export const RESET_STORE = 'RESET_STORE';
export const GET_IN_PROGRESS_TOPS = {
  CALL_API: 'GET_IN_PROGRESS_CALL_API',
  START: 'GET_IN_PROGRESS_API_START',
  SUCCESS: 'GET_IN_PROGRESS_API_SUCCESS',
  FAILED: 'GET_IN_PROGRESS_API_FAILED',
  END: 'GET_IN_PROGRESS_API_END',
};
export const UPDATE_COLLECTION = {
  CALL_API: 'UPDATE_COLLECTION_CALL_API',
  START: 'UPDATE_COLLECTION_API_START',
  SUCCESS: 'UPDATE_COLLECTION_API_SUCCESS',
  FAILED: 'UPDATE_COLLECTION_API_FAILED',
  END: 'UPDATE_COLLECTION_API_END',
};
export const GET_PURCHASED_TOPS = {
  CALL_API: 'GET_PURCHASED_TOPS_CALL_API',
  START: 'GET_PURCHASED_TOPS_API_START',
  SUCCESS: 'GET_PURCHASED_TOPS_API_SUCCESS',
  FAILED: 'GET_PURCHASED_TOPS_API_FAILED',
  END: 'GET_PURCHASED_TOPS_API_END',
};
export const BUY_TOPS = {
  CALL_API: 'BUY_TOPS_CALL_API',
  START: 'BUY_TOPS_API_START',
  SUCCESS: 'BUY_TOPS_API_SUCCESS',
  FAILED: 'BUY_TOPS_API_FAILED',
  END: 'BUY_TOPS_API_END',
};

export const GET_TOPS_BY_COLLECTION = {
  CALL_API: 'GET_TOPS_BY_COLLECTION_CALL_API',
  START: 'GET_TOPS_BY_COLLECTION_API_START',
  SUCCESS: 'GET_TOPS_BY_COLLECTION_API_SUCCESS',
  FAILED: 'GET_TOPS_BY_COLLECTION_API_FAILED',
  END: 'GET_TOPS_BY_COLLECTION_API_END',
};

export const ADD_TOPS_TO_COLLECTION = {
  CALL_API: 'ADD_TOPS_TO_COLLECTION_CALL_API',
  START: 'ADD_TOPS_TO_COLLECTION_API_START',
  SUCCESS: 'ADD_TOPS_TO_COLLECTION_API_SUCCESS',
  FAILED: 'ADD_TOPS_TO_COLLECTION_API_FAILED',
  END: 'ADD_TOPS_TO_COLLECTION_API_END',
};

export const GET_CHILD_LIST_DATA = {
  CALL_API: 'GET_CHILD_LIST_DATA_CALL_API',
  START: 'GET_CHILD_LIST_DATA_API_START',
  SUCCESS: 'GET_CHILD_LIST_DATA_API_SUCCESS',
  FAILED: 'GET_CHILD_LIST_DATA_API_FAILED',
  END: 'GET_CHILD_LIST_DATA_API_END',
};
export const DELETE_COLLECTIONS_TOPS_DATA = {
  CALL_API: 'DELETE_COLLECTIONS_TOPS_DATA_CALL_API',
  START: 'DELETE_COLLECTIONS_TOPS_DATA_API_START',
  SUCCESS: 'DELETE_COLLECTIONS_TOPS_DATA_API_SUCCESS',
  FAILED: 'DELETE_COLLECTIONS_TOPS_DATA_API_FAILED',
  END: 'DELETE_COLLECTIONS_TOPS_DATA_API_END',
};
export const DELETE_TOPS = {
  CALL_API: 'DELETE_TOPS_CALL_API',
  START: 'DELETE_TOPS_API_START',
  SUCCESS: 'DELETE_TOPS_API_SUCCESS',
  FAILED: 'DELETE_TOPS_API_FAILED',
  END: 'DELETE_TOPS_API_END',
};
export const CREATE_GROUP_MANAGEMENT = {
  CALL_API: 'CREATE_GROUP_MANAGEMENT_CALL_API',
  START: 'CREATE_GROUP_MANAGEMENT_API_START',
  SUCCESS: 'CREATE_GROUP_MANAGEMENT_API_SUCCESS',
  FAILED: 'CREATE_GROUP_MANAGEMENT_API_FAILED',
  END: 'CREATE_GROUP_MANAGEMENT_API_END',
};
export const UPDATE_GROUP = {
  CALL_API: 'UPDATE_GROUP_CALL_API',
  START: 'UPDATE_GROUP_API_START',
  SUCCESS: 'UPDATE_GROUP_API_SUCCESS',
  FAILED: 'UPDATE_GROUP_API_FAILED',
  END: 'UPDATE_GROUP_API_END',
};
export const MARK_TRADABLE_TOPS = {
  CALL_API: 'MARK_TRADABLE_TOPS_CALL_API',
  START: 'MARK_TRADABLE_TOPS_API_START',
  SUCCESS: 'MARK_TRADABLE_TOPS_API_SUCCESS',
  FAILED: 'MARK_TRADABLE_TOPS_API_FAILED',
  END: 'MARK_TRADABLE_TOPS_API_END',
};
export const CHILD_APPROVEL_REQUEST_LIST = {
  CALL_API: 'CHILD_APPROVEL_REQUEST_LIST_CALL_API',
  START: 'CHILD_APPROVEL_REQUEST_LIST_API_START',
  SUCCESS: 'CHILD_APPROVEL_REQUEST_LIST_API_SUCCESS',
  FAILED: 'CHILD_APPROVEL_REQUEST_LIST_API_FAILED',
  END: 'CHILD_APPROVEL_REQUEST_LIST_API_END',
};
export const USER_LIST = {
  CALL_API: 'USER_LIST_CALL_API',
  START: 'USER_LIST_API_START',
  SUCCESS: 'USER_LIST_API_SUCCESS',
  FAILED: 'USER_LIST_API_FAILED',
  END: 'USER_LIST_API_END',
};

export const USER_ADD_ON_GROUP = {
  CALL_API: 'USER_ADD_ON_GROUP_CALL_API',
  START: 'USER_ADD_ON_GROUP_API_START',
  SUCCESS: 'USER_ADD_ON_GROUP_API_SUCCESS',
  FAILED: 'USER_ADD_ON_GROUP_API_FAILED',
  END: 'USER_ADD_ON_GROUP_API_END',
};

export const MY_GROUP_LIST = {
  CALL_API: 'MY_GROUP_LIST_CALL_API',
  START: 'MY_GROUP_LIST_API_START',
  SUCCESS: 'MY_GROUP_LIST_API_SUCCESS',
  FAILED: 'MY_GROUP_LIST_API_FAILED',
  END: 'MY_GROUP_LIST_API_END',
};

export const GROUP_IN_LIST = {
  CALL_API: 'GROUP_IN_LIST_CALL_API',
  START: 'GROUP_IN_LIST_API_START',
  SUCCESS: 'GROUP_IN_LIST_API_SUCCESS',
  FAILED: 'GROUP_IN_LIST_API_FAILED',
  END: 'GROUP_IN_LIST_API_END',
};

export const GROUP_INVITATION_LIST = {
  CALL_API: 'GROUP_INVITATION_LIST_CALL_API',
  START: 'GROUP_INVITATION_LIST_API_START',
  SUCCESS: 'GROUP_INVITATION_LIST_API_SUCCESS',
  FAILED: 'GROUP_INVITATION_LIST_API_FAILED',
  END: 'GROUP_INVITATION_LIST_API_END',
};
export const APPROVE_OR_REJECT = {
  CALL_API: 'APPROVE_OR_REJECT_API',
  START: 'APPROVE_OR_REJECT_API_START',
  SUCCESS: 'APPROVE_OR_REJECT_API_SUCCESS',
  FAILED: 'APPROVE_OR_REJECT_API_FAILED',
  END: 'APPROVE_OR_REJECT_API_END',
};
export const GET_CHILD_PROFILE_DATA = {
  CALL_API: 'GET_CHILD_PROFILE_DATA_CALL_API',
  START: 'GET_CHILD_PROFILE_DATA_API_START',
  SUCCESS: 'GET_CHILD_PROFILE_DATA_API_SUCCESS',
  FAILED: 'GET_CHILD_PROFILE_DATA_API_FAILED',
  END: 'GET_CHILD_PROFILE_DATA_API_END',
};
export const GROUP_INVITATION_APPROVE = {
  CALL_API: 'GROUP_INVITATION_APPROVE_CALL_API',
  START: 'GROUP_INVITATION_APPROVE_API_START',
  SUCCESS: 'GROUP_INVITATION_APPROVE_API_SUCCESS',
  FAILED: 'GROUP_INVITATION_APPROVE_API_FAILED',
  END: 'GROUP_INVITATION_APPROVE_API_END',
};
export const GROUP_INVITATION_REJECT = {
  CALL_API: 'GROUP_INVITATION_REJECT_CALL_API',
  START: 'GROUP_INVITATION_REJECT_API_START',
  SUCCESS: 'GROUP_INVITATION_REJECT_API_SUCCESS',
  FAILED: 'GROUP_INVITATION_REJECT_API_FAILED',
  END: 'GROUP_INVITATION_REJECT_API_END',
};
export const UPDATE_PROFILE = {
  CALL_API: 'UPDATE_PROFILE_API',
  START: 'UPDATE_PROFILE_API_START',
  SUCCESS: 'UPDATE_PROFILE_API_SUCCESS',
  FAILED: 'UPDATE_PROFILE_API_FAILED',
  END: 'UPDATE_PROFILE_API_END',
};
export const UPDATE_PROFILE_IMAGE = {
  CALL_API: 'UPDATE_PROFILE_IMAGE_API',
  START: 'UPDATE_PROFILE_IMAGE_API_START',
  SUCCESS: 'UPDATE_PROFILE_IMAGE_API_SUCCESS',
  FAILED: 'UPDATE_PROFILE_IMAGE_API_FAILED',
  END: 'UPDATE_PROFILE_IMAGE_API_END',
};
export const GROUP_USER_LIST = {
  CALL_API: 'GROUP_USER_LIST_CALL_API',
  START: 'GROUP_USER_LIST_API_START',
  SUCCESS: 'GROUP_USER_LIST_API_SUCCESS',
  FAILED: 'GROUP_USER_LIST_API_FAILED',
  END: 'GROUP_USER_LIST_API_END',
};
export const GROUP_DETAIL = {
  CALL_API: 'GROUP_DETAIL_CALL_API',
  START: 'GROUP_DETAIL_API_START',
  SUCCESS: 'GROUP_DETAIL_API_SUCCESS',
  FAILED: 'GROUP_DETAIL_API_FAILED',
  END: 'GROUP_DETAIL_API_END',
};
export const REMOVE_USER_FROM_GROUP = {
  CALL_API: 'REMOVE_USER_FROM_GROUP_CALL_API',
  START: 'REMOVE_USER_FROM_GROUP_API_START',
  SUCCESS: 'REMOVE_USER_FROM_GROUP_API_SUCCESS',
  FAILED: 'REMOVE_USER_FROM_GROUP_API_FAILED',
  END: 'REMOVE_USER_FROM_GROUP_API_END',
};
export const GROUP_DELETE = {
  CALL_API: 'GROUP_DELETE_CALL_API',
  START: 'GROUP_DELETE_API_START',
  SUCCESS: 'GROUP_DELETE_API_SUCCESS',
  FAILED: 'GROUP_DELETE_API_FAILED',
  END: 'GROUP_DELETE_API_END',
};
export const TRADE_RECEVIED_OFFER = {
  CALL_API: 'TRADE_RECEVIED_OFFER_CALL_API',
  START: 'TRADE_RECEVIED_OFFER_API_START',
  SUCCESS: 'TRADE_RECEVIED_OFFER_API_SUCCESS',
  FAILED: 'TRADE_RECEVIED_OFFER_API_FAILED',
  END: 'TRADE_RECEVIED_OFFER_API_END',
};
export const TRADE_SENT_OFFER = {
  CALL_API: 'TRADE_SENT_OFFER_CALL_API',
  START: 'TRADE_SENT_OFFER_API_START',
  SUCCESS: 'TRADE_SENT_OFFER_API_SUCCESS',
  FAILED: 'TRADE_SENT_OFFER_API_FAILED',
  END: 'TRADE_SENT_OFFER_API_END',
};
export const USER_LIST_SEARCH = {
  CALL_API: 'USER_LIST_SEARCH_CALL_API',
  START: 'USER_LIST_SEARCH_API_START',
  SUCCESS: 'USER_LIST_SEARCH_API_SUCCESS',
  FAILED: 'USER_LIST_SEARCH_API_FAILED',
  END: 'USER_LIST_API_SEARCH_END',
};
export const DISTRIBUTE_SEARCH = {
  CALL_API: 'DISTRIBUTE_SEARCH_CALL_API',
  START: 'DISTRIBUTE_SEARCH_API_START',
  SUCCESS: 'DISTRIBUTE_SEARCH_API_SUCCESS',
  FAILED: 'DISTRIBUTE_SEARCH_API_FAILED',
  END: 'DISTRIBUTE_SEARCH_END',
};
export const LEAVE_GROUP_ACTION = {
  CALL_API: 'LEAVE_GROUP_ACTION_CALL_API',
  START: 'LEAVE_GROUP_ACTION_API_START',
  SUCCESS: 'LEAVE_GROUP_ACTION_API_SUCCESS',
  FAILED: 'LEAVE_GROUP_ACTION_API_FAILED',
  END: 'LEAVE_GROUP_ACTION_API_END',
};
export const GROUP_LIST_SEARCH = {
  CALL_API: 'GROUP_LIST_SEARCH_CALL_API',
  START: 'GROUP_LIST_SEARCH_API_START',
  SUCCESS: 'GROUP_LIST_SEARCH_API_SUCCESS',
  FAILED: 'GROUP_LIST_SEARCH_API_FAILED',
  END: 'GROUP_LIST_API_SEARCH_END',
};
export const GROUP_USER_LIST_SEARCH = {
  CALL_API: 'GROUP_USER_LIST_SEARCH_CALL_API',
  START: 'GROUP_USER_LIST_SEARCH_API_START',
  SUCCESS: 'GROUP_USER_LIST_SEARCH_API_SUCCESS',
  FAILED: 'GROUP_USER_LIST_SEARCH_API_FAILED',
  END: 'GROUP_USER_LIST_API_SEARCH_END',
};
export const APPROVE_RECEIVED_OFFER = {
  CALL_API: 'APPROVE_RECEIVED_OFFER_CALL_API',
  START: 'APPROVE_RECEIVED_OFFER_API_START',
  SUCCESS: 'APPROVE_RECEIVED_OFFER_API_SUCCESS',
  FAILED: 'APPROVE_RECEIVED_OFFER_API_FAILED',
  END: 'APPROVE_RECEIVED_OFFER_API_END',
};
export const CANCEL_SENT_OFFER = {
  CALL_API: 'CANCEL_SENT_OFFER_CALL_API',
  START: 'CANCEL_SENT_OFFER_API_START',
  SUCCESS: 'CANCEL_SENT_OFFER_API_SUCCESS',
  FAILED: 'CANCEL_SENT_OFFER_API_FAILED',
  END: 'CANCEL_SENT_OFFER_API_END',
};
export const REJECT_RECEIVED_OFFER = {
  CALL_API: 'REJECT_RECEIVED_OFFER_CALL_API',
  START: 'REJECT_RECEIVED_OFFER_API_START',
  SUCCESS: 'REJECT_RECEIVED_OFFER_API_SUCCESS',
  FAILED: 'REJECT_RECEIVED_OFFER_API_FAILED',
  END: 'REJECT_RECEIVED_OFFER_API_END',
};
export const CREATE_OFFER = {
  CALL_API: 'CREATE_OFFER_CALL_API',
  START: 'CREATE_OFFER_API_START',
  SUCCESS: 'CREATE_OFFER_API_SUCCESS',
  FAILED: 'CREATE_OFFER_API_FAILED',
  END: 'CREATE_OFFER_SEARCH_END',
};
export const TOPS_MOVE_TO_COLLECTION = {
  CALL_API: 'TOPS_MOVE_TO_COLLECTION_CALL_API',
  START: 'TOPS_MOVE_TO_COLLECTION_API_START',
  SUCCESS: 'TOPS_MOVE_TO_COLLECTION_API_SUCCESS',
  FAILED: 'TOPS_MOVE_TO_COLLECTION_API_FAILED',
  END: 'TOPS_MOVE_TO_COLLECTION_API_END',
};
export const COUNTER_OFFER = {
  CALL_API: 'COUNTER_OFFER_CALL_API',
  START: 'COUNTER_OFFER_API_START',
  SUCCESS: 'COUNTER_OFFER_API_SUCCESS',
  FAILED: 'COUNTER_OFFER_API_FAILED',
  END: 'COUNTER_OFFER_SEARCH_END',
};
export const CART_CHECKOUT = {
  CALL_API: 'CART_CHECKOUT_CALL_API',
  START: 'CART_CHECKOUT_API_START',
  SUCCESS: 'CART_CHECKOUT_API_SUCCESS',
  FAILED: 'CART_CHECKOUT_API_FAILED',
  END: 'CART_CHECKOUT_END',
};

export const TRADE_APPROVAL_SETTING = {
  CALL_API: 'TRADE_APPROVAL_SETTING_CALL_API',
  START: 'TRADE_APPROVAL_SETTING_API_START',
  SUCCESS: 'TRADE_APPROVAL_SETTING_API_SUCCESS',
  FAILED: 'TRADE_APPROVAL_SETTING_API_FAILED',
  END: 'TRADE_APPROVAL_SETTING_API_END',
};

export const TRADE_NOT_APPROVE_USER_LIST = {
  CALL_API: 'TRADE_NOT_APPROVE_USER_LIST_CALL_API',
  START: 'TRADE_NOT_APPROVE_USER_LIST_API_START',
  SUCCESS: 'TRADE_NOT_APPROVE_USER_LIST_API_SUCCESS',
  FAILED: 'TRADE_NOT_APPROVE_USER_LIST_API_FAILED',
  END: 'TRADE_NOT_APPROVE_USER_LIST_API_END',
};

export const USER_ADD_INTO_TRADE = {
  CALL_API: 'USER_ADD_INTO_TRADE_CALL_API',
  START: 'USER_ADD_INTO_TRADE_API_START',
  SUCCESS: 'USER_ADD_INTO_TRADE_API_SUCCESS',
  FAILED: 'USER_ADD_INTO_TRADE_API_FAILED',
  END: 'USER_ADD_INTO_TRADE_API_END',
};

export const TRADE_APPROVE_USER_LIST = {
  CALL_API: 'TRADE_APPROVE_USER_LIST_CALL_API',
  START: 'TRADE_APPROVE_USER_LIST_API_START',
  SUCCESS: 'TRADE_APPROVE_USER_LIST_API_SUCCESS',
  FAILED: 'TRADE_APPROVE_USER_LIST_API_FAILED',
  END: 'TRADE_APPROVE_USER_LIST_API_END',
};

export const GROUP_INVITATION_CHILD_LIST = {
  CALL_API: 'GROUP_INVITATION_CHILD_LIST_CALL_API',
  START: 'GROUP_INVITATION_CHILD_LIST_API_START',
  SUCCESS: 'GROUP_INVITATION_CHILD_LIST_API_SUCCESS',
  FAILED: 'GROUP_INVITATION_CHILD_LIST_API_FAILED',
  END: 'GROUP_INVITATION_CHILD_LIST_API_END',
};

export const USER_REMOVE_FROM_TRADE = {
  CALL_API: 'USER_REMOVE_FROM_TRADE_CALL_API',
  START: 'USER_REMOVE_FROM_TRADE_API_START',
  SUCCESS: 'USER_REMOVE_FROM_TRADE_API_SUCCESS',
  FAILED: 'USER_REMOVE_FROM_TRADE_API_FAILED',
  END: 'USER_REMOVE_FROM_TRADE_API_END',
};

export const TRADE_APPROVAL_RECEIVED_LIST = {
  CALL_API: 'TRADE_APPROVAL_RECEIVED_LIST_CALL_API',
  START: 'TRADE_APPROVAL_RECEIVED_LIST_API_START',
  SUCCESS: 'TRADE_APPROVAL_RECEIVED_LIST_API_SUCCESS',
  FAILED: 'TRADE_APPROVAL_RECEIVED_LIST_API_FAILED',
  END: 'TRADE_APPROVAL_RECEIVED_LIST_API_END',
};

export const GROUP_CHILD_INVITATION_APPROVED = {
  CALL_API: 'GROUP_CHILD_INVITATION_APPROVED_CALL_API',
  START: 'GROUP_CHILD_INVITATION_APPROVED_API_START',
  SUCCESS: 'GROUP_CHILD_INVITATION_APPROVED_API_SUCCESS',
  FAILED: 'GROUP_CHILD_INVITATION_APPROVED_API_FAILED',
  END: 'GROUP_CHILD_INVITATION_APPROVED_API_END',
};

export const GROUP_CHILD_INVITATION_REJECT = {
  CALL_API: 'GROUP_CHILD_INVITATION_REJECT_CALL_API',
  START: 'GROUP_CHILD_INVITATION_REJECT_API_START',
  SUCCESS: 'GROUP_CHILD_INVITATION_REJECT_API_SUCCESS',
  FAILED: 'GROUP_CHILD_INVITATION_REJECT_API_FAILED',
  END: 'GROUP_CHILD_INVITATION_REJECT_API_END',
};

export const CHILD_GROUP_APPROVAL_SETTING = {
  CALL_API: 'CHILD_GROUP_APPROVAL_SETTING_CALL_API',
  START: 'CHILD_GROUP_APPROVAL_SETTING_API_START',
  SUCCESS: 'CHILD_GROUP_APPROVAL_SETTING_API_SUCCESS',
  FAILED: 'CHILD_GROUP_APPROVAL_SETTING_API_FAILED',
  END: 'CHILD_GROUP_APPROVAL_SETTING_API_END',
};

export const CHILD_TRADABLE_REQUEST_LIST = {
  CALL_API: 'CHILD_TRADABLE_REQUEST_LIST_CALL_API',
  START: 'CHILD_TRADABLE_REQUEST_LIST_API_START',
  SUCCESS: 'CHILD_TRADABLE_REQUEST_LIST_API_SUCCESS',
  FAILED: 'CHILD_TRADABLE_REQUEST_LIST_API_FAILED',
  END: 'CHILD_TRADABLE_REQUEST_LIST_API_END',
};

export const TRADE_APPROVAL_SENT_LIST = {
  CALL_API: 'TRADE_APPROVAL_SENT_LIST_CALL_API',
  START: 'TRADE_APPROVAL_SENT_LIST_API_START',
  SUCCESS: 'TRADE_APPROVAL_SENT_LIST_API_SUCCESS',
  FAILED: 'TRADE_APPROVAL_SENT_LIST_API_FAILED',
  END: 'TRADE_APPROVAL_SENT_LIST_API_END',
};

export const SENT_TRADE_APPROVE_OR_REJECT_API = {
  CALL_API: 'SENT_TRADE_APPROVA_OR_REJECT_CALL_API',
  START: 'SENT_TRADE_APPROVA_OR_REJECT_API_START',
  SUCCESS: 'SENT_TRADE_APPROVA_OR_REJECT_API_SUCCESS',
  FAILED: 'SENT_TRADE_APPROVA_OR_REJECT_API_FAILED',
  END: 'SENT_TRADE_APPROVA_OR_REJECT_API_END',
};

export const CHILD_TRADABLE_SETTING = {
  CALL_API: 'CHILD_TRADABLE_SETTING_CALL_API',
  START: 'CHILD_TRADABLE_SETTING_API_START',
  SUCCESS: 'CHILD_TRADABLE_SETTING_API_SUCCESS',
  FAILED: 'CHILD_TRADABLE_SETTING_API_FAILED',
  END: 'CHILD_TRADABLE_SETTING_API_END',
};

export const CHILD_TRADABLE_APPROVED_REJECT = {
  CALL_API: 'CHILD_TRADABLE_APPROVED_REJECT_CALL_API',
  START: 'CHILD_TRADABLE_APPROVED_REJECT_API_START',
  SUCCESS: 'CHILD_TRADABLE_APPROVED_REJECT_API_SUCCESS',
  FAILED: 'CHILD_TRADABLE_APPROVED_REJECT_API_FAILED',
  END: 'CHILD_TRADABLE_APPROVED_REJECT_API_END',
};

export const DISTRIBUTE_TOP_APPROVAL_SETTING = {
  CALL_API: 'DISTRIBUTE_TOP_APPROVAL_SETTING_CALL_API',
  START: 'DISTRIBUTE_TOP_APPROVAL_SETTING_API_START',
  SUCCESS: 'DISTRIBUTE_TOP_APPROVAL_SETTING_API_SUCCESS',
  FAILED: 'DISTRIBUTE_TOP_APPROVAL_SETTING_API_FAILED',
  END: 'DISTRIBUTE_TOP_APPROVAL_SETTING_API_END',
};

export const NOTIFICATION_LIST = {
  CALL_API: 'NOTIFICATION_LIST_CALL_API',
  START: 'NOTIFICATION_LIST_API_START',
  SUCCESS: 'NOTIFICATION_LIST_API_SUCCESS',
  FAILED: 'NOTIFICATION_LIST_API_FAILED',
  END: 'NOTIFICATION_LIST_API_END',
};

export const NOTIFICATION_COMPLETED_LIST = {
  CALL_API: 'NNOTIFICATION_COMPLETED_LIST_CALL_API',
  START: 'NNOTIFICATION_COMPLETED_LIST_API_START',
  SUCCESS: 'NNOTIFICATION_COMPLETED_LIST_API_SUCCESS',
  FAILED: 'NNOTIFICATION_COMPLETED_LIST_API_FAILED',
  END: 'NNOTIFICATION_COMPLETED_LIST_API_END',
};

export const NOTIFICATION_UNREAD_COUNT = {
  START: 'NOTIFICATION_UNREAD_COUNT_API_START',
  SUCCESS: 'NOTIFICATION_UNREAD_COUNT_API_SUCCESS',
  FAILED: 'NOTIFICATION_UNREAD_COUNT_API_FAILED',
  END: 'NOTIFICATION_UNREAD_COUNT_API_END',
};

export const NOTIF_TOKEN_UPDATE_ON_SERVER = {
  START: 'NOTIF_TOKEN_UPDATE_ON_SERVER_API_START',
  SUCCESS: 'NOTIF_TOKEN_UPDATE_ON_SERVER_API_SUCCESS',
  FAILED: 'NOTIF_TOKEN_UPDATE_ON_SERVER_API_FAILED',
  END: 'NOTIF_TOKEN_UPDATE_ON_SERVER_API_END',
};

export const TOP_BUYING_SETTING = {
  CALL_API: 'TOP_BUYING_SETTING_CALL_API',
  START: 'TOP_BUYING_SETTING_API_START',
  SUCCESS: 'TOP_BUYING_SETTING_API_SUCCESS',
  FAILED: 'TOP_BUYING_SETTING_API_FAILED',
  END: 'TOP_BUYING_SETTING_API_END',
};

export const TOP_BUYING_APPROVAL = {
  CALL_API: 'TOP_BUYING_APPROVAL_CALL_API',
  START: 'TOP_BUYING_APPROVAL_API_START',
  SUCCESS: 'TOP_BUYING_APPROVAL_API_SUCCESS',
  FAILED: 'TOP_BUYING_APPROVAL_API_FAILED',
  END: 'TOP_BUYING_APPROVAL_API_END',
};
export const USER_ADD_INTO_DISTRIBUTE = {
  CALL_API: 'USER_ADD_INTO_DISTRIBUTE_CALL_API',
  START: 'USER_ADD_INTO_DISTRIBUTE_API_START',
  SUCCESS: 'USER_ADD_INTO_DISTRIBUTE_API_SUCCESS',
  FAILED: 'USER_ADD_INTO_DISTRIBUTE_API_FAILED',
  END: 'USER_ADD_INTO_DISTRIBUTE_API_END',
};

export const DISTRIBUTE_APPROVE_USER_LIST = {
  CALL_API: 'DISTRIBUTE_APPROVE_USER_LIST_CALL_API',
  START: 'DISTRIBUTE_APPROVE_USER_LIST_API_START',
  SUCCESS: 'DISTRIBUTE_APPROVE_USER_LIST_API_SUCCESS',
  FAILED: 'DISTRIBUTE_APPROVE_USER_LIST_API_FAILED',
  END: 'DISTRIBUTE_APPROVE_USER_LIST_API_END',
};

export const USER_REMOVE_FROM_DISTRIBUTE_APPROVED_LIST = {
  CALL_API: 'USER_REMOVE_FROM_DISTRIBUTE_APPROVED_LIST_CALL_API',
  START: 'USER_REMOVE_FROM_DISTRIBUTE_APPROVED_LIST_API_START',
  SUCCESS: 'USER_REMOVE_FROM_DISTRIBUTE_APPROVED_LIST_API_SUCCESS',
  FAILED: 'USER_REMOVE_FROM_DISTRIBUTE_APPROVED_LIST_API_FAILED',
  END: 'USER_REMOVE_FROM_DISTRIBUTE_APPROVED_LIST_API_END',
};

export const DISTRIBUTE_REQUEST_LIST = {
  CALL_API: 'DISTRIBUTE_REQUEST_LIST_CALL_API',
  START: 'DISTRIBUTE_REQUEST_LIST_API_START',
  SUCCESS: 'DISTRIBUTE_REQUEST_LIST_API_SUCCESS',
  FAILED: 'DISTRIBUTE_REQUEST_LIST_API_FAILED',
  END: 'DISTRIBUTE_REQUEST_LIST_API_END',
};

export const DISTRIBUTE_REQUEST_APPROVE_OR_REJECT_API = {
  CALL_API: 'DISTRIBUTE_REQUEST_APPROVE_OR_REJECT_CALL_API',
  START: 'DISTRIBUTE_REQUEST_APPROVE_OR_REJECT_API_START',
  SUCCESS: 'DISTRIBUTE_REQUEST_APPROVE_OR_REJECT_API_SUCCESS',
  FAILED: 'DISTRIBUTE_REQUEST_APPROVE_OR_REJECT_API_FAILED',
  END: 'DISTRIBUTE_REQUEST_APPROVE_OR_REJECT_API_END',
};

export const ACCEPT_REJECT_BY_PARENT = {
  START: 'ACCEPT_REJECT_BY_PARENT_API_START',
  SUCCESS: 'ACCEPT_REJECT_BY_PARENT_API_SUCCESS',
  FAILED: 'ACCEPT_REJECT_BY_PARENT_API_FAILED',
  END: 'ACCEPT_REJECT_BY_PARENT_API_END',
};

export const BUYING_REQUEST_TO_PARENT = {
  CALL_API: 'BUYING_REQUEST_TO_PARENT_CALL_API',
  START: 'BUYING_REQUEST_TO_PARENT_API_START',
  SUCCESS: 'BUYING_REQUEST_TO_PARENT_API_SUCCESS',
  FAILED: 'BUYING_REQUEST_TO_PARENT_API_FAILED',
  END: 'BUYING_REQUEST_TO_PARENT_END',
};

export const RESEND_APPROVAL_REQUEST_DATA = {
  CALL_API: 'RESEND_APPROVAL_REQUEST_CALL_API',
  START: 'RESEND_APPROVAL_REQUEST_API_START',
  SUCCESS: 'RESEND_APPROVAL_REQUEST_API_SUCCESS',
  FAILED: 'RESEND_APPROVAL_REQUEST_API_FAILED',
  END: 'RESEND_APPROVAL_REQUEST_END',
};

export const TOP_AMOUNT_AND_REPORT_ABUSE_REQUEST_DATA = {
  CALL_API: 'TOP_AMOUNT_AND_REPORT_ABUSE_REQUEST_DATA_CALL_API',
  START: 'TOP_AMOUNT_AND_REPORT_ABUSE_REQUEST_DATA_API_START',
  SUCCESS: 'TOP_AMOUNT_AND_REPORT_ABUSE_REQUEST_DATA_API_SUCCESS',
  FAILED: 'TOP_AMOUNT_AND_REPORT_ABUSE_REQUEST_DATA_API_FAILED',
  END: 'TOP_AMOUNT_AND_REPORT_ABUSE_REQUEST_DATA_END',
};

export const VERIFY_COUPON_CODE = {
  CALL_API: 'VERIFY_COUPON_CODE_CALL_API',
  START: 'VERIFY_COUPON_CODE_API_START',
  SUCCESS: 'VERIFY_COUPON_CODE_API_SUCCESS',
  FAILED: 'VERIFY_COUPON_CODE_API_FAILED',
  END: 'VERIFY_COUPON_CODE_END',
};

export const REPORT_ABUSE_REQUEST_DATA = {
  CALL_API: 'REPORT_ABUSE_REQUEST_DATA_CALL_API',
  START: 'REPORT_ABUSE_REQUEST_DATA_API_START',
  SUCCESS: 'REPORT_ABUSE_REQUEST_DATA_API_SUCCESS',
  FAILED: 'REPORT_ABUSE_REQUEST_DATA_API_FAILED',
  END: 'REPORT_ABUSE_REQUEST_DATA_END',
};

export const TRADE_OFFER_REPORT = {
  CALL_API: 'TRADE_OFFER_REPORT_CALL_API',
  START: 'TRADE_OFFER_REPORT_API_START',
  SUCCESS: 'TRADE_OFFER_REPORT_API_SUCCESS',
  FAILED: 'TRADE_OFFER_REPORT_API_FAILED',
  END: 'TRADE_OFFER_REPORT_API_END',
};

export const TRADE_OFFER_DETAIL = {
  CALL_API: 'TRADE_OFFER_DETAIL_CALL_API',
  START: 'TRADE_OFFER_DETAIL_API_START',
  SUCCESS: 'TRADE_OFFER_DETAIL_API_SUCCESS',
  FAILED: 'TRADE_OFFER_DETAIL_API_FAILED',
  END: 'TRADE_OFFER_DETAIL_API_END',
};

export const DISTRIBUTE_RECEIVED_REQUEST_LIST = {
  CALL_API: 'DISTRIBUTE_RECEIVED_REQUEST_CALL_API',
  START: 'DISTRIBUTE_RECEIVED_REQUEST_API_START',
  SUCCESS: 'DISTRIBUTE_RECEIVED_REQUEST_API_SUCCESS',
  FAILED: 'DISTRIBUTE_RECEIVED_REQUEST_API_FAILED',
  END: 'DISTRIBUTE_RECEIVED_REQUEST_API_END',
};

export const DISTRIBUTE_RECEIVED_REQUEST_APPROVE_OR_REJECTOR_REPORT_API = {
  CALL_API: 'DISTRIBUTE_RECEIVED_REQUEST_APPROVE_OR_REJECT_CALL_API',
  START: 'DISTRIBUTE_RECEIVED_REQUEST_APPROVE_OR_REJECTOR_REPORT_API_START',
  SUCCESS: 'DISTRIBUTE_RECEIVED_REQUEST_APPROVE_OR_REJECTOR_REPORT_API_SUCCESS',
  FAILED: 'DISTRIBUTE_RECEIVED_REQUEST_APPROVE_OR_REJECTOR_REPORT_API_FAILED',
  END: 'DISTRIBUTE_RECEIVED_REQUEST_APPROVE_OR_REJECTOR_REPORT_API_END',
};

export const DISTRIBUTE_TOP_OFFER_DETAIL = {
  CALL_API: 'DISTRIBUTE_TOP_OFFER_DETAIL_CALL_API',
  START: 'DISTRIBUTE_TOP_OFFER_DETAIL_API_START',
  SUCCESS: 'DISTRIBUTE_TOP_OFFER_DETAIL_API_SUCCESS',
  FAILED: 'DISTRIBUTE_TOP_OFFER_DETAIL_API_FAILED',
  END: 'DISTRIBUTE_TOP_OFFER_DETAIL_API_END',
};

export const TOP_IMAGE_UPLOAD_ACTION = {
  CALL_API: ' TOP_IMAGE_UPLOAD_ACTION_CALL_API',
  START: ' TOP_IMAGE_UPLOAD_ACTION_API_START',
  SUCCESS: ' TOP_IMAGE_UPLOAD_ACTION_API_SUCCESS',
  FAILED: ' TOP_IMAGE_UPLOAD_ACTION_API_FAILED',
  END: ' TOP_IMAGE_UPLOAD_ACTION_API_END',
};

export const TOP_TRADE_STATUS_UPDATE = {
  CALL_API: 'TOP_TRADE_STATUS_UPDATE_CALL_API',
  START: 'TOP_TRADE_STATUS_UPDATE_API_START',
  SUCCESS: 'TOP_TRADE_STATUS_UPDATE_API_SUCCESS',
  FAILED: 'TOP_TRADE_STATUS_UPDATE_API_FAILED',
  END: 'TOP_TRADE_STATUS_UPDATE_API_END',
};

export const USER_PLAN_PURCHACE_ACTION = {
  CALL_API: 'USER_PLAN_PURCHACE_ACTION_CALL_API',
  START: 'USER_PLAN_PURCHACE_ACTION_API_START',
  SUCCESS: 'USER_PLAN_PURCHACE_ACTION_API_SUCCESS',
  FAILED: 'USER_PLAN_PURCHACE_ACTION_API_FAILED',
  END: 'USER_PLAN_PURCHACE_ACTION_END',
};

export const WALLET_TRASACTION_HISTORY = {
  CALL_API: 'WALLET_TRASACTION_HISTORY_CALL_API',
  START: 'WALLET_TRASACTION_HISTORY_API_START',
  SUCCESS: 'WALLET_TRASACTION_HISTORY_API_SUCCESS',
  FAILED: 'WALLET_TRASACTION_HISTORY_API_FAILED',
  END: 'WALLET_TRASACTION_HISTORY_END',
};

export const TOPCREDITS_REQUEST_ACTION = {
  CALL_API: 'TOPCREDITS_REQUEST_ACTION_CALL_API',
  START: 'TOPCREDITS_REQUEST_ACTION_API_START',
  SUCCESS: 'TOPCREDITS_REQUEST_ACTION_API_SUCCESS',
  FAILED: 'TOPCREDITS_REQUEST_ACTION_API_FAILED',
  END: 'TOPCREDITS_REQUEST_ACTION_END',
};

export const BLOCK_USER_LIST_ACTION_API = {
  CALL_API: 'BLOCK_USER_LIST_ACTION_API',
  START: 'BLOCK_USER_LIST_ACTION_API_START',
  SUCCESS: 'BLOCK_USER_LIST_ACTION_API_SUCCESS',
  FAILED: 'BLOCK_USER_LIST_ACTION_API_FAILED',
  END: 'BLOCK_USER_LIST_ACTION_API_END',
};

export const UNBLOCK_USER_ACTION_API = {
  CALL_API: 'UNBLOCK_USER_ACTION_API',
  START: 'UNBLOCK_USER_ACTION_API_START',
  SUCCESS: 'UNBLOCK_USER_ACTION_API_SUCCESS',
  FAILED: 'BLOCK_USER_LIST_ACTION_API_FAILED',
  END: 'UNBLOCK_USER_ACTION_API_END',
};

export const DISTRIBUTE_TO_EMAIL_TEXT = {
  CALL_API: 'DISTRIBUTE_TO_EMAIL_TEXT_API',
  START: 'DISTRIBUTE_TO_EMAIL_TEXT_API_START',
  SUCCESS: 'DISTRIBUTE_TO_EMAIL_TEXT_API_SUCCESS',
  FAILED: 'DISTRIBUTE_TO_EMAIL_TEXT_API_FAILED',
  END: 'DISTRIBUTE_TO_EMAIL_TEXT_API_END',
};

export const DISTRIBUTE_TOP_VIA_CSV = {
  CALL_API: 'DISTRIBUTE_TOP_VIA_CSV_API',
  START: 'DISTRIBUTE_TOP_VIA_CSV_API_START',
  SUCCESS: 'DISTRIBUTE_TOP_VIA_CSV_API_SUCCESS',
  FAILED: 'DISTRIBUTE_TOP_VIA_CSV_API_FAILED',
  END: 'DISTRIBUTE_TOP_VIA_CSV_API_END',
};

export const VERIFY_REEDEM_CODE = {
  CALL_API: 'VERIFY_REEDEM_CODE_CALL_API',
  START: 'VERIFY_REEDEM_CODE_API_START',
  SUCCESS: 'VERIFY_REEDEM_CODE_API_SUCCESS',
  FAILED: 'VERIFY_REEDEM_CODE_API_FAILED',
  END: 'VERIFY_REEDEM_CODE_END',
};

export const FAVORITE_USER_LIST_ACTION_API = {
  CALL_API: 'FAVORITE_USER_LIST_ACTION_API',
  START: 'FAVORITE_USER_LIST_ACTION_API_START',
  SUCCESS: 'FAVORITE_USER_LIST_ACTION_API_SUCCESS',
  FAILED: 'FAVORITE_USER_LIST_ACTION_API_FAILED',
  END: 'FAVORITE_USER_LIST_ACTION_API_END',
};

export const VERIFY_IS_FAVORITE_USER = {
  CALL_API: 'VERIFY_IS_FAVORITE_USER_ACTION_API',
  START: 'VERIFY_IS_FAVORITE_USER_ACTION_API_START',
  SUCCESS: 'VERIFY_IS_FAVORITE_USER_ACTION_API_SUCCESS',
  FAILED: 'VERIFY_IS_FAVORITE_USER_ACTION_API_FAILED',
  END: 'VERIFY_IS_FAVORITE_USER_ACTION_API_END',
};

export const USER_MARK_UNMARK_FAVORITE = {
  CALL_API: 'USER_MARK_UNMARK_FAVORITE_ACTION_API',
  START: 'USER_MARK_UNMARK_FAVORITE_ACTION_API_START',
  SUCCESS: 'USER_MARK_UNMARK_FAVORITE_ACTION_API_SUCCESS',
  FAILED: 'USER_MARK_UNMARK_FAVORITE_ACTION_API_FAILED',
  END: 'USER_MARK_UNMARK_FAVORITE_ACTION_API_END',
};

export const NOTES_ADD = {
  CALL_API: 'NOTES_ADD_ACTION_API',
  START: 'NOTES_ADD_ACTION_API_START',
  SUCCESS: 'NOTES_ADD_ACTION_API_SUCCESS',
  FAILED: 'NOTES_ADD_ACTION_API_FAILED',
  END: 'NOTES_ADD_ACTION_API_END',
};

export const NOTES_UPDATE = {
  CALL_API: 'NOTES_UPDATE_ACTION_API',
  START: 'NOTES_UPDATE_ACTION_API_START',
  SUCCESS: 'NOTES_UPDATE_ACTION_API_SUCCESS',
  FAILED: 'NOTES_UPDATE_ACTION_API_FAILED',
  END: 'NOTES_UPDATE_ACTION_API_END',
};

export const NOTES_LIST = {
  CALL_API: 'NOTES_LIST_ACTION_API',
  START: 'NOTES_LIST_ACTION_API_START',
  SUCCESS: 'NOTES_LIST_ACTION_API_SUCCESS',
  FAILED: 'NOTES_LIST_ACTION_API_FAILED',
  END: 'NOTES_LIST_ACTION_API_END',
};

export const NOTES_DELETE = {
  CALL_API: 'NOTES_DELETE_ACTION_API',
  START: 'NOTES_DELETE_ACTION_API_START',
  SUCCESS: 'NOTES_DELETE_ACTION_API_SUCCESS',
  FAILED: 'NOTES_DELETE_ACTION_API_FAILED',
  END: 'NOTES_DELETE_ACTION_API_END',
};
export const TRANSFER_TOP_CREDITS = {
  CALL_API: 'TRANSFER_TOP_CREDITS_ACTION_API',
  START: 'TRANSFER_TOP_CREDITS_ACTION_API_START',
  SUCCESS: 'TRANSFER_TOP_CREDITS_ACTION_API_SUCCESS',
  FAILED: 'TRANSFER_TOP_CREDITS_ACTION_API_FAILED',
  END: 'TRANSFER_TOP_CREDITS_ACTION_API_END',
};

export const TOP_CREDITS_PULLBACK = {
  CALL_API: 'TTOP_CREDITS_PULLBACK_ACTION_API',
  START: 'TOP_CREDITS_PULLBACK_ACTION_API_START',
  SUCCESS: 'TOP_CREDITS_PULLBACK_ACTION_API_SUCCESS',
  FAILED: 'TOP_CREDITS_PULLBACK_ACTION_API_FAILED',
  END: 'TOP_CREDITS_PULLBACK_ACTION_API_END',
};

export const TRACKING_LIST = {
  CALL_API: 'TRACKING_LIST_CALL_API',
  START: 'TRACKING_LIST_API_START',
  SUCCESS: 'TRACKING_LIST_API_SUCCESS',
  FAILED: 'TRACKING_LIST_API_FAILED',
  END: 'TRACKING_LIST_API_END',
};
export const USER_SEARCH_LIST = {
  CALL_API: 'USER_SEARCH_LIST_CALL_API',
  START: 'USER_SEARCH__LIST_API_START',
  SUCCESS: 'USER_SEARCH__LIST_API_SUCCESS',
  FAILED: 'USER_SEARCH__LIST_API_FAILED',
  END: 'USER_SEARCH__LIST_API_END',
};
export const TRACKING_CANCEL = {
  CALL_API: 'TRACKING_CANCEL_CALL_API',
  START: 'TRACKING_CANCEL_API_START',
  SUCCESS: 'TRACKING_CANCEL_API_SUCCESS',
  FAILED: 'TRACKING_CANCEL_API_FAILED',
  END: 'TRACKING_CANCEL_API_END',
};
export const TRACKING_DETAIL = {
  CALL_API: 'TRACKING_DETAIL_CALL_API',
  START: 'TRACKING_DETAIL_API_START',
  SUCCESS: 'TRACKING_DETAIL_API_SUCCESS',
  FAILED: 'TRACKING_DETAIL_API_FAILED',
  END: 'TRACKING_DETAIL_API_END',
};
export const SEND_REMINDER = {
  CALL_API: 'SEND_REMINDER_CALL_API',
  START: 'SEND_REMINDER_API_START',
  SUCCESS: 'SEND_REMINDER_API_SUCCESS',
  FAILED: 'SEND_REMINDER_API_FAILED',
  END: 'SEND_REMINDER_API_END',
};

export const CHECK_FOR_MULTIPLE_ACCOUNT = {
  CALL_API: 'CHECK_FOR_MULTIPLE_ACCOUNT_CALL_API',
  START: 'CHECK_FOR_MULTIPLE_ACCOUNT_START',
  SUCCESS: 'CHECK_FOR_MULTIPLE_ACCOUNT_SUCCESS',
  FAILED: 'CHECK_FOR_MULTIPLE_ACCOUNT_FAILED',
  END: 'CHECK_FOR_MULTIPLE_ACCOUNT_END',
};

export const TUTORIAL_LIST = {
  CALL_API: 'TUTORIAL_LIST_CALL_API',
  START: 'TUTORIAL_LIST_API_START',
  SUCCESS: 'TUTORIAL_LIST_API_SUCCESS',
  FAILED: 'TUTORIAL_LIST_API_FAILED',
  END: 'TUTORIAL_LIST_API_END',
};

export const FORGOT_USERNAME = {
  CALL_API: 'FORGOT_USERNAME_CALL_API',
  START: 'FORGOT_USERNAME_START',
  SUCCESS: 'FORGOT_USERNAME_SUCCESS',
  FAILED: 'FORGOT_USERNAME_FAILED',
  END: 'FORGOT_USERNAME_END',
};
export const SEND_REMINDER_ALL = {
  CALL_API: 'SEND_REMINDER_ALL_CALL_API',
  START: 'SEND_REMINDER_ALL_API_START',
  SUCCESS: 'SEND_REMINDER_ALL_API_SUCCESS',
  FAILED: 'SEND_REMINDER_ALL_API_FAILED',
  END: 'SEND_REMINDER_ALL_API_END',
};
