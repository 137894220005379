import React from 'react';
import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  Percentage,
  FONT_NAMES,
  GlobalStyleValues,
} from '../../constants';
import {IPrimaryButton} from '../../types/componentTypes/buttonType';
import {normalizeFont, scaleHeight} from '../../utils/responsive';

const styles = (isDisabled?: boolean) =>
  StyleSheet.create({
    mainView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_58),
      width: Percentage.PRECENTAGE_100,
      borderRadius: FixedValue.CONSTANT_VALUE_100,
    },
    labelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: isDisabled ? COLORS.BDBDBD : COLORS.WHITE,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_14),
    },
    secondLabelStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: isDisabled ? COLORS._666666 : COLORS.WHITE,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_14),
    },
    backgroundPrimaryColor: {
      backgroundColor: COLORS.PRIMARY_BLUE,
    },
    disableBtn: {
      backgroundColor: COLORS.EAEAEA,
    },
    flexDirectionRow: {
      ...BASESTYLE.column,
    },
    appliedCouponButton: {
      justifyContent: GlobalStyleValues.SPACE_BETWEEN,
      paddingHorizontal: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      alignItems: GlobalStyleValues.CENTER,
      flexDirection: GlobalStyleValues.ROW,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_61),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
  });

const CouponAppliedButton: React.FC<IPrimaryButton> = (
  props: IPrimaryButton
) => {
  const {
    onPress,
    isDisabled,
    labelStyle,
    buttonLabel,
    element,
    isLoading,
    startElement,
    secondLabel,
    secondLableText,
  } = props;
  const [apiStatus, setApiStatus] = React.useState<boolean>(isLoading || false);
  const [_isDisabled, setIsDisabled] = React.useState<boolean>(
    isDisabled || false
  );
  const style = styles(_isDisabled);

  const PressIn = React.useCallback(async (): Promise<void> => {
    if (!_isDisabled && onPress) {
      onPress();
    }
  }, [_isDisabled, onPress]);

  React.useEffect(() => {
    setApiStatus(isLoading || false);
    if (isLoading) {
      setIsDisabled(true);
    } else {
      setIsDisabled(isDisabled || false);
    }
  }, [isLoading]);

  React.useEffect(() => {
    if (isDisabled) {
      setIsDisabled(true);
    } else {
      setIsDisabled(isDisabled || false);
    }
  }, [isDisabled]);

  const buttonMainView: TextStyle = props?.buttonwidth
    ? props?.buttonwidth
    : style.mainView;

  const buttonStyle: ViewStyle = !_isDisabled
    ? {...style.backgroundPrimaryColor, ...props?.buttonBackGroundColor}
    : style.disableBtn;

  return (
    <View style={[buttonMainView, buttonStyle]}>
      {apiStatus ? (
        <ActivityIndicator color={COLORS.PRIMARY_BLUE} size="small" />
      ) : (
        <View style={[style.appliedCouponButton]}>
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            {startElement && (
              <View
                style={{
                  marginRight: 10,
                  justifyContent: 'center',
                }}
              >
                {startElement}
              </View>
            )}
            <View style={style.flexDirectionRow}>
              <Text style={[style.labelstyle, labelStyle]}>{buttonLabel}</Text>
              {secondLabel && (
                <Text style={[style.secondLabelStyle, secondLableText]}>
                  {secondLabel}
                </Text>
              )}
            </View>
          </View>
          {element && (
            <Pressable
              onPress={PressIn}
              disabled={isLoading}
              style={secondLableText}
            >
              {element}
            </Pressable>
          )}
        </View>
      )}
    </View>
  );
};

export default React.memo(CouponAppliedButton);
