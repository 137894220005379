import React from 'react';
import {View, Pressable} from 'react-native';
import {ThemeContext} from '../../../contextAPI/themeContext';
import SafeAreaContainer from '../../../components/layout/safeAreaContainer';
import {ROUTE_NAME, TEXT} from '../../../constants';
import {TopTradinglStyle} from './topTrading.style';
import Settings from '../../../components/renderSvgs/settings';
import TopTradingScreens from './topTradingScreens';
import {navigate} from '../../../services/navigationService';
import TopBarWithRightButton from '../../../components/header/topBarWithRightButton';

const TopTrading = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = TopTradinglStyle(ColorTheme);
  const onPress = React.useCallback((): void => {
    navigate(ROUTE_NAME.TOP_SETTING);
  }, []);
  return (
    <SafeAreaContainer>
      <View
        style={styles.topTradingMainContainer}
        dataSet={{media: ids.topTradingMainContainer}}
      >
        <TopBarWithRightButton
          headerLabel={TEXT.TOP_TRADING}
          Icon={
            <Pressable onPress={onPress}>
              <Settings stroke={ColorTheme.color} />
            </Pressable>
          }
          headerColor={ColorTheme.color}
        />
        <TopTradingScreens />
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(TopTrading);
