import React from 'react';
import {View, Text, Pressable} from 'react-native';
import BackButton from '../../components/button/backButton';
import PrimaryButton from '../../components/button/primaryButton';
import LabelInput from '../../components/input/labelInput';
import {FixedValue, TEXT} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {style} from './styles';
import SelectedCountry from '../../components/smallDesigns/selectedCountry';
import useDistributeHooks from '../../hooks/componentHooks/useDistributeHooks';
import RadioSelectButton from '../../components/renderSvgs/radioSelectButton';
import RadioUnselectButton from '../../components/renderSvgs/radioUnselectButton';
import {_InputType, _ToastHandler} from '../../utils/helperFunctions';
import {SignUpContext} from '../../contextAPI/signUpContext';
import CountriesList from '../../modals/allCountriesList/countriesList';
import BottomListModalWithCheckbox from '../../modals/bottomListWithCheckbox';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import {
  HomeStore,
  checkForMultipleAccountStart,
} from '../../redux/reducers/homeSlice';
import {useIsFocused} from '@react-navigation/native';
import {
  CHECK_FOR_MULTIPLE_ACCOUNT,
  FORGOT_USERNAME,
} from '../../constants/actionTypes';
import {
  ChildListModel,
  IUserProfileData,
} from '../../types/reducerTypes/userProfileTypes';
import {IUserMulipleData} from '../../types/reducerTypes/searchTabStoreType';
import {
  newPasswordState,
  onForgotUserNameStart,
} from '../../redux/reducers/resetPasswordSlice';
import {goBack} from '../../services/navigationService';
import {TOAST_TEXT} from '../../constants/textMessage';

const ForgotUserNameContentView: React.FC = () => {
  const {
    values,
    handleChange,
    handleBlur,
    InviteViaEmailOrPhoneError,
    isEmailSelected,
    onSelectEmailOrText,
    multipleUserListData,
    showMultipleUserModal,
    setShowMultipleUserModal,
    isValid,
    setMultipleUserListData,
    setFieldValue,
    setFieldTouched,
  } = useDistributeHooks();
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = style(ColorTheme);
  const {selectedCountry, setSelectedCountry, showCountry, setShowCountry} =
    React.useContext(SignUpContext);
  const {userDuplicateData} = useAppSelector(HomeStore);
  const isFocused = useIsFocused();
  const dispatch = useAppDispatch();
  const {forgotUserNameData} = useAppSelector(newPasswordState);

  React.useEffect(() => {
    if (isFocused) {
      switch (userDuplicateData.status) {
        case CHECK_FOR_MULTIPLE_ACCOUNT.SUCCESS:
          const isMultipleAccountExits =
            // @ts-ignore
            userDuplicateData.success?.data?.results?.length >
            FixedValue.CONSTANT_VALUE_1;
          if (isMultipleAccountExits) {
            setMultipleUserListData(
              userDuplicateData.success?.data?.results ?? []
            );
            setShowMultipleUserModal(true);
          } else {
            sendEmailOrOtp(
              userDuplicateData.success?.data?.results?.[
                FixedValue.CONSTANT_VALUE_0
              ] ?? null
            );
          }
          break;
        default:
          break;
      }
    }
  }, [userDuplicateData.status]);

  React.useEffect(() => {
    if (isFocused) {
      switch (forgotUserNameData.status) {
        case FORGOT_USERNAME.SUCCESS:
          const toastText = isEmailSelected
            ? TOAST_TEXT.THE_EMAIL_HAS_BEEN_SENT
            : TOAST_TEXT.THE_TEXT_HAS_BEEN_SENT;
          _ToastHandler(toastText, true);
          setFieldValue('email_mobile', '');
          setFieldTouched('email_mobile', false);
          break;
        default:
          break;
      }
    }
  }, [forgotUserNameData.status]);

  const sendEmailOrOtp = React.useCallback(
    (
      item: ChildListModel | IUserProfileData | IUserMulipleData | null
    ): void => {
      let data: any = {
        isEmailSelected,
      };
      if (item !== null) {
        data.userId = item?.id;
      }
      dispatch(onForgotUserNameStart(data));
      setShowMultipleUserModal(false);
    },
    [isEmailSelected]
  );

  const _RadioSelectButton = (): JSX.Element => (
    <RadioSelectButton
      width={FixedValue.CONSTANT_VALUE_28}
      height={FixedValue.CONSTANT_VALUE_28}
    />
  );

  const _RadioUnselectButton = (): JSX.Element => (
    <RadioUnselectButton
      width={FixedValue.CONSTANT_VALUE_28}
      height={FixedValue.CONSTANT_VALUE_28}
    />
  );

  const customModaldata = {
    closeModal: setShowMultipleUserModal,
    modalVisible: showMultipleUserModal,
    onPress: sendEmailOrOtp,
    listData: multipleUserListData ?? [],
    showLoggedInUser: false,
    headerText: TEXT.RETRIVE_USER_NAME,
  };

  const checkUserIsExistsOrNot = React.useCallback(() => {
    let paramsForEmailOrMobile: any = {isEmailSelected};
    if (isEmailSelected) {
      paramsForEmailOrMobile.email = values?.email_mobile?.trim().toLowerCase();
    } else {
      paramsForEmailOrMobile.phoneNumber = values?.email_mobile?.trim();
      paramsForEmailOrMobile.countryCode = selectedCountry?.dial_code || '+1';
    }
    dispatch(checkForMultipleAccountStart(paramsForEmailOrMobile));
    setShowMultipleUserModal(false);
  }, [values, selectedCountry, isEmailSelected, showMultipleUserModal]);

  const multipleChildListModal = React.useMemo((): JSX.Element | null => {
    return <BottomListModalWithCheckbox customModaldata={customModaldata} />;
  }, [
    multipleUserListData,
    showMultipleUserModal,
    setShowMultipleUserModal,
    isEmailSelected,
  ]);
  React.useEffect(() => {
    const initialCountryCode = {
      name: 'United States',
      flag: '🇺🇸',
      code: 'US',
      dial_code: '+1',
    };
    if (isFocused) {
      setSelectedCountry(initialCountryCode);
    }
  }, [isFocused]);
  return (
    <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
      <BackButton />
      <Text style={styles.titleText}>{TEXT.LOST_N_USERNAME}</Text>
      <Text style={styles.descText}>{TEXT.LOST_USERNAME_DESC}</Text>
      <>
        <View style={styles.emailOrPhoneContainer}>
          <Pressable
            style={styles.radioButtonAndTextContainer}
            onPress={() => onSelectEmailOrText(true)}
          >
            {isEmailSelected ? (
              <_RadioSelectButton />
            ) : (
              <_RadioUnselectButton />
            )}
            <Text style={styles.selectEmailOrNumber}>{TEXT.EMAIL}</Text>
          </Pressable>
          <Pressable
            style={styles.radioButtonAndTextContainer}
            onPress={() => onSelectEmailOrText(false)}
          >
            {!isEmailSelected ? (
              <_RadioSelectButton />
            ) : (
              <_RadioUnselectButton />
            )}
            <Text style={styles.selectEmailOrNumber}>{TEXT.TEXT}</Text>
          </Pressable>
        </View>
        <View>
          <LabelInput
            inputLabel={
              isEmailSelected
                ? TEXT.ENTER_YOUR_EMAIL
                : TEXT.ENTER_YOUR_MOBILE_NUMBER
            }
            value={values.email_mobile}
            onChangeText={handleChange(TEXT.EMAIL_OR_MOBILE)}
            onBlur={handleBlur(TEXT.EMAIL_OR_MOBILE)}
            haveError={InviteViaEmailOrPhoneError}
            maxLength={
              isEmailSelected
                ? FixedValue.CONSTANT_VALUE_200
                : FixedValue.CONSTANT_VALUE_10
            }
            placeholder={
              isEmailSelected ? TEXT.ENTER_EMAIL_ID_2 : TEXT.ENTER_MOBILE_NUMBER
            }
            LeftElement={!isEmailSelected ? <SelectedCountry /> : null}
            onPress={() => setShowCountry(true)}
            keyboardType={
              !isEmailSelected ? _InputType.phoneNumber : _InputType.email
            }
          />
        </View>
      </>
      <View style={styles.buttonViewStyle} />
      <PrimaryButton
        buttonLabel={isEmailSelected ? TEXT.SEND_EMAIL : TEXT.SEND_TEXT}
        onPress={checkUserIsExistsOrNot}
        isDisabled={!isValid}
        isLoading={userDuplicateData.isLoading || forgotUserNameData.isLoading}
      />
      <Text style={styles.dontHaveAccount}>
        {TEXT.HAVE_ACCOUNT}{' '}
        <Text onPress={goBack} style={styles.signUpText}>
          {TEXT.LOGIN_IN}
        </Text>
      </Text>
      <CountriesList
        onPress={() => setShowCountry(false)}
        showCountry={showCountry && !isEmailSelected}
        selectCountryCode={selectedCountry?.dial_code}
        selectedCountryFunction={setSelectedCountry}
      />
      {showMultipleUserModal && multipleChildListModal}
    </View>
  );
};

export default React.memo(ForgotUserNameContentView);
