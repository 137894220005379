import React from 'react';
import {Pressable, View} from 'react-native';

import {TopBuyingStyle} from './topBuying.style';
import {ThemeContext} from '../../contextAPI/themeContext';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import Settings from '../../components/renderSvgs/settings';
import {navigate} from '../../services/navigationService';
import {ROUTE_NAME, TEXT} from '../../constants';
import TopBuyingContent from './topBuying';
import TopBarWithRightButton from '../../components/header/topBarWithRightButton';
import AppLoader from '../../components/smallDesigns/appLoader';
import {ChildProfileContext} from '../../contextAPI/childProfileContext';

const TopBuying = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = TopBuyingStyle(ColorTheme);
  const {initialLoader} = React.useContext(ChildProfileContext);
  const onPress = React.useCallback((): void => {
    navigate(ROUTE_NAME.TOP_BUYING_SETTING);
  }, []);

  const SettingIcon = React.useMemo(
    (): JSX.Element => (
      <Pressable onPress={onPress}>
        <Settings stroke={ColorTheme.color} />
      </Pressable>
    ),
    []
  );

  return (
    <>
      {initialLoader && <AppLoader />}
      <SafeAreaContainer>
        <View
          style={styles.topBuyingMainContainer}
          dataSet={{media: ids.topBuyingMainContainer}}
        >
          <TopBarWithRightButton
            headerLabel={TEXT.TOP_BUYING_APPROVAL}
            Icon={SettingIcon}
            headerColor={ColorTheme.color}
          />
          <TopBuyingContent />
        </View>
      </SafeAreaContainer>
    </>
  );
};

export default React.memo(TopBuying);
