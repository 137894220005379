import React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';

const MentalIcon = (props: SvgProps) => (
  <Svg
    width={21}
    height={22}
    viewBox="0 0 21 22"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M19.7642 14.9999V6.9999C19.7638 6.64918 19.6712 6.30471 19.4957 6.00106C19.3202 5.69742 19.0679 5.44526 18.7642 5.2699L11.7642 1.2699C11.4601 1.09437 11.1152 1.00195 10.7642 1.00195C10.4131 1.00195 10.0682 1.09437 9.76416 1.2699L2.76416 5.2699C2.46042 5.44526 2.20814 5.69742 2.03262 6.00106C1.8571 6.30471 1.76452 6.64918 1.76416 6.9999V14.9999C1.76452 15.3506 1.8571 15.6951 2.03262 15.9987C2.20814 16.3024 2.46042 16.5545 2.76416 16.7299L9.76416 20.7299C10.0682 20.9054 10.4131 20.9979 10.7642 20.9979C11.1152 20.9979 11.4601 20.9054 11.7642 20.7299L18.7642 16.7299C19.0679 16.5545 19.3202 16.3024 19.4957 15.9987C19.6712 15.6951 19.7638 15.3506 19.7642 14.9999Z"
      stroke={props.stroke ?? 'black'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default MentalIcon;
