import React from 'react';
import {View} from 'react-native';
import {ThemeContext} from '../../../contextAPI/themeContext';
import SafeAreaContainer from '../../../components/layout/safeAreaContainer';
import {FixedValue, TEXT} from '../../../constants';
import {TopSettingStyle} from './setting.style';
import TopSettingScreen from './settingContent';
import {TRADE_APPROVAL_SETTING} from '../../../constants/actionTypes';
import {childProfileStore} from '../../../redux/reducers/childProfileSlice';
import {useAppSelector} from '../../../hooks/reduxHooks';
import {ChildProfileContext} from '../../../contextAPI/childProfileContext';
import useChildListHooks from '../../../hooks/componentHooks/useChildListHooks';
import TitleWithButton from '../../../components/header/titleWithButton';
import {_ToastHandler} from '../../../utils/helperFunctions';
import {TOAST_TEXT} from '../../../constants/textMessage';
import {goBack} from '../../../services/navigationService';
import {useIsFocused} from '@react-navigation/native';

const TopTradingSetting = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = TopSettingStyle(ColorTheme);
  const isFocused = useIsFocused();
  const {tradeSettingApproval} = useAppSelector(childProfileStore);
  const {childUserData, setShowTradeSettingChangePopUp} =
    React.useContext(ChildProfileContext);
  const {callChildProfileAPI} = useChildListHooks();

  React.useEffect(() => {
    if (isFocused) {
      switch (tradeSettingApproval.status) {
        case TRADE_APPROVAL_SETTING.SUCCESS:
          callChildProfileAPI(childUserData?.id ?? FixedValue.CONSTANT_VALUE_0);
          setShowTradeSettingChangePopUp(false);
          _ToastHandler(
            TOAST_TEXT.TOP_TRADING_APPROVAL_SUCCESFULLY_CHANGED,
            true
          );
          goBack();
          break;
        default:
          break;
      }
    }
  }, [tradeSettingApproval.status]);

  return (
    <SafeAreaContainer>
      <View
        style={styles.settingMainContainer}
        dataSet={{media: ids.settingMainContainer}}
      >
        <TitleWithButton title={TEXT.SETTING} />
        <TopSettingScreen />
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(TopTradingSetting);
