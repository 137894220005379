import React from 'react';
import {View} from 'react-native';
import LabelInput from '../../components/input/labelInput';

import CheckBoxWithTerm from '../../components/smallDesigns/checkBoxWithTerm';
import CheckboxWithText from '../../components/smallDesigns/checkboxWithText';
import {FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import {PROFILE_OPTIONS_TEXT} from '../../constants/textMessage';
import {SignUpContext} from '../../contextAPI/signUpContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import {navigate} from '../../services/navigationService';
import {IBasicForm} from '../../types/componentTypes/signUpType';
import {signUpStyle} from './signUp.style';
import {useAppDispatch} from '../../hooks/reduxHooks';
import {onParentUsernameStart} from '../../redux/reducers/authSlice';

const ParentialForm = (props: IBasicForm) => {
  const {
    values,
    handleBlur,
    handleChange,
    parentUserNameErr,
    parentEmailErr,
    errors,
    setIsFirstParentUserName,
    setFieldError,
  } = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = signUpStyle(ColorTheme);
  const {
    agreeTNC,
    setAgreeTNC,
    userAge,
    haveParentsUsername,
    setHaveParentsUsername,
    agreeReceiveSms,
    setAgreeReceiveSms,
  } = React.useContext(SignUpContext);
  const isAgeLessThan13: boolean =
    userAge >= FixedValue.CONSTANT_VALUE_0 &&
    userAge < FixedValue.CONSTANT_VALUE_13;
  const dispatch = useAppDispatch();

  const navigateTo = () => {
    const headerTitle = PROFILE_OPTIONS_TEXT.TERMS_CONDITIONS;
    const webUrl =
      'https://d2gt0lv09tntxf.cloudfront.net/term-and-service.html';
    navigate(ROUTE_NAME.WEB_VIEW_SCREEN, {headerTitle, webUrl});
    return;
  };

  const navigateToReceiveSMS = () => {
    const params = {
      headerTitle: TEXT.RECEIVE_SMS,
      renderHTML: true,
    };
    navigate(ROUTE_NAME.WEB_VIEW_SCREEN, params);
  };

  const handleParentUsernameChange = (name: any, value: any) => {
    // @ts-ignore
    handleChange(name)(value);
    if (
      (name === TEXT.PARENT_USERNAME || name === TEXT.PARENT_USERNAME_2) &&
      setIsFirstParentUserName &&
      value.length > FixedValue.CONSTANT_VALUE_0
    ) {
      const isFirstUserName = TEXT.PARENT_USERNAME === name;
      setIsFirstParentUserName(isFirstUserName);
      if (TEXT.PARENT_USERNAME_2 === name) {
        if (value.trim().length > FixedValue.CONSTANT_VALUE_0) {
          dispatch(onParentUsernameStart({username: value}));
        } else {
          setFieldError && setFieldError(TEXT.PARENT_EMAIL_2, '');
        }
      }
    }
  };

  return (
    <>
      {isAgeLessThan13 && (
        <>
          <CheckboxWithText
            isAccept={haveParentsUsername}
            termText={TEXT.TERM_TEXT_PERENTS}
            onPress={setHaveParentsUsername}
          />
          <View style={styles.dividerLine} />
        </>
      )}
      {isAgeLessThan13 && haveParentsUsername && (
        <LabelInput
          inputLabel={TEXT.PARENT_USERNAME_}
          value={values.ParentUsername}
          placeholder={TEXT.ENTER_USERNAME_2}
          onChangeText={value =>
            handleParentUsernameChange(TEXT.PARENT_USERNAME, value)
          }
          onBlur={handleBlur(TEXT.PARENT_USERNAME)}
          maxLength={FixedValue.CONSTANT_VALUE_50}
          haveError={`${parentUserNameErr}`}
        />
      )}
      {isAgeLessThan13 && (
        <LabelInput
          inputLabel={TEXT.PARENT_EMAIL_}
          value={values.ParentEmail}
          placeholder={TEXT.ENTER_EMAIL_ID_2}
          onChangeText={handleChange(TEXT.PARENT_EMAIL)}
          onBlur={handleBlur(TEXT.PARENT_EMAIL)}
          maxLength={FixedValue.CONSTANT_VALUE_50}
          haveError={`${parentEmailErr}`}
        />
      )}
      {isAgeLessThan13 && haveParentsUsername && (
        <LabelInput
          inputLabel={TEXT.SECOND_PARENT_USERNAME_}
          value={values.ParentUsername2}
          placeholder={TEXT.ENTER_USERNAME_2}
          onChangeText={value =>
            handleParentUsernameChange(TEXT.PARENT_USERNAME_2, value)
          }
          onBlur={handleBlur(TEXT.PARENT_USERNAME_2)}
          maxLength={FixedValue.CONSTANT_VALUE_50}
          haveError={errors?.ParentUsername2}
        />
      )}
      {isAgeLessThan13 && (
        <LabelInput
          inputLabel={TEXT.PARENT_EMAIL_2_}
          value={values.ParentEmail2}
          placeholder={TEXT.ENTER_EMAIL_ID_2}
          onChangeText={handleChange(TEXT.PARENT_EMAIL_2)}
          onBlur={handleBlur(TEXT.PARENT_EMAIL_2)}
          maxLength={FixedValue.CONSTANT_VALUE_50}
          haveError={errors?.ParentEmail2}
        />
      )}
      <CheckBoxWithTerm
        isAccept={agreeTNC}
        onPress={setAgreeTNC}
        onPressTerms={() => navigateTo()}
      />
      <View style={styles.marginTop10}>
        <CheckBoxWithTerm
          isAccept={agreeReceiveSms}
          onPress={() => setAgreeReceiveSms(!agreeReceiveSms)}
          onPressTerms={() => navigateToReceiveSMS()}
          highLightText={TEXT.SMS_NOTIFICATION}
          firstText={TEXT.I_AGREE_TO_RECEIVE}
        />
      </View>
    </>
  );
};

export default React.memo(ParentialForm);
