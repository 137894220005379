import React from 'react';
import {ImageBackground, Pressable, View} from 'react-native';
import ModalContainer from '../../components/layout/modalContainer';
import {ThemeContext} from '../../contextAPI/themeContext';
import CreateGroupModalField from './createEditGroupField';
import {createGroupModalStyle} from './createEditGroupModal.styles';
import {GroupContext} from '../../contextAPI/groupManagementContext';
import {COLORS, FixedValue, TEXT} from '../../constants';
import EditPencil from '../../components/renderSvgs/editPencil';
import useCreateEditGroupHooks from '../../hooks/componentHooks/useCreateEditGroupHooks';
import PlusIcon from '../../components/renderSvgs/plusIcon';
import ModalHeaderWithCross from '../../components/header/modalHeaderWithCross';
import useAwsS3 from '../../hooks/libraryHooks/useAwsS3';

const CreateEditGroupFormModal = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = createGroupModalStyle(ColorTheme);
  const {
    isGroupEditMode,
    groupProfileImages,
    openCreateGroupModal,
    groupInitialImage,
  } = React.useContext(GroupContext);
  const {closeProfileModalAndImageBlank, openProfileModal} =
    useCreateEditGroupHooks();
  const {loadSignedImageUrl} = useAwsS3();
  const [svgCode, setSvgCode] = React.useState<string | undefined>();
  const [_, setErrorInDecoding] = React.useState<boolean>();
  const {groupDetailData} = React.useContext(GroupContext);
  const modalHeaderName = isGroupEditMode
    ? TEXT.EDIT_GROUP
    : TEXT.CREATE_NEW_GROUP;

  const getGroupInitials = (): string => {
    // Get the first letter of each word in the group name
    const words = groupDetailData?.groupName.split(' ');
    const initials = words?.map(word => word.charAt(0));
    return initials?.join('') ?? '';
  };

  const getSvgUrl = React.useCallback(async (): Promise<void> => {
    try {
      const imageUrl = await loadSignedImageUrl(groupInitialImage ?? '');
      if (imageUrl) {
        setSvgCode(imageUrl);
      }
    } catch (error) {
      console.log('Error fetching or decoding SVG image:', error);
      setErrorInDecoding(true);
    }
  }, []);

  React.useEffect(() => {
    if (groupInitialImage !== '') {
      getSvgUrl();
    }
  }, [groupInitialImage]);

  const RenderImage = React.useMemo((): JSX.Element | undefined => {
    if (svgCode) {
      try {
        return (
          <Pressable onPress={() => openProfileModal()}>
            <ImageBackground
              style={[
                styles.profilePlaceholderView,
                styles.imageBackgroundColor,
              ]}
              source={{
                uri: svgCode,
              }}
            >
              <EditPencil
                height={FixedValue.CONSTANT_VALUE_20}
                width={FixedValue.CONSTANT_VALUE_20}
                stroke={COLORS.WHITE}
              />
            </ImageBackground>
          </Pressable>
        );
      } catch (error) {
        console.log('Error decoding SVG image:', error);
      }
    }
  }, [svgCode]);

  const RenderImageThroughURI = React.useMemo((): JSX.Element | undefined => {
    if (groupProfileImages?.uri) {
      return (
        <Pressable onPress={() => openProfileModal()}>
          <ImageBackground
            style={[styles.profilePlaceholderView, styles.imageBackgroundColor]}
            source={{
              uri: groupProfileImages?.uri,
            }}
          >
            <EditPencil
              height={FixedValue.CONSTANT_VALUE_20}
              width={FixedValue.CONSTANT_VALUE_20}
              stroke={COLORS.WHITE}
            />
          </ImageBackground>
        </Pressable>
      );
    } else {
      return (
        <Pressable
          onPress={() => openProfileModal()}
          style={[styles.profilePlaceholderView, styles.imageBackgroundColor]}
        >
          <PlusIcon
            stroke={ColorTheme.backgroundColor}
            height={FixedValue.CONSTANT_VALUE_28}
            width={FixedValue.CONSTANT_VALUE_28}
          />
        </Pressable>
      );
    }
  }, [groupProfileImages, groupInitialImage]);

  return (
    <ModalContainer
      visible={openCreateGroupModal}
      onPressOutside={closeProfileModalAndImageBlank}
      backgroundColor={true}
    >
      <View style={styles.subContainer}>
        <ModalHeaderWithCross
          onPress={closeProfileModalAndImageBlank}
          headerTitle={modalHeaderName}
        />
        {groupInitialImage !== '' ? (
          <>{RenderImage}</>
        ) : (
          <>{RenderImageThroughURI}</>
        )}
        <CreateGroupModalField isEditMode={isGroupEditMode} />
      </View>
    </ModalContainer>
  );
};

export default CreateEditGroupFormModal;
