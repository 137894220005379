import React from 'react';
import {Pressable, StyleSheet} from 'react-native';

import {BASESTYLE, FixedValue} from '../../constants';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import CreateIcon from '../renderSvgs/createCollection';
import {ICreateCollectionButton} from '../../types/componentTypes/buttonType';
import {ThemeContext} from '../../contextAPI/themeContext';

const styles = StyleSheet.create({
  viewStyle: {
    ...BASESTYLE.inCenter,
  },
});

const CreateCollectionButton = (
  props: ICreateCollectionButton
): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);

  const onPress = React.useCallback((): void => {
    props.onPress();
  }, []);
  return (
    <Pressable style={styles.viewStyle} onPress={onPress}>
      <CreateIcon
        stroke={ColorTheme.color}
        height={scaleHeight(FixedValue.CONSTANT_VALUE_24)}
        width={scaleWidth(FixedValue.CONSTANT_VALUE_24)}
      />
    </Pressable>
  );
};

export default React.memo(CreateCollectionButton);
