import {METHODS} from './enum';

const MULTIPARTS = 'multipart/form-data';

export const API_URL = {
  DEVICE: {
    name: 'user/device',
    type: METHODS.POST,
  },
  LOGOUT: {
    name: 'user/logout',
    type: METHODS.POST,
  },
  DELETE_ACCOUNT: {
    name: 'user',
    type: METHODS.DELETE,
  },
  USER_AVAILABILITY: {
    name: 'user/auth/check-username-availability',
    type: METHODS.GET,
  },
  PARENT_INFO: {
    name: 'user/auth/parent-info',
    type: METHODS.GET,
  },
  CREATE_NEW_TOP: {
    name: 'top',
    type: METHODS.POST,
  },
  USER_REGISTER: {
    name: 'user/auth/register',
    type: METHODS.POST,
  },
  CREATE_NEW_COLLECTION: {
    name: 'collection',
    type: METHODS.POST,
  },
  COLLECTION_TOP_LISTS: {
    name: 'top/collections',
    type: METHODS.GET,
  },
  USER_PROFILE: {
    name: 'user',
    type: METHODS.GET,
  },
  GET_IN_PROGRESS_TOPS: {
    name: 'top/list/',
    type: METHODS.GET,
  },
  BUY_TOPS: {
    name: 'top/purchase/',
    type: METHODS.GET,
  },
  ADD_TOPS_TO_COLLECTION: {
    name: 'top/move-to-collection',
    type: METHODS.PUT,
  },
  ADD_PARENT_CHILD_LIST: {
    name: 'user/minor/list',
    type: METHODS.GET,
  },
  DELETE_COLLECTION_AND_TOPS: {
    name: 'collection',
    type: METHODS.DELETE,
  },
  DELETE_TOPS: {
    name: 'top',
    type: METHODS.GET,
  },
  CREATE_NEW_GROUP: {
    name: 'group',
    type: METHODS.POST,
  },
  UPDATE_GROUP_END_POINT: {
    name: 'group/update-group',
    type: METHODS.PUT,
  },
  CHILD_APPROVEL_REQUEST_LIST: {
    name: 'user/approval-request/list',
    type: METHODS.GET,
  },
  USER_LIST_END_POINTS: {
    name: 'group/user-list',
    type: METHODS.GET,
  },
  USER_ADD_IN_GROUP: {
    name: 'group',
    type: METHODS.POST,
  },
  MY_GROUP_LIST_END_POINTS: {
    name: 'group',
    type: METHODS.GET,
  },
  GROUP_I_AM_IN_LIST_END_POINTS: {
    name: 'group/group-list',
    type: METHODS.GET,
  },
  GROUP_INVITATIONS_LIST_END_POINTS: {
    name: 'group/invitations',
    type: METHODS.GET,
  },
  APPROVE_OR_REJECT: {
    name: 'user/approval-request',
    type: METHODS.PUT,
  },
  GET_CHILD_PROFILE: {
    name: 'user/profile',
    type: METHODS.GET,
  },
  GROUP_INVITATIONS_APPROVE_END_POINTS: {
    name: 'group',
    type: METHODS.PUT,
  },
  GROUP_INVITATIONS_REJECT_END_POINTS: {
    name: 'group/reject',
    type: METHODS.PUT,
  },
  UPDATE_PROFILE: {
    name: 'user/profile/update-profile',
    type: METHODS.PUT,
  },
  UPDATE_PROFILE_IMAGE: {
    name: 'user/profile/update-profile-image',
    type: METHODS.PUT,
  },
  MY_GROUP_USER_LIST_END_POINTS: {
    name: 'group/userList',
    type: METHODS.GET,
  },
  GROUP_I_AM_IN_USER_LIST_END_POINTS: {
    name: 'group/group-user-detail',
    type: METHODS.GET,
  },
  GET_GROUP_DETAIL: {
    name: 'group',
    type: METHODS.GET,
  },
  GROUP_USER_REMOVE_END_POINTS: {
    name: 'group',
    type: METHODS.DELETE,
  },
  DELETE_GROUP_END_POINTS: {
    name: 'group/delete-group',
    type: METHODS.DELETE,
  },
  TRADE_RECEVIED_SEND_OFFER: {
    name: 'top/offer',
    type: METHODS.GET,
  },
  USER_LIST_SEARCH_END_POINTS: {
    name: 'user/search',
    type: METHODS.GET,
  },
  DISTRIBUTE_TOP: {
    name: 'top/distribute',
    type: METHODS.POST,
  },
  LEAVE_GROUP_END_POINTS: {
    name: 'group/leave-group',
    type: METHODS.DELETE,
  },
  GROUP_LIST_SEARCH_END_POINTS: {
    name: 'group/search',
    type: METHODS.GET,
  },
  CREATE_TRADE_OFFER: {
    name: 'top/offer',
    type: METHODS.POST,
  },
  ADD_TOPS_TO_COLLECTION_WITH_QUANTITY: {
    name: 'top/move-to-collection-with-quantity',
    type: METHODS.PUT,
  },
  CART_CHECKOUT_API: {
    name: 'top/cart/checkout',
    type: METHODS.POST,
  },
  TRADE_APPROVAL_REQUEST: {
    name: 'permission/offer',
    type: METHODS.PUT,
  },
  TRADE_NOT_APPROVED_USER_LIST: {
    name: 'permission/user/search',
    type: METHODS.GET,
  },
  TRADE_USER_ADD_FOR_APPROVAL: {
    name: 'permission/offer/add-user',
    type: METHODS.POST,
  },
  TRADE_APPROVED_USER_LIST_END_POINT: {
    name: 'permission/offer/get-users',
    type: METHODS.GET,
  },
  INVITATIONS_FOR_CHILD: {
    name: 'group/invitationsForChild',
    type: METHODS.GET,
  },
  USER_REMOVE_FROM_TRADE_APPROVE_LIST: {
    name: 'permission/offer/remove-user',
    type: METHODS.DELETE,
  },
  CHILD_GROUP_APPROVAL_REQUEST: {
    name: 'permission/group',
    type: METHODS.PUT,
  },
  CHILD_TRADABLE_REQUEST: {
    name: 'top/tradable',
    type: METHODS.GET,
  },
  CHILD_TRADABLE_SETTING: {
    name: 'permission/tradable',
    type: METHODS.PUT,
  },
  DISTRIBUTE_TOP_APPROVAL_REQUEST: {
    name: 'permission/distribute',
    type: METHODS.PUT,
  },
  NOTIFICATION_LIST: {
    name: 'notification',
    type: METHODS.GET,
  },
  PURCHASE_PERMISSION: {
    name: 'permission/purchase',
    type: METHODS.PUT,
  },
  DISTRIBUTE_USER_ADD_FOR_APPROVAL: {
    name: 'permission/distribute/add-user',
    type: METHODS.POST,
  },
  DISTRIBUTE_APPROVED_USER_LIST_END_POINT: {
    name: 'permission/distribute/get-users',
    type: METHODS.GET,
  },
  USER_REMOVE_FROM_DISTRIBUTE_APPROVE_LIST: {
    name: 'permission/distribute/remove-user',
    type: METHODS.DELETE,
  },
  DISTRIBUTE_REQUEST_LIST_END_POINT: {
    name: 'top/distribute',
    type: METHODS.GET,
  },
  CART_BUYING_REQUEST_TO_PARENT_API: {
    name: '/top/purchase/request-to-guardian',
    type: METHODS.POST,
  },
  RESEND_APPROVAL_REQUEST: {
    name: 'user/minor/resend-account-approval-request',
    type: METHODS.PUT,
  },
  GET_CART_AMOUNT_AND_ABUSE_DATA: {
    name: 'open-api/config',
    type: METHODS.GET,
  },
  COUPON_CODE_END_POINT: {
    name: 'coupon/verfiy-coupon',
    type: METHODS.GET,
  },
  GET_ABUSE_DATA_END_POINT: {
    name: 'open-api/report-abuse/list',
    type: METHODS.GET,
  },
  TRADE_REPORT_END_POINT: {
    name: 'top/offer/report',
    type: METHODS.POST,
  },
  UPLOAD_TOP_IMAGE_END_POINT: {
    name: 'upload-s3-file',
    type: METHODS.PUT,
    contType: MULTIPARTS,
  },
  TOP_TRADE_STATUS_UPDATE: {
    name: 'top/offer/update-status',
    type: METHODS.PUT,
  },
  BUY_PLAN_END_POINT: {
    name: 'wallet',
    type: METHODS.PUT,
  },
  MY_TRANSACTION_END_POINT: {
    name: 'transaction-history/list',
    type: METHODS.GET,
  },
  TOPCREDITS_REQUEST_ACTION_END_POINT: {
    name: 'wallet/wallet/',
    type: METHODS.PUT,
  },
  BLOCK_USER_LIST_END_POINT: {
    name: 'user/block/list',
    type: METHODS.GET,
  },
  UNBLOCK_USER_END_POINTS: {
    name: 'user/unblock',
    type: METHODS.POST,
  },
  DISTRIBUTE_TOP_VIA_EMAIL_TEXT: {
    name: 'top/distributeBulkEmail',
    type: METHODS.POST,
  },
  DISTRIBUTE_TOP_CSV: {
    name: 'top/distributeBulkEmail/csv',
    type: METHODS.POST,
  },
  REEDEM_CODE_END_POINT: {
    name: 'top/verify/redeem-code',
    type: METHODS.POST,
  },
  FAVORITE_USER_LIST_END_POINT: {
    name: 'favorite/list-favorite-users',
    type: METHODS.GET,
  },
  FAVORITE_USER_CHECK: {
    name: 'favorite/checkFavorite',
    type: METHODS.GET,
  },
  MARK_UNMARK_FAVORITE_USER: {
    name: 'favorite/updateFavorite',
    type: METHODS.POST,
  },
  NOTES_ADD_END_POINT: {
    name: 'note/addNote',
    type: METHODS.POST,
  },
  NOTES_UPDATE_END_POINT: {
    name: 'note/updateNote',
    type: METHODS.PUT,
  },
  NOTES_LIST_END_POINT: {
    name: 'note/list',
    type: METHODS.GET,
  },
  NOTES_DELETE_END_POINT: {
    name: 'note/deleteNote',
    type: METHODS.DELETE,
  },
  TRANSFER_TOP_CREDIT_END_POINT: {
    name: 'wallet/wallet/transferTopCredits',
    type: METHODS.PUT,
  },
  TOP_CREDITS_PULL_REQUEST: {
    name: 'wallet/pullBack',
    type: METHODS.PUT,
  },
  TRACKING_LIST: {
    name: 'top/combined',
    type: METHODS.GET,
  },
  USER_SEARCH_END_POINT: {
    name: 'user/search',
    type: METHODS.GET,
  },
  TRACKING_CANCEL: {
    name: 'top/cancel',
    type: METHODS.PUT,
  },
  TRACKING_DETAIL: {
    name: 'top/detail',
    type: METHODS.GET,
  },
  SEND_REMINDER: {
    name: 'top/reminder',
    type: METHODS.POST,
  },
  MULTIPLE_ACCOUNT_SEARCH_END_POINTS: {
    name: 'user/searchUsers',
    type: METHODS.GET,
  },
  FORGOT_USERNAME_END_POINTS: {
    name: 'user/sendUsername',
    type: METHODS.POST,
  },
  TUTORIAL_LIST: {
    name: 'tutorials/list',
    type: METHODS.GET,
  },
  SEND_REMINDER_ALL: {
    name: 'top/remindAll',
    type: METHODS.PUT,
  },
};
