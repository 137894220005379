import React from 'react';
import {View, Text} from 'react-native';

import AppIconMd from './../../components/renderSvgs/appIcon';
import {TEXT, ROUTE_NAME} from '../../constants';
import {welcomeStyle} from './welcome.style';
import PrimaryButton from '../../components/button/primaryButton';
import SecondaryButton from '../../components/button/secondaryButton';
import {reset} from '../../services/navigationService';
import {ThemeContext} from '../../contextAPI/themeContext';
import {storeDataLocally} from '../../utils/helperFunctions';
import {StorageConstants} from '../../constants/appConst';
import {appStateName, FixedValue} from '../../constants/enum';

const ContentView: React.FC = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {ids, styles} = welcomeStyle(ColorTheme);

  const _navigateTo = React.useCallback(async (name: string): Promise<void> => {
    await storeDataLocally(
      StorageConstants.USER_APP_STATE,
      appStateName.AUTHENTICATION
    );
    reset(name);
  }, []);

  return (
    <View style={styles.outterContainer} dataSet={{media: ids.outterContainer}}>
      <View style={styles.logoView}>
        <AppIconMd
          width={FixedValue.CONSTANT_VALUE_136}
          height={FixedValue.CONSTANT_VALUE_154}
        />
      </View>
      <Text style={styles.titleText}>{TEXT.WELCOME_TO_MANTELTOP}</Text>
      <Text style={styles.descText}>{TEXT.APP_SLOGAN}</Text>
      <PrimaryButton
        buttonLabel={TEXT.CREATE_YOUR_FREE_ACCOUNT}
        onPress={() => _navigateTo(ROUTE_NAME.SIGN_UP)}
      />
      <View style={styles.bottomSpacing} />
      <View style={styles.button1Style}>
        <SecondaryButton
          buttonLabel={TEXT.ALREADY_HAVE_ACCOUNT + TEXT.LOGIN}
          onPress={() => _navigateTo(ROUTE_NAME.LOGIN_SCREEN)}
        />
      </View>
    </View>
  );
};

export default React.memo(ContentView);
