import React from 'react';

import ViewContainer from '../../components/layout/viewContainer';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import EditorContent from './editorContent';
import {EditorContext, EditorProvider} from '../../contextAPI/editorContext';
import {EditorImageProvider} from '../../contextAPI/editorImageContext';
import {EditorShapeProvider} from '../../contextAPI/editorShapeContext';
import {EditorTextProvider} from '../../contextAPI/editorTextContext';
import EditorFormModal from '../../modals/editorFormModal';
import AppLoader from '../../components/smallDesigns/appLoader';
import {useAppSelector} from '../../hooks/reduxHooks';
import {editorState} from '../../redux/reducers/editorSlice';
import {ThemeContext} from '../../contextAPI/themeContext';
import useEditorHooks from '../../hooks/componentHooks/useEditorHooks';
import CustomModal from '../../modals/commonModal';
import {FixedValue, TEXT} from '../../constants';

const EditorSubScreen = () => {
  const {createNewTop} = useAppSelector(editorState);
  const {ColorTheme} = React.useContext(ThemeContext);
  const {showCopyrightModal, setShowCopyrightModal} =
    React.useContext(EditorContext);
  const {SaveUpdateTop} = useEditorHooks();

  return (
    <ViewContainer>
      <SafeAreaContainer>
        <>
          <EditorContent />
          <EditorFormModal />
          {showCopyrightModal && (
            <CustomModal
              modalVisible={showCopyrightModal}
              onClose={() => setShowCopyrightModal(false)}
              actionType={FixedValue.CONSTANT_VALUE_1}
              description={TEXT.COMMERICIAL_MESSAGE}
              yesOptionClicked={() => {
                setShowCopyrightModal(false);
                SaveUpdateTop();
              }}
              confirmButtonTitle={TEXT.CONFIRM}
              rejectButtonTitle={TEXT.CANCEL}
            />
          )}
        </>
      </SafeAreaContainer>
      {createNewTop.isLoading && (
        <AppLoader backgroundColor={ColorTheme?.loaderBackgroundColor} />
      )}
    </ViewContainer>
  );
};

const EditorScreen = () => (
  <EditorProvider>
    <EditorImageProvider>
      <EditorShapeProvider>
        <EditorTextProvider>
          <EditorSubScreen />
        </EditorTextProvider>
      </EditorShapeProvider>
    </EditorImageProvider>
  </EditorProvider>
);

export default React.memo(EditorScreen);
