import React from 'react';
import {View} from 'react-native';
import OTPInputView from '@twotalltotems/react-native-otp-input';

import {ThemeContext} from '../../contextAPI/themeContext';
import {styles} from './Input.style';
import {FixedValue} from '../../constants';
import {IOtpInput} from '../../types/componentTypes/inputTypes';
import {useAppSelector} from '../../hooks/reduxHooks';
import {verifyOtpState} from '../../redux/reducers/verifyOtp';
import {VERIFY_OTP} from '../../constants/actionTypes';

const OtpInput = (props: IOtpInput): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  const [isError, setIsError] = React.useState<boolean>(false);
  const {verifyOtp} = useAppSelector(verifyOtpState);
  const borderColor = isError ? style.redBorderColor : style.lightGreyBorder;

  React.useEffect((): void => {
    switch (verifyOtp.status) {
      case VERIFY_OTP.SUCCESS:
        setIsError(false);
        break;
      case VERIFY_OTP.FAILED:
        setIsError(true);
        break;
      default:
        break;
    }
  }, [verifyOtp.status]);

  return (
    <View style={style.otpContainer}>
      <OTPInputView
        pinCount={FixedValue.CONSTANT_VALUE_6}
        autoFocusOnLoad
        code={props.value}
        onCodeChanged={props.onChangeText}
        codeInputFieldStyle={borderColor}
        codeInputHighlightStyle={style.underlineStyleHighLighted}
      />
    </View>
  );
};

export default React.memo(OtpInput);
