import React from 'react';
import {FlatList, Keyboard, Modal, Pressable, View} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {CustomUserSelctModalProps} from '../../types/reducerTypes/searchTabStoreType';
import {BASESTYLE, FixedValue, GlobalStyleValues, TEXT} from '../../constants';
import {customModalForSelectUserStyle} from './customModalForSelectUser.style';
import ModalHeaderWithCross from '../../components/header/modalHeaderWithCross';
import SearchInput from '../../components/input/searchInput';
import UserListComponent from '../../components/userListComponent';
import PrimaryButton from '../../components/button/primaryButton';
import AppLoader from '../../components/smallDesigns/appLoader';

const CustomModalForSelectUser = (
  props: CustomUserSelctModalProps
): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = customModalForSelectUserStyle(ColorTheme);
  const {customModaldata} = props;

  return (
    <Modal transparent visible={customModaldata?.modalVisible}>
      {customModaldata?.initialLoader && <AppLoader />}
      <View style={styles.modalStyle} dataSet={{media: ids.modalStyle}}>
        <Pressable
          style={styles.outterPart}
          onPress={() => customModaldata?.closeModal()}
        />
        <View style={styles.innerView}>
          <ModalHeaderWithCross
            onPress={customModaldata?.closeModal}
            headerTitle={customModaldata.modalHeaderName}
          />
          <SearchInput
            placeholder={customModaldata.searchPlaceholder}
            value={customModaldata.value}
            onChangeText={customModaldata.onChangeText}
          />
          <FlatList
            keyExtractor={(_, index) => `${TEXT.USER_LIST}-${index}`}
            keyboardShouldPersistTaps={GlobalStyleValues.HANDLED}
            data={customModaldata?.listData}
            contentContainerStyle={styles.flatListContentStyle}
            onEndReached={customModaldata?.onReachedEnd}
            onEndReachedThreshold={FixedValue.CONSTANT_VALUE_1}
            showsVerticalScrollIndicator={false}
            onScrollBeginDrag={() => Keyboard.dismiss()}
            scrollEnabled
            renderItem={({item}) => (
              <UserListComponent
                key={item.id}
                // @ts-ignore
                item={item}
                showLogInUser={customModaldata?.showLogInUser ?? false}
                showInvitationPending={customModaldata?.showInvitationPending}
                onSelectOrUnSelectUser={customModaldata.onSelectOrUnSelectUser}
              />
            )}
          />
          {customModaldata?.selectedUsers &&
            customModaldata?.selectedUsers?.length >
              FixedValue.CONSTANT_VALUE_0 && (
              <View style={BASESTYLE.inCenter}>
                <PrimaryButton
                  isLoading={customModaldata?.buttonLoader}
                  buttonLabel={TEXT.ADD_NEW}
                  buttonwidth={styles.buttonStyle}
                  labelStyle={styles.buttonLabelstyle}
                  onPress={() => customModaldata.callAddUserToGroupAPI()}
                />
              </View>
            )}
        </View>
      </View>
    </Modal>
  );
};

export default CustomModalForSelectUser;
