import React from 'react';

import {FixedValue} from '../../constants';
import {controllerName} from '../../constants/enum';
import {EditorContext} from '../../contextAPI/editorContext';
import {EditorTextContext} from '../../contextAPI/editorTextContext';
import {
  TextRefType,
  TopEditorItemType,
} from '../../types/componentTypes/editorType';
import {numberToArray} from '../../utils/helperFunctions';

const useEditorText = () => {
  const {
    selectedItemIndex,
    items,
    setItems,
    deleteSelectedItem,
    setPrimaryTabSelectedIndex,
  } = React.useContext(EditorContext);
  const {
    selectedController,
    textValue,
    selectedText,
    selectedFont,
    setSelectedFont,
    setTextValue,
    setShowTextInput,
    setSelectedText,
  } = React.useContext(EditorTextContext);
  const isNotMinusOne: boolean =
    selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1;
  const showScaleController: boolean =
    isNotMinusOne && selectedController === controllerName.SCALE;
  const showRotationController: boolean =
    isNotMinusOne && selectedController === controllerName.ROTATION;
  const showPaletteController: boolean =
    isNotMinusOne && selectedController === controllerName.COLOR_PELETTE;

  const sizeResult: number[] = numberToArray(
    selectedText?.current?.getPosition()?.scale
  );
  const rotationResult: number[] = numberToArray(
    selectedText?.current?.getPosition()?.rotation
  );

  const onChangeSizeValue = React.useCallback(
    (val: number[]): void => {
      if (selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1) {
        // @ts-ignore
        selectedText?.current?.changeScale(val[FixedValue.CONSTANT_VALUE_0]);
      }
    },
    [selectedText, selectedItemIndex]
  );

  const onChangeRotationValue = React.useCallback(
    (val: number[]): void => {
      if (selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1) {
        // @ts-ignore
        selectedText?.current?.changeRotation(val[FixedValue.CONSTANT_VALUE_0]);
      }
    },
    [selectedText, selectedItemIndex]
  );

  const onChangeTextFont = React.useCallback(
    (font: string): void => {
      if (selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1) {
        // @ts-ignore
        selectedText?.current?.changeFont(font);
        setSelectedFont(font);
      }
    },
    [selectedText, selectedItemIndex]
  );

  const addNewText = React.useCallback(
    (el: JSX.Element): void => {
      setItems(el);
      setTextValue('');
    },
    [textValue]
  );

  const onChangeFillColor = React.useCallback(
    (color: string): void => {
      if (selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1) {
        // @ts-ignore
        items[selectedItemIndex]?.ref?.current?.changeFillColor(color);
      }
    },
    [selectedItemIndex, items]
  );

  const onChangeStrokeColor = React.useCallback(
    (color: string): void => {
      if (selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1) {
        // @ts-ignore
        items[selectedItemIndex]?.ref?.current?.changeStroke(color);
      }
    },
    [selectedItemIndex, items]
  );

  const onDeleteText = React.useCallback((): void => {
    deleteSelectedItem();
    setTextValue('');
    if (items.length === FixedValue.CONSTANT_VALUE_1) {
      setPrimaryTabSelectedIndex(FixedValue.CONSTANT_VALUE_MIN_1);
    }
  }, [items, selectedItemIndex]);

  const makeTextEditable = React.useCallback((): void => {
    if (selectedText != null) {
      setTextValue(selectedText?.current?.getPosition().textValue);
      setShowTextInput(true);
    }
  }, [selectedText]);

  React.useEffect((): void => {
    if (
      selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1 &&
      selectedText != null
    ) {
      selectedText?.current.changeTextValue(textValue);
    }
  }, [selectedText, textValue]);

  React.useEffect((): void => {
    if (selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1) {
      const item = // @ts-ignore -- used to get item ref
        items[selectedItemIndex].ref as NonNullRefObject<TextRefType>;
      if (item.current?.getTopEditorItemType() === TopEditorItemType.TEXT) {
        setTextValue(item.current.getPosition().textValue);
        setShowTextInput(false);
        setSelectedText(item);
        setSelectedFont(item?.current?.getPosition()?.font);
      }
    } else {
      setSelectedFont('');
    }
  }, [selectedItemIndex, items]);

  return {
    showPaletteController,
    showRotationController,
    showScaleController,
    sizeResult,
    rotationResult,
    selectedFont,
    onChangeTextFont,
    addNewText,
    onChangeSizeValue,
    onChangeRotationValue,
    onChangeFillColor,
    onChangeStrokeColor,
    onDeleteText,
    makeTextEditable,
  };
};

export default useEditorText;
