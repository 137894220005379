import React from 'react';
import {View} from 'react-native';
import ModalContainer from '../../components/layout/modalContainer';
import {HomeContext} from '../../contextAPI/homeContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import {editCollectionModalStyle} from './editCollectionModal.styles';
import EditCollectionContent from './editCollectionModalContent';
import {EditCollectionModalProps} from '../../types/reducerTypes/searchTabStoreType';

const EditCollectionModal = (props: EditCollectionModalProps): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = editCollectionModalStyle(ColorTheme);
  const {openCreateModal, setOpenCreateModal} = React.useContext(HomeContext);
  return (
    <ModalContainer
      visible={openCreateModal}
      onPressOutside={setOpenCreateModal}
    >
      <View style={styles.subContainer}>
        <EditCollectionContent
          showUncategorizedListId={props?.showUncategorizedListId}
          totalTopsInUncategorizedCollections={
            props?.totalTopsInUncategorizedCollections
          }
        />
      </View>
    </ModalContainer>
  );
};

export default EditCollectionModal;
