import {webFonts} from '../../constants/enum';
import {ImageController} from '../contextTypes/editorContext';

/** type that contains all necessary details for a UserImage */
export type UserImageType = {
  source?: string;
  width?: number;
  height?: number;
  imageSource?: string;
  itemPositionOverride: {itemType: string};
  isSelected?: boolean;
  // selectItemCallback: (itemIndex: number) => void
};

export type RenderImageTypes = {
  userImage: UserImageType[];
  // addItem: (item: object, index: number) => void;
};

/** Prop type for all elements used in the control area of the Top Editor */
export type TopEditorControlsItemProps = {
  elementId: string;
  newItemIndex: number;
  addItemCallback: (el: JSX.Element) => void;
  selectItemCallback: (itemIndex: number) => void;
  _isSelected: boolean;
};

export interface ImageState {
  x: number;
  y: number;
  scale: number;
  rotation: number;
  source: number | string;
  isSelected: boolean;
  offset: {x: number; y: number};
}
/** BaseItemPositionOverride is used/extended by every item that can be added to the Top Editor */
export interface BaseItemPositionOverride {
  itemType: TopEditorItemType;
  fillColor?: string;
  strokeColor?: string;
  strokeWidth?: number;
  scale?: number;
  rotation?: number;
}
export type ImageRefType = {
  getName: () => 'Image';
  getPosition: () => ImageState;
  renderElementToSave: () => JSX.Element;
  getItemOrderTabItem: () => JSX.Element;
  deselect: () => void;
  changeIndex: (newIndex: number) => void;
  changeScale: (newScale: number) => void;
  changeRotation: (newRotation: number) => void;
  /** Function that gets the TopEditorItemType; returns "Image" for images*/
  getTopEditorItemType: () => TopEditorItemType;
};

export interface NonNullRefObject<T> {
  readonly current: T;
}

export interface TextState {
  textValue: string;
  font: string;
  fontSize: number;
  x: number;
  y: number;
  width: number;
  height: number;
  fillColor: string;
  strokeColor: string;
  strokeWidth: number;
  scale: number;
  rotation: number;
  isSelected: boolean;
  offset: {x: number; y: number};
}

export type TextRefType = {
  getName: () => 'Text';
  getPosition: () => TextState;
  renderElementToSave: () => string;
  getItemOrderTabItem: () => JSX.Element;
  deselect: () => void;
  changeIndex: (newIndex: number) => void;
  changeFont: (newFont: string) => void;
  changeTextValue: (newTextValue: string) => void;
  changeFillColor: (color: string) => void;
  changeStroke: (color: string, strokeWidth?: number) => void;
  changeRotation: (newRotation: number) => void;
  changeScale: (newScale: number) => void;
  /** Function that gets the TopEditorItemType; returns "Text" for text*/
  getTopEditorItemType: () => TopEditorItemType;
};

export type ShapeItemName =
  | 'Badge'
  | 'Logo'
  | 'Circle'
  | 'Square'
  | 'Medal'
  | 'Rectangle'
  | 'Ribbon'
  | 'Triangle'
  | 'Trophy';

interface BaseShapeState {
  isSelected: boolean;
  offset: {x: number; y: number};
  scale: number;
  fillColor: string;
  strokeColor: string;
  strokeWidth: number;
  rotation: number;
}

export interface ShapeState extends BaseShapeState {
  x: number;
  y: number;
  height: number;
  width: number;
}

/** BasicCircle is separated because it is the only shape that uses cx, cy, and radius */
export interface CircleState extends BaseShapeState {
  /** X coordinate of the circle's center */
  cx: number;
  /** Y coordinate of the circle's center */
  cy: number;
  /** The radius of the circle */
  radius: number;
}

export type ShapeRefType = {
  getName: () => ShapeItemName;
  getPosition: () => ShapeState | CircleState;
  renderElementToSave: () => JSX.Element;
  getItemOrderTabItem: () => JSX.Element;
  deselect: () => void;
  changeIndex: (newIndex: number) => void;
  changeScale: (newScale: number) => void;
  changeFillColor: (newColor: string) => void;
  /** Change the stroke color & stroke width */
  changeStroke: (color: string, width?: number) => void;
  changeRotation: (newRotation: number) => void;
  /** Function that gets the TopEditorItemType; returns "Shape" for shapes */
  getTopEditorItemType: () => TopEditorItemType;
  /** Gets initial scale of shape before it is potentially altered by user */
  getInitialScale: () => number;
};

export interface ImageItemPositionOverride extends BaseItemPositionOverride {
  x?: number;
  y?: number;
}
/** Enum that covers the possible TopEditorItems. Each item has a function that returns its type */
export enum TopEditorItemType {
  SHAPE = 'Shape',
  TEXT = 'Text',
  IMAGE = 'Image',
}

export type TopEditorItemProps<T extends BaseItemPositionOverride> = {
  elementId: string;
  selectItemCallback?: (index: number) => void;
  elementIndex: number;
  disableInteraction?: boolean;
  itemPositionOverride: any;
};

export interface UserImageItemProps
  extends TopEditorItemProps<ImageItemPositionOverride> {
  imageSource: string;
  width: number;
  height: number;
  imageIndex?: number;
}

export type ImageInfo = {
  itemElementId: string;
  itemName: string;
  itemIndex: number;
  positionOverride: unknown;
  height: number;
  width: number;
  imageSource: string;
};

export type ShapeInfo = {
  itemElementId: string;
  itemName: string;
  itemIndex: number;
  positionOverride: any;
};

export type TextInfo = {
  itemElementId: string;
  itemName: string;
  itemIndex: number;
  positionOverride: any;
};

export interface UserImageControlsItemProps extends TopEditorControlsItemProps {
  imageSource: string;
  width: number;
  height: number;
  index?: number;
  isSelected?: boolean;
  imageIndex: number;
}

export type TextControlsItemProps = {
  elementId: string;
  newItemIndex: number;
  addItemCallback: (el: JSX.Element) => void;
  selectItemCallback: (itemIndex: number) => void;
  font: string;
};

export interface TextItemPositionOverride extends BaseItemPositionOverride {
  textValue: string;
  font: string;
  fontSize?: number;
  x?: number;
  y?: number;
  height?: number;
  width?: number;
}

export interface ShapeItemPositionOverride extends BaseItemPositionOverride {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}

export interface CircleItemPositionOverride extends BaseItemPositionOverride {
  cx?: number;
  cy?: number;
}

export interface ISizeSlider {
  values: number[];
  onValuesChange?: (values: number[]) => void;
  LeftIcon?:
    | React.ComponentType<any>
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | null
    | undefined;
  RightIcon?:
    | React.ComponentType<any>
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | null
    | undefined;
  min?: number;
  max?: number;
}

export type TopSvgSaveObject = {
  items: JSX.Element[];
  bbox: {x: number; y: number; width: number; height: number};
};

export type EditorItemRef = NonNullRefObject<
  TextRefType | ImageRefType | ShapeRefType
>;

export type FontFamily =
  | webFonts.BEBASNEUE_REGULAR
  | webFonts.ITIM_REGULAR
  | webFonts.JOSEFINESANS_REGULAR
  | webFonts.MONTSERRAT_REGULAR
  | webFonts.PACIFICO_REGULAR
  | webFonts.ROBOTO_REGULAR;

export type saveTopResultType = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export interface IHeaderRightIcon {
  controllerType: ImageController | null;
  onPressResize: () => void;
  onPressRotate: () => void;
  onPressDelete: () => void;
}

export interface ISizeController {
  result: number[];
  onChangeValue: (values: number[]) => void;
  minValue?: number;
  maxValue?: number;
}

export interface IColorCollection {
  onSelectColor: (values: string) => void;
  showTransparentColor?: boolean;
}

export interface IColorPelette {
  onChangeFillColor: (color: string) => void;
  onChangeStrokeColor: (color: string) => void;
  upperRowText: string;
  lowerRowText: string;
}

export interface IFontProps {
  label: string;
  font: string;
  isSelected: boolean;
  onPress: (val: string) => void;
}

export interface EditTopData {
  name: string;
  hashTag: string;
  image: string;
  note: string;
  id: number;
  creationDate: Date;
  tradable: boolean;
  quantity: number;
  noteId?: number;
}
