import {takeLatest, put, call, select, delay} from 'redux-saga/effects';
import {AppConst, FixedValue} from '../../constants';
import {IActionType} from '../../types/constantFilesTypes';
import {
  _approveOffer,
  _cancelOffer,
  _distributeTopDetail,
  _getTradeReceviedOrSentList,
  _rejectOffer,
  _tradeOfferDetail,
  _tradeRequestReport,
  _tradeStatusUpdate,
} from '../../utils/apis';
import {authState} from '../reducers/authSlice';

import {
  onReceivedOfferEnd,
  onReceivedOfferFailed,
  onReceivedOfferSuccess,
  onSentOfferEnd,
  onSentOfferFailed,
  onSentOfferSuccess,
  onApproveOfferEnd,
  onApproveOfferFailed,
  onApproveOfferSuccess,
  onRejectOfferEnd,
  onRejectOfferSuccess,
  onCancelOfferEnd,
  onCancelOfferFailed,
  onCancelOfferSuccess,
  onTradeReportFailed,
  onTradeReportEnd,
  onTradeReportSuccess,
  onTradeOfferSuccess,
  onTradeOfferFailed,
  onTradeOfferEnd,
  onDistributeTopDetailSuccess,
  onDistributeTopDetailFailed,
  onDistributeTopDetailEnd,
  onTopTradeStatusUpdateSuccess,
  onTopTradeStatusUpdateFailed,
  onTopTradeStatusUpdateEnd,
} from '../reducers/tradeTabSlice';
import {
  ITradeOfferDetailResponse,
  TradeTabReceviedOrSentArray,
} from '../../types/reducerTypes/tradeTabTypes';
import {isWebsite} from '../../utils/responsive';
import {showAlert, _ToastHandler} from '../../utils/helperFunctions';
import {TOAST_TEXT} from '../../constants/textMessage';
import {APPROVED_OR_REJECT, ACCEPTED_OR_REJECTED} from '../../constants/enum';

const {StoreConstants} = AppConst;

function* receivedOfferList(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: TradeTabReceviedOrSentArray = yield call(
      _getTradeReceviedOrSentList,
      action.payload,
      userTokens
    );
    yield put(onReceivedOfferSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onReceivedOfferFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onReceivedOfferEnd());
  }
}

function* sentOfferList(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: TradeTabReceviedOrSentArray = yield call(
      _getTradeReceviedOrSentList,
      action.payload,
      userTokens
    );
    yield put(onSentOfferSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onSentOfferFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onSentOfferEnd());
  }
}
function* cancelOfferAPI(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: object = yield call(_cancelOffer, action.payload, userTokens);
    yield put(onCancelOfferSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onCancelOfferFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onCancelOfferEnd());
  }
}
function* approveOfferAPI(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: object = yield call(_approveOffer, action.payload, userTokens);
    _ToastHandler(TOAST_TEXT.OFFER_SUCCESSFULLY_ACCEPTED, true);
    yield put(onApproveOfferSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onApproveOfferFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onApproveOfferEnd());
  }
}
function* rejectOfferAPI(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: object = yield call(_rejectOffer, action.payload, userTokens);
    _ToastHandler(TOAST_TEXT.OFFER_SUCCESSFULLY_REJECTED, true);
    yield put(onRejectOfferSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onReceivedOfferFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onRejectOfferEnd());
  }
}
//on trade request report work
function* tradeRequestReportAPI(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: object = yield call(
      _tradeRequestReport,
      action.payload,
      userTokens
    );
    yield put(onTradeReportSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onTradeReportFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onTradeReportEnd());
  }
}

//on get trade offer detail
function* getTradeOfferDetailAPI(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ITradeOfferDetailResponse = yield call(
      _tradeOfferDetail,
      action.payload,
      userTokens
    );
    yield put(onTradeOfferSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onTradeOfferFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onTradeOfferEnd());
  }
}

//on get distribute top detail
function* getDistributeTopDetailAPI(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ITradeOfferDetailResponse = yield call(
      _distributeTopDetail,
      action.payload,
      userTokens
    );
    yield put(onDistributeTopDetailSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onDistributeTopDetailFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onDistributeTopDetailEnd());
  }
}

//on trade status update
function* tradeStatusUpdateFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: object = yield call(
      _tradeStatusUpdate,
      action.payload,
      userTokens
    );
    if (action.payload?.status !== ACCEPTED_OR_REJECTED.REPORT) {
      const toastMsg: string =
        action.payload?.status === APPROVED_OR_REJECT.APPROVED
          ? TOAST_TEXT.OFFER_SUCCESSFULLY_ACCEPTED
          : TOAST_TEXT.OFFER_SUCCESSFULLY_REJECTED;
      _ToastHandler(toastMsg, true);
    } else {
      showAlert();
    }
    yield put(onTopTradeStatusUpdateSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onTopTradeStatusUpdateFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onTopTradeStatusUpdateEnd());
  }
}

export default function* tradeTabListSaga() {
  yield takeLatest(
    `${StoreConstants.TRADE_RECEVIED_SEND_OFFER}/onReceivedOfferStart`,
    receivedOfferList
  );
  yield takeLatest(
    `${StoreConstants.TRADE_RECEVIED_SEND_OFFER}/onSentOfferStart`,
    sentOfferList
  );
  yield takeLatest(
    `${StoreConstants.TRADE_RECEVIED_SEND_OFFER}/onApproveOfferStart`,
    approveOfferAPI
  );
  yield takeLatest(
    `${StoreConstants.TRADE_RECEVIED_SEND_OFFER}/onCancelOfferStart`,
    cancelOfferAPI
  );
  yield takeLatest(
    `${StoreConstants.TRADE_RECEVIED_SEND_OFFER}/onRejectOfferStart`,
    rejectOfferAPI
  );
  yield takeLatest(
    `${StoreConstants.TRADE_RECEVIED_SEND_OFFER}/onTradeReportStart`,
    tradeRequestReportAPI
  );
  yield takeLatest(
    `${StoreConstants.TRADE_RECEVIED_SEND_OFFER}/onTradeOfferStart`,
    getTradeOfferDetailAPI
  );
  yield takeLatest(
    `${StoreConstants.TRADE_RECEVIED_SEND_OFFER}/onDistributeTopDetailStart`,
    getDistributeTopDetailAPI
  );
  yield takeLatest(
    `${StoreConstants.TRADE_RECEVIED_SEND_OFFER}/onTopTradeStatusUpdateStart`,
    tradeStatusUpdateFunction
  );
}
