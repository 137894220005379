import React from 'react';
import {useAppDispatch} from '../reduxHooks';
import {onApproveOrRejectStart, onChildProfileApproveStart} from '../../redux/reducers/childApproveSlice';
import {FixedValue} from '../../constants';

const useChildApprovelRequestListHooks = () => {
  const dispatch = useAppDispatch();
  const callChildApproveListAPI = React.useCallback((page: number): void => {
    const params = {page, size: FixedValue.CONSTANT_VALUE_10};
    dispatch(onChildProfileApproveStart(params));
  }, []);

  const callApproveOrRejectAPI = React.useCallback((requestId: number,status:string): void => {
    const params = {requestId,status};
     dispatch(onApproveOrRejectStart(params));
  }, []);

  return {
    callChildApproveListAPI,
    callApproveOrRejectAPI
  };
};

export default useChildApprovelRequestListHooks;
