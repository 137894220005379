import {StyleSheet} from 'react-native';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';

export const tradeCompStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainView: {
      ...BASESTYLE.row,
      ...BASESTYLE.spaceBetween,
      width: Percentage.PRECENTAGE_100,
    },
    headingView: {
      ...BASESTYLE.inRow,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_24),
      backgroundColor: _theme.backgroundColor,
    },
    titleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_30),
      fontFamily: FONT_NAMES.SF_BOLD,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_36),
      color: _theme.color,
    },
    tabBar: {
      ...BASESTYLE.row,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      backgroundColor: COLORS.E9EEFB,
      borderBottomWidth: scaleWidth(FixedValue.CONSTANT_VALUE_1),
      borderBottomColor: COLORS.TRANSPARENT,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_25),
    },
    cardStyle: {
      height: scaleWidth(FixedValue.CONSTANT_VALUE_69),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_69),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_9),
      backgroundColor: COLORS.E9EEFB,
      overflow: GlobalStyleValues.HIDDEN,
    },
    textView: {
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_5),
    },
    buttonCommonStyle: {
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_27),
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      paddingVertical: scaleHeight(FixedValue.CONSTANT_VALUE_4),
    },
    owner: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: _theme.color,
    },
    title: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_LIGHT,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_5),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_6),
      color: _theme.color,
    },
    day: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
      fontFamily: FONT_NAMES.SF_LIGHT,
      color: _theme.color,
    },
    buttonView: {
      backgroundColor: _theme.backgroundColor,
      ...BASESTYLE.spaceBetween,
      ...BASESTYLE.row,
      ...BASESTYLE.alignCenter,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    statusView: {
      backgroundColor: _theme.backgroundColor,
      ...BASESTYLE.spaceBetween,
      ...BASESTYLE.row,
      ...BASESTYLE.alignCenter,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      justifyContent: GlobalStyleValues.FLEX_END,
    },
    threedot: {
      ...BASESTYLE.center,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_15),
      backgroundColor: _theme.backgroundColor,
      alignItems: GlobalStyleValues.FLEX_END,
    },
    panding: {
      ...BASESTYLE.row,
      ...BASESTYLE.inCenter,
      backgroundColor: _theme.backgroundColor,
      marginRight:scaleWidth(FixedValue.CONSTANT_VALUE_10)
    },
    imageLoader: {
      ...BASESTYLE.center,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_69),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_69),
      backgroundColor: COLORS.E9EEFB,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_9),
    },
    noOffer: {
      ...BASESTYLE.textAlignCenter,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: _theme.color,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_35),
    },
    noOfferView: {
      ...BASESTYLE.textAlignCenter,
      ...BASESTYLE.flex1,
      ...BASESTYLE.alignCenter,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_90),
    },

    tapoffer: {
      fontFamily: FONT_NAMES.SF_REGULAR,
      textAlign: GlobalStyleValues.CENTER,
      color: COLORS._4A4A4A,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_24),
    },
    threeDotView: {
      ...BASESTYLE.inCenter,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_24),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_24),
    },
    justifyEnd: {
      justifyContent: GlobalStyleValues.FLEX_END,
    },
  });
