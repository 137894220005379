import {createAction, createSlice} from '@reduxjs/toolkit';
import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {LogOutStateType} from '../../types/reducerTypes/authStoreTypes';

const {StoreConstants} = AppConst;
const {RESET_STORE, LOGOUT, DELETE_ACCOUNT} = ActionType;

const initialState: LogOutStateType = {
  logout: {
    isLoading: false,
    success: null,
    failed: null,
    status: LOGOUT.END,
  },
  deleteAccount: {
    isLoading: false,
    status: DELETE_ACCOUNT.END,
  },
};

/**
 * call this action while logout or unauthorized User state.
 * Revert all state to initial level.
 */
export const ResetAllState = createAction(RESET_STORE);

const LogoutSlice = createSlice({
  initialState,
  name: StoreConstants.LOGOUT,
  reducers: {
    onLogoutStart: state => {
      state.logout.isLoading = true;
      state.logout.status = LOGOUT.START;
    },
    onLogoutSuccess: (state, action) => {
      state.logout.success = action.payload;
      state.logout.status = LOGOUT.SUCCESS;
    },
    onLogoutFailed: (state, action) => {
      state.logout.failed = action.payload;
      state.logout.status = LOGOUT.FAILED;
    },
    onLogoutEnd: state => {
      state.logout.isLoading = false;
      state.logout.status = LOGOUT.END;
    },
    onDeleteStart: state => {
      state.deleteAccount.isLoading = true;
      state.deleteAccount.status = DELETE_ACCOUNT.START;
    },
    onDeleteEnd: state => {
      state.deleteAccount.isLoading = false;
      state.deleteAccount.status = DELETE_ACCOUNT.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  onLogoutStart,
  onLogoutSuccess,
  onLogoutFailed,
  onLogoutEnd,
  onDeleteStart,
  onDeleteEnd,
} = LogoutSlice.actions;
export default LogoutSlice.reducer;
export const logoutdState = (state: RootState) => state.logout;
