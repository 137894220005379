/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {useAppDispatch, useAppSelector} from '../reduxHooks';
import {ChildProfileContext} from '../../contextAPI/childProfileContext';
import {
  onTradeSettingStart,
  onTradeNotApprovedUserListStart,
  childProfileStore,
  onTradeUserAddStart,
  onTradeApprovedListStart,
  onGroupInvitationChildStart,
  onUserRemoveFromTradeApprovedListStart,
  onTradeApprovalReceivedListStart,
  onGroupChildInvitaionApproveStart,
  onGroupChildInvitaionRejectStart,
  onChildGroupSettingStart,
  onchildTradableRequestListStart,
  onTradeApprovalSentListStart,
  onSentTradeApproveOrRejectStart,
  onChildTradableSettingStart,
  onChildTradableApproveRejectStart,
  onDistributeTopSettingStart,
  onDistributeUserAddStart,
  onDistributeApprovedListStart,
  onUserRemoveFromDistributeApprovedListStart,
  onDistributeRequestListStart,
  onSentDistributeApproveOrRejectStart,
  onDistributeReceivedRequestListStart,
  onReceivedDistributeApproveOrRejectOrReportStart,
} from '../../redux/reducers/childProfileSlice';
import {FixedValue, ROUTE_NAME} from '../../constants';
import {
  SENT_OR_RECEIVED,
  USER_LIST_TYPE,
  APPROVED_OR_REJECT,
  TopType,
} from '../../constants/enum';
import {
  onApproveOfferStart,
  onRejectOfferStart,
} from '../../redux/reducers/tradeTabSlice';
import {DistributeRequestModel} from '../../types/componentTypes/childProfileType';
import {TopModel} from '../../types/reducerTypes/searchTabStoreType';
import {navigate} from '../../services/navigationService';
import {ReportFormState} from '../../types/reducerTypes/tradeTabTypes';
import {NotificationContext} from '../../contextAPI/notificationContext';

const useTradingApprovalHooks = () => {
  const dispatch = useAppDispatch();
  const {
    tradeNotApprovedUserList,
    onTradeApprovedListData,
    onDistributeApprovedListData,
    onTradeApprovalReceivedListData,
    onGroupInvitationChild,
    childTradableRequestListData,
    onTradeApprovalSentListData,
    onDistributeRequestListData,
    onDistributeReceivedRequestData,
  } = useAppSelector(childProfileStore);
  const {
    childUserData,
    addNewChildUserSearchText,
    selectedUserInTrade,
    addNewChildUserModal,
    childProfileContextPullDownToRefresh,
    userRemoveId,
    selectedTradeReceivedData,
    groupInvitation,
    childTradable,
    isApproveChildTradable,
    showDistributeUserListModal,
    searchTypeForTradeAndDistribute,
    setAddNewChildUserSearchText,
    setAddNewChildUserModal,
    updateSelectedUserInTrade,
    setChildProfileContextPullDownToRefresh,
    setUserRemoveId,
    setShowDistributeUserListModal,
    setShowDistributeApproveUserRemoveAlert,
    setShowTradeApproveUserRemoveAlert,
  } = React.useContext(ChildProfileContext);
  const {selectedNotificationCard, selectedNotificationCardIndex} =
    React.useContext(NotificationContext);

  //call trade recevied and sent api
  const getTradeReceviedOrSentList = React.useCallback(
    (offerType: string, page: number): void => {
      const params = {
        offerType,
        page,
        minorId: childUserData?.id,
        size: FixedValue.CONSTANT_VALUE_15,
      };
      if (offerType === SENT_OR_RECEIVED.RECEIVED) {
        dispatch(onTradeApprovalReceivedListStart(params));
      } else {
        dispatch(onTradeApprovalSentListStart(params));
      }
    },
    [childUserData]
  );

  //call child trade approved received Trade API
  const callChildReceivedApproveTradeAPI = React.useCallback(
    (offerID: number): void => {
      dispatch(onApproveOfferStart(offerID));
    },
    [selectedTradeReceivedData]
  );

  //call child trade Reject received Trade API
  const callChildSentApproveOfferAPI = React.useCallback(
    (offerID: number): void => {
      dispatch(onRejectOfferStart(offerID));
    },
    [selectedTradeReceivedData]
  );

  //call trade approve setting api
  const callTradingApproveSettingAPI = React.useCallback(
    (status: string): void => {
      const params = {
        status,
        childId: childUserData?.id,
      };
      dispatch(onTradeSettingStart(params));
    },
    [childUserData]
  );

  //call change distribute top setting api
  const callDistributeTopSettingAPI = React.useCallback(
    (status: string): void => {
      const params = {
        status,
        childId: childUserData?.id,
      };
      dispatch(onDistributeTopSettingStart(params));
    },
    [childUserData]
  );

  //on trade approve list api call
  const callTradingApproveListAPI = React.useCallback(
    (page: number): void => {
      const params = {
        page,
        childId: childUserData?.id,
        size: FixedValue.CONSTANT_VALUE_15,
      };
      dispatch(onTradeApprovedListStart(params));
    },
    [childUserData]
  );

  //on distribute approve list api call
  const callDistributeApproveListAPI = React.useCallback(
    (page: number): void => {
      const params = {
        page,
        childId: childUserData?.id,
        size: FixedValue.CONSTANT_VALUE_15,
      };
      dispatch(onDistributeApprovedListStart(params));
    },
    [childUserData]
  );

  //on distribute request list api call
  const callDistributeRequestListAPI = React.useCallback(
    (page: number): void => {
      const params = {
        page,
        minorId: childUserData?.id,
        size: FixedValue.CONSTANT_VALUE_15,
        offerType: SENT_OR_RECEIVED.SENT,
      };
      dispatch(onDistributeRequestListStart(params));
    },
    [childUserData]
  );

  //call not approved user list on trade
  const callTradeNotApprovedUserListAPI = React.useCallback(
    (keyword: string, page: number, searchType: string): void => {
      const params = {
        keyword,
        page,
        searchType,
        minorId: childUserData?.id,
        size: FixedValue.CONSTANT_VALUE_15,
      };
      dispatch(onTradeNotApprovedUserListStart(params));
    },
    [childUserData]
  );

  //call API for add user in trade approve list
  const callAddUserInTradeApprovedAPI = React.useCallback(() => {
    const userIds = selectedUserInTrade.map(item => item.id);
    const params = {
      minorId: childUserData?.id,
      usersId: userIds,
    };
    dispatch(onTradeUserAddStart(params));
  }, [selectedUserInTrade, childUserData]);

  //call API for add user in distribute approve list
  const callAddUserInDistributeApprovedAPI = React.useCallback(() => {
    const userIds = selectedUserInTrade.map(item => item.id);
    const params = {
      minorId: childUserData?.id,
      usersId: userIds,
    };
    dispatch(onDistributeUserAddStart(params));
  }, [selectedUserInTrade, childUserData]);

  //function call when on add user for trade modal user list flatlist onEndReach
  const onTradeUserListEndReach = React.useCallback((): void => {
    if (
      tradeNotApprovedUserList.success != null &&
      tradeNotApprovedUserList.success.pageNumber <
        tradeNotApprovedUserList.success.totalPages
    ) {
      callTradeNotApprovedUserListAPI(
        addNewChildUserSearchText,
        tradeNotApprovedUserList.success.pageNumber +
          FixedValue.CONSTANT_VALUE_1,
        searchTypeForTradeAndDistribute
      );
    }
  }, [
    tradeNotApprovedUserList,
    addNewChildUserSearchText,
    searchTypeForTradeAndDistribute,
  ]);

  //function call when on approve user list for trade flatlist onEndReach
  const onTradeApproveUserListEndReach = React.useCallback((): void => {
    if (
      onTradeApprovedListData.success != null &&
      onTradeApprovedListData.success.pageNumber <
        onTradeApprovedListData.success.totalPages
    ) {
      callTradingApproveListAPI(
        onTradeApprovedListData.success.pageNumber + FixedValue.CONSTANT_VALUE_1
      );
    }
  }, [onTradeApprovedListData]);

  //function call when on approve user list for distribute flatlist onEndReach
  const onDistributeApproveUserListEndReach = React.useCallback((): void => {
    if (
      onDistributeApprovedListData.success != null &&
      onDistributeApprovedListData.success.pageNumber <
        onDistributeApprovedListData.success.totalPages
    ) {
      callDistributeApproveListAPI(
        onDistributeApprovedListData.success.pageNumber +
          FixedValue.CONSTANT_VALUE_1
      );
    }
  }, [onDistributeApprovedListData]);

  //user remove from trade approve list function through API
  const userRemoveFromTradeApprovedList = React.useCallback(() => {
    const params = {
      childId: childUserData?.id,
      userId: userRemoveId,
    };
    dispatch(onUserRemoveFromTradeApprovedListStart(params));
  }, [childUserData, userRemoveId]);

  //user remove from distribute approve list function through API
  const userRemoveFromDistributeApprovedList = React.useCallback(() => {
    const params = {
      childId: childUserData?.id,
      userId: userRemoveId,
    };
    dispatch(onUserRemoveFromDistributeApprovedListStart(params));
  }, [childUserData, userRemoveId]);

  //function call when user search on user modal
  const _onChildUserModalSearchText = React.useCallback(
    (value: string): void => {
      setAddNewChildUserSearchText(value);
      if (value.length > FixedValue.CONSTANT_VALUE_2) {
        callTradeNotApprovedUserListAPI(
          value,
          FixedValue.CONSTANT_VALUE_0,
          searchTypeForTradeAndDistribute
        );
      }
      if (value.length === FixedValue.CONSTANT_VALUE_0) {
        callTradeNotApprovedUserListAPI(
          value,
          FixedValue.CONSTANT_VALUE_0,
          searchTypeForTradeAndDistribute
        );
      }
    },
    [addNewChildUserSearchText, searchTypeForTradeAndDistribute]
  );

  //user add on trade approve and distribute list modal open
  const openAddUserInTradeModal = React.useCallback(
    (searchType: string): void => {
      updateSelectedUserInTrade([]);
      setAddNewChildUserSearchText('');
      callTradeNotApprovedUserListAPI(
        '',
        FixedValue.CONSTANT_VALUE_0,
        searchType
      );
      if (USER_LIST_TYPE.OFFER === searchType) {
        setAddNewChildUserModal();
      } else {
        setShowDistributeUserListModal();
      }
    },
    [
      addNewChildUserModal,
      selectedUserInTrade,
      showDistributeUserListModal,
      addNewChildUserModal,
    ]
  );

  //pull down to refesh on child trade approve list
  const callGroupInvitationChildListAPI = React.useCallback(
    (page: number): void => {
      const params = {
        page,
        userId: childUserData?.id,
        size: FixedValue.CONSTANT_VALUE_15,
      };
      dispatch(onGroupInvitationChildStart(params));
    },
    [dispatch, childUserData]
  );

  const onGroupInvitationChildListEndReach = React.useCallback((): void => {
    if (
      onGroupInvitationChild.success != null &&
      onGroupInvitationChild.success.pageNumber <
        onGroupInvitationChild.success.totalPages
    ) {
      callGroupInvitationChildListAPI(
        onGroupInvitationChild.success.pageNumber + FixedValue.CONSTANT_VALUE_1
      );
    }
  }, [onGroupInvitationChild]);

  //pull down to refesh on trade approve list
  const pullDownToRefreshOnApproveList = React.useCallback(() => {
    setChildProfileContextPullDownToRefresh(true);
    callTradingApproveListAPI(FixedValue.CONSTANT_VALUE_0);
  }, [childProfileContextPullDownToRefresh]);

  //pull down to refesh on trade approve list
  const pullDownToRefreshOnDistributeApproveList = React.useCallback(() => {
    setChildProfileContextPullDownToRefresh(true);
    callDistributeApproveListAPI(FixedValue.CONSTANT_VALUE_0);
  }, [childProfileContextPullDownToRefresh]);

  //pull down to refesh on child trade received list
  const pullDownToRefreshOnReceivedList = React.useCallback(() => {
    setChildProfileContextPullDownToRefresh(true);
    getTradeReceviedOrSentList(
      SENT_OR_RECEIVED.RECEIVED,
      FixedValue.CONSTANT_VALUE_0
    );
  }, [childProfileContextPullDownToRefresh]);

  //pull down to refesh on child trade sent list
  const pullDownToRefreshOnTradeSentList = React.useCallback(() => {
    setChildProfileContextPullDownToRefresh(true);
    getTradeReceviedOrSentList(
      SENT_OR_RECEIVED.SENT,
      FixedValue.CONSTANT_VALUE_0
    );
  }, [childProfileContextPullDownToRefresh]);

  //function call on child trade received list flatlist onEndReach function call
  const OnChildTradeReceivedListEndReach = React.useCallback((): void => {
    if (
      onTradeApprovalReceivedListData.success != null &&
      onTradeApprovalReceivedListData.success.pageNumber <
        onTradeApprovalReceivedListData.success.totalPages
    ) {
      getTradeReceviedOrSentList(
        SENT_OR_RECEIVED.RECEIVED,
        onTradeApprovalReceivedListData.success.pageNumber +
          FixedValue.CONSTANT_VALUE_1
      );
    }
  }, [onTradeApprovalReceivedListData]);

  //function call on child trade sent list flatlist onEndReach function call
  const OnChildTradeSentListEndReach = React.useCallback((): void => {
    if (
      onTradeApprovalSentListData.success != null &&
      onTradeApprovalSentListData.success.pageNumber <
        onTradeApprovalSentListData.success.totalPages
    ) {
      getTradeReceviedOrSentList(
        SENT_OR_RECEIVED.SENT,
        onTradeApprovalSentListData.success.pageNumber +
          FixedValue.CONSTANT_VALUE_1
      );
    }
  }, [onTradeApprovalSentListData]);

  //pull down to refesh on child distribute sent list
  const pullDownToRefreshOnDistributeSentList = React.useCallback(() => {
    setChildProfileContextPullDownToRefresh(true);
    callDistributeRequestListAPI(FixedValue.CONSTANT_VALUE_0);
  }, [childProfileContextPullDownToRefresh]);

  //function call on child distribute sent request list flatlist onEndReach function call
  const OnChildDistributeSentListEndReach = React.useCallback((): void => {
    if (
      onDistributeRequestListData.success != null &&
      onDistributeRequestListData.success.pageNumber <
        onDistributeRequestListData.success.totalPages
    ) {
      callDistributeRequestListAPI(
        onDistributeRequestListData.success.pageNumber +
          FixedValue.CONSTANT_VALUE_1
      );
    }
  }, [onDistributeRequestListData]);

  const groupChildInvitaionAcceptApiCall = React.useCallback((): void => {
    const params = {
      groupId: groupInvitation?.group?.id,
      userId: childUserData?.id,
    };
    dispatch(onGroupChildInvitaionApproveStart(params));
  }, [childUserData, groupInvitation, dispatch]);

  const groupInvitationRejectApiCall = React.useCallback((): void => {
    const params = {
      userId: childUserData?.id,
      groupId: groupInvitation?.group?.id,
    };
    dispatch(onGroupChildInvitaionRejectStart(params));
  }, [childUserData, groupInvitation, dispatch]);

  const pullDownToRefreshOnGroupInvitationList = React.useCallback(() => {
    setChildProfileContextPullDownToRefresh(true);
    callGroupInvitationChildListAPI(FixedValue.CONSTANT_VALUE_0);
  }, [childProfileContextPullDownToRefresh]);

  const callChildGroupApproveSettingAPI = React.useCallback(
    (permission: string): void => {
      const params = {
        permission,
        childId: childUserData?.id,
      };
      dispatch(onChildGroupSettingStart(params));
    },
    [childUserData]
  );

  const callChildTradableSettingAPI = React.useCallback(
    (status: string): void => {
      const params = {
        status,
        childId: childUserData?.id,
      };
      dispatch(onChildTradableSettingStart(params));
    },
    [childUserData]
  );

  //Child sent trade approve or reject API
  const childSentTradeApproveOrRejectApi = React.useCallback(
    (status: string): void => {
      const params = {
        status,
        offerId: selectedTradeReceivedData?.id,
      };
      dispatch(onSentTradeApproveOrRejectStart(params));
    },
    [selectedTradeReceivedData]
  );

  //Child sent distribute request approve or reject API
  const childDistributeRequestApproveOrRejectApi = React.useCallback(
    (status: string): void => {
      const params = {
        status,
        requestId: (selectedTradeReceivedData as DistributeRequestModel)
          ?.requestId,
      };
      dispatch(onSentDistributeApproveOrRejectStart(params));
    },
    [selectedTradeReceivedData]
  );

  const getChildTradableRequestList = React.useCallback(
    (page: number): void => {
      const params = {
        page,
        minorId: childUserData?.id,
        size: FixedValue.CONSTANT_VALUE_15,
      };
      dispatch(onchildTradableRequestListStart(params));
    },
    [childUserData]
  );

  const pullDownToRefreshTradableRequestList = React.useCallback(() => {
    setChildProfileContextPullDownToRefresh(true);
    getChildTradableRequestList(FixedValue.CONSTANT_VALUE_0);
  }, [childProfileContextPullDownToRefresh]);

  const OnChildTradableRequestListEndReach = React.useCallback((): void => {
    if (
      childTradableRequestListData.success != null &&
      childTradableRequestListData.success.pageNumber <
        childTradableRequestListData.success.totalPages
    ) {
      getChildTradableRequestList(
        childTradableRequestListData.success.pageNumber +
          FixedValue.CONSTANT_VALUE_1
      );
    }
  }, [childTradableRequestListData]);

  const childTradableApproveRejecApiCall = React.useCallback((): void => {
    const params = {
      requestId: childTradable?.requestId,
      status: isApproveChildTradable
        ? APPROVED_OR_REJECT.APPROVED
        : APPROVED_OR_REJECT.REJECT,
    };
    dispatch(onChildTradableApproveRejectStart(params));
  }, [isApproveChildTradable, childTradable]);

  //user remove from distribute approve list function through API
  const distributeApproveUserRemoveAlertFunction = React.useCallback(
    (userId: number) => {
      setUserRemoveId(userId);
      setShowDistributeApproveUserRemoveAlert(true);
    },
    [userRemoveId]
  );

  //user remove from trade approve list function through API
  const tradeApproveUserRemoveAlertFunction = React.useCallback(
    (userId: number) => {
      setUserRemoveId(userId);
      setShowTradeApproveUserRemoveAlert(true);
    },
    [userRemoveId]
  );

  const navigateToTopDetailScreen = React.useCallback(
    (topDetails: TopModel): void => {
      navigate(ROUTE_NAME.TOP_DETAIL_SCREEN, {
        topDetails,
        comingFrom: TopType.seeOnlyDetails,
      });
    },
    []
  );

  //on distribute received request list api call
  const callDistributeReceivedRequestListAPI = React.useCallback(
    (page: number): void => {
      const params = {
        page,
        minorId: childUserData?.id,
        size: FixedValue.CONSTANT_VALUE_15,
        offerType: SENT_OR_RECEIVED.RECEIVED,
      };
      dispatch(onDistributeReceivedRequestListStart(params));
    },
    [childUserData]
  );

  //function call when child distribute reveived request list flatlist onEndReach function call
  const OnChildDistributeReceivedListEndReach = React.useCallback((): void => {
    if (
      onDistributeReceivedRequestData?.success != null &&
      onDistributeReceivedRequestData?.success?.pageNumber <
        onDistributeReceivedRequestData?.success?.totalPages
    ) {
      callDistributeReceivedRequestListAPI(
        onDistributeReceivedRequestData?.success?.pageNumber +
          FixedValue.CONSTANT_VALUE_1
      );
    }
  }, [onDistributeReceivedRequestData]);

  //pull down to refesh on child distribute reveived list
  const pullDownToRefreshOnDistributeReceivedList = React.useCallback(() => {
    setChildProfileContextPullDownToRefresh(true);
    callDistributeReceivedRequestListAPI(FixedValue.CONSTANT_VALUE_0);
  }, [childProfileContextPullDownToRefresh]);

  //Child received distribute request approve or reject or report API
  const childDistributeRequestApproveOrRejectOrReportApi = React.useCallback(
    (argumentData: ReportFormState): void => {
      dispatch(onReceivedDistributeApproveOrRejectOrReportStart(argumentData));
    },
    [selectedNotificationCard, childUserData, selectedNotificationCardIndex]
  );

  return {
    callTradingApproveSettingAPI,
    callTradeNotApprovedUserListAPI,
    _onChildUserModalSearchText,
    onTradeUserListEndReach,
    callAddUserInTradeApprovedAPI,
    openAddUserInTradeModal,
    callTradingApproveListAPI,
    onTradeApproveUserListEndReach,
    callGroupInvitationChildListAPI,
    onGroupInvitationChildListEndReach,
    pullDownToRefreshOnApproveList,
    userRemoveFromTradeApprovedList,
    getTradeReceviedOrSentList,
    OnChildTradeReceivedListEndReach,
    pullDownToRefreshOnReceivedList,
    groupChildInvitaionAcceptApiCall,
    groupInvitationRejectApiCall,
    pullDownToRefreshOnGroupInvitationList,
    callChildGroupApproveSettingAPI,
    callChildTradableSettingAPI,
    getChildTradableRequestList,
    pullDownToRefreshTradableRequestList,
    OnChildTradableRequestListEndReach,
    callChildReceivedApproveTradeAPI,
    callChildSentApproveOfferAPI,
    OnChildTradeSentListEndReach,
    pullDownToRefreshOnTradeSentList,
    childSentTradeApproveOrRejectApi,
    childTradableApproveRejecApiCall,
    callDistributeTopSettingAPI,
    callAddUserInDistributeApprovedAPI,
    callDistributeApproveListAPI,
    pullDownToRefreshOnDistributeApproveList,
    onDistributeApproveUserListEndReach,
    userRemoveFromDistributeApprovedList,
    distributeApproveUserRemoveAlertFunction,
    callDistributeRequestListAPI,
    childDistributeRequestApproveOrRejectApi,
    OnChildDistributeSentListEndReach,
    pullDownToRefreshOnDistributeSentList,
    tradeApproveUserRemoveAlertFunction,
    navigateToTopDetailScreen,
    callDistributeReceivedRequestListAPI,
    childDistributeRequestApproveOrRejectOrReportApi,
    pullDownToRefreshOnDistributeReceivedList,
    OnChildDistributeReceivedListEndReach,
  };
};

export default useTradingApprovalHooks;
