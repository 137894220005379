import StyleSheet from 'react-native-media-query';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {BASESTYLE, FONT_NAMES, FixedValue, Percentage} from '../../constants';
import {normalizeFont, scaleHeight} from '../../utils/responsive';

export const SentOfferStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.selfCenter,
      width: Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
    },
    comingSoonText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_30),
      fontFamily: FONT_NAMES.SF_BOLD,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_36),
      color: _theme.color,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_48),
    },
    tabBarStyle: {
      width: Percentage.PRECENTAGE_90,
    },
  });
