import {Badge} from 'native-base';
import React from 'react';
import {COLORS} from '../../constants';
import {nativeBaseEnums} from '../../constants/enum';

import {ThemeContext} from '../../contextAPI/themeContext';
import {IbadgeCount} from '../../types/componentTypes/smallDesignsType';
import {styles} from './smallDesign.style';

const BadgeCount = (props: IbadgeCount): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  return (
    <Badge
      rounded={nativeBaseEnums.FULL}
      backgroundColor={COLORS.F54336}
      _text={style.badgeText}
      style={props.badgeStyle}
    >
      {props.count}
    </Badge>
  );
};

export default React.memo(BadgeCount);
