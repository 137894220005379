import React from 'react';
import {processFontFamily} from 'expo-font';
import {GestureResponderEvent} from 'react-native';
import {G, Rect, Text as SVGText} from 'react-native-svg';

import {COLORS, FixedValue, GlobalStyleValues} from '../../../constants';
import {EditorContext} from '../../../contextAPI/editorContext';
import {
  FontFamily,
  TextItemPositionOverride,
  TextRefType,
  TextState,
  TopEditorItemProps,
  TopEditorItemType,
} from '../../../types/componentTypes/editorType';
import {
  getFontFamilyForSave,
  getHeightConstant,
} from '../../../utils/helperFunctions';
import {isWebsite} from '../../../utils/responsive';

const BasicText = React.forwardRef(
  (
    {
      elementId,
      selectItemCallback,
      elementIndex,
      disableInteraction,
      itemPositionOverride,
    }: TopEditorItemProps<TextItemPositionOverride>,
    ref: React.ForwardedRef<TextRefType>
  ): JSX.Element => {
    const {editorControlItemDimensions, editorSvgDimensions} =
      React.useContext(EditorContext);

    const _heightMultiplier: number = getHeightConstant(
      itemPositionOverride?.font as FontFamily
    );
    const _originalTextValue: string = itemPositionOverride?.textValue
      ? itemPositionOverride?.textValue
      : '';
    const _originalFontSize: number = itemPositionOverride?.fontSize
      ? itemPositionOverride?.fontSize
      : FixedValue.CONSTANT_VALUE_30;

    const itemPositionOverrideTextValue: number =
      itemPositionOverride?.textValue
        ? itemPositionOverride?.textValue.length * FixedValue.CONSTANT_VALUE_18
        : _originalTextValue.length * FixedValue.CONSTANT_VALUE_16;

    const itemPositionOverrideFontSize: number = itemPositionOverride?.fontSize
      ? Math.floor(itemPositionOverride?.fontSize * _heightMultiplier)
      : Math.floor(_originalFontSize * _heightMultiplier);

    const textScale: number = FixedValue.CONSTANT_VALUE_1;

    const [isDragging, setIsDragging] = React.useState<boolean>(false);
    const [position, setPosition] = React.useState<TextState>({
      textValue: itemPositionOverride?.textValue
        ? itemPositionOverride?.textValue
        : '',
      font: itemPositionOverride ? itemPositionOverride.font : '',
      fontSize: itemPositionOverride?.fontSize
        ? itemPositionOverride?.fontSize
        : FixedValue.CONSTANT_VALUE_30,
      x: itemPositionOverride?.x
        ? itemPositionOverride.x
        : editorSvgDimensions.width / FixedValue.CONSTANT_VALUE_2,
      y: itemPositionOverride?.y
        ? itemPositionOverride.y
        : editorSvgDimensions.height / FixedValue.CONSTANT_VALUE_2,
      width: itemPositionOverride?.width
        ? itemPositionOverride?.width
        : itemPositionOverrideTextValue,
      height: itemPositionOverride?.height
        ? itemPositionOverride?.height
        : itemPositionOverrideFontSize,
      fillColor: itemPositionOverride?.fillColor
        ? itemPositionOverride?.fillColor
        : COLORS.BLACK,
      strokeColor: itemPositionOverride?.strokeColor
        ? itemPositionOverride?.strokeColor
        : GlobalStyleValues.NONE,
      strokeWidth: itemPositionOverride?.strokeWidth
        ? itemPositionOverride?.strokeWidth
        : FixedValue.CONSTANT_VALUE_1,
      scale: itemPositionOverride?.scale
        ? itemPositionOverride.scale
        : textScale,
      rotation: itemPositionOverride?.rotation
        ? itemPositionOverride.rotation
        : FixedValue.CONSTANT_VALUE_0,
      isSelected: false,
      offset: {x: FixedValue.CONSTANT_VALUE_0, y: FixedValue.CONSTANT_VALUE_0},
    });
    const [itemIndex, setItemIndex] = React.useState<number>(elementIndex);

    React.useImperativeHandle(
      ref,
      () => ({
        getName: () => 'Text',
        getPosition: (): TextState => position,
        renderElementToSave: renderForSave,
        getItemOrderTabItem: (): JSX.Element => renderItemOrderTabItem(),
        deselect: (): void =>
          setPosition(prev => ({...prev, isSelected: false})),
        changeIndex: (newIndex: number) => setItemIndex(newIndex),
        changeFont: (newFont: string): void =>
          setPosition(prev => ({...prev, font: newFont})),
        changeTextValue: (newTextValue: string): void =>
          setPosition(prev => {
            const newWidth = newTextValue.length * FixedValue.CONSTANT_VALUE_16;
            return {...prev, textValue: newTextValue, width: newWidth};
          }),
        changeFillColor: (color: string): void =>
          setPosition(prev => ({...prev, fillColor: color})),
        changeStroke: (color: string, width?: number) =>
          setPosition(prev => ({
            ...prev,
            strokeColor: color,
            strokeWidth: !!width ? width : prev.strokeWidth,
          })),
        changeRotation: (newRotation: number): void =>
          setPosition(prev => ({
            ...prev,
            rotation:
              newRotation === FixedValue.CONSTANT_VALUE_360
                ? FixedValue.CONSTANT_VALUE_0
                : newRotation,
          })),
        getTopEditorItemType: (): TopEditorItemType => TopEditorItemType.TEXT,
        changeScale: (newScale: number): void =>
          setPosition(prev => ({...prev, scale: newScale})),
      }),
      [position, itemIndex]
    );

    const renderForSave = React.useCallback((): string => {
      const x = position.x - position.width / 2;
      const y = position.y;
      const originX = position.x;
      const originY = position.y;
      const transform = `translate(${originX}, ${originY}) scale(${position.scale}) rotate(${position.rotation}) translate(-${originX}, -${originY})`;
      const str1 = `<text id="text-top-element-${itemIndex}" x="${x}" y="${y}" height="${position.height}" width="${position.width}" `;
      const str2 = `fill="${position.fillColor}" stroke="${position.strokeColor}" stroke-width="${position.strokeWidth}" `;
      const str3 = `transform="${transform}" font-family="${getFontFamilyForSave(
        position.font as FontFamily
      )}" `;
      const str4 = `font-size="${position.fontSize}px">${position.textValue}</text>`;
      return str1 + str2 + str3 + str4;
    }, [position, itemIndex]);

    const handlePointerDown = React.useCallback(
      (event: GestureResponderEvent): void => {
        event.stopPropagation();
        if (selectItemCallback) selectItemCallback(itemIndex);
        const el = event.target;
        const x = event.nativeEvent.pageX - position.x;
        const y = event.nativeEvent.pageY - position.y;
        // @ts-ignore Correct usage for web
        if (isWebsite()) el.setPointerCapture(event.pointerId);
        setPosition({
          ...position,
          isSelected: true,
          offset: {x, y},
        });
        setIsDragging(true);
      },
      [selectItemCallback, position, itemIndex]
    );

    const handlePointerMove = React.useCallback(
      (event: GestureResponderEvent): void => {
        const diffX = event.nativeEvent.pageX - position.x;
        const diffY = event.nativeEvent.pageY - position.y;
        const cx: number = position.x - (position.offset.x - diffX);
        const cy: number = position.y - (position.offset.y - diffY);
        if (position.isSelected && isDragging) {
          setPosition({
            ...position,
            x: cx,
            y: cy,
          });
        }
      },
      [position, isDragging]
    );

    const handlePointerUp = React.useCallback((): void => {
      setIsDragging(false);
    }, []);

    const renderItemOrderTabItem = (): JSX.Element => {
      return (
        <G>
          <SVGText
            id={`text-${elementId}`}
            x={FixedValue.CONSTANT_VALUE_0}
            y={FixedValue.CONSTANT_VALUE_50}
            fill={position.fillColor}
            stroke={position.strokeColor}
            strokeWidth={
              (position.strokeWidth * FixedValue.CONSTANT_VALUE_25) /
              position.fontSize
            }
            fontSize={FixedValue.CONSTANT_VALUE_25}
            // @ts-ignore will never be null, only undefined or a valid fontFamily
            fontFamily={
              processFontFamily(position.font) === null
                ? undefined
                : processFontFamily(position.font)
            }
            alignmentBaseline={GlobalStyleValues.CENTER}
          >
            {position.textValue.substring(
              FixedValue.CONSTANT_VALUE_0,
              FixedValue.CONSTANT_VALUE_4
            ) +
              (position.textValue.length > FixedValue.CONSTANT_VALUE_4
                ? '...'
                : '')}
          </SVGText>
          <Rect
            // @ts-ignore Used to include id attribute when testing
            testID={'TE-text-item-selection-rect-top'}
            accessibilityLabel={'TE-text-item-selection-rect-top'}
            key={`selection-box-${elementId}-top`}
            x={FixedValue.CONSTANT_VALUE_0}
            y={FixedValue.CONSTANT_VALUE_0}
            width={editorControlItemDimensions.width}
            height={editorControlItemDimensions.height}
            fill={COLORS.WHITE}
            fillOpacity={FixedValue.CONSTANT_VALUE_0}
          />
        </G>
      );
    };

    const renderNonInteractive = (): JSX.Element => {
      return (
        <SVGText
          id={`text-${elementId}`}
          key={elementId}
          x={position.x - position.width / FixedValue.CONSTANT_VALUE_2}
          y={
            isWebsite()
              ? position.y
              : position.y - position.height / FixedValue.CONSTANT_VALUE_2
          }
          height={position.height} // Does not affect height of text; only used for saving/loading
          width={position.width} // Does not affect width of text; only used for saving/loading
          originX={position.x}
          originY={position.y}
          rotation={position.rotation}
          scale={position.scale}
          fill={position.fillColor}
          stroke={position.strokeColor}
          strokeWidth={position.strokeWidth}
          fontSize={position.fontSize}
          // @ts-ignore will never be null, only undefined or a valid fontFamily
          fontFamily={
            processFontFamily(position.font) === null
              ? undefined
              : processFontFamily(position.font)
          }
          alignmentBaseline={GlobalStyleValues.CENTER}
        >
          {position.textValue}
        </SVGText>
      );
    };

    const renderInteractive = (): JSX.Element => {
      if (isWebsite()) {
        return (
          <G
            originX={position.x}
            originY={position.y}
            rotation={position.rotation}
            scale={position.scale}
          >
            <Rect
              // @ts-ignore Used to include id attribute when testing
              testID={'TE-text-item-selection-rect'}
              accessibilityLabel={'TE-text-item-selection-rect'}
              key={`selection-box-${elementId}`}
              x={
                position.x -
                position.width / FixedValue.CONSTANT_VALUE_2 -
                FixedValue.CONSTANT_VALUE_10
              }
              y={position.y - position.height}
              width={position.width + FixedValue.CONSTANT_VALUE_25}
              height={position.height + FixedValue.CONSTANT_VALUE_20}
              fill={COLORS.WHITE}
              fillOpacity={FixedValue.CONSTANT_VALUE_0}
              stroke={position.isSelected ? COLORS.PRIMARY_BLUE : ''}
              strokeWidth={FixedValue.CONSTANT_VALUE_2 / position.scale}
              // @ts-ignore Used to handle pointer events
              onPointerDown={handlePointerDown}
              onPointerUp={handlePointerUp}
              onPointerMove={handlePointerMove}
              onPressIn={handlePointerDown}
              onPressOut={handlePointerUp}
              onResponderMove={handlePointerMove}
            />
            {/** Actual item */}
            <SVGText
              id={`text-${elementId}`}
              key={`text-${elementId}`}
              x={position.x - position.width / FixedValue.CONSTANT_VALUE_2}
              y={position.y}
              fill={position.fillColor}
              stroke={position.strokeColor}
              strokeWidth={position.strokeWidth}
              fontSize={position.fontSize}
              // @ts-ignore will never be null, only undefined or a valid fontFamily
              fontFamily={
                processFontFamily(position.font) === null
                  ? undefined
                  : processFontFamily(position.font)
              }
              alignmentBaseline={GlobalStyleValues.CENTER}
            >
              {position.textValue}
            </SVGText>
            <Rect
              // @ts-ignore Used to include id attribute when testing
              testID={'TE-text-item-selection-rect-top'}
              accessibilityLabel={'TE-text-item-selection-rect-top'}
              key={`selection-box-${elementId}-top`}
              x={position.x - position.width / FixedValue.CONSTANT_VALUE_2}
              y={position.y - position.height + FixedValue.CONSTANT_VALUE_10}
              width={position.width}
              height={position.height}
              fill={COLORS.WHITE}
              fillOpacity={FixedValue.CONSTANT_VALUE_0}
              // @ts-ignore Used to handle pointer events
              onPointerDown={handlePointerDown}
              onPointerUp={handlePointerUp}
              onPointerMove={handlePointerMove}
              onPressIn={handlePointerDown}
              onPressOut={handlePointerUp}
              onResponderMove={handlePointerMove}
            />
          </G>
        );
      } else {
        return (
          <G
            originX={position.x}
            originY={position.y}
            rotation={position.rotation}
            scale={position.scale}
          >
            <Rect
              // @ts-ignore Used to include id attribute when testing
              testID={'TE-text-item-selection-rect'}
              accessibilityLabel={'TE-text-item-selection-rect'}
              key={`selection-box-${elementId}`}
              x={position.x - position.width / FixedValue.CONSTANT_VALUE_2}
              y={position.y - position.height / FixedValue.CONSTANT_VALUE_2}
              width={position.width + FixedValue.CONSTANT_VALUE_100}
              height={position.height + FixedValue.CONSTANT_VALUE_25}
              fill={COLORS.WHITE}
              fillOpacity={FixedValue.CONSTANT_VALUE_0}
              stroke={position.isSelected ? COLORS.PRIMARY_BLUE : ''}
              strokeWidth={FixedValue.CONSTANT_VALUE_2 / position.scale}
              // @ts-ignore Used to handle pointer events
              onPointerDown={handlePointerDown}
              onPointerUp={handlePointerUp}
              onPointerMove={handlePointerMove}
              onPressIn={handlePointerDown}
              onPressOut={handlePointerUp}
              onResponderMove={handlePointerMove}
            />
            {/** Actual item */}
            <SVGText
              id={`text-${elementId}`}
              key={`text-${elementId}`}
              x={position.x - position.width / FixedValue.CONSTANT_VALUE_2}
              y={position.y}
              fill={position.fillColor}
              stroke={position.strokeColor}
              strokeWidth={position.strokeWidth}
              fontSize={position.fontSize}
              // @ts-ignore will never be null, only undefined or a valid fontFamily
              fontFamily={
                processFontFamily(position.font) === null
                  ? undefined
                  : processFontFamily(position.font)
              }
              alignmentBaseline={GlobalStyleValues.CENTER}
              // @ts-ignore Used to handle pointer events
              onPointerDown={handlePointerDown}
              onPointerUp={handlePointerUp}
              onPointerMove={handlePointerMove}
              onPressIn={handlePointerDown}
              onPressOut={handlePointerUp}
              onResponderMove={handlePointerMove}
            >
              {position.textValue}
            </SVGText>
          </G>
        );
      }
    };

    return disableInteraction ? renderNonInteractive() : renderInteractive();
  }
);

export default BasicText;
