import React from 'react';
import {View, Text} from 'react-native';
import {Badge} from 'native-base';

import {COLORS, FixedValue, TabBarName} from '../../../constants';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {styles} from './styles';

interface INotificationTabHeader {
  badgeCount: number;
}

const NotificationTabHeader = ({
  badgeCount,
}: INotificationTabHeader): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);

  return (
    <View style={style.container}>
      <Text style={style.titleText}>{TabBarName.NOTIFICATION}</Text>
      {badgeCount > FixedValue.CONSTANT_VALUE_0 && (
        <Badge
          backgroundColor={COLORS.F54336}
          rounded="full"
          zIndex={1}
          variant="solid"
          _text={style.textStyle}
        >
          {badgeCount > FixedValue.CONSTANT_VALUE_9 ? '9+' : badgeCount}
        </Badge>
      )}
    </View>
  );
};

export default React.memo(NotificationTabHeader);
