import {StyleSheet} from 'react-native';

import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';

export const styles = (theme: IColorTheme) =>
  StyleSheet.create({
    searchView: {
      width: Percentage.PRECENTAGE_100,
      alignItems: GlobalStyleValues.CENTER,
    },
    searchText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: theme.color,
      textAlign: GlobalStyleValues.CENTER,
    },
    searchBoldText: {
      fontFamily: FONT_NAMES.SF_BOLD,
    },
    noDataHeading: {
      ...BASESTYLE.textAlignCenter,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: theme.color,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_28),
    },
    noDataSubHeading: {
      ...BASESTYLE.textAlignCenter,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme.color,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_25),
    },
    noDataDesc: {
      fontFamily: FONT_NAMES.SF_REGULAR,
      textAlign: GlobalStyleValues.CENTER,
      color: COLORS._4A4A4A,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_24),
    },
    createTopButton: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_179),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_57),
    },
    createTopButtonText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    primaryButtonStyle: {
      ...BASESTYLE.inCenter,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_179),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
    },
    primaryButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
    fullHeight: {
      height: Percentage.PRECENTAGE_100,
    },
  });
