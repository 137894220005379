import {takeLatest, put, call, select, delay} from 'redux-saga/effects';
import {AppConst, FixedValue} from '../../constants';
import {IActionType} from '../../types/constantFilesTypes';
import {
  _approveOrReject,
  _approvelRequestList,
  _topCreditsAPI,
  approveOrRejectByParent,
  topBuyingApprovalData,
  updateTopBuyingSettings,
} from '../../utils/apis';
import {authState} from '../reducers/authSlice';
import {
  onChildProfileApproveSuccess,
  onChildProfileApproveFailed,
  onChildProfileApproveEnd,
  onApproveOrRejectSuccess,
  onApproveOrRejectFailed,
  onApproveOrRejectEnd,
  onTopBuyingApprovalSettingSuccess,
  onTopBuyingApprovalSettingFailed,
  onTopBuyingApprovalSettingEnd,
  onTopBuyingApprovalListSuccess,
  onTopBuyingApprovalListFailed,
  onTopBuyingApprovalListEnd,
  onAcceptOrRejectbyParentSuccess,
  onAcceptOrRejectbyParentFailed,
  onAcceptOrRejectbyParentEnd,
  onTopCreditsRequestSuccess,
  onTopCreditsRequestFailed,
  onTopCreditsRequestEnd,
} from '../reducers/childApproveSlice';
import {
  ApproveOrReject,
  ChildApproveListArray,
  ITopBuyingApprovalListResponse,
} from '../../types/reducerTypes/childApproveRequestStoreTypes';
import {isWebsite} from '../../utils/responsive';
import {_ToastHandler} from '../../utils/helperFunctions';

const {StoreConstants} = AppConst;

// Call Create COllection API
function* createChildApproveList(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const childApproveOrReject: ChildApproveListArray = yield call(
      _approvelRequestList,
      action.payload,
      userTokens
    );

    yield put(onChildProfileApproveSuccess(childApproveOrReject));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onChildProfileApproveFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onChildProfileApproveEnd());
  }
}
function* approveOrRejectProfile(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const approveorreject: ApproveOrReject = yield call(
      _approveOrReject,
      action.payload,
      userTokens
    );
    yield put(onApproveOrRejectSuccess(approveorreject));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onApproveOrRejectFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onApproveOrRejectEnd());
  }
}

function* onTopBuyingApprovalSettingStart(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: string = yield call(
      updateTopBuyingSettings,
      action.payload,
      userTokens
    );
    yield put(onTopBuyingApprovalSettingSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onTopBuyingApprovalSettingFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onTopBuyingApprovalSettingEnd());
  }
}

function* acceptRejectByParent(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: string = yield call(
      approveOrRejectByParent,
      action.payload,
      userTokens
    );
    yield put(onAcceptOrRejectbyParentSuccess(data));
  } catch (error: any) {
    yield put(onAcceptOrRejectbyParentFailed(error?.errors[0].msg ?? ''));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onAcceptOrRejectbyParentEnd());
  }
}

function* topBuyingApprovalList(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ITopBuyingApprovalListResponse = yield call(
      topBuyingApprovalData,
      action.payload,
      userTokens
    );
    yield put(onTopBuyingApprovalListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onTopBuyingApprovalListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onTopBuyingApprovalListEnd());
  }
}

function* topCreditRequestFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: string = yield call(_topCreditsAPI, action.payload, userTokens);
    yield put(onTopCreditsRequestSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onTopCreditsRequestFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onTopCreditsRequestEnd());
  }
}

export default function* childApproveSaga() {
  yield takeLatest(
    `${StoreConstants.CHILD_APPROVEL_REQUEST_LIST}/onChildProfileApproveStart`,
    createChildApproveList
  );
  yield takeLatest(
    `${StoreConstants.CHILD_APPROVEL_REQUEST_LIST}/onApproveOrRejectStart`,
    approveOrRejectProfile
  );
  yield takeLatest(
    `${StoreConstants.CHILD_APPROVEL_REQUEST_LIST}/onTopBuyingApprovalSettingStart`,
    onTopBuyingApprovalSettingStart
  );
  yield takeLatest(
    `${StoreConstants.CHILD_APPROVEL_REQUEST_LIST}/onTopBuyingApprovalListStart`,
    topBuyingApprovalList
  );
  yield takeLatest(
    `${StoreConstants.CHILD_APPROVEL_REQUEST_LIST}/onAcceptOrRejectbyParentStart`,
    acceptRejectByParent
  );
  yield takeLatest(
    `${StoreConstants.CHILD_APPROVEL_REQUEST_LIST}/onTopCreditsRequestStart`,
    topCreditRequestFunction
  );
}
