import React from 'react';
import {View, Text} from 'react-native';

import LabelInput from '../../components/input/labelInput';
import AppIconMd from '../../components/renderSvgs/appIcon';
import PrimaryButton from '../../components/button/primaryButton';
import {FixedValue, ResetPasswordSettings, TEXT} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import useCreatePwdHooks from '../../hooks/componentHooks/useCreatePwdHooks';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import {createPwdStyle} from './createPassword.style';
import PwdValidation from '../../components/smallDesigns/pwdValidation';
import openEye from '../../components/renderSvgs/openEye';
import {CreatePasswordContext} from '../../contextAPI/createPassword';
import closeEye from '../../components/renderSvgs/closeEye';
import {_InputType} from '../../utils/helperFunctions';
import BackButton from '../../components/button/backButton';

const CreatePwdContent: React.FC = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    ConfirmPasswordError,
  } = useCreatePwdHooks();
  const {styles, ids} = createPwdStyle(ColorTheme);
  const {
    showPassword,
    showConfirmPassword,
    isBtnLoading,
    setShowPassword,
    setShowConfirmPassword,
  } = React.useContext(CreatePasswordContext);

  return (
    <View style={styles.container} dataSet={{media: ids.container}}>
      <BackButton />
      <View style={styles.iconView}>
        <AppIconMd
          width={scaleWidth(FixedValue.CONSTANT_VALUE_63)}
          height={scaleHeight(FixedValue.CONSTANT_VALUE_57)}
        />
      </View>
      <Text style={styles.titleText}>{TEXT.CREATE_PASSWORD}</Text>
      <LabelInput
        inputLabel={TEXT.NEW_PASSWORD}
        value={values.NewPassword}
        placeholder={TEXT.ENTER_NEW_PASSWORD}
        onChangeText={handleChange(ResetPasswordSettings.NEW_PASSWORD)}
        onBlur={handleBlur(ResetPasswordSettings.NEW_PASSWORD)}
        maxLength={FixedValue.CONSTANT_VALUE_25}
        secureTextEntry={showPassword}
        keyboardType={_InputType.password}
        onPress={setShowPassword}
        Icon={!showPassword ? openEye : closeEye}
      />
      <LabelInput
        inputLabel={TEXT.CONFIRM_PASSWORD}
        value={values.ConfirmPassword}
        placeholder={TEXT.ENTER_CONFIRM_PASSWORD}
        onChangeText={handleChange(ResetPasswordSettings.COMFIRM_PASSWORD)}
        onBlur={handleBlur(ResetPasswordSettings.COMFIRM_PASSWORD)}
        maxLength={FixedValue.CONSTANT_VALUE_25}
        Icon={!showConfirmPassword ? openEye : closeEye}
        secureTextEntry={showConfirmPassword}
        keyboardType={_InputType.password}
        onPress={setShowConfirmPassword}
        haveError={ConfirmPasswordError}
      />
      <PwdValidation Password={values.NewPassword} />
      <View style={styles.buttonStyle}>
        <PrimaryButton
          buttonLabel={TEXT.SUBMIT}
          onPress={handleSubmit}
          isDisabled={!isValid}
          isLoading={isBtnLoading}
        />
      </View>
    </View>
  );
};

export default React.memo(CreatePwdContent);
