import React from 'react';
import {FlatList, ScrollView, TextStyle, View} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';

import PrimaryButton from '../../components/button/primaryButton';
import EmptyChildListView from '../../components/emptyListView/emptyChildListView';
import GroupListTile from '../../components/RenderItemTile/groupListTile';
import {BASESTYLE, FixedValue, GlobalStyleValues, TEXT} from '../../constants';
import {GroupContext} from '../../contextAPI/groupManagementContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import useGroupManagementListHooks from '../../hooks/componentHooks/useGroupManagementListHooks';
import {useAppSelector} from '../../hooks/reduxHooks';
import CreateEditGroupFormModal from '../../modals/createEditGroupModal';
import {groupManagementStore} from '../../redux/reducers/groupManagementSlice';
import {myGroupListStyle} from './myGroupList.style';

const MyGroupList = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = myGroupListStyle(ColorTheme);
  const {
    myGroupListResult,
    groupMangementPullDownRefresh,
    setOpenCreateGroupModal,
    setCreateEditGroupModalData,
    setGroupInitialImage,
    setTempGroupData,
    setGroupMangementPullDownRefresh,
  } = React.useContext(GroupContext);
  const {myGroupList} = useAppSelector(groupManagementStore);
  const {onMyGroupListReachedEnd, callMyGroupListAPI} =
    useGroupManagementListHooks();

  const handleRefresh = React.useCallback(() => {
    setGroupMangementPullDownRefresh(true);
    callMyGroupListAPI(FixedValue.CONSTANT_VALUE_0);
  }, [groupMangementPullDownRefresh]);

  const primaryButtonFunction = (style: TextStyle) => {
    return (
      <PrimaryButton
        buttonLabel={TEXT.CREATE_A_NEW_GROUP}
        buttonwidth={style}
        labelStyle={styles.buttonLabelstyle}
        onPress={() => {
          setTempGroupData('');
          setGroupInitialImage('');
          setCreateEditGroupModalData(null);
          setOpenCreateGroupModal();
        }}
      />
    );
  };

  //show empty view when there is no data available
  const renderMyGroupEmptyUI = React.useMemo((): JSX.Element | null => {
    if (myGroupListResult?.length === FixedValue.CONSTANT_VALUE_0) {
      return (
        <ScrollView
          contentContainerStyle={styles.scrollviewStyle}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={groupMangementPullDownRefresh}
              onRefresh={handleRefresh}
            />
          }
        >
          <View style={styles.emptyView}>
            <EmptyChildListView
              emptyViewTitle={TEXT.SORRY_NO_GROUP}
              emptyViewSubTitle={''}
            />
          </View>
          <View style={BASESTYLE.inCenter}>
            {primaryButtonFunction(styles.buttonStyle)}
          </View>
        </ScrollView>
      );
    }
    return null;
  }, [myGroupListResult, groupMangementPullDownRefresh]);

  const renderMyGroupFlatList = React.useMemo((): JSX.Element | null => {
    return (
      <FlatList
        keyExtractor={(_, index) => `${TEXT.GROUP_LIST}-${index}`}
        keyboardShouldPersistTaps={GlobalStyleValues.HANDLED}
        data={myGroupListResult}
        contentContainerStyle={styles.flatListContentContainerStyle}
        onEndReached={() => onMyGroupListReachedEnd()}
        onEndReachedThreshold={FixedValue.CONSTANT_VALUE_1}
        showsVerticalScrollIndicator={false}
        scrollEnabled
        refreshControl={
          <RefreshControl
            refreshing={groupMangementPullDownRefresh}
            onRefresh={handleRefresh}
          />
        }
        renderItem={({item}) => (
          <GroupListTile key={item.id} item={item} isComingFromMyGroup={true} />
        )}
      />
    );
  }, [myGroupListResult, groupMangementPullDownRefresh]);

  return (
    <>
      {!myGroupList?.isLoading && renderMyGroupEmptyUI}
      {myGroupListResult.length > FixedValue.CONSTANT_VALUE_0 && (
        <View style={styles.mainList}>{renderMyGroupFlatList}</View>
      )}
      {myGroupListResult.length ? primaryButtonFunction(styles.buttonStyle2) : null}
      <CreateEditGroupFormModal />
    </>
  );
};
export default React.memo(MyGroupList);
