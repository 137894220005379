import * as React from 'react';
import Svg, {SvgProps, G, Path, Defs, ClipPath, Rect} from 'react-native-svg';
const GiftImage = (props: SvgProps) => (
  <Svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G clipPath="url(#clip0_769_8002)">
      <Path
        d="M16.6667 10V18.3333H3.33334V10"
        stroke={props?.stroke ? props?.stroke : 'white'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M18.3333 5.83301H1.66666V9.99967H18.3333V5.83301Z"
        stroke={props?.stroke ? props?.stroke : 'white'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M10 18.333V5.83301"
        stroke={props?.stroke ? props?.stroke : 'white'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M9.99999 5.83366H6.24999C5.69746 5.83366 5.16755 5.61416 4.77685 5.22346C4.38615 4.83276 4.16666 4.30286 4.16666 3.75033C4.16666 3.19779 4.38615 2.66789 4.77685 2.27719C5.16755 1.88649 5.69746 1.66699 6.24999 1.66699C9.16666 1.66699 9.99999 5.83366 9.99999 5.83366Z"
        stroke={props?.stroke ? props?.stroke : 'white'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M10 5.83366H13.75C14.3025 5.83366 14.8324 5.61416 15.2231 5.22346C15.6138 4.83276 15.8333 4.30286 15.8333 3.75033C15.8333 3.19779 15.6138 2.66789 15.2231 2.27719C14.8324 1.88649 14.3025 1.66699 13.75 1.66699C10.8333 1.66699 10 5.83366 10 5.83366Z"
        stroke={props?.stroke ? props?.stroke : 'white'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_769_8002">
        <Rect
          width={20}
          height={20}
          fill='white'
        />
      </ClipPath>
    </Defs>
  </Svg>
);
export default GiftImage;
