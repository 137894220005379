import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  FixedValue,
  Percentage,
  FONT_NAMES,
  GlobalStyleValues,
  COLORS,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const WebViewStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.flex1,
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    titleText: {
      color: theme.color,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_REGULAR,
      // marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      // bottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      textAlign: GlobalStyleValues.CENTER,
    },
    titleTextTwo: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_17),
      color: theme.labelColor,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_5),
    },
    buttonStyle: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_26),
    },
    btnStyle: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_30),
    },
    extraTopMargin: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    infoView:{
      ...BASESTYLE.flex1,
      ...BASESTYLE.inCenter,
    },
    decorateTerm: {
      color: COLORS.PRIMARY_BLUE,
      textDecorationLine: GlobalStyleValues.UNDERLINE,
    },
    iFrameStyle: {
      width: Percentage.PRECENTAGE_99,
      height: Percentage.PRECENTAGE_100,
      backgroundColor: COLORS.WHITE
    },
  });
