import React from 'react';
import {
  CREATE_GROUP_MANAGEMENT,
  GROUP_DELETE,
  GROUP_DETAIL,
  GROUP_INVITATION_LIST,
  GROUP_IN_LIST,
  GROUP_USER_LIST,
  LEAVE_GROUP_ACTION,
  MY_GROUP_LIST,
  REMOVE_USER_FROM_GROUP,
  UPDATE_GROUP,
  USER_ADD_ON_GROUP,
  USER_LIST,
} from '../constants/actionTypes';
import {useAppSelector} from '../hooks/reduxHooks';
import {groupManagementStore} from '../redux/reducers/groupManagementSlice';
import {
  IGroupManagementContext,
  IGroupManagementProvider,
} from '../types/contextTypes/groupManagementContextType';
import {CustomUserListModalListData} from '../types/reducerTypes/searchTabStoreType';
import {_ToastHandler} from '../utils/helperFunctions';
import {
  MyGroupInvitationsModelData,
  MyGroupListModelData,
  ICameraClickProps,
} from '../types/reducerTypes/groupMangementTypes';
import {FixedValue} from '../constants';
import {goBack} from '../services/navigationService';
import {TOAST_TEXT} from '../constants/textMessage';
// Create a context with initial values
const GroupContext = React.createContext<IGroupManagementContext>({
  openCreateGroupModal: false,
  openCloseProfileModal: false,
  openCloseGroupEditDeleteModal: false,
  showDeleteGroupModal: false,
  groupInvitationApprovedModal: false,
  selectUserListModal: false,
  groupProfileImages: null,
  isGroupEditMode: false,
  userListResult: [],
  selectedUsers: [],
  myGroupListResult: [],
  groupUserListResult: [],
  groupInListResult: [],
  groupInvitationsListResult: [],
  searchUserListText: '',
  selectedGroupInvitaions: null,
  groupDetailData: null,
  isUserComingFromMyGroup: false,
  selectedGroupUserId: 0,
  groupInitialImage: '',
  showLeaveGroupModal: false,
  showRemoveUserFromGroupModal: false,
  selectedGroupId: 0,
  createEditGroupModalData: null,
  tempGroupData: '',
  clickOnApprovedButton: 0,
  groupMangementPullDownRefresh: false,
  initialLoader: false,
  setCreateEditGroupModalData: _val => {},
  setSearchUserListText: _val => {},
  setUserListResult: _val => {},
  setMyGroupListResult: _val => {},
  setGroupUserListResult: _val => {},
  setGroupInListResult: _val => {},
  setGroupInvitationsListResult: _val => {},
  setSelectedGroupInvitaions: _val => {},
  setGroupProfileImages: () => {},
  setOpenCreateGroupModal: () => {},
  setOpenCloseProfileModal: () => {},
  setOpenCloseGroupEditDeleteModal: _val => {},
  setShowDeleteGroupModal: () => {},
  setGroupInvitationApprovedModal: () => {},
  setSelectUserListModal: () => {},
  removeProfileImage: () => {},
  setIsGroupEditMode: _val => {},
  clearSearchUserListResult: () => {},
  selectOrUnSelectUser: _val => {},
  setGroupDetailData: _val => {},
  clearGroupUserListResult: () => {},
  setIsUserComingFromMyGroup: _val => {},
  setSelectedGroupUserId: _val => {},
  updateGroupUserListResult: _val => {},
  setGroupInitialImage: _val => {},
  setShowLeaveGroupModal: () => {},
  setShowRemoveUserFromGroupModal: () => {},
  updateSelectedUserInGroup: _val => {},
  setSelectedGroupId: _val => {},
  setTempGroupData: _val => {},
  setClickOnApprovedButton: _val => {},
  setGroupMangementPullDownRefresh: _val => {},
  setInitialLoader: _val => {},
});
// Define a provider for the context
const CreateGroupProvider = (props: IGroupManagementProvider): JSX.Element => {
  // Get the create and update collection actions from the redux store
  const {
    userList,
    userAddInGroup,
    myGroupList,
    groupInList,
    groupInvitaionsList,
    groupUserList,
    groupDetailAPIData,
    groupDeleteAPIData,
    groupLeaveAPIData,
    updateGroup,
    createNewGroup,
    removeUserFromGroupAPI,
  } = useAppSelector(groupManagementStore);
  const [groupProfileImages, setGroupProfileImages] =
    React.useState<ICameraClickProps | null>(null);
  // Initialize the state variables

  const [openCreateGroupModal, setOpenCreateGroupModal] =
    React.useState<boolean>(false);
  const [openCloseProfileModal, setOpenCloseProfileModal] =
    React.useState<boolean>(false);
  const [openCloseGroupEditDeleteModal, setOpenCloseGroupEditDeleteModal] =
    React.useState<boolean>(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] =
    React.useState<boolean>(false);
  const [groupInvitationApprovedModal, setGroupInvitationApprovedModal] =
    React.useState<boolean>(false);
  const [selectUserListModal, setSelectUserListModal] =
    React.useState<boolean>(false);
  // Add a state variable for the Add/Edit Mode state of collection
  const [isGroupEditMode, setIsGroupEditMode] = React.useState<boolean>(false);
  const [userListResult, setUserListResult] = React.useState<
    CustomUserListModalListData[]
  >([]);
  const [myGroupListResult, setMyGroupListResult] = React.useState<
    MyGroupListModelData[]
  >([]);
  const [groupUserListResult, setGroupUserListResult] = React.useState<
    CustomUserListModalListData[]
  >([]);
  const [groupInListResult, setGroupInListResult] = React.useState<
    MyGroupListModelData[]
  >([]);
  const [groupInvitationsListResult, setGroupInvitationsListResult] =
    React.useState<MyGroupInvitationsModelData[]>([]);
  const [selectedGroupInvitaions, setSelectedGroupInvitaions] =
    React.useState<MyGroupInvitationsModelData | null>(null);
  const [groupDetailData, setGroupDetailData] =
    React.useState<MyGroupListModelData | null>(null);
  const [selectedUsers, setSelectedUsers] = React.useState<
    CustomUserListModalListData[]
  >([]);
  const [selectedGroupId, setSelectedGroupId] = React.useState<number>(0);
  const [groupInitialImage, setGroupInitialImage] = React.useState<string>('');
  const [showLeaveGroupModal, setShowLeaveGroupModal] =
    React.useState<boolean>(false);
  const [showRemoveUserFromGroupModal, setShowRemoveUserFromGroupModal] =
    React.useState<boolean>(false);
  const [searchUserListText, setSearchUserListText] =
    React.useState<string>('');
  const [_, selectOrUnSelectUser] =
    React.useState<CustomUserListModalListData | null>(null);
  const [isUserComingFromMyGroup, setIsUserComingFromMyGroup] =
    React.useState<boolean>(false);
  const [selectedGroupUserId, setSelectedGroupUserId] =
    React.useState<number>(0);
  const [createEditGroupModalData, setCreateEditGroupModalData] =
    React.useState<MyGroupListModelData | null>(null);
  const [tempGroupData, setTempGroupData] = React.useState<string>('');
  const [clickOnApprovedButton, setClickOnApprovedButton] =
    React.useState<number>(FixedValue.CONSTANT_VALUE_0);
  const [groupMangementPullDownRefresh, setGroupMangementPullDownRefresh] =
    React.useState<boolean>(false);
  const [initialLoader, setInitialLoader] = React.useState<boolean>(false);

  const _setCreateEditGroupModalData = React.useCallback(
    (NewData: MyGroupListModelData | null): void => {
      setCreateEditGroupModalData(NewData);
      if (NewData === null) {
        setIsGroupEditMode(false);
      }
    },
    [createEditGroupModalData]
  );

  React.useEffect(() => {
    if (createEditGroupModalData) {
      setIsGroupEditMode(true);
    } else {
      setIsGroupEditMode(false);
    }
  }, [createEditGroupModalData]);

  //pulldown refresh variable setter function for group management
  const _setGroupMangementPullDownRefresh = React.useCallback(
    (val: boolean): void => {
      setGroupMangementPullDownRefresh(val);
    },
    [groupMangementPullDownRefresh]
  );

  // Define a callback function to toggle the modal state
  const _setOpenCreateGroupModal = React.useCallback((): void => {
    setOpenCreateGroupModal(prev => !prev);
  }, [openCreateGroupModal]);

  const _setOpenCloseProfileModal = React.useCallback((): void => {
    setOpenCloseProfileModal(prev => !prev);
  }, [openCloseProfileModal]);

  const _setOpenCloseGroupEditDeleteModal = React.useCallback(
    (val: boolean): void => {
      setOpenCloseGroupEditDeleteModal(val);
    },
    [openCloseGroupEditDeleteModal]
  );

  const _setShowDeleteGroupModal = React.useCallback((): void => {
    setShowDeleteGroupModal(prev => !prev);
  }, [showDeleteGroupModal]);

  const _setGroupInvitationApprovedModal = React.useCallback((): void => {
    setGroupInvitationApprovedModal(prev => !prev);
  }, [groupInvitationApprovedModal]);

  const _setSelectedGroupInvitaions = React.useCallback(
    (value: MyGroupInvitationsModelData): void => {
      setSelectedGroupInvitaions(value);
    },
    [selectedGroupInvitaions]
  );

  const _clearSearchUserListResult = React.useCallback((): void => {
    setUserListResult([]);
  }, [userListResult]);

  const _setSelectUserListModal = React.useCallback((): void => {
    setSelectUserListModal(prev => !prev);
    _clearSearchUserListResult();
    _setSearchUserListText('');
  }, [selectUserListModal, userListResult, searchUserListText]);

  // Define a callback function to toggle the edit mode
  const _setIsGroupEditMode = React.useCallback(
    (val: boolean): void => {
      setIsGroupEditMode(val);
    },
    [isGroupEditMode]
  );

  const _setClickOnApprovedButton = React.useCallback(
    (val: number): void => {
      setClickOnApprovedButton(val);
    },
    [clickOnApprovedButton]
  );

  const _setGroupInitialImage = React.useCallback(
    (val: string): void => {
      setGroupInitialImage(val);
    },
    [groupInitialImage]
  );

  const _setInitialLoader = React.useCallback(
    (val: boolean): void => {
      setInitialLoader(val);
    },
    [initialLoader]
  );

  //show or hide leave group modal
  const _setShowLeaveGroupModal = React.useCallback((): void => {
    setShowLeaveGroupModal(prev => !prev);
  }, [showLeaveGroupModal]);

  //show or hide remove user from group modal
  const _setShowRemoveUserFromGroupModal = React.useCallback((): void => {
    setShowRemoveUserFromGroupModal(prev => !prev);
  }, [showRemoveUserFromGroupModal]);

  const _setGroupProfileImages = React.useCallback(
    (value: ICameraClickProps | null): void => {
      setGroupProfileImages(value);
      _setOpenCreateGroupModal();
    },
    [groupProfileImages]
  );

  const _setUserListResult = React.useCallback(
    (NewData: CustomUserListModalListData[]): void => {
      setUserListResult(prev => [...prev, ...NewData]);
    },
    [userListResult]
  );
  //set group id
  const _setSelectedGroupId = React.useCallback(
    (data: number): void => {
      setSelectedGroupId(data);
    },
    [selectedGroupId]
  );

  //set user id for remove from group
  const _setSelectedGroupUserId = React.useCallback(
    (data: number): void => {
      setSelectedGroupUserId(data);
    },
    [selectedGroupUserId]
  );

  const _setIsUserComingFromMyGroup = React.useCallback(
    (data: boolean): void => {
      setIsUserComingFromMyGroup(data);
    },
    [isUserComingFromMyGroup]
  );

  const _removeProfileImage = React.useCallback((): void => {
    setGroupProfileImages(null);
  }, [groupProfileImages]);

  const _clearGroupUserListResult = React.useCallback((): void => {
    setGroupUserListResult([]);
  }, []);

  const _updateGroupUserListResult = React.useCallback(
    (NewData: CustomUserListModalListData[]): void => {
      setGroupUserListResult(NewData);
    },
    [groupUserListResult]
  );

  React.useEffect(() => {
    switch (userAddInGroup.status) {
      case USER_ADD_ON_GROUP.SUCCESS:
        _ToastHandler(TOAST_TEXT.INVITATION_SENT_SUCCESSFULLY, true);
        _setSearchUserListText('');
        setSelectedUsers([]);
        _setSelectUserListModal();
        break;
      default:
        break;
    }
  }, [userAddInGroup.status]);

  const _updateSelectedUserInGroup = React.useCallback(
    (data: CustomUserListModalListData[]): void => {
      setSelectedUsers(data);
    },
    [selectedUsers]
  );
  //set edit group name
  const _setTempGroupData = React.useCallback(
    (data: string): void => {
      setTempGroupData(data);
    },
    [setTempGroupData]
  );

  React.useEffect(() => {
    switch (userList.status) {
      case USER_LIST.SUCCESS:
        // Create a new array by mapping over userListArray and updating matching items from selectedUser
        const updatedArray2 = userList.success?.data?.results?.map(item1 => {
          const matchingItem2 = selectedUsers.find(
            item2 => item2.id === item1.id
          );
          return matchingItem2
            ? {...item1, ...matchingItem2, alreadyPresentUser: true}
            : item1;
        });
        if (userList.success?.pageNumber === FixedValue.CONSTANT_VALUE_0) {
          setUserListResult(updatedArray2 ?? []);
        } else {
          _setUserListResult(updatedArray2 ?? []);
        }
        setInitialLoader(false);
        break;
      case USER_LIST.FAILED:
        setInitialLoader(false);
        break;
      default:
        break;
    }
  }, [userList.status]);

  const _setMyGroupListResult = React.useCallback(
    (NewData: MyGroupListModelData[]): void => {
      setMyGroupListResult(prev => [...prev, ...NewData]);
    },
    [myGroupListResult]
  );

  React.useEffect(() => {
    switch (myGroupList.status) {
      case MY_GROUP_LIST.SUCCESS:
        if (myGroupList.success?.pageNumber === FixedValue.CONSTANT_VALUE_0) {
          setMyGroupListResult(myGroupList.success?.data?.results ?? []);
        } else {
          _setMyGroupListResult(myGroupList.success?.data?.results ?? []);
        }
        setInitialLoader(false);
        setGroupMangementPullDownRefresh(false);
        break;
      case MY_GROUP_LIST.FAILED:
        setInitialLoader(false);
        setGroupMangementPullDownRefresh(false);
        break;
      default:
        break;
    }
  }, [myGroupList.status]);

  const _setGroupInListResult = React.useCallback(
    (NewData: MyGroupListModelData[]): void => {
      setGroupInListResult(prev => [...prev, ...NewData]);
    },
    [groupInListResult]
  );

  React.useEffect(() => {
    switch (groupInList.status) {
      case GROUP_IN_LIST.SUCCESS:
        if (groupInList.success?.pageNumber === FixedValue.CONSTANT_VALUE_0) {
          setGroupInListResult(groupInList.success?.data?.results ?? []);
        } else {
          _setGroupInListResult(groupInList.success?.data?.results ?? []);
        }
        setInitialLoader(false);
        setGroupMangementPullDownRefresh(false);
        break;
      case GROUP_IN_LIST.FAILED:
        setInitialLoader(false);
        setGroupMangementPullDownRefresh(false);
        break;
      default:
        break;
    }
  }, [groupInList.status]);

  const _setGroupInvitationsListResult = React.useCallback(
    (NewData: MyGroupInvitationsModelData[]): void => {
      setGroupInvitationsListResult(prev => [...prev, ...NewData]);
    },
    [groupInvitationsListResult]
  );

  React.useEffect(() => {
    switch (groupInvitaionsList.status) {
      case GROUP_INVITATION_LIST.SUCCESS:
        if (
          groupInvitaionsList.success?.pageNumber ===
          FixedValue.CONSTANT_VALUE_0
        ) {
          setGroupInvitationsListResult(
            groupInvitaionsList.success?.data?.results ?? []
          );
        } else {
          _setGroupInvitationsListResult(
            groupInvitaionsList.success?.data?.results ?? []
          );
        }
        setInitialLoader(false);
        setGroupMangementPullDownRefresh(false);
        break;
      case GROUP_INVITATION_LIST.FAILED:
        setInitialLoader(false);
        setGroupMangementPullDownRefresh(false);
        break;
      default:
        break;
    }
  }, [groupInvitaionsList.status]);

  const _setSearchUserListText = React.useCallback(
    (value: string): void => {
      setSearchUserListText(value);
    },
    [searchUserListText]
  );

  const _selectOrUnSelectUser = React.useCallback(
    (data: CustomUserListModalListData): void => {
      // Update the users list value in the users array
      setUserListResult(prev => {
        return prev.map(item => {
          if (item.id === data?.id) {
            return {
              ...item,
              alreadyPresentUser: !data.alreadyPresentUser,
            };
          }
          return item;
        });
      });
      // Check if the User is already selected
      const index = selectedUsers.findIndex(
        selected => selected?.id === data?.id
      );
      if (index !== FixedValue.CONSTANT_VALUE_MIN_1) {
        // User is already selected, remove it from the selected items array
        const newSelectedItems = [...selectedUsers];
        newSelectedItems.splice(index, FixedValue.CONSTANT_VALUE_1);
        setSelectedUsers(newSelectedItems);
      } else {
        // User is not selected, add it to the selected items array
        let item = {...data};
        item.alreadyPresentUser = !item.alreadyPresentUser;
        setSelectedUsers([...selectedUsers, item]);
      }
    },
    [userListResult]
  );

  const _setGroupUserListResult = React.useCallback(
    (NewData: CustomUserListModalListData[]): void => {
      setGroupUserListResult(prev => [...prev, ...NewData]);
    },
    [groupUserListResult]
  );
  const _setGroupDetailData = React.useCallback(
    (data: MyGroupListModelData | null): void => {
      setGroupDetailData(data);
    },
    [groupDetailData]
  );

  React.useEffect(() => {
    switch (groupDetailAPIData.status) {
      case GROUP_DETAIL.SUCCESS:
        _setGroupDetailData(groupDetailAPIData.success);
        break;
      default:
        break;
    }
  }, [groupDetailAPIData.status]);

  //get user list api success and failed handle
  React.useEffect(() => {
    switch (groupUserList.status) {
      case GROUP_USER_LIST.SUCCESS:
        if (groupUserList.success?.pageNumber === FixedValue.CONSTANT_VALUE_0) {
          setGroupUserListResult(groupUserList.success?.data?.results ?? []);
        } else {
          _setGroupUserListResult(groupUserList.success?.data?.results ?? []);
        }
        setInitialLoader(false);
        break;
      default:
        break;
    }
  }, [groupUserList.status]);

  //group delete api success and failed handle
  React.useEffect(() => {
    switch (groupDeleteAPIData.status) {
      case GROUP_DELETE.SUCCESS:
        const newMyGroupListArray = myGroupListResult.filter(
          item => item.id !== groupDetailData?.id
        );
        setMyGroupListResult(newMyGroupListArray);
        _setShowDeleteGroupModal();
        _ToastHandler(TOAST_TEXT.GROUP_SUCCESFULLY_DELETED, true);
        goBack();
        break;
      case GROUP_DELETE.FAILED:
        _setShowDeleteGroupModal();
        break;
      default:
        break;
    }
  }, [groupDeleteAPIData.status]);

  //leave group api success and failed handle
  React.useEffect(() => {
    switch (groupLeaveAPIData.status) {
      case LEAVE_GROUP_ACTION.SUCCESS:
        const newGroupArray = groupInListResult.filter(
          item => item.id !== groupDetailData?.id
        );
        setGroupInListResult(newGroupArray);
        _ToastHandler(TOAST_TEXT.GROUP_SUCCESSFULLY_LEFT, true);
        _setShowLeaveGroupModal();
        goBack();
        break;
      case LEAVE_GROUP_ACTION.FAILED:
        _setShowLeaveGroupModal();
        break;
      default:
        break;
    }
  }, [groupLeaveAPIData.status]);

  //after calling create new group api response handle
  React.useEffect(() => {
    switch (createNewGroup.status) {
      case CREATE_GROUP_MANAGEMENT.SUCCESS:
        setSelectedGroupId(
          createNewGroup.success?.data?.id ?? FixedValue.CONSTANT_VALUE_0
        );
        _ToastHandler(TOAST_TEXT.GROUP_SUCCESFULLY_CREATED, true);
        _setOpenCreateGroupModal();
        _removeProfileImage();
        _clearSearchUserListResult();
        _setSelectUserListModal();
        setInitialLoader(true);
        break;
      case CREATE_GROUP_MANAGEMENT.FAILED:
        const msg: string =
          //@ts-ignore
          createNewGroup?.failed?.errors?.[FixedValue.CONSTANT_VALUE_0]?.msg ??
          '';
        _ToastHandler(msg, false);
        break;
      default:
        break;
    }
  }, [createNewGroup.status]);

  //after calling group Update api response handle
  React.useEffect(() => {
    switch (updateGroup.status) {
      case UPDATE_GROUP.SUCCESS:
        _ToastHandler(TOAST_TEXT.GROUP_SUCCESFULLY_UPDATED, true);
        break;
      case UPDATE_GROUP.FAILED:
        const msg: string =
          //@ts-ignore
          updateGroup?.failed?.errors?.[FixedValue.CONSTANT_VALUE_0]?.msg ?? '';
        _ToastHandler(msg, false);
        break;
      default:
        break;
    }
  }, [updateGroup.status]);

  //on group detail remove user response handle and toast msg show
  //remove user data from the list loacally
  React.useEffect(() => {
    switch (removeUserFromGroupAPI.status) {
      case REMOVE_USER_FROM_GROUP.SUCCESS:
        const newUserListArray = groupUserListResult.filter(
          item => item.id !== selectedGroupUserId
        );
        _updateGroupUserListResult(newUserListArray);
        _setShowRemoveUserFromGroupModal();
        _ToastHandler(TOAST_TEXT.USER_SUCCESSFULLY_REMOVED, true);
        break;
      case REMOVE_USER_FROM_GROUP.FAILED:
        _setShowRemoveUserFromGroupModal();
        break;
      default:
        break;
    }
  }, [removeUserFromGroupAPI.status]);

  // Memoize the context values using useMemo
  const values = React.useMemo(
    (): IGroupManagementContext => ({
      openCreateGroupModal,
      selectUserListModal,
      groupProfileImages,
      userListResult,
      myGroupListResult,
      groupUserListResult,
      groupInListResult,
      groupInvitationsListResult,
      selectedUsers,
      isGroupEditMode,
      searchUserListText,
      openCloseProfileModal,
      openCloseGroupEditDeleteModal,
      showDeleteGroupModal,
      groupInvitationApprovedModal,
      selectedGroupInvitaions,
      groupDetailData,
      isUserComingFromMyGroup,
      selectedGroupUserId,
      groupInitialImage,
      showLeaveGroupModal,
      showRemoveUserFromGroupModal,
      selectedGroupId,
      createEditGroupModalData,
      tempGroupData,
      clickOnApprovedButton,
      groupMangementPullDownRefresh,
      initialLoader,
      setCreateEditGroupModalData: _setCreateEditGroupModalData,
      updateSelectedUserInGroup: _updateSelectedUserInGroup,
      setOpenCreateGroupModal: _setOpenCreateGroupModal,
      setOpenCloseProfileModal: _setOpenCloseProfileModal,
      setOpenCloseGroupEditDeleteModal: _setOpenCloseGroupEditDeleteModal,
      setShowDeleteGroupModal: _setShowDeleteGroupModal,
      setGroupInvitationApprovedModal: _setGroupInvitationApprovedModal,
      setSelectUserListModal: _setSelectUserListModal,
      setGroupProfileImages: _setGroupProfileImages,
      setUserListResult: _setUserListResult,
      setMyGroupListResult: _setMyGroupListResult,
      setGroupUserListResult: _setGroupUserListResult,
      setGroupInListResult: _setGroupInListResult,
      setGroupInvitationsListResult: _setGroupInvitationsListResult,
      removeProfileImage: _removeProfileImage,
      setIsGroupEditMode: _setIsGroupEditMode,
      setSearchUserListText: _setSearchUserListText,
      clearSearchUserListResult: _clearSearchUserListResult,
      selectOrUnSelectUser: _selectOrUnSelectUser,
      setSelectedGroupInvitaions: _setSelectedGroupInvitaions,
      setGroupDetailData: _setGroupDetailData,
      clearGroupUserListResult: _clearGroupUserListResult,
      setIsUserComingFromMyGroup: _setIsUserComingFromMyGroup,
      setSelectedGroupUserId: _setSelectedGroupUserId,
      updateGroupUserListResult: _updateGroupUserListResult,
      setGroupInitialImage: _setGroupInitialImage,
      setShowLeaveGroupModal: _setShowLeaveGroupModal,
      setShowRemoveUserFromGroupModal: _setShowRemoveUserFromGroupModal,
      setSelectedGroupId: _setSelectedGroupId,
      setTempGroupData: _setTempGroupData,
      setClickOnApprovedButton: _setClickOnApprovedButton,
      setGroupMangementPullDownRefresh: _setGroupMangementPullDownRefresh,
      setInitialLoader: _setInitialLoader,
    }),
    [
      showRemoveUserFromGroupModal,
      openCreateGroupModal,
      isGroupEditMode,
      openCloseProfileModal,
      showDeleteGroupModal,
      openCloseGroupEditDeleteModal,
      groupProfileImages,
      userListResult,
      myGroupListResult,
      groupUserListResult,
      groupInListResult,
      searchUserListText,
      selectUserListModal,
      groupInvitationApprovedModal,
      selectedGroupInvitaions,
      groupDetailData,
      isUserComingFromMyGroup,
      selectedGroupUserId,
      groupInitialImage,
      showLeaveGroupModal,
      selectedGroupId,
      createEditGroupModalData,
      tempGroupData,
      clickOnApprovedButton,
      groupInvitationsListResult,
      selectedUsers,
      groupMangementPullDownRefresh,
      initialLoader,
      setCreateEditGroupModalData,
      setShowRemoveUserFromGroupModal,
      setSearchUserListText,
      setOpenCreateGroupModal,
      setOpenCloseProfileModal,
      setOpenCloseGroupEditDeleteModal,
      setShowDeleteGroupModal,
      setGroupInvitationApprovedModal,
      setSelectUserListModal,
      setGroupProfileImages,
      _removeProfileImage,
      setUserListResult,
      selectOrUnSelectUser,
      setMyGroupListResult,
      setGroupUserListResult,
      setGroupInListResult,
      setGroupInvitationsListResult,
      setSelectedGroupInvitaions,
      setTempGroupData,
      setClickOnApprovedButton,
      setGroupMangementPullDownRefresh,
      setInitialLoader,
    ]
  );
  return (
    <GroupContext.Provider value={values}>
      {props.children}
    </GroupContext.Provider>
  );
};

export {GroupContext, CreateGroupProvider};
