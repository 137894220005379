import React from 'react';
import {BackHandler, Text, View} from 'react-native';
import {RouteProp, useIsFocused, useRoute} from '@react-navigation/native';
import TitleWithButton from '../../components/header/titleWithButton';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import {HomeContext} from '../../contextAPI/homeContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import {capitalizeFirstLetter} from '../../utils/helperFunctions';
import AllTopsResultList from './selectTopsList';
import {allTopsStyle} from '../seeAllTops/seeAll.styles';
import {RootStackParamList} from '../../types/navigationType';
import {FixedValue, TEXT} from '../../constants';
import PrimaryButton from '../../components/button/primaryButton';
import {TradeContext} from '../../contextAPI/tradeContext';
import useCreateTradeHooks from '../../hooks/componentHooks/useCreateTradeHooks';
import {goBack} from '../../services/navigationService';

let offerNextClicked = false;

const SelectTopsScreen = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const route = useRoute<RouteProp<RootStackParamList, 'SelectTopsForOffer'>>();
  const {styles, ids} = allTopsStyle(ColorTheme);
  const {seeAllScreenMode, setSearchUserListText} =
    React.useContext(HomeContext);
  const {collectionModel, showUncategorizedListId, fromTopToCreateTrade} =
    route.params;
  const {
    collectionSelectedTops,
    setTotalTop,
    totalTops,
    setCollectionSelectedTops,
    setCollectionSelectedTopsOther,
    setCounterOffer,
    setIsTopEdit,
    setFromProposeTrade,
    setCollectionModel,
  } = React.useContext(TradeContext);
  const isFocused = useIsFocused();
  const {navigateSelectTradeUser} = useCreateTradeHooks();

  const getHeaderName = () =>
    `${collectionModel.name} ${
      collectionModel.totalTopsCount
        ? `(${collectionModel.totalTopsCount})`
        : ''
    }`;

  const topsHeader: string | undefined = getHeaderName();

  const navigateScreen = () => {
    if (!offerNextClicked) {
      offerNextClicked = true;
      navigateSelectTradeUser();
    }
  };

  React.useEffect(() => {
    if (isFocused) {
      const totalCount = collectionSelectedTops.reduce(
        (total, values) => values.selectedQuantity! + total,
        FixedValue.CONSTANT_VALUE_0
      );
      setTotalTop(totalCount);
      // android back button handler.
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        onPressBack
      );
      setCollectionModel(collectionModel);
      offerNextClicked = false;
      return () => backHandler.remove();
    }
  }, [isFocused]);

  const resetTradeData = () => {
    setCollectionSelectedTops([]);
    setSearchUserListText('');
    setCollectionSelectedTopsOther([]);
    setCounterOffer(false);
    setIsTopEdit(false);
    setFromProposeTrade(false);
  };

  const onPressBack = () => {
    if (fromTopToCreateTrade) {
      resetTradeData();
    }
    goBack();
    return true;
  };

  return (
    <View style={styles.container} dataSet={{media: ids.container}}>
      <View style={styles.topHeader}>
        <TitleWithButton
          title={capitalizeFirstLetter(topsHeader ?? '')}
          onPress={onPressBack}
        />
      </View>
      <AllTopsResultList
        collectionModel={collectionModel}
        displayMode={seeAllScreenMode}
        showUncategorizedListId={showUncategorizedListId}
        headerValue={''}
      />
      {collectionSelectedTops.length > FixedValue.CONSTANT_VALUE_0 &&
        totalTops > FixedValue.CONSTANT_VALUE_0 && (
          <View style={styles.nextButtonView}>
            <PrimaryButton
              buttonLabel={TEXT.NEXT}
              element={
                <Text style={styles.nextButtonTextStyle}>
                  {`Total: ${totalTops}`}
                </Text>
              }
              buttonwidth={styles.nextButtonStyle}
              labelStyle={styles.nextButtonTextStyle}
              onPress={() => navigateScreen()}
            />
          </View>
        )}
    </View>
  );
};

const SelectTopsForOffer = (): JSX.Element => (
  <SafeAreaContainer>
    <SelectTopsScreen />
  </SafeAreaContainer>
);

export default React.memo(SelectTopsForOffer);
