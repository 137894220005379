import React from 'react';
import {View, Text, TextStyle, ViewStyle, Pressable} from 'react-native';
import {TabView, SceneMap, TabBarProps, Route} from 'react-native-tab-view';

import {FixedValue} from '../../constants';
import {
  ITabSceneMap,
  ITopTabBarProps,
} from '../../types/componentTypes/layoutType';
import {layoutStyle} from './layout.style';

const TopTabBarLayout = (props: ITopTabBarProps): JSX.Element => {
  const {tabArray, setCurrentActiveTab, customTabStyle, setTab} = props;
  const routesResult: ITabSceneMap[] = tabArray.map(tab => ({
    key: tab.routeName,
    title: tab.tabBarName,
  }));
  const CustomeSceneResult = tabArray.reduce(
    (obj, tab) => Object.assign(obj, {[tab.routeName]: tab.component}),
    {}
  );
  const [index, setIndex] = React.useState<number>(FixedValue.CONSTANT_VALUE_0);
  const [routes] = React.useState<ITabSceneMap[]>(routesResult);

  const renderScene = SceneMap(CustomeSceneResult);

  const setTabIndex = (index: number) => {
    setIndex(index);
    setTab && setTab(index);
  };

  React.useEffect((): void => {
    setIndex(prevIndex => setCurrentActiveTab ?? prevIndex);
  }, [setCurrentActiveTab]);

  // @ts-ignore
  const renderTabBar = ({navigationState}: TabBarProps): JSX.Element => {
    return (
      <View style={[layoutStyle.container, customTabStyle]}>
        {navigationState.routes.map((route: Route, tabIndex: number) => {
          const isFocused: boolean = index === tabIndex;
          const tabNameStyle: TextStyle = isFocused
            ? layoutStyle.activeTextStyle
            : layoutStyle.inActiveTextStyle;

          const btnStyle: ViewStyle = isFocused
            ? layoutStyle.activeTabStyle
            : layoutStyle.inActiveTabStyle;

          return (
            <Pressable
              onPress={() => setTabIndex(tabIndex)}
              key={`tab-label-${route.key}`}
              style={btnStyle}
            >
              <Text style={tabNameStyle}>{route.title}</Text>
            </Pressable>
          );
        })}
      </View>
    );
  };

  return (
    <TabView
      navigationState={{index, routes}}
      renderScene={renderScene}
      renderTabBar={renderTabBar}
      onIndexChange={setTabIndex}
      swipeEnabled={false}
      animationEnabled={false}
    />
  );
};

export default React.memo(TopTabBarLayout);
