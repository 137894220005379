import React from 'react';
import {View} from 'react-native';
import {SentOfferStyle} from './tradeSentOffer.style';
import {ThemeContext} from '../../contextAPI/themeContext';
import TradeSentofferList from './tradeSentofferList';
import CancelModal from '../../modals/cancleModal/cancelModal';

const SentOffer = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = SentOfferStyle(ColorTheme);

  return (
    <>
      <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
        <TradeSentofferList />
      </View>
      <CancelModal />
    </>
  );
};

export default React.memo(SentOffer);
