import React from 'react';
import {View, Text, Pressable} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {userListComponentStyle} from './style';
import {FixedValue, GlobalStyleValues, Percentage, TEXT} from '../../constants';
import {getInitials} from '../../utils/helperFunctions';
import {
  CustomUserListSearchModalListData,
  UserListComponent,
} from '../../types/reducerTypes/searchTabStoreType';
import SelectCheckBox from '../renderSvgs/selectCheckbox';
import UncheckSelectBox from '../renderSvgs/uncheckSelectBox';
import UnSelectCheckboxDisabled from '../renderSvgs/unSelectCheckboxDisabled';
import QunatitySelector from '../smallDesigns/qunatitySelector';
import ProfilePics from '../smallDesigns/profilePics';
import RightArrow from '../renderSvgs/rightArrow';
import {useAppSelector} from '../../hooks/reduxHooks';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import FavoriteButton from '../button/favouriteBtn';

const UserListComponentScreen = (props: UserListComponent): JSX.Element => {
  const {
    item,
    onSelectOrUnSelectUser,
    quantity,
    onUpdateQuantityTap,
    isArrow,
    onPressArrow,
    showInvitationPending,
    showLogInUser,
    icon,
    onPressFavAndUnFav,
    showFavourite = false,
    isInputQuantity,
    onChangeQuantity,
    checkQuantityOnOnChange = false,
    textInputSupportDarkTheme = false,
    showSelelctIcon = false,
    onSelectOrUnselelctUser,
  } = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const styles = userListComponentStyle(ColorTheme);
  const fullName = item?.firstName + ' ' + item?.lastName ?? '';
  const {userData} = useAppSelector(userProfileStore);
  const UserDetail = userData?.success;
  const showPendingText =
    showInvitationPending && item?.request === TEXT.PENDING_IN_CAPS;
  const isLoggedInUser = UserDetail?.id === item?.id;
  const checkCurrentUser = (id: number) => {
    return UserDetail?.id === id && !showLogInUser;
  };

  const getNameWidth = () => {
    if (quantity) {
      return Percentage.PRECENTAGE_80;
    } else if (showPendingText) {
      return Percentage.PRECENTAGE_85;
    } else {
      return Percentage.PRECENTAGE_77;
    }
  };

  const getViewWidth = () => {
    if (quantity) {
      return Percentage.PRECENTAGE_70;
    } else if (showPendingText) {
      return Percentage.PRECENTAGE_60;
    } else {
      return Percentage.PRECENTAGE_90;
    }
  };

  const getCheckBoxImage = () => {
    if (item?.alreadyPresentUser) {
      return <SelectCheckBox />;
    } else if (showPendingText) {
      return <UnSelectCheckboxDisabled />;
    } else {
      return (
        <UncheckSelectBox
          stroke={ColorTheme.color}
          height={FixedValue.CONSTANT_VALUE_20}
          width={FixedValue.CONSTANT_VALUE_20}
        />
      );
    }
  };

  return (
    <Pressable
      style={styles.userListStyle}
      onPress={() => !quantity && isArrow && onPressArrow && onPressArrow()}
    >
      <View style={[styles.inRow, {width: getViewWidth()}]}>
        <ProfilePics
          image={item?.profileImage ?? ''}
          text={getInitials(fullName)}
          mainViewStyle={styles.mainViewStyle}
          initialsTextStyle={styles.profileNameText}
        />
        <View
          style={[
            styles.columnStyle,
            (quantity || showPendingText) && {width: getNameWidth()},
          ]}
        >
          <Text
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
            style={styles.userNameText}
          >
            {item?.firstName} {item?.lastName}
          </Text>
          <Text
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
            style={[styles.userNameTextStyle, {width: getNameWidth()}]}
          >
            {item?.username}
          </Text>
        </View>
      </View>
      <View
        style={[
          styles.inRow,
          {
            justifyContent: GlobalStyleValues.FLEX_END,
          },
        ]}
      >
        {showFavourite && !isLoggedInUser && (
          <FavoriteButton
            isFavorite={
              item?.isFavorite !== undefined
                ? item.isFavorite
                : item?.favorite ?? false
            }
            onFavOrUnFavUser={onPressFavAndUnFav}
          />
        )}
        {showPendingText && (
          <View style={styles.pendingStyleView}>
            <Text
              style={styles.pendingTextStyle}
              numberOfLines={FixedValue.CONSTANT_VALUE_1}
            >
              {TEXT.PENDING_IN_SMALL}
            </Text>
          </View>
        )}
        {quantity && !checkCurrentUser(item.id) && (
          <QunatitySelector
            count={
              (item as CustomUserListSearchModalListData).quantity ??
              FixedValue.CONSTANT_VALUE_0
            }
            onMinusPress={() =>
              onUpdateQuantityTap && onUpdateQuantityTap(TEXT.MINNUS)
            }
            onPlusPress={() =>
              onUpdateQuantityTap && onUpdateQuantityTap(TEXT.ADD)
            }
            qunatityTextStyle={
              item?.alreadyPresentUser ? undefined : styles.disableText
            }
            quantityStyle={styles.quantitySelectorViewStyle}
            isInputQuantity={isInputQuantity}
            onChangeQuantity={onChangeQuantity}
            checkQuantityOnOnChange={checkQuantityOnOnChange}
            textInputSupportDarkTheme={textInputSupportDarkTheme}
          />
        )}
        {!quantity && (
          <View style={styles.checkboxView}>
            {icon && (
              <Pressable
                style={styles.right20}
                onPress={() => onSelectOrUnSelectUser(item)}
              >
                {icon}
              </Pressable>
            )}
            {isArrow && !icon && (
              <RightArrow
                stroke={ColorTheme.color}
                height={FixedValue.CONSTANT_VALUE_20}
                width={FixedValue.CONSTANT_VALUE_20}
              />
            )}
            {!isArrow && !checkCurrentUser(item.id) && (
              <Pressable
                onPress={() => !showPendingText && onSelectOrUnSelectUser(item)}
              >
                {getCheckBoxImage()}
              </Pressable>
            )}
          </View>
        )}
        {!checkCurrentUser(item.id) && showSelelctIcon && (
          <Pressable
            style={[styles.selectOrUnselectView]}
            onPress={onSelectOrUnselelctUser}
          >
            {item?.alreadyPresentUser ? (
              <SelectCheckBox />
            ) : (
              <UncheckSelectBox stroke={ColorTheme.disabledTextColor} />
            )}
          </Pressable>
        )}
        {quantity && checkCurrentUser(item.id) && (
          <Text style={styles.youText}>{'(You)'}</Text>
        )}
      </View>
    </Pressable>
  );
};

export default React.memo(UserListComponentScreen);
