import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const CloseRed = (props: SvgProps) => (
  <Svg
    width={13}
    height={12}
    viewBox="0 0 13 12"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M9.5 3L3.5 9"
      stroke="#F54336"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M3.5 3L9.5 9"
      stroke="#F54336"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default CloseRed;
