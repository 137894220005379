import {StyleSheet} from 'react-native';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  GlobalStyleValues,
} from '../../../constants';
import {scaleWidth} from '../../../utils/responsive';
import {IColorTheme} from '../../../types/contextTypes/themeContext';

export const styles = (theme: IColorTheme) =>
  StyleSheet.create({
    imageView: {
      ...BASESTYLE.inCenter,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_100),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_100),
      backgroundColor: COLORS.E9EEFB,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
    },
    imageView2: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_100),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_100),
      backgroundColor: COLORS.E9EEFB,
      borderRadius: FixedValue.CONSTANT_VALUE_6,
      overflow: GlobalStyleValues.HIDDEN,
    },
    marginRight8: {
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_8),
    },
    imageContainer: {
      ...BASESTYLE.flex1,
      backgroundColor: COLORS.E9EEFB,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      overflow: GlobalStyleValues.HIDDEN,
    },
    imageLoader: {
      ...BASESTYLE.center,
      ...BASESTYLE.alignCenter,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_100),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_100),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_6),
      backgroundColor: COLORS.E9EEFB,
      overflow: GlobalStyleValues.HIDDEN,
    },
  });
