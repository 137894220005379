import {StyleSheet} from 'react-native';
import {
  BASESTYLE,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../../constants';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const styles = (theme: IColorTheme) =>
  StyleSheet.create({
    mainView: {
      width: Percentage.PRECENTAGE_100,
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    imageView: {
      ...BASESTYLE.inRow,
    },
    imageStyle: {
      ...BASESTYLE.inCenter,
      height: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      overflow: GlobalStyleValues.HIDDEN,
    },
    listname: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme?.color,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_230),
    },
    crossIconView: {
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_18),
    },
    profileNameText: {
      textAlign: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme?.backgroundColor,
    },
    backgroundColorPlaceholder: {
      ...BASESTYLE.inCenter,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      overflow: GlobalStyleValues.HIDDEN,
      backgroundColor: theme?.color,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
    },
  });
