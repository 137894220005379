import * as React from 'react';
import Svg, {SvgProps, Line} from 'react-native-svg';
const ModalCloseLine = (props: SvgProps) => (
  <Svg
    width={44}
    height={2}
    viewBox="0 0 44 2"
    fill="none"
    //@ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Line
      opacity={0.3}
      x1={1}
      y1={1}
      x2={43}
      y2={1}
      stroke={props?.stroke ? props.stroke : 'black'}
      strokeWidth={2}
      strokeLinecap="round"
    />
  </Svg>
);
export default ModalCloseLine;
