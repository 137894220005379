import React from 'react';
import {FlatList, ScrollView, View} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';

import TopsEmptyList from '../../components/emptyListView/topsListEmptyView';
import ImageCard from '../../components/mantelTopTabComp/imageCard';
import {BASESTYLE, FixedValue, TEXT} from '../../constants';
import {TopType} from '../../constants/enum';
import {HomeContext} from '../../contextAPI/homeContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import usePurchasedTopsHook from '../../hooks/componentHooks/usePurchasedTopsHooks';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import EditTopModal from '../../modals/editTopModal';
import {HomeStore} from '../../redux/reducers/homeSlice';
import {purchasedTopsStyle} from './purchasedTops.style';
import {updateOpenForEditValue} from '../../redux/reducers/editorSlice';
import {useIsFocused} from '@react-navigation/native';

const PurchasedTopsResultList = (): JSX.Element => {
  const {purchasedTopLists} = useAppSelector(HomeStore);
  const dispatch = useAppDispatch();
  const {
    purchasedTops,
    homeScreenUserData,
    isRefreshing,
    isProfileSwitchToChild,
    setOpenEditTopModal,
    setIsRefreshing,
    setSelectedTopData,
    initialLoader,
  } = React.useContext(HomeContext);
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = purchasedTopsStyle(ColorTheme);
  const {onReachedEnd, callPurchasedTopsListAPI, navigateToDetailScreen} =
    usePurchasedTopsHook();
  const isFocused = useIsFocused();

  const handleRefresh = React.useCallback(() => {
    setIsRefreshing(true);
    callPurchasedTopsListAPI(FixedValue.CONSTANT_VALUE_0);
  }, [homeScreenUserData]);

  const RenderList = React.useMemo((): JSX.Element | null => {
    if (purchasedTops.length > FixedValue.CONSTANT_VALUE_0) {
      return (
        <FlatList
          numColumns={FixedValue.CONSTANT_VALUE_2}
          data={purchasedTops}
          keyExtractor={item => `TopNumber-${item.id}`}
          onEndReached={onReachedEnd}
          onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
          showsVerticalScrollIndicator={false}
          style={BASESTYLE.flex1}
          refreshControl={
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={handleRefresh}
            />
          }
          renderItem={({item}) => (
            <ImageCard
              key={item.id}
              {...item}
              isProfileSwitchToChild={isProfileSwitchToChild}
              onMenuPress={() => {
                dispatch(updateOpenForEditValue(false));
                setSelectedTopData(item);
                setOpenEditTopModal();
              }}
              type={TopType.purchased}
              onShowDetailPress={() =>
                navigateToDetailScreen(item, TopType.purchased)
              }
            />
          )}
        />
      );
    }
    return null;
  }, [purchasedTops, isRefreshing]);

  const RenderEmptyUI = React.useMemo((): JSX.Element | null => {
    if (purchasedTops.length === FixedValue.CONSTANT_VALUE_0) {
      return (
        <ScrollView
          style={BASESTYLE.flex1}
          contentContainerStyle={BASESTYLE.flex1}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={handleRefresh}
            />
          }
        >
          <TopsEmptyList
            emptyViewTitle={TEXT.YOU_HAVE_NO_TOPS_INPURCHASED}
            emptyViewDesc={isProfileSwitchToChild ? '' : TEXT.OPTIONS_TO_CREATE_TOP}          />
        </ScrollView>
      );
    }
    return null;
  }, [purchasedTops, isRefreshing,isProfileSwitchToChild]);

  return (
    <View style={styles.contentContainer}>
      {!purchasedTopLists.isLoading && !initialLoader && RenderEmptyUI}
      {RenderList}
      {isFocused && <EditTopModal />}
    </View>
  );
};

export default React.memo(PurchasedTopsResultList);
