import React from 'react';
import {ERROR_MESSAGES, FixedValue} from '../../constants';
import {CreateCollectionContext} from '../../contextAPI/createCollectionContext';
import {
  onCreateCollectionStart,
  onUpdateCollectionStart,
} from '../../redux/reducers/collectionSlice';
import {useFormik} from 'formik';
import {useAppDispatch} from '../reduxHooks';
import {CreateCollectionSchema} from '../../utils/formikSchema';
import {_ToastHandler} from '../../utils/helperFunctions';

export type CollectionField = {CollectionName: string};

const useCreateCollectionHooks = () => {
  const dispatch = useAppDispatch();
  const {setOpenCreateCollectionModal, collectionData} = React.useContext(
    CreateCollectionContext
  );

  // Set the initial values with an object containing CollectionName property
  const [initialValues, setInitialValues] = React.useState<CollectionField>({
    CollectionName: '',
  });

  // Add a state variable for the disabled state of the button
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true); // Update initial value of isButtonDisabled

  // Add a state variable for the Add/Edit Mode state of collection
  const [isEditMode, setIsEditMode] = React.useState(false); // Update initial value of isButtonDisabled

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
    isValid,
  } = useFormik<CollectionField>({
    validationSchema: CreateCollectionSchema,
    initialErrors: {
      CollectionName: ERROR_MESSAGES.REQUIRED_COLLECTION_NAME,
    },
    initialTouched: {
      CollectionName: false,
    },
    initialValues: {
      CollectionName: '',
    },
    onSubmit: val => {
      isEditMode ? callUpdateCollectionApi(val) : callCreateNowApi(val);
    },
  });

  const CollectionNameError: string =
    touched.CollectionName && errors.CollectionName
      ? errors.CollectionName
      : '';

  const callUpdateCollectionApi = React.useCallback(
    (value: CollectionField) => {
      const data = {
        name: value.CollectionName.trim(),
        id: collectionData?.id,
      };
      setOpenCreateCollectionModal();
      dispatch(onUpdateCollectionStart(data));
    },
    [dispatch]
  );

  const callCreateNowApi = React.useCallback(
    (value: CollectionField) => {
      const data = {
        name: value.CollectionName.trim(),
      };
      setOpenCreateCollectionModal();
      dispatch(onCreateCollectionStart(data));
    },
    [dispatch, setOpenCreateCollectionModal]
  );

  React.useEffect(() => {
    // Disable the button if the form is not valid
    setIsButtonDisabled(!isValid);
  }, [isValid]);

  React.useEffect(() => {
    if (collectionData) {
      // Update the initial values when collectionData changes
      setInitialValues({CollectionName: collectionData.name ?? ''});
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [collectionData]);

  React.useEffect(() => {
    if (values.CollectionName.trim().length > FixedValue.CONSTANT_VALUE_02) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [values.CollectionName]);

  React.useEffect(() => {
    if (collectionData) {
      // Prefill the CollectionName field when there is collectionData
      setFieldValue('CollectionName', collectionData.name);
    } else {
      // Clear the CollectionName field when there is no collectionData
      setFieldValue('CollectionName', '');
    }
  }, [collectionData, setFieldValue]);

  return {
    handleChange,
    handleSubmit,
    handleBlur,
    isValid,
    values,
    setFieldValue,
    callCreateNowApi,
    CollectionNameError,
    isButtonDisabled,
    isEditMode,
  };
};

export default useCreateCollectionHooks;
