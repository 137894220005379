import React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';
const ThreeDots = (props: SvgProps) => (
  <Svg
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    width={4}
    height={14}
    viewBox="0 0 4 14"
    fill="none"
    {...props}
  >
    <Path
      d="M2 7.625C2.34518 7.625 2.625 7.34518 2.625 7C2.625 6.65482 2.34518 6.375 2 6.375C1.65482 6.375 1.375 6.65482 1.375 7C1.375 7.34518 1.65482 7.625 2 7.625Z"
      fill={props.stroke ?? 'black'}
      stroke={props.stroke ?? 'black'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M2 13.0415C2.34518 13.0415 2.625 12.7617 2.625 12.4165C2.625 12.0713 2.34518 11.7915 2 11.7915C1.65482 11.7915 1.375 12.0713 1.375 12.4165C1.375 12.7617 1.65482 13.0415 2 13.0415Z"
      fill={props.stroke ?? 'black'}
      stroke={props.stroke ?? 'black'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M2 2.20825C2.34518 2.20825 2.625 1.92843 2.625 1.58325C2.625 1.23807 2.34518 0.958252 2 0.958252C1.65482 0.958252 1.375 1.23807 1.375 1.58325C1.375 1.92843 1.65482 2.20825 2 2.20825Z"
      fill={props.stroke ?? 'black'}
      stroke={props.stroke ?? 'black'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default ThreeDots;
