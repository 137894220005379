import { Dimensions, PixelRatio, Platform } from 'react-native';
import { FixedValue } from '../constants';

export const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } =
  Dimensions.get('window');

// based on iphone 6 scale
const ScaleHeight = SCREEN_HEIGHT / FixedValue.CONSTANT_VALUE_812;
const ScaleWidth = SCREEN_WIDTH / FixedValue.CONSTANT_VALUE_375;

export const isWebsite = (): boolean => Platform.OS === 'web';

export const normalizeFont = (size: number) => {
  if (isWebsite()) {
    return size;
  }
  const newSize = size * ScaleWidth;
  if (Platform.OS === 'ios') {
    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  } else {
    return (
      Math.round(PixelRatio.roundToNearestPixel(newSize)) -
      FixedValue.CONSTANT_VALUE_2
    );
  }
};

export const scaleHeight = (height: number) => {
  if (isWebsite()) {
    return height;
  }
  return Math.round(height * ScaleHeight);
};

export const scaleWidth = (width: number) => {
  if (isWebsite()) {
    return width;
  }
  return Math.round(width * ScaleWidth);
};
