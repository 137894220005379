import React from 'react';
import {FixedValue} from '../constants';
import {ImageRefType, UserImageType} from '../types/componentTypes/editorType';
import {
  IEditorImageContext,
  IEditorProvider,
  ImageController,
} from '../types/contextTypes/editorContext';

const EditorImageContext = React.createContext<IEditorImageContext>({
  userImages: [],
  setUserImages: _el => {},
  allImages: [],
  setAllImages: _val => {},
  allImageSize: FixedValue.CONSTANT_VALUE_0,
  setAllImageSize: _index => {},
  onSelectImage: null,
  setOnSelectImage: _value => {},
  markImageAsSelected: _value => {},
  markImageAsUnSelected: _value => {},
  selectedController: null,
  setSelectedController: _value => {},
  clearAllSelectedImages: () => {},
  resetEditorImageContext: () => {},
});

const EditorImageProvider = (props: IEditorProvider): JSX.Element => {
  const [userImages, setUserImages] = React.useState<UserImageType[]>([]);
  const [allImageSize, setAllImageSize] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_0
  );
  const [allImages, setAllImages] = React.useState<UserImageType[]>(userImages);
  const [onSelectImage, setOnSelectImage] =
    React.useState<React.RefObject<ImageRefType> | null>(null);
  const [selectedController, setSelectedController] =
    React.useState<ImageController | null>(null);

  const _setUserImages = React.useCallback(
    (value: UserImageType[]): void => {
      setUserImages((prev: UserImageType[]) => [...prev, ...value]);
    },
    [userImages]
  );

  const _setAllImages = React.useCallback(
    (value: UserImageType[]): void => {
      setAllImages((prev: UserImageType[]) => [...prev, ...value]);
    },
    [allImages]
  );

  const _setAllImageSize = React.useCallback(
    (size: number): void => {
      setAllImageSize(size);
    },
    [allImageSize]
  );

  const _setOnSelectImage = React.useCallback(
    (value: React.RefObject<ImageRefType> | null): void => {
      setOnSelectImage(value);
    },
    [allImageSize]
  );

  const _markImageAsSelected = React.useCallback(
    (index: number): void => {
      if (userImages.length > FixedValue.CONSTANT_VALUE_0) {
        const result = [...userImages];
        result[index].isSelected = true;
        setUserImages(result);
      }
    },
    [userImages]
  );

  const _markImageAsUnSelected = React.useCallback(
    (index: number): void => {
      if (userImages.length > FixedValue.CONSTANT_VALUE_0) {
        const result = [...userImages];
        result[index].isSelected = false;
        setUserImages(result);
      }
    },
    [userImages]
  );

  const _clearAllSelectedImages = React.useCallback((): void => {
    const result = userImages.map(image => ({...image, isSelected: false}));
    setUserImages(result);
  }, [userImages]);

  const _setSelectedController = React.useCallback(
    (value: ImageController | null): void => {
      setSelectedController(value);
    },
    [selectedController]
  );

  const _resetEditorImageContext = React.useCallback((): void => {
    setUserImages([]);
    setAllImageSize(FixedValue.CONSTANT_VALUE_0);
    setAllImages([]);
    setOnSelectImage(null);
    setSelectedController(null);
  }, []);

  const values = React.useMemo(
    (): IEditorImageContext => ({
      userImages,
      allImageSize,
      allImages,
      onSelectImage,
      selectedController,
      setOnSelectImage: _setOnSelectImage,
      setUserImages: _setUserImages,
      setAllImageSize: _setAllImageSize,
      setAllImages: _setAllImages,
      markImageAsSelected: _markImageAsSelected,
      markImageAsUnSelected: _markImageAsUnSelected,
      setSelectedController: _setSelectedController,
      clearAllSelectedImages: _clearAllSelectedImages,
      resetEditorImageContext: _resetEditorImageContext,
    }),
    [
      userImages,
      allImageSize,
      onSelectImage,
      selectedController,
      setUserImages,
      setAllImageSize,
      setOnSelectImage,
      setSelectedController,
    ]
  );

  return (
    <EditorImageContext.Provider value={values}>
      {props.children}
    </EditorImageContext.Provider>
  );
};

export {EditorImageContext, EditorImageProvider};
