export const ERROR_MESSAGES = {
  REQUIRED_USERNAME: 'Username is required',
  REQUIRED_PARENT_USERNAME: 'Username is required',
  REQUIRED_FIRST_NAME: 'First name is required',
  REQUIRED_LAST_NAME: 'Last name is required',
  REQUIRED_EMAIL: 'Email is required',
  REQUIRED_PASSWORD: 'Password is required',
  REQUIRED_NEW_PASSWORD: 'New Password is required',
  REQUIRED_CONFIRM_PASSWORD: 'Confirm New Password is required',
  INVALID_PASSWORD_: 'Enter valid password',
  INVALID_PASSWORD:
    'Password must contain at least 8 characters, 1 uppercase, 1 lowercase and 1 number',
  ONE_CHARACTER: 'Enter minimum 1 characters',
  THREE_CHARACTER: 'Please enter a minimum of 3 characters',
  PLEASE_ENTER_THREE_CHARACTER: 'Please enter a minimum of 3 characters',
  EIGHT_CHARACTER: 'Please enter a minimum of 8 characters',
  INVALID_EMAIL: 'Please enter a valid email address',
  INVALID_MOBILE: 'Please enter a valid mobile number',
  INVALID_OTP: 'Enter valid verification code',
  PWD_NOT_MATCH: 'New Password and Confirm New Password do not match',
  ALLOW_PERMISSION:
    'Sorry, We need camera roll permissions in order to import your images',
  FILE_TOO_LARGE:
    'Selected image size is too large. Please select another image.',
  VALID_DATE: 'Must be a valid date with format MM/DD/YYYY',
  REQUIRED_THIS: 'This is required',
  DELETE_SELECTED_ITEM: 'Select the item first',
  USERNAME_ALREADY_EXIST: 'Username already exists',
  USERNAME_DOESNOT_EXIST: 'Username does not exist',
  REQUIRED_CURRENT_PASSWORD: 'Current Password is required',
  INVALID_USERNAME: 'Please enter a valid username',
  INVALID_VERIFICATIONCODE:
    'Invalid verification code entered. Please try again.',
  REQUIRED_TOP_NAME: 'Top Name is a required field',
  REQUIRED_ADD_HASHTAG: 'HashTag is a required field',
  REQUIRED_NOTE: 'Note is a required field',
  INVAID_HASHTAG: '# is missing in the text',
  NO_INTERNET: 'No internet',
  REQUIRED_PHONE: 'Mobile number is required',
  PARENTS_USERNAME_NOT_FOUND: 'Parent/Guardian’s Username not found',
  INVALID_CREDENTIALS:
    'Invalid credentials entered. Please try again with valid credentials.',
  REQUIRED_COLLECTION_NAME: 'Collection Name is a required field',
  USER_NOT_CONFIRM: 'User is not confirmed.',
  USER_NOT_VERIFIED: 'User is not Verified.',
  APPROVAL_PENDING: 'Parent approval pending',
  ACCESS_TOKEN_NOT: 'Access token not found',
  TOKEN_EXPIRE: 'Token expired, try login again',
  REQUIRED_GROUP_NAME: 'Group Name is a required field',
  INVALID_CHARACTER_FOUND: 'invalid character found',
  REQUIRED_DOB: 'Date of Birth is required',
  INVALID_FORMAT_KEY: 'isValidFormat',
  VALID_DOB_FORMAT: 'Date format should be "eg. January 1, 2023"',
  INVALID_DATE_KEY: 'isValidDate',
  INVALID_DATE: 'Date of Birth is Invalid',
  MAX_DATE_KEY: 'maxDate',
  MAX_DATE_ERROR: 'Date of Birth cannot be in the future',
  ACTION_NOT_PERFORM: 'Parent permission required.',
  ACCOUNT_DELETED: 'Account deleted successfully.',
  REQUIRED_COUPON_CODE: 'Coupon Code is a required field',
  INVALID_COUPON_CODE: 'Please enter a valid Coupon Code',
  INVALID_REEDEM_CODE: 'Please enter a valid Reedem Code',
  REQUIRED: 'Required',
  DUPLICATE_USERNAME: 'ParentUsername2 cannot be the same as ParentUsername',
  ONLY_NUMBERS_AND_TWO_DECIMALS: 'Only numbers and up to two decimal places are allowed',
  NO_SPACES: 'Spaces are not allowed',
};
