import React from 'react';
import {View, Text, Pressable} from 'react-native';
import moment from 'moment-timezone';

import {TEXT} from '../../../constants';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {TopModel} from '../../../types/reducerTypes/searchTabStoreType';
import {styles} from './styles';
import {timeformat, TopType} from '../../../constants/enum';
import useSearchTabHooks from '../../../hooks/componentHooks/useSearchTabHooks';
import useAwsS3 from '../../../hooks/libraryHooks/useAwsS3';
import {useAppSelector} from '../../../hooks/reduxHooks';
import {userProfileStore} from '../../../redux/reducers/userProfileSlice';
import {getTopImage} from '../../../utils/imageUtils';

const SearchListTile = (props: TopModel): JSX.Element => {
  const {user, name, creationDate, image} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  const {loadSignedUrl} = useAwsS3();
  const {navigateToDetailScreen} = useSearchTabHooks();
  const {userData} = useAppSelector(userProfileStore);
  const UserDetail = userData?.success;
  const [svgCode, setSvgCode] = React.useState<JSX.Element | undefined>(
    undefined
  );

  const getSvgUrl = React.useCallback(async (): Promise<void> => {
    const imageUrl = await loadSignedUrl(image ?? '');
    if (imageUrl) {
      const topImage = await getTopImage(imageUrl ?? '');
      setSvgCode(topImage);
    }
  }, [image, svgCode]);

  React.useEffect(() => {
    getSvgUrl();
  }, [image]);

  const getTopOwner = () => {
    if (user?.id === UserDetail?.id) {
      return TopType.collections;
    } else {
      return TopType.otherUserTop;
    }
  };
  return (
    <Pressable
      onPress={() => navigateToDetailScreen(props, getTopOwner())}
      style={style.container}
    >
      <View style={style.imageView}>{svgCode}</View>
      <View>
        <Text style={style.topTitleText}>{name}</Text>
        <Text style={style.createTopText}>
          {TEXT.CREATED_ON} {moment(creationDate).format(timeformat.MMMDDYY)}
        </Text>
      </View>
    </Pressable>
  );
};

export default React.memo(SearchListTile);
