import React from 'react';
import {View, Text, Pressable} from 'react-native';
import {FixedValue} from '../../../constants';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {styles} from './styles';
import ProfilePics from '../../smallDesigns/profilePics';
import CircleBlack from '../../renderSvgs/circleBlack';
import {getInitials} from '../../../utils/helperFunctions';
import {ITradeApproveListTile} from '../../../types/reducerTypes/childProfileTypes';

const TradeApproveTile = (props: ITradeApproveListTile): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  const {item, onPressUserRemoveFunction} = props;
  const fullName = item?.firstName + ' ' + item?.lastName ?? '';

  return (
    <View style={style.mainView}>
      <View style={style.imageView}>
        <ProfilePics
          image={item?.profileImage ?? ''}
          text={getInitials(fullName)}
          mainViewStyle={style.imageStyle}
          initialsTextStyle={style.profileNameText}
          initialsTextViewStyle={style.backgroundColorPlaceholder}
        />
        <Text
          style={style.listname}
          numberOfLines={FixedValue.CONSTANT_VALUE_1}
        >
          {item?.firstName} {item?.lastName}
        </Text>
        <Pressable
          style={style.crossIconView}
          onPress={() => onPressUserRemoveFunction(item?.id)}
        >
          <CircleBlack
            stroke={ColorTheme.color}
            height={FixedValue.CONSTANT_VALUE_20}
            width={FixedValue.CONSTANT_VALUE_20}
          />
        </Pressable>
      </View>
    </View>
  );
};

export default React.memo(TradeApproveTile);
