import React from 'react';
import {View} from 'react-native';
import ModalContainer from '../../components/layout/modalContainer';
import {ThemeContext} from '../../contextAPI/themeContext';
import {style} from './style';
import ApprovedRejectCustomModalContent from './approvedRejectCustomModalContent';
import {CustomApprovedRejectModalProps} from '../../types/reducerTypes/groupMangementTypes';

const ApprovedRejectCustomModal = (
  props: CustomApprovedRejectModalProps
): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = style(ColorTheme);
  const {
    modalVisible,
    onClose,
    actionType,
    callGroupInvitationsApproveRejectAPI,
  } = props;
  return (
    <ModalContainer visible={modalVisible} onPressOutside={onClose}>
      <View style={styles.subContainer}>
        <ApprovedRejectCustomModalContent
          actionType={actionType}
          onClose={onClose}
          callGroupInvitationsApproveRejectAPI={
            callGroupInvitationsApproveRejectAPI
          }
        />
      </View>
    </ModalContainer>
  );
};

export default ApprovedRejectCustomModal;
