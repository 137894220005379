import React from 'react';
import {View, Pressable} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {navigate} from '../../services/navigationService';
import {ROUTE_NAME, TEXT} from '../../constants';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import Settings from '../../components/renderSvgs/settings';
import {DistributingTopStyle} from './distributingTop.style';
import DistributingTopContent from '../distributingTopRequest/distrinutingTopContent';
import TopBarWithRightButton from '../../components/header/topBarWithRightButton';

const DistributingTop = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = DistributingTopStyle(ColorTheme);
  const onPress = React.useCallback((): void => {
    navigate(ROUTE_NAME.DISTRIBUTING_SETTING);
  }, []);
  return (
    <SafeAreaContainer>
      <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
        <TopBarWithRightButton
          headerLabel={TEXT.DISTRIBUTING_TOP_REQUEST}
          Icon={
            <Pressable onPress={onPress}>
              <Settings stroke={ColorTheme.color} />
            </Pressable>
          }
          headerColor={ColorTheme.color}
        />
        <DistributingTopContent />
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(DistributingTop);
