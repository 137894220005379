import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const topDetailStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.flex1,
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    outterContainer: {
      ...BASESTYLE.center,
      marginBottom: FixedValue.CONSTANT_VALUE_40,
    },
    imageStyle: {
      ...BASESTYLE.selfCenter,
      height: scaleWidth(FixedValue.CONSTANT_VALUE_334),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_334),
      borderWidth: FixedValue.CONSTANT_VALUE_1,
      borderColor: COLORS.BLACK,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_24),
      overflow: GlobalStyleValues.HIDDEN,
      backgroundColor: COLORS.WHITE,
    },
    topInfoView: {
      ...BASESTYLE.spaceBetween,
      ...BASESTYLE.row,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_16),
      width: Percentage.PRECENTAGE_100,
    },
    infoContainer: {
      ...BASESTYLE.flex1,
      justifyContent: GlobalStyleValues.FLEX_START,
      alignItems: GlobalStyleValues.FLEX_START,
    },
    labelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_17),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
    userName: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: _theme?.color,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    topName: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: _theme?.color,
    },
    createBy: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: _theme?.color,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_14),
    },
    createDate: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SFUIText_LightItalic,
      color: _theme?.color,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_14),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_5),
    },
    createdByName: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: _theme?.color,
    },
    fontfamilyMedium: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SFUIText_LightItalic,
      color: _theme?.color,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_3),
    },
    noteHeading: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: _theme?.color,
    },
    notes: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: _theme?.color,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_8),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    buttonView: {
      ...BASESTYLE.row,
      ...BASESTYLE.spaceBetween,
      width: Percentage.PRECENTAGE_100,
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    noButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_17),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
    unTradeableButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_17),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
    distributeButtonView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: Percentage.PRECENTAGE_48,
      borderRadius: FixedValue.CONSTANT_VALUE_100,
    },
    buttonBackGroundColor: {
      backgroundColor: COLORS.E9EEFB,
    },
    nonTradeableButton: {
      backgroundColor: COLORS.F54336,
    },
    proposeButtonBackGroundColor: {
      backgroundColor: COLORS.PRIMARY_BLUE,
    },
    quantityButton: {
      ...BASESTYLE.center,
      backgroundColor: COLORS._F8CC02,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_17),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_35),
      paddingHorizontal: scaleHeight(FixedValue.CONSTANT_VALUE_15),
    },
    quanitiyButtonText: {
      color: COLORS.BLACK,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    proposeButtonView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: Percentage.PRECENTAGE_100,
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.center,
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    proposeButton: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_265),
      borderRadius: FixedValue.CONSTANT_VALUE_100,
    },
    buyAgainButton: {
      backgroundColor: COLORS._4EAF51,
    },
    buyNowButton: {
      ...BASESTYLE.absolutePosition,
      ...BASESTYLE.selfCenter,
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.center,
      backgroundColor: COLORS._4EAF51,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_25),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: Percentage.PRECENTAGE_100,
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    iconView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_44),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_44),
      backgroundColor: _theme?.backgroundColor,
    },
    tradeView: {
      ...BASESTYLE.inCenter,
      marginBottom: FixedValue.CONSTANT_VALUE_10,
    },
    distributeButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_17),
      color: COLORS.PRIMARY_BLUE,
    },
    topNameQuanityView: {
      ...BASESTYLE.row,
      justifyContent: GlobalStyleValues.SPACE_BETWEEN,
      width: Percentage.PRECENTAGE_100,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    flexDirectionColumn: {
      flexDirection: GlobalStyleValues.COLUMN,
    },
    nameImageContainer: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_11),
      ...BASESTYLE.row,
      justifyContent: GlobalStyleValues.SPACE_BETWEEN,
      width: Percentage.PRECENTAGE_100,
    },
    flexDirectionRow: {
      flexDirection: GlobalStyleValues.ROW,
    },
    profileImageView: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_34),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_34),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      backgroundColor: _theme?.color,
    },
    initialsText: {
      justifyContent: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      color: _theme?.backgroundColor,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      textTransform: GlobalStyleValues.UPPERCASE,
    },
    nameAndArrow: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_3),
      alignItems: GlobalStyleValues.CENTER,
    },
    maxWidth85: {
      maxWidth: Percentage.PRECENTAGE_100,
    },
    heartContainer: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_46),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_46),
      backgroundColor: COLORS.E9EEFB,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_46),
      justifyContent: GlobalStyleValues.CENTER,
      alignItems: GlobalStyleValues.CENTER,
    },
    countCountainer: {
      justifyContent: GlobalStyleValues.CENTER,
      flexDirection: GlobalStyleValues.COLUMN,
      width: Percentage.PRECENTAGE_65,
    },
    quanitytext: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_18),
      color: _theme?.color,
      fontFamily: FONT_NAMES.SF_LIGHT,
    },
    textColorPrimary: {
      color: COLORS.PRIMARY_BLUE,
    },
    marginTop6: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_6),
    },
    notesHeaderContainer: {
      justifyContent: GlobalStyleValues.SPACE_BETWEEN,
      flexDirection: GlobalStyleValues.ROW,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_5),
    },
    iconWrapper: {
      alignItems: GlobalStyleValues.CENTER,
      position: GlobalStyleValues.ABSOLUTE,
      alignSelf: GlobalStyleValues.CENTER,
      width: Percentage.PRECENTAGE_100,
    },
    addNewNotesButton: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_18),
      color: COLORS.PRIMARY_BLUE,
      fontFamily: FONT_NAMES.SF_LIGHT,
    },
    width100: {
      width: Percentage.PRECENTAGE_100,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    flatlistView: {
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
      width: isWebsite()
        ? FixedValue.CONSTANT_VALUE_500
        : Percentage.PRECENTAGE_100,
      alignSelf: GlobalStyleValues.CENTER,
    },
    innerFlat: {
      ...BASESTYLE.flex1,
      backgroundColor: _theme?.backgroundColor,
      paddingHorizontal:scaleWidth(FixedValue.CONSTANT_VALUE_60)
    },
    bottomsheetView: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.selfCenter,
      justifyContent: GlobalStyleValues.FLEX_END,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      backgroundColor: GlobalStyleValues.TRANSPARENT,
    },
    bottomPadding100: {
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_100),
    },
    backgroundColorBottomSheet: {
      backgroundColor: _theme.color,
    },
  });
