import * as React from "react";
import Svg, { G, Path, Circle, Defs, SvgProps } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */
const BACKGROUND = (props:SvgProps) => (
  <Svg
    width={375}
    height={812}
    viewBox="0 0 375 812"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G opacity={0.3}>
      <G filter="url(#filter0_d_1253_11181)">
        <Path
          d="M-0.613484 414.881L12.8017 413.792L7.03755 425.954L-0.613484 414.881Z"
          fill="#FFF129"
        />
      </G>
      <G filter="url(#filter1_d_1253_11181)">
        <Path
          d="M259.311 355.901L281.209 347.564L277.48 370.696L259.311 355.901Z"
          fill="#ED33B9"
        />
      </G>
      <G filter="url(#filter2_d_1253_11181)">
        <Path
          d="M359.314 809.014L381.473 823.491L357.856 835.443L359.314 809.014Z"
          fill="#ED33B9"
        />
      </G>
      <G filter="url(#filter3_d_1253_11181)">
        <Path
          d="M49.3562 245.372L58.2853 228.749L68.2171 244.794L49.3562 245.372Z"
          fill="#ED33B9"
        />
      </G>
      <G filter="url(#filter4_d_1253_11181)">
        <Path
          d="M119.344 120.909L127.643 150.248L98.0854 142.765L119.344 120.909Z"
          fill="#FFF129"
        />
      </G>
      <G filter="url(#filter5_d_1253_11181)">
        <Path
          d="M290.865 577.044L309.657 583.581L294.6 596.586L290.865 577.044Z"
          fill="#ED33B9"
        />
      </G>
      <G filter="url(#filter6_d_1253_11181)">
        <Path
          d="M187.805 609.814L191.288 596.813L200.805 606.33L187.805 609.814Z"
          fill="#51E5FF"
        />
      </G>
      <G filter="url(#filter7_d_1253_11181)">
        <Path
          d="M124.655 671.146L138.136 648.615L150.908 671.556L124.655 671.146Z"
          fill="#51E5FF"
        />
      </G>
      <G filter="url(#filter8_d_1253_11181)">
        <Path
          d="M100.233 338.257L103.415 315.098L121.88 329.433L100.233 338.257Z"
          fill="#51E5FF"
        />
      </G>
      <G filter="url(#filter9_d_1253_11181)">
        <Path
          d="M280.678 31.9224L305.018 20.3214L302.894 47.2011L280.678 31.9224Z"
          fill="#51E5FF"
        />
      </G>
      <G filter="url(#filter10_d_1253_11181)">
        <Circle
          cx={52.1796}
          cy={622.458}
          r={7.20137}
          transform="rotate(-90 52.1796 622.458)"
          fill="#FFF129"
        />
      </G>
      <G filter="url(#filter11_d_1253_11181)">
        <Circle
          cx={105.566}
          cy={494.747}
          r={5.17422}
          transform="rotate(-90 105.566 494.747)"
          fill="#23CE6B"
        />
      </G>
      <G filter="url(#filter12_d_1253_11181)">
        <Circle
          cx={56.8011}
          cy={762.437}
          r={4.39216}
          transform="rotate(-90 56.8011 762.437)"
          fill="#51E5FF"
        />
      </G>
      <G filter="url(#filter13_d_1253_11181)">
        <Circle
          cx={336.549}
          cy={139.425}
          r={5.7436}
          transform="rotate(-90 336.549 139.425)"
          fill="#51E5FF"
        />
      </G>
      <G filter="url(#filter14_d_1253_11181)">
        <Circle
          cx={319.659}
          cy={258.351}
          r={3.71644}
          transform="rotate(-90 319.659 258.351)"
          fill="#FFF129"
        />
      </G>
      <G filter="url(#filter15_d_1253_11181)">
        <Circle
          cx={162.216}
          cy={38.068}
          r={3.71644}
          transform="rotate(-90 162.216 38.068)"
          fill="#51E5FF"
        />
      </G>
      <G filter="url(#filter16_d_1253_11181)">
        <Circle
          cx={264.246}
          cy={647.565}
          r={3.71644}
          transform="rotate(-90 264.246 647.565)"
          fill="#23CE6B"
        />
      </G>
      <G filter="url(#filter17_d_1253_11181)">
        <Circle
          cx={364.256}
          cy={707.704}
          r={5.06788}
          transform="rotate(-90 364.256 707.704)"
          fill="#FFF129"
        />
      </G>
      <G filter="url(#filter18_d_1253_11181)">
        <Circle
          cx={238.907}
          cy={128.952}
          r={4.0543}
          transform="rotate(-90 238.907 128.952)"
          fill="#ED33B9"
        />
      </G>
      <G filter="url(#filter19_d_1253_11181)">
        <Circle
          cx={155.795}
          cy={808.723}
          r={6.75717}
          transform="rotate(-90 155.795 808.723)"
          fill="#ED33B9"
        />
      </G>
      <G filter="url(#filter20_d_1253_11181)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M291.864 473.446L287.744 477.833L304.563 493.625L308.683 489.238L291.864 473.446ZM291.869 473.446L302.855 461.746L307.242 465.866L296.257 477.566L291.869 473.446ZM289.189 501.206L300.174 489.506L304.562 493.626L293.576 505.326L289.189 501.206Z"
          fill="white"
        />
      </G>
      <G filter="url(#filter21_d_1253_11181)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M150.159 430.407L136.26 438.431L133.25 433.219L147.145 425.197L138.617 410.426L143.829 407.417L155.364 427.397L150.157 430.403L150.159 430.407ZM160.74 404.605L146.841 412.63L143.831 407.418L157.73 399.393L160.74 404.605Z"
          fill="white"
        />
      </G>
      <G filter="url(#filter22_d_1253_11181)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.5586 -0.195046L35.0609 3.95878L33.5032 9.77214L18.0009 5.61831L19.5586 -0.195046ZM7.77018 20.5303L7.76979 20.5318L13.5831 22.0895L19.5543 -0.195099L13.7409 -1.75278L9.32786 14.717L-6.17016 10.5643L-7.72785 16.3776L7.77018 20.5303Z"
          fill="white"
        />
      </G>
      <G filter="url(#filter23_d_1253_11181)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M288.171 760.315L285.161 755.103L265.182 766.638L268.191 771.85L282.956 763.326L290.98 777.223L296.192 774.214L288.168 760.316L288.171 760.315ZM262.36 749.73L270.385 763.629L265.173 766.638L257.148 752.739L262.36 749.73Z"
          fill="white"
        />
      </G>
      <G filter="url(#filter24_d_1253_11181)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M222.272 243.727L227.855 258.774L222.212 260.868L216.63 245.821L222.272 243.727ZM198.553 246.107L198.547 246.109L200.641 251.752L222.271 243.727L220.177 238.084L204.195 244.014L198.613 228.968L192.971 231.061L198.553 246.107Z"
          fill="white"
        />
      </G>
    </G>
    <Defs></Defs>
  </Svg>
);
export default BACKGROUND;
