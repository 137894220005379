import React from 'react';
import {GestureResponderEvent} from 'react-native';
import {G, Image, Rect} from 'react-native-svg';

import {COLORS, FixedValue, Percentage} from '../../../constants';
import {EditorContext} from '../../../contextAPI/editorContext';
import {
  ImageRefType,
  ImageState,
  TopEditorItemType,
  UserImageItemProps,
} from '../../../types/componentTypes/editorType';
import {isWebsite} from '../../../utils/responsive';

const RenderUserImages = React.forwardRef(
  (
    props: UserImageItemProps,
    ref: React.ForwardedRef<ImageRefType>
  ): JSX.Element => {
    /** Loading Context Variables */
    const {
      editorControlItemDimensions,
      editorSvgDimensions,
      selectedItemIndex,
    } = React.useContext(EditorContext);
    const {
      imageSource,
      width,
      height,
      itemPositionOverride,
      elementId,
      elementIndex,
      selectItemCallback,
      disableInteraction,
      imageIndex,
    } = props;

    const _itemWidth: number = width;
    const _itemHeight: number = height;

    /** Calculates (x, y) points to use in state when no x or y is provided in itemPositionOverride */
    const getCenterForAdd = React.useMemo(() => {
      return {
        x:
          editorSvgDimensions.width / FixedValue.CONSTANT_VALUE_2 -
          _itemWidth / FixedValue.CONSTANT_VALUE_2,
        y:
          editorSvgDimensions.height / FixedValue.CONSTANT_VALUE_2 -
          _itemHeight / FixedValue.CONSTANT_VALUE_2,
      };
    }, [_itemWidth, _itemHeight, editorSvgDimensions]);
    const [isDragging, setIsDragging] = React.useState<boolean>(false);
    const otherScale = isWebsite()
      ? FixedValue.CONSTANT_VALUE_025
      : FixedValue.CONSTANT_VALUE_05;
    const [state, setState] = React.useState<ImageState>({
      x: itemPositionOverride?.x ? itemPositionOverride.x : getCenterForAdd.x,
      y: itemPositionOverride?.y ? itemPositionOverride.y : getCenterForAdd.y,
      scale: itemPositionOverride?.scale
        ? itemPositionOverride.scale
        : otherScale,
      rotation: itemPositionOverride?.rotation
        ? itemPositionOverride?.rotation
        : FixedValue.CONSTANT_VALUE_0,
      source: imageSource,
      isSelected: false,
      offset: {x: FixedValue.CONSTANT_VALUE_0, y: FixedValue.CONSTANT_VALUE_0},
    });
    const [itemIndex, setItemIndex] = React.useState<number>(elementIndex);

    React.useImperativeHandle(
      ref,
      () => ({
        elementIndex,
        imageIndex,
        getName: () => TopEditorItemType.IMAGE,
        getPosition: (): ImageState => state,
        renderElementToSave: () => renderNonInteractive(),
        getItemOrderTabItem: () => renderItemOrderTabItem(),
        deselect: (): void => setState(prev => ({...prev, isSelected: false})),
        changeIndex: (newIndex: number) => setItemIndex(newIndex),
        changeScale: (newScale: number): void =>
          setState(prev => ({...prev, scale: newScale})),
        changeRotation: (newRotation: number): void =>
          setState(prev => {
            return {
              ...prev,
              rotation:
                newRotation === FixedValue.CONSTANT_VALUE_360
                  ? FixedValue.CONSTANT_VALUE_0
                  : newRotation,
            };
          }),
        getTopEditorItemType: (): TopEditorItemType => TopEditorItemType.IMAGE,
      }),
      [state, itemIndex]
    );

    const isThisImageSelected: boolean =
      (selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1 &&
        state.isSelected) ||
      selectedItemIndex === FixedValue.CONSTANT_VALUE_MIN_1;

    const handlePointerDown = React.useCallback(
      (event: GestureResponderEvent): void => {
        event.stopPropagation();
        if (selectItemCallback) {
          selectItemCallback(itemIndex);
        }
        const el = event.target;
        const x = event.nativeEvent.pageX - state.x;
        const y = event.nativeEvent.pageY - state.y;
        if (isWebsite()) {
          // @ts-ignore Correct usage for web
          el.setPointerCapture(event.pointerId);
        }
        setState({
          ...state,
          isSelected: true,
          offset: {x, y},
        });
        setIsDragging(true);
      },
      [itemIndex, selectItemCallback, state, isWebsite]
    );

    const handlePointerMove = React.useCallback(
      (event: GestureResponderEvent): void => {
        const offsetX = event.nativeEvent.pageX - state.x;
        const offsetY = event.nativeEvent.pageY - state.y;
        const x: number = state.x - (state.offset.x - offsetX);
        const y: number = state.y - (state.offset.y - offsetY);
        if (state.isSelected && isDragging) {
          setState({...state, x, y});
        }
      },
      [isDragging, state]
    );

    const handlePointerUp = React.useCallback((): void => {
      setIsDragging(false);
    }, []);

    const getCenter = (): {x: number; y: number} => ({
      x: state.x + _itemWidth / FixedValue.CONSTANT_VALUE_2,
      y: state.y + _itemHeight / FixedValue.CONSTANT_VALUE_2,
    });

    const renderItemOrderTabItem = (): JSX.Element => (
      <G>
        <Rect
          // @ts-ignore Used to show id when testing
          testID={`TE-user-image-${elementId}-item-order-control-item-selection-box`}
          accessibilityLabel={`TE-user-image-${elementId}-item-order-control-item-selection-box`}
          key={`${elementId}-outer`}
          id={`${elementId}-outer`}
          x={FixedValue.CONSTANT_VALUE_0}
          y={FixedValue.CONSTANT_VALUE_0}
          height={editorControlItemDimensions.height}
          width={editorControlItemDimensions.width}
          fill={COLORS.RGBA_255_255_255_5}
          fillOpacity={FixedValue.CONSTANT_VALUE_0}
        />
        <Image
          // @ts-ignore Used to show id when testing
          testID={`TE-user-image-${elementId}-item-order-control-item`}
          accessibilityLabel={`TE-user-image-${elementId}-item-order-control-item`}
          key={elementId}
          id={elementId}
          x={FixedValue.CONSTANT_VALUE_0}
          y={FixedValue.CONSTANT_VALUE_0}
          height={Percentage.PRECENTAGE_100}
          width={Percentage.PRECENTAGE_100}
          // @ts-ignore Used to show image
          href={imageSource}
        />
        <Rect
          // @ts-ignore Used to show id
          nativeID={`${elementId}-item-order-draggable-rect`}
          key={`${elementId}-item-order-draggable-rect`}
          x={FixedValue.CONSTANT_VALUE_0}
          y={FixedValue.CONSTANT_VALUE_0}
          height={editorControlItemDimensions.height}
          width={editorControlItemDimensions.width}
          opacity={FixedValue.CONSTANT_VALUE_0}
        />
      </G>
    );

    const renderNonInteractive = (): JSX.Element => (
      <Image
        id={`image-top-element-${itemIndex}`}
        key={elementId}
        // @ts-ignore Used to show image
        href={state.source}
        x={state.x}
        y={state.y}
        height={_itemHeight}
        width={_itemWidth}
        originX={getCenter().x}
        originY={getCenter().y}
        rotation={state.rotation}
        scale={state.scale}
      />
    );

    const renderInteractive = (): JSX.Element => (
      <G
        originX={getCenter().x}
        originY={getCenter().y}
        rotation={state.rotation}
        scale={state.scale}
        opacity={
          isThisImageSelected
            ? FixedValue.CONSTANT_VALUE_1
            : FixedValue.CONSTANT_VALUE_05
        }
      >
        <Rect
          // @ts-ignore Used to show id when testing
          testID={`TE-${elementId}-selection-box`}
          accessibilityLabel={`TE-${elementId}-selection-box`}
          key={`selection-box-${elementId}`}
          x={state.x - FixedValue.CONSTANT_VALUE_10}
          y={state.y - FixedValue.CONSTANT_VALUE_10}
          width={_itemWidth + FixedValue.CONSTANT_VALUE_20}
          height={_itemHeight + FixedValue.CONSTANT_VALUE_20}
          fill={COLORS.WHITE}
          fillOpacity={FixedValue.CONSTANT_VALUE_0}
          stroke={state.isSelected ? COLORS.PRIMARY_BLUE : ''}
          strokeWidth={
            isWebsite()
              ? FixedValue.CONSTANT_VALUE_10
              : FixedValue.CONSTANT_VALUE_70
          }
          // @ts-ignore Used to handle pointer events
          onPointerDown={handlePointerDown}
          onPointerUp={handlePointerUp}
          onPointerMove={handlePointerMove}
          onPressIn={handlePointerDown}
          onPressOut={handlePointerUp}
          onResponderMove={handlePointerMove}
        />
        <Image
          // @ts-ignore Used to show id when testing
          testID={elementId}
          accessibilityLabel={elementId}
          id={elementId}
          key={elementId}
          // @ts-ignore Used to show image
          href={state.source}
          x={state.x}
          y={state.y}
          height={_itemHeight}
          width={_itemWidth}
        />
        <Rect
          // @ts-ignore Used to show id when testing
          testID={`${elementId}-draggable-rect`}
          accessibilityLabel={`${elementId}-draggable-rect`}
          key={`${elementId}-draggable-rect`}
          x={state.x}
          y={state.y}
          height={_itemHeight}
          width={_itemWidth}
          opacity={FixedValue.CONSTANT_VALUE_0}
          // @ts-ignore Used to handle pointer events
          onPointerDown={handlePointerDown}
          onPointerUp={handlePointerUp}
          onPointerMove={handlePointerMove}
          onPressIn={handlePointerDown}
          onPressOut={handlePointerUp}
          onResponderMove={handlePointerMove}
        />
      </G>
    );

    return disableInteraction ? renderNonInteractive() : renderInteractive();
  }
);

export default RenderUserImages;
