import {takeLatest, put, call, delay} from 'redux-saga/effects';
import {AppConst} from '../../constants';
import {userLogOut, deleteUserAccount} from '../../utils/apis';
import {removeAllData, _ToastHandler} from '../../utils/helperFunctions';
import {
  onDeleteEnd,
  onLogoutEnd,
  onLogoutFailed,
  onLogoutSuccess,
  ResetAllState,
} from '../reducers/logoutslice';
import {isWebsite} from '../../utils/responsive';

const {StoreConstants} = AppConst;

function* logout() {
  try {
    const data: object = yield call(userLogOut);
    yield removeAllData();
    yield put(onLogoutSuccess(data));
    yield put(ResetAllState());
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onLogoutFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onLogoutEnd());
  }
}

function* deleteAccount() {
  try {
    const data: object = yield call(deleteUserAccount);
    yield removeAllData();
    yield put(ResetAllState());
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onDeleteEnd());
  }
}

export default function* logoutSaga() {
  yield takeLatest(`${StoreConstants.LOGOUT}/onLogoutStart`, logout);
  yield takeLatest(`${StoreConstants.LOGOUT}/onDeleteStart`, deleteAccount);
}
