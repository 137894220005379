import React from 'react';

import SizeSlider from './sizeSlider';
import {COLORS, FixedValue} from '../../constants';
import {EditorShapeContext} from '../../contextAPI/editorShapeContext';
import {numberToArray} from '../../utils/helperFunctions';
import PlusIcon from '../renderSvgs/plusIcon';
import MinusSign from '../renderSvgs/minus';

const StrokeWidthController = (): JSX.Element => {
  const {onSelectShape} = React.useContext(EditorShapeContext);
  const sizeResult: number[] = numberToArray(
    onSelectShape?.current?.getPosition()?.strokeWidth
  );
  const ColorResult: string =
    onSelectShape?.current?.getPosition()?.strokeColor ?? COLORS.WHITE;

  const onChangeStrokeWidth = React.useCallback(
    (value: number[]): void => {
      onSelectShape?.current?.changeStroke(ColorResult, value[0]);
    },
    [onSelectShape]
  );

  return (
    <SizeSlider
      values={sizeResult}
      LeftIcon={
        <MinusSign
          stroke={COLORS.WHITE}
          width={FixedValue.CONSTANT_VALUE_24}
          height={FixedValue.CONSTANT_VALUE_24}
        />
      }
      RightIcon={
        <PlusIcon
          stroke={COLORS.WHITE}
          width={FixedValue.CONSTANT_VALUE_24}
          height={FixedValue.CONSTANT_VALUE_24}
        />
      }
      onValuesChange={onChangeStrokeWidth}
      min={-FixedValue.CONSTANT_VALUE_1}
      max={FixedValue.CONSTANT_VALUE_10}
    />
  );
};

export default React.memo(StrokeWidthController);
