import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
  SCREEN_HEIGHT,
} from '../../utils/responsive';

export const modalStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.selfCenter,
      justifyContent: GlobalStyleValues.FLEX_END,
      width: isWebsite()
        ? FixedValue.CONSTANT_VALUE_500
        : Percentage.PRECENTAGE_100,
      alignItems: GlobalStyleValues.CENTER,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    subContainer: {
      width: Percentage.PRECENTAGE_100,
      backgroundColor: _theme?.backgroundColor,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_15),
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_18),
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_18,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_18,
      shadowColor: COLORS._333333,
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_0,
        height: -FixedValue.CONSTANT_VALUE_3,
      },
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
      elevation: FixedValue.CONSTANT_VALUE_3,
      alignItems: GlobalStyleValues.CENTER,
    },
    topView: {
      ...BASESTYLE.row,
      ...BASESTYLE.spaceBetween,
      ...BASESTYLE.alignCenter,
      width: Percentage.PRECENTAGE_100,
      backgroundColor: _theme.backgroundColor,
    },
    headingText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_15),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: _theme.color,
      maxWidth: Percentage.PRECENTAGE_70,
    },
    newCollectionText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: _theme.color,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_5),
    },
    newCollectionView: {
      ...BASESTYLE.row,
      ...BASESTYLE.alignCenter,
    },
    buttonsView: {
      ...BASESTYLE.row,
      ...BASESTYLE.spaceBetween,
      ...BASESTYLE.alignCenter,
      width: Percentage.PRECENTAGE_100,
      backgroundColor: _theme.backgroundColor,
    },
    buttonWidth: {
      width: Percentage.PRECENTAGE_50,
    },
    contentContainerStyle: {
      paddingVertical: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    cellView: {
      ...BASESTYLE.row,
      ...BASESTYLE.spaceBetween,
      ...BASESTYLE.alignCenter,
      width: Percentage.PRECENTAGE_100,
      height: FixedValue.CONSTANT_VALUE_48,
      backgroundColor: _theme.backgroundColor,
    },
    collectionNameText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: _theme.color,
      maxWidth: Percentage.PRECENTAGE_40,
    },
    seperatorView: {
      height: scaleHeight(1.2),
      backgroundColor: COLORS.B6BFC9,
    },
    doneTextColor: {
      color: COLORS._4EAF51,
    },
    topListContainer: {
      width: Percentage.PRECENTAGE_100,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_150),
      marginTop: FixedValue.CONSTANT_VALUE_10,
      backgroundColor: COLORS.TRANSPARENT,
      borderBottomWidth: FixedValue.CONSTANT_VALUE_1,
      borderBottomColor: COLORS.B6BFC9,
    },
    collectionList: {
      width: Percentage.PRECENTAGE_100,
      maxHeight: SCREEN_HEIGHT * FixedValue.CONSTANT_VALUE_05,
    },
    outterPart: {
      width: Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      ...BASESTYLE.absolutePosition,
      zIndex: FixedValue.CONSTANT_VALUE_MIN_1,
      backgroundColor: _theme?.loaderBackgroundColor,
    },
  });
