import React from 'react';
import {View} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {ReceviedOfferStyle} from './tradeRecevied.style';
import TradeReceviedOfferList from './tradeReceviedOfferList';

const ReceviedOffer = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = ReceviedOfferStyle(ColorTheme);

  return (
    <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
      <TradeReceviedOfferList />
    </View>
  );
};

export default React.memo(ReceviedOffer);
