import {StyleSheet} from 'react-native';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../../constants';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const styles = (theme: IColorTheme) =>
  StyleSheet.create({
    groupListView: {
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
      width: Percentage.PRECENTAGE_100,
      borderWidth: FixedValue.CONSTANT_VALUE_1,
      borderColor: COLORS.C2D2ED,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_6),
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_14),
      paddingVertical: scaleHeight(FixedValue.CONSTANT_VALUE_8)
    },
    groupNameText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme?.color,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    imageView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_65),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    placeHolderBackgroundColor: {
      backgroundColor: theme?.color,
    },
    countUserStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: theme?.color,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_7),
    },
    groupNameStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme?.color,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_161),
      textTransform: GlobalStyleValues.CAPITALIZE,
    },
    dateTextStyle: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_4),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS._GREY,
    },
    groupProfileText: {
      textAlign: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      color: theme?.backgroundColor,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      textTransform: GlobalStyleValues.UPPERCASE,
    },
    imageStyle: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_65),
    },
    mainViewStyle: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      backgroundColor: theme?.color,
      ...BASESTYLE.inCenter,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_65),
      overflow: GlobalStyleValues.HIDDEN,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    inRowStyle: {
      ...BASESTYLE.inRow,
    },
    columnStyle: {
      ...BASESTYLE.column,
    },
  });
