import React from 'react';
import {FlatList, ScrollView, View} from 'react-native';
import TopsEmptyList from '../../components/emptyListView/topsListEmptyView';
import {BASESTYLE, FixedValue, TEXT} from '../../constants';
import {TradeContext} from '../../contextAPI/tradeContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import {useAppSelector} from '../../hooks/reduxHooks';
import {HomeStore} from '../../redux/reducers/homeSlice';
import {
  AllTopListProps,
  TopModel,
} from '../../types/reducerTypes/searchTabStoreType';
import {scaleHeight} from '../../utils/responsive';
import {allTopsStyle} from '../seeAllTops/seeAll.styles';
import useCreateTradeHooks from '../../hooks/componentHooks/useCreateTradeHooks';
import CardTile from '../../components/RenderItemTile/cardTile';
import {useIsFocused} from '@react-navigation/native';
import AppLoader from '../../components/smallDesigns/appLoader';
import {showAlertForExceedQuantity} from '../../utils/helperFunctions';

const SelectTopsList = (props: AllTopListProps): JSX.Element => {
  const {collectionBasedTopList} = useAppSelector(HomeStore);
  const {
    collectionsTops,
    selectOrUnSelectSingleTop,
    selectedTopsToMove,
    updateTopQuantity,
    clearTopsinCollection,
    setIsOtherUserTopList,
    setInitialLoader,
    initialLoader,
    collectionModel,
  } = React.useContext(TradeContext);
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = allTopsStyle(ColorTheme);
  const {onReachedEnd, callAllTopsListAPI} = useCreateTradeHooks();
  const isFocused = useIsFocused();

  React.useEffect((): void => {}, [props.headerValue]);

  const modifyQuantity = (item: TopModel, val: string, quant?: number) => {
    if (val === TEXT.MANUAL && quant) {
      if (quant > (item.quantity ?? 0)) {
        showAlertForExceedQuantity(
          item?.quantity ?? FixedValue.CONSTANT_VALUE_0
        );
      } else {
        updateTopQuantity(item, val, quant);
      }
    } else {
      if (val === TEXT.MINNUS || item.quantity !== item.selectedQuantity) {
        updateTopQuantity(item, val);
      } else {
        showAlertForExceedQuantity(
          item?.quantity ?? FixedValue.CONSTANT_VALUE_0
        );
      }
    }
  };

  const updateQuantity = (item: TopModel, val: string, quant?: number) => {
    if (quant) {
      modifyQuantity(item, val, quant);
    } else if (item.selectedQuantity === FixedValue.CONSTANT_VALUE_1) {
      {
        val === TEXT.MINNUS
          ? selectOrUnSelectSingleTop(item)
          : modifyQuantity(item, val);
      }
    } else {
      modifyQuantity(item, val);
    }
  };

  const RenderList = React.useMemo((): JSX.Element | null => {
    if (collectionsTops?.length > FixedValue.CONSTANT_VALUE_0) {
      return (
        <FlatList
          data={collectionsTops}
          keyExtractor={(_, index) => `TopNumber-${index}`}
          onEndReached={() =>
            onReachedEnd(collectionModel?.id ?? FixedValue.CONSTANT_VALUE_0)
          }
          onEndReachedThreshold={FixedValue.CONSTANT_VALUE_1}
          showsVerticalScrollIndicator={false}
          style={BASESTYLE.flex1}
          contentContainerStyle={{
            paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_55),
          }}
          renderItem={({item, index}) => (
            <CardTile
              key={index}
              topModel={item}
              onAddButtonTap={() => selectOrUnSelectSingleTop(item)}
              onUpdateQuantityTap={(val, quant) => {
                updateQuantity(item, val, quant);
              }}
              isInputQuantity
              checkQuantityOnOnChange
              isSelected={item.isSelected}
              callAddAndSubFunction={true}
            />
          )}
        />
      );
    }
    return null;
  }, [collectionsTops, selectedTopsToMove, collectionModel]);

  const RenderEmptyUI = React.useMemo((): JSX.Element | null => {
    if (collectionsTops?.length === FixedValue.CONSTANT_VALUE_0) {
      return (
        <ScrollView
          style={BASESTYLE.flex1}
          contentContainerStyle={BASESTYLE.flex1}
          showsVerticalScrollIndicator={false}
        >
          <TopsEmptyList
            emptyViewTitle={TEXT.YOU_HAVE_NO_TOPS}
            emptyViewDesc={''}
          />
        </ScrollView>
      );
    }
    return null;
  }, [collectionsTops]);

  React.useEffect(() => {
    if (isFocused) {
      setInitialLoader(true);
      if (props.userId) {
        setIsOtherUserTopList(true);
      }
      clearTopsinCollection();
      callAllTopsListAPI(props.collectionModel.id, FixedValue.CONSTANT_VALUE_0);
      return () => {
        if (props.userId) {
          setIsOtherUserTopList(true);
        } else {
          setIsOtherUserTopList(false);
        }
      };
    }
  }, [isFocused]);

  return (
    <View style={styles.contentContainer}>
      {initialLoader && <AppLoader />}
      {!collectionBasedTopList.isLoading && !initialLoader && RenderEmptyUI}
      {!initialLoader && RenderList}
    </View>
  );
};

export default React.memo(SelectTopsList);
