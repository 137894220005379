import React from 'react';
import {Pressable, Text, View} from 'react-native';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import {FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {style} from './style';
import Settings from '../../components/renderSvgs/settings';
import ChildGroupListContent from '../childGroupInvitationsList/childGroupListContant';
import {navigate} from '../../services/navigationService';
import {useIsFocused} from '@react-navigation/native';
import useTradingApprovalHooks from '../../hooks/componentHooks/useTradingApprovalHooks';
import ConfirmationModal from '../../modals/confirmationModal/confirmationModal';
import {ChildProfileContext} from '../../contextAPI/childProfileContext';
import {_ToastHandler} from '../../utils/helperFunctions';
import {useAppSelector} from '../../hooks/reduxHooks';
import {childProfileStore} from '../../redux/reducers/childProfileSlice';
import {
  GROUP_CHILD_INVITATION_APPROVED,
  GROUP_CHILD_INVITATION_REJECT,
} from '../../constants/actionTypes';
import AppLoader from '../../components/smallDesigns/appLoader';
import TopBarWithRightButton from '../../components/header/topBarWithRightButton';

const ChildGroupInvitation = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = style(ColorTheme);
  const isFocused = useIsFocused();
  const {
    callGroupInvitationChildListAPI,
    groupChildInvitaionAcceptApiCall,
    groupInvitationRejectApiCall,
  } = useTradingApprovalHooks();
  const {
    isApproveInvitation,
    modalVisible,
    initialLoader,
    setModalVisible,
    setInitialLoader,
  } = React.useContext(ChildProfileContext);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const {groupChildInvitaionApprove, groupChildInvitaionReject} =
    useAppSelector(childProfileStore);

  const onPress = React.useCallback((): void => {
    navigate(ROUTE_NAME.GROUP_INVIATION_SETTINGS);
  }, []);

  React.useEffect(() => {
    if (isFocused) {
      setInitialLoader(true);
      callGroupInvitationChildListAPI(FixedValue.CONSTANT_VALUE_0);
    }
  }, [isFocused]);

  React.useEffect(() => {
    switch (groupChildInvitaionApprove.status) {
      case GROUP_CHILD_INVITATION_APPROVED.SUCCESS:
        callGroupInvitationChildListAPI(FixedValue.CONSTANT_VALUE_0);
        setModalVisible(false);
        _ToastHandler('Group Invitation Approved', true);
        break;
      case GROUP_CHILD_INVITATION_APPROVED.FAILED:
        setModalVisible(false);
        break;
      default:
        break;
    }
  }, [groupChildInvitaionApprove.status]);

  React.useEffect(() => {
    setLoading(groupChildInvitaionApprove.isLoading);
  }, [groupChildInvitaionApprove.isLoading]);

  React.useEffect(() => {
    switch (groupChildInvitaionReject.status) {
      case GROUP_CHILD_INVITATION_REJECT.SUCCESS:
        callGroupInvitationChildListAPI(FixedValue.CONSTANT_VALUE_0);
        setModalVisible(false);
        _ToastHandler('Group Invitation Reject', true);
        break;
      case GROUP_CHILD_INVITATION_REJECT.FAILED:
        setModalVisible(false);
        break;
      default:
        break;
    }
  }, [groupChildInvitaionReject.status]);

  React.useEffect(() => {
    setLoading(groupChildInvitaionReject.isLoading);
  }, [groupChildInvitaionReject.isLoading]);

  return (
    <>
      {initialLoader && <AppLoader />}
      <SafeAreaContainer>
        <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
          <TopBarWithRightButton
            headerLabel={TEXT.GROUP_INVITATION}
            Icon={
              <Pressable onPress={onPress}>
                <Settings stroke={ColorTheme.color} />
              </Pressable>
            }
            headerColor={ColorTheme.color}
          />
          <ChildGroupListContent />
          <ConfirmationModal
            title={
              isApproveInvitation
                ? TEXT.APPROVE_INVITATION
                : TEXT.REJECT_INVITATION
            }
            modalVisible={modalVisible}
            onPressYes={() => {
              isApproveInvitation
                ? groupChildInvitaionAcceptApiCall()
                : groupInvitationRejectApiCall();
            }}
            onShowHide={() => setModalVisible(false)}
            isLoading={isLoading}
          />
        </View>
      </SafeAreaContainer>
    </>
  );
};

export default React.memo(ChildGroupInvitation);
