import React from 'react';
import Svg, {SvgProps, Rect, Path} from 'react-native-svg';

const GroupOrder = (props: SvgProps) => (
  <Svg
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={64}
    viewBox="0 0 48 64"
    fill="none"
    {...props}
  >
    <Rect x={0.5} y={0.5} width={47} height={47} rx={7.5} stroke="#333333" />
    <Path
      d="M24.2402 15.8462L14.2402 20.8462L24.2402 25.8462L34.2402 20.8462L24.2402 15.8462Z"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M14.2402 30.8462L24.2402 35.8462L34.2402 30.8462"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M14.2402 25.8462L24.2402 30.8462L34.2402 25.8462"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M14.9369 62.166C16.9486 62.166 18.1693 60.7256 18.1693 58.4795V58.4697C18.1693 56.2139 16.9389 54.7881 14.9369 54.7881C12.9447 54.7881 11.7045 56.209 11.7045 58.4697V58.4795C11.7045 60.7305 12.9154 62.166 14.9369 62.166ZM14.9369 61.3555C13.4721 61.3555 12.6029 60.2227 12.6029 58.4795V58.4697C12.6029 56.7119 13.5014 55.5986 14.9369 55.5986C16.3773 55.5986 17.2709 56.7119 17.2709 58.4697V58.4795C17.2709 60.2227 16.3822 61.3555 14.9369 61.3555ZM19.2854 62H20.135V58.7383C20.135 57.9668 20.7111 57.4541 21.5217 57.4541C21.7072 57.4541 21.8684 57.4736 22.0441 57.5029V56.6777C21.9611 56.6631 21.7805 56.6436 21.6193 56.6436C20.9064 56.6436 20.4133 56.9658 20.2131 57.5176H20.135V56.7363H19.2854V62ZM24.5908 62.0928C25.3232 62.0928 25.9092 61.7461 26.2314 61.1602H26.3096V62H27.1592V54.6465H26.3096V57.5664H26.2314C25.9434 57.0049 25.3135 56.6436 24.5908 56.6436C23.2529 56.6436 22.3838 57.7129 22.3838 59.3633V59.373C22.3838 61.0137 23.2578 62.0928 24.5908 62.0928ZM24.7861 61.3408C23.8242 61.3408 23.2529 60.5986 23.2529 59.373V59.3633C23.2529 58.1377 23.8242 57.3955 24.7861 57.3955C25.7432 57.3955 26.3291 58.1475 26.3291 59.3633V59.373C26.3291 60.5889 25.7432 61.3408 24.7861 61.3408ZM30.6385 62.0928C31.8787 62.0928 32.6307 61.3896 32.8113 60.6768L32.8211 60.6377H31.9715L31.952 60.6816C31.8104 60.999 31.3709 61.3359 30.658 61.3359C29.7205 61.3359 29.1199 60.7012 29.0955 59.6123H32.8846V59.2803C32.8846 57.708 32.0154 56.6436 30.5848 56.6436C29.1541 56.6436 28.2264 57.7568 28.2264 59.3828V59.3877C28.2264 61.0381 29.1346 62.0928 30.6385 62.0928ZM30.5799 57.4004C31.3563 57.4004 31.9324 57.8936 32.0203 58.9336H29.1102C29.2029 57.9326 29.7986 57.4004 30.5799 57.4004ZM33.9029 62H34.7525V58.7383C34.7525 57.9668 35.3287 57.4541 36.1393 57.4541C36.3248 57.4541 36.4859 57.4736 36.6617 57.5029V56.6777C36.5787 56.6631 36.398 56.6436 36.2369 56.6436C35.524 56.6436 35.0309 56.9658 34.8307 57.5176H34.7525V56.7363H33.9029V62Z"
      fill="white"
    />
  </Svg>
);
export default GroupOrder;
