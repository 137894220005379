import React from 'react';
import {View, Text} from 'react-native';
import {style} from './style';
import {ThemeContext} from '../../contextAPI/themeContext';
import {getGroupInitials} from '../../utils/helperFunctions';
import {FixedValue, TEXT} from '../../constants';
import {IGroupListComponentProps} from '../../types/reducerTypes/groupMangementTypes';
import ProfilePics from '../../components/smallDesigns/profilePics';

const GroupDetailUpperContent = (
  props: IGroupListComponentProps
): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = style(ColorTheme);
  const {item} = props;
  const multipleUserExits: boolean =
    item?.userCount > FixedValue.CONSTANT_VALUE_1;

  return (
    <>
      <View style={styles.centerImageView}>
        <View style={styles.topView}>
          <ProfilePics
            image={item?.groupImage ?? ''}
            text={getGroupInitials(item?.groupName ?? '')}
            mainViewStyle={styles.mainViewStyle}
            initialsTextStyle={styles.groupProfileText}
          />
          <View>
            <Text style={styles.groupNameStyle}>{item?.groupName}</Text>
          </View>
        </View>

        <View style={styles.userStyleView}>
          <Text style={styles.userCountStyle}>
            {item.userCount} {multipleUserExits ? TEXT.USERS : TEXT.USER}
          </Text>
        </View>
      </View>
      <View style={styles.horizontalLineStyle} />
    </>
  );
};

export default React.memo(GroupDetailUpperContent);
