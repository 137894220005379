export const TEXT = {
  LOGIN_IN: 'Log in',
  LOGIN: 'Log In',
  SIGN_UP: 'Sign up',
  WELCOME_TO_MANTELTOP: 'Welcome To\nManteltop',
  WELCOME_DESCRIPTION:
    'urna tortor. sapien volutpat felis, luctus sit faucibus Nam volutpat tincidunt quis nisl. cursus cursus lacus nulla, viverra lorem. faucibus sodales.',
  CREATE_YOUR_FREE_ACCOUNT: 'Create Your Free Account',
  ALREADY_HAVE_ACCOUNT: 'Already have an account? ',
  DONT_HAVE_ACCOUNT: 'Don’t have an account?',
  WELCOME: 'Welcome!',
  WELCOME_DESC: 'Enter your login details below!',
  USERNAME_: 'Username',
  FIRST_NAME_: 'Firstname',
  LAST_NAME_: 'Lastname',
  DOB_: 'DateOfBirth',
  ENTER_DOB: 'e.g. January 1, 2023',
  PARENT_USERNAME: 'ParentUsername',
  PARENT_USERNAME_2: 'ParentUsername2',
  MOBILE_NUMBER: 'MobileNumber',
  MOBILE_NUMBER_: 'Mobile Number*',
  ENTER_MOBILE_NUMBER: 'e.g. 999 999 9999',
  EMAIL: 'Email',
  PARENT_EMAIL: 'ParentEmail',
  PARENT_EMAIL_: 'Parent/Guardian’s Email Address*',
  PARENT_EMAIL_2: 'ParentEmail2',
  PARENT_EMAIL_2_: '2nd Parent/Guardian’s Email Address',
  EMAIL_ID: 'Email Address',
  CODE: 'Code',
  ENTER_USERNAME: 'Enter your username',
  PASSWORD_: 'Password',
  ENTER_PASSWORD: 'Enter your password',
  PARENT_USERNAME_: 'Parent/Guardian’s Username*',
  SECOND_PARENT_USERNAME_: '2nd Parent/Guardian’s Username',
  ENTER_PASSWORD_STAR: '**************',
  ACCOUT_HOLDER_DOB: 'Account Holder Date of Birth*',
  LOST_PASSWORD: 'Forgot password?',
  LOST_USERNAME: 'Forgot username?',
  LOST_N_PASSWORD: 'Forgot\nPassword?',
  LOST_N_USERNAME: 'Forgot\nUsername?',
  LOST_PASSWORD_DESC: `Provide your account's username to reset your password`,
  LOST_USERNAME_DESC: `Provide your account's email/mobile number to find your username`,
  ENTER_EMAIL_ID: 'Enter your email address',
  ENTER_EMAIL_ID_2: 'e.g. abc@domain.com',
  HAVE_ACCOUNT: 'Have an account?',
  VERIFY_EMAIL: 'Verify Email',
  ENTER_OTP: 'Enter Verification Code',
  VERIFY_EMAIL_DESC: 'A verification code has been sent to ',
  VERIFY_USERNAME_DESC:
    'A verification code has been sent to the email associated with username ',
  ENTER_VERIFICATION_CODE: 'Enter your verification code',
  SUBMIT: 'Submit',
  SEND_OTP: 'Send Verification Code',
  RESENT_CODE: 'Resend Verification Code',
  CLEAR_ALL: 'Clear All',
  SAVE_TOP: 'Save Top',
  UPDATE_TOP: 'Update Top',
  CREATE_PASSWORD: 'Create New Password',
  NEW_PASSWORD: 'New Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  ENTER_NEW_PASSWORD: 'Enter New Password',
  ENTER_CONFIRM_PASSWORD: 'Enter Confirm New Password',
  UPPERCASE: 'uppercase',
  NUMBER: 'number',
  AMOUNT: 'Amount',
  CHARACTERS: 'characters',
  GRANTED: 'granted',
  CANCEL: 'Cancel',
  GO_TO_SETTING: 'Go to Settings',
  LOGOUT: 'Log Out',
  YOUR_PASSWORD_RESET: 'Your password has been reset',
  CHANGE_PASSWORD_SUCCESS: 'Password updated successfully',
  WHERE_TO_REDIRECT: 'Where would you like to be redirected?',
  LOGIN_NOW: 'Log In Now',
  CREATE_FREE_ACCOUNT: 'Create Your Account',
  IT_IS_FREE: `(It's free)`,
  FIRST_NAME: 'First Name*',
  LAST_NAME: 'Last Name*',
  ENTER_FIRSTNAME: 'e.g. Justin',
  ENTER_LASTNAME: 'e.g. Dias',
  ENTER_USERNAME_2: 'e.g. justin1998',
  MORE_FIELD_TERM: `You are under 13 years of age, so you will require authentication using your Parent/Guardian's email address to continue with the registration.`,
  TERM_TEXT_1: 'Do you have a personal email address?',
  TERM_TEXT_PERENTS: `Do you know your Parent/Guardian’s username?`,
  TERM_FIRST_LINE: `I agree to Manteltop's `,
  LET_GO: 'Let’s Go',
  DATE: 'date',
  THANKS_FOR_CREATING_ACCOUNT:
    'Thank you for creating an account with Manteltop!',
  CONSENT_TEXT:
    'Because you are below 13 years old, your parent or legal guardian will need to approve your profile before you can use the service.',
  CONSENT_TEXT_TWO: `It complies with COPPA regulations wherein parental consent is mandatory for verifying personal information for children under 13 years. The parent receives an email with instructions to approve their child's profile.`,
  CONSENT_TEXT_FOUR:
    'Once they have approved your profile, you will be able to access the service.',
  LOG_IN_NOW: 'Log In Now',
  CALENDER: 'calendar',
  CODE_SENT_SUCCESSFULLY: 'Code sent successfully!',
  CHANGE_PASSWORD: 'Change Password',
  UPDATE: 'Update',
  CURRENT_PASSWORD: 'Current Password',
  ENTER_CURRENT_PASSWORD: 'Enter Current Password',
  ENTER_TOP_NAME: 'Enter Top Name',
  SEARCH_TOP: 'Search Tops',
  TOP_NAME: 'Top Name*',
  SAVE_TOP_NOW: 'Save Top Now',
  UPDATE_TOP_NOW: 'Update Top Now',
  ADD_HASHTAG: 'Add Hashtag#',
  ADD_HASHTAG_: 'AddHashTag',
  NOTE: 'Note',
  TOP_NAME_: 'TopName',
  NO_RECENT_SEARCH: 'No recent searches',
  NO_RESULT_FOR: 'There are no matches for\n"',
  RECENT_SEARCH: 'RECENT SEARCH',
  DEFAULT: 'default',
  GUARDIAN_EMAIL: 'Parent/Guardian’s Email Address',
  EMAIL_ADDRESS: 'Email Address',
  CREATE_TOP: 'Create top',
  SUCCESSFULLY_CREATED: 'Top successfully created',
  SUCCESSFULLY_UPDATED: 'Top successfully updated',
  OKEY: 'Ok',
  CREATED_ON: 'Created on:',
  TOP_DETAILS: 'Top Details',
  PROPOSE_A_TRADE: 'Propose a Trade for this Top',
  TRADABLE: 'Tradable',
  PASSWORD: 'Password*',
  USERNAME: 'Username*',
  SIGN_UP_SUCCESS:
    'Your account has been created successfully. Please log in to continue.',
  OTP_CODE_INCORRECT: 'Entered OTP is incorrect',
  COLLECTIONS: 'Collections',
  IN_PROGRESS: 'In Progress',
  PURCHASES: 'Purchases',
  NEW_COLLECTION_NAME: 'New Collection Name',
  CREATE_NEW_COLLECTION: 'Create New Collection',
  CREATE_NOW: 'Create Now',
  COLLECTION_NAME_: 'CollectionName',
  COLLECTION_NAME: 'Collection Name',
  COLLECTION_SUCCESSFULLY_CREATED: 'Collection created successfully',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  APP_NAME: 'Manteltop',
  PARENT_MANAGEMENT: 'Parent/Guardian Management',
  ADD_NEW_TOP: 'Add new Tops to Collection',
  EDIT_COLLECTION_NAME: 'Edit Collection Name',
  DELETE_COLLECTION: 'Delete Collection',
  VERIFIED_SUCCESS:
    'User verified successfully, Please login again to continue',
  BUY_NOW: 'Buy Now',
  SEE_ALL: 'See All',
  CREATED_AT: 'Created on: ',
  MOVE_TOPS: '+ Move Tops to this Collection',
  EDIT_TOP: 'Edit Top',
  DELETE_TOP: 'Delete Top',
  COLLECTION_SUCCESSFULLY_UPDATED: 'Collection successfully updated',
  YOU_HAVE_NO_TOPS: 'You have no Tops',
  YOU_HAVE_NO_TOPS_INPURCHASED: 'You have not purchased any Tops',

  OPTIONS_TO_CREATE_TOP: 'Create a Top by clicking on “+” icon',
  CREATE_NEW_TOP: '+  Create a New Top',
  YOU_HAVE_NO_COLLECTION: 'You have no Collections',
  THERE_ARE_NO_REQUEST: 'Your Approved List is currently empty.',
  NO_TRADING_APPROVAL_FOUND: 'No Trading approvals\n found',
  NO_TOP_BUYING_APPROVAL_FOUND: 'No Buying approvals\n found',
  NO_TRADING_SENT_FOUND: 'No Trading approvals\n found',
  SUBTEXT_FOR_NO_COLLECTIONS:
    'Options here are to create a top, or wait for someone to distribute one to you',
  YOU_HAVE_NO_TOPS_COLLECTION: 'You have no Tops in this Collection',
  FILL_COLOR: 'Fill Color',
  STROKE_COLOR: 'Stroke Color',
  STROKE_WIDTH: 'Stroke Width',
  SELECT_ALL: 'Select All',
  SELECT: 'Select',
  UNCATEGORIZED: 'Uncategorized',
  MORE: 'More',
  ADD_TO_COLLECTION: '+  Add to Collection Now',
  UNSELECT_ALL: 'Unselect All',
  CHILD_LIST: 'Child_List',
  ROLE_PARENT: 'ROLE_PARENT',
  ROLE_CHILD: 'ROLE_CHILD',
  YOU_HAVE_NO_CHILD: 'You have no child',
  NO_REQUEST_YET: 'No Requests yet',
  ADD_NEW_TEXT: 'Add New Text',
  CONTINUE: 'Continue',
  I_WANT: 'I want to',
  DELETE_DECS: 'Delete the Collection and delete the Tops',
  MOVE_OR_DELETE: 'Move Tops to Uncategorized and\ndelete the Collection',
  ARE_YOU_SURE: 'Are you sure',
  WANT_TO_DELETE: ' you want to delete the Collection?',
  WANT_TO_TOPS: ' you want to delete the Tops?',
  WANT_TO_TOPS_AND_MOVE:
    ' you want to delete the Collection and move Tops to Uncategorized?',
  DELETE_WARNING:
    'Warning: Tops will be permanently deleted and cannot be recovered if you continue with this action.',
  DELETE_WARNING_COLLECTION:
    'Warning: Collection will be permanently deleted and cannot be recovered if you continue with this action.',
  YES: 'Yes',
  NO: 'No',
  KID: 'Kid’s Profile',
  EDIT_PROFILE: 'Edit Profile',
  PROFILE_REQ: 'Profile Approval Request',
  APPROVE: 'Approve',
  REJECT: 'Reject',
  REJECTED: 'Rejected',
  INVALID: 'Invalid',
  APPROVE_WITH_TICK: 'Approve   ✓',
  REJECT_WITH_CROSS: 'Reject   x',
  PROFILE_NEED: 'Profile needs your approval.',
  AGE: ' year old',
  YEARS_OLD: ' years old',
  DAY: ' ago',
  GROUP_MANAGEMENT: 'Group Management',
  SORRY_NO_GROUP: 'Sorry, no Groups have been created.',
  PLEASE_CREATE_A_GROUP: 'To create a Group, please tap on the button below.',
  CREATE_A_NEW_GROUP: '+  Create a New Group',
  MY_GROUPS: 'My Groups',
  GROUPS_IN: `Groups I'm In`,
  INVITATIONS: 'Invitations',
  GROUP_NAME: 'Group Name',
  GROUP_NAME_: 'groupName',
  EDIT_GROUP_NAME: 'Edit Group Name',
  CREATE: 'Create',
  CREATE_NEW_GROUP: 'Create New Group',
  SORRY_NO_GROUP_IN: 'Sorry, you are not currently in any Groups',
  SORRY_NO_INVITATIONS: 'Sorry, you don’t have any invitations.',
  ARE_YOU_SURE_WANT_TO: 'Are you sure you want to delete\nthis',
  ARE_YOU_SURE_WANT_TO_DELETE: 'Are you sure you want to delete',
  ARE_YOU_SURE_CANCEL: 'Are you sure you want to cancel',
  PROFILE_IMAGE: 'Profile Image',
  UPLOAD_A_PHOTO: 'Upload a Photo',
  TAKE_A_PHOTO: 'Take a Photo',
  REMOVE: 'Remove',
  CHOOSE_FONT: 'Choose Font',
  DISTRIBUTE_TOP: 'Distribute the Top',
  DISTRIBUTE_TOP_: 'Distribute Top',
  MARK_AS_TRADABLE: 'Mark as Tradable',
  MARK_AS_NON_TRADEABLE: 'Mark as Untradable',
  QUANTITY: 'Quantity',
  BUY_AGAIN: 'Buy again',
  SEARCH_USER: 'Search User',
  INDIVIDUAL: 'Individual',
  GROUP: 'Group',
  BACK: 'Back',
  FRONT: 'Front',
  CREATE_TRADE_OFFER: 'Create Trade Offer',
  MOVE_TO_COLLECTION: 'Move to Collection',
  PROFILE_APPROVE: 'Are you sure you want to approve this profile?',
  OFFER_APPROVE: 'Are you sure you want to approve this\n offer?',
  OFFER_REJECT: 'Are you sure you want to reject this\n offer?',
  REQUEST_APPROVE: 'Are you sure you want to approve this\n request?',
  REQUEST_REJECT: 'Are you sure you want to reject this\n request?',
  OFFER_CANCEL: 'Are you sure you want to cancel this\n offer?',
  PROFILE_REJECT: 'Are you sure you want to reject this profile?',
  ADD_USERS: 'Add Users',
  SEARCH_USERS: 'Search users by name, email, or username',
  USER_LIST: 'User List',
  ADD_NEW: '+ Add New',
  GROUP_LIST: 'GroupList',
  TRADE_APPROVE_LIST: 'TradeApproveList',
  DISTRIBUTE_APPROVE_LIST: 'DistributeApproveList',
  USER_LIST_2: 'UserList',
  USERS: 'Users',
  USER: 'User',
  INVITATION: 'invitation',
  INVITE_YOU_TO_JOIN: ' sent you an invite to join the ',
  GROUP_WITH_DOT: ' group.',
  SENT_YOU_AN: 'sent you an',
  APPROVAL_REQUEST: 'Approval Request',
  INVITATION_FOR_JOINING: 'Invitation to join a Group',
  MY_MANTEL: 'My Mantel',
  GROUP_INVITATION: 'Group Invitation',
  ICON: 'Icon',
  COMING_SOON: 'Coming Soon',
  SETTING: 'Settings',
  YOU_CAN_SELECT_ONE: 'You can select only one option.',
  PARENT_GUARDIAN_MUST:
    'Parent/Guardian must approve all Group membership requests',
  PARENT_GUARDIAN_ALLOW:
    'Parent/Guardian allows the child account to accept/reject Group membership requests without Parent/Guardian approval',
  ADD_NEW_USER: '+ Add New User',
  ADD_DISTRIBUTOR: '+ Add Distributor`s',
  ADD_NEW_USER_ONLY: 'Add New User',
  LEAVE_GROUP: 'Leave Group',
  SELECT_YOUR_TOPS_FOR_TRADE: 'Select your Tops to trade',
  ADD_NOW: 'Add now',
  DELETE_GROUP: 'Delete Group',
  EDIT_GROUP: 'Edit Group',
  ARE_YOU_SURE_DELETE: 'Are you sure you want to delete ',
  ORDER: 'Order',
  DONE: 'Done',
  RECEIVED_OFFER: 'Received Offer',
  SENT_OFFER: 'Sent Offer',
  _TRADE_OFFER: 'Trade offer',
  TRADE_OFFER: 'Received Trade Offer Request',
  SENT_OFFER_MODAL: 'Sent Trade Offer Request',
  CANCEL_OFFER: 'Cancel Offer',
  CHILD_TRADE_RECEIVED_LIST: 'ChildTradeReceivedList',
  CHILD_TRADE_SENT_LIST: 'ChildTradeSentList',
  CHILD_DISTRIBUTE_REQUEST_LIST: 'ChildDistributeRequestList',
  TRADE_DECS:
    'You will receive these tops from Cooper Dias.  Instead of saying My Tops-Exchange with it would say, You will give this Top to Cooper Dias',
  SUCCESSFULLY_TOP_DISTRIBUTED: 'Top Distribution request successfully sent.',
  ADD: 'Add',
  MINNUS: 'Minus',
  DISTRIBUTE_NOW: 'Distribute now',
  ARE_YOU_SURE_REMOVE:
    'Are you sure you want to remove this user from the Group?',
  ARE_YOU_SURE_LEAVE: 'Are you sure you want to leave the Group?',
  TEXT_COLOR: 'Text Color',
  OUTLINE_COLOR: 'Outline Color',
  APPROVE_INVITATION: 'Are you sure you want to approve this invitation?',
  ACCEPT_INVITATION: 'Are you sure you want to accept this invitation?',
  REJECT_INVITATION: 'Are you sure you want to reject this invitation?',
  SEARCH_GROUPS: 'Search Groups',
  ACCEPTED: 'Accepted',
  ACCEPT: 'Accept   ✓',
  ACCEPT_TEXT: 'Accept',
  NEXT: 'Next',
  TOP_TRADING: 'Top Trading Approval',
  DISTRIBUTING_TOP_REQUEST: 'Top Distribution Request',
  SHIFTING_TO_TRADEABLE: 'Shifting to Tradable Request',
  TOP_RECEIVED: 'Received ',
  TOP_SENT: 'Sent',
  TOP_APPROVED_LIST: 'Approved List',
  SELECT_ONE_OPTION: 'You can select only one option.',
  APPROVE_ALL_TRADE:
    'Parent/Guardian must approve all incoming and outgoing Trade Requests.',
  APPROVE_ALL_TRADABLE:
    'Parent/Guardian must approve all changes to Tradable/Untradable status',
  ALLOW_CHILD_ACCOUNT:
    'Child may propose and receive Trade Requests without Parent/Guardian approval.',
  REQUIRE_PARENT_APPROVEL:
    'Parent/Guardian allows child account to\nmake Trades without Parent/Guardian\napproval to the people on the pre-\n approval list.  All other trade requests\nrequire Parent/Guardian approval',
  ACCEPT_GROUP_MEMBERSHIP:
    'Parent/Guardian allows child account to\nmake trades without Parent/Guardian\napproval to the people on the pre-\n approval list.  All other trade requests\nrequire Parent/Guardian approval',
  ACCEPT_TRDABLE_MEMBERSHIP:
    'Parent/Guardian allows child account to make changes to Tradable/Untradable status without Parent/Guardian approval',
  TRADE_SETTING_FOR_PRE_APPROVAL:
    'Parent/Guardian must approve all incoming and outgoing Trade Requests except for those users on the “Approved List” for which no Parent/Guardian approval is required.',
  TOP_LIST: 'Top List',
  SELECT_USER: 'Select User',
  EDIT_TOPS: 'Edit Tops',
  CANCEL_OFFER_WITH_CROSS: 'Cancel Offer   x',
  GO_TO_TRADE: 'Go to Trade',
  SAVE_NOW: 'Save Now',
  REQUEST_SENT: 'Request Sent\nSuccessfully!',
  PLEASE_WAIT:
    'Your request has been sent and is\nnow waiting for approval. Please wait for the\n response.',
  PENDING_IN_CAPS: 'PENDING',
  CANCELLED_IN_CAPS: 'CANCELLED',
  REJECTED_IN_CAPS: 'REJECTED',
  ACCEPTED_IN_CAPS: 'ACCEPTED',
  INVALID_IN_CAPS: 'INVALID',
  CANCELLED_IN_SMALL: 'Canceled',
  REJECTED_IN_SMALL: 'Rejected',
  ACCEPTED_IN_SMALL: 'Accepted',
  PENDING_IN_SMALL: 'Pending',
  SEND_OFFER: 'Send Offer',
  SEND_OFFER_MODAL: 'Send Trade Offer Request',
  TOP_BUYING_APPROVAL: 'Top Buying Approval',
  TOP_DETAIL: 'Top Detail',
  APPROVE_ALL_PURCHASES: 'Parent/Guardian must approve all purchases',
  ALLOW_CHILD_PURCHASES:
    'Parent/Guardian allows child account to make purchases without Parent/Guardian approval',
  REQUIRE_PARENT_APPROVEL_FOR_PURCHASES:
    'Parent/Guardian allows child account to make purchases without Parent/Guardian approval up to a set amount each month. All purchases that exceed the monthly limit require Parent/Guardian approval',
  COUNTER_OFFER: 'Counter Offer',
  SUBMIT_COUNTER_OFFER: 'Submit the Counter Offer',
  COUNTER_OFFER_SENT: 'Counter Offer Sent\nSuccessfully!',
  OWNER: 'Owner',
  REQUEST: 'Request',
  ALL_DISTRIBUTION_REQUEST:
    'Parent/Guardian must approve all incoming and outgoing Top distributions.',
  MUST_APPROVE_REQUEST:
    'Child may receive and distribute Tops without Parent/Guardian approval.',
  APPROVED_LIST:
    'Parent/Guardian must approve all incoming and outgoing Top distributions except for those users on the “Approved List” for which no Parent/Guardian approval is required.',
  SEARCH_BY_TOPS: 'Search Tops by Top title, owner name, or hashtags.',
  OPTIONS_RECEIVED_OFFERS: `Create an offer by clicking on the 'Trade' icon.`,
  NO_RECEIVED_OFFERS: 'No offers received to date',
  NO_SENT_OFFERS: 'No offers sent to date',
  LOWERCASE: 'lowercase',
  ADD_TO_CART: 'Add to Cart',
  MY_CART: 'My Cart',
  TOP: 'Top Name',
  CREATED_DATE: 'Created on: Jan 01, 2023',
  HASH: '#Sportstop',
  DUMMY_TEXT:
    ' urna tortor. sapien volutpat felis, luctus sit faucibus Nam volutpat tincidunt quis nisl. cursus cursus lacus nulla, viverra lorem.faucibus sodales.',
  YOUR_CART_EMPTY: 'Oops! Your cart seems to be empty.',
  PLEASE_ADD_ITEM: 'Please add items to your cart to continue shopping',
  SAVE: 'Save',
  CHANGE_GROUP_INVITATION_SETTING:
    'Are you sure you want to change the Group invitation setting?',
  TRADEABLE_EMPTY: 'Your tradable request list\n is currently empty.',
  CHANGE_TRADABLE_SETTING:
    'Are you sure you want to change the Tradable/Untradable request setting?',
  CHANGE_TRADE_SETTING:
    'Are you sure you want to change the Top trading approval setting?',
  CHANGE_DISTRIBUTE_TOP_SETTING:
    'Are you sure you want to change the Top distribution setting?',
  APPROVE_TRADABLE_REQUEST: 'Are you sure you want to approve this request?',
  REJECT_TRADABLE_REQUEST: 'Are you sure you want to reject this request?',
  SHIFTING_TO_TRADEABLE_APPROVED:
    'Change to Tradable/Untradable Request approved',
  SHIFTING_TO_TRADEABLE_REJECTED:
    'Change to Tradable/Untradable Request rejected.',
  USER_REMOVED_FROM_APPROVAL_LIST:
    'Are you sure you want to \nRemove from Approval List',
  SELECTED_TOP_TO_DISTRIBUTE: 'Selected Top to distribute',
  NO_REQUEST_TO_SHOW: 'There are currently no Top \nrequests to show.',
  DISTRIBUTE_REQUEST_APPROVE:
    'Are you sure you want to \napprove this request?',
  DISTRIBUTE_REQUEST_REJECT: 'Are you sure you want to \nreject this request?',
  MARK_TRADABLE: 'mark-tradable',
  TOP_DISTRIBUTE: 'top/distribute',
  SHIFTING_REQUEST_SENT:
    'Change to Tradable/Untradable request has been sent to the Parent/Guardian.',
  SHIFTING_REQUEST_SENT_ALREADY:
    'Change to Tradable/Untradable request has been sent to the Parent/Guardian.',
  DISTRIBUTE_REQUEST_SENT:
    'Top distribution approval request has been sent to the Parent/Guardian.',
  CHANGE_PASSWORD_FAILED: 'Password entered is incorrect.',
  COMMERICIAL_MESSAGE:
    'Please confirm that the image you have uploaded is either owned by you or has no legal protections such as a trademark or a copyright',
  NEW_COLLECTION: 'New Collection',
  COLLECTION_DELETED: 'Collection successfully deleted',
  MAKING_TRADE_UNTRADBLE_ALERT:
    'Are you sure that you want to mark the Top as Untradable?\n\nMarking a Top as Untradable will cancel any active trade offers for that Top.',
  MOVE_TOPS_CONFIRM_1: 'Are you sure that you want to move the Tops to',
  MOVE_TOPS_CONFIRM_2:
    'Moving Tops from one collection to another will cancel any active trade offer for that Top.',
  CONFIRM: 'Confirm',
  APP_SLOGAN: 'Trade, share, and collect mementos...forever',
  NO_INTERNET_TITLE: "It looks like you're offline",
  NO_INTERNET_DESC:
    'Check if your device is connected to a\nmobile network or wifi',
  CONFIRM_LOGOUT: 'Are you sure want to logout?',
  DELETE_USER: 'Are you sure want to delete this account?',
  CHANGE_BUYING_SETTING:
    'Are you sure you want to change the Top buying approval setting?',
  PAYMENT_SUCCESS: 'Purchase Successful!',
  CONGRATULATIONS: `Congratulations! Your payment has been successfully processed.`,
  THANK_YOU: `Thank you for choosing our services and\ncompleting the transaction.`,
  GO_TO_MANTEL: 'Go to Mantel',
  PAYMENT_FAILED: 'Payment Failed!',
  GO_TO_CART: 'Go to Cart',
  REGRET: `We regret to inform you that your payment\nattempt was unsuccessful.`,
  APOLOGIZE: `We apologize for any inconvenience this\nmay have caused.`,
  GO_TO_BACK: 'Go Back',
  APPLY_COUPON: 'Apply Coupon',
  APPLY_COUPON_CODE: 'Apply Coupon Code',
  APPLY: 'Apply',
  COUPON_PLACEHOLDER: 'eg. HDJSHA84736',
  COUPON_CODE_: 'couponCode',
  COUPON_APPLIED: 'Coupon Applied',
  REPORT_ABUSE: 'Report Abuse',
  WHY_ARE_YOUR: 'Why are you reporting this request?',
  HELP_US: 'Help us understand the problem better',
  DESCRIPTION: 'Description',
  DESCRIPTION_: 'description',
  RECEIVED: 'Received',
  REPORT: 'Report',
  CHILD_DISTRIBUTE_RECEIVED_REQUEST_LIST: 'ChildDistributeReceivedRequestList',
  DISTRIBUTE_TOP_HEADER: 'Distribute Top',
  DISTRIBUTION_REQUEST_APPROVE:
    'Are you sure you want to approve this\n distribution request?',
  DISTRIBUTION_REQUEST_REJECT:
    'Are you sure you want to reject this\n distribution request?',
  TRANSACTION_CANCELED: 'Transaction Canceled',
  WE_REGET_TO_INFORM_YOU_THAT:
    'We regret to inform you that your transaction has been canceled.',
  EACH_TOP_COSTS: 'Each top costs ',
  TOPCREDIT: ' TopCredit',
  TOPCREDIT_WITHOUT_SPACE: 'TopCredit',
  TOPCREDITS: ' TopCredits',
  TOPCREDITS_WITHOUT_SPACE: 'TopCredits',
  CHECKOUT: 'Checkout',
  TOTAL_TOPS_IN_CART: 'Total Tops in Cart: ',
  MY_WALLET_BALANCE: 'My Wallet Balance',
  TopCredits_To_Wallet: ' TopCredits to Wallet',
  TRANSACTION_HISTORY: 'Transaction History',
  TODAY: 'Today',
  GO_TO_Wallet: 'Go to Wallet',
  BALANCE: 'Balance: ',
  NO_TRANSACTION_YET: 'No transactions to date',
  YOU_HAVE_NOT_MADE: `You have no transactions to date`,
  ORDER_SUMMARY: 'Order Summary',
  ADD_TOPCREDIT: 'Add TopCredits',
  WALLET_BALANCE: 'Wallet Balance',
  BUY_WITH_TOPCREDIT: 'Buy with 10 TopCredits',
  TOTAL_ORDER: 'Total Order',
  TOPS_USING: ' Tops using ',
  COUPON_DISCOUNT: 'Coupon Discount',
  GRAND_TOTAL: 'Grand Total',
  PAY_NOW: 'Pay Now',
  PAY_WITH_WALLET: 'Pay with TopCredits',
  PAY_WITH_CARD: 'Pay ',
  REQUEST_FOR_PACK_PURCHASE: 'Request to buy TopCredits',
  PURCHASE_TOP: 'Purchase Top',
  PURCHASE_TOPCREDITS: 'Purchase TopCredits',
  GO_TO_ORDER_SUMMARY: 'Go To Order Summary',
  HELP_AND_SUPPORT: 'Help and Support',
  COPYRIGHT: 'Copyright Policy',
  ETIQUTTE: 'Etiquette Policy',
  FAQS: 'FAQs',
  PRIVACY_POLICY: 'Privacy Policy',
  TERMS_CONDITIONS: 'Terms of Service',
  REQUEST_TO_PURCHASE_TOPS: 'Request to buy Tops',
  BLOCK_USER: 'Blocked User',
  SORRY_NO_USER_BLOCK: 'You have no blocked users.',
  UNBLOCK: 'Unblock',
  REPORT_THIS_TOP: 'Report this Top',
  BLOCK_AND_REPORT: 'Block and Report',
  CREATED_BY: 'Created by: ',
  CONGRATULATIONS_ONLY: 'Congratulations!',
  YOU_HAVE_BEEN: `You've been awarded a Welcome Top.`,
  GET_STARTED: 'Get Started',
  FAVORITES: 'Favorites',
  INVITE: 'Invite',
  GROUPS: 'Groups',
  MANTELVERSE: 'Mantelverse',
  DISTRIBUTE_VIA_EMAIL: 'Distribute Via Email',
  DISTRIBUTE_VIA_PHONE: 'Distribute Via Text',
  DISTRIBUTE_INPUT_PLACEHOLDER: 'Enter email or phone',
  DISTRIBUTE_VIA_: 'DistributeVia',
  IF_YOU_DONT_HAVE: `If you have more than 5 emails/texts to include please upload a CSV file with the required information.  A CSV template can be downloaded below.`,
  OR: 'OR',
  UPLAOD_CSV: 'Upload CSV',
  SAMPLE_CSV: 'Sample CSV',
  SENT_INVITE: 'Send Invite',
  EMAIL_OR_MOBILE: 'email_mobile',
  TEXT: 'Text',
  APPLY_REEDEM_CODE: 'Enter Redemption Code',
  REEDEM_CODE_: 'reedemCode',
  ENTER_CODE: 'Enter Code',
  APPLY_CODE: 'Apply Code',
  SORRY_NO_FAVORITE_USER: 'You have no favorite users.',
  FAVOURITE_LIST: 'FAVOURITE_LIST',
  DISTRIBUTION: 'Distribution:',
  TRADE_OFFER_2: 'Trade offer:',
  MOVE_TOPS_CONFIRM_3: `Are you sure that you want to move all the selected \ntops`,
  NOTES: 'Notes',
  ADD_NEW_NOTE: '+ Add Note',
  ADDED_BY: 'Added by ',
  ADD_NEW_NOTE_: 'Add Note',
  EDIT_NEW_NOTE_: 'Edit Note',
  YOU_WANNA_DELETE_THIS_NOTE: 'Are you sure you want to delete this note?',
  NOTES_LIST: 'NOTES_LIST',
  TOP_DISTRIBUTE_BY: 'Top distributed by',
  ACTION_NEEDED: 'Action Needed',
  COMPLETED: 'Completed',
  DISTRIBUTE_TOP_REQUEST: 'Distribute Top Request',
  SET_THE_MONTHLY: 'Set the monthly limit ($)',
  SELECT_ONE_OPTION_IN_EACH: 'You can select only one option in each section.',
  DELETE_NOTE: 'Delete Note',
  RECEIVE_SMS: 'Receive SMS',
  SMS_NOTIFICATION: 'SMS Notification',
  I_AGREE_TO_RECEIVE: `I agree to receive `,
  TRANSFER_TOP_CREDITS: 'Transfer Top Credits',
  TOP_CREDITS_TRANSFER_BACK: 'Top Credits Transfer Back',
  TOP_CREDIT_TO_TRANSFER: 'Top Credits to transfer from',
  TRANSFER: 'Transfer',
  SENT: 'Sent',
  EG_10: 'eg. 10',
  TOP_CREDIT_: 'topCredit',
  NO_CHILD_ADDED: 'No child added',
  HOW_MANY_TOP_CREDIT: 'How many top credits do you want to transfer back?',
  PULLBACK: 'Transfer Back',
  SEARCH_TOP_AND_USERS_PLACEHOLDER: 'Search Tops and Users',
  SEARCH_TOPS_AND_USERS: ' Tops and Users',
  SEARCH: 'Search "',
  TOPS: 'Tops',
  NO_TRACKING_LIST: 'You have no tracking in list.',
  TOP_DISTRIBUTION_PENDING: 'Top Distribution Pending',
  TOP_DISTRIBUTION: 'Top Distribution',
  GROUP_INVITATION_PENDING: 'Group Invitation Pending',
  YOU_SENT_TOP_DISTRIBUTION: 'You sent a Top Distribution.',
  INVITATION_TO_JOIN: 'Invitation to join',
  WELCOME_TO_MANTELTOP_MSG: 'Welcome to Manteltop',
  REMOVE_TOP: 'Remove Top',
  FROM_LIST: 'From list',
  NO_TRACKING_DATA: '   You have no tracking item in list',
  CANCEL_USER: 'Cancel User',
  REQUEST_CANCLED: 'Request successfully canceled.',
  REMINDER_SENT: 'Reminder has been sent to the user.',
  NO_TRACKING_USER: ' No data found.',
  SUCCESS: 'Success',
  FAILED: 'Failed',
  ERROR_ALERT: 'An error occurred',
  ERROR: 'Error',
  ENTER_YOUR_EMAIL: 'Enter your Email',
  ENTER_YOUR_MOBILE_NUMBER: 'Enter your Mobile Number',
  SEND_EMAIL: 'Send Email',
  SEND_TEXT: 'Send Text',
  FROM_THE_LIST: 'From the favorites list.',
  TO_THE_LIST: 'to the favorites list.',
  YOU_HAVE_REMOVED: 'You have removed',
  YOU_HAVE_ADDED: 'You have added',
  SEARCH_FRIENDS: 'Search Friends',
  TUTORIALS: 'Tutorials',
  VERIFY_MOBILE: 'Verify Mobile',
  VERIFY_USERNAME_MOBILE_DESC:
    'A verification code has been sent to the mobile associated with username ',
  VERIFY_USERNAME_EMAIL_DESC:
    'A verification code has been sent to the email associated with username ',
  VERIFY_USERNAME_MOBILE: 'A verification code has been sent to the mobile no ',
  CANCEL_ALL: 'Cancel All',
  REMIND_ALL: 'Remind All',
  REMIND_ALL_SENT: 'All recipients have been reminded.',
  ARE_YOU_SURE_REMIND_ALL: 'Are you sure you want to remind all recipients?',
  RETRIVE_USER_NAME:
    'Please select which user you wish to retrieve the username from:',
  DISTRIBUTE_USER_NAME:
    'Please select which user you wish to distribute this top to:',
  MANUAL: 'manual',
};
export const REQUEST_TYPE = {
  DISTRIBUTION: 'DISTRIBUTION',
  GROUP: 'GROUP',
  DISTRIBUTION_EMAIL: 'DISTRIBUTION_EMAIL',
  TUTORIALS_VIDEO: 'Tutorials',
};
export const TOAST_TEXT = {
  PROFILE_UPDATED_SUCCESSFULLY: 'Profile image successfully updated.',
  INVITATION_SENT_SUCCESSFULLY:
    'The invitation has been sent successfully to selected users.',
  GROUP_SUCCESFULLY_CREATED: 'Group successfully created.',
  GROUP_SUCCESFULLY_UPDATED: 'Group successfully updated.',
  GROUP_SUCCESFULLY_DELETED: 'Group successfully deleted.',
  USER_SUCCESSFULLY_REMOVED: 'User successfully removed from the group.',
  TOP_TRADING_APPROVAL_SUCCESFULLY_CHANGED:
    'Top Trading Approval Successfully Changed.',
  CHILD_REQUEST_NOT_APPOVED:
    "The child profile approval request is pending, please confirm the child's account first.",
  USER_SUCCESSFULLY_ADDED: 'User Successfully Added.',
  USER_SUCCESFULLY_REMOVED_FROM_TRADE_LIST:
    'User removed from the Approved Trade List.',
  CHILD_TRADE_SUCCESSFULLY_REMOVED: 'Trade Withdrawn.',
  CHILD_TRADE_SUCCESSFULLY_APPROVED: 'Trade Sent by Child Approved.',
  CHILD_TRADE_SUCCESSFULLY_REJECT: 'Trade Sent by Child Rejected.',
  CHILD_TRADABLE_SETTING_SUCCESS:
    'Tradable/Nontradable request setting changed.',
  DISTRIBUTE_TOP_APPROVAL_SETTING_CHANGE:
    'Top Distribution Approval Setting Changed.',
  USER_SUCCESFULLY_REMOVED_FROM_DISTRIBUTE_LIST:
    'User removed from the distribution list.',
  CHILD_DISTRIBUTE_SUCCESSFULLY_REJECT:
    'Distribution Top request sent by Child Rejected.',
  CHILD_DISTRIBUTE_SUCCESSFULLY_APPROVED:
    'Distribution Top request sent by Child Approved.',
  PROFILE_SUCCESSFULLY_UPDATED: 'Profile updated.',
  GROUP_SUCCESSFULLY_LEFT: 'You have left the Group.',
  GROUP_INVITATION_ACCEPTED: 'Group invitation Accepted.',
  GROUP_INVITATION_REJECTED: 'Group invitation Rejected.',
  OFFER_SUCCESSFULLY_ACCEPTED: 'Trade offer accepted.',
  OFFER_SUCCESSFULLY_REJECTED: 'Trade Offer Rejected.',
  LIMIT_EXHAUSTED:
    'You have reached your monthly limit. Your Parent/Guardian will need to increase the limit for you to complete this transaction.',
  THANKS_FOR_REPORTING_ONLY: `Thank you for reporting`,
  THANKS_FOR_REPORTING: `Your feedback is important to help us to keep Manteltop safe and fun for all users. Our support staff will investigate this report.`,
  DISTRIBUTE_SUCCESSFULLY_REJECT: 'Top distribution request Rejected.',
  DISTRIBUTE_SUCCESSFULLY_APPROVED: 'Top distribution request Approved.',
  PURCHASED_PACK_REQUEST_SENT_TO: `Purchase request sent to Parent/Guardian`,
  PURCHASED_TOPCREDITS_REQUEST_REJECT: 'TopCredits purchase request Rejected',
  INSUFFICIENT_CREDIT: `Insufficient TopCredits in Child's Wallet`,
  BLOCK_USER_MAIN_TEXT: 'Are you sure?',
  BLOCK_ALERT_SUB_TEXT: `You won't be able to view the Tops and the profile of the user.`,
  USER_SUCCESFFULLY_UNBLOCKED: 'User has been unblocked',
  DISTRIBUTE_TOP_VIA_EMAIL_OR_TEXT: `You will be notified via email when the invitation has been sent`,
  SAMPLE_CSV_DOWNLOAD_FOR_DISTRIBUTE: 'Sample CSV Downloaded',
  YOU_HAVE_RECEIVED_THE_TOP: `You have received the Top`,
  USER_REMOVED_FROM_FAVORITE: `User removed from Favorites List.`,
  ONLY_CSV_CAN_UPLOAD: 'Only CSV File are Allowed',
  NOTE_ADDED: `Note successfully added.`,
  NOTE_UPDATED: `Note successfully updated.`,
  NOTE_DELETED: `Note successfully deleted.`,
  GROUP_INVITATION_SETTING: 'Group Invitation setting changed successfully.',
  TOP_BUYING_APPROVAL: 'Top Buying Approval setting changed successfully.',
  TOP_CRDITS_TRANSFER_TO: ' Tops Credits transfered to ',
  INVITATION_HAS_BEEN_SENT: 'Invitation has been sent.',
  PURCHASED_REQUEST_SENT:
    'Purchased request sent to Parent/Guardian successfully',
  THE_EMAIL_HAS_BEEN_SENT: 'The username has been sent to your email address.',
  THE_TEXT_HAS_BEEN_SENT: 'The username has been sent to your mobile number.',
  INCORRECT_OLD_PASS: 'Incorrect old password. Please try again.',
  ATTEMPT_LIMIT: 'Attempt limit exceeded. Please try again later.',
  USER_NOT_FOUND: 'User not found. Please check your credentials.',
  INVALID_NEW_PASS:
    'Invalid new password. Please ensure it meets the required criteria.',
};

export const PROFILE_OPTIONS_TEXT = {
  GROUP_MANAGEMENT: 'Group Management',
  CHANGE_PASSWORD: 'Change Password',
  HELP_SUPPORT: 'Help, Support and Documents',
  TERMS_CONDITIONS: 'Terms of Service',
  PRIVACY_POLICY: 'Privacy Policy',
  DELETE_ACCOUNT: 'Delete Account',
  BLOCK_USER: 'Blocked User',
  FAVORITES: 'Favorites',
  TUTORIALS_VIDEO: 'Tutorials',
};

export const CHANGE_PASSWORD_ERROR_TYPE = {
  NotAuthorizedException: 'NotAuthorizedException',
  LimitExceededException: 'LimitExceededException',
  UserNotFoundException: 'UserNotFoundException',
  InvalidPasswordException: 'InvalidPasswordException',
};
