import React from 'react';
import {GestureResponderEvent} from 'react-native';
import {G, Rect} from 'react-native-svg';
import {COLORS, FixedValue} from '../../../../constants';
import {EditorContext} from '../../../../contextAPI/editorContext';
import {
  ShapeItemPositionOverride,
  ShapeRefType,
  ShapeState,
  TopEditorItemProps,
  TopEditorItemType,
} from '../../../../types/componentTypes/editorType';
import {isWebsite} from '../../../../utils/responsive';

export const RECTANGLE_SIZE = {
  height: FixedValue.CONSTANT_VALUE_250,
  width: FixedValue.CONSTANT_VALUE_500,
};

const BasicRectangle = React.forwardRef(
  (
    {
      itemPositionOverride,
      elementId,
      elementIndex,
      selectItemCallback,
      disableInteraction,
    }: TopEditorItemProps<ShapeItemPositionOverride>,
    ref: React.ForwardedRef<ShapeRefType>
  ) => {
    const {
      editorControlItemDimensions,
      editorSvgDimensions,
      selectedItemIndex,
    } = React.useContext(EditorContext);

    const _itemHeight: number = RECTANGLE_SIZE.height;
    const _itemWidth: number = RECTANGLE_SIZE.width;
    const otherScale = isWebsite()
      ? FixedValue.CONSTANT_VALUE_025
      : FixedValue.CONSTANT_VALUE_05;

    const [isDragging, setIsDragging] = React.useState<boolean>(false);
    const [position, setPosition] = React.useState<ShapeState>({
      x: itemPositionOverride?.x
        ? itemPositionOverride.x
        : editorSvgDimensions.width / FixedValue.CONSTANT_VALUE_2,
      y: itemPositionOverride?.y
        ? itemPositionOverride.y
        : editorSvgDimensions.height / FixedValue.CONSTANT_VALUE_2,
      width: itemPositionOverride?.width
        ? itemPositionOverride.width
        : _itemWidth,
      height: itemPositionOverride?.height
        ? itemPositionOverride.height
        : _itemHeight,
      isSelected: false,
      offset: {x: FixedValue.CONSTANT_VALUE_0, y: FixedValue.CONSTANT_VALUE_0},
      scale: itemPositionOverride?.scale
        ? itemPositionOverride.scale
        : otherScale,
      fillColor: itemPositionOverride?.fillColor
        ? itemPositionOverride?.fillColor
        : COLORS.WHITE,
      strokeColor: itemPositionOverride?.strokeColor
        ? itemPositionOverride?.strokeColor
        : COLORS.BLACK,
      strokeWidth: itemPositionOverride?.strokeWidth
        ? itemPositionOverride?.strokeWidth
        : FixedValue.CONSTANT_VALUE_1,
      rotation: itemPositionOverride?.rotation
        ? itemPositionOverride.rotation
        : FixedValue.CONSTANT_VALUE_0,
    });
    const [itemIndex, setItemIndex] = React.useState<number>(elementIndex);

    React.useImperativeHandle(
      ref,
      () => ({
        getName: () => 'Rectangle',
        getPosition: () => position,
        renderElementToSave: () => renderNonInteractive(),
        getItemOrderTabItem: () => renderItemOrderTabItem(),
        deselect: () => setPosition(prev => ({...prev, isSelected: false})),
        changeIndex: (newIndex: number) => setItemIndex(newIndex),
        changeScale: (newScale: number) =>
          setPosition(prev => ({...prev, scale: newScale})),
        changeFillColor: (color: string): void =>
          setPosition(prev => ({...prev, fillColor: color})),
        changeStroke: (color: string, width?: number) =>
          setPosition(prev => ({
            ...prev,
            strokeColor: color,
            strokeWidth: !!width ? width : prev.strokeWidth,
          })),
        changeRotation: (newRotation: number): void =>
          setPosition(prev => ({
            ...prev,
            rotation:
              newRotation == FixedValue.CONSTANT_VALUE_360
                ? FixedValue.CONSTANT_VALUE_0
                : newRotation,
          })),
        getTopEditorItemType: (): TopEditorItemType => TopEditorItemType.SHAPE,
        getInitialScale: (): number => otherScale,
      }),
      [position, itemIndex]
    );

    const isThisShapeSelected: boolean =
      (selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1 &&
        position.isSelected) ||
      selectedItemIndex === FixedValue.CONSTANT_VALUE_MIN_1;

    const handlePointerDown = React.useCallback(
      (event: GestureResponderEvent): void => {
        event.stopPropagation();
        if (selectItemCallback) selectItemCallback(itemIndex);
        const el = event.target;
        const x = event.nativeEvent.pageX - position.x;
        const y = event.nativeEvent.pageY - position.y;
        // @ts-ignore Correct usage for web
        if (isWebsite()) el.setPointerCapture(event.pointerId);
        setPosition({
          ...position,
          isSelected: true,
          offset: {x, y},
        });
        setIsDragging(true);
      },
      [position, isDragging]
    );

    const handlePointerMove = React.useCallback(
      (event: GestureResponderEvent): void => {
        const diffX = event.nativeEvent.pageX - position.x;
        const diffY = event.nativeEvent.pageY - position.y;
        const cx: number = position.x - (position.offset.x - diffX);
        const cy: number = position.y - (position.offset.y - diffY);
        if (position.isSelected && isDragging) {
          setPosition({
            ...position,
            x: cx,
            y: cy,
          });
        }
      },
      [position, isDragging]
    );

    const handlePointerUp = React.useCallback((): void => {
      setIsDragging(false);
    }, []);

    const getScaledDimensions = React.useCallback(
      (): {width: number; height: number} => ({
        width: _itemWidth * position.scale,
        height: _itemHeight * position.scale,
      }),
      [position.scale]
    );

    const renderItemOrderTabItem = (): JSX.Element => {
      return (
        <G>
          <Rect
            // @ts-ignore Used to show id when testing
            testID={`TE-rectangle-item-order-control-item-selection-box`}
            accessibilityLabel={`TE-rectangle-item-order-control-item-selection-box`}
            key={`${elementId}-outer`}
            id={`${elementId}-outer`}
            x={FixedValue.CONSTANT_VALUE_0}
            y={FixedValue.CONSTANT_VALUE_0}
            height={editorControlItemDimensions.height}
            width={editorControlItemDimensions.width}
            fill={COLORS.WHITE}
            fillOpacity={FixedValue.CONSTANT_VALUE_0}
          />
          <Rect
            // @ts-ignore Used to show id when testing
            testID={`TE-rectangle-item-order-control-item`}
            accessibilityLabel={`TE-rectangle-item-order-control-item`}
            key={elementId}
            id={elementId}
            x={FixedValue.CONSTANT_VALUE_1}
            y={FixedValue.CONSTANT_VALUE_4}
            width={FixedValue.CONSTANT_VALUE_23}
            height={FixedValue.CONSTANT_VALUE_15}
            fill={position.fillColor}
            stroke={position.strokeColor}
            strokeWidth={position.strokeWidth}
          />
        </G>
      );
    };

    const renderNonInteractive = (): JSX.Element => {
      const rotate: string = `rotate(${position.rotation}, ${position.x}, ${position.y})`;
      return (
        <Rect
          id={`rectangle-top-element-${itemIndex}`}
          key={elementId}
          x={
            position.x -
            getScaledDimensions().width / FixedValue.CONSTANT_VALUE_2
          }
          y={
            position.y -
            getScaledDimensions().height / FixedValue.CONSTANT_VALUE_2
          }
          width={getScaledDimensions().width}
          height={getScaledDimensions().height}
          transform={`${rotate}`}
          fill={position.fillColor}
          stroke={position.strokeColor}
          strokeWidth={position.strokeWidth}
        />
      );
    };

    const renderInteractive = (): JSX.Element => {
      const rotate: string = `rotate(${position.rotation}, ${position.x}, ${position.y})`;
      return (
        <G
          transform={`${rotate}`}
          opacity={
            isThisShapeSelected
              ? FixedValue.CONSTANT_VALUE_1
              : FixedValue.CONSTANT_VALUE_05
          }
        >
          <Rect
            // @ts-ignore Used to show id when testing
            testID={`TE-${elementId}-selection-box`}
            accessibilityLabel={`TE-${elementId}-selection-box`}
            key={`selection-box-${elementId}`}
            x={
              position.x -
              getScaledDimensions().width / FixedValue.CONSTANT_VALUE_2 -
              FixedValue.CONSTANT_VALUE_10
            }
            y={
              position.y -
              getScaledDimensions().height / FixedValue.CONSTANT_VALUE_2 -
              FixedValue.CONSTANT_VALUE_10
            }
            width={getScaledDimensions().width + FixedValue.CONSTANT_VALUE_20}
            height={getScaledDimensions().height + FixedValue.CONSTANT_VALUE_20}
            fill={COLORS.WHITE}
            fillOpacity={FixedValue.CONSTANT_VALUE_0}
            stroke={position.isSelected ? COLORS.PRIMARY_BLUE : ''}
            strokeWidth={FixedValue.CONSTANT_VALUE_5}
          />
          <Rect
            // @ts-ignore Used to show id when testing
            testID={elementId}
            accessibilityLabel={elementId}
            key={elementId}
            id={elementId}
            x={
              position.x -
              getScaledDimensions().width / FixedValue.CONSTANT_VALUE_2
            }
            y={
              position.y -
              getScaledDimensions().height / FixedValue.CONSTANT_VALUE_2
            }
            width={getScaledDimensions().width}
            height={getScaledDimensions().height}
            // @ts-ignore Used to handle pointer events
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerMove={handlePointerMove}
            onPressIn={handlePointerDown}
            onPressOut={handlePointerUp}
            onResponderMove={handlePointerMove}
            fill={position.fillColor}
            stroke={position.strokeColor}
            strokeWidth={position.strokeWidth}
          />
        </G>
      );
    };

    return disableInteraction ? renderNonInteractive() : renderInteractive();
  }
);

export default BasicRectangle;
