import {createSlice} from '@reduxjs/toolkit';
import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {ResetAllState} from './logoutslice';
import {ITradeTab} from '../../types/reducerTypes/tradeTabTypes';
import {
  DISTRIBUTE_TOP_OFFER_DETAIL,
  TOP_TRADE_STATUS_UPDATE,
  TRADE_OFFER_DETAIL,
  TRADE_OFFER_REPORT,
} from '../../constants/actionTypes';

const {StoreConstants} = AppConst;
const {
  TRADE_RECEVIED_OFFER,
  TRADE_SENT_OFFER,
  APPROVE_RECEIVED_OFFER,
  REJECT_RECEIVED_OFFER,
  CANCEL_SENT_OFFER,
} = ActionType;

const initialState: ITradeTab = {
  receivedOffers: {
    isLoading: false,
    success: null,
    failed: null,
    status: TRADE_RECEVIED_OFFER.END,
  },
  sentOffers: {
    isLoading: false,
    success: null,
    failed: null,
    status: TRADE_SENT_OFFER.END,
  },
  approveOffer: {
    isLoading: false,
    success: null,
    failed: null,
    status: APPROVE_RECEIVED_OFFER.END,
  },
  rejectOffer: {
    isLoading: false,
    success: null,
    failed: null,
    status: REJECT_RECEIVED_OFFER.END,
  },
  cancelOffer: {
    isLoading: false,
    success: null,
    failed: null,
    status: CANCEL_SENT_OFFER.END,
  },
  tradeAbuseReport: {
    isLoading: false,
    success: null,
    failed: null,
    status: TRADE_OFFER_REPORT.END,
  },
  tradeOfferDetailWork: {
    isLoading: false,
    success: null,
    failed: null,
    status: TRADE_OFFER_DETAIL.END,
  },
  distributeTopDetailWork: {
    isLoading: false,
    success: null,
    failed: null,
    status: DISTRIBUTE_TOP_OFFER_DETAIL.END,
  },
  topTradeStatusUpdate: {
    isLoading: false,
    success: null,
    failed: null,
    status: TOP_TRADE_STATUS_UPDATE.END,
  },
};

const tradeTabSlice = createSlice({
  initialState,
  name: StoreConstants.TRADE_RECEVIED_SEND_OFFER,
  reducers: {
    onReceivedOfferStart: (state, _) => {
      state.receivedOffers.isLoading = true;
      state.receivedOffers.status = TRADE_RECEVIED_OFFER.START;
    },
    onReceivedOfferSuccess: (state, action) => {
      state.receivedOffers.success = action.payload;
      state.receivedOffers.status = TRADE_RECEVIED_OFFER.SUCCESS;
    },
    onReceivedOfferFailed: (state, action) => {
      state.receivedOffers.failed = action.payload;
      state.receivedOffers.status = TRADE_RECEVIED_OFFER.FAILED;
    },
    onReceivedOfferEnd: state => {
      state.receivedOffers.isLoading = false;
      state.receivedOffers.status = TRADE_RECEVIED_OFFER.END;
    },
    onSentOfferStart: (state, _) => {
      state.sentOffers.isLoading = true;
      state.sentOffers.status = TRADE_SENT_OFFER.START;
    },
    onSentOfferSuccess: (state, action) => {
      state.sentOffers.success = action.payload;
      state.sentOffers.status = TRADE_SENT_OFFER.SUCCESS;
    },
    onSentOfferFailed: (state, action) => {
      state.sentOffers.failed = action.payload;
      state.sentOffers.status = TRADE_SENT_OFFER.FAILED;
    },
    onSentOfferEnd: state => {
      state.sentOffers.isLoading = false;
      state.sentOffers.status = TRADE_SENT_OFFER.END;
    },
    onApproveOfferStart: (state, _) => {
      state.approveOffer.isLoading = true;
      state.approveOffer.status = APPROVE_RECEIVED_OFFER.START;
    },
    onApproveOfferSuccess: (state, action) => {
      state.approveOffer.success = action.payload;
      state.approveOffer.status = APPROVE_RECEIVED_OFFER.SUCCESS;
    },
    onApproveOfferFailed: (state, action) => {
      state.approveOffer.failed = action.payload;
      state.approveOffer.status = APPROVE_RECEIVED_OFFER.FAILED;
    },
    onApproveOfferEnd: state => {
      state.approveOffer.isLoading = false;
      state.approveOffer.status = APPROVE_RECEIVED_OFFER.END;
    },
    onRejectOfferStart: (state, _) => {
      state.rejectOffer.isLoading = true;
      state.rejectOffer.status = REJECT_RECEIVED_OFFER.START;
    },
    onRejectOfferSuccess: (state, action) => {
      state.rejectOffer.success = action.payload;
      state.rejectOffer.status = REJECT_RECEIVED_OFFER.SUCCESS;
    },
    onRejectOfferFailed: (state, action) => {
      state.rejectOffer.failed = action.payload;
      state.rejectOffer.status = REJECT_RECEIVED_OFFER.FAILED;
    },
    onRejectOfferEnd: state => {
      state.rejectOffer.isLoading = false;
      state.rejectOffer.status = REJECT_RECEIVED_OFFER.END;
    },
    onCancelOfferStart: (state, _) => {
      state.cancelOffer.isLoading = true;
      state.cancelOffer.status = CANCEL_SENT_OFFER.START;
    },
    onCancelOfferSuccess: (state, action) => {
      state.cancelOffer.success = action.payload;
      state.cancelOffer.status = CANCEL_SENT_OFFER.SUCCESS;
    },
    onCancelOfferFailed: (state, action) => {
      state.cancelOffer.failed = action.payload;
      state.cancelOffer.status = CANCEL_SENT_OFFER.FAILED;
    },
    onCancelOfferEnd: state => {
      state.cancelOffer.isLoading = false;
      state.cancelOffer.status = CANCEL_SENT_OFFER.END;
    },
    onTradeReportStart: (state, _) => {
      state.tradeAbuseReport.isLoading = true;
      state.tradeAbuseReport.status = TRADE_OFFER_REPORT.START;
    },
    onTradeReportSuccess: (state, action) => {
      state.tradeAbuseReport.success = action.payload;
      state.tradeAbuseReport.status = TRADE_OFFER_REPORT.SUCCESS;
    },
    onTradeReportFailed: (state, action) => {
      state.tradeAbuseReport.failed = action.payload;
      state.tradeAbuseReport.status = TRADE_OFFER_REPORT.FAILED;
    },
    onTradeReportEnd: state => {
      state.tradeAbuseReport.isLoading = false;
      state.tradeAbuseReport.status = TRADE_OFFER_REPORT.END;
    },
    onTradeOfferStart: (state, _) => {
      state.tradeOfferDetailWork.isLoading = true;
      state.tradeOfferDetailWork.status = TRADE_OFFER_DETAIL.START;
    },
    onTradeOfferSuccess: (state, action) => {
      state.tradeOfferDetailWork.success = action.payload;
      state.tradeOfferDetailWork.status = TRADE_OFFER_DETAIL.SUCCESS;
    },
    onTradeOfferFailed: (state, action) => {
      state.tradeOfferDetailWork.failed = action.payload;
      state.tradeOfferDetailWork.status = TRADE_OFFER_DETAIL.FAILED;
    },
    onTradeOfferEnd: state => {
      state.tradeOfferDetailWork.isLoading = false;
      state.tradeOfferDetailWork.status = TRADE_OFFER_DETAIL.END;
    },
    onDistributeTopDetailStart: (state, _) => {
      state.distributeTopDetailWork.isLoading = true;
      state.distributeTopDetailWork.status = DISTRIBUTE_TOP_OFFER_DETAIL.START;
    },
    onDistributeTopDetailSuccess: (state, action) => {
      state.distributeTopDetailWork.success = action.payload;
      state.distributeTopDetailWork.status =
        DISTRIBUTE_TOP_OFFER_DETAIL.SUCCESS;
    },
    onDistributeTopDetailFailed: (state, action) => {
      state.distributeTopDetailWork.failed = action.payload;
      state.distributeTopDetailWork.status = DISTRIBUTE_TOP_OFFER_DETAIL.FAILED;
    },
    onDistributeTopDetailEnd: state => {
      state.distributeTopDetailWork.isLoading = false;
      state.distributeTopDetailWork.status = DISTRIBUTE_TOP_OFFER_DETAIL.END;
    },
    onTopTradeStatusUpdateStart: (state, _) => {
      state.topTradeStatusUpdate.isLoading = true;
      state.topTradeStatusUpdate.status = TOP_TRADE_STATUS_UPDATE.START;
    },
    onTopTradeStatusUpdateSuccess: (state, action) => {
      state.topTradeStatusUpdate.success = action.payload;
      state.topTradeStatusUpdate.status = TOP_TRADE_STATUS_UPDATE.SUCCESS;
    },
    onTopTradeStatusUpdateFailed: (state, action) => {
      state.topTradeStatusUpdate.failed = action.payload;
      state.topTradeStatusUpdate.status = TOP_TRADE_STATUS_UPDATE.FAILED;
    },
    onTopTradeStatusUpdateEnd: state => {
      state.topTradeStatusUpdate.isLoading = false;
      state.topTradeStatusUpdate.status = TOP_TRADE_STATUS_UPDATE.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  onReceivedOfferEnd,
  onReceivedOfferFailed,
  onReceivedOfferStart,
  onReceivedOfferSuccess,
  onSentOfferEnd,
  onSentOfferFailed,
  onSentOfferStart,
  onSentOfferSuccess,
  onApproveOfferEnd,
  onApproveOfferFailed,
  onApproveOfferStart,
  onApproveOfferSuccess,
  onRejectOfferEnd,
  onRejectOfferFailed,
  onRejectOfferStart,
  onRejectOfferSuccess,
  onCancelOfferEnd,
  onCancelOfferFailed,
  onCancelOfferStart,
  onCancelOfferSuccess,
  onTradeReportStart,
  onTradeReportSuccess,
  onTradeReportFailed,
  onTradeReportEnd,
  onTradeOfferStart,
  onTradeOfferSuccess,
  onTradeOfferFailed,
  onTradeOfferEnd,
  onDistributeTopDetailStart,
  onDistributeTopDetailSuccess,
  onDistributeTopDetailFailed,
  onDistributeTopDetailEnd,
  onTopTradeStatusUpdateStart,
  onTopTradeStatusUpdateSuccess,
  onTopTradeStatusUpdateFailed,
  onTopTradeStatusUpdateEnd,
} = tradeTabSlice.actions;
export default tradeTabSlice.reducer;

export const TradeTabStore = (state: RootState) => state.tradeTab;
