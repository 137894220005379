import React from 'react';
import PrimaryButton from '../../components/button/primaryButton';
import LabelInput from '../../components/input/labelInput';
import {FixedValue, TEXT} from '../../constants';
import {GroupContext} from '../../contextAPI/groupManagementContext';
import useCreateEditGroupHooks from '../../hooks/componentHooks/useCreateEditGroupHooks';
import {useAppSelector} from '../../hooks/reduxHooks';
import {groupManagementStore} from '../../redux/reducers/groupManagementSlice';
import {CreateEditGroupModalProps} from '../../types/reducerTypes/searchTabStoreType';

const CreateEditGroupModalField = (props: CreateEditGroupModalProps) => {
  const {createNewGroup, updateGroup} = useAppSelector(groupManagementStore);
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    GroupNameError,
    isButtonDisabled,
  } = useCreateEditGroupHooks();
  const {isGroupEditMode} = React.useContext(GroupContext);
  const buttontext = isGroupEditMode ? TEXT.UPDATE : TEXT.CREATE;
  return (
    <>
      <LabelInput
        inputLabel={props.isEditMode ? TEXT.EDIT_GROUP_NAME : TEXT.GROUP_NAME}
        value={values.groupName}
        onChangeText={handleChange(TEXT.GROUP_NAME_)}
        onBlur={handleBlur(TEXT.GROUP_NAME_)}
        haveError={GroupNameError}
        maxLength={FixedValue.CONSTANT_VALUE_30}
      />
      <PrimaryButton
        isLoading={createNewGroup?.isLoading || updateGroup?.isLoading}
        buttonLabel={buttontext}
        onPress={handleSubmit}
        isDisabled={isButtonDisabled}
      />
    </>
  );
};

export default React.memo(CreateEditGroupModalField);
