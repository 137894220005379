/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {FlatList, Pressable, ScrollView, Text, View} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';
import {useIsFocused} from '@react-navigation/native';

import {tradeStyle} from './offer.styles';
import {TEXT, FixedValue, Percentage, BASESTYLE} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {
  capitalizeFirstLetter,
  _InputType,
  showAlertForExceedQuantity,
} from '../../utils/helperFunctions';
import {TradeContext} from '../../contextAPI/tradeContext';
import {HomeStore} from '../../redux/reducers/homeSlice';
import {useAppSelector} from '../../hooks/reduxHooks';
import TopsListEmptyView from '../../components/emptyListView/topsListEmptyView';
import EmptyCollection from '../../components/emptyListView/emptyCollection';
import {
  CollectionModel,
  TopModel,
} from '../../types/reducerTypes/searchTabStoreType';
import ImageCard from '../../components/mantelTopTabComp/imageCard';
import {TopType} from '../../constants/enum';
import useCreateTradeHooks from '../../hooks/componentHooks/useCreateTradeHooks';
import AppLoader from '../../components/smallDesigns/appLoader';
import QunatitySelector from '../../components/smallDesigns/qunatitySelector';

interface ISelectMyTopsProps {
  userId?: number;
}

const SelectMyTops = (props: ISelectMyTopsProps) => {
  const {userId} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = tradeStyle(ColorTheme);
  const {collectionTopLists} = useAppSelector(HomeStore);
  const {screenNavigateTo, callCollectionsAPI, onListReachedEnd} =
    useCreateTradeHooks();
  const {
    collectionsList,
    isRefreshing,
    setIsRefreshing,
    updateCollectionTopQuantityMoreThanOne,
    updateCollectionTopQuantity,
    updateCollectionTopQuantityOnEdit,
  } = React.useContext(TradeContext);
  const isFocused = useIsFocused();
  let seeAllClicked = false;

  React.useEffect(() => {
    if (isFocused) {
      seeAllClicked = false;
    }
  }, [isFocused]);

  const handleRefresh = React.useCallback(() => {
    setIsRefreshing(true);
    callCollectionsAPI(FixedValue.CONSTANT_VALUE_0, userId);
  }, []);

  const loadMoreRecords = React.useCallback(() => {
    onListReachedEnd(userId);
  }, [userId]);

  const navigateScreen = (collectionData: CollectionModel, userId?: number) => {
    if (!seeAllClicked) {
      seeAllClicked = true;
      screenNavigateTo(collectionData, userId);
    }
  };

  const modifyQuantity = (
    collectionId: number,
    item: TopModel,
    val: string
  ) => {
    if (val === TEXT.MINNUS || item.quantity !== item.selectedQuantity) {
      updateCollectionTopQuantityMoreThanOne(collectionId, item, val);
    } else {
      showAlertForExceedQuantity(item?.quantity ?? FixedValue.CONSTANT_VALUE_0);
    }
  };

  const updateQuantity = (
    collectionId: number,
    item: TopModel,
    val: string
  ) => {
    if (item.selectedQuantity === FixedValue.CONSTANT_VALUE_1) {
      {
        val === TEXT.MINNUS
          ? updateCollectionTopQuantity(collectionId, item)
          : modifyQuantity(collectionId, item, val);
      }
    } else {
      modifyQuantity(collectionId, item, val);
    }
  };

  const updateQuantityOnChange = (
    collectionId: number,
    item: TopModel,
    val: number
  ) => {
    if ((item?.quantity ?? FixedValue.CONSTANT_VALUE_0) >= val) {
      updateCollectionTopQuantityOnEdit(collectionId, item, val);
    } else {
      showAlertForExceedQuantity(item?.quantity ?? FixedValue.CONSTANT_VALUE_0);
    }
  };

  //render Tops function
  const renderTops = (
    item: TopModel[],
    mainIndex: number,
    parentItem: CollectionModel
  ) => {
    if (item?.length > FixedValue.CONSTANT_VALUE_0) {
      return (
        <FlatList
          horizontal
          data={item}
          contentContainerStyle={styles.paddingLeft10}
          showsHorizontalScrollIndicator={false}
          keyExtractor={(_, index) => `${TEXT.COLLECTIONS + 'Nested'}-${index}`}
          renderItem={({item}) => (
            <View style={styles.flexDirectionColumn}>
              <ImageCard key={item?.id} {...item} type={TopType.tradeOffer} />
              {!item?.isSelected ? (
                <Pressable
                  onPress={() =>
                    updateCollectionTopQuantity(parentItem?.id, item)
                  }
                  style={styles.addNowButtonContainer}
                >
                  <Text style={styles.addNowButton}>{TEXT.ADD_NOW}</Text>
                </Pressable>
              ) : (
                <QunatitySelector
                  count={item.selectedQuantity ?? FixedValue.CONSTANT_VALUE_1}
                  qunatityTextStyle={styles.quantityTextStyle}
                  quantityStyle={styles.quantitySelectorViewStyle}
                  plusStyle={styles.plusButtonStyle}
                  minusStyle={styles.minusButtonStyle}
                  plusTextStyle={styles.plusTextStyle}
                  onMinusPress={() =>
                    updateQuantity(parentItem?.id, item, TEXT.MINNUS)
                  }
                  onPlusPress={() =>
                    updateQuantity(parentItem?.id, item, TEXT.ADD)
                  }
                  onChangeQuantity={(val: number) =>
                    updateQuantityOnChange(parentItem?.id, item, val)
                  }
                  checkQuantityOnOnChange={true}
                  isInputQuantity={true}
                />
              )}
            </View>
          )}
        />
      );
    }
    return (
      <EmptyCollection
        mainText={'No Tops found'}
        onPressAction={() => {
          console.log('Empty view clicked');
        }}
      />
    );
  };

  //render collection list function
  const renderCollectionList = (item: CollectionModel, index: number) => {
    return (
      <>
        <View style={styles.collectionTitleView}>
          <View style={[BASESTYLE.inRow, styles.paddingHorizontal20]}>
            <Text
              style={styles.collectionTitleStyle}
              numberOfLines={FixedValue.CONSTANT_VALUE_1}
            >
              {capitalizeFirstLetter(item?.name ?? '')} ({item.totalTopsCount})
            </Text>
          </View>
          <View style={BASESTYLE.inRow}>
            <Pressable
              style={styles.marginRight20}
              onPress={() => navigateScreen(item, userId)}
            >
              <Text style={styles.seeAllText}>{TEXT.SEE_ALL}</Text>
            </Pressable>
          </View>
        </View>
        {renderTops(item?.tops || [], index, item)}
      </>
    );
  };

  //show empty view when there is no data available
  const renderEmptyUI = React.useMemo((): JSX.Element | null => {
    if (collectionsList?.length === FixedValue.CONSTANT_VALUE_0) {
      return (
        <ScrollView
          style={{height: Percentage.PRECENTAGE_100}}
          contentContainerStyle={BASESTYLE.flex1}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={handleRefresh}
            />
          }
        >
          <TopsListEmptyView
            emptyViewTitle={TEXT.YOU_HAVE_NO_COLLECTION}
            emptyViewDesc={''}
          />
        </ScrollView>
      );
    }
    return null;
  }, [collectionsList]);

  const getCollectionsUI = React.useMemo((): JSX.Element | null => {
    if (collectionsList?.length === FixedValue.CONSTANT_VALUE_0) {
      return null;
    } else {
      return (
        <FlatList
          data={collectionsList}
          keyExtractor={(_, index) => `${TEXT.COLLECTIONS}-${index}`}
          onEndReached={() => loadMoreRecords()}
          onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
          refreshControl={
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={handleRefresh}
            />
          }
          contentContainerStyle={styles.paddingBottom90}
          showsVerticalScrollIndicator={false}
          renderItem={({item, index}) => renderCollectionList(item, index)}
        />
      );
    }
  }, [collectionsList, isRefreshing]);

  return (
    <>
      {!collectionTopLists?.isLoading && renderEmptyUI}
      {getCollectionsUI}
      {collectionTopLists?.isLoading && (
        <AppLoader loaderStyle={styles.marginTop50} />
      )}
    </>
  );
};

export default React.memo(SelectMyTops);
