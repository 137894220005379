import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath, Rect, SvgProps} from 'react-native-svg';
import {ThemeContext} from '../../contextAPI/themeContext';

const DistributeTopButton = (props: SvgProps) => {
  const {ColorTheme} = React.useContext(ThemeContext);

  return (
    <Svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      // @ts-ignore
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G clipPath="url(#clip0_4527_5046)">
        <Path
          d="M11 1L5.5 6.5"
          stroke={props?.color ?? ColorTheme.backgroundColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M11 1L7.5 11L5.5 6.5L1 4.5L11 1Z"
          stroke={props?.color ?? ColorTheme.backgroundColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_4527_5046">
          <Rect width={12} height={12} fill={ColorTheme.backgroundColor} />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
export default DistributeTopButton;
