import React from 'react';
import {View, Text, Pressable} from 'react-native';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {IModalHeaderProps} from '../../../types/componentTypes/inputTypes';
import ModalBack from '../../renderSvgs/modalBack';
import {styles} from './modalHeader.style';

const ModalHeader = (props: IModalHeaderProps): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  return (
    <View style={style.container}>
      <Text style={[style.titleText, props?.customTextStyle]}>
        {props?.headerTitle}
      </Text>
      <Pressable onPress={props?.onPress}>
        <ModalBack />
      </Pressable>
    </View>
  );
};

export default React.memo(ModalHeader);
