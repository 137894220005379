import React from 'react';
import {FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import {TopType} from '../../constants/enum';
import {HomeContext} from '../../contextAPI/homeContext';
import {
  onPurchasedTopListsStart,
  HomeStore,
} from '../../redux/reducers/homeSlice';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import {navigate} from '../../services/navigationService';
import {
  IInProgressTopParams,
  TopModel,
} from '../../types/reducerTypes/searchTabStoreType';
import {useAppDispatch, useAppSelector} from '../reduxHooks';

const usePurchasedTopsHooks = () => {
  const dispatch = useAppDispatch();
  const {purchasedTopLists} = useAppSelector(HomeStore);
  const {userData} = useAppSelector(userProfileStore);
  const UserDetail = userData?.success;
  const {homeScreenUserData} = React.useContext(HomeContext);
  const isChildUserSelected: boolean =
    UserDetail?.id !== homeScreenUserData?.id &&
    UserDetail?.role === TEXT.ROLE_PARENT &&
    homeScreenUserData !== null;
  const callPurchasedTopsListAPI = React.useCallback(
    (page: number): void => {
      const params: IInProgressTopParams = {
        page,
        size: FixedValue.CONSTANT_VALUE_15,
      };
      if (isChildUserSelected) {
        params.minorId = homeScreenUserData?.id;
      }
      dispatch(onPurchasedTopListsStart(params));
    },
    [UserDetail, homeScreenUserData]
  );

  const onReachedEnd = React.useCallback((): void => {
    if (
      purchasedTopLists.success != null &&
      purchasedTopLists.success.pageNumber <
        purchasedTopLists.success.totalPages
    ) {
      callPurchasedTopsListAPI(purchasedTopLists.success.pageNumber + 1);
    }
  }, [purchasedTopLists]);

  const navigateToDetailScreen = React.useCallback(
    (topDetails: TopModel, comingFrom: TopType): void => {
      navigate(ROUTE_NAME.TOP_DETAIL_SCREEN, {topDetails, comingFrom});
    },
    []
  );

  return {
    onReachedEnd,
    navigateToDetailScreen,
    callPurchasedTopsListAPI,
  };
};

export default usePurchasedTopsHooks;
