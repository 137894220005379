import {StyleSheet} from 'react-native';
import {BASESTYLE, COLORS, FixedValue, FONT_NAMES} from '../../../constants';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const styles = (theme: IColorTheme) =>
  StyleSheet.create({
    flatListContentContainerStyle: {
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_70),
      paddingVertical: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    buttonViewStyle: {
      ...BASESTYLE.selfCenter,
      ...BASESTYLE.absolutePosition,
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_21),
    },
    buttonStyle: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_179),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      backgroundColor: 'transparent',
    },
    buttonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
    },
    mainViewStyle: {
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      ...BASESTYLE.flex1,
    },
  });
