import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FONT_NAMES,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
  SCREEN_HEIGHT,
} from '../../utils/responsive';

export const DistributeModalModalStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      justifyContent: GlobalStyleValues.FLEX_END,
      width: isWebsite()
        ? FixedValue.CONSTANT_VALUE_500
        : Percentage.PRECENTAGE_100,
      alignItems: GlobalStyleValues.CENTER,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    innerView: {
      ...BASESTYLE.flex1,
      backgroundColor: _theme?.backgroundColor,
      width: Percentage.PRECENTAGE_100,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      maxHeight: SCREEN_HEIGHT - scaleHeight(FixedValue.CONSTANT_VALUE_52),
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_16,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_16,
      shadowColor: _theme?.color,
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
      shadowRadius: FixedValue.CONSTANT_VALUE_5,
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_2,
        height: FixedValue.CONSTANT_VALUE_2,
      },
      elevation: FixedValue.CONSTANT_VALUE_2,
    },
    container: {
      ...BASESTYLE.flex1,
      width: Percentage.PRECENTAGE_100,
    },
    crossImage: {
      // alignItems: GlobalStyleValues.FLEX_END,
    },
    outterPart: {
      width: Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      ...BASESTYLE.absolutePosition,
      zIndex: FixedValue.CONSTANT_VALUE_MIN_1,
      backgroundColor: _theme?.loaderBackgroundColor,
    },
    primaryButtonStyle: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_111),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      marginRight: FixedValue.CONSTANT_VALUE_10,
    },
    primaryButtonStyle2: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_100),
      marginRight: FixedValue.CONSTANT_VALUE_10,
      borderRadius: FixedValue.CONSTANT_VALUE_70,
    },
    primaryButtonStyle3: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_335),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
    },
    primaryButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    primaryButtonLabelstyle1: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.F54336,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    noButtonBackGroundColor: {
      backgroundColor: COLORS.FDE4E1,
    },
    noButtonBackGroundColor1: {
      backgroundColor: COLORS._4EAF51,
    },
    cardStyle: {
      height: scaleWidth(FixedValue.CONSTANT_VALUE_69),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_69),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      backgroundColor: COLORS.E9EEFB,
    },
    topCardStyle: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_69),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_69),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      backgroundColor: COLORS.E9EEFB,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),

      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_9),
    },
    owner: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: _theme.color,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_12),
    },
    topOwner: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: _theme.color,
      marginBottom: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    day: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
      fontFamily: FONT_NAMES.SF_LIGHT,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_12),
      color: _theme.color,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_5),
    },
    imageStyle: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_69),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_69),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      marginBottom: scaleWidth(FixedValue.CONSTANT_VALUE_121),
    },
    approveButton: {
      ...BASESTYLE.inRow,
      ...BASESTYLE.center,
      ...BASESTYLE.absolutePosition,
      ...BASESTYLE.selfCenter,
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    userNameInitials: {
      textAlign: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      color: COLORS.BLACK,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      textTransform: GlobalStyleValues.UPPERCASE,
    },
    nameView: {
      width: Percentage.PRECENTAGE_50,
      flexGrow: FixedValue.CONSTANT_VALUE_1,
    },
    topContainer: {
      ...BASESTYLE.row,
      ...BASESTYLE.inCenter
    },
    offerStatus: {
      ...BASESTYLE.inCenter,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      backgroundColor: COLORS._FEF5CC,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_27),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_25),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_65),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_22),
    },
    cancelledofferStatus: {
      ...BASESTYLE.inCenter,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      backgroundColor: COLORS.FDE4E1,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_27),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_25),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_65),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_22),
    },
    acceptedOfferStatus: {
      ...BASESTYLE.inCenter,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      backgroundColor: COLORS._E6F3E5,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_27),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_25),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_65),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_22),
    },
    rejectStatusText: {
      color: COLORS.F54336,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_REGULAR,
    },
    approvedStatusText: {
      color: COLORS._4EAF51,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_REGULAR,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_15),
    },
    pendingStatusText: {
      color: COLORS._A07608,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_15),
      fontFamily: FONT_NAMES.SF_REGULAR,
    },
    counterOfferButton: {
      ...BASESTYLE.row,
      justifyContent: GlobalStyleValues.FLEX_END,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_25),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_65),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_34),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_19),
    },
    topContainerMain: {
      ...BASESTYLE.row,
      justifyContent: GlobalStyleValues.SPACE_BETWEEN,
    },
    reportImageStyle: {
      ...BASESTYLE.inCenter,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    flex1: {
      ...BASESTYLE.flex1,
    },
    favIconContainer: {
      right: scaleWidth(FixedValue.CONSTANT_VALUE_4),
      top: scaleHeight(FixedValue.CONSTANT_VALUE_15),
    },
  });
