import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';
import {COLORS} from '../../constants';

const StrokeWidth = (props: SvgProps) => (
  <Svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M24.9999 13C24.9999 19.6274 19.6274 25 13 25C6.37257 25 1 19.6274 1 13C1 6.37258 6.37257 1 13 1C19.6274 1 24.9999 6.37258 24.9999 13Z"
      stroke={props?.stroke ?? COLORS.PRIMARY_BLUE}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="6 6"
    />
  </Svg>
);
export default StrokeWidth;
