import React from 'react';
import {Text, View} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import PrimaryButton from '../../components/button/primaryButton';
import {TEXT, BASESTYLE, FixedValue} from '../../constants';
import CustomButton from '../../components/button/customButton';
import {ChildProfileApprovedModalStyle} from './childProfileApproved.style';
import {HomeContext} from '../../contextAPI/homeContext';
import GreenTick from '../../components/renderSvgs/greenTick';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import DeleteAlertcircle from '../../components/renderSvgs/deleteAlertcircle';
import useChildApprovelRequestListHooks from '../../hooks/componentHooks/useChildApprovelRequestListHooks';

const ChildProfileApprovedContent = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = ChildProfileApprovedModalStyle(ColorTheme);
  const {isApprove, onApprove, isStatus, setOpenChildProfileApproveModal} =
    React.useContext(HomeContext);
  const {callApproveOrRejectAPI} = useChildApprovelRequestListHooks();

  const onApproveOrReject = () => {
    callApproveOrRejectAPI(onApprove ? onApprove : 0, isStatus);
  };

  return (
    <View style={styles.container}>
      <View style={BASESTYLE.alignCenter}>
        {isApprove == true ? (
          <>
            <GreenTick
              width={scaleWidth(FixedValue.CONSTANT_VALUE_40)}
              height={scaleHeight(FixedValue.CONSTANT_VALUE_40)}
            />
            <Text style={styles.wantToApprove}>{TEXT.PROFILE_APPROVE}</Text>
          </>
        ) : (
          <>
            <DeleteAlertcircle
              width={scaleWidth(FixedValue.CONSTANT_VALUE_40)}
              height={scaleHeight(FixedValue.CONSTANT_VALUE_40)}
            />
            <Text style={styles.wantToApprove}>{TEXT.PROFILE_REJECT}</Text>
          </>
        )}
        <View style={[BASESTYLE.inRow, BASESTYLE.inCenter]}>
          <CustomButton
            buttonLabel={TEXT.NO}
            buttonwidth={styles.yesOrNoButtonView}
            buttonBackGroundColor={styles.noButtonBackGroundColor}
            labelStyle={styles.noButtonLabelstyle}
            onPress={() => setOpenChildProfileApproveModal()}
            isDisabled={false}
          />
          <PrimaryButton
            buttonLabel={TEXT.YES}
            buttonwidth={styles.yesButtonView}
            labelStyle={styles.yesButtonLabelstyle}
            onPress={() => onApproveOrReject()}
            isDisabled={false}
          />
        </View>
      </View>
    </View>
  );
};

export default ChildProfileApprovedContent;
