import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FONT_NAMES,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const RequestSentStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.alignCenter,
      backgroundColor: _theme.backgroundColor,
    },
    requestView: {
      zIndex: FixedValue.CONSTANT_VALUE_10,
      ...BASESTYLE.flex1,
      position: GlobalStyleValues.ABSOLUTE,
      ...BASESTYLE.alignCenter,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_229),
    },
    button: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_250),
      borderRadius: FixedValue.CONSTANT_VALUE_100,
    },
    yesButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_17),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
    request: {
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_32),
      ...BASESTYLE.textAlignCenter,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_24),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_25),
      color: _theme.color,
    },
    wait: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_LIGHT,
      ...BASESTYLE.textAlignCenter,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_22),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_17),
      color: _theme.color,
    },
  });
