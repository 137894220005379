import React from 'react';
import {FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import {StorageConstants} from '../../constants/appConst';
import {TopType} from '../../constants/enum';
import {HomeContext} from '../../contextAPI/homeContext';
import {
  HomeStore,
  onCollectionTopListsStart,
} from '../../redux/reducers/homeSlice';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import {navigate} from '../../services/navigationService';
import {
  CollectionModel,
  IInProgressTopParams,
  TopModel,
} from '../../types/reducerTypes/searchTabStoreType';
import {
  ChildListModel,
  IUserProfileData,
} from '../../types/reducerTypes/userProfileTypes';
import {getDataLocally, storeDataLocally} from '../../utils/helperFunctions';
import {useAppDispatch, useAppSelector} from '../reduxHooks';

const useTopCollectionHooks = () => {
  const dispatch = useAppDispatch();
  const {collectionTopLists} = useAppSelector(HomeStore);
  const {
    profileSwitchListModal,
    homeScreenUserData,
    isProfileSwitchToChild,
    homeScreenDataLoader,
    setProfileSwitchListModal,
    setHomeScreenUserData,
    setIsProfileSwitchToChild,
    setHomeScreenDataLoader,
  } = React.useContext(HomeContext);
  const {userData} = useAppSelector(userProfileStore);
  const UserDetail = userData?.success;
  const isChildUserSelected: boolean =
    UserDetail?.id !== homeScreenUserData?.id &&
    UserDetail?.role === TEXT.ROLE_PARENT &&
    homeScreenUserData !== null;
  const callInToCollectionListAPI = React.useCallback(
    (page: number): void => {
      const params: IInProgressTopParams = {
        page,
        size: FixedValue.CONSTANT_VALUE_15,
      };
      if (isChildUserSelected) {
        params.userId = homeScreenUserData?.id;
      }
      dispatch(onCollectionTopListsStart(params));
    },
    [UserDetail, homeScreenUserData]
  );

  const onReachedEnd = React.useCallback((): void => {
    if (
      collectionTopLists.success != null &&
      collectionTopLists.success.pageNumber <
        collectionTopLists.success.totalPages
    ) {
      callInToCollectionListAPI(
        collectionTopLists.success.pageNumber + FixedValue.CONSTANT_VALUE_1
      );
    }
  }, [collectionTopLists, homeScreenUserData]);

  const navigateToDetailScreen = React.useCallback(
    (topDetails: TopModel, comingFrom: TopType): void => {
      navigate(ROUTE_NAME.TOP_DETAIL_SCREEN, {topDetails, comingFrom});
    },
    []
  );

  //check index is zero or not
  const checkIndexIsZero = React.useCallback((mainIndex: number): boolean => {
    return mainIndex !== FixedValue.CONSTANT_VALUE_0;
  }, []);

  //check if function call from uncategorized collection then move to create top screen
  //otherwise navigate to alltop list and show uncategorzized list
  const screenNavigateTo = React.useCallback(
    (
      index: number,
      collectionData: CollectionModel,
      uncategorizedCollectionId: number,
      unCataegorizedTopsCount: number
    ): void => {
      if (index === FixedValue.CONSTANT_VALUE_0) {
        navigate(ROUTE_NAME.EDITOR_SCREEN);
      } else {
        navigate(ROUTE_NAME.SEE_ALL_TOPS_SCREEN, {
          collectionModel: collectionData,
          showUncategorizedListId: uncategorizedCollectionId,
          totalTopsInUncategorizedCollections: unCataegorizedTopsCount,
        });
      }
    },
    []
  );

  const saveSwitchProfileDataOnSelect = React.useCallback(
    async (data: ChildListModel | IUserProfileData | null): Promise<void> => {
      setHomeScreenDataLoader(true);
      setHomeScreenUserData(data);
      if (data !== null) {
        await storeDataLocally(StorageConstants.SWITCH_USER_DETAIL, data);
      }
      setIsProfileSwitchToChild(UserDetail?.role !== data?.role);
      setProfileSwitchListModal(false);
    },
    [
      homeScreenUserData,
      profileSwitchListModal,
      isProfileSwitchToChild,
      userData,
      homeScreenDataLoader,
    ]
  );

  const saveSwitchProfileDataOnAppLoad =
    React.useCallback(async (): Promise<void> => {
      if (homeScreenUserData === null) {
        const profileSwitchUserData = await getDataLocally(
          StorageConstants.SWITCH_USER_DETAIL
        );
        setHomeScreenUserData(profileSwitchUserData);
        setIsProfileSwitchToChild(
          UserDetail?.role !== profileSwitchUserData?.role
        );
      }
    }, [
      homeScreenUserData,
      profileSwitchListModal,
      isProfileSwitchToChild,
      userData,
    ]);

  return {
    onReachedEnd,
    navigateToDetailScreen,
    callInToCollectionListAPI,
    checkIndexIsZero,
    screenNavigateTo,
    saveSwitchProfileDataOnAppLoad,
    saveSwitchProfileDataOnSelect,
  };
};

export default useTopCollectionHooks;
