import React from 'react';
import {Alert} from 'react-native';
import {FixedValue, ROUTE_NAME} from '../../constants';
import {useAppDispatch, useAppSelector} from '../reduxHooks';
import {
  onAcceptOrRejectbyParentStart,
  onTopBuyingApprovalListStart,
  onTopCreditsRequestStart,
} from '../../redux/reducers/childApproveSlice';
import {ChildProfileContext} from '../../contextAPI/childProfileContext';
import {
  ITopBuyApprovalParams,
  Result,
} from '../../types/reducerTypes/childApproveRequestStoreTypes';
import {childProfileApproveStore} from '../../redux/reducers/childApproveSlice';
import {
  TOP_BUYING_APPROVAL,
  ACCEPT_REJECT_BY_PARENT,
  TOPCREDITS_REQUEST_ACTION,
} from '../../constants/actionTypes';
import {_ToastHandler} from '../../utils/helperFunctions';
import {navigate} from '../../services/navigationService';
import {APPROVED_OR_REJECT} from '../../constants/enum';
import {TEXT, TOAST_TEXT} from '../../constants/textMessage';

const useTopBuyingApproval = () => {
  const dispatch = useAppDispatch();
  const {
    topBuyingApprovalListData,
    acceptRejectByParent,
    topCreditsRequestAction,
  } = useAppSelector(childProfileApproveStore);
  const [topBuyingApprovalData, setTopBuyingApprovalData] = React.useState<
    Result[]
  >([]);
  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  const [totalTopsAmount, setTotalTopsAmount] = React.useState(
    FixedValue.CONSTANT_VALUE_0
  );
  const {
    childUserData,
    selectDataForApproveOrReject,
    setShowTopBuyingApprovalModal,
    setInitialLoader,
  } = React.useContext(ChildProfileContext);
  const selectedData = selectDataForApproveOrReject.selectedData;
  const isApprove = selectDataForApproveOrReject.isApprove;
  const isLoading = topBuyingApprovalListData.isLoading ?? false;

  const fetchList = React.useCallback(
    (page: number): void => {
      const params: ITopBuyApprovalParams = {
        id: childUserData?.id ?? FixedValue.CONSTANT_VALUE_0,
        data: {page, size: FixedValue.CONSTANT_VALUE_15},
      };
      dispatch(onTopBuyingApprovalListStart(params));
    },
    [childUserData]
  );

  React.useEffect((): void => {
    switch (topBuyingApprovalListData.status) {
      case TOP_BUYING_APPROVAL.SUCCESS:
        if (topBuyingApprovalListData.success !== null) {
          const result =
            topBuyingApprovalListData?.success?.data?.results ?? [];
          if (
            topBuyingApprovalListData.success.pageNumber ===
            FixedValue.CONSTANT_VALUE_0
          ) {
            setTopBuyingApprovalData(result);
          } else {
            setTopBuyingApprovalData(prev => [...prev, ...result]);
          }
        }
        setInitialLoader(false);
        break;
      case TOP_BUYING_APPROVAL.FAILED:
        setInitialLoader(false);
        _ToastHandler(topBuyingApprovalListData.failed ?? '', false);
        break;
      default:
        break;
    }
  }, [topBuyingApprovalListData.status]);

  React.useEffect(() => {
    switch (acceptRejectByParent.status) {
      case ACCEPT_REJECT_BY_PARENT.SUCCESS:
        fetchList(FixedValue.CONSTANT_VALUE_0);
        setShowLoader(false);
        if (isApprove) {
          navigate(ROUTE_NAME.PAYMENT_SUCCESS, {isBack: true});
        } else {
          _ToastHandler('Purchase request rejected successfully', true);
        }
        break;
      case ACCEPT_REJECT_BY_PARENT.FAILED:
        const apiError =
          acceptRejectByParent.failed ?? TEXT.SOMETHING_WENT_WRONG;
        if (acceptRejectByParent.failed === TOAST_TEXT.INSUFFICIENT_CREDIT) {
          Alert.alert(
            'Insufficient TopCredits',
            `${apiError}`,
            [
              {
                text: 'Ok',
                onPress: () => console.log('Okay button pressed'),
              },
            ],
            {cancelable: false}
          );
        } else {
          _ToastHandler(apiError, false);
        }
        setShowLoader(false);
        break;
      default:
        break;
    }
  }, [acceptRejectByParent.status]);

  React.useEffect(() => {
    switch (topCreditsRequestAction.status) {
      case TOPCREDITS_REQUEST_ACTION.SUCCESS:
        fetchList(FixedValue.CONSTANT_VALUE_0);
        setShowLoader(false);
        if (isApprove) {
          navigate(ROUTE_NAME.PAYMENT_SUCCESS, {isBack: true});
        } else {
          _ToastHandler(TOAST_TEXT.PURCHASED_TOPCREDITS_REQUEST_REJECT, true);
        }
        break;
      case TOPCREDITS_REQUEST_ACTION.FAILED:
        setShowLoader(false);
        break;
      default:
        break;
    }
  }, [topCreditsRequestAction.status]);

  const onEndReached = React.useCallback((): void => {
    if (topBuyingApprovalListData.success != null) {
      const nextPage = topBuyingApprovalListData.success.pageNumber + 1;
      if (nextPage < topBuyingApprovalListData.success.totalPages) {
        fetchList(topBuyingApprovalListData.success.pageNumber + 1);
      }
    }
  }, [topBuyingApprovalListData]);

  const onRefresh = React.useCallback((): void => {
    fetchList(FixedValue.CONSTANT_VALUE_0);
  }, []);

  React.useEffect(() => {
    if (selectedData != null) {
      let totalTopsAmount = 0;
      selectedData?.tops?.map(item => {
        totalTopsAmount = totalTopsAmount + item.price;
      });
      setTotalTopsAmount(totalTopsAmount);
    }
  }, [selectedData]);

  const checkOut = React.useCallback(async (): Promise<void> => {
    const isRequestForTop = selectedData?.cartId !== undefined;
    if (isRequestForTop) {
      let totalPrice = FixedValue.CONSTANT_VALUE_0;
      selectedData?.tops?.forEach(top => {
        totalPrice += top.price;
      });
      setShowTopBuyingApprovalModal(false);
      if (totalPrice > FixedValue.CONSTANT_VALUE_0) {
        setShowLoader(true);
      } else {
        callAPIForAcceptReject();
      }
    } else {
      if (isApprove) {
        setShowLoader(true);
      } else {
        callBuyPackTopCreditsPaymentAcceptAndReject();
      }
    }
  }, [selectedData, isApprove]);

  const callAPIForAcceptReject = React.useCallback(() => {
    const params = {
      id: selectedData?.cartId,
      status: isApprove ? 'APPROVED' : 'REJECT',
    };
    setShowTopBuyingApprovalModal(false);
    dispatch(onAcceptOrRejectbyParentStart(params));
  }, [selectedData, isApprove]);

  const callBuyPackTopCreditsPaymentAcceptAndReject = React.useCallback(() => {
    setShowTopBuyingApprovalModal(false);
    const params = {
      requestId: selectedData?.walletRequestResponse?.walletRequestId,
      status: isApprove
        ? APPROVED_OR_REJECT.APPROVED
        : APPROVED_OR_REJECT.REJECT,
    };
    dispatch(onTopCreditsRequestStart(params));
  }, [selectedData, isApprove]);

  return {
    totalTopsAmount,
    fetchList,
    topBuyingApprovalData,
    checkOut,
    showLoader,
    setShowLoader,
    onRefresh,
    isLoading,
    onEndReached,
    callAPIForAcceptReject,
    callBuyPackTopCreditsPaymentAcceptAndReject,
  };
};

export default useTopBuyingApproval;
