import React, {useContext, useEffect} from 'react';
import {Text, View, Pressable, ActivityIndicator} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {TEXT, TOAST_TEXT} from '../../constants/textMessage';
import {
  _ToastHandler,
  checkTopCreditGreaterThanOne,
} from '../../utils/helperFunctions';
import {HomeContext} from '../../contextAPI/homeContext';
import {allTopsStyle} from '../seeAllTops/seeAll.styles';
import {COLORS, FixedValue, ROUTE_NAME} from '../../constants';
import {goBack, navigate} from '../../services/navigationService';
import WalletImage from '../../components/renderSvgs/walletImage';
import {
  onUserDataStart,
  userProfileStore,
} from '../../redux/reducers/userProfileSlice';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import PrimaryButton from '../../components/button/primaryButton';
import RadioSelectButton from '../../components/renderSvgs/radioSelectButton';
import RadioUnselectButton from '../../components/renderSvgs/radioUnselectButton';
import CardImage from '../../components/renderSvgs/cardImage';
import {useIsFocused} from '@react-navigation/native';
import {
  BUYING_REQUEST_TO_PARENT,
  CART_CHECKOUT,
  USER_PROFILE_DATA,
} from '../../constants/actionTypes';
import {CHILD_PERMISSION, GlobalStyleValues} from '../../constants/enum';
import {HomeStore} from '../../redux/reducers/homeSlice';
import useInProgressTopsHooks from '../../hooks/componentHooks/useInProgressTopsHooks';
import {activateInCollectionTab} from '../../redux/reducers/collectionSlice';
import PayPalModal from '../../modals/paypalModal';

const OrderSummaryForSingleTop = (): JSX.Element => {
  const {ColorTheme} = useContext(ThemeContext);
  const {styles} = allTopsStyle(ColorTheme);
  const isFocused = useIsFocused();
  const {cartCheckout, sendBuyingRequestToParent, topAmountAndReportAbuseData} =
    useAppSelector(HomeStore);
  const {
    setApplyCouponCodeData,
    setDiscountPrice,
    topAmount,
    isPaymentFromCash,
    setIsPaymentFromCash,
    clearSelectedTopsResult,
  } = useContext(HomeContext);
  const dispatch = useAppDispatch();
  const {callCartTopAPI, callBuyingRequestToParent} = useInProgressTopsHooks();
  const [showLoader, setShowLoader] = React.useState(false);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const {userData} = useAppSelector(userProfileStore);
  const buyNowAmountRoundOff =
    topAmountAndReportAbuseData?.success?.data?.singleTopPrice ??
    FixedValue.CONSTANT_VALUE_0;
  useEffect(() => {
    if (isFocused) {
      setIsPaymentFromCash(false);
      setApplyCouponCodeData(null);
      setDiscountPrice(FixedValue.CONSTANT_VALUE_0);
    }
  }, [isFocused]);

  React.useEffect(() => {
    setLoading(cartCheckout.isLoading);
  }, [cartCheckout.isLoading]);

  React.useEffect(() => {
    switch (sendBuyingRequestToParent.status) {
      case BUYING_REQUEST_TO_PARENT.SUCCESS:
        _ToastHandler(TOAST_TEXT.PURCHASED_REQUEST_SENT, true);
        clearSelectedTopsResult();
        setApplyCouponCodeData(null);
        setDiscountPrice(FixedValue.CONSTANT_VALUE_0);
        goBack();
        break;
      case BUYING_REQUEST_TO_PARENT.FAILED:
        break;
      default:
        break;
    }
  }, [sendBuyingRequestToParent.status]);

  React.useEffect(() => {
    switch (cartCheckout.status) {
      case CART_CHECKOUT.SUCCESS:
        dispatch(onUserDataStart());
        dispatch(activateInCollectionTab(true));
        navigate(ROUTE_NAME.PAYMENT_SUCCESS);
        setTimeout(() => {
          clearSelectedTopsResult();
        }, 1200);
        setApplyCouponCodeData(null);
        setDiscountPrice(FixedValue.CONSTANT_VALUE_0);
        break;
      default:
        break;
    }
  }, [cartCheckout.status]);

  React.useEffect(() => {
    if (isFocused) {
      switch (userData.status) {
        case USER_PROFILE_DATA.SUCCESS:
          if (
            userData?.success?.permissionsStatus?.purchasePermission?.status ===
              CHILD_PERMISSION.HAVE_LIMIT &&
            isPaymentFromCash
          ) {
            const monthlyLimit: number =
              userData?.success?.permissionsStatus?.purchasePermission
                ?.monthlyLimit ?? FixedValue.CONSTANT_VALUE_0;
            const usedLimit: number =
              userData?.success?.permissionsStatus?.purchasePermission
                ?.usedMonthlyLimit ?? FixedValue.CONSTANT_VALUE_0;
            if (
              monthlyLimit <= usedLimit ||
              buyNowAmountRoundOff > monthlyLimit - usedLimit
            ) {
              _ToastHandler(TOAST_TEXT.LIMIT_EXHAUSTED, false);
              return;
            } else {
              callPaymentGateWay();
            }
          } else if (
            userData?.success?.permissionsStatus?.purchasePermission?.status ===
            CHILD_PERMISSION.NOT_ALLOWED
          ) {
            callBuyingRequestToParent();
          } else {
            callPaymentGateWay();
          }
          break;
        case USER_PROFILE_DATA.FAILED:
          break;
        default:
          break;
      }
    }
  }, [userData.status]);

  // check if child doesnt have permission send request to parent for buying the top
  const checkPermissionForBuying = () => {
    dispatch(onUserDataStart());
  };

  const checkPoyNowButtonDisabled = !isPaymentFromCash
    ? userData?.success?.walletAmount ??
      FixedValue.CONSTANT_VALUE_0 >= topAmount
    : true;

  const callPaymentGateWay = () => {
    if (buyNowAmountRoundOff > FixedValue.CONSTANT_VALUE_0) {
      if (isPaymentFromCash) {
        setShowLoader(true);
      } else {
        callCartTopAPI();
      }
    } else {
      callCartTopAPI();
    }
  };

  return (
    <>
      <Pressable
        onPress={() => setIsPaymentFromCash(false)}
        style={styles.walletContainer}
      >
        {!isPaymentFromCash ? (
          <RadioSelectButton
            height={FixedValue.CONSTANT_VALUE_20}
            width={FixedValue.CONSTANT_VALUE_20}
          />
        ) : (
          <RadioUnselectButton
            height={FixedValue.CONSTANT_VALUE_20}
            width={FixedValue.CONSTANT_VALUE_20}
          />
        )}
        <View style={styles.content}>
          <Text style={styles.myWalletText}>{TEXT.PAY_WITH_WALLET}</Text>
          <Text style={styles.balanceText}>
            {TEXT.BALANCE}
            {checkTopCreditGreaterThanOne(userData?.success?.walletAmount)}
          </Text>
        </View>
        <WalletImage
          height={FixedValue.CONSTANT_VALUE_40}
          width={FixedValue.CONSTANT_VALUE_40}
          color={ColorTheme.color}
        />
      </Pressable>
      <View style={styles.marginTop13} />
      <View style={styles.marginTopdynamic} />
      <Pressable
        onPress={() => setIsPaymentFromCash(true)}
        style={styles.walletContainer}
      >
        {isPaymentFromCash ? (
          <RadioSelectButton
            height={FixedValue.CONSTANT_VALUE_20}
            width={FixedValue.CONSTANT_VALUE_20}
          />
        ) : (
          <RadioUnselectButton
            height={FixedValue.CONSTANT_VALUE_20}
            width={FixedValue.CONSTANT_VALUE_20}
          />
        )}
        <View style={styles.content}>
          <Text style={styles.myWalletText}>
            {TEXT.PAY_WITH_CARD}$
            {topAmountAndReportAbuseData?.success?.data?.singleTopPrice}
          </Text>
        </View>
        <CardImage
          height={FixedValue.CONSTANT_VALUE_30}
          width={FixedValue.CONSTANT_VALUE_30}
          color={ColorTheme.color}
        />
      </Pressable>
      <View style={styles.totalOrderContainer}>
        <View>
          <Text style={styles.totalOrder}>{TEXT.TOTAL_ORDER}</Text>
          <Text style={styles.topCreditText}>
            ${topAmountAndReportAbuseData?.success?.data?.singleTopPrice}
            {' Or '}
            {checkTopCreditGreaterThanOne(topAmount)}
          </Text>
        </View>
      </View>
      <View style={styles.buyButtonView}>
        <PrimaryButton
          buttonLabel={TEXT.PAY_NOW}
          buttonwidth={styles.butTopButton}
          labelStyle={styles.nextButtonTextStyle}
          onPress={() => checkPermissionForBuying()}
          isLoading={isLoading}
          isDisabled={!checkPoyNowButtonDisabled}
        />
      </View>
      {showLoader && (
        <View style={styles.loaderStyle}>
          <ActivityIndicator
            size={GlobalStyleValues.LARGE}
            color={COLORS.PRIMARY_BLUE}
          />
        </View>
      )}
      {!!showLoader && (
        <PayPalModal
          totalTopsAmount={buyNowAmountRoundOff}
          modalVisible={true}
          onClose={() => {
            setShowLoader(false);
          }}
          onSuccessPayment={() => callCartTopAPI()}
          onCancelPayment={() =>
            navigate(ROUTE_NAME.PAYMENT_SUCCESS, {
              isFailed: true,
              comingFromWallet: true,
              mainText: TEXT.TRANSACTION_CANCELED,
              subText: TEXT.WE_REGET_TO_INFORM_YOU_THAT,
            })
          }
        />
      )}
    </>
  );
};

export default React.memo(OrderSummaryForSingleTop);
