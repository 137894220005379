import React, {useEffect} from 'react';
import {FlatList, View} from 'react-native';
import TitleWithButton from '../../components/header/titleWithButton';
import SearchInput from '../../components/input/searchInput';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import SelectedTopComponent from '../../components/selectedTopComponent';
import AppLoader from '../../components/smallDesigns/appLoader';
import {FixedValue, GlobalStyleValues, ROUTE_NAME, TEXT} from '../../constants';
import {HomeContext} from '../../contextAPI/homeContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import {TradeContext} from '../../contextAPI/tradeContext';
import useHomeHooks from '../../hooks/componentHooks/useHomeHooks';
import {style} from './style';
import {useIsFocused} from '@react-navigation/native';
import ViewContainer from '../../components/layout/viewContainer';
import {ISingleTabProps} from '../../types/componentTypes/layoutType';
import tradeFavorites from './tradeFavorites';
import mantelverse from './mantelverse';
import TopTabBarLayout from '../../components/layout/topTabBarLayout';
import useFavouriteHooks from '../../hooks/componentHooks/useFavouriteHooks';

let userClicked = false;

const SelectTradeUser = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = style(ColorTheme);
  const {
    searchUserListText,
    setInitialLoader,
    initialLoader,
    searchFavouriteUserListText,
  } = React.useContext(HomeContext);
  const {onChangeSearchUserText, callUserListSearchAPI} = useHomeHooks();
  const [tabIndex, setTabIndex] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_0
  );
  const {collectionSelectedTops, setIsOtherUserTopList} =
    React.useContext(TradeContext);
  const isFocused = useIsFocused();
  const isFavoriteTabSelected = tabIndex === FixedValue.CONSTANT_VALUE_0;
  const {onChangeSearchFavouriteUser} = useHomeHooks();
  const {getfavoriteUserList} = useFavouriteHooks();

  useEffect(() => {
    setInitialLoader(true);
    getfavoriteUserList(
      searchFavouriteUserListText,
      FixedValue.CONSTANT_VALUE_0
    );
    callUserListSearchAPI(searchUserListText, FixedValue.CONSTANT_VALUE_0);
  }, []);

  React.useEffect(() => {
    if (isFocused) {
      userClicked = false;
      setIsOtherUserTopList(false);
    }
  }, [isFocused]);

  const tabBarList: ISingleTabProps[] = [
    {
      routeName: ROUTE_NAME.RECEIVED_OFFER,
      component: tradeFavorites,
      tabBarName: TEXT.FAVORITES,
    },
    {
      routeName: ROUTE_NAME.SENT_OFFER,
      component: mantelverse,
      tabBarName: TEXT.MANTELVERSE,
    },
  ];

  return (
    <ViewContainer>
      <SafeAreaContainer>
        <View style={styles.container} dataSet={{media: ids.container}}>
          {initialLoader && <AppLoader />}
          <TitleWithButton title={TEXT.SELECT_USER} />
          <FlatList
            data={collectionSelectedTops}
            style={{flexGrow: FixedValue.CONSTANT_VALUE_0}}
            keyboardShouldPersistTaps={GlobalStyleValues.HANDLED}
            keyExtractor={(_, index) => `${TEXT.TOP_LIST}-${index}`}
            showsHorizontalScrollIndicator={false}
            horizontal
            contentContainerStyle={styles.horizontalFlatListContent}
            renderItem={({item}) => (
              <SelectedTopComponent key={item.id} item={item} />
            )}
          />
          <SearchInput
            placeholder={TEXT.SEARCH_USERS}
            value={
              isFavoriteTabSelected
                ? searchFavouriteUserListText
                : searchUserListText
            }
            onChangeText={
              isFavoriteTabSelected
                ? onChangeSearchFavouriteUser
                : onChangeSearchUserText
            }
          />
          <TopTabBarLayout
            customTabStyle={styles.tabBarStyle}
            tabArray={tabBarList}
            setCurrentActiveTab={tabIndex}
            setTab={setTabIndex}
          />
        </View>
      </SafeAreaContainer>
    </ViewContainer>
  );
};

export default React.memo(SelectTradeUser);
