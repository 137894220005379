import {StyleSheet} from 'react-native';
import {BASESTYLE, COLORS, FixedValue, FONT_NAMES} from '../../../constants';
import {
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const editorStyle = StyleSheet.create({
  container: {
    height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
    paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_22),
    paddingRight: scaleWidth(FixedValue.CONSTANT_VALUE_16),
    paddingVertical: scaleHeight(FixedValue.CONSTANT_VALUE_14),
    ...BASESTYLE.inRow,
    ...BASESTYLE.spaceBetween,
  },
  crossView: {
    marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_24),
  },
  lowOpacity: {
    opacity: FixedValue.CONSTANT_VALUE_03,
    color: COLORS.WHITE,
  },
  saveTop: {
    fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_15),
    color: COLORS._00B25B,
    fontFamily: FONT_NAMES.SF_MEDIUM,
  },
});
