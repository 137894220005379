import React, {useState} from 'react';
import {ICountryType} from '../types/reducerTypes/authStoreTypes';
import {
  IUserProfileContext,
  IUserProfileProvider,
} from '../types/contextTypes/userProfileContext';
import {useAppSelector} from '../hooks/reduxHooks';
import {userProfileStore} from '../redux/reducers/userProfileSlice';

export interface ICountryTileProps {
  country: ICountryType;
  onPress: (value: ICountryType) => void;
}

const initialCountryCode = {
  name: 'United States',
  flag: '🇺🇸',
  code: 'US',
  dial_code: '+1',
};

const userProfileContext = React.createContext<IUserProfileContext>({
  showCountry: false,
  setShowCountry: _val => {},
  selectedCountry: initialCountryCode,
  setSelectedCountry: () => {},
  openCloseUploadProfileModal: false,
  setOpenCloseUploadProfileModal: () => {},
});

const UserProfileProvider = (props: IUserProfileProvider): JSX.Element => {
  const {userData} = useAppSelector(userProfileStore);
  const UserDetail = userData?.success;
  const [showCountry, setShowCountry] = React.useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = React.useState<ICountryType>({
    ...initialCountryCode,
    dial_code: UserDetail?.countryCode ?? '+1',
  });
  const [openCloseUploadProfileModal, setOpenCloseUploadProfileModal] =
    React.useState<boolean>(false);

  const _setShowCountry = React.useCallback(
    (value: boolean): void => setShowCountry(value),
    [showCountry]
  );

  const _setSelectedCountry = React.useCallback(
    (country: ICountryType): void => {
      _setShowCountry(false);
      setSelectedCountry(country);
    },
    [selectedCountry]
  );

  const _setOpenCloseUploadProfileModal = React.useCallback((): void => {
    setOpenCloseUploadProfileModal(prev => !prev);
  }, [openCloseUploadProfileModal]);

  const values = React.useMemo(
    (): IUserProfileContext => ({
      showCountry,
      selectedCountry,
      openCloseUploadProfileModal,
      setShowCountry: _setShowCountry,
      setSelectedCountry: _setSelectedCountry,
      setOpenCloseUploadProfileModal: _setOpenCloseUploadProfileModal,
    }),
    [
      showCountry,
      selectedCountry,
      openCloseUploadProfileModal,
      setShowCountry,
      setSelectedCountry,
      setOpenCloseUploadProfileModal,
    ]
  );

  return (
    <userProfileContext.Provider value={values}>
      {props.children}
    </userProfileContext.Provider>
  );
};

export {userProfileContext, UserProfileProvider};
