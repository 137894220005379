import React from 'react';
import {Pressable, Text, View} from 'react-native';
import ModalContainer from '../../components/layout/modalContainer';
import {ThemeContext} from '../../contextAPI/themeContext';
import {style} from './styles';
import {TEXT} from '../../constants';
import NoteInput from '../../components/input/noteInput';
import PrimaryButton from '../../components/button/primaryButton';
import ModalBack from '../../components/renderSvgs/modalBack';
import useTopDetails from '../../hooks/componentHooks/useTopDetailsHooks';
import {NotesModel} from '../../types/reducerTypes/searchTabStoreType';

interface INotesModalProps {
  notesData: NotesModel | null;
  showModal: boolean;
  buttonLoader: boolean;
  isNotesEditMode: boolean;
  setCloseModal: (val: boolean) => void;
}

const NotesModal = (props: INotesModalProps) => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = style(ColorTheme);
  const {notesData, showModal, setCloseModal, isNotesEditMode, buttonLoader} =
    props;
  const {
    values,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    NoteError,
  } = useTopDetails();

  React.useEffect((): void => {
    if (notesData) {
      setValues({
        Note: notesData?.noteText ?? '',
      });
    }
  }, [notesData]);

  const closeModal = () => {
    setCloseModal(false);
  };

  return (
    <ModalContainer visible={showModal} onPressOutside={closeModal}>
      <View style={styles.subContainer}>
        <View style={styles.container}>
          <Text style={styles.titleText}>
            {isNotesEditMode ? TEXT.EDIT_NEW_NOTE_ : TEXT.ADD_NEW_NOTE_}
          </Text>
          <Pressable onPress={closeModal}>
            <ModalBack />
          </Pressable>
        </View>
        <NoteInput
          inputLabel={TEXT.NOTE}
          value={values.Note}
          onChangeText={handleChange(TEXT.NOTE)}
          onBlur={handleBlur(TEXT.NOTE)}
          haveError={NoteError}
        />
        <PrimaryButton
          buttonLabel={isNotesEditMode ? TEXT.UPDATE : TEXT.SUBMIT}
          onPress={handleSubmit}
          isDisabled={!isValid}
          isLoading={buttonLoader}
        />
      </View>
    </ModalContainer>
  );
};

export default NotesModal;
