import React from 'react';
import {View, Image, Text, ActivityIndicator, Pressable} from 'react-native';
import CloseRed from '../../../components/renderSvgs/closeRed';
import {getDateFormated, getInitials} from '../../../utils/helperFunctions';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {trackingTabStyle} from './trackingTab.style';
import useAwsS3 from '../../../hooks/libraryHooks/useAwsS3';
import {COLORS, FixedValue, TEXT} from '../../../constants';
import {getTopImage} from '../../../utils/imageUtils';
import {REQUEST_TYPE} from '../../../constants/textMessage';
import RightArrowBlue from '../../../components/renderSvgs/RightArrowBlue';
import {
  BadgeProps,
  TrackingTileProps,
} from '../../../types/reducerTypes/tradeTabTypes';

const TrackingTile: React.FC<TrackingTileProps> = ({
  item,
  onPress,
  onCancelPress,
  onRemindAllPress,
}) => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = trackingTabStyle(ColorTheme);
  const {loadSignedImageUrl, loadSignedUrl} = useAwsS3();
  const [svgCode, setSvgCode] = React.useState<JSX.Element | undefined>(
    undefined
  );
  const [profileImageUrl, setProfileImageUrl] = React.useState<
    string | undefined
  >();
  const [_, setErrorInDecoding] = React.useState<boolean>();

  const _imageUrl = item.group ? item.group.groupImage : item.top?.image;
  const name = item.group ? item.group?.groupName : item.top?.name;
  const title = item.group
    ? TEXT.GROUP_INVITATION_PENDING
    : TEXT.TOP_DISTRIBUTION_PENDING;

  const {
    top = null,
    group = null,
    requestForUnRegisteredUser = false,
  } = item || {};
  let trackingType = top
    ? REQUEST_TYPE.DISTRIBUTION
    : group
    ? REQUEST_TYPE.GROUP
    : REQUEST_TYPE.DISTRIBUTION_EMAIL;

  if (requestForUnRegisteredUser) {
    trackingType = REQUEST_TYPE.DISTRIBUTION_EMAIL;
  }

  const isForDistribution =
    trackingType === REQUEST_TYPE.DISTRIBUTION ||
    trackingType === REQUEST_TYPE.DISTRIBUTION_EMAIL;
  React.useEffect(() => {
    if (isForDistribution) {
      getSvgUrl();
    } else {
      getSvgUrlGroup();
    }
  }, [_imageUrl]);

  const getSvgUrl = React.useCallback(async (): Promise<void> => {
    const imageUrl = await loadSignedUrl(_imageUrl ?? '');
    if (imageUrl) {
      const topImage = await getTopImage(imageUrl ?? '');
      setSvgCode(topImage);
    }
  }, [_imageUrl, svgCode]);

  const getSvgUrlGroup = React.useCallback(async (): Promise<void> => {
    try {
      const profileImageUrl = await loadSignedImageUrl(_imageUrl ?? '');
      if (profileImageUrl) {
        setProfileImageUrl(profileImageUrl);
      } else {
        setProfileImageUrl(undefined);
      }
    } catch (error) {
      setErrorInDecoding(true);
    }
  }, [_imageUrl]);

  const renderContentForImage = () => {
    if (isForDistribution) {
      return svgCode ? (
        <View style={styles.image}>{svgCode}</View>
      ) : (
        <View style={styles.image}>
          <ActivityIndicator size={'small'} color={COLORS.PRIMARY_BLUE} />
        </View>
      );
    } else {
      return (
        <View style={styles.placeholder}>
          {profileImageUrl ? (
            <Image source={{uri: profileImageUrl}} style={styles.image} />
          ) : (
            <Text style={styles.initials}>
              {getInitials(name || 'Unknown')}
            </Text>
          )}
        </View>
      );
    }
  };

  const Badge: React.FC<BadgeProps> = ({status, count}) => {
    return (
      <View style={[styles.badge, styles[status]]}>
        <Text style={styles.badgeText}>{`${status}: ${count}`}</Text>
      </View>
    );
  };

  return (
    <>
      <View style={styles.itemContainer}>
        <>
          {renderContentForImage()}
          <View style={styles.tutorialContainer}>
            <View style={styles.textContainer}>
              <Pressable onPress={onPress}>
                <Text style={styles.title}>{title}</Text>
                <Text style={styles.timeColor}>
                  {getDateFormated(item.creationDate)}
                </Text>
              </Pressable>
              <View style={styles.container2}>
                <Badge
                  status="Accepted"
                  count={item?.acceptedCount ?? FixedValue.CONSTANT_VALUE_0}
                />
                <Badge
                  status="Pending"
                  count={item?.pendingCount ?? FixedValue.CONSTANT_VALUE_0}
                />
                <Badge
                  status="Rejected"
                  count={item?.rejectedCount ?? FixedValue.CONSTANT_VALUE_0}
                />
              </View>
              <View style={styles.containerCanccelAll}>
                <Pressable
                  style={styles.cancelButton1}
                  // @ts-ignore
                  onPress={() => onCancelPress(item)}
                >
                  <View style={styles.rowContainer}>
                    <Text style={styles.cancelButtonText}>
                      {TEXT.CANCEL_ALL}
                    </Text>
                    <CloseRed />
                  </View>
                </Pressable>
                <Pressable
                  style={styles.remindAllBtn}
                  // @ts-ignore
                  onPress={() => onRemindAllPress(item)}
                >
                  <View style={styles.buttonContainer}>
                    <Text style={styles.sendReminderBtnText}>
                      {TEXT.REMIND_ALL}
                    </Text>
                    <RightArrowBlue />
                  </View>
                </Pressable>
              </View>
            </View>
          </View>
        </>
      </View>
      <View style={styles.underLineView}></View>
    </>
  );
};

export default TrackingTile;
