import React from 'react';
import {FlatList, View} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';

import {ThemeContext} from '../../contextAPI/themeContext';
import {ShiftingStyle} from './shifting.style';
import ShiftingTradeableComponent from '../../components/shiftingToTradeableComponent/shiftingComponent';
import {ChildProfileContext} from '../../contextAPI/childProfileContext';
import {FixedValue, TEXT} from '../../constants';
import TopsListEmptyView from '../../components/emptyListView/topsListEmptyView';
import useTradingApprovalHooks from '../../hooks/componentHooks/useTradingApprovalHooks';
import NoImage from '../../components/renderSvgs/noImage';
import {childProfileStore} from '../../redux/reducers/childProfileSlice';
import {useAppSelector} from '../../hooks/reduxHooks';
import AppLoader from '../../components/smallDesigns/appLoader';

const ShiftingTradeableContent = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = ShiftingStyle(ColorTheme);
  const {
    childTradableListData,
    childProfileContextPullDownToRefresh,
    setIsApproveChildTradable,
    setModalVisibleChildTradable,
    setChildTradable,
    initialLoader,
  } = React.useContext(ChildProfileContext);
  const {
    OnChildTradableRequestListEndReach,
    pullDownToRefreshTradableRequestList,
  } = useTradingApprovalHooks();
  const {childTradableRequestListData} = useAppSelector(childProfileStore);

  const renderChildTradableList = React.useMemo((): JSX.Element | null => {
    return (
      <FlatList
        keyExtractor={(_, index) => `ChildTradable-${index}`}
        data={childTradableListData}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.flatListContentContainerStyle}
        onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
        scrollEnabled
        onEndReached={() => OnChildTradableRequestListEndReach()}
        renderItem={({item}) => (
          <ShiftingTradeableComponent
            key={item.requestId}
            item={item}
            onPressTick={() => {
              setIsApproveChildTradable(true);
              setModalVisibleChildTradable(true);
              setChildTradable(item);
            }}
            onPressCross={() => {
              setIsApproveChildTradable(false);
              setModalVisibleChildTradable(true);
              setChildTradable(item);
            }}
          />
        )}
        ListEmptyComponent={
          childTradableRequestListData.isLoading || initialLoader ? (
            <></>
          ) : (
            <View style={styles.noChildData}>
              <TopsListEmptyView
                emptyViewDesc={''}
                placeHolder={<NoImage />}
                emptyViewTitle={TEXT.TRADEABLE_EMPTY}
              />
            </View>
          )
        }
        refreshControl={
          <RefreshControl
            refreshing={childProfileContextPullDownToRefresh}
            onRefresh={pullDownToRefreshTradableRequestList}
          />
        }
      />
    );
  }, [childTradableListData, childTradableRequestListData, initialLoader]);

  return (
    <>
      {initialLoader && <AppLoader />}
      {renderChildTradableList}
    </>
  );
};

export default React.memo(ShiftingTradeableContent);
