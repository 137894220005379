import {takeLatest, put, call, select, delay} from 'redux-saga/effects';
import * as Notifications from 'expo-notifications';

import {AppConst, FixedValue} from '../../constants';
import {
  NotificationDataList,
  NotificationGetCount,
  _updatePushTokenOnServer,
} from '../../utils/apis';
import {_ToastHandler} from '../../utils/helperFunctions';
import {IActionType} from '../../types/constantFilesTypes';
import {authState} from '../reducers/authSlice';
import {
  notificationState,
  onNotificationCountEnd,
  onNotificationCountFailed,
  onNotificationCountSuccess,
  onNotificationListEnd,
  onNotificationListFailed,
  onNotificationListSuccess,
  onUpdateTokenOnServerEnd,
  onUpdateTokenOnServerFailed,
  onUpdateTokenOnServerSuccess,
  onNotificationCompletedListSuccess,
  onNotificationCompletedListFailed,
  onNotificationCompletedListEnd,
} from '../reducers/notificationSlice';
import {
  INotificationCountResponse,
  INotificationResponse,
  IPushTokenUpdateParams,
} from '../../types/reducerTypes/notificationTypes';
import {Platform} from 'react-native';
import {userProfileStore} from '../reducers/userProfileSlice';
import {isWebsite} from '../../utils/responsive';

const {StoreConstants} = AppConst;

function* getNotificationList(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: INotificationResponse = yield call(
      NotificationDataList,
      action.payload,
      userTokens
    );
    yield put(onNotificationListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onNotificationListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onNotificationListEnd());
  }
}

function* getNotificationCount() {
  try {
    const {userTokens} = yield select(authState);
    const data: INotificationCountResponse = yield call(
      NotificationGetCount,
      userTokens
    );
    yield put(onNotificationCountSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onNotificationCountFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onNotificationCountEnd());
  }
}

function* updatePushTokenOnServer() {
  try {
    const {userTokens} = yield select(authState);
    const {userData} = yield select(userProfileStore);
    const {notificationToken} = yield select(notificationState);
    let deviceToken = notificationToken;
    if (deviceToken == null) {
      const token: Notifications.ExpoPushToken =
        yield Notifications.getExpoPushTokenAsync();
      deviceToken = token.data;
    }
    const params: IPushTokenUpdateParams = {
      deviceToken,
      deviceId: userData.success.firstName + '-' + userData.success.id,
      deviceType: Platform.OS.toUpperCase(),
    };
    const data: string = yield call(
      _updatePushTokenOnServer,
      params,
      userTokens
    );
    yield put(onUpdateTokenOnServerSuccess(data));
  } catch (error: any) {
    yield put(
      onUpdateTokenOnServerFailed(
        error?.errors[0]?.msg ?? 'Push token not updated'
      )
    );
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onUpdateTokenOnServerEnd());
  }
}

function* getNotificationCompleteList(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: INotificationResponse = yield call(
      NotificationDataList,
      action.payload,
      userTokens
    );
    yield put(onNotificationCompletedListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onNotificationCompletedListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onNotificationCompletedListEnd());
  }
}

export default function* notificationSaga() {
  yield takeLatest(
    `${StoreConstants.NOTIFICATION}/onNotificationListStart`,
    getNotificationList
  );
  yield takeLatest(
    `${StoreConstants.NOTIFICATION}/onUpdateTokenOnServerStart`,
    updatePushTokenOnServer
  );
  yield takeLatest(
    `${StoreConstants.NOTIFICATION}/onNotificationCountStart`,
    getNotificationCount
  );
  yield takeLatest(
    `${StoreConstants.NOTIFICATION}/onNotificationCompletedListStart`,
    getNotificationCompleteList
  );
}
