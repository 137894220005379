import {extendTheme} from 'native-base';
import {StyleSheet} from 'react-native';
import {COLORS} from './colors';
import {FixedValue, GlobalStyleValues} from './enum';

export const BASESTYLE = StyleSheet.create({
  flex1: {
    flex: FixedValue.CONSTANT_VALUE_1,
  },
  flexGrow1: {
    flexGrow: FixedValue.CONSTANT_VALUE_1,
  },
  flexShrink1: {
    flexShrink: FixedValue.CONSTANT_VALUE_1,
  },
  flex2: {
    flex: FixedValue.CONSTANT_VALUE_2,
  },
  flex8: {
    flex: FixedValue.CONSTANT_VALUE_8,
  },
  row: {
    flexDirection: GlobalStyleValues.ROW,
  },
  column: {
    flexDirection: GlobalStyleValues.COLUMN,
  },
  inRow: {
    alignItems: GlobalStyleValues.CENTER,
    flexDirection: GlobalStyleValues.ROW,
  },
  inCenter: {
    justifyContent: GlobalStyleValues.CENTER,
    alignItems: GlobalStyleValues.CENTER,
  },
  selfCenter: {
    alignSelf: GlobalStyleValues.CENTER,
  },
  spaceBetween: {
    justifyContent: GlobalStyleValues.SPACE_BETWEEN,
  },
  spaceEvenly: {
    justifyContent: GlobalStyleValues.SPACE_EVENLY,
  },
  center: {
    justifyContent: GlobalStyleValues.CENTER,
  },
  alignCenter: {
    alignItems: GlobalStyleValues.CENTER,
  },
  spaceAround: {
    justifyContent: GlobalStyleValues.SPACE_AROUND,
  },
  absolutePosition: {
    position: GlobalStyleValues.ABSOLUTE,
  },
  textAlignCenter: {
    textAlign: GlobalStyleValues.CENTER,
  },
});

export const nativeBaseTheme = extendTheme({
  components: {
    Input: {
      baseStyle: {
        _focus: {
          borderColor: COLORS.C7C9D9,
        },
        _hover: {
          borderColor: COLORS.PRIMARY_BLUE,
        },
      },
    },
  },
});
