import React from 'react';
import {Text, View} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import PrimaryButton from '../../components/button/primaryButton';
import {TEXT, BASESTYLE, FixedValue} from '../../constants';
import CustomButton from '../../components/button/customButton';
import {style} from './style';
import GreenTick from '../../components/renderSvgs/greenTick';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import DeleteAlertcircle from '../../components/renderSvgs/deleteAlertcircle';
import {CustomModalPropsType} from '../../types/reducerTypes/groupMangementTypes';
const CustomModalContent = (
  props: CustomModalPropsType
): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = style(ColorTheme);
  const {actionType, onClose, yesOptionClicked, 
    description, showIcon, confirmButtonTitle, rejectButtonTitle} = props;
  return (
    <View style={styles.mainContainer}>
      <View style={BASESTYLE.alignCenter}>
        {actionType === FixedValue.CONSTANT_VALUE_1 ? (
          <>
            {showIcon && (
              <GreenTick
                width={scaleWidth(FixedValue.CONSTANT_VALUE_40)}
                height={scaleHeight(FixedValue.CONSTANT_VALUE_40)}
              />
            )}

            <Text style={styles.wantToApprove}>{description}</Text>
          </>
        ) : (
          <>
            {showIcon && (
              <DeleteAlertcircle
                width={scaleWidth(FixedValue.CONSTANT_VALUE_40)}
                height={scaleHeight(FixedValue.CONSTANT_VALUE_40)}
              />
            )}
            <Text style={styles.wantToApprove}>{description}</Text>
          </>
        )}
        <View style={[BASESTYLE.inRow, BASESTYLE.inCenter]}>
          <CustomButton
            buttonLabel={rejectButtonTitle}
            buttonwidth={styles.yesOrNoButtonView}
            buttonBackGroundColor={styles.noButtonBackGroundColor}
            labelStyle={styles.noButtonLabelstyle}
            onPress={onClose}
            isDisabled={false}
          />
          <View style={styles.marginLeft16}>
            <PrimaryButton
              buttonLabel={confirmButtonTitle}
              buttonwidth={styles.yesOrNoButtonView}
              labelStyle={styles.yesButtonLabelstyle}
              onPress={yesOptionClicked}
              isDisabled={false}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default CustomModalContent;
