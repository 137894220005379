import {StyleSheet} from 'react-native';
import {BASESTYLE, FixedValue, FONT_NAMES, Percentage} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {normalizeFont, scaleHeight} from '../../utils/responsive';

export const styles = (theme?: IColorTheme) =>
  StyleSheet.create({
    listView: {
      ...BASESTYLE.row,
      ...BASESTYLE.spaceBetween,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_8),
    },
    fname: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_24),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: theme?.color,
      maxWidth: Percentage.PRECENTAGE_80,
    },
    day: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_10),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme?.color,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_8),
    },
    tops: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_REGULAR,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_24),
      color: theme?.color,
    },
    flatListView: {
      ...BASESTYLE.row,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_3),
    },
  });
