import * as React from 'react';
import Svg, {SvgProps, Path, Circle} from 'react-native-svg';
import { COLORS } from '../../constants';
const MoveArrow = (props: SvgProps) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    // @ts-ignore
   xmlns="http://www.w3.org/2000/svg"
   {...props}
  >
    <Path
      d="M15 14L20 9L15 4"
      stroke={props.stroke ?? COLORS.BLACK}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M4 20V13C4 11.9391 4.42143 10.9217 5.17157 10.1716C5.92172 9.42143 6.93913 9 8 9H20"
      stroke={props.stroke ?? COLORS.BLACK}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default MoveArrow;
