import React from 'react';
import {Pressable, View} from 'react-native';

import {editorStyle} from './editorHeader.style';
import {BASESTYLE, COLORS, FixedValue} from '../../../constants';

import ArrowLeftIcon from '../../renderSvgs/arrowLeft';
import GreenTick from '../../renderSvgs/greenTick';
import {EditorContext} from '../../../contextAPI/editorContext';
import {EditorImageContext} from '../../../contextAPI/editorImageContext';
import EditorHeaderRightIcons from '../../smallDesigns/editorHeaderRightIcons';
import {controllerName} from '../../../constants/enum';
import {ImageController} from '../../../types/contextTypes/editorContext';

const ImageHeader = (): JSX.Element => {
  const {selectedItemIndex, items, deleteSelectedItem, onClickTick} =
    React.useContext(EditorContext);
  const {setSelectedController, markImageAsUnSelected, selectedController} =
    React.useContext(EditorImageContext);

  React.useEffect((): void => {
    if (selectedItemIndex === FixedValue.CONSTANT_VALUE_MIN_1) {
      setSelectedController(null);
    }
  }, [selectedItemIndex]);

  const RenderBackBtn = React.useMemo((): JSX.Element => {
    if (items.length > FixedValue.CONSTANT_VALUE_0) {
      return <View />;
    }
    return (
      <Pressable onPress={onClickTick}>
        <ArrowLeftIcon />
      </Pressable>
    );
  }, [items]);

  const openControlller = React.useCallback(
    (value: ImageController | null): void => setSelectedController(value),
    [selectedItemIndex]
  );
  const onClickDeleteIcon = React.useCallback(() => {
    // @ts-ignore Used to access item ref
    const index = items[selectedItemIndex]?.ref?.current?.imageIndex;
    markImageAsUnSelected(index);
    deleteSelectedItem();
  }, [selectedItemIndex, items]);

  return (
    <View style={editorStyle.container}>
      {RenderBackBtn}
      <View style={BASESTYLE.inRow}>
        {selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1 && (
          <EditorHeaderRightIcons
            controllerType={selectedController}
            onPressRotate={() => openControlller(controllerName.ROTATION)}
            onPressResize={() => openControlller(controllerName.SCALE)}
            onPressDelete={onClickDeleteIcon}
          />
        )}
        <View style={editorStyle.crossView} />
        <Pressable
          disabled={items.length <= FixedValue.CONSTANT_VALUE_0}
          onPress={onClickTick}
        >
          <GreenTick
            stroke={
              items.length > FixedValue.CONSTANT_VALUE_0
                ? COLORS._00B25B
                : COLORS._7A7F85
            }
            width={FixedValue.CONSTANT_VALUE_29}
            height={FixedValue.CONSTANT_VALUE_29}
          />
        </Pressable>
      </View>
    </View>
  );
};

export default React.memo(ImageHeader);
