import React, {useEffect} from 'react';
import {Pressable, Text} from 'react-native';
import TransferTopIcon from '../../components/renderSvgs/transferTopIcon';
import {ThemeContext} from '../../contextAPI/themeContext';
import {MyWalletStyle} from './styles';
import {FixedValue, TEXT} from '../../constants';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import {ProfileTabContext} from '../../contextAPI/profileTabContext';
import TopCreditTransferModal from '../../modals/topCreditTransfer';

const TransferTopCreditsContent = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = MyWalletStyle(ColorTheme);
  const {
    openTopCreditTransferModal,
    setOpenTopCreditTransferModal,
    parentChildListData,
    setSelectedChild,
    isUserWantToTransferTopCredit,
    setIsUserWantToTransferTopCredit,
    transactionId,
  } = React.useContext(ProfileTabContext);
  const modalHeaderName = !isUserWantToTransferTopCredit
    ? TEXT.TOP_CREDITS_TRANSFER_BACK
    : TEXT.TRANSFER_TOP_CREDITS;

  const onlyRequestApprovedChildUserChild = parentChildListData?.filter(
    list => list?.accountApprovalRequests === FixedValue.CONSTANT_VALUE_0
  );

  const onOpenTransferTopCreditModal = () => {
    if (
      onlyRequestApprovedChildUserChild &&
      onlyRequestApprovedChildUserChild?.length > FixedValue.CONSTANT_VALUE_0
    ) {
      setSelectedChild(
        onlyRequestApprovedChildUserChild[FixedValue.CONSTANT_VALUE_0]
      );
    }
    setIsUserWantToTransferTopCredit(true);
    setOpenTopCreditTransferModal(true);
  };

  useEffect(() => {
    const onlyRequestApprovedChildUserChild = parentChildListData?.filter(
      list => list?.accountApprovalRequests === FixedValue.CONSTANT_VALUE_0
    );
    const pullBackChild = onlyRequestApprovedChildUserChild?.filter(
      list => list?.id === transactionId
    );
    setSelectedChild(pullBackChild?.[FixedValue.CONSTANT_VALUE_0] ?? null);
  }, [parentChildListData, transactionId]);

  return (
    <>
      <Pressable
        style={styles.transferTopCredit}
        onPress={() => onOpenTransferTopCreditModal()}
      >
        <TransferTopIcon
          height={scaleHeight(FixedValue.CONSTANT_VALUE_20)}
          width={scaleWidth(FixedValue.CONSTANT_VALUE_20)}
        />
        <Text style={styles.transferText}>{TEXT.TRANSFER_TOP_CREDITS}</Text>
      </Pressable>
      <TopCreditTransferModal
        modalVisible={openTopCreditTransferModal}
        closeModal={() => setOpenTopCreditTransferModal(false)}
        modalHeaderName={modalHeaderName}
      />
    </>
  );
};

export default React.memo(TransferTopCreditsContent);
