import StyleSheet from 'react-native-media-query';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {BASESTYLE, Percentage} from '../../../constants';

export const DistributingReceivedRequestStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.selfCenter,
      width: Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
    },
  });
