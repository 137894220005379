import React from 'react';
import {View, Text, FlatList} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {styles} from './style';
import {getDaysAgo} from '../../utils/helperFunctions';
import {IChildTradeOfferComponentParams} from '../../types/reducerTypes/childProfileTypes';
import RenderTopImage from '../RenderItemTile/renderTopImage';
import {FixedValue, TEXT} from '../../constants';

const ChildTradeComponent = (
  props: IChildTradeOfferComponentParams
): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  const {
    userDetail,
    imageArray,
    creationDate,
    showDistributeTopTitle,
    showTopDetail,
    item,
  } = props;

  return (
    <>
      <View style={style.listView}>
        <Text style={style.fname} numberOfLines={FixedValue.CONSTANT_VALUE_1}>
          {item?.requestForUnRegisteredUser
            ? item?.email
            : userDetail?.firstName}{' '}
          {userDetail?.lastName}
        </Text>
        {creationDate && (
          <Text style={style.day}>{getDaysAgo(creationDate)}</Text>
        )}
      </View>
      {showDistributeTopTitle && (
        <Text style={style.tops}>{TEXT.SELECTED_TOP_TO_DISTRIBUTE}</Text>
      )}
      {imageArray && imageArray?.length > FixedValue.CONSTANT_VALUE_0 && (
        <View style={style.flatListView}>
          <FlatList
            data={imageArray}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            renderItem={({item}) => (
              <RenderTopImage
                item={item}
                key={item?.id}
                showTopDetail={showTopDetail}
              />
            )}
          />
        </View>
      )}
    </>
  );
};

export default React.memo(ChildTradeComponent);
