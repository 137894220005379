import React from 'react';
import {FlatList, ScrollView, View, Text} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';

import TopsEmptyList from '../../components/emptyListView/topsListEmptyView';
import ImageCard from '../../components/mantelTopTabComp/imageCard';
import {BASESTYLE, FixedValue, TEXT} from '../../constants';
import {TopType} from '../../constants/enum';
import {HomeContext} from '../../contextAPI/homeContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import useInProgressTopsHook from '../../hooks/componentHooks/useInProgressTopsHooks';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import EditTopModal from '../../modals/editTopModal';
import {HomeStore} from '../../redux/reducers/homeSlice';
import {TopModel} from '../../types/reducerTypes/searchTabStoreType';
import {inProgressTopsStyle} from './inProgressTops.style';
import {updateOpenForEditValue} from '../../redux/reducers/editorSlice';
import {useIsFocused} from '@react-navigation/native';
import {checkTopCreditGreaterThanOne} from '../../utils/helperFunctions';

const InProgressTopsResultList = (): JSX.Element => {
  const {inProgressTopList} = useAppSelector(HomeStore);
  const dispatch = useAppDispatch();
  const {
    topAmount,
    topsResult,
    isRefreshing,
    isProfileSwitchToChild,
    homeScreenUserData,
    setOpenEditTopModal,
    setIsRefreshing,
    setSelectedTopData,
    selectOrUnSelectResultTop,
    updateTopResultQuantity,
    initialLoader,
  } = React.useContext(HomeContext);
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = inProgressTopsStyle(ColorTheme);
  const {onReachedEnd, callInProgressTopsListAPI, navigateToDetailScreen} =
    useInProgressTopsHook();
  const isFocused = useIsFocused();

  const handleRefresh = React.useCallback(() => {
    setIsRefreshing(true);
    callInProgressTopsListAPI(FixedValue.CONSTANT_VALUE_0);
  }, [homeScreenUserData, isRefreshing]);

  const updateQuantity = (item: TopModel, val: string) => {
    if (item.selectedQuantity === FixedValue.CONSTANT_VALUE_1) {
      {
        val === TEXT.MINNUS
          ? selectOrUnSelectResultTop(item)
          : updateTopResultQuantity(item, val);
      }
    } else {
      updateTopResultQuantity(item, val);
    }
  };

  const onChangeUpdateQuantity = (top: TopModel, quantity: number) => {
    if (quantity) {
      updateTopResultQuantity(top, TEXT.MINNUS, quantity);
    } else {
      selectOrUnSelectResultTop(top);
    }
  };

  const RenderList = React.useMemo((): JSX.Element | null => {
    if (topsResult.length > FixedValue.CONSTANT_VALUE_0) {
      return (
        <FlatList
          numColumns={FixedValue.CONSTANT_VALUE_2}
          data={[...topsResult]}
          keyExtractor={topData => `TopNumber-${topData.id}`}
          onEndReached={onReachedEnd}
          onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
          showsVerticalScrollIndicator={false}
          style={BASESTYLE.flex1}
          refreshControl={
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={handleRefresh}
            />
          }
          renderItem={({item}) => (
            <ImageCard
              key={item.id}
              {...item}
              isProfileSwitchToChild={isProfileSwitchToChild}
              type={TopType.inProgress}
              onMenuPress={() => {
                dispatch(updateOpenForEditValue(true));
                setSelectedTopData(item);
                setOpenEditTopModal();
              }}
              onBuyPressed={() => selectOrUnSelectResultTop(item)}
              onShowDetailPress={() =>
                navigateToDetailScreen(item, TopType.inProgress)
              }
              onUpdateQuantityTap={val => {
                updateQuantity(item, val);
              }}
              isInputQuantity
              onChangeQuantity={(val: number) =>
                onChangeUpdateQuantity(item, val)
              }
            />
          )}
        />
      );
    }
    return null;
  }, [topsResult, isRefreshing]);

  const RenderEmptyUI = React.useMemo((): JSX.Element | null => {
    if (topsResult.length === FixedValue.CONSTANT_VALUE_0) {
      return (
        <ScrollView
          style={BASESTYLE.flex1}
          contentContainerStyle={BASESTYLE.flex1}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={handleRefresh}
            />
          }
        >
          <TopsEmptyList
            emptyViewTitle={TEXT.YOU_HAVE_NO_TOPS}
            emptyViewDesc={''}
          />
        </ScrollView>
      );
    }
    return null;
  }, [topsResult]);

  return (
    <View style={styles.contentContainer}>
      {!inProgressTopList.isLoading && !initialLoader && RenderEmptyUI}
      {topsResult.length > FixedValue.CONSTANT_VALUE_0 && (
        <Text style={styles.eachTopCost}>
          {TEXT.EACH_TOP_COSTS}
          <Text style={styles.topCreditFont}>
            {checkTopCreditGreaterThanOne(topAmount)}
          </Text>
        </Text>
      )}
      {RenderList}
      {isFocused && <EditTopModal />}
    </View>
  );
};

export default React.memo(InProgressTopsResultList);
