import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  FixedValue,
  GlobalStyleValues,
  Percentage,
  FONT_NAMES,
} from '../../constants';
import { IColorTheme } from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const welcomeStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    outterContainer: {
      ...BASESTYLE.selfCenter,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
      justifyContent: GlobalStyleValues.FLEX_END,
      alignItems: GlobalStyleValues.CENTER,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    logoView: {
      alignItems: GlobalStyleValues.CENTER,
    },
    titleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_30),
      color: theme.color,
      fontFamily: FONT_NAMES.SF_HEAVY,
      marginVertical: scaleHeight(FixedValue.CONSTANT_VALUE_31),
      textAlign: GlobalStyleValues.CENTER,
    },
    descText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_24),
      color: theme.color,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_31),
      textAlign:GlobalStyleValues.CENTER,
    },
    bottomSpacing: {
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    button1Style: {
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_55),
      width: Percentage.PRECENTAGE_100,
    },
  });
