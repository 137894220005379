import {createSlice} from '@reduxjs/toolkit';
import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {ITradableTop} from '../../types/reducerTypes/searchTabStoreType';
import { ResetAllState } from './logoutslice';

const {StoreConstants} = AppConst;
const {
  MARK_TRADABLE_TOPS
} = ActionType;

const initialState: ITradableTop = {
  tradableTop: {
    isLoading: false,
    success: null,
    failed: null,
    status: MARK_TRADABLE_TOPS.END,
  },
};

const tradableTopSlice = createSlice({
  initialState,
  name: StoreConstants.TRADABLE_TOPS,
  reducers: {
    onTradableTopStart: (state, _) => {
      state.tradableTop.isLoading = true;
      state.tradableTop.status = MARK_TRADABLE_TOPS.START;
    },
    onTradableTopSuccess: (state, action) => {
      state.tradableTop.success = action.payload;
      state.tradableTop.status = MARK_TRADABLE_TOPS.SUCCESS;
    },
    onTradableTopFailed: (state, action) => {
      state.tradableTop.failed = action.payload;
      state.tradableTop.status = MARK_TRADABLE_TOPS.FAILED;
    },
    onTradableTopEnd: state => {
      state.tradableTop.isLoading = false;
      state.tradableTop.status = MARK_TRADABLE_TOPS.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),

});

export const {
  onTradableTopStart,
  onTradableTopSuccess,
  onTradableTopFailed,
  onTradableTopEnd
} = tradableTopSlice.actions;
export default tradableTopSlice.reducer;

export const TradableStore = (state: RootState) => state.tradableTops;
