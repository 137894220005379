import React from 'react';
import {View} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import GroupInList from './groupInList';
import {groupInListStyle} from './groupInScreen.style';

const GroupInScreen = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = groupInListStyle(ColorTheme);

  return (
    <View style={styles.listMainView}>
      <GroupInList />
    </View>
  );
};

export default React.memo(GroupInScreen);
