import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const style = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.selfCenter,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
        paddingHorizontal: FixedValue.CONSTANT_VALUE_0,
      },
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_0),
      ...BASESTYLE.flex1,
    },
    header: {
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      width: Percentage.PRECENTAGE_100,
    },
    upperContainer: {
      ...BASESTYLE.row,
    },
    buttonsView: {
      ...BASESTYLE.absolutePosition,
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      width: Percentage.PRECENTAGE_100,
    },
    primaryButtonStyle: {
      ...BASESTYLE.row,
      ...BASESTYLE.inCenter,
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: Percentage.PRECENTAGE_100,
    },
    primaryButtonStyle2: {
      ...BASESTYLE.inCenter,
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: Percentage.PRECENTAGE_100,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    primaryButtonLabelstyle1: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      color: COLORS.F54336,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    noButtonBackGroundColor: {
      backgroundColor: COLORS.FDE4E1,
    },
    primaryButtonLabelstyle: {
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    noButtonBackGroundColor1: {
      backgroundColor: COLORS._4EAF51,
    },
    nameView: {
      ...BASESTYLE.center,
      flexGrow: FixedValue.CONSTANT_VALUE_1,
      width: Percentage.PRECENTAGE_60,
    },
    owner: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      color: _theme.color,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_12),
    },
    dayText: {
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_12),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_14),
      color: _theme.color,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_5),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
      fontFamily: FONT_NAMES.SF_LIGHT,
    },
    topOwner: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      color: _theme.color,
      fontFamily: FONT_NAMES.SF_BOLD,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      marginBottom: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    mainViewStyle: {
      ...BASESTYLE.inCenter,
      borderRadius: FixedValue.CONSTANT_VALUE_10,
      backgroundColor: _theme?.color,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_69),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_69),
      overflow: GlobalStyleValues.HIDDEN,
    },
    initialsTextStyle: {
      color: _theme?.backgroundColor,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      textTransform: 'uppercase',
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    innerView: {
      ...BASESTYLE.flex1,
      width: Percentage.PRECENTAGE_100,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    seeAllText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.PRIMARY_BLUE,
    },
    seeAllView: {
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
    },
    flatStyle: {
      marginLeft: FixedValue.CONSTANT_VALUE_MIN_10,
    },
    greenColor: {
      color: COLORS._4EAF51,
    },
  });
