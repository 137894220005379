import React from 'react';
import {View} from 'react-native';
import {FixedValue} from '../../../constants';
import {ThemeContext} from '../../../contextAPI/themeContext';
import useTradeReceviedOrSentHooks from '../../../hooks/componentHooks/useTradeReceviedOrSentHooks';
import {tradeTabStyle} from './tradeTab.Style';
import TradeTabScreen from './tradeTabScreen';
import SafeAreaContainer from '../../../components/layout/safeAreaContainer';
import ViewContainer from '../../../components/layout/viewContainer';
import TradeTabHeader from '../../../components/header/tradeTabHeader/tradeTab';
import {useIsFocused} from '@react-navigation/native';
import {TradeContext} from '../../../contextAPI/tradeContext';
import AppLoader from '../../../components/smallDesigns/appLoader';
import { NotificationContext } from '../../../contextAPI/notificationContext';

const TradeTab = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = tradeTabStyle(ColorTheme);
  const {getOffersListAPI} = useTradeReceviedOrSentHooks();
  const isFocused = useIsFocused();
  const {setInitialLoader, initialLoader} = React.useContext(TradeContext);
  const {setSelectedNotificationCard} = React.useContext(NotificationContext);

  React.useEffect((): void => {
    if (isFocused) {
      setInitialLoader(true);
      getOffersListAPI('RECEIVED', FixedValue.CONSTANT_VALUE_0);
      getOffersListAPI('SENT', FixedValue.CONSTANT_VALUE_0);
      setSelectedNotificationCard(null)
    }
  }, [isFocused]);

  return (
    <SafeAreaContainer>
      <ViewContainer>
        <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
          {initialLoader && <AppLoader />}
          <TradeTabHeader />
          <TradeTabScreen />
        </View>
      </ViewContainer>
    </SafeAreaContainer>
  );
};

export default React.memo(TradeTab);
