import React from 'react';
import {View} from 'react-native';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {ProfileApprovelStyle} from './profileApprovel.style';
import {TEXT} from '../../../constants';
import CustomButton from '../../../components/button/customButton';
import {HomeContext} from '../../../contextAPI/homeContext';
import {ChildApproveListApi} from '../../../types/reducerTypes/childApproveRequestStoreTypes';
import TICKICON from '../../../components/renderSvgs/tickIcon';
import CROSSICON from '../../../components/renderSvgs/smallCrossIcon';
export type childProps = {
  item: ChildApproveListApi;
};
const ProfileApprovelButtom = (props: childProps) => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = ProfileApprovelStyle(ColorTheme);
  const {
    setOpenChildProfileApproveModal,
    setIsApprove,
    setOnApprove,
    setIsStatus,
  } = React.useContext(HomeContext);
  const getdata = props.item?.requestId;
  const approve = () => {
    setIsApprove(true);
    setOnApprove(getdata);
    setIsStatus('APPROVED');
    setOpenChildProfileApproveModal();
  };
  const reject = () => {
    setIsApprove(false);
    setOnApprove(getdata);
    setIsStatus('REJECT');
    setOpenChildProfileApproveModal();
  };
  return (
    <View style={styles.buttonView}>
      <CustomButton
        buttonLabel={TEXT.APPROVE}
        buttonwidth={styles.primaryButtonStyle}
        labelStyle={styles.primaryButtonLabelstyle}
        buttonBackGroundColor={styles.noButtonBackGroundColor1}
        element={<TICKICON />}
        onPress={() => approve()}
      />

      <CustomButton
        buttonLabel={TEXT.REJECT}
        buttonwidth={styles.primaryButtonStyle2}
        labelStyle={styles.primaryButtonLabelstyle1}
        element={<CROSSICON />}
        buttonBackGroundColor={styles.noButtonBackGroundColor}
        onPress={() => {
          reject();
        }}
      />
    </View>
  );
};

export default React.memo(ProfileApprovelButtom);
