import React from 'react';
import {GestureResponderEvent} from 'react-native';
import {Circle, G, Rect} from 'react-native-svg';
import {COLORS, FixedValue} from '../../../../constants';

import {EditorContext} from '../../../../contextAPI/editorContext';
import {
  CircleItemPositionOverride,
  CircleState,
  ShapeRefType,
  TopEditorItemProps,
  TopEditorItemType,
} from '../../../../types/componentTypes/editorType';
import {isWebsite} from '../../../../utils/responsive';

export const CIRCLE_RADIUS: number = FixedValue.CONSTANT_VALUE_250;

const BasicCircle = React.forwardRef(
  (
    {
      itemPositionOverride,
      elementId,
      elementIndex,
      selectItemCallback,
      disableInteraction,
    }: TopEditorItemProps<CircleItemPositionOverride>,
    ref: React.ForwardedRef<ShapeRefType>
  ) => {
    const {
      editorControlItemDimensions,
      editorSvgDimensions,
      selectedItemIndex,
    } = React.useContext(EditorContext);

    const _itemRadius: number = CIRCLE_RADIUS;
    const otherScale: number = isWebsite()
      ? FixedValue.CONSTANT_VALUE_025
      : FixedValue.CONSTANT_VALUE_05;

    const [isDragging, setIsDragging] = React.useState<boolean>(false);
    const [position, setPosition] = React.useState<CircleState>({
      cx: itemPositionOverride?.cx
        ? itemPositionOverride.cx
        : editorSvgDimensions.width / FixedValue.CONSTANT_VALUE_2,
      cy: itemPositionOverride?.cy
        ? itemPositionOverride.cy
        : editorSvgDimensions.height / FixedValue.CONSTANT_VALUE_2,
      radius: _itemRadius,
      isSelected: false,
      offset: {x: FixedValue.CONSTANT_VALUE_0, y: FixedValue.CONSTANT_VALUE_0},
      scale: itemPositionOverride?.scale
        ? itemPositionOverride.scale
        : otherScale,
      fillColor: itemPositionOverride?.fillColor
        ? itemPositionOverride?.fillColor
        : COLORS.WHITE,
      strokeColor: itemPositionOverride?.strokeColor
        ? itemPositionOverride?.strokeColor
        : COLORS.BLACK,
      strokeWidth: itemPositionOverride?.strokeWidth
        ? itemPositionOverride?.strokeWidth
        : FixedValue.CONSTANT_VALUE_1,
      rotation: itemPositionOverride?.rotation
        ? itemPositionOverride?.rotation
        : FixedValue.CONSTANT_VALUE_0,
    });
    const [itemIndex, setItemIndex] = React.useState<number>(elementIndex);

    React.useImperativeHandle(
      ref,
      () => ({
        getName: () => 'Circle',
        getPosition: () => position,
        renderElementToSave: () => renderNonInteractive(),
        getItemOrderTabItem: () => renderItemOrderTabItem(),
        deselect: () => setPosition(prev => ({...prev, isSelected: false})),
        changeIndex: (newIndex: number) => setItemIndex(newIndex),
        changeScale: (newScale: number) =>
          setPosition(prev => ({...prev, scale: newScale})),
        changeFillColor: (color: string): void =>
          setPosition(prev => ({...prev, fillColor: color})),
        changeStroke: (color: string, width?: number) =>
          setPosition(prev => ({
            ...prev,
            strokeColor: color,
            strokeWidth: !!width ? width : prev.strokeWidth,
          })),
        changeRotation: (newRotation: number): void =>
          setPosition(prev => ({
            ...prev,
            rotation:
              newRotation === FixedValue.CONSTANT_VALUE_360
                ? FixedValue.CONSTANT_VALUE_0
                : newRotation,
          })),
        getTopEditorItemType: (): TopEditorItemType => TopEditorItemType.SHAPE,
        getInitialScale: (): number => otherScale,
      }),
      [position, itemIndex]
    );

    const isThisShapeSelected: boolean =
      (selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1 &&
        position.isSelected) ||
      selectedItemIndex === FixedValue.CONSTANT_VALUE_MIN_1;

    const scaledRadius = React.useMemo(
      (): number => position.radius * position.scale,
      [position]
    );

    const getBBox = React.useCallback(() => {
      const width: number = scaledRadius * FixedValue.CONSTANT_VALUE_2;
      const height: number = scaledRadius * FixedValue.CONSTANT_VALUE_2;
      const top: number = position.cy - scaledRadius;
      const left: number = position.cx - scaledRadius;
      const right: number = left + width;
      const bottom: number = top + height;
      return {
        width,
        height,
        top,
        right,
        bottom,
        left,
        x: left,
        y: top,
      };
    }, [position]);

    const handlePointerDown = React.useCallback(
      (event: GestureResponderEvent): void => {
        event.stopPropagation();
        if (selectItemCallback) selectItemCallback(itemIndex);
        const el = event.target;
        const bbox = getBBox();
        const x = event.nativeEvent.pageX - bbox.left;
        const y = event.nativeEvent.pageY - bbox.top;
        // @ts-ignore Correct usage for web
        if (isWebsite()) el.setPointerCapture(event.pointerId);
        setPosition({
          ...position,
          isSelected: true,
          offset: {x: x, y: y},
        });
        setIsDragging(true);
      },
      [selectItemCallback, position, itemIndex]
    );

    const handlePointerMove = React.useCallback(
      (event: GestureResponderEvent): void => {
        const bbox = getBBox();
        const x = event.nativeEvent.pageX - bbox.left;
        const y = event.nativeEvent.pageY - bbox.top;
        const cx: number = position.cx - (position.offset.x - x);
        const cy: number = position.cy - (position.offset.y - y);
        if (position.isSelected && isDragging) {
          setPosition({
            ...position,
            cx: cx,
            cy: cy,
          });
        }
      },
      [position, isDragging]
    );

    const handlePointerUp = React.useCallback((): void => {
      setIsDragging(false);
    }, []);

    const renderItemOrderTabItem = (): JSX.Element => {
      return (
        <G>
          <Rect
            // @ts-ignore Used to show id when testing
            testID={`TE-circle-item-order-control-item-selection-box`}
            accessibilityLabel={`TE-circle-item-order-control-item-selection-box`}
            key={`${elementId}-outer`}
            id={`${elementId}-outer`}
            x={FixedValue.CONSTANT_VALUE_0}
            y={FixedValue.CONSTANT_VALUE_0}
            height={editorControlItemDimensions.height}
            width={editorControlItemDimensions.width}
            fill={COLORS.WHITE}
            fillOpacity={FixedValue.CONSTANT_VALUE_0}
          />
          <Circle
            // @ts-ignore Used to show id when testing
            testID={elementId}
            accessibilityLabel={elementId}
            key={elementId}
            id={elementId}
            cx={FixedValue.CONSTANT_VALUE_14}
            cy={FixedValue.CONSTANT_VALUE_14}
            r={FixedValue.CONSTANT_VALUE_14 - FixedValue.CONSTANT_VALUE_5}
            fill={position.fillColor}
            stroke={position.strokeColor}
            strokeWidth={position.strokeWidth}
          />
        </G>
      );
    };

    const renderNonInteractive = (): JSX.Element => {
      const rotate: string = `rotate(${position.rotation}, ${position.cx}, ${position.cy})`;
      return (
        <Circle
          id={`circle-top-element-${itemIndex}`}
          key={elementId}
          cx={position.cx}
          cy={position.cy}
          r={scaledRadius}
          fill={position.fillColor}
          stroke={position.strokeColor}
          strokeWidth={position.strokeWidth}
          transform={`${rotate}`}
        />
      );
    };

    const renderInteractive = (): JSX.Element => {
      const rotate: string = `rotate(${position.rotation}, ${position.cx}, ${position.cy})`;
      const bbox = getBBox();
      return (
        <G
          transform={`${rotate}`}
          opacity={
            isThisShapeSelected
              ? FixedValue.CONSTANT_VALUE_1
              : FixedValue.CONSTANT_VALUE_05
          }
        >
          <Rect
            // @ts-ignore Used to show id when testing
            testID={`TE-${elementId}-selection-box`}
            accessibilityLabel={`TE-${elementId}-selection-box`}
            key={`selection-box-${elementId}`}
            x={bbox.x}
            y={bbox.y}
            width={bbox.width}
            height={bbox.height}
            fill={COLORS.WHITE}
            fillOpacity={FixedValue.CONSTANT_VALUE_0}
            stroke={position.isSelected ? COLORS.PRIMARY_BLUE : ''}
            strokeWidth={FixedValue.CONSTANT_VALUE_5}
          />
          {/** Actual item */}
          <Circle
            // @ts-ignore Used to show id when testing
            testID={elementId}
            accessibilityLabel={elementId}
            key={elementId}
            id={elementId}
            cx={position.cx}
            cy={position.cy}
            r={scaledRadius}
            fill={position.fillColor}
            stroke={position.strokeColor}
            strokeWidth={position.strokeWidth}
            // @ts-ignore Used to handle pointer events
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerMove={handlePointerMove}
            onPressIn={handlePointerDown}
            onPressOut={handlePointerUp}
            onResponderMove={handlePointerMove}
          />
        </G>
      );
    };

    return disableInteraction ? renderNonInteractive() : renderInteractive();
  }
);

export default BasicCircle;
