import React from 'react';
import {FixedValue} from '../constants';
import {
  TRADE_APPROVAL_RECEIVED_LIST,
  GROUP_INVITATION_CHILD_LIST,
  TRADE_APPROVE_USER_LIST,
  TRADE_NOT_APPROVE_USER_LIST,
  USER_ADD_INTO_TRADE,
  USER_REMOVE_FROM_TRADE,
  CHILD_TRADABLE_REQUEST_LIST,
  TRADE_APPROVAL_SENT_LIST,
  REJECT_RECEIVED_OFFER,
  USER_ADD_INTO_DISTRIBUTE,
  DISTRIBUTE_APPROVE_USER_LIST,
  USER_REMOVE_FROM_DISTRIBUTE_APPROVED_LIST,
  DISTRIBUTE_REQUEST_LIST,
  DISTRIBUTE_RECEIVED_REQUEST_LIST,
} from '../constants/actionTypes';
import {TOAST_TEXT} from '../constants/textMessage';
import {useAppSelector} from '../hooks/reduxHooks';
import {childProfileStore} from '../redux/reducers/childProfileSlice';
import {
  IChildProfileProvider,
  IChildProfileContext,
  ISelectedApproveOrReject,
} from '../types/contextTypes/childProfileContextType';
import {
  ChildTradableRequest,
  OfferModel,
} from '../types/reducerTypes/tradeTabTypes';
import {
  GroupInvitationChildResult,
  UserListDataForTrade,
} from '../types/reducerTypes/childProfileTypes';
import {ChildListModel} from '../types/reducerTypes/userProfileTypes';
import {_ToastHandler} from '../utils/helperFunctions';
import {TradeTabStore} from '../redux/reducers/tradeTabSlice';
import {DistributeRequestModel} from '../types/componentTypes/childProfileType';

// Create a context with initial values
const ChildProfileContext = React.createContext<IChildProfileContext>({
  childUserData: null,
  setChildUserData: _val => {},
  addNewChildUserModal: false,
  setAddNewChildUserModal: () => {},
  addNewChildUserSearchText: '',
  setAddNewChildUserSearchText: _val => {},
  tradeUserListData: [],
  setTradeUserListData: _val => {},
  selectOrUnSelectTradeUser: _val => {},
  selectedUserInTrade: [],
  updateSelectedUserInTrade: _val => {},
  tradeApprovedUserListData: [],
  setTradeApprovedUserListData: _val => {},
  groupInvitationChildListData: [],
  setGroupInvitationChildListData: _val => {},
  childProfileContextPullDownToRefresh: false,
  setChildProfileContextPullDownToRefresh: _val => {},
  userRemoveId: 0,
  setUserRemoveId: _val => {},
  tradeReceivedList: [],
  setTradeReceivedList: _val => {},
  tradeSentList: [],
  setTradeSentList: _val => {},
  isApproveInvitation: false,
  setIsApproveInvitation: _val => {},
  modalVisible: false,
  setModalVisible: _val => {},
  groupInvitation: null,
  setGroupInvitation: _val => {},
  childTradableListData: [],
  setChildTradableListData: _val => {},
  clearGroupInvitation: () => {},
  modalVisibleGroupInvitation: false,
  setModalVisibleGroupInvitation: _val => {},
  isTradeReceivedApproved: false,
  setIsTradeReceivedApproved: _val => {},
  selectedTradeReceivedData: null,
  setSelectedTradeReceivedData: _val => {},
  showTradeApproveConfirmModal: false,
  setShowTradeApproveConfirmModal: () => {},
  showTradeSentApproveConfirmModal: false,
  setShowTradeSentApproveConfirmModal: () => {},
  isApproveChildTradable: false,
  setIsApproveChildTradable: _val => {},
  modalVisibleChildTradable: false,
  setModalVisibleChildTradable: _val => {},
  childTradable: null,
  setChildTradable: _val => {},
  clearChildTradableList: () => {},
  showTradeSettingChangePopUp: false,
  setShowTradeSettingChangePopUp: _val => {},
  showDistributeTopSettingChangePopUp: false,
  setShowDistributeTopSettingChangePopUp: _val => {},
  showDistributeUserListModal: false,
  setShowDistributeUserListModal: () => {},
  distributeApprovedUserListData: [],
  setDistributeApprovedUserListData: _val => {},
  showDistributeApproveUserRemoveAlert: false,
  setShowDistributeApproveUserRemoveAlert: _val => {},
  distributeRequestListArray: [],
  setDistributeRequestListArray: _val => {},
  searchTypeForTradeAndDistribute: '',
  setSearchTypeForTradeAndDistribute: _val => {},
  showDistributeRequestConfirmModal: false,
  setShowDistributeRequestConfirmModal: () => {},
  showTopBuyingApprovalModal: false,
  setShowTopBuyingApprovalModal: _val => {},
  selectDataForApproveOrReject: {isApprove: false, selectedData: null},
  setSelectDataForApproveOrReject: _val => {},
  showTradeApproveUserRemoveAlert: false,
  setShowTradeApproveUserRemoveAlert: _val => {},
  clearDistributeRequestListArray: () => {},
  initialLoader: false,
  setInitialLoader: _val => {},
  clearTradeReceivedList: () => {},
  clearSetTradeApprovedUserListData: () => {},
  showBuySettingChangePopUp: false,
  setShowBuySettingChangePopUp: _val => {},
  distributeReceivedRequestListArray: [],
  setDistributeReceivedRequestListArray: _val => {},
  isPaymentTypeIsCard: false,
  setIsPaymentTypeIsCard: _val => {},
});
// Define a provider for the context
const ChildProfileProvider = (props: IChildProfileProvider): JSX.Element => {
  const {
    tradeSettingApproval,
    tradeNotApprovedUserList,
    onTradeUserAdd,
    onTradeApprovedListData,
    onGroupInvitationChild,
    onUserRemoveFromTrade,
    onTradeApprovalReceivedListData,
    childTradableRequestListData,
    onTradeApprovalSentListData,
    distributeTopSettingApproval,
    onDistributeUserAdd,
    onDistributeApprovedListData,
    onUserRemoveFromDistribute,
    onDistributeRequestListData,
    onDistributeReceivedRequestData,
  } = useAppSelector(childProfileStore);
  const {rejectOffer} = useAppSelector(TradeTabStore);
  const [childUserData, setChildUserData] =
    React.useState<ChildListModel | null>(null);
  const [addNewChildUserModal, setAddNewChildUserModal] =
    React.useState<boolean>(false);
  const [addNewChildUserSearchText, setAddNewChildUserSearchText] =
    React.useState<string>('');
  const [tradeUserListData, setTradeUserListData] = React.useState<
    UserListDataForTrade[]
  >([]);
  const [_, selectOrUnSelectTradeUser] =
    React.useState<UserListDataForTrade | null>(null);
  const [selectedUserInTrade, selSelectedUserInTrade] = React.useState<
    UserListDataForTrade[]
  >([]);
  const [tradeApprovedUserListData, setTradeApprovedUserListData] =
    React.useState<UserListDataForTrade[]>([]);
  const [groupInvitationChildListData, setGroupInvitationChildListData] =
    React.useState<GroupInvitationChildResult[]>([]);
  const [
    childProfileContextPullDownToRefresh,
    setChildProfileContextPullDownToRefresh,
  ] = React.useState<boolean>(false);
  const [userRemoveId, setUserRemoveId] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_0
  );
  const [tradeReceivedList, setTradeReceivedList] = React.useState<
    OfferModel[]
  >([]);
  const [tradeSentList, setTradeSentList] = React.useState<OfferModel[]>([]);
  const [isApproveInvitation, setIsApproveInvitation] =
    React.useState<boolean>(false);
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [groupInvitation, setGroupInvitation] =
    React.useState<GroupInvitationChildResult | null>(null);
  const [childTradableListData, setChildTradableListData] = React.useState<
    ChildTradableRequest[]
  >([]);
  const [modalVisibleGroupInvitation, setModalVisibleGroupInvitation] =
    React.useState<boolean>(false);
  const [isTradeReceivedApproved, setIsTradeReceivedApproved] =
    React.useState<boolean>(false);
  const [selectedTradeReceivedData, setSelectedTradeReceivedData] =
    React.useState<OfferModel | null | DistributeRequestModel>(null);
  const [showTradeApproveConfirmModal, setShowTradeApproveConfirmModal] =
    React.useState<boolean>(false);
  const [
    showTradeSentApproveConfirmModal,
    setShowTradeSentApproveConfirmModal,
  ] = React.useState<boolean>(false);
  const [isApproveChildTradable, setIsApproveChildTradable] =
    React.useState<boolean>(false);
  const [modalVisibleChildTradable, setModalVisibleChildTradable] =
    React.useState<boolean>(false);
  const [childTradable, setChildTradable] =
    React.useState<ChildTradableRequest | null>(null);
  const [showTradeSettingChangePopUp, setShowTradeSettingChangePopUp] =
    React.useState<boolean>(false);
  const [
    showDistributeTopSettingChangePopUp,
    setShowDistributeTopSettingChangePopUp,
  ] = React.useState<boolean>(false);
  const [showDistributeUserListModal, setShowDistributeUserListModal] =
    React.useState<boolean>(false);
  const [distributeApprovedUserListData, setDistributeApprovedUserListData] =
    React.useState<UserListDataForTrade[]>([]);
  const [
    showDistributeApproveUserRemoveAlert,
    setShowDistributeApproveUserRemoveAlert,
  ] = React.useState<boolean>(false);
  const [showTradeApproveUserRemoveAlert, setShowTradeApproveUserRemoveAlert] =
    React.useState<boolean>(false);
  const [distributeRequestListArray, setDistributeRequestListArray] =
    React.useState<DistributeRequestModel[]>([]);
  const [searchTypeForTradeAndDistribute, setSearchTypeForTradeAndDistribute] =
    React.useState<string>('');
  const [
    showDistributeRequestConfirmModal,
    setShowDistributeRequestConfirmModal,
  ] = React.useState<boolean>(false);
  const [showTopBuyingApprovalModal, setShowTopBuyingApprovalModal] =
    React.useState<boolean>(false);
  const [selectDataForApproveOrReject, setSelectDataForApproveOrReject] =
    React.useState<ISelectedApproveOrReject>({
      isApprove: false,
      selectedData: null,
    });
  const [initialLoader, setInitialLoader] = React.useState<boolean>(false);
  const [showBuySettingChangePopUp, setShowBuySettingChangePopUp] =
    React.useState<boolean>(false);
  const [
    distributeReceivedRequestListArray,
    setDistributeReceivedRequestListArray,
  ] = React.useState<DistributeRequestModel[]>([]);
  const [isPaymentTypeIsCard, setIsPaymentTypeIsCard] =
    React.useState<boolean>(false);

  const _setChildUserData = React.useCallback(
    (NewData: ChildListModel | null): void => {
      setChildUserData(NewData);
    },
    [childUserData]
  );
  const _setSearchTypeForTradeAndDistribute = React.useCallback(
    (NewData: string): void => {
      setSearchTypeForTradeAndDistribute(NewData);
    },
    [searchTypeForTradeAndDistribute]
  );

  const _setSelectedTradeReceivedData = React.useCallback(
    (NewData: OfferModel | null | DistributeRequestModel): void => {
      setSelectedTradeReceivedData(NewData);
    },
    [selectedTradeReceivedData]
  );

  //pulldown refresh variable setter function for child profile context
  const _setChildProfileContextPullDownToRefresh = React.useCallback(
    (val: boolean): void => {
      setChildProfileContextPullDownToRefresh(val);
    },
    [childProfileContextPullDownToRefresh]
  );

  const _setShowDistributeApproveUserRemoveAlert = React.useCallback(
    (val: boolean): void => {
      setShowDistributeApproveUserRemoveAlert(val);
    },
    [showDistributeApproveUserRemoveAlert]
  );

  const _setShowTradeApproveUserRemoveAlert = React.useCallback(
    (val: boolean): void => {
      setShowTradeApproveUserRemoveAlert(val);
    },
    [showTradeApproveUserRemoveAlert]
  );

  //initial loader for child tab all screen
  const _setInitialLoader = React.useCallback(
    (val: boolean): void => {
      setInitialLoader(val);
    },
    [initialLoader]
  );

  const _setShowTradeSettingChangePopUp = React.useCallback(
    (val: boolean): void => {
      setShowTradeSettingChangePopUp(val);
    },
    [showTradeSettingChangePopUp]
  );

  const _setShowDistributeTopSettingChangePopUp = React.useCallback(
    (val: boolean): void => {
      setShowDistributeTopSettingChangePopUp(val);
    },
    [showDistributeTopSettingChangePopUp]
  );

  const _setShowTradeApproveConfirmModal = React.useCallback((): void => {
    setShowTradeApproveConfirmModal(prev => !prev);
  }, [showTradeApproveConfirmModal]);

  const _setShowDistributeUserListModal = React.useCallback((): void => {
    setShowDistributeUserListModal(prev => !prev);
  }, [showDistributeUserListModal]);

  const _setShowTradeSentApproveConfirmModal = React.useCallback((): void => {
    setShowTradeSentApproveConfirmModal(prev => !prev);
  }, [showTradeSentApproveConfirmModal]);

  const _setShowDistributeRequestConfirmModal = React.useCallback((): void => {
    setShowDistributeRequestConfirmModal(prev => !prev);
  }, [showDistributeRequestConfirmModal]);

  const _setIsTradeReceivedApproved = React.useCallback(
    (val: boolean): void => {
      setIsTradeReceivedApproved(val);
    },
    [isTradeReceivedApproved]
  );

  const _updateSelectedUserInTrade = React.useCallback(
    (data: UserListDataForTrade[]): void => {
      setTradeUserListData([]);
      selSelectedUserInTrade(data);
    },
    [selectedUserInTrade, tradeUserListData]
  );

  const _setTradeUserListData = React.useCallback(
    (NewData: UserListDataForTrade[]): void => {
      setTradeUserListData(prev => [...prev, ...NewData]);
    },
    [tradeUserListData]
  );

  const _setDistributeRequestListArray = React.useCallback(
    (NewData: DistributeRequestModel[]): void => {
      setDistributeRequestListArray(prev => [...prev, ...NewData]);
    },
    [distributeRequestListArray]
  );

  const _clearDistributeRequestListArray = React.useCallback((): void => {
    setDistributeRequestListArray([]);
  }, []);

  const _setTradeReceivedList = React.useCallback(
    (NewData: OfferModel[]): void => {
      setTradeReceivedList(prev => [...prev, ...NewData]);
    },
    [tradeReceivedList]
  );

  const _setTradeSentList = React.useCallback(
    (NewData: OfferModel[]): void => {
      setTradeSentList(prev => [...prev, ...NewData]);
    },
    [tradeSentList]
  );

  const _setTradeApprovedUserListData = React.useCallback(
    (NewData: UserListDataForTrade[]): void => {
      setTradeApprovedUserListData(prev => [...prev, ...NewData]);
    },
    [tradeApprovedUserListData]
  );

  const _setDistributeApprovedUserListData = React.useCallback(
    (NewData: UserListDataForTrade[]): void => {
      setDistributeApprovedUserListData(prev => [...prev, ...NewData]);
    },
    [distributeApprovedUserListData]
  );

  const _setAddNewChildUserModal = React.useCallback((): void => {
    setAddNewChildUserModal(prev => !prev);
  }, [addNewChildUserModal]);

  const _setAddNewChildUserSearchText = React.useCallback(
    (value: string): void => {
      setAddNewChildUserSearchText(value);
    },
    [addNewChildUserSearchText]
  );

  const _selectOrUnSelectTradeUser = React.useCallback(
    (data: UserListDataForTrade): void => {
      // Update the users list value in the users array
      setTradeUserListData(prev => {
        return prev.map(item => {
          if (item.id === data?.id) {
            return {
              ...item,
              alreadyPresentUser: !data.alreadyPresentUser,
            };
          }
          return item;
        });
      });
      // Check if the User is already selected
      const index = selectedUserInTrade.findIndex(
        selected => selected?.id === data?.id
      );
      if (index !== FixedValue.CONSTANT_VALUE_MIN_1) {
        // User is already selected, remove it from the selected items array
        const newSelectedItems = [...selectedUserInTrade];
        newSelectedItems.splice(index, FixedValue.CONSTANT_VALUE_1);
        selSelectedUserInTrade(newSelectedItems);
      } else {
        // User is not selected, add it to the selected items array
        let item = {...data};
        item.alreadyPresentUser = !item.alreadyPresentUser;
        selSelectedUserInTrade([...selectedUserInTrade, item]);
      }
    },
    [tradeUserListData]
  );

  const _setGroupInvitationChildListData = React.useCallback(
    (NewData: GroupInvitationChildResult[]): void => {
      setGroupInvitationChildListData(prev => [...prev, ...NewData]);
    },
    [groupInvitationChildListData]
  );

  const _setIsApproveInvitation = React.useCallback(
    (val: boolean): void => {
      setIsApproveInvitation(val);
    },
    [isApproveInvitation]
  );

  const _setModalVisible = React.useCallback(
    (val: boolean): void => {
      setModalVisible(val);
    },
    [modalVisible]
  );

  const _setGroupInvitation = React.useCallback(
    (val: GroupInvitationChildResult): void => {
      setGroupInvitation(val);
    },
    [groupInvitation]
  );

  const _setChildTradableListData = React.useCallback(
    (NewData: ChildTradableRequest[]): void => {
      setChildTradableListData(prev => [...prev, ...NewData]);
    },
    [childTradableListData]
  );

  const _clearGroupInvitation = React.useCallback((): void => {
    setGroupInvitationChildListData([]);
  }, []);

  const _clearTradeReceivedList = React.useCallback((): void => {
    setTradeReceivedList([]);
  }, []);

  const _clearSetTradeApprovedUserListData = React.useCallback((): void => {
    setTradeApprovedUserListData([]);
  }, []);

  const _setModalVisibleGroupInvitation = React.useCallback(
    (val: boolean): void => {
      setModalVisibleGroupInvitation(val);
    },
    [modalVisibleGroupInvitation]
  );

  const _setIsApproveChildTradable = React.useCallback(
    (val: boolean): void => {
      setIsApproveChildTradable(val);
    },
    [isApproveChildTradable]
  );

  const _setModalVisibleChildTradable = React.useCallback(
    (val: boolean): void => {
      setModalVisibleChildTradable(val);
    },
    [modalVisibleChildTradable]
  );

  const _setShowTopBuyingApprovalModal = React.useCallback(
    (val: boolean): void => {
      setShowTopBuyingApprovalModal(val);
    },
    []
  );

  const _setChildTradable = React.useCallback(
    (val: ChildTradableRequest): void => {
      setChildTradable(val);
    },
    [childTradable]
  );

  const _clearChildTradableList = React.useCallback((): void => {
    setChildTradableListData([]);
  }, []);

  const _setShowBuySettingChangePopUp = React.useCallback(
    (val: boolean): void => {
      setShowBuySettingChangePopUp(val);
    },
    [showBuySettingChangePopUp]
  );

  //on user remove from trade list api response handle
  React.useEffect(() => {
    switch (onUserRemoveFromTrade.status) {
      case USER_REMOVE_FROM_TRADE.SUCCESS:
        setShowTradeApproveUserRemoveAlert(false);
        _ToastHandler(
          TOAST_TEXT.USER_SUCCESFULLY_REMOVED_FROM_TRADE_LIST,
          true
        );
        break;
      default:
        break;
    }
  }, [onUserRemoveFromTrade.status]);

  //on user remove from distribute list api response handle
  React.useEffect(() => {
    switch (onUserRemoveFromDistribute.status) {
      case USER_REMOVE_FROM_DISTRIBUTE_APPROVED_LIST.SUCCESS:
        _ToastHandler(
          TOAST_TEXT.USER_SUCCESFULLY_REMOVED_FROM_DISTRIBUTE_LIST,
          true
        );
        setShowDistributeApproveUserRemoveAlert(false);
        break;
      default:
        break;
    }
  }, [onUserRemoveFromDistribute.status]);

  React.useEffect(() => {
    switch (rejectOffer.status) {
      case REJECT_RECEIVED_OFFER.SUCCESS:
        if (selectedTradeReceivedData?.id) {
          setTradeReceivedList(
            tradeReceivedList?.filter(
              user => user?.id !== selectedTradeReceivedData?.id
            )
          );
          setSelectedTradeReceivedData(null);
        }
        break;
      case REJECT_RECEIVED_OFFER.FAILED:
        break;
      default:
        break;
    }
  }, [rejectOffer.status]);

  //on user add on trade for not approved user list api response handle
  React.useEffect(() => {
    switch (tradeNotApprovedUserList.status) {
      case TRADE_NOT_APPROVE_USER_LIST.SUCCESS:
        // Create a new array by mapping over userListArray and updating matching items from selectedUser
        const updatedArray2 =
          tradeNotApprovedUserList.success?.data?.results?.map(item1 => {
            const matchingItem2 = selectedUserInTrade.find(
              item2 => item2.id === item1.id
            );
            return matchingItem2
              ? {...item1, ...matchingItem2, alreadyPresentUser: true}
              : item1;
          });
        if (
          tradeNotApprovedUserList.success?.pageNumber ===
          FixedValue.CONSTANT_VALUE_0
        ) {
          setTradeUserListData(updatedArray2 ?? []);
        } else {
          _setTradeUserListData(updatedArray2 ?? []);
        }
        break;
      case TRADE_NOT_APPROVE_USER_LIST.FAILED:
        break;
      default:
        break;
    }
  }, [tradeNotApprovedUserList.status]);

  //trade approved user list api response handle
  React.useEffect(() => {
    switch (onTradeApprovedListData.status) {
      case TRADE_APPROVE_USER_LIST.SUCCESS:
        if (
          onTradeApprovedListData.success?.pageNumber ===
          FixedValue.CONSTANT_VALUE_0
        ) {
          setTradeApprovedUserListData(
            onTradeApprovedListData.success?.data?.results ?? []
          );
        } else {
          _setTradeApprovedUserListData(
            onTradeApprovedListData.success?.data?.results ?? []
          );
        }
        setInitialLoader(false);
        setChildProfileContextPullDownToRefresh(false);
        break;
      case TRADE_APPROVE_USER_LIST.FAILED:
        setInitialLoader(false);
        setChildProfileContextPullDownToRefresh(false);
        break;
      default:
        break;
    }
  }, [onTradeApprovedListData.status]);

  //distribute approved user list api response handle
  React.useEffect(() => {
    switch (onDistributeApprovedListData.status) {
      case DISTRIBUTE_APPROVE_USER_LIST.SUCCESS:
        if (
          onDistributeApprovedListData.success?.pageNumber ===
          FixedValue.CONSTANT_VALUE_0
        ) {
          setDistributeApprovedUserListData(
            onDistributeApprovedListData.success?.data?.results ?? []
          );
        } else {
          _setDistributeApprovedUserListData(
            onDistributeApprovedListData.success?.data?.results ?? []
          );
        }
        setInitialLoader(false);
        setChildProfileContextPullDownToRefresh(false);
        break;
      case DISTRIBUTE_APPROVE_USER_LIST.FAILED:
        setInitialLoader(false);
        setChildProfileContextPullDownToRefresh(false);
        break;
      default:
        break;
    }
  }, [onDistributeApprovedListData.status]);

  //distribute request list api response handle
  React.useEffect(() => {
    switch (onDistributeRequestListData.status) {
      case DISTRIBUTE_REQUEST_LIST.SUCCESS:
        if (
          onDistributeRequestListData.success?.pageNumber ===
          FixedValue.CONSTANT_VALUE_0
        ) {
          setDistributeRequestListArray(
            onDistributeRequestListData.success?.data?.results ?? []
          );
        } else {
          _setDistributeRequestListArray(
            onDistributeRequestListData.success?.data?.results ?? []
          );
        }
        setInitialLoader(false);
        setChildProfileContextPullDownToRefresh(false);
        break;
      case DISTRIBUTE_REQUEST_LIST.FAILED:
        setInitialLoader(false);
        setChildProfileContextPullDownToRefresh(false);
        break;
      default:
        break;
    }
  }, [onDistributeRequestListData.status]);

  //child trade received list api response handle
  React.useEffect(() => {
    switch (onTradeApprovalReceivedListData.status) {
      case TRADE_APPROVAL_RECEIVED_LIST.SUCCESS:
        if (
          onTradeApprovalReceivedListData.success?.pageNumber ===
          FixedValue.CONSTANT_VALUE_0
        ) {
          setTradeReceivedList(
            onTradeApprovalReceivedListData.success?.data?.results ?? []
          );
        } else {
          _setTradeReceivedList(
            onTradeApprovalReceivedListData.success?.data?.results ?? []
          );
        }
        setInitialLoader(false);
        setChildProfileContextPullDownToRefresh(false);
        break;
      case TRADE_APPROVAL_RECEIVED_LIST.FAILED:
        setInitialLoader(false);
        setChildProfileContextPullDownToRefresh(false);
        break;
      default:
        break;
    }
  }, [onTradeApprovalReceivedListData.status]);

  //child trade sent list api response handle
  React.useEffect(() => {
    switch (onTradeApprovalSentListData.status) {
      case TRADE_APPROVAL_SENT_LIST.SUCCESS:
        if (
          onTradeApprovalSentListData.success?.pageNumber ===
          FixedValue.CONSTANT_VALUE_0
        ) {
          setTradeSentList(
            onTradeApprovalSentListData.success?.data?.results ?? []
          );
        } else {
          _setTradeSentList(
            onTradeApprovalSentListData.success?.data?.results ?? []
          );
        }
        setInitialLoader(false);
        setChildProfileContextPullDownToRefresh(false);
        break;
      case TRADE_APPROVAL_RECEIVED_LIST.FAILED:
        setInitialLoader(false);
        setChildProfileContextPullDownToRefresh(false);
        break;
      default:
        break;
    }
  }, [onTradeApprovalSentListData.status]);

  //user add in trade approved list response handle
  React.useEffect(() => {
    switch (onTradeUserAdd.status) {
      case USER_ADD_INTO_TRADE.SUCCESS:
        _ToastHandler(TOAST_TEXT.USER_SUCCESSFULLY_ADDED, true);
        _setAddNewChildUserModal();
        break;
      default:
        break;
    }
  }, [onTradeUserAdd.status]);

  //user add in distribute approved list response handle
  React.useEffect(() => {
    switch (onDistributeUserAdd.status) {
      case USER_ADD_INTO_DISTRIBUTE.SUCCESS:
        _ToastHandler(TOAST_TEXT.USER_SUCCESSFULLY_ADDED, true);
        _setShowDistributeUserListModal();
        break;
      default:
        break;
    }
  }, [onDistributeUserAdd.status]);

  React.useEffect(() => {
    switch (onGroupInvitationChild.status) {
      case GROUP_INVITATION_CHILD_LIST.SUCCESS:
        if (!onGroupInvitationChild.success) {
          setGroupInvitationChildListData([]);
        } else if (
          onGroupInvitationChild.success?.pageNumber ===
          FixedValue.CONSTANT_VALUE_0
        ) {
          setGroupInvitationChildListData(
            onGroupInvitationChild.success?.data?.results ?? []
          );
        } else {
          _setGroupInvitationChildListData(
            onGroupInvitationChild.success?.data?.results ?? []
          );
        }
        setInitialLoader(false);
        setChildProfileContextPullDownToRefresh(false);
        break;
      case GROUP_INVITATION_CHILD_LIST.FAILED:
        setInitialLoader(false);
        setChildProfileContextPullDownToRefresh(false);
        break;
      default:
        break;
    }
  }, [onGroupInvitationChild.status]);

  React.useEffect(() => {
    switch (childTradableRequestListData.status) {
      case CHILD_TRADABLE_REQUEST_LIST.SUCCESS:
        if (
          childTradableRequestListData.success?.pageNumber ===
          FixedValue.CONSTANT_VALUE_0
        ) {
          setChildTradableListData(
            childTradableRequestListData.success?.data?.results ?? []
          );
        } else {
          _setChildTradableListData(
            childTradableRequestListData.success?.data?.results ?? []
          );
        }
        setInitialLoader(false);
        setChildProfileContextPullDownToRefresh(false);
        break;
      case CHILD_TRADABLE_REQUEST_LIST.FAILED:
        setChildProfileContextPullDownToRefresh(false);
        break;
      default:
        break;
    }
  }, [childTradableRequestListData.status]);

  const _setDistributeReceivedRequestListArray = React.useCallback(
    (NewData: DistributeRequestModel[]): void => {
      setDistributeReceivedRequestListArray(prev => [...prev, ...NewData]);
    },
    [distributeReceivedRequestListArray]
  );

  //distribute received request list api response handle
  React.useEffect(() => {
    switch (onDistributeReceivedRequestData.status) {
      case DISTRIBUTE_RECEIVED_REQUEST_LIST.SUCCESS:
        if (
          onDistributeReceivedRequestData.success?.pageNumber ===
          FixedValue.CONSTANT_VALUE_0
        ) {
          setDistributeReceivedRequestListArray(
            onDistributeReceivedRequestData.success?.data?.results ?? []
          );
        } else {
          _setDistributeReceivedRequestListArray(
            onDistributeReceivedRequestData.success?.data?.results ?? []
          );
        }
        setInitialLoader(false);
        setChildProfileContextPullDownToRefresh(false);
        break;
      case DISTRIBUTE_RECEIVED_REQUEST_LIST.FAILED:
        setInitialLoader(false);
        setChildProfileContextPullDownToRefresh(false);
        break;
      default:
        break;
    }
  }, [onDistributeReceivedRequestData.status]);

  const _setIsPaymentTypeIsCard = React.useCallback(
    (val: boolean): void => {
      setIsPaymentTypeIsCard(val);
    },
    [isPaymentTypeIsCard]
  );

  // Memoize the context values using useMemo
  const values = React.useMemo(
    (): IChildProfileContext => ({
      childUserData,
      setChildUserData: _setChildUserData,
      addNewChildUserModal,
      setAddNewChildUserModal: _setAddNewChildUserModal,
      addNewChildUserSearchText,
      setAddNewChildUserSearchText: _setAddNewChildUserSearchText,
      tradeUserListData,
      setTradeUserListData: _setTradeUserListData,
      selectOrUnSelectTradeUser: _selectOrUnSelectTradeUser,
      selectedUserInTrade,
      updateSelectedUserInTrade: _updateSelectedUserInTrade,
      tradeApprovedUserListData,
      setTradeApprovedUserListData: _setTradeApprovedUserListData,
      groupInvitationChildListData,
      setGroupInvitationChildListData: _setGroupInvitationChildListData,
      childProfileContextPullDownToRefresh,
      setChildProfileContextPullDownToRefresh:
        _setChildProfileContextPullDownToRefresh,
      userRemoveId,
      setUserRemoveId,
      tradeReceivedList,
      setTradeReceivedList: _setTradeReceivedList,
      tradeSentList,
      setTradeSentList: _setTradeSentList,
      isApproveInvitation,
      setIsApproveInvitation: _setIsApproveInvitation,
      modalVisible,
      setModalVisible: _setModalVisible,
      groupInvitation,
      setGroupInvitation: _setGroupInvitation,
      childTradableListData,
      setChildTradableListData: _setChildTradableListData,
      clearGroupInvitation: _clearGroupInvitation,
      modalVisibleGroupInvitation,
      setModalVisibleGroupInvitation: _setModalVisibleGroupInvitation,
      isTradeReceivedApproved,
      setIsTradeReceivedApproved: _setIsTradeReceivedApproved,
      selectedTradeReceivedData,
      setSelectedTradeReceivedData: _setSelectedTradeReceivedData,
      showTradeApproveConfirmModal,
      setShowTradeApproveConfirmModal: _setShowTradeApproveConfirmModal,
      showTradeSentApproveConfirmModal,
      setShowTradeSentApproveConfirmModal: _setShowTradeSentApproveConfirmModal,
      isApproveChildTradable,
      setIsApproveChildTradable: _setIsApproveChildTradable,
      modalVisibleChildTradable,
      setModalVisibleChildTradable: _setModalVisibleChildTradable,
      childTradable,
      setChildTradable: _setChildTradable,
      clearChildTradableList: _clearChildTradableList,
      showTradeSettingChangePopUp,
      setShowTradeSettingChangePopUp: _setShowTradeSettingChangePopUp,
      showDistributeTopSettingChangePopUp,
      setShowDistributeTopSettingChangePopUp:
        _setShowDistributeTopSettingChangePopUp,
      showDistributeUserListModal,
      setShowDistributeUserListModal: _setShowDistributeUserListModal,
      distributeApprovedUserListData,
      setDistributeApprovedUserListData: _setDistributeApprovedUserListData,
      showDistributeApproveUserRemoveAlert,
      setShowDistributeApproveUserRemoveAlert:
        _setShowDistributeApproveUserRemoveAlert,
      distributeRequestListArray,
      setDistributeRequestListArray: _setDistributeRequestListArray,
      searchTypeForTradeAndDistribute,
      setSearchTypeForTradeAndDistribute: _setSearchTypeForTradeAndDistribute,
      showDistributeRequestConfirmModal,
      setShowDistributeRequestConfirmModal:
        _setShowDistributeRequestConfirmModal,
      showTopBuyingApprovalModal,
      setShowTopBuyingApprovalModal: _setShowTopBuyingApprovalModal,
      selectDataForApproveOrReject,
      setSelectDataForApproveOrReject,
      showTradeApproveUserRemoveAlert,
      setShowTradeApproveUserRemoveAlert: _setShowTradeApproveUserRemoveAlert,
      clearDistributeRequestListArray: _clearDistributeRequestListArray,
      initialLoader,
      setInitialLoader: _setInitialLoader,
      clearTradeReceivedList: _clearTradeReceivedList,
      clearSetTradeApprovedUserListData: _clearSetTradeApprovedUserListData,
      showBuySettingChangePopUp,
      setShowBuySettingChangePopUp: _setShowBuySettingChangePopUp,
      distributeReceivedRequestListArray,
      setDistributeReceivedRequestListArray:
        _setDistributeReceivedRequestListArray,
      isPaymentTypeIsCard,
      setIsPaymentTypeIsCard: _setIsPaymentTypeIsCard,
    }),
    [
      childUserData,
      setChildUserData,
      addNewChildUserModal,
      setAddNewChildUserModal,
      addNewChildUserSearchText,
      setAddNewChildUserSearchText,
      tradeUserListData,
      setTradeUserListData,
      selectOrUnSelectTradeUser,
      selectedUserInTrade,
      tradeApprovedUserListData,
      setTradeApprovedUserListData,
      groupInvitationChildListData,
      setGroupInvitationChildListData,
      childProfileContextPullDownToRefresh,
      setChildProfileContextPullDownToRefresh,
      userRemoveId,
      setUserRemoveId,
      tradeReceivedList,
      setTradeReceivedList,
      isApproveInvitation,
      setIsApproveInvitation,
      modalVisible,
      setModalVisible,
      groupInvitation,
      setGroupInvitation,
      childTradableListData,
      setChildTradableListData,
      modalVisibleGroupInvitation,
      setModalVisibleGroupInvitation,
      isTradeReceivedApproved,
      setIsTradeReceivedApproved,
      selectedTradeReceivedData,
      setSelectedTradeReceivedData,
      showTradeApproveConfirmModal,
      setShowTradeApproveConfirmModal,
      tradeSentList,
      setTradeSentList,
      showTradeSentApproveConfirmModal,
      setShowTradeSentApproveConfirmModal,
      isApproveChildTradable,
      setIsApproveChildTradable,
      modalVisibleChildTradable,
      setModalVisibleChildTradable,
      childTradable,
      setChildTradable,
      showTradeSettingChangePopUp,
      setShowTradeSettingChangePopUp,
      showDistributeTopSettingChangePopUp,
      setShowDistributeTopSettingChangePopUp,
      showDistributeUserListModal,
      setShowDistributeUserListModal,
      distributeApprovedUserListData,
      setDistributeApprovedUserListData,
      showDistributeApproveUserRemoveAlert,
      setShowDistributeApproveUserRemoveAlert,
      distributeRequestListArray,
      setDistributeRequestListArray,
      searchTypeForTradeAndDistribute,
      setSearchTypeForTradeAndDistribute,
      showDistributeRequestConfirmModal,
      setShowDistributeRequestConfirmModal,
      showTopBuyingApprovalModal,
      setShowTopBuyingApprovalModal,
      selectDataForApproveOrReject,
      setSelectDataForApproveOrReject,
      showTradeApproveUserRemoveAlert,
      setShowTradeApproveUserRemoveAlert,
      initialLoader,
      setInitialLoader,
      showBuySettingChangePopUp,
      setShowBuySettingChangePopUp,
      distributeReceivedRequestListArray,
      setDistributeReceivedRequestListArray,
      isPaymentTypeIsCard,
      setIsPaymentTypeIsCard,
    ]
  );

  return (
    <ChildProfileContext.Provider value={values}>
      {props.children}
    </ChildProfileContext.Provider>
  );
};

export {ChildProfileContext, ChildProfileProvider};
