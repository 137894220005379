import React from 'react';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {Text, TextInput} from 'react-native';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {NativeBaseProvider} from 'native-base';
import TextEncodingPolyfill from 'text-encoding';
import BigInt from 'big-integer';

import MainNavigation from './src/navigations/mainNavigation';
import UserAppState from './src/navigations/userAppState';
import {store, persistor} from './src/redux/store';
import {ThemeProvider} from './src/contextAPI/themeContext';
import {BASESTYLE, nativeBaseTheme} from './src/constants/baseStyle';
import {TopDetailProvider} from './src/contextAPI/topDetailContext';
import {HomeProvider} from './src/contextAPI/homeContext';
import {CreateCollectionProvider} from './src/contextAPI/createCollectionContext';
import {CreateGroupProvider} from './src/contextAPI/groupManagementContext';
import {TradeProvider} from './src/contextAPI/tradeContext';
import {NotificationProvider} from './src/contextAPI/notificationContext';
import {ProfileTabProvider} from './src/contextAPI/profileTabContext';
import {LoginProvider} from './src/contextAPI/loginContext';
import {SignUpProvider} from './src/contextAPI/signUpContext';
import {ChildProfileProvider} from './src/contextAPI/childProfileContext';
import NoInternetModal from './src/modals/noInternetModal';
import AppLoader from './src/components/smallDesigns/appLoader';
import {PayPalScriptProvider} from '@paypal/react-paypal-js';
import {paypalOption} from './src/constants/environment';
import {UserProfileProvider} from './src/contextAPI/userProfileContext';
import {FavoriteProvider} from './src/contextAPI/favoriteContext';

Object.assign(global, {
  TextEncoder: TextEncodingPolyfill.TextEncoder,
  TextDecoder: TextEncodingPolyfill.TextDecoder,
  BigInt,
});

// Define the defaultProps property on the Text type
(Text as any).defaultProps = (Text as any).defaultProps || {};
(Text as any).defaultProps.allowFontScaling = false;

// Define the defaultProps property on the TextInput type
(TextInput as any).defaultProps = (TextInput as any).defaultProps || {};
(TextInput as any).defaultProps.allowFontScaling = false;

const App = (): JSX.Element => {
  const [initialRouteName, setInitialRouteName] = React.useState<
    string | undefined
  >();

  return (
    <GestureHandlerRootView style={BASESTYLE.flex1}>
      <PayPalScriptProvider options={paypalOption}>
        <Provider store={store}>
          <PersistGate loading={<AppLoader />} persistor={persistor}>
            <LoginProvider>
              <SignUpProvider>
                <HomeProvider>
                  <TopDetailProvider>
                    <CreateCollectionProvider>
                      <CreateGroupProvider>
                        <TradeProvider>
                          <NotificationProvider>
                            <ProfileTabProvider>
                              <UserProfileProvider>
                                <ChildProfileProvider>
                                  <FavoriteProvider>
                                    <ThemeProvider>
                                      <NativeBaseProvider
                                        theme={nativeBaseTheme}
                                      >
                                        <UserAppState
                                          setInitialRouteName={
                                            setInitialRouteName
                                          }
                                        >
                                          {initialRouteName && (
                                            <>
                                              <MainNavigation
                                                initialRouteName={
                                                  initialRouteName
                                                }
                                              />
                                              <NoInternetModal />
                                            </>
                                          )}
                                        </UserAppState>
                                      </NativeBaseProvider>
                                    </ThemeProvider>
                                  </FavoriteProvider>
                                </ChildProfileProvider>
                              </UserProfileProvider>
                            </ProfileTabProvider>
                          </NotificationProvider>
                        </TradeProvider>
                      </CreateGroupProvider>
                    </CreateCollectionProvider>
                  </TopDetailProvider>
                </HomeProvider>
              </SignUpProvider>
            </LoginProvider>
          </PersistGate>
        </Provider>
      </PayPalScriptProvider>
    </GestureHandlerRootView>
  );
};

export default App;
