import React from 'react';
import {View, FlatList} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';

import {ThemeContext} from '../../../contextAPI/themeContext';
import {DistributingApproveListStyle} from './distributingAppeove.style';
import {FixedValue, TEXT} from '../../../constants';
import TopsListEmptyView from '../../../components/emptyListView/topsListEmptyView';
import ApproveListEmpty from '../../../components/renderSvgs/approveListEmpty';
import {ChildProfileContext} from '../../../contextAPI/childProfileContext';
import useTradingApprovalHooks from '../../../hooks/componentHooks/useTradingApprovalHooks';
import {useAppSelector} from '../../../hooks/reduxHooks';
import {childProfileStore} from '../../../redux/reducers/childProfileSlice';
import {USER_ADD_INTO_DISTRIBUTE} from '../../../constants/actionTypes';
import TradeApproveTile from '../../../components/RenderItemTile/tradeApproveTile';

const DistributingTopApproveListScreen = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = DistributingApproveListStyle(ColorTheme);
  const {
    initialLoader,
    distributeApprovedUserListData,
    childProfileContextPullDownToRefresh,
  } = React.useContext(ChildProfileContext);
  const {
    callDistributeApproveListAPI,
    onDistributeApproveUserListEndReach,
    pullDownToRefreshOnDistributeApproveList,
  } = useTradingApprovalHooks();
  const {onDistributeUserAdd, onDistributeApprovedListData} =
    useAppSelector(childProfileStore);
  const {distributeApproveUserRemoveAlertFunction} = useTradingApprovalHooks();

  React.useEffect(() => {
    switch (onDistributeUserAdd.status) {
      case USER_ADD_INTO_DISTRIBUTE.SUCCESS:
        callDistributeApproveListAPI(FixedValue.CONSTANT_VALUE_0);
        break;
      case USER_ADD_INTO_DISTRIBUTE.FAILED:
        break;
      default:
        break;
    }
  }, [onDistributeUserAdd.status]);

  const renderDistributeApproveListFlatList =
    React.useMemo((): JSX.Element | null => {
      return (
        <FlatList
          keyExtractor={(_, index) =>
            `${TEXT.DISTRIBUTE_APPROVE_LIST}-${index}`
          }
          data={distributeApprovedUserListData}
          contentContainerStyle={styles.flatListContentContainerStyle}
          onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
          showsVerticalScrollIndicator={false}
          scrollEnabled
          ListEmptyComponent={
            onDistributeApprovedListData.isLoading || initialLoader ? (
              <></>
            ) : (
              <TopsListEmptyView
                emptyViewTitle={TEXT.THERE_ARE_NO_REQUEST}
                emptyViewDesc={''}
                placeHolder={<ApproveListEmpty />}
              />
            )
          }
          onEndReached={() => onDistributeApproveUserListEndReach()}
          refreshControl={
            <RefreshControl
              refreshing={childProfileContextPullDownToRefresh}
              onRefresh={pullDownToRefreshOnDistributeApproveList}
            />
          }
          renderItem={({item}) => (
            <TradeApproveTile
              key={item.id}
              item={item}
              onPressUserRemoveFunction={
                distributeApproveUserRemoveAlertFunction
              }
            />
          )}
        />
      );
    }, [distributeApprovedUserListData, onDistributeApprovedListData]);

  return (
    <View style={styles.marginTop20}>
      {renderDistributeApproveListFlatList}
    </View>
  );
};

export default React.memo(DistributingTopApproveListScreen);
