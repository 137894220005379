import {takeLatest, put, call, select, delay} from 'redux-saga/effects';
import {AppConst, TEXT} from '../../constants';
import {IActionType} from '../../types/constantFilesTypes';
import {
  IChildSettingApprovalResponse,
  IGroupInvitationChildSuccessRespone,
  ITradeUserListSuccessResponse,
  IUserAddOnGroupRespones,
} from '../../types/reducerTypes/childProfileTypes';
import {
  ChildDistributeRequest,
  ChildTradableRequestResponsePayload,
  TradeTabReceviedOrSentArray,
} from '../../types/reducerTypes/tradeTabTypes';
import {
  _childGroupSettingApprovalAPI,
  _childSentTradeApproveOrReject,
  _childTradableRequestListApiFunction,
  _groupInvitationChildAPI,
  _onChildTradaleApproveReject,
  _onMyGroupInvitationsApprove,
  _onMyGroupInvitationsReject,
  _tradeApproveUserListAPI,
  _tradeNotApproveUserListAPI,
  _tradeSettingApprovalAPI,
  _userAddForTradeApproval,
  _userRemoveFromTradeApprove,
  _userTradeApprovalListApiFunction,
  _childTradableSettingAPI,
  _topDistributeSettingApprovalAPI,
  _userAddForDistributeApproval,
  _distributeApproveUserListAPI,
  _userRemoveFromDistributeApprove,
  _distributeRequestListAPIFunction,
  _childSentDistributeApproveOrReject,
  _distributeRequestReceivedListAPIFunction,
  _distributeReceivedRequestApproveOrRejectOrReport,
} from '../../utils/apis';
import {authState} from '../reducers/authSlice';
import {
  onTradeSettingSuccess,
  onTradeSettingFailed,
  onTradeSettingEnd,
  onTradeNotApprovedUserListSuccess,
  onTradeNotApprovedUserListFailed,
  onTradeNotApprovedUserListEnd,
  onTradeUserAddSuccess,
  onTradeUserAddFailed,
  onTradeUserAddEnd,
  onTradeApprovedListSuccess,
  onTradeApprovedListFailed,
  onTradeApprovedListEnd,
  onGroupInvitationChildSuccess,
  onGroupInvitationChildFailed,
  onGroupInvitationChildEnd,
  onUserRemoveFromTradeApprovedListSuccess,
  onUserRemoveFromTradeApprovedListFailed,
  onUserRemoveFromTradeApprovedListEnd,
  onTradeApprovalReceivedListSuccess,
  onTradeApprovalReceivedListFailed,
  onTradeApprovalReceivedListEnd,
  onGroupChildInvitaionApproveEnd,
  onGroupChildInvitaionApproveFailed,
  onGroupChildInvitaionApproveSuccess,
  onGroupChildInvitaionRejectSuccess,
  onGroupChildInvitaionRejectFailed,
  onGroupChildInvitaionRejectEnd,
  onChildGroupSettingSuccess,
  onChildGroupSettingFailed,
  onChildGroupSettingEnd,
  onchildTradableRequestListSuccess,
  onchildTradableRequestListFailed,
  onchildTradableRequestListEnd,
  onTradeApprovalSentListSuccess,
  onTradeApprovalSentListFailed,
  onTradeApprovalSentListEnd,
  onSentTradeApproveOrRejectSuccess,
  onSentTradeApproveOrRejectFailed,
  onSentTradeApproveOrRejectEnd,
  onChildTradableSettingSuccess,
  onChildTradableSettingFailed,
  onChildTradableSettingEnd,
  onChildTradableApproveRejectSuccess,
  onChildTradableApproveRejectFailed,
  onChildTradableApproveRejectEnd,
  onDistributeTopSettingSuccess,
  onDistributeTopSettingFailed,
  onDistributeTopSettingEnd,
  onDistributeUserAddSuccess,
  onDistributeUserAddFailed,
  onDistributeUserAddEnd,
  onDistributeApprovedListSuccess,
  onDistributeApprovedListFailed,
  onDistributeApprovedListEnd,
  onUserRemoveFromDistributeApprovedListSuccess,
  onUserRemoveFromDistributeApprovedListFailed,
  onUserRemoveFromDistributeApprovedListEnd,
  onDistributeRequestListSuccess,
  onDistributeRequestListFailed,
  onDistributeRequestListEnd,
  onSentDistributeApproveOrRejectSuccess,
  onSentDistributeApproveOrRejectFailed,
  onSentDistributeApproveOrRejectEnd,
  onDistributeReceivedRequestListSuccess,
  onDistributeReceivedRequestListFailed,
  onDistributeReceivedRequestListEnd,
  onReceivedDistributeApproveOrRejectOrReportSuccess,
  onReceivedDistributeApproveOrRejectOrReportFailed,
  onReceivedDistributeApproveOrRejectOrReportEnd,
} from '../reducers/childProfileSlice';
import {isWebsite} from '../../utils/responsive';
import {showAlert, _ToastHandler} from '../../utils/helperFunctions';
import {
  ACCEPTED_OR_REJECTED,
  APPROVED_OR_REJECT,
  FixedValue,
} from '../../constants/enum';
import {TOAST_TEXT} from '../../constants/textMessage';

const {StoreConstants} = AppConst;

function* tradeSettingApprovalFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IChildSettingApprovalResponse = yield call(
      _tradeSettingApprovalAPI,
      action.payload,
      userTokens
    );
    yield put(onTradeSettingSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onTradeSettingFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onTradeSettingEnd());
  }
}

function* tradeNotApproveUserListFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ITradeUserListSuccessResponse = yield call(
      _tradeNotApproveUserListAPI,
      action.payload,
      userTokens
    );
    yield put(onTradeNotApprovedUserListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onTradeNotApprovedUserListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onTradeNotApprovedUserListEnd());
  }
}

function* userAddForTradeApproval(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IChildSettingApprovalResponse = yield call(
      _userAddForTradeApproval,
      action.payload,
      userTokens
    );
    yield put(onTradeUserAddSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onTradeUserAddFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onTradeUserAddEnd());
  }
}

function* userTradeApproveListFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ITradeUserListSuccessResponse = yield call(
      _tradeApproveUserListAPI,
      action.payload,
      userTokens
    );
    yield put(onTradeApprovedListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onTradeApprovedListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onTradeApprovedListEnd());
  }
}

function* groupInvitationChildFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IGroupInvitationChildSuccessRespone = yield call(
      _groupInvitationChildAPI,
      action.payload,
      userTokens
    );
    yield put(onGroupInvitationChildSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onGroupInvitationChildFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onGroupInvitationChildEnd());
  }
}

function* userRemoveFromTradeApproveListFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IChildSettingApprovalResponse = yield call(
      _userRemoveFromTradeApprove,
      action.payload,
      userTokens
    );
    yield put(onUserRemoveFromTradeApprovedListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onUserRemoveFromTradeApprovedListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onUserRemoveFromTradeApprovedListEnd());
  }
}

function* onTradeApprovalReceivedListFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: TradeTabReceviedOrSentArray = yield call(
      _userTradeApprovalListApiFunction,
      action.payload,
      userTokens
    );
    yield put(onTradeApprovalReceivedListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onTradeApprovalReceivedListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onTradeApprovalReceivedListEnd());
  }
}

function* onGroupChildInvitationsApprove(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserAddOnGroupRespones = yield call(
      _onMyGroupInvitationsApprove,
      action.payload,
      userTokens
    );
    yield put(onGroupChildInvitaionApproveSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onGroupChildInvitaionApproveFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onGroupChildInvitaionApproveEnd());
  }
}

function* onGroupChildInvitationsReject(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserAddOnGroupRespones = yield call(
      _onMyGroupInvitationsReject,
      action.payload,
      userTokens
    );
    yield put(onGroupChildInvitaionRejectSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onGroupChildInvitaionRejectFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onGroupChildInvitaionRejectEnd());
  }
}

function* childGroupSettingApprovalFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IChildSettingApprovalResponse = yield call(
      _childGroupSettingApprovalAPI,
      action.payload,
      userTokens
    );
    yield put(onChildGroupSettingSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onChildGroupSettingFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onChildGroupSettingEnd());
  }
}

function* onchildTradableRequestListFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ChildTradableRequestResponsePayload = yield call(
      _childTradableRequestListApiFunction,
      action.payload,
      userTokens
    );
    yield put(onchildTradableRequestListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onchildTradableRequestListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onchildTradableRequestListEnd());
  }
}

function* onTradeApprovalSentListFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: TradeTabReceviedOrSentArray = yield call(
      _userTradeApprovalListApiFunction,
      action.payload,
      userTokens
    );
    yield put(onTradeApprovalSentListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onTradeApprovalSentListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onTradeApprovalSentListEnd());
  }
}

function* onSentTradeApproveOrRejectFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserAddOnGroupRespones = yield call(
      _childSentTradeApproveOrReject,
      action.payload,
      userTokens
    );
    const toastMsg: string =
      action.payload.status === APPROVED_OR_REJECT.APPROVED
        ? TOAST_TEXT.CHILD_TRADE_SUCCESSFULLY_APPROVED
        : TOAST_TEXT.CHILD_TRADE_SUCCESSFULLY_REJECT;
    _ToastHandler(toastMsg, true);
    yield put(onSentTradeApproveOrRejectSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onSentTradeApproveOrRejectFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onSentTradeApproveOrRejectEnd());
  }
}

function* childTradableSettingFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IChildSettingApprovalResponse = yield call(
      _childTradableSettingAPI,
      action.payload,
      userTokens
    );
    yield put(onChildTradableSettingSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onChildTradableSettingFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onChildTradableSettingEnd());
  }
}

function* onChildTradableApproveReject(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserAddOnGroupRespones = yield call(
      _onChildTradaleApproveReject,
      action.payload,
      userTokens
    );
    const isApprove: boolean =
      action.payload.status === APPROVED_OR_REJECT.APPROVED;
    _ToastHandler(
      isApprove
        ? TEXT.SHIFTING_TO_TRADEABLE_APPROVED
        : TEXT.SHIFTING_TO_TRADEABLE_REJECTED,
      true
    );
    yield put(onChildTradableApproveRejectSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onChildTradableApproveRejectFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onChildTradableApproveRejectEnd());
  }
}

function* distributeTopSettingApprovalFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IChildSettingApprovalResponse = yield call(
      _topDistributeSettingApprovalAPI,
      action.payload,
      userTokens
    );
    yield put(onDistributeTopSettingSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onDistributeTopSettingFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onDistributeTopSettingEnd());
  }
}

function* userAddForDistributeApproval(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IChildSettingApprovalResponse = yield call(
      _userAddForDistributeApproval,
      action.payload,
      userTokens
    );
    yield put(onDistributeUserAddSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onDistributeUserAddFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onDistributeUserAddEnd());
  }
}

function* userDistributeApproveListFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ITradeUserListSuccessResponse = yield call(
      _distributeApproveUserListAPI,
      action.payload,
      userTokens
    );
    yield put(onDistributeApprovedListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onDistributeApprovedListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onDistributeApprovedListEnd());
  }
}

function* userRemoveFromDistributeApproveListFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IChildSettingApprovalResponse = yield call(
      _userRemoveFromDistributeApprove,
      action.payload,
      userTokens
    );
    yield put(onUserRemoveFromDistributeApprovedListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onUserRemoveFromDistributeApprovedListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onUserRemoveFromDistributeApprovedListEnd());
  }
}

function* onDistributeRequestListFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ChildDistributeRequest = yield call(
      _distributeRequestListAPIFunction,
      action.payload,
      userTokens
    );
    yield put(onDistributeRequestListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onDistributeRequestListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onDistributeRequestListEnd());
  }
}

function* onSentDistributeRequestApproveOrRejectFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserAddOnGroupRespones = yield call(
      _childSentDistributeApproveOrReject,
      action.payload,
      userTokens
    );
    const toastMsg: string =
      action.payload.status === ACCEPTED_OR_REJECTED.ACCEPTED
        ? TOAST_TEXT.CHILD_DISTRIBUTE_SUCCESSFULLY_APPROVED
        : TOAST_TEXT.CHILD_DISTRIBUTE_SUCCESSFULLY_REJECT;
    _ToastHandler(toastMsg, true);
    yield put(onSentDistributeApproveOrRejectSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onSentDistributeApproveOrRejectFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onSentDistributeApproveOrRejectEnd());
  }
}

function* onDistributeReceivedRequestListFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ChildDistributeRequest = yield call(
      _distributeRequestReceivedListAPIFunction,
      action.payload,
      userTokens
    );
    yield put(onDistributeReceivedRequestListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onDistributeReceivedRequestListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onDistributeReceivedRequestListEnd());
  }
}

function* onReceivedDistributeRequestApproveOrRejectFunction(
  action: IActionType
) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserAddOnGroupRespones = yield call(
      _distributeReceivedRequestApproveOrRejectOrReport,
      action.payload,
      userTokens
    );
    if (action.payload?.status !== ACCEPTED_OR_REJECTED.REPORTED) {
      const toastMsg: string =
        action.payload?.status === ACCEPTED_OR_REJECTED.ACCEPTED
          ? TOAST_TEXT.DISTRIBUTE_SUCCESSFULLY_APPROVED
          : TOAST_TEXT.DISTRIBUTE_SUCCESSFULLY_REJECT;
      _ToastHandler(toastMsg, true);
    } else {
      showAlert();
    }
    yield put(onReceivedDistributeApproveOrRejectOrReportSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onReceivedDistributeApproveOrRejectOrReportFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onReceivedDistributeApproveOrRejectOrReportEnd());
  }
}

export default function* childProfileSaga() {
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onTradeSettingStart`,
    tradeSettingApprovalFunction
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onTradeNotApprovedUserListStart`,
    tradeNotApproveUserListFunction
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onTradeUserAddStart`,
    userAddForTradeApproval
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onTradeApprovedListStart`,
    userTradeApproveListFunction
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onGroupInvitationChildStart`,
    groupInvitationChildFunction
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onUserRemoveFromTradeApprovedListStart`,
    userRemoveFromTradeApproveListFunction
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onTradeApprovalReceivedListStart`,
    onTradeApprovalReceivedListFunction
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onGroupChildInvitaionApproveStart`,
    onGroupChildInvitationsApprove
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onGroupChildInvitaionRejectStart`,
    onGroupChildInvitationsReject
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onChildGroupSettingStart`,
    childGroupSettingApprovalFunction
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onchildTradableRequestListStart`,
    onchildTradableRequestListFunction
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onTradeApprovalSentListStart`,
    onTradeApprovalSentListFunction
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onSentTradeApproveOrRejectStart`,
    onSentTradeApproveOrRejectFunction
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onChildTradableSettingStart`,
    childTradableSettingFunction
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onChildTradableApproveRejectStart`,
    onChildTradableApproveReject
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onDistributeTopSettingStart`,
    distributeTopSettingApprovalFunction
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onDistributeUserAddStart`,
    userAddForDistributeApproval
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onDistributeApprovedListStart`,
    userDistributeApproveListFunction
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onUserRemoveFromDistributeApprovedListStart`,
    userRemoveFromDistributeApproveListFunction
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onDistributeRequestListStart`,
    onDistributeRequestListFunction
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onSentDistributeApproveOrRejectStart`,
    onSentDistributeRequestApproveOrRejectFunction
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onDistributeReceivedRequestListStart`,
    onDistributeReceivedRequestListFunction
  );
  yield takeLatest(
    `${StoreConstants.CHILD_PROFILE}/onReceivedDistributeApproveOrRejectOrReportStart`,
    onReceivedDistributeRequestApproveOrRejectFunction
  );
}
