import React from 'react';
import GroupInvitationsList from './groupInvitationsList';
import {
  GROUP_INVITATION_APPROVE,
  GROUP_INVITATION_REJECT,
} from '../../constants/actionTypes';
import {groupManagementStore} from '../../redux/reducers/groupManagementSlice';
import {useAppSelector} from '../../hooks/reduxHooks';
import {GroupContext} from '../../contextAPI/groupManagementContext';
import {_ToastHandler} from '../../utils/helperFunctions';
import {FixedValue} from '../../constants';
import useGroupManagementListHooks from '../../hooks/componentHooks/useGroupManagementListHooks';

const GroupInvitaions = (): JSX.Element => {
  const {groupInvitaionApprove, groupInvitaionReject} =
    useAppSelector(groupManagementStore);
  const {setGroupInvitationApprovedModal} = React.useContext(GroupContext);
  const {callGroupInvitationListAPI, callGroupInListAPI} =
    useGroupManagementListHooks();

  React.useEffect(() => {
    switch (groupInvitaionApprove.status) {
      case GROUP_INVITATION_APPROVE.SUCCESS:
        callGroupInvitationListAPI(FixedValue.CONSTANT_VALUE_0);
        callGroupInListAPI(FixedValue.CONSTANT_VALUE_0);
        break;
      case GROUP_INVITATION_APPROVE.FAILED:
        setGroupInvitationApprovedModal();
        break;
      default:
        break;
    }
  }, [groupInvitaionApprove.status]);

  React.useEffect(() => {
    switch (groupInvitaionReject.status) {
      case GROUP_INVITATION_REJECT.SUCCESS:
        callGroupInvitationListAPI(FixedValue.CONSTANT_VALUE_0);
        callGroupInListAPI(FixedValue.CONSTANT_VALUE_0);
        break;
      default:
        break;
    }
  }, [groupInvitaionReject.status]);

  return <GroupInvitationsList />;
};

export default React.memo(GroupInvitaions);
