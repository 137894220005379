import StyleSheet from 'react-native-media-query';
import {COLORS, FixedValue, Percentage} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {scaleHeight, scaleWidth} from '../../utils/responsive';

export const applyCouponCodeModalStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    subContainer: {
      backgroundColor: theme.backgroundColor,
      width: Percentage.PRECENTAGE_100,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_18),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_15),
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_18,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_18,
      shadowColor: COLORS._333333,
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_0,
        height: -FixedValue.CONSTANT_VALUE_3,
      },
      elevation: FixedValue.CONSTANT_VALUE_3,
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
    },
    labelInputStyle: {
      marginTop: scaleHeight(-FixedValue.CONSTANT_VALUE_20),
    },
  });
