import React from 'react';
import {View, Pressable, ViewStyle, Image} from 'react-native';
import {FixedValue, IMAGES} from '../../constants';

import {COLORS, PELETTE_COLOR} from '../../constants/colors';
import {IColorCollection} from '../../types/componentTypes/editorType';
import {styles} from './smallDesign.style';

const ColorCollections = (props: IColorCollection): JSX.Element => {
  const [selectedColor, setSelectedColor] = React.useState<string | null>(null);
  const {onSelectColor, showTransparentColor} = props;
  const style = styles();
  const onClickColor = React.useCallback(
    (color: string): void => {
      onSelectColor(color);
      setSelectedColor(color);
    },
    [selectedColor]
  );
  return (
    <View style={style.colorButton}>
      {showTransparentColor && (
        <Pressable onPress={() => onClickColor(COLORS.TRANSPARENT)}>
          <Image
            source={IMAGES.TRANSPARENT}
            resizeMode="contain"
            style={style.colorView}
          />
        </Pressable>
      )}
      {PELETTE_COLOR.map(color => {
        const borderWidth: number =
          selectedColor === color
            ? FixedValue.CONSTANT_VALUE_3
            : FixedValue.CONSTANT_VALUE_1;
        const colorStyle: ViewStyle = {backgroundColor: color, borderWidth};
        return (
          <Pressable
            key={color}
            style={[style.colorView, colorStyle]}
            onPress={() => onClickColor(color)}
          />
        );
      })}
    </View>
  );
};

export default ColorCollections;
