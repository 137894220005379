import StyleSheet from 'react-native-media-query';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {
  BASESTYLE,
  COLORS,
  FONT_NAMES,
  FixedValue,
  Percentage,
  GlobalStyleValues,
} from '../../../constants';
import {
  SCREEN_WIDTH,
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const ProfileApprovelStyle = (theme?: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : SCREEN_WIDTH,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    headerstyle: {
      ...BASESTYLE.inRow,
    },
    headertext: {
      alignItems: 'center',
      ...BASESTYLE.flex1,
    },
    textView: {
      marginLeft: FixedValue.CONSTANT_VALUE_21,
    },
    profileText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: theme?.color,
    },
    titleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: theme?.color,
    },
    titleTextan: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_LIGHT,
      color: theme?.color,
    },

    titleText1: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_24),
      color: theme?.placeHolderColor,
    },
    titleTextAge: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
      fontFamily: FONT_NAMES.SF_BOLD,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_24),
      color: theme?.placeHolderColor,
    },
    titleTextDay: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_10),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_24),
      color: theme?.color,
    },
    imageView: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_38),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_38),
      backgroundColor: theme?.color,
      ...BASESTYLE.inCenter,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
    },
    profileNameText: {
      textAlign: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      color: theme?.backgroundColor,
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    backgroundColorPlaceholder: {
      backgroundColor: theme?.color,
    },
    primaryButtonStyle: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_38),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_121),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      marginRight: FixedValue.CONSTANT_VALUE_10,
    },
    primaryButtonStyle2: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_38),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_118),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
    },
    primaryButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    primaryButtonLabelstyle1: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      color: COLORS.F54336,
    },
    noButtonBackGroundColor: {
      backgroundColor: COLORS.FDE4E1,
    },
    noButtonBackGroundColor1: {
      backgroundColor: COLORS._4EAF51,
    },
    buttonView: {
      ...BASESTYLE.row,
      ...BASESTYLE.spaceBetween,
      marginTop: FixedValue.CONSTANT_VALUE_11,
    },
    mainView: {
      ...BASESTYLE.row,
    },
    maxWidthForText: {
      maxWidth: Percentage.PRECENTAGE_95,
    },
    flex1: {
      ...BASESTYLE.flex1,
    },
    flatListView: {
      ...BASESTYLE.flex1,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_19),
    },
    bottomLineStyle: {
      backgroundColor: COLORS._E6E6E6,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_1),
    },
  });
