import React from 'react';
import {View} from 'react-native';
import MultiSlider from '@ptomasroos/react-native-multi-slider';

import {styles} from '../footer/editorFooter/editorFooter.style';
import {FixedValue} from '../../constants';
import {scaleWidth} from '../../utils/responsive';
import Cursor from '../renderSvgs/cursor';
import {ISizeSlider} from '../../types/componentTypes/editorType';

const SizeSlider = (props: ISizeSlider): JSX.Element => {
  const {values, onValuesChange, RightIcon, LeftIcon, min, max} = props;
  return (
    <View style={styles.sliderView}>
      <>
        {LeftIcon}
        <View style={styles.slider}>
          <MultiSlider
            values={values}
            onValuesChange={onValuesChange}
            trackStyle={styles.trackStyle}
            selectedStyle={styles.trackStyle}
            sliderLength={scaleWidth(FixedValue.CONSTANT_VALUE_287)}
            customMarker={() => <Cursor />}
            step={FixedValue.CONSTANT_VALUE_001}
            min={min}
            max={max}
          />
        </View>
        {RightIcon}
      </>
    </View>
  );
};

export default React.memo(SizeSlider);
