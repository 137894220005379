import {createSlice} from '@reduxjs/toolkit';
import {AppConst, ActionType, FixedValue} from '../../constants';
import {RootState} from '../store';
import {IHomeType} from '../../types/reducerTypes/searchTabStoreType';
import {ResetAllState} from './logoutslice';
import {
  CHECK_FOR_MULTIPLE_ACCOUNT,
  DISTRIBUTE_TOP_VIA_CSV,
  DISTRIBUTE_TO_EMAIL_TEXT,
  REPORT_ABUSE_REQUEST_DATA,
  TOP_AMOUNT_AND_REPORT_ABUSE_REQUEST_DATA,
  VERIFY_COUPON_CODE,
  VERIFY_REEDEM_CODE,
} from '../../constants/actionTypes';

const {StoreConstants} = AppConst;
const {
  GET_IN_PROGRESS_TOPS,
  COLLECTION_TOP_LISTS,
  GET_PURCHASED_TOPS,
  BUY_TOPS,
  GET_TOPS_BY_COLLECTION,
  ADD_TOPS_TO_COLLECTION,
  DELETE_COLLECTIONS_TOPS_DATA,
  DELETE_TOPS,
  USER_LIST_SEARCH,
  DISTRIBUTE_SEARCH,
  GROUP_LIST_SEARCH,
  GROUP_USER_LIST_SEARCH,
  CREATE_OFFER,
  TOPS_MOVE_TO_COLLECTION,
  COUNTER_OFFER,
  CART_CHECKOUT,
  BUYING_REQUEST_TO_PARENT,
} = ActionType;

const initialState: IHomeType = {
  inProgressTopList: {
    isLoading: false,
    success: null,
    failed: null,
    status: GET_IN_PROGRESS_TOPS.END,
  },
  collectionTopLists: {
    isLoading: false,
    success: null,
    failed: null,
    status: COLLECTION_TOP_LISTS.END,
  },
  purchasedTopLists: {
    isLoading: false,
    success: null,
    failed: null,
    status: GET_PURCHASED_TOPS.END,
  },
  buyTop: {
    isLoading: false,
    success: null,
    failed: null,
    status: BUY_TOPS.END,
  },
  collectionBasedTopList: {
    isLoading: false,
    success: null,
    failed: null,
    status: GET_TOPS_BY_COLLECTION.END,
  },
  addTopsToCollection: {
    isLoading: false,
    success: null,
    failed: null,
    status: ADD_TOPS_TO_COLLECTION.END,
  },
  deleteCollectionAndTops: {
    isLoading: false,
    success: '',
    failed: null,
    status: DELETE_COLLECTIONS_TOPS_DATA.END,
  },
  deleteTops: {
    isLoading: false,
    success: null,
    failed: null,
    status: DELETE_TOPS.END,
  },
  userListSearch: {
    isLoading: false,
    success: null,
    failed: null,
    status: USER_LIST_SEARCH.END,
  },
  distributeTop: {
    isLoading: false,
    success: null,
    failed: null,
    status: DISTRIBUTE_SEARCH.END,
  },
  groupListSearch: {
    isLoading: false,
    success: null,
    failed: null,
    status: GROUP_LIST_SEARCH.END,
  },
  groupUserListSearch: {
    isLoading: false,
    success: null,
    failed: null,
    status: GROUP_USER_LIST_SEARCH.END,
  },
  createOffer: {
    isLoading: false,
    success: null,
    failed: null,
    status: CREATE_OFFER.END,
  },
  moveToCollection: {
    isLoading: false,
    success: null,
    failed: null,
    status: TOPS_MOVE_TO_COLLECTION.END,
  },
  counterOffer: {
    isLoading: false,
    success: null,
    failed: null,
    status: COUNTER_OFFER.END,
  },
  cartCheckout: {
    isLoading: false,
    success: null,
    failed: null,
    status: CART_CHECKOUT.END,
  },
  sendBuyingRequestToParent: {
    isLoading: false,
    success: null,
    failed: null,
    status: BUYING_REQUEST_TO_PARENT.END,
  },
  moveToSentOffer: false,
  updatedTopQuantity: FixedValue.CONSTANT_VALUE_0,
  topAmountAndReportAbuseData: {
    isLoading: false,
    success: null,
    failed: null,
    status: TOP_AMOUNT_AND_REPORT_ABUSE_REQUEST_DATA.END,
  },
  couponCodeData: {
    isLoading: false,
    success: null,
    failed: null,
    status: VERIFY_COUPON_CODE.END,
  },
  reportAbuseData: {
    isLoading: false,
    success: null,
    failed: null,
    status: REPORT_ABUSE_REQUEST_DATA.END,
  },
  disributeViaEmailData: {
    isLoading: false,
    success: null,
    failed: null,
    status: DISTRIBUTE_TO_EMAIL_TEXT.END,
  },
  disributeViaCSV: {
    isLoading: false,
    success: null,
    failed: null,
    status: DISTRIBUTE_TOP_VIA_CSV.END,
  },
  reedemCodeData: {
    isLoading: false,
    success: null,
    failed: null,
    status: VERIFY_REEDEM_CODE.END,
  },
  userDuplicateData: {
    isLoading: false,
    success: null,
    failed: null,
    status: CHECK_FOR_MULTIPLE_ACCOUNT.END,
  },
};

const homeSlice = createSlice({
  initialState,
  name: StoreConstants.GET_IN_PROGRESS_TOPS,
  reducers: {
    onInProgressTopsListStart: (state, _) => {
      state.inProgressTopList.isLoading = true;
      state.inProgressTopList.status = GET_IN_PROGRESS_TOPS.START;
    },
    onInProgressTopsSuccess: (state, action) => {
      state.inProgressTopList.success = action.payload;
      state.inProgressTopList.status = GET_IN_PROGRESS_TOPS.SUCCESS;
    },
    onInProgressTopsListFailed: (state, action) => {
      state.inProgressTopList.failed = action.payload;
      state.inProgressTopList.status = GET_IN_PROGRESS_TOPS.FAILED;
    },
    onInProgressTopsListEnd: state => {
      state.inProgressTopList.isLoading = false;
      state.inProgressTopList.status = GET_IN_PROGRESS_TOPS.END;
    },
    onCollectionTopListsStart: (state, action) => {
      state.collectionTopLists.isLoading = true;
      state.collectionTopLists.status = COLLECTION_TOP_LISTS.START;
    },
    onCollectionTopListsSuccess: (state, action) => {
      state.collectionTopLists.success = action.payload;
      state.collectionTopLists.status = COLLECTION_TOP_LISTS.SUCCESS;
    },
    onCollectionTopListsFailed: (state, action) => {
      state.collectionTopLists.failed = action.payload;
      state.collectionTopLists.status = COLLECTION_TOP_LISTS.FAILED;
    },
    onCollectionTopListsEnd: state => {
      state.collectionTopLists.isLoading = false;
      state.collectionTopLists.status = COLLECTION_TOP_LISTS.END;
    },
    onPurchasedTopListsStart: (state, _) => {
      state.purchasedTopLists.isLoading = true;
      state.purchasedTopLists.status = GET_PURCHASED_TOPS.START;
    },
    onPurchasedTopListsSuccess: (state, action) => {
      state.purchasedTopLists.success = action.payload;
      state.purchasedTopLists.status = GET_PURCHASED_TOPS.SUCCESS;
    },
    onPurchasedTopListsFailed: (state, action) => {
      state.purchasedTopLists.failed = action.payload;
      state.purchasedTopLists.status = GET_PURCHASED_TOPS.FAILED;
    },
    onPurchasedTopListsEnd: state => {
      state.purchasedTopLists.isLoading = false;
      state.purchasedTopLists.status = GET_PURCHASED_TOPS.END;
    },
    onBuyTopStart: (state, _) => {
      state.buyTop.isLoading = true;
      state.buyTop.status = BUY_TOPS.START;
    },
    onBuyTopSuccess: (state, action) => {
      state.buyTop.success = action.payload;
      state.buyTop.status = BUY_TOPS.SUCCESS;
    },
    onBuyTopFailed: (state, action) => {
      state.buyTop.failed = action.payload;
      state.buyTop.status = BUY_TOPS.FAILED;
    },
    onBuyTopEnd: state => {
      state.buyTop.isLoading = false;
      state.buyTop.status = BUY_TOPS.END;
    },
    onCollectionsSpecificTopListsStart: (state, _) => {
      state.collectionBasedTopList.isLoading = true;
      state.collectionBasedTopList.status = GET_TOPS_BY_COLLECTION.START;
    },
    onCollectionsSpecificTopListsSuccess: (state, action) => {
      state.collectionBasedTopList.success = action.payload;
      state.collectionBasedTopList.status = GET_TOPS_BY_COLLECTION.SUCCESS;
    },
    onCollectionsSpecificTopListsFailed: (state, action) => {
      state.collectionBasedTopList.failed = action.payload;
      state.collectionBasedTopList.status = GET_TOPS_BY_COLLECTION.FAILED;
    },
    onCollectionsSpecificTopListsEnd: state => {
      state.collectionBasedTopList.isLoading = false;
      state.collectionBasedTopList.status = GET_TOPS_BY_COLLECTION.END;
    },
    onAddTopsToCollectionStart: (state, _) => {
      state.addTopsToCollection.isLoading = true;
      state.addTopsToCollection.status = ADD_TOPS_TO_COLLECTION.START;
    },
    onAddTopsToCollectionSuccess: (state, action) => {
      state.addTopsToCollection.success = action.payload;
      state.addTopsToCollection.status = ADD_TOPS_TO_COLLECTION.SUCCESS;
    },
    onAddTopsToCollectionFailed: (state, action) => {
      state.addTopsToCollection.failed = action.payload;
      state.addTopsToCollection.status = ADD_TOPS_TO_COLLECTION.FAILED;
    },
    onAddTopsToCollectionEnd: state => {
      state.addTopsToCollection.isLoading = false;
      state.addTopsToCollection.status = ADD_TOPS_TO_COLLECTION.END;
    },
    onDeleteCollectionAndTopsStart: (state, _) => {
      state.deleteCollectionAndTops.isLoading = true;
      state.deleteCollectionAndTops.status = DELETE_COLLECTIONS_TOPS_DATA.START;
    },
    onDeleteCollectionAndTopsSuccess: (state, action) => {
      state.deleteCollectionAndTops.success = action.payload;
      state.deleteCollectionAndTops.status =
        DELETE_COLLECTIONS_TOPS_DATA.SUCCESS;
    },
    onDeleteCollectionAndTopsFailed: (state, action) => {
      state.deleteCollectionAndTops.failed = action.payload;
      state.deleteCollectionAndTops.status =
        DELETE_COLLECTIONS_TOPS_DATA.FAILED;
    },
    onDeleteCollectionAndTopsEnd: state => {
      state.deleteCollectionAndTops.isLoading = false;
      state.deleteCollectionAndTops.status = DELETE_COLLECTIONS_TOPS_DATA.END;
    },
    onDeleteTopStart: (state, _) => {
      state.deleteTops.isLoading = true;
      state.deleteTops.status = DELETE_TOPS.START;
    },
    onDeleteTopSuccess: (state, action) => {
      state.deleteTops.success = action.payload;
      state.deleteTops.status = DELETE_TOPS.SUCCESS;
    },
    onDeleteTopFailed: (state, action) => {
      state.deleteTops.failed = action.payload;
      state.deleteTops.status = DELETE_TOPS.FAILED;
    },
    onDeleteTopEnd: state => {
      state.deleteTops.isLoading = false;
      state.deleteTops.status = DELETE_TOPS.END;
    },
    onUserListSearchStart: (state, _) => {
      state.userListSearch.isLoading = true;
      state.userListSearch.status = USER_LIST_SEARCH.START;
    },
    onUserListSearchSuccess: (state, action) => {
      state.userListSearch.success = action.payload;
      state.userListSearch.status = USER_LIST_SEARCH.SUCCESS;
    },
    onUserListSearchFailed: (state, action) => {
      state.userListSearch.failed = action.payload;
      state.userListSearch.status = USER_LIST_SEARCH.FAILED;
    },
    onUserListSearchEnd: state => {
      state.userListSearch.isLoading = false;
      state.userListSearch.status = USER_LIST_SEARCH.END;
    },
    onDistributeTopStart: (state, _) => {
      state.distributeTop.isLoading = true;
      state.distributeTop.status = DISTRIBUTE_SEARCH.START;
    },
    onDistributeTopSuccess: (state, action) => {
      state.distributeTop.success = action.payload;
      state.distributeTop.status = DISTRIBUTE_SEARCH.SUCCESS;
    },
    onDistributeTopFailed: (state, action) => {
      state.distributeTop.failed = action.payload;
      state.distributeTop.status = DISTRIBUTE_SEARCH.FAILED;
    },
    onDistributeTopEnd: state => {
      state.distributeTop.isLoading = false;
      state.distributeTop.status = DISTRIBUTE_SEARCH.END;
    },
    onGroupListSearchStart: (state, _) => {
      state.groupListSearch.isLoading = true;
      state.groupListSearch.status = GROUP_LIST_SEARCH.START;
    },
    onGroupListSearchSuccess: (state, action) => {
      state.groupListSearch.success = action.payload;
      state.groupListSearch.status = GROUP_LIST_SEARCH.SUCCESS;
    },
    onGroupListSearchFailed: (state, action) => {
      state.groupListSearch.failed = action.payload;
      state.groupListSearch.status = GROUP_LIST_SEARCH.FAILED;
    },
    onGroupListSearchEnd: state => {
      state.groupListSearch.isLoading = false;
      state.groupListSearch.status = GROUP_LIST_SEARCH.END;
    },
    onGroupUserListSearchStart: (state, _) => {
      state.groupUserListSearch.isLoading = true;
      state.groupUserListSearch.status = GROUP_USER_LIST_SEARCH.START;
    },
    onGroupUserListSearchSuccess: (state, action) => {
      state.groupUserListSearch.success = action.payload;
      state.groupUserListSearch.status = GROUP_USER_LIST_SEARCH.SUCCESS;
    },
    onGroupUserListSearchFailed: (state, action) => {
      state.groupUserListSearch.failed = action.payload;
      state.groupUserListSearch.status = GROUP_USER_LIST_SEARCH.FAILED;
    },
    onGroupUserListSearchEnd: state => {
      state.groupUserListSearch.isLoading = false;
      state.groupUserListSearch.status = GROUP_USER_LIST_SEARCH.END;
    },
    onCreateOfferStart: (state, action) => {
      state.createOffer.isLoading = true;
      state.createOffer.status = CREATE_OFFER.START;
    },
    onCreateOfferSuccess: (state, action) => {
      state.createOffer.success = action.payload;
      state.createOffer.status = CREATE_OFFER.SUCCESS;
    },
    onCreateOfferFailed: (state, action) => {
      state.createOffer.failed = action.payload;
      state.createOffer.status = CREATE_OFFER.FAILED;
    },
    onCreateOfferEnd: state => {
      state.createOffer.isLoading = false;
      state.createOffer.status = CREATE_OFFER.END;
    },
    moveTopsToCollectionStart: (state, _) => {
      state.moveToCollection.isLoading = true;
      state.moveToCollection.status = TOPS_MOVE_TO_COLLECTION.START;
    },
    moveTopsToCollectionSuccess: (state, action) => {
      state.moveToCollection.success = action.payload;
      state.moveToCollection.status = TOPS_MOVE_TO_COLLECTION.SUCCESS;
    },
    moveTopsToCollectionFailed: (state, action) => {
      state.moveToCollection.failed = action.payload;
      state.moveToCollection.status = TOPS_MOVE_TO_COLLECTION.FAILED;
    },
    moveTopsToCollectionEnd: state => {
      state.moveToCollection.isLoading = false;
      state.moveToCollection.status = TOPS_MOVE_TO_COLLECTION.END;
    },
    onCounterOfferStart: (state, action) => {
      state.counterOffer.isLoading = true;
      state.counterOffer.status = COUNTER_OFFER.START;
    },
    onCounterOfferSuccess: (state, action) => {
      state.counterOffer.success = action.payload;
      state.counterOffer.status = COUNTER_OFFER.SUCCESS;
    },
    onCounterOfferFailed: (state, action) => {
      state.counterOffer.failed = action.payload;
      state.counterOffer.status = COUNTER_OFFER.FAILED;
    },
    onCounterOfferEnd: state => {
      state.counterOffer.isLoading = false;
      state.counterOffer.status = COUNTER_OFFER.END;
    },
    onCartCheckoutStart: (state, _) => {
      state.cartCheckout.isLoading = true;
      state.cartCheckout.status = CART_CHECKOUT.START;
    },
    onCartCheckoutSuccess: (state, action) => {
      state.cartCheckout.success = action.payload;
      state.cartCheckout.status = CART_CHECKOUT.SUCCESS;
    },
    onCartCheckoutFailed: (state, action) => {
      state.cartCheckout.failed = action.payload;
      state.cartCheckout.status = CART_CHECKOUT.FAILED;
    },
    onCartCheckoutEnd: state => {
      state.cartCheckout.isLoading = false;
      state.cartCheckout.status = CART_CHECKOUT.END;
    },
    onBuyingRequestStart: (state, _) => {
      state.sendBuyingRequestToParent.isLoading = true;
      state.sendBuyingRequestToParent.status = BUYING_REQUEST_TO_PARENT.START;
    },
    onBuyingRequestSuccess: (state, action) => {
      state.sendBuyingRequestToParent.success = action.payload;
      state.sendBuyingRequestToParent.status = BUYING_REQUEST_TO_PARENT.SUCCESS;
    },
    onBuyingRequestFailed: (state, action) => {
      state.sendBuyingRequestToParent.failed = action.payload;
      state.sendBuyingRequestToParent.status = BUYING_REQUEST_TO_PARENT.FAILED;
    },
    onBuyingRequestEnd: state => {
      state.sendBuyingRequestToParent.isLoading = false;
      state.sendBuyingRequestToParent.status = BUYING_REQUEST_TO_PARENT.END;
    },
    activateInSentOfferTab: (state, action) => {
      state.moveToSentOffer = action.payload;
    },
    updateTopQuantity: (state, action) => {
      state.updatedTopQuantity = action.payload;
    },
    topAmountAndReportAbuseDataStart: state => {
      state.topAmountAndReportAbuseData.isLoading = true;
      state.topAmountAndReportAbuseData.status =
        TOP_AMOUNT_AND_REPORT_ABUSE_REQUEST_DATA.START;
    },
    topAmountAndReportAbuseDataSuccess: (state, action) => {
      state.topAmountAndReportAbuseData.success = action.payload;
      state.topAmountAndReportAbuseData.status =
        TOP_AMOUNT_AND_REPORT_ABUSE_REQUEST_DATA.SUCCESS;
    },
    topAmountAndReportAbuseDataFailed: (state, action) => {
      state.topAmountAndReportAbuseData.failed = action.payload;
      state.topAmountAndReportAbuseData.status =
        TOP_AMOUNT_AND_REPORT_ABUSE_REQUEST_DATA.FAILED;
    },
    topAmountAndReportAbuseDataEnd: state => {
      state.topAmountAndReportAbuseData.isLoading = false;
      state.topAmountAndReportAbuseData.status =
        TOP_AMOUNT_AND_REPORT_ABUSE_REQUEST_DATA.END;
    },
    verifyCouponCodeStart: (state, _) => {
      state.couponCodeData.isLoading = true;
      state.couponCodeData.status = VERIFY_COUPON_CODE.START;
    },
    verifyCouponCodeSuccess: (state, action) => {
      state.couponCodeData.success = action.payload;
      state.couponCodeData.status = VERIFY_COUPON_CODE.SUCCESS;
    },
    verifyCouponCodeFailed: (state, action) => {
      state.couponCodeData.failed = action.payload;
      state.couponCodeData.status = VERIFY_COUPON_CODE.FAILED;
    },
    verifyCouponCodeEnd: state => {
      state.couponCodeData.isLoading = false;
      state.couponCodeData.status = VERIFY_COUPON_CODE.END;
    },
    reportAbuseDataStart: state => {
      state.reportAbuseData.isLoading = true;
      state.reportAbuseData.status = REPORT_ABUSE_REQUEST_DATA.START;
    },
    reportAbuseDataSuccess: (state, action) => {
      state.reportAbuseData.success = action.payload;
      state.reportAbuseData.status = REPORT_ABUSE_REQUEST_DATA.SUCCESS;
    },
    reportAbuseDataFailed: (state, action) => {
      state.reportAbuseData.failed = action.payload;
      state.reportAbuseData.status = REPORT_ABUSE_REQUEST_DATA.FAILED;
    },
    reportAbuseDataEnd: state => {
      state.reportAbuseData.isLoading = false;
      state.reportAbuseData.status = REPORT_ABUSE_REQUEST_DATA.END;
    },
    distributeViaEmailOrPhoneStart: (state, _) => {
      state.disributeViaEmailData.isLoading = true;
      state.disributeViaEmailData.status = DISTRIBUTE_TO_EMAIL_TEXT.START;
    },
    distributeViaEmailOrPhoneSuccess: (state, action) => {
      state.disributeViaEmailData.success = action.payload;
      state.disributeViaEmailData.status = DISTRIBUTE_TO_EMAIL_TEXT.SUCCESS;
    },
    distributeViaEmailOrPhoneFailed: (state, action) => {
      state.disributeViaEmailData.failed = action.payload;
      state.disributeViaEmailData.status = DISTRIBUTE_TO_EMAIL_TEXT.FAILED;
    },
    distributeViaEmailOrPhoneEnd: state => {
      state.disributeViaEmailData.isLoading = false;
      state.disributeViaEmailData.status = DISTRIBUTE_TO_EMAIL_TEXT.END;
    },
    distributeViaCSVStart: (state, _) => {
      state.disributeViaCSV.isLoading = true;
      state.disributeViaCSV.status = DISTRIBUTE_TOP_VIA_CSV.START;
    },
    distributeViaCSVSuccess: (state, action) => {
      state.disributeViaCSV.success = action.payload;
      state.disributeViaCSV.status = DISTRIBUTE_TOP_VIA_CSV.SUCCESS;
    },
    distributeViaCSVFailed: (state, action) => {
      state.disributeViaCSV.failed = action.payload;
      state.disributeViaCSV.status = DISTRIBUTE_TOP_VIA_CSV.FAILED;
    },
    distributeViaCSVEnd: state => {
      state.disributeViaCSV.isLoading = false;
      state.disributeViaCSV.status = DISTRIBUTE_TOP_VIA_CSV.END;
    },
    verifyReedemCodeStart: (state, _) => {
      state.reedemCodeData.isLoading = true;
      state.reedemCodeData.status = VERIFY_REEDEM_CODE.START;
    },
    verifyReedemCodeSuccess: (state, action) => {
      state.reedemCodeData.success = action.payload;
      state.reedemCodeData.status = VERIFY_REEDEM_CODE.SUCCESS;
    },
    verifyReedemCodeFailed: (state, action) => {
      state.reedemCodeData.failed = action.payload;
      state.reedemCodeData.status = VERIFY_REEDEM_CODE.FAILED;
    },
    verifyReedemCodeEnd: state => {
      state.reedemCodeData.isLoading = false;
      state.reedemCodeData.status = VERIFY_REEDEM_CODE.END;
    },
    checkForMultipleAccountStart: (state, _) => {
      state.userDuplicateData.isLoading = true;
      state.userDuplicateData.status = CHECK_FOR_MULTIPLE_ACCOUNT.START;
    },
    checkForMultipleAccountSuccess: (state, action) => {
      state.userDuplicateData.success = action.payload;
      state.userDuplicateData.status = CHECK_FOR_MULTIPLE_ACCOUNT.SUCCESS;
    },
    checkForMultipleAccountFailed: (state, action) => {
      state.userDuplicateData.failed = action.payload;
      state.userDuplicateData.status = CHECK_FOR_MULTIPLE_ACCOUNT.FAILED;
    },
    checkForMultipleAccountEnd: state => {
      state.userDuplicateData.isLoading = false;
      state.userDuplicateData.status = CHECK_FOR_MULTIPLE_ACCOUNT.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  onInProgressTopsListEnd,
  onInProgressTopsListFailed,
  onInProgressTopsListStart,
  onInProgressTopsSuccess,
  onCollectionTopListsStart,
  onCollectionTopListsSuccess,
  onCollectionTopListsFailed,
  onCollectionTopListsEnd,
  onPurchasedTopListsStart,
  onPurchasedTopListsSuccess,
  onPurchasedTopListsEnd,
  onPurchasedTopListsFailed,
  onBuyTopStart,
  onBuyTopSuccess,
  onBuyTopFailed,
  onBuyTopEnd,
  onCollectionsSpecificTopListsStart,
  onCollectionsSpecificTopListsSuccess,
  onCollectionsSpecificTopListsEnd,
  onCollectionsSpecificTopListsFailed,
  onAddTopsToCollectionStart,
  onAddTopsToCollectionSuccess,
  onAddTopsToCollectionFailed,
  onAddTopsToCollectionEnd,
  onDeleteCollectionAndTopsStart,
  onDeleteCollectionAndTopsSuccess,
  onDeleteCollectionAndTopsFailed,
  onDeleteCollectionAndTopsEnd,
  onDeleteTopStart,
  onDeleteTopSuccess,
  onDeleteTopEnd,
  onDeleteTopFailed,
  onUserListSearchStart,
  onUserListSearchSuccess,
  onUserListSearchFailed,
  onUserListSearchEnd,
  onDistributeTopStart,
  onDistributeTopSuccess,
  onDistributeTopFailed,
  onDistributeTopEnd,
  onGroupListSearchStart,
  onGroupListSearchSuccess,
  onGroupListSearchFailed,
  onGroupListSearchEnd,
  onGroupUserListSearchStart,
  onGroupUserListSearchSuccess,
  onGroupUserListSearchFailed,
  onGroupUserListSearchEnd,
  onCreateOfferStart,
  onCreateOfferSuccess,
  onCreateOfferFailed,
  onCreateOfferEnd,
  moveTopsToCollectionStart,
  moveTopsToCollectionSuccess,
  moveTopsToCollectionFailed,
  moveTopsToCollectionEnd,
  onCounterOfferStart,
  onCounterOfferSuccess,
  onCounterOfferFailed,
  onCounterOfferEnd,
  onCartCheckoutStart,
  onCartCheckoutSuccess,
  onCartCheckoutFailed,
  onCartCheckoutEnd,
  onBuyingRequestStart,
  onBuyingRequestSuccess,
  onBuyingRequestEnd,
  onBuyingRequestFailed,
  activateInSentOfferTab,
  updateTopQuantity,
  topAmountAndReportAbuseDataStart,
  topAmountAndReportAbuseDataSuccess,
  topAmountAndReportAbuseDataEnd,
  topAmountAndReportAbuseDataFailed,
  verifyCouponCodeStart,
  verifyCouponCodeSuccess,
  verifyCouponCodeFailed,
  verifyCouponCodeEnd,
  reportAbuseDataStart,
  reportAbuseDataSuccess,
  reportAbuseDataFailed,
  reportAbuseDataEnd,
  distributeViaEmailOrPhoneStart,
  distributeViaEmailOrPhoneSuccess,
  distributeViaEmailOrPhoneFailed,
  distributeViaEmailOrPhoneEnd,
  distributeViaCSVStart,
  distributeViaCSVSuccess,
  distributeViaCSVFailed,
  distributeViaCSVEnd,
  verifyReedemCodeStart,
  verifyReedemCodeSuccess,
  verifyReedemCodeFailed,
  verifyReedemCodeEnd,
  checkForMultipleAccountStart,
  checkForMultipleAccountSuccess,
  checkForMultipleAccountFailed,
  checkForMultipleAccountEnd,
} = homeSlice.actions;
export default homeSlice.reducer;

export const HomeStore = (state: RootState) => state.inProgressTopsList;
