import {createSlice} from '@reduxjs/toolkit';
import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {IEditorStoreType} from '../../types/reducerTypes/editorStoreType';
import {ResetAllState} from './logoutslice';
import {TOP_IMAGE_UPLOAD_ACTION} from '../../constants/actionTypes';

const {StoreConstants} = AppConst;
const {CREATE_NEW_TOP, UPDATE_TOP} = ActionType;

const initialState: IEditorStoreType = {
  createNewTop: {
    isLoading: false,
    success: null,
    failed: null,
    status: CREATE_NEW_TOP.END,
  },
  updateExistingTop: {
    isLoading: false,
    success: null,
    failed: null,
    status: UPDATE_TOP.END,
  },
  openForEdit: false,
  topImageUploadResponse: {
    isLoading: false,
    success: null,
    failed: null,
    status: UPDATE_TOP.END,
  },
};

const EditorSlice = createSlice({
  initialState,
  name: StoreConstants.EDITOR,
  reducers: {
    startEditorLoader: state => {
      state.createNewTop.isLoading = true;
    },
    onCreateTopStart: (state, _) => {
      state.createNewTop.isLoading = true;
      state.createNewTop.status = CREATE_NEW_TOP.START;
    },
    onCreateTopSuccess: (state, action) => {
      state.createNewTop.success = action.payload;
      state.createNewTop.status = CREATE_NEW_TOP.SUCCESS;
    },
    onCreateTopFailed: (state, action) => {
      state.createNewTop.failed = action.payload;
      state.createNewTop.status = CREATE_NEW_TOP.FAILED;
    },
    onCreateTopEnd: state => {
      state.createNewTop.isLoading = false;
      state.createNewTop.status = CREATE_NEW_TOP.END;
    },
    onUpdateTopStart: (state, _) => {
      state.updateExistingTop.isLoading = true;
      state.updateExistingTop.status = UPDATE_TOP.START;
    },
    onUpdateTopSuccess: (state, action) => {
      state.updateExistingTop.success = action.payload;
      state.updateExistingTop.status = UPDATE_TOP.SUCCESS;
    },
    onUpdateTopFailed: (state, action) => {
      state.updateExistingTop.failed = action.payload;
      state.updateExistingTop.status = UPDATE_TOP.FAILED;
    },
    onUpdateTopEnd: state => {
      state.updateExistingTop.isLoading = false;
      state.createNewTop.isLoading = false;
      state.updateExistingTop.status = UPDATE_TOP.END;
    },
    updateOpenForEditValue: (state, action) => {
      state.openForEdit = action.payload;
    },
    onTopImageUploadStart: (state, _) => {
      state.topImageUploadResponse.isLoading = true;
      state.topImageUploadResponse.status = TOP_IMAGE_UPLOAD_ACTION.START;
    },
    onTopImageUploadSuccess: (state, action) => {
      state.topImageUploadResponse.success = action.payload;
      state.topImageUploadResponse.status = TOP_IMAGE_UPLOAD_ACTION.SUCCESS;
    },
    onTopImageUploadFailed: (state, action) => {
      state.topImageUploadResponse.failed = action.payload;
      state.topImageUploadResponse.status = TOP_IMAGE_UPLOAD_ACTION.FAILED;
    },
    onTopImageUploadEnd: state => {
      state.topImageUploadResponse.isLoading = false;
      state.updateExistingTop.status = TOP_IMAGE_UPLOAD_ACTION.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  startEditorLoader,
  onCreateTopStart,
  onCreateTopSuccess,
  onCreateTopEnd,
  onCreateTopFailed,
  onUpdateTopEnd,
  onUpdateTopFailed,
  onUpdateTopStart,
  onUpdateTopSuccess,
  updateOpenForEditValue,
  onTopImageUploadStart,
  onTopImageUploadSuccess,
  onTopImageUploadFailed,
  onTopImageUploadEnd,
} = EditorSlice.actions;
export default EditorSlice.reducer;
export const editorState = (state: RootState) => state.editor;
