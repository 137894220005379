import React, {useCallback, useEffect, useRef} from 'react';
import {Pressable, Text, View} from 'react-native';
import CustomButton from '../../components/button/customButton';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import ScrollableContainer from '../../components/layout/scrollableContainer';
import {ROUTE_NAME, TEXT} from '../../constants';
import {FixedValue, Percentage, TopType} from '../../constants/enum';
import {TopDetailContext} from '../../contextAPI/topDetailContext';
import {topDetailStyle} from './topDetail.style';
import TopDetailContent from './topDetailContent';
import {RouteProp, useIsFocused, useRoute} from '@react-navigation/native';
import {RootStackParamList} from '../../types/navigationType';
import ThreeDots from '../../components/renderSvgs/threeDots';
import {ThemeContext} from '../../contextAPI/themeContext';
import EditTopModal from '../../modals/editTopModal';
import {HomeContext} from '../../contextAPI/homeContext';
import {HomeStore, updateTopQuantity} from '../../redux/reducers/homeSlice';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import {goBack, navigate} from '../../services/navigationService';
import {
  ADD_TOPS_TO_COLLECTION,
  CART_CHECKOUT,
  DELETE_TOPS,
  DISTRIBUTE_SEARCH,
  TOPS_MOVE_TO_COLLECTION,
  USER_MARK_UNMARK_FAVORITE,
} from '../../constants/actionTypes';
import TopOptionsInCollectionModal from '../../modals/collectionOptionsModal';
import useTopDetails from '../../hooks/componentHooks/useTopDetailsHooks';
import {ITradableParams} from '../../types/reducerTypes/searchTabStoreType';
import MoveToCollectionsModal from '../../modals/moveToCollectionsModal';
import TopBarWithRightButton from '../../components/header/topBarWithRightButton';
import DistributeTopModal from '../../modals/distributeTopModal';
import useHomeHooks from '../../hooks/componentHooks/useHomeHooks';
import {
  onTopDetailDataStart,
  searchTabStore,
} from '../../redux/reducers/searchTabSlice';
import {TradeContext} from '../../contextAPI/tradeContext';
import {updateOpenForEditValue} from '../../redux/reducers/editorSlice';
import ProposeTopCollectionsModal from '../../modals/proposeTopCollectionsModal';
import ConfirmationModal from '../../modals/confirmationModal/confirmationModal';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import {NotificationContext} from '../../contextAPI/notificationContext';
import useFavouriteHooks from '../../hooks/componentHooks/useFavouriteHooks';
import {_ToastHandler} from '../../utils/helperFunctions';
import NotesCell from '../../components/notesCell';
import NotesModal from '../../modals/notesModal';
import {FlatList} from 'react-native';
import BottomSheet from '@gorhom/bottom-sheet';
import {scaleHeight, SCREEN_HEIGHT} from '../../utils/responsive';
import ArrowUp2 from '../../components/renderSvgs/ArrowUp2';
import ArrowDown2 from '../../components/renderSvgs/ArrowDown2';

const TopDetailScreen = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {
    setOpenOptionsModal,
    openOptionsModal,
    topDetail,
    setTopDetail,
    setSvgCode,
    setOpenProposeModal,
    notesListData,
    setNotesListData,
    openNotesAddEditModal,
    setOpenNotesAddEditModal,
    notesData,
    isNotesEditMode,
    setShowFavoriteIcon,
  } = React.useContext(TopDetailContext);
  const {styles, ids} = topDetailStyle(ColorTheme);
  const route = useRoute<RouteProp<RootStackParamList, 'TopDetailScreen'>>();
  const {comingFrom, topDetails} = route.params;
  const {
    setOpenEditTopModal,
    setSelectedTopData,
    setShowDistributiveModal,
    selectOrUnSelectResultTop,
    isProfileSwitchToChild,
    searchUserListText,
    searchGroupListText,
    selectedTopsResult,
    setInitialLoader,
    topAmount,
    showDistributiveModal,
  } = React.useContext(HomeContext);
  const {
    deleteTops,
    addTopsToCollection,
    distributeTop,
    moveToCollection,
    cartCheckout,
    updatedTopQuantity,
  } = useAppSelector(HomeStore);
  const {
    markTopTradableAPI,
    getSvgUrl,
    onReachedEndNotesList,
    onCallEditNotes,
    onCloseNoteModal,
    onAddOpenNoteModal,
    onDeleteNotesAlert,
    callNotesList,
  } = useTopDetails();
  const {callUserListSearchAPI, callGroupListSearchAPI} = useHomeHooks();
  const dispatch = useAppDispatch();
  const isFocused = useIsFocused();
  const {
    setOpenReceviedOfferModal,
    currentActiveTab,
    setOpenSentOfferModal,
    selectOrUnSelectSingleTop,
    setIsOtherUserTopList,
    isOtherUserTopList,
    setOtherTradeUser,
    setFromProposeTrade,
    showUntradableAlert,
    setShowUntradableAlert,
  } = React.useContext(TradeContext);
  const {showDistributionModal} = React.useContext(NotificationContext);
  const {userData, userMarkAndUnmarkFavoriteData} =
    useAppSelector(userProfileStore);
  const {getfavoriteUserList} = useFavouriteHooks();
  const UserDetail = userData?.success;
  const {notesAddResponse, notesUpdateResponse} =
    useAppSelector(searchTabStore);
  const notesButtonLoader =
    notesAddResponse.isLoading || notesUpdateResponse.isLoading;
  const [indexOfBottomSheet, setIndexOfBottomSheet] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_0
  );
  const isTradable = topDetail?.tradable
    ? FixedValue.CONSTANT_VALUE_0
    : FixedValue.CONSTANT_VALUE_5;
  const notesViewInitialPercentage = topDetail?.hashTag
    ? FixedValue.CONSTANT_VALUE_17 + isTradable
    : FixedValue.CONSTANT_VALUE_21 + isTradable;
  const snapPoints = [
    `${notesViewInitialPercentage}%`,
    Percentage.PRECENTAGE_100,
  ];
  const bottomSheetRef = useRef(null);
  const indexOfBottomSheetIsZero =
    indexOfBottomSheet === FixedValue.CONSTANT_VALUE_0;
  const isNotesAvailable = notesListData?.length > FixedValue.CONSTANT_VALUE_0;
  const showNotesAddButton = topDetail?.user?.id === UserDetail?.id;

  React.useEffect(() => {
    switch (cartCheckout.status) {
      case CART_CHECKOUT.SUCCESS:
        // @ts-ignore
        setTopDetail(prev => ({
          ...prev,
          quantity: prev.quantity + updatedTopQuantity,
        }));
        dispatch(updateTopQuantity(FixedValue.CONSTANT_VALUE_0));
        break;
      case CART_CHECKOUT.FAILED:
        break;
      default:
        break;
    }
  }, [cartCheckout.status]);

  React.useEffect(() => {
    setSvgCode(null);
    setTopDetail(topDetails);
    if (topDetails.image) getSvgUrl(topDetails.image);
  }, [topDetails]);

  React.useEffect(() => {
    if (deleteTops.status === DELETE_TOPS.SUCCESS) {
      goBack();
    }
  }, [deleteTops.status]);

  React.useEffect(() => {
    switch (addTopsToCollection.status) {
      case ADD_TOPS_TO_COLLECTION.SUCCESS:
        goBack();
        break;
      case ADD_TOPS_TO_COLLECTION.FAILED:
        break;
      default:
        break;
    }
  }, [addTopsToCollection.status]);

  React.useEffect(() => {
    switch (moveToCollection.status) {
      case TOPS_MOVE_TO_COLLECTION.SUCCESS:
        goBack();
        break;
      case TOPS_MOVE_TO_COLLECTION.FAILED:
        break;
      default:
        break;
    }
  }, [moveToCollection.status]);

  const callTopDetailAPI = React.useCallback((): void => {
    dispatch(
      onTopDetailDataStart({
        topId: topDetails?.id,
        fromSearch: comingFrom !== TopType.otherUserTop ? false : true,
      })
    );
  }, [comingFrom, topDetails]);

  React.useEffect(() => {
    if (distributeTop.status === DISTRIBUTE_SEARCH.SUCCESS) {
      callTopDetailAPI();
      goBack();
    } else if (distributeTop.status === DISTRIBUTE_SEARCH.FAILED) {
      callTopDetailAPI();
    }
  }, [distributeTop.status]);

  useEffect(() => {
    if (isFocused) {
      switch (userMarkAndUnmarkFavoriteData.status) {
        case USER_MARK_UNMARK_FAVORITE.SUCCESS:
          callTopDetailAPI();
          const firstText = topDetail?.user?.favorite
            ? 'You have removed'
            : 'You have added ';
          const fullName = topDetail?.createdBy;
          const favText = topDetail?.user?.favorite
            ? 'From the favorites list.'
            : 'to the favorites list.';
          const toastMsg = `${firstText} "${fullName}" ${favText}`;
          if (!showDistributiveModal) {
            _ToastHandler(toastMsg, true);
          }
          break;
        case USER_MARK_UNMARK_FAVORITE.FAILED:
          break;
      }
    }
  }, [userMarkAndUnmarkFavoriteData?.status]);

  React.useEffect(() => {
    if (isFocused) {
      setShowFavoriteIcon(false);
      setNotesListData([]);
      callTopDetailAPI();
      callNotesList(FixedValue.CONSTANT_VALUE_0);
    }
  }, [isFocused]);

  const onPressTopCollectionCommonFunction = React.useCallback(
    (callingFunction: any): void => {
      if (
        (!isProfileSwitchToChild || topDetail?.user?.id === UserDetail?.id) &&
        callingFunction !== undefined
      ) {
        callingFunction && callingFunction();
      }
    },
    [topDetail, isProfileSwitchToChild]
  );

  //Mark Tradable/UnTradable Tops
  const markTradableTop = () => {
    const params: ITradableParams = {
      topId: topDetail?.id ?? FixedValue.CONSTANT_VALUE_0,
      type: !topDetail?.tradable,
    };
    setSelectedTopData(topDetail);
    markTopTradableAPI(params);
  };

  const handleDistributeTop = () => {
    setInitialLoader(true);
    setShowDistributiveModal(true);
    getfavoriteUserList('', FixedValue.CONSTANT_VALUE_0);
    callUserListSearchAPI(searchUserListText, FixedValue.CONSTANT_VALUE_0);
    callGroupListSearchAPI(searchGroupListText, FixedValue.CONSTANT_VALUE_0);
  };

  const onPressProposeTrade = () => {
    setIsOtherUserTopList(true);
  };

  React.useEffect(() => {
    if (isOtherUserTopList && isFocused) {
      // @ts-ignore
      setOtherTradeUser({...topDetails.user, alreadyPresentUser: false});
      setFromProposeTrade(true);
      selectOrUnSelectSingleTop(
        topDetails
          ? {
              ...topDetails,
              isSelected: false,
              selectedQuantity: FixedValue.CONSTANT_VALUE_1,
            }
          : null
      );
      setOpenProposeModal();
    }
  }, [isOtherUserTopList, isFocused]);

  const onPressEditTop = React.useCallback((): void => {
    navigate(ROUTE_NAME.EDITOR_SCREEN, {
      createdTopData: topDetail,
      isEdit: false,
    });
  }, [topDetail]);

  const onPressBuyAgain = React.useCallback((): void => {
    if (topDetail != null) {
      const isTopAvailableInCart = selectedTopsResult.find(
        top => top.id === topDetail.id
      );
      if (!isTopAvailableInCart) {
        const topResult = {...topDetail};
        topResult.isSelected = false;
        topResult.selectedQuantity = FixedValue.CONSTANT_VALUE_1;
        topResult.amount = topAmount;
        topResult.fromPurchase = true;
        selectOrUnSelectResultTop(topResult);
      }
      navigate(ROUTE_NAME.MY_CART_TOPS);
    }
  }, [topDetail, selectedTopsResult, topAmount]);

  const onPressBuyNow = React.useCallback((): void => {
    if (topDetail != null) {
      const isTopAvailableInCart = selectedTopsResult.find(
        top => top.id === topDetail.id
      );
      if (!isTopAvailableInCart) {
        const topResult = {...topDetail};
        topResult.selectedQuantity = FixedValue.CONSTANT_VALUE_1;
        topResult.amount = topAmount;
        topResult.isSelected = false;
        selectOrUnSelectResultTop(topResult);
      }
      navigate(ROUTE_NAME.MY_CART_TOPS);
    }
  }, [topDetail, selectedTopsResult, topAmount]);

  const makeTradeTradable = React.useCallback((): void => {
    if (!topDetail?.tradable) {
      onPressTopCollectionCommonFunction(markTradableTop);
    } else {
      setShowUntradableAlert(true);
    }
  }, [topDetail]);

  const onArrowPressed = () => {
    if (bottomSheetRef.current) {
      // Added null check
      const snapToIndex = indexOfBottomSheetIsZero
        ? FixedValue.CONSTANT_VALUE_1
        : FixedValue.CONSTANT_VALUE_0;
      bottomSheetRef.current.snapToIndex(snapToIndex); // Correct method name
    }
  };

  const getBottomOptionView = () => {
    switch (comingFrom) {
      case TopType.collections:
        return (
          <View style={styles.buttonView}>
            <CustomButton
              buttonLabel={TEXT.DISTRIBUTE_TOP}
              buttonwidth={styles.distributeButtonView}
              buttonBackGroundColor={styles.buttonBackGroundColor}
              labelStyle={styles.distributeButtonLabelstyle}
              onPress={() =>
                onPressTopCollectionCommonFunction(handleDistributeTop)
              }
              isDisabled={false}
            />
            <CustomButton
              buttonLabel={
                topDetail?.tradable
                  ? TEXT.MARK_AS_NON_TRADEABLE
                  : TEXT.MARK_AS_TRADABLE
              }
              buttonwidth={styles.distributeButtonView}
              buttonBackGroundColor={
                topDetail?.tradable
                  ? styles.nonTradeableButton
                  : styles.buttonBackGroundColor
              }
              labelStyle={
                topDetail?.tradable
                  ? styles.unTradeableButtonLabelstyle
                  : styles.distributeButtonLabelstyle
              }
              onPress={() => makeTradeTradable()}
              isDisabled={false}
            />
          </View>
        );
      case TopType.otherUserTop:
        return (
          <View style={styles.proposeButtonView}>
            <CustomButton
              buttonLabel={TEXT.PROPOSE_A_TRADE}
              buttonwidth={styles.proposeButton}
              buttonBackGroundColor={styles.proposeButtonBackGroundColor}
              labelStyle={styles.noButtonLabelstyle}
              onPress={onPressProposeTrade}
              isDisabled={false}
            />
          </View>
        );
      case TopType.inProgress:
        return (
          <Pressable style={styles.buyNowButton} onPress={onPressBuyNow}>
            <Text style={styles.labelstyle}>{TEXT.BUY_NOW}</Text>
          </Pressable>
        );
      case TopType.purchased:
        return (
          <View style={styles.buttonView}>
            <CustomButton
              buttonLabel={TEXT.EDIT_TOP}
              buttonwidth={styles.distributeButtonView}
              buttonBackGroundColor={styles.buttonBackGroundColor}
              labelStyle={styles.distributeButtonLabelstyle}
              onPress={onPressEditTop}
              isDisabled={false}
            />
            <CustomButton
              buttonLabel={TEXT.BUY_AGAIN}
              buttonwidth={styles.distributeButtonView}
              buttonBackGroundColor={styles.buyAgainButton}
              onPress={onPressBuyAgain}
              isDisabled={false}
            />
          </View>
        );
      default:
        break;
    }
  };

  //Collections - Create Trade Offer, Move to collections and Delete Top
  // InProgress - Edit Top and Delete Top
  // Purchases - Edit Top
  // Search - No three dots
  const showOptions = () => {
    setSelectedTopData(topDetail);
    switch (comingFrom) {
      case TopType.collections:
        setOpenOptionsModal();
        break;
      case TopType.inProgress:
        dispatch(updateOpenForEditValue(true));
        setOpenEditTopModal();
        break;
      case TopType.purchased:
        dispatch(updateOpenForEditValue(false));
        setOpenEditTopModal();
        break;
      default:
        break;
    }
  };
  const backToPreviousScreen = () => {
    setSvgCode(null);
    if (comingFrom === TopType.viewOffer && !showDistributionModal) {
      if (currentActiveTab === ROUTE_NAME.RECEIVED_OFFER) {
        setOpenReceviedOfferModal();
      } else {
        setOpenSentOfferModal();
      }
      goBack();
    } else {
      goBack();
    }
  };
  const getOptionView = () => {
    if (isProfileSwitchToChild) {
      if (comingFrom === TopType.otherUserTop) {
        return getBottomOptionView();
      } else if (topDetail?.user?.id === UserDetail?.id) {
        return getBottomOptionView();
      } else {
        return <></>;
      }
    } else {
      return getBottomOptionView();
    }
  };

  const showAddNowButton = (topMargin: number) => {
    return (
      <View
        style={[
          styles.notesHeaderContainer,
          {marginTop: scaleHeight(topMargin)},
        ]}
      >
        <Text style={styles.noteHeading}>{TEXT.NOTES}</Text>
        {isNotesAvailable && (
          <View style={styles.iconWrapper}>
            {indexOfBottomSheetIsZero ? (
              <Pressable onPress={onArrowPressed}>
                <ArrowUp2 color={ColorTheme.color} />
              </Pressable>
            ) : (
              <Pressable onPress={onArrowPressed}>
                <ArrowDown2 color={ColorTheme.color} />
              </Pressable>
            )}
          </View>
        )}
        {showNotesAddButton && (
          <Pressable onPress={onAddOpenNoteModal}>
            <Text style={styles.addNewNotesButton}>{TEXT.ADD_NEW_NOTE}</Text>
          </Pressable>
        )}
      </View>
    );
  };

  const getNotesView = useCallback(() => {
    return (
      <FlatList
        keyExtractor={(_, index) => `${TEXT.NOTES_LIST}-${index}`}
        data={notesListData}
        onEndReached={onReachedEndNotesList}
        showsVerticalScrollIndicator={false}
        onEndReachedThreshold={0.5}
        contentContainerStyle={
          notesListData.length < 6
            ? {paddingBottom: SCREEN_HEIGHT - 100}
            : styles.bottomPadding100
        }
        ListHeaderComponent={() =>
          showAddNowButton(FixedValue.CONSTANT_VALUE_0)
        }
        style={[styles.innerFlat, styles.flatlistView]}
        renderItem={({item}) => (
          <NotesCell
            item={item}
            setOpenCloseNotesModal={setOpenNotesAddEditModal}
            onCallEditNotes={onCallEditNotes}
            onDeleteNotesAlert={onDeleteNotesAlert}
          />
        )}
      />
    );
  }, [
    notesListData,
    onReachedEndNotesList,
    showAddNowButton,
    indexOfBottomSheetIsZero,
    setOpenNotesAddEditModal,
    onCallEditNotes,
    onDeleteNotesAlert,
  ]);

  return (
    <SafeAreaContainer>
      <View style={styles.container} dataSet={{media: ids.container}}>
        <TopBarWithRightButton
          headerLabel="Top Details"
          Icon={
            comingFrom !== TopType.otherUserTop &&
            (!isProfileSwitchToChild ||
              topDetail?.user?.id === UserDetail?.id) &&
            comingFrom !== TopType.viewOffer ? (
              <Pressable
                style={styles.iconView}
                onPress={() => onPressTopCollectionCommonFunction(showOptions)}
              >
                <ThreeDots stroke={ColorTheme.color} />
              </Pressable>
            ) : null
          }
          headerColor={ColorTheme.color}
          onPress={() => backToPreviousScreen()}
        />
        <ScrollableContainer
          KeyboardAwareProps={{showsVerticalScrollIndicator: true}}
        >
          <>
            <TopDetailContent />
            {!isNotesAvailable && showNotesAddButton && showAddNowButton(-30)}
          </>
        </ScrollableContainer>
        {isNotesAvailable && (
          <BottomSheet
            handleComponent={null}
            style={styles.bottomsheetView}
            snapPoints={snapPoints}
            animateOnMount
            keyboardBehavior="fillParent"
            ref={bottomSheetRef}
            onChange={index => {
              setIndexOfBottomSheet(index);
            }}
            backgroundComponent={() => (
              <View style={styles.backgroundColorBottomSheet} />
            )}
            handleIndicatorStyle={styles.backgroundColorBottomSheet}
            enableContentPanningGesture={true}
          >
            {getNotesView()}
          </BottomSheet>
        )}
        {indexOfBottomSheetIsZero && getOptionView()}
        {openOptionsModal && isFocused && <TopOptionsInCollectionModal />}
        {isFocused && (
          <>
            <EditTopModal />
            <MoveToCollectionsModal />
            <DistributeTopModal />
            <ProposeTopCollectionsModal />
            <NotesModal
              buttonLoader={notesButtonLoader}
              notesData={notesData}
              showModal={openNotesAddEditModal}
              setCloseModal={onCloseNoteModal}
              isNotesEditMode={isNotesEditMode}
            />
            {showUntradableAlert && isFocused && (
              <ConfirmationModal
                title={TEXT.MAKING_TRADE_UNTRADBLE_ALERT}
                modalVisible={showUntradableAlert}
                onPressYes={() => {
                  setShowUntradableAlert(false);
                  onPressTopCollectionCommonFunction(markTradableTop);
                }}
                onShowHide={() => setShowUntradableAlert(false)}
              />
            )}
          </>
        )}
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(TopDetailScreen);
