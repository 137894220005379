import React from 'react';
import Svg, {SvgProps, Circle, Path} from 'react-native-svg';

const ModalBack = (props: SvgProps) => (
  <Svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    //@ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={16} cy={16} r={16} fill="#F1F2F4" />
    <Path
      d="M20 12L12 20"
      stroke="#7A7F85"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M12 12L20 20"
      stroke="#7A7F85"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default ModalBack;
