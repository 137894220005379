import React from 'react';
import {View, Pressable} from 'react-native';

import {COLORS} from '../../constants';
import {controllerName} from '../../constants/enum';
import {IHeaderRightIcon} from '../../types/componentTypes/editorType';
import DeleteImage from '../renderSvgs/deleteBin';
import Rotation from '../renderSvgs/leftRotation';
import ScaleIcon from '../renderSvgs/scaleIcon';
import {styles} from './smallDesign.style';

const EditorHeaderRightIcons = (props: IHeaderRightIcon): JSX.Element => {
  const {onPressDelete, onPressResize, onPressRotate, controllerType} = props;
  const style = styles();

  return (
    <>
      <Pressable onPress={onPressRotate}>
        <Rotation
          stroke={
            controllerType === controllerName.ROTATION
              ? COLORS._00B25B
              : COLORS.WHITE
          }
        />
      </Pressable>
      <View style={style.crossView} />
      <Pressable onPress={onPressResize}>
        <ScaleIcon
          stroke={
            controllerType === controllerName.SCALE
              ? COLORS._00B25B
              : COLORS.WHITE
          }
        />
      </Pressable>
      <View style={style.crossView} />
      <Pressable onPress={onPressDelete}>
        <DeleteImage />
      </Pressable>
    </>
  );
};

export default React.memo(EditorHeaderRightIcons);
