import * as React from 'react';
import Svg, {SvgProps, G, Path, Defs, ClipPath, Rect} from 'react-native-svg';
const Done = (props: SvgProps) => (
  <Svg
    width={80}
    height={80}
    viewBox="0 0 80 80"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G clipPath="url(#clip0_34_982)">
      <Path
        d="M15.3375 11.7945C16.3994 11.3095 17.4192 10.7862 18.3076 10.1397C21.5366 7.7898 23.7571 3.20743 27.6364 1.94787C31.3793 0.73258 35.8519 3.09491 40 3.09491C44.1481 3.09491 48.6207 0.73258 52.3636 1.94787C56.2429 3.20743 58.4634 7.7898 61.6924 10.1397C64.9537 12.5132 69.9891 13.2253 72.3625 16.4866C74.7124 19.7156 73.849 24.7129 75.1087 28.5922C76.324 32.3352 80 35.8519 80 40C80 44.1481 76.324 47.6649 75.1087 51.4078C73.8492 55.2871 74.7126 60.2845 72.3625 63.5134C69.9891 66.7747 64.9536 67.4869 61.6924 69.8603C58.4634 72.2102 56.2429 76.7926 52.3636 78.0522C48.6207 79.2675 44.1481 76.9051 40 76.9051C35.8519 76.9051 31.3793 79.2675 27.6364 78.0522C23.7571 76.7926 21.5366 72.2102 18.3076 69.8603C15.0463 67.4869 10.0109 66.7747 7.63751 63.5134C5.28757 60.2845 6.15099 55.2871 4.89143 51.4078C3.67598 47.6649 0 44.1481 0 40C0 35.8519 3.67598 32.3352 4.89127 28.5922C6.15082 24.7129 5.28757 19.7156 7.63751 16.4866C8.38632 15.4577 9.40008 14.6827 10.5394 14.029"
        fill={props.stroke ?? '#00CA67'}
      />
      <Path
        d="M72.4254 39.9999C72.4254 43.3625 69.4454 46.2135 68.4603 49.2477C67.4392 52.3925 68.1392 56.4435 66.2342 59.0612C64.3101 61.7049 60.2282 62.2821 57.5845 64.2063C54.9669 66.1113 53.167 69.8259 50.0222 70.8471C46.988 71.8323 43.3622 69.9173 39.9996 69.9173C36.6371 69.9173 33.0113 71.8323 29.9771 70.8471C26.8323 69.8261 25.0324 66.1113 22.4148 64.2063C19.7711 62.2822 15.6891 61.705 13.765 59.0612C11.8599 56.4435 12.5598 52.3925 11.5388 49.2479C10.5535 46.2137 7.57373 43.3628 7.57373 40.0001C7.57373 36.6375 10.5537 33.7865 11.5388 30.7523C12.5598 27.6075 11.8599 23.5564 13.765 20.939C15.689 18.2953 19.7709 17.7181 22.4148 15.7939C25.0324 13.8888 26.8323 10.1742 29.9771 9.15307C33.0113 8.1678 36.6371 10.0828 39.9996 10.0828C43.3622 10.0828 46.988 8.1678 50.0222 9.15291C53.167 10.1739 54.9669 13.8887 57.5845 15.7937C60.2282 17.7177 64.3103 18.295 66.2343 20.9388C68.1394 23.5564 67.4395 27.6075 68.4605 30.7521C69.4454 33.7863 72.4254 36.6373 72.4254 39.9999Z"
        fill="white"
      />
      <Path
        d="M75.1087 28.5923C73.8492 24.7131 74.7126 19.7157 72.3625 16.4868C69.989 13.2254 64.9535 12.5133 61.6924 10.1399C58.4634 7.78993 56.2429 3.20756 52.3636 1.948C50.7789 1.43356 49.0633 1.56088 47.297 1.8783C47.3764 1.90068 47.4559 1.92241 47.5346 1.948C51.4139 3.20756 53.6344 7.78993 56.8634 10.1399C60.1247 12.5133 65.1601 13.2254 67.5335 16.4868C69.8834 19.7157 69.02 24.7131 70.2797 28.5923C71.495 32.3351 75.171 35.8521 75.171 40.0002C75.171 44.1482 71.495 47.665 70.2797 51.408C69.0202 55.2872 69.8836 60.2846 67.5335 63.5136C65.1601 66.7749 60.1246 67.487 56.8634 69.8604C53.6344 72.2104 51.4139 76.7927 47.5346 78.0523C47.4559 78.0779 47.3764 78.0996 47.2969 78.1222C49.0632 78.4394 50.7789 78.5669 52.3636 78.0523C56.2429 76.7927 58.4634 72.2104 61.6924 69.8604C64.9537 67.487 69.989 66.7749 72.3625 63.5136C74.7124 60.2846 73.8492 55.2872 75.1087 51.408C76.324 47.665 80 44.1482 80 40.0002C80 35.8521 76.324 32.3351 75.1087 28.5923Z"
        fill={props.stroke ?? '#00CA67'}
      />
      <Path
        d="M68.461 30.7521C67.44 27.6073 68.1399 23.5563 66.2348 20.9388C64.3108 18.2951 60.2289 17.7178 57.585 15.7939C54.9674 13.8889 53.1675 10.1741 50.0227 9.15308C48.5674 8.68049 46.9759 8.87558 45.3428 9.20604C48.3953 10.2945 50.1803 13.9193 52.756 15.7939C55.3997 17.7179 59.4818 18.2951 61.4059 20.9388C63.3109 23.5565 62.611 27.6075 63.632 30.7521C64.6173 33.7863 67.5971 36.6372 67.5971 39.9999C67.5971 43.3625 64.6171 46.2135 63.632 49.2477C62.6108 52.3925 63.3109 56.4435 61.4059 59.061C59.4818 61.7047 55.3999 62.2821 52.7562 64.2061C50.1806 66.0806 48.3955 69.7054 45.3429 70.7938C46.9761 71.1243 48.5676 71.3194 50.0228 70.8469C53.1676 69.8258 54.9676 66.1112 57.5852 64.2061C60.2289 62.2821 64.3108 61.7049 66.2348 59.061C68.1399 56.4434 67.44 52.3924 68.461 49.2477C69.4461 46.2135 72.4261 43.3627 72.4261 39.9999C72.4261 36.6372 69.4461 33.7863 68.461 30.7521Z"
        fill={props.fill ?? '#F1F2F4'}
      />
      <Path
        d="M31.7637 52.973L25.7705 45.7619C24.866 44.6736 25.015 43.058 26.1033 42.1535L27.7835 40.7572C28.8718 39.8527 30.4872 40.0018 31.3919 41.09L34.4113 44.723C35.1327 45.5911 36.45 45.6359 37.2286 44.8185L52.6646 29.7579C53.6407 28.7332 55.2626 28.6939 56.2871 29.67L57.8691 31.177C58.8938 32.1531 58.9331 33.775 57.957 34.7995L39.312 53.2286C37.2261 55.4184 33.6967 55.299 31.7637 52.973Z"
        fill={props.stroke ?? '#00CA67'}
      />
      <Path
        d="M57.8691 31.177L56.2871 29.6701C55.2624 28.694 53.6405 28.7334 52.6646 29.758L52.1142 30.2949L53.0401 31.177C54.0648 32.1531 54.1041 33.775 53.128 34.7996L34.483 53.2287C34.1081 53.6223 33.6862 53.9398 33.2358 54.1848C35.1771 55.24 37.684 54.9377 39.312 53.2287L57.9571 34.7996C58.9331 33.7749 58.8938 32.153 57.8691 31.177Z"
        fill={props.stroke ?? '#00CA67'}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_34_982">
        <Rect width={80} height={80} fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default Done;
