import * as React from 'react';
import Svg, {Rect, Path, Circle, SvgProps} from 'react-native-svg';
const WalletImage = (props: SvgProps) => (
  <Svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    //@ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Rect
      x={6.16671}
      y={11.5007}
      width={27.6667}
      height={21}
      rx={1.83333}
      stroke={props?.color ?? "black"}
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M22 20C22 18.1591 23.4924 16.6667 25.3334 16.6667H34.6667C35.7713 16.6667 36.6667 17.5621 36.6667 18.6667V25.3333C36.6667 26.4379 35.7713 27.3333 34.6667 27.3333H25.3334C23.4924 27.3333 22 25.8409 22 24V20Z"
      fill={props?.color ?? "black"}
      stroke={props?.color ?? "black"}
      strokeWidth={1.33333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M15.0377 10.8761L26.0705 6.47134L27.9093 10.8761"
      stroke={props?.color ?? "black"}
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Circle
      cx={27.3334}
      cy={22}
      r={1.33333}
      fill="white"
      stroke="white"
      strokeWidth={1.33333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default WalletImage;
