import * as DocumentPicker from 'expo-document-picker';
import {_ToastHandler, alertPolyfill} from './helperFunctions';
import {TEXT, TOAST_TEXT} from '../constants/textMessage';

export const pickerOpen = async () => {
  try {
    const res = await DocumentPicker.getDocumentAsync({
      type: 'file/*',
      multiple: false,
    });
    if (res.type === 'success') {
      if (res.name.endsWith('.csv')) {
        return {
          uri: res.uri,
          type: res.mimeType,
          name: res.name,
          size: res.size,
        };
      } else {
        // Selected file is not a CSV, show error or handle accordingly
        alertPolyfill(TOAST_TEXT.ONLY_CSV_CAN_UPLOAD, '', [
          {
            text: TEXT.OKEY,
            onPress: () => {
              {
              }
            },
          },
        ]);
        return null;
      }
    } else {
      // Handle cancel or any other type of errors
      console.log('Document picker cancelled or failed');
      return null;
    }
  } catch (err) {
    console.error('Error selecting file:', err);
    return null;
  }
};
