import React from 'react';
import {Text, Pressable} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {style} from './styles';
import {PackListObject} from '../../types/reducerTypes/searchTabStoreType';
import {COLORS} from '../../constants';

interface TagViewProps {
  onPress: (item: PackListObject) => void;
  item: PackListObject;
  selectedPlanId: number | null;
}

const Plantag = ({onPress, item, selectedPlanId}: TagViewProps) => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const styles = style(ColorTheme);
  const isAnyPlanSelected = item?.id === selectedPlanId;
  const backgroundColor = isAnyPlanSelected
    ? COLORS.PRIMARY_BLUE
    : COLORS.WHITE;
  const color = isAnyPlanSelected ? COLORS.WHITE : COLORS.BLACK;

  return (
    <Pressable
      onPress={() => onPress(item)}
      style={[styles.tagContainer, {backgroundColor}]}
    >
      <Text style={[styles.tagText, {color}]}>${item?.packAmount}</Text>
    </Pressable>
  );
};

export default Plantag;
