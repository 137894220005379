import React, {useContext} from 'react';
import {Keyboard, View} from 'react-native';
import {styles} from './styles';
import {FlatList} from 'react-native';
import {BASESTYLE, FixedValue} from '../../constants';
import SearchListTile from '../RenderItemTile/searchListTile';
import {SearchTabContext} from '../../contextAPI/SearchTabBarContext';
import useSearchTabHooks from '../../hooks/componentHooks/useSearchTabHooks';

const TopsListComponent = (): JSX.Element => {
  const {searchText, searchResult} = useContext(SearchTabContext);
  const {onReachedEnd} = useSearchTabHooks();

  const RenderTopsList = React.useMemo((): JSX.Element => {
    if (
      searchText.length > FixedValue.CONSTANT_VALUE_2 &&
      searchResult.length > FixedValue.CONSTANT_VALUE_0
    ) {
      return (
        <FlatList
          data={searchResult}
          style={BASESTYLE.flex1}
          keyExtractor={(_, index) => `TopNumber-${index}`}
          onEndReached={onReachedEnd}
          onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
          contentContainerStyle={styles.contentContainerStyle}
          showsVerticalScrollIndicator={false}
          renderItem={({item}) => <SearchListTile {...item} />}
          onScrollBeginDrag={() => Keyboard.dismiss()}
        />
      );
    }
    return <View />;
  }, [searchText, searchResult]);

  return RenderTopsList;
};

export default React.memo(TopsListComponent);
