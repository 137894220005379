import React from 'react';
import {View, Modal, Pressable} from 'react-native';

import DistributiveTopContent from './distributiveTopContent';
import {ThemeContext} from '../../contextAPI/themeContext';
import {distributiveTopStyle} from './distributiveTop.style';
import {HomeContext} from '../../contextAPI/homeContext';
import useHomeHooks from '../../hooks/componentHooks/useHomeHooks';

const DistributeTop = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {showDistributiveModal} = React.useContext(HomeContext);
  const {styles, ids} = distributiveTopStyle(ColorTheme);
  const {resetDistributeTopsHelperData} = useHomeHooks();

  return (
    <Modal visible={showDistributiveModal} transparent>
      <View style={styles.modalStyle} dataSet={{media: ids.modalStyle}}>
        <Pressable
          onPress={resetDistributeTopsHelperData}
          style={styles.outterPart}
        />
        <View style={styles.innerView}>
          <DistributiveTopContent />
        </View>
      </View>
    </Modal>
  );
};

export default React.memo(DistributeTop);
