import {
  useRef,
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import {TextInput} from 'react-native';
import {ChildProfileContext} from '../../contextAPI/childProfileContext';
import {CHILD_PERMISSION, FixedValue} from '../../constants/enum';
import useTradingApprovalHooks from './useTradingApprovalHooks';
import {TEXT} from '../../constants';
import useTopBuyingSetting from './useTopBuyingSetting';
import {OnSaveChildSettingType} from '../../types/componentTypes/userProfileTypes';
import {
  CHILD_GROUP_APPROVAL_SETTING,
  CHILD_TRADABLE_SETTING,
  DISTRIBUTE_TOP_APPROVAL_SETTING,
  TOP_BUYING_SETTING,
  TRADE_APPROVAL_SETTING,
} from '../../constants/actionTypes';
import {TOAST_TEXT} from '../../constants/textMessage';
import {_ToastHandler} from '../../utils/helperFunctions';
import {childProfileStore} from '../../redux/reducers/childProfileSlice';
import {useAppSelector} from '../reduxHooks';
import useChildListHooks from './useChildListHooks';
import {useIsFocused} from '@react-navigation/native';
import {childProfileApproveStore} from '../../redux/reducers/childApproveSlice';

const useChildProfileHook = () => {
  const isFocused = useIsFocused();
  const {
    childUserData,
    showDistributeTopSettingChangePopUp,
    setShowDistributeTopSettingChangePopUp,
  } = useContext(ChildProfileContext);

  // Extracting permission statuses from childUserData
  const permissionData = childUserData?.permissionsStatus;

  // Setting up initial state for all permissions
  const initialState = {
    tradeSettingApproval:
      permissionData?.offerPermission?.permissionStatus ??
      CHILD_PERMISSION.NOT_ALLOWED,
    selectedTopBuying:
      permissionData?.purchasePermission?.status ??
      CHILD_PERMISSION.NOT_ALLOWED,
    distributeApproval:
      permissionData?.distributePermission?.permissionStatus ??
      CHILD_PERMISSION.NOT_ALLOWED,
    tradableSettingApproval:
      permissionData?.tradablePermissionStatus ?? CHILD_PERMISSION.NOT_ALLOWED,
    groupSettingApproval:
      permissionData?.groupPermissionStatus ?? CHILD_PERMISSION.NOT_ALLOWED,
  };

  // State for permissions and top buying limit value
  const [permissions, setPermissions] = useState(initialState);
  const [topBuyingLimitValue, setTopBuyingLimitValue] = useState<string>(
    permissionData?.purchasePermission?.monthlyLimit?.toString() ?? ''
  );
  // Reference for the text input
  const textInputRef = useRef<TextInput>(null);
  // Extracting necessary hooks
  const {
    callDistributeTopSettingAPI,
    callChildTradableSettingAPI,
    callTradingApproveSettingAPI,
    callChildGroupApproveSettingAPI,
  } = useTradingApprovalHooks();
  const [isLoading, setLoading] = useState(false);
  const {saveSettings} = useTopBuyingSetting();
  const {
    childTradableSetting,
    childGroupSettingApproval,
    distributeTopSettingApproval,
    tradeSettingApproval,
  } = useAppSelector(childProfileStore);
  const {callChildProfileAPI} = useChildListHooks();
  const {topBuyingSettingData} = useAppSelector(childProfileApproveStore);

  // Function to handle the press event for changing settings
  const handlePress = useCallback(
    (status: CHILD_PERMISSION, updatedKey: string, headerTitle: string) => {
      setPermissions(prevApprovals => ({
        ...prevApprovals,
        [updatedKey]: status,
      }));
      if (headerTitle === TEXT.TOP_BUYING_APPROVAL) {
        updateSelectedOption(status);
      }
    },
    []
  );

  // Function to update the selected option for top buying setting
  const updateSelectedOption = (val: CHILD_PERMISSION): void => {
    if (permissions.selectedTopBuying !== val) {
      if (textInputRef.current) {
        textInputRef.current.blur();
      }
      setTopBuyingLimitValue('');
    }
  };

  // Memoized value to check if the save button should be disabled
  const isDisabled = useMemo((): boolean => {
    if (permissions.selectedTopBuying === CHILD_PERMISSION.HAVE_LIMIT) {
      return (
        permissions.selectedTopBuying &&
        topBuyingLimitValue.length === FixedValue.CONSTANT_VALUE_0
      );
    }
    return false;
  }, [topBuyingLimitValue, permissions.selectedTopBuying]);

  // Arrays defining the available settings options
  const distributeSettingArray = [
    {text: TEXT.ALL_DISTRIBUTION_REQUEST, status: CHILD_PERMISSION.NOT_ALLOWED},
    {text: TEXT.MUST_APPROVE_REQUEST, status: CHILD_PERMISSION.ALLOWED},
    {text: TEXT.APPROVED_LIST, status: CHILD_PERMISSION.SPECIFIC_USERS},
  ];

  const topBuyingSettingArray = [
    {text: TEXT.APPROVE_ALL_PURCHASES, status: CHILD_PERMISSION.NOT_ALLOWED},
    {text: TEXT.ALLOW_CHILD_PURCHASES, status: CHILD_PERMISSION.ALLOWED},
    {
      text: TEXT.REQUIRE_PARENT_APPROVEL_FOR_PURCHASES,
      status: CHILD_PERMISSION.HAVE_LIMIT,
    },
  ];

  const tradeSettingArray = [
    {text: TEXT.APPROVE_ALL_TRADE, status: CHILD_PERMISSION.NOT_ALLOWED},
    {text: TEXT.ALLOW_CHILD_ACCOUNT, status: CHILD_PERMISSION.ALLOWED},
    {
      text: TEXT.TRADE_SETTING_FOR_PRE_APPROVAL,
      status: CHILD_PERMISSION.SPECIFIC_USERS,
    },
  ];

  const groupSettingArray = [
    {text: TEXT.PARENT_GUARDIAN_MUST, status: CHILD_PERMISSION.NOT_ALLOWED},
    {text: TEXT.PARENT_GUARDIAN_ALLOW, status: CHILD_PERMISSION.ALLOWED},
  ];

  const shiftingToTradeableArray = [
    {text: TEXT.APPROVE_ALL_TRADABLE, status: CHILD_PERMISSION.NOT_ALLOWED},
    {text: TEXT.ACCEPT_TRDABLE_MEMBERSHIP, status: CHILD_PERMISSION.ALLOWED},
  ];

  // Combine all settings into a single array with headers
  const combinedSettingsWithHeaders: {
    title: string;
    selectedItem: string;
    updatedKey: string;
    showButtonLoader: boolean;
    data: {text: string; status: CHILD_PERMISSION}[];
    onSaveChildSetting: OnSaveChildSettingType;
  }[] = [
    {
      title: TEXT.TOP_TRADING,
      selectedItem: permissions.tradeSettingApproval,
      updatedKey: 'tradeSettingApproval',
      data: tradeSettingArray,
      showButtonLoader: tradeSettingApproval.isLoading,
      onSaveChildSetting: (status?: string) =>
        callTradingApproveSettingAPI(status ?? ''),
    },
    {
      title: TEXT.TOP_BUYING_APPROVAL,
      selectedItem: permissions.selectedTopBuying,
      updatedKey: 'selectedTopBuying',
      data: topBuyingSettingArray,
      showButtonLoader: topBuyingSettingData.isLoading,
      onSaveChildSetting: (status?: string, limit?: string) =>
        saveSettings(status, limit),
    },
    {
      title: TEXT.SHIFTING_TO_TRADEABLE,
      selectedItem: permissions.tradableSettingApproval,
      updatedKey: 'tradableSettingApproval',
      data: shiftingToTradeableArray,
      showButtonLoader: childTradableSetting.isLoading,
      onSaveChildSetting: (status?: string) =>
        callChildTradableSettingAPI(status ?? ''),
    },
    {
      title: TEXT.DISTRIBUTE_TOP_REQUEST,
      selectedItem: permissions.distributeApproval,
      updatedKey: 'distributeApproval',
      data: distributeSettingArray,
      showButtonLoader: distributeTopSettingApproval.isLoading,

      onSaveChildSetting: (status?: string) =>
        callDistributeTopSettingAPI(status ?? ''),
    },
    {
      title: TEXT.GROUP_INVITATION,
      selectedItem: permissions.groupSettingApproval,
      updatedKey: 'groupSettingApproval',
      data: groupSettingArray,
      showButtonLoader: childGroupSettingApproval.isLoading,
      onSaveChildSetting: (status?: string) =>
        callChildGroupApproveSettingAPI(status ?? ''),
    },
  ];

  // Effect to handle the top trading child setting update response
  useEffect(() => {
    if (isFocused) {
      switch (tradeSettingApproval.status) {
        case TRADE_APPROVAL_SETTING.SUCCESS:
          callChildProfileAPI(childUserData?.id ?? FixedValue.CONSTANT_VALUE_0);
          _ToastHandler(
            TOAST_TEXT.TOP_TRADING_APPROVAL_SUCCESFULLY_CHANGED,
            true
          );
          setLoading(false);
          break;
        case TRADE_APPROVAL_SETTING.FAILED:
          setLoading(false);
          break;
        default:
          break;
      }
    }
  }, [tradeSettingApproval.status]);

  // Effect to handle the top buying child setting update response
  useEffect((): void => {
    if (isFocused) {
      switch (topBuyingSettingData.status) {
        case TOP_BUYING_SETTING.SUCCESS:
          callChildProfileAPI(childUserData?.id ?? FixedValue.CONSTANT_VALUE_0);
          _ToastHandler(TOAST_TEXT.TOP_BUYING_APPROVAL, true);
          setLoading(false);
          break;
        case TOP_BUYING_SETTING.FAILED:
          setLoading(false);
          break;
        default:
          break;
      }
    }
  }, [topBuyingSettingData.status]);

  // Effect to handle the tradeable shifting child setting update response
  useEffect(() => {
    if (isFocused) {
      switch (childTradableSetting.status) {
        case CHILD_TRADABLE_SETTING.SUCCESS:
          _ToastHandler(TOAST_TEXT.CHILD_TRADABLE_SETTING_SUCCESS, true);
          callChildProfileAPI(childUserData?.id ?? FixedValue.CONSTANT_VALUE_0);
          setLoading(false);
          break;
        case CHILD_TRADABLE_SETTING.FAILED:
          setLoading(false);
          break;
        default:
          break;
      }
    }
  }, [childTradableSetting.status]);

  // Effect to handle the distribute top child setting update response
  useEffect(() => {
    if (isFocused) {
      switch (distributeTopSettingApproval.status) {
        case DISTRIBUTE_TOP_APPROVAL_SETTING.SUCCESS:
          callChildProfileAPI(childUserData?.id ?? FixedValue.CONSTANT_VALUE_0);
          setShowDistributeTopSettingChangePopUp(false);
          _ToastHandler(
            TOAST_TEXT.DISTRIBUTE_TOP_APPROVAL_SETTING_CHANGE,
            true
          );
          setLoading(false);
          break;
        case DISTRIBUTE_TOP_APPROVAL_SETTING.FAILED:
          setLoading(false);
          break;
        default:
          break;
      }
    }
  }, [distributeTopSettingApproval.status]);

  // Effect to handle the group invitation child setting update response
  useEffect(() => {
    if (isFocused) {
      switch (childGroupSettingApproval.status) {
        case CHILD_GROUP_APPROVAL_SETTING.SUCCESS:
          _ToastHandler(TOAST_TEXT.GROUP_INVITATION_SETTING, true);
          callChildProfileAPI(childUserData?.id ?? FixedValue.CONSTANT_VALUE_0);
          setLoading(false);
          break;
        case CHILD_GROUP_APPROVAL_SETTING.FAILED:
          setLoading(false);
          break;
        default:
          break;
      }
    }
  }, [childGroupSettingApproval.status]);

  return {
    permissions,
    topBuyingLimitValue,
    textInputRef,
    isLoading,
    showDistributeTopSettingChangePopUp,
    setShowDistributeTopSettingChangePopUp,
    combinedSettingsWithHeaders,
    handlePress,
    setTopBuyingLimitValue,
    callDistributeTopSettingAPI,
    setLoading,
    isDisabled,
  };
};

export default useChildProfileHook;
