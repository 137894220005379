import React from 'react';
import {ActivityIndicator, Pressable, View} from 'react-native';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {TopModel} from '../../../types/reducerTypes/searchTabStoreType';
import {styles} from './style';
import useAwsS3 from '../../../hooks/libraryHooks/useAwsS3';
import {COLORS} from '../../../constants';
import {ChildDistributeRequestTop} from '../../../types/componentTypes/childProfileType';
import CircularView from '../../CircularView';
import {getTopImage} from '../../../utils/imageUtils';
interface IRenderTopImageProps {
  item: TopModel | ChildDistributeRequestTop;
  showTopDetail?: (val: TopModel) => void;
}

const RenderTopImage = ({
  item,
  showTopDetail,
}: IRenderTopImageProps): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  const {loadSignedUrl} = useAwsS3();
  const [svgCode, setSvgCode] = React.useState<JSX.Element | undefined>(
    undefined
  );
  const getSvgUrl = React.useCallback(async (): Promise<void> => {
    const imageUrl = await loadSignedUrl(item?.image ?? '');
    if (imageUrl) {
      const topImage = await getTopImage(imageUrl ?? '');
      setSvgCode(topImage);
    }
  }, [item?.image, svgCode]);

  React.useEffect(() => {
    getSvgUrl();
  }, []);

  return (
    <Pressable
      onPress={() => showTopDetail && showTopDetail(item)}
      style={style.marginRight8}
    >
      {svgCode ? (
        <View style={style.imageView2}>{svgCode}</View>
      ) : (
        <View style={style.imageLoader}>
          <ActivityIndicator size={'small'} color={COLORS.PRIMARY_BLUE} />
        </View>
      )}
      <CircularView quantity={item?.quantity?.toString() ?? ''} isSmall />
    </Pressable>
  );
};

export default React.memo(RenderTopImage);
