import StyleSheet from 'react-native-media-query';
import {
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {scaleHeight, scaleWidth, normalizeFont} from '../../utils/responsive';

export const editCollectionModalStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    subContainer: {
      width: Percentage.PRECENTAGE_100,
      backgroundColor: theme.backgroundColor,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_15),
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_18),
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_18,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_18,
      shadowColor: COLORS._333333,
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_0,
        height: -FixedValue.CONSTANT_VALUE_3,
      },
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
      elevation: FixedValue.CONSTANT_VALUE_3,
      alignItems: GlobalStyleValues.CENTER,
    },
    headingText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: COLORS._7A7F85,
    },
    alignEnd: {
      alignSelf: GlobalStyleValues.FLEX_END,
    },
  });
