import React from 'react';
import {View, Text, Pressable} from 'react-native';
import {RouteProp, useIsFocused, useRoute} from '@react-navigation/native';

import ViewContainer from '../../components/layout/viewContainer';
import {RootStackParamList} from '../../types/navigationType';
import {TEXT} from '../../constants';
import {topDetailStyle} from './topDetail.style';
import {ThemeContext} from '../../contextAPI/themeContext';
import {FixedValue, timeformat, TopType} from '../../constants/enum';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import {
  onTopDetailDataStart,
  searchTabStore,
} from '../../redux/reducers/searchTabSlice';
import TradableLable from '../../components/smallDesigns/tradableLable';
import {TopDetailContext} from '../../contextAPI/topDetailContext';
import TagView from '../../components/tagView';
import {TradableStore} from '../../redux/reducers/tradableTopsSlice';
import {MARK_TRADABLE_TOPS} from '../../constants/actionTypes';
import {formatUTCTimeToLocalTime} from '../../utils/helperFunctions';
import HeartIcon from '../../components/renderSvgs/heartIcon';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import HeartFavoriteIcon from '../../components/renderSvgs/heartFavoriteIcon';
import useFavouriteHooks from '../../hooks/componentHooks/useFavouriteHooks';
import AppLoader from '../../components/smallDesigns/appLoader';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';

const TopDetailContent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isFocused = useIsFocused();
  const {svgCode, topDetail, showFavoriteIcon} =
    React.useContext(TopDetailContext);
  const route = useRoute<RouteProp<RootStackParamList, 'TopDetailScreen'>>();
  const {comingFrom, topDetails} = route.params;
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = topDetailStyle(ColorTheme);
  const {tradableTop} = useAppSelector(TradableStore);
  const {addFavoriteAndUnFavorite, setFavoriteLoader, favoriteLoader} =
    useFavouriteHooks();
  const {userData} = useAppSelector(userProfileStore);
  const {topDetailData} = useAppSelector(searchTabStore);
  const UserDetail = userData?.success;
  const showHeartIcon =
    topDetail?.user?.favoriteUserId !== UserDetail?.id &&
    topDetail?.user?.favoriteUserId !== FixedValue.CONSTANT_VALUE_0;

  React.useEffect(() => {
    if (
      isFocused &&
      comingFrom !== TopType.viewOffer &&
      comingFrom !== TopType.seeOnlyDetails &&
      comingFrom !== TopType.purchased
    ) {
      dispatch(
        onTopDetailDataStart({
          topId: topDetails?.id,
          fromSearch: comingFrom !== TopType.otherUserTop ? false : true,
        })
      );
    }
  }, [isFocused]);

  const onPressFavAndUnFav = React.useCallback(
    (favorite: boolean): void => {
      setFavoriteLoader(true);
      const params = {
        favorite,
        userIds: [
          topDetail?.user?.favoriteUserId ?? FixedValue.CONSTANT_VALUE_0,
        ],
      };
      addFavoriteAndUnFavorite(params);
    },
    [topDetail]
  );

  React.useEffect(() => {
    switch (tradableTop.status) {
      case MARK_TRADABLE_TOPS.SUCCESS:
        dispatch(
          onTopDetailDataStart({
            topId: topDetail?.id,
            fromSearch: comingFrom !== TopType.otherUserTop ? false : true,
          })
        );
        break;
      case MARK_TRADABLE_TOPS.FAILED:
        break;
      default:
        break;
    }
  }, [tradableTop.status]);

  const getOwnerName = () => {
    return (
      <View style={styles.flexDirectionRow}>
        <Text style={styles.createBy}>{TEXT.CREATED_BY}</Text>
        <Text
          style={styles.createdByName}
          numberOfLines={FixedValue.CONSTANT_VALUE_1}
        >
          {topDetail?.createdByFullName ?? ''}
        </Text>
      </View>
    );
  };

  const getTopInfoView = () => {
    return (
      <View style={styles.topInfoView}>
        <View style={styles.infoContainer}>
          <Text style={styles.topName}>{topDetail?.name}</Text>
          <View style={styles.topNameQuanityView}>
            <View style={styles.countCountainer}>
              <View style={styles.flexDirectionRow}>
                <Text
                  numberOfLines={FixedValue.CONSTANT_VALUE_1}
                  style={styles.quanitytext}
                >
                  {TEXT.DISTRIBUTION}{' '}
                  <Text style={styles.textColorPrimary}>
                    {topDetail?.pendingRequest?.pendingDistributionRequests ??
                      FixedValue.CONSTANT_VALUE_0}
                  </Text>
                </Text>
                <Text
                  numberOfLines={FixedValue.CONSTANT_VALUE_1}
                  style={styles.quanitytext}
                >
                  {`,  `}
                  {TEXT.TRADE_OFFER_2}{' '}
                  <Text style={styles.textColorPrimary}>
                    {topDetail?.pendingRequest?.pendingTradeRequests ??
                      FixedValue.CONSTANT_VALUE_0}
                  </Text>
                </Text>
              </View>
            </View>

            {comingFrom !== TopType.inProgress && (
              <Pressable style={styles.quantityButton}>
                <Text style={styles.quanitiyButtonText}>
                  {TEXT.QUANTITY}:{' '}
                  {topDetail?.quantity ?? FixedValue.CONSTANT_VALUE_0}
                </Text>
              </Pressable>
            )}
          </View>
          <>
            <View style={styles.nameImageContainer}>
              <View style={styles.flexDirectionRow}>
                <View style={styles.flexDirectionColumn}>
                  <View style={styles.maxWidth85}>{getOwnerName()}</View>
                  <View style={styles.marginTop6} />
                  <Text style={styles.createBy}>
                    {TEXT.CREATED_ON}{' '}
                    <Text style={styles.createdByName}>
                      {formatUTCTimeToLocalTime(
                        topDetail?.creationDate ?? '',
                        timeformat.MMDDYY
                      )}
                    </Text>
                  </Text>
                  {topDetail?.hashTag && (
                    <TagView value={topDetail?.hashTag ?? ''} />
                  )}
                </View>
              </View>
              {showHeartIcon &&
                showFavoriteIcon &&
                (topDetail?.user?.favorite ? (
                  <Pressable
                    onPress={() =>
                      onPressFavAndUnFav(!topDetail?.user?.favorite ?? false)
                    }
                    style={styles.heartContainer}
                  >
                    <HeartFavoriteIcon
                      height={scaleHeight(FixedValue.CONSTANT_VALUE_32)}
                      width={scaleWidth(FixedValue.CONSTANT_VALUE_32)}
                    />
                  </Pressable>
                ) : (
                  <Pressable
                    onPress={() =>
                      onPressFavAndUnFav(!topDetail?.user?.favorite ?? true)
                    }
                    style={styles.heartContainer}
                  >
                    <HeartIcon
                      height={scaleHeight(FixedValue.CONSTANT_VALUE_46)}
                      width={scaleWidth(FixedValue.CONSTANT_VALUE_46)}
                    />
                  </Pressable>
                ))}
            </View>
          </>
        </View>
      </View>
    );
  };

  return (
    <ViewContainer>
      <>
        {(favoriteLoader || topDetailData.isLoading) && <AppLoader />}
        <View style={styles.tradeView}>
          {topDetail?.tradable && <TradableLable />}
        </View>
        <View style={styles.outterContainer}>
          <View style={styles.imageStyle}>{svgCode}</View>
          {getTopInfoView()}
        </View>
      </>
    </ViewContainer>
  );
};

export default React.memo(TopDetailContent);
