import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
} from '../../../constants';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {normalizeFont, scaleHeight} from '../../../utils/responsive';

export const style = (_theme?: IColorTheme) =>
  StyleSheet.create({
    container: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_75),
      ...BASESTYLE.absolutePosition,
      zIndex: FixedValue.CONSTANT_VALUE_1,
      bottom: FixedValue.CONSTANT_VALUE_0,
      justifyContent: GlobalStyleValues.FLEX_END,
      left: FixedValue.CONSTANT_VALUE_0,
      right: FixedValue.CONSTANT_VALUE_0,
    },
    bgView: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_75),
      ...BASESTYLE.absolutePosition,
      zIndex: FixedValue.CONSTANT_VALUE_MIN_1,
      left: FixedValue.CONSTANT_VALUE_0,
      right: FixedValue.CONSTANT_VALUE_0,
      bottom: FixedValue.CONSTANT_VALUE_0,
    },
    primaryButtonStyle: {
      ...BASESTYLE.inRow,
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_179),
      justifyContent: GlobalStyleValues.CENTER,
    },
    primaryButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      marginLeft: FixedValue.CONSTANT_VALUE_10,
    },
    primaryDisabledButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.BDBDBD,
      marginLeft: FixedValue.CONSTANT_VALUE_10,
    },
    buttonView: {
      ...BASESTYLE.inRow,
      justifyContent: GlobalStyleValues.SPACE_BETWEEN,
      padding: FixedValue.CONSTANT_VALUE_20,
    },
    remainingText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.PRIMARY_BLUE,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
    },
    fadeBlack: {
      backgroundColor: COLORS.FADE_BLACK,
    },
    fadeWhite: {
      backgroundColor: COLORS.FADE_WHITE,
    },
  });
