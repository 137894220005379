import {StyleSheet} from 'react-native';
import {COLORS, FixedValue, FONT_NAMES} from '../../../constants';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {normalizeFont, scaleHeight} from '../../../utils/responsive';

export const styles = (theme: IColorTheme) =>
  StyleSheet.create({
    titleText: {
      fontSize: normalizeFont(30),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: theme.color,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
  });
