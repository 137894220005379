import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';
const NotificationBell = (props: SvgProps) => (
  <Svg
    width={250}
    height={200}
    viewBox="0 0 250 200"
    fill="none"
    //@ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63 134H154C154.515 134 155.017 133.944 155.5 133.839C155.983 133.944 156.485 134 157 134H209C212.866 134 216 130.866 216 127C216 123.134 212.866 120 209 120H203C199.134 120 196 116.866 196 113C196 109.134 199.134 106 203 106H222C225.866 106 229 102.866 229 99C229 95.134 225.866 92 222 92H200C203.866 92 207 88.866 207 85C207 81.134 203.866 78 200 78H136C139.866 78 143 74.866 143 71C143 67.134 139.866 64 136 64H79C75.134 64 72 67.134 72 71C72 74.866 75.134 78 79 78H39C35.134 78 32 81.134 32 85C32 88.866 35.134 92 39 92H64C67.866 92 71 95.134 71 99C71 102.866 67.866 106 64 106H24C20.134 106 17 109.134 17 113C17 116.866 20.134 120 24 120H63C59.134 120 56 123.134 56 127C56 130.866 59.134 134 63 134ZM226 134C229.866 134 233 130.866 233 127C233 123.134 229.866 120 226 120C222.134 120 219 123.134 219 127C219 130.866 222.134 134 226 134Z"
      fill="#F3F7FF"
    />
    <Path
      d="M157.5 69C157.5 61.4413 154.497 54.1922 149.153 48.8475C143.808 43.5027 136.559 40.5 129 40.5C121.441 40.5 114.192 43.5027 108.847 48.8475C103.503 54.1922 100.5 61.4413 100.5 69C100.5 102.25 86.25 111.75 86.25 111.75H171.75C171.75 111.75 157.5 102.25 157.5 69Z"
      fill="white"
      stroke="#1F64E7"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M102 67.3176C102.184 67.3144 102.368 67.3127 102.553 67.3127C118.787 67.3127 132.08 79.9696 133.194 96C146.116 93.9618 156 82.7233 156 69.1649C156 54.1622 143.898 42 128.969 42C114.658 42 102.945 53.1764 102 67.3176Z"
      fill="#E8F0FE"
    />
    <Path
      d="M137.218 120.75C136.383 122.19 135.184 123.385 133.742 124.215C132.3 125.046 130.665 125.483 129.001 125.483C127.336 125.483 125.701 125.046 124.259 124.215C122.817 123.385 121.618 122.19 120.783 120.75"
      stroke="#1F64E7"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default NotificationBell;
