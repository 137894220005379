import React from 'react';
import {View, Text} from 'react-native';
import PrimaryButton from '../../components/button/primaryButton';

import ViewContainer from '../../components/layout/viewContainer';
import Done from '../../components/renderSvgs/done';
import {ROUTE_NAME, TEXT} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {navigate} from '../../services/navigationService';
import {passwordResetStyle} from './passwordReset.style';

const PasswordReset = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = passwordResetStyle(ColorTheme);
  const moveToLogin = React.useCallback(
    (): void => navigate(ROUTE_NAME.LOGIN_SCREEN),
    []
  );
  return (
    <ViewContainer>
      <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
        <Done />
        <Text style={styles.titleText}>{TEXT.YOUR_PASSWORD_RESET}</Text>
        {/* <Text style={styles.descText}>{TEXT.WHERE_TO_REDIRECT}</Text> */}
        <View style={styles.buttonView}>
          <PrimaryButton buttonLabel={TEXT.LOGIN_NOW} onPress={moveToLogin} />
        </View>
      </View>
    </ViewContainer>
  );
};

export default React.memo(PasswordReset);
