import React from 'react';
import {View, Text} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {circularStyle} from './circularView.style';
import {FixedValue} from '../../constants';

interface CircularViewProps {
  quantity: string;
  isSmall?: boolean;
  showSecondaryCircle?: boolean;
  totalPendingRequests?: number;
}

const CircularView = (props: CircularViewProps) => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const styles = circularStyle(ColorTheme);
  const {
    quantity,
    isSmall,
    showSecondaryCircle = false,
    totalPendingRequests = FixedValue.CONSTANT_VALUE_0,
  } = props;

  return (
    <View style={[styles.container, isSmall && styles.isSmallStyle]}>
      <View style={styles.flexDirectionRow}>
        <View style={styles.circle}>
          <Text style={styles.quantityLabel}>{quantity}</Text>
        </View>
        {showSecondaryCircle &&
          totalPendingRequests !== FixedValue.CONSTANT_VALUE_0 && (
            <View style={styles.secondCircle}>
              <Text style={styles.secondQuantityLabel}>
                {totalPendingRequests}*
              </Text>
            </View>
          )}
      </View>
    </View>
  );
};

export default CircularView;
