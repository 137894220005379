/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {useFormik} from 'formik';
import {RouteProp, useRoute} from '@react-navigation/native';
import {useAppDispatch, useAppSelector} from '../reduxHooks';

import {ERROR_MESSAGES, ROUTE_NAME, TEXT} from '../../constants';
import {goBack, navigate} from '../../services/navigationService';
import {CreateNewPwdScheme} from '../../utils/formikSchema';
import {RootStackParamList} from '../../types/navigationType';
import {
  newPasswordState,
  onResetPasswordStart,
} from '../../redux/reducers/resetPasswordSlice';
import {RESET_PASSWORD} from '../../constants/actionTypes';
import {_ToastHandler} from '../../utils/helperFunctions';
import {CreatePasswordContext} from '../../contextAPI/createPassword';

export type CreatPwdFields = {NewPassword: string; ConfirmPassword: string};

const useCreatePwdHooks = () => {
  const {setIsBtnLoading} = React.useContext(CreatePasswordContext);
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isValid,
    values,
    setFieldValue,
  } = useFormik<CreatPwdFields>({
    validationSchema: CreateNewPwdScheme,
    initialErrors: {
      NewPassword: ERROR_MESSAGES.REQUIRED_USERNAME,
      ConfirmPassword: ERROR_MESSAGES.REQUIRED_PASSWORD,
    },
    initialTouched: {NewPassword: false, ConfirmPassword: false},
    initialValues: {NewPassword: '', ConfirmPassword: ''},
    onSubmit: _val => resetUserPassword(_val),
  });
  const dispatch = useAppDispatch();
  const route = useRoute<RouteProp<RootStackParamList, 'CreateNewPassword'>>();
  const {emailId, Code} = route.params;
  const {resetPassword} = useAppSelector(newPasswordState);

  const resetUserPassword = React.useCallback((val: CreatPwdFields): void => {
    setIsBtnLoading(true);
    const payload = {
      Code,
      Email: emailId,
      Password: val?.ConfirmPassword,
    };
    dispatch(onResetPasswordStart(payload));
  }, []);

  React.useEffect(() => {
    switch (resetPassword.status) {
      case RESET_PASSWORD.SUCCESS:
        setIsBtnLoading(false);
        navigate(ROUTE_NAME.PASSWORD_RESET);
        break;
      case RESET_PASSWORD.FAILED:
        setIsBtnLoading(false);
        if (resetPassword.failed === ERROR_MESSAGES.INVALID_VERIFICATIONCODE) {
          _ToastHandler(TEXT.OTP_CODE_INCORRECT, false);
          goBack();
        } else {
          _ToastHandler(resetPassword.failed ?? '', false);
        }
        break;
      default:
        break;
    }
  }, [resetPassword.status]);

  const NewPasswordError =
    touched.NewPassword && errors.NewPassword ? errors.NewPassword : '';
  const ConfirmPasswordError =
    touched.ConfirmPassword && errors.ConfirmPassword
      ? errors.ConfirmPassword
      : '';

  return {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isValid,
    values,
    setFieldValue,
    NewPasswordError,
    ConfirmPasswordError,
  };
};

export default useCreatePwdHooks;
