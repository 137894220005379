import StyleSheet2 from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FONT_NAMES,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {StyleSheet} from 'react-native';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';

export const WelcomeTopModalStyle = (_theme: IColorTheme) =>
  StyleSheet2.create({
    modalBackground: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.inCenter,
      position: GlobalStyleValues.ABSOLUTE,
      alignItems: GlobalStyleValues.CENTER,
      justifyContent: GlobalStyleValues.CENTER,
      height: Percentage.PRECENTAGE_100,
      width: Percentage.PRECENTAGE_100,
    },
    blurBackground: {
      ...StyleSheet.absoluteFillObject,
      ...BASESTYLE.inCenter,
    },
    modalContent: {
      alignItems: GlobalStyleValues.CENTER,
    },
    congratsText: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      color: COLORS.WHITE,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_34),
    },
    awardedText: {
      fontFamily: FONT_NAMES.SF_LIGHT,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      color: COLORS.WHITE,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_16),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_30),
    },
    getStartedButton: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_268),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      backgroundColor: GlobalStyleValues.TRANSPARENT,
      justifyContent: GlobalStyleValues.CENTER,
    },
    getStartedTextStyle: {
      fontFamily: FONT_NAMES.SF_LIGHT,
      color: COLORS.WHITE,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_25),
    },
    blurViewStyle: {
      height: Percentage.PRECENTAGE_100,
      width: Percentage.PRECENTAGE_100,
    },
    lottieViewStyle: {
      ...BASESTYLE.inCenter,
      height: Percentage.PRECENTAGE_50,
      width: Percentage.PRECENTAGE_100,
      position: GlobalStyleValues.ABSOLUTE,
    },
    imageContainer: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_114),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_114),
      borderRadius: FixedValue.CONSTANT_VALUE_12,
    },
    imageLoader: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.center,
      ...BASESTYLE.alignCenter,
      backgroundColor: COLORS.E9EEFB,
      overflow: GlobalStyleValues.HIDDEN,
      borderRadius: FixedValue.CONSTANT_VALUE_12,
    },
  });
