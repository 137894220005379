import {View, FlatList, Keyboard} from 'react-native';
import React from 'react';
import ViewContainer from '../../layout/viewContainer';
import {FixedValue, GlobalStyleValues, TEXT} from '../../../constants';
import {HomeContext} from '../../../contextAPI/homeContext';
import {ThemeContext} from '../../../contextAPI/themeContext';
import useHomeHooks from '../../../hooks/componentHooks/useHomeHooks';
import SearchGroupListTile from '../../RenderItemTile/searchGroupListTile';
import {styles} from './styles';
import PrimaryButton from '../../button/primaryButton';
import {GroupContext} from '../../../contextAPI/groupManagementContext';
import CreateEditGroupFormModal from '../../../modals/createEditGroupModal';
import CustomModalForSelectUser from '../../../modals/customModalForSelectUser';
import useCreateEditGroupHooks from '../../../hooks/componentHooks/useCreateEditGroupHooks';
import CustomBottomListModal from '../../../modals/customBottomListModal';
import UploadGalleryImage from '../../renderSvgs/uploadGalleryImage';
import CameraImage from '../../renderSvgs/cameraImage';
import DeleteIcon from '../../renderSvgs/deleteBin';
import {isWebsite, scaleHeight, scaleWidth} from '../../../utils/responsive';
import {CREATE_GROUP_MANAGEMENT} from '../../../constants/actionTypes';
import {useAppSelector} from '../../../hooks/reduxHooks';
import {groupManagementStore} from '../../../redux/reducers/groupManagementSlice';
import AppLoader from '../../smallDesigns/appLoader';

const DistributeGroup = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  const {
    groupListSearchResult,
    searchGroupListText,
    setInitialLoader,
    initialLoader,
  } = React.useContext(HomeContext);
  const {onReachedEndGroupListSearch, callGroupListSearchAPI} = useHomeHooks();
  const {
    setOpenCreateGroupModal,
    setIsGroupEditMode,
    setGroupInitialImage,
    selectUserListModal,
    searchUserListText,
    userListResult,
    setSelectUserListModal,
    selectOrUnSelectUser,
    selectedUsers,
    openCloseProfileModal,
    setOpenCloseProfileModal,
    groupProfileImages,
    initialLoader: groupIntialLoader,
  } = React.useContext(GroupContext);

  const {
    _onChangeSearchText,
    onReachedEnd,
    callAddUserToGroupAPI,
    _openGallery,
    _takePicture,
    _removeImage,
    callUserListAPI,
  } = useCreateEditGroupHooks();
  const iconHeight = scaleHeight(FixedValue.CONSTANT_VALUE_18);
  const iconHWidth = scaleWidth(FixedValue.CONSTANT_VALUE_18);
  const {createNewGroup} = useAppSelector(groupManagementStore);
  const customUserSelectModaldata = {
    searchPlaceholder: TEXT.SEARCH_USERS,
    listData: userListResult,
    modalHeaderName: TEXT.ADD_USERS,
    modalVisible: selectUserListModal,
    value: searchUserListText,
    closeModal: setSelectUserListModal,
    onChangeText: _onChangeSearchText,
    onSelectOrUnSelectUser: selectOrUnSelectUser,
    onReachedEnd,
    callAddUserToGroupAPI,
    selectedUsers,
    initialLoader:groupIntialLoader,
  };

  const listData = [
    {
      LeftIcon: UploadGalleryImage,
      label: TEXT.UPLOAD_A_PHOTO,
      onPress: _openGallery,
      isDelete: false,
    },
    {
      LeftIcon: CameraImage,
      label: TEXT.TAKE_A_PHOTO,
      onPress: _takePicture,
      isDelete: false,
    },
  ];

  if (isWebsite()) {
    listData.splice(FixedValue.CONSTANT_VALUE_1, FixedValue.CONSTANT_VALUE_1);
  }

  const listDataWithDelete = [
    ...listData,
    {
      LeftIcon: DeleteIcon,
      label: TEXT.REMOVE,
      onPress: _removeImage,
      isDelete: true,
    },
  ];

  const customModaldata = {
    modalHeaderName: TEXT.PROFILE_IMAGE,
    height: iconHeight,
    width: iconHWidth,
    closeModal: setOpenCloseProfileModal,
    modalVisible: openCloseProfileModal,
    listData: groupProfileImages ? listDataWithDelete : listData,
  };

  //after calling create new group api response handle
  React.useEffect(() => {
    switch (createNewGroup.status) {
      case CREATE_GROUP_MANAGEMENT.SUCCESS:
        setInitialLoader(true);
        callGroupListSearchAPI(
          searchGroupListText,
          FixedValue.CONSTANT_VALUE_0
        );
        callUserListAPI(searchUserListText, FixedValue.CONSTANT_VALUE_0);
        break;
      default:
        break;
    }
  }, [createNewGroup.status]);

  return (
    <>
      <ViewContainer>
        {initialLoader && <AppLoader />}
        <View style={style.mainViewStyle}>
          <FlatList
            keyExtractor={(_, index) => `${TEXT.GROUP_LIST}-${index}`}
            keyboardShouldPersistTaps={GlobalStyleValues.HANDLED}
            data={groupListSearchResult}
            contentContainerStyle={style.flatListContentContainerStyle}
            onEndReached={onReachedEndGroupListSearch}
            onEndReachedThreshold={FixedValue.CONSTANT_VALUE_1}
            showsVerticalScrollIndicator={false}
            scrollEnabled
            renderItem={({item}) => (
              <SearchGroupListTile key={item.id} item={item} />
            )}
            onScrollBeginDrag={() => Keyboard.dismiss()}
          />
          <View style={style.buttonViewStyle}>
            <PrimaryButton
              buttonLabel={TEXT.CREATE_A_NEW_GROUP}
              buttonwidth={style.buttonStyle}
              labelStyle={style.buttonLabelstyle}
              onPress={() => {
                setGroupInitialImage('');
                setIsGroupEditMode(false);
                setOpenCreateGroupModal();
              }}
            />
          </View>
        </View>
      </ViewContainer>
      <CreateEditGroupFormModal />
      {selectUserListModal && (
        //@ts-ignore
        <CustomModalForSelectUser customModaldata={customUserSelectModaldata} />
      )}
      {openCloseProfileModal && (
        <CustomBottomListModal customModaldata={customModaldata} />
      )}
    </>
  );
};

export default React.memo(DistributeGroup);
