import React from 'react';
import {View, Text} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {TEXT} from '../../constants';
import {modalStyle} from './style';
import CustomButton from '../../components/button/customButton';
import ModalContainer from '../../components/layout/modalContainer';

interface IConfirmationModalProps {
  title: string;
  onPressYes: () => void;
  modalVisible: boolean;
  onShowHide: () => void;
  isLoading?: boolean;
}

const ConfirmationModal = (props: IConfirmationModalProps): JSX.Element => {
  const {title, onPressYes, modalVisible, onShowHide, isLoading} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = modalStyle(ColorTheme);

  return (
    <ModalContainer visible={modalVisible} onPressOutside={onShowHide}>
      <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
        <Text style={styles.wantDelete}>{title}</Text>
        <View style={styles.approveButton}>
          <CustomButton
            buttonLabel={TEXT.NO}
            buttonwidth={styles.primaryButtonStyle2}
            labelStyle={styles.primaryButtonLabelstyle1}
            buttonBackGroundColor={styles.noButtonBackGroundColor}
            onPress={onShowHide}
          />
          <CustomButton
            buttonLabel={TEXT.YES}
            buttonwidth={styles.primaryButtonStyle}
            labelStyle={styles.primaryButtonLabelstyle}
            onPress={onPressYes}
            isLoading={isLoading}
          />
        </View>
      </View>
    </ModalContainer>
  );
};

export default ConfirmationModal;
