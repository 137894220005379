import React from 'react';
import {View, Text} from 'react-native';
import {COLORS, TEXT} from '../../../constants';
import {ThemeContext} from '../../../contextAPI/themeContext';
import PrimaryButton from '../../button/primaryButton';
import DistributeTopButton from '../../renderSvgs/distributeTopButton';
import {style} from './styles';

interface IDistributeTopFooter {
  onClickDistributeTop: () => void;
  remaining: number | null;
  topQuantity?: number | null;
  isLoading?: boolean;
}

const DistributeTopFooter = (props: IDistributeTopFooter): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = style(ColorTheme);

  const {onClickDistributeTop, remaining, topQuantity, isLoading} = props;
  return (
    <View style={styles.container}>
      <View
        style={[
          styles.bgView,
          ColorTheme.color === COLORS.BLACK
            ? styles.fadeWhite
            : styles.fadeBlack,
        ]}
      />
      <View style={styles.buttonView}>
        <Text style={styles.remainingText}>
          {`Remaining: ${remaining} Qty`}
        </Text>
        <PrimaryButton
          buttonLabel={TEXT.DISTRIBUTE_NOW}
          buttonwidth={styles.primaryButtonStyle}
          labelStyle={
            remaining === topQuantity
              ? styles.primaryDisabledButtonLabelstyle
              : styles.primaryButtonLabelstyle
          }
          onPress={onClickDistributeTop}
          isDisabled={remaining === topQuantity}
          element={
            <DistributeTopButton
              color={remaining === topQuantity ? COLORS.BDBDBD : COLORS.WHITE}
            />
          }
          isLoading={isLoading}
        />
      </View>
    </View>
  );
};

export default DistributeTopFooter;
