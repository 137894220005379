import React from 'react';
import {View, Text, FlatList} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';

import {ThemeContext} from '../../../contextAPI/themeContext';
import {ProfileApprovelStyle} from './profileApprovel.style';
import {FixedValue, TEXT} from '../../../constants';
import useChildApprovelRequestListHooks from '../../../hooks/componentHooks/useChildApprovelRequestListHooks';
import {childProfileApproveStore} from '../../../redux/reducers/childApproveSlice';
import {useAppSelector} from '../../../hooks/reduxHooks';
import {RouteProp, useIsFocused, useRoute} from '@react-navigation/native';
import {
  calculateAgeFromDate,
  formatUTCTimeToLocalTime,
  getInitials,
} from '../../../utils/helperFunctions';
import ProfileApprovelButton from './profileApprovelButton';
import {APPROVE_OR_REJECT} from '../../../constants/actionTypes';
import {goBack} from '../../../services/navigationService';
import {RootStackParamList} from '../../../types/navigationType';
import {ProfileTabContext} from '../../../contextAPI/profileTabContext';
import {timeformat} from '../../../constants/enum';

const ProfileApprovelContent = () => {
  const isFocused = useIsFocused();
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = ProfileApprovelStyle(ColorTheme);
  const {callChildApproveListAPI} = useChildApprovelRequestListHooks();
  const route = useRoute<RouteProp<RootStackParamList, 'ChildProfile'>>();
  const item = route?.params?.item;
  const {childApproveOrReject, approveOrReject} = useAppSelector(
    childProfileApproveStore
  );
  const {profileTabPulldownRefresh, setProfileTabPulldownRefresh} =
    React.useContext(ProfileTabContext);
  React.useEffect(() => {
    if (isFocused) {
      callChildApproveListAPI(FixedValue.CONSTANT_VALUE_0);
    }
  }, [isFocused]);
  const handleRefresh = React.useCallback(() => {
    setProfileTabPulldownRefresh(true);
    callChildApproveListAPI(FixedValue.CONSTANT_VALUE_0);
  }, [profileTabPulldownRefresh]);
  const responseArray = childApproveOrReject?.success?.data.results;

  React.useEffect(() => {
    switch (approveOrReject.status) {
      case APPROVE_OR_REJECT.SUCCESS:
        goBack();
        break;
      case APPROVE_OR_REJECT.FAILED:
        break;
      default:
        break;
    }
  }, [approveOrReject.status]);
  const renderFlatList = () => {
    let data = responseArray?.filter(child => child.child.id === item.id);
    const fullName = item?.firstName + ' ' + item?.lastName ?? '';
    return (
      <FlatList
        style={styles.flex1}
        data={data}
        refreshControl={
          <RefreshControl
            refreshing={profileTabPulldownRefresh}
            onRefresh={handleRefresh}
          />
        }
        keyExtractor={(_, index) => `${TEXT.CHILD_LIST}-${index}`}
        showsVerticalScrollIndicator={false}
        renderItem={({item}) => (
          <>
            <View style={styles.mainView}>
              <View
                style={[styles.imageView, styles.backgroundColorPlaceholder]}
              >
                <Text style={styles.profileNameText}>
                  {getInitials(fullName)}
                </Text>
              </View>
              <View style={styles.textView}>
                <View style={[styles.mainView, styles.maxWidthForText]}>
                  <Text style={styles.titleText}>
                    {item?.child?.firstName} {item?.child?.lastName}
                    <Text style={styles.titleTextan}> {TEXT.SENT_YOU_AN} </Text>
                    <Text style={styles.titleText}>
                      {' '}
                      {TEXT.APPROVAL_REQUEST}
                    </Text>
                  </Text>
                </View>
                <Text style={styles.titleText1}>{TEXT.PROFILE_NEED}</Text>
                <Text style={styles.titleTextAge}>
                  {calculateAgeFromDate(item?.child?.dob)}
                  {calculateAgeFromDate(item?.child?.dob) >=
                  FixedValue.CONSTANT_VALUE_1
                    ? TEXT.YEARS_OLD
                    : TEXT.AGE}
                </Text>
                <Text style={styles.titleTextDay}>
                  {formatUTCTimeToLocalTime(
                    item?.creationDate ?? '',
                    timeformat.MMMDDYY
                  )}
                </Text>
                <ProfileApprovelButton item={item} />
              </View>
            </View>
            <View style={styles.bottomLineStyle} />
          </>
        )}
      />
    );
  };

  return <View style={styles.flatListView}>{renderFlatList()}</View>;
};

export default React.memo(ProfileApprovelContent);
