import 'regenerator-runtime/runtime';
import {combineReducers, configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import ExpoFileSystemStorage from 'redux-persist-expo-filesystem';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';

import authSlice from './reducers/authSlice';
import rootSaga from './saga';
import verifyOtpSlice from './reducers/verifyOtp';
import createNewPasswordSlice from './reducers/resetPasswordSlice';
import forgotPasswordSlice from './reducers/forgotPasswordSlice';
import logoutSlice from './reducers/logoutslice';
import editorSlice from './reducers/editorSlice';
import searchTabSlice from './reducers/searchTabSlice';
import collectionSlice from './reducers/collectionSlice';
import userProfileSlice from './reducers/userProfileSlice';
import homeSlice from './reducers/homeSlice';
import groupManagementSlice from './reducers/groupManagementSlice';
import tradableTopsSlice from './reducers/tradableTopsSlice';
import childApproveList from './reducers/childApproveSlice';
import updateProfileSlice from './reducers/updateProfileSlice';
import tradeTabSlice from './reducers/tradeTabSlice';
import childProfileSlice from './reducers/childProfileSlice';
import notificationSlice from './reducers/notificationSlice';
import appTopSlice from './reducers/appTopSlice';
import {isWebsite} from '../utils/responsive';
import trackingListSlice from './reducers/trackingListSlice';
import TrackingCancelSlice from './reducers/trackingCancelSlice';
import trackingDetailSlice from './reducers/trackingDetailSlice';
import sendReminderSlice from './reducers/sendReminderSlice';
import tutorialListSlice from './reducers/tutorialListSlice';
import sendReminderAllSlice from './reducers/sendReminderAllSlice';

const saga = createSagaMiddleware();

const rootPersistConfig = {
  key: 'root',
  storage: isWebsite() ? storage('myDB') : ExpoFileSystemStorage,
  whitelist: ['appTops'],
};

const rootReducer = combineReducers({
  auth: authSlice,
  verifyOtp: verifyOtpSlice,
  newPassword: createNewPasswordSlice,
  forgotPassword: forgotPasswordSlice,
  logout: logoutSlice,
  editor: editorSlice,
  searchTopStore: searchTabSlice,
  createCollection: collectionSlice,
  userProfileStore: userProfileSlice,
  inProgressTopsList: homeSlice,
  groupManagement: groupManagementSlice,
  tradableTops: tradableTopsSlice,
  childProfileApproveStore: childApproveList,
  updateProfile: updateProfileSlice,
  tradeTab: tradeTabSlice,
  childProfileSlice: childProfileSlice,
  notificationSlice: notificationSlice,
  appTops: appTopSlice,
  trackingList: trackingListSlice,
  trackingCancel: TrackingCancelSlice,
  trackingDetail: trackingDetailSlice,
  sendReminder: sendReminderSlice,
  tutorialList : tutorialListSlice,
  sendReminderAll: sendReminderAllSlice,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [saga],
});
export const persistor = persistStore(store);
saga.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
