import React from 'react';
import {Modal, Pressable, View} from 'react-native';
import StyleSheet from 'react-native-media-query';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {IModalContainerProps} from '../../types/componentTypes/layoutType';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {isWebsite} from '../../utils/responsive';

const ModalContainerStyle = (theme: IColorTheme, backgroundColor?: string) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      backgroundColor: theme.backgroundColor,
    },
    contentContainerStyle: {
      width: Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
    },
    outterBtn: {
      ...BASESTYLE.absolutePosition,
      zIndex: FixedValue.CONSTANT_VALUE_MIN_1,
      width: Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      backgroundColor: backgroundColor ?? COLORS.LIGHT_BLACK,
    },
    innerView: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.selfCenter,
      justifyContent: GlobalStyleValues.FLEX_END,
      width: isWebsite()
        ? FixedValue.CONSTANT_VALUE_500
        : Percentage.PRECENTAGE_100,
      alignItems: GlobalStyleValues.CENTER,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
      backgroundColor: theme.loaderBackgroundColor,
    },
  });

const ModalContainer = (props: IModalContainerProps) => {
  const {children, visible, onPressOutside, backgroundColor} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = ModalContainerStyle(ColorTheme, backgroundColor);
  return (
    <Modal transparent visible={visible} onRequestClose={onPressOutside}>
      <KeyboardAwareScrollView
        style={BASESTYLE.flex1}
        alwaysBounceHorizontal={false}
        alwaysBounceVertical={false}
        bounces={false}
        overScrollMode="never"
        contentContainerStyle={styles.contentContainerStyle}
        keyboardShouldPersistTaps={GlobalStyleValues.HANDLED}
      >
        <View style={styles.innerView} dataSet={{media: ids.innerView}}>
          <Pressable style={styles.outterBtn} onPress={onPressOutside} />
          {children}
        </View>
      </KeyboardAwareScrollView>
    </Modal>
  );
};

export default React.memo(ModalContainer);
