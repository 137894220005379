import React from 'react';
import {View} from 'react-native';
import {RouteProp, useRoute} from '@react-navigation/native';
import TitleWithButton from '../../components/header/titleWithButton';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import {HomeContext} from '../../contextAPI/homeContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import {
  capitalizeFirstLetter,
  getRightSideHeaderName,
} from '../../utils/helperFunctions';
import AllTopsResultList from './allTopsList';
import {allTopsStyle} from './seeAll.styles';
import {FixedValue, TEXT} from '../../constants';
import {RootStackParamList} from '../../types/navigationType';
import {SeeAll_ScreenMode} from '../../constants/enum';
import useSeeAllTopsHooks from '../../hooks/componentHooks/useSeeAllTopsHooks';

const AllTopsScreen = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const route = useRoute<RouteProp<RootStackParamList, 'AllTopsScreen'>>();
  const {styles, ids} = allTopsStyle(ColorTheme);
  const {collectionsTops, seeAllScreenMode, isProfileSwitchToChild} =
    React.useContext(HomeContext);
  const {changeViewToSelectOnSeeAll} = useSeeAllTopsHooks();
  const {
    collectionModel,
    showUncategorizedListId,
    totalTopsInUncategorizedCollections,
    isEmptyCollection,
  } = route.params;

  const collectionTopResult: string = collectionsTops?.every(
    getRightSideHeaderName
  )
    ? TEXT.UNSELECT_ALL
    : TEXT.SELECT_ALL;

  const isUnCategoryValid: boolean =
    seeAllScreenMode === SeeAll_ScreenMode.VIEW;

  const getResult: string = isUnCategoryValid
    ? TEXT.SELECT
    : collectionTopResult;

  const getHeaderName = React.useCallback((): string | undefined => {
    if (
      seeAllScreenMode === SeeAll_ScreenMode.VIEW ||
      (seeAllScreenMode === SeeAll_ScreenMode.MOVE_TOPS_TO_OTHER_COLLECTION &&
        !isEmptyCollection)
    ) {
      return `${capitalizeFirstLetter(collectionModel.name ?? '')} (${
        totalTopsInUncategorizedCollections ?? FixedValue.CONSTANT_VALUE_0
      })`;
    } else {
      return `${TEXT.UNCATEGORIZED} (${totalTopsInUncategorizedCollections})`;
    }
  }, [collectionsTops, collectionModel, totalTopsInUncategorizedCollections]);

  const topsHeader: string | undefined = getHeaderName();

  return (
    <View style={styles.container} dataSet={{media: ids.container}}>
      <View style={styles.topHeader}>
        <TitleWithButton
          title={topsHeader ?? ''}
          rightSideHeaderText={
            collectionsTops?.length > FixedValue.CONSTANT_VALUE_0 &&
            !isProfileSwitchToChild
              ? getResult
              : ''
          }
          onPress={() => changeViewToSelectOnSeeAll()}
        />
      </View>
      <AllTopsResultList
        collectionModel={collectionModel}
        displayMode={seeAllScreenMode}
        showUncategorizedListId={showUncategorizedListId}
        headerValue={getResult}
      />
    </View>
  );
};

const AllTops = (): JSX.Element => (
  <SafeAreaContainer>
    <AllTopsScreen />
  </SafeAreaContainer>
);

export default React.memo(AllTops);
