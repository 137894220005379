import React from 'react';
import {
  ICreatePasswordContext,
  ICreatePasswordProvider,
} from '../types/contextTypes/createPwdContext';

const CreatePasswordContext = React.createContext<ICreatePasswordContext>({
  showPassword: true,
  setShowPassword: () => {},
  showConfirmPassword: true,
  setShowConfirmPassword: () => {},
  isBtnLoading: false,
  setIsBtnLoading: _val => {},
});

const CreatePasswordProvider = (
  props: ICreatePasswordProvider
): JSX.Element => {
  const [showPassword, setShowPassword] = React.useState<boolean>(true);
  const [isBtnLoading, setIsBtnLoading] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    React.useState<boolean>(true);

  const _setShowPassword = React.useCallback((): void => {
    setShowPassword((prev: boolean) => !prev);
  }, [showPassword]);

  const _setShowConfirmPassword = React.useCallback((): void => {
    setShowConfirmPassword((prev: boolean) => !prev);
  }, [showConfirmPassword]);

  const _setIsBtnLoading = React.useCallback(
    (value: boolean): void => {
      setIsBtnLoading(value);
    },
    [isBtnLoading]
  );

  const values = React.useMemo(
    (): ICreatePasswordContext => ({
      showPassword,
      showConfirmPassword,
      isBtnLoading,
      setIsBtnLoading: _setIsBtnLoading,
      setShowPassword: _setShowPassword,
      setShowConfirmPassword: _setShowConfirmPassword,
    }),
    [
      showPassword,
      isBtnLoading,
      setIsBtnLoading,
      showConfirmPassword,
      setShowPassword,
      setShowConfirmPassword,
    ]
  );
  return (
    <CreatePasswordContext.Provider value={values}>
      {props.children}
    </CreatePasswordContext.Provider>
  );
};

export {CreatePasswordContext, CreatePasswordProvider};
