import StyleSheet from 'react-native-media-query';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  Percentage,
} from '../../../constants';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const TopReceivedStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    receivedMainContainer: {
      ...BASESTYLE.selfCenter,
      width: Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
    },
    mainContainer: {
      ...BASESTYLE.selfCenter,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_10,
        width: Percentage.PRECENTAGE_100,
      },
    },
    buttonView: {
      ...BASESTYLE.row,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_15),
    },
    primaryButtonStyle: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_38),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_121),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      marginRight: FixedValue.CONSTANT_VALUE_10,
    },
    primaryButtonStyle2: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_38),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_118),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
    },
    primaryButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    primaryButtonLabelstyle1: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.F54336,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    noButtonBackGroundColor: {
      backgroundColor: COLORS.FDE4E1,
    },
    noButtonBackGroundColor1: {
      backgroundColor: COLORS._4EAF51,
    },
    bottomLineStyle: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_1),
      backgroundColor: COLORS._E6E6E6,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_17),
    },
    flatListContentContainerStyle: {
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_50),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    approveButton: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_38),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_111),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      marginRight: FixedValue.CONSTANT_VALUE_10,
    },
    rejectButton: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_38),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_100),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      marginRight: FixedValue.CONSTANT_VALUE_10,
    },
  });
