import React from 'react';
import {View, Text, Pressable, ScrollView} from 'react-native';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {profileStyle} from './profile.style';
import {ProfileButtonArray} from '../../../constants/appConst';
import RightArrow from '../../../components/renderSvgs/rightArrow';
import {BASESTYLE, FixedValue, ROUTE_NAME, TEXT} from '../../../constants';
import BadgeCount from '../../../components/smallDesigns/badgeCount';
import {
  getCurrentActiveRoute,
  navigate,
} from '../../../services/navigationService';
import {useAppSelector} from '../../../hooks/reduxHooks';
import {userProfileStore} from '../../../redux/reducers/userProfileSlice';
import {PROFILE_OPTIONS_TEXT} from '../../../constants/textMessage';
import {ProfileTabContext} from '../../../contextAPI/profileTabContext';
import {ProfileBtnType} from '../../../types/componentTypes/userProfileTypes';

const ProfileBottomBtn = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = profileStyle(ColorTheme);
  const {userData} = useAppSelector(userProfileStore);
  const {setModalVisibleDeleteUser, setModalVisibleLogout} =
    React.useContext(ProfileTabContext);
  const UserDetail = userData.success;
  const currectActiveRoute: string | undefined = getCurrentActiveRoute();
  const showLogOutButton =
    currectActiveRoute && currectActiveRoute === ROUTE_NAME.PROFILE_TAB;

  const getBadgeCountForProfileRequest = () => {
    const sum = Object.values(UserDetail?.pendingRequestsCount ?? {}).reduce(
      (total, value) => total + value,
      FixedValue.CONSTANT_VALUE_0
    );
    return UserDetail?.accountApprovalRequests
      ? UserDetail?.accountApprovalRequests + Number(sum)
      : Number(sum);
  };
  const navigateTo = (item: ProfileBtnType) => {
    const {label, onPress, webUrl} = item;
    if (
      label === PROFILE_OPTIONS_TEXT.TERMS_CONDITIONS ||
      label === PROFILE_OPTIONS_TEXT.PRIVACY_POLICY
    ) {
      const headerTitle = label;
      navigate(ROUTE_NAME.WEB_VIEW_SCREEN, {headerTitle, webUrl});
    } else {
      navigate(onPress);
    }
  };

  const onPressButton = React.useCallback((item: ProfileBtnType): void => {
    if (item.label === TEXT.LOGOUT) {
      setModalVisibleLogout(true);
    } else if (item.label !== PROFILE_OPTIONS_TEXT.DELETE_ACCOUNT) {
      navigateTo(item);
    } else {
      setModalVisibleDeleteUser(true);
    }
  }, []);

  return (
    <ScrollView style={styles.profileBtnView}>
      {UserDetail?.role === TEXT.ROLE_PARENT && (
        <Pressable
          style={styles.profileSingleBtnView}
          key={'Parent/Guardian Management'}
          onPress={() => navigate(ROUTE_NAME.CHILD_LIST_SCREEN)}
        >
          <Text style={styles.profileBtnLabelText}>
            {'Parent/Guardian Management'}
          </Text>
          <View style={BASESTYLE.inRow}>
            {getBadgeCountForProfileRequest() > FixedValue.CONSTANT_VALUE_0 && (
              <BadgeCount
                count={getBadgeCountForProfileRequest()}
                badgeStyle={styles.badgeStyle}
              />
            )}
            <RightArrow stroke={ColorTheme.color} />
          </View>
        </Pressable>
      )}
      {ProfileButtonArray.map((item: ProfileBtnType) => (
        <>
          {showLogOutButton ? (
            <Pressable
              style={[
                styles.profileSingleBtnView,
                item.label === PROFILE_OPTIONS_TEXT.DELETE_ACCOUNT
                  ? styles.customStyle
                  : item.label === TEXT.LOGOUT
                  ? styles.customStyle2
                  : null,
              ]}
              key={item.label}
              onPress={() => onPressButton(item)}
            >
              <Text
                style={[
                  styles.profileBtnLabelText,
                  item.label === PROFILE_OPTIONS_TEXT.DELETE_ACCOUNT ||
                  item.label === TEXT.LOGOUT
                    ? styles.customProfileBtnLabelText
                    : null,
                ]}
              >
                {item.label}
              </Text>
              {item.label !== PROFILE_OPTIONS_TEXT.DELETE_ACCOUNT &&
              item.label !== TEXT.LOGOUT ? (
                <View style={BASESTYLE.inRow}>
                  <RightArrow stroke={ColorTheme.color} />
                </View>
              ) : null}
            </Pressable>
          ) : null}
        </>
      ))}
      <View style={styles.extraView}></View>
    </ScrollView>
  );
};

export default React.memo(ProfileBottomBtn);
