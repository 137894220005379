import React from 'react';
import {Text, Pressable, Keyboard} from 'react-native';
import {TEXT} from '../../constants';
import {SearchTabContext} from '../../contextAPI/SearchTabBarContext';
import {ThemeContext} from '../../contextAPI/themeContext';

import NoSearchResult from '../renderSvgs/noSearchResult';
import {styles} from './emptyListView.style';

interface ISearchTabEmptyList {
  showEmptyResutText: boolean;
  emptyViewContainer?: any;
}

const SearchTabEmptyList = ({
  showEmptyResutText,
  emptyViewContainer,
}: ISearchTabEmptyList): JSX.Element => {
  const {searchText} = React.useContext(SearchTabContext);
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  const isTextSearch = searchText !== '';
  const conatainer = emptyViewContainer ? emptyViewContainer : style.searchView;

  const RenderText = React.useMemo((): JSX.Element => {
    if (showEmptyResutText)
      return (
        <Text style={style.searchText}>
          {isTextSearch ? TEXT.NO_RESULT_FOR : TEXT.SEARCH}
          <Text style={style.searchBoldText}>
            {isTextSearch ? searchText : TEXT.SEARCH_TOPS_AND_USERS}
          </Text>
          "
        </Text>
      );
    return <Text style={style.searchText}>{TEXT.SEARCH_TOP}</Text>;
  }, [searchText, showEmptyResutText]);

  return (
    <Pressable style={conatainer} onPress={() => Keyboard.dismiss()}>
      <NoSearchResult />
      {RenderText}
    </Pressable>
  );
};

export default React.memo(SearchTabEmptyList);
