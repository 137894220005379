import {FixedValue} from './enum';
import {
  BEBAS_NEUE,
  extraFontOriginalNames,
  ITIM,
  JOSEFIN_SANS,
  MONTSERRAT,
  PACIFICO,
  ROBOTO,
} from './fonts';
import {ROUTE_NAME} from './routeNames';
import {PROFILE_OPTIONS_TEXT, TEXT} from './textMessage';

export const StorageConstants = {
  ENVIRONMENT: 'environment',
  USER_APP_STATE: 'userAppState',
  USER_PROFILE_DATA: 'userProfileData',
  USER_TOKENS: 'userToken',
  SWITCH_USER_DETAIL: 'switchUserDetail',
  EXPO_TOKEN: 'expoToken',
};

export const ENVIRONMENT = {
  DEV: 'dev',
  STAGE: 'stage',
  QA: 'qa',
  PROD: 'prod',
};

export const StoreConstants = {
  AUTH_STORE: 'authStore',
  CHAT_STORE: 'chatStore',
  CHECK_USERNAME: 'checkUsername',
  VERIFY_OTP_STORE: 'verifyOtp',
  CHECK_PARENT_USERNAME: 'parentUserName',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESEND_CODE_STORE: 'resendCode',
  FORGOT_PASSWORD: 'forgotPassword',
  CHANGE_PASSWORD: 'changePassword',
  LOGOUT: 'logOut',
  EDITOR: 'editorStore',
  SEARCH_TAB: 'searchTopStore',
  REGISTER: 'userRegister',
  CREATE_COLLECTION: 'createCollection',
  COLLECTION_TOP_LISTS: 'collectionTopLists',
  USER_PROFILE: 'userProfileStore',
  GET_IN_PROGRESS_TOPS: 'getInProgressTops',
  GROUP_MANAGEMENT: 'groupManagement',
  TRADABLE_TOPS: 'tradableTops',
  CHILD_APPROVEL_REQUEST_LIST: 'childApprovelRequestList',
  APPROVE_OR_REJECT: 'approveOrReject',
  UPDATE_PROFILE: 'updateProfile',
  TRADE_RECEVIED_SEND_OFFER: 'tradeReceivedSendOffer',
  CHILD_PROFILE: 'childProfile',
  NOTIFICATION: 'notification',
  APP_TOPS_OBJECT: 'appTopObject',
  TRACKING_LIST: 'TrackingList',
  TRACKING_CANCEL: 'TrackingCancel',
  TRACKING_DETAIL: 'TrackingDetail',
  SEND_REMINDER: 'sendReminder',
  TUTORIALS_VIDEO: 'tutorials',
  SEND_REMINDER_ALL: 'sendReminderAll',
};

export const HttpStatusCode = {
  UNAUTHORISED: 401,
  SUCCESS_REQUEST: 200,
  SUCCESS_CODE_202: 202,
  SUCCESS_CODE_201: 201,
  SUCCESS_CODE_204: 204,
  UNAUTHORISED_400: 400,
  UNAUTHORISED_409: 409,
  SUCCESS_CODE_205: 205,
  SERVER_CODE_502: 502,
  DELETE_ACCOUNT: 404,
};

export const TIMEOUT_MS = 300000;

export const ProfileButtonArray = [
  {
    label: PROFILE_OPTIONS_TEXT.TUTORIALS_VIDEO,
    badgeCount: FixedValue.CONSTANT_VALUE_0,
    onPress: ROUTE_NAME.TUTORIAL_VIDEO,
  },
  {
    label: PROFILE_OPTIONS_TEXT.BLOCK_USER,
    badgeCount: FixedValue.CONSTANT_VALUE_0,
    onPress: ROUTE_NAME.BLOCK_USER_LIST,
  },
  {
    label: PROFILE_OPTIONS_TEXT.GROUP_MANAGEMENT,
    badgeCount: FixedValue.CONSTANT_VALUE_0,
    onPress: ROUTE_NAME.GROUP_MANAGEMENT_SCREEN,
  },
  {
    label: PROFILE_OPTIONS_TEXT.CHANGE_PASSWORD,
    badgeCount: FixedValue.CONSTANT_VALUE_0,
    onPress: ROUTE_NAME.CHANGE_PASSWORD,
  },
  {
    label: PROFILE_OPTIONS_TEXT.HELP_SUPPORT,
    badgeCount: FixedValue.CONSTANT_VALUE_0,
    onPress: ROUTE_NAME.HELP_SUPPORT,
  },
  {
    label: PROFILE_OPTIONS_TEXT.TERMS_CONDITIONS,
    badgeCount: FixedValue.CONSTANT_VALUE_0,
    onPress: ROUTE_NAME.WEB_VIEW_SCREEN,
    webUrl: 'https://d2gt0lv09tntxf.cloudfront.net/term-and-service.html',
  },
  {
    label: PROFILE_OPTIONS_TEXT.PRIVACY_POLICY,
    badgeCount: FixedValue.CONSTANT_VALUE_0,
    onPress: ROUTE_NAME.WEB_VIEW_SCREEN,
    webUrl: 'https://d2gt0lv09tntxf.cloudfront.net/privacy-policy.html',
  },
  {
    label: PROFILE_OPTIONS_TEXT.FAVORITES,
    badgeCount: FixedValue.CONSTANT_VALUE_0,
    onPress: ROUTE_NAME.FAVORITES_LIST,
  },
  {
    label: TEXT.LOGOUT,
    badgeCount: FixedValue.CONSTANT_VALUE_0,
    onPress: ROUTE_NAME.WEB_VIEW_SCREEN,
  },
  {
    label: PROFILE_OPTIONS_TEXT.DELETE_ACCOUNT,
    badgeCount: FixedValue.CONSTANT_VALUE_0,
    onPress: ROUTE_NAME.WEB_VIEW_SCREEN,
  },
];

export const ChildProfileButtonArray = [
  {
    label: TEXT.PROFILE_REQ,
    badgeCount: FixedValue.CONSTANT_VALUE_1,
    onPress: ROUTE_NAME.PROFILE_APPROVEL,
  },
  {
    label: TEXT.TOP_TRADING,
    badgeCount: FixedValue.CONSTANT_VALUE_0,
    onPress: ROUTE_NAME.TOP_TRADING,
  },
  {
    label: TEXT.TOP_BUYING_APPROVAL,
    badgeCount: FixedValue.CONSTANT_VALUE_0,
    onPress: ROUTE_NAME.TOP_BUYING_APPROVAL,
  },
  {
    label: TEXT.SHIFTING_TO_TRADEABLE,
    badgeCount: FixedValue.CONSTANT_VALUE_0,
    onPress: ROUTE_NAME.SHIFTING_TO_TRADEABLE,
  },
  {
    label: TEXT.DISTRIBUTING_TOP_REQUEST,
    badgeCount: FixedValue.CONSTANT_VALUE_0,
    onPress: ROUTE_NAME.DISTRIBUTING_TOP,
  },
  {
    label: TEXT.GROUP_INVITATION,
    badgeCount: FixedValue.CONSTANT_VALUE_0,
    onPress: ROUTE_NAME.CHILD_GROUP_INVITATION,
  },
];

export const FONT_LIST = [
  {
    label: extraFontOriginalNames.JosefinSans,
    font: JOSEFIN_SANS,
  },
  {
    label: extraFontOriginalNames.Montserrat,
    font: MONTSERRAT,
  },
  {
    label: extraFontOriginalNames.Roboto,
    font: ROBOTO,
  },
  {
    label: extraFontOriginalNames.Pacifico,
    font: PACIFICO,
  },
  {
    label: extraFontOriginalNames.BebasNeue,
    font: BEBAS_NEUE,
  },
  {
    label: extraFontOriginalNames.Itim,
    font: ITIM,
  },
];

export const HelpAndSupportTileData = [
  {
    label: TEXT.HELP_AND_SUPPORT,
    badgeCount: FixedValue.CONSTANT_VALUE_0,
    onPress: ROUTE_NAME.WEB_VIEW_SCREEN,
  },
  {
    label: TEXT.COPYRIGHT,
    badgeCount: FixedValue.CONSTANT_VALUE_0,
    onPress: ROUTE_NAME.WEB_VIEW_SCREEN,
    webUrl: 'https://d2gt0lv09tntxf.cloudfront.net/copyright-policy.html',
  },
  {
    label: TEXT.ETIQUTTE,
    badgeCount: FixedValue.CONSTANT_VALUE_0,
    onPress: ROUTE_NAME.WEB_VIEW_SCREEN,
    webUrl: 'https://d2gt0lv09tntxf.cloudfront.net/etiquette-policy.html',
  },
  {
    label: TEXT.FAQS,
    badgeCount: FixedValue.CONSTANT_VALUE_0,
    onPress: ROUTE_NAME.WEB_VIEW_SCREEN,
    webUrl: 'https://d2gt0lv09tntxf.cloudfront.net/faq.html',
  },
];

export const FONT_B64_PRE = 'data:application/font-woff;charset=utf-8;base64,';
