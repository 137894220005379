import React from 'react';
import {View} from 'react-native';
import ModalContainer from '../../components/layout/modalContainer';
import {TopDetailContext} from '../../contextAPI/topDetailContext';
import {ThemeContext} from '../../contextAPI/themeContext';

import {optionsModalStyle} from './styles';
import OptionsModalContent from './content';

const TopOptionsInCollectionModal = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = optionsModalStyle(ColorTheme);
  const {openOptionsModal, setOpenOptionsModal} =
    React.useContext(TopDetailContext);
  return (
    <ModalContainer
      visible={openOptionsModal}
      onPressOutside={setOpenOptionsModal}
    >
      <View style={styles.subContainer}>
        <OptionsModalContent />
      </View>
    </ModalContainer>
  );
};

export default TopOptionsInCollectionModal;
