import React from 'react';
import ModalButton from '../../components/button/modalButton';
import {COLORS, FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import editIcon from '../../components/renderSvgs/editPencil';
import addIcon from '../../components/renderSvgs/plusIcon';
import DeleteIcon from '../../components/renderSvgs/deleteBin';
import {HomeContext} from '../../contextAPI/homeContext';
import {CreateCollectionContext} from '../../contextAPI/createCollectionContext';
import {navigate} from '../../services/navigationService';
import {EditCollectionModalProps} from '../../types/reducerTypes/searchTabStoreType';
import {SeeAll_ScreenMode} from '../../constants/enum';
import {Pressable} from 'react-native';
import ModalBack from '../../components/renderSvgs/modalBack';
import { ThemeContext } from '../../contextAPI/themeContext';
import { editCollectionModalStyle } from './editCollectionModal.styles';

const EditCollectionModalField = (
  props: EditCollectionModalProps
): JSX.Element => {
  const {
    setOpenCreateModal,
    openCreateModal,
    setOpenDeleteCollectionModal,
    openDeleteCollectionModal,
    setSeeAllScreenMode,
  } = React.useContext(HomeContext);
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = editCollectionModalStyle(ColorTheme);

  const {setOpenCreateCollectionModal, collectionData} = React.useContext(
    CreateCollectionContext
  );
  const {showUncategorizedListId, totalTopsInUncategorizedCollections} = props;

  // handle adding new tops to collection
  const handleAddNewTops = React.useCallback((): void => {
    setSeeAllScreenMode(SeeAll_ScreenMode.MOVE_TOPS_TO_THIS_COLLECTION);
    navigate(ROUTE_NAME.SEE_ALL_TOPS_SCREEN, {
      collectionModel: collectionData,
      showUncategorizedListId: showUncategorizedListId,
      totalTopsInUncategorizedCollections: totalTopsInUncategorizedCollections,
    });
    setOpenCreateModal();
  }, [openCreateModal]);

  const handleEditCollection = React.useCallback((): void => {
    // handle editing collection name
    setOpenCreateModal();
    setTimeout(() => {
      setOpenCreateCollectionModal();
    }, FixedValue.CONSTANT_VALUE_500);
  }, [openCreateModal]);

  const handelCancelOption = React.useCallback((): void => {
    setOpenCreateModal();
  }, [openCreateModal]);

  const handelDeleteOption = React.useCallback((): void => {
    setOpenCreateModal();
    setOpenDeleteCollectionModal();
  }, [openDeleteCollectionModal]);

  return (
    <>
      <Pressable style={styles.alignEnd} onPress={handelCancelOption}>
        <ModalBack />
      </Pressable>
      <ModalButton
        LeftIcon={addIcon}
        label={TEXT.ADD_NEW_TOP}
        onPress={handleAddNewTops}
      />
      <ModalButton
        LeftIcon={editIcon}
        label={TEXT.EDIT_COLLECTION_NAME}
        onPress={handleEditCollection}
      />
      <ModalButton
        LeftIcon={() => <DeleteIcon stroke={COLORS._FF3D3D} />}
        label={TEXT.DELETE_COLLECTION}
        isDelete={true}
        onPress={handelDeleteOption}
      />
    </>
  );
};

export default React.memo(EditCollectionModalField);
