import React, {PropsWithChildren} from 'react';
import {StyleSheet, View} from 'react-native';
import {BASESTYLE} from '../../constants';

const styles = StyleSheet.create({
  view: {
    ...BASESTYLE.flex1,
  },
});

const ViewContainer = (props: PropsWithChildren) => {
  const {children} = props;

  return <View style={styles.view}>{children}</View>;
};

export default ViewContainer;
