import React, {useCallback, useState} from 'react';
import {useFormik} from 'formik';
import {ERROR_MESSAGES, FixedValue, TEXT} from '../../constants';
import {EmailSchema, MobilNoSchema} from '../../utils/formikSchema';
import {useAppDispatch} from '../reduxHooks';
import {SignUpContext} from '../../contextAPI/signUpContext';
import {HomeContext} from '../../contextAPI/homeContext';
import {
  checkForMultipleAccountStart,
  distributeViaCSVStart,
  distributeViaEmailOrPhoneStart,
} from '../../redux/reducers/homeSlice';
import {
  EmailMobileType,
  IUserMulipleData,
} from '../../types/reducerTypes/searchTabStoreType';
import {pickerOpen} from '../../utils/filePickerUtils';
import {
  _ToastHandler,
  showAlertForExceedQuantity,
} from '../../utils/helperFunctions';
import useAwsS3 from '../libraryHooks/useAwsS3';
import {
  ChildListModel,
  IUserProfileData,
} from '../../types/reducerTypes/userProfileTypes';

export type DistributeViaMailOrPhone = {email_mobile: string};

const useDistributeHooks = () => {
  const dispatch = useAppDispatch();
  const {uploadCSV, getCSVDownloadURL} = useAwsS3();
  const [isButtonDisabled, setIsButtonDisabled] = React.useState<boolean>(true);
  const [totalDistributeTop, setTotalDistributeTop] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_0
  );
  const [topDistributorList, setTopDistributorList] = React.useState<
    EmailMobileType[]
  >([]);
  const [isEmailSelected, setIsEmailSelected] = React.useState<boolean>(true);
  const {selectedCountry} = React.useContext(SignUpContext);
  const {selectedTopData} = React.useContext(HomeContext);
  const [selectedFile, setSelectedFile] = useState<{
    uri: string;
    type: string;
    name: string;
    size: number;
  } | null>(null);
  const [uploadProgress, setUploadProgress] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_0
  );
  const [csvUploadLoader, setCsvUploadLoader] = React.useState<boolean>(false);
  const [showMultipleUserModal, setShowMultipleUserModal] =
    React.useState<boolean>(false);
  const [multipleUserListData, setMultipleUserListData] = React.useState<
    IUserMulipleData[] | null
  >(null);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
    isValid,
    setFieldTouched,
  } = useFormik<DistributeViaMailOrPhone>({
    validationSchema: isEmailSelected ? EmailSchema : MobilNoSchema,
    initialErrors: {
      email_mobile: ERROR_MESSAGES.REQUIRED,
    },
    initialTouched: {
      email_mobile: false,
    },
    initialValues: {
      email_mobile: '',
    },
    onSubmit: () => {
      addUserToDistributorList();
    },
  });
  const InviteViaEmailOrPhoneError: string =
    touched.email_mobile && errors.email_mobile ? errors.email_mobile : '';

  const inviteTopDistribute = React.useCallback(() => {
    const selectUserWithQuantity = topDistributorList.filter(
      item => item.quantity > FixedValue.CONSTANT_VALUE_0
    );
    const payload = {
      topId: selectedTopData?.id,
      userQuantity: selectUserWithQuantity,
    };
    dispatch(distributeViaEmailOrPhoneStart(payload));
  }, [dispatch, topDistributorList, selectedTopData]);

  React.useEffect(() => {
    // Disable the button if the form is not valid
    setIsButtonDisabled(!isValid);
  }, [isValid]);

  React.useEffect(() => {
    if (values.email_mobile.trim().length > FixedValue.CONSTANT_VALUE_2) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [values.email_mobile]);

  const addUserToDistributorList = React.useCallback(() => {
    const updatedTotal = topDistributorList.reduce(
      (total, obj) => obj.quantity + total,
      FixedValue.CONSTANT_VALUE_0
    );
    if (
      updatedTotal < (selectedTopData?.quantity ?? FixedValue.CONSTANT_VALUE_0)
    ) {
      if (topDistributorList.length < FixedValue.CONSTANT_VALUE_5) {
        const isAlreadyExist = topDistributorList.findIndex(
          item => item?.emailPhoneNumber === values?.email_mobile.trim()
        );
        if (isAlreadyExist === FixedValue.CONSTANT_VALUE_MIN_1) {
          let paramsForEmailOrMobile: any = {isEmailSelected};;
          if (isEmailSelected) {
            paramsForEmailOrMobile.email = values?.email_mobile
              ?.trim()
              .toLowerCase();
            paramsForEmailOrMobile.countryCode =
              selectedCountry.dial_code || '+1';
          } else {
            paramsForEmailOrMobile.phoneNumber = values?.email_mobile?.trim();
            paramsForEmailOrMobile.countryCode =
              selectedCountry.dial_code || '+1';
          }
          dispatch(checkForMultipleAccountStart(paramsForEmailOrMobile));
        }
      }
    } else {
      alert(`you can't select more than ${selectedTopData?.quantity} quantity`);
    }
  }, [
    values,
    selectedCountry,
    topDistributorList,
    isEmailSelected,
    selectedTopData,
    totalDistributeTop,
  ]);

  // Function to remove an item from the list and update total quantity
  const removeItemAndUpdateTotal = (itemToRemove: EmailMobileType) => {
    setTopDistributorList(prevList =>
      prevList.filter(item => item !== itemToRemove)
    );
    setTotalDistributeTop(prevTotal => prevTotal - itemToRemove.quantity);
  };

  const onSelectEmailOrText = React.useCallback(
    (val: boolean) => {
      if (isEmailSelected !== val) {
        setIsEmailSelected(val);
        setFieldValue('email_mobile', '');
        setFieldTouched('email_mobile', false);
      }
    },
    [isEmailSelected]
  );

  const updateQuantity = useCallback(
    (item: any, val: string) => {
      // Calculate the new value based on the operation type
      const newValue =
        val === TEXT.MINNUS
          ? Math.max(
              FixedValue.CONSTANT_VALUE_0,
              item.quantity - FixedValue.CONSTANT_VALUE_1
            )
          : item.quantity + FixedValue.CONSTANT_VALUE_1;
      // Calculate the updated total quantity
      const updatedTotal = topDistributorList.reduce(
        (total, obj) => (obj === item ? newValue : obj.quantity) + total,
        FixedValue.CONSTANT_VALUE_0
      );
      // Check if the updated total quantity is within the limit
      if (
        updatedTotal <=
        (selectedTopData?.quantity ?? FixedValue.CONSTANT_VALUE_0)
      ) {
        // If within limit, update the item quantity
        setTopDistributorList(prevList =>
          prevList.map(prevItem =>
            prevItem === item ? {...prevItem, quantity: newValue} : prevItem
          )
        );
        setTotalDistributeTop(updatedTotal);
      } else {
        // If exceeds limit, handle it (e.g., show an error message)
        showAlertForExceedQuantity(
          selectedTopData?.quantity ?? FixedValue.CONSTANT_VALUE_0
        );
      }
    },
    [selectedTopData, topDistributorList]
  );

  const selectCSVFile = async () => {
    try {
      const file = await pickerOpen();
      if (file !== null) {
        const data = {
          uri: file?.uri ?? '',
          type: file?.type ?? '',
          name: file?.name ?? '',
          size: file?.size ?? FixedValue.CONSTANT_VALUE_0,
        };
        setSelectedFile(data);
      }
    } catch (error: any) {
      console.error('Error:', error.message);
    }
  };

  const uploadCSVToS3 = React.useCallback(async (): Promise<void> => {
    setCsvUploadLoader(true);
    try {
      if (selectedFile) {
        const CVSName = `mantalTop-${new Date().getTime()}`;
        // Call uploadCSV with progress callback
        const uploadS3CSVUrl: any = uploadCSV(selectedFile?.uri, `${CVSName}`);
        const payload = {
          topId: selectedTopData?.id,
          fileName: `${CVSName}.csv`,
        };
        dispatch(distributeViaCSVStart(payload));
      }
    } catch (error: any) {
      setCsvUploadLoader(false);
      _ToastHandler(error, false);
    }
  }, [selectedFile, selectedTopData]);

  const downloadSampleCSV = async () => {
    setCsvUploadLoader(true);
    try {
      const sampleDownloadCSVFile = `sampleDistributeTop.csv`;
      const imageUrl = await getCSVDownloadURL(sampleDownloadCSVFile);
      setCsvUploadLoader(false);
    } catch (error) {
      setCsvUploadLoader(false);
    }
  };

  const updateTotalQuantity = React.useCallback((): void => {
    const updatedTotal = topDistributorList.reduce(
      (total, obj) => obj.quantity + total,
      FixedValue.CONSTANT_VALUE_0
    );
    setTotalDistributeTop(updatedTotal + FixedValue.CONSTANT_VALUE_1);
  }, [topDistributorList]);

  const onUserSelect = React.useCallback(
    (
      item: ChildListModel | IUserProfileData | IUserMulipleData | null
    ): void => {
      let data: any = {
        countryCode: selectedCountry.dial_code || '+1',
        emailPhoneNumber: values?.email_mobile?.trim().toLowerCase(),
        quantity: FixedValue.CONSTANT_VALUE_1,
        isEmailSelected: isEmailSelected,
        userId: item?.id,
      };
      updateTotalQuantity();
      setTopDistributorList(prevState => [...prevState, data]);
      setFieldValue('email_mobile', '');
      setFieldTouched('email_mobile', false);
      setShowMultipleUserModal(false);
    },
    [selectedCountry, values, isEmailSelected]
  );

  return {
    handleChange,
    handleSubmit,
    handleBlur,
    isValid,
    values,
    setFieldValue,
    InviteViaEmailOrPhoneError,
    isButtonDisabled,
    setTopDistributorList,
    topDistributorList,
    addUserToDistributorList,
    isEmailSelected,
    setIsEmailSelected,
    onSelectEmailOrText,
    inviteTopDistribute,
    setTotalDistributeTop,
    totalDistributeTop,
    updateQuantity,
    removeItemAndUpdateTotal,
    selectCSVFile,
    uploadCSVToS3,
    downloadSampleCSV,
    selectedFile,
    setSelectedFile,
    uploadProgress,
    setUploadProgress,
    setCsvUploadLoader,
    csvUploadLoader,
    setFieldTouched,
    showMultipleUserModal,
    setShowMultipleUserModal,
    multipleUserListData,
    setMultipleUserListData,
    onUserSelect,
    updateTotalQuantity,
  };
};

export default useDistributeHooks;
