import {isProdEnvSelected} from '../constants/environment';

const AMPLIFY_CONFIG = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',
    userPoolId: isProdEnvSelected
      ? 'us-east-1_SjmsehQDl'
      : 'us-east-1_2bpKGuVbn',
    userPoolWebClientId: isProdEnvSelected
      ? '4nd76fn087hi4ul1rqdpf5ojt'
      : '4a2b3tnhoclc7hshaqia02he5l',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
};

export default AMPLIFY_CONFIG;
