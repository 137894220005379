import React, {useEffect} from 'react';
import {FlatList, Keyboard} from 'react-native';
import {FixedValue, GlobalStyleValues, TEXT} from '../../../constants';
import {HomeContext} from '../../../contextAPI/homeContext';
import {ThemeContext} from '../../../contextAPI/themeContext';
import useHomeHooks from '../../../hooks/componentHooks/useHomeHooks';
import {useAppDispatch, useAppSelector} from '../../../hooks/reduxHooks';
import {customModalForSelectUserStyle} from '../../../modals/customModalForSelectUser/customModalForSelectUser.style';
import {HomeStore} from '../../../redux/reducers/homeSlice';
import {CustomUserListSearchModalListData} from '../../../types/reducerTypes/searchTabStoreType';
import DistributeTopFooter from '../../footer/distributiveTopFooter';
import ViewContainer from '../../layout/viewContainer';
import AppLoader from '../../smallDesigns/appLoader';
import UserListComponent from '../../userListComponent';
import useFavouriteHooks from '../../../hooks/componentHooks/useFavouriteHooks';
import {FavoriteContext} from '../../../contextAPI/favoriteContext';
import {onClearFavoriteUserListEnd} from '../../../redux/reducers/userProfileSlice';
import {DISTRIBUTE_SEARCH} from '../../../constants/actionTypes';
import {useIsFocused} from '@react-navigation/native';
import {showAlertForExceedQuantity} from '../../../utils/helperFunctions';

const DistributeFavourite = (): JSX.Element => {
  const {
    selectedTopData,
    initialLoader,
    selectedUsersListSearch,
    remainingTop,
    setRemainingTop,
  } = React.useContext(HomeContext);
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = customModalForSelectUserStyle(ColorTheme);
  const {callDistributeTopAPI} = useHomeHooks();
  const {distributeTop} = useAppSelector(HomeStore);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const {onFavoriteUserListReachedEnd} = useFavouriteHooks();
  const {
    favoriteListData,
    setSelectedUsersFavouriteListSearch,
    setFavoriteListData,
  } = React.useContext(FavoriteContext);
  const dispatch = useAppDispatch();
  const isFoucsed = useIsFocused();

  useEffect(() => {
    const updatedArray = favoriteListData.map(item => {
      const matchingItem = selectedUsersListSearch.find(
        subItem => subItem.id === item.id
      );
      if (matchingItem) {
        return {
          ...item,
          quantity: matchingItem.quantity,
          alreadyPresentUser: true,
        };
      }
      return {...item, quantity: 0};
    });
    setFavoriteListData(updatedArray);
  }, [selectedUsersListSearch]);

  const updateFavouriteListQuantity = (
    item: CustomUserListSearchModalListData,
    val: string
  ) => {
    if (val === TEXT.MINNUS) {
      if (
        item.quantity !== FixedValue.CONSTANT_VALUE_1 &&
        item.quantity !== FixedValue.CONSTANT_VALUE_0
      ) {
        setSelectedUsersFavouriteListSearch({
          ...item,
          quantity: item.quantity! - FixedValue.CONSTANT_VALUE_1,
          alreadyPresentUser: !item.alreadyPresentUser,
          isCounter: true,
        });
      } else if (item.quantity === FixedValue.CONSTANT_VALUE_1) {
        setSelectedUsersFavouriteListSearch({
          ...item,
          quantity: FixedValue.CONSTANT_VALUE_1,
          isCounter: false,
        });
      }
    } else {
      const totalValue = selectedUsersListSearch.reduce(
        (total, obj) => obj.quantity! + total,
        FixedValue.CONSTANT_VALUE_0
      );
      if (
        totalValue < (selectedTopData?.quantity ?? FixedValue.CONSTANT_VALUE_0)
      ) {
        if (item.quantity === FixedValue.CONSTANT_VALUE_0) {
          setSelectedUsersFavouriteListSearch({
            ...item,
            quantity: FixedValue.CONSTANT_VALUE_1,
            isCounter: false,
          });
        } else {
          setSelectedUsersFavouriteListSearch({
            ...item,
            quantity: item.quantity! + FixedValue.CONSTANT_VALUE_1,
            alreadyPresentUser: !item.alreadyPresentUser,
            isCounter: true,
          });
        }
      } else {
        showAlertForExceedQuantity(
          selectedTopData?.quantity ?? FixedValue.CONSTANT_VALUE_0
        );
      }
    }
  };

  const onChangeUpdateQuantity = (
    item: CustomUserListSearchModalListData,
    quantity: number
  ) => {
    let tempSelectedUser = [...selectedUsersListSearch];
    tempSelectedUser = tempSelectedUser?.filter(user => user.id !== item.id);
    const totalValue = tempSelectedUser?.reduce(
      (total, obj) => obj?.quantity! + total,
      FixedValue.CONSTANT_VALUE_0
    );
    const totalQuantityAfterChange = totalValue + quantity;
    if (
      totalQuantityAfterChange <=
      (selectedTopData?.quantity ?? FixedValue.CONSTANT_VALUE_0)
    ) {
      if (item?.quantity === FixedValue.CONSTANT_VALUE_0) {
        setSelectedUsersFavouriteListSearch({
          ...item,
          quantity: quantity,
          isCounter: false,
        });
      } else {
        setSelectedUsersFavouriteListSearch({
          ...item,
          quantity: quantity,
          alreadyPresentUser: !item?.alreadyPresentUser,
          isCounter: true,
        });
      }
    } else {
      showAlertForExceedQuantity(
        selectedTopData?.quantity ?? FixedValue.CONSTANT_VALUE_0
      );
    }
  };

  useEffect(() => {
    const totalValue = selectedUsersListSearch.reduce(
      (total, obj) => obj.quantity! + total,
      FixedValue.CONSTANT_VALUE_0
    );
    const count = selectedTopData?.quantity! - totalValue;
    setRemainingTop(count);
  }, []);

  React.useEffect(() => {
    setLoading(distributeTop.isLoading);
  }, [distributeTop.isLoading]);

  const onPressDistributeNow = React.useCallback((): void => {
    callDistributeTopAPI(selectedUsersListSearch);
    dispatch(onClearFavoriteUserListEnd());
  }, [selectedUsersListSearch]);

  React.useEffect(() => {
    if (isFoucsed) {
      switch (distributeTop.status) {
        case DISTRIBUTE_SEARCH.SUCCESS:
          dispatch(onClearFavoriteUserListEnd());
          setRemainingTop(FixedValue.CONSTANT_VALUE_0);
          setSelectedUsersFavouriteListSearch([]);
          break;
        case DISTRIBUTE_SEARCH.FAILED:
          dispatch(onClearFavoriteUserListEnd());
          setRemainingTop(FixedValue.CONSTANT_VALUE_0);
          setSelectedUsersFavouriteListSearch([]);
          break;
        default:
          break;
      }
    }
  }, [distributeTop.status]);

  return (
    <>
      <ViewContainer>
        {initialLoader && <AppLoader />}
        <FlatList
          keyExtractor={(_, index) => `${TEXT.FAVOURITE_LIST}-${index}`}
          keyboardShouldPersistTaps={GlobalStyleValues.HANDLED}
          data={favoriteListData}
          contentContainerStyle={styles.userListContentStyle}
          onEndReached={onFavoriteUserListReachedEnd}
          onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
          showsVerticalScrollIndicator={false}
          scrollEnabled
          renderItem={({item}) => (
            <UserListComponent
              item={item}
              onSelectOrUnSelectUser={val => {}}
              onUpdateQuantityTap={val => {
                updateFavouriteListQuantity(item, val);
              }}
              quantity
              isInputQuantity
              onChangeQuantity={(val: number) =>
                onChangeUpdateQuantity(item, val)
              }
              checkQuantityOnOnChange={true}
              textInputSupportDarkTheme={true}
            />
          )}
          onScrollBeginDrag={() => Keyboard.dismiss()}
        />
      </ViewContainer>
      <DistributeTopFooter
        onClickDistributeTop={() => onPressDistributeNow()}
        remaining={remainingTop}
        topQuantity={selectedTopData?.quantity}
        isLoading={isLoading}
      />
    </>
  );
};

export default React.memo(DistributeFavourite);
