import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
const StraightLine = (props: SvgProps) => (
  <Svg
    width={44}
    height={4}
    viewBox="0 0 44 4"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      opacity={0.5}
      d="M2 2H42"
      stroke={props?.color ?? 'black'}
      strokeWidth={3}
      strokeLinecap="round"
    />
  </Svg>
);
export default StraightLine;
