import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import {Subscription} from 'expo-modules-core';

import {Platform} from 'react-native';
import {COLORS, ROUTE_NAME} from '../constants';
import {store} from '../redux/store';
import {
  onNotificationCountStart,
  updateNotificationToken,
} from '../redux/reducers/notificationSlice';
import {navigate} from './navigationService';

class NotificationService {
  constructor() {
    Notifications.setNotificationHandler({
      handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: true,
      }),
    });
    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: COLORS.PRIMARY_BLUE,
        sound: 'default',
        showBadge: true,
        enableVibrate: true,
      });
    }
  }
  static serviceInstance: NotificationService | null = null;
  static notificationListener: Subscription;
  static responseListener: Subscription;
  static messageListener = () => {};

  static getInstance() {
    if (!NotificationService.serviceInstance) {
      NotificationService.serviceInstance = new NotificationService();
    }
    return NotificationService.serviceInstance;
  }

  register = (): void => {
    this.checkPermission();
    this.createNotificationListeners();
  };

  requestPermission = async (): Promise<void> => {
    try {
      const {status} = await Notifications.requestPermissionsAsync();
      if (status === 'granted') this.getToken();
      else console.log('request failed');
    } catch (error) {
      console.log(error, 'FCM requested permission rejected');
    }
  };

  getToken = async (): Promise<void> => {
    try {
      const token: Notifications.ExpoPushToken =
        await Notifications.getExpoPushTokenAsync();
      store.dispatch(updateNotificationToken(token.data));
    } catch (error) {
      console.log(error, 'failed to get FCM token');
    }
  };

  checkPermission = async (): Promise<void> => {
    try {
      if (Device.isDevice) {
        const {status} = await Notifications.getPermissionsAsync();
        if (status !== 'granted') this.requestPermission();
        else this.getToken();
      }
    } catch (error) {
      console.log(error, 'error in Notification check permission');
    }
  };

  createNotificationListeners = (): void => {
    NotificationService.notificationListener =
      Notifications.addNotificationReceivedListener(
        (_notification: Notifications.Notification) => {
          console.log(
            JSON.stringify(_notification),
            'addNotificationReceivedListener'
          );
          // Forground state notification
          store.dispatch(onNotificationCountStart());
        }
      );

    NotificationService.responseListener =
      Notifications.addNotificationResponseReceivedListener(
        (_response: Notifications.NotificationResponse) => {
          console.log(
            JSON.stringify(_response),
            'addNotificationResponseReceivedListener'
          );
          // Background and kill state.
          navigate(ROUTE_NAME.BOTTOM_TAB, {
            screen: ROUTE_NAME.NOTIFICATION_TAB,
          });
        }
      );
  };

  removeAllListeners = (): void => {
    Notifications.removeNotificationSubscription(
      NotificationService.notificationListener
    );
    Notifications.removeNotificationSubscription(
      NotificationService.responseListener
    );
  };
}

export default NotificationService;
