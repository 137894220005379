import React from 'react';

import CircleControlItem from './circle/circleControlItem';
import RectangleControlItem from './rectangle/RectangleControlItem';
import SquareControlItem from './square/squareControlItem';

const GetControlShapes = (
  index: number,
  addItem: (el: JSX.Element) => void,
  selectItem: (itemIndex: number) => void,
  isSquareSelected: boolean,
  isRectangleSelected: boolean,
  isCircleSelected: boolean,
): JSX.Element[] => {
  return [
    <SquareControlItem
      key={`controls-item-circle`}
      elementId={`controls-item-circle`}
      newItemIndex={index}
      _isSelected={isSquareSelected}
      addItemCallback={addItem}
      selectItemCallback={selectItem}
    />,
    <CircleControlItem
      key={`controls-item-circle`}
      elementId={`controls-item-circle`}
      newItemIndex={index}
      _isSelected={isCircleSelected}
      addItemCallback={addItem}
      selectItemCallback={selectItem}
    />,
    <RectangleControlItem
      key={`controls-item-rectangle`}
      elementId={`controls-item-rectangle`}
      newItemIndex={index}
      _isSelected={isRectangleSelected}
      addItemCallback={addItem}
      selectItemCallback={selectItem}
    />,
  ];
};

export default GetControlShapes;
