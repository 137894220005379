export const IMAGES = {
  WHITE_CHECK: require('./../assets/images/check.png'),
  ERROR_ICON: require('./../assets/images/errorIcon.png'),
  PROFILE_BG: require('./../assets/images/profilebg.png'),
  PROFILE_DARK: require('./../assets/images/profileDark.png'),
  TRANSPARENT: require('./../assets/images/trasparent.png'),
  WELCOME_LIGHT: require('./../assets/images/welcomeLight.png'),
  WELCOME_DARK: require('./../assets/images/welcomeDark.png'),
  CONGRATS2: require('./../assets/images/gitFile/congrats.gif'),
  CONGRATS: require('./../assets/images/gitFile/congrats2.json'),
  LOGOO: require('./../assets/images/logoo.png'),
  P_USER: require('./../assets/images/pUser/pUser.png'),
};
