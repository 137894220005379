import {StyleSheet} from 'react-native';
import {COLORS, FixedValue, GlobalStyleValues} from '../../constants';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import {IColorTheme} from '../../types/contextTypes/themeContext';

export const style = (theme?: IColorTheme) =>
  StyleSheet.create({
    flatListContent: {
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    heartContainer: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_34),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_34),
      backgroundColor: COLORS.E9EEFB,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_34),
      justifyContent: GlobalStyleValues.CENTER,
      alignItems: GlobalStyleValues.CENTER,
    },
  });
