import React from 'react';
import {Image, Text, View} from 'react-native';
import {TopBuyingDetailStyle} from './topDetail.style';
import {ThemeContext} from '../../../contextAPI/themeContext';
import TradableLable from '../../../components/smallDesigns/tradableLable';
import CustomButton from '../../../components/button/customButton';
import {TEXT} from '../../../constants';
import CROSSICON from '../../../components/renderSvgs/smallCrossIcon';
import TICKICON from '../../../components/renderSvgs/tickIcon';

const TopDetailContent = ({}): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = TopBuyingDetailStyle(ColorTheme);

  return (
    <View>
      <View style={styles.container}>
        <Image
          style={styles.imageStyle}
          source={{
            uri: 'https://fastly.picsum.photos/id/364/200/200.jpg?hmac=wN2-1fE2NpZHjoUsBhb3BfnYFXmdmhd-DVhDqHG_Kg0',
          }}
        />
        <View style={styles.lable}>
          <TradableLable />
        </View>
      </View>
      <Text style={styles.topName}>{TEXT.TOP}</Text>
      <Text style={styles.createdDate}>{TEXT.CREATED_DATE}</Text>
      <Text style={styles.hashTag}>{TEXT.HASH}</Text>
      <View>
        <Text style={styles.note}>Note</Text>
        <Text style={styles.desc}>{TEXT.DUMMY_TEXT}</Text>
      </View>
      <View style={styles.approveButton}>
        <CustomButton
          buttonLabel={TEXT.REJECT}
          buttonwidth={styles.primaryButtonStyle1}
          labelStyle={styles.primaryButtonLabelstyle1}
          element={<CROSSICON />}
          buttonBackGroundColor={styles.noButtonBackGroundColor1}
          onPress={() => {}}
        />
        <CustomButton
          buttonLabel={TEXT.APPROVE}
          buttonwidth={styles.primaryButtonStyle}
          labelStyle={styles.primaryButtonLabelstyle}
          element={<TICKICON />}
          buttonBackGroundColor={styles.noButtonBackGroundColor}
          onPress={() => {}}
        />
      </View>
    </View>
  );
};

export default React.memo(TopDetailContent);
