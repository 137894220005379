import {ENVIRONMENT} from './appConst';
import {FixedValue} from './enum';

const BASE_URL_PREFIX_DEV = 'https://api-dev.manteltop.com';
const BASE_URL_PREFIX_QA = 'https://api-qa.manteltop.com';
const BASE_URL_PREFIX_STAGE = 'https://api-stage.manteltop.com';
const BASE_URL_PREFIX_PROD = 'https://api.manteltop.com';

const SELECTED_ENV: string = ENVIRONMENT.DEV;

export const getBaseUrl = (): string => {
  switch (SELECTED_ENV) {
    case ENVIRONMENT.DEV:
      return `${BASE_URL_PREFIX_DEV}/api/v2/`;
    case ENVIRONMENT.QA:
      return `${BASE_URL_PREFIX_QA}/api/v2/`;
    case ENVIRONMENT.STAGE:
      return `${BASE_URL_PREFIX_STAGE}/api/v2/`;
    case ENVIRONMENT.PROD:
      return `${BASE_URL_PREFIX_PROD}/api/v2/`;
    default:
      return '';
  }
};

export const isProdEnvSelected = getBaseUrl().includes(BASE_URL_PREFIX_PROD);

export const checkUploadS3Bucket = (): string => {
  switch (SELECTED_ENV) {
    case ENVIRONMENT.DEV:
      return `manteltop-dev-scanning`;
    case ENVIRONMENT.QA:
      return `manteltop-qa-scanning`;
    case ENVIRONMENT.STAGE:
      return `manteltop-stage-scanning`;
    case ENVIRONMENT.PROD:
      return `manteltop-prod-scanning`;
    default:
      return '';
  }
};

export const options = {
  keyPrefix: 'uploads/',
  region: 'us-east-1',
  successActionStatus: FixedValue.CONSTANT_VALUE_201,
  uploadS3Bucket: checkUploadS3Bucket(),
  bucket: isProdEnvSelected ? 'manteltop-assests' : 'manteltop-testing-assests',
  accessKey: isProdEnvSelected
    ? 'AKIA5UELLUIFFSGYTUNY'
    : 'AKIA5UELLUIFDPGMCPF2',
  secretKey: isProdEnvSelected
    ? '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'
    : 'af6MqwdBerrjWU3VDLfdGONcQ7vkeHr1Ie4NG+dc',
};

export const paypalOption = {
  clientId: isProdEnvSelected
    ? 'ATUTqeDlCu9FXa1s87o945GZ-Ju9idXETvrE9eSiNmzJaVJl-gKxlOu9sVFfTKJ1K-yrzALnIxPfpDy7'
    : 'AfueFm4HOvcmG27vJtsDzf8cW_qVDr7aBbgksgz6R86duup5FricYXDI_5v14j1lgt5u5OywoR4YoTeN',
  currency: 'USD',
  intent: 'capture',
};
