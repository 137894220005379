import React, {useRef} from 'react';
import {useFormik} from 'formik';
import {ERROR_MESSAGES, FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import {TopType} from '../../constants/enum';
import {HomeContext} from '../../contextAPI/homeContext';
import {
  onInProgressTopsListStart,
  HomeStore,
  onBuyTopStart,
  onCartCheckoutStart,
  onBuyingRequestStart,
  verifyCouponCodeStart,
} from '../../redux/reducers/homeSlice';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import {navigate} from '../../services/navigationService';
import {
  BuyTopParams,
  IInProgressTopParams,
  TopModel,
  RequestDataForParentRequest,
} from '../../types/reducerTypes/searchTabStoreType';
import {useAppDispatch, useAppSelector} from '../reduxHooks';
import {ApllyCouponCodeSchema} from '../../utils/formikSchema';
import {uTCTimeToLocalTime} from '../../utils/helperFunctions';

export type CouponCodeField = {couponCode: string};

const useInProgressTopsHooks = () => {
  const dispatch = useAppDispatch();
  const {inProgressTopList, topAmountAndReportAbuseData} =
    useAppSelector(HomeStore);
  const {
    homeScreenUserData,
    selectedTopsResult,
    totalTopsAmount,
    topAmount,
    applyCouponCodeData,
    isPaymentFromCash,
    discountPrice,
  } = React.useContext(HomeContext);
  const {userData} = useAppSelector(userProfileStore);
  const UserDetail = userData?.success;
  const isChildUserSelected: boolean =
    UserDetail?.id !== homeScreenUserData?.id &&
    UserDetail?.role === TEXT.ROLE_PARENT &&
    homeScreenUserData !== null;
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
    isValid,
  } = useFormik<CouponCodeField>({
    validationSchema: ApllyCouponCodeSchema,
    initialErrors: {
      couponCode: ERROR_MESSAGES.REQUIRED_COUPON_CODE,
    },
    initialTouched: {
      couponCode: false,
    },
    initialValues: {
      couponCode: '',
    },
    onSubmit: val => {
      verifyCouponCode(val?.couponCode);
    },
  });

  const CouponCodeError: string =
    touched.couponCode && errors.couponCode ? errors.couponCode : '';

  React.useEffect(() => {
    // Disable the button if the form is not valid
    setIsButtonDisabled(!isValid);
  }, [isValid]);

  React.useEffect(() => {
    if (values.couponCode.trim().length > FixedValue.CONSTANT_VALUE_3) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [values.couponCode]);

  const callInProgressTopsListAPI = React.useCallback(
    (page: number): void => {
      const params: IInProgressTopParams = {
        page,
        size: FixedValue.CONSTANT_VALUE_15,
      };
      if (isChildUserSelected) {
        params.minorId = homeScreenUserData?.id;
      }
      dispatch(onInProgressTopsListStart(params));
    },
    [UserDetail, homeScreenUserData]
  );

  // Call API for buying Top
  const callApiForBuyingTop = React.useCallback((topId: number): void => {
    dispatch(onBuyTopStart({topId}));
  }, []);

  const onReachedEnd = React.useCallback((): void => {
    if (
      inProgressTopList.success != null &&
      inProgressTopList.success.pageNumber <
        inProgressTopList.success.totalPages
    ) {
      callInProgressTopsListAPI(inProgressTopList.success.pageNumber + 1);
    }
  }, [inProgressTopList]);

  const navigateToDetailScreen = React.useCallback(
    (topDetails: TopModel, comingFrom: TopType): void => {
      navigate(ROUTE_NAME.TOP_DETAIL_SCREEN, {topDetails, comingFrom});
    },
    []
  );

  const applyCouponCodeDataRef = useRef(applyCouponCodeData);
  const selectedTopsResultRef = useRef(selectedTopsResult);
  const isPaymentFromCashRef = useRef(isPaymentFromCash);
  React.useEffect(() => {
    applyCouponCodeDataRef.current = applyCouponCodeData;
    selectedTopsResultRef.current = selectedTopsResult;
    isPaymentFromCashRef.current = isPaymentFromCash;
  }, [applyCouponCodeData, selectedTopsResult, isPaymentFromCash]);

  const callCartTopAPI = React.useCallback((): void => {
    const quantityData = selectedTopsResultRef?.current?.map(
      (top: TopModel) => {
        const quantity = top?.selectedQuantity ?? FixedValue.CONSTANT_VALUE_1;
        const amount = top?.amount ?? topAmount;
        const couponData = applyCouponCodeDataRef?.current;
        const initialPrice = Number(quantity * amount);
        let discountPrice = FixedValue.CONSTANT_VALUE_0;
        if (applyCouponCodeData) {
          discountPrice = (initialPrice * applyCouponCodeData.value) / 100;
        }
        const amountToBePaid = initialPrice - discountPrice;
        const baseObject: BuyTopParams = {
          topId: top.id,
          quantity: quantity,
          price: amountToBePaid,
        };
        // Check if applyCouponCodeData is not null
        if (couponData) {
          baseObject.initialPrice = initialPrice;
          baseObject.couponId = couponData.id;
          baseObject.discountPrice = discountPrice;
        }
        return baseObject;
      }
    );
    const apiParams = {
      purchaseRequests: quantityData,
      cash: isPaymentFromCashRef?.current,
    };
    dispatch(onCartCheckoutStart(apiParams));
  }, [
    dispatch,
    selectedTopsResult,
    totalTopsAmount,
    topAmount,
    applyCouponCodeData,
    applyCouponCodeDataRef,
    selectedTopsResultRef,
    isPaymentFromCashRef,
    isPaymentFromCash,
  ]);

  const callBuyingRequestToParent = React.useCallback((): void => {
    const quantityData = selectedTopsResult?.map((top: TopModel) => {
      const quantity = top?.selectedQuantity ?? FixedValue.CONSTANT_VALUE_1;
      const amount = top?.amount ?? FixedValue.CONSTANT_VALUE_099;
      const initialPrice = Number(quantity * amount);
      let discountPrice = FixedValue.CONSTANT_VALUE_0;
      if (applyCouponCodeData) {
        discountPrice = (initialPrice * applyCouponCodeData.value) / 100;
      }
      const amountToBePaid = initialPrice - discountPrice;
      const baseObject: BuyTopParams = {
        topId: top.id,
        quantity: quantity,
        price: amountToBePaid,
      };
      // Check if applyCouponCodeData is not null
      if (applyCouponCodeData) {
        baseObject.initialPrice = initialPrice;
        baseObject.couponId = applyCouponCodeData.id;
        baseObject.discountPrice = discountPrice;
      }
      return baseObject;
    });
    const totalQuantity = selectedTopsResult.reduce(
      (total, top) =>
        total + (top?.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0),
      FixedValue.CONSTANT_VALUE_0
    );
    if (
      totalQuantity === FixedValue.CONSTANT_VALUE_1 &&
      isPaymentFromCashRef?.current
    ) {
      quantityData[FixedValue.CONSTANT_VALUE_0].price =
        topAmountAndReportAbuseData?.success?.data?.singleTopPrice ??
        FixedValue.CONSTANT_VALUE_0;
    }
    const requestData: RequestDataForParentRequest = {
      quantityData,
      cash: isPaymentFromCashRef?.current,
    };
    dispatch(onBuyingRequestStart(requestData));
  }, [
    dispatch,
    selectedTopsResult,
    totalTopsAmount,
    topAmount,
    applyCouponCodeData,
    isPaymentFromCashRef,
    isPaymentFromCash,
    topAmountAndReportAbuseData,
  ]);

  const verifyCouponCode = React.useCallback(
    (couponCode: string): void => {
      const currentDate = uTCTimeToLocalTime();
      dispatch(verifyCouponCodeStart({couponCode, currentDate}));
    },
    [dispatch, selectedTopsResult, totalTopsAmount, topAmount, discountPrice]
  );

  return {
    onReachedEnd,
    navigateToDetailScreen,
    callInProgressTopsListAPI,
    callApiForBuyingTop,
    callCartTopAPI,
    callBuyingRequestToParent,
    handleChange,
    handleSubmit,
    handleBlur,
    isValid,
    values,
    setFieldValue,
    CouponCodeError,
    isButtonDisabled,
  };
};

export default useInProgressTopsHooks;
