import React from 'react';
import {Pressable, Text, View} from 'react-native';
import {ThemeContext} from '../../../contextAPI/themeContext';
import Ellipse from '../../../components/renderSvgs/ellipse';
import SelectEllipse from '../../../components/renderSvgs/selectEllipse';
import {FixedValue, TEXT} from '../../../constants';
import {scaleHeight} from '../../../utils/responsive';
import CustomButton from '../../../components/button/customButton';
import {ShiftingSettingStyle} from './style';
import {ChildProfileContext} from '../../../contextAPI/childProfileContext';
import useTradingApprovalHooks from '../../../hooks/componentHooks/useTradingApprovalHooks';
import {CHILD_PERMISSION} from '../../../constants/enum';
import {useAppSelector} from '../../../hooks/reduxHooks';
import useChildListHooks from '../../../hooks/componentHooks/useChildListHooks';
import {childProfileStore} from '../../../redux/reducers/childProfileSlice';
import {CHILD_TRADABLE_SETTING} from '../../../constants/actionTypes';
import {_ToastHandler} from '../../../utils/helperFunctions';
import {goBack} from '../../../services/navigationService';
import ConfirmationModal from '../../../modals/confirmationModal/confirmationModal';
import {TOAST_TEXT} from '../../../constants/textMessage';
import {useIsFocused} from '@react-navigation/native';

const ShiftingSettingScreen = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const isFocused = useIsFocused();
  const {styles} = ShiftingSettingStyle(ColorTheme);
  const {
    childUserData,
    modalVisibleGroupInvitation,
    setModalVisibleGroupInvitation,
  } = React.useContext(ChildProfileContext);
  const shiftinfToTradablePermission: string =
    childUserData?.permissionsStatus?.tradablePermissionStatus ?? '';
  const [tradableSettingApproval, setTradeableSettingApproval] =
    React.useState<boolean>(
      shiftinfToTradablePermission === CHILD_PERMISSION.ALLOWED
    );
  const {callChildTradableSettingAPI} = useTradingApprovalHooks();
  const {childTradableSetting} = useAppSelector(childProfileStore);
  const {callChildProfileAPI} = useChildListHooks();
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const onPress = React.useCallback(
    (item: string): void => {
      setTradeableSettingApproval(CHILD_PERMISSION.ALLOWED === item);
    },
    [tradableSettingApproval]
  );

  React.useEffect(() => {
    if (isFocused) {
      switch (childTradableSetting.status) {
        case CHILD_TRADABLE_SETTING.SUCCESS:
          setModalVisibleGroupInvitation(false);
          _ToastHandler(TOAST_TEXT.CHILD_TRADABLE_SETTING_SUCCESS, true);
          callChildProfileAPI(childUserData?.id ?? FixedValue.CONSTANT_VALUE_0);
          goBack();
          break;
        case CHILD_TRADABLE_SETTING.FAILED:
          setModalVisibleGroupInvitation(false);
          break;
        default:
          break;
      }
    }
  }, [childTradableSetting.status]);

  React.useEffect(() => {
    setLoading(childTradableSetting.isLoading);
  }, [childTradableSetting.isLoading]);

  return (
    <View style={styles.settingContainer}>
      <View style={styles.selectView}>
        <Text style={styles.oneOption}>{TEXT.SELECT_ONE_OPTION}</Text>
      </View>
      <View>
        <View style={styles.allTradeView}>
          <Pressable onPress={() => onPress(CHILD_PERMISSION.NOT_ALLOWED)}>
            {!tradableSettingApproval ? (
              <SelectEllipse
                width={FixedValue.CONSTANT_VALUE_20}
                height={FixedValue.CONSTANT_VALUE_20}
              />
            ) : (
              <Ellipse
                width={FixedValue.CONSTANT_VALUE_20}
                height={FixedValue.CONSTANT_VALUE_20}
              />
            )}
          </Pressable>
          <Text
            style={styles.allTrade}
            onPress={() => onPress(CHILD_PERMISSION.NOT_ALLOWED)}
            suppressHighlighting
          >
            {TEXT.APPROVE_ALL_TRADABLE}
          </Text>
        </View>
        <View style={styles.ellipseView}>
          <View style={{marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_1)}}>
            <Pressable onPress={() => onPress(CHILD_PERMISSION.ALLOWED)}>
              {tradableSettingApproval ? (
                <SelectEllipse
                  width={FixedValue.CONSTANT_VALUE_20}
                  height={FixedValue.CONSTANT_VALUE_20}
                />
              ) : (
                <Ellipse
                  width={FixedValue.CONSTANT_VALUE_20}
                  height={FixedValue.CONSTANT_VALUE_20}
                />
              )}
            </Pressable>
          </View>
          <Text
            style={styles.allTrade}
            onPress={() => onPress(CHILD_PERMISSION.ALLOWED)}
            suppressHighlighting
          >
            {TEXT.ACCEPT_TRDABLE_MEMBERSHIP}
          </Text>
        </View>
      </View>
      <View style={styles.buttonView}>
        <CustomButton
          buttonLabel={TEXT.SAVE}
          buttonwidth={styles.buttonStyle}
          labelStyle={styles.buttonLabelstyle}
          onPress={() => setModalVisibleGroupInvitation(true)}
        />
      </View>
      <ConfirmationModal
        title={TEXT.CHANGE_TRADABLE_SETTING}
        modalVisible={modalVisibleGroupInvitation}
        onPressYes={() => {
          callChildTradableSettingAPI(
            tradableSettingApproval
              ? CHILD_PERMISSION.ALLOWED
              : CHILD_PERMISSION.NOT_ALLOWED
          );
        }}
        onShowHide={() => setModalVisibleGroupInvitation(false)}
        isLoading={isLoading}
      />
    </View>
  );
};

export default React.memo(ShiftingSettingScreen);
