import React from 'react';

import {FixedValue} from '../constants';
import {ShapeRefType} from '../types/componentTypes/editorType';
import {
  IEditorProvider,
  IEditorShapeContext,
  ImageController,
} from './../types/contextTypes/editorContext';

const EditorShapeContext = React.createContext<IEditorShapeContext>({
  controlsItems: [],
  setControlsItems: _el => {},
  selectedController: null,
  setSelectedController: _value => {},
  onSelectShape: null,
  setOnSelectShape: _value => {},
});

const EditorShapeProvider = (props: IEditorProvider): JSX.Element => {
  const [controlsItems, setControlsItems] = React.useState<JSX.Element[]>([]);
  const [selectedController, setSelectedController] =
    React.useState<ImageController | null>(null);
  const [onSelectShape, setOnSelectShape] =
    React.useState<React.RefObject<ShapeRefType> | null>(null);

  const _setControlsItems = React.useCallback(
    (value: JSX.Element[]): void => {
      setControlsItems(value);
    },
    [controlsItems]
  );

  const _setSelectedController = React.useCallback(
    (value: ImageController | null): void => {
      setSelectedController(value);
    },
    [selectedController]
  );

  const _setOnSelectShape = React.useCallback(
    (value: React.RefObject<ShapeRefType> | null): void => {
      setOnSelectShape(value);
    },
    [onSelectShape]
  );

  const values = React.useMemo(
    (): IEditorShapeContext => ({
      selectedController,
      onSelectShape,
      controlsItems,
      setControlsItems: _setControlsItems,
      setOnSelectShape: _setOnSelectShape,
      setSelectedController: _setSelectedController,
    }),
    [
      selectedController,
      onSelectShape,
      controlsItems,
      setSelectedController,
      setControlsItems,
      setOnSelectShape,
    ]
  );

  return (
    <EditorShapeContext.Provider value={values}>
      {props.children}
    </EditorShapeContext.Provider>
  );
};

export {EditorShapeContext, EditorShapeProvider};
