import React from 'react';
import {FlatList} from 'react-native';
import {useAppDispatch, useAppSelector} from '../reduxHooks';
import {NotificationContext} from '../../contextAPI/notificationContext';
import {
  notificationState,
  onNotificationCompletedListStart,
  onNotificationCountStart,
  onNotificationListStart,
} from '../../redux/reducers/notificationSlice';
import {FixedValue, TEXT} from '../../constants';
import {INotificationListItem} from '../../types/reducerTypes/notificationTypes';
import {
  onGroupInvitationsApprovedStart,
  onGroupInvitationsRejectStart,
} from '../../redux/reducers/groupManagementSlice';
import {
  ACCEPTED_OR_REJECTED,
  APPROVED_OR_REJECT,
  NOTIFICATION_LIST_TYPE,
  NOTIFICATION_TYPE,
} from '../../constants/enum';
import {
  onChildTradableApproveRejectStart,
  onSentDistributeApproveOrRejectStart,
  onSentTradeApproveOrRejectStart,
} from '../../redux/reducers/childProfileSlice';
import {
  onApproveOfferStart,
  onRejectOfferStart,
} from '../../redux/reducers/tradeTabSlice';
import {onUserChildProfileStart} from '../../redux/reducers/userProfileSlice';
import {_ToastHandler} from '../../utils/helperFunctions';

const useNotificationHook = () => {
  const FlatlistRef = React.useRef<React.RefObject<FlatList>>();
  const dispatch = useAppDispatch();
  const {notificationData, notificationCompletedData} =
    useAppSelector(notificationState);
  const {
    setIsRefreshing,
    notificationListData,
    acceptRejectAction,
    setConfirmationModal,
  } = React.useContext(NotificationContext);

  const fetchNotificationList = React.useCallback(
    (page: number, notificationType: string): void => {
      if (notificationType === NOTIFICATION_LIST_TYPE.ACTION_NEEDED) {
        dispatch(
          onNotificationListStart({
            page,
            notificationType,
            size: FixedValue.CONSTANT_VALUE_15,
          })
        );
      } else {
        dispatch(
          onNotificationCompletedListStart({
            page,
            notificationType,
            size: FixedValue.CONSTANT_VALUE_15,
          })
        );
      }
    },
    []
  );

  const handleRefresh = React.useCallback((actionTypes: string): void => {
    setIsRefreshing(true);
    dispatch(onNotificationCountStart());
    fetchNotificationList(FixedValue.CONSTANT_VALUE_0, actionTypes);
  }, []);

  const onReachedEnd = React.useCallback((): void => {
    if (notificationData.success) {
      const nextPage =
        notificationData.success.pageNumber + FixedValue.CONSTANT_VALUE_1;
      if (nextPage < notificationData.success.totalPages)
        fetchNotificationList(nextPage, NOTIFICATION_LIST_TYPE.ACTION_NEEDED);
    }
  }, [notificationData]);

  const onNotificationCompletedListReachedEnd = React.useCallback((): void => {
    if (notificationCompletedData.success) {
      const nextPage =
        notificationCompletedData.success.pageNumber +
        FixedValue.CONSTANT_VALUE_1;
      if (nextPage < notificationCompletedData.success.totalPages)
        fetchNotificationList(nextPage, NOTIFICATION_LIST_TYPE.COMPLETED);
    }
  }, [notificationCompletedData]);

  const groupInvitaionAcceptRejectApiCall = React.useCallback(
    (item: INotificationListItem, type: string, index: number): void => {
      if (item.json) {
        const jsonData = JSON.parse(item.json);
        const params = {
          groupId: jsonData?.groupId ?? FixedValue.CONSTANT_VALUE_0,
          userId: jsonData?.userId ?? FixedValue.CONSTANT_VALUE_0,
        };
        if (type === TEXT.ACCEPT) {
          dispatch(onGroupInvitationsApprovedStart(params));
          acceptRejectAction(index);
        } else {
          dispatch(onGroupInvitationsRejectStart(params));
          acceptRejectAction(index);
        }
      }
    },
    [notificationListData]
  );

  const childTradableApproveRejecApiCall = React.useCallback(
    (item: INotificationListItem, type: string, index: number): void => {
      const params = {
        requestId: item.requestId ?? FixedValue.CONSTANT_VALUE_0,
        status: type,
      };
      dispatch(onChildTradableApproveRejectStart(params));
      const isApprove: boolean = type === APPROVED_OR_REJECT.APPROVED;
      acceptRejectAction(index);
    },
    [notificationListData]
  );

  const childDistributeRequestApproveOrRejectApi = React.useCallback(
    (item: INotificationListItem, type: string, index: number): void => {
      const params = {
        requestId: item.requestId ?? FixedValue.CONSTANT_VALUE_0,
        status: type,
      };
      dispatch(onSentDistributeApproveOrRejectStart(params));
      const isApprove: boolean = type === ACCEPTED_OR_REJECTED.ACCEPTED;
      acceptRejectAction(index);
    },
    [notificationListData]
  );

  const onPressNo = React.useCallback((): void => {
    setConfirmationModal({isVisible: false, title: '', type: ''});
  }, []);

  const getModalSuccessTitle = React.useCallback(
    (type: NOTIFICATION_TYPE): string => {
      if (type === NOTIFICATION_TYPE.TRADABLE_PUSH)
        return TEXT.APPROVE_TRADABLE_REQUEST;
      else if (type === NOTIFICATION_TYPE.TRADE_OFFER_PUSH)
        return TEXT.OFFER_APPROVE;
      else if (type === NOTIFICATION_TYPE.GROUP_PUSH)
        return TEXT.APPROVE_INVITATION;
      else if (
        type === NOTIFICATION_TYPE.DISTRIBUTION_PUSH ||
        type === NOTIFICATION_TYPE.DISTRIBUTION_PUSH_EMAIL
      )
        return TEXT.APPROVE_TRADABLE_REQUEST;
      return '';
    },
    []
  );

  const getModalRejectTitle = React.useCallback(
    (type: NOTIFICATION_TYPE): string => {
      if (type === NOTIFICATION_TYPE.TRADABLE_PUSH)
        return TEXT.REJECT_TRADABLE_REQUEST;
      else if (type === NOTIFICATION_TYPE.TRADE_OFFER_PUSH)
        return TEXT.OFFER_REJECT;
      else if (type === NOTIFICATION_TYPE.GROUP_PUSH)
        return TEXT.REJECT_INVITATION;
      else if (
        type === NOTIFICATION_TYPE.DISTRIBUTION_PUSH ||
        type === NOTIFICATION_TYPE.DISTRIBUTION_PUSH_EMAIL
      )
        return TEXT.REJECT_TRADABLE_REQUEST;
      return '';
    },
    []
  );

  const tradeOfferAcceptRejectApiCall = React.useCallback(
    (item: INotificationListItem, type: string, index: number): void => {
      if (item.json) {
        const isAccept: boolean = type == TEXT.ACCEPT_TEXT;
        const jsonData = JSON.parse(item.json);
        if (jsonData.isChild) {
          const params = {
            status: isAccept
              ? APPROVED_OR_REJECT.APPROVED
              : APPROVED_OR_REJECT.REJECT,
            offerId: jsonData.id,
          };
          dispatch(onSentTradeApproveOrRejectStart(params));
        } else {
          if (isAccept) dispatch(onApproveOfferStart(jsonData.id));
          else dispatch(onRejectOfferStart(jsonData.id));
        }
      }
    },
    [notificationListData]
  );

  const callChildProfileAPI = React.useCallback((userId: number): void => {
    dispatch(onUserChildProfileStart(userId));
  }, []);

  return {
    childDistributeRequestApproveOrRejectApi,
    getModalSuccessTitle,
    getModalRejectTitle,
    onPressNo,
    tradeOfferAcceptRejectApiCall,
    childTradableApproveRejecApiCall,
    groupInvitaionAcceptRejectApiCall,
    fetchNotificationList,
    handleRefresh,
    onReachedEnd,
    FlatlistRef,
    callChildProfileAPI,
    onNotificationCompletedListReachedEnd,
  };
};

export default useNotificationHook;
