import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
  SCREEN_WIDTH,
} from '../../utils/responsive';
import {Dimensions} from 'react-native';
const {width} = Dimensions.get('window');
const numColumns = 2;

export const TutorialsSyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
    },
    headerView: {
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    tile: {
      flex: FixedValue.CONSTANT_VALUE_1,
      margin: FixedValue.CONSTANT_VALUE_10,
      width: width / numColumns - FixedValue.CONSTANT_VALUE_50,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_250),
      justifyContent: GlobalStyleValues.CENTER,
      alignItems: GlobalStyleValues.CENTER,
    },
    thumbnail: {
      width: Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_80,
      borderRadius: FixedValue.CONSTANT_VALUE_14,
    },
    name: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_5),
      color: _theme.color,
      textAlign: GlobalStyleValues.CENTER,
      fontFamily: FONT_NAMES.SF_BOLD,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      alignSelf: GlobalStyleValues.CENTER,
      width: Percentage.PRECENTAGE_100,
    },
    listContent: {
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_25),
    },
    extraTopMargin: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    container: {
      ...BASESTYLE.flex1,
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    video: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      flex: FixedValue.CONSTANT_VALUE_1,
      width: SCREEN_WIDTH,
    },
  });
