import {StyleSheet} from 'react-native';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  FONTS,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';

export const styles = (theme?: IColorTheme) =>
  StyleSheet.create({
    textInputContainer: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      marginHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    container: {
      alignItems: GlobalStyleValues.CENTER,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_19),
    },
    lineContainer: {
      flexDirection: GlobalStyleValues.ROW,
      alignItems: GlobalStyleValues.CENTER,
    },
    line: {
      ...BASESTYLE.flex1,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_1),
      backgroundColor: COLORS.E9EEFB,
    },
    orText: {
      marginHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_19),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_14),
      color: COLORS._666666,
      fontFamily: FONTS.SF_LIGHT,
    },
    ifYouDont: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      color: theme?.color,
      fontFamily: FONTS.SF_LIGHT,
    },
    primaryButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      marginLeft: FixedValue.CONSTANT_VALUE_10,
    },
    buttonView: {
      flexDirection: GlobalStyleValues.ROW,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    uploadButtonStyle: {
      ...BASESTYLE.inRow,
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_160),
      justifyContent: GlobalStyleValues.CENTER,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_15),
    },
    uploadButtonColor: {
      backgroundColor: COLORS._4EAF51,
    },
    downloadButtonStyle: {
      ...BASESTYLE.inRow,
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_160),
      justifyContent: GlobalStyleValues.CENTER,
      borderWidth: FixedValue.CONSTANT_VALUE_1,
      borderColor: COLORS.E9EEFB,
    },
    downloadButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.PRIMARY_BLUE,
      marginLeft: FixedValue.CONSTANT_VALUE_10,
    },
    downloadButtonColor: {
      backgroundColor: COLORS.WHITE,
    },
    sendInviteButtonColor: {
      backgroundColor: COLORS.PRIMARY_BLUE,
    },
    marginTop30: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_30),
    },
    selectEmailOrNumber: {
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_5),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_30),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_17),
      color: theme?.color,
      fontFamily: FONTS.SF_LIGHT,
    },
    emailOrPhoneContainer: {
      flexDirection: GlobalStyleValues.ROW,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_12),
    },
    radioButtonAndTextContainer: {
      ...BASESTYLE.inCenter,
      flexDirection: GlobalStyleValues.ROW,
    },
    inputSecondElement: {
      right: scaleWidth(FixedValue.CONSTANT_VALUE_30),
    },
    distributeUserListContainer: {
      ...BASESTYLE.inRow,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      width: Percentage.PRECENTAGE_100,
    },
    emailPhoneText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_17),
      color: theme?.color,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      width: Percentage.PRECENTAGE_64,
    },
    quantitySelectorViewStyle: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_28),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_81),
      borderWidth: FixedValue.CONSTANT_VALUE_1,
      borderRadius: FixedValue.CONSTANT_VALUE_100,
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      ...BASESTYLE.spaceBetween,
      backgroundColor: theme?.backgroundColor,
      overflow: GlobalStyleValues.HIDDEN,
      borderColor: COLORS._B3B3B3,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    disableText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      color: theme?.disabledTextColor,
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    containerCSV: {
      ...BASESTYLE.row,
      alignItems: GlobalStyleValues.CENTER,
      justifyContent: GlobalStyleValues.SPACE_BETWEEN,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_14),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_17),
    },
    leftColumn: {
      alignItems: GlobalStyleValues.CENTER,
      ...BASESTYLE.row,
      width: Percentage.PRECENTAGE_42,
    },
    rightColumn: {
      alignItems: GlobalStyleValues.CENTER,
      ...BASESTYLE.row,
    },
    fileNameStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_15),
      color: theme?.color,
      fontFamily: FONT_NAMES.SF_REGULAR,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_4),
    },
    kbText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      color: COLORS._GREY,
      fontFamily: FONT_NAMES.SF_REGULAR,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_5),
    },
    marginRight16: {
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_16),
    },
    csvContainer: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_77),
      borderRadius: FixedValue.CONSTANT_VALUE_8,
      borderColor: COLORS.E9EEFB,
      borderWidth: scaleWidth(FixedValue.CONSTANT_VALUE_1),
    },
    progreeContainer: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_13),
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_16),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_12),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_5),
      backgroundColor: COLORS.E9EEFB,
      borderRadius: 5,
      overflow: GlobalStyleValues.HIDDEN,
    },
    progressBar: {
      backgroundColor: COLORS.PRIMARY_BLUE,
      height: Percentage.PRECENTAGE_100,
    },
  });
