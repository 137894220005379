import React from 'react';
import {Text, TextStyle} from 'react-native';
import {FONTS, FONT_NAMES} from '../../constants';

interface IMessageComponent {
  message: string;
  textStyle: TextStyle;
}

const NotificationMessageText = ({
  message,
  textStyle,
}: IMessageComponent): JSX.Element => {
  const regExp = /<b>(.*?)<\/b>|([^<]+)/g;
  return (
    <Text>
      {message?.match(regExp)?.map((tag, index) => {
        if (tag.startsWith('<b>')) {
          const boldText = tag.replace(/<\/?b>/g, '');
          return (
            <Text key={index} style={[textStyle, {fontFamily: FONT_NAMES.SF_BOLD}]}>
              {boldText}
            </Text>
          );
        } else {
          return (
            <Text key={index} style={textStyle}>
              {tag}
            </Text>
          );
        }
      })}
    </Text>
  );
};

export default React.memo(NotificationMessageText);
