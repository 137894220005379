import React from 'react';
import {View, Text, Pressable} from 'react-native';

import {TEXT} from '../../../constants';
import {EditorContext} from '../../../contextAPI/editorContext';
import {ThemeContext} from '../../../contextAPI/themeContext';
import ModalBack from '../../renderSvgs/modalBack';
import {styles} from './editorModalHeader.style';

const EditorModalHeader = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {setOpenEditorModal} = React.useContext(EditorContext);
  const style = styles(ColorTheme);
  return (
    <View style={style.container}>
      <Text style={style.titleText}>{TEXT.ENTER_TOP_NAME}</Text>
      <Pressable onPress={setOpenEditorModal}>
        <ModalBack />
      </Pressable>
    </View>
  );
};

export default React.memo(EditorModalHeader);
