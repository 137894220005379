import * as React from 'react';
import Svg, {
  G,
  Rect,
  Path,
  Circle,
  Defs,
  ClipPath,
  SvgProps,
} from 'react-native-svg';
const MyWalletBackgroundImage = (props: SvgProps) => (
  <Svg
    width={122}
    height={176}
    viewBox="0 0 122 176"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G opacity={0.1} clipPath="url(#clip0_7504_11483)">
      <Rect
        x={-21.534}
        y={75.6913}
        width={96.6}
        height={73.6}
        rx={6.9}
        transform="rotate(-19.3102 -21.534 75.6913)"
        stroke="black"
        strokeWidth={4.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M40.4471 85.6532C38.3468 79.6592 41.5033 73.0976 47.4973 70.9973L77.8858 60.3493C81.4821 59.0892 85.4191 60.9831 86.6793 64.5794L94.285 86.2855C95.5452 89.8819 93.6513 93.8189 90.0549 95.079L59.6664 105.727C53.6725 107.827 47.1108 104.671 45.0105 98.6768L40.4471 85.6532Z"
        fill="white"
        stroke="black"
        strokeWidth={4.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M5.46822 63.4628L36.3649 36.5345L47.377 48.7781"
        stroke="black"
        strokeWidth={4.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Circle
        cx={60.0937}
        cy={86.0805}
        r={4.6}
        transform="rotate(-19.3102 60.0937 86.0805)"
        fill="black"
        stroke="white"
        strokeWidth={4.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_7504_11483">
        <Rect
          width={138}
          height={138}
          fill="white"
          transform="translate(-54 45.6338) rotate(-19.3102)"
        />
      </ClipPath>
    </Defs>
  </Svg>
);
export default MyWalletBackgroundImage;
