import React from 'react';
import {View, Text, Linking, Platform} from 'react-native';
import {WebView} from 'react-native-webview'; // Retain this for non-web platforms
import {RouteProp, useRoute} from '@react-navigation/native';

import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import {ThemeContext} from '../../contextAPI/themeContext';
import {WebViewStyle} from './styles';
import TitleWithButton from '../../components/header/titleWithButton';
import {RootStackParamList} from '../../types/navigationType';
import {isWebsite} from '../../utils/responsive';

const WebViewScreen = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = WebViewStyle(ColorTheme);
  const route = useRoute<RouteProp<RootStackParamList, 'WebViewScreen'>>();
  const {
    headerTitle,
    webUrl,
    showSupportInfo,
    renderHTML = false,
  } = route.params;

  let smsHtml = `<!DOCTYPE html>
<html lang="en"><head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Welcome to MantelTop</title>
    <!-- Google poppins font -->
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&amp;display=swap" rel="stylesheet">
    <style>
      html{
        margin: 0;
        padding: 0;
      }
      body{
        padding: 30px;
        margin: 0;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 300;
      }
      *{
        box-sizing: border-box;
      }
      h1{
        font-size: 1.5rem;
        margin: 0.5rem 0 1.5rem;
        font-weight: 600;
      }
      p{
        font-size: 0.875rem;
        margin: 0 0 1.25rem;
      }
      strong{
        font-weight: 600;
      }
      ul{
        padding-left: 30px;
      }
      li{
        font-size: 0.875rem;
        margin: 0 0 1.25rem;
      }
    </style>
</head>
<body cz-shortcut-listen="true">
    <h1>Welcome to MantelTop!</h1>
    <p>At MantelTop, we're dedicated to creating a safe and authentic community for all our users. As part of this
        commitment, we request mobile number verification during the sign-up process, and here's why it benefits you:
    </p>
    <ul>
        <li><strong>Enhanced Security:</strong> Verifying your mobile number helps us confirm your identity and ensures
            that each account is associated with a unique and valid phone number, making our platform more secure.</li>
        <li><strong>Account Recovery:</strong> Your verified mobile number provides a secure method for account recovery
            if you ever forget your password or face account-related issues, ensuring a hassle-free experience.</li>
        <li><strong>Privacy:</strong> We take your privacy seriously. Rest assured that we have stringent data
            protection measures in place. Review our Privacy Policy for more information on how we handle your data.
        </li>
    </ul>
    <p>Your trust and security are our top priorities. During the sign-up process, you'll have the choice to opt in or
        opt out of receiving SMS notifications. Thank you for being part of our community!</p>
</body></html>`;

  const handleEmailPress = () => {
    const getLink = isWebsite()
      ? 'https://mail.google.com/mail/u/0/?view=cm&amp;fs=1&amp;tf=1&amp;source=mailto&amp;to=support@manteltop.com'
      : 'mailto:support@manteltop.com';
    Linking.openURL(getLink);
  };

  const getWebView = () => {
    if (Platform.OS === 'web') {
      return (
        <iframe
          style={styles.iFrameStyle}
          src={webUrl}
          title="Web View"
          sandbox="allow-scripts allow-same-origin"
        />
      );
    } else {
      return (
        <WebView
          source={{uri: webUrl ?? ''}}
          style={{zIndex: 1, backgroundColor: 'white'}}
        />
      );
    }
  };
  return (
    <SafeAreaContainer>
      <View style={styles.container} dataSet={{media: ids.container}}>
        <TitleWithButton title={headerTitle ?? ''} />
        <View style={styles.extraTopMargin} />
        {!webUrl && !renderHTML ? (
          <View style={styles.infoView}>
            {showSupportInfo ? (
              <Text style={styles.titleText} onPress={handleEmailPress}>
                Please email{' '}
                <Text style={styles.decorateTerm}>support@manteltop.com</Text>{' '}
                with any feedback or questions. A representative will respond to
                you shortly.
              </Text>
            ) : (
              <Text style={styles.titleText}> Coming soon</Text>
            )}
          </View>
        ) : !renderHTML ? (
          getWebView()
        ) : (
          <iframe
            srcDoc={smsHtml}
            style={styles.iFrameStyle}
            title="Web View"
            sandbox="allow-scripts allow-same-origin"
          />
        )}
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(WebViewScreen);
