import {takeLatest, put, call, delay} from 'redux-saga/effects';
import {AppConst} from '../../constants';
import {IActionType} from '../../types/constantFilesTypes';
import {forgotPasswordInit} from '../../utils/apis';
import {
  onForgotPasswordEnd,
  onForgotPasswordFailed,
  onForgotPasswordSuccess,
} from '../reducers/forgotPasswordSlice';
import {isWebsite} from '../../utils/responsive';

const {StoreConstants} = AppConst;

function* forgotPassword(action: IActionType) {
  try {
    const data: object = yield call(forgotPasswordInit, action.payload);
    yield put(onForgotPasswordSuccess(data));
  } catch (error: any) {
    yield put(onForgotPasswordFailed(error.message));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onForgotPasswordEnd());
  }
}

export default function* forgotPasswordSaga() {
  yield takeLatest(
    `${StoreConstants.FORGOT_PASSWORD}/onForgotPasswordStart`,
    forgotPassword
  );
}
