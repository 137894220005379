import React from 'react';
import PrimaryButton from '../../components/button/primaryButton';

import LabelInput from '../../components/input/labelInput';
import {FixedValue, TEXT} from '../../constants';
import useCreateCollectionHooks from '../../hooks/componentHooks/useCreateNewCollectionHooks';
type CollectionModalProps = {
  isEditMode: boolean;
};
const CreateCollectionModalField = (props: CollectionModalProps) => {
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    CollectionNameError,
    isButtonDisabled,
  } = useCreateCollectionHooks();
  return (
    <>
      <LabelInput
        inputLabel={
          props.isEditMode ? TEXT.COLLECTION_NAME : TEXT.NEW_COLLECTION_NAME
        }
        value={values.CollectionName}
        onChangeText={handleChange(TEXT.COLLECTION_NAME_)}
        onBlur={handleBlur(TEXT.COLLECTION_NAME_)}
        haveError={CollectionNameError}
        maxLength={FixedValue.CONSTANT_VALUE_30}
      />
      <PrimaryButton
        buttonLabel={props.isEditMode ? TEXT.UPDATE : TEXT.CREATE_NOW}
        onPress={handleSubmit}
        isDisabled={isButtonDisabled}
      />
    </>
  );
};

export default React.memo(CreateCollectionModalField);
