import moment from 'moment-timezone';
import React from 'react';
import DateTimePicker from '@react-native-community/datetimepicker';

import {FixedValue, timeformat} from '../../constants/enum';
import {SignUpContext} from '../../contextAPI/signUpContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import {styles} from './smallDesign.style';
import {Modal, Platform, Pressable, Text, View} from 'react-native';

const DatePickerComp = (): JSX.Element => {
  const {onDateChange, dateString, showCalender, setShowCalender} =
    React.useContext(SignUpContext);
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  const [selectedDate, setSelectedDate] = React.useState<string | undefined>();

  const dateValueInDate = React.useMemo((): Date => {
    if (dateString.length > FixedValue.CONSTANT_VALUE_0) {
      return moment(dateString, timeformat.YYYY_MM_DD).toDate();
    }
    return new Date();
  }, [dateString]);

  const onDateChanged = (event: any, selectedDate: Date | undefined) => {
    if (event.type === 'dismissed') {
      setShowCalender(false);
      return;
    } else {
      let dateObj = moment(selectedDate, '').toISOString();
      setSelectedDate(dateObj);
      if (Platform.OS === 'android') {
        setShowCalender(false);
        onDateChange(dateObj ?? '');
      }
    }
  };
  const doneMethod = () => {
    setShowCalender(false);
    if (selectedDate) {
      onDateChange(selectedDate ?? '');
    } else {
      setSelectedDate(new Date().toISOString());
      onDateChange(new Date().toISOString() ?? '');
    }
  };
  const getCalendar = () => {
    if (showCalender) {
      if (Platform.OS === 'android') {
        return (
          <DateTimePicker
            testID="dateTimePicker"
            value={
              moment(dateValueInDate, timeformat.YYYY_MM_DD).toDate() ??
              new Date()
            }
            mode={'date'}
            onChange={onDateChanged}
            maximumDate={new Date()}
            display="default"
          />
        );
      } else if (Platform.OS === 'ios') {
        return (
          <Modal visible={showCalender} transparent>
            <View style={style.modalStyle}>
              <Pressable
                style={style.outterPart}
                onPress={() => setShowCalender(false)}
              />
              <View style={style.innerView}>
                <View style={style.cancelView}>
                  <Pressable
                    style={style.cancelButton}
                    onPress={() => setShowCalender(false)}
                  >
                    <Text style={style.cancelText}>Cancel</Text>
                  </Pressable>
                  <Pressable
                    style={style.cancelButton}
                    onPress={() => doneMethod()}
                  >
                    <Text style={style.doneText}>Done</Text>
                  </Pressable>
                </View>
                <DateTimePicker
                  testID="dateTimePicker"
                  mode={'date'}
                  onChange={onDateChanged}
                  maximumDate={new Date()}
                  display="spinner"
                  style={style.datePickerStyle}
                  value={
                    moment(dateValueInDate, timeformat.YYYY_MM_DD).toDate() ??
                    new Date()
                  }
                />
              </View>
            </View>
          </Modal>
        );
      } else {
        return <></>;
      }
    }
  };

  return <>{getCalendar()}</>;
};

export default React.memo(DatePickerComp);
