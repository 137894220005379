import {createSlice} from '@reduxjs/toolkit';
import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {ResetAllState} from './logoutslice';
import {IUserProfile} from '../../types/reducerTypes/userProfileTypes';
import {
  USER_PLAN_PURCHACE_ACTION,
  WALLET_TRASACTION_HISTORY,
  BLOCK_USER_LIST_ACTION_API,
  UNBLOCK_USER_ACTION_API,
  FAVORITE_USER_LIST_ACTION_API,
  USER_MARK_UNMARK_FAVORITE,
  VERIFY_IS_FAVORITE_USER,
  TRANSFER_TOP_CREDITS,
  TOP_CREDITS_PULLBACK,
} from '../../constants/actionTypes';

const {StoreConstants} = AppConst;
const {
  USER_PROFILE_DATA,
  GET_CHILD_LIST_DATA,
  GET_CHILD_PROFILE_DATA,
  RESEND_APPROVAL_REQUEST_DATA,
} = ActionType;

const initialState: IUserProfile = {
  userData: {
    isLoading: false,
    success: null,
    failed: null,
    status: USER_PROFILE_DATA.END,
  },
  childListData: {
    isLoading: false,
    success: null,
    failed: null,
    status: GET_CHILD_LIST_DATA.END,
  },
  childProfileData: {
    isLoading: false,
    success: null,
    failed: null,
    status: GET_CHILD_PROFILE_DATA.END,
  },
  resendApprovalRequestData: {
    isLoading: false,
    success: null,
    failed: null,
    status: RESEND_APPROVAL_REQUEST_DATA.END,
  },
  userPlanPurchaseData: {
    isLoading: false,
    success: null,
    failed: null,
    status: USER_PLAN_PURCHACE_ACTION.END,
  },
  walletTrasactionList: {
    isLoading: false,
    success: null,
    failed: null,
    status: WALLET_TRASACTION_HISTORY.END,
  },
  blockUserData: {
    isLoading: false,
    success: null,
    failed: null,
    status: BLOCK_USER_LIST_ACTION_API.END,
  },
  unBlockUser: {
    isLoading: false,
    success: null,
    failed: null,
    status: UNBLOCK_USER_ACTION_API.END,
  },
  favoriteUserListData: {
    isLoading: false,
    success: null,
    failed: null,
    status: FAVORITE_USER_LIST_ACTION_API.END,
  },
  userIsFavouriteData: {
    isLoading: false,
    success: null,
    failed: null,
    status: FAVORITE_USER_LIST_ACTION_API.END,
  },
  userMarkAndUnmarkFavoriteData: {
    isLoading: false,
    success: null,
    failed: null,
    status: USER_MARK_UNMARK_FAVORITE.END,
  },
  transferTopCreditData: {
    isLoading: false,
    success: null,
    failed: null,
    status: TRANSFER_TOP_CREDITS.END,
  },
  topCreditPullbackData: {
    isLoading: false,
    success: null,
    failed: null,
    status: TOP_CREDITS_PULLBACK.END,
  },
};

const UserProfileSlice = createSlice({
  initialState,
  name: StoreConstants.USER_PROFILE,
  reducers: {
    onUserDataStart: state => {
      state.userData.isLoading = true;
      state.userData.status = USER_PROFILE_DATA.START;
    },
    onUserDataSuccess: (state, action) => {
      state.userData.success = action.payload;
      state.userData.status = USER_PROFILE_DATA.SUCCESS;
    },
    onUserDataFailed: (state, action) => {
      state.userData.failed = action.payload;
      state.userData.status = USER_PROFILE_DATA.FAILED;
    },
    onUserDataEnd: state => {
      state.userData.isLoading = false;
      state.userData.status = USER_PROFILE_DATA.END;
    },
    onUserChildListStart: state => {
      state.childListData.isLoading = true;
      state.childListData.status = GET_CHILD_LIST_DATA.START;
    },
    onUserChildListSuccess: (state, action) => {
      state.childListData.success = action.payload;
      state.childListData.status = GET_CHILD_LIST_DATA.SUCCESS;
    },
    onUserChildListFailed: (state, action) => {
      state.childListData.failed = action.payload;
      state.childListData.status = GET_CHILD_LIST_DATA.FAILED;
    },
    onUserChildListEnd: state => {
      state.childListData.isLoading = false;
      state.childListData.status = GET_CHILD_LIST_DATA.END;
    },
    onUserChildProfileStart: (state, _) => {
      state.childProfileData.isLoading = true;
      state.childProfileData.success = null;
      state.childProfileData.status = GET_CHILD_PROFILE_DATA.START;
    },
    onUserChildProfileSuccess: (state, action) => {
      state.childProfileData.failed = null;
      state.childProfileData.success = action.payload;
      state.childProfileData.status = GET_CHILD_PROFILE_DATA.SUCCESS;
    },
    onUserChildProfileFailed: (state, action) => {
      state.childProfileData.failed = action.payload;
      state.childProfileData.success = null;
      state.childProfileData.status = GET_CHILD_PROFILE_DATA.FAILED;
    },
    onUserChildProfileEnd: state => {
      state.childProfileData.isLoading = false;
      state.childProfileData.success = null;
      state.childProfileData.status = GET_CHILD_PROFILE_DATA.END;
    },
    onupdateProfile: (state, action) => {
      state.userData.success = {...state.userData.success, ...action.payload};
    },
    onUpdateProfilePicture: (state, action) => {
      state.userData.success = {...state.userData.success, ...action.payload};
    },
    onResendApprovalRequestStart: state => {
      state.resendApprovalRequestData.isLoading = true;
      state.resendApprovalRequestData.status =
        RESEND_APPROVAL_REQUEST_DATA.START;
    },
    onResendApprovalRequestSuccess: (state, action) => {
      state.resendApprovalRequestData.success = action.payload;
      state.resendApprovalRequestData.status =
        RESEND_APPROVAL_REQUEST_DATA.SUCCESS;
    },
    onResendApprovalRequestFailed: (state, action) => {
      state.resendApprovalRequestData.failed = action.payload;
      state.resendApprovalRequestData.status =
        RESEND_APPROVAL_REQUEST_DATA.FAILED;
    },
    onResendApprovalRequestEnd: state => {
      state.resendApprovalRequestData.isLoading = false;
      state.resendApprovalRequestData.status = RESEND_APPROVAL_REQUEST_DATA.END;
    },
    onUserPlanPurchaseStart: state => {
      state.userPlanPurchaseData.isLoading = true;
      state.userPlanPurchaseData.status = USER_PLAN_PURCHACE_ACTION.START;
    },
    onUserPlanPurchaseSuccess: (state, action) => {
      state.userPlanPurchaseData.success = action.payload;
      state.userPlanPurchaseData.status = USER_PLAN_PURCHACE_ACTION.SUCCESS;
    },
    onUserPlanPurchaseFailed: (state, action) => {
      state.userPlanPurchaseData.failed = action.payload;
      state.userPlanPurchaseData.status = USER_PLAN_PURCHACE_ACTION.FAILED;
    },
    onUserPlanPurchaseEnd: state => {
      state.userPlanPurchaseData.isLoading = false;
      state.userPlanPurchaseData.status = USER_PLAN_PURCHACE_ACTION.END;
    },
    onWalletTransactionHistoryStart: state => {
      state.walletTrasactionList.isLoading = true;
      state.walletTrasactionList.status = WALLET_TRASACTION_HISTORY.START;
    },
    onWalletTransactionHistorySuccess: (state, action) => {
      state.walletTrasactionList.success = action.payload;
      state.walletTrasactionList.status = WALLET_TRASACTION_HISTORY.SUCCESS;
    },
    onWalletTransactionHistoryFailed: (state, action) => {
      state.walletTrasactionList.failed = action.payload;
      state.walletTrasactionList.status = WALLET_TRASACTION_HISTORY.FAILED;
    },
    onWalletTransactionHistoryEnd: state => {
      state.walletTrasactionList.isLoading = false;
      state.walletTrasactionList.status = WALLET_TRASACTION_HISTORY.END;
    },
    onUserBlockListStart: (state, _) => {
      state.blockUserData.isLoading = true;
      state.blockUserData.status = BLOCK_USER_LIST_ACTION_API.START;
    },
    onUserBlockListSuccess: (state, action) => {
      state.blockUserData.success = action.payload;
      state.blockUserData.status = BLOCK_USER_LIST_ACTION_API.SUCCESS;
    },
    onUserBlockListFailed: (state, action) => {
      state.blockUserData.failed = action.payload;
      state.blockUserData.status = BLOCK_USER_LIST_ACTION_API.FAILED;
    },
    onUserBlockListEnd: state => {
      state.blockUserData.isLoading = false;
      state.blockUserData.status = BLOCK_USER_LIST_ACTION_API.END;
    },
    onUserUnBlockStart: (state, _) => {
      state.unBlockUser.isLoading = true;
      state.unBlockUser.status = UNBLOCK_USER_ACTION_API.START;
    },
    onUserUnBlockSuccess: (state, action) => {
      state.unBlockUser.success = action.payload;
      state.unBlockUser.status = UNBLOCK_USER_ACTION_API.SUCCESS;
    },
    onUserUnBlockFailed: (state, action) => {
      state.unBlockUser.failed = action.payload;
      state.unBlockUser.status = UNBLOCK_USER_ACTION_API.FAILED;
    },
    onUserUnBlockEnd: state => {
      state.unBlockUser.isLoading = false;
      state.unBlockUser.status = UNBLOCK_USER_ACTION_API.END;
    },
    onFavoriteUserListStart: (state, _) => {
      state.favoriteUserListData.isLoading = true;
      state.favoriteUserListData.status = FAVORITE_USER_LIST_ACTION_API.START;
    },
    onFavoriteUserListSuccess: (state, action) => {
      state.favoriteUserListData.success = action.payload;
      state.favoriteUserListData.status = FAVORITE_USER_LIST_ACTION_API.SUCCESS;
    },
    onFavoriteUserListFailed: (state, action) => {
      state.favoriteUserListData.failed = action.payload;
      state.favoriteUserListData.status = FAVORITE_USER_LIST_ACTION_API.FAILED;
    },
    onFavoriteUserListEnd: state => {
      state.favoriteUserListData.isLoading = false;
      state.favoriteUserListData.status = FAVORITE_USER_LIST_ACTION_API.END;
    },
    onClearFavoriteUserListEnd: state => {
      state.favoriteUserListData.isLoading = false;
      state.favoriteUserListData.success = null;
      state.favoriteUserListData.failed = null;
      state.favoriteUserListData.status = FAVORITE_USER_LIST_ACTION_API.END;
    },
    onIsUserIsFavoriteStart: (state, _) => {
      state.userIsFavouriteData.isLoading = true;
      state.userIsFavouriteData.status = VERIFY_IS_FAVORITE_USER.START;
    },
    onIsUserIsFavoriteSuccess: (state, action) => {
      state.userIsFavouriteData.success = action.payload;
      state.userIsFavouriteData.status = VERIFY_IS_FAVORITE_USER.SUCCESS;
    },
    onIsUserIsFavoriteFailed: (state, action) => {
      state.userIsFavouriteData.failed = action.payload;
      state.userIsFavouriteData.status = VERIFY_IS_FAVORITE_USER.FAILED;
    },
    onIsUserIsFavoriteEnd: state => {
      state.userIsFavouriteData.isLoading = false;
      state.userIsFavouriteData.status = VERIFY_IS_FAVORITE_USER.END;
    },
    onUserMarkAndUnmarkFavoriteStart: (state, _) => {
      state.userMarkAndUnmarkFavoriteData.isLoading = true;
      state.userMarkAndUnmarkFavoriteData.status =
        USER_MARK_UNMARK_FAVORITE.START;
    },
    onUserMarkAndUnmarkFavoriteSuccess: (state, action) => {
      state.userMarkAndUnmarkFavoriteData.success = action.payload;
      state.userMarkAndUnmarkFavoriteData.status =
        USER_MARK_UNMARK_FAVORITE.SUCCESS;
    },
    onUserMarkAndUnmarkFavoriteFailed: (state, action) => {
      state.userMarkAndUnmarkFavoriteData.failed = action.payload;
      state.userMarkAndUnmarkFavoriteData.status =
        USER_MARK_UNMARK_FAVORITE.FAILED;
    },
    onUserMarkAndUnmarkFavoriteEnd: state => {
      state.userMarkAndUnmarkFavoriteData.isLoading = false;
      state.userMarkAndUnmarkFavoriteData.status =
        USER_MARK_UNMARK_FAVORITE.END;
    },
    onTransferTopCreditStart: (state, _) => {
      state.transferTopCreditData.isLoading = true;
      state.transferTopCreditData.status = TRANSFER_TOP_CREDITS.START;
    },
    onTransferTopCreditSuccess: (state, action) => {
      state.transferTopCreditData.success = action.payload;
      state.transferTopCreditData.status = TRANSFER_TOP_CREDITS.SUCCESS;
    },
    onTransferTopCreditFailed: (state, action) => {
      state.transferTopCreditData.failed = action.payload;
      state.transferTopCreditData.status = TRANSFER_TOP_CREDITS.FAILED;
    },
    onTransferTopCreditEnd: state => {
      state.transferTopCreditData.isLoading = false;
      state.transferTopCreditData.status = TRANSFER_TOP_CREDITS.END;
    },
    onTopCreditPullbackStart: (state, _) => {
      state.topCreditPullbackData.isLoading = true;
      state.topCreditPullbackData.status = TOP_CREDITS_PULLBACK.START;
    },
    onTopCreditPullbackSuccess: (state, action) => {
      state.topCreditPullbackData.success = action.payload;
      state.topCreditPullbackData.status = TOP_CREDITS_PULLBACK.SUCCESS;
    },
    onTopCreditPullbackFailed: (state, action) => {
      state.topCreditPullbackData.failed = action.payload;
      state.topCreditPullbackData.status = TOP_CREDITS_PULLBACK.FAILED;
    },
    onTopCreditPullbackEnd: state => {
      state.topCreditPullbackData.isLoading = false;
      state.topCreditPullbackData.status = TOP_CREDITS_PULLBACK.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  onUserDataStart,
  onUserDataSuccess,
  onUserDataFailed,
  onUserDataEnd,
  onUserChildListStart,
  onUserChildListSuccess,
  onUserChildListFailed,
  onUserChildListEnd,
  onUserChildProfileStart,
  onUserChildProfileSuccess,
  onUserChildProfileEnd,
  onUserChildProfileFailed,
  onupdateProfile,
  onUpdateProfilePicture,
  onResendApprovalRequestStart,
  onResendApprovalRequestSuccess,
  onResendApprovalRequestFailed,
  onResendApprovalRequestEnd,
  onUserPlanPurchaseStart,
  onUserPlanPurchaseSuccess,
  onUserPlanPurchaseFailed,
  onUserPlanPurchaseEnd,
  onWalletTransactionHistoryStart,
  onWalletTransactionHistorySuccess,
  onWalletTransactionHistoryFailed,
  onWalletTransactionHistoryEnd,
  onUserBlockListStart,
  onUserBlockListSuccess,
  onUserBlockListFailed,
  onUserBlockListEnd,
  onUserUnBlockStart,
  onUserUnBlockSuccess,
  onUserUnBlockFailed,
  onUserUnBlockEnd,
  onFavoriteUserListStart,
  onFavoriteUserListSuccess,
  onFavoriteUserListFailed,
  onFavoriteUserListEnd,
  onClearFavoriteUserListEnd,
  onIsUserIsFavoriteStart,
  onIsUserIsFavoriteSuccess,
  onIsUserIsFavoriteFailed,
  onIsUserIsFavoriteEnd,
  onUserMarkAndUnmarkFavoriteStart,
  onUserMarkAndUnmarkFavoriteSuccess,
  onUserMarkAndUnmarkFavoriteFailed,
  onUserMarkAndUnmarkFavoriteEnd,
  onTransferTopCreditStart,
  onTransferTopCreditSuccess,
  onTransferTopCreditFailed,
  onTransferTopCreditEnd,
  onTopCreditPullbackStart,
  onTopCreditPullbackSuccess,
  onTopCreditPullbackFailed,
  onTopCreditPullbackEnd,
} = UserProfileSlice.actions;
export default UserProfileSlice.reducer;
export const userProfileStore = (state: RootState) => state.userProfileStore;
