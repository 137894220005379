import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';
const DeleteWithBackground = (props: SvgProps) => (
  <Svg
    width={17}
    height={17}
    viewBox="0 0 17 17"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M3.50195 5.48633H13.9878"
      stroke="#FF3D3D"
      strokeWidth={1.41559}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M7.43457 8.10791V12.0401"
      stroke="#FF3D3D"
      strokeWidth={1.41559}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10.0557 8.10791V12.0401"
      stroke="#FF3D3D"
      strokeWidth={1.41559}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M4.48535 5.48633L4.81304 13.3507C4.81304 13.6984 4.95113 14.0318 5.19694 14.2776C5.44275 14.5234 5.77614 14.6615 6.12377 14.6615H11.3667C11.7143 14.6615 12.0477 14.5234 12.2935 14.2776C12.5393 14.0318 12.6774 13.6984 12.6774 13.3507L13.0051 5.48633"
      stroke="#FF3D3D"
      strokeWidth={1.41559}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M6.7793 5.48621V3.52011C6.7793 3.3463 6.84834 3.1796 6.97125 3.0567C7.09415 2.93379 7.26085 2.86475 7.43466 2.86475H10.0561C10.2299 2.86475 10.3966 2.93379 10.5195 3.0567C10.6425 3.1796 10.7115 3.3463 10.7115 3.52011V5.48621"
      stroke="#FF3D3D"
      strokeWidth={1.41559}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default DeleteWithBackground;
