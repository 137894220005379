import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath, Rect, SvgProps} from 'react-native-svg';

const TransferTopIcon = (props: SvgProps) => (
  <Svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G clipPath="url(#clip0_10305_12700)">
      <Path
        d="M19.7909 7.3518L14.7908 2.35171C14.5118 2.0728 14.0596 2.07288 13.7806 2.35188C13.6468 2.48581 13.5715 2.66737 13.5715 2.85675V4.99964H8.57141C8.1769 4.99964 7.8571 5.31945 7.8571 5.71396C7.8571 6.10847 8.1769 6.42827 8.57141 6.42827H14.2858C14.6803 6.42827 15.0001 6.10847 15.0001 5.71396V4.58103L18.2759 7.8568L15.0001 11.1326V9.9997C15.0001 9.60519 14.6803 9.28539 14.2858 9.28539H6.42852V7.14249C6.42843 6.74798 6.10855 6.42827 5.71408 6.42835C5.52474 6.42839 5.34313 6.5036 5.2092 6.63749L0.209152 11.6376C-0.0697172 11.9165 -0.0697172 12.3687 0.209152 12.6476L5.20925 17.6477C5.34318 17.7817 5.52482 17.857 5.71425 17.857C5.80813 17.8573 5.90117 17.8388 5.9878 17.8027C6.25466 17.692 6.4286 17.4316 6.42852 17.1427V14.9998H11.4286C11.8231 14.9998 12.1429 14.68 12.1429 14.2855C12.1429 13.891 11.8231 13.5712 11.4286 13.5712H5.71425C5.31974 13.5712 4.99994 13.891 4.99994 14.2855V15.4184L1.72416 12.1426L4.99994 8.86681V9.9997C4.99994 10.3942 5.31974 10.714 5.71425 10.714H13.5715V12.8569C13.5716 13.2514 13.8915 13.5711 14.2859 13.571C14.4753 13.571 14.6569 13.4958 14.7908 13.3619L19.7909 8.36181C20.0697 8.08286 20.0697 7.63071 19.7909 7.3518Z"
        fill="#1F53D3"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_10305_12700">
        <Rect width={20} height={20} fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default TransferTopIcon;
