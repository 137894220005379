import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';

const LeftRotate = (props: SvgProps) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M21 11V8C21 6.4087 20.3679 4.88258 19.2426 3.75736C18.1174 2.63214 16.5913 2 15 2H9L12 5"
      stroke={props.stroke ?? '#4EAF51'}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M14 10H6C4.89543 10 4 10.8954 4 12V20C4 21.1046 4.89543 22 6 22H14C15.1046 22 16 21.1046 16 20V12C16 10.8954 15.1046 10 14 10Z"
      stroke={props.stroke ?? '#4EAF51'}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </Svg>
);
export default LeftRotate;
