import StyleSheet from 'react-native-media-query';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {
  BASESTYLE,
  COLORS,
  FONT_NAMES,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../../constants';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const DistributingApproveListStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.selfCenter,
      width: Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
    },
    mainView: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      width: Percentage.PRECENTAGE_100,
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
    },
    imageView: {
      ...BASESTYLE.inRow,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    imageStyle: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      overflow: GlobalStyleValues.HIDDEN,
    },
    listname: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: _theme?.color,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_200),
    },
    circle: {
      ...BASESTYLE.inRow,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    buttonStyle: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_179),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
    },
    buttonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
    buttonView: {
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.absolutePosition,
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      left: scaleWidth(FixedValue.CONSTANT_VALUE_0),
      right: scaleWidth(FixedValue.CONSTANT_VALUE_0),
    },
    emptyView: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_100),
    },
    marginTop20: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    flatListContentContainerStyle: {
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_70),
    },
  });
