import React from 'react';
import {View, Pressable, ActivityIndicator} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {TopModel} from '../../types/reducerTypes/searchTabStoreType';
import {style} from './style';
import {COLORS} from '../../constants';
import useAwsS3 from '../../hooks/libraryHooks/useAwsS3';
import CircularView from '../CircularView';
import {getTopImage} from '../../utils/imageUtils';
interface ISelectedTopComponentProps {
  item: TopModel;
}

const SelectedTopComponent = (
  props: ISelectedTopComponentProps
): JSX.Element => {
  const {item} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const styles = style(ColorTheme);
  const {loadSignedUrl} = useAwsS3();
  const [svgCode, setSvgCode] = React.useState<JSX.Element | undefined>(
    undefined
  );

  const getSvgUrl = React.useCallback(async (): Promise<void> => {
    const imageUrl = await loadSignedUrl(item?.image ?? '');
    if (imageUrl) {
      const topImage = await getTopImage(imageUrl ?? '');
      setSvgCode(topImage);
    }
  }, [item?.image, svgCode]);

  React.useEffect(() => {
    getSvgUrl();
  }, []);

  return (
    <Pressable style={styles.imageMainContainer} onPress={() => {}}>
      {svgCode ? (
        <View style={styles.topImageContainer}>{svgCode}</View>
      ) : (
        <View style={styles.progressLoader}>
          <ActivityIndicator size={'small'} color={COLORS.PRIMARY_BLUE} />
        </View>
      )}
      <CircularView
        quantity={item.selectedQuantity?.toString() ?? ''}
        isSmall
      />
    </Pressable>
  );
};

export default React.memo(SelectedTopComponent);
