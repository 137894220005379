/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import ScrollableContainer from '../../components/layout/scrollableContainer';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import ForgotUserNameContentView from './forgotUserNameContent';
import {_ToastHandler} from '../../utils/helperFunctions';
import {useIsFocused} from '@react-navigation/native';
import {HomeContext} from '../../contextAPI/homeContext';

const ForgotUserName: React.FC = () => {
  const isFocused = useIsFocused();
  const {setHomeScreenUserData} = React.useContext(HomeContext);

  React.useEffect(() => {
    if (isFocused) {
      setHomeScreenUserData(null);
    }
  }, [isFocused]);

  return (
    <ScrollableContainer>
      <SafeAreaContainer>
        <ForgotUserNameContentView />
      </SafeAreaContainer>
    </ScrollableContainer>
  );
};

export default React.memo(ForgotUserName);
