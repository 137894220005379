import React from 'react';
import {RouteProp, useRoute} from '@react-navigation/native';

import {RootStackParamList} from '../../types/navigationType';
import useAwsS3 from '../libraryHooks/useAwsS3';
import {TopDetailContext} from '../../contextAPI/topDetailContext';
import {useAppDispatch, useAppSelector} from '../reduxHooks';
import {onTradableTopStart} from '../../redux/reducers/tradableTopsSlice';
import {
  ITradableParams,
  NotesModel,
} from '../../types/reducerTypes/searchTabStoreType';
import {getTopImage} from '../../utils/imageUtils';
import {ERROR_MESSAGES, FixedValue, TEXT} from '../../constants';
import {NotesSchema} from '../../utils/formikSchema';
import {useFormik} from 'formik';
import {
  onNotesAddStart,
  onNotesDeleteStart,
  onNotesListStart,
  onNotesUpdateStart,
  searchTabStore,
} from '../../redux/reducers/searchTabSlice';
import {Alert, Platform} from 'react-native';
import {
  NOTES_ADD,
  NOTES_DELETE,
  NOTES_UPDATE,
} from '../../constants/actionTypes';
import {_ToastHandler, alertPolyfill} from '../../utils/helperFunctions';
import {TOAST_TEXT} from '../../constants/textMessage';

export type NotesField = {Note: string};
const useTopDetails = () => {
  const route = useRoute<RouteProp<RootStackParamList, 'TopDetailScreen'>>();
  const {
    setSvgCode,
    svgCode,
    isNotesEditMode,
    setIsNotesEditMode,
    setNotesData,
    notesData,
    setOpenNotesAddEditModal,
  } = React.useContext(TopDetailContext);
  const {loadSignedUrl} = useAwsS3();
  const dispatch = useAppDispatch();
  const {topDetails} = route.params;
  const {
    notesListResponse,
    notesAddResponse,
    notesUpdateResponse,
    notesDeleteResponse,
  } = useAppSelector(searchTabStore);
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isValid,
    values,
    setValues,
    resetForm,
  } = useFormik<NotesField>({
    validationSchema: NotesSchema,
    initialErrors: {
      Note: ERROR_MESSAGES.REQUIRED_NOTE,
    },
    initialTouched: {Note: false},
    initialValues: {Note: ''},
    onSubmit: val => {
      callAddOrEditNotes(val.Note);
    },
  });
  const NoteError: string = touched.Note && errors.Note ? errors.Note : '';

  const getSvgUrl = React.useCallback(
    async (image: string): Promise<void> => {
      const imageUrl = await loadSignedUrl(image ?? '');
      if (imageUrl) {
        const topImage = await getTopImage(imageUrl ?? '');
        setSvgCode(topImage);
      }
    },
    [svgCode, topDetails]
  );

  const markTopTradableAPI = React.useCallback(
    (model: ITradableParams): void => {
      dispatch(onTradableTopStart(model));
    },
    []
  );
  //call notes list API
  const callNotesList = React.useCallback(
    (page: number): void => {
      const params = {
        page,
        size: FixedValue.CONSTANT_VALUE_15,
        topId: topDetails?.id,
      };
      dispatch(onNotesListStart(params));
    },
    [dispatch, topDetails]
  );

  //on end reach for notes list
  const onReachedEndNotesList = React.useCallback((): void => {
    if (
      notesListResponse.success != null &&
      notesListResponse.success.pageNumber <
        notesListResponse.success.totalPages
    ) {
      callNotesList(
        notesListResponse.success.pageNumber + FixedValue.CONSTANT_VALUE_1
      );
    }
  }, [notesListResponse, topDetails]);

  //add or edit notes
  const callAddOrEditNotes = React.useCallback(
    async (noteText: string): Promise<void> => {
      if (isNotesEditMode) {
        dispatch(onNotesUpdateStart({noteText, noteId: notesData?.id}));
      } else {
        dispatch(onNotesAddStart({noteText, topId: topDetails?.id}));
      }
    },
    [topDetails, isNotesEditMode, notesData]
  );

  //when user click on edit notes
  const onCallEditNotes = React.useCallback(
    (data: NotesModel): void => {
      setNotesData(data);
      setIsNotesEditMode(true);
      setOpenNotesAddEditModal(true);
    },
    [topDetails, notesData]
  );

  //reset notesdata
  const commonFunctionForNotes = React.useCallback((): void => {
    callNotesList(FixedValue.CONSTANT_VALUE_0);
    setIsNotesEditMode(false);
    setOpenNotesAddEditModal(false);
    resetForm();
    setNotesData(null);
  }, []);

  //on call close notes modal
  const onCloseNoteModal = React.useCallback((val: boolean): void => {
    setIsNotesEditMode(val);
    setOpenNotesAddEditModal(val);
    setNotesData(null);
  }, []);

  //on add notes modal open
  const onAddOpenNoteModal = React.useCallback((): void => {
    setIsNotesEditMode(false);
    setNotesData(null);
    setOpenNotesAddEditModal(true);
  }, []);

  //on delete notes api call
  const onDeleteNotes = React.useCallback(
    (noteId: number): void => {
      dispatch(onNotesDeleteStart({noteId}));
    },
    [notesData]
  );

  //on delete notes alert show
  const onDeleteNotesAlert = React.useCallback((data: NotesModel): void => {
    if (Platform.OS === 'web') {
      alertPolyfill(TEXT.DELETE_NOTE, TEXT.YOU_WANNA_DELETE_THIS_NOTE, [
        {
          text: TEXT.NO,
          style: 'cancel',
          onPress: () => {},
        },
        {
          text: TEXT.YES,
          onPress: () => onDeleteNotes(data?.id),
        },
      ]);
    } else {
      Alert.alert(
        TEXT.DELETE_NOTE,
        TEXT.YOU_WANNA_DELETE_THIS_NOTE,
        [
          {
            text: TEXT.NO,
            onPress: () => {},
            style: 'cancel',
          },
          {
            text: TEXT.YES,
            onPress: () => onDeleteNotes(data?.id),
          },
        ],
        {cancelable: false}
      );
    }
  }, []);

  //notes add response handle
  React.useEffect(() => {
    switch (notesAddResponse.status) {
      case NOTES_ADD.SUCCESS:
        _ToastHandler(TOAST_TEXT.NOTE_ADDED, true);
        commonFunctionForNotes();
        break;
      case NOTES_ADD.FAILED:
        break;
      default:
        break;
    }
  }, [notesAddResponse.status]);

  //notes update response handle
  React.useEffect(() => {
    switch (notesUpdateResponse.status) {
      case NOTES_UPDATE.SUCCESS:
        _ToastHandler(TOAST_TEXT.NOTE_UPDATED, true);
        commonFunctionForNotes();
        break;
      case NOTES_UPDATE.FAILED:
        break;
      default:
        break;
    }
  }, [notesUpdateResponse.status]);

  //notes delete response handle
  React.useEffect(() => {
    switch (notesDeleteResponse.status) {
      case NOTES_DELETE.SUCCESS:
        _ToastHandler(TOAST_TEXT.NOTE_DELETED, true);
        callNotesList(FixedValue.CONSTANT_VALUE_0);
        break;
      case NOTES_DELETE.FAILED:
        break;
      default:
        break;
    }
  }, [notesDeleteResponse.status]);

  return {
    getSvgUrl,
    markTopTradableAPI,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isValid,
    values,
    setValues,
    resetForm,
    callNotesList,
    onReachedEndNotesList,
    NoteError,
    onCallEditNotes,
    onCloseNoteModal,
    onAddOpenNoteModal,
    onDeleteNotes,
    onDeleteNotesAlert,
  };
};

export default useTopDetails;
