import StyleSheet from 'react-native-media-query';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {FONT_NAMES, FixedValue, Percentage, BASESTYLE} from '../../constants';

export const parentConsentStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      paddingHorizontal: isWebsite()
        ? 0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_12),
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    titleText: {
      color: theme.color,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_30),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_36),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_32),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_BOLD,
    },
    descText: {
      color: theme.color,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      marginBottom: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    buttonStyle: {
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_40),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_30),
    },
  });
