import React from 'react';
import {View} from 'react-native';

import {COLORS, FixedValue, TabBarName} from '../constants';
import {TabBarIconsProps} from '../types/navigationType';
import {ThemeContext} from '../contextAPI/themeContext';

import MentalIcon from '../components/renderSvgs/mentalIcon';
import TradeIcon from '../components/renderSvgs/tradeIcon';
import SearchIcon from '../components/renderSvgs/searchIcon';
import NotificationIcon from '../components/renderSvgs/notificationIcon';
import ProfileIcon from '../components/renderSvgs/profileIcon';
import {navigationStyle} from './navigation.styles';
import {NotificationContext} from '../contextAPI/notificationContext';
import TrackingIcon from '../components/renderSvgs/tracking';

const GetTabBarIcons = (props: TabBarIconsProps): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = navigationStyle(ColorTheme);
  const {getUnReadCount} = React.useContext(NotificationContext);

  switch (props.TabName) {
    case TabBarName.MENTAL:
      return (
        <MentalIcon
          stroke={props.stroke}
          fill={props.focused ? props.stroke : ColorTheme.backgroundColor}
        />
      );
    case TabBarName.TRADE:
      return <TradeIcon stroke={props.stroke} />;
    case TabBarName.TRACKING:
      return <TrackingIcon stroke={props.stroke} />;
    case TabBarName.NOTIFICATION:
      return (
        <>
          <NotificationIcon
            stroke={props.stroke}
            fill={props.focused ? props.stroke : ColorTheme.backgroundColor}
          />
          {getUnReadCount > FixedValue.CONSTANT_VALUE_0 && (
            <View style={styles.notificationUnReadIcon} />
          )}
        </>
      );
    case TabBarName.PROFILE:
      return (
        <ProfileIcon
          stroke={props.stroke}
          fill={props.focused ? props.stroke : ColorTheme.backgroundColor}
        />
      );

    default:
      return <View />;
  }
};

export default React.memo(GetTabBarIcons);
