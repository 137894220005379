import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
const DollarImage = (props: SvgProps) => (
  <Svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    //@ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M13 26C20.1118 26 26 20.099 26 13C26 5.88824 20.099 0 12.9873 0C5.88824 0 0 5.88824 0 13C0 20.099 5.90098 26 13 26ZM13 23.8333C6.98431 23.8333 2.17941 19.0157 2.17941 13C2.17941 6.98431 6.97157 2.16667 12.9873 2.16667C19.0029 2.16667 23.8206 6.98431 23.8333 13C23.8461 19.0157 19.0157 23.8333 13 23.8333ZM12.9873 20.2775C13.3314 20.2775 13.5863 20.0353 13.5863 19.6784V18.7863C15.5618 18.5951 17.1039 17.448 17.1039 15.5235C17.1039 13.7902 15.9824 12.7451 13.7647 12.35L13.5863 12.3118V8.75588C14.5039 8.92157 15.1412 9.46961 15.3961 10.3235C15.5235 10.7059 15.7657 10.948 16.1735 10.948C16.6069 10.948 16.9255 10.6422 16.9255 10.1961C16.9255 10.0686 16.8873 9.94118 16.8618 9.80098C16.5304 8.42451 15.2176 7.48137 13.5863 7.2902V6.41078C13.5863 6.05392 13.3314 5.81176 12.9873 5.81176C12.6431 5.81176 12.3882 6.05392 12.3882 6.41078V7.2902C10.451 7.50686 9.07451 8.71765 9.07451 10.451C9.07451 12.0696 10.1706 13.0382 12.299 13.5353L12.3882 13.5608V17.3333C11.2412 17.1676 10.5657 16.5304 10.3618 15.6765C10.2088 15.2431 9.95392 15.0392 9.57157 15.0392C9.13824 15.0392 8.83235 15.3451 8.83235 15.7912C8.83235 15.9314 8.85784 16.0588 8.89608 16.1863C9.25294 17.652 10.6804 18.5951 12.3882 18.7608V19.6784C12.3882 20.0353 12.6431 20.2775 12.9873 20.2775ZM12.3882 11.9931L12.299 11.9676C11.152 11.6363 10.6422 11.1265 10.6422 10.3745C10.6422 9.59706 11.3049 8.94706 12.3882 8.75588V11.9931ZM13.5863 13.8667L13.8667 13.9304C15.1029 14.2745 15.5235 14.7971 15.5235 15.6127C15.5235 16.5049 14.8863 17.1804 13.5863 17.3333V13.8667Z"
      fill="#1F53D3"
    />
  </Svg>
);
export default DollarImage;
