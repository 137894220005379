import {Pressable, View} from 'react-native';
import React, {useEffect, useState} from 'react';
import TitleWithButton from '../../components/header/titleWithButton';
import {FixedValue, IMAGES, ROUTE_NAME, TEXT} from '../../constants';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import {ThemeContext} from '../../contextAPI/themeContext';
import {TutorialsSyle} from './tutorials.style';
import {FlatList, Image, Text} from 'native-base';
import {
  onTutorialListStart,
  tutorialListState,
} from '../../redux/reducers/tutorialListSlice';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import {useIsFocused} from '@react-navigation/native';
import {TUTORIAL_LIST} from '../../constants/actionTypes';
import {TutorialListResultsModel} from '../../types/componentTypes/childProfileType';
import {navigate} from '../../services/navigationService';
import {formatTutorialVideoTitle} from '../../utils/helperFunctions';

const Tutorials = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = TutorialsSyle(ColorTheme);
  const numColumns = 2;
  const dispatch = useAppDispatch();
  const isFocused = useIsFocused();
  const {tutorialList} = useAppSelector(tutorialListState);
  const [videoList, setVideoList] = useState<TutorialListResultsModel[] | []>(
    []
  );
  const apiHit = (page: number) => {
    dispatch(onTutorialListStart(page));
  };
  useEffect(() => {
    if (isFocused) {
      apiHit(FixedValue.CONSTANT_VALUE_0);
    }
  }, [isFocused]);

  React.useEffect(() => {
    switch (tutorialList.status) {
      case TUTORIAL_LIST.END:
        const listData = tutorialList.data ?? [];
        setVideoList(listData);
        break;
      case TUTORIAL_LIST.FAILED:
        setVideoList([]);
        break;
      default:
        break;
    }
  }, [tutorialList]);

  const renderItem = ({item}: {item: TutorialListResultsModel}) => {
    return (
      <Pressable
        style={styles.tile}
        onPress={() =>
          navigate(ROUTE_NAME.FULLSCREEN_PLAYER, {
            videoUri: item.url,
            name: item?.title,
          })
        }
      >
        <Image
          source={item?.thumbnailUr ? {uri: item?.thumbnailUr} : IMAGES.LOGOO}
          style={styles.thumbnail}
        />
        <Text style={styles.name} numberOfLines={FixedValue.CONSTANT_VALUE_1}>
          {formatTutorialVideoTitle(item.title)}
        </Text>
      </Pressable>
    );
  };

  return (
    <SafeAreaContainer>
      <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
        <View style={styles.headerView}>
          <TitleWithButton title={TEXT.TUTORIALS} />
        </View>
        <FlatList
          data={videoList}
          renderItem={renderItem}
          keyExtractor={item => item?.id?.toString()}
          numColumns={numColumns}
          contentContainerStyle={styles.listContent}
          style={{marginHorizontal: FixedValue.CONSTANT_VALUE_15}}
        />
      </View>
    </SafeAreaContainer>
  );
};

export default Tutorials;
