import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {isWebsite, scaleHeight, scaleWidth} from '../../utils/responsive';

export const PayPalModalStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      justifyContent: GlobalStyleValues.FLEX_END,
      width: isWebsite()
        ? FixedValue.CONSTANT_VALUE_500
        : Percentage.PRECENTAGE_100,
      alignItems: GlobalStyleValues.CENTER,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    innerView: {
      ...BASESTYLE.flex1,
      backgroundColor: _theme?.backgroundColor,
      width: Percentage.PRECENTAGE_100,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      height: Percentage.PRECENTAGE_100,
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_16,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_16,
      shadowColor: _theme?.color,
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
      shadowRadius: FixedValue.CONSTANT_VALUE_5,
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_2,
        height: FixedValue.CONSTANT_VALUE_2,
      },
      elevation: FixedValue.CONSTANT_VALUE_2,
    },
    container: {
      ...BASESTYLE.flex1,
      width: Percentage.PRECENTAGE_100,
    },
    crossImage: {
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    outterPart: {
      width: Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      ...BASESTYLE.absolutePosition,
      zIndex: FixedValue.CONSTANT_VALUE_MIN_1,
      backgroundColor: COLORS.TRANSPARENT,
    },
    paypalButtonView: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.absolutePosition,
      backgroundColor: COLORS.WHITE,
      zIndex: FixedValue.CONSTANT_VALUE_1,
      height: Percentage.PRECENTAGE_100,
      width: Percentage.PRECENTAGE_100,
    },
    contentContainerStyle: {
      ...BASESTYLE.selfCenter,
      width: Percentage.PRECENTAGE_75,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    closeIconView: {
      ...BASESTYLE.absolutePosition,
      zIndex: FixedValue.CONSTANT_VALUE_2,
      right: FixedValue.CONSTANT_VALUE_20,
      top: FixedValue.CONSTANT_VALUE_20,
    },
  });
