import React from 'react';
import {Text, View} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {style} from './styles';
import DollarImage from '../renderSvgs/dollarImage';
import {ITransactionHistoryObject} from '../../types/componentTypes/userProfileTypes';
import {COLORS, TEXT} from '../../constants';
import {
  checkTopCreditGreaterThanOne,
  formatDateTimeToLocalTime,
} from '../../utils/helperFunctions';
import MantelTop from '../renderSvgs/mantelTop';
import {FixedValue, timeformat} from '../../constants/enum';
import CustomButton from '../button/customButton';

const TransactionHistoryTile = (props: ITransactionHistoryObject) => {
  const {item, onClickTransfer} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const styles = style(ColorTheme);
  const isAmountAdded = item?.color === 'Green';
  const color = isAmountAdded ? COLORS._4EAF51 : COLORS._FF3D3D;
  const dateTimeString = item?.createDate;
  const formattedTime = formatDateTimeToLocalTime(
    dateTimeString,
    timeformat.HH_MM_A
  );

  return (
    <>
      <View style={styles.historyListView}>
        {item?.purchased ? <MantelTop /> : <DollarImage />}
        <View style={styles.textContainer}>
          <View style={styles.horizontalTextContainer}>
            <Text style={styles.descText}>{item.message}</Text>
            {isAmountAdded ? (
              <Text style={[styles.amountText, {color}]}>
                {item?.creditValueChanged}
                <Text style={styles.amountSubtextFont}>{item?.creditText}</Text>
              </Text>
            ) : (
              <Text style={[styles.amountText, {color}]}>
                {item?.creditValueChanged}
                <Text style={styles.amountSubtextFont}>{item?.creditText}</Text>
              </Text>
            )}
          </View>
          {item.toUser && (
            <CustomButton
              buttonLabel={TEXT.PULLBACK}
              buttonwidth={styles.primaryButtonStyle}
              labelStyle={styles.primaryButtonLabelstyle}
              onPress={() =>
                onClickTransfer &&
                onClickTransfer(item?.toUser ?? FixedValue.CONSTANT_VALUE_0)
              }
            />
          )}
          <View style={styles.horizontalTextContainer}>
            <Text style={styles.timeText}>{formattedTime}</Text>
            <Text style={styles.timeText}>
              {TEXT.BALANCE}
              {checkTopCreditGreaterThanOne(item?.walletAmount)}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

export default TransactionHistoryTile;
