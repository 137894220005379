import {createSlice} from '@reduxjs/toolkit';
import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {TrackingCancelStateType} from '../../types/reducerTypes/authStoreTypes';
import {ResetAllState} from './logoutslice';

const {StoreConstants} = AppConst;
const {TRACKING_CANCEL} = ActionType;

const initialState: TrackingCancelStateType = {
  trackingCancel: {
    isLoading: false,
    failed: null,
    data: [],
    status: TRACKING_CANCEL.END,
    success: false,
  },
};

const TrackingCancelSlice = createSlice({
  initialState,
  name: StoreConstants.TRACKING_CANCEL,
  reducers: {
    ontrackingCancelStart: (state, _) => {
      state.trackingCancel.isLoading = true;
      state.trackingCancel.status = TRACKING_CANCEL.START;
      state.trackingCancel.success = false;
    },
    ontrackingCancelSuccess: (state, action) => {
      state.trackingCancel.data = action.payload?.results;
      state.trackingCancel.status = TRACKING_CANCEL.SUCCESS;
      state.trackingCancel.success = true;
    },
    ontrackingCancelFailed: (state, action) => {
      state.trackingCancel.failed = action.payload;
      state.trackingCancel.status = TRACKING_CANCEL.FAILED;
      state.trackingCancel.success = false;
    },
    ontrackingCancelEnd: state => {
      state.trackingCancel.isLoading = false;
      state.trackingCancel.status = TRACKING_CANCEL.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  ontrackingCancelStart,
  ontrackingCancelSuccess,
  ontrackingCancelFailed,
  ontrackingCancelEnd,
} = TrackingCancelSlice.actions;
export default TrackingCancelSlice.reducer;
export const TrackingCancelState = (state: RootState) => state.trackingCancel;
