import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  normalizeFont,
  scaleHeight,
  scaleWidth,
  SCREEN_HEIGHT,
} from '../../utils/responsive';

export const abuseReportStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    innerView: {
      ...BASESTYLE.flex1,
      backgroundColor: _theme?.backgroundColor,
      width: Percentage.PRECENTAGE_100,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      maxHeight: SCREEN_HEIGHT - scaleHeight(FixedValue.CONSTANT_VALUE_52),
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_16,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_16,
      shadowColor: _theme?.color,
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
      shadowRadius: FixedValue.CONSTANT_VALUE_5,
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_2,
        height: FixedValue.CONSTANT_VALUE_2,
      },
      elevation: FixedValue.CONSTANT_VALUE_2,
    },
    subTitleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_24),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: _theme.color,
    },
    marginTop20: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_5),
      paddingRight: scaleHeight(FixedValue.CONSTANT_VALUE_15),
    },
    abuseListConatiner: {
      flexDirection: GlobalStyleValues.ROW,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_15),
    },
    abuseText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: _theme.color,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    helpUs: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: _theme.color,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_30),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_5),
    },
    descriptionView: {
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_30),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_5),
    },
    scrollViewStyle: {
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_40),
    },
    blockAndReportContainer: {
      marginTop: scaleHeight(-FixedValue.CONSTANT_VALUE_15),
    },
  });
