import React from 'react';
import {View, Text} from 'react-native';
import ProfileEditForm from '../editProfile/editForm';
import {ThemeContext} from '../../../../contextAPI/themeContext';
import {EditprofileStyle} from './editProfile.style';
import {FixedValue, ROUTE_NAME, TEXT} from '../../../../constants';
import BackButton from '../../../../components/button/backButton';
import useUserProfileHooks from '../../../../hooks/componentHooks/useUserProfileHooks';
import {userProfileContext} from '../../../../contextAPI/userProfileContext';
import PrimaryButton from '../../../../components/button/primaryButton';
import CountriesList from '../../../../modals/allCountriesList/countriesList';
import {useAppDispatch, useAppSelector} from '../../../../hooks/reduxHooks';
import {
  onupdateProfile,
  onUserDataStart,
  userProfileStore,
} from '../../../../redux/reducers/userProfileSlice';
import CustomBottomListModal from '../../../../modals/customBottomListModal';
import {isWebsite, scaleHeight, scaleWidth} from '../../../../utils/responsive';
import UploadGalleryImage from '../../../../components/renderSvgs/uploadGalleryImage';
import CameraImage from '../../../../components/renderSvgs/cameraImage';
import DeleteIcon from '../../../../components/renderSvgs/deleteBin';
import {
  UPDATE_PROFILE,
  UPDATE_PROFILE_IMAGE,
} from '../../../../constants/actionTypes';
import {goBack, navigate} from '../../../../services/navigationService';
import {updateProfileState} from '../../../../redux/reducers/updateProfileSlice';
import {TOAST_TEXT} from '../../../../constants/textMessage';
import {_ToastHandler} from '../../../../utils/helperFunctions';

const EditProfileContent = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = EditprofileStyle(ColorTheme);
  const {
    values,
    handleBlur,
    handleChange,
    firstNameError,
    lastNameError,
    emailError,
    phoneError,
    isValid,
    setValues,
    _takePicture,
    _removeImage,
    _openGallery,
    handleSubmit,
  } = useUserProfileHooks();
  const {
    setShowCountry,
    showCountry,
    selectedCountry,
    setSelectedCountry,
    openCloseUploadProfileModal,
    setOpenCloseUploadProfileModal,
  } = React.useContext(userProfileContext);
  const {userData} = useAppSelector(userProfileStore);
  const {updateUserData, uploadProfileImage} =
    useAppSelector(updateProfileState);
  const dispatch = useAppDispatch();

  const UserDetail = userData?.success;
  const iconHeight = scaleHeight(FixedValue.CONSTANT_VALUE_18);
  const iconHWidth = scaleWidth(FixedValue.CONSTANT_VALUE_18);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const listData = [
    {
      LeftIcon: UploadGalleryImage,
      label: TEXT.UPLOAD_A_PHOTO,
      onPress: _openGallery,
      isDelete: false,
    },
    {
      LeftIcon: CameraImage,
      label: TEXT.TAKE_A_PHOTO,
      onPress: _takePicture,
      isDelete: false,
    },
  ];

  if (isWebsite()) {
    listData.splice(FixedValue.CONSTANT_VALUE_1, FixedValue.CONSTANT_VALUE_1);
  }

  const listDataWithDelete = [
    ...listData,
    {
      LeftIcon: DeleteIcon,
      label: TEXT.REMOVE,
      onPress: _removeImage,
      isDelete: true,
    },
  ];

  const customModaldata = {
    modalHeaderName: TEXT.PROFILE_IMAGE,
    height: iconHeight,
    width: iconHWidth,
    closeModal: setOpenCloseUploadProfileModal,
    modalVisible: openCloseUploadProfileModal,
    listData: UserDetail?.profileImage ? listDataWithDelete : listData,
  };

  React.useEffect(() => {
    setValues({
      Firstname: UserDetail?.firstName,
      Lastname: UserDetail?.lastName,
      DateOfBirth: UserDetail?.dob,
      Email: UserDetail?.email,
      MobileNumber: UserDetail?.mobile,
    });
  }, []);

  React.useEffect(() => {
    switch (updateUserData.status) {
      case UPDATE_PROFILE.SUCCESS:
        dispatch(onupdateProfile(updateUserData.success?.data));
        if (updateUserData.success?.data.mobile !== UserDetail?.mobile) {
          navigate(ROUTE_NAME.VERIFY_EMAIL, {
            mobileNo: updateUserData.success?.data?.mobile,
            navigateFrom: ROUTE_NAME.EDIT_PROFILE_MOBILE,
          });
        } else if (updateUserData.success?.data.email !== UserDetail?.email) {
          navigate(ROUTE_NAME.VERIFY_EMAIL, {
            emailId: updateUserData.success?.data.email,
            navigateFrom: ROUTE_NAME.EDIT_PROFILE,
          });
        } else {
          _ToastHandler(TOAST_TEXT.PROFILE_SUCCESSFULLY_UPDATED, true);
          goBack();
        }
        break;
      case UPDATE_PROFILE.FAILED:
        break;
      default:
        break;
    }
  }, [updateUserData.status]);

  React.useEffect(() => {
    switch (uploadProfileImage.status) {
      case UPDATE_PROFILE_IMAGE.SUCCESS:
        _ToastHandler(TOAST_TEXT.PROFILE_UPDATED_SUCCESSFULLY, true);
        dispatch(onUserDataStart());
        break;
      case UPDATE_PROFILE_IMAGE.FAILED:
        break;
      default:
        break;
    }
  }, [uploadProfileImage.status]);

  React.useEffect(() => {
    setLoading(updateUserData.isLoading);
  }, [updateUserData.isLoading]);

  return (
    <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
      {openCloseUploadProfileModal && (
        <CustomBottomListModal customModaldata={customModaldata} />
      )}
      <View style={styles.headerstyle}>
        <BackButton />
        <View style={styles.headertext}>
          <Text style={styles.titleText}>{TEXT.EDIT_PROFILE}</Text>
        </View>
      </View>
      <ProfileEditForm
        values={values}
        handleBlur={handleBlur}
        handleChange={handleChange}
        firstNameError={firstNameError}
        lastNameError={lastNameError}
        emailError={emailError}
        phoneError={phoneError}
        onPress={() => setShowCountry(true)}
      />
      <View>
        <PrimaryButton
          buttonLabel={TEXT.UPDATE}
          onPress={handleSubmit}
          isDisabled={!isValid}
          isLoading={isLoading}
        />
      </View>
      <CountriesList
        onPress={() => setShowCountry(false)}
        showCountry={showCountry}
        selectCountryCode={selectedCountry?.dial_code}
        selectedCountryFunction={setSelectedCountry}
      />
    </View>
  );
};

export default React.memo(EditProfileContent);
