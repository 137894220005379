import React from 'react';
import {Text, View} from 'react-native';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {TopBuyingDetailStyle} from './topDetail.style';
import SafeAreaContainer from '../../../components/layout/safeAreaContainer';
import BackButton from '../../../components/button/backButton';
import {TEXT} from '../../../constants';
import TopDetailContent from './topDetailContent';

const TopBuyingDetail = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = TopBuyingDetailStyle(ColorTheme);
  
  return (
    <SafeAreaContainer>
      <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
        <View style={styles.headerstyle}>
          <BackButton />
          <View style={styles.headertext}>
            <Text style={styles.titleText}>{TEXT.TOP_DETAIL}</Text>
          </View>
        </View>
        <TopDetailContent />
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(TopBuyingDetail);
