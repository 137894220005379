import React from 'react';
import {
  ILoginContext,
  ILoginProvider,
} from '../types/contextTypes/loginContext';

const LoginContext = React.createContext<ILoginContext>({
  showPassword: true,
  setShowPassword: () => {},
});

const LoginProvider = (props: ILoginProvider): JSX.Element => {
  const [showPassword, setShowPassword] = React.useState<boolean>(true);

  const _setShowPassword = React.useCallback((): void => {
    setShowPassword((prev: boolean) => !prev);
  }, [showPassword]);

  const values = React.useMemo(
    (): ILoginContext => ({
      showPassword,
      setShowPassword: _setShowPassword,
    }),
    [showPassword, setShowPassword]
  );
  return (
    <LoginContext.Provider value={values}>
      {props.children}
    </LoginContext.Provider>
  );
};

export {LoginContext, LoginProvider};
