import StyleSheet from 'react-native-media-query';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  BASESTYLE,
  COLORS,
  FONT_NAMES,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {
  SCREEN_WIDTH,
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const ChildprofileStyle = (theme?: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : SCREEN_WIDTH,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    headingView: {
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_6),
    },
    headerstyle: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.inRow,
    },
    headertext: {
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.flex1,
    },
    imageView: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_100),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_100),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      backgroundColor: theme?.color,
      ...BASESTYLE.inCenter,
      overflow: GlobalStyleValues.HIDDEN,
    },
    backgroundColorPlaceholder: {
      backgroundColor: theme?.color,
    },
    profileNameText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_48),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme?.backgroundColor,
    },
    titleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_36),
      color: theme?.color,
    },
    profilePicView: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      ...BASESTYLE.inRow,
    },
    userNameText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: theme?.color,
      maxWidth: scaleWidth(FixedValue.CONSTANT_VALUE_229),
    },
    userDetailView: {
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_12),
      justifyContent: GlobalStyleValues.CENTER,
    },
    profileBtnView: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_44),
    },
    profileSingleBtnView: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: Percentage.PRECENTAGE_100,
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
      borderWidth: FixedValue.CONSTANT_VALUE_1,
      borderColor: COLORS.C2D2ED,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_6),
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_12),
    },
    profileBtnLabelText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme?.color,
    },
    badgeStyle: {
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_9),
    },
  });
