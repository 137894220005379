import React from 'react';
import {Pressable, Text, View} from 'react-native';
import {FixedValue, TEXT} from '../../../constants';
import {SeeAll_ScreenMode} from '../../../constants/enum';
import {HomeContext} from '../../../contextAPI/homeContext';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {useAppSelector} from '../../../hooks/reduxHooks';
import {HomeStore} from '../../../redux/reducers/homeSlice';
import {ITitleWithBack} from '../../../types/reducerTypes/searchTabStoreType';
import BackButton from '../../button/backButton';
import {styles} from './styles';

const TitleWithBackButton = ({
  title,
  rightSideHeaderText,
  headerIcon,
  onPress,
}: ITitleWithBack): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  const {setSelectAllClicked, seeAllScreenMode, selectAllClicked} =
    React.useContext(HomeContext);
  const {collectionBasedTopList} = useAppSelector(HomeStore);

  const RenderRightBtn = React.useMemo((): JSX.Element => {
    if (rightSideHeaderText) {
      if (rightSideHeaderText === TEXT.ICON) {
        return <Pressable onPress={onPress}>{headerIcon}</Pressable>;
      } else if (rightSideHeaderText === TEXT.SELECT) {
        return (
          <Pressable onPress={onPress}>
            <Text
              numberOfLines={FixedValue.CONSTANT_VALUE_1}
              style={[style.rightSideHeaderStyle, style.blacktext]}
            >
              {rightSideHeaderText}
            </Text>
          </Pressable>
        );
      } else {
        return (
          <Pressable onPress={() => setSelectAllClicked(rightSideHeaderText)}>
            <Text
              numberOfLines={FixedValue.CONSTANT_VALUE_1}
              style={[style.rightSideHeaderStyle, style.textGreen]}
            >
              {rightSideHeaderText}
            </Text>
          </Pressable>
        );
      }
    } else {
      return <View style={style.rightTitleWidth} />;
    }
  }, [collectionBasedTopList, rightSideHeaderText]);

  const RenderLeftButtonOrIcon = React.useMemo((): JSX.Element | null => {
    if (
      rightSideHeaderText === TEXT.SELECT ||
      seeAllScreenMode === SeeAll_ScreenMode.MOVE_TOPS_TO_THIS_COLLECTION
    ) {
      return <BackButton />;
    } else if (
      rightSideHeaderText === TEXT.SELECT_ALL ||
      rightSideHeaderText === TEXT.UNSELECT_ALL
    ) {
      return (
        <Pressable onPress={onPress}>
          <Text
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
            style={[style.rightSideHeaderStyle, style.blacktext]}
          >
            {TEXT.CANCEL}
          </Text>
        </Pressable>
      );
    } else {
      return <BackButton onPress={onPress}/>;
    }
  }, [
    collectionBasedTopList,
    rightSideHeaderText,
    seeAllScreenMode,
    selectAllClicked,
  ]);

  return (
    <View style={style.container}>
      {RenderLeftButtonOrIcon}
      <Text numberOfLines={FixedValue.CONSTANT_VALUE_1} style={style.titleText}>
        {title}
      </Text>
      {RenderRightBtn}
    </View>
  );
};

export default React.memo(TitleWithBackButton);
