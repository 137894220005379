import React from 'react';
import {
  View,
  Text,
  Pressable,
  TextInput,
  NativeSyntheticEvent,
  TextInputFocusEventData,
  ViewStyle,
} from 'react-native';

import {ILabelInput} from '../../types/componentTypes/inputTypes';
import {COLORS, FixedValue, TEXT} from '../../constants';
import {styles} from './Input.style';
import {ThemeContext} from '../../contextAPI/themeContext';
import {getKeyboardType} from '../../utils/helperFunctions';
import {EMOJI_REGEX} from '../../constants/regex';

const LabelInput = (props: ILabelInput): JSX.Element => {
  const {
    Icon,
    LeftElement,
    haveError,
    keyboardType = TEXT.DEFAULT,
    value,
    isDisabled,
    SecondElement,
    extraRightPadding,
  } = props;
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  const isError: boolean =
    haveError && haveError.length > FixedValue.CONSTANT_VALUE_0 ? true : false;
  const nonFocusStyle: ViewStyle = isError
    ? style.errorBorder
    : style.defaultBorder;
  const borderStyle: ViewStyle =
    isFocused && !isError ? style.focusBorder : nonFocusStyle;
  const hasIcons: boolean = LeftElement || Icon ? true : false;
  const isNameText: boolean =
    props?.inputLabel === TEXT.FIRST_NAME ||
    props?.inputLabel === TEXT.LAST_NAME;

  const onClickIcon = React.useCallback((): void => {
    if (props.onPress) {
      props.onPress();
    }
  }, []);

  const onFocused = React.useCallback((): void => setIsFocused(true), []);

  const onBlured = React.useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>): void => {
      setIsFocused(false);
      if (props.onBlur) {
        props.onBlur(e);
      }
    },
    []
  );

  return (
    <View style={[style.mainContainer, props.containerStyle]}>
      <Text style={style.labelText}>{props.inputLabel}</Text>
      <View
        style={[
          style.textInputContainer,
          borderStyle,
          isDisabled && style.disabledBorderColor,
        ]}
      >
        {LeftElement && (
          <Pressable
            style={[style.IconView, style.iconViewPercent]}
            onPress={onClickIcon}
          >
            {LeftElement}
          </Pressable>
        )}
        <TextInput
          value={value}
          placeholder={props.placeholder}
          onChangeText={(text: string) =>
            props.onChangeText(text.replace(EMOJI_REGEX, ''))
          }
          placeholderTextColor={ColorTheme.placeHolderColor}
          underlineColorAndroid={COLORS.TRANSPARENT}
          secureTextEntry={props.secureTextEntry}
          onFocus={onFocused}
          onBlur={onBlured}
          autoCorrect={false}
          textContentType="oneTimeCode"
          editable={props.editable}
          maxLength={props.maxLength}
          keyboardType={getKeyboardType(keyboardType)}
          style={[
            style.inputStyle,
            hasIcons && style.smallInputWidth,
            isDisabled && style.disabledBackgroundColor,
            isDisabled && style.disabledTextColor,
            LeftElement && style.inputPercent,
            extraRightPadding && style.textPaddingRight,
          ]}
        />
        {SecondElement && <SecondElement />}
        {Icon && (
          <Pressable
            style={[
              style.IconView,
              isDisabled && style.disabledBackgroundColor,
            ]}
            onPress={onClickIcon}
          >
            <Icon />
          </Pressable>
        )}
      </View>
      {isError && (
        <Text style={[isNameText && style.errorContainer, style.errorText]}>
          {haveError}
        </Text>
      )}
    </View>
  );
};

export default React.memo(LabelInput);
