import React from 'react';
import {TextInput} from 'react-native';

import {ChildProfileContext} from '../../contextAPI/childProfileContext';
import {CHILD_PERMISSION, FixedValue} from '../../constants/enum';
import {TEXT} from '../../constants';
import {useAppDispatch} from '../reduxHooks';
import {onTopBuyingApprovalSettingStart} from '../../redux/reducers/childApproveSlice';
import {_ToastHandler} from '../../utils/helperFunctions';
export interface ISaveSetting {
  childId: number;
  status: string;
  monthlyLimit?: number;
}

const useTopBuyingSetting = () => {
  const dispatch = useAppDispatch();
  const TextInputRef = React.useRef<React.RefObject<TextInput>>();
  const {childUserData} = React.useContext(ChildProfileContext);
  const [selectedOption, setSelectedOption] = React.useState<string>(
    childUserData?.permissionsStatus?.purchasePermission?.status ??
      CHILD_PERMISSION.NOT_ALLOWED
  );
  const [limitCount, setLimitCount] = React.useState<string>(
    childUserData?.permissionsStatus?.purchasePermission?.monthlyLimit?.toString() ??
      ''
  );
  const SettingOptions = [
    {text: TEXT.APPROVE_ALL_PURCHASES, status: CHILD_PERMISSION.NOT_ALLOWED},
    {text: TEXT.ALLOW_CHILD_PURCHASES, status: CHILD_PERMISSION.ALLOWED},
    {
      text: TEXT.REQUIRE_PARENT_APPROVEL_FOR_PURCHASES,
      status: CHILD_PERMISSION.HAVE_LIMIT,
    },
  ];

  const updateSelectedOption = React.useCallback(
    (val: string): void => {
      if (selectedOption !== val) {
        // @ts-ignore
        if (TextInputRef.current) TextInputRef?.current?.blur();
        setLimitCount('');
        setSelectedOption(val);
      }
    },
    [selectedOption]
  );

  const saveSettings = React.useCallback(
    (status?: string, amount?: string): void => {
      const params: ISaveSetting = {
        childId: childUserData?.id ?? 0,
        status: status ?? selectedOption,
      };
      if (selectedOption === CHILD_PERMISSION.HAVE_LIMIT || amount !== '') {
        params.monthlyLimit = Number(amount ?? limitCount);
      }
      dispatch(onTopBuyingApprovalSettingStart(params));
    },
    [selectedOption, childUserData, limitCount]
  );

  const isDisabled = React.useMemo((): boolean => {
    if (selectedOption === CHILD_PERMISSION.HAVE_LIMIT) {
      return (
        selectedOption && limitCount.length === FixedValue.CONSTANT_VALUE_0
      );
    }
    return false;
  }, [limitCount, selectedOption]);

  return {
    limitCount,
    SettingOptions,
    selectedOption,
    isDisabled,
    TextInputRef,
    updateSelectedOption,
    setLimitCount,
    saveSettings,
  };
};

export default useTopBuyingSetting;
