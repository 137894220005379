import React from 'react';

import ReceviedOfferContent from '../../components/tradeComponent/receviedOfferCard';
import TradeModal from '../../modals/tradeModal';
import {TradeContext} from '../../contextAPI/tradeContext';
import AbuseReportModal from '../../modals/abuseReportModal';
import useTradeReceviedOrSentHooks from '../../hooks/componentHooks/useTradeReceviedOrSentHooks';
import {showAlert, _ToastHandler} from '../../utils/helperFunctions';
import {FixedValue} from '../../constants';
import {TRADE_OFFER_REPORT} from '../../constants/actionTypes';
import {
  TradeTabStore,
  onTradeReportStart,
} from '../../redux/reducers/tradeTabSlice';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import {ReportFormState} from '../../types/reducerTypes/tradeTabTypes';

const ReceviedOfferList = (): JSX.Element => {
  const {
    receivedOfferList,
    openReceviedOfferModal,
    setOpenReceviedOfferModal,
    openReportModal,
    setOpenReportModal,
    selectedItem,
  } = React.useContext(TradeContext);
  const {getOffersListAPI} = useTradeReceviedOrSentHooks();
  const {tradeAbuseReport} = useAppSelector(TradeTabStore);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    switch (tradeAbuseReport.status) {
      case TRADE_OFFER_REPORT.SUCCESS:
        showAlert();
        getOffersListAPI('RECEIVED', FixedValue.CONSTANT_VALUE_0);
        break;
      case TRADE_OFFER_REPORT.FAILED:
        break;
      default:
        break;
    }
  }, [tradeAbuseReport.status]);

  const callTradeReportApiFunction = React.useCallback(
    (abuseValue: ReportFormState): void => {
      setOpenReportModal(false);
      dispatch(onTradeReportStart(abuseValue));
    },
    []
  );

  return (
    <>
      <ReceviedOfferContent data={receivedOfferList} />
      {openReceviedOfferModal && (
        <TradeModal
          isSent={true}
          modalVisible={openReceviedOfferModal}
          onClose={setOpenReceviedOfferModal}
          isTradeReceivedRequest={true}
          showCancelButton={false}
          showActionButton={true}
          isTradeSentRequest={true}
        />
      )}
      {openReportModal && (
        <AbuseReportModal
          modalVisible={openReportModal}
          onClose={setOpenReportModal}
          onSubmit={callTradeReportApiFunction}
          offerId={selectedItem?.id ?? -1}
        />
      )}
    </>
  );
};

export default React.memo(ReceviedOfferList);
