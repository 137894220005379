import React from 'react';
import {View} from 'react-native';
import ViewContainer from '../../components/layout/viewContainer';
import {ThemeContext} from '../../contextAPI/themeContext';
import InProgressTopsResultList from './inProgressTopsList';
import {inProgressTopsStyle} from './inProgressTops.style';

const InProgressScreen = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = inProgressTopsStyle(ColorTheme);
  return (
    <ViewContainer>
      <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
        <InProgressTopsResultList />
      </View>
    </ViewContainer>
  );
};

export default React.memo(InProgressScreen);
