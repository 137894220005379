import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";
const CrossWithCircle =(props: SvgProps) => (
  <Svg
    width={34}
    height={34}
    viewBox="0 0 34 34"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M29.6667 17.0002C29.6667 23.9958 23.9956 29.6668 17 29.6668C10.0044 29.6668 4.33333 23.9958 4.33333 17.0002C4.33333 10.0046 10.0044 4.3335 17 4.3335C23.9956 4.3335 29.6667 10.0046 29.6667 17.0002Z"
      stroke="#F54336"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M21.25 12.75L12.75 21.25"
      stroke="#F54336"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M12.75 12.75L21.25 21.25"
      stroke="#F54336"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default CrossWithCircle;
