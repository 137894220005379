import {takeLatest, put, call, delay, select} from 'redux-saga/effects';
import {AppConst} from '../../constants';
import {trackingList} from '../../utils/apis';
import {_ToastHandler} from '../../utils/helperFunctions';
import {isWebsite} from '../../utils/responsive';
import {authState} from '../reducers/authSlice';
import {
  ontrackingListEnd,
  ontrackingListFailed,
  ontrackingListSuccess,
} from '../reducers/trackingListSlice';
import {TrackingListResponse} from '../../types/reducerTypes/tradeTabTypes';
import {IActionType} from '../../types/constantFilesTypes';

const {StoreConstants} = AppConst;

function* trackingListContact(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: TrackingListResponse = yield call(
      trackingList,
      userTokens,
      action.payload
    );

    yield put(ontrackingListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.response?.data?.message || 'An error occurred', false);
    yield put(ontrackingListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(ontrackingListEnd());
  }
}

export default function* trackingListSaga() {
  yield takeLatest(
    `${StoreConstants.TRACKING_LIST}/ontrackingListStart`,
    trackingListContact
  );
}
