import * as React from 'react';
import Svg, {G, Path} from 'react-native-svg';
const RightArrowBlue = props => (
  <Svg
    width={13}
    height={12}
    top={2}
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G id="u:arrow-right">
      <Path
        id="Vector"
        d="M11.7724 6.74435C11.7407 6.66259 11.6931 6.58789 11.6325 6.52453L8.30189 3.19395C8.23978 3.13184 8.16605 3.08257 8.0849 3.04896C8.00375 3.01535 7.91678 2.99805 7.82894 2.99805C7.65155 2.99805 7.48143 3.06851 7.356 3.19395C7.29389 3.25605 7.24462 3.32979 7.21101 3.41093C7.1774 3.49208 7.1601 3.57906 7.1601 3.66689C7.1601 3.84428 7.23057 4.0144 7.356 4.13983L9.55419 6.33136H3.16612C2.98945 6.33136 2.82002 6.40154 2.6951 6.52646C2.57018 6.65138 2.5 6.82081 2.5 6.99748C2.5 7.17414 2.57018 7.34357 2.6951 7.46849C2.82002 7.59342 2.98945 7.6636 3.16612 7.6636H9.55419L7.356 9.85512C7.29356 9.91705 7.24401 9.99072 7.21019 10.0719C7.17637 10.1531 7.15896 10.2401 7.15896 10.3281C7.15896 10.416 7.17637 10.5031 7.21019 10.5842C7.24401 10.6654 7.29356 10.7391 7.356 10.801C7.41792 10.8634 7.4916 10.913 7.57277 10.9468C7.65394 10.9806 7.74101 10.998 7.82894 10.998C7.91688 10.998 8.00394 10.9806 8.08512 10.9468C8.16629 10.913 8.23996 10.8634 8.30189 10.801L11.6325 7.47042C11.6931 7.40707 11.7407 7.33237 11.7724 7.2506C11.839 7.08843 11.839 6.90653 11.7724 6.74435Z"
        fill="#1F53D3"
      />
    </G>
  </Svg>
);
export default RightArrowBlue;
