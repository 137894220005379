import {createSlice} from '@reduxjs/toolkit';
import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {ForgotPasswordState} from '../../types/reducerTypes/authStoreTypes';
import {ResetAllState} from './logoutslice';

const {StoreConstants} = AppConst;
const {FORGOT_PASSWORD} = ActionType;

const initialState: ForgotPasswordState = {
  forgotPassword: {
    isLoading: false,
    success: null,
    failed: null,
    status: FORGOT_PASSWORD.END,
  },
};

const ForgotPasswordSlice = createSlice({
  initialState,
  name: StoreConstants.FORGOT_PASSWORD,
  reducers: {
    onForgotPasswordStart: (state, _) => {
      state.forgotPassword.isLoading = true;
      state.forgotPassword.status = FORGOT_PASSWORD.START;
    },
    onForgotPasswordSuccess: (state, action) => {
      state.forgotPassword.success = action.payload;
      state.forgotPassword.status = FORGOT_PASSWORD.SUCCESS;
    },
    onForgotPasswordFailed: (state, action) => {
      state.forgotPassword.failed = action.payload;
      state.forgotPassword.status = FORGOT_PASSWORD.FAILED;
    },
    onForgotPasswordEnd: state => {
      state.forgotPassword.isLoading = false;
      state.forgotPassword.status = FORGOT_PASSWORD.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  onForgotPasswordStart,
  onForgotPasswordSuccess,
  onForgotPasswordFailed,
  onForgotPasswordEnd,
} = ForgotPasswordSlice.actions;
export default ForgotPasswordSlice.reducer;
export const forgotPasswordState = (state: RootState) => state.forgotPassword;
