import React, {useEffect, useRef} from 'react';
import {FlatList, ScrollView, View} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';
import {useIsFocused} from '@react-navigation/native';

import {BASESTYLE, FixedValue, TEXT} from '../../constants';
import ChildListComponent from '../../components/childListComponent';
import useChildListHooks from '../../hooks/componentHooks/useChildListHooks';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import {useAppSelector} from '../../hooks/reduxHooks';
import {ThemeContext} from '../../contextAPI/themeContext';
import {childListstyle} from './childList.style';
import EmptyChildListView from '../../components/emptyListView/emptyChildListView';
import {ProfileTabContext} from '../../contextAPI/profileTabContext';
import {childProfileApproveStore} from '../../redux/reducers/childApproveSlice';
import {
  APPROVE_OR_REJECT,
  USER_MARK_UNMARK_FAVORITE,
} from '../../constants/actionTypes';
import {ChildListModel} from '../../types/reducerTypes/userProfileTypes';
import useFavouriteHooks from '../../hooks/componentHooks/useFavouriteHooks';
import {_ToastHandler} from '../../utils/helperFunctions';

const ChildListContent = (): JSX.Element => {
  const isFocused = useIsFocused();
  const {callParentChildListAPI} = useChildListHooks();
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = childListstyle(ColorTheme);
  const {childListData} = useAppSelector(userProfileStore);
  const {
    parentChildListData,
    profileTabPulldownRefresh,
    setProfileTabPulldownRefresh,
  } = React.useContext(ProfileTabContext);
  const {approveOrReject} = useAppSelector(childProfileApproveStore);
  const selectedItemRef = useRef<ChildListModel>();
  const {addFavoriteAndUnFavorite, setFavoriteLoader} = useFavouriteHooks();
  const {userMarkAndUnmarkFavoriteData} = useAppSelector(userProfileStore);

  const onPressFavAndUnFav = React.useCallback(
    (item: ChildListModel): void => {
      setFavoriteLoader(true);
      selectedItemRef.current = item;
      const params = {
        favorite: item?.favorite ? !item?.favorite : true,
        userIds: [item?.id ?? FixedValue.CONSTANT_VALUE_0],
      };
      addFavoriteAndUnFavorite(params);
    },
    [addFavoriteAndUnFavorite, setFavoriteLoader]
  );

  useEffect(() => {
    if (isFocused) {
      switch (userMarkAndUnmarkFavoriteData.status) {
        case USER_MARK_UNMARK_FAVORITE.SUCCESS:
          if (selectedItemRef.current) {
            const {firstName, lastName, favorite} = selectedItemRef.current;
            const firstText = favorite
              ? TEXT.YOU_HAVE_REMOVED
              : TEXT.YOU_HAVE_ADDED;
            const fullName = `${firstName} ${lastName}`;
            const favText = favorite ? TEXT.FROM_THE_LIST : TEXT.TO_THE_LIST;
            const msg = `${firstText} "${fullName}" ${favText}`;
            alert(msg);
            callParentChildListAPI();
          }
          break;
        case USER_MARK_UNMARK_FAVORITE.FAILED:
          break;
      }
    }
  }, [isFocused, userMarkAndUnmarkFavoriteData.status]);

  React.useEffect(() => {
    if (isFocused) {
      callParentChildListAPI();
    }
  }, [isFocused]);

  const handleRefresh = React.useCallback(() => {
    setProfileTabPulldownRefresh(true);
    callParentChildListAPI();
  }, [profileTabPulldownRefresh]);

  React.useEffect(() => {
    switch (approveOrReject.status) {
      case APPROVE_OR_REJECT.SUCCESS:
        callParentChildListAPI();
        break;
      case APPROVE_OR_REJECT.FAILED:
        callParentChildListAPI();
        break;
      default:
        break;
    }
  }, [approveOrReject.status]);

  const RenderEmptyUI = React.useMemo((): JSX.Element | null => {
    if (
      parentChildListData?.length === FixedValue.CONSTANT_VALUE_0 ||
      parentChildListData === null
    ) {
      return (
        <ScrollView
          contentContainerStyle={styles.scrollviewStyle}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={profileTabPulldownRefresh}
              onRefresh={handleRefresh}
            />
          }
        >
          <View style={[BASESTYLE.inCenter, BASESTYLE.flex1]}>
            <EmptyChildListView emptyViewTitle={TEXT.NO_REQUEST_YET} />
          </View>
        </ScrollView>
      );
    }
    return null;
  }, [parentChildListData, profileTabPulldownRefresh]);

  const RenderList = React.useMemo((): JSX.Element | null => {
    if (
      parentChildListData &&
      parentChildListData?.length > FixedValue.CONSTANT_VALUE_0
    ) {
      return (
        <FlatList
          data={parentChildListData}
          keyExtractor={(_, index) => `${TEXT.CHILD_LIST}-${index}`}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={profileTabPulldownRefresh}
              onRefresh={handleRefresh}
            />
          }
          renderItem={({item}) => {
            return (
              <ChildListComponent
                key={item?.id}
                item={item}
                onPressFavAndUnFav={() => onPressFavAndUnFav(item)}
                showFavouriteIcon={
                  item?.accountApprovalRequests === FixedValue.CONSTANT_VALUE_0
                }
              />
            );
          }}
        />
      );
    }
    return null;
  }, [parentChildListData, profileTabPulldownRefresh]);

  return (
    <View style={styles.contentContainer}>
      {!childListData.isLoading && RenderEmptyUI}
      {RenderList}
    </View>
  );
};

export default React.memo(ChildListContent);
