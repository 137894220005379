import React from 'react';

import {BASESTYLE, FixedValue} from '../../../constants';
import {EditorImageContext} from '../../../contextAPI/editorImageContext';
import RectangleImage from '../../renderSvgs/rectangleImage';
import RenderImages from './previewImages';
import {View} from 'react-native';
import {isWebsite} from '../../../utils/responsive';

const _RenderEmptyImages = (): JSX.Element => {
  const {userImages} = React.useContext(EditorImageContext);
  const imageView = {
    ...BASESTYLE.inRow,
    marginBottom: isWebsite()
      ? FixedValue.CONSTANT_VALUE_20
      : FixedValue.CONSTANT_VALUE_0,
  };
  const GiveSpaceInRight = React.useMemo((): JSX.Element | undefined => {
    if (isWebsite()) {
      return <View style={{marginRight: FixedValue.CONSTANT_VALUE_10}} />;
    }
  }, []);

  return React.useMemo((): JSX.Element => {
    if (userImages?.length === FixedValue.CONSTANT_VALUE_0) {
      return (
        <View style={imageView}>
          <RectangleImage />
          {GiveSpaceInRight}
          <RectangleImage />
          {GiveSpaceInRight}
          <RectangleImage />
          {GiveSpaceInRight}
          <RectangleImage />
        </View>
      );
    }
    return <RenderImages />;
  }, [userImages]);
};

export default React.memo(_RenderEmptyImages);
