import React from 'react';

import {FixedValue} from '../../../constants';
import {EditorContext} from '../../../contextAPI/editorContext';
import {EditorImageContext} from '../../../contextAPI/editorImageContext';
import {EditorShapeContext} from '../../../contextAPI/editorShapeContext';
import {EditorTextContext} from '../../../contextAPI/editorTextContext';
import ImageHeader from './imageHeader';
import MainHeader from './mainHeader';
import ShapeHeader from './shapeHeader';
import TextHeader from './textHeader';

const _EditorHeader = (): JSX.Element => {
  const {primaryTabSelectedIndex, selectedItemIndex} =
    React.useContext(EditorContext);
  const {setSelectedText} = React.useContext(EditorTextContext);
  const {setOnSelectImage} = React.useContext(EditorImageContext);
  const {setOnSelectShape} = React.useContext(EditorShapeContext);

  React.useEffect(() => {
    setSelectedText(null);
    setOnSelectImage(null);
    setOnSelectShape(null);
  }, [selectedItemIndex]);

  return React.useMemo(() => {
    switch (primaryTabSelectedIndex) {
      case FixedValue.CONSTANT_VALUE_0:
        return <ImageHeader />;
      case FixedValue.CONSTANT_VALUE_1:
        return <TextHeader />;
      case FixedValue.CONSTANT_VALUE_2:
        return <ShapeHeader />;
      case FixedValue.CONSTANT_VALUE_MIN_1:
      case FixedValue.CONSTANT_VALUE_3:
      default:
        return <MainHeader />;
    }
  }, [primaryTabSelectedIndex]);
};

export default React.memo(_EditorHeader);
