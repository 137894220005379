import React from 'react';
import {View, Text} from 'react-native';
import DraggableFlatList, {
  DragEndParams,
  NestableScrollContainer,
} from 'react-native-draggable-flatlist';

import {orderItemStyle} from './orderItem.style';
import {FixedValue, TEXT} from '../../constants';
import {EditorContext} from '../../contextAPI/editorContext';
import DraggableTile from '../../components/RenderItemTile/draggableTile';

const OrderItemContent = (): JSX.Element => {
  const {styles} = orderItemStyle();
  const {
    items,
    updateItemOrder,
    setShowOrderModal,
    setPrimaryTabSelectedIndex,
  } = React.useContext(EditorContext);

  const orderedItem = React.useMemo(
    (): JSX.Element[] => [...items].reverse(),
    [items]
  );

  const onCloseModal = React.useCallback(() => {
    setShowOrderModal(false);
    setPrimaryTabSelectedIndex(FixedValue.CONSTANT_VALUE_MIN_1);
  }, []);

  const onDragEnd = ({data, to, from}: DragEndParams<JSX.Element>): void => {
    if (to !== from) {
      const tempItems: JSX.Element[] = [...data].reverse();
      tempItems.forEach((item, index) => {
        // @ts-ignore -- used to access item ref
        item.ref.current.changeIndex(index);
      });
      updateItemOrder(tempItems);
    }
  };

  return (
    <NestableScrollContainer showsVerticalScrollIndicator={false}>
      <View style={styles.headerView}>
        <Text style={styles.orderText}>{TEXT.ORDER}</Text>
        <Text onPress={onCloseModal} style={styles.doneText}>
          {TEXT.DONE}
        </Text>
      </View>
      <DraggableFlatList
        data={orderedItem}
        autoscrollSpeed={400}
        keyExtractor={item => (item.key as React.Key).toString()}
        onDragEnd={onDragEnd}
        renderItem={({item, getIndex, drag, isActive}) => (
          <DraggableTile
            item={item}
            getIndex={getIndex}
            drag={drag}
            isActive={isActive}
          />
        )}
      />
    </NestableScrollContainer>
  );
};

export default React.memo(OrderItemContent);
