import {StyleSheet} from 'react-native';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';

export const userListComponentStyle = (theme?: IColorTheme) =>
  StyleSheet.create({
    userListStyle: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      width: Percentage.PRECENTAGE_100,
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
    },
    inRow: {
      ...BASESTYLE.inRow,
    },
    imageView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_58),
    },
    backgroundColorPlaceholder: {
      backgroundColor: theme?.color,
    },
    profileNameText: {
      textAlign: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      color: theme?.backgroundColor,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      textTransform: GlobalStyleValues.UPPERCASE,
    },
    userNameText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      color: theme?.color,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      textTransform: GlobalStyleValues.CAPITALIZE,
      width: Percentage.PRECENTAGE_90,
    },
    userNameTextStyle: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_4),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS._GREY,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      width: Percentage.PRECENTAGE_90,
    },
    columnStyle: {
      ...BASESTYLE.column,
      width: Percentage.PRECENTAGE_85,
    },
    disableText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      color: theme?.disabledTextColor,
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    checkboxView: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_25),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_25),
      alignContent: GlobalStyleValues.CENTER,
      justifyContent: GlobalStyleValues.CENTER,
    },
    mainViewStyle: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      backgroundColor: theme?.color,
      ...BASESTYLE.inCenter,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_58),
      overflow: GlobalStyleValues.HIDDEN,
    },
    youText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_14),
      color: theme?.color,
      fontFamily: FONT_NAMES.SF_REGULAR,
    },
    pendingStyleView: {
      ...BASESTYLE.selfCenter,
      ...BASESTYLE.inRow,
      borderRadius: FixedValue.CONSTANT_VALUE_100,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_77),
      justifyContent: GlobalStyleValues.CENTER,
      backgroundColor: COLORS._FEF5CC,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_4),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_22),
    },
    pendingTextStyle: {
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_14),
      color: COLORS.F54336,
      textTransform: GlobalStyleValues.CAPITALIZE,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      textAlign: GlobalStyleValues.CENTER,
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    quantitySelectorViewStyle: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_28),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_90),
      borderWidth: FixedValue.CONSTANT_VALUE_1,
      borderRadius: FixedValue.CONSTANT_VALUE_100,
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      ...BASESTYLE.spaceBetween,
      margin: FixedValue.CONSTANT_VALUE_10,
      backgroundColor: theme?.backgroundColor,
      overflow: GlobalStyleValues.HIDDEN,
      borderColor: COLORS._B3B3B3,
      marginRight: FixedValue.CONSTANT_VALUE_0,
    },
    right20: {
      right: scaleWidth(FixedValue.CONSTANT_VALUE_25),
    },
    selectOrUnselectView: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_5),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_4),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
  });
