import React from 'react';
import {View, Text, ActivityIndicator} from 'react-native';

import {COLORS, FixedValue, TEXT} from '../../constants';
import {HomeContext} from '../../contextAPI/homeContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import {getDateTimeUtil} from '../../utils/helperFunctions';
import {styles} from './smallDesign.style';
import useDistributiveTopHooks from '../../hooks/componentHooks/useDistributiveTopHooks';

const DistributeTopDetails = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  const {selectedTopData} = React.useContext(HomeContext);
  const {svgCode, loadSvgUrl} = useDistributiveTopHooks();

  React.useEffect(() => {
    loadSvgUrl();
  }, []);
  return (
    <View style={style.distributeTopDetailContainer}>
      {svgCode ? ( // conditionally render the SVG data
        <View style={style.topImageView}>{svgCode}</View>
      ) : (
        <View style={style.topImageView}>
          <ActivityIndicator size={'small'} color={COLORS.PRIMARY_BLUE} />
        </View>
      )}
      <View>
        <Text style={style.topTitle}>{selectedTopData?.name ?? ''}</Text>
        <Text
          numberOfLines={FixedValue.CONSTANT_VALUE_1}
          style={style.topDescription}
        >
          {TEXT.CREATED_AT}
          {getDateTimeUtil(selectedTopData?.creationDate as string)}
        </Text>
        <Text style={style.topDescription}>
          {TEXT.QUANTITY}: {selectedTopData?.quantity}
        </Text>
      </View>
    </View>
  );
};

export default React.memo(DistributeTopDetails);
