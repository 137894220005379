import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FONT_NAMES,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const style = (theme: IColorTheme) =>
  StyleSheet.create({
    subContainer: {
      width: Percentage.PRECENTAGE_100,
      backgroundColor: theme.backgroundColor,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_15),
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_18),
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_18,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_18,
      shadowColor: COLORS._333333,
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_0,
        height: -FixedValue.CONSTANT_VALUE_3,
      },
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
      elevation: FixedValue.CONSTANT_VALUE_3,
    },
    container: {
      width: Percentage.PRECENTAGE_100,
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_18),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    crossImage: {
      alignItems: GlobalStyleValues.FLEX_END,
    },
    yesOrNoButtonView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_105),
      borderRadius: FixedValue.CONSTANT_VALUE_100,
    },
    primaryButtonStyle: {
      ...BASESTYLE.inCenter,
      width: scaleHeight(FixedValue.CONSTANT_VALUE_159_5),
      borderRadius: FixedValue.CONSTANT_VALUE_100,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
    },
    primaryButtonStyle2: {
      ...BASESTYLE.inCenter,
      borderRadius: FixedValue.CONSTANT_VALUE_100,
      width: scaleHeight(FixedValue.CONSTANT_VALUE_159_5),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_8),
    },
    yesButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_17),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
    marginLeft16: {
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_16),
    },
    noButtonBackGroundColor: {
      backgroundColor: COLORS.E9EEFB,
    },
    noButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_17),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.PRIMARY_BLUE,
    },
    approve: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_22),
      color: COLORS._4EAF51,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_32),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_16),
    },
    wantToApprove: {
      // paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_69),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      color: theme.color,
      fontFamily: FONT_NAMES.SF_LIGHT,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_24),
      textAlign: GlobalStyleValues.CENTER,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    reject: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_22),
      color: COLORS._FF3D3D,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_32),
    },
  });
