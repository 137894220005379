import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
  SCREEN_WIDTH,
} from '../../utils/responsive';

export const style = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : SCREEN_WIDTH,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    headerText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: _theme?.color,
    },
    row: {
      ...BASESTYLE.row,
    },
    headerView: {
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.flex1,
    },
    listMainView: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.inCenter,
      backgroundColor: _theme.backgroundColor,
    },
    emptyView: {
      marginHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_28),
    },

    mainView: {
      width: Percentage.PRECENTAGE_100,
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_25),
    },
    marginTop: {
      // marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_25),
    },
    imageView: {
      ...BASESTYLE.inRow,
      width:  Percentage.PRECENTAGE_74,
    },
    imageStyle: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_43),
      overflow: GlobalStyleValues.HIDDEN,
    },
    textView: {
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      width: Percentage.PRECENTAGE_76,
    },

    listname: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: _theme?.color,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
    },
    groupTitle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_LIGHT,
      color: _theme?.color,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_17),
    },
    groupDay: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_LIGHT,
      color: _theme?.color,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_14),
    },
    circle: {
      ...BASESTYLE.inRow,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_70),
      ...BASESTYLE.spaceBetween,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_17),
    },
    flatListContentContainerStyle: {
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_70),
    },
    profileNameText: {
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      textAlign: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: _theme?.backgroundColor,
    },
    backgroundColorPlaceholder: {
      ...BASESTYLE.inCenter,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_43),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      overflow: GlobalStyleValues.HIDDEN,
      backgroundColor: _theme?.color,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
    },
    noChildData:{
      marginTop:scaleHeight(FixedValue.CONSTANT_VALUE_100)
    }
  });
