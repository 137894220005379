import React from 'react';
import {Text, View} from 'react-native';

import LabelInput from '../../components/input/labelInput';
import CheckboxWithText from '../../components/smallDesigns/checkboxWithText';
import SelectedCountry from '../../components/smallDesigns/selectedCountry';
import {FixedValue, TEXT} from '../../constants';
import {SignUpContext} from '../../contextAPI/signUpContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import {IBasicForm} from '../../types/componentTypes/signUpType';
import {_InputType} from '../../utils/helperFunctions';
import {signUpStyle} from './signUp.style';

const EmailMobileForm = (props: IBasicForm) => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = signUpStyle(ColorTheme);
  const {values, handleBlur, handleChange, emailError, phoneError, onPress} =
    props;
  const {userAge, havePersonalEmail, setHavePersonalEmail} =
    React.useContext(SignUpContext);
  const isGreaterThnZero: boolean =
    userAge >= FixedValue.CONSTANT_VALUE_0 &&
    userAge < FixedValue.CONSTANT_VALUE_13;
  const isMatureAge: boolean = userAge > FixedValue.CONSTANT_VALUE_12;
  return (
    <>
      {isGreaterThnZero && (
        <Text style={styles.morefieldTerm}>{TEXT.MORE_FIELD_TERM}</Text>
      )}
      {isGreaterThnZero && (
        <CheckboxWithText
          isAccept={havePersonalEmail}
          termText={TEXT.TERM_TEXT_1}
          onPress={setHavePersonalEmail}
        />
      )}
      <View style={styles.dividerLine} />
      {(isMatureAge || havePersonalEmail) && (
        <LabelInput
          inputLabel={TEXT.EMAIL_ADDRESS + '*'}
          value={values.Email}
          placeholder={TEXT.ENTER_EMAIL_ID_2}
          onChangeText={handleChange(TEXT.EMAIL)}
          onBlur={handleBlur(TEXT.EMAIL)}
          maxLength={FixedValue.CONSTANT_VALUE_50}
          haveError={`${emailError}`}
        />
      )}
      <LabelInput
        inputLabel={TEXT.MOBILE_NUMBER_}
        value={values.MobileNumber}
        placeholder={TEXT.ENTER_MOBILE_NUMBER}
        onChangeText={handleChange(TEXT.MOBILE_NUMBER)}
        onBlur={handleBlur(TEXT.MOBILE_NUMBER)}
        maxLength={FixedValue.CONSTANT_VALUE_10}
        haveError={`${phoneError}`}
        LeftElement={<SelectedCountry />}
        onPress={onPress}
        keyboardType={_InputType.phoneNumber}
      />
    </>
  );
};

export default React.memo(EmailMobileForm);
