import React from 'react';
import {Pressable, Text, TextStyle, View} from 'react-native';
import {deleteCollectionModalStyle} from './deleteCollectionModal.styles';
import {ThemeContext} from '../../contextAPI/themeContext';
import PrimaryButton from '../../components/button/primaryButton';
import {FixedValue, TEXT, BASESTYLE} from '../../constants';
import useDeleteCollectionHooks from '../../hooks/componentHooks/useDeleteCollectionHooks';
import {HomeContext} from '../../contextAPI/homeContext';
import CustomButton from '../../components/button/customButton';

const DeleteModalContent = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = deleteCollectionModalStyle(ColorTheme);
  const {
    forwardToNextModal,
    deleteModalNo,
    collectionDeleteType,
    setCollectionDeleteType,
    callDeleteCollectionAndTopsAPI,
    forTopDelete,
    forCollectionDelete,
  } = useDeleteCollectionHooks();
  const {setOpenDeleteCollectionModal} = React.useContext(HomeContext);
  const modalTwoActive = deleteModalNo === FixedValue.CONSTANT_VALUE_2;
  const selectDeleteCollectionOnly =
    collectionDeleteType !== FixedValue.CONSTANT_VALUE_2;
  const selectDeleteTopsAndCollection =
    collectionDeleteType !== FixedValue.CONSTANT_VALUE_1;
  const collectionDeleteTypeTwo =
    collectionDeleteType === FixedValue.CONSTANT_VALUE_2;
  const backGroundColor = (data: boolean): TextStyle => {
    return data
      ? styles.backgroundColorLighBlue
      : styles.backgroundColorPrimaryBlue;
  };

  const textColor = (data: boolean): TextStyle => {
    return data ? styles.textColorWhite : styles.textColorPrimaryBlue;
  };

  const returnTickTextStyle = (data: boolean): any => {
    return <Text style={[textColor(data), styles.marginRight10]}>{'✓'}</Text>;
  };

  return (
    <View style={styles.container}>
      <View style={BASESTYLE.alignCenter}>
        {deleteModalNo === FixedValue.CONSTANT_VALUE_0 && (
          <>
            <Text style={styles.iWant}>{TEXT.I_WANT} </Text>
            <Pressable
              onPress={() => forTopDelete()}
              style={[
                styles.activeTabButton,
                backGroundColor(selectDeleteTopsAndCollection),
              ]}
            >
              {collectionDeleteType === FixedValue.CONSTANT_VALUE_1 &&
                returnTickTextStyle(!selectDeleteTopsAndCollection)}
              <Text
                style={[
                  textColor(!selectDeleteTopsAndCollection),
                  styles.deleteTextStyle,
                ]}
              >
                {TEXT.DELETE_DECS}
              </Text>
            </Pressable>
            <Pressable
              onPress={() => forCollectionDelete()}
              style={[
                styles.activeTabButton,
                backGroundColor(selectDeleteCollectionOnly),
              ]}
            >
              {collectionDeleteType === FixedValue.CONSTANT_VALUE_2 &&
                returnTickTextStyle(!selectDeleteCollectionOnly)}
              <Text
                style={[
                  styles.deleteTextStyle,
                  textColor(!selectDeleteCollectionOnly),
                ]}
              >
                {TEXT.MOVE_OR_DELETE}
              </Text>
            </Pressable>
          </>
        )}

        {deleteModalNo === FixedValue.CONSTANT_VALUE_1 && (
          <>
            <Text style={styles.wantDelete}>
              {TEXT.ARE_YOU_SURE}
              {TEXT.WANT_TO_DELETE}
            </Text>
            <PrimaryButton
              buttonLabel={TEXT.CONTINUE}
              onPress={() => forwardToNextModal()}
              buttonwidth={styles.countinueButtonView}
              labelStyle={styles.continueButtonLabelstyle}
              isDisabled={
                collectionDeleteType === FixedValue.CONSTANT_VALUE_1 ||
                collectionDeleteType === FixedValue.CONSTANT_VALUE_2
                  ? false
                  : true
              }
            />
          </>
        )}

        {modalTwoActive && (
          <>
            {!collectionDeleteTypeTwo ? (
              <Text style={styles.wantDelete2}>
                {TEXT.ARE_YOU_SURE}
                {TEXT.WANT_TO_TOPS}
              </Text>
            ) : (
              <Text style={styles.wantDelete2}>
                {TEXT.ARE_YOU_SURE}
                {TEXT.WANT_TO_TOPS_AND_MOVE}
              </Text>
            )}
            <View style={styles.warningView}>
              <Text style={styles.deleteWarning}>
                {!collectionDeleteTypeTwo
                  ? TEXT.DELETE_WARNING
                  : TEXT.DELETE_WARNING_COLLECTION}
              </Text>
            </View>
          </>
        )}
      </View>
      <View style={styles.marginTop20}>
        {modalTwoActive ? (
          <>
            <View style={[BASESTYLE.inRow, BASESTYLE.inCenter]}>
              <CustomButton
                buttonLabel={TEXT.NO}
                buttonwidth={styles.primaryButtonStyle2}
                buttonBackGroundColor={styles.noButtonBackGroundColor}
                labelStyle={styles.noButtonLabelstyle}
                onPress={() => setOpenDeleteCollectionModal()}
                isDisabled={false}
              />
              <CustomButton
                buttonLabel={TEXT.YES}
                buttonwidth={styles.primaryButtonStyle}
                labelStyle={styles.yesButtonLabelstyle}
                onPress={() =>
                  callDeleteCollectionAndTopsAPI(collectionDeleteType)
                }
                isDisabled={false}
              />
            </View>
          </>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};

export default DeleteModalContent;
