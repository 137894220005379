import React from 'react';
import {View} from 'react-native';

import {ThemeContext} from '../../contextAPI/themeContext';
import {orderItemStyle} from './orderItem.style';
import OrderItemContent from './orderItemContent';

const OrderItemModal = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = orderItemStyle(ColorTheme);

  return (
    <View style={styles.modalStyle} dataSet={{media: ids.modalStyle}}>
      <View style={styles.outterPart}>
        <View style={styles.innerView}>
          <OrderItemContent />
        </View>
      </View>
    </View>
  );
};

export default React.memo(OrderItemModal);
