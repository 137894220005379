import React from 'react';
import {View, Text, Pressable, ActivityIndicator} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {tradeCompStyle} from './receviedOffer.style';
import {COLORS, FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import {normalizeFont, scaleWidth} from '../../utils/responsive';
import {TradeContext} from '../../contextAPI/tradeContext';
import {OfferModel} from '../../types/reducerTypes/tradeTabTypes';
import useAwsS3 from '../../hooks/libraryHooks/useAwsS3';
import {formatUTCTimeToLocalTime} from '../../utils/helperFunctions';
import CrossWithCircle from '../renderSvgs/crossWithCircle';
import {Percentage, timeformat} from '../../constants/enum';
import {getTopImage} from '../../utils/imageUtils';

const SentOfferRender = (data: OfferModel): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const styles = tradeCompStyle(ColorTheme);
  const {
    setOpenSentOfferModal,
    setSelectedItem,
    setCurrentActiveTab,
    setCancelModal,
  } = React.useContext(TradeContext);
  const [svgCode, setSvgCode] = React.useState<JSX.Element | undefined>(
    undefined
  );
  const {loadSignedUrl} = useAwsS3();

  const getSvgUrl = React.useCallback(async (): Promise<void> => {
    const imageUrl = await loadSignedUrl(
      data?.toUserTops[FixedValue.CONSTANT_VALUE_0].image ?? ''
    );
    if (imageUrl) {
      const topImage = await getTopImage(imageUrl ?? '');
      setSvgCode(topImage);
    }
  }, [data?.toUserTops, svgCode]);

  React.useEffect(() => {
    getSvgUrl();
  }, [data?.toUserTops]);
  const callDataintoModal = () => {
    setOpenSentOfferModal();
    setCurrentActiveTab(ROUTE_NAME.SENT_OFFER);
    setSelectedItem(data);
  };
  const showModal = () => {
    setSelectedItem(data);
    setCancelModal();
  };
  return (
    <View style={styles.mainView}>
      <Pressable
        style={[
          styles.headingView,
          {
            width:
              data.status === TEXT.PENDING_IN_CAPS
                ? Percentage.PRECENTAGE_67
                : Percentage.PRECENTAGE_75,
          },
        ]}
        onPress={() => callDataintoModal()}
      >
        {svgCode ? ( // conditionally render the SVG data
          <View style={styles.cardStyle}>{svgCode}</View>
        ) : (
          <View style={styles.imageLoader}>
            <ActivityIndicator size={'small'} color={COLORS.PRIMARY_BLUE} />
          </View>
        )}
        <View
          style={[
            styles.textView,
            {
              width:
                data.status === TEXT.PENDING_IN_CAPS
                  ? Percentage.PRECENTAGE_64
                  : Percentage.PRECENTAGE_68,
            },
          ]}
        >
          <Text
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
            style={styles.owner}
          >
            {data?.toUser?.firstName} {data?.toUser?.lastName}
          </Text>
          <Text
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
            style={styles.title}
          >
            {data?.toUserTops[FixedValue.CONSTANT_VALUE_0]?.name ?? ''}
          </Text>
          <Text numberOfLines={FixedValue.CONSTANT_VALUE_1} style={styles.day}>
            {formatUTCTimeToLocalTime(
              data?.creationDate ?? '',
              timeformat.MMMDDYY
            )}
          </Text>
        </View>
      </Pressable>

      <View
        style={[
          styles.threedot,
          {
            width:
              data.status === TEXT.PENDING_IN_CAPS
                ? Percentage.PRECENTAGE_33
                : Percentage.PRECENTAGE_25,
          },
        ]}
      >
        {data.status === TEXT.CANCELLED_IN_CAPS ? (
          <View
            style={[
              styles.buttonCommonStyle,
              {
                backgroundColor: COLORS.FDE4E1,
              },
            ]}
          >
            <Text
              style={{
                color: COLORS.F54336,
                fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
              }}
            >
              {TEXT.CANCELLED_IN_SMALL}
            </Text>
          </View>
        ) : null}
        {data.status === TEXT.REJECTED_IN_CAPS ? (
          <View
            style={[
              styles.buttonCommonStyle,
              {
                backgroundColor: COLORS.FDE4E1,
              },
            ]}
          >
            <Text
              style={{
                color: COLORS.F54336,
                fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
              }}
            >
              {TEXT.REJECTED_IN_SMALL}
            </Text>
          </View>
        ) : null}
        {data.status === TEXT.INVALID_IN_CAPS ? (
          <View
            style={[
              styles.buttonCommonStyle,
              {
                backgroundColor: COLORS.FDE4E1,
              },
            ]}
          >
            <Text
              style={{
                color: COLORS.F54336,
                fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
              }}
            >
              {TEXT.INVALID}
            </Text>
          </View>
        ) : null}
        {data.status === TEXT.ACCEPTED_IN_CAPS ? (
          <View
            style={[
              styles.buttonCommonStyle,
              {
                backgroundColor: COLORS._E6F3E5,
              },
            ]}
          >
            <Text
              style={{
                color: COLORS._4EAF51,
                fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
              }}
            >
              {TEXT.ACCEPTED_IN_SMALL}
            </Text>
          </View>
        ) : null}
        <View style={styles.panding}>
          {data.status === TEXT.PENDING_IN_CAPS ? (
            <View
              style={[
                styles.buttonCommonStyle,
                {
                  backgroundColor: COLORS._FEF5CC,
                },
              ]}
            >
              <Text
                style={{
                  color: COLORS._A07608,
                  fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
                }}
              >
                {TEXT.PENDING_IN_SMALL}
              </Text>
            </View>
          ) : null}
          {data.status === TEXT.PENDING_IN_CAPS ? (
            <View style={styles.threeDotView}>
              <Pressable
                onPress={() => {
                  showModal();
                }}
              >
                <View
                  style={{marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_20)}}
                >
                  <CrossWithCircle
                    width={FixedValue.CONSTANT_VALUE_25}
                    height={FixedValue.CONSTANT_VALUE_25}
                  />
                </View>
              </Pressable>
            </View>
          ) : null}
        </View>
      </View>
    </View>
  );
};

export default React.memo(SentOfferRender);
