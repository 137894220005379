import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FONT_NAMES,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  SCREEN_HEIGHT,
} from '../../utils/responsive';

export const orderItemStyle = (_theme?: IColorTheme) =>
  StyleSheet.create({
    modalStyle: {
      ...BASESTYLE.flex1,
      zIndex: FixedValue.CONSTANT_VALUE_10,
      height: Percentage.PRECENTAGE_100,
      width: isWebsite()
        ? FixedValue.CONSTANT_VALUE_500
        : Percentage.PRECENTAGE_100,
      ...BASESTYLE.selfCenter,
      ...BASESTYLE.absolutePosition,
      alignItems: GlobalStyleValues.CENTER,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    outterPart: {
      width: Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      backgroundColor: COLORS.LGHT_BLCK_02,
      justifyContent: GlobalStyleValues.FLEX_END,
    },
    innerView: {
      backgroundColor: COLORS.WHITE,
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
      width: Percentage.PRECENTAGE_100,
      padding: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      paddingBottom: isWebsite()
        ? FixedValue.CONSTANT_VALUE_20
        : scaleHeight(FixedValue.CONSTANT_VALUE_80),
      shadowColor: _theme?.color,
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_16,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_16,
      maxHeight: SCREEN_HEIGHT - scaleHeight(FixedValue.CONSTANT_VALUE_80),
      shadowRadius: FixedValue.CONSTANT_VALUE_5,
      elevation: FixedValue.CONSTANT_VALUE_2,
      shadowOffset: {
        height: FixedValue.CONSTANT_VALUE_2,
        width: FixedValue.CONSTANT_VALUE_2,
      },
    },
    extraSpace: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    headerView: {
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_22),
    },
    orderText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: COLORS.BLACK,
    },
    doneText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: COLORS._4EAF51,
    },
  });
