import React from 'react';
import {Pressable, View, StyleSheet} from 'react-native';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import {COLORS, GlobalStyleValues} from '../../constants';
import HeartFavoriteIcon from '../renderSvgs/heartFavoriteIcon';
import HeartIcon from '../renderSvgs/heartIcon';
import {FixedValue} from '../../constants';

const FavoriteButton = ({
  isFavorite,
  onFavOrUnFavUser,
}: {
  isFavorite: boolean;
  onFavOrUnFavUser?: () => void;
}) => {
  const getFavoriteIcon = (val: boolean) => {
    if (val) {
      return (
        <View style={styles.heartContainer}>
          <HeartFavoriteIcon
            height={scaleHeight(FixedValue.CONSTANT_VALUE_28)}
            width={scaleWidth(FixedValue.CONSTANT_VALUE_28)}
          />
        </View>
      );
    } else {
      return (
        <View style={styles.heartContainer}>
          <HeartIcon
            height={scaleHeight(FixedValue.CONSTANT_VALUE_34)}
            width={scaleWidth(FixedValue.CONSTANT_VALUE_34)}
          />
        </View>
      );
    }
  };

  return (
    <Pressable onPress={onFavOrUnFavUser}>
      {getFavoriteIcon(isFavorite)}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  heartContainer: {
    height: scaleHeight(FixedValue.CONSTANT_VALUE_34),
    width: scaleWidth(FixedValue.CONSTANT_VALUE_34),
    backgroundColor: COLORS.E9EEFB,
    borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_34),
    justifyContent: GlobalStyleValues.CENTER,
    alignItems: GlobalStyleValues.CENTER,
  },
});

export default FavoriteButton;
