import React from 'react';
import {View} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {DistributeModalModalStyle} from './distributeModal.style';
import DistributeModalContent from './distributeModalContent';
import ModalHeaderWithCross from '../../components/header/modalHeaderWithCross';
import {TEXT} from '../../constants';
import ModalContainer from '../../components/layout/modalContainer';
import AppLoader from '../../components/smallDesigns/appLoader';

interface TradeProps {
  isSent: boolean;
  modalVisible: boolean;
  onClose: () => void;
  showLoader?: boolean;
  isChild?: boolean;
  showActionButton?: boolean;
}
const DistributeModal = (props: TradeProps): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = DistributeModalModalStyle(ColorTheme);
  const {
    modalVisible,
    onClose,
    showLoader,
    isChild = false,
    showActionButton = false,
  } = props;

  return (
    <ModalContainer visible={modalVisible} onPressOutside={onClose}>
      <View style={styles.innerView}>
        <View style={styles.crossImage}>
          <ModalHeaderWithCross
            onPress={onClose}
            headerTitle={TEXT.DISTRIBUTE_TOP_HEADER}
          />
        </View>
        {showLoader ? (
          <View style={styles.flex1}>
            <AppLoader />
          </View>
        ) : (
          <DistributeModalContent
            onClose={onClose}
            isChild={isChild}
            showActionButton={showActionButton}
          />
        )}
      </View>
    </ModalContainer>
  );
};

export default DistributeModal;
