import React, {useEffect} from 'react';
import {
  FlatList,
  Pressable,
  RefreshControl,
  ScrollView,
  Text,
  View,
} from 'react-native';

import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import {ThemeContext} from '../../contextAPI/themeContext';
import TitleWithButton from '../../components/header/titleWithButton';
import {FavoriteListStyle} from './Style';
import {FixedValue, TEXT} from '../../constants';
import {useIsFocused} from '@react-navigation/native';
import useFavouriteHooks from '../../hooks/componentHooks/useFavouriteHooks';
import {FavoriteContext} from '../../contextAPI/favoriteContext';
import EmptyChildListView from '../../components/emptyListView/emptyChildListView';
import ProfilePics from '../../components/smallDesigns/profilePics';
import {
  _ToastHandler,
  alertPolyfill,
  getInitials,
} from '../../utils/helperFunctions';
import AppLoader from '../../components/smallDesigns/appLoader';
import {USER_MARK_UNMARK_FAVORITE} from '../../constants/actionTypes';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import {useAppSelector} from '../../hooks/reduxHooks';
import {TOAST_TEXT} from '../../constants/textMessage';
import {CustomUserListSearchModalListData} from '../../types/reducerTypes/searchTabStoreType';

const FavoriteList = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = FavoriteListStyle(ColorTheme);
  const isFocused = useIsFocused();
  const {
    favoriteListData,
    favoriteListLoader,
    setFavoriteListLoader,
    favoriteListPullDownRefresh,
    setFavoriteListPullDownRefresh,
  } = React.useContext(FavoriteContext);
  const {userMarkAndUnmarkFavoriteData} = useAppSelector(userProfileStore);

  // Pull-down refresh function for the favorite user list
  const favoriteUserListHandleRefresh = React.useCallback(() => {
    setFavoriteListPullDownRefresh(true);
    getfavoriteUserList('', FixedValue.CONSTANT_VALUE_0);
  }, [favoriteListPullDownRefresh]);

  const {
    getfavoriteUserList,
    onFavoriteUserListReachedEnd,
    addFavoriteAndUnFavorite,
  } = useFavouriteHooks();

  useEffect(() => {
    if (isFocused) {
      setFavoriteListLoader(true);
      getfavoriteUserList('', FixedValue.CONSTANT_VALUE_0);
    }
  }, [isFocused]);

  const showConfirmationDialog = ({
    item,
  }: {
    item: CustomUserListSearchModalListData;
  }) => {
    const sunText = `Are you sure you want to remove '${
      item.firstName + ' ' + item.lastName
    }' from your Favorite list?`;
    alertPolyfill('Remove Favorite', sunText, [
      {
        text: 'No',
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: 'Yes',
        onPress: () =>
          onPressRemoveFavorite(item.id ?? FixedValue.CONSTANT_VALUE_0),
      },
    ]);
  };

  const onPressRemoveFavorite = React.useCallback(
    (removeUserId: number): void => {
      setFavoriteListLoader(true);
      const params = {
        favorite: false,
        userIds: [removeUserId ?? FixedValue.CONSTANT_VALUE_0],
      };
      addFavoriteAndUnFavorite(params);
    },
    []
  );

  useEffect(() => {
    if (isFocused) {
      switch (userMarkAndUnmarkFavoriteData.status) {
        case USER_MARK_UNMARK_FAVORITE.SUCCESS:
          _ToastHandler(TOAST_TEXT.USER_REMOVED_FROM_FAVORITE, true);
          getfavoriteUserList('', FixedValue.CONSTANT_VALUE_0);
          break;
        case USER_MARK_UNMARK_FAVORITE.FAILED:
          break;
      }
    }
  }, [userMarkAndUnmarkFavoriteData?.status]);

  const renderFavoriteUserItem = ({
    item,
  }: {
    item: CustomUserListSearchModalListData;
  }) => (
    <View style={styles.userItem}>
      <View style={styles.userContainer}>
        <ProfilePics
          image={item.profileImage ?? ''}
          text={getInitials(
            item.firstName.trim() + ' ' + item.lastName.trim() ?? ''
          )}
          mainViewStyle={styles.profileImage}
          initialsTextStyle={styles.groupProfileText}
        />
        <Text
          style={styles.userName}
          numberOfLines={FixedValue.CONSTANT_VALUE_1}
        >
          {item.firstName.trim()} {item.lastName.trim()}
        </Text>
      </View>
      <Pressable
        onPress={() => showConfirmationDialog({item})}
        style={styles.removeContainer}
      >
        <Text style={styles.removeButton}>{TEXT.REMOVE}</Text>
      </Pressable>
    </View>
  );

  const renderMyFavoriteEmptyUI = React.useMemo((): JSX.Element | null => {
    if (favoriteListData?.length === FixedValue.CONSTANT_VALUE_0) {
      return (
        <ScrollView
          contentContainerStyle={styles.scrollviewStyle}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={favoriteListPullDownRefresh}
              onRefresh={favoriteUserListHandleRefresh}
            />
          }
        >
          <View style={styles.emptyView}>
            <EmptyChildListView
              emptyViewTitle={TEXT.SORRY_NO_FAVORITE_USER}
              emptyViewSubTitle={''}
            />
          </View>
        </ScrollView>
      );
    }
    return null;
  }, [favoriteListData]);

  return (
    <SafeAreaContainer>
      <>
        {favoriteListLoader && <AppLoader />}
        <View style={styles.container} dataSet={{media: ids.container}}>
          <TitleWithButton title={TEXT.FAVORITES ?? ''} />
          <FlatList
            data={favoriteListData}
            renderItem={renderFavoriteUserItem}
            ListEmptyComponent={
              !favoriteListLoader ? renderMyFavoriteEmptyUI : null
            }
            onEndReached={() => onFavoriteUserListReachedEnd()}
            onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
            showsVerticalScrollIndicator={false}
            scrollEnabled
            refreshControl={
              <RefreshControl
                refreshing={favoriteListPullDownRefresh}
                onRefresh={favoriteUserListHandleRefresh}
              />
            }
            keyExtractor={item => item.id.toString()}
          />
        </View>
      </>
    </SafeAreaContainer>
  );
};

export default React.memo(FavoriteList);
