import React from 'react';
import {View, Text, Pressable} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {BASESTYLE, FixedValue, TEXT} from '../../constants';
import BadgeCount from '../../components/smallDesigns/badgeCount';
import {navigate} from '../../services/navigationService';
import RightArrow from '../../components/renderSvgs/rightArrow';
import {ChildprofileStyle} from './child.style';
import {ChildProfileBtnType} from '../../types/componentTypes/childProfileType';
import {ChildProfileButtonArray} from '../../constants/appConst';
import {ChildListModel} from '../../types/reducerTypes/userProfileTypes';
import {ChildProfileContext} from '../../contextAPI/childProfileContext';
import {_ToastHandler} from '../../utils/helperFunctions';
import {TOAST_TEXT} from '../../constants/textMessage';
import {
  onClearApprovalReceivedListEnd,
  onClearDistributeRequestListEnd,
  onClearTradeApprovalSentListEnd,
  onClearTradeApprovedListEnd,
  onClearChildTradableRequestListEnd,
} from '../../redux/reducers/childProfileSlice';
import {useAppDispatch} from '../../hooks/reduxHooks';

type propsInfo = {
  childObj: ChildListModel | null;
};

const ChildProfileBottomBtn = (props: propsInfo) => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = ChildprofileStyle(ColorTheme);
  const {
    childUserData,
    clearChildTradableList,
    clearDistributeRequestListArray,
    clearGroupInvitation,
    clearTradeReceivedList,
    clearSetTradeApprovedUserListData,
  } = React.useContext(ChildProfileContext);
  const {childObj} = props;
  const modifiedChildProfileButtonArray =
    childObj?.accountApprovalRequests === FixedValue.CONSTANT_VALUE_0
      ? ChildProfileButtonArray.slice(FixedValue.CONSTANT_VALUE_1)
      : ChildProfileButtonArray;
  const dispatch = useAppDispatch();

  const getBadgeCount = (itemInfo: ChildProfileBtnType) => {
    switch (itemInfo?.label) {
      case TEXT.PROFILE_REQ:
        return childObj?.accountApprovalRequests
          ? childObj?.accountApprovalRequests
          : FixedValue.CONSTANT_VALUE_0;
      case TEXT.TOP_TRADING:
        return (
          (childObj?.pendingRequestsCount?.sentOffer ??
            FixedValue.CONSTANT_VALUE_0) +
          (childObj?.pendingRequestsCount?.receivedOffer ??
            FixedValue.CONSTANT_VALUE_0)
        );
      case TEXT.TOP_BUYING_APPROVAL:
        return (
          childObj?.pendingRequestsCount?.purchase ??
          FixedValue.CONSTANT_VALUE_0
        );
      case TEXT.SHIFTING_TO_TRADEABLE:
        return (
          childObj?.pendingRequestsCount?.tradable ??
          FixedValue.CONSTANT_VALUE_0
        );
      case TEXT.DISTRIBUTING_TOP_REQUEST:
        return (
          (childObj?.pendingRequestsCount?.receivedDistribute ??
            FixedValue.CONSTANT_VALUE_0) +
            (childObj?.pendingRequestsCount?.sentDistribute ??
              FixedValue.CONSTANT_VALUE_0) ?? FixedValue.CONSTANT_VALUE_0
        );
      case TEXT.GROUP_INVITATION:
        return (
          childObj?.pendingRequestsCount?.group ?? FixedValue.CONSTANT_VALUE_0
        );
      default:
        return FixedValue.CONSTANT_VALUE_0;
    }
  };

  //check child user request is accept or not
  const onPress = React.useCallback(
    (itemData: ChildProfileBtnType): void => {
      if (
        childUserData?.accountApprovalRequests ===
          FixedValue.CONSTANT_VALUE_0 ||
        TEXT.PROFILE_REQ === itemData?.label
      ) {
        if (TEXT.TOP_TRADING === itemData?.label) {
          dispatch(onClearApprovalReceivedListEnd());
          dispatch(onClearTradeApprovalSentListEnd());
          dispatch(onClearTradeApprovedListEnd());
          clearTradeReceivedList();
          clearSetTradeApprovedUserListData();
        } else if (TEXT.SHIFTING_TO_TRADEABLE === itemData?.label) {
          dispatch(onClearChildTradableRequestListEnd());
          clearChildTradableList();
        } else if (TEXT.DISTRIBUTING_TOP_REQUEST === itemData?.label) {
          dispatch(onClearDistributeRequestListEnd());
          clearDistributeRequestListArray();
        } else if (TEXT.GROUP_INVITATION === itemData?.label) {
          clearGroupInvitation();
        } else {
          // Do nothing.
        }
        navigate(itemData?.onPress ?? '', {item: childObj});
      } else {
        _ToastHandler(TOAST_TEXT.CHILD_REQUEST_NOT_APPOVED, false);
      }
    },
    [childObj, modifiedChildProfileButtonArray]
  );

  return (
    <View style={styles.profileBtnView}>
      {modifiedChildProfileButtonArray.map((itemInfo: ChildProfileBtnType) => (
        <Pressable
          style={styles.profileSingleBtnView}
          key={itemInfo.label}
          onPress={() => onPress(itemInfo)}
        >
          <Text style={styles.profileBtnLabelText}>{itemInfo.label}</Text>
          <View style={BASESTYLE.inRow}>
            {getBadgeCount(itemInfo) > FixedValue.CONSTANT_VALUE_0 && (
              <BadgeCount
                count={getBadgeCount(itemInfo)}
                badgeStyle={styles.badgeStyle}
              />
            )}
            <RightArrow stroke={ColorTheme.color} />
          </View>
        </Pressable>
      ))}
    </View>
  );
};

export default React.memo(ChildProfileBottomBtn);
