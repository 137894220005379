import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {isWebsite, scaleHeight, SCREEN_HEIGHT} from '../../utils/responsive';

export const countryListStyle = (_theme?: IColorTheme) =>
  StyleSheet.create({
    modalStyle: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.selfCenter,
      justifyContent: GlobalStyleValues.FLEX_END,
      width: isWebsite()
        ? FixedValue.CONSTANT_VALUE_500
        : Percentage.PRECENTAGE_100,
      alignItems: GlobalStyleValues.CENTER,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    innerView: {
      backgroundColor: _theme?.backgroundColor,
      width: Percentage.PRECENTAGE_100,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      paddingHorizontal: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_16,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_16,
      height: SCREEN_HEIGHT / FixedValue.CONSTANT_VALUE_2,
      shadowColor: _theme?.color,
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
      shadowRadius: FixedValue.CONSTANT_VALUE_5,
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_2,
        height: FixedValue.CONSTANT_VALUE_2,
      },
      elevation: FixedValue.CONSTANT_VALUE_2,
    },
    contentContainerStyle: {
      paddingVertical: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    outterPart: {
      width: Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      ...BASESTYLE.absolutePosition,
      zIndex: FixedValue.CONSTANT_VALUE_MIN_1,
      backgroundColor: COLORS.LIGHT_BLACK,
    },
  });
