import React, {useEffect} from 'react';
import {ScrollView, View} from 'react-native';
import TitleWithButton from '../../components/header/titleWithButton';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import {FixedValue, TEXT} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {blockContentSyle} from './blockUserContentSyle';
import {useIsFocused} from '@react-navigation/native';
import AppLoader from '../../components/smallDesigns/appLoader';
import BlockUserContent from './blockUserContent';
import useUserProfileHooks from '../../hooks/componentHooks/useUserProfileHooks';
import {ProfileTabContext} from '../../contextAPI/profileTabContext';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import {useAppSelector} from '../../hooks/reduxHooks';
import {UNBLOCK_USER_ACTION_API} from '../../constants/actionTypes';
import {_ToastHandler} from '../../utils/helperFunctions';
import {TOAST_TEXT} from '../../constants/textMessage';

const BlockUserList = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = blockContentSyle(ColorTheme);
  const isFocused = useIsFocused();
  const {getUserBlockList} = useUserProfileHooks();
  const {userBlockListLoader, setUserBlockListLoader} =
    React.useContext(ProfileTabContext);
  const {unBlockUser} = useAppSelector(userProfileStore);

  // Handle unblock user API response
  useEffect(() => {
    switch (unBlockUser.status) {
      case UNBLOCK_USER_ACTION_API.SUCCESS:
        _ToastHandler(TOAST_TEXT.USER_SUCCESFFULLY_UNBLOCKED, true);
        getUserBlockList(FixedValue.CONSTANT_VALUE_0);
        break;
      case UNBLOCK_USER_ACTION_API.FAILED:
        setUserBlockListLoader(false);
        break;
    }
  }, [unBlockUser?.status]);

  // Fetch user block list on screen focus
  useEffect(() => {
    if (isFocused) {
      setUserBlockListLoader(true);
      getUserBlockList(FixedValue.CONSTANT_VALUE_0);
    }
  }, [isFocused]);

  return (
    <SafeAreaContainer>
      <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
        <View style={styles.headerView}>
          {/* Display title with a button in the header */}
          <TitleWithButton title={TEXT.BLOCK_USER} />
        </View>
        {/* Display block user content */}
        <ScrollView>
          <BlockUserContent />
        </ScrollView>
        {/* Display loader while user block list and unblock API is calling */}
        {userBlockListLoader && <AppLoader />}
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(BlockUserList);
