import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";
const CROSSICON = (props:SvgProps) => (
  <Svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M9 3L3 9"
      stroke="#F54336"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M3 3L9 9"
      stroke="#F54336"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default CROSSICON;
