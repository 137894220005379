import React from 'react';
import {View, FlatList, Text, Modal, Pressable} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {modalStyle} from './styles';
import {FixedValue, TEXT} from '../../constants';
import {TopDetailContext} from '../../contextAPI/topDetailContext';
import CreateCollectionButton from '../../components/button/createCollectionButton';
import {CreateCollectionContext} from '../../contextAPI/createCollectionContext';
import CreateCollectionFormModal from '../createCollectionModal';
import CancelButton from '../../components/button/cancelButton';
import {HomeContext} from '../../contextAPI/homeContext';
import {
  CollectionModel,
  TopModel,
} from '../../types/reducerTypes/searchTabStoreType';
import TopCard from '../../components/mantelTopTabComp/topCardWithQuantity';
import SelectCheckBox from '../../components/renderSvgs/selectedRadioBox';
import UncheckBox from '../../components/renderSvgs/unselectedRadioBox';
import useSeeAllTopsHooks from '../../hooks/componentHooks/useSeeAllTopsHooks';
import {HomeStore} from '../../redux/reducers/homeSlice';
import {useAppSelector} from '../../hooks/reduxHooks';
import {
  ADD_TOPS_TO_COLLECTION,
  TOPS_MOVE_TO_COLLECTION,
} from '../../constants/actionTypes';
import ConfirmationModal from '../confirmationModal/confirmationModal';
import {
  capitalizeFirstLetter,
  showAlertForExceedQuantity,
} from '../../utils/helperFunctions';
import {useIsFocused} from '@react-navigation/native';
import ModalContainer from '../../components/layout/modalContainer';

const MoveToCollections = (): JSX.Element => {
  const {callApiToMoveToCollection} = useSeeAllTopsHooks();
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = modalStyle(ColorTheme);
  const {
    openMoveToCollectionModal,
    openMoveTopConfirmationModal,
    setOpenMoveToCollectionModal,
    setOpenMoveTopConfirmationModal,
  } = React.useContext(TopDetailContext);
  const {setOpenCreateCollectionModal, setCollectionData} = React.useContext(
    CreateCollectionContext
  );
  const {
    collectionsResult,
    selectedTopsToMove,
    selectedFromCollectionData,
    setSelectedFromCollectionData,
    updateTopQuantity,
    setSelectedTopsToMove
  } = React.useContext(HomeContext);
  const [selectedTop, setSelectedTop] = React.useState<CollectionModel | null>(
    null
  );
  const Separator = () => <View style={styles.seperatorView} />;
  const {addTopsToCollection, moveToCollection} = useAppSelector(HomeStore);
  const hasPendingRequests = selectedTopsToMove.some(
    item =>
      item.pendingRequest &&
      item.pendingRequest.totalPendingRequests &&
      item.pendingRequest.totalPendingRequests >= FixedValue.CONSTANT_VALUE_1
  );
  const isFocused = useIsFocused();
  const confirmText = `${TEXT.MOVE_TOPS_CONFIRM_1} '${selectedTop?.name}'? \n\n${TEXT.MOVE_TOPS_CONFIRM_2}`;

  React.useEffect(() => {
    switch (addTopsToCollection.status) {
      case ADD_TOPS_TO_COLLECTION.SUCCESS:
        setSelectedTop(null);
        setSelectedFromCollectionData(null);
        break;
      case ADD_TOPS_TO_COLLECTION.FAILED:
        break;
      default:
        break;
    }
  }, [addTopsToCollection.status]);

  React.useEffect(() => {
    switch (moveToCollection.status) {
      case TOPS_MOVE_TO_COLLECTION.SUCCESS:
        setSelectedTop(null);
        setSelectedFromCollectionData(null);
        break;
      case TOPS_MOVE_TO_COLLECTION.FAILED:
        break;
      default:
        break;
    }
  }, [moveToCollection.status]);

  const handelCancelOption = () => {
    setSelectedTop(null);
    setOpenMoveToCollectionModal();
  };
  //call API for move uncategorized tops to collection tops
  const callAddToColectionAPI = () => {
    const tops = selectedTopsToMove?.map((top: TopModel) => ({
      topId: top.id,
      quantity: top.selectedQuantity,
    }));
    const params = {
      topsData: tops,
      collectionId: selectedTop?.id,
    };
    callApiToMoveToCollection(params);
  };

  const quantityModify = (item: TopModel, val: string) => {
    if (val === TEXT.MINNUS || item.quantity !== item.selectedQuantity) {
      updateTopQuantity(item, val);
    } else {
      showAlertForExceedQuantity(item?.quantity ?? FixedValue.CONSTANT_VALUE_0);
    }
  };

  const quantityUpdate = (item: TopModel, val: string) => {
    if (item.selectedQuantity === FixedValue.CONSTANT_VALUE_1) {
      if (val !== TEXT.MINNUS) {
        quantityModify(item, val);
      }
    } else {
      quantityModify(item, val);
    }
  };

  const onChangeUpdateQuantity = (item: TopModel, quantity: number) => {
    const itemQuantity = item?.quantity ?? FixedValue.CONSTANT_VALUE_0;
    if (itemQuantity >= quantity) {
      // @ts-ignore
      setSelectedTopsToMove(prev => {
        // @ts-ignore
        const newUpdatedCollection = prev.map(topData => {
          if (topData?.id === item?.id) {
            return {
              ...topData,
              selectedQuantity: quantity,
            };
          }
          return topData;
        });
        return newUpdatedCollection;
      });
    } else {
      showAlertForExceedQuantity(item?.quantity ?? FixedValue.CONSTANT_VALUE_0);
    }
  };

  const renderCollectionList = (item: CollectionModel, index: number) => {
    const isSelected = item.id === selectedTop?.id;

    const handlePress = () => {
      if (isSelected) {
        setSelectedTop(null);
      } else {
        setSelectedTop(item);
      }
    };
    return (
      <View style={styles.cellView} dataSet={{media: ids.cellView}}>
        <Text style={styles.collectionNameText} numberOfLines={1}>
          {capitalizeFirstLetter(item.name ?? '')}
        </Text>
        <View style={styles.newCollectionView}>
          <Pressable onPress={() => handlePress()}>
            {isSelected ? <SelectCheckBox /> : <UncheckBox />}
          </Pressable>
        </View>
      </View>
    );
  };

  const getSelectedTopsList = React.useMemo((): JSX.Element | null => {
    return (
      <FlatList
        horizontal
        showsHorizontalScrollIndicator={false}
        style={styles.topListContainer}
        ItemSeparatorComponent={Separator}
        data={selectedTopsToMove}
        keyExtractor={(_, index) => `${TEXT.COLLECTIONS}-${index}`}
        onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
        showsVerticalScrollIndicator={false}
        renderItem={({item}) => (
          <TopCard
            key={item.id}
            topModel={item}
            showQuantity
            onUpdateQuantityTap={val => quantityUpdate(item, val)}
            isInputQuantity
            onChangeQuantity={(val: number) =>
              onChangeUpdateQuantity(item, val)
            }
            checkQuantityOnOnChange={true}
          />
        )}
      />
    );
  }, [selectedTopsToMove, ColorTheme, selectedFromCollectionData]);
  const getCollectionList = React.useMemo((): JSX.Element | null => {
    const getCollectionData = collectionsResult.filter(
      item => item.name !== selectedFromCollectionData?.name
    );

    return (
      <FlatList
        style={styles.collectionList}
        ItemSeparatorComponent={Separator}
        data={getCollectionData}
        keyExtractor={(_, index) => `${TEXT.COLLECTIONS}-${index}`}
        onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
        showsVerticalScrollIndicator={false}
        renderItem={({item, index}) => renderCollectionList(item, index)}
      />
    );
  }, [collectionsResult, selectedTop, ColorTheme, selectedFromCollectionData]);
  function getTopView() {
    return (
      <View style={styles.topView}>
        <Text
          numberOfLines={FixedValue.CONSTANT_VALUE_1}
          style={styles.headingText}
        >
          {TEXT.MOVE_TO_COLLECTION}
        </Text>
        <View style={styles.newCollectionView}>
          <Text style={styles.newCollectionText}>{TEXT.NEW_COLLECTION}</Text>
          <CreateCollectionButton
            onPress={() => {
              setCollectionData(null);
              setOpenCreateCollectionModal();
            }}
          />
        </View>
      </View>
    );
  }
  function getButtonsView() {
    return (
      <View style={styles.buttonsView}>
        <View style={styles.buttonWidth}>
          <CancelButton label={TEXT.CANCEL} onPress={handelCancelOption} />
        </View>
        <View style={styles.buttonWidth}>
          <CancelButton
            label={'Done'}
            onPress={() => {
              setOpenMoveToCollectionModal();
              if (hasPendingRequests) {
                setOpenMoveTopConfirmationModal(true);
              } else {
                callAddToColectionAPI();
              }
            }}
            buttonTextColor={styles.doneTextColor}
            disable={selectedTop ? false : true}
          />
        </View>
      </View>
    );
  }

  return (
    <>
      <ModalContainer
        visible={openMoveToCollectionModal}
        onPressOutside={() => handelCancelOption()}
      >
        <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
          <View style={styles.subContainer}>
            {getTopView()}
            {getSelectedTopsList}
            {getCollectionList}
            {getButtonsView()}
          </View>
          <CreateCollectionFormModal />
        </View>
      </ModalContainer>
      {openMoveTopConfirmationModal && isFocused && (
        <ConfirmationModal
          title={confirmText}
          modalVisible={openMoveTopConfirmationModal}
          onPressYes={() => {
            setOpenMoveTopConfirmationModal(false);
            callAddToColectionAPI();
          }}
          onShowHide={() => {
            setOpenMoveTopConfirmationModal(false);
            setSelectedTop(null);
          }}
        />
      )}
    </>
  );
};

export default React.memo(MoveToCollections);
