import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  FONTS,
  GlobalStyleValues,
  Percentage,
} from '../../../constants';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
  SCREEN_WIDTH,
} from '../../../utils/responsive';
import {IColorTheme} from '../../../types/contextTypes/themeContext';

export const profileStyle = (theme?: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : SCREEN_WIDTH,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    headingView: {
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
    },
    titleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_30),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: theme?.color,
    },
    profilePicView: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      ...BASESTYLE.inRow,
    },
    userNameText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: theme?.color,
      maxWidth: scaleWidth(FixedValue.CONSTANT_VALUE_229),
    },
    userDetailView: {
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_12),
      justifyContent: GlobalStyleValues.CENTER,
    },
    profileBtnView: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_6),
      paddingBottom:scaleHeight(FixedValue.CONSTANT_VALUE_40)
    },
    profileSingleBtnView: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: Percentage.PRECENTAGE_100,
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
      borderWidth: FixedValue.CONSTANT_VALUE_1,
      borderColor: COLORS.C2D2ED,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_6),
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_12),
    },
    customStyle: {
      borderWidth: FixedValue.CONSTANT_VALUE_0,
      justifyContent: GlobalStyleValues.CENTER,
    },
    customStyle2: {
      borderWidth: FixedValue.CONSTANT_VALUE_1,
      justifyContent: GlobalStyleValues.CENTER,
    },
    profileBtnLabelText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme?.color,
    },
    badgeStyle: {
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_9),
    },
    customProfileBtnLabelText: {
      color: COLORS._FF0000,
      alignSelf: GlobalStyleValues.CENTER,
      alignContent: GlobalStyleValues.CENTER,
    },
    mainViewStyle: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_100),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_100),
      backgroundColor: theme?.color,
      ...BASESTYLE.inCenter,
      borderRadius: FixedValue.CONSTANT_VALUE_16,
      overflow: GlobalStyleValues.HIDDEN,
    },
    initialsTextStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_48),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme?.backgroundColor,
    },
    verifyEmailText: {
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: COLORS.PRIMARY_BLUE,
    },
    extraView: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_50),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_50),
      backgroundColor: COLORS.TRANSPARENT,
    },
    container: {
      backgroundColor: COLORS._DAE6FC,
      flexDirection: GlobalStyleValues.ROW,
      alignItems: GlobalStyleValues.CENTER,
      justifyContent: GlobalStyleValues.CENTER,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_26),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_16),
      paddingRight: scaleWidth(FixedValue.CONSTANT_VALUE_17),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_77),
    },
    content: {
      ...BASESTYLE.flex1,
      paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    myWalletText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      color: COLORS.BLACK,
      fontFamily: FONTS.SF_MEDIUM,
    },
    topCreditText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      color: COLORS.BLACK,
      fontFamily: FONTS.SF_BOLD,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_5),
    },
    topcreditsText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
    },
  });
