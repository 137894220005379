import React from 'react';
import SentOfferContent from '../../components/tradeComponent/sentOfferCard';
import {TradeContext} from '../../contextAPI/tradeContext';
import TradeModal from '../../modals/tradeModal';

const SentOfferList = (): JSX.Element => {
  const {sentOfferList, openSentOfferModal, setOpenSentOfferModal} =
    React.useContext(TradeContext);
  return (
    <>
      <SentOfferContent data={sentOfferList} />
      {openSentOfferModal && (
        <TradeModal
          isSent={false}
          modalVisible={openSentOfferModal}
          onClose={setOpenSentOfferModal}
          showActionButton={true}
        />
      )}
    </>
  );
};

export default React.memo(SentOfferList);
