import {createSlice} from '@reduxjs/toolkit';
import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {ISearchStoreType} from '../../types/reducerTypes/searchTabStoreType';
import {ResetAllState} from './logoutslice';
import {
  NOTES_ADD,
  NOTES_DELETE,
  NOTES_LIST,
  NOTES_UPDATE,
  USER_SEARCH_LIST,
} from '../../constants/actionTypes';

const {StoreConstants} = AppConst;
const {SEARCH_TOP_LIST, RECENT_SEARCH_LIST, TOP_DETAIL} = ActionType;

const initialState: ISearchStoreType = {
  searchTopList: {
    isLoading: false,
    success: null,
    failed: null,
    status: SEARCH_TOP_LIST.END,
  },
  recentTopData: {
    isLoading: false,
    success: null,
    failed: null,
    status: RECENT_SEARCH_LIST.END,
  },
  topDetailData: {
    isLoading: false,
    success: null,
    failed: null,
    status: TOP_DETAIL.END,
  },
  notesAddResponse: {
    isLoading: false,
    success: null,
    failed: null,
    status: NOTES_ADD.END,
  },
  notesUpdateResponse: {
    isLoading: false,
    success: null,
    failed: null,
    status: NOTES_UPDATE.END,
  },
  notesListResponse: {
    isLoading: false,
    success: null,
    failed: null,
    status: NOTES_LIST.END,
  },
  notesDeleteResponse: {
    isLoading: false,
    success: null,
    failed: null,
    status: NOTES_DELETE.END,
  },
  userUserSearchList: {
    isLoading: false,
    success: null,
    failed: null,
    status: USER_SEARCH_LIST.END,
  },
};

const searchTabSlice = createSlice({
  initialState,
  name: StoreConstants.SEARCH_TAB,
  reducers: {
    onSearchListStart: (state, _) => {
      state.searchTopList.isLoading = true;
      state.searchTopList.status = SEARCH_TOP_LIST.START;
    },
    onSearchListSuccess: (state, action) => {
      state.searchTopList.success = action.payload;
      state.searchTopList.status = SEARCH_TOP_LIST.SUCCESS;
    },
    onSearchListFailed: (state, action) => {
      state.searchTopList.failed = action.payload;
      state.searchTopList.status = SEARCH_TOP_LIST.FAILED;
    },
    onSearchListEnd: state => {
      state.searchTopList.isLoading = false;
      state.searchTopList.status = SEARCH_TOP_LIST.END;
    },
    onRecentSearchListStart: state => {
      state.recentTopData.isLoading = true;
      state.recentTopData.status = RECENT_SEARCH_LIST.START;
    },
    onRecentSearchListSuccess: (state, action) => {
      state.recentTopData.success = action.payload;
      state.recentTopData.status = RECENT_SEARCH_LIST.SUCCESS;
    },
    onRecentSearchListFailed: (state, action) => {
      state.recentTopData.failed = action.payload;
      state.recentTopData.status = RECENT_SEARCH_LIST.FAILED;
    },
    onRecentSearchListEnd: state => {
      state.recentTopData.isLoading = false;
      state.recentTopData.status = RECENT_SEARCH_LIST.END;
    },
    onTopDetailDataStart: (state, _) => {
      state.topDetailData.isLoading = true;
      state.topDetailData.status = TOP_DETAIL.START;
    },
    onTopDetailDataSuccess: (state, action) => {
      state.topDetailData.success = action.payload;
      state.topDetailData.status = TOP_DETAIL.SUCCESS;
    },
    onTopDetailDataFailed: (state, action) => {
      state.topDetailData.failed = action.payload;
      state.topDetailData.status = TOP_DETAIL.FAILED;
    },
    onTopDetailDataEnd: state => {
      state.topDetailData.isLoading = false;
      state.topDetailData.status = TOP_DETAIL.END;
    },
    onNotesAddStart: (state, _) => {
      state.notesAddResponse.isLoading = true;
      state.notesAddResponse.status = NOTES_ADD.START;
    },
    onNotesAddSuccess: (state, action) => {
      state.notesAddResponse.success = action.payload;
      state.notesAddResponse.status = NOTES_ADD.SUCCESS;
    },
    onNotesAddFailed: (state, action) => {
      state.notesAddResponse.failed = action.payload;
      state.notesAddResponse.status = NOTES_ADD.FAILED;
    },
    onNotesAddEnd: state => {
      state.notesAddResponse.isLoading = false;
      state.notesAddResponse.status = NOTES_ADD.END;
    },
    onNotesUpdateStart: (state, _) => {
      state.notesUpdateResponse.isLoading = true;
      state.notesUpdateResponse.status = NOTES_UPDATE.START;
    },
    onNotesUpdateSuccess: (state, action) => {
      state.notesUpdateResponse.success = action.payload;
      state.notesUpdateResponse.status = NOTES_UPDATE.SUCCESS;
    },
    onNotesUpdateFailed: (state, action) => {
      state.notesUpdateResponse.failed = action.payload;
      state.notesUpdateResponse.status = NOTES_UPDATE.FAILED;
    },
    onNotesUpdateEnd: state => {
      state.notesUpdateResponse.isLoading = false;
      state.notesUpdateResponse.status = NOTES_UPDATE.END;
    },
    onNotesListStart: (state, _) => {
      state.notesListResponse.isLoading = true;
      state.notesListResponse.status = NOTES_LIST.START;
    },
    onNotesListSuccess: (state, action) => {
      state.notesListResponse.success = action.payload;
      state.notesListResponse.status = NOTES_LIST.SUCCESS;
    },
    onNotesListFailed: (state, action) => {
      state.notesListResponse.failed = action.payload;
      state.notesListResponse.status = NOTES_LIST.FAILED;
    },
    onNotesListEnd: state => {
      state.notesListResponse.isLoading = false;
      state.notesListResponse.status = NOTES_LIST.END;
    },
    onNotesDeleteStart: (state, _) => {
      state.notesDeleteResponse.isLoading = true;
      state.notesDeleteResponse.status = NOTES_DELETE.START;
    },
    onNotesDeleteSuccess: (state, action) => {
      state.notesDeleteResponse.success = action.payload;
      state.notesDeleteResponse.status = NOTES_DELETE.SUCCESS;
    },
    onNotesDeleteFailed: (state, action) => {
      state.notesDeleteResponse.failed = action.payload;
      state.notesDeleteResponse.status = NOTES_DELETE.FAILED;
    },
    onNotesDeleteEnd: state => {
      state.notesDeleteResponse.isLoading = false;
      state.notesDeleteResponse.status = NOTES_DELETE.END;
    },
    onUserSearchListStart: (state, _) => {
      state.userUserSearchList.isLoading = true;
      state.userUserSearchList.status = USER_SEARCH_LIST.START;
    },
    onUserSearchListSuccess: (state, action) => {
      state.userUserSearchList.success = action.payload;
      state.userUserSearchList.status = USER_SEARCH_LIST.SUCCESS;
    },
    onUserSearchListFailed: (state, action) => {
      state.userUserSearchList.failed = action.payload;
      state.userUserSearchList.status = USER_SEARCH_LIST.FAILED;
    },
    onUserSearchListEnd: state => {
      state.userUserSearchList.isLoading = false;
      state.userUserSearchList.status = USER_SEARCH_LIST.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  onSearchListStart,
  onSearchListSuccess,
  onSearchListFailed,
  onSearchListEnd,
  onRecentSearchListFailed,
  onRecentSearchListStart,
  onRecentSearchListEnd,
  onRecentSearchListSuccess,
  onTopDetailDataEnd,
  onTopDetailDataFailed,
  onTopDetailDataStart,
  onTopDetailDataSuccess,
  onNotesAddStart,
  onNotesAddSuccess,
  onNotesAddFailed,
  onNotesAddEnd,
  onNotesUpdateStart,
  onNotesUpdateSuccess,
  onNotesUpdateFailed,
  onNotesUpdateEnd,
  onNotesListStart,
  onNotesListSuccess,
  onNotesListFailed,
  onNotesListEnd,
  onNotesDeleteStart,
  onNotesDeleteSuccess,
  onNotesDeleteFailed,
  onNotesDeleteEnd,
  onUserSearchListStart,
  onUserSearchListSuccess,
  onUserSearchListFailed,
  onUserSearchListEnd,
} = searchTabSlice.actions;
export default searchTabSlice.reducer;

export const searchTabStore = (state: RootState) => state.searchTopStore;
