import React from 'react';
import { FixedValue } from '../../constants';
import {onDeleteTopStart} from '../../redux/reducers/homeSlice';
import {useAppDispatch} from '../reduxHooks';

const useDeleteTopHooks = () => {
  const dispatch = useAppDispatch();
  const [deleteModalNo, setDeleteModalNo] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_0
  );

  const callDeleteTopAPI = React.useCallback((topId?: number): void => {
    dispatch(onDeleteTopStart({topId}));
  }, []);
  const updateDeleteModalNo = React.useCallback((selectedNo?: number): void => {
    setDeleteModalNo(selectedNo ?? 0)
  }, [deleteModalNo]);
  
  return {
    deleteModalNo,
    updateDeleteModalNo,
    callDeleteTopAPI,
  };
};

export default useDeleteTopHooks;
