import React from 'react';
import {View, FlatList} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';

import {ThemeContext} from '../../../contextAPI/themeContext';
import {TopApprovedListStyle} from './approvedList.style';
import {FixedValue, TEXT} from '../../../constants';
import {ChildProfileContext} from '../../../contextAPI/childProfileContext';
import TradeApproveTile from '../../../components/RenderItemTile/tradeApproveTile';
import {USER_ADD_INTO_TRADE, USER_REMOVE_FROM_TRADE} from '../../../constants/actionTypes';
import useTradingApprovalHooks from '../../../hooks/componentHooks/useTradingApprovalHooks';
import {useAppSelector} from '../../../hooks/reduxHooks';
import {childProfileStore} from '../../../redux/reducers/childProfileSlice';
import TopsListEmptyView from '../../../components/emptyListView/topsListEmptyView';
import ApproveListEmpty from '../../../components/renderSvgs/approveListEmpty';

const TopApproveListScreen = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = TopApprovedListStyle(ColorTheme);
  const {
    initialLoader,
    tradeApprovedUserListData,
    childProfileContextPullDownToRefresh,
  } = React.useContext(ChildProfileContext);
  const {
    callTradingApproveListAPI,
    onTradeApproveUserListEndReach,
    pullDownToRefreshOnApproveList,
  } = useTradingApprovalHooks();
  const {onTradeUserAdd, onTradeApprovedListData, onUserRemoveFromTrade} =
    useAppSelector(childProfileStore);
  const {tradeApproveUserRemoveAlertFunction} = useTradingApprovalHooks();

  React.useEffect(() => {
    switch (onTradeUserAdd.status) {
      case USER_ADD_INTO_TRADE.SUCCESS:
        callTradingApproveListAPI(FixedValue.CONSTANT_VALUE_0);
        break;
      case USER_ADD_INTO_TRADE.FAILED:
        break;
      default:
        break;
    }
  }, [onTradeUserAdd.status]);

  React.useEffect(() => {
    switch (onUserRemoveFromTrade.status) {
      case USER_REMOVE_FROM_TRADE.SUCCESS:
        callTradingApproveListAPI(FixedValue.CONSTANT_VALUE_0);
        break;
      default:
        break;
    }
  }, [onUserRemoveFromTrade.status]);

  const renderTradeApproveListFlatList =
    React.useMemo((): JSX.Element | null => {
      return (
        <FlatList
          keyExtractor={(_, index) => `${TEXT.TRADE_APPROVE_LIST}-${index}`}
          data={tradeApprovedUserListData}
          contentContainerStyle={styles.flatListContentContainerStyle}
          onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
          showsVerticalScrollIndicator={false}
          scrollEnabled
          ListEmptyComponent={
            onTradeApprovedListData.isLoading || initialLoader ? (
              <></>
            ) : (
              <TopsListEmptyView
                emptyViewTitle={TEXT.THERE_ARE_NO_REQUEST}
                emptyViewDesc={''}
                placeHolder={<ApproveListEmpty />}
              />
            )
          }
          onEndReached={() => onTradeApproveUserListEndReach()}
          refreshControl={
            <RefreshControl
              refreshing={childProfileContextPullDownToRefresh}
              onRefresh={pullDownToRefreshOnApproveList}
            />
          }
          renderItem={({item}) => (
            <TradeApproveTile
              key={item.id}
              item={item}
              onPressUserRemoveFunction={tradeApproveUserRemoveAlertFunction}
            />
          )}
        />
      );
    }, [tradeApprovedUserListData, onTradeApprovedListData, initialLoader]);

  return (
    <View style={styles.marginTop20}>{renderTradeApproveListFlatList}</View>
  );
};

export default React.memo(TopApproveListScreen);
