import React from 'react';
import {View} from 'react-native';
import ModalContainer from '../../components/layout/modalContainer';
import {ThemeContext} from '../../contextAPI/themeContext';
import {applyCouponCodeModalStyle} from './reedemCodeModal.styles';
import {FixedValue, TEXT} from '../../constants';
import ModalHeaderWithCross from '../../components/header/modalHeaderWithCross';
import {useAppSelector} from '../../hooks/reduxHooks';
import {groupManagementStore} from '../../redux/reducers/groupManagementSlice';
import PrimaryButton from '../../components/button/primaryButton';
import LabelInput from '../../components/input/labelInput';
import {ApplyCouponCodeProps} from '../../types/reducerTypes/searchTabStoreType';
import useReedemCodeHooks from '../../hooks/componentHooks/useReedemHooks';

const ReedemCodeModal = (props: ApplyCouponCodeProps) => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = applyCouponCodeModalStyle(ColorTheme);
  const {visble, onClose} = props;
  const {createNewGroup, updateGroup} = useAppSelector(groupManagementStore);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    CouponCodeError,
    isButtonDisabled,
  } = useReedemCodeHooks();

  const onCloseModal = () => {
    onClose(false);
  };

  return (
    <ModalContainer visible={visble} onPressOutside={() => onCloseModal()}>
      <View style={styles.subContainer}>
        <ModalHeaderWithCross
          onPress={() => onCloseModal()}
          headerTitle={TEXT.APPLY_REEDEM_CODE}
        />
        <>
          <View style={styles.labelInputStyle} />
          <LabelInput
            inputLabel={''}
            placeholder={TEXT.COUPON_PLACEHOLDER}
            value={values.reedemCode}
            onChangeText={handleChange(TEXT.REEDEM_CODE_)}
            onBlur={handleBlur(TEXT.REEDEM_CODE_)}
            haveError={CouponCodeError}
            maxLength={FixedValue.CONSTANT_VALUE_6}
          />
          <PrimaryButton
            isLoading={createNewGroup?.isLoading || updateGroup?.isLoading}
            buttonLabel={TEXT.APPLY_CODE}
            onPress={() => {
              onClose(false), handleSubmit();
            }}
            isDisabled={isButtonDisabled}
          />
        </>
      </View>
    </ModalContainer>
  );
};

export default ReedemCodeModal;
