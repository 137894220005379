import React from 'react';
import {View, Text, FlatList} from 'react-native';

import {EditorContext} from '../../../contextAPI/editorContext';
import {EditorShapeContext} from '../../../contextAPI/editorShapeContext';
import {TopEditorItemType} from '../../../types/componentTypes/editorType';
import {styles} from './editorFooter.style';
import RotationController from '../../smallDesigns/rotationController';
import SizeController from '../../smallDesigns/sizeController';
import ColorPelette from '../../smallDesigns/colorPelette';
import StrokeWidthController from '../../smallDesigns/strokeWidthController';
import useEditorShape from '../../../hooks/componentHooks/useEditorShapeHooks';
import { FixedValue, TEXT } from '../../../constants';

const ShapeFooter = (): JSX.Element => {
  const {selectedItemIndex} = React.useContext(EditorContext);
  const {controlsItems} = React.useContext(EditorShapeContext);
  const {
    showPaletteController,
    showScaleController,
    onChangeSizeValue,
    showRotationController,
    onChangeRotationValue,
    onChangeFillColor,
    onChangeStrokeColor,
    sizeResult,
    rotationResult,
    showStrokeWidthController,
  } = useEditorShape();

  return (
    <>
      {showScaleController && (
        <SizeController
          key={selectedItemIndex}
          onChangeValue={onChangeSizeValue}
          result={sizeResult}
          minValue={FixedValue.CONSTANT_VALUE_001}
          maxValue={FixedValue.CONSTANT_VALUE_1}
        />
      )}
      {showRotationController && (
        <RotationController
          key={selectedItemIndex}
          onChangeValue={onChangeRotationValue}
          result={rotationResult}
        />
      )}
      {showPaletteController && (
        <ColorPelette
          key={selectedItemIndex}
          onChangeFillColor={onChangeFillColor}
          onChangeStrokeColor={onChangeStrokeColor}
          upperRowText={TEXT.FILL_COLOR}
          lowerRowText={TEXT.OUTLINE_COLOR}
        />
      )}
      {showStrokeWidthController && (
        <StrokeWidthController key={selectedItemIndex} />
      )}
      <View style={styles.shapefooter}>
        <Text style={styles.titleText}>{TopEditorItemType.SHAPE}</Text>
        <FlatList
          data={controlsItems}
          keyExtractor={(_, index) => `controls-item-${index}-svg`}
          horizontal
          ItemSeparatorComponent={() => <View style={styles.itemSeperator} />}
          contentContainerStyle={styles.shapeListContentstyle}
          renderItem={({item: controlsItem, index}) => (
            <View
              testID={`controls-item-${index}-svg`}
              accessibilityLabel={`controls-item-${index}-svg`}
              key={`controls-item-${index}-svg`}
            >
              {controlsItem}
            </View>
          )}
        />
      </View>
    </>
  );
};

export default React.memo(ShapeFooter);
