import React from 'react';

import CustomButton from '../button/customButton';
import TICKICON from '../renderSvgs/tickIcon';
import {APPROVED_OR_REJECT, NOTIFICATION_SUB_TYPE} from '../../constants/enum';
import {TEXT} from '../../constants';
import CROSSICON from '../renderSvgs/smallCrossIcon';
import {INotificationListItem} from '../../types/reducerTypes/notificationTypes';
import {NotificationCardStyle} from './notificationCards.style';
import {ThemeContext} from '../../contextAPI/themeContext';

interface IStatusButton {
  item: INotificationListItem;
  onPressAccept: () => void;
  onPressReject: () => void;
}

const StatusButton = ({
  item,
  onPressAccept,
  onPressReject,
}: IStatusButton): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = NotificationCardStyle(ColorTheme);

  return (
    <>
      {item.subType === NOTIFICATION_SUB_TYPE.NOTIFICATION_INVITATION &&
        item.status === undefined && (
          <>
            <CustomButton
              buttonLabel={TEXT.ACCEPT_TEXT}
              buttonwidth={styles.primaryButtonStyle}
              labelStyle={styles.primaryButtonLabelstyle}
              buttonBackGroundColor={styles.noButtonBackGroundColor}
              element={<TICKICON />}
              onPress={onPressAccept}
            />
            <CustomButton
              buttonLabel={TEXT.REJECT}
              buttonwidth={styles.primaryButtonStyle1}
              labelStyle={styles.primaryButtonLabelstyle1}
              buttonBackGroundColor={styles.noButtonBackGroundColor1}
              element={<CROSSICON />}
              onPress={onPressReject}
            />
          </>
        )}
      {item.subType === NOTIFICATION_SUB_TYPE.NOTIFICATION_INVITATION &&
        item.status && (
          <>
            {(item.status === 'Accepted' ||
              item.status === 'ACCEPTED' ||
              item.status === APPROVED_OR_REJECT.APPROVED) && (
              <CustomButton
                buttonLabel={TEXT.ACCEPTED}
                buttonwidth={styles.primaryButtonStyle}
                labelStyle={styles.primaryButtonLabelstyle}
                buttonBackGroundColor={styles.noButtonBackGroundColor}
                element={<TICKICON />}
              />
            )}
            {(item.status === 'Rejected' ||
              item.status === 'REJECTED' ||
              item.status === APPROVED_OR_REJECT.REJECT) && (
              <CustomButton
                buttonLabel={TEXT.REJECTED}
                buttonwidth={styles.primaryButtonStyle1}
                labelStyle={styles.primaryButtonLabelstyle1}
                buttonBackGroundColor={styles.noButtonBackGroundColor1}
                element={<CROSSICON />}
              />
            )}
            {item.status === 'INVALID' && (
              <CustomButton
                buttonLabel={TEXT.INVALID}
                buttonwidth={styles.primaryButtonStyle1}
                labelStyle={styles.invalidButtonLabelstyle}
                buttonBackGroundColor={styles.noButtonBackGroundColor1}
              />
            )}
          </>
        )}
      {item.subType === NOTIFICATION_SUB_TYPE.NOTIFICATION_ACCEPTED && (
        <CustomButton
          buttonLabel={TEXT.ACCEPTED}
          buttonwidth={styles.primaryButtonStyle}
          labelStyle={styles.primaryButtonLabelstyle}
          buttonBackGroundColor={styles.noButtonBackGroundColor}
          element={<TICKICON />}
        />
      )}
      {item.subType === NOTIFICATION_SUB_TYPE.NOTIFICATION_REJECTED && (
        <CustomButton
          buttonLabel={TEXT.REJECTED}
          buttonwidth={styles.primaryButtonStyle1}
          labelStyle={styles.primaryButtonLabelstyle1}
          buttonBackGroundColor={styles.noButtonBackGroundColor1}
          element={<CROSSICON />}
        />
      )}
    </>
  );
};

export default React.memo(StatusButton);
