import React, {useContext} from 'react';
import {FlatList, Keyboard} from 'react-native';
import UserListComponent from '../../components/userListComponent';
import {
  BASESTYLE,
  FixedValue,
  GlobalStyleValues,
  ROUTE_NAME,
  TEXT,
} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {TradeContext} from '../../contextAPI/tradeContext';
import {push} from '../../services/navigationService';
import {CustomUserListSearchModalListData} from '../../types/reducerTypes/searchTabStoreType';
import {style} from './style';
import {useIsFocused} from '@react-navigation/native';
import useFavouriteHooks from '../../hooks/componentHooks/useFavouriteHooks';
import {FavoriteContext} from '../../contextAPI/favoriteContext';

let userClicked = false;

const TradeFavorites = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = style(ColorTheme);
  const {
    setOtherTradeUser,
    setCollectionSelectedTopsOther,
    setIsOtherUserTopList,
  } = React.useContext(TradeContext);
  const isFocused = useIsFocused();
  const {favoriteListData} = useContext(FavoriteContext);
  const {onFavoriteUserListReachedEnd} = useFavouriteHooks();

  React.useEffect(() => {
    if (isFocused) {
      userClicked = false;
    }
  }, [isFocused]);

  const onPress = React.useCallback(
    (item: CustomUserListSearchModalListData): void => {
      if (!userClicked) {
        userClicked = true;
        setCollectionSelectedTopsOther([]);
        setOtherTradeUser(item);
        setIsOtherUserTopList(true);
        push(ROUTE_NAME.CREATE_OFFER_OTHER, {userId: item.id});
      }
    },
    [userClicked]
  );

  return (
    <FlatList
      keyExtractor={(_, index) => `${TEXT.USER_LIST}-${index}`}
      data={favoriteListData}
      onEndReached={onFavoriteUserListReachedEnd}
      style={BASESTYLE.flex1}
      contentContainerStyle={styles.flatListContent}
      keyboardShouldPersistTaps={GlobalStyleValues.HANDLED}
      onEndReachedThreshold={FixedValue.CONSTANT_VALUE_03}
      showsVerticalScrollIndicator={false}
      scrollEnabled
      renderItem={({item}) => (
        <UserListComponent
          isArrow
          onPressArrow={() => onPress(item)}
          item={item}
          onSelectOrUnSelectUser={() => {}}
        />
      )}
      onScrollBeginDrag={() => Keyboard.dismiss()}
    />
  );
};

export default React.memo(TradeFavorites);
