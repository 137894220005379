import {useIsFocused} from '@react-navigation/native';
import React, {useContext} from 'react';
import {BackHandler, FlatList, Pressable, Text, View} from 'react-native';
import CustomButton from '../../components/button/customButton';
import PrimaryButton from '../../components/button/primaryButton';
import TitleWithButton from '../../components/header/titleWithButton';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import TopCardTile from '../../components/mantelTopTabComp/topCardTile';
import DistributeTopButton from '../../components/renderSvgs/distributeTopButton';
import ProfilePics from '../../components/smallDesigns/profilePics';
import {FixedValue, GlobalStyleValues, ROUTE_NAME, TEXT} from '../../constants';
import {COUNTER_OFFER, CREATE_OFFER} from '../../constants/actionTypes';
import {timeformat} from '../../constants/enum';
import {HomeContext} from '../../contextAPI/homeContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import {TradeContext} from '../../contextAPI/tradeContext';
import useCreateTradeHooks from '../../hooks/componentHooks/useCreateTradeHooks';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import {
  activateInSentOfferTab,
  HomeStore,
} from '../../redux/reducers/homeSlice';
import {goBack, navigate, push} from '../../services/navigationService';
import {TopModel} from '../../types/reducerTypes/searchTabStoreType';
import {
  formatUTCTimeToLocalTime,
  getInitials,
  _ToastHandler,
} from '../../utils/helperFunctions';
import {style} from './style';
import {onClearFavoriteUserListEnd} from '../../redux/reducers/userProfileSlice';
import {FavoriteContext} from '../../contextAPI/favoriteContext';

let editClicked = false;

const SentTradeOfferRequest = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = style(ColorTheme);
  const {
    collectionSelectedTops,
    otherTradeUser,
    collectionSelectedTopsOther,
    setCollectionSelectedTops,
    setCollectionSelectedTopsOther,
    setIsTopEdit,
    isCounterOffer,
    setCounterOffer,
    selectedItem,
    setFromProposeTrade,
    fromProposeTrade,
    setIsOtherUserTopList,
  } = React.useContext(TradeContext);
  const {callCreateTradeOfferAPI, callCounterOfferAPI} = useCreateTradeHooks();
  const {createOffer, counterOffer} = useAppSelector(HomeStore);
  const {setSearchUserListText, setSearchFavouriteUserListText} =
    React.useContext(HomeContext);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [isLoadingOffer, setLoadingOffer] = React.useState<boolean>(false);
  const isFocused = useIsFocused();
  const dispatch = useAppDispatch();
  const {setFavoriteListData} = useContext(FavoriteContext);
  const filteredCollectionSelectedTops = collectionSelectedTops?.filter(
    item =>
      (item?.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0) >
      FixedValue.CONSTANT_VALUE_0
  );
  const filteredCollectionSelectedTopsOther =
    collectionSelectedTopsOther?.filter(
      item =>
        (item?.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0) >
        FixedValue.CONSTANT_VALUE_0
    );

  React.useEffect(() => {
    switch (createOffer.status) {
      case CREATE_OFFER.SUCCESS:
        dispatch(activateInSentOfferTab(true));
        resetTradeData();
        navigate(ROUTE_NAME.REQUEST_SENT, {isCounter: undefined});
        break;
      default:
        break;
    }
  }, [createOffer.status]);

  React.useEffect(() => {
    switch (counterOffer.status) {
      case COUNTER_OFFER.SUCCESS:
        resetTradeData();
        navigate(ROUTE_NAME.REQUEST_SENT, {isCounter: true});
        break;
      default:
        break;
    }
  }, [counterOffer.status]);

  React.useEffect(() => {
    setLoading(counterOffer.isLoading);
  }, [counterOffer.isLoading]);

  React.useEffect(() => {
    setLoadingOffer(createOffer.isLoading);
  }, [createOffer.isLoading]);

  React.useEffect(() => {
    if (isFocused) {
      setTimeout(() => {
        setIsTopEdit(false);
      }, FixedValue.CONSTANT_VALUE_500);
      // android back button handler.
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        onBackPress
      );
      editClicked = false;
      return () => backHandler.remove();
    }
  }, [isFocused]);

  const resetTradeData = () => {
    setCollectionSelectedTops([]);
    setSearchUserListText('');
    setSearchFavouriteUserListText('');
    setFavoriteListData([]);
    dispatch(onClearFavoriteUserListEnd());
    setCollectionSelectedTopsOther([]);
    setCounterOffer(false);
    setIsTopEdit(false);
    setFromProposeTrade(false);
    setIsOtherUserTopList(false);
  };

  const cancelOffer = () => {
    resetTradeData();
    navigate(ROUTE_NAME.TRADE_TAB);
  };

  const editUserTops = () => {
    if (!editClicked) {
      setIsOtherUserTopList(true);
      editClicked = true;
      setIsTopEdit(true);
      push(ROUTE_NAME.CREATE_OFFER_OTHER, {userId: otherTradeUser?.id});
    }
  };

  const editMyTops = () => {
    if (!editClicked) {
      setIsOtherUserTopList(false);
      editClicked = true;
      setIsTopEdit(true);
      push(ROUTE_NAME.CREATE_TRADE, {isEdit: true});
    }
  };

  const onBackPress = () => {
    if (isCounterOffer) {
      // resetTradeData();
    }
    goBack();
    return true;
  };

  const disabledButton = () => {
    const myTops = selectedItem?.toUserTops?.map((top: TopModel) => ({
      topId: top.id,
      quantity: top.quantity,
    }));
    const userTops = selectedItem?.fromUserTops?.map((userTop: TopModel) => ({
      topId: userTop.id,
      quantity: userTop.quantity,
    }));
    const mySelecctedTops = filteredCollectionSelectedTops?.map((top: TopModel) => ({
      topId: top.id,
      quantity: top.selectedQuantity,
    }));
    const userSelectedTops = filteredCollectionSelectedTopsOther?.map(
      (userTop: TopModel) => ({
        topId: userTop.id,
        quantity: userTop.selectedQuantity,
      })
    );
    const myTopsDifference = myTops?.filter(
      obj1 =>
        !mySelecctedTops.some(
          obj2 => JSON.stringify(obj1) === JSON.stringify(obj2)
        )
    );
    const myTopsDifferenceReverse = mySelecctedTops?.filter(
      obj1 =>
        !myTops?.some(obj2 => JSON.stringify(obj1) === JSON.stringify(obj2))
    );
    const userTopsDifference = userTops?.filter(
      obj1 =>
        !userSelectedTops.some(
          obj2 => JSON.stringify(obj1) === JSON.stringify(obj2)
        )
    );
    const userTopsDifferenceReverse = userSelectedTops?.filter(
      obj1 =>
        !userTops?.some(obj2 => JSON.stringify(obj1) === JSON.stringify(obj2))
    );
    // check length of selected tops.
    if (!filteredCollectionSelectedTops.length || !filteredCollectionSelectedTopsOther.length) {
      return true;
    }
    // check difference selected tops.
    if (
      myTopsDifference?.length ||
      userTopsDifference?.length ||
      myTopsDifferenceReverse?.length ||
      userTopsDifferenceReverse?.length
    ) {
      return false;
    }
    return true;
  };

  const removeUserTop = (item: TopModel) => {
    const index = filteredCollectionSelectedTopsOther.findIndex(
      top => top.id === item.id
    );
    const updatedCollectionTops = [...filteredCollectionSelectedTopsOther];
    updatedCollectionTops.splice(index, FixedValue.CONSTANT_VALUE_1);
    setCollectionSelectedTopsOther(updatedCollectionTops);
  };

  const removeMyTop = (item: TopModel) => {
    const index = filteredCollectionSelectedTops.findIndex(top => top.id === item.id);
    const updatedCollectionTops = [...filteredCollectionSelectedTops];
    updatedCollectionTops.splice(index, FixedValue.CONSTANT_VALUE_1);
    setCollectionSelectedTops(updatedCollectionTops);
  };

  const disabledSendOffer = () => {
    if (!filteredCollectionSelectedTops.length || !filteredCollectionSelectedTopsOther.length) {
      return true;
    }
    return false;
  };

  const getToUserTops = () => {
    return (
      <>
        <View style={styles.seeAllView}>
          <Text
            style={styles.topOwner}
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
          >
            {`Tops you receive from ${otherTradeUser?.firstName}`}
          </Text>
          {!fromProposeTrade && (
            <Pressable onPress={editUserTops}>
              <Text
                style={[styles.seeAllText, isCounterOffer && styles.greenColor]}
              >
                {isCounterOffer
                  ? `+ Add ${otherTradeUser?.firstName}'s Tops`
                  : TEXT.EDIT_TOPS}
              </Text>
            </Pressable>
          )}
        </View>
        <FlatList
          style={styles.flatStyle}
          data={filteredCollectionSelectedTopsOther}
          showsHorizontalScrollIndicator={false}
          keyboardShouldPersistTaps={GlobalStyleValues.HANDLED}
          keyExtractor={(_, index) => `${TEXT.COLLECTIONS + 'Top'}-${index}`}
          horizontal
          renderItem={({item}) => (
            <TopCardTile
              key={item.id}
              {...item}
              isCross
              onCrossPress={() => removeUserTop(item)}
            />
          )}
        />
      </>
    );
  };

  const getFromUserTops = () => {
    return (
      <>
        <View style={styles.seeAllView}>
          <Text
            style={styles.topOwner}
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
          >
            {`Tops you give to ${otherTradeUser?.firstName}`}
          </Text>
          <Pressable onPress={editMyTops}>
            <Text
              style={[styles.seeAllText, isCounterOffer && styles.greenColor]}
            >
              {isCounterOffer ? '+ Add My Tops' : TEXT.EDIT_TOPS}
            </Text>
          </Pressable>
        </View>
        <FlatList
          style={styles.flatStyle}
          showsHorizontalScrollIndicator={false}
          data={filteredCollectionSelectedTops}
          keyboardShouldPersistTaps={GlobalStyleValues.HANDLED}
          keyExtractor={(_, index) => `${TEXT.COLLECTIONS + 'Nested'}-${index}`}
          horizontal
          renderItem={({item}) => (
            <TopCardTile
              key={item.id}
              {...item}
              isCross
              onCrossPress={() => removeMyTop(item)}
            />
          )}
        />
      </>
    );
  };

  return (
    <SafeAreaContainer>
      <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
        <View style={styles.header}>
          <TitleWithButton
            title={isCounterOffer ? TEXT.COUNTER_OFFER : TEXT.SEND_OFFER_MODAL}
            onPress={onBackPress}
          />
        </View>
        <View style={styles.innerView}>
          <View style={styles.upperContainer}>
            <ProfilePics
              image={otherTradeUser?.profileImage ?? ''}
              text={getInitials(
                otherTradeUser?.firstName + ' ' + otherTradeUser?.lastName
              )}
              initialsTextStyle={styles.initialsTextStyle}
              mainViewStyle={styles.mainViewStyle}
            />
            <View style={styles.nameView}>
              <Text
                style={styles.owner}
                numberOfLines={FixedValue.CONSTANT_VALUE_1}
              >
                {otherTradeUser?.firstName} {otherTradeUser?.lastName}
              </Text>
              {isCounterOffer && (
                <Text
                  style={styles.dayText}
                  numberOfLines={FixedValue.CONSTANT_VALUE_1}
                >
                  {formatUTCTimeToLocalTime(
                    selectedItem?.creationDate ?? '',
                    timeformat.MMMDDYY
                  )}
                </Text>
              )}
            </View>
          </View>
          <View>{getToUserTops()}</View>
          <View>{getFromUserTops()}</View>
        </View>
        <View style={styles.buttonsView}>
          {isCounterOffer ? (
            <PrimaryButton
              buttonLabel={TEXT.SUBMIT_COUNTER_OFFER}
              onPress={callCounterOfferAPI}
              buttonwidth={styles.primaryButtonStyle}
              isLoading={isLoading}
              isDisabled={disabledButton()}
            />
          ) : (
            <>
              <CustomButton
                labelStyle={styles.primaryButtonLabelstyle}
                buttonBackGroundColor={styles.noButtonBackGroundColor1}
                buttonLabel={TEXT.SEND_OFFER}
                buttonwidth={styles.primaryButtonStyle}
                element={<DistributeTopButton />}
                onPress={callCreateTradeOfferAPI}
                isDisabled={disabledSendOffer()}
                isLoading={isLoadingOffer}
              />
              <CustomButton
                labelStyle={styles.primaryButtonLabelstyle1}
                buttonLabel={TEXT.CANCEL_OFFER_WITH_CROSS}
                buttonwidth={styles.primaryButtonStyle2}
                buttonBackGroundColor={styles.noButtonBackGroundColor}
                onPress={cancelOffer}
              />
            </>
          )}
        </View>
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(SentTradeOfferRequest);
