import React, {createContext, useCallback} from 'react';
import {FixedValue, ROUTE_NAME, TEXT} from '../constants';
import {
  APPROVE_RECEIVED_OFFER,
  CANCEL_SENT_OFFER,
  COLLECTION_TOP_LISTS,
  GET_TOPS_BY_COLLECTION,
  REJECT_RECEIVED_OFFER,
  TRADE_RECEVIED_OFFER,
  TRADE_SENT_OFFER,
} from '../constants/actionTypes';
import {useAppSelector} from '../hooks/reduxHooks';
import {HomeStore} from '../redux/reducers/homeSlice';
import {
  ITradeContext,
  ITradeProvider,
} from '../types/contextTypes/tradeContext';
import {
  CollectionModel,
  CustomUserListSearchModalListData,
  TopModel,
} from '../types/reducerTypes/searchTabStoreType';
import {_ToastHandler} from '../utils/helperFunctions';
import {TradeTabStore} from '../redux/reducers/tradeTabSlice';
import {OfferModel} from '../types/reducerTypes/tradeTabTypes';

const TradeContext = createContext<ITradeContext>({
  collectionsList: [], // Updated initial state
  collectionsTops: [],
  selectedTopsToMove: [],
  receivedOfferList: [],
  sentOfferList: [],
  isRefreshing: false,
  openReceviedOfferModal: false,
  approveModal: false,
  isApprove: false,
  cancelModal: false,
  selectedItem: null,
  setSelectedTopsToMove: _val => {},
  setIsApprove: _val => {},
  setIsRefreshing: _val => {},
  setCollectionsList: _val => {},
  clearCollectionsList: () => {},
  setCollectionsTops: _val => {},
  clearTopsinCollection: () => {},
  selectOrUnSelectSingleTop: _val => {},
  clearSelectedTopsToMove: () => {},
  updateTopQuantity: _val => {},
  setOpenReceviedOfferModal: () => {},
  setApproveModal: () => {},
  setCancelModal: () => {},
  setSelectedItem: _val => {},
  setReceivedOfferList: _val => {},
  setSenetOfferList: _val => {},
  currentActiveTab: ROUTE_NAME.RECEIVED_OFFER,
  setCurrentActiveTab: _val => {},
  collectionSelectedTops: [],
  setCollectionSelectedTops: _val => {},
  totalTops: FixedValue.CONSTANT_VALUE_0,
  setTotalTop: _val => {},
  otherTradeUser: null,
  setOtherTradeUser: _val => {},
  openCancelModal: false,
  setOpenCancelModal: _val => {},
  isOtherUserTopList: false,
  setIsOtherUserTopList: _val => {},
  collectionSelectedTopsOther: [],
  setCollectionSelectedTopsOther: _val => {},
  isTopEdit: false,
  setIsTopEdit: _val => {},
  openSentOfferModal: false,
  setOpenSentOfferModal: () => {},
  isCounterOffer: false,
  setCounterOffer: _val => {},
  fromProposeTrade: false,
  setFromProposeTrade: _val => {},
  showUntradableAlert: false,
  setShowUntradableAlert: _val => {},
  initialLoader: false,
  setInitialLoader: _val => {},
  collectionModel: null,
  setCollectionModel: _val => {},
  openReportModal: false,
  setOpenReportModal: () => {},
  _setCollectionsList: _val => {},
  updateCollectionTopQuantity: _val => {},
  updateCollectionTopQuantityMoreThanOne: _val => {},
  updateCollectionTopQuantityOnEdit: _val => {},
});

const TradeProvider = (props: ITradeProvider): JSX.Element => {
  const {collectionBasedTopList, collectionTopLists} =
    useAppSelector(HomeStore);
  const {receivedOffers, sentOffers, approveOffer, rejectOffer, cancelOffer} =
    useAppSelector(TradeTabStore);
  const [collectionsList, setCollectionsList] = React.useState<
    CollectionModel[]
  >([]); // Initialize state with collectionResult from HomeContext
  const [collectionsTops, setCollectionsTops] = React.useState<TopModel[]>([]);
  const [isRefreshing, setIsRefreshing] = React.useState<boolean>(false);
  const [openSentOfferModal, setOpenSentOfferModal] =
    React.useState<boolean>(false);
  const [selectedTopsToMove, setSelectedTopsToMove] = React.useState<
    TopModel[]
  >([]);
  const [openReceviedOfferModal, setOpenReceviedOfferModal] =
    React.useState<boolean>(false);
  const [approveModal, setApproveModal] = React.useState<boolean>(false);
  const [cancelModal, setCancelModal] = React.useState<boolean>(false);

  const [selectedItem, setSelectedItem] = React.useState<OfferModel | null>(
    null
  );
  const [receivedOfferList, setReceivedOfferList] = React.useState<
    OfferModel[]
  >([]);
  const [sentOfferList, setSentOfferList] = React.useState<OfferModel[]>([]);
  const [currentActiveTab, setCurrentActiveTab] = React.useState<string>(
    ROUTE_NAME.RECEIVED_OFFER
  );
  const [collectionSelectedTops, setCollectionSelectedTops] = React.useState<
    TopModel[]
  >([]);
  const [totalTops, setTotalTop] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_0
  );
  const [otherTradeUser, setOtherTradeUser] =
    React.useState<CustomUserListSearchModalListData | null>(null);
  const [isApprove, setIsApprove] = React.useState<boolean>(false);

  const [openCancelModal, setopenCancelModal] = React.useState<boolean>(false);
  const [initialLoader, setInitialLoader] = React.useState<boolean>(false);
  const [collectionModel, setCollectionModel] =
    React.useState<CollectionModel | null>(null);
  const [openReportModal, setOpenReportModal] = React.useState<boolean>(false);
  const _setopenCancelModal = React.useCallback(
    (val: boolean): void => {
      setopenCancelModal(val);
    },
    [openCancelModal]
  );

  const [isOtherUserTopList, setIsOtherUserTopList] =
    React.useState<boolean>(false);

  const [collectionSelectedTopsOther, setCollectionSelectedTopsOther] =
    React.useState<TopModel[]>([]);
  const [isTopEdit, setIsTopEdit] = React.useState<boolean>(false);
  const [isCounterOffer, setCounterOffer] = React.useState<boolean>(false);
  const [fromProposeTrade, setFromProposeTrade] =
    React.useState<boolean>(false);
  const [showUntradableAlert, setShowUntradableAlert] =
    React.useState<boolean>(false);

  const _setCurrentActiveTab = React.useCallback(
    (value: string): void => {
      setCurrentActiveTab(value);
    },
    [currentActiveTab]
  );
  // modal open and close function for sent
  const _setOpenSentOfferModal = React.useCallback((): void => {
    setOpenSentOfferModal(prev => !prev);
  }, [openSentOfferModal]);

  // modal open and close function for recevied
  const _setOpenReceviedOfferModal = React.useCallback((): void => {
    setOpenReceviedOfferModal(prev => !prev);
  }, [openReceviedOfferModal]);

  const _setApproveModal = React.useCallback((): void => {
    setApproveModal(prev => !prev);
  }, [approveModal]);

  const _setCancelModal = React.useCallback((): void => {
    setCancelModal(prev => !prev);
  }, [cancelModal]);

  const _setSelectedItem = React.useCallback(
    (NewData: OfferModel | null): void => {
      setSelectedItem(NewData);
    },
    [selectedItem]
  );
  const _setIsApprove = React.useCallback(
    (val: boolean): void => {
      setIsApprove(val);
    },
    [isApprove]
  );

  const _setCollectionsList = React.useCallback(
    (NewData: CollectionModel[], reset?: boolean): void => {
      if (reset) {
        setCollectionsList(NewData ?? []);
      } else {
        setCollectionsList(prev => [...prev, ...NewData]);
      }
    },
    [collectionsList]
  );
  // Set Sent Offer List Response
  const _setSentOfferList = React.useCallback(
    (NewData: OfferModel[]): void => {
      setSentOfferList(prev => [...prev, ...NewData]);
    },
    [sentOfferList]
  );
  // Set Received Offer List
  const _setReceivedOfferList = React.useCallback(
    (NewData: OfferModel[]): void => {
      setReceivedOfferList(prev => [...prev, ...NewData]);
    },
    [receivedOfferList]
  );
  // Set Collection Specific tops
  const _setCollectionsTops = React.useCallback(
    (NewData: TopModel[]): void => {
      setCollectionsTops(prev => [...prev, ...NewData]);
    },
    [collectionsTops]
  );
  const _setSelectedTopsToMove = React.useCallback(
    (data: TopModel[]): void => {
      setSelectedTopsToMove(data);
    },
    [selectedTopsToMove]
  );
  const _clearCollectionsList = React.useCallback((): void => {
    setCollectionsList([]);
  }, []);
  const _clearTopsInCollection = React.useCallback((): void => {
    setCollectionsTops([]);
  }, []);
  const _clearSelectedTopsToMove = React.useCallback((): void => {
    setSelectedTopsToMove([]);
  }, []);
  const _setIsRefreshing = React.useCallback((val: boolean): void => {
    setIsRefreshing(val);
  }, []);

  const _selectOrUnSelectSingleTop = React.useCallback(
    (data: TopModel | null): void => {
      setCollectionsTops(prev => {
        return prev.map(item => {
          if (item.id === data?.id) {
            return {
              ...item,
              isSelected: !data.isSelected,
            };
          }
          return item;
        });
      });
      if (data) {
        const topData: TopModel = {...data};
        let newSelectedTops: TopModel[] = [];
        if (!topData?.isSelected) {
          topData.isSelected = !topData.isSelected;
          if (isOtherUserTopList) {
            newSelectedTops = [...collectionSelectedTopsOther, topData];
            setCollectionSelectedTopsOther([
              ...collectionSelectedTopsOther,
              topData,
            ]);
          } else {
            newSelectedTops = [...collectionSelectedTops, topData];
            setCollectionSelectedTops([...collectionSelectedTops, topData]);
          }
        } else {
          const selectedTops = isOtherUserTopList
            ? collectionSelectedTopsOther
            : collectionSelectedTops;
          const index = selectedTops.findIndex(top => top.id === topData.id);
          const updatedCollectionTops = [...selectedTops];
          updatedCollectionTops.splice(index, FixedValue.CONSTANT_VALUE_1);
          newSelectedTops = updatedCollectionTops;
          if (isOtherUserTopList) {
            setCollectionSelectedTopsOther(updatedCollectionTops);
          } else {
            setCollectionSelectedTops(updatedCollectionTops);
          }
        }
        // set total top count
        const totalValue = newSelectedTops.reduce(
          (total, obj) => obj.selectedQuantity! + total,
          FixedValue.CONSTANT_VALUE_0
        );
        _setTotalTop(totalValue);
      }
    },
    [
      collectionsTops,
      collectionSelectedTops,
      totalTops,
      collectionSelectedTopsOther,
      isOtherUserTopList,
    ]
  );

  const _updateTopQuantity = React.useCallback(
    (data: TopModel | null, type: string, quant?: number): void => {
      setCollectionsTops(prev =>
        prev.map(item => {
          if (item.id === data?.id) {
            return {
              ...item,
              selectedQuantity: quant
                ? quant
                : type === TEXT.ADD
                ? (item.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0) +
                  FixedValue.CONSTANT_VALUE_1
                : (item.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0) -
                  FixedValue.CONSTANT_VALUE_1,
            };
          }
          return item;
        })
      );
      const setSelectedTop = isOtherUserTopList
        ? setCollectionSelectedTopsOther
        : setCollectionSelectedTops;
      setSelectedTop(prev => {
        const newUpdatedCollection = prev.map(top => {
          if (top.id === data?.id) {
            return {
              ...top,
              selectedQuantity: quant
                ? quant
                : type === TEXT.ADD
                ? (top.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0) +
                  FixedValue.CONSTANT_VALUE_1
                : (top.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0) -
                  FixedValue.CONSTANT_VALUE_1,
            };
          }
          return top;
        });
        // set total top count
        const totalValue = newUpdatedCollection.reduce(
          (total, obj) => obj.selectedQuantity! + total,
          FixedValue.CONSTANT_VALUE_0
        );
        _setTotalTop(totalValue);
        return newUpdatedCollection;
      });
    },
    [
      collectionsTops,
      collectionSelectedTops,
      totalTops,
      collectionSelectedTopsOther,
    ]
  );

  // Set Collection Specific tops
  const _setCollectionSelectedTops = React.useCallback(
    (NewData: TopModel[]): void => {
      setCollectionSelectedTops(NewData);
    },
    [collectionSelectedTops]
  );

  const _setTotalTop = React.useCallback(
    (value: number): void => {
      setTotalTop(value);
    },
    [totalTops]
  );

  const _setOtherTradeUser = React.useCallback(
    (value: CustomUserListSearchModalListData): void => {
      setOtherTradeUser(value);
    },
    [otherTradeUser]
  );

  const _setIsOtherUserTopList = React.useCallback(
    (val: boolean): void => {
      setIsOtherUserTopList(val);
    },
    [isOtherUserTopList]
  );

  const _setCollectionSelectedTopsOther = React.useCallback(
    (NewData: TopModel[]): void => {
      setCollectionSelectedTopsOther(NewData);
    },
    [collectionSelectedTopsOther]
  );

  const _setIsTopEdit = React.useCallback(
    (val: boolean): void => {
      setIsTopEdit(val);
    },
    [isTopEdit]
  );

  const _setCounterOffer = React.useCallback(
    (val: boolean): void => {
      setCounterOffer(val);
    },
    [isCounterOffer]
  );

  const _setFromProposeTrade = React.useCallback(
    (val: boolean): void => {
      setFromProposeTrade(val);
    },
    [fromProposeTrade]
  );

  const _setShowUntradableAlert = React.useCallback(
    (val: boolean): void => {
      setShowUntradableAlert(val);
    },
    [showUntradableAlert]
  );

  const _setInitialLoader = React.useCallback(
    (val: boolean): void => {
      setInitialLoader(val);
    },
    [initialLoader]
  );

  const _setCollectionModel = React.useCallback(
    (NewData: CollectionModel): void => {
      setCollectionModel(NewData);
    },
    [collectionModel]
  );

  // Handle Collection Specific Tops Response
  React.useEffect(() => {
    switch (collectionBasedTopList.status) {
      case GET_TOPS_BY_COLLECTION.SUCCESS:
        const topsData = collectionBasedTopList.success?.data?.results?.map(
          item => {
            const selectedTops = isOtherUserTopList
              ? collectionSelectedTopsOther
              : collectionSelectedTops;
            const matchingItem = selectedTops.find(
              item1 => item1.id === item.id
            );
            return matchingItem
              ? {...item, ...matchingItem, quantity: item.quantity}
              : {
                  ...item,
                  isSelected: false,
                  selectedQuantity: 1,
                };
          }
        );
        if (
          collectionBasedTopList.success?.pageNumber ===
          FixedValue.CONSTANT_VALUE_0
        ) {
          setCollectionsTops(topsData ?? []);
          setIsRefreshing(false);
        } else {
          _setCollectionsTops(topsData ?? []);
          setIsRefreshing(false);
        }
        setInitialLoader(false);
        break;
      case GET_TOPS_BY_COLLECTION.FAILED:
        setIsRefreshing(false);
        setInitialLoader(false);
        break;
      default:
        break;
    }
  }, [collectionBasedTopList.status]);
  // Handle ReceivedOffer List Response
  // Handle Collections list response
  React.useEffect(() => {
    switch (receivedOffers.status) {
      case TRADE_RECEVIED_OFFER.SUCCESS:
        if (
          receivedOffers.success?.pageNumber === FixedValue.CONSTANT_VALUE_0
        ) {
          setReceivedOfferList(receivedOffers.success?.data?.results ?? []);
          setIsRefreshing(false);
        } else {
          _setReceivedOfferList(receivedOffers.success?.data?.results ?? []);
          setIsRefreshing(false);
        }
        setInitialLoader(false);
        break;
      case TRADE_RECEVIED_OFFER.FAILED:
        setIsRefreshing(false);
        setInitialLoader(false);
        break;
      default:
        break;
    }
  }, [receivedOffers.status]);

  // Handle Sent Offer List Response
  React.useEffect(() => {
    switch (sentOffers.status) {
      case TRADE_SENT_OFFER.SUCCESS:
        if (sentOffers.success?.pageNumber === FixedValue.CONSTANT_VALUE_0) {
          setSentOfferList(sentOffers.success?.data?.results ?? []);
          setIsRefreshing(false);
        } else {
          _setSentOfferList(sentOffers.success?.data?.results ?? []);
          setIsRefreshing(false);
        }
        setInitialLoader(false);
        break;
      case TRADE_SENT_OFFER.FAILED:
        setIsRefreshing(false);
        setInitialLoader(false);
        break;
      default:
        break;
    }
  }, [sentOffers.status]);

  // Handle Collections list response
  React.useEffect(() => {
    switch (collectionTopLists.status) {
      case COLLECTION_TOP_LISTS.SUCCESS:
        const matchingArray = isOtherUserTopList
          ? collectionSelectedTopsOther
          : collectionSelectedTops;
        const updatedArray = collectionTopLists?.success?.data?.results?.map(
          collection => {
            // Update tops within each collection
            const updatedTops = collection?.tops?.map(top => {
              // Find the matching top from the selectedTops array
              const selectedTop = matchingArray?.find(
                selected => selected.id === top.id
              );
              if (selectedTop) {
                // Update top properties if a match is found
                return {
                  ...top,
                  selectedQuantity: selectedTop.selectedQuantity,
                  isSelected: selectedTop.isSelected,
                };
              }
              return top; // Return the top unchanged if no match is found
            });
            // Return the collection with updated tops
            return {
              ...collection,
              tops: updatedTops,
            };
          }
        );
        if (
          collectionTopLists.success?.pageNumber === FixedValue.CONSTANT_VALUE_0
        ) {
          setCollectionsList(updatedArray ?? []);
          setIsRefreshing(false);
        } else {
          _setCollectionsList(updatedArray ?? []);
          setIsRefreshing(false);
        }
        setInitialLoader(false);
        break;
      case COLLECTION_TOP_LISTS.FAILED:
        setIsRefreshing(false);
        setInitialLoader(false);
        break;
      default:
        break;
    }
  }, [collectionTopLists.status]);
  // Approve Offer
  // Handle Collections list response
  function removeOfferByID(id: number, newStatus: string): void {
    const indexToUpdate = receivedOfferList?.findIndex(
      model => model?.id === id
    );
    if (indexToUpdate !== FixedValue.CONSTANT_VALUE_MIN_1) {
      const newOffersResult = [...receivedOfferList];
      newOffersResult[indexToUpdate] = {
        ...newOffersResult[indexToUpdate],
        status: newStatus,
      };
      setReceivedOfferList(newOffersResult);
    }
  }
  function removeOfferSentOffersByID(id: number, newStatus: string): void {
    const indexToUpdate = sentOfferList?.findIndex(model => model?.id === id);
    if (indexToUpdate !== FixedValue.CONSTANT_VALUE_MIN_1) {
      const newOffersResult = [...sentOfferList];
      newOffersResult[indexToUpdate] = {
        ...newOffersResult[indexToUpdate],
        status: newStatus,
      };
      setSentOfferList(newOffersResult);
    }
  }
  React.useEffect(() => {
    switch (approveOffer.status) {
      case APPROVE_RECEIVED_OFFER.SUCCESS:
        if (selectedItem?.id) {
          removeOfferByID(selectedItem?.id, TEXT.ACCEPTED_IN_CAPS);
          setSelectedItem(null);
        }
        break;
      case APPROVE_RECEIVED_OFFER.FAILED:
        let msg: string =
          //@ts-ignore
          approveOffer?.failed?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '';
        _ToastHandler(msg, false);
        break;
      default:
        break;
    }
  }, [approveOffer.status]);
  React.useEffect(() => {
    switch (rejectOffer.status) {
      case REJECT_RECEIVED_OFFER.SUCCESS:
        if (selectedItem?.id) {
          removeOfferByID(selectedItem?.id, TEXT.REJECTED_IN_CAPS);
          setSelectedItem(null);
        }
        break;
      case REJECT_RECEIVED_OFFER.FAILED:
        let msg: string =
          //@ts-ignore
          rejectOffer?.failed?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '';
        _ToastHandler(msg, false);
        break;
      default:
        break;
    }
  }, [rejectOffer.status]);

  React.useEffect(() => {
    switch (cancelOffer.status) {
      case CANCEL_SENT_OFFER.SUCCESS:
        if (selectedItem?.id) {
          removeOfferSentOffersByID(selectedItem?.id, TEXT.CANCELLED_IN_CAPS);
          _ToastHandler('Sent Offer Canceled Successfully', true);
          setSelectedItem(null);
        }
        break;
      case CANCEL_SENT_OFFER.FAILED:
        let msg: string =
          //@ts-ignore
          cancelOffer?.failed?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '';
        _ToastHandler(msg, false);
        break;
      default:
        break;
    }
  }, [cancelOffer.status]);

  // modal open and close function for report modal
  const _setOpenReportModal = React.useCallback(
    (value: boolean): void => {
      setOpenReportModal(value);
    },
    [openReportModal]
  );

  const updateCollectionTopQuantity = useCallback(
    (collectionId: number, item: TopModel) => {
      // Find the index of the collection with the given collectionId
      const topData = {...item};
      let newSelectedTops = [];

      if (!topData?.isSelected) {
        // If the item is not selected, mark it as selected and set its quantity to 1
        topData.isSelected = true;
        topData.selectedQuantity = FixedValue.CONSTANT_VALUE_1;
        if (isOtherUserTopList) {
          newSelectedTops = [...collectionSelectedTopsOther, topData];
          setCollectionSelectedTopsOther(newSelectedTops);
        } else {
          newSelectedTops = [...collectionSelectedTops, topData];
          setCollectionSelectedTops(newSelectedTops);
        }
      } else {
        // If the item is already selected, find it and remove from the list
        const selectedTops = isOtherUserTopList
          ? collectionSelectedTopsOther
          : collectionSelectedTops;
        const index = selectedTops.findIndex(top => top.id === topData.id);
        newSelectedTops = selectedTops.filter((_, idx) => idx !== index);
        if (isOtherUserTopList) {
          setCollectionSelectedTopsOther(newSelectedTops);
        } else {
          setCollectionSelectedTops(newSelectedTops);
        }
      }

      // Find the index of the collection with the given collectionId
      const collectionIndex = collectionsList?.findIndex(
        collection => collection.id === collectionId
      );

      // Map over collectionsList to create a new array
      const updatedCollectionsList = collectionsList.map((collection, idx) => {
        if (idx === collectionIndex) {
          // Map over tops to create a new array with the updated top
          const updatedTops = collection?.tops?.map(top => {
            if (top.id === item.id) {
              // Toggle the selection state and update quantity
              if (top.isSelected) {
                return {
                  ...top,
                  selectedQuantity: FixedValue.CONSTANT_VALUE_0,
                  isSelected: false,
                };
              } else {
                return {
                  ...top,
                  selectedQuantity: FixedValue.CONSTANT_VALUE_1,
                  isSelected: true,
                };
              }
            }
            return top;
          });
          // Return a new collection object with the updated tops
          return {...collection, tops: updatedTops};
        }
        return collection;
      });

      // Set the updated collections list in the state
      _setCollectionsList(updatedCollectionsList, true);

      // Calculate the total selectedQuantity from newSelectedTops
      const totalValue = newSelectedTops.reduce(
        (total, obj) => obj.selectedQuantity! + total,
        FixedValue.CONSTANT_VALUE_0
      );

      // Update the totalTop state with the calculated total value
      _setTotalTop(totalValue);
    },
    [
      isOtherUserTopList,
      collectionSelectedTops,
      collectionSelectedTopsOther,
      collectionsList,
      _setCollectionsList,
      _setTotalTop,
      setCollectionSelectedTops,
      setCollectionSelectedTopsOther,
    ]
  );

  const updateCollectionTopQuantityMoreThanOne = useCallback(
    (collectionId: number, item: TopModel, action: string) => {
      // Determine the correct setter and state based on whether it's other user top list
      const setSelectedTop = isOtherUserTopList
        ? setCollectionSelectedTopsOther
        : setCollectionSelectedTops;
      // Get the correct temporary data based on the condition
      let tempData = isOtherUserTopList
        ? collectionSelectedTopsOther
        : collectionSelectedTops;
      // Update the selectedQuantity of the matching item in tempData
      tempData = tempData.map(data => {
        if (data.id === item?.id) {
          const newQuantity =
            action === TEXT.ADD
              ? (data.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0) +
                FixedValue.CONSTANT_VALUE_1
              : (data.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0) -
                FixedValue.CONSTANT_VALUE_1;
          return {
            ...data,
            selectedQuantity: newQuantity,
          };
        }
        return data;
      });

      // Update the state with the modified tempData
      setSelectedTop(tempData);

      // Find the index of the collection with the given collectionId
      const collectionIndex = collectionsList?.findIndex(
        collection => collection.id === collectionId
      );

      // Map over collectionsList to create a new array with the updated top
      const updatedCollectionsList = collectionsList.map((collection, idx) => {
        if (idx === collectionIndex) {
          // Map over tops to create a new array with the updated top
          const updatedTops = collection?.tops?.map(top => {
            if (top.id === item.id) {
              // Calculate the new selectedQuantity based on the action
              const newQuantity =
                action === TEXT.ADD
                  ? (top.selectedQuantity || 0) + 1
                  : (top.selectedQuantity || 0) - 1;
              // Ensure selectedQuantity does not go below 0
              const finalQuantity = Math.max(newQuantity, 0);

              // Return a new object with the updated properties
              return {
                ...top,
                selectedQuantity: finalQuantity,
                isSelected: finalQuantity > 0,
              };
            }
            return top;
          });

          // Return a new collection object with the updated tops
          return {...collection, tops: updatedTops};
        }
        return collection;
      });

      // Set the updated collections list in the state
      _setCollectionsList(updatedCollectionsList, true);

      // Calculate the total selectedQuantity from tempData
      const totalValue = tempData.reduce(
        (total, obj) => obj.selectedQuantity! + total,
        FixedValue.CONSTANT_VALUE_0
      );

      // Update the totalTop state with the calculated total value
      _setTotalTop(totalValue);
    },
    [
      isOtherUserTopList,
      setCollectionSelectedTops,
      setCollectionSelectedTopsOther,
      collectionSelectedTops,
      collectionSelectedTopsOther,
      collectionsList,
      _setCollectionsList,
      _setTotalTop,
    ]
  );

  const updateCollectionTopQuantityOnEdit = useCallback(
    (collectionId: number, item: TopModel, quantity: number) => {
      // Determine the correct setter and state based on whether it's other user top list
      const setSelectedTop = isOtherUserTopList
        ? setCollectionSelectedTopsOther
        : setCollectionSelectedTops;
      // Get the correct temporary data based on the condition
      let tempData = isOtherUserTopList
        ? collectionSelectedTopsOther
        : collectionSelectedTops;
      // Update the selectedQuantity of the matching item in tempData
      tempData = tempData.map(data => {
        if (data.id === item?.id) {
          const newQuantity = quantity;
          return {
            ...data,
            selectedQuantity: newQuantity,
          };
        }
        return data;
      });

      // Update the state with the modified tempData
      setSelectedTop(tempData);

      // Find the index of the collection with the given collectionId
      const collectionIndex = collectionsList?.findIndex(
        collection => collection?.id === collectionId
      );

      // Map over collectionsList to create a new array with the updated top
      const updatedCollectionsList = collectionsList.map((collection, idx) => {
        if (idx === collectionIndex) {
          // Map over tops to create a new array with the updated top
          const updatedTops = collection?.tops?.map(top => {
            if (top.id === item.id) {
              // Calculate the new selectedQuantity based on the action
              const newQuantity = quantity;

              const finalQuantity = Math.max(newQuantity, 0);
              // Return a new object with the updated properties
              return {
                ...top,
                selectedQuantity: finalQuantity,
                isSelected: true,
              };
            }
            return top;
          });

          // Return a new collection object with the updated tops
          return {...collection, tops: updatedTops};
        }
        return collection;
      });
      // Set the updated collections list in the state
      _setCollectionsList(updatedCollectionsList, true);
      // Calculate the total selectedQuantity from tempData
      const totalValue = tempData.reduce(
        (total, obj) => obj.selectedQuantity! + total,
        FixedValue.CONSTANT_VALUE_0
      );
      // Update the totalTop state with the calculated total value
      _setTotalTop(totalValue);
    },
    [
      isOtherUserTopList,
      setCollectionSelectedTops,
      setCollectionSelectedTopsOther,
      collectionSelectedTops,
      collectionSelectedTopsOther,
      collectionsList,
      _setCollectionsList,
      _setTotalTop,
    ]
  );

  const values = React.useMemo(
    (): ITradeContext => ({
      collectionsList,
      collectionsTops,
      selectedTopsToMove,
      receivedOfferList,
      sentOfferList,
      isRefreshing,
      currentActiveTab,
      collectionSelectedTops,
      totalTops,
      otherTradeUser,
      openCancelModal,
      isOtherUserTopList,
      collectionSelectedTopsOther,
      isTopEdit,
      approveModal,
      cancelModal,
      isApprove,
      openSentOfferModal,
      isCounterOffer,
      openReportModal,
      setIsRefreshing: _setIsRefreshing,
      setCollectionsList: _setCollectionsList,
      clearCollectionsList: _clearCollectionsList,
      setCollectionsTops: _setCollectionsTops,
      clearTopsinCollection: _clearTopsInCollection,
      selectOrUnSelectSingleTop: _selectOrUnSelectSingleTop,
      setSelectedTopsToMove: _setSelectedTopsToMove,
      clearSelectedTopsToMove: _clearSelectedTopsToMove,
      updateTopQuantity: _updateTopQuantity,
      openReceviedOfferModal,
      selectedItem,
      setOpenReceviedOfferModal: _setOpenReceviedOfferModal,
      setIsApprove: _setIsApprove,
      setCancelModal: _setCancelModal,
      setApproveModal: _setApproveModal,
      setSelectedItem: _setSelectedItem,
      setReceivedOfferList: _setReceivedOfferList,
      setSenetOfferList: _setSentOfferList,
      setCurrentActiveTab: _setCurrentActiveTab,
      setCollectionSelectedTops: _setCollectionSelectedTops,
      setTotalTop: _setTotalTop,
      setOtherTradeUser: _setOtherTradeUser,
      setOpenCancelModal: _setopenCancelModal,
      setIsOtherUserTopList: _setIsOtherUserTopList,
      setCollectionSelectedTopsOther: _setCollectionSelectedTopsOther,
      setIsTopEdit: _setIsTopEdit,
      setOpenSentOfferModal: _setOpenSentOfferModal,
      setCounterOffer: _setCounterOffer,
      fromProposeTrade,
      setFromProposeTrade: _setFromProposeTrade,
      showUntradableAlert,
      setShowUntradableAlert: _setShowUntradableAlert,
      initialLoader,
      setInitialLoader: _setInitialLoader,
      collectionModel,
      setCollectionModel: _setCollectionModel,
      setOpenReportModal: _setOpenReportModal,
      _setCollectionsList,
      updateCollectionTopQuantity,
      updateCollectionTopQuantityMoreThanOne,
      updateCollectionTopQuantityOnEdit,
    }),
    [
      collectionsList,
      collectionsTops,
      selectedTopsToMove,
      sentOfferList,
      receivedOfferList,
      openReceviedOfferModal,
      approveModal,
      cancelModal,
      selectedItem,
      currentActiveTab,
      collectionSelectedTops,
      totalTops,
      otherTradeUser,
      openCancelModal,
      isOtherUserTopList,
      collectionSelectedTopsOther,
      isTopEdit,
      openSentOfferModal,
      isCounterOffer,
      isRefreshing,
      openReportModal,
      setIsRefreshing,
      setOpenReceviedOfferModal,
      setApproveModal,
      setCancelModal,
      setSelectedItem,
      setCollectionSelectedTops,
      setTotalTop,
      setOtherTradeUser,
      setopenCancelModal,
      setIsOtherUserTopList,
      setCollectionSelectedTopsOther,
      setIsTopEdit,
      setOpenSentOfferModal,
      setCounterOffer,
      fromProposeTrade,
      setFromProposeTrade,
      showUntradableAlert,
      setShowUntradableAlert,
      initialLoader,
      setInitialLoader,
      collectionModel,
      setCollectionModel,
      setOpenReportModal,
      updateCollectionTopQuantityOnEdit,
    ] // Updated dependencies
  );
  return (
    <TradeContext.Provider value={values}>
      {props.children}
    </TradeContext.Provider>
  );
};
export {TradeContext, TradeProvider};
