import React from 'react';
import {View} from 'react-native';
import {FixedValue, ROUTE_NAME, TEXT} from '../../../constants';
import {ThemeContext} from '../../../contextAPI/themeContext';
import InProgressScreen from '../../inProgressTops';
import CollectionScreen from '../../topCollections';
import PurchasedTopsScreen from '../../purchasedTops';
import {mantelTabStyle} from './mantelTop.style';
import useInProgressTopsHook from '../../../hooks/componentHooks/useInProgressTopsHooks';
import TopTabBarLayout from '../../../components/layout/topTabBarLayout';
import {ISingleTabProps} from '../../../types/componentTypes/layoutType';
import {useAppDispatch, useAppSelector} from '../../../hooks/reduxHooks';
import {
  activateInCollectionTab,
  activateInProgressTab,
  createCollectionStore,
} from '../../../redux/reducers/collectionSlice';
import DistributeTopModal from '../../../modals/distributeTopModal';
import BottomListModalWithCheckbox from '../../../modals/bottomListWithCheckbox';
import {HomeContext} from '../../../contextAPI/homeContext';
import {ProfileTabContext} from '../../../contextAPI/profileTabContext';
import useTopCollectionHooks from '../../../hooks/componentHooks/useTopCollectionListsHooks';
import {useIsFocused} from '@react-navigation/native';
import usePurchasedTopsHooks from '../../../hooks/componentHooks/usePurchasedTopsHooks';
import {
  HomeStore,
  reportAbuseDataStart,
  topAmountAndReportAbuseDataStart,
} from '../../../redux/reducers/homeSlice';
import CreateTopButton from '../../../components/button/createTopButton';
import {navigate} from '../../../services/navigationService';
import {DELETE_TOPS, VERIFY_REEDEM_CODE} from '../../../constants/actionTypes';
import ReedemCodeModal from '../../../modals/reedemCodeModal';
import {_ToastHandler} from '../../../utils/helperFunctions';
import {TOAST_TEXT} from '../../../constants/textMessage';
import {onNotificationCountStart} from '../../../redux/reducers/notificationSlice';

const MainTabScreen = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = mantelTabStyle(ColorTheme);
  const {
    profileSwitchListModal,
    homeScreenUserData,
    setProfileSwitchListModal,
    setHomeScreenDataLoader,
    currentActiveTab,
    collectionsResult,
    isProfileSwitchToChild,
    selectedTopsResult,
    topsResult,
    setSelectedTopsResult,
    setInitialLoader,
    openReedemModal,
    setOpenReedemModal,
    showWelcomeTop,
  } = React.useContext(HomeContext);
  const {
    saveSwitchProfileDataOnAppLoad,
    saveSwitchProfileDataOnSelect,
    callInToCollectionListAPI,
  } = useTopCollectionHooks();
  const {moveToInProgress, moveToInCollection} = useAppSelector(
    createCollectionStore
  );
  const dispatch = useAppDispatch();
  const {callInProgressTopsListAPI} = useInProgressTopsHook();
  const {callPurchasedTopsListAPI} = usePurchasedTopsHooks();
  const {parentChildListData} = React.useContext(ProfileTabContext);
  const {
    inProgressTopList,
    purchasedTopLists,
    collectionTopLists,
    deleteTops,
    reedemCodeData,
  } = useAppSelector(HomeStore);
  const isFocused = useIsFocused();
  const [tabIndex, setTabIndex] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_0
  );

  const tabBarList: ISingleTabProps[] = [
    {
      routeName: ROUTE_NAME.COLLECTIONS_SCREEN,
      component: CollectionScreen,
      tabBarName: TEXT.COLLECTIONS,
    },
    {
      routeName: ROUTE_NAME.IN_PROGRESS_TOPS_SCREEN,
      component: InProgressScreen,
      tabBarName: TEXT.IN_PROGRESS,
    },
    {
      routeName: ROUTE_NAME.PURCHASED_TOPS_SCREEN,
      component: PurchasedTopsScreen,
      tabBarName: TEXT.PURCHASES,
    },
  ];

  React.useEffect(() => {
    dispatch(reportAbuseDataStart());
    dispatch(topAmountAndReportAbuseDataStart());
  }, []);

  React.useEffect(() => {
    if (
      !collectionTopLists.isLoading &&
      !inProgressTopList.isLoading &&
      !purchasedTopLists.isLoading
    ) {
      setHomeScreenDataLoader(false);
    }
  }, [
    collectionTopLists.status,
    inProgressTopList.status,
    purchasedTopLists.status,
  ]);

  React.useEffect(() => {
    if (isFocused && homeScreenUserData !== null) {
      setInitialLoader(true);
      callInToCollectionListAPI(FixedValue.CONSTANT_VALUE_0);
      callInProgressTopsListAPI(FixedValue.CONSTANT_VALUE_0);
      callPurchasedTopsListAPI(FixedValue.CONSTANT_VALUE_0);
    }
  }, [homeScreenUserData, isFocused]);

  React.useEffect(() => {
    if (isFocused) {
      saveSwitchProfileDataOnAppLoad();
    }
  }, [isFocused]);

  const onlyRequestApprovedChildUserChild = parentChildListData?.filter(
    list => list?.accountApprovalRequests === FixedValue.CONSTANT_VALUE_0
  );

  React.useEffect((): void => {
    if (moveToInProgress) {
      setTabIndex(FixedValue.CONSTANT_VALUE_1);
      callInProgressTopsListAPI(FixedValue.CONSTANT_VALUE_0);
      dispatch(activateInProgressTab(false));
    }
  }, [moveToInProgress]);

  React.useEffect((): void => {
    if (moveToInCollection) {
      setInitialLoader(true);
      setTabIndex(FixedValue.CONSTANT_VALUE_0);
      callInToCollectionListAPI(FixedValue.CONSTANT_VALUE_0);
      callInProgressTopsListAPI(FixedValue.CONSTANT_VALUE_0);
      callPurchasedTopsListAPI(FixedValue.CONSTANT_VALUE_0);
      dispatch(activateInCollectionTab(false));
    }
  }, [moveToInCollection]);

  React.useEffect(() => {
    const updatedSelectedTopsResult = selectedTopsResult.filter(top => {
      const matchingItem = topsResult.find(result => result.id === top.id);
      return top.id === matchingItem?.id || top.fromPurchase;
    });
    const topsData = updatedSelectedTopsResult?.map(item => {
      const matchingItem = topsResult.find(item1 => item1.id === item.id);
      return matchingItem
        ? {
            ...matchingItem,
            selectedQuantity: item.selectedQuantity,
            isSelected: item.isSelected,
            quantity: item.quantity,
            amount: item.amount,
          }
        : {...item};
    });
    setSelectedTopsResult(topsData ?? []);
  }, [topsResult]);

  React.useEffect(() => {
    switch (deleteTops.status) {
      case DELETE_TOPS.SUCCESS:
        callInToCollectionListAPI(FixedValue.CONSTANT_VALUE_0);
        callInProgressTopsListAPI(FixedValue.CONSTANT_VALUE_0);
        break;
      default:
        break;
    }
  }, [deleteTops.status]);

  const customModaldata = {
    closeModal: setProfileSwitchListModal,
    modalVisible: profileSwitchListModal,
    onPress: saveSwitchProfileDataOnSelect,
    listData: onlyRequestApprovedChildUserChild ?? [],
  };

  const childListModal = React.useMemo((): JSX.Element | null => {
    // @ts-ignore
    return <BottomListModalWithCheckbox customModaldata={customModaldata} />;
  }, [parentChildListData, profileSwitchListModal, setProfileSwitchListModal]);

  //check to show create top icon on homeTab or not
  const checkShowCreateTopIcon: boolean =
    currentActiveTab !== ROUTE_NAME.COLLECTIONS_SCREEN ||
    collectionsResult?.length > FixedValue.CONSTANT_VALUE_0;

  const onPress = React.useCallback((): void => {
    if (tabIndex === FixedValue.CONSTANT_VALUE_1) {
      navigate(ROUTE_NAME.MY_CART_TOPS);
    } else {
      navigate(ROUTE_NAME.EDITOR_SCREEN);
    }
  }, [tabIndex]);

  React.useEffect(() => {
    if (isFocused) {
      switch (reedemCodeData.status) {
        case VERIFY_REEDEM_CODE.SUCCESS:
          callInToCollectionListAPI(FixedValue.CONSTANT_VALUE_0);
          _ToastHandler(TOAST_TEXT.YOU_HAVE_RECEIVED_THE_TOP, true);
          dispatch(onNotificationCountStart());
          break;
        default:
          break;
      }
    }
  }, [reedemCodeData.status, isFocused]);

  return (
    <View style={styles.container}>
      <TopTabBarLayout
        customTabStyle={styles.tabBarStyle}
        tabArray={tabBarList}
        setTab={setTabIndex}
        setCurrentActiveTab={tabIndex}
      />
      {childListModal}
      {checkShowCreateTopIcon && !isProfileSwitchToChild && (
        <CreateTopButton onPress={onPress} tabIndex={tabIndex} />
      )}
      {isFocused && <DistributeTopModal />}
      {openReedemModal && isFocused && !showWelcomeTop && (
        <ReedemCodeModal
          visble={openReedemModal}
          onClose={() => setOpenReedemModal(false)}
        />
      )}
    </View>
  );
};

export default MainTabScreen;
