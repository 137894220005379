import StyleSheet from 'react-native-media-query';
import {BASESTYLE, FixedValue, Percentage} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {isWebsite, scaleHeight, scaleWidth} from '../../utils/responsive';

export const groupMangementStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
    },
    headerView: {
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    tabBarStyle: {
      width: Percentage.PRECENTAGE_90,
    },
  });
