import StyleSheet from 'react-native-media-query';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  FONTS,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';

export const trackingTabDetailStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    modalContainer: {
      width: Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_90,
      backgroundColor: theme.backgroundColor,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_15),
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_18),
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_18,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_18,
      shadowColor: COLORS._333333,
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_0,
        height: -FixedValue.CONSTANT_VALUE_3,
      },
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
      elevation: FixedValue.CONSTANT_VALUE_3,
    },
    topImage: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_70),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_70),
      borderRadius: FixedValue.CONSTANT_VALUE_8,
    },
    rowContainer: {
      flexDirection: GlobalStyleValues.ROW,
    },
    top: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_15),
    },
    quantity: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme.color,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    title: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_18),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: theme.color,
    },
    subtitle: {
      color: '#5E6272',
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_11),
      fontFamily: FONT_NAMES.SF_LIGHT,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_8),
    },
    boldText: {
      fontFamily: FONT_NAMES.SF_BOLD,
    },
    seaerchContainer: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    list: {
      flex: FixedValue.CONSTANT_VALUE_1,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    timeColor: {
      color: theme.color,
    },
    cancelButton: {
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_46),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_84),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      alignItems: GlobalStyleValues.CENTER,
      justifyContent: GlobalStyleValues.CENTER,
      backgroundColor: COLORS.FDE4E1,
    },
    sendReminderBtn: {
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_46),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_145),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      alignItems: GlobalStyleValues.CENTER,
      justifyContent: GlobalStyleValues.CENTER,
      backgroundColor: COLORS.E9EEFB,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    cancelButtonText: {
      color: COLORS.F54336,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_15),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_4),
    },
    sendReminderBtnText: {
      color: COLORS._1F53D3,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_15),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_4),
    },
    remindAllBtn: {
      borderRadius: FixedValue.CONSTANT_VALUE_46,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_110),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      alignItems: GlobalStyleValues.CENTER,
      justifyContent: GlobalStyleValues.CENTER,
      backgroundColor: COLORS.E9EEFB,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    cancelButton1: {
      borderRadius: FixedValue.CONSTANT_VALUE_46,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      alignItems: GlobalStyleValues.CENTER,
      justifyContent: GlobalStyleValues.CENTER,
      backgroundColor: COLORS.FDE4E1,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_110),  
    },
    underLineView: {
      flexDirection: GlobalStyleValues.COLUMN,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_1),
      backgroundColor: COLORS.LIGHTGRAY,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    emptyView: {
      marginHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_28),
    },
    scrollviewStyle: {
      ...BASESTYLE.flex1,
      justifyContent: GlobalStyleValues.CENTER,
    },
    placeholder: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_38),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_38),
      borderRadius: FixedValue.CONSTANT_VALUE_4,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      backgroundColor: theme.color,
      justifyContent: GlobalStyleValues.CENTER,
      alignItems: GlobalStyleValues.CENTER,
    },
    detailPlaceholder: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_70),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_70),
      borderRadius: FixedValue.CONSTANT_VALUE_10,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      backgroundColor: theme.color,
      justifyContent: GlobalStyleValues.CENTER,
      alignItems: GlobalStyleValues.CENTER,
    },
    initials: {
      color: theme.backgroundColor,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    circleImage: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      backgroundColor: theme.color,
      justifyContent: GlobalStyleValues.CENTER,
      alignItems: GlobalStyleValues.CENTER,
    },
    textContainer: {
      flex: FixedValue.CONSTANT_VALUE_1,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    itemContainer: {
      flexDirection: GlobalStyleValues.ROW,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    container: {
      flex: FixedValue.CONSTANT_VALUE_1,
      justifyContent: GlobalStyleValues.CENTER,
      alignItems: GlobalStyleValues.CENTER,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_500),
    },
    text: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: theme.color,
    },
    heartContainer: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_46),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_46),
      backgroundColor: COLORS.E9EEFB,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_46),
      justifyContent: GlobalStyleValues.CENTER,
      alignItems: GlobalStyleValues.CENTER,
    },
    sameRow: {
      width: Percentage.PRECENTAGE_85,
    },
    container2: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_5),
      flexDirection: GlobalStyleValues.ROW,
      alignItems: GlobalStyleValues.CENTER,
    },
    containerCanccelAll: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_15),
      flexDirection: GlobalStyleValues.ROW,
    },
    badge: {
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      paddingVertical: scaleWidth(FixedValue.CONSTANT_VALUE_3),
      paddingHorizontal: scaleHeight(FixedValue.CONSTANT_VALUE_6),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_5),
    },
    badgeText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_15),
      color: COLORS.BLACK,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONTS.SF_MEDIUM,
    },
    Accepted: {
      backgroundColor: COLORS._E6F3E5,
    },
    Pending: {
      backgroundColor: COLORS._FEFAE6,
    },
    Rejected: {
      backgroundColor: COLORS.FDE4E1,
    },
    buttonContainer: {
      flexDirection: GlobalStyleValues.ROW,
      justifyContent: GlobalStyleValues.CENTER,
      alignItems: GlobalStyleValues.CENTER,
    },
    statusButtonContainer: {
      ...BASESTYLE.inCenter,
      flexDirection: GlobalStyleValues.ROW,
    },
  });
