import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath, Rect, SvgProps} from 'react-native-svg';
const Heart = (props: SvgProps) => (
  <Svg
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <G clipPath="url(#clip0_8759_11607)">
      <Path
        d="M12 22.3784C11.8786 22.3784 11.7572 22.347 11.6485 22.2842C11.5304 22.2161 8.72392 20.5866 5.8772 18.1314C4.18999 16.6763 2.84318 15.233 1.87427 13.8417C0.620464 12.0415 -0.0100507 10.3098 0.000121129 8.69482C0.0120274 6.8156 0.685104 5.04833 1.89551 3.71848C3.12635 2.36624 4.76894 1.62158 6.5208 1.62158C8.76596 1.62158 10.8187 2.87924 12.0001 4.87151C13.1814 2.87928 15.2341 1.62158 17.4793 1.62158C19.1344 1.62158 20.7134 2.29349 21.9258 3.51355C23.2563 4.85244 24.0123 6.74421 23.9999 8.70372C23.9897 10.3159 23.3473 12.0449 22.0908 13.8427C21.1189 15.2333 19.7739 16.6759 18.0933 18.1306C15.257 20.5856 12.4707 22.215 12.3535 22.2832C12.2442 22.3466 12.1221 22.3784 12 22.3784Z"
        fill={props?.fill ?? '#BCCBF2'}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_8759_11607">
        <Rect width={24} height={24} fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default Heart;
