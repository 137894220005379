import * as React from "react";
import Svg, { Path, Circle, SvgProps } from "react-native-svg";
const NoImage = (props:SvgProps) => (
  <Svg
    width={250}
    height={200}
    viewBox="0 0 250 200"
    fill="none"
    //@ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63 134H154C154.515 134 155.017 133.944 155.5 133.839C155.983 133.944 156.485 134 157 134H209C212.866 134 216 130.866 216 127C216 123.134 212.866 120 209 120H203C199.134 120 196 116.866 196 113C196 109.134 199.134 106 203 106H222C225.866 106 229 102.866 229 99C229 95.134 225.866 92 222 92H200C203.866 92 207 88.866 207 85C207 81.134 203.866 78 200 78H136C139.866 78 143 74.866 143 71C143 67.134 139.866 64 136 64H79C75.134 64 72 67.134 72 71C72 74.866 75.134 78 79 78H39C35.134 78 32 81.134 32 85C32 88.866 35.134 92 39 92H64C67.866 92 71 95.134 71 99C71 102.866 67.866 106 64 106H24C20.134 106 17 109.134 17 113C17 116.866 20.134 120 24 120H63C59.134 120 56 123.134 56 127C56 130.866 59.134 134 63 134ZM226 134C229.866 134 233 130.866 233 127C233 123.134 229.866 120 226 120C222.134 120 219 123.134 219 127C219 130.866 222.134 134 226 134Z"
      fill="#F3F7FF"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.2783 139.123L82.2081 139.695C80.0205 140.002 77.9978 138.478 77.6904 136.291L66.5565 57.0691C66.2491 54.8815 67.7733 52.8588 69.9609 52.5513L148.192 41.5567C150.38 41.2492 152.402 42.7734 152.71 44.961C152.71 44.961 153.422 50.0264 153.667 51.7688"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.805 134.713L85.1092 135.238C83.1227 135.52 81.2886 134.139 81.0126 132.153L71.0174 60.2281C70.7414 58.242 72.128 56.403 74.1145 56.1207L145.152 46.0239C147.138 45.7416 148.973 47.1228 149.249 49.1089L150.108 55.2897C150.154 55.6241 153.223 77.8236 159.313 121.888C159.619 124.101 158.093 126.145 155.905 126.454C155.882 126.458 155.858 126.461 155.835 126.464L88.805 134.713Z"
      fill="#E8F0FE"
    />
    <Path
      d="M86.2783 139.123L82.2081 139.695C80.0205 140.002 77.9978 138.478 77.6904 136.291L66.5565 57.0691C66.2491 54.8815 67.7733 52.8588 69.9609 52.5513L148.192 41.5567C150.38 41.2492 152.402 42.7734 152.71 44.961C152.71 44.961 153.422 50.0264 153.667 51.7688"
      stroke="#1F64E7"
      strokeWidth={2.5}
      strokeLinecap="round"
    />
    <Path
      d="M154.5 56.3799L155 59.5003"
      stroke="#1F64E7"
      strokeWidth={2.5}
      strokeLinecap="round"
    />
    <Path
      d="M99.8221 60.4886C99.9809 58.9782 101.334 57.8824 102.844 58.0411L181.412 66.2989C182.922 66.4577 184.018 67.8108 183.859 69.3213L175.497 148.883C175.338 150.394 173.985 151.489 172.475 151.331L93.9073 143.073C92.3969 142.914 91.3011 141.561 91.4598 140.05L99.8221 60.4886Z"
      fill="white"
      stroke="#1F64E7"
      strokeWidth={2.5}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.693 69.2551C106.866 67.6073 108.343 66.4119 109.99 66.5851L172.645 73.1704C174.293 73.3436 175.488 74.8197 175.315 76.4675L169.775 129.177C169.602 130.825 168.126 132.02 166.478 131.847L103.823 125.262C102.175 125.089 100.98 123.612 101.153 121.965L106.693 69.2551Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M109.741 109.878L119.538 103.27C121.172 102.168 123.365 102.42 124.706 103.864L131.879 111.587C132.237 111.973 132.833 112.015 133.242 111.683L148.581 99.2539C150.429 97.7566 153.167 98.1927 154.459 100.19L164.44 115.627L165.873 118.019L165.187 126.144C165.139 126.705 164.639 127.116 164.08 127.054L107.117 120.725C106.575 120.665 106.182 120.182 106.232 119.639L106.987 111.441L109.741 109.878Z"
      fill="#E8F0FE"
    />
    <Path
      d="M107.936 69.3857C108.037 68.4245 108.899 67.7272 109.86 67.8282L172.515 74.4135C173.476 74.5145 174.173 75.3757 174.072 76.3369L168.532 129.047C168.431 130.008 167.57 130.705 166.609 130.604L103.954 124.019C102.993 123.918 102.295 123.057 102.396 122.095L107.936 69.3857Z"
      stroke="#1F64E7"
      strokeWidth={2.5}
    />
    <Circle
      cx={122.032}
      cy={85.9498}
      r={6}
      transform="rotate(6 122.032 85.9498)"
      fill="#F3F7FF"
      stroke="#1F64E7"
      strokeWidth={2.5}
    />
    <Path
      d="M107.729 111.425C111.666 108.707 119.538 103.27 119.538 103.27C121.172 102.168 123.365 102.42 124.706 103.864L131.879 111.587C132.238 111.972 132.833 112.014 133.242 111.683L148.582 99.2535C150.298 97.8627 152.817 98.1267 154.208 99.8431C154.297 99.954 154.381 100.07 154.459 100.19C154.459 100.19 163.427 114.42 165.523 117.746"
      stroke="#1F64E7"
      strokeWidth={2.5}
      strokeLinecap="round"
    />
  </Svg>
);
export default NoImage;
