import React from 'react';
import {View, Text, Pressable} from 'react-native';
import {FixedValue, TEXT} from '../../../constants';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {IUserListWithCheckboxProps} from '../../../types/componentTypes/smallDesignsType';
import {getInitials} from '../../../utils/helperFunctions';
import SelectCheckBox from '../../renderSvgs/selectedRadioBox';
import UncheckSelectBox from '../../renderSvgs/unselectedRadioBox';
import ProfilePics from '../profilePics';
import {listWithCheckboxStyle} from './listWithCheckboxStyle';
import CustomButton from '../../button/customButton';

const ListWithCheckbox = ({
  item,
  onPress,
  isSelected,
  isForBlockedUser,
}: IUserListWithCheckboxProps) => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const styles = listWithCheckboxStyle(ColorTheme);
  const fullName = item?.firstName + ' ' + item?.lastName ?? '';

  const onPressUnBlockFunction = React.useCallback(() => {
    onPress(item);
  }, [item]);

  return (
    <View style={styles.userListStyle}>
      <ProfilePics
        image={item?.profileImage ?? ''}
        text={getInitials(fullName)}
        mainViewStyle={styles.mainViewStyle}
        initialsTextStyle={styles.profileNameText}
      />
      <Pressable
        onPress={() => !isForBlockedUser && onPress(item)}
        style={styles.middleContainer}
      >
        <Text
          numberOfLines={FixedValue.CONSTANT_VALUE_1}
          style={[styles.userNameText]}
        >
          {fullName}
        </Text>
      </Pressable>
      {isForBlockedUser ? (
        <CustomButton
          buttonLabel={TEXT.UNBLOCK}
          buttonwidth={styles.primaryButtonStyle1}
          labelStyle={styles.primaryButtonLabelstyle1}
          onPress={() => onPressUnBlockFunction()}
        />
      ) : (
        <View style={styles.rightContainer}>
          <View style={styles.checkboxView}>
            <Pressable onPress={() => onPress(item)}>
              {isSelected ? (
                <SelectCheckBox />
              ) : (
                <UncheckSelectBox
                  stroke={ColorTheme.color}
                  height={FixedValue.CONSTANT_VALUE_20}
                  width={FixedValue.CONSTANT_VALUE_20}
                />
              )}
            </Pressable>
          </View>
        </View>
      )}
    </View>
  );
};
export default ListWithCheckbox;
