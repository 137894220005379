import React, {useEffect} from 'react';
import {ImageBackground, Text, View} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {PaymentSuccessStyle} from './style';
import Done from '../../components/renderSvgs/done';
import BACKGROUND from '../../components/renderSvgs/background';
import CustomButton from '../../components/button/customButton';
import {BASESTYLE, FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import {scaleHeight} from '../../utils/responsive';
import {
  goBack,
  navigate,
  popToBack,
  popToTop,
} from '../../services/navigationService';
import {RouteProp, useRoute} from '@react-navigation/native';
import {RootStackParamList} from '../../types/navigationType';
import Failed from '../../components/renderSvgs/failed';

const PaymentSuccess = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = PaymentSuccessStyle(ColorTheme);
  const route = useRoute<RouteProp<RootStackParamList, 'PaymentSuccess'>>();
  const isFailed = route?.params?.isFailed;
  const isBack = route?.params?.isBack;
  const {mainText, subText, comingFromWallet, numberOfScreenToBack} =
    route?.params ?? {};
  const getMainText = React.useCallback(() => {
    return mainText ? mainText : TEXT.PAYMENT_FAILED;
  }, [mainText]);

  const getSubText = React.useCallback(() => {
    return subText ? subText : TEXT.REGRET;
  }, [subText]);

  return (
    <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
      <ImageBackground style={styles.mainContainer}>
        <BACKGROUND />
        <View style={styles.requestView}>
          {isFailed ? <Failed fill={ColorTheme.backgroundColor} /> : <Done />}
          <Text style={styles.request}>
            {isFailed ? getMainText() : TEXT.PAYMENT_SUCCESS}
          </Text>
          <Text style={styles.wait}>
            {isFailed ? getSubText() : TEXT.CONGRATULATIONS}
          </Text>
          <Text style={styles.wait}>
            {isFailed ? TEXT.APOLOGIZE : TEXT.THANK_YOU}
          </Text>
          <View
            style={[
              BASESTYLE.inRow,
              BASESTYLE.inCenter,
              {marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_34)},
            ]}
          >
            <CustomButton
              buttonLabel={
                comingFromWallet
                  ? TEXT.GO_TO_Wallet
                  : isBack
                  ? TEXT.GO_TO_BACK
                  : isFailed
                  ? TEXT.GO_TO_CART
                  : TEXT.GO_TO_MANTEL
              }
              buttonwidth={styles.button}
              labelStyle={styles.yesButtonLabelstyle}
              onPress={() =>
                comingFromWallet
                  ? popToBack(numberOfScreenToBack ?? 1)
                  : isFailed || isBack
                  ? goBack()
                  : navigate(ROUTE_NAME.MENTAL_TAB)
              }
            />
          </View>
        </View>
      </ImageBackground>
    </View>
  );
};
export default React.memo(PaymentSuccess);
