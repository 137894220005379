import React from 'react';
import {View} from 'react-native';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {DistributingTopRequestStyle} from './distributingRequest.style';
import DistributingTopContent from '../distributingRequest/distributingRequestContent';
import {ChildProfileContext} from '../../../contextAPI/childProfileContext';
import useTradingApprovalHooks from '../../../hooks/componentHooks/useTradingApprovalHooks';
import ConfirmationModal from '../../../modals/confirmationModal/confirmationModal';
import {TEXT} from '../../../constants';
import {ACCEPTED_OR_REJECTED} from '../../../constants/enum';
import {ReportFormState} from '../../../types/reducerTypes/tradeTabTypes';
import {DistributeRequestModel} from '../../../types/componentTypes/childProfileType';

const DistributingTopRequest = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = DistributingTopRequestStyle(ColorTheme);
  const {
    isTradeReceivedApproved,
    showDistributeRequestConfirmModal,
    setShowDistributeRequestConfirmModal,
    selectedTradeReceivedData,
    childUserData,
  } = React.useContext(ChildProfileContext);
  const {childDistributeRequestApproveOrRejectOrReportApi} =
    useTradingApprovalHooks();

  //distribute top request approve reject and report
  const distrbuteTopReport = React.useCallback(
    (reportData: ReportFormState): void => {
      const approveRejectParams: any = {
        distributeRequestId:
          (selectedTradeReceivedData as DistributeRequestModel)?.requestId ?? 0,
        status: reportData?.status,
        minorId: childUserData?.id,
        requestForUnRegisteredUser:
          selectedTradeReceivedData?.requestForUnRegisteredUser,
      };
      childDistributeRequestApproveOrRejectOrReportApi(approveRejectParams);
    },
    [selectedTradeReceivedData, childUserData]
  );
  return (
    <View style={styles.mainContainer}>
      <DistributingTopContent />
      {showDistributeRequestConfirmModal && (
        <ConfirmationModal
          title={
            isTradeReceivedApproved
              ? TEXT.DISTRIBUTE_REQUEST_APPROVE
              : TEXT.DISTRIBUTE_REQUEST_REJECT
          }
          modalVisible={showDistributeRequestConfirmModal}
          onPressYes={() => {
            distrbuteTopReport({
              status: isTradeReceivedApproved
                ? ACCEPTED_OR_REJECTED.ACCEPTED
                : ACCEPTED_OR_REJECTED.REJECTED,
            });
            setShowDistributeRequestConfirmModal();
          }}
          onShowHide={setShowDistributeRequestConfirmModal}
        />
      )}
    </View>
  );
};

export default React.memo(DistributingTopRequest);
