import React from 'react';
import {View} from 'react-native';
import {TEXT} from '../../../constants';
import {SENT_TRADE_APPROVE_OR_REJECT_API} from '../../../constants/actionTypes';
import {
  APPROVED_OR_REJECT,
  FixedValue,
  SENT_OR_RECEIVED,
} from '../../../constants/enum';
import {ChildProfileContext} from '../../../contextAPI/childProfileContext';
import {ThemeContext} from '../../../contextAPI/themeContext';
import useTradeReceviedOrSentHooks from '../../../hooks/componentHooks/useTradeReceviedOrSentHooks';
import useTradingApprovalHooks from '../../../hooks/componentHooks/useTradingApprovalHooks';
import {useAppSelector} from '../../../hooks/reduxHooks';
import ConfirmationModal from '../../../modals/confirmationModal/confirmationModal';
import {childProfileStore} from '../../../redux/reducers/childProfileSlice';
import {TopReceivedStyle} from '../topTradingReceived/topReceived.style';
import TopSentContent from './topSentContent';

const TopTradingSent = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = TopReceivedStyle(ColorTheme);
  const {
    isTradeReceivedApproved,
    selectedTradeReceivedData,
    showTradeSentApproveConfirmModal,
    setShowTradeSentApproveConfirmModal,
  } = React.useContext(ChildProfileContext);
  const {getTradeReceviedOrSentList} = useTradingApprovalHooks();
  const {callTopTradeStatusUpdateAPI} = useTradeReceviedOrSentHooks();
  const {onSentTradeApproveOrRejectData} = useAppSelector(childProfileStore);

  //on child sent trade approve or reject api response handle
  React.useEffect(() => {
    switch (onSentTradeApproveOrRejectData.status) {
      case SENT_TRADE_APPROVE_OR_REJECT_API.SUCCESS:
        getTradeReceviedOrSentList(
          SENT_OR_RECEIVED.SENT,
          FixedValue.CONSTANT_VALUE_0
        );
        break;
      default:
        break;
    }
  }, [onSentTradeApproveOrRejectData.status]);

  const childTradeReceivedAction = React.useCallback((): void => {
    const tradeApproveRejectParams = {
      status: isTradeReceivedApproved
        ? APPROVED_OR_REJECT.APPROVED
        : APPROVED_OR_REJECT.REJECT,
      offerId: selectedTradeReceivedData?.id,
    };
    callTopTradeStatusUpdateAPI(tradeApproveRejectParams);
  }, [selectedTradeReceivedData, isTradeReceivedApproved]);

  return (
    <View style={styles.receivedMainContainer}>
      <TopSentContent />
      {showTradeSentApproveConfirmModal && (
        <ConfirmationModal
          title={
            isTradeReceivedApproved ? TEXT.OFFER_APPROVE : TEXT.OFFER_REJECT
          }
          modalVisible={showTradeSentApproveConfirmModal}
          onPressYes={() => {
            childTradeReceivedAction();
            setShowTradeSentApproveConfirmModal();
          }}
          onShowHide={setShowTradeSentApproveConfirmModal}
        />
      )}
    </View>
  );
};

export default React.memo(TopTradingSent);
