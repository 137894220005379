import React from 'react';
import {ScrollView} from 'react-native';

import {FixedValue} from '../../../constants';
import {EditorContext} from '../../../contextAPI/editorContext';
import {EditorImageContext} from '../../../contextAPI/editorImageContext';
import {UserImageType} from '../../../types/componentTypes/editorType';
import ShowSvgImages from './showSvgImages';
import {styles} from '../editorComp.style';

const RenderImages = (): JSX.Element => {
  const {items, setItems, setSelectedItemIndex} =
    React.useContext(EditorContext);
  const {userImages, markImageAsSelected} =
    React.useContext(EditorImageContext);

  const onSelectImage = React.useCallback(
    (_el: JSX.Element, index: number): void => {
      setItems(_el);
      markImageAsSelected(index);
    },
    [userImages]
  );

  const renderImage = React.useCallback(
    (image: UserImageType, index: number) => (
      <ShowSvgImages
        key={`${image.source?.slice(0, 10)}-${index}`}
        imageIndex={index}
        imageSource={image?.source ?? ''}
        width={image?.width ?? FixedValue.CONSTANT_VALUE_0}
        height={image?.height ?? FixedValue.CONSTANT_VALUE_0}
        elementId={`controls-item-user-image-${index}`}
        newItemIndex={items.length}
        isSelected={image.isSelected}
        addItemCallback={el => onSelectImage(el, index)}
        selectItemCallback={setSelectedItemIndex}
        _isSelected={false}
      />
    ),
    [items, userImages]
  );

  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={styles.scrollViewStyle}
    >
      {userImages?.map((image, _i) => renderImage(image, _i))}
    </ScrollView>
  );
};

export default React.memo(RenderImages);
