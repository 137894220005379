import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  FixedValue,
  Percentage,
  FONT_NAMES,
  COLORS,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const helpAndSupportStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.flex1,
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    extraTopMargin: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    profileSingleBtnView: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: Percentage.PRECENTAGE_100,
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
      borderWidth: FixedValue.CONSTANT_VALUE_1,
      borderColor: COLORS.C2D2ED,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_6),
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_12),
    },
    profileBtnLabelText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme?.color,
    },
    inRow: {
      ...BASESTYLE.inRow,
    },
  });
