import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {IAuthStateType} from '../../types/reducerTypes/authStoreTypes';
import {ResetAllState} from './logoutslice';

const {StoreConstants} = AppConst;
const {USER_LOGIN, CHECK_USERNAME, PARENT_USERNAME, SIGN_UP, REGISTER} =
  ActionType;

const initialState: IAuthStateType = {
  userLogin: {
    isLoading: false,
    success: null,
    failed: null,
    status: USER_LOGIN.END,
  },
  userTokens: {
    CognitoUser: null,
    accessToken: '',
    idToken: '',
    refreshToken: '',
  },
  userName: {
    isLoading: false,
    success: null,
    failed: null,
    status: CHECK_USERNAME.END,
  },
  parentUsername: {
    isLoading: false,
    success: null,
    failed: null,
    status: PARENT_USERNAME.END,
  },
  userSignUp: {
    isLoading: false,
    success: null,
    failed: null,
    status: SIGN_UP.END,
  },
  userRegister: {
    isLoading: false,
    success: null,
    failed: null,
    status: REGISTER.END,
  },
};

const AuthSlice = createSlice({
  initialState,
  name: StoreConstants.AUTH_STORE,
  reducers: {
    onLoginStart: (state, _) => {
      state.userLogin.isLoading = true;
      state.userLogin.status = USER_LOGIN.START;
    },
    onLoginSuccess: (state, action) => {
      state.userLogin.success = action.payload;
      state.userLogin.status = USER_LOGIN.SUCCESS;
    },
    onLoginFailed: (state, action) => {
      state.userLogin.failed = action.payload;
      state.userLogin.status = USER_LOGIN.FAILED;
    },
    onLoginEnd: state => {
      state.userLogin.isLoading = false;
      state.userLogin.status = USER_LOGIN.END;
    },
    getUserTokens: (state, action) => {
      state.userTokens = action.payload;
    },
    onUsernameStart: (state, _) => {
      state.userName.isLoading = true;
      state.userName.status = CHECK_USERNAME.START;
    },
    onUsernameSuccess: (state, action) => {
      state.userName.success = action.payload;
      state.userName.status = CHECK_USERNAME.SUCCESS;
    },
    onUsernameFailed: (state, action) => {
      state.userName.failed = action.payload;
      state.userName.status = CHECK_USERNAME.FAILED;
    },
    onUsernameEnd: state => {
      state.userName.isLoading = false;
      state.userName.status = CHECK_USERNAME.END;
    },
    onParentUsernameStart: (state, _) => {
      state.parentUsername.isLoading = true;
      state.parentUsername.status = PARENT_USERNAME.START;
    },
    onParentUsernameSuccess: (state, action) => {
      state.parentUsername.success = action.payload;
      state.parentUsername.status = PARENT_USERNAME.SUCCESS;
    },
    onParentUsernameFailed: (state, action) => {
      state.parentUsername.failed = action.payload;
      state.parentUsername.status = PARENT_USERNAME.FAILED;
    },
    onParentUsernameEnd: state => {
      state.parentUsername.isLoading = false;
      state.parentUsername.status = PARENT_USERNAME.END;
    },
    onSignUpStart: (state, _) => {
      state.userSignUp.isLoading = true;
      state.userSignUp.status = SIGN_UP.START;
    },
    onSignUpSuccess: (state, action) => {
      state.userSignUp.success = action.payload;
      state.userSignUp.status = SIGN_UP.SUCCESS;
    },
    onSignUpFailed: (state, action) => {
      state.userSignUp.failed = action.payload;
      state.userSignUp.status = SIGN_UP.FAILED;
    },
    onSignUpEnd: state => {
      state.userSignUp.isLoading = false;
      state.userSignUp.status = SIGN_UP.END;
    },
    onRegisterStart: (state, _) => {
      state.userRegister.isLoading = true;
      state.userRegister.status = REGISTER.START;
    },
    onRegisterSuccess: (state, action) => {
      state.userRegister.success = action.payload;
      state.userRegister.status = REGISTER.SUCCESS;
    },
    onRegisterFailed: (state, action) => {
      state.userRegister.failed = action.payload;
      state.userRegister.status = REGISTER.FAILED;
    },
    onRegisterEnd: state => {
      state.userRegister.isLoading = false;
      state.userRegister.status = REGISTER.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  onLoginStart,
  onLoginSuccess,
  onLoginFailed,
  onLoginEnd,
  getUserTokens,
  onUsernameEnd,
  onUsernameFailed,
  onUsernameStart,
  onUsernameSuccess,
  onParentUsernameStart,
  onParentUsernameSuccess,
  onParentUsernameFailed,
  onParentUsernameEnd,
  onSignUpStart,
  onSignUpSuccess,
  onSignUpFailed,
  onSignUpEnd,
  onRegisterStart,
  onRegisterSuccess,
  onRegisterFailed,
  onRegisterEnd,
} = AuthSlice.actions;
export default AuthSlice.reducer;
export const authState = (state: RootState) => state.auth;
