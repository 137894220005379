import React from 'react';
import {View, Text, Pressable} from 'react-native';
import {RenderItemParams} from 'react-native-draggable-flatlist';
import BurgerMenu from '../../renderSvgs/burgerMenu';
import {styles} from './draggableTile.style';
import {Svg} from 'react-native-svg';
import {scaleWidth} from '../../../utils/responsive';
import {FixedValue} from '../../../constants';
import TextIcon from '../../renderSvgs/textIcon';
import {TopEditorItemType} from '../../../types/componentTypes/editorType';

const DraggableTile = ({
  item,
  getIndex,
  drag,
}: RenderItemParams<JSX.Element>): JSX.Element => {
  // @ts-ignore - ref from item
  const itemType = item?.ref?.current?.getTopEditorItemType();
  const index = getIndex();
  return (
    <Pressable onPressIn={drag} style={styles.container}>
      <BurgerMenu />
      <View style={styles.logoView}>
        {itemType === TopEditorItemType.TEXT ? (
          <TextIcon />
        ) : (
          <Svg
            testID={`item-order-${index}`}
            accessibilityLabel={`item-order-${index}`}
            key={`item-order-${index}`}
            height={scaleWidth(FixedValue.CONSTANT_VALUE_24)}
            width={scaleWidth(FixedValue.CONSTANT_VALUE_24)}
          >
            {
              // @ts-ignore used to get item ref
              item.ref.current.getItemOrderTabItem({
                width: scaleWidth(FixedValue.CONSTANT_VALUE_13),
                height: scaleWidth(FixedValue.CONSTANT_VALUE_13),
              })
            }
          </Svg>
        )}
      </View>
      <Text style={styles.itemTypeText}>{itemType}</Text>
    </Pressable>
  );
};

export default React.memo(DraggableTile);
