import React from 'react';
import {Text, View} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {FixedValue, TEXT} from '../../constants';
import {Style} from './style';
import CustomButton from '../button/customButton';
import TICKICON from '../renderSvgs/tickIcon';
import CROSSICON from '../renderSvgs/smallCrossIcon';
import {ChildTradableRequest} from '../../types/reducerTypes/tradeTabTypes';
import {getDaysAgo} from '../../utils/helperFunctions';
import TopCardTile from '../mantelTopTabComp/topCardTile';

interface IShiftingTradeableProps {
  item: ChildTradableRequest;
  onPressTick: () => void;
  onPressCross: () => void;
}

const ShiftingTradeableComponent = (
  props: IShiftingTradeableProps
): JSX.Element => {
  const {item, onPressTick, onPressCross} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = Style(ColorTheme);

  return (
    <View style={styles.mainView}>
      <View
        style={styles.mainViewTextContainer}
      >
        <Text style={styles.headerText}>
          Request to mark a Top as tradable
        </Text>
        <Text style={styles.day}>{getDaysAgo(item.creationDate)}</Text>
      </View>
      <TopCardTile
        key={item.requestId}
        id={item.requestId}
        tradable={false}
        collection={{
          name: '',
          id: FixedValue.CONSTANT_VALUE_0,
          default: false,
        }}
        user={{
          id: FixedValue.CONSTANT_VALUE_0,
          firstName: '',
          lastName: '',
          profileImage: '',
        }}
        isProfileSwitchToChild={false}
        {...item}
        image={item.topImage}
        ismodal
      />
      <View style={styles.buttonView}>
        <CustomButton
          buttonLabel={TEXT.APPROVE}
          buttonwidth={styles.primaryButtonStyle}
          labelStyle={styles.primaryButtonLabelstyle}
          buttonBackGroundColor={styles.noButtonBackGroundColor1}
          element={<TICKICON />}
          onPress={onPressTick}
        />
        <CustomButton
          buttonLabel={TEXT.REJECT}
          buttonwidth={styles.primaryButtonStyle2}
          labelStyle={styles.primaryButtonLabelstyle1}
          buttonBackGroundColor={styles.noButtonBackGroundColor}
          element={<CROSSICON />}
          onPress={onPressCross}
        />
      </View>
      <View style={styles.seperatorView} />
    </View>
  );
};

export default React.memo(ShiftingTradeableComponent);
