import React from 'react';
import {View} from 'react-native';
import PrimaryButton from '../../components/button/primaryButton';
import LabelInput from '../../components/input/labelInput';
import {FixedValue, TEXT} from '../../constants';
import {useAppSelector} from '../../hooks/reduxHooks';
import useWalletHooks from '../../hooks/componentHooks/useWalletHooks';
import {ProfileTabContext} from '../../contextAPI/profileTabContext';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import {style} from './styles';
import {ThemeContext} from '../../contextAPI/themeContext';
import {_InputType} from '../../utils/helperFunctions';

const TopCreditTransferContent = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = style(ColorTheme);
  const {transferTopCreditData, topCreditPullbackData} =
    useAppSelector(userProfileStore);
  const {values, handleBlur, handleChange, handleSubmit, isValid, errors} =
    useWalletHooks();
  const {selectedChild, isUserWantToTransferTopCredit} =
    React.useContext(ProfileTabContext);
  const buttontext = !isUserWantToTransferTopCredit ? TEXT.TRANSFER : TEXT.SENT;
  const {userData} = useAppSelector(userProfileStore);
  const userDetail = userData?.success;

  const textInputTitle = () => {
    const headertext = TEXT.TOP_CREDIT_TO_TRANSFER;
    if (!isUserWantToTransferTopCredit) {
      return `${headertext} ${selectedChild?.firstName} to ${userDetail?.firstName}`;
    } else {
      return `${headertext} ${userDetail?.firstName} to ${selectedChild?.firstName}`;
    }
  };

  return (
    <>
      <View style={styles.marginTop22} />
      <LabelInput
        placeholder={TEXT.EG_10}
        inputLabel={textInputTitle()}
        value={values.topCredit}
        onChangeText={handleChange(TEXT.TOP_CREDIT_)}
        onBlur={handleBlur(TEXT.TOP_CREDIT_)}
        haveError={errors.topCredit}
        maxLength={FixedValue.CONSTANT_VALUE_10}
        keyboardType={_InputType.phoneNumber}
      />
      <PrimaryButton
        isLoading={
          transferTopCreditData?.isLoading || topCreditPullbackData?.isLoading
        }
        buttonLabel={buttontext}
        onPress={handleSubmit}
        isDisabled={!isValid}
      />
    </>
  );
};

export default React.memo(TopCreditTransferContent);
