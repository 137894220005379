import React from 'react';
import {View} from 'react-native';

import SearchTabHeader from '../../../components/header/searchTabHeader';
import SafeAreaContainer from '../../../components/layout/safeAreaContainer';
import ViewContainer from '../../../components/layout/viewContainer';
import {SearchTabProvider} from '../../../contextAPI/SearchTabBarContext';
import {ThemeContext} from '../../../contextAPI/themeContext';
import SearchResultList from './searchResultList';
import {searchTabStyle} from './searchTab.style';

const SearchTab = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = searchTabStyle(ColorTheme);
  return (
    <SearchTabProvider>
      <SafeAreaContainer>
        <ViewContainer>
          <View
            style={styles.mainContainer}
            dataSet={{media: ids.mainContainer}}
          >
            <SearchTabHeader />
            <SearchResultList />
          </View>
        </ViewContainer>
      </SafeAreaContainer>
    </SearchTabProvider>
  );
};

export default SearchTab;
