import React from 'react';
import {View} from 'react-native';
import TitleWithButton from '../../components/header/titleWithButton';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import {TEXT} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {childListstyle} from './childList.style';
import ChildListContent from './childListContent';

const ChildScreen = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = childListstyle(ColorTheme);
  return (
    <SafeAreaContainer>
      <View style={styles.container} dataSet={{media: ids.container}}>
        <TitleWithButton title={TEXT.PARENT_MANAGEMENT} />
        <ChildListContent />
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(ChildScreen);
