import StyleSheet from 'react-native-media-query';
import {
  FixedValue,
  GlobalStyleValues,
  Percentage,
  FONT_NAMES,
  BASESTYLE,
  COLORS,
} from '../../constants';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';
import {IColorTheme} from '../../types/contextTypes/themeContext';

export const tradeStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.selfCenter,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_0),
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_0,
        width: Percentage.PRECENTAGE_100,
      },
    },
    headerView: {
      paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_16),
    },
    selectTopsView: {
      ...BASESTYLE.selfCenter,
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.center,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_9),
      backgroundColor: COLORS._FEFAE6,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_208),
      borderRadius: scaleHeight(FixedValue.CONSTANT_VALUE_16),
      alignSelf: 'center',
    },
    select_your_tops: {
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_9),
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_9),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      color: COLORS.BLACK,
      fontFamily: FONT_NAMES.SF_REGULAR,
    },
    collectionTitleView: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_21),
      ...BASESTYLE.spaceBetween,
      ...BASESTYLE.inRow,
    },
    paddingBottom90: {
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_90),
    },
    paddingLeft10: {
      paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    collectionTitleStyle: {
      color: theme.color,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_BOLD,
    },
    seeAllText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      // lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.PRIMARY_BLUE,
    },
    marginRight20: {
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    paddingHorizontal20: {
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      width: Percentage.PRECENTAGE_70,
    },
    nextButtonView: {
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_21),
      ...BASESTYLE.selfCenter,
      ...BASESTYLE.absolutePosition,
      width: Percentage.PRECENTAGE_100,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    nextButtonStyle: {
      ...BASESTYLE.inRow,
      backgroundColor: 'transparent',
      justifyContent: GlobalStyleValues.SPACE_BETWEEN,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    nextButtonTextStyle: {
      color: COLORS.WHITE,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
    },
    marginTop50: {
      marginTop: scaleWidth(FixedValue.CONSTANT_VALUE_50),
    },
    addNowButtonContainer:{
      width: scaleWidth(FixedValue.CONSTANT_VALUE_155),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_34),
      backgroundColor: COLORS._4EAF51,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_70),
      alignSelf: GlobalStyleValues.CENTER,
      ...BASESTYLE.center,
    },
    addNowButton: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      lineHeight:scaleHeight(FixedValue.CONSTANT_VALUE_19),
      textAlign:GlobalStyleValues.CENTER,
    },
    flexDirectionColumn:{
      flexDirection:GlobalStyleValues.COLUMN    
    },
    quantityTextStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
    plusTextStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
    quantitySelectorViewStyle: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      ...BASESTYLE.spaceBetween,
      alignSelf: GlobalStyleValues.CENTER,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_34),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_155),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_70),
      backgroundColor: COLORS._4EAF51,
    },
    plusButtonStyle: {
      ...BASESTYLE.inCenter,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_24),
      right: FixedValue.CONSTANT_VALUE_5,
      backgroundColor: COLORS.TRANSPARENT,
    },
    minusButtonStyle: {
      ...BASESTYLE.inCenter,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_24),
      backgroundColor: COLORS.TRANSPARENT,
      left: FixedValue.CONSTANT_VALUE_5,
    },
  });
