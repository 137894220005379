import {all} from 'redux-saga/effects';
import AuthSaga from './authSaga';
import verifyOtpSaga from './verifyOtpSaga';
import resetPasswordSaga from './resetPasswordSaga';
import forgotPasswordSaga from './forgotPasswordSaga';
import logoutSaga from './logoutSaga';
import EditorSaga from './editorSaga';
import searchTabSaga from './searchTabSaga';
import collectionSaga from './collectionsSaga';
import userProfileSaga from './userProfileSaga';
import topsSaga from './topsSaga';
import groupManagementSaga from './groupManagementSaga';
import childApproveSaga from './childApproveSaga';
import updateProfileSaga from './updateProfileSaga';
import tradeTabListSaga from './tradeTabSaga';
import childProfileSaga from './childProfileSaga';
import notificationSaga from './notificationSaga';
import trackingListSaga from './trackingListSaga';
import trackingCancelSaga from './trackingCancelSaga';
import trackingDetailSaga from './trackingDetailSaga';
import sendReminderSaga from './sendReminderSaga';
import tutorialListSaga from './tutorialListSaga';
import sendReminderAllSaga from './senndReminderAllSaga';
export default function* rootSaga() {
  yield all([
    AuthSaga(),
    verifyOtpSaga(),
    resetPasswordSaga(),
    forgotPasswordSaga(),
    logoutSaga(),
    EditorSaga(),
    searchTabSaga(),
    collectionSaga(),
    userProfileSaga(),
    topsSaga(),
    groupManagementSaga(),
    childApproveSaga(),
    updateProfileSaga(),
    tradeTabListSaga(),
    childProfileSaga(),
    notificationSaga(),
    trackingListSaga(),
    trackingCancelSaga(),
    trackingDetailSaga(),
    sendReminderSaga(),
    tutorialListSaga(),
    sendReminderAllSaga(),
  ]);
}
