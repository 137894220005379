import {createSlice} from '@reduxjs/toolkit';
import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {VerifyOtpState} from '../../types/reducerTypes/authStoreTypes';
import {ResetAllState} from './logoutslice';

const {StoreConstants} = AppConst;
const {VERIFY_OTP, RESEND_CODE} = ActionType;

const initialState: VerifyOtpState = {
  verifyOtp: {
    isLoading: false,
    success: null,
    failed: null,
    status: VERIFY_OTP.END,
  },
  resendCode: {
    isLoading: false,
    success: null,
    failed: null,
    status: RESEND_CODE.END,
  },
  isLoaderMoving: false,
};

const VerifyOtp = createSlice({
  initialState,
  name: StoreConstants.VERIFY_OTP_STORE,
  reducers: {
    updateLoaderStatus: (state, action) => {
      state.isLoaderMoving = action.payload;
    },
    onVerifyOtpStart: (state, _) => {
      state.verifyOtp.isLoading = true;
      state.verifyOtp.status = VERIFY_OTP.START;
    },
    onVerifyOtpSuccess: (state, action) => {
      state.verifyOtp.success = action.payload;
      state.verifyOtp.status = VERIFY_OTP.SUCCESS;
    },
    onVerifyOtpFailed: (state, action) => {
      state.verifyOtp.failed = action.payload;
      state.verifyOtp.status = VERIFY_OTP.FAILED;
    },
    onVerifyOtpEnd: state => {
      state.verifyOtp.isLoading = false;
      state.verifyOtp.status = VERIFY_OTP.END;
    },
    onResendCodeStart: (state, _) => {
      state.resendCode.isLoading = true;
      state.resendCode.status = RESEND_CODE.START;
    },
    onResendCodeSuccess: (state, action) => {
      state.resendCode.success = action.payload;
      state.resendCode.status = RESEND_CODE.SUCCESS;
    },
    onResendCodeFailed: (state, action) => {
      state.resendCode.failed = action.payload;
      state.resendCode.status = RESEND_CODE.FAILED;
    },
    onResendCodeEnd: state => {
      state.resendCode.isLoading = false;
      state.resendCode.status = RESEND_CODE.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  updateLoaderStatus,
  onVerifyOtpStart,
  onVerifyOtpSuccess,
  onVerifyOtpFailed,
  onVerifyOtpEnd,
  onResendCodeStart,
  onResendCodeSuccess,
  onResendCodeFailed,
  onResendCodeEnd,
} = VerifyOtp.actions;
export default VerifyOtp.reducer;
export const verifyOtpState = (state: RootState) => state.verifyOtp;
