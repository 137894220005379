import StyleSheet from 'react-native-media-query';
import { IColorTheme } from '../../../types/contextTypes/themeContext';
import {
  BASESTYLE,
  COLORS,
  FONT_NAMES,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../../constants';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const DistributingSettingStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    settingMainContainer: {
      ...BASESTYLE.selfCenter,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    mainViewstyle: {
      ...BASESTYLE.inRow,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    textView: {
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.flex1,
    },
    titleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: _theme?.color,
    },
    settingContainer: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_16),
      ...BASESTYLE.flex1,
    },
    selectView: {
      ...BASESTYLE.inCenter,
      backgroundColor: COLORS._FEFAE6,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_27),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_25),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_213),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_28),
      ...BASESTYLE.alignCenter,
      alignSelf: GlobalStyleValues.CENTER,
    },
    oneOption: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
    },
    requestView: {
      ...BASESTYLE.row,
    },
    ellipseView: {
      ...BASESTYLE.row,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_22),
    },
    mustApprove: {
      color: _theme?.color,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
    },
    buttonStyle: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_179),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
    },
    buttonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
    buttonView: {
      ...BASESTYLE.alignCenter,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_20)
    },
    headerStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: _theme?.color,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_20)
    },
    limitView: {
      marginLeft: Percentage.PRECENTAGE_10,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    limitText: {
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_5),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_17),
      color: _theme?.color,
    },
    limitInput: {
      fontFamily: FONT_NAMES.SF_REGULAR,
      borderWidth: FixedValue.CONSTANT_VALUE_1,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_244),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_40),
      borderColor: COLORS.C7C9D9,
      color: _theme?.color,
      paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_16),
    },
    seperatorView: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_1),
      backgroundColor: COLORS.B6BFC9,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    selectViewEachSection: {
      ...BASESTYLE.inCenter,
      backgroundColor: COLORS._FEFAE6,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_27),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_25),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_287),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_28),
      ...BASESTYLE.alignCenter,
      alignSelf: GlobalStyleValues.CENTER,
    },
    allTrade: {
      color: _theme?.color,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      paddingRight: scaleWidth(FixedValue.CONSTANT_VALUE_10)
    },
  });
