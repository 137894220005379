import React from 'react';
import {View, Text, Pressable} from 'react-native';

import {headerStyle} from './header.style';

import CrossCircle from '../../renderSvgs/crossCircle';
import {TEXT} from '../../../constants';
import {goBack} from '../../../services/navigationService';
import {ThemeContext} from '../../../contextAPI/themeContext';

interface IMainHeaderProps {
  onPress?: () => void;
};

const MainHeader = (props: IMainHeaderProps): JSX.Element => {
  const {onPress} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = headerStyle(ColorTheme);

  return (
    <View style={styles.container}>
      <>
        <Pressable onPress={onPress ?? goBack}>
          <CrossCircle stroke={ColorTheme.color} />
        </Pressable>
        <Text style={styles.titleText}>{TEXT.CREATE_TRADE_OFFER}</Text>
      </>
    </View>
  );
};

export default React.memo(MainHeader);
