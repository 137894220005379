import React from 'react';

import {FixedValue, ROUTE_NAME} from '../../constants';
import {TopType} from '../../constants/enum';
import { HomeContext } from '../../contextAPI/homeContext';
import {SearchTabContext} from '../../contextAPI/SearchTabBarContext';
import {
  onRecentSearchListStart,
  onSearchListStart,
  onUserSearchListStart,
  searchTabStore,
} from '../../redux/reducers/searchTabSlice';
import {navigate} from '../../services/navigationService';
import {TopModel} from '../../types/reducerTypes/searchTabStoreType';
import {useAppDispatch, useAppSelector} from '../reduxHooks';

const useSearchTabHooks = () => {
  const dispatch = useAppDispatch();
  const {searchTopList, userUserSearchList} = useAppSelector(searchTabStore);
  const {setSearchText, searchText, clearSearchResult} =
    React.useContext(SearchTabContext);
  const {setSelectedTopData, setSelectedFromCollectionData} =
    React.useContext(HomeContext);

  const callSearchListAPI = React.useCallback(
    (keyword: string, page: number): void => {
      const params = {keyword, page, pageSize: FixedValue.CONSTANT_VALUE_15};
      dispatch(onSearchListStart(params));
    },
    [searchText]
  );

  const callRecentSearchAPI = React.useCallback((): void => {
    dispatch(onRecentSearchListStart());
  }, []);

  const callUserSearchListAPI = React.useCallback(
    (keyword: string, page: number): void => {
      const params = {keyword, page, size: FixedValue.CONSTANT_VALUE_15};
      dispatch(onUserSearchListStart(params));
    },
    [searchText]
  );

  const onChangeSearchText = React.useCallback((value: string): void => {
    setSearchText(value);
    if (value.length > FixedValue.CONSTANT_VALUE_2) {
      callSearchListAPI(value, FixedValue.CONSTANT_VALUE_0);
      callUserSearchListAPI(value, FixedValue.CONSTANT_VALUE_0);
    }
    if (value.length === FixedValue.CONSTANT_VALUE_0) {
      callSearchListAPI('', FixedValue.CONSTANT_VALUE_0);
      callUserSearchListAPI('', FixedValue.CONSTANT_VALUE_0);
    }
  }, []);

  const onReachedEnd = React.useCallback((): void => {
    if (
      searchTopList.success != null &&
      searchTopList.success.pageNumber < searchTopList.success.totalPages
    ) {
      callSearchListAPI(searchText, searchTopList.success.pageNumber + 1);
    }
  }, [searchTopList, searchText]);

  const onUserSearchListReachedEnd = React.useCallback((): void => {
    if (
      userUserSearchList.success != null &&
      userUserSearchList.success.pageNumber <
        userUserSearchList.success.totalPages
    ) {
      callUserSearchListAPI(
        searchText,
        userUserSearchList.success.pageNumber + FixedValue.CONSTANT_VALUE_1
      );
    }
  }, [userUserSearchList, searchText]);

  const navigateToDetailScreen = React.useCallback(
    (topDetails: TopModel, comingFrom: TopType): void => {
      setSelectedFromCollectionData(topDetails?.collection ?? null);
      setSelectedTopData(topDetails);
      navigate(ROUTE_NAME.TOP_DETAIL_SCREEN, {topDetails, comingFrom});
    },
    []
  );

  return {
    onChangeSearchText,
    onReachedEnd,
    navigateToDetailScreen,
    callRecentSearchAPI,
    onUserSearchListReachedEnd,
    callUserSearchListAPI
  };
};

export default useSearchTabHooks;
