import * as React from 'react';
import Svg, {Rect, G, Path, Defs, ClipPath, SvgProps} from 'react-native-svg';
import {ThemeContext} from '../../contextAPI/themeContext';

const EditPicImage = (props: SvgProps) => {
  const {ColorTheme} = React.useContext(ThemeContext);

  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      // @ts-ignore
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Rect width={24} height={24} rx={12} fill={ColorTheme.backgroundColor} />
      <G clipPath="url(#clip0_774_8306)">
        <Path
          d="M14.9167 6.74985C15.0699 6.59664 15.2518 6.47511 15.4519 6.39219C15.6521 6.30928 15.8667 6.2666 16.0833 6.2666C16.3 6.2666 16.5145 6.30928 16.7147 6.39219C16.9149 6.47511 17.0968 6.59664 17.25 6.74985C17.4032 6.90306 17.5247 7.08494 17.6076 7.28512C17.6906 7.4853 17.7332 7.69985 17.7332 7.91652C17.7332 8.13319 17.6906 8.34774 17.6076 8.54791C17.5247 8.74809 17.4032 8.92998 17.25 9.08318L9.37499 16.9582L6.16666 17.8332L7.04166 14.6249L14.9167 6.74985Z"
          stroke={ColorTheme.color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_774_8306">
          <Rect
            width={14}
            height={14}
            fill={ColorTheme.backgroundColor}
            transform="translate(5 5)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
export default EditPicImage;
