import {takeLatest, put, call, delay, select} from 'redux-saga/effects';
import {AppConst, TEXT} from '../../constants';
import {sendReminderAll} from '../../utils/apis';
import {_ToastHandler} from '../../utils/helperFunctions';
import {isWebsite} from '../../utils/responsive';
import {authState} from '../reducers/authSlice';
import {
  ISendReminderResponse,
} from '../../types/reducerTypes/tradeTabTypes';
import {IActionType} from '../../types/constantFilesTypes';
import {
  onSendReminderAllEnd,
  onSendReminderAllFailed,
  onSendReminderAllSuccess,
} from '../reducers/sendReminderAllSlice';
import { Alert } from 'react-native';

const {StoreConstants} = AppConst;

function* sendReminderAllContact(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);    
    const data: ISendReminderResponse = yield call(
      sendReminderAll,
      action.payload,
      userTokens
    );
    alert(TEXT.REMIND_ALL_SENT);
    yield put(onSendReminderAllSuccess({}));
  } catch (error: any) {
    alert(TEXT.ERROR_ALERT);
    yield put(onSendReminderAllFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onSendReminderAllEnd());
  }
}

export default function* sendReminderAllSaga() {
  yield takeLatest(
    `${StoreConstants.SEND_REMINDER_ALL}/onSendReminderAllStart`,
    sendReminderAllContact
  );
}
