import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const myGroupListStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    listMainView: {
      ...BASESTYLE.alignCenter,
      backgroundColor: _theme.backgroundColor,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
      },
      height: Percentage.PRECENTAGE_100,
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    emptyView: {
      marginHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_28),
    },
    scrollviewStyle: {
      ...BASESTYLE.flex1,
      justifyContent: GlobalStyleValues.CENTER,
    },
    buttonStyle: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_47),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      backgroundColor: COLORS.TRANSPARENT,
      width: !isWebsite()
      ? undefined
      : scaleHeight(FixedValue.CONSTANT_VALUE_179),
    paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_14),
    },
    buttonStyle2: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.absolutePosition,
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_21),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: !isWebsite()
        ? undefined
        : scaleHeight(FixedValue.CONSTANT_VALUE_179),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_47),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      backgroundColor: COLORS.TRANSPARENT,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_14),
    },
    buttonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
    },
    flatListContentContainerStyle: {
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_12),
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_70),
    },
    mainList: {
      width: Percentage.PRECENTAGE_100,
    }
  });
