import React from 'react';
import {View, Text, Pressable, Image} from 'react-native';

import {BASESTYLE} from '../../../constants';
import {ICountryTileProps} from '../../../contextAPI/signUpContext';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {styles} from './styles';

const CountryCode = (props: ICountryTileProps): JSX.Element => {
  const {onPress, country} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  return (
    <Pressable style={style.container} onPress={() => onPress(country)}>
      <View style={BASESTYLE.inRow}>
        <Image
          source={{
            uri: `https://flagcdn.com/28x21/${country.code.toLowerCase()}.png`,
          }}
          style={style.flagStyle}
        />
        <Text style={[style.textStyle, style.middleText]}>
          {'  '}
          {country.code}
          {'  '}
        </Text>
        <Text style={style.textStyle}>
          {country.name}
          {'  '}
        </Text>
      </View>
    </Pressable>
  );
};

export default React.memo(CountryCode);
