import {StyleSheet} from 'react-native';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
  SCREEN_WIDTH,
} from '../../utils/responsive';

export const childListComponentStyles = (theme?: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      ...BASESTYLE.flex1,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : SCREEN_WIDTH,
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    childListView: {
      marginLeft: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      marginRight: scaleHeight(FixedValue.CONSTANT_VALUE_25),
    },
    childListStyle: {
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      width: Percentage.PRECENTAGE_100,
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
    },
    childNameText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      color: theme?.color,
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    badgeStyle: {
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_9),
    },
    imageView: {
      ...BASESTYLE.inCenter,
      height: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      overflow: GlobalStyleValues.HIDDEN,
    },
    backgroundColorPlaceholder: {
      ...BASESTYLE.inCenter,
      height: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      overflow: GlobalStyleValues.HIDDEN,
      backgroundColor: theme?.color,
    },
    profileNameText: {
      textAlign: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      color: theme?.backgroundColor,
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    imageLoader: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.center,
      ...BASESTYLE.alignCenter,
      overflow: GlobalStyleValues.HIDDEN,
      backgroundColor: COLORS.E9EEFB,
    },
    flexDirectionRow: {
      ...BASESTYLE.inRow,
    },
  });
