import React, {useCallback, useContext, useEffect} from 'react';
import {Text, View, Keyboard, ActivityIndicator} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {TEXT, TOAST_TEXT} from '../../constants/textMessage';
import {
  _ToastHandler,
  checkTopCreditGreaterThanOne,
  convertToIntegerForSmallValues,
} from '../../utils/helperFunctions';
import ApplyCouponCodeFormModal from '../../modals/applyCouponModal';
import CustomButton from '../../components/button/customButton';
import CouponAppliedButton from '../../components/button/couponAppliedButton';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import DeleteWithBackground from '../../components/renderSvgs/deleteWithBackground';
import COUPONGREENTICK from '../../components/renderSvgs/couponGreenTick';
import {HomeContext} from '../../contextAPI/homeContext';
import {allTopsStyle} from '../seeAllTops/seeAll.styles';
import {COLORS, FixedValue, ROUTE_NAME} from '../../constants';
import {goBack, navigate, push} from '../../services/navigationService';
import WalletImage from '../../components/renderSvgs/walletImage';
import {
  onUserDataStart,
  userProfileStore,
} from '../../redux/reducers/userProfileSlice';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import PrimaryButton from '../../components/button/primaryButton';
import {
  BUYING_REQUEST_TO_PARENT,
  CART_CHECKOUT,
  USER_PROFILE_DATA,
} from '../../constants/actionTypes';
import {CHILD_PERMISSION, GlobalStyleValues} from '../../constants/enum';
import useInProgressTopsHooks from '../../hooks/componentHooks/useInProgressTopsHooks';
import {HomeStore} from '../../redux/reducers/homeSlice';
import {activateInCollectionTab} from '../../redux/reducers/collectionSlice';
import {useIsFocused} from '@react-navigation/native';
import PayPalModal from '../../modals/paypalModal';

const OrderSummaryContent = (): JSX.Element => {
  const {ColorTheme} = useContext(ThemeContext);
  const {styles} = allTopsStyle(ColorTheme);
  const dispatch = useAppDispatch();
  const {
    setApplyCouponCodeData,
    discountPrice,
    setDiscountPrice,
    totalTopsAmount,
    applyCouponCodeData,
    selectedTopsResult,
    clearSelectedTopsResult,
    setIsPaymentFromCash,
  } = useContext(HomeContext);
  const isFocused = useIsFocused();
  const {userData} = useAppSelector(userProfileStore);
  const [showApplyCouponCode, setShowApplyCouponCode] =
    React.useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState(false);
  const {callCartTopAPI, callBuyingRequestToParent} = useInProgressTopsHooks();
  const [showLoader, setShowLoader] = React.useState(false);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const {cartCheckout, sendBuyingRequestToParent, topAmountAndReportAbuseData} =
    useAppSelector(HomeStore);
  const amountAfterDiscount = totalTopsAmount - discountPrice;
  const buyNowAmountRoundOff =
    convertToIntegerForSmallValues(amountAfterDiscount);
  const showCouponFunctionlity =
    topAmountAndReportAbuseData?.success?.data?.showCouponCode ?? true;
  const totalQuantity = selectedTopsResult?.reduce(
    (total, top) =>
      total + (top?.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0),
    FixedValue.CONSTANT_VALUE_0
  );
  useEffect(() => {
    if (isFocused) {
      setShowConfirmationModal(false);
      setIsPaymentFromCash(false);
    }
  }, [isFocused]);
  const navigateToMyWallet = useCallback((): void => {
    push(ROUTE_NAME.MY_WALLET_BALANCE, {
      numberOfScreenToBack: FixedValue.CONSTANT_VALUE_2,
    });
  }, []);

  React.useEffect(() => {
    setLoading(cartCheckout.isLoading);
  }, [cartCheckout.isLoading]);

  React.useEffect(() => {
    switch (sendBuyingRequestToParent.status) {
      case BUYING_REQUEST_TO_PARENT.SUCCESS:
        _ToastHandler(TOAST_TEXT.PURCHASED_REQUEST_SENT, true);
        clearSelectedTopsResult();
        setApplyCouponCodeData(null);
        setDiscountPrice(FixedValue.CONSTANT_VALUE_0);
        goBack();
        break;
      case BUYING_REQUEST_TO_PARENT.FAILED:
        break;
      default:
        break;
    }
  }, [sendBuyingRequestToParent.status]);

  React.useEffect(() => {
    switch (cartCheckout.status) {
      case CART_CHECKOUT.SUCCESS:
        dispatch(onUserDataStart());
        dispatch(activateInCollectionTab(true));
        navigate(ROUTE_NAME.PAYMENT_SUCCESS);
        setTimeout(() => {
          clearSelectedTopsResult();
        }, 1200);
        setApplyCouponCodeData(null);
        setDiscountPrice(FixedValue.CONSTANT_VALUE_0);
        break;
      default:
        break;
    }
  }, [cartCheckout.status]);

  React.useEffect(() => {
    if (isFocused) {
      switch (userData.status) {
        case USER_PROFILE_DATA.SUCCESS:
          if (
            userData?.success?.permissionsStatus?.purchasePermission?.status ===
            CHILD_PERMISSION.NOT_ALLOWED
          ) {
            callBuyingRequestToParent();
          } else {
            callPaymentGateWay();
          }
          break;
        case USER_PROFILE_DATA.FAILED:
          break;
        default:
          break;
      }
    }
  }, [userData.status]);

  // check if child doesnt have permission send request to parent for buying the top
  const checkPermissionForBuying = () => {
    dispatch(onUserDataStart());
  };

  const callPaymentGateWay = () => {
    if (buyNowAmountRoundOff > FixedValue.CONSTANT_VALUE_0) {
      if (
        (userData?.success?.walletAmount ?? FixedValue.CONSTANT_VALUE_0) >=
        buyNowAmountRoundOff
      ) {
        callCartTopAPI();
      } else {
        setShowLoader(true);
      }
    } else {
      callCartTopAPI();
    }
  };

  return (
    <>
      <View style={styles.walletContainer}>
        <WalletImage
          height={FixedValue.CONSTANT_VALUE_48}
          width={FixedValue.CONSTANT_VALUE_48}
          color={ColorTheme.color}
        />
        <View style={styles.content}>
          <Text style={styles.myWalletText}>{TEXT.WALLET_BALANCE}</Text>
          <Text style={styles.topCreditText}>
            {userData?.success?.walletAmount}
            <Text style={styles.topcreditsTextStyle}>
              {(userData?.success?.walletAmount ??
                FixedValue.CONSTANT_VALUE_0) > FixedValue.CONSTANT_VALUE_1
                ? TEXT.TOPCREDITS
                : TEXT.TOPCREDIT}
            </Text>
          </Text>
        </View>
        <CustomButton
          buttonLabel={TEXT.ADD_TOPCREDIT}
          buttonwidth={styles.addTopCreditButton}
          labelStyle={styles.addTopCreditTextStyle}
          onPress={navigateToMyWallet}
          buttonBackGroundColor={styles.addTopCreditBackgroundColor}
        />
      </View>
      {showCouponFunctionlity && (
        <View style={styles.couponButtonView}>
          {applyCouponCodeData ? (
            <CouponAppliedButton
              buttonLabel={`You saved ${checkTopCreditGreaterThanOne(
                discountPrice
              )} with this code`}
              element={
                <View style={styles.deleteBinView}>
                  <DeleteWithBackground
                    width={scaleWidth(FixedValue.CONSTANT_VALUE_18)}
                    height={scaleHeight(FixedValue.CONSTANT_VALUE_18)}
                  />
                </View>
              }
              startElement={<COUPONGREENTICK />}
              buttonwidth={styles.appliedCouponButton}
              labelStyle={styles.appliedCouponTextStyle}
              secondLableText={styles.appliedCouponSecondTextStyle}
              onPress={() => {
                setApplyCouponCodeData(null);
                Keyboard.dismiss();
                setDiscountPrice(FixedValue.CONSTANT_VALUE_0);
              }}
              secondLabel={TEXT.COUPON_APPLIED}
              buttonBackGroundColor={styles.appliedCouponBackgroundColor}
            />
          ) : (
            <CustomButton
              buttonLabel={TEXT.APPLY_COUPON}
              buttonwidth={styles.applyCouponButton}
              labelStyle={styles.applyCouponTextStyle}
              onPress={() => {
                setShowApplyCouponCode(true);
                Keyboard.dismiss();
              }}
              buttonBackGroundColor={styles.applyCouponBackgroundColor}
            />
          )}
        </View>
      )}
      <View style={styles.summaryContainer}>
        <Text style={styles.commonText}>{TEXT.TOTAL_ORDER}</Text>
        <Text style={styles.commonText2}>
          {totalQuantity}
          {TEXT.TOPS_USING}
          {checkTopCreditGreaterThanOne(totalTopsAmount)}
        </Text>
        {showCouponFunctionlity && (
          <>
            <View style={styles.paddingTop9}>
              <Text style={styles.commonText}>{TEXT.COUPON_DISCOUNT}</Text>
              <Text style={styles.commonText2}>
                {checkTopCreditGreaterThanOne(discountPrice)}
              </Text>
            </View>
            <View style={styles.paddingTop9}>
              <View style={styles.underLine} />
              <Text style={styles.grandTotalText}>{TEXT.GRAND_TOTAL}</Text>
              <Text style={styles.commonText2}>
                {totalQuantity}
                {TEXT.TOPS_USING}
                {checkTopCreditGreaterThanOne(buyNowAmountRoundOff)}
              </Text>
            </View>
          </>
        )}
        <View style={styles.buyButtonView} />
      </View>
      <View style={styles.buyButtonView}>
        <PrimaryButton
          buttonLabel={`Buy With ${checkTopCreditGreaterThanOne(
            buyNowAmountRoundOff
          )}`}
          buttonwidth={styles.butTopButton}
          labelStyle={styles.nextButtonTextStyle}
          isDisabled={
            buyNowAmountRoundOff >
            (userData?.success?.walletAmount ?? FixedValue.CONSTANT_VALUE_0)
          }
          onPress={checkPermissionForBuying}
          isLoading={isLoading}
        />
      </View>
      {showApplyCouponCode && (
        <ApplyCouponCodeFormModal
          visble={showApplyCouponCode}
          onClose={setShowApplyCouponCode}
        />
      )}
      {showLoader && (
        <View style={styles.loaderStyle}>
          <ActivityIndicator
            size={GlobalStyleValues.LARGE}
            color={COLORS.PRIMARY_BLUE}
          />
        </View>
      )}
      {!!showLoader && (
        <PayPalModal
          totalTopsAmount={buyNowAmountRoundOff}
          modalVisible={true}
          onClose={() => {
            setShowConfirmationModal(false);
          }}
          onSuccessPayment={() => callCartTopAPI()}
          onCancelPayment={() =>
            navigate(ROUTE_NAME.PAYMENT_SUCCESS, {
              isFailed: true,
              comingFromWallet: true,
              mainText: TEXT.TRANSACTION_CANCELED,
              subText: TEXT.WE_REGET_TO_INFORM_YOU_THAT,
            })
          }
        />
      )}
    </>
  );
};

export default React.memo(OrderSummaryContent);
