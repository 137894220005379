import React from 'react';
import {View} from 'react-native';
import {FixedValue, TEXT} from '../../../constants';

import {TEXT_SVG_FONTS} from '../../../constants/fonts';
import {EditorContext} from '../../../contextAPI/editorContext';
import useEditorText from '../../../hooks/componentHooks/useEditorTextHooks';
import ChangeFontModal from '../../../modals/changeFontModal';
import TextControlItem from '../../editorComp/texts/textControlItem';
import ColorPelette from '../../smallDesigns/colorPelette';
import RotationController from '../../smallDesigns/rotationController';
import SizeController from '../../smallDesigns/sizeController';
import {styles} from './editorFooter.style';

const TextFooter = (): JSX.Element => {
  const {selectedItemIndex, items, setSelectedItemIndex} =
    React.useContext(EditorContext);
  const {
    showPaletteController,
    showScaleController,
    showRotationController,
    sizeResult,
    rotationResult,
    selectedFont,
    onChangeSizeValue,
    onChangeRotationValue,
    addNewText,
    onChangeFillColor,
    onChangeStrokeColor,
  } = useEditorText();

  const selectedTextFont =
    selectedFont !== '' ? selectedFont : TEXT_SVG_FONTS[0];

  const RenderTextFooter = React.useMemo((): JSX.Element => {
    if (showScaleController || showRotationController) {
      return (
        <View style={styles.shapefooter}>
          {showScaleController && (
            <SizeController
              key={selectedItemIndex}
              onChangeValue={onChangeSizeValue}
              result={sizeResult}
              minValue={FixedValue.CONSTANT_VALUE_0075}
              maxValue={FixedValue.CONSTANT_VALUE_2}
            />
          )}
          {showRotationController && (
            <RotationController
              key={selectedItemIndex}
              onChangeValue={onChangeRotationValue}
              result={rotationResult}
            />
          )}
        </View>
      );
    } else if (showPaletteController) {
      return (
        <>
          <ColorPelette
            key={selectedItemIndex}
            onChangeFillColor={onChangeFillColor}
            onChangeStrokeColor={onChangeStrokeColor}
            upperRowText={TEXT.TEXT_COLOR}
            lowerRowText={TEXT.OUTLINE_COLOR}
          />
          <View style={styles.extraHeight} />
        </>
      );
    }
    return <View style={styles.bottomView} />;
  }, [selectedItemIndex, sizeResult, rotationResult, showPaletteController]);

  return (
    <>
      {RenderTextFooter}
      <TextControlItem
        elementId={`text-controls-item-${items.length}`}
        newItemIndex={items.length}
        addItemCallback={addNewText}
        selectItemCallback={setSelectedItemIndex}
        font={selectedTextFont}
      />
      <ChangeFontModal />
    </>
  );
};

export default TextFooter;
