import React from 'react';
import GetControlShapes from '../../components/editorComp/shapes/getControlShapes';
import {FixedValue} from '../../constants';
import {controllerName} from '../../constants/enum';
import {EditorContext} from '../../contextAPI/editorContext';
import {EditorShapeContext} from '../../contextAPI/editorShapeContext';
import {
  ShapeItemName,
  TopEditorItemType,
} from '../../types/componentTypes/editorType';
import {numberToArray} from '../../utils/helperFunctions';

const useEditorShape = () => {
  const {selectedItemIndex, items, setItems, setSelectedItemIndex} =
    React.useContext(EditorContext);
  const {
    selectedController,
    onSelectShape,
    setOnSelectShape,
    setControlsItems,
  } = React.useContext(EditorShapeContext);
  const isNotMinusOne: boolean =
    selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1;
  const showScaleController: boolean =
    isNotMinusOne && selectedController === controllerName.SCALE;
  const showRotationController: boolean =
    isNotMinusOne && selectedController === controllerName.ROTATION;
  const showPaletteController: boolean =
    isNotMinusOne && selectedController === controllerName.COLOR_PELETTE;
  const showStrokeWidthController: boolean =
    isNotMinusOne && selectedController === controllerName.STROKE_WIDTH;
  const sizeResult: number[] = numberToArray(
    onSelectShape?.current?.getPosition()?.scale
  );
  const rotationResult: number[] = numberToArray(
    onSelectShape?.current?.getPosition()?.rotation
  );

  const onChangeSizeValue = React.useCallback(
    (val: number[]): void => {
      onSelectShape?.current?.changeScale(val[FixedValue.CONSTANT_VALUE_0]);
    },
    [onSelectShape]
  );

  const onChangeRotationValue = React.useCallback(
    (val: number[]): void => {
      onSelectShape?.current?.changeRotation(val[FixedValue.CONSTANT_VALUE_0]);
    },
    [onSelectShape]
  );

  const onChangeFillColor = React.useCallback(
    (color: string): void => {
      if (selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1) {
        // @ts-ignore
        items[selectedItemIndex]?.ref?.current?.changeFillColor(color);
      }
    },
    [selectedItemIndex, items]
  );

  const onChangeStrokeColor = React.useCallback(
    (color: string): void => {
      if (selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1) {
        const selectedItem = items[selectedItemIndex];
        const strokeWidthResult: number =
          // @ts-ignore
          selectedItem?.ref?.current?.getPosition()?.strokeWidth ??
          FixedValue.CONSTANT_VALUE_1;
        // @ts-ignore
        selectedItem?.ref?.current?.changeStroke(color, strokeWidthResult);
      }
    },
    [selectedItemIndex, items]
  );

  const checkConndition = React.useCallback(
    (itemType: ShapeItemName): boolean => {
      let isSelected: boolean = false;
      items.forEach((value: JSX.Element) => {
        const item = // @ts-ignore -- used to get item ref
          value?.ref as NonNullRefObject<ShapeRefType>;
        if (
          item?.current?.getTopEditorItemType() === TopEditorItemType.SHAPE &&
          item?.current?.getName() === itemType
        ) {
          isSelected = true;
        }
      });
      return isSelected;
    },
    [items]
  );

  const isShapeSelected = React.useCallback(
    (itemType: ShapeItemName): boolean => {
      switch (itemType) {
        case 'Circle':
          return checkConndition('Circle');
        case 'Rectangle':
          return checkConndition('Rectangle');
        case 'Square':
          return checkConndition('Square');
        default:
          return false;
      }
    },
    [items]
  );

  React.useEffect((): void => {
    if (selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1) {
      const item = // @ts-ignore -- used to get item ref
        items[selectedItemIndex]?.ref as NonNullRefObject<ShapeRefType>;
      if (item?.current?.getTopEditorItemType() === TopEditorItemType.SHAPE) {
        setOnSelectShape(item);
      }
    } else {
      setOnSelectShape(null);
    }
  }, [selectedItemIndex, items]);

  React.useEffect((): void => {
    setControlsItems(
      GetControlShapes(
        items.length,
        setItems,
        setSelectedItemIndex,
        isShapeSelected('Square'),
        isShapeSelected('Rectangle'),
        isShapeSelected('Circle')
      )
    );
  }, [items]);

  return {
    showPaletteController,
    showRotationController,
    showScaleController,
    showStrokeWidthController,
    sizeResult,
    rotationResult,
    isShapeSelected,
    onChangeSizeValue,
    onChangeRotationValue,
    onChangeFillColor,
    onChangeStrokeColor,
  };
};

export default useEditorShape;
