import React from 'react';
import {Pressable, StyleSheet, Text} from 'react-native';
import {View} from 'native-base';

import {BASESTYLE, COLORS, FixedValue, FONT_NAMES} from '../../constants';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';
import PlusIcon from '../renderSvgs/plusIcon';
import CartIcon from '../renderSvgs/cartIcon';
import {HomeContext} from '../../contextAPI/homeContext';

const styles = StyleSheet.create({
  container: {
    ...BASESTYLE.absolutePosition,
    bottom: scaleHeight(FixedValue.CONSTANT_VALUE_94),
    right: scaleWidth(FixedValue.CONSTANT_VALUE_65),
  },
  viewStyle: {
    ...BASESTYLE.inCenter,
    width: scaleWidth(FixedValue.CONSTANT_VALUE_58),
    height: scaleWidth(FixedValue.CONSTANT_VALUE_58),
    backgroundColor: COLORS.PRIMARY_BLUE,
    borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_29),
    shadowColor: COLORS.PRIMARY_BLUE,
    shadowOpacity: FixedValue.CONSTANT_VALUE_03,
    shadowOffset: {
      width: FixedValue.CONSTANT_VALUE_5,
      height: FixedValue.CONSTANT_VALUE_5,
    },
    elevation: FixedValue.CONSTANT_VALUE_5,
  },
  countView: {
    ...BASESTYLE.inCenter,
    ...BASESTYLE.absolutePosition,
    top: scaleHeight(FixedValue.CONSTANT_VALUE_0),
    right: scaleWidth(FixedValue.CONSTANT_VALUE_0),
    zIndex: FixedValue.CONSTANT_VALUE_20,
    backgroundColor: COLORS._FF0000,
    width: scaleHeight(FixedValue.CONSTANT_VALUE_21),
    height: scaleHeight(FixedValue.CONSTANT_VALUE_21),
    borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_68),
  },
  countText: {
    color: COLORS.WHITE,
    fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
    fontFamily: FONT_NAMES.SF_SEMIBOLD,
  },
});

interface ICreateTopButtonProps {
  onPress: () => void;
  tabIndex?: number;
}

const CreateTopButton = (props: ICreateTopButtonProps) => {
  const {onPress, tabIndex} = props;
  const {selectedTopsResult} = React.useContext(HomeContext);

  return (
    <Pressable style={styles.container} onPress={onPress}>
      <View style={styles.viewStyle} shadow={FixedValue.CONSTANT_VALUE_3}>
        {tabIndex === FixedValue.CONSTANT_VALUE_1 ? (
          <CartIcon stroke={COLORS.WHITE} />
        ) : (
          <PlusIcon stroke={COLORS.WHITE} />
        )}
        {tabIndex === FixedValue.CONSTANT_VALUE_1 &&
          selectedTopsResult.length > FixedValue.CONSTANT_VALUE_0 && (
            <View style={styles.countView}>
              <Text style={styles.countText}>{selectedTopsResult.length}</Text>
            </View>
          )}
      </View>
    </Pressable>
  );
};

export default React.memo(CreateTopButton);
