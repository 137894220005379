import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
const ArrowUp2 = (props: SvgProps) => (
  <Svg
    width={14}
    height={8}
    viewBox="0 0 14 8"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M1 7L7 1L13 7"
      stroke={props?.color ?? 'black'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default ArrowUp2;
