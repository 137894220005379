import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';
const OpenEye = (props: SvgProps) => (
  <Svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M18.2667 9.66671C16.5834 5.75837 13.4167 3.33337 10.0001 3.33337C6.58339 3.33337 3.41672 5.75837 1.73339 9.66671C1.6875 9.77185 1.66382 9.88533 1.66382 10C1.66382 10.1148 1.6875 10.2282 1.73339 10.3334C3.41672 14.2417 6.58339 16.6667 10.0001 16.6667C13.4167 16.6667 16.5834 14.2417 18.2667 10.3334C18.3126 10.2282 18.3363 10.1148 18.3363 10C18.3363 9.88533 18.3126 9.77185 18.2667 9.66671ZM10.0001 15C7.35839 15 4.85839 13.0917 3.41672 10C4.85839 6.90837 7.35839 5.00004 10.0001 5.00004C12.6417 5.00004 15.1417 6.90837 16.5834 10C15.1417 13.0917 12.6417 15 10.0001 15ZM10.0001 6.66671C9.34078 6.66671 8.69632 6.8622 8.14816 7.22848C7.59999 7.59475 7.17275 8.11534 6.92046 8.72443C6.66816 9.33352 6.60215 10.0037 6.73077 10.6503C6.85939 11.2969 7.17686 11.8909 7.64303 12.3571C8.10921 12.8232 8.70315 13.1407 9.34975 13.2693C9.99636 13.3979 10.6666 13.3319 11.2757 13.0796C11.8848 12.8273 12.4053 12.4001 12.7716 11.8519C13.1379 11.3038 13.3334 10.6593 13.3334 10C13.3334 9.11599 12.9822 8.26814 12.3571 7.64302C11.732 7.0179 10.8841 6.66671 10.0001 6.66671ZM10.0001 11.6667C9.67042 11.6667 9.34819 11.569 9.07411 11.3858C8.80002 11.2027 8.5864 10.9424 8.46026 10.6378C8.33411 10.3333 8.3011 9.99819 8.36541 9.67489C8.42972 9.35159 8.58846 9.05462 8.82154 8.82153C9.05463 8.58844 9.3516 8.42971 9.6749 8.3654C9.99821 8.30109 10.3333 8.3341 10.6379 8.46024C10.9424 8.58639 11.2027 8.80001 11.3858 9.07409C11.569 9.34817 11.6667 9.6704 11.6667 10C11.6667 10.4421 11.4911 10.866 11.1786 11.1786C10.866 11.4911 10.4421 11.6667 10.0001 11.6667Z"
      fill="#8F90A6"
    />
  </Svg>
);
export default OpenEye;
