import React from 'react';
import {View, Image, Text, TouchableOpacity, Pressable} from 'react-native';
import RightArrowBlue from '../../components/renderSvgs/RightArrowBlue';
import {TEXT, FONT_NAMES, IMAGES} from '../../constants';
import {trackingTabDetailStyle} from './trackingDetail.style';
import {ThemeContext} from '../../contextAPI/themeContext';
import {getInitials, getDaysAgo} from '../../utils/helperFunctions';
import CloseRed from '../../components/renderSvgs/closeRed';
import {REQUEST_TYPE} from '../../constants/textMessage';
import useAwsS3 from '../../hooks/libraryHooks/useAwsS3';
import FavoriteButton from '../../components/button/favouriteBtn';

const TrackingUserTile = ({
  item,
  onCancelPress,
  onSendReminderPress,
  onPressFavAndUnFav,
}) => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = trackingTabDetailStyle(ColorTheme);
  const {loadSignedImageUrl} = useAwsS3();
  const [profileImageUrl, setProfileImageUrl] = React.useState<
    string | undefined
  >();
  const [_, setErrorInDecoding] = React.useState<boolean>();
  const fullName =
    item.firstName && item.lastName
      ? `${item.firstName} ${item.lastName}`
      : undefined;
  const displayName = fullName ? fullName : item.email;
  const showQuantity = !(item?.type === REQUEST_TYPE.GROUP);
  const _imageUrl = item?.profileImage ?? null;

  const isMobileNumber = (input: string): boolean => {
    // Trim the input to remove any leading or trailing whitespace
    const trimmedInput = input.trim();
    // Check if the last five characters are digits
    const lastFiveDigitsRegex = /\d{5}$/;
    return lastFiveDigitsRegex.test(trimmedInput);
  };

  const getSvgUrlGroup = React.useCallback(async (): Promise<void> => {
    try {
      const profileImageUrl = await loadSignedImageUrl(_imageUrl ?? '');
      if (profileImageUrl) {
        setProfileImageUrl(profileImageUrl);
      } else {
        setProfileImageUrl(undefined);
      }
    } catch (error) {
      setErrorInDecoding(true);
    }
  }, [_imageUrl]);

  React.useEffect(() => {
    getSvgUrlGroup();
  }, [_imageUrl]);

  const showButton = () => {
    return (
      <View style={styles.rowContainer}>
        <Pressable
          style={styles.cancelButton}
          onPress={() => onCancelPress(item)}
        >
          <View style={styles.buttonContainer}>
            <Text style={styles.cancelButtonText}>Cancel</Text>
            <CloseRed />
          </View>
        </Pressable>
        <Pressable
          style={styles.sendReminderBtn}
          onPress={() => onSendReminderPress(item)}
        >
          <View style={styles.buttonContainer}>
            <Text style={styles.sendReminderBtnText}>Send a reminder</Text>
            <RightArrowBlue />
          </View>
        </Pressable>
      </View>
    );
  };

  return (
    <>
      <View style={styles.itemContainer}>
        {profileImageUrl ? (
          <Image source={{uri: profileImageUrl}} style={styles.circleImage} />
        ) : isMobileNumber(displayName) ? (
          <View style={styles.circleImage}>
            <Image
              source={IMAGES.P_USER}
              style={{tintColor: ColorTheme.backgroundColor}}
            />
          </View>
        ) : (
          <View style={styles.circleImage}>
            <Text style={styles.initials}>
              {getInitials(displayName || 'Unknown')}
            </Text>
          </View>
        )}
        <View style={styles.textContainer}>
          <View style={styles.rowContainer}>
            <View style={[styles.sameRow, fullName ? null : {width: '100%'}]}>
              <Text style={[styles.title, fullName ? null : {width: '100%'}]}>
                {displayName}
              </Text>
              {showQuantity ? (
                <View style={styles.rowContainer}>
                  <Text style={styles.quantity}>{TEXT.QUANTITY}: </Text>
                  <Text
                    style={[styles.quantity, {fontFamily: FONT_NAMES.SF_BOLD}]}
                  >
                    {item?.quantity}
                  </Text>
                </View>
              ) : (
                showButton()
              )}
            </View>
            {!item.requestForUnRegisteredUser && (
              <FavoriteButton
                isFavorite={item?.favorite ?? false}
                onFavOrUnFavUser={onPressFavAndUnFav}
              />
            )}
          </View>
          {showQuantity && showButton()}
        </View>
      </View>
    </>
  );
};

export default TrackingUserTile;
