import {StyleSheet} from 'react-native';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';

export const circularStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.absolutePosition,
      zIndex: 1000,
    },
    circle: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_24),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_24),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_24) / 2,
      backgroundColor: COLORS._F8CC02,
      ...BASESTYLE.inCenter,
    },
    quantityLabel: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: COLORS.BLACK,
    },
    isSmallStyle: {
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_1),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_1),
    },
    secondCircle: {
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_6),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_24),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_24) / 2,
      backgroundColor: COLORS.PRIMARY_BLUE,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_1),
      ...BASESTYLE.inCenter,
    },
    secondQuantityLabel: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: COLORS.WHITE,
    },
    flexDirectionRow: {
      flexDirection: GlobalStyleValues.ROW,
    },
  });
