import React, {useEffect} from 'react';
import {FlatList, Image, Text, View} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {TEXT, BASESTYLE, FixedValue, ROUTE_NAME} from '../../constants';
import {DistributeModalModalStyle} from './distributeModal.style';
import CustomButton from '../../components/button/customButton';
import {TradeContext} from '../../contextAPI/tradeContext';
import useAwsS3 from '../../hooks/libraryHooks/useAwsS3';
import {
  formatUTCTimeToLocalTime,
  getInitials,
} from '../../utils/helperFunctions';
import TopCardTile from '../../components/mantelTopTabComp/topCardTile';
import useTradeReceviedOrSentHooks from '../../hooks/componentHooks/useTradeReceviedOrSentHooks';
import ConfirmationModal from '../confirmationModal/confirmationModal';
import {
  ACCEPTED_OR_REJECTED,
  NOTIFICATION_SUB_TYPE,
  NOTIFICATION_TYPE,
  timeformat,
  TopType,
} from '../../constants/enum';
import TICKICON from '../../components/renderSvgs/tickIcon';
import CROSSICON from '../../components/renderSvgs/smallCrossIcon';
import {ScrollView} from 'native-base';
import ReportImage from '../../components/renderSvgs/reportImage';
import {NotificationContext} from '../../contextAPI/notificationContext';
import useTradingApprovalHooks from '../../hooks/componentHooks/useTradingApprovalHooks';
import {useAppSelector} from '../../hooks/reduxHooks';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import {USER_MARK_UNMARK_FAVORITE} from '../../constants/actionTypes';
import {useIsFocused} from '@react-navigation/native';
import useFavouriteHooks from '../../hooks/componentHooks/useFavouriteHooks';
import {scaleWidth} from '../../utils/responsive';
import FavoriteButton from '../../components/button/favouriteBtn';

interface TradeContentProps {
  onClose?: () => void;
  isChild?: boolean;
  showActionButton?: boolean;
}

const DistributeModalContent = (props: TradeContentProps): JSX.Element => {
  const {onClose, showActionButton = false} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const {loadSignedImageUrl} = useAwsS3();
  const {styles} = DistributeModalModalStyle(ColorTheme);
  const {
    selectedItem,
    currentActiveTab,
    setApproveModal,
    setIsApprove,
    isApprove,
    approveModal,
    setOpenReportModal,
  } = React.useContext(TradeContext);
  const {selectedNotificationCard, selectedNotificationCardIndex} =
    React.useContext(NotificationContext);
  const [imageUrl, setImageUrl] = React.useState<string | undefined>();
  const {navigateToDetailScreen, getDistributeTopDetail} =
    useTradeReceviedOrSentHooks();
  const {childDistributeRequestApproveOrRejectOrReportApi} =
    useTradingApprovalHooks();
  const {userData} = useAppSelector(userProfileStore);
  const UserDetail = userData.success;
  const isDistributionReceivedRequest =
    selectedNotificationCard?.message.includes('received') ?? false;
  const isDistributionSentRequest =
    selectedNotificationCard?.message.includes('sent') ?? false;
  const showButtonAndStatus =
    selectedNotificationCard?.subType !==
      NOTIFICATION_SUB_TYPE.NOTIFICATION_ACCEPTED &&
    selectedNotificationCard?.subType !==
      NOTIFICATION_SUB_TYPE.NOTIFICATION_REJECTED;
  const [favLoading, setFavLoading] = React.useState<boolean>(false);
  const {addFavoriteAndUnFavorite, setFavoriteLoader} = useFavouriteHooks();
  const isFocused = useIsFocused();
  const {userMarkAndUnmarkFavoriteData} = useAppSelector(userProfileStore);

  //Get User Profile image Url
  const getUserProfileImageUrl = () => {
    if (currentActiveTab === ROUTE_NAME.SENT_OFFER) {
      return selectedItem?.toUser.profileImage ?? '';
    } else {
      return selectedItem?.fromUser.profileImage ?? '';
    }
  };

  const getUserProfileUrl = React.useCallback(async (): Promise<void> => {
    try {
      const imageUrl = await loadSignedImageUrl(getUserProfileImageUrl());
      if (imageUrl) {
        setImageUrl(imageUrl);
      }
    } catch (error) {
      console.log('Error fetching or decoding SVG image:', error);
      // Handle the error here, such as displaying an error message to the user
    }
  }, []);

  const getUserProfileData = () => {
    if (currentActiveTab === ROUTE_NAME.SENT_OFFER) {
      const fullName =
        selectedItem?.toUser?.firstName +
          ' ' +
          selectedItem?.toUser?.lastName ?? '';
      return getInitials(fullName);
    } else {
      const fullName =
        selectedItem?.fromUser?.firstName +
          ' ' +
          selectedItem?.fromUser?.lastName ?? '';
      return getInitials(fullName);
    }
  };

  const RenderImage = React.useMemo(() => {
    if (!imageUrl) {
      // Render the group initials if the image has not loaded yet
      return (
        <View style={[styles.cardStyle, BASESTYLE.center]}>
          <Text style={styles.userNameInitials}>{getUserProfileData()}</Text>
        </View>
      );
    }
    return (
      <Image
        source={{uri: imageUrl}}
        style={styles.cardStyle}
        onError={() => {
          setImageUrl(undefined);
        }}
      />
    );
  }, [imageUrl]);

  const getOfferStatusTagView = () => {
    switch (selectedItem?.status) {
      case TEXT.CANCELLED_IN_CAPS:
        return (
          <View style={styles.cancelledofferStatus}>
            <Text style={styles.rejectStatusText}>
              {TEXT.CANCELLED_IN_SMALL}
            </Text>
          </View>
        );
      case TEXT.REJECTED_IN_CAPS:
        return (
          <View style={styles.cancelledofferStatus}>
            <Text style={styles.rejectStatusText}>
              {TEXT.REJECTED_IN_SMALL}
            </Text>
          </View>
        );
      case TEXT.INVALID_IN_CAPS:
        return (
          <View style={styles.cancelledofferStatus}>
            <Text style={styles.rejectStatusText}>{TEXT.INVALID}</Text>
          </View>
        );
      case TEXT.ACCEPTED_IN_CAPS:
        return (
          <View style={styles.acceptedOfferStatus}>
            <Text style={styles.approvedStatusText}>
              {TEXT.ACCEPTED_IN_SMALL}
            </Text>
          </View>
        );
      case TEXT.PENDING_IN_CAPS:
        return (
          <View style={styles.offerStatus}>
            <Text style={styles.pendingStatusText}>
              {TEXT.PENDING_IN_SMALL}
            </Text>
          </View>
        );
      default:
        break;
    }
  };

  const getToUserDetail = () => {
    return (
      <View style={styles.nameView}>
        <Text numberOfLines={FixedValue.CONSTANT_VALUE_1} style={styles.owner}>
          {selectedItem?.toUser?.firstName} {selectedItem?.toUser?.lastName}
        </Text>
        <Text numberOfLines={FixedValue.CONSTANT_VALUE_1} style={styles.day}>
          {formatUTCTimeToLocalTime(
            selectedItem?.creationDate ?? '',
            timeformat.MMMDDYY
          )}
        </Text>
      </View>
    );
  };

  const getFromUserDetails = () => {
    return (
      <View style={styles.nameView}>
        <Text numberOfLines={FixedValue.CONSTANT_VALUE_1} style={styles.owner}>
          {selectedItem?.fromUser.firstName} {selectedItem?.fromUser.lastName}
        </Text>
        <View>
          <Text numberOfLines={FixedValue.CONSTANT_VALUE_1} style={styles.day}>
            {formatUTCTimeToLocalTime(
              selectedItem?.creationDate ?? '',
              timeformat.MMMDDYY
            )}
          </Text>
        </View>
      </View>
    );
  };

  const onPressFavAndUnFav = React.useCallback(
    (item: any): void => {
      setFavLoading(true);
      setFavoriteLoader(true);
      const user =
        currentActiveTab === ROUTE_NAME.SENT_OFFER
          ? item.toUser
          : item.fromUser;
      const params = {
        favorite: user?.favorite ? !user?.favorite : true,
        userIds: [user?.id ?? FixedValue.CONSTANT_VALUE_0],
      };
      addFavoriteAndUnFavorite(params);
    },
    [currentActiveTab]
  );

  useEffect(() => {
    if (isFocused) {
      setFavLoading(false);
      switch (userMarkAndUnmarkFavoriteData.status) {
        case USER_MARK_UNMARK_FAVORITE.SUCCESS:
          const user =
            currentActiveTab === ROUTE_NAME.SENT_OFFER
              ? selectedItem?.toUser
              : selectedItem?.fromUser;
          const {firstName, lastName, favorite} = user ?? {};
          const firstText = favorite
            ? TEXT.YOU_HAVE_REMOVED
            : TEXT.YOU_HAVE_ADDED;
          const fullName = firstName + ' ' + lastName;
          const favText = favorite ? TEXT.FROM_THE_LIST : TEXT.TO_THE_LIST;
          const msg = `${firstText} "${fullName}" ${favText}`;
          // @ts-ignore
          getDistributeTopDetail(selectedNotificationCard);
          alert(msg);
          break;
        case USER_MARK_UNMARK_FAVORITE.FAILED:
          break;
      }
    }
  }, [userMarkAndUnmarkFavoriteData?.status]);

  const getDistributorName = () => {
    return UserDetail?.id === selectedItem?.fromUser.id
      ? 'you'
      : selectedItem?.fromUser?.firstName ??
          '' + selectedItem?.fromUser?.lastName;
  };

  const getReceiverName = () => {
    return selectedItem?.email
      ? selectedItem?.email
      : selectedItem?.toUser?.firstName ?? '' + selectedItem?.toUser?.lastName;
  };

  const getFromUserTops = () => {
    return (
      <View>
        <Text
          numberOfLines={FixedValue.CONSTANT_VALUE_1}
          style={styles.topOwner}
        >
          {TEXT.TOP_DISTRIBUTE_BY} {`${getDistributorName()} to`}{' '}
          {getReceiverName()}
        </Text>
        <View>
          <FlatList
            style={{marginLeft: 0}}
            numColumns={3}
            data={selectedItem?.fromUserTops}
            showsHorizontalScrollIndicator={false}
            keyExtractor={(_, index) =>
              `${TEXT.COLLECTIONS + 'Nested'}-${index}`
            }
            renderItem={({item}) => (
              <TopCardTile
                key={item.id}
                {...item}
                ismodal
                onShowDetailPress={() => {
                  navigateToDetailScreen(item, TopType.viewOffer);
                }}
              />
            )}
          />
        </View>
      </View>
    );
  };

  const getTopUserInfo = () => {
    if (currentActiveTab === ROUTE_NAME.SENT_OFFER) {
      return getToUserDetail();
    } else {
      return getFromUserDetails();
    }
  };
  // For first Top list, show tops according to offer list i.e sent/received
  const getTopsList = () => {
    return getFromUserTops();
  };
  // Load User Profile image
  React.useEffect(() => {
    getUserProfileUrl();
  }, [selectedItem]);

  const onPressAcceptOrRejectFunction = React.useCallback((): void => {
    const minorId: number =
      JSON.parse(selectedNotificationCard?.json ?? '')?.minorId ??
      FixedValue.CONSTANT_VALUE_0;
    const isRequestForUnRegisteredUser: boolean =
      selectedNotificationCard?.type ===
      NOTIFICATION_TYPE.DISTRIBUTION_PUSH_EMAIL;
    let approveRejectParams: any = {
      distributeRequestId: selectedNotificationCard?.requestId,
      status: isApprove
        ? ACCEPTED_OR_REJECTED.ACCEPTED
        : ACCEPTED_OR_REJECTED.REJECTED,
      index: selectedNotificationCardIndex,
      requestForUnRegisteredUser: isRequestForUnRegisteredUser,
    };
    if (minorId) {
      approveRejectParams.minorId = minorId ?? 0;
    }
    childDistributeRequestApproveOrRejectOrReportApi(approveRejectParams);
    onClose && onClose();
  }, [selectedNotificationCard, selectedNotificationCardIndex, isApprove]);

  return (
    <View style={styles.container}>
      <ScrollView style={{marginBottom: 100}}>
        {/* Offer sender View */}
        <View style={styles.topContainerMain}>
          <View style={styles.topContainer}>
            <View style={styles.cardStyle}>{RenderImage}</View>
            {getTopUserInfo()}
          </View>
          <View style={{left: scaleWidth(70)}}>{getOfferStatusTagView()}</View>
          <View style={styles.favIconContainer}>
            {!(UserDetail?.id === selectedItem?.fromUser.id) && (
              <FavoriteButton
                isFavorite={selectedItem?.fromUser?.favorite ?? false}
                onFavOrUnFavUser={() => onPressFavAndUnFav(selectedItem)}
              />
            )}
          </View>
        </View>
        <View>{getTopsList()}</View>
      </ScrollView>
      {showButtonAndStatus &&
        selectedItem?.status === TEXT.PENDING_IN_CAPS &&
        (isDistributionReceivedRequest || isDistributionSentRequest) &&
        showActionButton && (
          <View style={styles.approveButton}>
            <>
              <CustomButton
                buttonLabel={TEXT.APPROVE}
                buttonwidth={styles.primaryButtonStyle}
                labelStyle={styles.primaryButtonLabelstyle}
                buttonBackGroundColor={styles.noButtonBackGroundColor1}
                element={<TICKICON />}
                onPress={() => {
                  setIsApprove(true);
                  setApproveModal();
                }}
              />
              <CustomButton
                buttonLabel={TEXT.REJECT}
                buttonwidth={styles.primaryButtonStyle2}
                labelStyle={styles.primaryButtonLabelstyle1}
                buttonBackGroundColor={styles.noButtonBackGroundColor}
                element={<CROSSICON />}
                onPress={() => {
                  setIsApprove(false);
                  setApproveModal();
                }}
              />
              {!isDistributionSentRequest && (
                <CustomButton
                  buttonLabel={TEXT.REPORT}
                  buttonwidth={styles.primaryButtonStyle2}
                  labelStyle={styles.primaryButtonLabelstyle1}
                  buttonBackGroundColor={styles.noButtonBackGroundColor}
                  element={
                    <ReportImage
                      width={FixedValue.CONSTANT_VALUE_12}
                      height={FixedValue.CONSTANT_VALUE_12}
                    />
                  }
                  onPress={() => {
                    setOpenReportModal(true);
                    onClose && onClose();
                  }}
                />
              )}
              <ConfirmationModal
                title={
                  isApprove
                    ? TEXT.DISTRIBUTION_REQUEST_APPROVE
                    : TEXT.DISTRIBUTION_REQUEST_REJECT
                }
                modalVisible={approveModal}
                onPressYes={() => {
                  onPressAcceptOrRejectFunction();
                  setApproveModal();
                }}
                onShowHide={setApproveModal}
              />
            </>
          </View>
        )}
    </View>
  );
};

export default DistributeModalContent;
