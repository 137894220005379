import {createSlice} from '@reduxjs/toolkit';
import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {ResetAllState} from './logoutslice';
import {IChildApproveListResponse} from '../../types/reducerTypes/childApproveRequestStoreTypes';
import {TOPCREDITS_REQUEST_ACTION} from '../../constants/actionTypes';

const {StoreConstants} = AppConst;
const {
  CHILD_APPROVEL_REQUEST_LIST,
  APPROVE_OR_REJECT,
  TOP_BUYING_SETTING,
  TOP_BUYING_APPROVAL,
  ACCEPT_REJECT_BY_PARENT,
} = ActionType;

const initialState: IChildApproveListResponse = {
  childApproveOrReject: {
    isLoading: false,
    success: null,
    failed: null,
    status: CHILD_APPROVEL_REQUEST_LIST.END,
  },
  approveOrReject: {
    isLoading: false,
    success: null,
    failed: null,
    status: APPROVE_OR_REJECT.END,
  },
  topBuyingSettingData: {
    isLoading: false,
    success: null,
    failed: null,
    status: TOP_BUYING_SETTING.END,
  },
  topBuyingApprovalListData: {
    isLoading: false,
    success: null,
    failed: null,
    status: TOP_BUYING_APPROVAL.END,
  },
  acceptRejectByParent: {
    isLoading: false,
    success: null,
    failed: null,
    status: ACCEPT_REJECT_BY_PARENT.END,
  },
  topCreditsRequestAction: {
    isLoading: false,
    success: null,
    failed: null,
    status: TOPCREDITS_REQUEST_ACTION.END,
  },
};

const ChildProfileApproveSlice = createSlice({
  initialState,
  name: StoreConstants.CHILD_APPROVEL_REQUEST_LIST,
  reducers: {
    onChildProfileApproveStart: (state, _) => {
      state.childApproveOrReject.isLoading = true;
      state.childApproveOrReject.status = CHILD_APPROVEL_REQUEST_LIST.START;
    },
    onChildProfileApproveSuccess: (state, action) => {
      state.childApproveOrReject.success = action.payload;
      state.childApproveOrReject.status = CHILD_APPROVEL_REQUEST_LIST.SUCCESS;
    },
    onChildProfileApproveFailed: (state, action) => {
      state.childApproveOrReject.failed = action.payload;
      state.childApproveOrReject.status = CHILD_APPROVEL_REQUEST_LIST.FAILED;
    },
    onChildProfileApproveEnd: state => {
      state.childApproveOrReject.isLoading = false;
      state.childApproveOrReject.status = CHILD_APPROVEL_REQUEST_LIST.END;
    },
    onApproveOrRejectStart: state => {
      state.approveOrReject.isLoading = true;
      state.approveOrReject.status = APPROVE_OR_REJECT.START;
    },
    onApproveOrRejectSuccess: (state, action) => {
      state.approveOrReject.success = action.payload;
      state.approveOrReject.status = APPROVE_OR_REJECT.SUCCESS;
    },
    onApproveOrRejectFailed: (state, action) => {
      state.approveOrReject.failed = action.payload;
      state.approveOrReject.status = APPROVE_OR_REJECT.FAILED;
    },
    onApproveOrRejectEnd: state => {
      state.approveOrReject.isLoading = false;
      state.approveOrReject.status = APPROVE_OR_REJECT.END;
    },
    onTopBuyingApprovalSettingStart: (state, _) => {
      state.topBuyingSettingData.isLoading = true;
      state.topBuyingSettingData.status = TOP_BUYING_SETTING.START;
    },
    onTopBuyingApprovalSettingSuccess: (state, action) => {
      state.topBuyingSettingData.success = action.payload;
      state.topBuyingSettingData.status = TOP_BUYING_SETTING.SUCCESS;
    },
    onTopBuyingApprovalSettingFailed: (state, action) => {
      state.topBuyingSettingData.failed = action.payload;
      state.topBuyingSettingData.status = TOP_BUYING_SETTING.FAILED;
    },
    onTopBuyingApprovalSettingEnd: state => {
      state.topBuyingSettingData.isLoading = false;
      state.topBuyingSettingData.status = TOP_BUYING_SETTING.END;
    },
    onTopBuyingApprovalListStart: (state, _) => {
      state.topBuyingApprovalListData.isLoading = true;
      state.topBuyingApprovalListData.status = TOP_BUYING_APPROVAL.START;
    },
    onTopBuyingApprovalListSuccess: (state, action) => {
      state.topBuyingApprovalListData.success = action.payload;
      state.topBuyingApprovalListData.status = TOP_BUYING_APPROVAL.SUCCESS;
    },
    onTopBuyingApprovalListFailed: (state, action) => {
      state.topBuyingApprovalListData.failed = action.payload;
      state.topBuyingApprovalListData.status = TOP_BUYING_APPROVAL.FAILED;
    },
    onTopBuyingApprovalListEnd: state => {
      state.topBuyingApprovalListData.isLoading = false;
      state.topBuyingApprovalListData.status = TOP_BUYING_APPROVAL.END;
    },
    onAcceptOrRejectbyParentStart: (state, _) => {
      state.acceptRejectByParent.isLoading = true;
      state.acceptRejectByParent.status = ACCEPT_REJECT_BY_PARENT.START;
    },
    onAcceptOrRejectbyParentSuccess: (state, action) => {
      state.acceptRejectByParent.success = action.payload;
      state.acceptRejectByParent.status = ACCEPT_REJECT_BY_PARENT.SUCCESS;
    },
    onAcceptOrRejectbyParentFailed: (state, action) => {
      state.acceptRejectByParent.failed = action.payload;
      state.acceptRejectByParent.status = ACCEPT_REJECT_BY_PARENT.FAILED;
    },
    onAcceptOrRejectbyParentEnd: state => {
      state.acceptRejectByParent.isLoading = false;
      state.acceptRejectByParent.status = ACCEPT_REJECT_BY_PARENT.END;
    },
    onTopCreditsRequestStart: (state, _) => {
      state.topCreditsRequestAction.isLoading = true;
      state.topCreditsRequestAction.status = TOPCREDITS_REQUEST_ACTION.START;
    },
    onTopCreditsRequestSuccess: (state, action) => {
      state.topCreditsRequestAction.success = action.payload;
      state.topCreditsRequestAction.status = TOPCREDITS_REQUEST_ACTION.SUCCESS;
    },
    onTopCreditsRequestFailed: (state, action) => {
      state.topCreditsRequestAction.failed = action.payload;
      state.topCreditsRequestAction.status = TOPCREDITS_REQUEST_ACTION.FAILED;
    },
    onTopCreditsRequestEnd: state => {
      state.topCreditsRequestAction.isLoading = false;
      state.topCreditsRequestAction.status = TOPCREDITS_REQUEST_ACTION.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  onChildProfileApproveStart,
  onChildProfileApproveSuccess,
  onChildProfileApproveFailed,
  onChildProfileApproveEnd,
  onApproveOrRejectStart,
  onApproveOrRejectSuccess,
  onApproveOrRejectFailed,
  onApproveOrRejectEnd,
  onTopBuyingApprovalListEnd,
  onTopBuyingApprovalListFailed,
  onTopBuyingApprovalListStart,
  onTopBuyingApprovalListSuccess,
  onTopBuyingApprovalSettingEnd,
  onTopBuyingApprovalSettingFailed,
  onTopBuyingApprovalSettingStart,
  onTopBuyingApprovalSettingSuccess,
  onAcceptOrRejectbyParentEnd,
  onAcceptOrRejectbyParentFailed,
  onAcceptOrRejectbyParentStart,
  onAcceptOrRejectbyParentSuccess,
  onTopCreditsRequestStart,
  onTopCreditsRequestSuccess,
  onTopCreditsRequestFailed,
  onTopCreditsRequestEnd,
} = ChildProfileApproveSlice.actions;
export default ChildProfileApproveSlice.reducer;
export const childProfileApproveStore = (state: RootState) =>
  state.childProfileApproveStore;
