import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  FixedValue,
  Percentage,
  FONT_NAMES,
  GlobalStyleValues,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const createPwdStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.flex1,
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_12),
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    iconView: {
      ...BASESTYLE.selfCenter,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_63),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_26),
    },
    titleText: {
      color: theme.color,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_24),
      fontFamily: FONT_NAMES.SF_BOLD,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      textAlign: GlobalStyleValues.CENTER,
    },
    titleTextTwo: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_17),
      color: theme.labelColor,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_5),
    },
    buttonStyle: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_26),
    },
    btnStyle: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_30),
    },
  });
