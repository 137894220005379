import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import {RouteProp, useRoute} from '@react-navigation/native';

import BackButton from '../../components/button/backButton';
import PrimaryButton from '../../components/button/primaryButton';
import {TEXT, ROUTE_NAME} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {RootStackParamList} from '../../types/navigationType';
import {verifyEmailStyle} from './verifyEmail.style';
import OtpInput from '../../components/input/otpInput';
import useVerifyHooks from '../../hooks/componentHooks/useVerifyHooks';
import {useAppSelector} from '../../hooks/reduxHooks';
import {verifyOtpState} from '../../redux/reducers/verifyOtp';

export type verifyOtpFields = {
  Username: string;
  Code: string;
  isVerifyEmail?: boolean;
  isVerifyMobile?: boolean;
};
const VerifyEmailContentView = (): JSX.Element => {
  const {isLoaderMoving} = useAppSelector(verifyOtpState);
  const route = useRoute<RouteProp<RootStackParamList, 'VerifyEmail'>>();
  const {values, isValid, handleChange, handleSubmit, resendOtp} =
    useVerifyHooks();
  const {emailId, navigateFrom, allFeilds, mobileNo} = route.params;
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = verifyEmailStyle(ColorTheme);

  const titleText = React.useMemo(() => {
    switch (navigateFrom) {
      case ROUTE_NAME.EDIT_PROFILE:
      case ROUTE_NAME.LOSS_PASSWORD:
        return TEXT.ENTER_OTP;
      case ROUTE_NAME.LOGIN_SCREEN:
      case ROUTE_NAME.SIGN_UP:
      case ROUTE_NAME.EDIT_PROFILE_MOBILE:
        return TEXT.VERIFY_MOBILE;
      default:
        return TEXT.VERIFY_EMAIL;
    }
  }, [navigateFrom]);

  const subTitleText = React.useMemo(() => {
    switch (navigateFrom) {
      case ROUTE_NAME.LOSS_PASSWORD:
        return TEXT.VERIFY_USERNAME_EMAIL_DESC;
      case ROUTE_NAME.LOGIN_SCREEN:
        return TEXT.VERIFY_USERNAME_MOBILE_DESC;
      case ROUTE_NAME.SIGN_UP:
      case ROUTE_NAME.EDIT_PROFILE_MOBILE:
        return TEXT.VERIFY_USERNAME_MOBILE;
      default:
        return TEXT.VERIFY_EMAIL_DESC;
    }
  }, [navigateFrom]);

  const otpField = React.useMemo(() => {
    switch (navigateFrom) {
      case ROUTE_NAME.LOSS_PASSWORD:
      case ROUTE_NAME.LOGIN_SCREEN:
      case ROUTE_NAME.SIGN_UP:
      case ROUTE_NAME.EDIT_PROFILE_MOBILE:
        return allFeilds?.MobileNumber ?? mobileNo;
      default:
        return emailId;
    }
  }, [navigateFrom]);
  
  return (
    <>
      <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
        <BackButton />
        <Text style={styles.titleText}>{titleText}</Text>
        <Text style={styles.descText}>
          {subTitleText}"<Text style={styles.emailText}>{otpField}</Text>"
        </Text>
        <Text style={styles.descText}>{TEXT.ENTER_VERIFICATION_CODE}</Text>
        <OtpInput value={values.Code} onChangeText={handleChange(TEXT.CODE)} />
        <View style={styles.buttonStyle}>
          <PrimaryButton
            buttonLabel={TEXT.SUBMIT}
            onPress={handleSubmit}
            isDisabled={!isValid}
            isLoading={isLoaderMoving}
          />
        </View>
        <TouchableOpacity onPress={resendOtp}>
          <Text style={styles.resentCode}>{TEXT.RESENT_CODE}</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};

export default React.memo(VerifyEmailContentView);
