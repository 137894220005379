import React from 'react';
import {View, Text} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {tradeCompStyle} from './receviedOffer.style';

import NoItemOffer from '../renderSvgs/noItemOffer';
import {TEXT} from '../../constants';

const EmptyView = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const styles = tradeCompStyle(ColorTheme);
  return (
    <View style={styles.noOfferView}>
      <NoItemOffer />
      <Text style={styles.noOffer}>{TEXT.NO_RECEIVED_OFFERS}</Text>
      <Text style={styles.tapoffer}>{TEXT.OPTIONS_RECEIVED_OFFERS}</Text>
    </View>
  );
};

export default React.memo(EmptyView);
