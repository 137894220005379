import React from 'react';
import {View, ActivityIndicator, ViewStyle} from 'react-native';
import { string } from 'yup';

import {COLORS, GlobalStyleValues} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {styles} from './smallDesign.style';

interface IAppLoader {
  backgroundColor?: string;
  loaderStyle?: ViewStyle;
}

const AppLoader = (props: IAppLoader): JSX.Element => {
  const {backgroundColor, loaderStyle} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  return (
    <View style={[style.loaderView, {backgroundColor}, loaderStyle]}>
      <ActivityIndicator
        size={GlobalStyleValues.LARGE}
        color={COLORS.PRIMARY_BLUE}
      />
    </View>
  );
};

export default React.memo(AppLoader);
