import React from 'react';

import {FixedValue} from '../../constants';
import {ISizeController} from '../../types/componentTypes/editorType';
import Gallery from '../renderSvgs/gallery';
import SizeSlider from './sizeSlider';

const SizeController = (props: ISizeController): JSX.Element => {
  const {result, onChangeValue, maxValue, minValue} = props;
  return (
    <SizeSlider
      values={result}
      onValuesChange={onChangeValue}
      LeftIcon={
        <Gallery
          width={FixedValue.CONSTANT_VALUE_16}
          height={FixedValue.CONSTANT_VALUE_16}
        />
      }
      RightIcon={
        <Gallery
          width={FixedValue.CONSTANT_VALUE_24}
          height={FixedValue.CONSTANT_VALUE_24}
        />
      }
      min={minValue}
      max={maxValue}
    />
  );
};

export default React.memo(SizeController);
