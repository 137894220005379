import React, {useCallback, useEffect, useState} from 'react';
import {View, Text, Pressable, ScrollView, Alert, Platform} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {abuseReportStyle} from './abuseReport.style';
import ModalHeaderWithCross from '../../components/header/modalHeaderWithCross';
import {FixedValue, TEXT} from '../../constants';
import ModalContainer from '../../components/layout/modalContainer';
import RadioSelectButton from '../../components/renderSvgs/radioSelectButton';
import {HomeContext} from '../../contextAPI/homeContext';
import RadioUnselectButton from '../../components/renderSvgs/radioUnselectButton';
import {
  _InputType,
  _ToastHandler,
  alertPolyfill,
} from '../../utils/helperFunctions';
import NoteInput from '../../components/input/noteInput';
import useTradeReceviedOrSentHooks from '../../hooks/componentHooks/useTradeReceviedOrSentHooks';
import PrimaryButton from '../../components/button/primaryButton';
import {useIsFocused} from '@react-navigation/native';
import {ReportFormState} from '../../types/reducerTypes/tradeTabTypes';
import {ACCEPTED_OR_REJECTED, Percentage} from '../../constants/enum';
import {SCREEN_HEIGHT, scaleHeight} from '../../utils/responsive';
import {TOAST_TEXT} from '../../constants/textMessage';
interface AbuseModalProps {
  modalVisible: boolean;
  onClose: (val: boolean) => void;
  onSubmit: (val: ReportFormState) => void;
  offerId: number;
  isForChild?: boolean;
}
const AbuseReportModal = (props: AbuseModalProps): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = abuseReportStyle(ColorTheme);
  const {modalVisible, onClose, onSubmit, offerId, isForChild = false} = props;
  const {abuseData} = React.useContext(HomeContext);
  const isFocused = useIsFocused();
  const {
    values,
    isValid,
    handleChange,
    handleBlur,
    errors,
    touched,
    setValues,
    resetForm,
  } = useTradeReceviedOrSentHooks();
  const [showReportAndBlockModal, setShowReportAndBlockModal] =
    useState<boolean>(true);
  const [showReportAndBlockValue, setShowReportAndBlockValue] = useState<
    number | null
  >(null);
  const [isThisBlockUser, setIsThisBlockUser] = useState<boolean>(false);

  const reportAndBlockData = [TEXT.REPORT, TEXT.BLOCK_AND_REPORT];
  const modalHeader = showReportAndBlockModal
    ? TEXT.BLOCK_AND_REPORT
    : TEXT.REPORT_ABUSE;

  const modalHeight = showReportAndBlockModal
    ? Percentage.PRECENTAGE_25
    : SCREEN_HEIGHT - scaleHeight(FixedValue.CONSTANT_VALUE_52);
  const reportApiParams = {
    ...values,
    offerId,
    isForChild,
    userBlocked: isThisBlockUser,
    status: ACCEPTED_OR_REJECTED.REPORTED,
  };

  useEffect(() => {
    if (isFocused && modalVisible) {
      resetForm();
    }
  }, [isFocused, modalVisible]);

  const onSelectValue = useCallback(
    (value: number): void => {
      if (value === FixedValue.CONSTANT_VALUE_1) {
        if (Platform.OS === 'web') {
          alertPolyfill(
            TOAST_TEXT.BLOCK_USER_MAIN_TEXT,
            TOAST_TEXT.BLOCK_ALERT_SUB_TEXT,
            [
              {
                text: TEXT.CANCEL,
                style: 'cancel',
                onPress: () => {
                  onClose(false);
                },
              },
              {
                text: TEXT.OKEY,
                onPress: () => {
                  setIsThisBlockUser(true);
                  setShowReportAndBlockModal(false);
                },
              },
            ]
          );
        } else {
          Alert.alert(
            TOAST_TEXT.BLOCK_USER_MAIN_TEXT,
            TOAST_TEXT.BLOCK_ALERT_SUB_TEXT,
            [
              {
                text: TEXT.CANCEL,
                style: 'cancel',
                onPress: () => {
                  onClose(false);
                },
              },
              {
                text: TEXT.OKEY,
                onPress: () => {
                  setIsThisBlockUser(true);
                  setShowReportAndBlockModal(false);
                },
              },
            ]
          );
        }
      } else {
        setShowReportAndBlockModal(false);
      }
      setShowReportAndBlockValue(value);
    },
    [showReportAndBlockValue]
  );

  return (
    <ModalContainer
      visible={modalVisible}
      onPressOutside={() => onClose(false)}
    >
      <View style={[styles.innerView, {maxHeight: modalHeight}]}>
        <ModalHeaderWithCross
          onPress={() => onClose(false)}
          headerTitle={modalHeader}
        />
        {!showReportAndBlockModal ? (
          <ScrollView
            contentContainerStyle={styles.scrollViewStyle}
            showsVerticalScrollIndicator={false}
          >
            <Text style={styles.subTitleText}>{TEXT.WHY_ARE_YOUR}</Text>
            <View style={styles.marginTop20}>
              {abuseData?.map((item, index) => (
                <Pressable
                  style={styles.abuseListConatiner}
                  key={index}
                  onPress={() => setValues({...values, reportId: item?.id})}
                >
                  {values?.reportId === item?.id ? (
                    <RadioSelectButton
                      height={FixedValue.CONSTANT_VALUE_20}
                      width={FixedValue.CONSTANT_VALUE_20}
                    />
                  ) : (
                    <RadioUnselectButton
                      height={FixedValue.CONSTANT_VALUE_20}
                      width={FixedValue.CONSTANT_VALUE_20}
                    />
                  )}
                  <Text style={styles.abuseText}>{item?.description}</Text>
                </Pressable>
              ))}
            </View>
            <Text style={styles.helpUs}>{TEXT.HELP_US}</Text>
            <View style={styles.descriptionView}>
              <NoteInput
                placeholder={TEXT.DESCRIPTION}
                inputLabel={''}
                value={values.description}
                onChangeText={handleChange(TEXT.DESCRIPTION_)}
                onBlur={handleBlur(TEXT.DESCRIPTION)}
                haveError={
                  errors?.description && touched?.description
                    ? errors?.description
                    : ''
                }
              />
            </View>
            <PrimaryButton
              buttonLabel={TEXT.SUBMIT}
              onPress={() => onSubmit(reportApiParams)}
              isDisabled={!isValid}
            />
          </ScrollView>
        ) : (
          <>
            <View style={styles.blockAndReportContainer}>
              {reportAndBlockData?.map((item, index) => (
                <Pressable
                  style={styles.abuseListConatiner}
                  key={index}
                  onPress={() => onSelectValue(index)}
                >
                  {showReportAndBlockValue === index ? (
                    <RadioSelectButton
                      height={FixedValue.CONSTANT_VALUE_20}
                      width={FixedValue.CONSTANT_VALUE_20}
                    />
                  ) : (
                    <RadioUnselectButton
                      height={FixedValue.CONSTANT_VALUE_20}
                      width={FixedValue.CONSTANT_VALUE_20}
                    />
                  )}
                  <Text style={styles.abuseText}>{item}</Text>
                </Pressable>
              ))}
            </View>
          </>
        )}
      </View>
    </ModalContainer>
  );
};

export default AbuseReportModal;
