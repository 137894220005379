import React from 'react';
import {StyleSheet, Pressable, Text} from 'react-native';

import {
  BASESTYLE,
  FixedValue,
  Percentage,
  COLORS,
  FONT_NAMES,
} from '../../constants';
import {normalizeFont, scaleHeight} from '../../utils/responsive';
import {IPrimaryButton} from '../../types/componentTypes/buttonType';

const styles = StyleSheet.create({
  mainView: {
    ...BASESTYLE.inCenter,
    height: scaleHeight(FixedValue.CONSTANT_VALUE_58),
    width: Percentage.PRECENTAGE_100,
    borderRadius: FixedValue.CONSTANT_VALUE_100,
    backgroundColor: COLORS.E9EEFB,
  },
  labelstyle: {
    fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
    fontFamily: FONT_NAMES.SF_MEDIUM,
    color: COLORS.PRIMARY_BLUE,
  },
});

const SecondaryButton: React.FC<IPrimaryButton> = (props: IPrimaryButton) => {
  const {onPress, isDisabled, labelStyle, buttonLabel} = props;
  const _isDisabled = isDisabled || false;
  const onClick = React.useCallback((): void => {
    if (!_isDisabled) {
      onPress();
    }
  }, [_isDisabled]);

  return (
    <Pressable onPress={onClick} style={styles.mainView}>
      <Text style={[styles.labelstyle, labelStyle]}>{buttonLabel}</Text>
    </Pressable>
  );
};

export default React.memo(SecondaryButton);
