import React from 'react';

import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import ScrollableContainer from '../../components/layout/scrollableContainer';
import {CreatePasswordProvider} from '../../contextAPI/createPassword';
import CreatePwdContent from './createPwdContent';

const CreatePassword = (): JSX.Element => {
  return (
    <CreatePasswordProvider>
      <ScrollableContainer>
        <SafeAreaContainer>
          <CreatePwdContent />
        </SafeAreaContainer>
      </ScrollableContainer>
    </CreatePasswordProvider>
  );
};

export default React.memo(CreatePassword);
