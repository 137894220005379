import React from 'react';
import {Image, View} from 'react-native';
import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  FixedValue,
  GlobalStyleValues,
  IMAGES,
  Percentage,
  themeTypes,
} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {isWebsite, SCREEN_HEIGHT, SCREEN_WIDTH} from '../../utils/responsive';

const profileBackgroundStyle = StyleSheet.create({
  container: {
    ...BASESTYLE.flex1,
    ...BASESTYLE.absolutePosition,
    zIndex: FixedValue.CONSTANT_VALUE_MIN_1,
    ...BASESTYLE.selfCenter,
    height: Percentage.PRECENTAGE_100,
    width: isWebsite() ? Percentage.PRECENTAGE_100 : SCREEN_WIDTH,
    '@media (max-width: 500px)': {
      width: Percentage.PRECENTAGE_100,
    },
  },
  imageStyle: {
    width: SCREEN_WIDTH,
    height: SCREEN_HEIGHT,
  },
  webImageStyle: {
    width: Percentage.PRECENTAGE_100,
    height: Percentage.PRECENTAGE_100,
    resizeMode: GlobalStyleValues.COVER,
  },
});

const ProfileBackGround = () => {
  const {themeState} = React.useContext(ThemeContext);
  const {styles, ids} = profileBackgroundStyle;
  const RenderImage = React.useMemo((): JSX.Element => {
    return (
      <Image
        source={
          themeState === themeTypes.LIGHT
            ? IMAGES.PROFILE_BG
            : IMAGES.PROFILE_DARK
        }
        style={isWebsite() ? styles.webImageStyle : styles.imageStyle}
      />
    );
  }, [themeState]);

  return (
    <View style={styles.container} dataSet={{media: ids.container}}>
      {RenderImage}
    </View>
  );
};

export default React.memo(ProfileBackGround);
