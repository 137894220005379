import React, {RefObject, memo} from 'react';
import {Pressable, Text, TextInput, View} from 'react-native';
import SelectEllipse from '../../../components/renderSvgs/selectEllipse';
import Ellipse from '../../../components/renderSvgs/ellipse';
import {FixedValue, Percentage, TEXT} from '../../../constants';
import {CHILD_PERMISSION} from '../../../constants/enum';
import {DistributingSettingStyle} from '../../distributingTopRequest/distributingSetting/style';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {handleTopBuyingLimitChangeRegex} from '../../../utils/validation';

interface SettingItemProps {
  item: {text: string; status: CHILD_PERMISSION};
  selected: string;
  onPress: (
    status: CHILD_PERMISSION,
    index: string,
    headerTitle: string
  ) => void;
  updatedKey: string;
  headerTitle: string;
  topBuyingLimitValue: string;
  setTopBuyingLimitValue: (value: string) => void;
  textInputRef: RefObject<TextInput>;
}

const SettingItem: React.FC<SettingItemProps> = ({
  item,
  selected,
  onPress,
  updatedKey,
  headerTitle,
  topBuyingLimitValue,
  setTopBuyingLimitValue,
  textInputRef,
}) => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = DistributingSettingStyle(ColorTheme);

  // Function to handle input change
  const handleTopBuyingLimitChange = (value: string) => {
    if (handleTopBuyingLimitChangeRegex(value)) {
      setTopBuyingLimitValue(value);
    }
  };

  return (
    <>
      <Pressable
        onPress={() => onPress(item.status, updatedKey, headerTitle)}
        style={styles.ellipseView}
      >
        <View style={{width: Percentage.PRECENTAGE_11_5}}>
          {item.status === selected ? (
            <SelectEllipse
              height={FixedValue.CONSTANT_VALUE_20}
              width={FixedValue.CONSTANT_VALUE_20}
            />
          ) : (
            <Ellipse
              height={FixedValue.CONSTANT_VALUE_20}
              width={FixedValue.CONSTANT_VALUE_20}
            />
          )}
        </View>
        <View style={{width: Percentage.PRECENTAGE_90}}>
          <Text style={styles.mustApprove}>{item.text}</Text>
        </View>
      </Pressable>
      {item.status === CHILD_PERMISSION.HAVE_LIMIT &&
        selected === CHILD_PERMISSION.HAVE_LIMIT &&
        headerTitle === TEXT.TOP_BUYING_APPROVAL && (
          <View style={styles.limitView}>
            <Text style={styles.limitText}>{TEXT.SET_THE_MONTHLY}</Text>
            <TextInput
              ref={textInputRef}
              editable={selected === CHILD_PERMISSION.HAVE_LIMIT}
              style={styles.limitInput}
              keyboardType="numeric"
              maxLength={FixedValue.CONSTANT_VALUE_10}
              placeholder="00.00"
              value={
                selected === CHILD_PERMISSION.HAVE_LIMIT
                  ? topBuyingLimitValue
                  : ''
              }
              onChangeText={handleTopBuyingLimitChange}
            />
          </View>
        )}
    </>
  );
};

export default memo(SettingItem);
