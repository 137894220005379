import React from 'react';
import {Text, View} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import PrimaryButton from '../../components/button/primaryButton';
import {TEXT, BASESTYLE, FixedValue} from '../../constants';
import CustomButton from '../../components/button/customButton';
import {style} from './style';
import GreenTick from '../../components/renderSvgs/greenTick';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import DeleteAlertcircle from '../../components/renderSvgs/deleteAlertcircle';
import {ApprovedRejectModalPropsType} from '../../types/reducerTypes/groupMangementTypes';
const ApprovedRejectCustomModalContent = (
  props: ApprovedRejectModalPropsType
): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = style(ColorTheme);
  const {actionType, onClose, callGroupInvitationsApproveRejectAPI} = props;
  return (
    <View style={styles.container}>
      <View style={BASESTYLE.alignCenter}>
        {actionType === FixedValue.CONSTANT_VALUE_1 ? (
          <>
            <GreenTick
              width={scaleWidth(FixedValue.CONSTANT_VALUE_40)}
              height={scaleHeight(FixedValue.CONSTANT_VALUE_40)}
            />
            <Text style={styles.wantToApprove}>{TEXT.ACCEPT_INVITATION}</Text>
          </>
        ) : (
          <>
            <DeleteAlertcircle
              width={scaleWidth(FixedValue.CONSTANT_VALUE_40)}
              height={scaleHeight(FixedValue.CONSTANT_VALUE_40)}
            />
            <Text style={styles.wantToApprove}>{TEXT.REJECT_INVITATION}</Text>
          </>
        )}
        <View style={[BASESTYLE.inRow, BASESTYLE.inCenter]}>
          <CustomButton
            buttonLabel={TEXT.NO}
            buttonwidth={styles.primaryButtonStyle2}
            buttonBackGroundColor={styles.noButtonBackGroundColor}
            labelStyle={styles.noButtonLabelstyle}
            onPress={onClose}
            isDisabled={false}
          />
          <CustomButton
            buttonLabel={TEXT.YES}
            buttonwidth={styles.primaryButtonStyle}
            labelStyle={styles.yesButtonLabelstyle}
            onPress={callGroupInvitationsApproveRejectAPI}
            isDisabled={false}
          />
        </View>
      </View>
    </View>
  );
};

export default ApprovedRejectCustomModalContent;
