import React from 'react';
import {View, FlatList} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';

import {style} from './style';
import {ThemeContext} from '../../contextAPI/themeContext';
import {FixedValue, TEXT} from '../../constants';
import TopsListEmptyView from '../../components/emptyListView/topsListEmptyView';
import {ChildProfileContext} from '../../contextAPI/childProfileContext';
import useTradingApprovalHooks from '../../hooks/componentHooks/useTradingApprovalHooks';
import ChildGroupTile from './childGroupTile';
import NoChildData from '../../components/renderSvgs/noChildDataPlaceholder';
import {childProfileStore} from '../../redux/reducers/childProfileSlice';
import {useAppSelector} from '../../hooks/reduxHooks';

const ChildGroupListContent = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = style(ColorTheme);
  const {
    groupInvitationChildListData,
    initialLoader,
    setIsApproveInvitation,
    setModalVisible,
    setGroupInvitation,
    childProfileContextPullDownToRefresh,
  } = React.useContext(ChildProfileContext);
  const {
    onGroupInvitationChildListEndReach,
    pullDownToRefreshOnGroupInvitationList,
  } = useTradingApprovalHooks();
  const {onGroupInvitationChild} = useAppSelector(childProfileStore);

  const renderChildGroupList = React.useMemo((): JSX.Element | null => {
    return (
      <FlatList
        keyExtractor={(_, index) => `GroupInvitationchild-${index}`}
        data={groupInvitationChildListData}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.flatListContentContainerStyle}
        onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
        scrollEnabled
        onEndReached={() => onGroupInvitationChildListEndReach()}
        renderItem={({item, index}) => (
          <ChildGroupTile
            key={item.invitationId}
            index={index}
            item={item}
            onPressTick={() => {
              setIsApproveInvitation(true);
              setModalVisible(true);
              setGroupInvitation(item);
            }}
            onPressCross={() => {
              setIsApproveInvitation(false);
              setModalVisible(true);
              setGroupInvitation(item);
            }}
          />
        )}
        ListEmptyComponent={
          onGroupInvitationChild?.isLoading || initialLoader ? (
            <></>
          ) : (
            <View style={styles.noChildData}>
              <TopsListEmptyView
                emptyViewTitle={TEXT.SORRY_NO_INVITATIONS}
                emptyViewDesc={''}
                placeHolder={<NoChildData />}
              />
            </View>
          )
        }
        refreshControl={
          <RefreshControl
            refreshing={childProfileContextPullDownToRefresh}
            onRefresh={pullDownToRefreshOnGroupInvitationList}
          />
        }
      />
    );
  }, [groupInvitationChildListData, onGroupInvitationChild, initialLoader]);

  return <>{renderChildGroupList}</>;
};
export default React.memo(ChildGroupListContent);
