import React from 'react';
import {TouchableOpacity, Text, ColorValue} from 'react-native';
import {View} from 'native-base';
import {BottomTabBarProps} from '@react-navigation/bottom-tabs';

import {navigationStyle} from './navigation.styles';
import {ThemeContext} from '../contextAPI/themeContext';
import TabBarIcons from './tabBarIcons';
import {COLORS, navigationEmitType} from '../constants';
import {useAppDispatch} from '../hooks/reduxHooks';
import {onNotificationCountStart} from '../redux/reducers/notificationSlice';

const CustomeMobileTabBar = ({
  state,
  descriptors,
  navigation,
}: BottomTabBarProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = navigationStyle(ColorTheme);

  return (
    <>
      <View
        dataSet={{media: ids.mobileTabBarStyle}}
        style={styles.mobileTabBarStyle}
      >
        {state.routes.map((route, index) => {
          const {options} = descriptors[route.key];
          const label: string =
            options.title !== undefined ? options.title : route.name;
          const isFocused: boolean = state.index === index;
          const IconColors: ColorValue = isFocused
            ? COLORS.PRIMARY_BLUE
            : ColorTheme.color;

          const onPress = (): void => {
            const event = navigation.emit({
              type: navigationEmitType.TAB_PRESS,
              target: route.key,
              canPreventDefault: true,
            });

            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate(route.name);
              dispatch(onNotificationCountStart());
            }
          };

          const onLongPress = (): void => {
            navigation.emit({
              type: navigationEmitType.TAB_LONG_PRESS,
              target: route.key,
            });
          };

          return (
            <TouchableOpacity
              key={label}
              accessibilityState={isFocused ? {selected: true} : {}}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              onPress={onPress}
              onLongPress={onLongPress}
              style={styles.singleTabView}
            >
              <View style={styles.iconView} dataSet={{media: ids.iconView}}>
                <TabBarIcons
                  TabName={options.title}
                  stroke={IconColors}
                  focused={isFocused}
                />
              </View>
              <Text style={{...styles.labelText, color: IconColors}}>
                {label}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    </>
  );
};

export default React.memo(CustomeMobileTabBar);
