import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';

export const createGroupModalStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    subContainer: {
      width: Percentage.PRECENTAGE_100,
      backgroundColor: theme.backgroundColor,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_15),
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_18),
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_18,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_18,
      shadowColor: COLORS._333333,
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_0,
        height: -FixedValue.CONSTANT_VALUE_3,
      },
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
      elevation: FixedValue.CONSTANT_VALUE_3,
    },
    profilePlaceholderView: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.textAlignCenter,
      alignSelf: GlobalStyleValues.CENTER,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_27),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_31),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_108),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_108),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_71),
      overflow: GlobalStyleValues.HIDDEN,
    },
    profileNameText: {
      textAlign: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_36),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_43),
      color: theme?.backgroundColor,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      textTransform: GlobalStyleValues.UPPERCASE,
    },
    absoluteTextView: {
      ...BASESTYLE.absolutePosition,
      height: Percentage.PRECENTAGE_100,
      width: Percentage.PRECENTAGE_100,
      backgroundColor: theme?.color,
      ...BASESTYLE.inCenter,
    },
    imageBackgroundColor: {
      backgroundColor: theme.color,
    },
    plusIconStyle: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.textAlignCenter,
      color: theme.backgroundColor,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_55),
    },
   
  });
