import React from 'react';
import {View, Text} from 'react-native';

import {TEXT} from '../../constants';
import {IColorPelette} from '../../types/componentTypes/editorType';
import ColorCollections from './colorCollections';
import {styles} from './smallDesign.style';

const ColorPelette = (props: IColorPelette): JSX.Element => {
  const {onChangeFillColor, onChangeStrokeColor, upperRowText, lowerRowText} = props;
  const style = styles();

  return (
    <View style={style.peletteView}>
      <Text style={style.peletteText}>{upperRowText}</Text>
      <ColorCollections onSelectColor={onChangeFillColor} showTransparentColor/>
      <Text style={style.peletteText}>{lowerRowText}</Text>
      <ColorCollections onSelectColor={onChangeStrokeColor} />
    </View>
  );
};

export default ColorPelette;
