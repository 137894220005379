import {StyleSheet} from 'react-native';
import {
  FONT_NAMES,
  COLORS,
  FixedValue,
  GlobalStyleValues,
  Percentage,
  BASESTYLE,
} from '../../constants';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';
import {IColorTheme} from '../../types/contextTypes/themeContext';

export const styles = (theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      width: Percentage.PRECENTAGE_100,
      alignItems: GlobalStyleValues.FLEX_START,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_19),
    },
    labelText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      color: theme.labelColor,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_5),
    },
    textInputContainer: {
      ...BASESTYLE.inRow,
      width: Percentage.PRECENTAGE_100,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      borderColor: COLORS.C7C9D9,
    },
    inputStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      color: theme.color,
      fontFamily: FONT_NAMES.SF_REGULAR,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_44),
      backgroundColor: theme.backgroundColor,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_16),
      width: Percentage.PRECENTAGE_100,
      outlineStyle: GlobalStyleValues.NONE,
    },
    noteViewStyle: {
      width: Percentage.PRECENTAGE_100,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_134),
      borderColor: COLORS.C7C9D9,
    },
    noteStyle: {
      height: Percentage.PRECENTAGE_100,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    smallInputWidth: {
      width: Percentage.PRECENTAGE_85,
    },
    IconView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_44),
      width: Percentage.PRECENTAGE_15,
      backgroundColor: theme.backgroundColor,
    },
    defaultBorder: {
      borderWidth: FixedValue.CONSTANT_VALUE_1,
      backgroundColor: theme.backgroundColor,
    },
    focusBorder: {
      borderWidth: FixedValue.CONSTANT_VALUE_2,
      borderColor: COLORS.PRIMARY_BLUE,
    },
    errorBorder: {
      borderWidth: FixedValue.CONSTANT_VALUE_2,
      borderColor: COLORS.F54336,
    },
    otpContainer: {
      width: Percentage.PRECENTAGE_100,
      marginTop: FixedValue.CONSTANT_VALUE_3,
      height: FixedValue.CONSTANT_VALUE_50,
    },
    underlineStyleBase: {
      width: scaleHeight(FixedValue.CONSTANT_VALUE_50),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_50),
      borderColor: COLORS._1E1F1F,
      color: COLORS.PRIMARY_BLUE,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_30),
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    lightGreyBorder: {
      width: scaleHeight(FixedValue.CONSTANT_VALUE_50),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.PRIMARY_BLUE,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_50),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_30),
      borderColor: COLORS._1E1F1F,
    },
    redBorderColor: {
      width: scaleHeight(FixedValue.CONSTANT_VALUE_50),
      borderColor: COLORS._FF3D3D,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_50),
      color: COLORS.PRIMARY_BLUE,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_30),
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    underlineStyleHighLighted: {
      width: scaleHeight(FixedValue.CONSTANT_VALUE_50),
      borderColor: COLORS.PRIMARY_BLUE,
      borderWidth: FixedValue.CONSTANT_VALUE_2,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_50),
      color: COLORS.PRIMARY_BLUE,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_30),
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    errorContainer: {
      width: Percentage.PRECENTAGE_100,
    },
    errorText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      color: COLORS.F54336,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_5),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_5),
    },
    searchView: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_42),
      width: Percentage.PRECENTAGE_100,
      borderRadius: FixedValue.CONSTANT_VALUE_40,
      backgroundColor: COLORS.F3F7FF,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_16),
      ...BASESTYLE.inRow,
    },
    searchIconRight: {
      ...BASESTYLE.inCenter,
      alignItems: GlobalStyleValues.FLEX_END,
    },
    searchInputStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      color: COLORS._333333,
      fontFamily: FONT_NAMES.SF_REGULAR,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_42),
      backgroundColor: COLORS.F3F7FF,
      width: Percentage.PRECENTAGE_95,
      outlineStyle: GlobalStyleValues.NONE,
    },
    disabledBorderColor: {
      borderColor: COLORS.C7C9D9,
    },
    disabledBackgroundColor: {
      backgroundColor: theme.disabledBackgroundColor,
    },
    disabledTextColor: {
      color: theme.disabledTextColor,
    },
    iconViewPercent: {
      width: Percentage.PRECENTAGE_20,
    },
    inputPercent: {
      width: Percentage.PRECENTAGE_80,
    },
    textPaddingRight: {
      paddingRight: scaleWidth(FixedValue.CONSTANT_VALUE_35),
    },
  });
