import StyleSheet from 'react-native-media-query';
import {BASESTYLE, FixedValue, Percentage} from '../../../constants';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {isWebsite, scaleWidth} from '../../../utils/responsive';

export const tradeTabStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.selfCenter,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    container: {
      ...BASESTYLE.flex1,
    },
    tabBarStyle: {
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
  });
