/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import ScrollableContainer from '../../components/layout/scrollableContainer';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import LostPwdContentView from './lostPwdContentView';

const LostPassword: React.FC = () => {
  return (
    <ScrollableContainer>
      <SafeAreaContainer>
        <LostPwdContentView />
      </SafeAreaContainer>
    </ScrollableContainer>
  );
};

export default React.memo(LostPassword);
