import StyleSheet from 'react-native-media-query';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {
  BASESTYLE,
  COLORS,
  FONT_NAMES,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../../constants';
import {
  SCREEN_WIDTH,
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const TopBuyingDetailStyle = (theme?: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : SCREEN_WIDTH,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    headerstyle: {
      ...BASESTYLE.inRow,
    },
    headertext: {
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.flex1,
    },

    titleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: theme?.color,
    },
    container: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_24),
    },
    imageStyle: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_335),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_326),
      backgroundColor: theme?.color,
      ...BASESTYLE.inCenter,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
    },
    lable: {
      position: GlobalStyleValues.ABSOLUTE,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    topName: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_12),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_24),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: theme?.color,
    },
    createdDate: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: theme?.color,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_4),
    },
    hashTag: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_11),
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_11),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: COLORS.PRIMARY_BLUE,
    },
    note: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_BOLD,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_37),
      color: theme?.color,
    },
    desc: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_12),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_17),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: theme?.color,
    },
    approveButton: {
      ...BASESTYLE.inRow,
      ...BASESTYLE.center,
      top: scaleHeight(FixedValue.CONSTANT_VALUE_4),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_88),
    },
    primaryButtonStyle: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_145),
      borderRadius: FixedValue.CONSTANT_VALUE_46,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    primaryButtonStyle1: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_145),
      borderRadius: FixedValue.CONSTANT_VALUE_46,
    },
    primaryButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      marginRight:scaleWidth(FixedValue.CONSTANT_VALUE_10)
    },
    primaryButtonLabelstyle1: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.F54336,
      marginRight:scaleWidth(FixedValue.CONSTANT_VALUE_10)
    },
    noButtonBackGroundColor1: {
      backgroundColor: COLORS.FDE4E1,
    },
    noButtonBackGroundColor: {
      backgroundColor: COLORS._4EAF51,
    },
  });
