import React from 'react';
import {View, Text} from 'react-native';
import BackButton from '../../components/button/backButton';
import PrimaryButton from '../../components/button/primaryButton';
import {TEXT} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import { removeAllData } from '../../utils/helperFunctions';
import {parentConsentStyle} from './parentConsent.style';

const ParentConsentView: React.FC = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = parentConsentStyle(ColorTheme);
  const titleText = TEXT.THANKS_FOR_CREATING_ACCOUNT;

  const onSubmit = React.useCallback(() => removeAllData(), []);

  return (
    <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
      <BackButton onPress={onSubmit} />
      <Text style={styles.titleText}>{titleText}</Text>
      <Text style={styles.descText}>{TEXT.CONSENT_TEXT}</Text>
      <Text style={styles.descText}>{TEXT.CONSENT_TEXT_TWO}</Text>
      <Text style={styles.descText}>{TEXT.CONSENT_TEXT_FOUR}</Text>
      <View style={styles.buttonStyle}>
        <PrimaryButton
          buttonLabel={TEXT.LOG_IN_NOW}
          onPress={onSubmit}
          isDisabled={false}
        />
      </View>
    </View>
  );
};

export default React.memo(ParentConsentView);
