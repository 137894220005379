import StyleSheet from 'react-native-media-query';
import {BASESTYLE, FixedValue, Percentage} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {isWebsite, scaleHeight, scaleWidth} from '../../utils/responsive';

export const style = (_theme: IColorTheme) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.selfCenter,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    topHeader: {
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      width: Percentage.PRECENTAGE_100,
    },
    flatListContent: {
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    horizontalFlatListContent: {
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_25),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    tabBarStyle: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_22),
    },
  });
