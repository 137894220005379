import React from 'react';
import {Platform, Pressable, Text, TextInput, View} from 'react-native';
import {FixedValue} from '../../constants';
import {NUMBER_REGEX} from '../../constants/regex';
import {ThemeContext} from '../../contextAPI/themeContext';
import {IQuantityProps} from '../../types/componentTypes/smallDesignsType';
import {styles} from './smallDesign.style';

const QuantitySelector = (props: IQuantityProps): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  const {
    count,
    onPlusPress,
    onMinusPress,
    isInputQuantity,
    onChangeQuantity,
    setIskeyboardFocused,
    checkQuantityOnOnChange,
    textInputSupportDarkTheme = false,
  } = props;
  const [inputCount, setInputCount] = React.useState<string>(
    count?.toString() ?? ''
  );

  React.useEffect(() => {
    setInputCount(count?.toString() ?? '');
  }, [count]);

  const onUpdateQuantity = (val?: string) => {
    if (val !== undefined || val !== '') {
      onChangeQuantity && onChangeQuantity(Number(val));
    } else if (checkQuantityOnOnChange && !val) {
      onChangeQuantity && onChangeQuantity(Number(inputCount));
    } else {
      onChangeQuantity && onChangeQuantity(Number(inputCount));
    }
  };

  const getQuantityValue = () => {
    if (checkQuantityOnOnChange) {
      return count?.toString();
    } else {
      return inputCount;
    }
  };

  function formatNumber(input: string) {
    // Regular expression to match leading zeros
    const regex = /^0+(?=\d)/;
    // If the input is "0", return "0"
    if (input === '0') {
      return '0';
    }
    // Otherwise, remove leading zeros
    return input.replace(regex, '');
  }

  return (
    <Pressable
      style={
        props.quantityStyle ? props.quantityStyle : style.quantitySelectorView
      }
    >
      <Pressable
        style={props.minusStyle ? props.minusStyle : style.minuButton}
        onPress={onMinusPress}
      >
        <Text
          style={props.plusTextStyle ? props.plusTextStyle : style.plusText}
        >
          -
        </Text>
      </Pressable>
      {!isInputQuantity ? (
        <Text
          style={
            props.qunatityTextStyle
              ? props.qunatityTextStyle
              : style.quantityText
          }
        >
          {count}
        </Text>
      ) : (
        <TextInput
          value={getQuantityValue()}
          onChangeText={(text: string) => {
            const num = formatNumber(text);
            setInputCount(num);
            if (checkQuantityOnOnChange) {
              onUpdateQuantity(num);
            }
          }}
          onBlur={() => {
            if (!checkQuantityOnOnChange) {
              onUpdateQuantity(getQuantityValue());
            }
          }}
          autoCorrect={false}
          maxLength={FixedValue.CONSTANT_VALUE_3}
          keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'numeric'}
          style={[
            style.quantityInput,
            textInputSupportDarkTheme && {color: ColorTheme.color},
          ]}
          onFocus={() => setIskeyboardFocused && setIskeyboardFocused(true)}
        />
      )}
      <Pressable
        style={props.plusStyle ? props.plusStyle : style.plusButton}
        onPress={onPlusPress}
      >
        <Text
          style={props.plusTextStyle ? props.plusTextStyle : style.plusText}
        >
          +
        </Text>
      </Pressable>
    </Pressable>
  );
};

export default React.memo(QuantitySelector);
