import React from 'react';
import {View, Text} from 'react-native';
import PrimaryButton from '../../components/button/primaryButton';
import {TEXT} from '../../constants';
import {SignUpContext} from '../../contextAPI/signUpContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import useSignUpHooks from '../../hooks/componentHooks/useSignUpHooks';
import Basicform from './basicform';
import EmailMobileForm from './emailMobileForm';
import ParentialForm from './parentialForm';
import {signUpStyle} from './signUp.style';
import CountriesList from '../../modals/allCountriesList/countriesList';
import DatePicker from '../../components/smallDesigns/datePicker';
import TitleWithButton from '../../components/header/titleWithButton';

const SignUpContent = (): JSX.Element => {
  const {
    values,
    handleBlur,
    handleChange,
    moveToLogin,
    firstNameError,
    lastNameError,
    usernameError,
    passwordError,
    emailError,
    phoneError,
    parentUserNameErr,
    parentEmailErr,
    checkAllFieldIsValid,
    dobError,
    submitForm,
    errors,
    setFieldError,
    setIsFirstParentUserName,
  } = useSignUpHooks();
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = signUpStyle(ColorTheme);
  const {
    havePersonalEmail,
    haveParentsUsername,
    selectedCountry,
    setSelectedCountry,
    showCountry,
    setShowCountry,
    showCalender,
    showBackButton,
    isBtnLoading,
  } = React.useContext(SignUpContext);

  React.useEffect(() => {
    const initialCountryCode = {
      name: 'United States',
      flag: '🇺🇸',
      code: 'US',
      dial_code: '+1',
    };
    setSelectedCountry(initialCountryCode);
  }, []);

  return (
    <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
      {showBackButton && <TitleWithButton title={''} onPress={moveToLogin} />}
      <Text style={styles.titleText}>{TEXT.CREATE_FREE_ACCOUNT}</Text>
      <Text style={styles.titleTextTwo}>{TEXT.IT_IS_FREE}</Text>
      <Basicform
        values={values}
        handleBlur={handleBlur}
        handleChange={handleChange}
        firstNameError={firstNameError}
        lastNameError={lastNameError}
        usernameError={usernameError}
        passwordError={passwordError}
        dobError={dobError}
      />
      <EmailMobileForm
        values={values}
        handleBlur={handleBlur}
        handleChange={handleChange}
        havePersonalEmail={havePersonalEmail}
        emailError={emailError}
        phoneError={phoneError}
        onPress={() => setShowCountry(true)}
      />
      <ParentialForm
        values={values}
        handleBlur={handleBlur}
        handleChange={handleChange}
        haveParentsUsername={haveParentsUsername}
        parentUserNameErr={parentUserNameErr}
        parentEmailErr={parentEmailErr}
        errors={errors}
        setIsFirstParentUserName={setIsFirstParentUserName}
        setFieldError={setFieldError}
      />
      <View style={styles.bottomBtn}>
        <PrimaryButton
          buttonLabel={TEXT.LET_GO}
          onPress={submitForm}
          isDisabled={!checkAllFieldIsValid}
          isLoading={isBtnLoading}
        />
      </View>
      <View style={styles.bottomLine}>
        <Text style={styles.alreadyText}>
          {TEXT.ALREADY_HAVE_ACCOUNT}
          <Text onPress={moveToLogin} style={styles.loginText}>
            {TEXT.LOGIN}
          </Text>
        </Text>
      </View>
      <CountriesList
        onPress={() => setShowCountry(false)}
        showCountry={showCountry}
        selectCountryCode={selectedCountry?.dial_code}
        selectedCountryFunction={setSelectedCountry}
      />
      {showCalender && <DatePicker />}
    </View>
  );
};

export default React.memo(SignUpContent);
