import StyleSheet from 'react-native-media-query';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {
  BASESTYLE,
  COLORS,
  FONT_NAMES,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../../constants';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const TopSettingStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    settingMainContainer: {
      ...BASESTYLE.selfCenter,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    topTradingstyle: {
      ...BASESTYLE.inRow,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    topTradingtextView: {
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.flex1,
    },
    settingTitleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: _theme?.color,
    },
    settingContainer: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_16),
      ...BASESTYLE.flex1,
    },
    selectView: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.alignCenter,
      alignSelf: GlobalStyleValues.CENTER,
      backgroundColor: COLORS._FEFAE6,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_27),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_25),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_213),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_28),
    },
    oneOption: {
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.textAlignCenter,
      ...BASESTYLE.center,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
    },
    allTradeView: {
      ...BASESTYLE.row,
    },
    ellipseView: {
      ...BASESTYLE.row,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_22),
    },
    allTrade: {
      color: _theme?.color,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
    },
    buttonStyle: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_179),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
    },
    buttonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
    buttonView: {
      ...BASESTYLE.alignCenter,
      position: GlobalStyleValues.ABSOLUTE,
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_44),
      left: scaleWidth(FixedValue.CONSTANT_VALUE_0),
      right: scaleWidth(FixedValue.CONSTANT_VALUE_0),
    },
  });
