import React from 'react';
import {View, Text} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {notesCellStyle} from './notesCell.style';
import {COLORS, FixedValue, TEXT} from '../../constants';
import DeleteImage from '../renderSvgs/deleteBin';
import EditPencil from '../renderSvgs/editPencil';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import {NotesModel} from '../../types/reducerTypes/searchTabStoreType';
import {Pressable} from 'react-native';
import {formatUTCTimeToLocalTime} from '../../utils/helperFunctions';
import {timeformat} from '../../constants/enum';

interface INotesCeleData {
  item: NotesModel;
  setOpenCloseNotesModal: (val: boolean) => void;
  onCallEditNotes: (val: NotesModel) => void;
  onDeleteNotesAlert: (val: NotesModel) => void;
}

const NotesCell = (props: INotesCeleData) => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const styles = notesCellStyle(ColorTheme);
  const {item, onCallEditNotes, onDeleteNotesAlert} = props;
  const editModalOpen = () => {
    onCallEditNotes(item);
  };

  return (
    <>
      <View style={styles.notesCellView}>
        <View style={styles.notesTextContainer}>
          <Text style={styles.addedByText}>
            {TEXT.ADDED_BY}
            {item?.self ? 'You' : item.createdBy}
          </Text>
          {item?.self && (
            <View style={styles.iconView}>
              <Pressable onPress={editModalOpen} style={styles.editIconView}>
                <EditPencil
                  height={FixedValue.CONSTANT_VALUE_12}
                  width={FixedValue.CONSTANT_VALUE_12}
                  stroke={COLORS.PRIMARY_BLUE}
                />
              </Pressable>
              <Pressable
                onPress={() => onDeleteNotesAlert(item)}
                style={styles.deleteBinView}
              >
                <DeleteImage
                  stroke={COLORS._FF3D3D}
                  width={scaleWidth(FixedValue.CONSTANT_VALUE_12)}
                  height={scaleHeight(FixedValue.CONSTANT_VALUE_12)}
                />
              </Pressable>
            </View>
          )}
        </View>
        <Text style={styles.noteText}>{item?.noteText}</Text>
      </View>
      <Text style={styles.dateText}>
        {formatUTCTimeToLocalTime(
          item?.creationDate,
          timeformat.MMM_DD_YYYY_H_MM_A
        )}
      </Text>
    </>
  );
};

export default NotesCell;
