import React from 'react';
import {View, Text} from 'react-native';

import BackButton from '../../components/button/backButton';
import PrimaryButton from '../../components/button/primaryButton';
import LabelInput from '../../components/input/labelInput';
import username from '../../components/renderSvgs/username';
import {FixedValue, TEXT} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import useLostPwdHooks from '../../hooks/componentHooks/useLostPwdHooks';
import {lostPwdStyle} from './lostPwd.style';

const LostPwdContentView: React.FC = () => {
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    _moveToLogin,
    UsernameError,
    isBtnLoading
  } = useLostPwdHooks();
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = lostPwdStyle(ColorTheme);

  return (
    <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
      <BackButton />
      <Text style={styles.titleText}>{TEXT.LOST_N_PASSWORD}</Text>
      <Text style={styles.descText}>{TEXT.LOST_PASSWORD_DESC}</Text>
      <LabelInput
        inputLabel={TEXT.USERNAME}
        value={values.Username}
        placeholder={TEXT.ENTER_USERNAME}
        onChangeText={handleChange(TEXT.USERNAME_)}
        onBlur={handleBlur(TEXT.USERNAME_)}
        Icon={username}
        haveError={UsernameError}
        maxLength={FixedValue.CONSTANT_VALUE_30}
      />
      <View style={styles.buttonViewStyle} />
      <PrimaryButton
        buttonLabel={TEXT.SEND_OTP}
        onPress={handleSubmit}
        isDisabled={!isValid}
        isLoading={isBtnLoading}
      />
      <Text style={styles.dontHaveAccount}>
        {TEXT.HAVE_ACCOUNT}{' '}
        <Text onPress={_moveToLogin} style={styles.signUpText}>
          {TEXT.LOGIN_IN}
        </Text>
      </Text>
    </View>
  );
};

export default React.memo(LostPwdContentView);
