import {StyleSheet} from 'react-native';
import {
  BASESTYLE,
  COLORS,
  FONT_NAMES,
  FixedValue,
  Percentage,
} from '../../../constants';
import {
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const styles = StyleSheet.create({
  container: {
    width: Percentage.PRECENTAGE_100,
    height: scaleHeight(FixedValue.CONSTANT_VALUE_40),
    ...BASESTYLE.inRow,
  },
  logoView: {
    marginHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_16),
  },
  itemTypeText: {
    fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
    fontFamily: FONT_NAMES.SF_SEMIBOLD,
    color: COLORS.BLACK,
  },
});
