import {createSlice} from '@reduxjs/toolkit';
import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {IResetPasswordStateType} from '../../types/reducerTypes/authStoreTypes';
import {ResetAllState} from './logoutslice';
import {FORGOT_USERNAME} from '../../constants/actionTypes';

const {StoreConstants} = AppConst;
const {USER_LOGIN, RESET_PASSWORD, CHANGE_PASSWORD} = ActionType;

const initialState: IResetPasswordStateType = {
  resetPassword: {
    isLoading: false,
    success: null,
    failed: null,
    status: USER_LOGIN.END,
  },
  changePassword: {
    isLoading: false,
    success: null,
    failed: null,
    status: CHANGE_PASSWORD.END,
  },
  forgotUserNameData: {
    isLoading: false,
    success: null,
    failed: null,
    status: FORGOT_USERNAME.END,
  },
};

const CreateNewPasswordSlice = createSlice({
  initialState,
  name: StoreConstants.RESET_PASSWORD,
  reducers: {
    onResetPasswordStart: (state, _) => {
      state.resetPassword.isLoading = true;
      state.resetPassword.status = RESET_PASSWORD.START;
    },
    onResetPasswordSuccess: (state, action) => {
      state.resetPassword.success = action.payload;
      state.resetPassword.status = RESET_PASSWORD.SUCCESS;
    },
    onResetPasswordFailed: (state, action) => {
      state.resetPassword.failed = action.payload;
      state.resetPassword.status = RESET_PASSWORD.FAILED;
    },
    onResetPasswordEnd: state => {
      state.resetPassword.isLoading = false;
      state.resetPassword.status = RESET_PASSWORD.END;
    },
    onChangePasswordStart: (state, _) => {
      state.changePassword.isLoading = true;
      state.changePassword.status = CHANGE_PASSWORD.START;
    },
    onChangePasswordSuccess: (state, action) => {
      state.changePassword.success = action.payload;
      state.changePassword.status = CHANGE_PASSWORD.SUCCESS;
    },
    onChangePasswordFailed: (state, action) => {
      state.changePassword.failed = action.payload;
      state.changePassword.status = CHANGE_PASSWORD.FAILED;
    },
    onChangePasswordEnd: state => {
      state.changePassword.isLoading = false;
      state.changePassword.status = CHANGE_PASSWORD.END;
    },
    onForgotUserNameStart: (state, _) => {
      state.forgotUserNameData.isLoading = true;
      state.forgotUserNameData.status = FORGOT_USERNAME.START;
    },
    onForgotUserNameSuccess: (state, action) => {
      state.forgotUserNameData.success = action.payload;
      state.forgotUserNameData.status = FORGOT_USERNAME.SUCCESS;
    },
    onForgotUserNameFailed: (state, action) => {
      state.forgotUserNameData.failed = action.payload;
      state.forgotUserNameData.status = FORGOT_USERNAME.FAILED;
    },
    onForgotUserNameEnd: state => {
      state.forgotUserNameData.isLoading = false;
      state.forgotUserNameData.status = FORGOT_USERNAME.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  onResetPasswordStart,
  onResetPasswordSuccess,
  onResetPasswordFailed,
  onResetPasswordEnd,
  onChangePasswordStart,
  onChangePasswordSuccess,
  onChangePasswordFailed,
  onChangePasswordEnd,
  onForgotUserNameStart,
  onForgotUserNameSuccess,
  onForgotUserNameFailed,
  onForgotUserNameEnd,
} = CreateNewPasswordSlice.actions;
export default CreateNewPasswordSlice.reducer;
export const newPasswordState = (state: RootState) => state.newPassword;
