import React from 'react';
import {Text, View} from 'react-native';

import {tagStyle} from './tag.styles';
interface TagViewProps {
  value: string;
}
const TagView = ({ value }: TagViewProps): JSX.Element => {
    return (
    <View style={tagStyle.container}>
      <Text style={tagStyle.tagText}>{value}</Text>
    </View>
  );
};

export default React.memo(TagView);
