import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';

const BurgerMenu = (props: SvgProps): JSX.Element => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    //@ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M3 18H21"
      stroke="black"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M3 12H21"
      stroke="black"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M3 6H21"
      stroke="black"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default BurgerMenu;
