import * as React from 'react';
import Svg, {Path, G, Defs, ClipPath, Rect, SvgProps} from 'react-native-svg';
const TransactionEmptyImage = (props: SvgProps) => (
  <Svg
    width={250}
    height={201}
    viewBox="0 0 250 201"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63 134.5H154C154.515 134.5 155.017 134.444 155.5 134.339C155.983 134.444 156.485 134.5 157 134.5H209C212.866 134.5 216 131.366 216 127.5C216 123.634 212.866 120.5 209 120.5H203C199.134 120.5 196 117.366 196 113.5C196 109.634 199.134 106.5 203 106.5H222C225.866 106.5 229 103.366 229 99.5C229 95.634 225.866 92.5 222 92.5H200C203.866 92.5 207 89.366 207 85.5C207 81.634 203.866 78.5 200 78.5H136C139.866 78.5 143 75.366 143 71.5C143 67.634 139.866 64.5 136 64.5H79C75.134 64.5 72 67.634 72 71.5C72 75.366 75.134 78.5 79 78.5H39C35.134 78.5 32 81.634 32 85.5C32 89.366 35.134 92.5 39 92.5H64C67.866 92.5 71 95.634 71 99.5C71 103.366 67.866 106.5 64 106.5H24C20.134 106.5 17 109.634 17 113.5C17 117.366 20.134 120.5 24 120.5H63C59.134 120.5 56 123.634 56 127.5C56 131.366 59.134 134.5 63 134.5ZM226 134.5C229.866 134.5 233 131.366 233 127.5C233 123.634 229.866 120.5 226 120.5C222.134 120.5 219 123.634 219 127.5C219 131.366 222.134 134.5 226 134.5Z"
      fill="#F3F7FF"
    />
    <Path
      d="M102 67.8176C102.184 67.8144 102.368 67.8127 102.553 67.8127C118.787 67.8127 132.08 80.4696 133.194 96.5C146.116 94.4618 156 83.2233 156 69.6649C156 54.6622 143.898 42.5 128.969 42.5C114.658 42.5 102.945 53.6764 102 67.8176Z"
      fill="#E8F0FE"
    />
    <Path
      d="M137.218 121.25C136.383 122.69 135.184 123.885 133.742 124.715C132.3 125.546 130.665 125.983 129.001 125.983C127.336 125.983 125.701 125.546 124.259 124.715C122.817 123.885 121.618 122.69 120.783 121.25"
      stroke="#1F64E7"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M89.5 148.5V152.5"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M89.5 160.5V164.5"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M81.5 156.5H85.5"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M93.5 156.5H97.5"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.711 131.626C162.102 129.923 163.352 128.142 164.461 126.299C166.901 122.244 168.66 117.888 169.734 113.397C170.821 108.856 171.209 104.176 170.894 99.5296C170.62 95.4756 169.811 91.4475 168.465 87.5598C167.131 83.7028 165.268 79.984 162.875 76.515C161.45 74.4479 159.836 72.4696 158.034 70.6036C155.388 67.864 152.486 65.5219 149.402 63.5787C146.089 61.4908 142.566 59.8634 138.926 58.6984C134.368 57.2393 129.628 56.5054 124.886 56.5C121.197 56.4959 117.507 56.9328 113.902 57.8125C109.845 58.8028 105.895 60.3543 102.177 62.4692C98.9076 64.3284 95.817 66.623 92.988 69.3547C89.4328 72.7875 86.553 76.6572 84.3512 80.7985C82.1956 84.8531 80.6899 89.1682 79.8366 93.5887C78.9574 98.1429 78.7706 102.809 79.2789 107.418C79.9378 113.391 81.7643 119.268 84.7644 124.679C86.684 128.141 89.0842 131.412 91.9663 134.396C96.5416 139.134 101.883 142.683 107.604 145.035C114.562 147.897 122.081 148.989 129.465 148.3C135.662 147.721 141.765 145.887 147.361 142.79"
      fill="white"
    />
    <Path
      d="M160.711 131.626C162.102 129.923 163.352 128.142 164.461 126.299C166.901 122.244 168.66 117.888 169.734 113.397C170.821 108.856 171.209 104.176 170.894 99.5296C170.62 95.4756 169.811 91.4475 168.465 87.5598C167.131 83.7028 165.268 79.984 162.875 76.515C161.45 74.4479 159.836 72.4696 158.034 70.6036C155.388 67.864 152.486 65.5219 149.402 63.5787C146.089 61.4908 142.566 59.8634 138.926 58.6984C134.368 57.2393 129.628 56.5054 124.886 56.5C121.197 56.4959 117.507 56.9328 113.902 57.8125C109.845 58.8028 105.895 60.3543 102.177 62.4692C98.9076 64.3284 95.817 66.623 92.988 69.3547C89.4328 72.7875 86.553 76.6572 84.3512 80.7985C82.1956 84.8531 80.6899 89.1682 79.8366 93.5887C78.9574 98.1429 78.7706 102.809 79.2789 107.418C79.9378 113.391 81.7643 119.268 84.7644 124.679C86.684 128.141 89.0842 131.412 91.9663 134.396C96.5416 139.134 101.883 142.683 107.604 145.035C114.562 147.897 122.081 148.989 129.465 148.3C135.662 147.721 141.765 145.887 147.361 142.79"
      stroke="#1F64E7"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.701 140.878C153.607 139.008 156.191 136.675 157.872 134.791L150.701 140.878Z"
      fill="white"
    />
    <Path
      d="M150.701 140.878C153.607 139.008 156.191 136.675 157.872 134.791"
      stroke="#1F64E7"
      strokeWidth={2.5}
      strokeLinecap="round"
    />
    <Path
      d="M167 105.117C166.714 105.122 166.427 105.125 166.14 105.125C140.886 105.125 120.209 85.4362 118.476 60.5C98.3752 63.6706 83 81.1526 83 102.243C83 125.581 101.825 144.5 125.048 144.5C147.309 144.5 165.53 127.115 167 105.117Z"
      fill="#E8F0FE"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110.824 64.9131C108.874 65.6447 106.969 66.5338 105.128 67.5806C102.269 69.2072 99.5648 71.2149 97.0899 73.6049C96.0754 74.5846 95.1237 75.605 94.235 76.661M91.4851 80.3378C90.7785 81.4057 90.1281 82.5004 89.5342 83.6176C89.0956 84.4428 88.6877 85.2803 88.3106 86.1285"
      fill="white"
    />
    <Path
      d="M111.263 66.0835C111.909 65.841 112.237 65.1204 111.994 64.474C111.752 63.8277 111.031 63.5003 110.385 63.7428L111.263 66.0835ZM105.128 67.5806L105.746 68.6671L105.128 67.5806ZM97.0899 73.6049L96.2216 72.7057L97.0899 73.6049ZM93.2786 75.8562C92.8341 76.3844 92.902 77.1729 93.4302 77.6174C93.9584 78.0619 94.7469 77.9941 95.1914 77.4659L93.2786 75.8562ZM92.5275 81.0277C92.9085 80.4519 92.7507 79.6764 92.1749 79.2954C91.5992 78.9144 90.8237 79.0723 90.4427 79.648L92.5275 81.0277ZM89.5342 83.6176L90.638 84.2044L90.638 84.2044L89.5342 83.6176ZM87.1684 85.6206C86.8879 86.2515 87.1719 86.9902 87.8027 87.2707C88.4335 87.5511 89.1723 87.2671 89.4528 86.6363L87.1684 85.6206ZM110.385 63.7428C108.373 64.4973 106.409 65.4143 104.51 66.494L105.746 68.6671C107.529 67.6532 109.374 66.7921 111.263 66.0835L110.385 63.7428ZM104.51 66.494C101.56 68.172 98.7724 70.2424 96.2216 72.7057L97.9582 74.5041C100.357 72.1874 102.977 70.2425 105.746 68.6671L104.51 66.494ZM96.2216 72.7057C95.176 73.7154 94.195 74.7673 93.2786 75.8562L95.1914 77.4659C96.0525 76.4427 96.9747 75.4538 97.9582 74.5041L96.2216 72.7057ZM90.4427 79.648C89.7138 80.7494 89.0431 81.8785 88.4305 83.0309L90.638 84.2044C91.2132 83.1222 91.8431 82.062 92.5275 81.0277L90.4427 79.648ZM88.4305 83.0309C87.978 83.882 87.5573 84.7459 87.1684 85.6206L89.4528 86.6363C89.818 85.8148 90.2131 85.0036 90.638 84.2044L88.4305 83.0309Z"
      fill="#75A4FE"
    />
    <G clipPath="url(#clip0_7630_15507)">
      <Path
        d="M142.725 85.3069V91.3705C141.233 88.9879 139.269 86.9226 136.923 85.2891C133.402 82.837 129.265 81.5409 124.959 81.5409C119.361 81.5409 114.097 83.721 110.139 87.6796C106.18 91.6384 104 96.9016 104 102.5H107.275C107.275 92.7488 115.208 84.8157 124.959 84.8157C131.333 84.8157 137.046 88.1441 140.189 93.4941H134.538V96.7689H146V85.3069H142.725ZM125.041 120.184C118.667 120.184 112.954 116.856 109.811 111.506H115.462V108.231H104V119.693H107.275V113.629C108.767 116.012 110.731 118.077 113.077 119.711C116.598 122.163 120.735 123.459 125.041 123.459C130.636 123.459 135.886 121.278 139.824 117.317C143.754 113.364 145.918 108.102 145.918 102.5H142.643C142.643 112.251 134.747 120.184 125.041 120.184Z"
        fill="#1F53D3"
      />
      <Path
        d="M122.75 104.707V106.096C122.75 107.613 124.035 108.944 125.5 108.944C126.991 108.944 128.25 107.651 128.25 106.12C128.25 104.802 126.973 104.063 124.891 102.966L124.864 102.952C123.276 102.116 120.619 100.717 120.619 97.2519C120.619 95.0587 122.101 93.203 124.125 92.6132V89.5H126.875V92.6143C128.899 93.2072 130.381 95.0728 130.381 97.2778V98.6667H127.631V97.2778C127.631 96.129 126.675 95.1944 125.5 95.1944C124.325 95.1944 123.369 96.1175 123.369 97.252C123.369 98.7753 124.222 99.4817 126.136 100.489L126.162 100.503C127.252 101.077 128.38 101.671 129.269 102.463C130.418 103.487 131 104.717 131 106.12C131 107.59 130.427 108.987 129.387 110.054C128.676 110.784 127.812 111.29 126.875 111.541V114.5H124.125V111.536C121.783 110.893 120 108.662 120 106.096V104.707H122.75Z"
        fill="#1F53D3"
      />
    </G>
    <Path
      d="M172.5 40.5V47.5"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M172.5 56.5V64.5"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M161 52H168"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M177 52H184"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Defs>
      <ClipPath id="clip0_7630_15507">
        <Rect
          width={42}
          height={42}
          fill="white"
          transform="translate(104 81.5)"
        />
      </ClipPath>
    </Defs>
  </Svg>
);
export default TransactionEmptyImage;
