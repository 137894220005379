import React from 'react';
import {Keyboard, Text, View} from 'react-native';
import {useIsFocused} from '@react-navigation/native';
import TitleWithButton from '../../components/header/titleWithButton';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import {HomeContext} from '../../contextAPI/homeContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import {allTopsStyle} from '../seeAllTops/seeAll.styles';
import {FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import PrimaryButton from '../../components/button/primaryButton';
import SelectResultTopsList from './selectResultTopsList';
import {navigate} from '../../services/navigationService';
import {_ToastHandler} from '../../utils/helperFunctions';
import {useAppDispatch} from '../../hooks/reduxHooks';

const MyCartTops = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = allTopsStyle(ColorTheme);
  const {
    selectedTopsResult,
    setTotalTopsAmount,
    topAmount,
    setApplyCouponCodeData,
    setDiscountPrice,
  } = React.useContext(HomeContext);
  const isFocused = useIsFocused();
  const dispatch = useAppDispatch();
  const [iskeyboardFocused, setIskeyboardFocused] =
    React.useState<boolean>(false);
  const totalQuantity = selectedTopsResult.reduce(
    (total, top) =>
      total + (top?.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0),
    FixedValue.CONSTANT_VALUE_0
  );

  React.useEffect(() => {
    setApplyCouponCodeData(null);
    setDiscountPrice(FixedValue.CONSTANT_VALUE_0);
  }, []);

  React.useEffect(() => {
    if (isFocused) {
      const totalCount = selectedTopsResult.reduce(
        (total, values) => values.selectedQuantity! + total,
        FixedValue.CONSTANT_VALUE_0
      );
      setTotalTopsAmount(
        Number((totalCount * topAmount).toFixed(FixedValue.CONSTANT_VALUE_2))
      );
    }
  }, [isFocused, topAmount]);

  const checkIsKeyboardIsOpen = () => {
    if (!iskeyboardFocused) {
      navigate(ROUTE_NAME.ORDER_SUMMARY, {
        totalTopQuantity: totalQuantity,
      });
    } else {
      Keyboard.dismiss();
    }
  };

  return (
    <SafeAreaContainer>
      <View style={styles.container} dataSet={{media: ids.container}}>
        <View style={styles.topHeader}>
          <TitleWithButton title={TEXT.MY_CART} />
        </View>
        <SelectResultTopsList setIskeyboardFocused={setIskeyboardFocused} />
        {selectedTopsResult.length > FixedValue.CONSTANT_VALUE_0 && (
          <>
            <View style={styles.totalTopstext}>
              <Text style={styles.totalTopstext}>
                {TEXT.TOTAL_TOPS_IN_CART}
                {totalQuantity}
              </Text>
            </View>
            <View style={styles.nextButtonView}>
              <PrimaryButton
                buttonLabel={TEXT.CHECKOUT}
                buttonwidth={styles.butTopButton}
                labelStyle={styles.nextButtonTextStyle}
                onPress={() => checkIsKeyboardIsOpen()}
              />
            </View>
          </>
        )}
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(MyCartTops);
