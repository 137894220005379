import React from 'react';
import {View, FlatList, Modal, Pressable, Keyboard} from 'react-native';

import {ThemeContext} from '../../contextAPI/themeContext';
import {countryListStyle} from './countriesList.style';
import countryCodeListData from '../../utils/countrycode.json';
import {ICountry} from '../../types/componentTypes/countryType';
import SearchInput from '../../components/input/searchInput';
import {FixedValue, GlobalStyleValues} from '../../constants';
import CountryCodeTile from '../../components/RenderItemTile/countryCodeTile';
import KeyboardAvoid from '../../components/layout/keyboardAvoid';
import {ICountryType} from '../../types/reducerTypes/authStoreTypes';

const CountriesList = (props: ICountry): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = countryListStyle(ColorTheme);
  const {showCountry, onPress, selectedCountryFunction} = props;
  const [filteredData, setFilterdata] =
    React.useState<ICountryType[]>(countryCodeListData);
  const [searchText, setSearchtext] = React.useState<string>('');

  const onChangeSearchText = React.useCallback(
    (val: string): void => {
      setSearchtext(val);
      if (val.length > FixedValue.CONSTANT_VALUE_0) {
        const filterData: ICountryType[] = countryCodeListData.filter(item =>
          item.name.toLowerCase().includes(val.toLowerCase())
        );
        setFilterdata(filterData);
      } else {
        setFilterdata(countryCodeListData);
      }
    },
    [searchText, filteredData]
  );
  return (
    <Modal visible={showCountry} transparent onRequestClose={onPress}>
      <KeyboardAvoid>
        <View style={styles.modalStyle} dataSet={{media: ids.modalStyle}}>
          <Pressable style={styles.outterPart} onPress={onPress} />
          <View style={styles.innerView}>
            <SearchInput value={searchText} onChangeText={onChangeSearchText} />
            <FlatList
              keyExtractor={(item: ICountryType) => `country-${item.name}`}
              keyboardShouldPersistTaps={GlobalStyleValues.HANDLED}
              contentContainerStyle={styles.contentContainerStyle}
              data={filteredData}
              renderItem={({item}) => (
                <CountryCodeTile
                  country={item}
                  onPress={selectedCountryFunction}
                />
              )}
              onScrollBeginDrag={() => Keyboard.dismiss()}
            />
          </View>
        </View>
      </KeyboardAvoid>
    </Modal>
  );
};

export default React.memo(CountriesList);
