import React from 'react';
import {
  RECENT_SEARCH_LIST,
  SEARCH_TOP_LIST,
  USER_SEARCH_LIST,
} from '../constants/actionTypes';
import {useAppSelector} from '../hooks/reduxHooks';
import {searchTabStore} from '../redux/reducers/searchTabSlice';
import {
  ISearchTabContext,
  ISearchTabProvider,
} from '../types/contextTypes/searchTabContext';
import {
  CustomUserListSearchModalListData,
  TopModel,
} from '../types/reducerTypes/searchTabStoreType';
import {FixedValue} from '../constants';

const SearchTabContext = React.createContext<ISearchTabContext>({
  searchText: '',
  setSearchText: _value => {},
  searchResult: [],
  setSearchResult: _val => {},
  recentSearchResult: [],
  setRecentSearchResult: _val => {},
  clearSearchResult: () => {},
  isSearchText: false,
  setIsSearchText: _value => {},
  userSearchResult: [],
  setUserSearchResult: _val => {},
  searchUserDetail: null,
  setSearchUserDetail: _val => {},
});

const SearchTabProvider = (props: ISearchTabProvider): JSX.Element => {
  const {searchTopList, recentTopData, userUserSearchList} =
    useAppSelector(searchTabStore);
  const [searchText, setSearchText] = React.useState<string>('');
  const [searchResult, setSearchResult] = React.useState<TopModel[]>([]);
  const [userSearchResult, setUserSearchResult] = React.useState<
    CustomUserListSearchModalListData[]
  >([]);
  const [searchUserDetail, setSearchUserDetail] =
    React.useState<CustomUserListSearchModalListData | null>(null);
  const [recentSearchResult, setRecentSearchResult] = React.useState<
    TopModel[]
  >([]);
  const [isSearchText, setIsSearchText] = React.useState<boolean>(false);

  const _setSearchText = React.useCallback(
    (value: string): void => {
      setSearchText(value);
      setIsSearchText(true);
      _clearSearchResult();
    },
    [searchText]
  );

  const _setSearchResult = React.useCallback(
    (NewData: TopModel[]): void => {
      setSearchResult(prev => [...prev, ...NewData]);
    },
    [searchResult]
  );

  const _setRecentSearchResult = React.useCallback(
    (NewData: TopModel[]): void => {
      setRecentSearchResult([...NewData]);
    },
    [recentSearchResult]
  );

  const _clearSearchResult = React.useCallback((): void => {
    setSearchResult([]);
  }, []);

  const _setIsSearchText = React.useCallback(
    (value: boolean): void => {
      setIsSearchText(value);
    },
    [isSearchText]
  );

  React.useEffect(() => {
    if (searchTopList.status === SEARCH_TOP_LIST.SUCCESS) {
      _setSearchResult(searchTopList.success?.data?.results ?? []);
      setIsSearchText(false);
    }
  }, [searchTopList.status]);

  React.useEffect(() => {
    if (recentTopData.status === RECENT_SEARCH_LIST.SUCCESS) {
      _setRecentSearchResult(recentTopData.success?.data?.results ?? []);
    }
  }, [recentTopData.status]);

  const _setUserSearchResult = React.useCallback(
    (NewData: CustomUserListSearchModalListData[]): void => {
      setUserSearchResult(prev => [...prev, ...NewData]);
    },
    [userSearchResult]
  );

  React.useEffect(() => {
    switch (userUserSearchList.status) {
      case USER_SEARCH_LIST.SUCCESS:
        if (
          userUserSearchList.success?.pageNumber === FixedValue.CONSTANT_VALUE_0
        ) {
          setUserSearchResult(userUserSearchList.success?.data?.results ?? []);
        } else {
          _setUserSearchResult(userUserSearchList.success?.data?.results ?? []);
        }
        setIsSearchText(false);
        break;
      case USER_SEARCH_LIST.FAILED:
        setIsSearchText(false);
        break;
      default:
        break;
    }
  }, [userUserSearchList.status]);

  const _setSearchUserDetail = React.useCallback(
    (value: CustomUserListSearchModalListData | null): void => {
      setSearchUserDetail(value);
    },
    [searchUserDetail]
  );

  const values = React.useMemo(
    (): ISearchTabContext => ({
      searchText,
      searchResult,
      recentSearchResult,
      isSearchText,
      userSearchResult,
      searchUserDetail,
      setSearchText: _setSearchText,
      setRecentSearchResult: _setRecentSearchResult,
      setSearchResult: _setSearchResult,
      clearSearchResult: _clearSearchResult,
      setIsSearchText: _setIsSearchText,
      setUserSearchResult: _setUserSearchResult,
      setSearchUserDetail: _setSearchUserDetail,
    }),
    [
      searchText,
      setSearchText,
      searchResult,
      setSearchResult,
      recentSearchResult,
      setRecentSearchResult,
      isSearchText,
      setIsSearchText,
      userSearchResult,
      setUserSearchResult,
      searchUserDetail,
      setSearchUserDetail,
    ]
  );
  return (
    <SearchTabContext.Provider value={values}>
      {props.children}
    </SearchTabContext.Provider>
  );
};

export {SearchTabContext, SearchTabProvider};
