import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  FONTS,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';

export const style = (theme: IColorTheme) =>
  StyleSheet.create({
    subContainer: {
      width: Percentage.PRECENTAGE_100,
      backgroundColor: theme.backgroundColor,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_15),
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_18),
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_18,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_18,
      shadowColor: COLORS._333333,
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_0,
        height: -FixedValue.CONSTANT_VALUE_3,
      },
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
      elevation: FixedValue.CONSTANT_VALUE_3,
       maxHeight:'80%'
    },
    profilePlaceholderView: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.textAlignCenter,
      alignSelf: GlobalStyleValues.CENTER,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_27),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_31),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_108),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_108),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_71),
      overflow: GlobalStyleValues.HIDDEN,
    },
    profileNameText: {
      textAlign: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      color: theme?.backgroundColor,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      textTransform: GlobalStyleValues.UPPERCASE,
    },
    absoluteTextView: {
      ...BASESTYLE.absolutePosition,
      height: Percentage.PRECENTAGE_100,
      width: Percentage.PRECENTAGE_100,
      backgroundColor: theme?.color,
      ...BASESTYLE.inCenter,
    },
    imageBackgroundColor: {
      backgroundColor: theme.color,
    },
    plusIconStyle: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.textAlignCenter,
      color: theme.backgroundColor,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_55),
    },
    container: {
      ...BASESTYLE.inCenter,
      backgroundColor: COLORS._DAE6FC,
      flexDirection: GlobalStyleValues.ROW,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_8),
    },
    content: {
      height: Percentage.PRECENTAGE_100,
      ...BASESTYLE.flex1,
      ...BASESTYLE.inRow,
      paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    myWalletText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_12),
      color: COLORS.BLACK,
      fontFamily: FONTS.SF_MEDIUM,
      paddingVertical: scaleHeight(FixedValue.CONSTANT_VALUE_14),
    },
    topCreditText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_12),
      color: COLORS.BLACK,
      fontFamily: FONTS.SF_BOLD,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_4),
    },
    topcreditsText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_10),
    },
    marginTop22: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_22),
    },
    mainViewStyle: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      backgroundColor: theme?.color,
      ...BASESTYLE.inCenter,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_58),
      overflow: GlobalStyleValues.HIDDEN,
    },
    profileImageContainer: {
      ...BASESTYLE.inCenter,
    },
    selectedChildName: {
      textAlign: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      color: theme?.color,
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_5),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    howManyText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      color: theme?.color,
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_15),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      marginBottom: -scaleHeight(FixedValue.CONSTANT_VALUE_5),
    },
  });
