import React from 'react';
import {View, Text, Pressable} from 'react-native';

import {TEXT} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {ICheckoxWithText} from '../../types/componentTypes/smallDesignsType';
import BlueCheck from '../renderSvgs/blueCheck';
import UncheckBox from '../renderSvgs/uncheckBox';
import {styles} from './smallDesign.style';

const CheckboxWithTerm = (props: ICheckoxWithText) => {
  const {isAccept, onPress, onPressTerms, highLightText, firstText} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  return (
    <View style={style.termView}>
      <Pressable onPress={onPress}>
        {isAccept ? <BlueCheck /> : <UncheckBox />}
      </Pressable>
      <Text style={style.termCondText}>
        {firstText ? firstText : TEXT.TERM_FIRST_LINE}
        <Text style={style.decorateTerm} onPress={onPressTerms}>
          {highLightText ? highLightText : TEXT.TERMS_CONDITIONS}
        </Text>
      </Text>
    </View>
  );
};

export default React.memo(CheckboxWithTerm);
