import {createSlice} from '@reduxjs/toolkit';
import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {ResetAllState} from './logoutslice';
import {IUpdateProfile} from '../../types/reducerTypes/userProfileTypes';
import {UPDATE_PROFILE_IMAGE} from '../../constants/actionTypes';

const {StoreConstants} = AppConst;
const {UPDATE_PROFILE} = ActionType;
const initialState: IUpdateProfile = {
  updateUserData: {
    isLoading: false,
    success: null,
    failed: null,
    status: UPDATE_PROFILE.END,
  },
  uploadProfileImage: {
    isLoading: false,
    success: null,
    failed: null,
    status: UPDATE_PROFILE.END,
  },
};

const UpdateProfileSlice = createSlice({
  initialState,
  name: StoreConstants.UPDATE_PROFILE,
  reducers: {
    onUpdateUserStart: (state, _) => {
      state.updateUserData.isLoading = true;
      state.updateUserData.status = UPDATE_PROFILE.START;
    },
    onUpdateUserSuccess: (state, action) => {
      state.updateUserData.success = action.payload;
      state.updateUserData.status = UPDATE_PROFILE.SUCCESS;
    },
    onUpdateUserFailed: (state, action) => {
      state.updateUserData.failed = action.payload;
      state.updateUserData.status = UPDATE_PROFILE.FAILED;
    },
    onUpdateUserEnd: state => {
      state.updateUserData.isLoading = false;
      state.updateUserData.status = UPDATE_PROFILE.END;
    },
    onUploadProfileImageStart: (state, _) => {
      state.uploadProfileImage.isLoading = true;
      state.uploadProfileImage.status = UPDATE_PROFILE_IMAGE.START;
    },
    onUploadProfileImageSuccess: (state, action) => {
      state.uploadProfileImage.success = action.payload;
      state.uploadProfileImage.status = UPDATE_PROFILE_IMAGE.SUCCESS;
    },
    onUploadProfileImageFailed: (state, action) => {
      state.uploadProfileImage.failed = action.payload;
      state.uploadProfileImage.status = UPDATE_PROFILE_IMAGE.FAILED;
    },
    onUploadProfileImageEnd: state => {
      state.uploadProfileImage.isLoading = false;
      state.uploadProfileImage.status = UPDATE_PROFILE_IMAGE.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  onUpdateUserStart,
  onUpdateUserSuccess,
  onUpdateUserFailed,
  onUpdateUserEnd,
  onUploadProfileImageStart,
  onUploadProfileImageSuccess,
  onUploadProfileImageFailed,
  onUploadProfileImageEnd,
} = UpdateProfileSlice.actions;
export default UpdateProfileSlice.reducer;
export const updateProfileState = (state: RootState) => state.updateProfile;
