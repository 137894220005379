import React from 'react';
import {View, Text} from 'react-native';
import {ROUTE_NAME, TEXT} from '../../constants';
import {MantelTopType} from '../../constants/enum';
import {ThemeContext} from '../../contextAPI/themeContext';
import {navigate} from '../../services/navigationService';
import PrimaryButton from '../button/primaryButton';
import NoCollections from '../renderSvgs/noCollections';
import {styles} from './emptyListView.style';

type TopsEmptyListProps = {
  emptyViewTitle: string;
  emptyViewDesc: string;
  tabType?: number;
  placeHolder?: React.ReactElement;
};
const TopsEmptyList = (props: TopsEmptyListProps): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  const {placeHolder} = props;

  const RenderText = React.useMemo((): JSX.Element => {
    return (
      <>
        <Text style={style.noDataHeading}>{props.emptyViewTitle}</Text>
        {props?.emptyViewDesc && (
          <Text style={style.noDataDesc}>{props.emptyViewDesc}</Text>
        )}
        {props?.tabType === MantelTopType.COLLECTION && (
          <PrimaryButton
            buttonLabel={TEXT.CREATE_NEW_TOP}
            buttonwidth={style.primaryButtonStyle}
            labelStyle={style.primaryButtonLabelstyle}
            onPress={() => navigate(ROUTE_NAME.EDITOR_SCREEN)}
          />
        )}
      </>
    );
  }, []);

  return (
    <View style={style.searchView}>
      {placeHolder ? placeHolder : <NoCollections />}
      {RenderText}
    </View>
  );
};

export default React.memo(TopsEmptyList);
