import React, {useMemo, useState} from 'react';
import {Platform, View, ActivityIndicator} from 'react-native';
import {formatTutorialVideoTitle} from '../../utils/helperFunctions';
import TitleWithButton from '../../components/header/titleWithButton';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import {Percentage} from '../../constants';

const HTML_PAGE = `<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <style>
    #myVideo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain; /* or 'cover' based on your requirement */
    }
    html, body {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      overflow: hidden; /* Prevent scrolling */
    }
    body {
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-weight: 300;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    * {
      box-sizing: border-box;
    }
  </style>
</head>
<body>
  <video id="myVideo" controls autoplay muted>
    <source id="videoSource" type="video/mp4">
    Your browser does not support the video tag.
  </video>

  <script>
    document.getElementById('videoSource').src = "{videoUri}";
    document.getElementById('myVideo').load();
  </script>
</body>
</html>`;

const VideoPlayer = ({route}) => {
  const [loader, setLoader] = useState(false);
  const iframeHtml = HTML_PAGE.replace('{videoUri}', route.params.videoUri);

  const renderPlayer = useMemo(() => {
    return (
      <iframe
        style={{
          width: Percentage.PRECENTAGE_100,
          height: Percentage.PRECENTAGE_100,
        }}
        srcDoc={iframeHtml}
        title="Video Player"
      />
    );
  }, [iframeHtml, route]);

  return (
    <SafeAreaContainer>
      <View style={{flex: 1}}>
        <TitleWithButton
          title={formatTutorialVideoTitle(route?.params?.name) ?? ''}
        />
        {loader && Platform.OS !== 'android' && (
          <View
            style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}
          >
            <ActivityIndicator size="large" />
          </View>
        )}
        {renderPlayer}
      </View>
    </SafeAreaContainer>
  );
};

export default VideoPlayer;
