import {StyleSheet} from 'react-native';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {
  isWebsite,
  scaleWidth,
  scaleHeight,
  normalizeFont,
} from '../../utils/responsive';

export const styles = StyleSheet.create({
  imageStyle: {
    height: FixedValue.CONSTANT_VALUE_89,
    width: FixedValue.CONSTANT_VALUE_67,
    marginHorizontal: FixedValue.CONSTANT_VALUE_10,
  },
  getBackgroundColor: {
    backgroundColor: COLORS.LIGHT_BLACK,
  },
  scrollViewStyle: {
    justifyContent: GlobalStyleValues.FLEX_START,
  },
  squareView: {
    width: scaleWidth(FixedValue.CONSTANT_VALUE_44),
    height: scaleWidth(FixedValue.CONSTANT_VALUE_44),
    backgroundColor: COLORS.BLACK,
    borderWidth: FixedValue.CONSTANT_VALUE_1,
    borderColor: COLORS.WHITE,
    ...BASESTYLE.inCenter,
  },
  circleView: {
    width: scaleWidth(FixedValue.CONSTANT_VALUE_44),
    height: scaleWidth(FixedValue.CONSTANT_VALUE_44),
    borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_22),
    backgroundColor: COLORS.BLACK,
    borderWidth: FixedValue.CONSTANT_VALUE_1,
    borderColor: COLORS.WHITE,
    ...BASESTYLE.inCenter,
  },
  rectangularView: {
    width: scaleWidth(FixedValue.CONSTANT_VALUE_75),
    height: scaleWidth(FixedValue.CONSTANT_VALUE_44),
    backgroundColor: COLORS.BLACK,
    borderWidth: FixedValue.CONSTANT_VALUE_1,
    borderColor: COLORS.WHITE,
    ...BASESTYLE.inCenter,
  },
  whiteCheck: {
    ...BASESTYLE.absolutePosition,
    margin: FixedValue.CONSTANT_VALUE_35,
    width: Percentage.PRECENTAGE_100,
    height: Percentage.PRECENTAGE_100,
    ...BASESTYLE.inCenter,
    backgroundColor: COLORS.LIGHT_BLACK,
  },
  imageSource: {
    width: Percentage.PRECENTAGE_100,
    height: Percentage.PRECENTAGE_100,
    resizeMode: GlobalStyleValues.COVER,
  },
  _imageStyle: {
    height: scaleWidth(FixedValue.CONSTANT_VALUE_65),
    width: scaleWidth(FixedValue.CONSTANT_VALUE_65),
    marginRight: FixedValue.CONSTANT_VALUE_10,
    ...BASESTYLE.inCenter,
    borderRadius: FixedValue.CONSTANT_VALUE_4,
    borderWidth: FixedValue.CONSTANT_VALUE_1,
    borderColor: COLORS._333333,
    borderStyle: GlobalStyleValues.DASHED,
    marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    overflow: GlobalStyleValues.HIDDEN,
  },
  textInputContainer: {
    ...BASESTYLE.absolutePosition,
    ...BASESTYLE.alignCenter,
    zIndex: FixedValue.CONSTANT_VALUE_1,
    width: Percentage.PRECENTAGE_100,
    height: Percentage.PRECENTAGE_100,
    paddingTop: Percentage.PRECENTAGE_50,
  },
  textInput: {
    paddingVertical: scaleHeight(FixedValue.CONSTANT_VALUE_8),
    paddingHorizontal: FixedValue.CONSTANT_VALUE_10,
    fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
    fontFamily: FONT_NAMES.SF_REGULAR,
    color: COLORS.BLACK,
    borderWidth: FixedValue.CONSTANT_VALUE_1,
    borderColor: COLORS.PRIMARY_BLUE,
    backgroundColor: COLORS.E9EEFB,
  },
});
