import React, {useEffect} from 'react';
import {FlatList, Image, Pressable, Text, View} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {TEXT, BASESTYLE, FixedValue, ROUTE_NAME} from '../../constants';
import {TradeModalModalStyle} from './trade.style';
import CustomButton from '../../components/button/customButton';
import {TradeContext} from '../../contextAPI/tradeContext';
import useAwsS3 from '../../hooks/libraryHooks/useAwsS3';
import {
  formatUTCTimeToLocalTime,
  getInitials,
} from '../../utils/helperFunctions';
import TopCardTile from '../../components/mantelTopTabComp/topCardTile';
import useTradeReceviedOrSentHooks from '../../hooks/componentHooks/useTradeReceviedOrSentHooks';
import ArrowWithCircle from '../../components/renderSvgs/arrowWithCircle';
import {CustomUserListSearchModalListData} from '../../types/reducerTypes/searchTabStoreType';
import {navigate} from '../../services/navigationService';
import ConfirmationModal from '../confirmationModal/confirmationModal';
import {
  APPROVED_OR_REJECT,
  NOTIFICATION_SUB_TYPE,
  timeformat,
  TopType,
} from '../../constants/enum';
import CancelModal from '../cancleModal/cancelModal';
import TICKICON from '../../components/renderSvgs/tickIcon';
import CROSSICON from '../../components/renderSvgs/smallCrossIcon';
import {ScrollView} from 'native-base';
import ReportImage from '../../components/renderSvgs/reportImage';
import GreenTick2 from '../../components/renderSvgs/greenTick2';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import {NotificationContext} from '../../contextAPI/notificationContext';
import RedCrossIcon from '../../components/renderSvgs/redCrossIcon';
import FavoriteButton from '../../components/button/favouriteBtn';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import {useAppSelector} from '../../hooks/reduxHooks';
import useFavouriteHooks from '../../hooks/componentHooks/useFavouriteHooks';
import {useIsFocused} from '@react-navigation/native';
import {USER_MARK_UNMARK_FAVORITE} from '../../constants/actionTypes';
interface TradeContentProps {
  onClose?: () => void;
  isChild?: boolean;
  showCancelButton?: boolean;
  isTradeReceivedRequest?: boolean;
  isTradeSentRequest?: boolean;
  showActionButton?: boolean;
  showPendingRequestCount?: boolean;
  showFavIcon?: boolean;
}

const TradeModalContent = (props: TradeContentProps): JSX.Element => {
  const {
    onClose,
    isChild = false,
    showCancelButton = true,
    isTradeReceivedRequest = false,
    isTradeSentRequest = false,
    showActionButton = false,
    showPendingRequestCount = true,
    showFavIcon = false,
  } = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const {loadSignedImageUrl} = useAwsS3();
  const {styles} = TradeModalModalStyle(ColorTheme);
  const {
    selectedItem,
    currentActiveTab,
    setOpenReceviedOfferModal,
    setOtherTradeUser,
    setCollectionSelectedTops,
    setCollectionSelectedTopsOther,
    setCounterOffer,
    setApproveModal,
    setIsApprove,
    setOpenSentOfferModal,
    setCancelModal,
    isApprove,
    approveModal,
    setOpenReportModal,
  } = React.useContext(TradeContext);
  const [imageUrl, setImageUrl] = React.useState<string | undefined>();
  const {
    callApproveOfferAPI,
    callRejectOfferAPI,
    navigateToDetailScreen,
    callTopTradeStatusUpdateAPI,
    getTradeOfferDetail,
  } = useTradeReceviedOrSentHooks();
  const {selectedNotificationCard} = React.useContext(NotificationContext);
  const showButtonAndStatus =
    selectedNotificationCard?.subType !==
      NOTIFICATION_SUB_TYPE.NOTIFICATION_ACCEPTED &&
    selectedNotificationCard?.subType !==
      NOTIFICATION_SUB_TYPE.NOTIFICATION_REJECTED;
  const {userMarkAndUnmarkFavoriteData} = useAppSelector(userProfileStore);
  const [favLoading, setFavLoading] = React.useState<boolean>(false);
  const {addFavoriteAndUnFavorite, setFavoriteLoader} = useFavouriteHooks();
  const isFocused = useIsFocused();
  const sendByLoggedInUser =
    selectedItem?.fromUser.id === selectedNotificationCard?.userId;
  //Get User Profile image Url
  const getUserProfileImageUrl = () => {
    if (currentActiveTab === ROUTE_NAME.SENT_OFFER) {
      return selectedItem?.toUser.profileImage ?? '';
    } else {
      return selectedItem?.fromUser.profileImage ?? '';
    }
  };
  const getUserProfileUrl = React.useCallback(async (): Promise<void> => {
    try {
      const imageUrl = await loadSignedImageUrl(getUserProfileImageUrl());
      if (imageUrl) {
        setImageUrl(imageUrl);
      }
    } catch (error) {
      console.log('Error fetching or decoding SVG image:', error);
      // Handle the error here, such as displaying an error message to the user
    }
  }, []);

  const getUserProfileData = () => {
    if (currentActiveTab === ROUTE_NAME.SENT_OFFER) {
      const fullName =
        selectedItem?.toUser?.firstName +
          ' ' +
          selectedItem?.toUser?.lastName ?? '';

      return getInitials(fullName);
    } else {
      const fullName =
        selectedItem?.fromUser?.firstName +
          ' ' +
          selectedItem?.fromUser?.lastName ?? '';

      return getInitials(fullName);
    }
  };

  const onPressCounter = () => {
    setOpenReceviedOfferModal();
    const tradeUser: CustomUserListSearchModalListData = {
      id: selectedItem?.fromUser.id ?? FixedValue.CONSTANT_VALUE_0,
      username: selectedItem?.fromUser?.username,
      firstName: selectedItem?.fromUser?.firstName ?? '',
      lastName: selectedItem?.fromUser?.lastName ?? '',
      email: selectedItem?.fromUser?.email,
      profileImage: selectedItem?.fromUser?.profileImage,
      alreadyPresentUser: false,
    };
    setOtherTradeUser(tradeUser);
    const selectedTops = selectedItem?.toUserTops.map(top => {
      return {
        ...top,
        selectedQuantity: top.quantity,
        isSelected: true,
      };
    });
    setCollectionSelectedTops(selectedTops ?? []);
    const selectedTopsOther = selectedItem?.fromUserTops.map(top => {
      return {
        ...top,
        isSelected: true,
        selectedQuantity: top.quantity,
      };
    });
    setCollectionSelectedTopsOther(selectedTopsOther ?? []);
    setCounterOffer(true);
    navigate(ROUTE_NAME.SENT_TRADE_OFFER_REQUEST);
  };

  const RenderImage = React.useMemo(() => {
    if (!imageUrl) {
      // Render the group initials if the image has not loaded yet
      return (
        <View style={[styles.cardStyle, BASESTYLE.center]}>
          <Text style={styles.userNameInitials}>{getUserProfileData()}</Text>
        </View>
      );
    }

    return (
      <Image
        source={{uri: imageUrl}}
        style={styles.cardStyle}
        onError={() => {
          setImageUrl(undefined);
        }}
      />
    );
  }, [imageUrl]);
  // Show Offer status in case of sent offer
  const getOfferStatus = () => {
    if (currentActiveTab === ROUTE_NAME.SENT_OFFER) {
      return getOfferStatusTagView();
    } else {
      if (!isChild) {
        if (
          selectedItem?.status === TEXT.PENDING_IN_CAPS &&
          showButtonAndStatus
        ) {
          return (
            <View style={styles.counterOfferButton}>
              <Pressable onPress={onPressCounter}>
                <ArrowWithCircle />
              </Pressable>
            </View>
          );
        } else {
          return getOfferStatusTagView();
        }
      } else {
        return getOfferStatusTagView();
      }
    }
  };

  const onPressFavAndUnFav = React.useCallback(
    (item: any): void => {
      setFavLoading(true);
      setFavoriteLoader(true);
      const user = item.fromUser;
      const params = {
        favorite: user?.isFavorite ? !user?.isFavorite : true,
        userIds: [user?.id ?? FixedValue.CONSTANT_VALUE_0],
      };
      addFavoriteAndUnFavorite(params);
    },
    [currentActiveTab]
  );

  const getFavIcon = () => {
    return (
      <View style={styles.favIconContainer}>
        <FavoriteButton
          isFavorite={selectedItem?.fromUser?.isFavorite ?? false}
          onFavOrUnFavUser={() => onPressFavAndUnFav(selectedItem)}
        />
      </View>
    );
  };
  useEffect(() => {
    if (isFocused) {
      setFavLoading(false);
      switch (userMarkAndUnmarkFavoriteData.status) {
        case USER_MARK_UNMARK_FAVORITE.SUCCESS:
          const user = selectedItem?.fromUser;
          const {firstName, lastName, isFavorite} = user ?? {};
          const firstText = isFavorite
            ? TEXT.YOU_HAVE_REMOVED
            : TEXT.YOU_HAVE_ADDED;
          const fullName = firstName + ' ' + lastName;
          const favText = isFavorite ? TEXT.FROM_THE_LIST : TEXT.TO_THE_LIST;
          const msg = `${firstText} "${fullName}" ${favText}`;
          // @ts-ignore
          getTradeOfferDetail(selectedNotificationCard);
          alert(msg);
          break;
        case USER_MARK_UNMARK_FAVORITE.FAILED:
          break;
      }
    }
  }, [userMarkAndUnmarkFavoriteData?.status]);
  const getOfferStatusTagView = () => {
    switch (selectedItem?.status) {
      case TEXT.CANCELLED_IN_CAPS:
        return (
          <View style={styles.cancelledofferStatus}>
            <Text style={styles.rejectStatusText}>
              {TEXT.CANCELLED_IN_SMALL}
            </Text>
          </View>
        );
      case TEXT.REJECTED_IN_CAPS:
        return (
          <View style={styles.cancelledofferStatus}>
            <Text style={styles.rejectStatusText}>
              {TEXT.REJECTED_IN_SMALL}
            </Text>
          </View>
        );
      case TEXT.INVALID_IN_CAPS:
        return (
          <View style={styles.cancelledofferStatus}>
            <Text style={styles.rejectStatusText}>{TEXT.INVALID}</Text>
          </View>
        );
      case TEXT.ACCEPTED_IN_CAPS:
        return (
          <View style={styles.acceptedOfferStatus}>
            <Text style={styles.approvedStatusText}>
              {TEXT.ACCEPTED_IN_SMALL}
            </Text>
          </View>
        );
      case TEXT.PENDING_IN_CAPS:
        return (
          <View style={styles.offerStatus}>
            <Text style={styles.pendingStatusText}>
              {TEXT.PENDING_IN_SMALL}
            </Text>
          </View>
        );
      default:
        break;
    }
  };
  const getToUserDetail = () => {
    return (
      <View style={styles.nameView}>
        <Text numberOfLines={FixedValue.CONSTANT_VALUE_1} style={styles.owner}>
          {selectedItem?.toUser?.firstName} {selectedItem?.toUser?.lastName}
        </Text>
        <Text numberOfLines={FixedValue.CONSTANT_VALUE_1} style={styles.day}>
          {formatUTCTimeToLocalTime(
            selectedItem?.creationDate ?? '',
            timeformat.MMMDDYY
          )}
        </Text>
      </View>
    );
  };
  const getFromUserDetails = () => {
    return (
      <View style={styles.nameView}>
        <Text numberOfLines={FixedValue.CONSTANT_VALUE_1} style={styles.owner}>
          {selectedItem?.fromUser?.firstName} {selectedItem?.fromUser?.lastName}
        </Text>
        <View>
          <Text numberOfLines={FixedValue.CONSTANT_VALUE_1} style={styles.day}>
            {formatUTCTimeToLocalTime(
              selectedItem?.creationDate ?? '',
              timeformat.MMMDDYY
            )}
          </Text>
        </View>
      </View>
    );
  };
  const getSectionTitleForFromUser = () => {
    if (
      currentActiveTab === ROUTE_NAME.RECEIVED_OFFER &&
      (isTradeSentRequest || isTradeReceivedRequest) &&
      !sendByLoggedInUser
    ) {
      return (
        `Tops ${getNameForChildRequest()} receive from ` +
        (isTradeSentRequest || isTradeReceivedRequest
          ? selectedItem?.fromUser?.firstName
          : selectedItem?.toUser?.firstName)
      );
    } else {
      return 'Tops you give to ' + selectedItem?.toUser?.firstName;
    }
  };
  const getFromUserTops = () => {
    return (
      <View>
        <Text
          numberOfLines={FixedValue.CONSTANT_VALUE_1}
          style={styles.topOwner}
        >
          {getSectionTitleForFromUser()}
        </Text>
        <View>
          <FlatList
            style={{marginLeft: FixedValue.CONSTANT_VALUE_0}}
            numColumns={FixedValue.CONSTANT_VALUE_3}
            data={selectedItem?.fromUserTops}
            showsHorizontalScrollIndicator={false}
            keyExtractor={(_, index) =>
              `${TEXT.COLLECTIONS + 'Nested'}-${index}`
            }
            renderItem={({item}) => (
              <TopCardTile
                key={item.id}
                {...item}
                ismodal
                onShowDetailPress={() => {
                  {
                    currentActiveTab === ROUTE_NAME.RECEIVED_OFFER
                      ? setOpenReceviedOfferModal()
                      : setOpenSentOfferModal();
                  }
                  navigateToDetailScreen(item, TopType.viewOffer);
                }}
              />
            )}
          />
        </View>
      </View>
    );
  };

  const getNameForChildRequest = () => {
    return isChild ? selectedItem?.toUser?.firstName : 'you';
  };

  const getSectionTitle = () => {
    if (
      currentActiveTab === ROUTE_NAME.RECEIVED_OFFER &&
      (isTradeSentRequest || isTradeReceivedRequest) &&
      !sendByLoggedInUser
    ) {
      return (
        `Tops ${getNameForChildRequest()} give to ` +
        (isTradeSentRequest || isTradeReceivedRequest
          ? selectedItem?.fromUser?.firstName
          : selectedItem?.toUser?.firstName)
      );
    } else {
      return 'Tops you receive from ' + selectedItem?.toUser?.firstName;
    }
  };

  const pendingCountIsGraterThanZero = (count: number) => {
    return count >= FixedValue.CONSTANT_VALUE_0;
  };

  const pendingCountTextColor = (count: number) => {
    return count >= FixedValue.CONSTANT_VALUE_0
      ? styles.totalTopsText
      : styles.totalTopsTextRed;
  };

  const getToUserTops = () => {
    return (
      <View>
        <Text
          numberOfLines={FixedValue.CONSTANT_VALUE_1}
          style={styles.topOwner}
        >
          {getSectionTitle()}
        </Text>
        <View>
          <FlatList
            style={{marginLeft: FixedValue.CONSTANT_VALUE_0}}
            numColumns={FixedValue.CONSTANT_VALUE_3}
            data={selectedItem?.toUserTops}
            showsHorizontalScrollIndicator={false}
            keyExtractor={(_, index) =>
              `${TEXT.COLLECTIONS + 'Nested'}-${index}`
            }
            renderItem={({item}) => (
              <View style={styles.flexDirectionColumn}>
                <TopCardTile
                  key={item.id}
                  {...item}
                  ismodal
                  onShowDetailPress={() => {
                    {
                      currentActiveTab === ROUTE_NAME.RECEIVED_OFFER
                        ? setOpenReceviedOfferModal()
                        : setOpenSentOfferModal();
                    }
                    navigateToDetailScreen(item, TopType.viewOffer);
                  }}
                />
                {currentActiveTab !== ROUTE_NAME.SENT_OFFER &&
                  showPendingRequestCount && (
                    <View style={styles.leftQuantityView}>
                      <Text
                        style={pendingCountTextColor(
                          item?.pendingRequest?.totalLeftQuantity ??
                            FixedValue.CONSTANT_VALUE_0
                        )}
                      >
                        {item?.pendingRequest?.totalLeftQuantity ??
                          FixedValue.CONSTANT_VALUE_0}
                      </Text>
                      {pendingCountIsGraterThanZero(
                        item?.pendingRequest?.totalLeftQuantity ??
                          FixedValue.CONSTANT_VALUE_0
                      ) ? (
                        <GreenTick2
                          height={scaleHeight(FixedValue.CONSTANT_VALUE_10)}
                          width={scaleHeight(FixedValue.CONSTANT_VALUE_10)}
                        />
                      ) : (
                        <RedCrossIcon
                          height={scaleHeight(FixedValue.CONSTANT_VALUE_10)}
                          width={scaleHeight(FixedValue.CONSTANT_VALUE_10)}
                        />
                      )}
                    </View>
                  )}
              </View>
            )}
          />
        </View>
      </View>
    );
  };

  const getTopUserInfo = () => {
    if (currentActiveTab === ROUTE_NAME.SENT_OFFER || sendByLoggedInUser) {
      return getToUserDetail();
    } else {
      return getFromUserDetails();
    }
  };
  // For first top list, show tops according to offer list i.e sent/received
  const getTopsList = () => {
    if (currentActiveTab === ROUTE_NAME.SENT_OFFER || sendByLoggedInUser) {
      return getToUserTops();
    } else {
      return getFromUserTops();
    }
  };
  // For second top list, reverse the user tops ordering and display them again
  const getSecondTopsList = () => {
    if (currentActiveTab === ROUTE_NAME.SENT_OFFER || sendByLoggedInUser) {
      return getFromUserTops();
    } else {
      return getToUserTops();
    }
  };
  // Load User Profile image
  React.useEffect(() => {
    getUserProfileUrl();
  }, [selectedItem]);
  const getCancelButton = () => {
    if (selectedItem?.status === TEXT.PENDING_IN_CAPS) {
      return (
        <View style={styles.approveButton}>
          <CustomButton
            buttonLabel={TEXT.CANCEL_OFFER}
            buttonwidth={styles.primaryButtonStyle3}
            labelStyle={styles.primaryButtonLabelstyle1}
            buttonBackGroundColor={styles.noButtonBackGroundColor}
            onPress={() => {
              setCancelModal();
            }}
          />
          <CancelModal isCancel={true} />
        </View>
      );
    } else {
      return <></>;
    }
  };

  const OnTradeModalApproveRejectButton = () => {
    if (isChild) {
      const params = {
        status: isApprove
          ? APPROVED_OR_REJECT.APPROVED
          : APPROVED_OR_REJECT.REJECT,
        offerId: selectedItem?.id ?? FixedValue.CONSTANT_VALUE_0,
      };
      callTopTradeStatusUpdateAPI(params);
    } else {
      if (isApprove)
        callApproveOfferAPI(selectedItem?.id ?? FixedValue.CONSTANT_VALUE_0);
      else {
        callRejectOfferAPI(selectedItem?.id ?? FixedValue.CONSTANT_VALUE_0);
      }
    }
  };

  return (
    <View style={styles.container}>
      <ScrollView style={{marginBottom: 100}}>
        {/* Offer sender View */}
        <View style={styles.topContainerMain}>
          <View style={styles.topContainer}>
            <View style={styles.cardStyle}>{RenderImage}</View>
            {getTopUserInfo()}
          </View>
          {showFavIcon && showActionButton ? (
            <>
              <View style={{left: scaleWidth(70)}}>{getOfferStatus()}</View>
              <View>{getFavIcon()}</View>
            </>
          ) : (
            getOfferStatus()
          )}
        </View>
        {/* Tops List */}
        <View>{getTopsList()}</View>
        <View>{getSecondTopsList()}</View>
      </ScrollView>
      {showButtonAndStatus &&
        (currentActiveTab === ROUTE_NAME.RECEIVED_OFFER &&
        selectedItem?.status === TEXT.PENDING_IN_CAPS &&
        (isTradeReceivedRequest || isTradeSentRequest) &&
        !showCancelButton
          ? showActionButton && (
              <View style={styles.approveButton}>
                <>
                  <CustomButton
                    buttonLabel={TEXT.APPROVE}
                    buttonwidth={styles.approveButtonStyle}
                    labelStyle={styles.primaryButtonLabelstyle}
                    buttonBackGroundColor={styles.noButtonBackGroundColor1}
                    element={<TICKICON />}
                    onPress={() => {
                      setIsApprove(true);
                      setApproveModal();
                    }}
                  />
                  <CustomButton
                    buttonLabel={TEXT.REJECT}
                    buttonwidth={styles.rejectButtonStyle}
                    labelStyle={styles.primaryButtonLabelstyle1}
                    buttonBackGroundColor={styles.noButtonBackGroundColor}
                    element={<CROSSICON />}
                    onPress={() => {
                      setIsApprove(false);
                      setApproveModal();
                    }}
                  />
                  <CustomButton
                    buttonLabel={TEXT.REPORT}
                    buttonwidth={styles.rejectButtonStyle}
                    labelStyle={styles.primaryButtonLabelstyle1}
                    buttonBackGroundColor={styles.noButtonBackGroundColor}
                    element={
                      <ReportImage
                        width={FixedValue.CONSTANT_VALUE_12}
                        height={FixedValue.CONSTANT_VALUE_12}
                      />
                    }
                    onPress={() => {
                      setOpenReportModal(true);
                      onClose && onClose();
                    }}
                  />
                  <ConfirmationModal
                    title={isApprove ? TEXT.OFFER_APPROVE : TEXT.OFFER_REJECT}
                    modalVisible={approveModal}
                    onPressYes={() => {
                      OnTradeModalApproveRejectButton();
                      setOpenReceviedOfferModal();
                      setApproveModal();
                    }}
                    onShowHide={setApproveModal}
                  />
                </>
              </View>
            )
          : getCancelButton())}
    </View>
  );
};

export default TradeModalContent;
