export const ROUTE_NAME = {
  WELCOME_SCREEN: 'WelcomeScreen',
  LOSS_PASSWORD: 'LossPassword',
  FORGOT_USERNAME: 'ForgotUsername',
  LOGIN_SCREEN: 'LoginScreen',
  SIGN_UP: 'SignUpScreen',
  VERIFY_EMAIL: 'VerifyEmail',
  BOTTOM_TAB: 'BottomTab',
  MENTAL_TAB: 'MentalTab',
  TRADE_TAB: 'TradeTab',
  SEARCH_TAB: 'SearchTab',
  NOTIFICATION_TAB: 'NotificationTab',
  PROFILE_TAB: 'ProfileTab',
  EDITOR_SCREEN: 'EditorScreen',
  CREATE_PASSWORD: 'CreateNewPassword',
  IMAGE_SCREEN: 'ImageScreen',
  PASSWORD_RESET: 'PasswordReset',
  PARENT_CONSENT: 'ParentConsent',
  CHANGE_PASSWORD: 'ChangePassword',
  TOP_DETAIL_SCREEN: 'TopDetailScreen',
  CHILD_LIST_SCREEN: 'ChildListScreen',
  IN_PROGRESS_TOPS_SCREEN: 'InProgressTopsScreen',
  COLLECTIONS_SCREEN: 'CollectionsScreen',
  PURCHASED_TOPS_SCREEN: 'PurchasedTopsScreen',
  SEE_ALL_TOPS_SCREEN: 'AllTopsScreen',
  CHILD_PROFILE: 'ChildProfile',
  PROFILE_APPROVEL: 'ProfileApprovel',
  FIRST_TOP_TAB: 'FirstTopTabbar',
  GROUP_MANAGEMENT_SCREEN: 'GroupManagementScreen',
  MY_GROUP_SCREEN: 'MyGroupScreen',
  GROUP_IN: 'Group_In',
  GROUP_INVITATIONS: 'GroupInvitations',
  EDIT_PROFILE: 'EditProfile',
  DISTRIBUTE_INDIVIDUAL: 'DistributeIndividual',
  DISTRIBUTE_GROUP: 'DistributeGroup',
  CHILD_GROUP_INVITATION: 'ChildGroupInvitation',
  GROUP_INVIATION_SETTINGS: 'GroupInviationSettings',
  GROUP_DETAIL_SCREEN: 'GroupDetailScreen',
  CREATE_TRADE: 'CreateTrade',
  SELECT_ALL_TOPS_FOR_OFFER: 'SelectTopsForOffer',
  RECEIVED_OFFER: 'ReceivedOffer',
  SENT_OFFER: 'SentOffer',
  TOP_TRADING: 'TopTrading',
  TOP_RECEIVED: 'TopReceived',
  TOP_SENT: 'TopSent',
  TOP_APPROVED_LIST: 'TopApprovedList',
  TOP_SETTING: 'TopTradingSetting',
  SELECT_TRADE_USER: 'SelectTradeUser',
  REQUEST_SENT: 'RequestSent',
  CREATE_OFFER_OTHER: 'CreateOfferOther',
  SELECT_TOPS_FOR_OFFER_OTHER: 'SelectTopsForOfferOther',
  SENT_TRADE_OFFER_REQUEST: 'SentTradeOfferRequest',
  MY_CART_TOPS: 'MyCartTops',
  DISTRIBUTING_TOP: 'DistributingTop',
  DISTRIBUTING_TOP_RECEIVED_REQUEST: 'DistributingTopReceivedRequest',
  DISTRIBUTING_TOP_REQUEST: 'DistributingTopRequest',
  DISTRIBUTING_TOP_APPROVE_LIST: 'DistributingTopApproveList',
  DISTRIBUTING_SETTING: 'DistributingSetting',
  TOP_BUYING_APPROVAL: 'TopBuyingApproval',
  TOP_BUYING_SETTING: 'TopBuyingSetting',
  TOP_BUYING_DETAIL: 'TopBuyingDetail',
  SHIFTING_TO_TRADEABLE: 'ShiftingToTradeable',
  SHIFTING_SETTING: 'ShiftingSetting',
  HELP_SUPPORT: 'HelpAndSupport',
  TERMS_CONDITIONS: 'TermsandConditions',
  WEB_VIEW_SCREEN: 'WebViewScreen',
  PAYMENT_SUCCESS: 'PaymentSuccess',
  MY_WALLET_BALANCE: 'MyWalletBalance',
  ORDER_SUMMARY: 'OrderSummary',
  BLOCK_USER_LIST: 'BlockUserList',
  FAVORITES_LIST: 'FavoriteList',
  ACTION_NEEDED: 'ActionNeeded',
  COMPLETED: 'Completed',
  CHILD_PROFILE_ALL_SETTING: 'ChildProfileAllSetting',
  TRACKING_TAB: 'TrackingTab',
  TUTORIAL_VIDEO: 'tutorials',
  FULLSCREEN_PLAYER: 'FullscreenVideoPlayer',
  EDIT_PROFILE_MOBILE: 'EditProfileMobile',
};
