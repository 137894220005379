import React from 'react';
import {FlatList, ScrollView, View, RefreshControl} from 'react-native';
import EmptyChildListView from '../../components/emptyListView/emptyChildListView';
import {FixedValue, GlobalStyleValues, TEXT} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {useAppSelector} from '../../hooks/reduxHooks';
import {blockContentSyle} from './blockUserContentSyle';
import ListWithCheckbox from '../../components/smallDesigns/listWithCheckbox/listWithCheckbox';
import useUserProfileHooks from '../../hooks/componentHooks/useUserProfileHooks';
import {ProfileTabContext} from '../../contextAPI/profileTabContext';
import {ChildListModel} from '../../types/reducerTypes/userProfileTypes';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';

const BlockUserContent = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = blockContentSyle(ColorTheme);
  // Get data and functions from the context and hooks
  const {
    blockUserListData,
    userBlockListPullDownRefresh,
    setUserBlockListLoader,
    setUserBlockListPullDownRefresh,
  } = React.useContext(ProfileTabContext);
  const {blockUserData} = useAppSelector(userProfileStore);
  const {
    getUserBlockList,
    onUserBlockedListReachedEnd,
    onUserUnBlockedFunction,
  } = useUserProfileHooks();

  // Pull-down refresh function for the block user list
  const blockUserListHandleRefresh = React.useCallback(() => {
    setUserBlockListPullDownRefresh(true);
    getUserBlockList(FixedValue.CONSTANT_VALUE_0);
  }, [userBlockListPullDownRefresh]);

  // Callback function to unblock a user
  const unBlockUserfunction = React.useCallback(
    (item: ChildListModel) => {
      setUserBlockListLoader(true);
      onUserUnBlockedFunction(item?.id);
    },
    [userBlockListPullDownRefresh]
  );

  // Render an empty view when there is no data available
  const renderMyGroupEmptyUI = React.useMemo((): JSX.Element | null => {
    if (blockUserListData?.length === FixedValue.CONSTANT_VALUE_0) {
      return (
        <ScrollView
          contentContainerStyle={styles.scrollviewStyle}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={userBlockListPullDownRefresh}
              onRefresh={blockUserListHandleRefresh}
            />
          }
        >
          <View style={styles.emptyView}>
            <EmptyChildListView
              emptyViewTitle={TEXT.SORRY_NO_USER_BLOCK}
              emptyViewSubTitle={''}
            />
          </View>
        </ScrollView>
      );
    }
    return null;
  }, [blockUserListData]);

  // Render the block user list
  const renderBlockUserList = React.useMemo((): JSX.Element | null => {
    return (
      <FlatList
        keyExtractor={(_, index) => `${TEXT.GROUP_LIST}-${index}`}
        keyboardShouldPersistTaps={GlobalStyleValues.HANDLED}
        data={blockUserListData}
        contentContainerStyle={styles.flatListContentContainerStyle}
        onEndReached={() => onUserBlockedListReachedEnd()}
        onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
        showsVerticalScrollIndicator={false}
        scrollEnabled
        refreshControl={
          <RefreshControl
            refreshing={userBlockListPullDownRefresh}
            onRefresh={blockUserListHandleRefresh}
          />
        }
        renderItem={({item}) => (
          <ListWithCheckbox
            isForBlockedUser={true}
            isSelected={true}
            item={item}
            onPress={() => unBlockUserfunction(item)}
          />
        )}
      />
    );
  }, [blockUserListData]);

  return (
    <>
      {!blockUserData?.isLoading && renderMyGroupEmptyUI}
      {/* Display block user list if there is data */}
      {blockUserListData?.length > FixedValue.CONSTANT_VALUE_0 && (
        <View style={styles.mainList}>{renderBlockUserList}</View>
      )}
    </>
  );
};

export default React.memo(BlockUserContent);
