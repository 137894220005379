import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../../constants';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {isWebsite, scaleHeight, scaleWidth} from '../../../utils/responsive';

export const searchTabStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.selfCenter,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    contentContainerStyle: {
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_15),
    },
    indicator: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_21),
    },
    contentContainer: {
      ...BASESTYLE.flex1,
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    tabBarStyle: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    emptyViewContainer: {
      ...BASESTYLE.flex1,
      width: Percentage.PRECENTAGE_100,
      alignItems: GlobalStyleValues.CENTER,
      marginTop: -scaleHeight(FixedValue.CONSTANT_VALUE_200),
    },
  });
