import React from 'react';
import {FixedValue, ROUTE_NAME} from '../../constants';
import {TradeContext} from '../../contextAPI/tradeContext';
import {
  HomeStore,
  onAddTopsToCollectionStart,
  onCollectionsSpecificTopListsStart,
  onCollectionTopListsStart,
  onCounterOfferStart,
  onCreateOfferStart,
} from '../../redux/reducers/homeSlice';
import {push} from '../../services/navigationService';
import {
  CollectionModel,
  CounterOfferPayload,
  TopModel,
} from '../../types/reducerTypes/searchTabStoreType';
import {useAppDispatch, useAppSelector} from '../reduxHooks';

const useCreateTradeHooks = () => {
  const dispatch = useAppDispatch();
  const {collectionBasedTopList, collectionTopLists} =
    useAppSelector(HomeStore);

  const [collectionModel, setCollectionModel] =
    React.useState<CollectionModel>();
  const {
    collectionSelectedTops,
    collectionSelectedTopsOther,
    otherTradeUser,
    isTopEdit,
    selectedItem,
  } = React.useContext(TradeContext);

  const callCollectionsAPI = React.useCallback(
    (page: number, userId?: number): void => {
      const params = {
        page,
        pageSize: FixedValue.CONSTANT_VALUE_15,
        userId: userId ?? '',
        tradable: true,
      };
      dispatch(onCollectionTopListsStart(params));
    },
    []
  );

  const callAllTopsListAPI = React.useCallback(
    (collectionId: number, page: number): void => {
      const params = {
        collectionId,
        page,
        size: FixedValue.CONSTANT_VALUE_10,
        tradable: true,
      };
      dispatch(onCollectionsSpecificTopListsStart(params));
    },
    []
  );

  const onListReachedEnd = React.useCallback(
    (userId?: number): void => {
      if (
        collectionTopLists.success != null &&
        collectionTopLists.success.pageNumber <
          collectionTopLists.success.totalPages
      ) {
        callCollectionsAPI(
          collectionTopLists.success.pageNumber + FixedValue.CONSTANT_VALUE_1,
          userId
        );
      }
    },
    [collectionTopLists]
  );

  // Call API for moving uncategorized tops to some collection tops
  const callApiForAddTopsToCollection = React.useCallback(
    (params: any): void => {
      dispatch(onAddTopsToCollectionStart(params));
    },
    []
  );
  // Handle load more for Select My tops
  const onReachedEnd = React.useCallback(
    (id: number): void => {
      if (
        collectionBasedTopList.success != null &&
        collectionBasedTopList.success.pageNumber <
          collectionBasedTopList.success.totalPages
      ) {
        callAllTopsListAPI(
          id ?? FixedValue.CONSTANT_VALUE_0,
          collectionBasedTopList.success.pageNumber +
            FixedValue.CONSTANT_VALUE_1
        );
      }
    },
    [collectionBasedTopList]
  );
  //otherwise navigate to alltop list and show uncategorzized list
  const screenNavigateTo = React.useCallback(
    (collectionData: CollectionModel, userId?: number): void => {
      if (userId) {
        push(ROUTE_NAME.SELECT_TOPS_FOR_OFFER_OTHER, {
          collectionModel: collectionData,
          userId,
        });
      } else {
        push(ROUTE_NAME.SELECT_ALL_TOPS_FOR_OFFER, {
          collectionModel: collectionData,
        });
      }
    },
    []
  );

  const navigateSelectTradeUser = React.useCallback(
    (userId?: number) => {
      if (userId || isTopEdit) {
        push(ROUTE_NAME.SENT_TRADE_OFFER_REQUEST);
      } else {
        push(ROUTE_NAME.SELECT_TRADE_USER);
      }
    },
    [isTopEdit]
  );

  const callCreateTradeOfferAPI = React.useCallback((): void => {
    const myTops = collectionSelectedTops
      ?.filter(
        top => (top?.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0) > 0
      )
      .map(top => ({
        topId: top.id,
        quantity: top.selectedQuantity,
      }));

    const userTops = collectionSelectedTopsOther
      ?.filter(
        userTop =>
          (userTop?.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0) > 0
      )
      .map(userTop => ({
        topId: userTop.id,
        quantity: userTop.selectedQuantity,
      }));

    const data = {
      userId: otherTradeUser?.id,
      myTops,
      userTops,
    };
    dispatch(onCreateOfferStart(data));
  }, [
    dispatch,
    collectionSelectedTops,
    collectionSelectedTopsOther,
    otherTradeUser,
  ]);

  const callCounterOfferAPI = React.useCallback((): void => {
    const myTops = collectionSelectedTops?.map((top: TopModel) => ({
      topId: top.id,
      quantity: top.selectedQuantity,
    }));
    const userTops = collectionSelectedTopsOther?.map((userTop: TopModel) => ({
      topId: userTop.id,
      quantity: userTop.selectedQuantity,
    }));
    const data: CounterOfferPayload = {
      offerId: selectedItem?.id,
      topsData: {
        myTops,
        userTops,
      },
    };
    dispatch(onCounterOfferStart(data));
  }, [
    dispatch,
    collectionSelectedTops,
    collectionSelectedTopsOther,
    selectedItem,
  ]);

  return {
    screenNavigateTo,
    onReachedEnd,
    callAllTopsListAPI,
    setCollectionModel,
    callApiForAddTopsToCollection,
    callCollectionsAPI,
    onListReachedEnd,
    navigateSelectTradeUser,
    callCreateTradeOfferAPI,
    callCounterOfferAPI,
  };
};

export default useCreateTradeHooks;
