import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";
const TICKICON = (props:SvgProps) => (
  <Svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M11.829 1.95977L11.2734 1.4041C11.0444 1.17519 10.6707 1.17519 10.4398 1.4041L4.61316 7.23279L1.56088 4.18051C1.33197 3.95158 0.958254 3.95158 0.727377 4.18051L0.171681 4.73618C-0.057227 4.96511 -0.057227 5.33881 0.171681 5.56969L4.19445 9.59243C4.3099 9.70788 4.45857 9.76658 4.60924 9.76658C4.75991 9.76658 4.91056 9.70788 5.02407 9.59243L11.8232 2.7933C12.058 2.56048 12.058 2.18873 11.829 1.95977Z"
      fill="white"
    />
  </Svg>
);
export default TICKICON;
