import React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';

const SVGComponent = (props: SvgProps) => (
  <Svg
    width={201}
    height={161}
    viewBox="0 0 201 161"
    fill="none"
    //@ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M166.01 52.1289C169.111 52.1289 171.624 54.6423 171.624 57.7428C171.624 60.8432 169.111 63.3566 166.01 63.3566H133.931C137.031 63.3566 139.545 65.8701 139.545 68.9705C139.545 72.071 137.031 74.5844 133.931 74.5844H151.575C154.675 74.5844 157.188 77.0978 157.188 80.1983C157.188 83.2987 154.675 85.8121 151.575 85.8121H143.415C139.506 85.8121 136.337 88.3256 136.337 91.426C136.337 93.493 137.941 95.3643 141.149 97.0399C144.249 97.0399 146.763 99.5533 146.763 102.654C146.763 105.754 144.249 108.268 141.149 108.268H74.5843C71.4838 108.268 68.9704 105.754 68.9704 102.654C68.9704 99.5533 71.4838 97.0399 74.5843 97.0399H43.307C40.2066 97.0399 37.6931 94.5265 37.6931 91.426C37.6931 88.3256 40.2066 85.8121 43.307 85.8121H75.3863C78.4867 85.8121 81.0001 83.2987 81.0001 80.1983C81.0001 77.0978 78.4867 74.5844 75.3863 74.5844H55.3367C52.2363 74.5844 49.7229 72.071 49.7229 68.9705C49.7229 65.8701 52.2363 63.3566 55.3367 63.3566H87.416C84.3155 63.3566 81.8021 60.8432 81.8021 57.7428C81.8021 54.6423 84.3155 52.1289 87.416 52.1289H166.01ZM166.01 74.5844C169.111 74.5844 171.624 77.0978 171.624 80.1983C171.624 83.2987 169.111 85.8121 166.01 85.8121C162.91 85.8121 160.396 83.2987 160.396 80.1983C160.396 77.0978 162.91 74.5844 166.01 74.5844Z"
      fill="#F3F7FF"
    />
    <Path
      d="M96.6388 106.663C111.477 106.663 123.505 94.6349 123.505 79.797C123.505 64.9592 111.477 52.9307 96.6388 52.9307C81.8009 52.9307 69.7724 64.9592 69.7724 79.797C69.7724 94.6349 81.8009 106.663 96.6388 106.663Z"
      fill="#F3F7FF"
      stroke="#1F64E7"
      strokeWidth={2.5}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.3338 100.644C93.7443 100.904 95.1793 101.039 96.6388 101.049C108.376 101.049 117.891 91.5344 117.891 79.7969C117.891 68.0595 108.376 58.5444 96.6388 58.5444C93.6236 58.5444 90.7551 59.1723 88.1569 60.3045C83.6417 62.2719 79.9428 65.7622 77.7088 70.1266C76.224 73.0274 75.3863 76.3143 75.3863 79.7969C75.3863 82.9568 76.0759 85.9555 77.3127 88.6509C78.1958 90.5752 79.3578 92.345 80.747 93.9083"
      fill="white"
    />
    <Path
      d="M92.3338 100.644C93.7443 100.904 95.1793 101.039 96.6388 101.049C108.376 101.049 117.891 91.5344 117.891 79.7969C117.891 68.0595 108.376 58.5444 96.6388 58.5444C93.6236 58.5444 90.7551 59.1723 88.1569 60.3045C83.6417 62.2719 79.9428 65.7622 77.7088 70.1266C76.224 73.0274 75.3863 76.3143 75.3863 79.7969C75.3863 82.9568 76.0759 85.9555 77.3127 88.6509C78.1958 90.5752 79.3578 92.345 80.747 93.9083"
      stroke="#1F64E7"
      strokeWidth={2.5}
      strokeLinecap="round"
    />
    <Path
      d="M83.2436 96.2974C84.9664 97.6976 86.9127 98.8335 89.0207 99.6431"
      stroke="#1F64E7"
      strokeWidth={2.5}
      strokeLinecap="round"
    />
    <Path
      d="M118.693 101.05L123.505 105.862"
      stroke="#1F64E7"
      strokeWidth={2.5}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122.727 105.084C121.21 106.602 121.21 109.063 122.727 110.581L131.618 119.471C133.136 120.989 135.597 120.989 137.115 119.471C138.632 117.953 138.632 115.492 137.115 113.974L128.224 105.084C126.706 103.566 124.245 103.566 122.727 105.084Z"
      fill="#E8F0FE"
      stroke="#1F64E7"
      strokeWidth={2.5}
    />
    <Path
      d="M126.713 106.664L135.535 115.485"
      stroke="white"
      strokeWidth={2.5}
      strokeLinecap="round"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.4259 70.5743C91.4259 79.8756 98.9662 87.4159 108.268 87.4159C110.095 87.4159 111.854 87.1249 113.501 86.5867C110.812 93.26 104.276 97.9705 96.6388 97.9705C86.6019 97.9705 78.4654 89.834 78.4654 79.797C78.4654 70.7022 85.1462 63.1678 93.868 61.8335C92.3185 64.3824 91.4259 67.3741 91.4259 70.5743Z"
      fill="#E8F0FE"
    />
    <Path
      d="M97.0398 64.9604C96.0185 64.9604 95.0208 65.0609 94.0558 65.2525M91.141 66.1442C85.6547 68.4497 81.8021 73.874 81.8021 80.1981"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
    />
    <Path
      d="M139.686 80.0195H133.129M144.758 73.7822H130.983H144.758ZM150.372 73.7822H148.59H150.372Z"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M67.5074 97.6633H60.9506M63.7575 90.624H49.9829H63.7575ZM45.312 90.624H41.9266H45.312Z"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default SVGComponent;
