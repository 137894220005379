import React from 'react';
import {Text, View} from 'react-native';
import {SignUpContext} from '../../contextAPI/signUpContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import {userProfileContext} from '../../contextAPI/userProfileContext';
import {IProfilePicProps} from '../../types/componentTypes/smallDesignsType';
import ArrowDown from '../renderSvgs/arrowDown';
import {styles} from './smallDesign.style';

const SelectedCountry = (props: IProfilePicProps) => {
  const {isUpdate} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const {selectedCountry} = isUpdate
    ? React.useContext(userProfileContext)
    : React.useContext(SignUpContext);
  const style = styles(ColorTheme);
  return (
    <View style={style.termView}>
      <Text style={style.coutnryCode}>{`${selectedCountry?.dial_code}`}</Text>
      <ArrowDown stroke={ColorTheme.color} />
    </View>
  );
};

export default React.memo(SelectedCountry);
