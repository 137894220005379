import {takeLatest, put, call, select, delay} from 'redux-saga/effects';
import {AppConst, FixedValue} from '../../constants';
import {IActionType} from '../../types/constantFilesTypes';
import {
  ICommonResponse,
  INotesListDataResponse,
  IRecentSearchTopResponse,
  ISearchTopListResponse,
  ITopDetailDataResponse,
  IUserListSearchSuccessRespones,
} from '../../types/reducerTypes/searchTabStoreType';
import {
  _notesAddAPI,
  _notesDeleteAPI,
  _notesListAPI,
  _notesUpdateAPI,
  _recentSearchTopList,
  _searchTopByText,
  _searchUserByText,
  _showTopToRecentList,
} from '../../utils/apis';
import {authState} from '../reducers/authSlice';
import {
  onSearchListEnd,
  onSearchListFailed,
  onSearchListSuccess,
  onRecentSearchListSuccess,
  onRecentSearchListFailed,
  onRecentSearchListEnd,
  onTopDetailDataSuccess,
  onTopDetailDataFailed,
  onTopDetailDataEnd,
  onNotesAddSuccess,
  onNotesAddFailed,
  onNotesAddEnd,
  onNotesUpdateSuccess,
  onNotesUpdateFailed,
  onNotesUpdateEnd,
  onNotesListSuccess,
  onNotesListFailed,
  onNotesListEnd,
  onNotesDeleteSuccess,
  onNotesDeleteFailed,
  onNotesDeleteEnd,
  onUserSearchListSuccess,
  onUserSearchListFailed,
  onUserSearchListEnd,
} from '../reducers/searchTabSlice';
import {isWebsite} from '../../utils/responsive';
import {_ToastHandler} from '../../utils/helperFunctions';

const {StoreConstants} = AppConst;

function* getSearchTopList(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ISearchTopListResponse = yield call(
      _searchTopByText,
      action.payload,
      userTokens
    );
    yield put(onSearchListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onSearchListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onSearchListEnd());
  }
}

function* getRecentSearchTopList() {
  try {
    const {userTokens} = yield select(authState);
    const data: IRecentSearchTopResponse = yield call(
      _recentSearchTopList,
      userTokens
    );
    yield put(onRecentSearchListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onRecentSearchListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onRecentSearchListEnd());
  }
}

function* makeTopToRecent(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ITopDetailDataResponse = yield call(
      _showTopToRecentList,
      action.payload,
      userTokens
    );
    yield put(onTopDetailDataSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onTopDetailDataFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onTopDetailDataEnd());
  }
}

function* notesAddFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ICommonResponse = yield call(
      _notesAddAPI,
      action.payload,
      userTokens
    );
    yield put(onNotesAddSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onNotesAddFailed(error));
  } finally {
    if (isWebsite()) yield delay(FixedValue.CONSTANT_VALUE_1);
    yield put(onNotesAddEnd());
  }
}

function* notesUpdateFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ICommonResponse = yield call(
      _notesUpdateAPI,
      action.payload,
      userTokens
    );
    yield put(onNotesUpdateSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onNotesUpdateFailed(error));
  } finally {
    if (isWebsite()) yield delay(FixedValue.CONSTANT_VALUE_1);
    yield put(onNotesUpdateEnd());
  }
}

function* notesListFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: INotesListDataResponse = yield call(
      _notesListAPI,
      action.payload,
      userTokens
    );
    yield put(onNotesListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onNotesListFailed(error));
  } finally {
    if (isWebsite()) yield delay(FixedValue.CONSTANT_VALUE_1);
    yield put(onNotesListEnd());
  }
}

function* notesDeleteFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ICommonResponse = yield call(
      _notesDeleteAPI,
      action.payload,
      userTokens
    );
    yield put(onNotesDeleteSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onNotesDeleteFailed(error));
  } finally {
    if (isWebsite()) yield delay(FixedValue.CONSTANT_VALUE_1);
    yield put(onNotesDeleteEnd());
  }
}

function* getUserSearchTopList(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserListSearchSuccessRespones = yield call(
      _searchUserByText,
      action.payload,
      userTokens
    );
    yield put(onUserSearchListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onUserSearchListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onUserSearchListEnd());
  }
}

export default function* searchTabSaga() {
  yield takeLatest(
    `${StoreConstants.SEARCH_TAB}/onSearchListStart`,
    getSearchTopList
  );
  yield takeLatest(
    `${StoreConstants.SEARCH_TAB}/onRecentSearchListStart`,
    getRecentSearchTopList
  );
  yield takeLatest(
    `${StoreConstants.SEARCH_TAB}/onTopDetailDataStart`,
    makeTopToRecent
  );
  yield takeLatest(
    `${StoreConstants.SEARCH_TAB}/onNotesAddStart`,
    notesAddFunction
  );
  yield takeLatest(
    `${StoreConstants.SEARCH_TAB}/onNotesUpdateStart`,
    notesUpdateFunction
  );
  yield takeLatest(
    `${StoreConstants.SEARCH_TAB}/onNotesListStart`,
    notesListFunction
  );
  yield takeLatest(
    `${StoreConstants.SEARCH_TAB}/onNotesDeleteStart`,
    notesDeleteFunction
  );
  yield takeLatest(
    `${StoreConstants.SEARCH_TAB}/onUserSearchListStart`,
    getUserSearchTopList
  );
}
