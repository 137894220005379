import React from 'react';
import {View} from 'react-native';

import {COLORS, FixedValue} from '../../constants';
import {ISizeController} from '../../types/componentTypes/editorType';
import Rotate from '../renderSvgs/leftRotation';
import SizeSlider from './sizeSlider';

const RotationController = (props: ISizeController): JSX.Element => {
  const {result, onChangeValue} = props;
  return (
    <SizeSlider
      values={result}
      onValuesChange={onChangeValue}
      LeftIcon={<Rotate stroke={COLORS.WHITE} />}
      RightIcon={
        <View style={{transform: [{rotateY: '180deg'}]}}>
          <Rotate stroke={COLORS.WHITE} />
        </View>
      }
      min={-FixedValue.CONSTANT_VALUE_180}
      max={FixedValue.CONSTANT_VALUE_180}
    />
  );
};

export default React.memo(RotationController);
