import StyleSheet from 'react-native-media-query';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  BASESTYLE,
  COLORS,
  FONT_NAMES,
  FixedValue,
  Percentage,
  GlobalStyleValues,
} from '../../constants';
import {
  SCREEN_WIDTH,
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const Style = (_theme?: IColorTheme) =>
  StyleSheet.create({
    topTradingMainContainer: {
      ...BASESTYLE.flex1,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : SCREEN_WIDTH,
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    topTradingHeaderstyle: {
      ...BASESTYLE.inRow,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    topTradingHeadertext: {
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.flex1,
    },
    topTradingTitleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: _theme?.color,
    },
    headerText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_24),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: _theme?.color,
      width: Percentage.PRECENTAGE_77,
    },
    day: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_10),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: _theme?.color,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_24),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_1),
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_0),
    },
    imageView: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_78),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_71),
      backgroundColor: _theme?.color,
      ...BASESTYLE.inCenter,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_12),
    },
    buttonView: {
      ...BASESTYLE.row,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_11),
    },
    primaryButtonStyle: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_38),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_121),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      marginRight: FixedValue.CONSTANT_VALUE_10,
    },
    primaryButtonStyle2: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_38),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_118),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
    },
    primaryButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    primaryButtonLabelstyle1: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.F54336,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    noButtonBackGroundColor: {
      backgroundColor: COLORS.FDE4E1,
    },
    noButtonBackGroundColor1: {
      backgroundColor: COLORS._4EAF51,
    },
    mainView: {
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      width: Percentage.PRECENTAGE_100,
    },
    seperatorView: {
      backgroundColor: COLORS.B6BFC9,
      height: FixedValue.CONSTANT_VALUE_1,
      width: Percentage.PRECENTAGE_100,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    mainViewTextContainer: {
      ...BASESTYLE.row,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      width: Percentage.PRECENTAGE_100,
      justifyContent: GlobalStyleValues.SPACE_BETWEEN,
    },
  });
