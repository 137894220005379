import React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';
const Gallery = (props: SvgProps) => (
  <Svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M10 5.33301H10.0067"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M11.3333 2.6665H4.66663C3.56206 2.6665 2.66663 3.56193 2.66663 4.6665V11.3332C2.66663 12.4377 3.56206 13.3332 4.66663 13.3332H11.3333C12.4379 13.3332 13.3333 12.4377 13.3333 11.3332V4.6665C13.3333 3.56193 12.4379 2.6665 11.3333 2.6665Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M2.66663 9.99997L5.33329 7.3333C5.63733 7.04074 5.98222 6.88672 6.33329 6.88672C6.68437 6.88672 7.02925 7.04074 7.33329 7.3333L10.6666 10.6666"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M9.33325 9.33298L9.99992 8.66631C10.304 8.37375 10.6488 8.21973 10.9999 8.21973C11.351 8.21973 11.6959 8.37375 11.9999 8.66631L13.3333 9.99964"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default Gallery;
