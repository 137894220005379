import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  GlobalStyleValues,
  Percentage,
  FONT_NAMES,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
  SCREEN_HEIGHT,
} from '../../utils/responsive';

export const distributiveTopStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    modalStyle: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.selfCenter,
      justifyContent: GlobalStyleValues.FLEX_END,
      backgroundColor: COLORS.LGHT_BLCK_02,
      width: isWebsite()
        ? FixedValue.CONSTANT_VALUE_500
        : Percentage.PRECENTAGE_100,
      alignItems: GlobalStyleValues.CENTER,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    innerView: {
      ...BASESTYLE.flex1,
      backgroundColor: _theme?.backgroundColor,
      width: Percentage.PRECENTAGE_100,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      maxHeight: SCREEN_HEIGHT - scaleHeight(FixedValue.CONSTANT_VALUE_52),
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_16,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_16,
      shadowColor: _theme?.color,
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
      shadowRadius: FixedValue.CONSTANT_VALUE_5,
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_2,
        height: FixedValue.CONSTANT_VALUE_2,
      },
      elevation: FixedValue.CONSTANT_VALUE_2,
    },
    paddingHorizontal: {
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    extraSpace: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    tabBarStyle: {
      width: Percentage.PRECENTAGE_90,
    },
    outterPart: {
      width: Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      ...BASESTYLE.absolutePosition,
      zIndex: FixedValue.CONSTANT_VALUE_MIN_1,
      backgroundColor: _theme?.loaderBackgroundColor,
    },
    imageTabsContainer: {
      ...BASESTYLE.inCenter,
      flexDirection: GlobalStyleValues.ROW,
      justifyContent: GlobalStyleValues.SPACE_BETWEEN,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_48),
    },
    imageCntainer: {
      ...BASESTYLE.inCenter,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_50),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_50),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_50),
      backgroundColor: COLORS.E9EEFB,
    },
    backgroundColorBlue: {
      backgroundColor: COLORS.PRIMARY_BLUE,
    },
    tabTitle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_LIGHT,
      color: _theme.color,
      textAlign: GlobalStyleValues.CENTER,
    },
    activeColor: {
      color: _theme.color,
    },
    unActiveColor: {
      color: COLORS._666666,
    },
    marginTop40: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_40),
    },
    alignItemsCenter: {
      alignItems: GlobalStyleValues.CENTER,
    },
    searchContainer: {
      marginHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_30),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
  });
