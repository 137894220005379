import {StyleSheet} from 'react-native';
import {BASESTYLE, COLORS, FixedValue} from '../../../constants';
import {FontFamily} from '../../../types/componentTypes/editorType';
import {normalizeFont, scaleHeight} from '../../../utils/responsive';

export const styles = (fontFamily: string, isSelected: boolean) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_16),
    },
    fontText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      color: COLORS.BLACK,
      fontFamily,
      fontWeight: isSelected ? '600' : '400',
    },
  });
