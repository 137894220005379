import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  FixedValue,
  Percentage,
  GlobalStyleValues,
  COLORS,
  FONT_NAMES,
  FONTS,
} from '../../constants';
import { IColorTheme } from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const FavoriteListStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.flex1,
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    extraTopMargin: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    userName: {
      color: theme.color,
      width: Percentage.PRECENTAGE_70,
      fontWeight: '600',
      fontFamily: FONT_NAMES.SF_REGULAR,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
    },
    userItem: {
      flexDirection: GlobalStyleValues.ROW,
      alignItems: GlobalStyleValues.CENTER,
      flex: FixedValue.CONSTANT_VALUE_1,
    },
    userContainer: {
      flexDirection: GlobalStyleValues.ROW,
      alignContent: GlobalStyleValues.SPACE_BETWEEN,
      paddingVertical: FixedValue.CONSTANT_VALUE_8,
      flex: FixedValue.CONSTANT_VALUE_1,
      alignItems: GlobalStyleValues.CENTER,
    },
    profileImage: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_40),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_40),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      backgroundColor: theme?.color,
      ...BASESTYLE.inCenter,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
    },
    userImage: {
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
    },
    groupProfileText: {
      justifyContent: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      color: theme?.backgroundColor,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      textTransform: GlobalStyleValues.UPPERCASE,
    },
    removeContainer: {
      backgroundColor: COLORS.E9EEFB,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_87),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_34),
      justifyContent: GlobalStyleValues.CENTER,
      alignItems: GlobalStyleValues.CENTER,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_14),
    },
    removeButton: {
      color: COLORS._1F53D3,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONTS.SF_MEDIUM,
    },
    listContainer: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    emptyView: {
      marginHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_28),
    },
    scrollviewStyle: {
      ...BASESTYLE.flex1,
      justifyContent: GlobalStyleValues.CENTER,
    },
  });
