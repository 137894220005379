import {takeLatest, put, call, delay, select} from 'redux-saga/effects';
import {AppConst, TEXT} from '../../constants';
import {sendReminder, trackingCancel} from '../../utils/apis';
import {_ToastHandler, okAlertPolyfill} from '../../utils/helperFunctions';
import {isWebsite} from '../../utils/responsive';
import {authState} from '../reducers/authSlice';
import {
  ISendReminderResponse,
  ITrackingCancelResponse,
} from '../../types/reducerTypes/tradeTabTypes';
import {IActionType} from '../../types/constantFilesTypes';
import {
  onSendReminderEnd,
  onSendReminderFailed,
  onSendReminderSuccess,
} from '../reducers/sendReminderSlice';
import { Alert } from 'react-native';

const {StoreConstants} = AppConst;

function* sendReminderContact(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ISendReminderResponse = yield call(
      sendReminder,
      action.payload,
      userTokens
    );
    okAlertPolyfill(
      '',
      TEXT.REMINDER_SENT,
      () => {
      }
    );
    yield put(onSendReminderSuccess({}));
  } catch (error: any) {
    okAlertPolyfill(
      TEXT.ERROR,
      error?.response?.data?.message || TEXT.ERROR_ALERT,
      () => {
      }
    )
    yield put(onSendReminderFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onSendReminderEnd());
  }
}

export default function* sendReminderSaga() {
  yield takeLatest(
    `${StoreConstants.SEND_REMINDER}/onSendReminderStart`,
    sendReminderContact
  );
}
