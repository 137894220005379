import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';

const Calendar = (props: SvgProps) => (
  <Svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M9.5 2H2.5C1.94772 2 1.5 2.44772 1.5 3V10C1.5 10.5523 1.94772 11 2.5 11H9.5C10.0523 11 10.5 10.5523 10.5 10V3C10.5 2.44772 10.0523 2 9.5 2Z"
      stroke={props.stroke ?? 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M1.5 5H10.5"
      stroke={props.stroke ?? 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8 1V3"
      stroke={props.stroke ?? 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M4 1V3"
      stroke={props.stroke ?? 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default Calendar;
