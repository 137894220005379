import StyleSheet from 'react-native-media-query';
import {BASESTYLE, FixedValue, Percentage, COLORS} from '../../../constants';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {isWebsite, scaleHeight, scaleWidth} from '../../../utils/responsive';

export const NotificationTabStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.selfCenter,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    container: {
      ...BASESTYLE.flex1,
      marginTop:scaleHeight(FixedValue.CONSTANT_VALUE_19),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_71),
    },
    bottomLineStyle: {
      backgroundColor: COLORS._E6E6E6,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_1),
    },
    tabBarStyle: {
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
  });
