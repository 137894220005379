import React from 'react';
import {View, Text} from 'react-native';
import {FixedValue, TEXT} from '../../../constants';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {
  checkTopCreditGreaterThanOne,
  formatUTCTimeToLocalTime,
} from '../../../utils/helperFunctions';
import {styles} from './styles';
import {CardProps} from '../../../types/reducerTypes/searchTabStoreType';
import TopCard from '../../mantelTopTabComp/topCardWithQuantity';
import {timeformat} from '../../../constants/enum';
import {HomeContext} from '../../../contextAPI/homeContext';
import {HomeStore} from '../../../redux/reducers/homeSlice';
import {useAppSelector} from '../../../hooks/reduxHooks';

const CardTile = (props: CardProps): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  const {
    topModel,
    onAddButtonTap,
    onUpdateQuantityTap,
    isAmount,
    isInputQuantity,
    onChangeQuantity,
    setIskeyboardFocused,
    callAddAndSubFunction = false,
    checkQuantityOnOnChange = false,
  } = props;
  const {topAmount} = React.useContext(HomeContext);
  const {topAmountAndReportAbuseData} = useAppSelector(HomeStore);
  return (
    <View style={style.containerView}>
      <View style={style.infoView}>
        <Text
          style={style.groupNameText}
          numberOfLines={FixedValue.CONSTANT_VALUE_1}
        >
          {topModel?.name}
        </Text>
        <Text
          style={style.createdDate}
          numberOfLines={FixedValue.CONSTANT_VALUE_1}
        >
          {TEXT.CREATED_AT}
          {formatUTCTimeToLocalTime(
            topModel?.creationDate as string,
            timeformat.MMM_DD_YYYY
          )}
        </Text>
        {isAmount ? (
          <>
            <Text
              style={style.quantityText}
              numberOfLines={FixedValue.CONSTANT_VALUE_1}
            >
              {`Quantity: ${
                topModel?.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0
              }`}
            </Text>
            <Text
              style={style.quantityText}
              numberOfLines={FixedValue.CONSTANT_VALUE_1}
            >
              {`Amount:`}
              {(topModel?.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0) >
              FixedValue.CONSTANT_VALUE_1
                ? ` ${checkTopCreditGreaterThanOne(topModel?.selectedQuantity)}`
                : ` $${
                    topAmountAndReportAbuseData?.success?.data?.singleTopPrice
                  }${' Or '} ${checkTopCreditGreaterThanOne(topAmount)}`}
            </Text>
          </>
        ) : (
          <Text
            style={style.quantityText}
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
          >
            Quantity: {topModel?.quantity ?? FixedValue.CONSTANT_VALUE_0}
          </Text>
        )}
      </View>
      <TopCard
        topModel={topModel}
        onAddButtonTap={onAddButtonTap}
        onUpdateQuantityTap={(val, quant) => onUpdateQuantityTap(val, quant)}
        isInputQuantity={isInputQuantity}
        onChangeQuantity={onChangeQuantity}
        setIskeyboardFocused={setIskeyboardFocused}
        callAddAndSubFunction={callAddAndSubFunction}
        checkQuantityOnOnChange={checkQuantityOnOnChange}
      />
    </View>
  );
};

export default React.memo(CardTile);
