import React, {useCallback} from 'react';
import {Pressable, Text, View} from 'react-native';

import ModalHeaderWithCross from '../../components/header/modalHeaderWithCross';
import SearchInput from '../../components/input/searchInput';
import DistributeTopDetail from '../../components/smallDesigns/distributeTopDetail';
import {COLORS, FixedValue, TEXT} from '../../constants';
import DistributeGroupScreen from '../../components/distributeTopTab/distributeGroup';
import {distributiveTopStyle} from './distributiveTop.style';
import {HomeContext} from '../../contextAPI/homeContext';
import useHomeHooks from '../../hooks/componentHooks/useHomeHooks';
import DistributeIndivitual from '../../components/distributeTopTab/distributeIndivitual';
import Heart from '../../components/renderSvgs/heart';
import Group from '../../components/renderSvgs/group';
import Earth from '../../components/renderSvgs/earth';
import Invite from '../../components/renderSvgs/invite';
import {ThemeContext} from '../../contextAPI/themeContext';
import InviteByEmailOrPhone from '../../components/inviteForDistribute';
import DistributeFavourite from '../../components/distributeTopTab/distributeFavourite';

const DistributiveTopContent = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = distributiveTopStyle(ColorTheme);
  const {
    searchUserListText,
    searchGroupListText,
    selectedGroupDetail,
    searchFavouriteUserListText,
  } = React.useContext(HomeContext);
  const {
    onChangeSearchUserText,
    resetDistributeTopsHelperData,
    onChangeSearchGroupText,
  } = useHomeHooks();
  const [tabIndex, setTabIndex] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_1
  );
  const {onChangeSearchFavouriteUser} = useHomeHooks();

  const imageTabs = [
    {
      id: FixedValue.CONSTANT_VALUE_1,
      text: TEXT.FAVORITES,
      activeTabIcon: <Heart />,
      unActiveTabIcon: <Heart fill={COLORS.WHITE} />,
    },
    {
      id: FixedValue.CONSTANT_VALUE_2,
      text: TEXT.INVITE,
      activeTabIcon: <Invite />,
      unActiveTabIcon: <Invite fill={COLORS.WHITE} />,
    },
    {
      id: FixedValue.CONSTANT_VALUE_3,
      text: TEXT.GROUPS,
      activeTabIcon: <Group />,
      unActiveTabIcon: <Group fill={COLORS.WHITE} />,
    },
    {
      id: FixedValue.CONSTANT_VALUE_4,
      activeTabIcon: <Earth />,
      text: TEXT.MANTELVERSE,
      unActiveTabIcon: <Earth fill={COLORS.WHITE} />,
    },
  ];

  const getComponentToRender = useCallback(() => {
    switch (tabIndex) {
      case FixedValue.CONSTANT_VALUE_1:
        return <DistributeFavourite />;
      case FixedValue.CONSTANT_VALUE_2:
        return <InviteByEmailOrPhone />;
      case FixedValue.CONSTANT_VALUE_3:
        return <DistributeGroupScreen />;
      case FixedValue.CONSTANT_VALUE_4:
        return <DistributeIndivitual />;
      default:
        return null;
    }
  }, [tabIndex]);

  const onSearchTextChange = useCallback(
    (text: string) => {
      if (tabIndex === FixedValue.CONSTANT_VALUE_1) {
        onChangeSearchFavouriteUser(text);
      } else if (tabIndex === FixedValue.CONSTANT_VALUE_4) {
        onChangeSearchUserText(text);
      } else if (tabIndex === FixedValue.CONSTANT_VALUE_3) {
        onChangeSearchGroupText(text);
      }
    },
    [tabIndex, onChangeSearchUserText, onChangeSearchGroupText]
  );

  return (
    <>
      {selectedGroupDetail ? (
        <DistributeIndivitual />
      ) : (
        <>
          <View style={styles.paddingHorizontal}>
            <ModalHeaderWithCross
              headerTitle={TEXT.DISTRIBUTE_TOP_}
              onPress={resetDistributeTopsHelperData}
            />
            <DistributeTopDetail />
          </View>
          <View style={styles.extraSpace} />
          <View style={styles.imageTabsContainer}>
            {imageTabs.map(tab => {
              const isActiveTab = tab.id === tabIndex;
              const textColor = isActiveTab
                ? styles.activeColor
                : styles.unActiveColor;
              return (
                <View key={tab.id} style={styles.alignItemsCenter}>
                  <Pressable onPress={() => setTabIndex(tab.id)}>
                    <View
                      style={[
                        styles.imageCntainer,
                        isActiveTab ? styles.backgroundColorBlue : null,
                      ]}
                    >
                      {isActiveTab ? tab.unActiveTabIcon : tab.activeTabIcon}
                    </View>
                  </Pressable>
                  <Text style={[styles.tabTitle, textColor]}>{tab?.text}</Text>
                </View>
              );
            })}
          </View>
          {tabIndex !== FixedValue.CONSTANT_VALUE_2 ? (
            <View style={styles.searchContainer}>
              <SearchInput
                placeholder={
                  tabIndex !== FixedValue.CONSTANT_VALUE_3
                    ? TEXT.SEARCH_USERS
                    : TEXT.SEARCH_GROUPS
                }
                value={
                  tabIndex === FixedValue.CONSTANT_VALUE_1
                    ? searchFavouriteUserListText
                    : tabIndex === FixedValue.CONSTANT_VALUE_4
                    ? searchUserListText
                    : searchGroupListText
                }
                onChangeText={onSearchTextChange}
              />
            </View>
          ) : (
            <></>
          )}
          {getComponentToRender()}
        </>
      )}
    </>
  );
};

export default React.memo(DistributiveTopContent);
