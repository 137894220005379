import StyleSheet from 'react-native-media-query';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {
  BASESTYLE,
  FONT_NAMES,
  FixedValue,
  Percentage,
} from '../../../constants';
import {
  SCREEN_WIDTH,
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const TopTradinglStyle = (theme?: IColorTheme) =>
  StyleSheet.create({
    topTradingMainContainer: {
      ...BASESTYLE.flex1,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : SCREEN_WIDTH,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    topTradingHeaderstyle: {
      ...BASESTYLE.row,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    topTradingHeadertext: {
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.flex1,
    },
    topTradingContainer: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_12),
      ...BASESTYLE.flex1,
    },
    topTradingTextView: {
      marginLeft: FixedValue.CONSTANT_VALUE_21,
    },
    topTradingTitleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: theme?.color,
    },
  });
