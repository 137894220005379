import React from 'react';
import {FlatList, ScrollView, View} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';
import {useIsFocused} from '@react-navigation/native';

import EmptyChildListView from '../../components/emptyListView/emptyChildListView';
import GroupInvitationListComponent from '../../components/groupInvitationListComponent';
import {FixedValue, TEXT} from '../../constants';
import {GroupContext} from '../../contextAPI/groupManagementContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import useGroupManagementListHooks from '../../hooks/componentHooks/useGroupManagementListHooks';
import {useAppSelector} from '../../hooks/reduxHooks';
import {groupManagementStore} from '../../redux/reducers/groupManagementSlice';
import {groupInviationsStyle} from './groupInvitations.style';
import ApprovedRejectCustomModal from '../../modals/approvedRejectCustomModal';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import {CHILD_PERMISSION, GlobalStyleValues} from '../../constants/enum';
import {
  GROUP_INVITATION_APPROVE,
  GROUP_INVITATION_REJECT,
} from '../../constants/actionTypes';

const GroupInvitationsList = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = groupInviationsStyle(ColorTheme);
  const {userData} = useAppSelector(userProfileStore);
  const isFocused = useIsFocused();
  const userDetail = userData.success;
  const showParentGuardianManagement =
    userDetail?.role === TEXT.ROLE_PARENT ||
    userDetail?.permissionsStatus?.groupPermissionStatus ===
      CHILD_PERMISSION.ALLOWED;
  const {
    groupInvitationsListResult,
    groupInvitationApprovedModal,
    clickOnApprovedButton,
    groupMangementPullDownRefresh,
    setGroupInvitationApprovedModal,
    setGroupMangementPullDownRefresh,
  } = React.useContext(GroupContext);
  const {groupInvitaionsList, groupInvitaionApprove, groupInvitaionReject} =
    useAppSelector(groupManagementStore);
  const {
    callGroupInvitationListAPI,
    onMyGroupInvitationsListReachedEnd,
    onApproveButton,
    onRejectButton,
    callGroupInvitationsApproveRejectAPI,
  } = useGroupManagementListHooks();

  const handleRefresh = React.useCallback(() => {
    setGroupMangementPullDownRefresh(true);
    callGroupInvitationListAPI(FixedValue.CONSTANT_VALUE_0);
  }, [groupMangementPullDownRefresh]);

  React.useEffect(() => {
    if (isFocused)
      switch (groupInvitaionApprove.status) {
        case GROUP_INVITATION_APPROVE.SUCCESS:
          setGroupInvitationApprovedModal();
          break;
        case GROUP_INVITATION_APPROVE.FAILED:
          setGroupInvitationApprovedModal();
          break;
        default:
          break;
      }
  }, [groupInvitaionApprove.status, isFocused]);

  React.useEffect(() => {
    if (isFocused)
      switch (groupInvitaionReject.status) {
        case GROUP_INVITATION_REJECT.SUCCESS:
          setGroupInvitationApprovedModal();
          break;
        default:
          break;
      }
  }, [groupInvitaionReject.status, isFocused]);

  //show empty view when there is no data available
  const renderGroupInvitationsEmptyUI =
    React.useMemo((): JSX.Element | null => {
      if (groupInvitationsListResult?.length === FixedValue.CONSTANT_VALUE_0) {
        return (
          <ScrollView
            contentContainerStyle={styles.scrollviewStyle}
            showsVerticalScrollIndicator={false}
            refreshControl={
              <RefreshControl
                refreshing={groupMangementPullDownRefresh}
                onRefresh={handleRefresh}
              />
            }
          >
            <View
              style={[
                styles.listMainView,
                {justifyContent: GlobalStyleValues.CENTER},
              ]}
            >
              <View style={styles.emptyView}>
                <EmptyChildListView
                  emptyViewTitle={TEXT.SORRY_NO_INVITATIONS}
                />
              </View>
            </View>
          </ScrollView>
        );
      }
      return null;
    }, [groupInvitationsListResult, groupMangementPullDownRefresh]);

  const renderInvitationFlatList = React.useMemo((): JSX.Element | null => {
    return (
      <FlatList
        keyExtractor={(_, index) => `${TEXT.GROUP_LIST}-${index}`}
        data={groupInvitationsListResult}
        contentContainerStyle={styles.flatListContentContainerStyle}
        onEndReached={() => onMyGroupInvitationsListReachedEnd()}
        onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={groupMangementPullDownRefresh}
            onRefresh={handleRefresh}
          />
        }
        scrollEnabled
        renderItem={({item}) => (
          <GroupInvitationListComponent
            key={item.invitationId}
            item={item}
            onApproveButton={onApproveButton}
            onRejectButton={onRejectButton}
            showParentGuardianManagement={showParentGuardianManagement}
          />
        )}
      />
    );
  }, [groupInvitationsListResult, groupMangementPullDownRefresh]);

  return (
    <>
      {!groupInvitaionsList?.isLoading && renderGroupInvitationsEmptyUI}
      {groupInvitationsListResult?.length > FixedValue.CONSTANT_VALUE_0 && (
        <View style={styles.listMainView}>{renderInvitationFlatList}</View>
      )}
      {groupInvitationApprovedModal && (
        <ApprovedRejectCustomModal
          modalVisible={groupInvitationApprovedModal}
          onClose={setGroupInvitationApprovedModal}
          actionType={clickOnApprovedButton}
          callGroupInvitationsApproveRejectAPI={
            callGroupInvitationsApproveRejectAPI
          }
        />
      )}
    </>
  );
};
export default React.memo(GroupInvitationsList);
