import {createSlice} from '@reduxjs/toolkit';
import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {ResetAllState} from './logoutslice';
import {ICreateChildProfile} from '../../types/reducerTypes/childProfileTypes';
import {DISTRIBUTE_RECEIVED_REQUEST_LIST} from '../../constants/actionTypes';

const {StoreConstants} = AppConst;
const {
  TRADE_APPROVAL_SETTING,
  TRADE_NOT_APPROVE_USER_LIST,
  USER_ADD_INTO_TRADE,
  TRADE_APPROVE_USER_LIST,
  GROUP_INVITATION_CHILD_LIST,
  USER_REMOVE_FROM_TRADE,
  TRADE_APPROVAL_RECEIVED_LIST,
  GROUP_CHILD_INVITATION_APPROVED,
  GROUP_CHILD_INVITATION_REJECT,
  CHILD_GROUP_APPROVAL_SETTING,
  CHILD_TRADABLE_REQUEST_LIST,
  TRADE_APPROVAL_SENT_LIST,
  SENT_TRADE_APPROVE_OR_REJECT_API,
  CHILD_TRADABLE_SETTING,
  CHILD_TRADABLE_APPROVED_REJECT,
  DISTRIBUTE_TOP_APPROVAL_SETTING,
  USER_ADD_INTO_DISTRIBUTE,
  DISTRIBUTE_APPROVE_USER_LIST,
  USER_REMOVE_FROM_DISTRIBUTE_APPROVED_LIST,
  DISTRIBUTE_REQUEST_LIST,
  DISTRIBUTE_REQUEST_APPROVE_OR_REJECT_API,
  DISTRIBUTE_RECEIVED_REQUEST_APPROVE_OR_REJECTOR_REPORT_API,
} = ActionType;

const initialState: ICreateChildProfile = {
  tradeSettingApproval: {
    isLoading: false,
    success: null,
    failed: null,
    status: TRADE_APPROVAL_SETTING.END,
  },
  tradeNotApprovedUserList: {
    isLoading: false,
    success: null,
    failed: null,
    status: TRADE_NOT_APPROVE_USER_LIST.END,
  },
  onTradeUserAdd: {
    isLoading: false,
    success: null,
    failed: null,
    status: USER_ADD_INTO_TRADE.END,
  },
  onTradeApprovedListData: {
    isLoading: false,
    success: null,
    failed: null,
    status: TRADE_APPROVE_USER_LIST.END,
  },
  onGroupInvitationChild: {
    isLoading: false,
    success: null,
    failed: null,
    status: GROUP_INVITATION_CHILD_LIST.END,
  },
  onUserRemoveFromTrade: {
    isLoading: false,
    success: null,
    failed: null,
    status: USER_REMOVE_FROM_TRADE.END,
  },
  onTradeApprovalReceivedListData: {
    isLoading: false,
    success: null,
    failed: null,
    status: TRADE_APPROVAL_RECEIVED_LIST.END,
  },
  groupChildInvitaionApprove: {
    isLoading: false,
    success: null,
    failed: null,
    status: GROUP_CHILD_INVITATION_APPROVED.END,
  },
  groupChildInvitaionReject: {
    isLoading: false,
    success: null,
    failed: null,
    status: GROUP_CHILD_INVITATION_REJECT.END,
  },
  childGroupSettingApproval: {
    isLoading: false,
    success: null,
    failed: null,
    status: CHILD_GROUP_APPROVAL_SETTING.END,
  },
  childTradableRequestListData: {
    isLoading: false,
    success: null,
    failed: null,
    status: CHILD_TRADABLE_REQUEST_LIST.END,
  },
  onTradeApprovalSentListData: {
    isLoading: false,
    success: null,
    failed: null,
    status: TRADE_APPROVAL_SENT_LIST.END,
  },
  onSentTradeApproveOrRejectData: {
    isLoading: false,
    success: null,
    failed: null,
    status: SENT_TRADE_APPROVE_OR_REJECT_API.END,
  },
  childTradableSetting: {
    isLoading: false,
    success: null,
    failed: null,
    status: CHILD_TRADABLE_SETTING.END,
  },
  childTradableApproveReject: {
    isLoading: false,
    success: null,
    failed: null,
    status: CHILD_TRADABLE_APPROVED_REJECT.END,
  },
  distributeTopSettingApproval: {
    isLoading: false,
    success: null,
    failed: null,
    status: DISTRIBUTE_TOP_APPROVAL_SETTING.END,
  },
  onDistributeUserAdd: {
    isLoading: false,
    success: null,
    failed: null,
    status: USER_ADD_INTO_DISTRIBUTE.END,
  },
  onDistributeApprovedListData: {
    isLoading: false,
    success: null,
    failed: null,
    status: DISTRIBUTE_APPROVE_USER_LIST.END,
  },
  onUserRemoveFromDistribute: {
    isLoading: false,
    success: null,
    failed: null,
    status: USER_REMOVE_FROM_DISTRIBUTE_APPROVED_LIST.END,
  },
  onDistributeRequestListData: {
    isLoading: false,
    success: null,
    failed: null,
    status: DISTRIBUTE_REQUEST_LIST.END,
  },
  onSentDistributeApproveOrRejectData: {
    isLoading: false,
    success: null,
    failed: null,
    status: DISTRIBUTE_REQUEST_APPROVE_OR_REJECT_API.END,
  },
  onDistributeReceivedRequestData: {
    isLoading: false,
    success: null,
    failed: null,
    status: DISTRIBUTE_RECEIVED_REQUEST_LIST.END,
  },
  onReceivedDistributeApproveOrRejectOrReportData: {
    isLoading: false,
    success: null,
    failed: null,
    status: DISTRIBUTE_RECEIVED_REQUEST_APPROVE_OR_REJECTOR_REPORT_API.END,
  },
};

const childProfileSlice = createSlice({
  initialState,
  name: StoreConstants.CHILD_PROFILE,
  reducers: {
    onTradeSettingStart: (state, _) => {
      state.tradeSettingApproval.isLoading = true;
      state.tradeSettingApproval.status = TRADE_APPROVAL_SETTING.START;
    },
    onTradeSettingSuccess: (state, action) => {
      state.tradeSettingApproval.success = action.payload;
      state.tradeSettingApproval.status = TRADE_APPROVAL_SETTING.SUCCESS;
    },
    onTradeSettingFailed: (state, action) => {
      state.tradeSettingApproval.failed = action.payload;
      state.tradeSettingApproval.status = TRADE_APPROVAL_SETTING.FAILED;
    },
    onTradeSettingEnd: state => {
      state.tradeSettingApproval.isLoading = false;
      state.tradeSettingApproval.status = TRADE_APPROVAL_SETTING.END;
    },
    onTradeNotApprovedUserListStart: (state, _) => {
      state.tradeNotApprovedUserList.isLoading = true;
      state.tradeNotApprovedUserList.status = TRADE_NOT_APPROVE_USER_LIST.START;
    },
    onTradeNotApprovedUserListSuccess: (state, action) => {
      state.tradeNotApprovedUserList.success = action.payload;
      state.tradeNotApprovedUserList.status =
        TRADE_NOT_APPROVE_USER_LIST.SUCCESS;
    },
    onTradeNotApprovedUserListFailed: (state, action) => {
      state.tradeNotApprovedUserList.failed = action.payload;
      state.tradeNotApprovedUserList.status =
        TRADE_NOT_APPROVE_USER_LIST.FAILED;
    },
    onTradeNotApprovedUserListEnd: state => {
      state.tradeNotApprovedUserList.isLoading = false;
      state.tradeNotApprovedUserList.status = TRADE_NOT_APPROVE_USER_LIST.END;
    },
    onTradeUserAddStart: (state, _) => {
      state.onTradeUserAdd.isLoading = true;
      state.onTradeUserAdd.status = USER_ADD_INTO_TRADE.START;
    },
    onTradeUserAddSuccess: (state, action) => {
      state.onTradeUserAdd.success = action.payload;
      state.onTradeUserAdd.status = USER_ADD_INTO_TRADE.SUCCESS;
    },
    onTradeUserAddFailed: (state, action) => {
      state.onTradeUserAdd.failed = action.payload;
      state.onTradeUserAdd.status = USER_ADD_INTO_TRADE.FAILED;
    },
    onTradeUserAddEnd: state => {
      state.onTradeUserAdd.isLoading = false;
      state.onTradeUserAdd.status = USER_ADD_INTO_TRADE.END;
    },
    onTradeApprovedListStart: (state, _) => {
      state.onTradeApprovedListData.isLoading = true;
      state.onTradeApprovedListData.status = TRADE_APPROVE_USER_LIST.START;
    },
    onTradeApprovedListSuccess: (state, action) => {
      state.onTradeApprovedListData.success = action.payload;
      state.onTradeApprovedListData.status = TRADE_APPROVE_USER_LIST.SUCCESS;
    },
    onTradeApprovedListFailed: (state, action) => {
      state.onTradeApprovedListData.failed = action.payload;
      state.onTradeApprovedListData.status = TRADE_APPROVE_USER_LIST.FAILED;
    },
    onTradeApprovedListEnd: state => {
      state.onTradeApprovedListData.isLoading = false;
      state.onTradeApprovedListData.status = TRADE_APPROVE_USER_LIST.END;
    },
    onClearTradeApprovedListEnd: state => {
      state.onTradeApprovedListData.isLoading = false;
      state.onTradeApprovedListData.success = null;
      state.onTradeApprovedListData.failed = null;
      state.onTradeApprovedListData.status = TRADE_APPROVE_USER_LIST.END;
    },
    onGroupInvitationChildStart: (state, _) => {
      state.onGroupInvitationChild.isLoading = true;
      state.onGroupInvitationChild.status = GROUP_INVITATION_CHILD_LIST.START;
    },
    onGroupInvitationChildSuccess: (state, action) => {
      state.onGroupInvitationChild.success = action.payload ?? null;
      state.onGroupInvitationChild.status = GROUP_INVITATION_CHILD_LIST.SUCCESS;
    },
    onGroupInvitationChildFailed: (state, action) => {
      state.onGroupInvitationChild.failed = action.payload;
      state.onGroupInvitationChild.status = GROUP_INVITATION_CHILD_LIST.FAILED;
    },
    onGroupInvitationChildEnd: state => {
      state.onGroupInvitationChild.isLoading = false;
      state.onGroupInvitationChild.status = GROUP_INVITATION_CHILD_LIST.END;
    },
    onUserRemoveFromTradeApprovedListStart: (state, _) => {
      state.onUserRemoveFromTrade.isLoading = true;
      state.onUserRemoveFromTrade.status = USER_REMOVE_FROM_TRADE.START;
    },
    onUserRemoveFromTradeApprovedListSuccess: (state, action) => {
      state.onUserRemoveFromTrade.success = action.payload;
      state.onUserRemoveFromTrade.status = USER_REMOVE_FROM_TRADE.SUCCESS;
    },
    onUserRemoveFromTradeApprovedListFailed: (state, action) => {
      state.onUserRemoveFromTrade.failed = action.payload;
      state.onUserRemoveFromTrade.status = USER_REMOVE_FROM_TRADE.FAILED;
    },
    onUserRemoveFromTradeApprovedListEnd: state => {
      state.onUserRemoveFromTrade.isLoading = false;
      state.onUserRemoveFromTrade.status = USER_REMOVE_FROM_TRADE.END;
    },
    onTradeApprovalReceivedListStart: (state, _) => {
      state.onTradeApprovalReceivedListData.isLoading = true;
      state.onTradeApprovalReceivedListData.status =
        TRADE_APPROVAL_RECEIVED_LIST.START;
    },
    onTradeApprovalReceivedListSuccess: (state, action) => {
      state.onTradeApprovalReceivedListData.success = action.payload;
      state.onTradeApprovalReceivedListData.status =
        TRADE_APPROVAL_RECEIVED_LIST.SUCCESS;
    },
    onTradeApprovalReceivedListFailed: (state, action) => {
      state.onTradeApprovalReceivedListData.failed = action.payload;
      state.onTradeApprovalReceivedListData.status =
        TRADE_APPROVAL_RECEIVED_LIST.FAILED;
    },
    onTradeApprovalReceivedListEnd: state => {
      state.onTradeApprovalReceivedListData.isLoading = false;
      state.onTradeApprovalReceivedListData.status =
        TRADE_APPROVAL_RECEIVED_LIST.END;
    },
    onClearApprovalReceivedListEnd: state => {
      state.onTradeApprovalReceivedListData.isLoading = false;
      state.onTradeApprovalReceivedListData.success = null;
      state.onTradeApprovalReceivedListData.failed = null;
      state.onTradeApprovalReceivedListData.status =
        TRADE_APPROVAL_RECEIVED_LIST.END;
    },
    onGroupChildInvitaionApproveStart: (state, _) => {
      state.groupChildInvitaionApprove.isLoading = true;
      state.groupChildInvitaionApprove.status =
        GROUP_CHILD_INVITATION_APPROVED.START;
    },
    onGroupChildInvitaionApproveSuccess: (state, action) => {
      state.groupChildInvitaionApprove.success = action.payload;
      state.groupChildInvitaionApprove.status =
        GROUP_CHILD_INVITATION_APPROVED.SUCCESS;
    },
    onGroupChildInvitaionApproveFailed: (state, action) => {
      state.groupChildInvitaionApprove.failed = action.payload;
      state.groupChildInvitaionApprove.status =
        GROUP_CHILD_INVITATION_APPROVED.FAILED;
    },
    onGroupChildInvitaionApproveEnd: state => {
      state.groupChildInvitaionApprove.isLoading = false;
      state.groupChildInvitaionApprove.status =
        GROUP_CHILD_INVITATION_APPROVED.END;
    },
    onGroupChildInvitaionRejectStart: (state, _) => {
      state.groupChildInvitaionReject.isLoading = true;
      state.groupChildInvitaionReject.status =
        GROUP_CHILD_INVITATION_REJECT.START;
    },
    onGroupChildInvitaionRejectSuccess: (state, action) => {
      state.groupChildInvitaionReject.success = action.payload;
      state.groupChildInvitaionReject.status =
        GROUP_CHILD_INVITATION_REJECT.SUCCESS;
    },
    onGroupChildInvitaionRejectFailed: (state, action) => {
      state.groupChildInvitaionReject.failed = action.payload;
      state.groupChildInvitaionReject.status =
        GROUP_CHILD_INVITATION_REJECT.FAILED;
    },
    onGroupChildInvitaionRejectEnd: state => {
      state.groupChildInvitaionReject.isLoading = false;
      state.groupChildInvitaionReject.status =
        GROUP_CHILD_INVITATION_REJECT.END;
    },
    onChildGroupSettingStart: (state, _) => {
      state.childGroupSettingApproval.isLoading = true;
      state.childGroupSettingApproval.status =
        CHILD_GROUP_APPROVAL_SETTING.START;
    },
    onChildGroupSettingSuccess: (state, action) => {
      state.childGroupSettingApproval.success = action.payload;
      state.childGroupSettingApproval.status =
        CHILD_GROUP_APPROVAL_SETTING.SUCCESS;
    },
    onChildGroupSettingFailed: (state, action) => {
      state.childGroupSettingApproval.failed = action.payload;
      state.childGroupSettingApproval.status =
        CHILD_GROUP_APPROVAL_SETTING.FAILED;
    },
    onChildGroupSettingEnd: state => {
      state.childGroupSettingApproval.isLoading = false;
      state.childGroupSettingApproval.status = CHILD_GROUP_APPROVAL_SETTING.END;
    },
    onchildTradableRequestListStart: (state, _) => {
      state.childTradableRequestListData.isLoading = true;
      state.childTradableRequestListData.status =
        CHILD_TRADABLE_REQUEST_LIST.START;
    },
    onchildTradableRequestListSuccess: (state, action) => {
      state.childTradableRequestListData.success = action.payload;
      state.childTradableRequestListData.status =
        CHILD_TRADABLE_REQUEST_LIST.SUCCESS;
    },
    onchildTradableRequestListFailed: (state, action) => {
      state.childTradableRequestListData.failed = action.payload;
      state.childTradableRequestListData.status =
        CHILD_TRADABLE_REQUEST_LIST.FAILED;
    },
    onchildTradableRequestListEnd: state => {
      state.childTradableRequestListData.isLoading = false;
      state.childTradableRequestListData.status =
        CHILD_TRADABLE_REQUEST_LIST.END;
    },
    onClearChildTradableRequestListEnd: state => {
      state.childTradableRequestListData.isLoading = false;
      state.childTradableRequestListData.success = null;
      state.childTradableRequestListData.failed = null;
      state.childTradableRequestListData.status =
        CHILD_TRADABLE_REQUEST_LIST.END;
    },
    onTradeApprovalSentListStart: (state, _) => {
      state.onTradeApprovalSentListData.isLoading = true;
      state.onTradeApprovalSentListData.status = TRADE_APPROVAL_SENT_LIST.START;
    },
    onTradeApprovalSentListSuccess: (state, action) => {
      state.onTradeApprovalSentListData.success = action.payload;
      state.onTradeApprovalSentListData.status =
        TRADE_APPROVAL_SENT_LIST.SUCCESS;
    },
    onTradeApprovalSentListFailed: (state, action) => {
      state.onTradeApprovalSentListData.failed = action.payload;
      state.onTradeApprovalSentListData.status =
        TRADE_APPROVAL_SENT_LIST.FAILED;
    },
    onTradeApprovalSentListEnd: state => {
      state.onTradeApprovalSentListData.isLoading = false;
      state.onTradeApprovalSentListData.status = TRADE_APPROVAL_SENT_LIST.END;
    },
    onClearTradeApprovalSentListEnd: state => {
      state.onTradeApprovalSentListData.isLoading = false;
      state.onTradeApprovalSentListData.success = null;
      state.onTradeApprovalSentListData.failed = null;
      state.onTradeApprovalSentListData.status = TRADE_APPROVAL_SENT_LIST.END;
    },
    onSentTradeApproveOrRejectStart: (state, _) => {
      state.onSentTradeApproveOrRejectData.isLoading = true;
      state.onSentTradeApproveOrRejectData.status =
        SENT_TRADE_APPROVE_OR_REJECT_API.START;
    },
    onSentTradeApproveOrRejectSuccess: (state, action) => {
      state.onSentTradeApproveOrRejectData.success = action.payload;
      state.onSentTradeApproveOrRejectData.status =
        SENT_TRADE_APPROVE_OR_REJECT_API.SUCCESS;
    },
    onSentTradeApproveOrRejectFailed: (state, action) => {
      state.onSentTradeApproveOrRejectData.failed = action.payload;
      state.onSentTradeApproveOrRejectData.status =
        SENT_TRADE_APPROVE_OR_REJECT_API.FAILED;
    },
    onSentTradeApproveOrRejectEnd: state => {
      state.onSentTradeApproveOrRejectData.isLoading = false;
      state.onSentTradeApproveOrRejectData.status =
        SENT_TRADE_APPROVE_OR_REJECT_API.END;
    },
    onChildTradableSettingStart: (state, _) => {
      state.childTradableSetting.isLoading = true;
      state.childTradableSetting.status = CHILD_TRADABLE_SETTING.START;
    },
    onChildTradableSettingSuccess: (state, action) => {
      state.childTradableSetting.success = action.payload;
      state.childTradableSetting.status = CHILD_TRADABLE_SETTING.SUCCESS;
    },
    onChildTradableSettingFailed: (state, action) => {
      state.childTradableSetting.failed = action.payload;
      state.childTradableSetting.status = CHILD_TRADABLE_SETTING.FAILED;
    },
    onChildTradableSettingEnd: state => {
      state.childTradableSetting.isLoading = false;
      state.childTradableSetting.status = CHILD_TRADABLE_SETTING.END;
    },
    onChildTradableApproveRejectStart: (state, _) => {
      state.childTradableApproveReject.isLoading = true;
      state.childTradableApproveReject.status =
        CHILD_TRADABLE_APPROVED_REJECT.START;
    },
    onChildTradableApproveRejectSuccess: (state, action) => {
      state.childTradableApproveReject.success = action.payload;
      state.childTradableApproveReject.status =
        CHILD_TRADABLE_APPROVED_REJECT.SUCCESS;
    },
    onChildTradableApproveRejectFailed: (state, action) => {
      state.childTradableApproveReject.failed = action.payload;
      state.childTradableApproveReject.status =
        CHILD_TRADABLE_APPROVED_REJECT.FAILED;
    },
    onChildTradableApproveRejectEnd: state => {
      state.childTradableApproveReject.isLoading = false;
      state.childTradableApproveReject.status =
        CHILD_TRADABLE_APPROVED_REJECT.END;
    },
    onDistributeTopSettingStart: (state, _) => {
      state.distributeTopSettingApproval.isLoading = true;
      state.distributeTopSettingApproval.status =
        DISTRIBUTE_TOP_APPROVAL_SETTING.START;
    },
    onDistributeTopSettingSuccess: (state, action) => {
      state.distributeTopSettingApproval.success = action.payload;
      state.distributeTopSettingApproval.status =
        DISTRIBUTE_TOP_APPROVAL_SETTING.SUCCESS;
    },
    onDistributeTopSettingFailed: (state, action) => {
      state.distributeTopSettingApproval.failed = action.payload;
      state.distributeTopSettingApproval.status =
        DISTRIBUTE_TOP_APPROVAL_SETTING.FAILED;
    },
    onDistributeTopSettingEnd: state => {
      state.distributeTopSettingApproval.isLoading = false;
      state.distributeTopSettingApproval.status =
        DISTRIBUTE_TOP_APPROVAL_SETTING.END;
    },
    onDistributeUserAddStart: (state, _) => {
      state.onDistributeUserAdd.isLoading = true;
      state.onDistributeUserAdd.status = USER_ADD_INTO_DISTRIBUTE.START;
    },
    onDistributeUserAddSuccess: (state, action) => {
      state.onDistributeUserAdd.success = action.payload;
      state.onDistributeUserAdd.status = USER_ADD_INTO_DISTRIBUTE.SUCCESS;
    },
    onDistributeUserAddFailed: (state, action) => {
      state.onDistributeUserAdd.failed = action.payload;
      state.onDistributeUserAdd.status = USER_ADD_INTO_DISTRIBUTE.FAILED;
    },
    onDistributeUserAddEnd: state => {
      state.onDistributeUserAdd.isLoading = false;
      state.onDistributeUserAdd.status = USER_ADD_INTO_DISTRIBUTE.END;
    },
    onDistributeApprovedListStart: (state, _) => {
      state.onDistributeApprovedListData.isLoading = true;
      state.onDistributeApprovedListData.status =
        DISTRIBUTE_APPROVE_USER_LIST.START;
    },
    onDistributeApprovedListSuccess: (state, action) => {
      state.onDistributeApprovedListData.success = action.payload;
      state.onDistributeApprovedListData.status =
        DISTRIBUTE_APPROVE_USER_LIST.SUCCESS;
    },
    onDistributeApprovedListFailed: (state, action) => {
      state.onDistributeApprovedListData.failed = action.payload;
      state.onDistributeApprovedListData.status =
        DISTRIBUTE_APPROVE_USER_LIST.FAILED;
    },
    onDistributeApprovedListEnd: state => {
      state.onDistributeApprovedListData.isLoading = false;
      state.onDistributeApprovedListData.status =
        DISTRIBUTE_APPROVE_USER_LIST.END;
    },
    onUserRemoveFromDistributeApprovedListStart: (state, _) => {
      state.onUserRemoveFromDistribute.isLoading = true;
      state.onUserRemoveFromDistribute.status =
        USER_REMOVE_FROM_DISTRIBUTE_APPROVED_LIST.START;
    },
    onUserRemoveFromDistributeApprovedListSuccess: (state, action) => {
      state.onUserRemoveFromDistribute.success = action.payload;
      state.onUserRemoveFromDistribute.status =
        USER_REMOVE_FROM_DISTRIBUTE_APPROVED_LIST.SUCCESS;
    },
    onUserRemoveFromDistributeApprovedListFailed: (state, action) => {
      state.onUserRemoveFromDistribute.failed = action.payload;
      state.onUserRemoveFromDistribute.status =
        USER_REMOVE_FROM_DISTRIBUTE_APPROVED_LIST.FAILED;
    },
    onUserRemoveFromDistributeApprovedListEnd: state => {
      state.onUserRemoveFromDistribute.isLoading = false;
      state.onUserRemoveFromDistribute.status =
        USER_REMOVE_FROM_DISTRIBUTE_APPROVED_LIST.END;
    },
    onDistributeRequestListStart: (state, _) => {
      state.onDistributeRequestListData.isLoading = true;
      state.onDistributeRequestListData.status = DISTRIBUTE_REQUEST_LIST.START;
    },
    onDistributeRequestListSuccess: (state, action) => {
      state.onDistributeRequestListData.success = action.payload;
      state.onDistributeRequestListData.status =
        DISTRIBUTE_REQUEST_LIST.SUCCESS;
    },
    onDistributeRequestListFailed: (state, action) => {
      state.onDistributeRequestListData.failed = action.payload;
      state.onDistributeRequestListData.status = DISTRIBUTE_REQUEST_LIST.FAILED;
    },
    onDistributeRequestListEnd: state => {
      state.onDistributeRequestListData.isLoading = false;
      state.onDistributeRequestListData.status = DISTRIBUTE_REQUEST_LIST.END;
    },
    onClearDistributeRequestListEnd: state => {
      state.onDistributeRequestListData.isLoading = false;
      state.onDistributeRequestListData.success = null;
      state.onDistributeRequestListData.failed = null;
      state.onDistributeRequestListData.status = DISTRIBUTE_REQUEST_LIST.END;
    },
    onSentDistributeApproveOrRejectStart: (state, _) => {
      state.onSentDistributeApproveOrRejectData.isLoading = true;
      state.onSentDistributeApproveOrRejectData.status =
        DISTRIBUTE_REQUEST_APPROVE_OR_REJECT_API.START;
    },
    onSentDistributeApproveOrRejectSuccess: (state, action) => {
      state.onSentDistributeApproveOrRejectData.success = action.payload;
      state.onSentDistributeApproveOrRejectData.status =
        DISTRIBUTE_REQUEST_APPROVE_OR_REJECT_API.SUCCESS;
    },
    onSentDistributeApproveOrRejectFailed: (state, action) => {
      state.onSentDistributeApproveOrRejectData.failed = action.payload;
      state.onSentDistributeApproveOrRejectData.status =
        DISTRIBUTE_REQUEST_APPROVE_OR_REJECT_API.FAILED;
    },
    onSentDistributeApproveOrRejectEnd: state => {
      state.onSentDistributeApproveOrRejectData.isLoading = false;
      state.onSentDistributeApproveOrRejectData.status =
        DISTRIBUTE_REQUEST_APPROVE_OR_REJECT_API.END;
    },
    onDistributeReceivedRequestListStart: (state, _) => {
      state.onDistributeReceivedRequestData.isLoading = true;
      state.onDistributeReceivedRequestData.status =
        DISTRIBUTE_RECEIVED_REQUEST_LIST.START;
    },
    onDistributeReceivedRequestListSuccess: (state, action) => {
      state.onDistributeReceivedRequestData.success = action.payload;
      state.onDistributeReceivedRequestData.status =
        DISTRIBUTE_RECEIVED_REQUEST_LIST.SUCCESS;
    },
    onDistributeReceivedRequestListFailed: (state, action) => {
      state.onDistributeReceivedRequestData.failed = action.payload;
      state.onDistributeReceivedRequestData.status =
        DISTRIBUTE_RECEIVED_REQUEST_LIST.FAILED;
    },
    onDistributeReceivedRequestListEnd: state => {
      state.onDistributeReceivedRequestData.isLoading = false;
      state.onDistributeReceivedRequestData.status =
        DISTRIBUTE_RECEIVED_REQUEST_LIST.END;
    },
    onReceivedDistributeApproveOrRejectOrReportStart: (state, _) => {
      state.onReceivedDistributeApproveOrRejectOrReportData.isLoading = true;
      state.onReceivedDistributeApproveOrRejectOrReportData.status =
        DISTRIBUTE_RECEIVED_REQUEST_APPROVE_OR_REJECTOR_REPORT_API.START;
    },
    onReceivedDistributeApproveOrRejectOrReportSuccess: (state, action) => {
      state.onReceivedDistributeApproveOrRejectOrReportData.success =
        action.payload;
      state.onReceivedDistributeApproveOrRejectOrReportData.status =
        DISTRIBUTE_RECEIVED_REQUEST_APPROVE_OR_REJECTOR_REPORT_API.SUCCESS;
    },
    onReceivedDistributeApproveOrRejectOrReportFailed: (state, action) => {
      state.onReceivedDistributeApproveOrRejectOrReportData.failed =
        action.payload;
      state.onReceivedDistributeApproveOrRejectOrReportData.status =
        DISTRIBUTE_RECEIVED_REQUEST_APPROVE_OR_REJECTOR_REPORT_API.FAILED;
    },
    onReceivedDistributeApproveOrRejectOrReportEnd: state => {
      state.onReceivedDistributeApproveOrRejectOrReportData.isLoading = false;
      state.onReceivedDistributeApproveOrRejectOrReportData.status =
        DISTRIBUTE_RECEIVED_REQUEST_APPROVE_OR_REJECTOR_REPORT_API.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  onTradeSettingStart,
  onTradeSettingSuccess,
  onTradeSettingFailed,
  onTradeSettingEnd,
  onTradeNotApprovedUserListStart,
  onTradeNotApprovedUserListSuccess,
  onTradeNotApprovedUserListFailed,
  onTradeNotApprovedUserListEnd,
  onTradeUserAddStart,
  onTradeUserAddSuccess,
  onTradeUserAddFailed,
  onTradeUserAddEnd,
  onTradeApprovedListStart,
  onTradeApprovedListSuccess,
  onTradeApprovedListFailed,
  onTradeApprovedListEnd,
  onClearTradeApprovedListEnd,
  onGroupInvitationChildStart,
  onGroupInvitationChildSuccess,
  onGroupInvitationChildFailed,
  onGroupInvitationChildEnd,
  onUserRemoveFromTradeApprovedListStart,
  onUserRemoveFromTradeApprovedListSuccess,
  onUserRemoveFromTradeApprovedListFailed,
  onUserRemoveFromTradeApprovedListEnd,
  onTradeApprovalReceivedListStart,
  onTradeApprovalReceivedListSuccess,
  onTradeApprovalReceivedListFailed,
  onTradeApprovalReceivedListEnd,
  onClearApprovalReceivedListEnd,
  onGroupChildInvitaionApproveStart,
  onGroupChildInvitaionApproveSuccess,
  onGroupChildInvitaionApproveFailed,
  onGroupChildInvitaionApproveEnd,
  onGroupChildInvitaionRejectStart,
  onGroupChildInvitaionRejectSuccess,
  onGroupChildInvitaionRejectFailed,
  onGroupChildInvitaionRejectEnd,
  onChildGroupSettingStart,
  onChildGroupSettingSuccess,
  onChildGroupSettingFailed,
  onChildGroupSettingEnd,
  onchildTradableRequestListStart,
  onchildTradableRequestListSuccess,
  onchildTradableRequestListFailed,
  onchildTradableRequestListEnd,
  onClearChildTradableRequestListEnd,
  onTradeApprovalSentListStart,
  onTradeApprovalSentListSuccess,
  onTradeApprovalSentListFailed,
  onTradeApprovalSentListEnd,
  onClearTradeApprovalSentListEnd,
  onSentTradeApproveOrRejectStart,
  onSentTradeApproveOrRejectSuccess,
  onSentTradeApproveOrRejectFailed,
  onSentTradeApproveOrRejectEnd,
  onChildTradableSettingStart,
  onChildTradableSettingSuccess,
  onChildTradableSettingFailed,
  onChildTradableSettingEnd,
  onChildTradableApproveRejectStart,
  onChildTradableApproveRejectSuccess,
  onChildTradableApproveRejectFailed,
  onChildTradableApproveRejectEnd,
  onDistributeTopSettingStart,
  onDistributeTopSettingSuccess,
  onDistributeTopSettingFailed,
  onDistributeTopSettingEnd,
  onDistributeUserAddStart,
  onDistributeUserAddSuccess,
  onDistributeUserAddFailed,
  onDistributeUserAddEnd,
  onDistributeApprovedListStart,
  onDistributeApprovedListSuccess,
  onDistributeApprovedListFailed,
  onDistributeApprovedListEnd,
  onUserRemoveFromDistributeApprovedListStart,
  onUserRemoveFromDistributeApprovedListSuccess,
  onUserRemoveFromDistributeApprovedListFailed,
  onUserRemoveFromDistributeApprovedListEnd,
  onDistributeRequestListStart,
  onDistributeRequestListSuccess,
  onDistributeRequestListFailed,
  onDistributeRequestListEnd,
  onClearDistributeRequestListEnd,
  onSentDistributeApproveOrRejectStart,
  onSentDistributeApproveOrRejectSuccess,
  onSentDistributeApproveOrRejectFailed,
  onSentDistributeApproveOrRejectEnd,
  onDistributeReceivedRequestListStart,
  onDistributeReceivedRequestListSuccess,
  onDistributeReceivedRequestListFailed,
  onDistributeReceivedRequestListEnd,
  onReceivedDistributeApproveOrRejectOrReportStart,
  onReceivedDistributeApproveOrRejectOrReportSuccess,
  onReceivedDistributeApproveOrRejectOrReportFailed,
  onReceivedDistributeApproveOrRejectOrReportEnd,
} = childProfileSlice.actions;
export default childProfileSlice.reducer;
export const childProfileStore = (state: RootState) => state.childProfileSlice;
