import React from 'react';
import {View, Pressable} from 'react-native';

import {styles} from './editorFooter.style';
import GroupImage from '../../renderSvgs/groupImage';
import GroupTextIcon from '../../renderSvgs/groupText';
import GroupShapes from '../../renderSvgs/groupShapes';
import GroupOrder from '../../renderSvgs/groupOrder';
import {EditorContext} from '../../../contextAPI/editorContext';
import {FixedValue} from '../../../constants';
import {EditorTextContext} from '../../../contextAPI/editorTextContext';

const MainFooter = (): JSX.Element => {
  const {setPrimaryTabSelectedIndex, setShowOrderModal, items} =
    React.useContext(EditorContext);
  const {setShowTextInput, setTextValue} = React.useContext(EditorTextContext);

  const changeBottomOption = React.useCallback((index: number): void => {
    if (index === FixedValue.CONSTANT_VALUE_3) {
      if (items.length > 1) setShowOrderModal(true);
      else return;
    }
    setTextValue('');
    setShowTextInput(true);
    setPrimaryTabSelectedIndex(index);
  }, [items]);

  return (
    <View style={styles.container}>
      <Pressable
        onPress={() => changeBottomOption(FixedValue.CONSTANT_VALUE_0)}
      >
        <GroupImage />
      </Pressable>
      <Pressable
        onPress={() => changeBottomOption(FixedValue.CONSTANT_VALUE_1)}
      >
        <GroupTextIcon />
      </Pressable>
      <Pressable
        onPress={() => changeBottomOption(FixedValue.CONSTANT_VALUE_2)}
      >
        <GroupShapes />
      </Pressable>
      <Pressable
        onPress={() => changeBottomOption(FixedValue.CONSTANT_VALUE_3)}
      >
        <GroupOrder />
      </Pressable>
    </View>
  );
};

export default React.memo(MainFooter);
