import StyleSheet from 'react-native-media-query';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  BASESTYLE,
  COLORS,
  FONT_NAMES,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';

export const NotificationCardStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.row,
      ...BASESTYLE.spaceBetween,
      width: Percentage.PRECENTAGE_100,
      padding: scaleWidth(FixedValue.CONSTANT_VALUE_8),
    },
    imageView: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_38),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_38),
      backgroundColor: _theme.color,
      ...BASESTYLE.inCenter,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      overflow: GlobalStyleValues.HIDDEN,
    },
    approveButton: {
      ...BASESTYLE.inRow,
      top: scaleHeight(FixedValue.CONSTANT_VALUE_4),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_14),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_4),
    },
    primaryButtonStyle: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_28),
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      borderRadius: FixedValue.CONSTANT_VALUE_46,
      marginRight: FixedValue.CONSTANT_VALUE_10,
    },
    primaryButtonStyle1: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_28),
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      borderRadius: FixedValue.CONSTANT_VALUE_46,
    },
    primaryButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    primaryButtonLabelstyle1: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.F54336,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    noButtonBackGroundColor1: {
      backgroundColor: COLORS.FDE4E1,
    },
    noButtonBackGroundColor: {
      backgroundColor: COLORS._4EAF51,
    },
    textView: {
      width: Percentage.PRECENTAGE_80,
    },
    titleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: _theme.color,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_4),
    },
    titleTextan: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_24),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: _theme.color,
    },
    titleText1: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
      color: _theme.color,
      fontFamily: FONT_NAMES.SF_REGULAR,
    },
    titleTextsetting: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_18),
      color: _theme.placeHolderColor,
    },
    titleTextAge: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_10),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: _theme.color,
    },
    profileNameText: {
      textAlign: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      color: _theme.backgroundColor,
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    backgroundColorPlaceholder: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_38),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_38),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      overflow: GlobalStyleValues.HIDDEN,
      backgroundColor: _theme.color,
    },
    invalidButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      color: COLORS.F54336,
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    buyButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
  });
