import React from 'react';
import {Text, View} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import PrimaryButton from '../../components/button/primaryButton';
import {FixedValue, TEXT, BASESTYLE, COLORS} from '../../constants';
import CustomButton from '../../components/button/customButton';
import {style} from './styles';
import ModalContainer from '../../components/layout/modalContainer';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import DeleteImage from '../../components/renderSvgs/deleteBin';
import {CustomDeleteModalProps} from '../../types/reducerTypes/searchTabStoreType';

const CustomDeleteModal = ({
  customDeleteModalData,
}: CustomDeleteModalProps): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = style(ColorTheme);

  return (
    <ModalContainer
      visible={customDeleteModalData.modalVisible}
      onPressOutside={customDeleteModalData?.closeModal}
    >
      <View style={styles.subContainer}>
        <View style={BASESTYLE.inCenter}>
          <View style={styles.deleteBinView}>
            <DeleteImage
              stroke={COLORS._FF3D3D}
              width={scaleWidth(FixedValue.CONSTANT_VALUE_28)}
              height={scaleHeight(FixedValue.CONSTANT_VALUE_40)}
            />
          </View>
          <View style={styles.container}>
            <View style={BASESTYLE.alignCenter}>
              <Text style={styles.wantDelete2}>
                {customDeleteModalData.subHeader}
              </Text>
            </View>
            <View style={styles.marginTop20}>
              <View style={[BASESTYLE.inRow, BASESTYLE.inCenter]}>
                <CustomButton
                  buttonLabel={TEXT.NO}
                  buttonwidth={styles.primaryButtonStyle2}
                  buttonBackGroundColor={styles.noButtonBackGroundColor}
                  labelStyle={styles.noButtonLabelstyle}
                  onPress={customDeleteModalData.onNoButton}
                  isDisabled={false}
                />
                <CustomButton
                  buttonLabel={TEXT.YES}
                  buttonwidth={styles.primaryButtonStyle}
                  labelStyle={styles.yesButtonLabelstyle}
                  onPress={customDeleteModalData.onYesButton}
                  isDisabled={false}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    </ModalContainer>
  );
};

export default CustomDeleteModal;
