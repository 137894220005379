import {StyleSheet} from 'react-native';
import {BASESTYLE, COLORS, FixedValue, FONT_NAMES} from '../../../constants';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {normalizeFont, scaleHeight} from '../../../utils/responsive';

export const styles = (theme: IColorTheme) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.inRow,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    titleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_30),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: theme.color,
      marginRight: scaleHeight(FixedValue.CONSTANT_VALUE_12)
    },
    textStyle: {
      fontSize: FixedValue.CONSTANT_VALUE_14,
      color: COLORS.WHITE,
      fontFamily: FONT_NAMES.SF_MEDIUM
    }
  });
