import React from 'react';
import {FlatList} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';

import ReceviedOfferRender from './receviedOfferRender';
import {BASESTYLE, FixedValue} from '../../constants';
import {OfferModel} from '../../types/reducerTypes/tradeTabTypes';
import {TradeContext} from '../../contextAPI/tradeContext';
import useTradeReceviedOrSentHooks from '../../hooks/componentHooks/useTradeReceviedOrSentHooks';
import EmptyView from './emptyReceivedView';
import {scaleHeight} from '../../utils/responsive';
import {useAppSelector} from '../../hooks/reduxHooks';
import {TradeTabStore} from '../../redux/reducers/tradeTabSlice';

interface OfferProps {
  data: OfferModel[];
}

const ReceviedOfferContent = (props: OfferProps): JSX.Element => {
  const {getOffersListAPI, onReachedEnd} = useTradeReceviedOrSentHooks();
  const {isRefreshing, setIsRefreshing, initialLoader} =
    React.useContext(TradeContext);
  const {receivedOffers} = useAppSelector(TradeTabStore);

  const handleRefresh = React.useCallback(() => {
    setIsRefreshing(true);
    getOffersListAPI('RECEIVED', FixedValue.CONSTANT_VALUE_0);
  }, [isRefreshing]);

  return (
    <FlatList
      keyExtractor={item => `${'Received-Offer'}-${item.id}`}
      data={props.data}
      style={BASESTYLE.flex1}
      refreshControl={
        <RefreshControl refreshing={isRefreshing} onRefresh={handleRefresh} />
      }
      renderItem={({item}) => <ReceviedOfferRender key={item.id} {...item} />}
      onEndReached={() => onReachedEnd('RECEIVED')}
      onEndReachedThreshold={FixedValue.CONSTANT_VALUE_1}
      ListEmptyComponent={() =>
        receivedOffers?.isLoading || initialLoader ? <></> : <EmptyView />
      }
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{
        paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_200),
      }}
    />
  );
};

export default React.memo(ReceviedOfferContent);
