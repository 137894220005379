import React from 'react';
import {Pressable, View, TextInput} from 'react-native';

import {ThemeContext} from '../../contextAPI/themeContext';
import {COLORS, FixedValue, TabBarName} from '../../constants';
import {ITabBarSearchInput} from '../../types/componentTypes/inputTypes';
import {styles} from './Input.style';
import CrossIcon from '../renderSvgs/crossIcon';
import SmallSearchIcon from '../renderSvgs/searchIcon';

const SearchInput = ({
  value,
  onChangeText,
  placeholder,
}: ITabBarSearchInput) => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  return (
    <View style={style.searchView}>
      <TextInput
        style={style.searchInputStyle}
        value={value}
        onChangeText={onChangeText}
        placeholder={placeholder ? placeholder : TabBarName.SEARCH}
        placeholderTextColor={ColorTheme.placeHolderColor}
        autoCorrect={false}
        underlineColorAndroid={COLORS.TRANSPARENT}
      />
      <Pressable style={style.searchIconRight} onPress={() => onChangeText('')}>
        {value?.length <= FixedValue.CONSTANT_VALUE_0 ? (
          <SmallSearchIcon
            width={FixedValue.CONSTANT_VALUE_17}
            height={FixedValue.CONSTANT_VALUE_17}
          />
        ) : (
          <CrossIcon />
        )}
      </Pressable>
    </View>
  );
};

export default React.memo(SearchInput);
