import React from 'react';
import {View, Modal, Pressable, FlatList} from 'react-native';
import ModalHeaderWithCross from '../../components/header/modalHeaderWithCross';

import FontTile from '../../components/RenderItemTile/fontTile';
import {AppConst, TEXT} from '../../constants';
import {EditorTextContext} from '../../contextAPI/editorTextContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import useEditorText from '../../hooks/componentHooks/useEditorTextHooks';
import {changeFontStyle} from './changeFont.style';

const ChangeFontModal = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {showChangeFont, setShowChangeFont} =
    React.useContext(EditorTextContext);
  const {styles, ids} = changeFontStyle(ColorTheme);
  const {selectedFont, onChangeTextFont} = useEditorText();

  return (
    <Modal
      visible={showChangeFont}
      transparent
      onRequestClose={setShowChangeFont}
    >
      <View style={styles.modalStyle} dataSet={{media: ids.modalStyle}}>
        <Pressable onPress={setShowChangeFont} style={styles.outterPart} />
        <View style={styles.innerView}>
          <ModalHeaderWithCross
            onPress={setShowChangeFont}
            headerTitle={TEXT.CHOOSE_FONT}
          />
          <FlatList
            data={AppConst.FONT_LIST}
            keyExtractor={item => `font-key-${item.label}`}
            scrollEnabled={false}
            renderItem={({item}) => (
              <FontTile
                font={item.font}
                label={item.label}
                isSelected={selectedFont === item.font}
                onPress={onChangeTextFont}
              />
            )}
          />
        </View>
      </View>
    </Modal>
  );
};

export default React.memo(ChangeFontModal);
