import {StyleSheet} from 'react-native';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../../constants';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const listWithCheckboxStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    userListStyle: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      width: Percentage.PRECENTAGE_100,
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
    },
    mainViewStyle: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      backgroundColor: theme?.color,
      ...BASESTYLE.inCenter,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_58),
      overflow: GlobalStyleValues.HIDDEN,
    },
    profileNameText: {
      textAlign: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      color: theme?.backgroundColor,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      textTransform: GlobalStyleValues.UPPERCASE,
    },
    middleContainer: {
      ...BASESTYLE.flex1,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    userNameText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      color: theme?.color,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      textTransform: GlobalStyleValues.CAPITALIZE,
      width: Percentage.PRECENTAGE_100,
    },
    rightContainer: {
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    checkboxView: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_25),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_25),
      alignContent: GlobalStyleValues.CENTER,
      justifyContent: GlobalStyleValues.CENTER,
    },
    primaryButtonStyle1: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      borderRadius: FixedValue.CONSTANT_VALUE_46,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_28),
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    primaryButtonLabelstyle1: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      color: COLORS.WHITE,
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
  });
