import React from 'react';

import ScrollableContainer from '../../components/layout/scrollableContainer';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import ParentConsentView from './parentConsentView';

const VerifyEmail: React.FC = () => {
  return (
    <ScrollableContainer>
      <SafeAreaContainer>
        <ParentConsentView />
      </SafeAreaContainer>
    </ScrollableContainer>
  );
};

export default React.memo(VerifyEmail);
