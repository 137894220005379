import * as React from 'react';
import Svg, {SvgProps, Path, Circle} from 'react-native-svg';
const NoChildData = (props: SvgProps) => (
  <Svg
    width={216}
    height={82}
    viewBox="0 0 216 82"
    fill="none"
    //@ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137 82H46C42.134 82 39 78.866 39 75C39 71.134 42.134 68 46 68H7C3.13401 68 0 64.866 0 61C0 57.134 3.13401 54 7 54H47C50.866 54 54 50.866 54 47C54 43.134 50.866 40 47 40H22C18.134 40 15 36.866 15 33C15 29.134 18.134 26 22 26H62C58.134 26 55 22.866 55 19C55 15.134 58.134 12 62 12H119C122.866 12 126 15.134 126 19C126 22.866 122.866 26 119 26H183C186.866 26 190 29.134 190 33C190 36.866 186.866 40 183 40H205C208.866 40 212 43.134 212 47C212 50.866 208.866 54 205 54H186C182.134 54 179 57.134 179 61C179 64.866 182.134 68 186 68H192C195.866 68 199 71.134 199 75C199 78.866 195.866 82 192 82H140C139.485 82 138.983 81.9444 138.5 81.8389C138.017 81.9444 137.515 82 137 82Z"
      fill="#F3F7FF"
    />
    <Circle cx={209} cy={75} r={7} fill="#F3F7FF" />
    <Path
      d="M152.224 75.0003V66.8892C152.224 62.5868 150.515 58.4606 147.472 55.4184C144.43 52.3761 140.304 50.667 136.002 50.667H103.557C99.2548 50.667 95.1286 52.3761 92.0863 55.4184C89.0441 58.4606 87.335 62.5868 87.335 66.8892V75.0003"
      fill="white"
    />
    <Path
      d="M152.224 75.0003V66.8892C152.224 62.5868 150.515 58.4606 147.472 55.4184C144.43 52.3761 140.304 50.667 136.002 50.667H103.557C99.2548 50.667 95.1286 52.3761 92.0863 55.4184C89.0441 58.4606 87.335 62.5868 87.335 66.8892V75.0003"
      stroke="#1F63E6"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M119.778 34.4444C128.737 34.4444 136 27.1815 136 18.2222C136 9.26293 128.737 2 119.778 2C110.818 2 103.555 9.26293 103.555 18.2222C103.555 27.1815 110.818 34.4444 119.778 34.4444Z"
      fill="white"
      stroke="#1F63E6"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M166.558 75.0004V66.8893C166.556 63.295 165.359 59.8034 163.157 56.9626C160.955 54.1219 157.872 52.0929 154.392 51.1943"
      stroke="#1F63E6"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M138.169 2.52734C141.658 3.42079 144.751 5.45019 146.96 8.2956C149.169 11.141 150.367 14.6406 150.367 18.2426C150.367 21.8446 149.169 25.3442 146.96 28.1896C144.751 31.035 141.658 33.0644 138.169 33.9579"
      stroke="#1F63E6"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M73.0001 75.0004V66.8893C73.0028 63.295 74.1991 59.8034 76.4012 56.9626C78.6033 54.1219 81.6866 52.0929 85.1667 51.1943"
      stroke="#1F63E6"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M101.389 2.52734C97.8999 3.42079 94.8071 5.45019 92.5984 8.2956C90.3898 11.141 89.1909 14.6406 89.1909 18.2426C89.1909 21.8446 90.3898 25.3442 92.5984 28.1896C94.8071 31.035 97.8999 33.0644 101.389 33.9579"
      stroke="#1F63E6"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default NoChildData;
