import React from 'react';
import MainHeader from './mainHeader';

interface ITradeHeaderProps {
  onPress?: () => void;
}

const TradeHeader = (props: ITradeHeaderProps): JSX.Element => {
  const {onPress} = props;

  return <MainHeader onPress={onPress} />;
};

export default React.memo(TradeHeader);
