import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
const RedCrossIcon = (props: SvgProps) => (
  <Svg
    width={10}
    height={10}
    viewBox="0 0 10 10"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10Z"
      fill="#F54336"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.8469 3.89178C7.05091 3.68778 7.05091 3.35701 6.8469 3.15301C6.64289 2.949 6.31213 2.949 6.10812 3.15301L5.00009 4.26103L3.89206 3.15301C3.68806 2.949 3.35729 2.949 3.15329 3.15301C2.94928 3.35701 2.94928 3.68778 3.15329 3.89178L4.26131 4.99981L3.15301 6.10812C2.949 6.31213 2.949 6.64289 3.15301 6.8469C3.35701 7.05091 3.68778 7.05091 3.89178 6.8469L5.00009 5.73859L6.1084 6.8469C6.31241 7.05091 6.64317 7.05091 6.84718 6.8469C7.05119 6.64289 7.05119 6.31213 6.84718 6.10812L5.73887 4.99981L6.8469 3.89178Z"
      fill="white"
    />
  </Svg>
);
export default RedCrossIcon;
