import React, {useContext} from 'react';
import {FlatList, Text, View} from 'react-native';
import CustomButton from '../../../components/button/customButton';
import KeyboardAvoid from '../../../components/layout/keyboardAvoid';
import SettingItem from './settingItem';
import useChildProfileHook from '../../../hooks/componentHooks/useChildProfileHook';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {DistributingSettingStyle} from '../../distributingTopRequest/distributingSetting/style';
import {TEXT} from '../../../constants';
import {OnSaveChildSettingType} from '../../../types/componentTypes/userProfileTypes';

const ChildAllPermissionContentScreen = () => {
  const {ColorTheme} = useContext(ThemeContext);
  const {styles} = DistributingSettingStyle(ColorTheme);
  const {
    topBuyingLimitValue,
    textInputRef,
    combinedSettingsWithHeaders,
    handlePress,
    setTopBuyingLimitValue,
    isDisabled,
  } = useChildProfileHook();

  // Function to render the save button with appropriate props
  const onsaveButtonPress = ({
    onSaveChildSetting,
    selectedItem,
    headertile,
    buttonLoader,
  }: {
    onSaveChildSetting: OnSaveChildSettingType;
    selectedItem: string;
    headertile: string;
    buttonLoader: boolean;
  }) => {
    const buttonDisabled =
      headertile === TEXT.TOP_BUYING_APPROVAL && isDisabled;
    return (
      <View style={styles.buttonView}>
        <CustomButton
          isDisabled={buttonDisabled}
          buttonLabel={TEXT.SAVE}
          buttonwidth={styles.buttonStyle}
          labelStyle={styles.buttonLabelstyle}
          onPress={() => {
            onSaveChildSetting(selectedItem, topBuyingLimitValue);
          }}
          isLoading={buttonLoader}
        />
      </View>
    );
  };

  return (
    <View style={styles.settingContainer}>
      <KeyboardAvoid>
        <>
          <View style={styles.selectViewEachSection}>
            <Text style={styles.oneOption}>
              {TEXT.SELECT_ONE_OPTION_IN_EACH}
            </Text>
          </View>
          <FlatList
            data={combinedSettingsWithHeaders}
            keyExtractor={(item, index) => item.title + index}
            ItemSeparatorComponent={() => <View style={styles.seperatorView} />}
            showsVerticalScrollIndicator={false}
            renderItem={({item: section}) => (
              <View>
                <Text style={styles.headerStyle}>{section.title}</Text>
                <FlatList
                  data={section.data}
                  showsVerticalScrollIndicator={false}
                  keyExtractor={(item, index) => item.text + index}
                  renderItem={({item}) => (
                    <SettingItem
                      key={item.text}
                      item={item}
                      selected={section.selectedItem}
                      onPress={handlePress}
                      updatedKey={section.updatedKey}
                      headerTitle={section.title}
                      topBuyingLimitValue={topBuyingLimitValue}
                      setTopBuyingLimitValue={setTopBuyingLimitValue}
                      textInputRef={textInputRef}
                    />
                  )}
                />
                {onsaveButtonPress({
                  onSaveChildSetting: section.onSaveChildSetting,
                  selectedItem: section.selectedItem,
                  headertile: section.title,
                  buttonLoader: section.showButtonLoader,
                })}
              </View>
            )}
          />
        </>
      </KeyboardAvoid>
    </View>
  );
};

export default React.memo(ChildAllPermissionContentScreen);
