import StyleSheet from 'react-native-media-query';
import {IColorTheme} from '../../../../types/contextTypes/themeContext';
import {
  BASESTYLE,
  COLORS,
  FONT_NAMES,
  FixedValue,
  Percentage,
  GlobalStyleValues,
} from '../../../../constants';
import {
  SCREEN_WIDTH,
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../../utils/responsive';

export const EditprofileStyle = (theme?: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : SCREEN_WIDTH,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    headingView: {
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_6),
    },
    headerstyle: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.inRow,
    },
    headertext: {
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.flex1,
    },
    titleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_36),
      color: theme?.color,
    },
    profilePicView: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_19),
    },
    buttonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
    },
    nameLabelView: {
      width: Percentage.PRECENTAGE_48,
      marginTop: FixedValue.CONSTANT_VALUE_33,
    },
    nameView: {
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
    },
    mainViewStyle: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_120),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_120),
      backgroundColor: theme?.color,
      ...BASESTYLE.inCenter,
      borderRadius: FixedValue.CONSTANT_VALUE_16,
      overflow: GlobalStyleValues.HIDDEN,
    },
    initialsTextStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_48),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme?.backgroundColor,
    },
  });
