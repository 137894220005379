import React from 'react';
import {View, Text, Pressable, ActivityIndicator} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {tradeCompStyle} from './receviedOffer.style';
import {COLORS, FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import {TradeContext} from '../../contextAPI/tradeContext';
import {OfferModel} from '../../types/reducerTypes/tradeTabTypes';
import {formatUTCTimeToLocalTime} from '../../utils/helperFunctions';
import {normalizeFont} from '../../utils/responsive';
import useAwsS3 from '../../hooks/libraryHooks/useAwsS3';
import {CHILD_PERMISSION, Percentage, timeformat} from '../../constants/enum';
import {useAppSelector} from '../../hooks/reduxHooks';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import {getTopImage} from '../../utils/imageUtils';

const ReceviedOfferRender = (data: OfferModel): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const styles = tradeCompStyle(ColorTheme);
  const [svgCode, setSvgCode] = React.useState<JSX.Element | undefined>(
    undefined
  );
  const {loadSignedUrl} = useAwsS3();
  const {setOpenReceviedOfferModal, setSelectedItem, setCurrentActiveTab} =
    React.useContext(TradeContext);
  const {userData} = useAppSelector(userProfileStore);
  const UserDetail = userData?.success;
  const offerPermission: string =
    UserDetail?.permissionsStatus?.offerPermission?.permissionStatus ?? '';

  const getSvgUrl = React.useCallback(async (): Promise<void> => {
    const imageUrl = await loadSignedUrl(
      data?.fromUserTops[FixedValue.CONSTANT_VALUE_0].image ?? ''
    );
    if (imageUrl) {
      const topImage = await getTopImage(imageUrl ?? '');
      setSvgCode(topImage);
    }
  }, [data?.fromUserTops, svgCode]);

  React.useEffect(() => {
    getSvgUrl();
  }, [data?.fromUserTops]);

  const callDataintoModal = () => {
    setOpenReceviedOfferModal();
    setCurrentActiveTab(ROUTE_NAME.RECEIVED_OFFER);
    setSelectedItem(data);
  };

  const getOptions = () => {
    switch (data.status) {
      case TEXT.PENDING_IN_CAPS:
        return (
          <View
            style={[
              styles.buttonCommonStyle,
              {
                backgroundColor: COLORS._FEF5CC,
              },
            ]}
          >
            <Text
              style={{
                color: COLORS._A07608,
                fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
              }}
            >
              {TEXT.PENDING_IN_SMALL}
            </Text>
          </View>
        );
      case TEXT.CANCELLED_IN_CAPS:
        return (
          <View
            style={[
              styles.buttonCommonStyle,
              {
                backgroundColor: COLORS.FDE4E1,
              },
            ]}
          >
            <Text
              style={{
                color: COLORS.F54336,
                fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
              }}
            >
              {TEXT.CANCELLED_IN_SMALL}
            </Text>
          </View>
        );
      case TEXT.REJECTED_IN_CAPS:
        return (
          <View
            style={[
              styles.buttonCommonStyle,
              {
                backgroundColor: COLORS.FDE4E1,
              },
            ]}
          >
            <Text
              style={{
                color: COLORS.F54336,
                fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
              }}
            >
              {TEXT.REJECTED_IN_SMALL}
            </Text>
          </View>
        );
      case TEXT.INVALID_IN_CAPS:
        return (
          <View
            style={[
              styles.buttonCommonStyle,
              {
                backgroundColor: COLORS.FDE4E1,
              },
            ]}
          >
            <Text
              style={{
                color: COLORS.F54336,
                fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
              }}
            >
              {TEXT.INVALID}
            </Text>
          </View>
        );
      case TEXT.ACCEPTED_IN_CAPS:
        return (
          <View
            style={[
              styles.buttonCommonStyle,
              {
                backgroundColor: COLORS._E6F3E5,
              },
            ]}
          >
            <Text
              style={{
                color: COLORS._4EAF51,
                fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
              }}
            >
              {TEXT.ACCEPTED_IN_SMALL}
            </Text>
          </View>
        );
      default:
        break;
    }
  };
  return (
    <Pressable onPress={() => callDataintoModal()} style={styles.mainView}>
      <View style={[styles.headingView, {width: Percentage.PRECENTAGE_75}]}>
        {svgCode ? (
          <View style={styles.cardStyle}>{svgCode}</View>
        ) : (
          <View style={styles.imageLoader}>
            <ActivityIndicator size={'small'} color={COLORS.PRIMARY_BLUE} />
          </View>
        )}
        <View style={[styles.textView, {width: Percentage.PRECENTAGE_68}]}>
          <Text
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
            style={styles.owner}
          >
            {data?.fromUser?.firstName} {data?.fromUser?.lastName}
          </Text>
          <Text
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
            style={styles.title}
          >
            {data?.fromUserTops[FixedValue.CONSTANT_VALUE_0].name}
          </Text>
          <Text numberOfLines={FixedValue.CONSTANT_VALUE_1} style={styles.day}>
            {formatUTCTimeToLocalTime(
              data?.creationDate ?? '',
              timeformat.MMMDDYY
            )}
          </Text>
        </View>
      </View>
      <View
        style={[
          styles.statusView,
          offerPermission === CHILD_PERMISSION.NOT_ALLOWED && styles.justifyEnd,
          {width: Percentage.PRECENTAGE_25},
        ]}
      >
        {getOptions()}
      </View>
    </Pressable>
  );
};

export default React.memo(ReceviedOfferRender);
