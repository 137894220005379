// Function to validate and set the top buying limit value
export const handleTopBuyingLimitChangeRegex = (value: string) => {
  // Allow only numbers and a dot, up to 2 decimal places
  const regex = /^\d*\.?\d{0,2}$/;
  // Check if the value matches the regex and its length is <= 10
  if (regex.test(value) && value.length <= 10) {
    // Ensure the 10th character is not a dot
    if (value.length === 10 && value.charAt(9) === '.') {
      return false;
    }
    return true;
  }
  return false;
};
