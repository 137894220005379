import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const style = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    headerView: {
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_30),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    optionView: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.selfCenter,
      backgroundColor: COLORS._FEFAE6,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_28),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_213),
      borderRadius: FixedValue.CONSTANT_VALUE_60,
    },
    selectTextStyle: {
      textAlign: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_REGULAR,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      color: COLORS.BLACK,
    },
    textStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: _theme?.color,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_22),
    },
    guardianAllow: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: _theme?.color,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    listView: {
      paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_30),
      paddingRight: scaleWidth(FixedValue.CONSTANT_VALUE_30),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_36),
      ...BASESTYLE.row,
    },
    listView1: {
      paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_30),
      paddingRight: scaleWidth(FixedValue.CONSTANT_VALUE_30),
      ...BASESTYLE.row,
    },
    buttonStyle: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_179),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
    },
    buttonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
    buttonView: {
      ...BASESTYLE.alignCenter,
      position: 'absolute',
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_50),
      left: scaleWidth(FixedValue.CONSTANT_VALUE_0),
      right: scaleWidth(FixedValue.CONSTANT_VALUE_0),
    },
  });
