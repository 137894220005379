/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {Pressable} from 'react-native';

import LeftArrow from '../renderSvgs/leftArrow';
import {goBack} from '../../services/navigationService';
import {ThemeContext} from '../../contextAPI/themeContext';

type IBackButton = {
  onPress?: () => void;
  headerColor?: string;
};

const BackButton = (props: IBackButton): JSX.Element => {
  const {onPress, headerColor} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  return (
    <Pressable onPress={onPress ?? goBack}>
      <LeftArrow stroke={headerColor ?? ColorTheme.color} />
    </Pressable>
  );
};

export default React.memo(BackButton);
