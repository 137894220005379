import React from 'react';
import {View, Text, StyleSheet, Pressable, TextStyle} from 'react-native';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IModalOptionsButton} from '../../types/componentTypes/buttonType';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: GlobalStyleValues.COLUMN,
    width: Percentage.PRECENTAGE_100,
  },
  cancelLabel: {
    marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_13),
    fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
    fontFamily: FONT_NAMES.SF_REGULAR,
  },
  buttonTextColor: {
    color: COLORS.PRIMARY_BLUE,
  },
  topSeperatorView: {
    height: FixedValue.CONSTANT_VALUE_1,
    borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
    backgroundColor: COLORS.B6BFC9,
  },
  cancelTextView: {
    ...BASESTYLE.inRow,
    ...BASESTYLE.inCenter,
    paddingVertical: scaleHeight(FixedValue.CONSTANT_VALUE_15),
  },
  doneLabel: {
    marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_13),
    fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
    fontFamily: FONT_NAMES.SF_REGULAR,
    color: COLORS._4EAF51,
  },
});

const CancelButton = ({
  label,
  onPress,
  buttonTextColor,
  disable,
  hideSeperator
}: IModalOptionsButton) => {
  const buttonColor: TextStyle = buttonTextColor
    ? buttonTextColor
    : styles.buttonTextColor;
  return (
    <Pressable style={styles.mainContainer} onPress={onPress} disabled={disable}>
      {!hideSeperator && <View style={styles.topSeperatorView}></View>}
      <View style={styles.cancelTextView}>
        <Text style={[styles.cancelLabel, buttonColor]}>{label}</Text>
      </View>
    </Pressable>
  );
};
export default CancelButton;
