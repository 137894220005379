import React from 'react';
import {View} from 'react-native';
import ModalContainer from '../../components/layout/modalContainer';
import {ThemeContext} from '../../contextAPI/themeContext';
import {style} from './style';
import CutomModalContent from './content';
import {CustomModalProps} from '../../types/reducerTypes/groupMangementTypes';

const CustomModal = (props: CustomModalProps): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = style(ColorTheme);
  const {modalVisible, onClose, actionType, 
    yesOptionClicked, description, confirmButtonTitle, rejectButtonTitle} =
    props;
  return (
    <ModalContainer visible={modalVisible} onPressOutside={onClose}>
      <View style={styles.innerContainer}>
        <CutomModalContent
          description={description}
          actionType={actionType}
          onClose={onClose}
          yesOptionClicked={yesOptionClicked}
          showIcon={false}
          confirmButtonTitle={confirmButtonTitle}
          rejectButtonTitle={rejectButtonTitle}
        />
      </View>
    </ModalContainer>
  );
};

export default CustomModal;
