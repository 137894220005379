import React from 'react';
import { Image } from 'react-native';
import { MessageType } from 'react-native-flash-message';
import { FixedValue, IMAGES } from '../../constants';
import { IconType } from '../../constants/enum';

const topBanner = (icon: MessageType): JSX.Element => (
  <Image
    source={icon === IconType.FAILED ? IMAGES.ERROR_ICON : IMAGES.WHITE_CHECK}
    style={{
      marginRight: FixedValue.CONSTANT_VALUE_8,
      width: FixedValue.CONSTANT_VALUE_24,
      height: FixedValue.CONSTANT_VALUE_24,
    }}
  />
);

export default topBanner;
