import React from 'react';
import {Text, View} from 'react-native';
import {FixedValue} from '../../../constants';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {ITopBarProps} from '../../../types/componentTypes/inputTypes';
import BackButton from '../../button/backButton';
import {styles} from './styles';

const TopBarWithRightButton = (props: ITopBarProps): JSX.Element => {
  const {Icon, headerLabel, headerColor} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);

  return (
    <View style={style.container}>
      <>
        <BackButton onPress={props.onPress} headerColor={headerColor} />
        <Text
          numberOfLines={FixedValue.CONSTANT_VALUE_1}
          style={[style.titleText, {color: headerColor}]}
        >
          {headerLabel}
        </Text>
        {Icon}
      </>
    </View>
  );
};

export default React.memo(TopBarWithRightButton);
