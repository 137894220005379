import React from 'react';
import {useFormik} from 'formik';
import {ERROR_MESSAGES, FixedValue} from '../../constants';
import {useAppDispatch} from '../reduxHooks';
import {ApllyReedemCodeSchema} from '../../utils/formikSchema';
import {verifyReedemCodeStart} from '../../redux/reducers/homeSlice';

export type ReedemCodeField = {reedemCode: string};

const useReedemCodeHooks = () => {
  const dispatch = useAppDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
    isValid,
  } = useFormik<ReedemCodeField>({
    validationSchema: ApllyReedemCodeSchema,
    initialErrors: {
      reedemCode: ERROR_MESSAGES.REQUIRED_COUPON_CODE,
    },
    initialTouched: {
      reedemCode: false,
    },
    initialValues: {
      reedemCode: '',
    },
    onSubmit: val => {
      verifyReedemCode(val?.reedemCode);
    },
  });
  const CouponCodeError: string =
    touched.reedemCode && errors.reedemCode ? errors.reedemCode : '';

  React.useEffect(() => {
    // Disable the button if the form is not valid
    setIsButtonDisabled(!isValid);
  }, [isValid]);

  React.useEffect(() => {
    if (values.reedemCode.trim().length > FixedValue.CONSTANT_VALUE_3) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [values.reedemCode]);

  const verifyReedemCode = React.useCallback(
    (redeemCode: string): void => {
      dispatch(verifyReedemCodeStart({redeemCode}));
    },
    [dispatch]
  );

  return {
    handleChange,
    handleSubmit,
    handleBlur,
    isValid,
    values,
    setFieldValue,
    CouponCodeError,
    isButtonDisabled,
  };
};

export default useReedemCodeHooks;
