import * as React from 'react';
import Svg, {SvgProps, G, Path, Defs, ClipPath, Rect} from 'react-native-svg';
const Failed = (props: SvgProps) => (
  <Svg
    width={80}
    height={80}
    viewBox="0 0 80 80"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M15.3375 11.7947C16.3994 11.3097 17.4192 10.7864 18.3076 10.14C21.5366 7.79005 23.7571 3.20767 27.6364 1.94812C31.3793 0.732824 35.8519 3.09516 40 3.09516C44.1481 3.09516 48.6207 0.732824 52.3636 1.94812C56.2429 3.20767 58.4634 7.79005 61.6924 10.14C64.9537 12.5134 69.9891 13.2255 72.3625 16.4869C74.7124 19.7158 73.849 24.7132 75.1087 28.5925C76.324 32.3354 80 35.8522 80 40.0003C80 44.1484 76.324 47.6651 75.1087 51.4081C73.8492 55.2873 74.7126 60.2847 72.3625 63.5137C69.9891 66.775 64.9536 67.4871 61.6924 69.8605C58.4634 72.2105 56.2429 76.7929 52.3636 78.0524C48.6207 79.2677 44.1481 76.9054 40 76.9054C35.8519 76.9054 31.3793 79.2677 27.6364 78.0524C23.7571 76.7929 21.5366 72.2105 18.3076 69.8605C15.0463 67.4871 10.0109 66.775 7.63751 63.5137C5.28757 60.2847 6.15099 55.2873 4.89143 51.4081C3.67598 47.6651 0 44.1484 0 40.0003C0 35.8522 3.67598 32.3354 4.89127 28.5925C6.15082 24.7132 5.28757 19.7158 7.63751 16.4869C8.38632 15.458 9.40008 14.6829 10.5394 14.0292"
      fill="#F54336"
    />
    <Path
      d="M72.4254 39.9998C72.4254 43.3624 69.4454 46.2134 68.4603 49.2476C67.4392 52.3924 68.1392 56.4434 66.2342 59.061C64.3101 61.7047 60.2282 62.282 57.5845 64.2061C54.9669 66.1112 53.167 69.8258 50.0222 70.8469C46.988 71.8322 43.3622 69.9172 39.9996 69.9172C36.6371 69.9172 33.0113 71.8322 29.9771 70.8469C26.8323 69.8259 25.0324 66.1112 22.4148 64.2061C19.7711 62.2821 15.6891 61.7049 13.765 59.061C11.8599 56.4434 12.5598 52.3924 11.5388 49.2478C10.5535 46.2135 7.57373 43.3627 7.57373 39.9999C7.57373 36.6374 10.5537 33.7863 11.5388 30.7521C12.5598 27.6074 11.8599 23.5563 13.765 20.9389C15.689 18.2952 19.7709 17.7179 22.4148 15.7938C25.0324 13.8887 26.8323 10.1741 29.9771 9.15295C33.0113 8.16768 36.6371 10.0827 39.9996 10.0827C43.3622 10.0827 46.988 8.16768 50.0222 9.15279C53.167 10.1738 54.9669 13.8886 57.5845 15.7936C60.2282 17.7176 64.3103 18.2948 66.2343 20.9387C68.1394 23.5563 67.4395 27.6074 68.4605 30.752C69.4454 33.7862 72.4254 36.6372 72.4254 39.9998Z"
      fill={props.fill ?? "white"}
    />
    <Path
      d="M40 51.333H40.0283"
      stroke="#F54336"
      strokeWidth={8.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M40 28.667V40.0003"
      stroke="#F54336"
      strokeWidth={8.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M75.1087 28.5921C73.8492 24.7128 74.7126 19.7155 72.3625 16.4865C69.989 13.2252 64.9535 12.5131 61.6924 10.1396C58.4634 7.78969 56.2429 3.20732 52.3636 1.94776C50.7789 1.43331 49.0633 1.56064 47.297 1.87806C47.3764 1.90043 47.4559 1.92217 47.5346 1.94776C51.4139 3.20732 53.6344 7.78969 56.8634 10.1396C60.1247 12.5131 65.1601 13.2252 67.5335 16.4865C69.8834 19.7155 69.02 24.7128 70.2797 28.5921C71.495 32.3349 75.171 35.8518 75.171 39.9999C75.171 44.148 71.495 47.6648 70.2797 51.4077C69.0202 55.287 69.8836 60.2843 67.5335 63.5133C65.1601 66.7746 60.1246 67.4867 56.8634 69.8602C53.6344 72.2101 51.4139 76.7925 47.5346 78.0521C47.4559 78.0777 47.3764 78.0994 47.2969 78.1219C49.0632 78.4392 50.7789 78.5667 52.3636 78.0521C56.2429 76.7925 58.4634 72.2101 61.6924 69.8602C64.9537 67.4867 69.989 66.7746 72.3625 63.5133C74.7124 60.2843 73.8492 55.287 75.1087 51.4077C76.324 47.6648 80 44.148 80 39.9999C80 35.8518 76.324 32.3349 75.1087 28.5921Z"
      fill="#F54336"
    />
    <Path
      d="M68.461 30.7527C67.44 27.6079 68.1399 23.5569 66.2348 20.9394C64.3108 18.2957 60.2289 17.7184 57.585 15.7945C54.9674 13.8895 53.1675 10.1747 50.0227 9.15369C48.5674 8.6811 46.9759 8.87619 45.3428 9.20665C48.3953 10.2951 50.1803 13.9199 52.756 15.7945C55.3997 17.7185 59.4818 18.2957 61.4059 20.9394C63.3109 23.5571 62.611 27.6081 63.632 30.7527C64.6173 33.7869 67.5971 36.6378 67.5971 40.0005C67.5971 43.3631 64.6171 46.2141 63.632 49.2483C62.6108 52.3931 63.3109 56.4442 61.4059 59.0616C59.4818 61.7053 55.3999 62.2827 52.7562 64.2067C50.1806 66.0812 48.3955 69.706 45.3429 70.7944C46.9761 71.1249 48.5676 71.32 50.0228 70.8475C53.1676 69.8264 54.9676 66.1118 57.5852 64.2067C60.2289 62.2827 64.3108 61.7055 66.2348 59.0616C68.1399 56.444 67.44 52.393 68.461 49.2483C69.4461 46.2141 72.4261 43.3633 72.4261 40.0005C72.4261 36.6378 69.4461 33.7869 68.461 30.7527Z"
      fill={props.fill ?? "#F1F2F4"}
    />
  </Svg>
);
export default Failed;
