import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {TrackingListStateType} from '../../types/reducerTypes/authStoreTypes';
import {ResetAllState} from './logoutslice';

const {StoreConstants} = AppConst;
const {RESET_STORE, TRACKING_LIST} = ActionType;

const initialState: TrackingListStateType = {
  trackingList: {
    isLoading: false,
    failed: null,
    data: {
      data: {
        results: null,
      },
      pageSize: 0,
      pageNumber: 0,
      totalPages: 0,
    },
    status: TRACKING_LIST.END,
    success: false,
  },
};

const TrackingListSlice = createSlice({
  initialState,
  name: StoreConstants.TRACKING_LIST,
  reducers: {
    ontrackingListStart: (state, _) => {
      state.trackingList.isLoading = true;
      state.trackingList.status = TRACKING_LIST.START;
      state.trackingList.success = false;
    },
    ontrackingListSuccess: (state, action) => {
      state.trackingList.data = action.payload ?? [];
      state.trackingList.status = TRACKING_LIST.SUCCESS;
      state.trackingList.success = true;
    },
    ontrackingListFailed: (state, action) => {
      state.trackingList.failed = action.payload;
      state.trackingList.status = TRACKING_LIST.FAILED;
      state.trackingList.success = false;
    },
    ontrackingListEnd: state => {
      state.trackingList.isLoading = false;
      state.trackingList.status = TRACKING_LIST.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  ontrackingListStart,
  ontrackingListSuccess,
  ontrackingListFailed,
  ontrackingListEnd,
} = TrackingListSlice.actions;
export default TrackingListSlice.reducer;
export const trackingListState = (state: RootState) => state.trackingList;
