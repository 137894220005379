import React from 'react';
import {View} from 'react-native';
import {ThemeContext} from '../../../contextAPI/themeContext';
import SafeAreaContainer from '../../../components/layout/safeAreaContainer';
import {ProfileApprovelStyle} from './profileApprovel.style';
import ProfileApprovelContent from './profileApprovelContent';
import {TEXT} from '../../../constants';
import ChildProfileApprovedModal from '../../../modals/childProfileApprovedModal';
import {HomeContext} from '../../../contextAPI/homeContext';
import TitleWithButton from '../../../components/header/titleWithButton';

const ProfileApprovel = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = ProfileApprovelStyle(ColorTheme);
  const {openChildProfileApproveModal} = React.useContext(HomeContext);

  return (
    <SafeAreaContainer>
      <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
        <TitleWithButton title={TEXT.PROFILE_REQ} />
        <ProfileApprovelContent />
        {openChildProfileApproveModal && <ChildProfileApprovedModal />}
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(ProfileApprovel);
