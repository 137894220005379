import React, {useState} from 'react';
import {useFormik} from 'formik';

import {ERROR_MESSAGES, FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import {SignUpScheme} from '../../utils/formikSchema';
import {navigate} from '../../services/navigationService';
import {useAppDispatch, useAppSelector} from '../reduxHooks';
import {SignUpContext} from '../../contextAPI/signUpContext';
import {
  CHECK_USERNAME,
  PARENT_USERNAME,
  SIGN_UP,
  VERIFY_OTP,
} from '../../constants/actionTypes';
import {
  authState,
  onParentUsernameStart,
  onRegisterStart,
  onSignUpStart,
  onUsernameStart,
} from '../../redux/reducers/authSlice';
import {
  calculateAgeFromDate,
  isAllFieldValids,
  isAllFieldValids2,
  _ToastHandler,
} from '../../utils/helperFunctions';
import {SignUpFieldsKeys} from '../../types/componentTypes/signUpType';
import {IRegisterRequest} from '../../types/reducerTypes/authStoreTypes';
import {verifyOtpState} from '../../redux/reducers/verifyOtp';
import {Platform} from 'react-native';
import moment from 'moment-timezone';
import {timeformat} from '../../constants/enum';
import {DOB_FORMAT} from '../../constants/regex';

export type SignUpFields = {
  Password: string;
  Username: string;
  Firstname: string;
  Lastname: string;
  DateOfBirth: string;
  Email: string;
  MobileNumber: string;
  ParentUsername: string;
  ParentEmail: string;
  ParentUsername2: string;
  ParentEmail2: string;
};
export type UsernameFields = {username: string};
const useSignUpHooks = () => {
  const [isFirstParentUserName, setIsFirstParentUserName] =
    useState<boolean>(true);
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    resetForm,
    setFieldError,
  } = useFormik<SignUpFields>({
    validationSchema: SignUpScheme,
    initialErrors: {
      Username: ERROR_MESSAGES.REQUIRED_USERNAME,
      Password: ERROR_MESSAGES.REQUIRED_PASSWORD,
      Firstname: ERROR_MESSAGES.REQUIRED_THIS,
      Lastname: ERROR_MESSAGES.REQUIRED_THIS,
      DateOfBirth: ERROR_MESSAGES.REQUIRED_THIS,
      Email: ERROR_MESSAGES.REQUIRED_THIS,
      MobileNumber: ERROR_MESSAGES.REQUIRED_THIS,
      ParentUsername: ERROR_MESSAGES.REQUIRED_THIS,
      ParentEmail: ERROR_MESSAGES.REQUIRED_THIS,
      ParentUsername2: ERROR_MESSAGES.REQUIRED_THIS,
      ParentEmail2: ERROR_MESSAGES.REQUIRED_THIS,
    },
    initialTouched: {
      Password: false,
      Username: false,
      Firstname: false,
      Lastname: false,
      DateOfBirth: false,
      Email: false,
      MobileNumber: false,
      ParentUsername: false,
      ParentEmail: false,
      ParentUsername2: false,
      ParentEmail2: false,
    },
    initialValues: {
      Password: '',
      Username: '',
      Firstname: '',
      Lastname: '',
      DateOfBirth: '',
      Email: '',
      MobileNumber: '',
      ParentUsername: '',
      ParentEmail: '',
      ParentUsername2: '',
      ParentEmail2: '',
    },
    onSubmit: _val => {},
  });
  const dispatch = useAppDispatch();
  const {userName, parentUsername, userSignUp} = useAppSelector(authState);
  const {verifyOtp} = useAppSelector(verifyOtpState);
  const {
    userAge,
    agreeTNC,
    dateString,
    setExtraError,
    extraError,
    havePersonalEmail,
    haveParentsUsername,
    selectedCountry,
    setIsBtnLoading,
    setUserAge,
    clearSignupState,
    agreeReceiveSms,
  } = React.useContext(SignUpContext);

  const submitForm = React.useCallback((): void => {
    setIsBtnLoading(true);
    const payload: IRegisterRequest = {
      firstName: values?.Firstname.trim(),
      lastName: values?.Lastname.trim(),
      username: values?.Username.trim(),
      email:
        (havePersonalEmail &&
          userAge <= FixedValue.CONSTANT_VALUE_12 &&
          values?.Email) ||
        (userAge > FixedValue.CONSTANT_VALUE_12 && values?.Email)
          ? values?.Email.trim()
          : values?.ParentEmail.trim(),
      dob:
        Platform.OS === 'web'
          ? moment(values?.DateOfBirth).format(timeformat.YYYY_MM_DD)
          : values?.DateOfBirth,
      countryCode: selectedCountry.dial_code || '+1',
      mobile: values?.MobileNumber,
      parentEmail:
        userAge <= FixedValue.CONSTANT_VALUE_12
          ? values?.ParentEmail.trim()
          : '',
      parentUsername: haveParentsUsername ? values?.ParentUsername.trim() : '',
    };
    if (values.ParentEmail2.trim()) {
      payload.guardianEmail = values.ParentEmail2.trim();
    }
    if (values.ParentUsername2.trim()) {
      payload.guardianUsername = values.ParentUsername2.trim();
    }
    dispatch(onRegisterStart(payload));
    dispatch(
      onSignUpStart({values, selectedCountry, userAge, havePersonalEmail})
    );
  }, [values, selectedCountry, havePersonalEmail, haveParentsUsername]);

  const moveToLogin = React.useCallback((): void => {
    navigate(ROUTE_NAME.LOGIN_SCREEN);
    resetForm();
    clearSignupState();
  }, []);

  const checkAllFieldIsValid = React.useMemo((): boolean => {
    if (userAge > FixedValue.CONSTANT_VALUE_12) {
      return (
        isAllFieldValids(errors) &&
        agreeTNC &&
        // agreeReceiveSms &&
        extraError.UsernameError.length === FixedValue.CONSTANT_VALUE_0
      );
    }
    return (
      isAllFieldValids2(errors, havePersonalEmail, haveParentsUsername) &&
      agreeTNC &&
      // agreeReceiveSms &&
      extraError.UsernameError.length === FixedValue.CONSTANT_VALUE_0 &&
      extraError.ParentUsernameError.length === FixedValue.CONSTANT_VALUE_0
    );
  }, [
    userAge,
    errors,
    agreeTNC,
    havePersonalEmail,
    haveParentsUsername,
    extraError,
    agreeReceiveSms,
  ]);

  React.useEffect((): void => {
    if (values.Username.length > FixedValue.CONSTANT_VALUE_1) {
      setExtraError('UsernameError', '');
      dispatch(onUsernameStart({username: values.Username}));
    }
  }, [values.Username]);

  React.useEffect((): void => {
    if (
      values.ParentUsername.length > FixedValue.CONSTANT_VALUE_1 &&
      haveParentsUsername
    ) {
      setExtraError('ParentUsernameError', '');
      dispatch(onParentUsernameStart({username: values.ParentUsername}));
    }
  }, [values.ParentUsername, haveParentsUsername]);

  React.useEffect((): void => {
    switch (userName.status) {
      case CHECK_USERNAME.SUCCESS:
        if (userName?.success?.available) {
          setExtraError('UsernameError', '');
        } else {
          setExtraError('UsernameError', ERROR_MESSAGES.USERNAME_ALREADY_EXIST);
        }
        break;
      case CHECK_USERNAME.FAILED:
        setExtraError('UsernameError', ERROR_MESSAGES.USERNAME_ALREADY_EXIST);
        break;
      default:
        break;
    }
  }, [userName.status]);

  React.useEffect((): void => {
    switch (parentUsername.status) {
      case PARENT_USERNAME.SUCCESS:
        if (isFirstParentUserName) {
          setExtraError('ParentUsernameError', '');
          handleChange(TEXT.PARENT_EMAIL)(
            parentUsername.success?.data.email ?? ''
          );
        } else {
          setFieldError(TEXT.PARENT_EMAIL_2, '');
          handleChange(TEXT.PARENT_EMAIL_2)(
            parentUsername.success?.data.email ?? ''
          );
        }
        break;
      case PARENT_USERNAME.FAILED:
        if (isFirstParentUserName) {
          setExtraError(
            'ParentUsernameError',
            ERROR_MESSAGES.PARENTS_USERNAME_NOT_FOUND
          );
        } else {
          setFieldError(
            TEXT.PARENT_USERNAME_2,
            ERROR_MESSAGES.PARENTS_USERNAME_NOT_FOUND
          );
        }
        break;
      default:
        break;
    }
  }, [parentUsername.status]);

  React.useEffect((): void => {
    switch (userSignUp.status) {
      case SIGN_UP.SUCCESS:
        setIsBtnLoading(false);
        navigate(ROUTE_NAME.VERIFY_EMAIL, {
          allFeilds: values,
          emailId:
            (userAge > FixedValue.CONSTANT_VALUE_12 && values?.Email) ||
            (havePersonalEmail &&
              userAge <= FixedValue.CONSTANT_VALUE_12 &&
              values?.Email)
              ? values?.Email
              : values?.ParentEmail,
          navigateFrom: ROUTE_NAME.SIGN_UP,
          userCredentials: {
            Password: values.Password,
            Username: values.Username,
          },
        });
        break;
      case SIGN_UP.FAILED:
        setIsBtnLoading(false);
        _ToastHandler(userSignUp.failed ?? '', false);
        break;
      default:
        break;
    }
  }, [userSignUp.status]);

  React.useEffect((): void => {
    if (verifyOtp.status === VERIFY_OTP.SUCCESS) {
      setIsBtnLoading(false);
      resetForm();
      clearSignupState();
    }
  }, [verifyOtp.status]);

  React.useEffect((): void => {
    if (dateString.length > FixedValue.CONSTANT_VALUE_1) {
      handleChange(TEXT.DOB_)(dateString);
    }
  }, [dateString]);

  React.useEffect((): void => {
    if (
      values.DateOfBirth &&
      values.DateOfBirth.match(DOB_FORMAT) &&
      moment(values.DateOfBirth).isValid() &&
      moment().diff(moment(values.DateOfBirth)) >=
        FixedValue.CONSTANT_VALUE_0 &&
      Platform.OS === 'web'
    ) {
      let dateObj = moment(values.DateOfBirth, '').toISOString();
      const getAge: number = calculateAgeFromDate(dateObj);
      setUserAge(getAge);
    }
  }, [values.DateOfBirth]);

  const getFieldError = React.useCallback(
    (key: SignUpFieldsKeys): string => {
      if (touched[key] && errors[key]) {
        return errors[key] ?? '';
      } else {
        return '';
      }
    },
    [touched, errors]
  );
  const firstNameError: string = getFieldError('Firstname');
  const lastNameError: string = getFieldError('Lastname');
  const usernameError: string =
    extraError.UsernameError.length > FixedValue.CONSTANT_VALUE_0
      ? extraError.UsernameError
      : getFieldError('Username');
  const passwordError: string = getFieldError('Password');
  const emailError: string = getFieldError('Email');
  const phoneError: string = getFieldError('MobileNumber');
  const dobError: string = getFieldError('DateOfBirth');
  const parentUserNameErr: string =
    extraError.ParentUsernameError.length > FixedValue.CONSTANT_VALUE_0
      ? extraError.ParentUsernameError
      : getFieldError('ParentUsername');
  const parentEmailErr: string = getFieldError('ParentEmail');
  return {
    handleChange,
    submitForm,
    handleBlur,
    values,
    moveToLogin,
    firstNameError,
    lastNameError,
    usernameError,
    passwordError,
    emailError,
    phoneError,
    dobError,
    parentEmailErr,
    parentUserNameErr,
    checkAllFieldIsValid,
    errors,
    setIsFirstParentUserName,
    isFirstParentUserName,
    setFieldError
  };
};
export default useSignUpHooks;
