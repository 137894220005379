import React from 'react';
import {View, Text, Pressable} from 'react-native';
import {style} from './style';
import {
  formatUTCTimeToLocalTime,
  getGroupInitials,
} from '../../utils/helperFunctions';
import {FixedValue, timeformat} from '../../constants/enum';
import TickWithCircle from '../../components/renderSvgs/tickWithCircle';
import CrossWithCircle from '../../components/renderSvgs/crossWithCircle';
import {ThemeContext} from '../../contextAPI/themeContext';
import ProfilePics from '../../components/smallDesigns/profilePics';
import {GroupInvitationChildResult} from '../../types/reducerTypes/childProfileTypes';

interface IChildGroupTile {
  index: number;
  item: GroupInvitationChildResult;
  onPressTick: () => void;
  onPressCross: () => void;
}

const ChildGroupTile = (props: IChildGroupTile): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = style(ColorTheme);
  const {item, index, onPressTick, onPressCross} = props;

  return (
    <View
      style={[
        styles.mainView,
        index !== FixedValue.CONSTANT_VALUE_0 && styles.marginTop,
      ]}
    >
      <View style={styles.imageView}>
        <ProfilePics
          image={item?.group.groupImage ?? ''}
          text={getGroupInitials(item?.group?.groupName)}
          initialsTextViewStyle={styles.backgroundColorPlaceholder}
          mainViewStyle={styles.imageStyle}
          initialsTextStyle={styles.profileNameText}
        />
        <View style={styles.textView}>
          <Text
            style={styles.listname}
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
          >
            {item?.user?.firstName + ' ' + item?.user?.lastName}
          </Text>
          <Text
            style={styles.groupTitle}
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
          >
            {item?.group?.groupName}
          </Text>
          <Text
            style={styles.groupDay}
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
          >
            {formatUTCTimeToLocalTime(item?.createdAt, timeformat.DD_MMM_YYYY)}
          </Text>
        </View>
      </View>
      <View style={styles.circle}>
        <Pressable onPress={onPressTick}>
          <TickWithCircle />
        </Pressable>
        <Pressable onPress={onPressCross}>
          <CrossWithCircle />
        </Pressable>
      </View>
    </View>
  );
};

export default React.memo(ChildGroupTile);
