/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {useAppDispatch, useAppSelector} from '../reduxHooks';
import {_ToastHandler} from '../../utils/helperFunctions';
import {FixedValue} from '../../constants';
import {
  HomeStore,
  onDistributeTopStart,
  onGroupListSearchStart,
  onGroupUserListSearchStart,
  onUserListSearchStart,
} from '../../redux/reducers/homeSlice';
import {HomeContext} from '../../contextAPI/homeContext';
import {CustomUserListSearchModalListData} from '../../types/reducerTypes/searchTabStoreType';
import useFavouriteHooks from './useFavouriteHooks';
import {FavoriteContext} from '../../contextAPI/favoriteContext';
import {onClearFavoriteUserListEnd} from '../../redux/reducers/userProfileSlice';

const useHomeHooks = () => {
  const dispatch = useAppDispatch();
  const {userListSearch, groupListSearch, groupUserListSearch} =
    useAppSelector(HomeStore);
  const {
    searchUserListText,
    setSearchUserListText,
    selectedTopData,
    showDistributiveModal,
    setShowDistributiveModal,
    setSelectedUsersListSearch,
    selectedUsersListSearch,
    setSearchGroupListText,
    searchGroupListText,
    selectedGroupDetail,
    setSelectedGroupDetail,
    isCloseGroupUser,
    setSearchFavouriteUserListText,
    searchFavouriteUserListText,
  } = React.useContext(HomeContext);
  const {setSelectedUsersFavouriteListSearch, setFavoriteListData} =
    React.useContext(FavoriteContext);
  const {getfavoriteUserList} = useFavouriteHooks();

  const callUserListSearchAPI = React.useCallback(
    (keyword: string, page: number): void => {
      const params = {
        keyword,
        page,
        size: FixedValue.CONSTANT_VALUE_15,
      };
      dispatch(onUserListSearchStart(params));
    },
    [dispatch]
  );

  const onReachedEnd = React.useCallback((): void => {
    if (
      userListSearch.success != null &&
      userListSearch.success.pageNumber < userListSearch.success.totalPages
    ) {
      if (!isCloseGroupUser) {
        callUserListSearchAPI(
          searchUserListText,
          userListSearch.success.pageNumber + FixedValue.CONSTANT_VALUE_1
        );
      }
    }
  }, [userListSearch]);

  const onChangeSearchUserText = React.useCallback(
    (value: string): void => {
      setSearchUserListText(value);
      if (
        value.length > FixedValue.CONSTANT_VALUE_2 ||
        value.length === FixedValue.CONSTANT_VALUE_0
      ) {
        callUserListSearchAPI(value, FixedValue.CONSTANT_VALUE_0);
      }
    },
    [searchUserListText]
  );

  const callDistributeTopAPI = React.useCallback(
    (selectedUsersListSearch: CustomUserListSearchModalListData[]): void => {
      const quantityData = selectedUsersListSearch?.map(
        (user: CustomUserListSearchModalListData) => ({
          userId: user.id,
          quantity: user.quantity,
        })
      );
      const data = {
        topId: selectedTopData?.id,
        quantity: quantityData,
      };
      dispatch(onDistributeTopStart(data));
    },
    [dispatch]
  );

  const resetDistributeTopsHelperData = React.useCallback((): void => {
    setShowDistributiveModal(false);
    setSelectedUsersListSearch([]);
    setSelectedUsersFavouriteListSearch([]);
    dispatch(onClearFavoriteUserListEnd());
    setFavoriteListData([]);
    setSearchUserListText('');
    setSearchFavouriteUserListText('');
    setSearchGroupListText('');
    setSelectedGroupDetail(null);
  }, [
    showDistributiveModal,
    selectedUsersListSearch,
    searchUserListText,
    searchGroupListText,
    selectedGroupDetail,
  ]);

  const callGroupListSearchAPI = React.useCallback(
    (keyword: string, page: number): void => {
      const params = {
        keyword,
        page,
        size: FixedValue.CONSTANT_VALUE_15,
      };
      dispatch(onGroupListSearchStart(params));
    },
    [dispatch]
  );

  const onChangeSearchGroupText = React.useCallback(
    (value: string): void => {
      setSearchGroupListText(value);
      if (
        value.length > FixedValue.CONSTANT_VALUE_2 ||
        value.length === FixedValue.CONSTANT_VALUE_0
      ) {
        callGroupListSearchAPI(value, FixedValue.CONSTANT_VALUE_0);
      }
    },
    [searchGroupListText]
  );

  const onReachedEndGroupListSearch = React.useCallback((): void => {
    if (
      groupListSearch.success != null &&
      groupListSearch.success.pageNumber < groupListSearch.success.totalPages
    ) {
      callGroupListSearchAPI(
        searchGroupListText,
        groupListSearch.success.pageNumber + FixedValue.CONSTANT_VALUE_1
      );
    }
  }, [groupListSearch]);

  const callGroupUserListSearchAPI = React.useCallback(
    (page: number, groupId?: number, size?: number): void => {
      const params = {
        groupId,
        page,
        size: size ?? FixedValue.CONSTANT_VALUE_15,
      };
      dispatch(onGroupUserListSearchStart(params));
    },
    [dispatch]
  );

  const onReachedEndGroupUserListSearch = React.useCallback((): void => {
    if (
      groupUserListSearch.success != null &&
      groupUserListSearch.success.pageNumber <
        groupUserListSearch.success.totalPages
    ) {
      callGroupUserListSearchAPI(
        groupUserListSearch.success.pageNumber + FixedValue.CONSTANT_VALUE_1,
        selectedGroupDetail?.id
      );
    }
  }, [groupUserListSearch]);

  const onChangeSearchFavouriteUser = React.useCallback(
    (value: string): void => {
      setSearchFavouriteUserListText(value);
      if (
        value.length > FixedValue.CONSTANT_VALUE_2 ||
        value.length === FixedValue.CONSTANT_VALUE_0
      ) {
        getfavoriteUserList(value, FixedValue.CONSTANT_VALUE_0);
      }
    },
    [searchFavouriteUserListText]
  );

  return {
    callUserListSearchAPI,
    onReachedEnd,
    onChangeSearchUserText,
    callDistributeTopAPI,
    resetDistributeTopsHelperData,
    callGroupListSearchAPI,
    onChangeSearchGroupText,
    onReachedEndGroupListSearch,
    callGroupUserListSearchAPI,
    onReachedEndGroupUserListSearch,
    onChangeSearchFavouriteUser,
  };
};

export default useHomeHooks;
