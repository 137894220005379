import React from 'react';
import {View} from 'react-native';
import {TopReceivedStyle} from './topReceived.style';
import {ThemeContext} from '../../../contextAPI/themeContext';
import TopReceivedContent from './topReceivedContent';
import ConfirmationModal from '../../../modals/confirmationModal/confirmationModal';
import {FixedValue, TEXT} from '../../../constants';
import {ChildProfileContext} from '../../../contextAPI/childProfileContext';
import useTradingApprovalHooks from '../../../hooks/componentHooks/useTradingApprovalHooks';
import {
  APPROVE_RECEIVED_OFFER,
  REJECT_RECEIVED_OFFER,
  TRADE_OFFER_REPORT,
} from '../../../constants/actionTypes';
import {TradeTabStore} from '../../../redux/reducers/tradeTabSlice';
import {useAppSelector} from '../../../hooks/reduxHooks';
import {
  ACCEPTED_OR_REJECTED,
  APPROVED_OR_REJECT,
  SENT_OR_RECEIVED,
} from '../../../constants/enum';
import AbuseReportModal from '../../../modals/abuseReportModal';
import {DistributeRequestModel} from '../../../types/componentTypes/childProfileType';
import {TradeContext} from '../../../contextAPI/tradeContext';
import {ReportFormState} from '../../../types/reducerTypes/tradeTabTypes';
import {showAlert, _ToastHandler} from '../../../utils/helperFunctions';
import useTradeReceviedOrSentHooks from '../../../hooks/componentHooks/useTradeReceviedOrSentHooks';
import {useIsFocused} from '@react-navigation/native';

const TopReceived = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = TopReceivedStyle(ColorTheme);
  const {
    isTradeReceivedApproved,
    selectedTradeReceivedData,
    showTradeApproveConfirmModal,
    setShowTradeApproveConfirmModal,
    setSelectedTradeReceivedData,
  } = React.useContext(ChildProfileContext);
  const {getTradeReceviedOrSentList} = useTradingApprovalHooks();
  const {approveOffer, rejectOffer} = useAppSelector(TradeTabStore);
  const {openReportModal, setOpenReportModal} = React.useContext(TradeContext);
  const {tradeAbuseReport} = useAppSelector(TradeTabStore);
  const {callTopTradeStatusUpdateAPI} = useTradeReceviedOrSentHooks();
  const isFocused = useIsFocused();

  const callReceivedTradeList = () => {
    getTradeReceviedOrSentList(
      SENT_OR_RECEIVED.RECEIVED,
      FixedValue.CONSTANT_VALUE_0
    );
    setSelectedTradeReceivedData(null);
  };

  React.useEffect(() => {
    switch (approveOffer.status) {
      case APPROVE_RECEIVED_OFFER.SUCCESS:
        callReceivedTradeList();
        getTradeReceviedOrSentList(
          SENT_OR_RECEIVED.SENT,
          FixedValue.CONSTANT_VALUE_0
        );
        break;
      case APPROVE_RECEIVED_OFFER.FAILED:
        break;
      default:
        break;
    }
    switch (rejectOffer.status) {
      case REJECT_RECEIVED_OFFER.SUCCESS:
        if (selectedTradeReceivedData?.id) {
          callReceivedTradeList();
        }
        break;
      case REJECT_RECEIVED_OFFER.FAILED:
        break;
      default:
        break;
    }
  }, [approveOffer.status, rejectOffer.status]);

  React.useEffect(() => {
    if (isFocused) {
      switch (tradeAbuseReport.status) {
        case TRADE_OFFER_REPORT.SUCCESS:
          showAlert();
          callReceivedTradeList();
          break;
        case TRADE_OFFER_REPORT.FAILED:
          break;
        default:
          break;
      }
    }
  }, [tradeAbuseReport.status]);

  //top trade report function
  const topTradeReport = React.useCallback(
    (reportData: ReportFormState): void => {
      const params = {...reportData, status: ACCEPTED_OR_REJECTED.REPORT};
      callTopTradeStatusUpdateAPI(params);
    },
    []
  );

  const childTradeReceivedAction = React.useCallback((): void => {
    const tradeApproveRejectParams = {
      status: isTradeReceivedApproved
        ? APPROVED_OR_REJECT.APPROVED
        : APPROVED_OR_REJECT.REJECT,
      offerId: selectedTradeReceivedData?.id,
    };
    callTopTradeStatusUpdateAPI(tradeApproveRejectParams);
  }, [selectedTradeReceivedData, isTradeReceivedApproved]);

  return (
    <View style={styles.receivedMainContainer}>
      <TopReceivedContent />
      {showTradeApproveConfirmModal && (
        <ConfirmationModal
          title={
            isTradeReceivedApproved ? TEXT.OFFER_APPROVE : TEXT.OFFER_REJECT
          }
          modalVisible={showTradeApproveConfirmModal}
          onPressYes={() => {
            childTradeReceivedAction();
            setShowTradeApproveConfirmModal();
          }}
          onShowHide={setShowTradeApproveConfirmModal}
        />
      )}
      {openReportModal && (
        <AbuseReportModal
          modalVisible={openReportModal}
          onClose={setOpenReportModal}
          onSubmit={topTradeReport}
          offerId={
            (selectedTradeReceivedData as DistributeRequestModel)?.id ?? -1
          }
          isForChild={true}
        />
      )}
    </View>
  );
};

export default React.memo(TopReceived);
