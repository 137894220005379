import React from 'react';
import {View, Text, Pressable} from 'react-native';
import {FixedValue, ROUTE_NAME, TabBarName} from '../../../constants';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {tradeHeaderStyle} from './styles';
import SearchIcon from '../../renderSvgs/searchIcon';
import {scaleHeight, scaleWidth} from '../../../utils/responsive';
import {navigate} from '../../../services/navigationService';

const TradeTabHeader = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = tradeHeaderStyle(ColorTheme);

  return (
    <View style={styles.container} dataSet={{media: ids.container}}>
      <View style={styles.headerConntainer}>
        <Text style={styles.titleText}>{TabBarName.TRADE}</Text>
        <Pressable
          onPress={() => {
            navigate(ROUTE_NAME.SEARCH_TAB);
          }}
          style={styles.marginRight18}
        >
          <SearchIcon
            height={scaleHeight(FixedValue.CONSTANT_VALUE_23)}
            width={scaleWidth(FixedValue.CONSTANT_VALUE_25)}
            stroke={ColorTheme.color}
          />
        </Pressable>
      </View>
    </View>
  );
};

export default React.memo(TradeTabHeader);
