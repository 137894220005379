import React from 'react';
import {View} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {TradeModalModalStyle} from './trade.style';
import TradeModalContent from './tradeContent';
import ModalHeaderWithCross from '../../components/header/modalHeaderWithCross';
import {TEXT} from '../../constants';
import {
  CANCEL_SENT_OFFER,
  REJECT_RECEIVED_OFFER,
} from '../../constants/actionTypes';
import {useAppSelector} from '../../hooks/reduxHooks';
import {TradeTabStore} from '../../redux/reducers/tradeTabSlice';
import ModalContainer from '../../components/layout/modalContainer';
import AppLoader from '../../components/smallDesigns/appLoader';
import {TradeContext} from '../../contextAPI/tradeContext';
import {NotificationContext} from '../../contextAPI/notificationContext';
interface TradeProps {
  isSent: boolean;
  modalVisible: boolean;
  onClose: () => void;
  showLoader?: boolean;
  isChild?: boolean;
  showCancelButton?: boolean;
  isTradeReceivedRequest?: boolean;
  isTradeSentRequest?: boolean;
  showActionButton?: boolean;
  showPendingRequestCount?: boolean;
  showFavIcon?: boolean;
}
const TradeModal = (props: TradeProps): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = TradeModalModalStyle(ColorTheme);
  const {
    isSent,
    modalVisible,
    onClose,
    showLoader = false,
    isChild = false,
    showCancelButton = true,
    isTradeReceivedRequest = false,
    isTradeSentRequest = false,
    showActionButton = false,
    showPendingRequestCount = true,
    showFavIcon = false,
  } = props;
  const {selectedItem} = React.useContext(TradeContext);
  const {selectedNotificationCard} = React.useContext(NotificationContext);
  const sendByLoggedInUser =
    selectedItem?.fromUser.id === selectedNotificationCard?.userId;
  const headerTitle =
    isSent && !sendByLoggedInUser ? TEXT.TRADE_OFFER : TEXT.SENT_OFFER_MODAL;
  const {rejectOffer, cancelOffer} = useAppSelector(TradeTabStore);

  React.useEffect(() => {
    switch (rejectOffer.status) {
      case REJECT_RECEIVED_OFFER.SUCCESS:
        onClose();
        break;
      case REJECT_RECEIVED_OFFER.FAILED:
        break;
      default:
        break;
    }
  }, [rejectOffer.status]);

  React.useEffect(() => {
    switch (cancelOffer.status) {
      case CANCEL_SENT_OFFER.SUCCESS:
        onClose();
        break;
      case CANCEL_SENT_OFFER.FAILED:
        break;
      default:
        break;
    }
  }, [cancelOffer.status]);
  return (
    <ModalContainer visible={modalVisible} onPressOutside={onClose}>
      <View style={styles.innerView}>
        <View style={styles.crossImage}>
          <ModalHeaderWithCross onPress={onClose} headerTitle={headerTitle} />
        </View>
        {showLoader ? (
          <View style={styles.flex1}>
            <AppLoader />
          </View>
        ) : (
          <TradeModalContent
            onClose={onClose}
            isChild={isChild}
            showCancelButton={showCancelButton}
            isTradeReceivedRequest={isTradeReceivedRequest}
            isTradeSentRequest={isTradeSentRequest}
            showActionButton={showActionButton}
            showPendingRequestCount={showPendingRequestCount}
            showFavIcon={showFavIcon}
          />
        )}
      </View>
    </ModalContainer>
  );
};

export default TradeModal;
