import React from 'react';
import {ERROR_MESSAGES, FixedValue} from '../../constants';
import {useFormik} from 'formik';
import {CreateEditGroupSchema} from '../../utils/formikSchema';
import {GroupContext} from '../../contextAPI/groupManagementContext';
import {useAppDispatch, useAppSelector} from '../reduxHooks';
import {
  groupManagementStore,
  onCreateGroupStart,
  onUpdateGroupStart,
  onUserListStart,
  onUserAddOnGroupStart,
} from '../../redux/reducers/groupManagementSlice';
import {_ToastHandler} from '../../utils/helperFunctions';
import useAwsS3 from '../libraryHooks/useAwsS3';
import {UpdateGroupParams} from '../../types/reducerTypes/groupMangementTypes';
import {
  captureImageFromCamera,
  pickImageFromGallery,
} from '../../utils/imageUtils';
export type GroupField = {groupName: string; groupImage?: string};

const useCreateEditGroupHooks = () => {
  const dispatch = useAppDispatch();
  const {uploadImage} = useAwsS3();
  const {
    groupProfileImages,
    groupDetailData,
    openCloseProfileModal,
    openCreateGroupModal,
    searchUserListText,
    selectedUsers,
    selectUserListModal,
    isGroupEditMode,
    openCloseGroupEditDeleteModal,
    groupInitialImage,
    selectedGroupId,
    groupUserListResult,
    createEditGroupModalData,
    initialLoader,
    tempGroupData,
    setOpenCloseGroupEditDeleteModal,
    setOpenCreateGroupModal,
    setGroupProfileImages,
    setOpenCloseProfileModal,
    removeProfileImage,
    setSearchUserListText,
    setGroupInitialImage,
    updateSelectedUserInGroup,
    setSelectUserListModal,
    setCreateEditGroupModalData,
    setIsGroupEditMode,
    setTempGroupData,
    setInitialLoader,
  } = React.useContext(GroupContext);

  const {userList, createNewGroup} = useAppSelector(groupManagementStore);

  // Set the initial values with an object containing groupName property
  const [initialValues, setInitialValues] = React.useState<GroupField>({
    groupName: '',
    groupImage: '',
  });

  // Add a state variable for the disabled state of the button
  const [isButtonDisabled, setIsButtonDisabled] = React.useState<boolean>(true);
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
    isValid,
  } = useFormik<GroupField>({
    validationSchema: CreateEditGroupSchema,
    initialErrors: {
      groupName: ERROR_MESSAGES.REQUIRED_GROUP_NAME,
    },
    initialTouched: {
      groupName: false,
      groupImage: false,
    },
    initialValues: {groupName: tempGroupData ?? '', groupImage: ''},
    onSubmit: val => {
      isGroupEditMode ? groupUpdateAPI(val) : createGroup(val);
    },
  });

  //set intials value when group edit
  React.useEffect(() => {
    if (tempGroupData) {
      setFieldValue('groupName', tempGroupData);
    } else {
      setFieldValue('groupName', values.groupName);
    }
  }, [tempGroupData, setFieldValue]);

  React.useEffect(() => {
    if (tempGroupData && createEditGroupModalData) {
      setInitialValues({groupName: tempGroupData ?? ''});
      setIsGroupEditMode(true);
    } else {
      setInitialValues({groupName: ''});
      setIsGroupEditMode(false);
    }
  }, [createEditGroupModalData]);

  const _onChangeSearchText = React.useCallback(
    (value: string): void => {
      setSearchUserListText(value);
      if (value.length > FixedValue.CONSTANT_VALUE_2) {
        callUserListAPI(value, FixedValue.CONSTANT_VALUE_0);
      }
      if (value.length === FixedValue.CONSTANT_VALUE_0) {
        callUserListAPI(value, FixedValue.CONSTANT_VALUE_0);
      }
    },
    [searchUserListText]
  );

  const onReachedEnd = React.useCallback((): void => {
    if (
      userList.success != null &&
      userList.success.pageNumber < userList.success.totalPages
    ) {
      callUserListAPI(
        searchUserListText,
        userList.success.pageNumber + FixedValue.CONSTANT_VALUE_1
      );
    }
  }, [userList]);

  const GroupNameError: string =
    touched.groupName && errors.groupName ? errors.groupName : '';

  React.useEffect(() => {
    if (values.groupName.trim().length > FixedValue.CONSTANT_VALUE_02) {
      setTempGroupData(values.groupName.trim());
      setIsButtonDisabled(false);
    } else {
      setTempGroupData(values.groupName.trim());
      setIsButtonDisabled(true);
    }
  }, [values]);

  //open phone and web gallery
  const _openGallery = async (): Promise<void> => {
    const image = await pickImageFromGallery();
    if (image) {
      setGroupProfileImages(image);
      setGroupInitialImage('');
      setOpenCloseProfileModal();
    }
  };

  //take picture from camera
  const _takePicture = async () => {
    const image = await captureImageFromCamera();
    if (image) {
      setGroupProfileImages(image);
      setGroupInitialImage('');
      setOpenCloseProfileModal();
    }
  };

  //remove profile image from create group
  const _removeImage = React.useCallback((): void => {
    setGroupInitialImage('');
    removeProfileImage();
    setOpenCloseProfileModal();
    setOpenCreateGroupModal();
  }, [openCloseProfileModal, groupProfileImages]);

  const callCreateGroupApi = React.useCallback(
    (value: GroupField) => {
      dispatch(onCreateGroupStart(value));
    },
    [dispatch]
  );

  const callUpdateGroupApi = React.useCallback(
    (value: UpdateGroupParams) => {
      dispatch(onUpdateGroupStart(value));
    },
    [dispatch]
  );

  const groupUpdateAPI = React.useCallback(
    async (value: GroupField): Promise<void> => {
      try {
        const data = {
          groupName: value.groupName.trim(),
          image: '',
          groupId: groupDetailData?.id ?? 0,
        };
        if (groupProfileImages) {
          try {
            const uploadedSource = await uploadImage(groupProfileImages);
            data.image = uploadedSource;
          } catch (error: any) {
            _ToastHandler(error, false);
            return;
          }
        } else {
          data.image = groupInitialImage;
        }
        callUpdateGroupApi(data);
      } catch (error: any) {
        _ToastHandler(error, false);
      }
    },
    [groupProfileImages, groupInitialImage]
  );

  const createGroup = React.useCallback(
    async (value: GroupField): Promise<void> => {
      try {
        const data = {groupName: value.groupName.trim(), groupImage: ''};
        if (groupProfileImages) {
          try {
            const uploadedSource = await uploadImage(groupProfileImages);
            data.groupImage = uploadedSource;
          } catch (error: any) {
            _ToastHandler(error, false);
            return;
          }
        }
        callCreateGroupApi(data);
      } catch (error: any) {
        _ToastHandler(error, false);
      }
    },
    [groupProfileImages]
  );

  const closeProfileModalAndImageBlank = React.useCallback((): void => {
    setOpenCreateGroupModal();
    removeProfileImage();
  }, [openCloseProfileModal, groupProfileImages]);

  const openProfileModal = React.useCallback((): void => {
    setOpenCreateGroupModal();
    setOpenCloseProfileModal();
  }, [openCreateGroupModal, openCloseProfileModal, values, initialValues]);

  //call API add user to group
  const callAddUserToGroupAPI = React.useCallback(() => {
    const userIds = selectedUsers.map(item => item.id);
    const params = {
      groupId: selectedGroupId,
      usersId: userIds,
    };
    dispatch(onUserAddOnGroupStart(params));
  }, [dispatch, selectedUsers, createNewGroup]);

  const callUserListAPI = React.useCallback(
    (keyword: string, page: number): void => {
      const params = {
        keyword,
        page,
        groupId: selectedGroupId,
        size: FixedValue.CONSTANT_VALUE_15,
      };
      dispatch(onUserListStart(params));
    },
    [searchUserListText, createNewGroup, selectUserListModal]
  );

  const openGroupEditModal = React.useCallback((): void => {
    if (groupDetailData?.groupImage) {
      setGroupInitialImage(groupDetailData?.groupImage ?? '');
    } else {
      setGroupInitialImage('');
    }
    setTempGroupData(groupDetailData?.groupName?.trim() ?? '');
    setCreateEditGroupModalData(groupDetailData);
    setOpenCloseGroupEditDeleteModal(false);
    setOpenCreateGroupModal();
  }, [
    openCreateGroupModal,
    openCloseGroupEditDeleteModal,
    groupInitialImage,
    groupDetailData,
    tempGroupData,
  ]);

  //cancel button action on create and edit group image
  const _cancelButtonAction = React.useCallback((): void => {
    setGroupInitialImage('');
    removeProfileImage();
    setOpenCreateGroupModal();
    setOpenCloseProfileModal();
  }, [
    groupInitialImage,
    openCloseProfileModal,
    groupProfileImages,
    openCloseProfileModal,
    tempGroupData,
  ]);

  //show app user list so user can add member group
  //show pending invitation list
  const openUserAddModal = React.useCallback((): void => {
    setInitialLoader(true);
    updateSelectedUserInGroup([]);
    callUserListAPI('', FixedValue.CONSTANT_VALUE_0);
    setSelectUserListModal();
  }, [groupUserListResult, initialLoader]);

  return {
    isValid,
    values,
    GroupNameError,
    isButtonDisabled,
    searchUserListText,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    _openGallery,
    _takePicture,
    _removeImage,
    callCreateGroupApi,
    closeProfileModalAndImageBlank,
    openProfileModal,
    _onChangeSearchText,
    onReachedEnd,
    callUserListAPI,
    callAddUserToGroupAPI,
    openGroupEditModal,
    _cancelButtonAction,
    openUserAddModal,
  };
};

export default useCreateEditGroupHooks;
