import React from 'react';
import {Modal, View, Text, Image} from 'react-native';
import PrimaryButton from '../../components/button/primaryButton';
import {COLORS, FixedValue, IMAGES, TEXT} from '../../constants';
import {WelcomeTopModalStyle} from './welcomeTop.style';
import {ThemeContext} from '../../contextAPI/themeContext';
import {BlurView} from 'expo-blur';
import useAwsS3 from '../../hooks/libraryHooks/useAwsS3';
import {getTopImage} from '../../utils/imageUtils';
import {HomeContext} from '../../contextAPI/homeContext';
import {ActivityIndicator} from 'react-native';

interface Modalrops {
  visible: boolean;
  onClose: () => void;
}

const WelcomeTopModal = (props: Modalrops): JSX.Element => {
  const {visible, onClose} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = WelcomeTopModalStyle(ColorTheme);
  const {loadSignedUrl} = useAwsS3();
  const [svgCode, setSvgCode] = React.useState<
    JSX.Element | undefined | String
  >(undefined);
  const {collectionsResult} = React.useContext(HomeContext);
  const image =
    collectionsResult?.[FixedValue.CONSTANT_VALUE_0]?.tops?.[
      FixedValue.CONSTANT_VALUE_0
    ]?.image;

  const getSvgUrl = React.useCallback(async (): Promise<void> => {
    const imageUrl = await loadSignedUrl(image ?? '');
    if (imageUrl) {
      const topImage = await getTopImage(imageUrl ?? '', styles.imageContainer);
      setSvgCode(topImage);
    }
  }, [image, svgCode]);

  React.useEffect(() => {
    getSvgUrl();
  }, [image]);

  return (
    <Modal
      visible={visible}
      transparent={true}
      animationType="fade"
      statusBarTranslucent={true}
    >
      <BlurView
        style={styles.blurViewStyle}
        intensity={FixedValue.CONSTANT_VALUE_30}
      />
      <View style={styles.modalBackground}>
        <Image source={IMAGES.CONGRATS2} style={styles.lottieViewStyle} />
        <View style={styles.modalContent}>
          <View style={styles.imageContainer}>
            {svgCode ? (
              <View style={styles.imageContainer}>{svgCode}</View>
            ) : (
              <View style={styles.imageLoader}>
                <ActivityIndicator size={'small'} color={COLORS.PRIMARY_BLUE} />
              </View>
            )}
          </View>
          <Text style={styles.congratsText}>{TEXT.CONGRATULATIONS_ONLY}</Text>
          <Text style={styles.awardedText}>{TEXT.YOU_HAVE_BEEN}</Text>
        </View>
        <View style={styles.modalContent}>
          <PrimaryButton
            buttonLabel={TEXT.GET_STARTED}
            buttonwidth={styles.getStartedButton}
            labelStyle={styles.getStartedTextStyle}
            onPress={onClose}
          />
        </View>
      </View>
    </Modal>
  );
};

export default WelcomeTopModal;
