import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const GreenTick2 = (props: SvgProps) => (
  <Svg
    width={10}
    height={10}
    viewBox="0 0 10 10"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10Z"
      fill="#4EAF51"
    />
    <Path
      d="M7.33317 3.3335L4.12484 6.54183L2.6665 5.0835"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default GreenTick2;
