import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {scaleHeight, scaleWidth} from '../../utils/responsive';

export const blockContentSyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
    },
    headerView: {
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    emptyView: {
      marginHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_28),
    },
    scrollviewStyle: {
      ...BASESTYLE.flex1,
      justifyContent: GlobalStyleValues.CENTER,
    },
    flatListContentContainerStyle: {
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_130),
    },
    mainList: {
      width: Percentage.PRECENTAGE_100,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
  });
