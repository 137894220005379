import StyleSheet from 'react-native-media-query';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {BASESTYLE, FONT_NAMES, FixedValue, Percentage} from '../../constants';
import {
  SCREEN_WIDTH,
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const DistributingTopStyle = (theme?: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : SCREEN_WIDTH,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    headerstyle: {
      ...BASESTYLE.inRow,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    headertext: {
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.flex1,
    },
    container: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_8),
      ...BASESTYLE.flex1,
    },
    titleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: theme?.color,
    },
  });
