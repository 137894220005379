import {StyleSheet} from 'react-native';
import {
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  BASESTYLE,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  SCREEN_WIDTH,
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const notesCellStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    notesHeaderContainer: {
      justifyContent: GlobalStyleValues.SPACE_BETWEEN,
      flexDirection: GlobalStyleValues.ROW,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    addNewNotesButton: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_18),
      color: COLORS.PRIMARY_BLUE,
      fontFamily: FONT_NAMES.SF_LIGHT,
    },
    addedByText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: COLORS._666666,
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_12),
    },
    noteHeading: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: _theme?.color,
    },
    deleteBinView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_24),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_24),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_21),
      backgroundColor: COLORS._FFEBEB,
    },
    editIconView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_24),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_24),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_21),
      backgroundColor: COLORS.E9EEFB,
      marginRight: scaleHeight(FixedValue.CONSTANT_VALUE_12),
    },
    iconView: {
      flexDirection: GlobalStyleValues.ROW,
    },
    notesCellView: {
      backgroundColor: COLORS._FFFAED,
      borderRadius: FixedValue.CONSTANT_VALUE_8,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      width: isWebsite() ? Percentage.PRECENTAGE_100 : SCREEN_WIDTH,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    notesTextContainer: {
      justifyContent: GlobalStyleValues.SPACE_BETWEEN,
      flexDirection: GlobalStyleValues.ROW,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    noteText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: COLORS.BLACK,
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_17),
    },
    dateText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: COLORS._666666,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_4),
    },
    width100: {
      width: Percentage.PRECENTAGE_100,
    },
  });
