import React from 'react';
import {View} from 'react-native';
import ModalContainer from '../../components/layout/modalContainer';
import {HomeContext} from '../../contextAPI/homeContext';
import {ThemeContext} from '../../contextAPI/themeContext';

import {editTopModalStyle} from './topModal.styles';
import EditTopModalContent from './editTopModalContent';
const EditTopModal = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = editTopModalStyle(ColorTheme);
  const {openEditTopModal, setOpenEditTopModal} = React.useContext(HomeContext);
  return (
    <ModalContainer
      visible={openEditTopModal}
      onPressOutside={setOpenEditTopModal}
    >
      <View style={styles.subContainer}>
        <EditTopModalContent />
      </View>
    </ModalContainer>
  );
};

export default EditTopModal;
