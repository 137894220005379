import React from 'react';
import {View, Text, Pressable} from 'react-native';
import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {IModalOptionsButton} from '../../types/componentTypes/buttonType';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';

export const modalOptionStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    viewStyle: {
      ...BASESTYLE.inCenter,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_24),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_24),
    },
    container: {
      flexDirection: GlobalStyleValues.COLUMN,
      width: Percentage.PRECENTAGE_100,
      backgroundColor: theme.backgroundColor,
    },
    optionLabel: {
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_13),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: theme.color,
    },
    deleteLabel: {
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_13),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: COLORS._FF3D3D,
    },
    seperatorView: {
      height: scaleWidth(1.2),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      backgroundColor: COLORS.B6BFC9,
    },
    optionView: {
      ...BASESTYLE.inRow,
      paddingVertical: scaleHeight(FixedValue.CONSTANT_VALUE_15),
      backgroundColor: theme.backgroundColor,
    },
  });

const ModalButton = ({
  LeftIcon,
  label,
  onPress,
  isDelete,
  height,
  width,
  hideSeperator = true,
}: IModalOptionsButton) => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = modalOptionStyle(ColorTheme);

  const getOptionColor = () => {
    if (isDelete) {
      return styles.deleteLabel;
    } else {
      return styles.optionLabel;
    }
  };
  return (
    <Pressable style={styles.container} onPress={onPress}>
      <View style={styles.optionView}>
        <View style={styles.viewStyle}>
          {LeftIcon && (
            <LeftIcon
              stroke={!isDelete ? ColorTheme.color : COLORS._FF3D3D}
              height={height}
              width={width}
            />
          )}
        </View>
        <Text style={getOptionColor()}>{label}</Text>
      </View>
      {hideSeperator && <View style={styles.seperatorView}></View>}
    </Pressable>
  );
};
export default ModalButton;
