import React from 'react';
import {useFormik} from 'formik';
import {RouteProp, useIsFocused, useRoute} from '@react-navigation/native';
import {Keyboard} from 'react-native';

import {ERROR_MESSAGES, FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import {VerifyOtpSchema} from '../../utils/formikSchema';
import {navigate, reset} from '../../services/navigationService';
import {useAppDispatch, useAppSelector} from '../reduxHooks';
import {
  verifyOtpState,
  onVerifyOtpStart,
  onResendCodeStart,
  updateLoaderStatus,
} from '../../redux/reducers/verifyOtp';
import {
  FORGOT_PASSWORD,
  RESEND_APPROVAL_REQUEST_DATA,
  RESEND_CODE,
  USER_LOGIN,
  USER_PROFILE_DATA,
  VERIFY_OTP,
} from '../../constants/actionTypes';
import {RootStackParamList} from '../../types/navigationType';
import {verifyOtpFields} from '../../screens/verifyEmail/verifyEmailContentView';
import {
  _ToastHandler,
  calculateAgeFromDate,
  saveAllUserDataWhileLogin,
  removeAllData,
} from '../../utils/helperFunctions';
import {
  forgotPasswordState,
  onForgotPasswordStart,
} from '../../redux/reducers/forgotPasswordSlice';
import {authState, onLoginStart} from '../../redux/reducers/authSlice';
import {
  onUserDataStart,
  onResendApprovalRequestStart,
  userProfileStore,
} from '../../redux/reducers/userProfileSlice';
import {HomeContext} from '../../contextAPI/homeContext';
import {onUpdateTokenOnServerStart} from '../../redux/reducers/notificationSlice';
import {isWebsite} from '../../utils/responsive';

type VerifyOtp = {Code: string};
const useVerifyHooks = () => {
  const route = useRoute<RouteProp<RootStackParamList, 'VerifyEmail'>>();
  const {userLogin, userRegister} = useAppSelector(authState);
  const {userData, resendApprovalRequestData} =
    useAppSelector(userProfileStore);
  const {verifyOtp, resendCode} = useAppSelector(verifyOtpState);
  const {forgotPassword} = useAppSelector(forgotPasswordState);
  const dispatch = useAppDispatch();
  const isFocused = useIsFocused();
  const {setHomeScreenUserData, setShowWelcomeTop, setOpenReedemModal} =
    React.useContext(HomeContext);
  const {allFeilds, navigateFrom, emailId, userCredentials} = route.params;

  const {handleChange, handleSubmit, isValid, values} = useFormik<VerifyOtp>({
    validationSchema: VerifyOtpSchema,
    initialErrors: {
      Code: ERROR_MESSAGES.REQUIRED_EMAIL,
    },
    initialTouched: {Code: false},
    initialValues: {Code: ''},
    onSubmit: _val => handleSbmt(_val),
  });

  const handleSbmt = React.useCallback(
    (value: VerifyOtp): void => {
      Keyboard.dismiss();
      switch (navigateFrom) {
        case ROUTE_NAME.LOSS_PASSWORD:
          navigate(ROUTE_NAME.CREATE_PASSWORD, {
            emailId,
            Code: value.Code,
          });
          break;
        case ROUTE_NAME.LOGIN_SCREEN:
          dispatch(updateLoaderStatus(true));
          const params: verifyOtpFields = {
            Username: emailId,
            Code: value.Code,
          };
          dispatch(onVerifyOtpStart(params));
          break;
        case ROUTE_NAME.SIGN_UP:
          dispatch(updateLoaderStatus(true));
          const payload: verifyOtpFields = {
            Username: allFeilds?.Username ?? '',
            Code: value.Code,
          };
          dispatch(onVerifyOtpStart(payload));
          break;
        case ROUTE_NAME.EDIT_PROFILE:
          dispatch(updateLoaderStatus(true));
          const emailParams: verifyOtpFields = {
            Username: emailId,
            Code: value.Code,
            isVerifyEmail: true,
          };
          dispatch(onVerifyOtpStart(emailParams));
          break;
        case ROUTE_NAME.EDIT_PROFILE_MOBILE:
          dispatch(updateLoaderStatus(true));
          const paramsObj: verifyOtpFields = {
            Username: emailId,
            Code: value.Code,
            isVerifyMobile: true,
          };
          dispatch(onVerifyOtpStart(paramsObj));
          break;
        default:
          break;
      }
    },
    [emailId, allFeilds, navigateFrom]
  );

  const resendOtp = React.useCallback((): void => {
    switch (navigateFrom) {
      case ROUTE_NAME.LOSS_PASSWORD:
        dispatch(onForgotPasswordStart({Username: emailId}));
        break;
      case ROUTE_NAME.LOGIN_SCREEN:
        dispatch(onResendCodeStart({Username: emailId ?? ''}));
        break;
      case ROUTE_NAME.SIGN_UP:
        dispatch(onResendCodeStart({Username: allFeilds?.Username ?? ''}));
        break;
      case ROUTE_NAME.EDIT_PROFILE:
        dispatch(
          onResendCodeStart({Username: emailId ?? '', isVerifyEmail: true})
        );
        break;
      case ROUTE_NAME.EDIT_PROFILE_MOBILE:
        dispatch(
          onResendCodeStart({Username: emailId ?? '', isVerifyMobile: true})
        );
        break;
      default:
        break;
    }
  }, [allFeilds, navigateFrom, emailId]);

  const loginUser = React.useCallback((): void => {
    dispatch(onLoginStart(userCredentials));
  }, []);

  const onSuccess = React.useCallback(async (): Promise<void> => {
    if (userLogin.success != null && userData.success != null) {
      setHomeScreenUserData(userData.success);
      setShowWelcomeTop(!userData?.success?.isLoggedBefore ?? false);
      setOpenReedemModal(
        userRegister?.success?.data?.isReedemCodeNotValid ?? false
      );
      await saveAllUserDataWhileLogin(userData.success);
      dispatch(updateLoaderStatus(false));
      if (!isWebsite()) dispatch(onUpdateTokenOnServerStart());
      reset(ROUTE_NAME.BOTTOM_TAB);
    }
  }, [userLogin.success, userData.success, allFeilds, userRegister]);

  React.useEffect((): void => {
    /** API response of resend OTP in forgot password flow */
    switch (forgotPassword.status) {
      case FORGOT_PASSWORD.SUCCESS:
        _ToastHandler(TEXT.CODE_SENT_SUCCESSFULLY, true);
        break;
      case FORGOT_PASSWORD.FAILED:
        _ToastHandler(TEXT.OTP_CODE_INCORRECT, false);
        break;
      default:
        break;
    }

    /** API response of resend OTP in Login or sign up flow */
    switch (resendCode.status) {
      case RESEND_CODE.SUCCESS:
        _ToastHandler(TEXT.CODE_SENT_SUCCESSFULLY, true);
        break;
      case RESEND_CODE.FAILED:
        _ToastHandler(resendCode.failed ?? '', false);
        break;
      default:
        break;
    }

    /** API response of verification OTP */

    const userAge: number = allFeilds
      ? calculateAgeFromDate(allFeilds.DateOfBirth)
      : 0;
    if (verifyOtp.status === VERIFY_OTP.SUCCESS) {
      if (navigateFrom === ROUTE_NAME.SIGN_UP) {
        if (userAge < FixedValue.CONSTANT_VALUE_13) {
          dispatch(updateLoaderStatus(false));
          removeAllData(true);
          navigate(ROUTE_NAME.PARENT_CONSENT);
        } else {
          loginUser();
        }
      } else if (navigateFrom === ROUTE_NAME.EDIT_PROFILE) {
        dispatch(updateLoaderStatus(false));
        navigate(ROUTE_NAME.PROFILE_TAB);
      } else if (navigateFrom === ROUTE_NAME.EDIT_PROFILE_MOBILE) {
        dispatch(updateLoaderStatus(false));
        navigate(ROUTE_NAME.PROFILE_TAB);
      } else if (navigateFrom === ROUTE_NAME.LOGIN_SCREEN) {
        if (userAge < FixedValue.CONSTANT_VALUE_13) {
          dispatch(onLoginStart(userCredentials));
        } else {
        }
      } else {
        loginUser();
      }
    }
    if (verifyOtp.status === VERIFY_OTP.FAILED) {
      dispatch(updateLoaderStatus(false));
      _ToastHandler(verifyOtp.failed ?? '', false);
    }
  }, [
    forgotPassword.status,
    resendCode.status,
    verifyOtp.status,
    navigateFrom,
  ]);

  React.useEffect((): void => {
    if (isFocused) {
      /** API response of user login */
      if (userLogin.status === USER_LOGIN.SUCCESS) {
        dispatch(onUserDataStart());
      } else if (userLogin.status === USER_LOGIN.FAILED) {
        dispatch(updateLoaderStatus(false));
      }
    }
  }, [isFocused, userLogin.status]);

  React.useEffect((): void => {
    if (isFocused) {
      /** API response of fetch user details */
      switch (userData.status) {
        case USER_PROFILE_DATA.SUCCESS:
          onSuccess();
          break;
        case USER_PROFILE_DATA.FAILED:
          if (userData.failed === ERROR_MESSAGES.APPROVAL_PENDING) {
            dispatch(onResendApprovalRequestStart());
          } else {
            _ToastHandler(userData.failed ?? '', false);
          }
          break;
        default:
          break;
      }
    }
  }, [isFocused, userData.status]);
  React.useEffect((): void => {
    if (isFocused) {
      switch (resendApprovalRequestData.status) {
        case RESEND_APPROVAL_REQUEST_DATA.SUCCESS:
        case RESEND_APPROVAL_REQUEST_DATA.FAILED:
          removeAllData(true);
          navigate(ROUTE_NAME.PARENT_CONSENT);
          break;
        default:
          break;
      }
    }
  }, [isFocused, resendApprovalRequestData.status]);

  return {
    handleChange,
    handleSubmit,
    resendOtp,
    isValid,
    values,
  };
};

export default useVerifyHooks;
