import StyleSheet from 'react-native-media-query';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  BASESTYLE,
  COLORS,
  FONT_NAMES,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';
import {Platform} from 'react-native';

export const TopBuyingStyle = (theme?: IColorTheme) =>
  StyleSheet.create({
    topBuyingMainContainer: {
      ...BASESTYLE.flex1,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    topBuyingHeaderstyle: {
      ...BASESTYLE.inRow,
    },
    topBuyingHeadertext: {
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.flex1,
    },
    topBuyingContainer: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_32),
      ...BASESTYLE.flex1,
    },
    topBuyingTabBarStyle: {
      width: Percentage.PRECENTAGE_90,
    },
    topBuyingTextView: {
      marginLeft: FixedValue.CONSTANT_VALUE_21,
    },
    topBuyingTitleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: theme?.color,
    },
    loaderView: {
      ...BASESTYLE.absolutePosition,
      ...BASESTYLE.inCenter,
      right: FixedValue.CONSTANT_VALUE_0,
      left: FixedValue.CONSTANT_VALUE_0,
      top: FixedValue.CONSTANT_VALUE_0,
      bottom: FixedValue.CONSTANT_VALUE_0,
    },
    contentContainerStyle: {
      paddingBottom:
        Platform.OS === 'ios'
          ? scaleHeight(FixedValue.CONSTANT_VALUE_26)
          : FixedValue.CONSTANT_VALUE_8,
    },
    topBuyingUnderline: {
      borderBottomWidth: scaleWidth(FixedValue.CONSTANT_VALUE_1),
      borderColor: COLORS._E6E6E6,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_11),
      width: GlobalStyleValues.AUTO,
    },
  });
