import React from 'react';
import {LayoutChangeEvent, ScrollView, View} from 'react-native';
import {RouteProp, useRoute} from '@react-navigation/native';

import EditorFooter from '../../components/footer/editorFooter';
import EditorHeader from '../../components/header/editorHeader';
import {FixedValue} from '../../constants';
import {EditorContext} from '../../contextAPI/editorContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import {TopEditorItemType} from '../../types/componentTypes/editorType';
import {editorStyle} from './editor.style';
import RenderSvgItems from './renderSvgItems';
import {RootStackParamList} from '../../types/navigationType';
import EditorService from '../../services/editorService';
import useAwsS3 from '../../hooks/libraryHooks/useAwsS3';
import {SCREEN_WIDTH, isWebsite} from '../../utils/responsive';
import {testId} from '../../constants/enum';
import ViewShot from 'react-native-view-shot';

const EditorContent = (): JSX.Element => {
  const {
    viewShotRef,
    items,
    isScroll,
    selectedItemIndex,
    updateEditorSvgDimensions,
    setSelectedItemIndex,
    setPrimaryTabSelectedIndex,
    updateItemOrder,
  } = React.useContext(EditorContext);
  const _EditorService = EditorService.getInstance();
  const {loadSignedUrlForEditTop} = useAwsS3();
  const route = useRoute<RouteProp<RootStackParamList, 'EditorScreen'>>();
  const createdTopData = route.params?.createdTopData;
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = editorStyle(ColorTheme);

  const onLayout = (_event: LayoutChangeEvent): void => {
    const {width} = _event.nativeEvent.layout;
    updateEditorSvgDimensions(width, width);
  };

  const loadDataToEdit = React.useCallback(async (): Promise<void> => {
    if (createdTopData) {
      const imageUrl: string | undefined = await loadSignedUrlForEditTop(
        createdTopData?.image ?? ''
      );
      if (imageUrl) {
        const result: JSX.Element[] = _EditorService.loadTopSvgForEditor(
          imageUrl,
          setSelectedItemIndex
        );
        updateItemOrder([...result]);
      }
    }
  }, []);

  React.useEffect((): void => {
    if (selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1) {
      // @ts-ignore get the ref of the currently selected item
      const selectedItemRef = items[selectedItemIndex]?.ref?.current;
      const selectedItemType: TopEditorItemType =
        selectedItemRef?.getTopEditorItemType();
      switch (selectedItemType) {
        case TopEditorItemType.IMAGE:
          setPrimaryTabSelectedIndex(FixedValue.CONSTANT_VALUE_0);
          break;
        case TopEditorItemType.TEXT:
          setPrimaryTabSelectedIndex(FixedValue.CONSTANT_VALUE_1);
          break;
        case TopEditorItemType.SHAPE:
          setPrimaryTabSelectedIndex(FixedValue.CONSTANT_VALUE_2);
          break;
        default:
          setPrimaryTabSelectedIndex(FixedValue.CONSTANT_VALUE_MIN_1);
          break;
      }
    }
  }, [selectedItemIndex]);

  React.useEffect((): void => {
    loadDataToEdit();
  }, []);

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.containerStyle}
      showsVerticalScrollIndicator={false}
      dataSet={{media: ids.container}}
      bounces={false}
      scrollEnabled={!isScroll}
    >
      <EditorHeader />
      <View style={styles.svgOutterView}>
        {/* <View style={styles.svgBoardView} onLayout={onLayout}>
          <RenderSvgItems />
        </View> */}
        <ViewShot
          //@ts-ignore
          ref={viewShotRef}
          style={styles.svgBoardView}
          onLayout={onLayout}
        >
          <RenderSvgItems />
        </ViewShot>
      </View>
      <EditorFooter />
    </ScrollView>
  );
};

export default React.memo(EditorContent);
