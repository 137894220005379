import React from 'react';
import {View} from 'react-native';

import EditorModalHeader from '../../components/header/editorModalHeader';
import ModalContainer from '../../components/layout/modalContainer';
import {EditorContext} from '../../contextAPI/editorContext';
import {ThemeContext} from '../../contextAPI/themeContext';

import {editorModalStyle} from './editorModal.styles';
import EditorModalField from './editorModalField';

const EditorFormModal = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = editorModalStyle(ColorTheme);
  const {openEditorModal, setOpenEditorModal} = React.useContext(EditorContext);
  return (
    <ModalContainer
      visible={openEditorModal}
      onPressOutside={setOpenEditorModal}
    >
      <View style={styles.subContainer}>
        <EditorModalHeader />
        <EditorModalField />
      </View>
    </ModalContainer>
  );
};

export default EditorFormModal;
