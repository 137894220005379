import StyleSheet from 'react-native-media-query';
import {
  FixedValue,
  Percentage,
  FONT_NAMES,
  COLORS,
  BASESTYLE,
} from '../../constants';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';
import {IColorTheme} from '../../types/contextTypes/themeContext';

export const lostPwdStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      paddingHorizontal: isWebsite()
        ? 0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_12),
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    inputContainer: {
      width: Percentage.PRECENTAGE_100,
    },
    titleText: {
      color: theme.color,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_40),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_22),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      fontFamily: FONT_NAMES.SF_BOLD,
    },
    descText: {
      color: theme.color,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_18),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      marginBottom: scaleWidth(FixedValue.CONSTANT_VALUE_30),
    },
    buttonViewStyle: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_16),
    },
    dontHaveAccount: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_40),
      color: theme.color,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_15),
      fontFamily: FONT_NAMES.SF_REGULAR,
      ...BASESTYLE.selfCenter,
    },
    signUpText: {
      color: COLORS.PRIMARY_BLUE,
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
  });
