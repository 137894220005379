import {takeLatest, put, call, delay} from 'redux-saga/effects';
import {AppConst, FixedValue} from '../../constants';
import {IActionType} from '../../types/constantFilesTypes';
import {
  _forgotUserName,
  changePassword,
  createNewPassword,
} from '../../utils/apis';
import {
  onChangePasswordEnd,
  onChangePasswordFailed,
  onChangePasswordSuccess,
  onResetPasswordEnd,
  onResetPasswordFailed,
  onResetPasswordSuccess,
  onForgotUserNameSuccess,
  onForgotUserNameFailed,
  onForgotUserNameEnd,
} from '../reducers/resetPasswordSlice';
import {isWebsite} from '../../utils/responsive';
import {_ToastHandler} from '../../utils/helperFunctions';
import {
  CHANGE_PASSWORD_ERROR_TYPE,
  TOAST_TEXT,
} from '../../constants/textMessage';

const {StoreConstants} = AppConst;

function* resetPassword(action: IActionType) {
  try {
    const data: string = yield call(createNewPassword, action.payload);
    yield put(onResetPasswordSuccess(data));
  } catch (error: any) {
    yield put(onResetPasswordFailed(error.message));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onResetPasswordEnd());
  }
}

function* changeUserPassword(action: IActionType) {
  try {
    const data: string = yield call(changePassword, action.payload);
    yield put(onChangePasswordSuccess(data));
  } catch (error: any) {
    console.log('error>>>>',error)
    let errorMessage = 'An error occurred while changing the password.';
    if (error.code === CHANGE_PASSWORD_ERROR_TYPE.NotAuthorizedException) {
      errorMessage = TOAST_TEXT.INCORRECT_OLD_PASS;
    } else if (
      error.code === CHANGE_PASSWORD_ERROR_TYPE.LimitExceededException
    ) {
      errorMessage = TOAST_TEXT.ATTEMPT_LIMIT;
    } else if (
      error.code === CHANGE_PASSWORD_ERROR_TYPE.UserNotFoundException
    ) {
      errorMessage = TOAST_TEXT.USER_NOT_FOUND;
    } else if (
      error.code === CHANGE_PASSWORD_ERROR_TYPE.InvalidPasswordException
    ) {
      errorMessage = TOAST_TEXT.INVALID_NEW_PASS;
    }
    yield put(onChangePasswordFailed(errorMessage));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onChangePasswordEnd());
  }
}

function* forgotUserName(action: IActionType) {
  try {
    const data: string = yield call(_forgotUserName, action.payload);
    yield put(onForgotUserNameSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onForgotUserNameFailed(error.message));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onForgotUserNameEnd());
  }
}

export default function* resetPasswordSaga() {
  yield takeLatest(
    `${StoreConstants.RESET_PASSWORD}/onResetPasswordStart`,
    resetPassword
  );
  yield takeLatest(
    `${StoreConstants.RESET_PASSWORD}/onChangePasswordStart`,
    changeUserPassword
  );
  yield takeLatest(
    `${StoreConstants.RESET_PASSWORD}/onForgotUserNameStart`,
    forgotUserName
  );
}
