import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  FONTS,
  GlobalStyleValues,
  Percentage,
} from '../../../constants';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const trackingTabStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.selfCenter,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_25,
        width: Percentage.PRECENTAGE_100,
      },
    },
    contentContainerStyle: {
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_15),
    },
    indicator: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_21),
    },
    indevText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
    },
    contentContainer: {
      ...BASESTYLE.flex1,
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_75),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_8),
    },
    list: {
      flex: FixedValue.CONSTANT_VALUE_1,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_65),
    },
    itemContainer: {
      flexDirection: GlobalStyleValues.ROW,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    image: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_38),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_38),
      borderRadius: FixedValue.CONSTANT_VALUE_4,
      overflow: 'hidden',
    },
    circleImage: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      borderRadius: FixedValue.CONSTANT_VALUE_24,
    },
    textContainer: {
      flex: FixedValue.CONSTANT_VALUE_1,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    title: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_15),
      fontFamily: FONT_NAMES.SF_BOLD,
      width: Percentage.PRECENTAGE_90,
      color: theme.color,
    },
    quantity: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    subtitle: {
      color: COLORS._5E6272,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
      fontFamily: FONT_NAMES.SF_LIGHT,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_6),
    },
    boldText: {
      fontFamily: FONT_NAMES.SF_BOLD,
    },
    timeColor: {
      color: theme.color,
    },
    cancelButton: {
      borderRadius: FixedValue.CONSTANT_VALUE_46,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_84),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      alignItems: GlobalStyleValues.CENTER,
      justifyContent: GlobalStyleValues.CENTER,
      backgroundColor: COLORS.FDE4E1,
    },
    cancelButton1: {
      ...BASESTYLE.inCenter,
      borderRadius: FixedValue.CONSTANT_VALUE_46,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      backgroundColor: COLORS.FDE4E1,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_110),  
    },
    sendReminderBtn: {
      marginVertical: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      borderRadius: FixedValue.CONSTANT_VALUE_46,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_145),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      alignItems: GlobalStyleValues.CENTER,
      justifyContent: GlobalStyleValues.CENTER,
      backgroundColor: COLORS.E9EEFB,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    remindAllBtn: {
      borderRadius: FixedValue.CONSTANT_VALUE_46,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_110),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      alignItems: GlobalStyleValues.CENTER,
      justifyContent: GlobalStyleValues.CENTER,
      backgroundColor: COLORS.E9EEFB,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    cancelButtonText: {
      color: COLORS.F54336,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_15),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_4),
    },
    sendReminderBtnText: {
      color: COLORS._1F53D3,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_15),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_4),
    },
    buttonContainer: {
      flexDirection: GlobalStyleValues.ROW,
      justifyContent: GlobalStyleValues.CENTER,
      alignItems: GlobalStyleValues.CENTER,
    },
    rowContainer: {
      ...BASESTYLE.inCenter,
      flexDirection: GlobalStyleValues.ROW,
    },
    underLineView: {
      flexDirection: GlobalStyleValues.COLUMN,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_1),
      backgroundColor: COLORS.LIGHTGRAY,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    emptyView: {
      marginHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_28),
    },
    scrollviewStyle: {
      ...BASESTYLE.flex1,
      justifyContent: GlobalStyleValues.CENTER,
    },
    placeholder: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_38),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_38),
      borderRadius: FixedValue.CONSTANT_VALUE_4,
      backgroundColor: theme.color,
      justifyContent: GlobalStyleValues.CENTER,
      alignItems: GlobalStyleValues.CENTER,
    },
    initials: {
      color: theme.backgroundColor,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    container: {
      marginTop: Percentage.PRECENTAGE_70,
      flex: FixedValue.CONSTANT_VALUE_1,
      justifyContent: GlobalStyleValues.CENTER,
      alignItems: GlobalStyleValues.CENTER,
      height: Percentage.PRECENTAGE_100,
    },
    text: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: theme.color,
    },
    imageContainer: {
      ...BASESTYLE.flex1,
      backgroundColor: COLORS.E9EEFB,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      overflow: GlobalStyleValues.HIDDEN,
    },
    imageLoader: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.center,
      ...BASESTYLE.alignCenter,
      backgroundColor: COLORS.E9EEFB,
      overflow: GlobalStyleValues.HIDDEN,
    },
    container2: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      flexDirection: GlobalStyleValues.ROW,
    },
    containerCanccelAll: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      flexDirection: GlobalStyleValues.ROW,
    },
    badge: {
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      paddingHorizontal: scaleHeight(FixedValue.CONSTANT_VALUE_7),
      paddingVertical: scaleHeight(FixedValue.CONSTANT_VALUE_3),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    badgeText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_15),
      color: COLORS.BLACK,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONTS.SF_MEDIUM,
    },
    Accepted: {
      backgroundColor: COLORS._E6F3E5,
    },
    Pending: {
      backgroundColor: COLORS._FEFAE6,
    },
    Rejected: {
      backgroundColor: COLORS.FDE4E1,
    },
    tutorialContainer: {
      flexDirection: GlobalStyleValues.ROW,
      flex: FixedValue.CONSTANT_VALUE_1,
    },
  });
