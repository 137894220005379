import React from 'react';
import {View} from 'react-native';

import PwdValidationOption from './pwdValidationOption';
import {BASESTYLE, FixedValue, TEXT} from '../../constants';
import {ICreatePwdProps} from '../../types/componentTypes/createPwdType';
import {
  hasUpperCase,
  has8Character,
  hasNumber,
  hasLowerCase,
} from '../../utils/helperFunctions';
import {styles} from './smallDesign.style';

const PwdValidation = (props: ICreatePwdProps): JSX.Element => {
  const {Password} = props;
  const style = styles();

  return (
    <>
      <View style={BASESTYLE.inRow}>
        <PwdValidationOption
          isValid={hasUpperCase(Password)}
          label={TEXT.UPPERCASE}
          count={FixedValue.CONSTANT_VALUE_1}
        />
        <PwdValidationOption
          isValid={hasLowerCase(Password)}
          label={TEXT.LOWERCASE}
          count={FixedValue.CONSTANT_VALUE_1}
        />
        <PwdValidationOption
          isValid={hasNumber(Password)}
          label={TEXT.NUMBER}
          count={FixedValue.CONSTANT_VALUE_1}
        />
      </View>
      <View style={[BASESTYLE.inRow, style.marginTopFive]}>
        <PwdValidationOption
          isValid={has8Character(Password)}
          label={TEXT.CHARACTERS}
          count={FixedValue.CONSTANT_VALUE_8}
        />
      </View>
    </>
  );
};

export default React.memo(PwdValidation);
