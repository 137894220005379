/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef} from 'react';
import {useAppDispatch, useAppSelector} from '../reduxHooks';
import {_ToastHandler} from '../../utils/helperFunctions';
import {
  onTopCreditPullbackStart,
  onTransferTopCreditStart,
  onUserPlanPurchaseStart,
  onWalletTransactionHistoryStart,
  userProfileStore,
} from '../../redux/reducers/userProfileSlice';
import {ProfileTabContext} from '../../contextAPI/profileTabContext';
import {useFormik} from 'formik';
import {TopCreditTransferSchema} from '../../utils/formikSchema';

export type TopCreditTransferField = {topCredit: string};
import {FixedValue} from '../../constants';

const useWalletHooks = () => {
  const dispatch = useAppDispatch();
  const {
    selectedPlan,
    selectedChild,
    setTransferTopCreditAmount,
    setOpenTopCreditTransferModal,
    setIsUserWantToTransferTopCredit,
    isUserWantToTransferTopCredit,
    setTransactionId,
  } = React.useContext(ProfileTabContext);
  const {walletTrasactionList, userData} = useAppSelector(userProfileStore);
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
    isValid,
  } = useFormik({
    validationSchema: TopCreditTransferSchema,
    initialErrors: {
      topCredit: '',
    },
    initialTouched: {
      topCredit: false,
    },
    initialValues: {topCredit: ''},
    onSubmit: val => {
      checkEnterAmountIsLessThanWalletBalance(val.topCredit);
    },
  });
  const fetchTransactionHistoryList = React.useCallback(
    (page: number): void => {
      dispatch(
        onWalletTransactionHistoryStart({
          page,
          size: FixedValue.CONSTANT_VALUE_15,
        })
      );
    },
    []
  );

  const onTransactionHistoryReachedEnd = React.useCallback((): void => {
    if (walletTrasactionList.success) {
      const nextPage =
        walletTrasactionList.success.pageNumber + FixedValue.CONSTANT_VALUE_1;
      if (nextPage < walletTrasactionList.success.totalPages)
        fetchTransactionHistoryList(nextPage);
    }
  }, [walletTrasactionList]);

  const selectedPlanRef = useRef(selectedPlan);
  React.useEffect(() => {
    selectedPlanRef.current = selectedPlan;
  }, [selectedPlan]);

  const planPurchaseAPI = React.useCallback((): void => {
    dispatch(onUserPlanPurchaseStart({packId: selectedPlan?.id}));
  }, [dispatch, selectedPlan]);
  const checkEnterAmountIsLessThanWalletBalance = React.useCallback(
    (val: string): void => {
      const amount = Number(val.trim());
      const walletAmount = isUserWantToTransferTopCredit
        ? userData?.success?.walletAmount || FixedValue.CONSTANT_VALUE_0
        : selectedChild?.walletAmount || FixedValue.CONSTANT_VALUE_0;
      if (walletAmount >= amount) {
        setTransferTopCreditAmount(amount);
        if (isUserWantToTransferTopCredit) {
          dispatch(
            onTransferTopCreditStart({
              childId: selectedChild?.id,
              amount: amount,
            })
          );
        } else {
          dispatch(
            onTopCreditPullbackStart({
              childId: selectedChild?.id,
              amount: amount,
            })
          );
        }
      } else {
        alert('Entered amount should be less than wallet balance.');
      }
    },
    [
      userData?.success?.walletAmount,
      selectedChild,
      isUserWantToTransferTopCredit,
    ]
  );

  const onClickPullBackTopCredit = React.useCallback((val: number): void => {
    setOpenTopCreditTransferModal(true);
    setIsUserWantToTransferTopCredit(false);
    setTransactionId(val);
  }, []);

  return {
    planPurchaseAPI,
    fetchTransactionHistoryList,
    onTransactionHistoryReachedEnd,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
    isValid,
    onClickPullBackTopCredit,
  };
};

export default useWalletHooks;
