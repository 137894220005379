import React from 'react';
import ModalButton from '../../components/button/modalButton';
import {COLORS, FixedValue, TEXT} from '../../constants';
import RedIcon from '../../components/renderSvgs/redCross';
import DeleteIcon from '../../components/renderSvgs/deleteBin';
import {ThemeContext} from '../../contextAPI/themeContext';
import CancelButton from '../../components/button/cancelButton';
import {Text, View} from 'react-native';
import {offerCancelModalStyle} from './styles';
import CustomButton from '../../components/button/customButton';
import PrimaryButton from '../../components/button/primaryButton';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import {TradeContext} from '../../contextAPI/tradeContext';
import useTradeReceviedOrSentHooks from '../../hooks/componentHooks/useTradeReceviedOrSentHooks';

const OfferCancelModalContent = () => {
  const {callCancelOfferAPI} = useTradeReceviedOrSentHooks();
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = offerCancelModalStyle(ColorTheme);

  const {setOpenCancelModal, selectedItem} = React.useContext(TradeContext);
  const handelCancelOption = () => {
    setOpenCancelModal(false);
  };
  const callCancelAPI = () => {
    setOpenCancelModal(false);
    callCancelOfferAPI(selectedItem?.id ?? 0)
  };

  return (
    <>
      <Text
        style={[
          styles.headingText,
          {marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_15)},
        ]}
      >
        {TEXT.MORE}
      </Text>
      <ModalButton LeftIcon={RedIcon} label={TEXT.CANCEL_OFFER} onPress={()=> callCancelAPI()} 
        isDelete={true} />
      <CancelButton label={TEXT.CANCEL} onPress={handelCancelOption} />
    </>
  );
};

export default React.memo(OfferCancelModalContent);
