import React from 'react';
import {View} from 'react-native';
import CameraImage from '../../components/renderSvgs/cameraImage';
import UploadGalleryImage from '../../components/renderSvgs/uploadGalleryImage';
import DeleteIcon from '../../components/renderSvgs/deleteBin';
import {FixedValue, TEXT} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import CustomBottomListModal from '../../modals/customBottomListModal';
import MyGroupList from './myGroupList';
import {myGroupListStyle} from './myGroupList.style';
import {isWebsite, scaleHeight, scaleWidth} from '../../utils/responsive';
import {GroupContext} from '../../contextAPI/groupManagementContext';
import useCreateEditGroupHooks from '../../hooks/componentHooks/useCreateEditGroupHooks';
import CustomModalForSelectUser from '../../modals/customModalForSelectUser';
import useGroupManagementListHooks from '../../hooks/componentHooks/useGroupManagementListHooks';
import {useAppSelector} from '../../hooks/reduxHooks';
import {groupManagementStore} from '../../redux/reducers/groupManagementSlice';
import {CREATE_GROUP_MANAGEMENT} from '../../constants/actionTypes';
import {_ToastHandler} from '../../utils/helperFunctions';

const MyGroupListScreen = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = myGroupListStyle(ColorTheme);
  const iconHeight = scaleHeight(FixedValue.CONSTANT_VALUE_18);
  const iconHWidth = scaleWidth(FixedValue.CONSTANT_VALUE_18);
  const {callMyGroupListAPI} = useGroupManagementListHooks();
  const {
    openCloseProfileModal,
    userListResult,
    searchUserListText,
    selectedUsers,
    selectUserListModal,
    groupProfileImages,
    initialLoader,
    setOpenCloseProfileModal,
    setOpenCreateGroupModal,
    selectOrUnSelectUser,
    setSelectUserListModal,
  } = React.useContext(GroupContext);
  const {
    _removeImage,
    _takePicture,
    _onChangeSearchText,
    onReachedEnd,
    callAddUserToGroupAPI,
    callUserListAPI,
    _openGallery,
  } = useCreateEditGroupHooks();
  const {createNewGroup, userAddInGroup} = useAppSelector(groupManagementStore);

  const listData = [
    {
      LeftIcon: UploadGalleryImage,
      label: TEXT.UPLOAD_A_PHOTO,
      onPress: _openGallery,
      isDelete: false,
    },
    {
      LeftIcon: CameraImage,
      label: TEXT.TAKE_A_PHOTO,
      onPress: _takePicture,
      isDelete: false,
    },
  ];

  if (isWebsite()) {
    listData.splice(FixedValue.CONSTANT_VALUE_1, FixedValue.CONSTANT_VALUE_1);
  }

  const listDataWithDelete = [
    ...listData,
    {
      LeftIcon: DeleteIcon,
      label: TEXT.REMOVE,
      onPress: _removeImage,
      isDelete: true,
    },
  ];

  const openGroupcreateModal = () => {
    setOpenCreateGroupModal();
    setOpenCloseProfileModal();
  };

  const customModaldata = {
    modalHeaderName: TEXT.PROFILE_IMAGE,
    height: iconHeight,
    width: iconHWidth,
    closeModal: openGroupcreateModal,
    modalVisible: openCloseProfileModal,
    listData: groupProfileImages ? listDataWithDelete : listData,
  };

  const customUserSelectModaldata = {
    modalHeaderName: TEXT.ADD_USERS,
    modalVisible: selectUserListModal,
    value: searchUserListText,
    searchPlaceholder: TEXT.SEARCH_USERS,
    listData: userListResult,
    buttonLoader: userAddInGroup?.isLoading,
    closeModal: setSelectUserListModal,
    onChangeText: _onChangeSearchText,
    onSelectOrUnSelectUser: selectOrUnSelectUser,
    callAddUserToGroupAPI,
    selectedUsers,
    onReachedEnd,
    initialLoader,
  };
  React.useEffect(() => {
    callMyGroupListAPI(FixedValue.CONSTANT_VALUE_0);
  }, []);

  React.useEffect(() => {
    switch (createNewGroup.status) {
      case CREATE_GROUP_MANAGEMENT.SUCCESS:
        callMyGroupListAPI(FixedValue.CONSTANT_VALUE_0);
        callUserListAPI(searchUserListText, FixedValue.CONSTANT_VALUE_0);
        break;
      default:
        break;
    }
  }, [createNewGroup.status]);

  return (
    <View style={styles.listMainView}>
      <MyGroupList />
      {openCloseProfileModal && (
        <CustomBottomListModal customModaldata={customModaldata} />
      )}
      {selectUserListModal && (
        <CustomModalForSelectUser
          // @ts-ignore
          customModaldata={customUserSelectModaldata}
        />
      )}
    </View>
  );
};

export default React.memo(MyGroupListScreen);
