import React from 'react';
import {View, Text, TextInput, ViewStyle} from 'react-native';

import {ILabelInput} from '../../types/componentTypes/inputTypes';
import {COLORS, FixedValue, GlobalStyleValues, TEXT} from '../../constants';
import {styles} from './Input.style';
import {ThemeContext} from '../../contextAPI/themeContext';

const NoteInput = (props: ILabelInput): JSX.Element => {
  const {haveError, value} = props;
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  const isError: boolean =
    haveError && haveError.length > FixedValue.CONSTANT_VALUE_0 ? true : false;
  const nonFocusStyle: ViewStyle = isError
    ? style.errorBorder
    : style.defaultBorder;
  const borderStyle: ViewStyle =
    isFocused || value.length > FixedValue.CONSTANT_VALUE_0
      ? style.focusBorder
      : nonFocusStyle;

  const onFocused = React.useCallback((): void => setIsFocused(true), []);

  return (
    <View style={[style.mainContainer, props.containerStyle]}>
      {props?.inputLabel && (
        <Text style={style.labelText}>{props.inputLabel}</Text>
      )}
      <View style={[style.noteViewStyle, borderStyle]}>
        <TextInput
          placeholder={props.placeholder ?? ''}
          placeholderTextColor={ColorTheme.placeHolderColor}
          value={props.value}
          onChangeText={props.onChangeText}
          underlineColorAndroid={COLORS.TRANSPARENT}
          style={[style.inputStyle, style.noteStyle]}
          multiline
          autoCorrect={false}
          editable={props.editable}
          maxLength={props.maxLength}
          onBlur={props.onBlur}
          onFocus={onFocused}
          textAlignVertical={GlobalStyleValues.TOP}
        />
      </View>
      {isError && (
        <Text
          style={[
            props?.inputLabel === TEXT.FIRST_NAME ||
            props?.inputLabel === TEXT.LAST_NAME
              ? style.errorContainer
              : null,
            style.errorText,
          ]}
        >
          {haveError}
        </Text>
      )}
    </View>
  );
};

export default React.memo(NoteInput);
