import React from 'react';
import {onUserChildListStart, onUserChildProfileStart} from '../../redux/reducers/userProfileSlice';
import {useAppDispatch} from '../reduxHooks';

const useChildListHooks = () => {
  const dispatch = useAppDispatch();
  const callParentChildListAPI = React.useCallback((): void => {
    dispatch(onUserChildListStart());
  }, []);

  // Get Child Profile based on childID
  const callChildProfileAPI = React.useCallback((userId: number): void => {
     dispatch(onUserChildProfileStart(userId));
  }, []);
  return {
    callParentChildListAPI,
    callChildProfileAPI,
  };
};

export default useChildListHooks;
