import * as React from 'react';
import Svg, {SvgProps, Rect, Path} from 'react-native-svg';
const BlueCheck = (props: SvgProps) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Rect x={2} y={2} width={20} height={20} rx={4} fill="#1F53D3" />
    <Path
      d="M10.5395 15.013C10.4177 14.9622 10.3071 14.8878 10.2141 14.7941L8.29409 12.8741C8.10579 12.6858 8 12.4304 8 12.1641C8 11.8978 8.10579 11.6424 8.29409 11.4541C8.4824 11.2658 8.73779 11.16 9.00409 11.16C9.27039 11.16 9.52579 11.2658 9.71409 11.4541L10.9241 12.6741L14.2941 9.29409C14.4824 9.10579 14.7378 9 15.0041 9C15.2704 9 15.5258 9.10579 15.7141 9.29409C15.9024 9.48239 16.0082 9.73779 16.0082 10.0041C16.0082 10.2704 15.9024 10.5258 15.7141 10.7141L11.6341 14.7941C11.5411 14.8878 11.4305 14.9622 11.3087 15.013C11.1868 15.0638 11.0561 15.0899 10.9241 15.0899C10.7921 15.0899 10.6614 15.0638 10.5395 15.013Z"
      fill="white"
    />
  </Svg>
);
export default BlueCheck;
