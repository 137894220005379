import moment from 'moment-timezone';
import {Platform} from 'react-native';
import {object, string, ref, number, mixed, TestFunction} from 'yup';

import {
  FixedValue,
  ResetPasswordSettings,
  ERROR_MESSAGES,
  PASSWORD_REGEX,
  TEXT,
} from '../constants';
import {ChangePasswordSettings} from '../constants/enum';
import {ALPHA_NUMBER, DOB_FORMAT, USERNAME_REGEX} from '../constants/regex';

export const LoginSchema = object().shape({
  Username: string()
    .required(ERROR_MESSAGES.REQUIRED_USERNAME)
    .min(FixedValue.CONSTANT_VALUE_1, ERROR_MESSAGES.ONE_CHARACTER)
    .max(FixedValue.CONSTANT_VALUE_30)
    .matches(USERNAME_REGEX, ERROR_MESSAGES.INVALID_USERNAME)
    .label(TEXT.USERNAME),
  Password: string()
    .required(ERROR_MESSAGES.REQUIRED_PASSWORD)
    .min(FixedValue.CONSTANT_VALUE_8, ERROR_MESSAGES.EIGHT_CHARACTER)
    .max(FixedValue.CONSTANT_VALUE_25)
    .matches(PASSWORD_REGEX, ERROR_MESSAGES.INVALID_PASSWORD)
    .label(TEXT.PASSWORD),
});

export const LostPwdSchema = object().shape({
  Username: string()
    .required(ERROR_MESSAGES.REQUIRED_USERNAME)
    .min(
      FixedValue.CONSTANT_VALUE_3,
      ERROR_MESSAGES.PLEASE_ENTER_THREE_CHARACTER
    )
    .max(FixedValue.CONSTANT_VALUE_30)
    .matches(USERNAME_REGEX, ERROR_MESSAGES.INVALID_USERNAME)
    .label(TEXT.USERNAME),
});

export const VerifyOtpSchema = object().shape({
  Code: string()
    .required()
    .min(FixedValue.CONSTANT_VALUE_6, ERROR_MESSAGES.INVALID_OTP)
    .label(TEXT.CODE),
});
export const ChangePwdScheme = object().shape({
  CurrentPassword: string()
    .required(ERROR_MESSAGES.REQUIRED_CURRENT_PASSWORD)
    .min(FixedValue.CONSTANT_VALUE_8, ERROR_MESSAGES.EIGHT_CHARACTER)
    .max(FixedValue.CONSTANT_VALUE_25)
    .matches(PASSWORD_REGEX, ERROR_MESSAGES.INVALID_PASSWORD)
    .label(ChangePasswordSettings.NEW_PASSWORD),
  NewPassword: string()
    .required(ERROR_MESSAGES.REQUIRED_NEW_PASSWORD)
    .min(FixedValue.CONSTANT_VALUE_8, ERROR_MESSAGES.EIGHT_CHARACTER)
    .max(FixedValue.CONSTANT_VALUE_25)
    .matches(PASSWORD_REGEX, ERROR_MESSAGES.INVALID_PASSWORD)
    .label(ChangePasswordSettings.NEW_PASSWORD),
  ConfirmPassword: string()
    .required(ERROR_MESSAGES.REQUIRED_CONFIRM_PASSWORD)
    .oneOf(
      [ref(ChangePasswordSettings.NEW_PASSWORD), null],
      ERROR_MESSAGES.PWD_NOT_MATCH
    )
    .label(ChangePasswordSettings.COMFIRM_PASSWORD),
});
export const CreateNewPwdScheme = object().shape({
  NewPassword: string()
    .required(ERROR_MESSAGES.REQUIRED_NEW_PASSWORD)
    .min(FixedValue.CONSTANT_VALUE_8, ERROR_MESSAGES.EIGHT_CHARACTER)
    .max(FixedValue.CONSTANT_VALUE_25)
    .matches(PASSWORD_REGEX, ERROR_MESSAGES.INVALID_PASSWORD)
    .label(ResetPasswordSettings.NEW_PASSWORD),
  ConfirmPassword: string()
    .required(ERROR_MESSAGES.REQUIRED_CONFIRM_PASSWORD)
    .oneOf(
      [ref(ResetPasswordSettings.NEW_PASSWORD), null],
      ERROR_MESSAGES.PWD_NOT_MATCH
    )
    .label(ResetPasswordSettings.COMFIRM_PASSWORD),
});

export const SignUpScheme = object().shape({
  Password: string()
    .required(ERROR_MESSAGES.REQUIRED_PASSWORD)
    .min(FixedValue.CONSTANT_VALUE_8, '')
    .max(FixedValue.CONSTANT_VALUE_25)
    .matches(PASSWORD_REGEX, '  ')
    .label(TEXT.PASSWORD),
  Username: string()
    .trim()
    .required(ERROR_MESSAGES.REQUIRED_USERNAME)
    .min(
      FixedValue.CONSTANT_VALUE_3,
      ERROR_MESSAGES.PLEASE_ENTER_THREE_CHARACTER
    )
    .matches(USERNAME_REGEX, ERROR_MESSAGES.INVALID_USERNAME)
    .max(FixedValue.CONSTANT_VALUE_30)
    .label(TEXT.USERNAME),
  Firstname: string()
    .trim()
    .required(ERROR_MESSAGES.REQUIRED_FIRST_NAME)
    .min(FixedValue.CONSTANT_VALUE_1, ERROR_MESSAGES.ONE_CHARACTER)
    .max(FixedValue.CONSTANT_VALUE_25)
    .label(TEXT.FIRST_NAME_),
  Lastname: string()
    .trim()
    .required(ERROR_MESSAGES.REQUIRED_LAST_NAME)
    .min(FixedValue.CONSTANT_VALUE_1, ERROR_MESSAGES.ONE_CHARACTER)
    .max(FixedValue.CONSTANT_VALUE_25)
    .label(TEXT.LAST_NAME_),
  Email: string()
    .trim()
    .required(ERROR_MESSAGES.REQUIRED_EMAIL)
    .email(ERROR_MESSAGES.INVALID_EMAIL)
    .label(TEXT.EMAIL),
  DateOfBirth: string()
    .required(ERROR_MESSAGES.REQUIRED_DOB)
    .test(
      ERROR_MESSAGES.INVALID_FORMAT_KEY,
      ERROR_MESSAGES.VALID_DOB_FORMAT,
      value => {
        if (Platform.OS !== 'web') {
          return true;
        } else {
          return Boolean(value?.match(DOB_FORMAT));
        }
      }
    )
    .test(
      ERROR_MESSAGES.INVALID_DATE_KEY,
      ERROR_MESSAGES.INVALID_DATE,
      value => {
        return moment(value).isValid();
      }
    )
    .test(ERROR_MESSAGES.MAX_DATE_KEY, ERROR_MESSAGES.MAX_DATE_ERROR, value => {
      return moment().diff(moment(value)) >= FixedValue.CONSTANT_VALUE_0;
    })
    .label(TEXT.DOB_),
  MobileNumber: string()
    .required(ERROR_MESSAGES.REQUIRED_PHONE)
    .min(FixedValue.CONSTANT_VALUE_10, ERROR_MESSAGES.INVALID_MOBILE)
    .label(TEXT.MOBILE_NUMBER),
  ParentUsername: string()
    .trim()
    .required(ERROR_MESSAGES.REQUIRED_PARENT_USERNAME)
    .min(FixedValue.CONSTANT_VALUE_1, ERROR_MESSAGES.ONE_CHARACTER)
    .max(FixedValue.CONSTANT_VALUE_25)
    .label(TEXT.PARENT_USERNAME),
  ParentEmail: string()
    .trim()
    .required(ERROR_MESSAGES.REQUIRED_EMAIL)
    .email(ERROR_MESSAGES.INVALID_EMAIL)
    .label(TEXT.PARENT_EMAIL),
  ParentUsername2: string()
    .trim()
    .notRequired()
    .min(FixedValue.CONSTANT_VALUE_1, ERROR_MESSAGES.ONE_CHARACTER)
    .max(FixedValue.CONSTANT_VALUE_25)
    .test(
      'unique-parent-username',
      ERROR_MESSAGES.DUPLICATE_USERNAME,
      function (value) {
        const {ParentUsername} = this.parent;
        return !value || value !== ParentUsername;
      }
    )
    .label(TEXT.PARENT_USERNAME_2),
  ParentEmail2: string()
    .trim()
    .notRequired()
    .email(ERROR_MESSAGES.INVALID_EMAIL)
    .label(TEXT.PARENT_EMAIL_2),
});

export const EditorSchema = object().shape({
  TopName: string()
    .required(ERROR_MESSAGES.REQUIRED_TOP_NAME)
    .min(
      FixedValue.CONSTANT_VALUE_3,
      ERROR_MESSAGES.PLEASE_ENTER_THREE_CHARACTER
    )
    .label(TEXT.TOP_NAME_),
  AddHashTag: string().label(TEXT.ADD_HASHTAG_),
  Note: string().label(TEXT.NOTE),
});

export const CreateCollectionSchema = object().shape({
  CollectionName: string()
    .required(ERROR_MESSAGES.REQUIRED_COLLECTION_NAME)
    .min(
      FixedValue.CONSTANT_VALUE_3,
      ERROR_MESSAGES.PLEASE_ENTER_THREE_CHARACTER
    )
    .max(FixedValue.CONSTANT_VALUE_30)
    .label(TEXT.COLLECTION_NAME_),
});

export const CreateEditGroupSchema = object().shape({
  groupName: string()
    .required(ERROR_MESSAGES.REQUIRED_GROUP_NAME)
    .min(
      FixedValue.CONSTANT_VALUE_3,
      ERROR_MESSAGES.PLEASE_ENTER_THREE_CHARACTER
    )
    .max(FixedValue.CONSTANT_VALUE_30)
    .label(TEXT.GROUP_NAME_),
  groupImage: string(),
});

export const UpdateProfileScheme = object().shape({
  Firstname: string()
    .trim()
    .required(ERROR_MESSAGES.REQUIRED_FIRST_NAME)
    .min(FixedValue.CONSTANT_VALUE_1, ERROR_MESSAGES.ONE_CHARACTER)
    .max(FixedValue.CONSTANT_VALUE_25)
    .label(TEXT.FIRST_NAME_),
  Lastname: string()
    .trim()
    .required(ERROR_MESSAGES.REQUIRED_LAST_NAME)
    .min(FixedValue.CONSTANT_VALUE_1, ERROR_MESSAGES.ONE_CHARACTER)
    .max(FixedValue.CONSTANT_VALUE_25)
    .label(TEXT.LAST_NAME_),
  Email: string()
    .trim()
    .required(ERROR_MESSAGES.REQUIRED_EMAIL)
    .email(ERROR_MESSAGES.INVALID_EMAIL)
    .label(TEXT.EMAIL),
  DateOfBirth: string().required().label(TEXT.DOB_),
  MobileNumber: string()
    .required(ERROR_MESSAGES.REQUIRED_PHONE)
    .min(FixedValue.CONSTANT_VALUE_10, ERROR_MESSAGES.INVALID_MOBILE)
    .label(TEXT.MOBILE_NUMBER),
});

export const ApllyCouponCodeSchema = object().shape({
  couponCode: string()
    .required(ERROR_MESSAGES.INVALID_COUPON_CODE)
    .min(
      FixedValue.CONSTANT_VALUE_3,
      ERROR_MESSAGES.PLEASE_ENTER_THREE_CHARACTER
    )
    .max(FixedValue.CONSTANT_VALUE_10)
    .matches(ALPHA_NUMBER, ERROR_MESSAGES.INVALID_COUPON_CODE)
    .label(TEXT.COUPON_CODE_),
});

export const AbuseReportSchema = object().shape({
  reportId: number().required(ERROR_MESSAGES.REQUIRED),
  description: string().when('reportId', {
    is: FixedValue.CONSTANT_VALUE_8, //if reportId is 8(user select other option) then desc is mandatory field
    then: schema =>
      schema
        .required(ERROR_MESSAGES.REQUIRED)
        .min(
          FixedValue.CONSTANT_VALUE_3,
          ERROR_MESSAGES.PLEASE_ENTER_THREE_CHARACTER
        )
        .trim()
        .label(TEXT.DESCRIPTION_),
    otherwise: schema => schema.min(FixedValue.CONSTANT_VALUE_0),
  }),
});

export const DistributeTopInvite = object().shape({
  email_mobile: mixed()
    .test(
      'is-valid',
      'Invalid email or mobile number',
      function (value: any) {
        // If value is not provided, return true to indicate it's valid
        if (!value) return true;

        // Convert value to string
        const valueAsString = String(value);

        // Check if the value is a valid email address
        const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(valueAsString);

        // Check if the value is a valid mobile number (10 digits or more)
        const isMobile = /^\d{10,}$/.test(valueAsString);

        // Return true if either email or mobile number is valid
        return isEmail || isMobile;
      } as TestFunction<any> // Adjusted TestFunction type
    )
    .required(ERROR_MESSAGES.REQUIRED)
    .label(TEXT.DISTRIBUTE_VIA_),
});

export const EmailSchema = object().shape({
  email_mobile: string()
    .trim()
    .required(ERROR_MESSAGES.REQUIRED_EMAIL)
    .email(ERROR_MESSAGES.INVALID_EMAIL)
    .label(TEXT.DISTRIBUTE_VIA_),
});

export const MobilNoSchema = object().shape({
  email_mobile: string()
    .required(ERROR_MESSAGES.REQUIRED_PHONE)
    .min(FixedValue.CONSTANT_VALUE_10, ERROR_MESSAGES.INVALID_MOBILE)
    .label(TEXT.DISTRIBUTE_VIA_),
});

export const ApllyReedemCodeSchema = object().shape({
  reedemCode: string()
    .required(ERROR_MESSAGES.INVALID_COUPON_CODE)
    .min(
      FixedValue.CONSTANT_VALUE_3,
      ERROR_MESSAGES.PLEASE_ENTER_THREE_CHARACTER
    )
    .max(FixedValue.CONSTANT_VALUE_6)
    .matches(ALPHA_NUMBER, ERROR_MESSAGES.INVALID_REEDEM_CODE)
    .label(TEXT.REEDEM_CODE_),
});

export const NotesSchema = object().shape({
  Note: string().label(TEXT.NOTE).required(ERROR_MESSAGES.REQUIRED),
});
export const TopCreditTransferSchema = object().shape({
  topCredit: string()
    .required(ERROR_MESSAGES.REQUIRED)
    .matches(/^\d+(\.\d{0,2})?$/, ERROR_MESSAGES.ONLY_NUMBERS_AND_TWO_DECIMALS) // Ensures only digits and up to two decimal places
    .matches(/^\S*$/, ERROR_MESSAGES.NO_SPACES) // Ensures no spaces are allowed
    .min(
      FixedValue.CONSTANT_VALUE_1,
      ERROR_MESSAGES.PLEASE_ENTER_THREE_CHARACTER
    )
    .max(FixedValue.CONSTANT_VALUE_10)
    .trim()
    .label(TEXT.TOP_CREDIT_),
});
