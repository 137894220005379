import React from 'react';
import {View} from 'react-native';
import TitleWithButton from '../../components/header/titleWithButton';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import TopTabBarLayout from '../../components/layout/topTabBarLayout';
import {FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {ISingleTabProps} from '../../types/componentTypes/layoutType';
import {groupMangementStyle} from './groupMainTabScreen.style';
import GroupInScreen from '../groupInScreen/index';
import MyGroupListScreen from '../myGroupsList/index';
import GroupInvitaions from '../groupInvitationsScreen/index';
import useGroupManagementListHooks from '../../hooks/componentHooks/useGroupManagementListHooks';
import {useIsFocused} from '@react-navigation/native';
import {GroupContext} from '../../contextAPI/groupManagementContext';
import AppLoader from '../../components/smallDesigns/appLoader';

const GroupManagementScreen = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = groupMangementStyle(ColorTheme);
  const {callGroupInvitationListAPI, callGroupInListAPI} =
    useGroupManagementListHooks();
  const {initialLoader, updateSelectedUserInGroup, setInitialLoader} =
    React.useContext(GroupContext);
  const isFocused = useIsFocused();
  const tabBarList: ISingleTabProps[] = [
    {
      routeName: ROUTE_NAME.MY_GROUP_SCREEN,
      component: MyGroupListScreen,
      tabBarName: TEXT.MY_GROUPS,
    },
    {
      routeName: ROUTE_NAME.GROUP_IN,
      component: GroupInScreen,
      tabBarName: TEXT.GROUPS_IN,
    },
    {
      routeName: ROUTE_NAME.GROUP_INVITATIONS,
      component: GroupInvitaions,
      tabBarName: TEXT.INVITATIONS,
    },
  ];
  React.useEffect(() => {
    setInitialLoader(true);
    callGroupInListAPI(FixedValue.CONSTANT_VALUE_0);
    callGroupInvitationListAPI(FixedValue.CONSTANT_VALUE_0);
  }, []);

  React.useEffect(() => {
    if (isFocused) {
      updateSelectedUserInGroup([]);
    }
  }, [isFocused]);

  return (
    <SafeAreaContainer>
      <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
        <View style={styles.headerView}>
          <TitleWithButton title={TEXT.GROUP_MANAGEMENT} />
        </View>
        {initialLoader && <AppLoader />}
        <TopTabBarLayout
          tabArray={tabBarList}
          customTabStyle={styles.tabBarStyle}
        />
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(GroupManagementScreen);
