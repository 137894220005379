import React from 'react';
import {Pressable, View} from 'react-native';
import ModalContainer from '../../components/layout/modalContainer';
import {deleteCollectionModalStyle} from './deleteCollectionModal.styles';
import {ThemeContext} from '../../contextAPI/themeContext';
import ModalBack from '../../components/renderSvgs/modalBack';
import DeleteImage from '../../components/renderSvgs/deleteBin';
import DeleteModalContent from './deleteModalContent';
import {BASESTYLE, COLORS, FixedValue} from '../../constants';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import {HomeContext} from '../../contextAPI/homeContext';

const DeleteCollectionModal = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = deleteCollectionModalStyle(ColorTheme);
  const {openDeleteCollectionModal, setOpenDeleteCollectionModal} =
    React.useContext(HomeContext);

  return (
    <ModalContainer
      visible={openDeleteCollectionModal}
      onPressOutside={setOpenDeleteCollectionModal}
    >
      <View style={styles.subContainer}>
        <View style={styles.crossImage}>
          <Pressable onPress={setOpenDeleteCollectionModal}>
            <ModalBack />
          </Pressable>
        </View>
        <View style={BASESTYLE.inCenter}>
          <View style={styles.deleteBinView}>
            <DeleteImage
              stroke={COLORS._FF3D3D}
              width={scaleWidth(FixedValue.CONSTANT_VALUE_21)}
              height={scaleHeight(FixedValue.CONSTANT_VALUE_24)}
            />
          </View>
          <DeleteModalContent />
        </View>
      </View>
    </ModalContainer>
  );
};

export default DeleteCollectionModal;
