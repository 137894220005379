import React, {useContext, useEffect} from 'react';
import {Keyboard, View} from 'react-native';
import {style} from './styles';
import {FlatList} from 'react-native';
import {BASESTYLE, FixedValue, GlobalStyleValues, TEXT} from '../../constants';
import {SearchTabContext} from '../../contextAPI/SearchTabBarContext';
import UserListComponent from '../userListComponent';
import {HomeContext} from '../../contextAPI/homeContext';
import {CustomUserListSearchModalListData} from '../../types/reducerTypes/searchTabStoreType';
import HeartIcon from '../renderSvgs/heartIcon';
import HeartFavoriteIcon from '../renderSvgs/heartFavoriteIcon';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import {ThemeContext} from '../../contextAPI/themeContext';
import useHomeHooks from '../../hooks/componentHooks/useHomeHooks';
import useFavouriteHooks from '../../hooks/componentHooks/useFavouriteHooks';
import {USER_MARK_UNMARK_FAVORITE} from '../../constants/actionTypes';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import {useAppSelector} from '../../hooks/reduxHooks';
import {useIsFocused} from '@react-navigation/native';
import {_ToastHandler} from '../../utils/helperFunctions';
import useSearchTabHooks from '../../hooks/componentHooks/useSearchTabHooks';

const SearchUserListComponent = (): JSX.Element => {
  const {searchText, userSearchResult, searchUserDetail, setSearchUserDetail} =
    useContext(SearchTabContext);
  const {ColorTheme} = React.useContext(ThemeContext);
  const styles = style(ColorTheme);
  const {addFavoriteAndUnFavorite, setFavoriteLoader} = useFavouriteHooks();
  const {userMarkAndUnmarkFavoriteData} = useAppSelector(userProfileStore);
  const isFocused = useIsFocused();
  const {callUserSearchListAPI, onUserSearchListReachedEnd} =
    useSearchTabHooks();
  const onPressFavAndUnFav = React.useCallback(
    (item: CustomUserListSearchModalListData): void => {
      setFavoriteLoader(true);
      setSearchUserDetail(item);
      const params = {
        favorite: !item?.isFavorite,
        userIds: [item.id ?? FixedValue.CONSTANT_VALUE_0],
      };
      addFavoriteAndUnFavorite(params);
    },
    []
  );

  const {searchUserListText, setInitialLoader, searchFavouriteUserListText} =
    React.useContext(HomeContext);
  const {callUserListSearchAPI} = useHomeHooks();
  const {getfavoriteUserList} = useFavouriteHooks();

  useEffect(() => {
    setInitialLoader(true);
    getfavoriteUserList(
      searchFavouriteUserListText,
      FixedValue.CONSTANT_VALUE_0
    );
    callUserListSearchAPI(searchUserListText, FixedValue.CONSTANT_VALUE_0);
  }, []);
  const getFavoriteIcon = (val: boolean) => {
    if (val) {
      return (
        <View style={styles.heartContainer}>
          <HeartFavoriteIcon
            height={scaleHeight(FixedValue.CONSTANT_VALUE_28)}
            width={scaleWidth(FixedValue.CONSTANT_VALUE_28)}
          />
        </View>
      );
    } else {
      return (
        <View style={styles.heartContainer}>
          <HeartIcon
            height={scaleHeight(FixedValue.CONSTANT_VALUE_34)}
            width={scaleWidth(FixedValue.CONSTANT_VALUE_34)}
          />
        </View>
      );
    }
  };

  useEffect(() => {
    if (isFocused) {
      switch (userMarkAndUnmarkFavoriteData.status) {
        case USER_MARK_UNMARK_FAVORITE.SUCCESS:
          const {firstName, lastName, isFavorite} = searchUserDetail ?? {};
          const firstText = isFavorite ? 'You have removed' : 'You have added';
          const fullName = firstName + ' ' + lastName;
          const favText = isFavorite
            ? 'From the favorites list.'
            : 'to the favorites list.';
          const toastMsg = `${firstText} "${fullName}" ${favText}`;
          callUserSearchListAPI(searchText, FixedValue.CONSTANT_VALUE_0);
          _ToastHandler(toastMsg, true);
          break;
        case USER_MARK_UNMARK_FAVORITE.FAILED:
          break;
      }
    }
  }, [userMarkAndUnmarkFavoriteData?.status]);

  const RenderTopsList = React.useMemo((): JSX.Element => {
    if (
      searchText.length > FixedValue.CONSTANT_VALUE_2 &&
      userSearchResult.length > FixedValue.CONSTANT_VALUE_0
    ) {
      return (
        <FlatList
          keyExtractor={(_, index) => `${TEXT.USER_LIST}-${index}`}
          data={userSearchResult}
          onEndReached={onUserSearchListReachedEnd}
          style={BASESTYLE.flex1}
          contentContainerStyle={styles.flatListContent}
          keyboardShouldPersistTaps={GlobalStyleValues.HANDLED}
          onEndReachedThreshold={FixedValue.CONSTANT_VALUE_03}
          showsVerticalScrollIndicator={false}
          scrollEnabled
          renderItem={({item}) => (
            <UserListComponent
              icon={getFavoriteIcon(item?.isFavorite ?? false)}
              isArrow
              item={item}
              onSelectOrUnSelectUser={() => onPressFavAndUnFav(item)}
            />
          )}
          onScrollBeginDrag={() => Keyboard.dismiss()}
        />
      );
    }
    return <View />;
  }, [searchText, userSearchResult]);

  return RenderTopsList;
};

export default React.memo(SearchUserListComponent);
