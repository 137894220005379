import { SEND_REMINDER_ALL } from './../../constants/actionTypes';
import {createSlice} from '@reduxjs/toolkit';
import {AppConst} from '../../constants';
import {RootState} from '../store';
import {SendReminderAllStateType} from '../../types/reducerTypes/authStoreTypes';
import {ResetAllState} from './logoutslice';

const {StoreConstants} = AppConst;

const initialState: SendReminderAllStateType = {
  sendReminderAll: {
    isLoading: false,
    failed: null,
    data: [],
    status: SEND_REMINDER_ALL.END,
    success: false,
  },
};

const SendReminderAllSlice = createSlice({
  initialState,
  name: StoreConstants.SEND_REMINDER_ALL,
  reducers: {
    onSendReminderAllStart: (state, _) => {
      state.sendReminderAll.isLoading = true;
      state.sendReminderAll.status = SEND_REMINDER_ALL.START;
      state.sendReminderAll.success = false;
    },
    onSendReminderAllSuccess: (state, action) => {
      state.sendReminderAll.data = action.payload?.results;
      state.sendReminderAll.status = SEND_REMINDER_ALL.SUCCESS;
      state.sendReminderAll.success = true;
    },
    onSendReminderAllFailed: (state, action) => {
      state.sendReminderAll.failed = action.payload;
      state.sendReminderAll.status = SEND_REMINDER_ALL.FAILED;
      state.sendReminderAll.success = false;
    },
    onSendReminderAllEnd: state => {
      state.sendReminderAll.isLoading = false;
      state.sendReminderAll.status = SEND_REMINDER_ALL.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  onSendReminderAllStart,
  onSendReminderAllSuccess,
  onSendReminderAllFailed,
  onSendReminderAllEnd,
} = SendReminderAllSlice.actions;
export default SendReminderAllSlice.reducer;
export const sendReminderAllState = (state: RootState) => state.sendReminderAll;
