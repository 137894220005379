import React, {useState, useEffect} from 'react';
import {
  BLOCK_USER_LIST_ACTION_API,
  CHILD_APPROVEL_REQUEST_LIST,
  GET_CHILD_LIST_DATA,
  WALLET_TRASACTION_HISTORY,
} from '../constants/actionTypes';
import {useAppSelector} from '../hooks/reduxHooks';
import {childProfileApproveStore} from '../redux/reducers/childApproveSlice';
import {userProfileStore} from '../redux/reducers/userProfileSlice';
import {
  IProfileTabContextType,
  IProfileTabProvider,
} from '../types/contextTypes/profileTabContextType';
import {ChildListModel} from '../types/reducerTypes/userProfileTypes';
import {_ToastHandler, getDateFormated} from '../utils/helperFunctions';
import {FixedValue} from '../constants';
import {
  ITransactionHistoryCustomizeData,
  ITransactionHistoryObject,
} from '../types/componentTypes/userProfileTypes';
import {PackListObject} from '../types/reducerTypes/searchTabStoreType';
// Create a context with initial values
const ProfileTabContext = React.createContext<IProfileTabContextType>({
  parentChildListData: [],
  setParentChildListData: _val => {},
  selectedChild: null,
  setSelectedChild: _val => {},
  profileTabPulldownRefresh: false,
  setProfileTabPulldownRefresh: _val => {},
  modalVisibleLogout: false,
  setModalVisibleLogout: _val => {},
  modalVisibleDeleteUser: false,
  setModalVisibleDeleteUser: _val => {},
  transactionHistory: [],
  setTransactionHistory: _val => {},
  transactionHistoryLoader: false,
  setTransactionHistoryLoader: _val => {},
  selectedPlan: null,
  setSelectedPlan: _val => {},
  blockUserListData: [],
  setBlockUserListData: _val => {},
  userBlockListLoader: false,
  setUserBlockListLoader: _val => {},
  userBlockListPullDownRefresh: false,
  setUserBlockListPullDownRefresh: _val => {},
  openTopCreditTransferModal: false,
  setOpenTopCreditTransferModal: _val => {},
  isUserWantToTransferTopCredit: false,
  setIsUserWantToTransferTopCredit: _val => {},
  transferTopCreditAmount: null,
  setTransferTopCreditAmount: _val => {},
  transactionId: null,
  setTransactionId: _val => {},
});
// Define a provider for the profile tab context
const ProfileTabProvider = (props: IProfileTabProvider): JSX.Element => {
  // Get the create and update profile tab actions from the redux store
  const {childListData, walletTrasactionList, blockUserData} =
    useAppSelector(userProfileStore);
  // Initialize the state variables
  const [parentChildListData, setParentChildListData] = React.useState<
    ChildListModel[] | null
  >(null);
  const [selectedChild, setSelectedChild] =
    React.useState<ChildListModel | null>(null);
  const [profileTabPulldownRefresh, setProfileTabPulldownRefresh] =
    React.useState<boolean>(false);
  const {childApproveOrReject} = useAppSelector(childProfileApproveStore);
  const [modalVisibleLogout, setModalVisibleLogout] =
    React.useState<boolean>(false);
  const [modalVisibleDeleteUser, setModalVisibleDeleteUser] =
    React.useState<boolean>(false);
  const [transactionHistory, setTransactionHistory] = useState<
    ITransactionHistoryCustomizeData[] | []
  >([]);
  const [transactionHistoryLoader, setTransactionHistoryLoader] =
    React.useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<PackListObject | null>(null);
  const [ExtraData, setExtraData] = useState<ITransactionHistoryObject[]>([]);
  const [blockUserListData, setBlockUserListData] = useState<
    ChildListModel[] | []
  >([]);
  const [userBlockListLoader, setUserBlockListLoader] =
    React.useState<boolean>(false);
  const [userBlockListPullDownRefresh, setUserBlockListPullDownRefresh] =
    React.useState<boolean>(false);
    const [openTopCreditTransferModal, setOpenTopCreditTransferModal] =
    React.useState<boolean>(false);
  const [isUserWantToTransferTopCredit, setIsUserWantToTransferTopCredit] =
    React.useState<boolean>(true);
  const [transferTopCreditAmount, setTransferTopCreditAmount] = React.useState<
    number | null
  >(null);
  const [transactionId, setTransactionId] = React.useState<number | null>(null);

  const _setSelectedChildData = React.useCallback(
    (NewData: ChildListModel | null): void => {
      setSelectedChild(NewData);
    },
    [selectedChild]
  );

  const _setParentChildListData = React.useCallback(
    (data: ChildListModel[] | null): void => {
      setParentChildListData(data);
    },
    [parentChildListData]
  );

  const _setTransactionHistoryLoader = React.useCallback(
    (val: boolean): void => {
      setTransactionHistoryLoader(val);
    },
    [transactionHistoryLoader]
  );

  const _setSelectedPlan = React.useCallback(
    (value: PackListObject | null): void => {
      setSelectedPlan(value);
    },
    [selectedPlan]
  );

  //pulldown refresh variable setter function for profile tab
  const _setProfileTabPulldownRefresh = React.useCallback(
    (val: boolean): void => {
      setProfileTabPulldownRefresh(val);
    },
    [profileTabPulldownRefresh]
  );

  React.useEffect(() => {
    switch (childListData.status) {
      case GET_CHILD_LIST_DATA.SUCCESS:
        _setParentChildListData(childListData?.success?.results ?? []);
        _setProfileTabPulldownRefresh(false);
        break;
      case GET_CHILD_LIST_DATA.FAILED:
        _setProfileTabPulldownRefresh(false);
        break;
      default:
    }
  }, [childListData.status]);

  const _setTransactionHistory = React.useCallback(
    (NewData: ITransactionHistoryCustomizeData[]): void => {
      setTransactionHistory(prev => [...prev, ...NewData]);
    },
    [transactionHistory]
  );

  useEffect(() => {
    if (ExtraData?.length > 0) {
      const groupedData = ExtraData.reduce((result, item) => {
         // @ts-ignore
        const daysAgo = getDateFormated(item.createDate);
         // @ts-ignore
        const index = result.findIndex(r => r?.title === daysAgo);
        if (index === -1) {
           // @ts-ignore
          result.push({data: [{list: [{...item}]}], title: daysAgo});
        } else {
          result[index].data?.[0].list.push(item);
        }
        return result;
      }, []);
      setTransactionHistory(groupedData);
      setTransactionHistoryLoader(false);
    }
  }, [ExtraData]);

  useEffect(() => {
    switch (walletTrasactionList.status) {
      case WALLET_TRASACTION_HISTORY.SUCCESS:
        const listData = walletTrasactionList?.success?.data.results ?? [];
        if (
          walletTrasactionList?.success?.pageNumber ===
            FixedValue.CONSTANT_VALUE_0 &&
          listData?.length === FixedValue.CONSTANT_VALUE_0
        ) {
          setExtraData([]);
          setTransactionHistory([]);
        }
        if (
          walletTrasactionList?.success?.pageNumber ===
          FixedValue.CONSTANT_VALUE_0
        ) {
          if (listData?.length === FixedValue.CONSTANT_VALUE_0) {
            setExtraData([]);
            setTransactionHistory([]);
          } else if (listData?.length > FixedValue.CONSTANT_VALUE_0) {
            setExtraData(listData ?? []);
          }
        } else {
          setExtraData([...ExtraData, ...listData]);
        }
        setTimeout(() => {
          setTransactionHistoryLoader(false);
        }, 500);
        break;
      case WALLET_TRASACTION_HISTORY.FAILED:
        setTransactionHistoryLoader(false);
        break;
    }
  }, [walletTrasactionList.status]);

  //child profile approval response handle
  React.useEffect(() => {
    switch (childApproveOrReject.status) {
      case CHILD_APPROVEL_REQUEST_LIST.SUCCESS:
        _setProfileTabPulldownRefresh(false);
        break;
      case CHILD_APPROVEL_REQUEST_LIST.FAILED:
        _setProfileTabPulldownRefresh(false);
        break;
      default:
    }
  }, [childApproveOrReject.status]);

  const _setBlockUserListData = React.useCallback(
    (val: ChildListModel[]): void => {
      setBlockUserListData(val);
    },
    [blockUserListData]
  );

  const _setModalVisibleLogout = React.useCallback(
    (val: boolean): void => {
      setModalVisibleLogout(val);
    },
    [modalVisibleLogout]
  );

  const _setUserBlockListLoader = React.useCallback(
    (val: boolean): void => {
      setUserBlockListLoader(val);
    },
    [userBlockListLoader]
  );

  const _setUserBlockListPullDownRefresh = React.useCallback(
    (val: boolean): void => {
      setUserBlockListPullDownRefresh(val);
    },
    [userBlockListPullDownRefresh]
  );

  //block user list api response handle
  useEffect(() => {
    switch (blockUserData.status) {
      case BLOCK_USER_LIST_ACTION_API.SUCCESS:
        const listData = blockUserData?.success?.data?.results ?? [];
        if (
          blockUserData?.success?.pageNumber === FixedValue.CONSTANT_VALUE_0
        ) {
          setBlockUserListData([...listData]);
        } else {
          setBlockUserListData(prev => [...prev, ...listData]);
        }
        setUserBlockListLoader(false);
        setUserBlockListPullDownRefresh(false);
        break;
      case BLOCK_USER_LIST_ACTION_API.FAILED:
        setUserBlockListLoader(false);
        setUserBlockListPullDownRefresh(false);
        break;
    }
  }, [blockUserData?.status]);

  const _setOpenTopCreditTransferModal = React.useCallback(
    (val: boolean): void => {
      setOpenTopCreditTransferModal(val);
    },
    [openTopCreditTransferModal]
  );

  const _setIsUserWantToTransferTopCredit = React.useCallback(
    (val: boolean): void => {
      setIsUserWantToTransferTopCredit(val);
    },
    [isUserWantToTransferTopCredit]
  );

  const _setTransferTopCreditAmount = React.useCallback(
    (val: number | null): void => {
      setTransferTopCreditAmount(val);
    },
    [transferTopCreditAmount]
  );

  const _setTransactionId = React.useCallback(
    (val: number | null): void => {
      setTransactionId(val);
    },
    [transactionId]
  );
  // Memoize the context values using useMemo
  const values = React.useMemo(
    (): IProfileTabContextType => ({
      parentChildListData,
      setParentChildListData: _setParentChildListData,
      selectedChild,
      setSelectedChild: _setSelectedChildData,
      profileTabPulldownRefresh,
      setProfileTabPulldownRefresh: _setProfileTabPulldownRefresh,
      modalVisibleLogout,
      setModalVisibleLogout: _setModalVisibleLogout,
      modalVisibleDeleteUser,
      setModalVisibleDeleteUser,
      transactionHistory,
      setTransactionHistory: _setTransactionHistory,
      transactionHistoryLoader,
      setTransactionHistoryLoader: _setTransactionHistoryLoader,
      selectedPlan,
      setSelectedPlan: _setSelectedPlan,
      blockUserListData,
      setBlockUserListData: _setBlockUserListData,
      userBlockListLoader,
      setUserBlockListLoader: _setUserBlockListLoader,
      userBlockListPullDownRefresh,
      setUserBlockListPullDownRefresh: _setUserBlockListPullDownRefresh,
      openTopCreditTransferModal,
      setOpenTopCreditTransferModal: _setOpenTopCreditTransferModal,
      isUserWantToTransferTopCredit,
      setIsUserWantToTransferTopCredit: _setIsUserWantToTransferTopCredit,
      transferTopCreditAmount,
      setTransferTopCreditAmount: _setTransferTopCreditAmount,
      transactionId,
      setTransactionId: _setTransactionId,
    }),
    [
      parentChildListData,
      selectedChild,
      profileTabPulldownRefresh,
      modalVisibleLogout,
      modalVisibleDeleteUser,
      setModalVisibleDeleteUser,
      setSelectedChild,
      setParentChildListData,
      setProfileTabPulldownRefresh,
      setModalVisibleLogout,
      transactionHistory,
      setTransactionHistory,
      transactionHistoryLoader,
      setTransactionHistoryLoader,
      selectedPlan,
      setSelectedPlan,
      blockUserListData,
      setBlockUserListData,
      userBlockListLoader,
      setUserBlockListLoader,
      userBlockListPullDownRefresh,
      setUserBlockListPullDownRefresh,
      openTopCreditTransferModal,
      setOpenTopCreditTransferModal,
      isUserWantToTransferTopCredit,
      setIsUserWantToTransferTopCredit,
      transferTopCreditAmount,
      setTransferTopCreditAmount,
      transactionId,
      setTransactionId,
    ]
  );
  return (
    <ProfileTabContext.Provider value={values}>
      {props.children}
    </ProfileTabContext.Provider>
  );
};

export {ProfileTabContext, ProfileTabProvider};
