import React from 'react';
import {Pressable, Text, View} from 'react-native';
import {ThemeContext} from '../../../contextAPI/themeContext';
import Ellipse from '../../../components/renderSvgs/ellipse';
import SelectEllipse from '../../../components/renderSvgs/selectEllipse';
import {FixedValue, TEXT} from '../../../constants';
import {scaleHeight} from '../../../utils/responsive';
import CustomButton from '../../../components/button/customButton';
import {DistributingSettingStyle} from './style';
import {ChildProfileContext} from '../../../contextAPI/childProfileContext';
import useTradingApprovalHooks from '../../../hooks/componentHooks/useTradingApprovalHooks';
import {CHILD_PERMISSION, Percentage} from '../../../constants/enum';
import ConfirmationModal from '../../../modals/confirmationModal/confirmationModal';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { childProfileStore } from '../../../redux/reducers/childProfileSlice';

const DistributingSettingScreen = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = DistributingSettingStyle(ColorTheme);
  const {
    childUserData,
    showDistributeTopSettingChangePopUp,
    setShowDistributeTopSettingChangePopUp,
  } = React.useContext(ChildProfileContext);
  const tradePermission: string =
    childUserData?.permissionsStatus?.distributePermission?.permissionStatus ??
    '';
  const [tradeSettingApprovalChange, setTradeSettingApproval] =
    React.useState<string>(tradePermission);
  const {callDistributeTopSettingAPI} = useTradingApprovalHooks();
  const {distributeTopSettingApproval} = useAppSelector(childProfileStore);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const SettingOptions = [
    {text: TEXT.ALL_DISTRIBUTION_REQUEST, status: CHILD_PERMISSION.NOT_ALLOWED},
    {text: TEXT.MUST_APPROVE_REQUEST, status: CHILD_PERMISSION.ALLOWED},
    {
      text: TEXT.APPROVED_LIST,
      status: CHILD_PERMISSION.SPECIFIC_USERS,
    },
  ];

  React.useEffect(() => {
    setLoading(distributeTopSettingApproval.isLoading);
  }, [distributeTopSettingApproval.isLoading]);

  return (
    <View style={styles.settingContainer}>
      <View style={styles.selectView}>
        <Text style={styles.oneOption}>{TEXT.SELECT_ONE_OPTION}</Text>
      </View>
      {SettingOptions.map(item => (
        <Pressable
          onPress={() => setTradeSettingApproval(item.status)}
          key={item.status}
          style={styles.ellipseView}
        >
          <View style={{width: Percentage.PRECENTAGE_11_5}}>
            {item.status === tradeSettingApprovalChange ? (
              <SelectEllipse
                height={FixedValue.CONSTANT_VALUE_20}
                width={FixedValue.CONSTANT_VALUE_20}
              />
            ) : (
              <Ellipse
                height={FixedValue.CONSTANT_VALUE_20}
                width={FixedValue.CONSTANT_VALUE_20}
              />
            )}
          </View>
          <View style={{width: Percentage.PRECENTAGE_90}}>
            <Text style={styles.mustApprove}>{item.text}</Text>
          </View>
        </Pressable>
      ))}
      <View style={styles.buttonView}>
        <CustomButton
          buttonLabel={TEXT.SAVE}
          buttonwidth={styles.buttonStyle}
          labelStyle={styles.buttonLabelstyle}
          onPress={() => setShowDistributeTopSettingChangePopUp(true)}
        />
      </View>
      {showDistributeTopSettingChangePopUp && (
        <ConfirmationModal
          title={TEXT.CHANGE_DISTRIBUTE_TOP_SETTING}
          modalVisible={showDistributeTopSettingChangePopUp}
          onPressYes={() => {
            callDistributeTopSettingAPI(tradeSettingApprovalChange);
          }}
          onShowHide={() => setShowDistributeTopSettingChangePopUp(false)}
          isLoading={isLoading}
        />
      )}
    </View>
  );
};

export default React.memo(DistributingSettingScreen);
