import React from 'react';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {ParamListBase, useIsFocused} from '@react-navigation/native';

import {ROUTE_NAME, TabBarName} from '../constants';
import {isWebsite} from '../utils/responsive';

import CustomWebTab from './customWebTab';
import CustomMobileTab from './customMobileTab';

import MantelTopTab from '../screens/tabBar/mantelTopTab';
import TradeTab from '../screens/tabBar/tradeTab';
import SearchTab from '../screens/tabBar/searchTab';
import NotificationTab from '../screens/tabBar/notificationTab';
import ProfileTab from '../screens/tabBar/profileTab';
import {useAppDispatch} from '../hooks/reduxHooks';
import {onNotificationCountStart} from '../redux/reducers/notificationSlice';
import TrackingTab from '../screens/tabBar/trackingTab';

const Tab = createBottomTabNavigator<ParamListBase>();
const MyTabBar = isWebsite() ? CustomWebTab : CustomMobileTab;

const BottomNavigation = (): JSX.Element => {
  const isFocused = useIsFocused();
  const dispatch = useAppDispatch();

  React.useEffect((): void => {
    if (isFocused) dispatch(onNotificationCountStart());
  }, [isFocused]);

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarHideOnKeyboard: true,
        headerShown: false,
      }}
      tabBar={props => <MyTabBar {...props} />}
    >
      <Tab.Screen
        name={ROUTE_NAME.MENTAL_TAB}
        component={MantelTopTab}
        options={{title: TabBarName.MENTAL}}
      />
      <Tab.Screen
        name={ROUTE_NAME.TRADE_TAB}
        component={TradeTab}
        options={{title: TabBarName.TRADE}}
      />
      <Tab.Screen
        name={ROUTE_NAME.TRACKING_TAB}
        component={TrackingTab}
        options={{title: TabBarName.TRACKING}}
      />
      <Tab.Screen
        name={ROUTE_NAME.NOTIFICATION_TAB}
        component={NotificationTab}
        options={{title: TabBarName.NOTIFICATION}}
      />
      <Tab.Screen
        name={ROUTE_NAME.PROFILE_TAB}
        component={ProfileTab}
        options={{title: TabBarName.PROFILE}}
      />
    </Tab.Navigator>
  );
};

export default React.memo(BottomNavigation);
