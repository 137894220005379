import * as React from 'react';
import Svg, {SvgProps, Path, G} from 'react-native-svg';

const TrackingIcon = (props: SvgProps) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G id="fi:list">
      <Path
        id="Vector"
        d="M8 18H21"
        stroke={props.stroke ?? 'black'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        id="Vector_2"
        d="M3 18H3.01"
        stroke={props.stroke ?? 'black'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        id="Vector_3"
        d="M8 12H21"
        stroke={props.stroke ?? 'black'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        id="Vector_4"
        d="M3 12H3.01"
        stroke={props.stroke ?? 'black'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        id="Vector_5"
        d="M8 6H21"
        stroke={props.stroke ?? 'black'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        id="Vector_6"
        d="M3 6H3.01"
        stroke={props.stroke ?? 'black'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
  </Svg>
);
export default TrackingIcon;
