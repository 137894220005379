import React from 'react';
import {Text, View} from 'react-native';
import {RouteProp, useIsFocused, useRoute} from '@react-navigation/native';
import TitleWithButton from '../../components/header/titleWithButton';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import {HomeContext} from '../../contextAPI/homeContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import {capitalizeFirstLetter} from '../../utils/helperFunctions';
import AllTopsResultList from '../selectTopsForOffer/selectTopsList';
import {allTopsStyle} from '../seeAllTops/seeAll.styles';
import {RootStackParamList} from '../../types/navigationType';
import {FixedValue, TEXT} from '../../constants';
import PrimaryButton from '../../components/button/primaryButton';
import {TradeContext} from '../../contextAPI/tradeContext';
import useCreateTradeHooks from '../../hooks/componentHooks/useCreateTradeHooks';

let offerOtherNextClicked = false;

const SelectTopsOtherScreen = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const route =
    useRoute<RouteProp<RootStackParamList, 'SelectTopsForOfferOther'>>();
  const {collectionModel, showUncategorizedListId, userId} = route.params;
  const {styles, ids} = allTopsStyle(ColorTheme);
  const {seeAllScreenMode} = React.useContext(HomeContext);
  const {
    collectionSelectedTopsOther,
    setTotalTop,
    totalTops,
    setCollectionModel,
  } = React.useContext(TradeContext);
  const {navigateSelectTradeUser} = useCreateTradeHooks();
  const isFocused = useIsFocused();

  const getHeaderTitle = () =>
    `${collectionModel.name} (${collectionModel.totalTopsCount})`;

  const topsHeaderTitle: string | undefined = getHeaderTitle();

  const navigateScreen = () => {
    if (!offerOtherNextClicked) {
      offerOtherNextClicked = true;
      navigateSelectTradeUser(userId);
    }
  };

  React.useEffect(() => {
    if (isFocused) {
      const totalValue = collectionSelectedTopsOther.reduce(
        (total, obj) => obj.selectedQuantity! + total,
        FixedValue.CONSTANT_VALUE_0
      );
      setTotalTop(totalValue);
      setCollectionModel(collectionModel);
      offerOtherNextClicked = false;
    }
  }, [isFocused]);

  return (
    <View style={styles.container} dataSet={{media: ids.container}}>
      <View style={styles.topHeader}>
        <TitleWithButton title={capitalizeFirstLetter(topsHeaderTitle ?? '')} />
      </View>
      <AllTopsResultList
        showUncategorizedListId={showUncategorizedListId}
        collectionModel={collectionModel}
        displayMode={seeAllScreenMode}
        headerValue={''}
        userId={userId}
      />
      {collectionSelectedTopsOther.length > FixedValue.CONSTANT_VALUE_0 &&
        totalTops > FixedValue.CONSTANT_VALUE_0 && (
          <View style={styles.nextButtonView}>
            <PrimaryButton
              buttonLabel={TEXT.NEXT}
              buttonwidth={styles.nextButtonStyle}
              labelStyle={styles.nextButtonTextStyle}
              onPress={() => navigateScreen()}
              element={
                <Text style={styles.nextButtonTextStyle}>
                  {`Total: ${totalTops}`}
                </Text>
              }
            />
          </View>
        )}
    </View>
  );
};

const SelectTopsForOfferOther = (): JSX.Element => (
  <SafeAreaContainer>
    <SelectTopsOtherScreen />
  </SafeAreaContainer>
);

export default React.memo(SelectTopsForOfferOther);
