import React from 'react';
import {View, Text, Pressable, Image} from 'react-native';

import {ThemeContext} from '../../contextAPI/themeContext';
import useUserProfileHooks from '../../hooks/componentHooks/useUserProfileHooks';
import useAwsS3 from '../../hooks/libraryHooks/useAwsS3';
import {IProfilePicProps} from '../../types/componentTypes/smallDesignsType';
import EditPicImage from '../renderSvgs/editPicImage';
import {styles} from './smallDesign.style';
import {FixedValue} from '../../constants';

const ProfilePic = (props: IProfilePicProps): JSX.Element => {
  const {
    isUpdate,
    image,
    tempImage,
    text,
    mainViewStyle,
    initialsTextViewStyle,
    initialsTextStyle,
    renderTextViewStyle,
    renderImageViewStyle,
    renderImageStyle,
    loadingTextViewStyle,
  } = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  const {openUploadProfileModal} = useUserProfileHooks();
  const [profileImageUrl, setProfileImageUrl] = React.useState<
    string | undefined
  >();
  const {loadSignedImageUrl} = useAwsS3();
  const [_, setErrorInDecoding] = React.useState<boolean>();
  const [showBgColor, setShowBgColor] = React.useState<boolean>(false);

  const getSvgUrl = React.useCallback(async (): Promise<void> => {
    try {
      const profileImageUrl = await loadSignedImageUrl(image ?? '');
      if (profileImageUrl) {
        setProfileImageUrl(profileImageUrl);
      } else {
        setProfileImageUrl(undefined);
      }
    } catch (error) {
      console.log('Error fetching or decoding SVG image:', error);
      setErrorInDecoding(true);
    }
  }, [image]);

  const RenderImage = React.useMemo((): JSX.Element | undefined => {
    if (tempImage) {
      return (
        <View style={renderImageViewStyle ?? style.profileImage}>
          <Image
            source={{uri: tempImage}}
            style={renderImageStyle ?? style.profileImage}
            resizeMode="cover"
            key={tempImage}
          />
        </View>
      );
    } else if (profileImageUrl) {
      return (
        <View style={renderImageViewStyle ?? style.profileImage}>
          <Image
            source={{uri: profileImageUrl}}
            style={
              renderImageStyle ?? [
                style.profileImage,
                style.profileAbsolutePosition,
                showBgColor && {backgroundColor: ColorTheme.color},
              ]
            }
            onLoad={() => setShowBgColor(true)}
            resizeMode="cover"
            key={profileImageUrl}
          />
          <View style={loadingTextViewStyle ?? style.absoluteTextView}>
            <Text style={initialsTextStyle}>{text}</Text>
          </View>
        </View>
      );
    }
    // Return the placeholder block in case an error occurred
    return (
      <View style={renderTextViewStyle}>
        <Text style={initialsTextStyle}>{text}</Text>
      </View>
    );
  }, [profileImageUrl, image, showBgColor]);

  React.useEffect(() => {
    getSvgUrl();
  }, [image]);

  return (
    <View style={mainViewStyle}>
      {profileImageUrl ? (
        RenderImage
      ) : (
        <View style={initialsTextViewStyle}>
          <Text style={initialsTextStyle}>{text}</Text>
        </View>
      )}
      {isUpdate && (
        <View style={style.profilePicEditView}>
          <Pressable onPress={() => openUploadProfileModal()}>
            <EditPicImage />
          </Pressable>
        </View>
      )}
    </View>
  );
};

export default React.memo(ProfilePic);
