import React from 'react';
import {Pressable, View} from 'react-native';
import {COLORS, FixedValue} from '../../../../constants';

import {
  ShapeRefType,
  TopEditorControlsItemProps,
  TopEditorItemType,
} from '../../../../types/componentTypes/editorType';
import {scaleHeight, scaleWidth} from '../../../../utils/responsive';
import GreenTick from '../../../renderSvgs/greenTick';
import {styles} from './../../editorComp.style';
import BasicRectangle from './basicRectangle';

const RectangleControlItem = ({
  elementId,
  selectItemCallback,
  addItemCallback,
  newItemIndex,
  _isSelected,
}: TopEditorControlsItemProps): JSX.Element => {
  const _selectItemCallback = React.useCallback(
    (newItemIndex: number): void => {
      if (selectItemCallback) {
        selectItemCallback(newItemIndex);
      }
    },
    []
  );

  const addEditorItem = React.useCallback((): void => {
    const ref = React.createRef<ShapeRefType>();
    addItemCallback(
      <BasicRectangle
        ref={ref}
        key={`top-element-${newItemIndex}`}
        elementId={`top-element-${newItemIndex}`}
        selectItemCallback={_selectItemCallback}
        elementIndex={newItemIndex}
        itemPositionOverride={{itemType: TopEditorItemType.SHAPE}}
      />
    );
  }, [addItemCallback, newItemIndex, _selectItemCallback]);

  return (
    <Pressable
      testID={`TE-rectangle-control-item-selection-box`}
      accessibilityLabel={`TE-rectangle-control-item-selection-box`}
      key={`${elementId}-outer`}
      // @ts-ignore
      id={`${elementId}-outer`}
      onPress={addEditorItem}
    >
      <View
        testID={`TE-rectangle-control-item`}
        accessibilityLabel={`TE-rectangle-control-item`}
        key={elementId}
        // @ts-ignore
        id={elementId}
        style={styles.rectangularView}
      >
        {_isSelected && (
          <GreenTick
            stroke={COLORS.WHITE}
            width={scaleWidth(FixedValue.CONSTANT_VALUE_14)}
            height={scaleHeight(FixedValue.CONSTANT_VALUE_14)}
          />
        )}
      </View>
    </Pressable>
  );
};

export default RectangleControlItem;
