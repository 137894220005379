import React from 'react';
import {View} from 'react-native';
import {NotificationTabStyle} from './notification.style';
import {ThemeContext} from '../../../contextAPI/themeContext';
import NotificationTabHeader from '../../../components/header/notificationTabHeader/notification';
import SafeAreaContainer from '../../../components/layout/safeAreaContainer';
import ViewContainer from '../../../components/layout/viewContainer';
import {NotificationContext} from '../../../contextAPI/notificationContext';
import {TradeContext} from '../../../contextAPI/tradeContext';
import {ROUTE_NAME} from '../../../constants';
import {useIsFocused} from '@react-navigation/native';
import NotificationTabScreen from './notificationTabScreen';

const NotificationTab = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = NotificationTabStyle(ColorTheme);
  const {getUnReadCount} = React.useContext(NotificationContext);
  const isFocused = useIsFocused();
  const {setCurrentActiveTab} = React.useContext(TradeContext);

  React.useEffect((): void => {
    if (isFocused) {
      setCurrentActiveTab(ROUTE_NAME.RECEIVED_OFFER);
    }
  }, [isFocused]);

  return (
    <SafeAreaContainer>
      <ViewContainer>
        <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
          <NotificationTabHeader badgeCount={getUnReadCount} />
          <NotificationTabScreen />
        </View>
      </ViewContainer>
    </SafeAreaContainer>
  );
};

export default React.memo(NotificationTab);
