import React from 'react';
import {View, Text, FlatList, Pressable} from 'react-native';
import {
  Result,
  TopBuyingApprovalDetail,
} from '../../../types/reducerTypes/childApproveRequestStoreTypes';
import {ChildProfileContext} from '../../../contextAPI/childProfileContext';
import {
  checkTopCreditGreaterThanOne,
  getDaysAgo,
} from '../../../utils/helperFunctions';
import {FixedValue, TEXT} from '../../../constants';
import CustomButton from '../../button/customButton';
import TICKICON from '../../renderSvgs/tickIcon';
import CROSSICON from '../../renderSvgs/smallCrossIcon';
import {topBuyingApprovalTileStyle} from './styles';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {scaleWidth} from '../../../utils/responsive';
import RenderTopImage from '../renderTopImage';
import useSearchTabHooks from '../../../hooks/componentHooks/useSearchTabHooks';
import {TopType} from '../../../constants/enum';
import CircularView from '../../CircularView';

function TopBuyingApprovalTile(props: Result) {
  const {creationDate, tops, cartId, walletRequestResponse} = props;
  const {
    childUserData,
    setShowTopBuyingApprovalModal,
    setSelectDataForApproveOrReject,
    selectDataForApproveOrReject,
    setIsPaymentTypeIsCard,
  } = React.useContext(ChildProfileContext);
  const {ColorTheme} = React.useContext(ThemeContext);
  const styles = topBuyingApprovalTileStyle(ColorTheme);
  const {navigateToDetailScreen} = useSearchTabHooks();
  let totalAmount = FixedValue.CONSTANT_VALUE_0;
  tops?.forEach(item => {
    totalAmount = totalAmount + item.price;
  });
  const isCashPayment = tops?.[FixedValue.CONSTANT_VALUE_0]?.cash ?? false;
  const customData = React.useCallback(
    (item: TopBuyingApprovalDetail) => ({
      id: item.topId,
      image: item.topImage,
      name: item.topName,
      price: item.price,
      quantity: item.quantity,
      creationDate,
    }),
    []
  );

  const onPressApproveOrReject = React.useCallback(
    (openModal: boolean, isApprove: boolean): void => {
      setSelectDataForApproveOrReject({
        isApprove,
        selectedData: props,
      });
      setShowTopBuyingApprovalModal(openModal);
      setIsPaymentTypeIsCard(isCashPayment);
    },
    [selectDataForApproveOrReject, isCashPayment]
  );

  return (
    <View>
      <View style={styles.mainView}>
        <Text style={styles.firstName}>
          {childUserData?.firstName} {childUserData?.lastName}
        </Text>
        <Text style={styles.day}>{getDaysAgo(creationDate)}</Text>
      </View>
      <Text style={styles.amount}>
        {cartId
          ? TEXT.REQUEST_TO_PURCHASE_TOPS
          : TEXT.REQUEST_FOR_PACK_PURCHASE}
      </Text>
      {cartId ? (
        <Text style={styles.amount}>
          {TEXT.AMOUNT} : {isCashPayment ? `$${totalAmount}` : ''}
          {isCashPayment ? '' : checkTopCreditGreaterThanOne(totalAmount)}
        </Text>
      ) : (
        <Text style={styles.amount}>
          {TEXT.AMOUNT} ${walletRequestResponse?.amount}
        </Text>
      )}
      <FlatList
        horizontal={true}
        data={tops ?? []}
        keyExtractor={item => `Topid-${item.topId}`}
        renderItem={({item}) => {
          const result = customData(item);
          return (
            <View style={{marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_4)}}>
              <CircularView
                isSmall={true}
                quantity={result?.quantity?.toString() ?? '0'}
              />
              <Pressable
                onPress={() =>
                  navigateToDetailScreen(result, TopType.viewOffer)
                }
              >
                <RenderTopImage item={result} />
              </Pressable>
            </View>
          );
        }}
      />
      <View style={styles.buttonView}>
        <CustomButton
          buttonLabel={TEXT.APPROVE}
          buttonwidth={styles.primaryButtonStyle}
          labelStyle={styles.primaryButtonLabelstyle}
          buttonBackGroundColor={styles.noButtonBackGroundColor1}
          element={<TICKICON />}
          onPress={() => onPressApproveOrReject(true, true)}
        />
        <CustomButton
          buttonLabel={TEXT.REJECT}
          buttonwidth={styles.primaryButtonStyle2}
          labelStyle={styles.primaryButtonLabelstyle1}
          buttonBackGroundColor={styles.noButtonBackGroundColor}
          element={<CROSSICON />}
          onPress={() => onPressApproveOrReject(true, false)}
        />
      </View>
    </View>
  );
}

export default React.memo(TopBuyingApprovalTile);
