import React from 'react';
import {KeyboardAvoidingView, Platform, StyleSheet} from 'react-native';

import {BASESTYLE, COLORS, FixedValue} from '../../constants';
import {ILayoutProps} from '../../types/componentTypes/layoutType';

const styles = StyleSheet.create({
  container: {
    ...BASESTYLE.flex1,
    backgroundColor: COLORS.TRANSPARENT,
  },
});

const keyboardAvoid = (props: ILayoutProps) => {
  const {children} = props;
  return (
    <KeyboardAvoidingView
      style={styles.container}
      keyboardVerticalOffset={FixedValue.CONSTANT_VALUE_10}
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
    >
      {children}
    </KeyboardAvoidingView>
  );
};

export default React.memo(keyboardAvoid);
