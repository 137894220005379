import React from 'react';
import {FlatList, ScrollView, View} from 'react-native';
import TopsEmptyList from '../../components/emptyListView/topsListEmptyView';
import {BASESTYLE, FixedValue, TEXT} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {TopModel} from '../../types/reducerTypes/searchTabStoreType';
import {scaleHeight} from '../../utils/responsive';
import {allTopsStyle} from '../seeAllTops/seeAll.styles';
import CardTile from '../../components/RenderItemTile/cardTile';
import {HomeContext} from '../../contextAPI/homeContext';
import NoItemOffer from '../../components/renderSvgs/noItemOffer';

interface ISelectResultTopsListProps {
  setIskeyboardFocused: (val: boolean) => void;
}

const SelectResultTopsList = (
  props: ISelectResultTopsListProps
): JSX.Element => {
  const {setIskeyboardFocused} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = allTopsStyle(ColorTheme);
  const {
    selectOrUnSelectResultTop,
    updateTopResultQuantity,
    selectedTopsResult,
  } = React.useContext(HomeContext);

  const updateQuantity = (top: TopModel, val: string) => {
    if (top.selectedQuantity === FixedValue.CONSTANT_VALUE_1) {
      {
        val === TEXT.MINNUS
          ? selectOrUnSelectResultTop(top)
          : updateTopResultQuantity(top, val);
      }
    } else {
      updateTopResultQuantity(top, val);
    }
  };

  const onChangeUpdateQuantity = (top: TopModel, quantity: number) => {
    if (quantity) {
      updateTopResultQuantity(top, TEXT.MINNUS, quantity);
    } else {
      selectOrUnSelectResultTop(top);
    }
    setIskeyboardFocused(false);
  };

  const RenderList = React.useMemo((): JSX.Element | null => {
    if (selectedTopsResult?.length > FixedValue.CONSTANT_VALUE_0) {
      return (
        <FlatList
          data={selectedTopsResult}
          keyExtractor={(_, i) => `TopNumber-id:${_.id}atIndex${i}`}
          showsVerticalScrollIndicator={false}
          style={BASESTYLE.flex1}
          contentContainerStyle={{
            paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_75),
          }}
          renderItem={({item}) => (
            <CardTile
              key={item.id}
              topModel={item}
              onAddButtonTap={() => selectOrUnSelectResultTop(item)}
              onUpdateQuantityTap={val => {
                updateQuantity(item, val);
              }}
              isSelected={item.isSelected}
              isAmount
              isInputQuantity
              onChangeQuantity={(val: number) =>
                onChangeUpdateQuantity(item, val)
              }
              setIskeyboardFocused={setIskeyboardFocused}
            />
          )}
        />
      );
    }
    return null;
  }, [selectedTopsResult]);

  const RenderEmptyUI = React.useMemo((): JSX.Element | null => {
    if (selectedTopsResult?.length === FixedValue.CONSTANT_VALUE_0) {
      return (
        <ScrollView
          contentContainerStyle={BASESTYLE.flex1}
          style={BASESTYLE.flex1}
          showsVerticalScrollIndicator={false}
        >
          <TopsEmptyList
            placeHolder={<NoItemOffer />}
            emptyViewDesc={TEXT.PLEASE_ADD_ITEM}
            emptyViewTitle={TEXT.YOUR_CART_EMPTY}
          />
        </ScrollView>
      );
    }
    return null;
  }, [selectedTopsResult]);

  return (
    <View style={styles.contentContainer}>
      {RenderEmptyUI}
      {RenderList}
    </View>
  );
};

export default React.memo(SelectResultTopsList);
