import * as React from 'react';
import Svg, {Circle, Path, SvgProps} from 'react-native-svg';
const HeartIcon = (props: SvgProps) => (
  <Svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle opacity={0.35} cx={20} cy={20} r={20} fill="#E9EEFB" />
    <Path
      d="M29.8776 13.1157L29.878 13.1162C30.6823 14.1701 31.1153 15.4966 31.1153 16.9635C31.1153 19.0198 29.8831 21.3729 27.2674 23.9628C25.1434 26.0659 22.6231 27.796 21.3059 28.6427C20.9182 28.8906 20.4677 29.0223 20.0075 29.0223C19.5471 29.0223 19.0963 28.8904 18.7085 28.6423C17.3904 27.7957 14.8714 26.0658 12.7475 23.9628C10.1318 21.3717 8.89966 19.0198 8.89966 16.9635C8.89966 15.4966 9.33262 14.1701 10.1369 13.1162L10.1373 13.1157C10.9007 12.1128 11.9789 11.3956 13.1989 11.0793C14.4189 10.763 15.7098 10.8659 16.8643 11.3716L16.8643 11.3716L16.8677 11.3731C17.9026 11.8173 18.839 12.576 19.607 13.6036L20.007 14.1389L20.4076 13.604C21.1757 12.5788 22.1124 11.8197 23.1482 11.3727L23.1482 11.3727L23.1507 11.3716C24.3051 10.8659 25.596 10.763 26.816 11.0793C28.036 11.3956 29.1143 12.1128 29.8776 13.1157Z"
      stroke="black"
    />
  </Svg>
);
export default HeartIcon;
