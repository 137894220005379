import React from 'react';
import {View, Text} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {FixedValue, TEXT} from '../../constants';
import {TradeContext} from '../../contextAPI/tradeContext';
import CustomButton from '../../components/button/customButton';
import ModalContainer from '../../components/layout/modalContainer';
import useTradeReceviedOrSentHooks from '../../hooks/componentHooks/useTradeReceviedOrSentHooks';
import {cancleModalStyle} from './styles';
interface ModalProps {
  isCancel?: boolean;
}
const CancelModal = (props: ModalProps): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = cancleModalStyle(ColorTheme);
  const {cancelModal, setCancelModal, selectedItem, setOpenSentOfferModal} =
    React.useContext(TradeContext);
  const {callCancelOfferAPI} = useTradeReceviedOrSentHooks();
  const closeModal = () => {
    setOpenSentOfferModal();
    setCancelModal();
  };
  return (
    <ModalContainer visible={cancelModal} onPressOutside={setCancelModal}>
      <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
        <Text style={styles.wantDelete}>{TEXT.OFFER_CANCEL}</Text>
        <View style={styles.approveButton}>
          <CustomButton
            buttonLabel={TEXT.NO}
            buttonwidth={styles.primaryButtonStyle2}
            labelStyle={styles.primaryButtonLabelstyle1}
            buttonBackGroundColor={styles.noButtonBackGroundColor}
            onPress={() => {
              setCancelModal();
            }}
          />
          <CustomButton
            buttonLabel={TEXT.YES}
            buttonwidth={styles.primaryButtonStyle}
            labelStyle={styles.primaryButtonLabelstyle}
            onPress={() => {
              callCancelOfferAPI(
                selectedItem?.id ?? FixedValue.CONSTANT_VALUE_0
              );
              {
                props.isCancel ? closeModal() : setCancelModal();
              }
            }}
          />
        </View>
      </View>
    </ModalContainer>
  );
};

export default CancelModal;
