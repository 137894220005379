import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";
const TickWithCircle = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M18.8533 10.4527L12.7758 16.5443L10.4383 14.2068C10.3113 14.0585 10.1551 13.9381 9.97929 13.8531C9.80353 13.768 9.6121 13.7202 9.41699 13.7127C9.22189 13.7052 9.02734 13.738 8.84554 13.8093C8.66375 13.8805 8.49864 13.9885 8.36058 14.1266C8.22252 14.2646 8.11449 14.4297 8.04327 14.6115C7.97204 14.7933 7.93917 14.9879 7.9467 15.183C7.95424 15.3781 8.00202 15.5695 8.08706 15.7453C8.17209 15.921 8.29254 16.0773 8.44083 16.2043L11.77 19.5477C11.9024 19.679 12.0594 19.7828 12.232 19.8533C12.4046 19.9238 12.5894 19.9596 12.7758 19.9585C13.1475 19.9569 13.5036 19.8094 13.7675 19.5477L20.8508 12.4643C20.9836 12.3326 21.089 12.1759 21.1609 12.0033C21.2329 11.8307 21.2699 11.6455 21.2699 11.4585C21.2699 11.2715 21.2329 11.0863 21.1609 10.9137C21.089 10.741 20.9836 10.5844 20.8508 10.4527C20.5854 10.1888 20.2263 10.0407 19.8521 10.0407C19.4778 10.0407 19.1188 10.1888 18.8533 10.4527ZM15 0.833496C12.1981 0.833496 9.45912 1.66436 7.12942 3.22101C4.79973 4.77766 2.98395 6.99019 1.91171 9.57881C0.839466 12.1674 0.558919 15.0159 1.10554 17.7639C1.65217 20.512 3.00141 23.0363 4.98266 25.0175C6.9639 26.9988 9.48816 28.348 12.2362 28.8946C14.9843 29.4412 17.8327 29.1607 20.4214 28.0885C23.01 27.0162 25.2225 25.2004 26.7792 22.8707C28.3358 20.541 29.1667 17.8021 29.1667 15.0002C29.1667 13.1398 28.8002 11.2976 28.0883 9.57881C27.3764 7.86003 26.3328 6.29831 25.0173 4.98282C23.7019 3.66732 22.1401 2.62381 20.4214 1.91187C18.7026 1.19993 16.8604 0.833496 15 0.833496ZM15 26.3335C12.7585 26.3335 10.5673 25.6688 8.70354 24.4235C6.83978 23.1782 5.38716 21.4081 4.52937 19.3372C3.67157 17.2663 3.44714 14.9876 3.88444 12.7891C4.32173 10.5907 5.40113 8.57128 6.98612 6.98629C8.57112 5.40129 10.5905 4.3219 12.789 3.8846C14.9874 3.4473 17.2662 3.67174 19.3371 4.52953C21.408 5.38732 23.178 6.83994 24.4233 8.7037C25.6686 10.5675 26.3333 12.7586 26.3333 15.0002C26.3333 18.0059 25.1393 20.8886 23.0139 23.014C20.8885 25.1395 18.0058 26.3335 15 26.3335Z"
      fill="#4EAF51"
    />
  </Svg>
);
export default TickWithCircle;
