import {createSlice} from '@reduxjs/toolkit';
import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {ICreateCollectionType} from '../../types/reducerTypes/collectionStoreTypes';
import {ResetAllState} from './logoutslice';

const {StoreConstants} = AppConst;
const {CREATE_COLLECTION, UPDATE_COLLECTION} = ActionType;

const initialState: ICreateCollectionType = {
  createNewCollection: {
    isLoading: false,
    success: null,
    failed: null,
    status: CREATE_COLLECTION.END,
  },
  updateCollection: {
    isLoading: false,
    success: null,
    failed: null,
    status: UPDATE_COLLECTION.END,
  },
  moveToInProgress: false,
  moveToInCollection: false,
};

const CreateCollectionSlice = createSlice({
  initialState,
  name: StoreConstants.CREATE_COLLECTION,
  reducers: {
    onCreateCollectionStart: (state, _) => {
      state.createNewCollection.isLoading = true;
      state.createNewCollection.status = CREATE_COLLECTION.START;
    },
    onCreateCollectionSuccess: (state, action) => {
      state.createNewCollection.success = action.payload;
      state.createNewCollection.status = CREATE_COLLECTION.SUCCESS;
    },
    onCreateCollectionFailed: (state, action) => {
      state.createNewCollection.failed = action.payload;
      state.createNewCollection.status = CREATE_COLLECTION.FAILED;
    },
    onCreateCollectionEnd: state => {
      state.createNewCollection.isLoading = false;
      state.createNewCollection.status = CREATE_COLLECTION.END;
    },
    onUpdateCollectionStart: (state, _) => {
      state.updateCollection.isLoading = true;
      state.updateCollection.status = UPDATE_COLLECTION.START;
    },
    onUpdateCollectionSuccess: (state, action) => {
      state.updateCollection.success = action.payload;
      state.updateCollection.status = UPDATE_COLLECTION.SUCCESS;
    },
    onUpdateCollectionFailed: (state, action) => {
      state.updateCollection.failed = action.payload;
      state.updateCollection.status = UPDATE_COLLECTION.FAILED;
    },
    onUpdateCollectionEnd: state => {
      state.updateCollection.isLoading = false;
      state.updateCollection.status = UPDATE_COLLECTION.END;
    },
    activateInProgressTab: (state, action) => {
      state.moveToInProgress = action.payload;
    },
    activateInCollectionTab: (state, action) => {
      state.moveToInCollection = action.payload;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  onCreateCollectionStart,
  onCreateCollectionSuccess,
  onCreateCollectionFailed,
  onCreateCollectionEnd,
  onUpdateCollectionEnd,
  onUpdateCollectionFailed,
  onUpdateCollectionStart,
  onUpdateCollectionSuccess,
  activateInProgressTab,
  activateInCollectionTab,
} = CreateCollectionSlice.actions;
export default CreateCollectionSlice.reducer;
export const createCollectionStore = (state: RootState) =>
  state.createCollection;
