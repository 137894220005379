import React from 'react';
import {View, StatusBar} from 'react-native';
import {useFonts} from 'expo-font';
import {preventAutoHideAsync, hideAsync} from 'expo-splash-screen';
import FlashMessage from 'react-native-flash-message';
import {Amplify} from 'aws-amplify';
import {initializeApp} from 'firebase/app';

import {
  ROUTE_NAME,
  FONTS,
  statusBarContentType,
  themeTypes,
  GlobalStyleValues,
  FixedValue,
} from '../constants';
import {ThemeContext} from '../contextAPI/themeContext';
import {navigationStyle} from './navigation.styles';
import renderFlashMessageIcon from './../components/layout/topBanner';
import AMPLIFY_CONFIG from '../config/AmplifyConfig';
import {StorageConstants} from '../constants/appConst';
import {useAppDispatch} from '../hooks/reduxHooks';
import {getUserTokens} from '../redux/reducers/authSlice';
import {appStateName} from '../constants/enum';
import {getDataLocally} from '../utils/helperFunctions';
import {
  onUserDataSuccess,
  onUserDataEnd,
} from '../redux/reducers/userProfileSlice';
import {firebaseConfig} from '../config/firebaseConfig';
import NotificationService from '../services/notificationService';
import {isWebsite} from '../utils/responsive';

interface IUserAppState {
  setInitialRouteName: Function;
}

const UserAppState = (props: React.PropsWithChildren<IUserAppState>) => {
  const {children, setInitialRouteName} = props;
  preventAutoHideAsync();
  const {themeState, ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = navigationStyle(ColorTheme);
  const dispatch = useAppDispatch();
  const notificationService = NotificationService.getInstance();

  const [fontsLoaded] = useFonts({
    SF_BOLD: FONTS.SF_BOLD,
    SF_HEAVY: FONTS.SF_HEAVY,
    SF_LIGHT: FONTS.SF_LIGHT,
    SF_MEDIUM: FONTS.SF_MEDIUM,
    SF_REGULAR: FONTS.SF_REGULAR,
    SF_SEMIBOLD: FONTS.SF_SEMIBOLD,
    JosefinSans_400Regular: FONTS.JosefinSans_400Regular,
    Montserrat_400Regular: FONTS.Montserrat_400Regular,
    Roboto_400Regular: FONTS.Roboto_400Regular,
    Pacifico_400Regular: FONTS.Pacifico_400Regular,
    BebasNeue_400Regular: FONTS.BebasNeue_400Regular,
    Itim_400Regular: FONTS.Itim_400Regular,
  });

  const userAppState = React.useCallback(async (): Promise<void> => {
    const userState = await getDataLocally(StorageConstants.USER_APP_STATE);
    switch (userState) {
      case appStateName.AUTHENTICATION:
        setInitialRouteName(ROUTE_NAME.LOGIN_SCREEN);
        break;
      case appStateName.INSIDE_APP:
        const result = await getDataLocally(StorageConstants.USER_TOKENS);
        const userProfile = await getDataLocally(
          StorageConstants.SWITCH_USER_DETAIL
        );
        if (result != null && typeof result === 'object') {
          dispatch(getUserTokens(result));
        }
        if (userProfile != null) {
          dispatch(onUserDataSuccess(userProfile));
        }
        setInitialRouteName(ROUTE_NAME.BOTTOM_TAB);
        dispatch(onUserDataEnd());
        break;
      case appStateName.INITIAL:
      default:
        setInitialRouteName(ROUTE_NAME.WELCOME_SCREEN);
        break;
    }
  }, []);

  const initializeThing = React.useCallback((): void => {
    Amplify.configure(AMPLIFY_CONFIG);
    initializeApp(firebaseConfig);
    if (!isWebsite()) notificationService.register();
  }, []);

  const onLayoutRootView = React.useCallback(async (): Promise<void> => {
    try {
      if (fontsLoaded) {
        initializeThing();
        await userAppState();
        setTimeout(async () => {
          await hideAsync();
        }, FixedValue.CONSTANT_VALUE_1024);
      }
    } catch (error) {}
  }, [fontsLoaded]);

  React.useEffect(() => {
    onLayoutRootView();
  }, [props, fontsLoaded]);

  const barStyle =
    themeState === themeTypes.LIGHT
      ? statusBarContentType.DARK
      : statusBarContentType.LIGHT;

  return (
    <View style={styles.mainViewWidth} dataSet={{media: ids.mainViewWidth}}>
      <View style={styles.innerContainer} dataSet={{media: ids.innerContainer}}>
        <StatusBar
          barStyle={barStyle}
          backgroundColor={ColorTheme.backgroundColor}
        />
        {children}
        <FlashMessage
          position={GlobalStyleValues.TOP}
          renderFlashMessageIcon={renderFlashMessageIcon}
        />
      </View>
    </View>
  );
};

export default UserAppState;
