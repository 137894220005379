import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  Text,
  View,
  SectionList,
  ActivityIndicator,
  FlatList,
  ScrollView,
} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {MyWalletStyle} from './styles';
import TopBarWithRightButton from '../../components/header/topBarWithRightButton';
import MyWalletBackgroundImage from '../../components/renderSvgs/myWalletBackgroundImage';
import {BASESTYLE, COLORS, FixedValue, TEXT, ROUTE_NAME} from '../../constants';
import PrimaryButton from '../../components/button/primaryButton';
import StraightLine from '../../components/renderSvgs/straightLine';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import {
  HomeStore,
  topAmountAndReportAbuseDataStart,
} from '../../redux/reducers/homeSlice';
import {PackListObject} from '../../types/reducerTypes/searchTabStoreType';
import Plantag from '../../components/planTag';
import TransactionHistoryTile from '../../components/planHistoryTag';
import {
  onUserChildListStart,
  onUserDataStart,
  userProfileStore,
} from '../../redux/reducers/userProfileSlice';
import {
  TOP_CREDITS_PULLBACK,
  TRANSFER_TOP_CREDITS,
  USER_PLAN_PURCHACE_ACTION,
  USER_PROFILE_DATA,
} from '../../constants/actionTypes';
import {
  CHILD_PERMISSION,
  GlobalStyleValues,
  Percentage,
} from '../../constants/enum';
import {TOAST_TEXT} from '../../constants/textMessage';
import {_ToastHandler} from '../../utils/helperFunctions';
import {navigate} from '../../services/navigationService';
import {goBack} from '../../services/navigationService';
import useWalletHooks from '../../hooks/componentHooks/useWalletHooks';
import PayPalModal from '../../modals/paypalModal';
import {ProfileTabContext} from '../../contextAPI/profileTabContext';
import TopsEmptyList from '../../components/emptyListView/topsListEmptyView';
import TransactionEmptyImage from '../../components/renderSvgs/transactionEmptyImage';
import {RouteProp, useIsFocused, useRoute} from '@react-navigation/native';
import {RootStackParamList} from '../../types/navigationType';
import BACKGROUND from '../../components/renderSvgs/background';
import Failed from '../../components/renderSvgs/failed';
import {scaleHeight} from '../../utils/responsive';
import CustomButton from '../../components/button/customButton';
import Done from '../../components/renderSvgs/done';
import TransferTopCreditsContent from './transferTopCreitsContent';
import BottomSheet, {BottomSheetSectionList} from '@gorhom/bottom-sheet';

const MyWalletContent = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = MyWalletStyle(ColorTheme);
  const isFocused = useIsFocused();
  const dispatch = useAppDispatch();
  const route = useRoute<RouteProp<RootStackParamList, 'MyWallet'>>();
  const {numberOfScreenToBack} = route?.params ?? {};
  const {
    transactionHistory,
    setTransactionHistory,
    transactionHistoryLoader,
    setTransactionHistoryLoader,
    selectedPlan,
    setSelectedPlan,
    setOpenTopCreditTransferModal,
    setSelectedChild,
    selectedChild,
    transferTopCreditAmount,
  } = React.useContext(ProfileTabContext);
  const {
    userData,
    userPlanPurchaseData,
    transferTopCreditData,
    topCreditPullbackData,
  } = useAppSelector(userProfileStore);
  const {topAmountAndReportAbuseData} = useAppSelector(HomeStore);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [paymentSuccessData, setPaymentSuccessData] = useState<any>({});
  const [checkuserPermission, setCheckuserPermission] = useState(true);
  const [showLoader, setShowLoader] = React.useState(false);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const {
    planPurchaseAPI,
    onTransactionHistoryReachedEnd,
    fetchTransactionHistoryList,
    onClickPullBackTopCredit,
  } = useWalletHooks();
  const walletobj = topAmountAndReportAbuseData?.success?.data;
  const coinToTopCreditRatio =
    walletobj?.coinToTopCreditRatio ?? FixedValue.CONSTANT_VALUE_1;
  const purchasedPlanAmount = selectedPlan
    ? selectedPlan.packAmount
    : FixedValue.CONSTANT_VALUE_0;
  const purchasedPlanButtonText = `${TEXT.ADD}${
    selectedPlan ? ` ${purchasedPlanAmount * coinToTopCreditRatio}` : ''
  }${TEXT.TopCredits_To_Wallet}`;
  const isChildHasPurchasePermission =
    userData?.success?.permissionsStatus?.purchasePermission?.status ===
    CHILD_PERMISSION.NOT_ALLOWED;
  const isPurchasedPlanButtonDisabled =
    purchasedPlanAmount === FixedValue.CONSTANT_VALUE_0 ||
    showLoader ||
    isLoading;
  const bottomSheetRef = useRef(null);
  const snapPoints = [
    `${FixedValue.CONSTANT_VALUE_50}%`,
    Percentage.PRECENTAGE_99,
  ];

  //transfer topcredit to child API resposnse handle

  useEffect(() => {
    if (isFocused) {
      switch (transferTopCreditData.status) {
        case TRANSFER_TOP_CREDITS.SUCCESS:
          const toastMsg = `${transferTopCreditAmount}${
            TOAST_TEXT.TOP_CRDITS_TRANSFER_TO
          }${selectedChild?.firstName + ' ' + selectedChild?.lastName} `;
          _ToastHandler(toastMsg, true);
          setOpenTopCreditTransferModal(false);
          setSelectedChild(null);
          fetchTransactionHistoryList(FixedValue.CONSTANT_VALUE_0);
          setCheckuserPermission(false);
          dispatch(onUserDataStart());
          dispatch(onUserChildListStart());
          break;
        case TRANSFER_TOP_CREDITS.FAILED:
          break;
        default:
          break;
      }
    }
  }, [transferTopCreditData.status]);

  //transfer topcredit pullback request API resposnse handle
  useEffect(() => {
    if (isFocused) {
      switch (topCreditPullbackData.status) {
        case TOP_CREDITS_PULLBACK.SUCCESS:
          const toastMsg = `${transferTopCreditAmount}${
            TOAST_TEXT.TOP_CRDITS_TRANSFER_TO
          }${UserDetail?.firstName + ' ' + UserDetail?.lastName} `;
          _ToastHandler(toastMsg, true);
          setOpenTopCreditTransferModal(false);
          setSelectedChild(null);
          fetchTransactionHistoryList(FixedValue.CONSTANT_VALUE_0);
          setCheckuserPermission(false);
          dispatch(onUserDataStart());
          dispatch(onUserChildListStart());
          break;
        case TOP_CREDITS_PULLBACK.FAILED:
          break;
        default:
          break;
      }
    }
  }, [topCreditPullbackData.status]);

  React.useEffect(() => {
    setLoading(userPlanPurchaseData.isLoading);
  }, [userPlanPurchaseData.isLoading]);

  //after calling plan api response handle
  useEffect(() => {
    switch (userPlanPurchaseData.status) {
      case USER_PLAN_PURCHACE_ACTION.SUCCESS:
        if (!isChildHasPurchasePermission) {
          fetchTransactionHistoryList(FixedValue.CONSTANT_VALUE_0);
          dispatch(onUserDataStart());
          const newPaymentSuccessData: any = {
            comingFromWallet: true,
            isBack: true,
          };
          setPaymentSuccessData(newPaymentSuccessData);
          setShowSuccessScreen(true);
        } else {
          _ToastHandler(TOAST_TEXT.PURCHASED_PACK_REQUEST_SENT_TO, true);
        }
        setShowLoader(false);
        setLoading(false);
        setSelectedPlan(null);
        setTimeout(() => {
          setCheckuserPermission(true);
        }, 3000);
        break;
      case USER_PLAN_PURCHACE_ACTION.FAILED:
        setShowLoader(false);
        setLoading(false);
        setCheckuserPermission(true);
        break;
      default:
        break;
    }
  }, [userPlanPurchaseData.status]);

  useEffect(() => {
    if (isFocused) {
      setTransactionHistory([]);
      fetchTransactionHistoryList(FixedValue.CONSTANT_VALUE_0);
      setTransactionHistoryLoader(true);
      setSelectedPlan(null);
      dispatch(topAmountAndReportAbuseDataStart());
      if (isAdultUserLoggedIn) {
        dispatch(onUserChildListStart());
      }
    }
  }, [isFocused]);

  useEffect(() => {
    if (isFocused) {
      switch (userData.status) {
        case USER_PROFILE_DATA.SUCCESS:
          if (checkuserPermission) {
            if (
              userData?.success?.permissionsStatus?.purchasePermission
                ?.status === CHILD_PERMISSION.HAVE_LIMIT
            ) {
              const monthlyLimit: number =
                userData?.success?.permissionsStatus?.purchasePermission
                  ?.monthlyLimit ?? FixedValue.CONSTANT_VALUE_0;
              const usedLimit: number =
                userData?.success?.permissionsStatus?.purchasePermission
                  ?.usedMonthlyLimit ?? FixedValue.CONSTANT_VALUE_0;
              if (
                monthlyLimit <= usedLimit ||
                purchasedPlanAmount > monthlyLimit - usedLimit
              ) {
                _ToastHandler(TOAST_TEXT.LIMIT_EXHAUSTED, false);
                return;
              } else {
                setShowLoader(true);
              }
            } else if (isChildHasPurchasePermission) {
              planPurchaseAPI();
            } else {
              setShowLoader(true);
            }
          }
          break;
        case USER_PROFILE_DATA.FAILED:
          break;
        default:
          break;
      }
    }
  }, [userData.status]);

  const onPlanSelect = useCallback(
    (item: PackListObject): void => {
      setSelectedPlan(item);
    },
    [selectedPlan]
  );

  const onPurchasePlan = useCallback((): void => {
    setCheckuserPermission(true);
    dispatch(onUserDataStart());
  }, [selectedPlan, walletobj]);

  // Memoized FlatList for rendering wallet plan items in a row
  const renderPlanList = useMemo(() => {
    return (
      <FlatList
        data={walletobj?.packList}
        renderItem={({item}) => (
          <Plantag
            item={item}
            onPress={onPlanSelect}
            selectedPlanId={selectedPlan?.id ?? FixedValue.CONSTANT_VALUE_0}
          />
        )}
        keyExtractor={item => item.id.toString()}
        numColumns={FixedValue.CONSTANT_VALUE_5}
        contentContainerStyle={styles.planMainView}
      />
    );
  }, [walletobj, selectedPlan, onPlanSelect]);

  const renderSectionData = ({item}: {item: any}) => {
    return (
      <FlatList
        data={item.list}
        style={[styles.innerFlat, styles.flatlistView]}
        contentContainerStyle={{flexGrow: FixedValue.CONSTANT_VALUE_1}}
        showsVerticalScrollIndicator={false}
        renderItem={({item, index}) => (
          <>
            <TransactionHistoryTile
              key={index}
              item={item}
              onClickTransfer={onClickPullBackTopCredit}
            />
            <View style={styles.underline} />
          </>
        )}
      />
    );
  };

  // Memoized FlatList for rendering transaction history items
  const memoizedTranscationFlatList = useMemo(() => {
    return (
      <SectionList
        // @ts-ignore
        sections={transactionHistory}
        stickySectionHeadersEnabled={false}
        onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
        keyExtractor={(item, index) => item.createDate + index}
        renderItem={renderSectionData}
        renderSectionHeader={({section: {title}}) => (
          <Text style={styles.dateText}>{title}</Text>
        )}
        style={[styles.innerFlat, styles.flatlistView]}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.sectionListPaddingBottom}
        onEndReached={onTransactionHistoryReachedEnd}
        ListEmptyComponent={
          <TopsEmptyList
            placeHolder={<TransactionEmptyImage />}
            emptyViewTitle={TEXT.NO_TRANSACTION_YET}
            emptyViewDesc={TEXT.YOU_HAVE_NOT_MADE}
          />
        }
      />
    );
  }, [transactionHistory]);

  const isFailed = paymentSuccessData?.isFailed;
  const isBack = paymentSuccessData?.isBack;
  const {mainText, subText, comingFromWallet} = paymentSuccessData ?? {};

  const getMainText = React.useCallback(() => {
    return mainText ? mainText : TEXT.PAYMENT_FAILED;
  }, [mainText]);

  const getSubText = React.useCallback(() => {
    return subText ? subText : TEXT.REGRET;
  }, [subText]);

  const onPaymentScreenGoToWallet = React.useCallback(() => {
    setShowSuccessScreen(false);
    setShowLoader(false);
  }, []);

  const PaymentSuccess = React.useMemo((): JSX.Element | null => {
    return (
      <View style={styles.mainContainer}>
        <View style={styles.mainContainer}>
          <BACKGROUND />
          <View style={styles.requestView}>
            {isFailed ? <Failed fill={ColorTheme.backgroundColor} /> : <Done />}
            <Text style={styles.request}>
              {isFailed ? getMainText() : TEXT.PAYMENT_SUCCESS}
            </Text>
            <Text style={styles.wait}>
              {isFailed ? getSubText() : TEXT.CONGRATULATIONS}
            </Text>
            <Text style={styles.wait}>
              {isFailed ? TEXT.APOLOGIZE : TEXT.THANK_YOU}
            </Text>
            <View
              style={[
                BASESTYLE.inRow,
                BASESTYLE.inCenter,
                {marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_34)},
              ]}
            >
              <CustomButton
                buttonLabel={
                  numberOfScreenToBack
                    ? TEXT.GO_TO_ORDER_SUMMARY
                    : comingFromWallet
                    ? TEXT.GO_TO_Wallet
                    : isBack
                    ? TEXT.GO_TO_BACK
                    : isFailed
                    ? TEXT.GO_TO_CART
                    : TEXT.GO_TO_MANTEL
                }
                buttonwidth={styles.button}
                labelStyle={styles.yesButtonLabelstyle}
                onPress={() =>
                  numberOfScreenToBack ? goBack() : onPaymentScreenGoToWallet()
                }
              />
            </View>
          </View>
        </View>
      </View>
    );
  }, [
    isFailed,
    mainText,
    subText,
    comingFromWallet,
    paymentSuccessData,
    numberOfScreenToBack,
    showSuccessScreen,
    showLoader,
    isLoading,
    checkuserPermission,
  ]);
  const UserDetail = userData.success;
  const isAdultUserLoggedIn = UserDetail?.role === TEXT.ROLE_PARENT;
  function walletScreen() {
    return (
      <>
        <View style={styles.walletBackgroundImage}>
          <MyWalletBackgroundImage />
        </View>
        <View style={styles.container} dataSet={{media: ids.container}}>
          <TopBarWithRightButton
            headerLabel={TEXT.MY_WALLET_BALANCE}
            headerColor={COLORS.BLACK}
          />
          <ScrollView
            style={styles.scrollContainer}
            showsVerticalScrollIndicator={false}
          >
            <Text style={styles.walletAmountText}>
              {userData?.success?.walletAmount}
            </Text>
            <Text style={styles.topCreditText}>
              {(userData?.success?.walletAmount ??
                FixedValue.CONSTANT_VALUE_0) > FixedValue.CONSTANT_VALUE_1
                ? TEXT.TOPCREDITS
                : TEXT.TOPCREDIT}
            </Text>
            <View>{renderPlanList}</View>
            <Text style={styles.exchangeAmount}>
              ${FixedValue.CONSTANT_VALUE_1} = {walletobj?.coinToTopCreditRatio}
              {(walletobj?.coinToTopCreditRatio ??
                FixedValue.CONSTANT_VALUE_0) > FixedValue.CONSTANT_VALUE_1
                ? TEXT.TOPCREDITS
                : TEXT.TOPCREDIT}
            </Text>
            <PrimaryButton
              buttonLabel={purchasedPlanButtonText}
              buttonwidth={styles.addTopCredit}
              labelStyle={
                isPurchasedPlanButtonDisabled
                  ? styles.addTopCreditStyle
                  : styles.addTopCreditStyleButtonDisaled
              }
              onPress={onPurchasePlan}
              isDisabled={isPurchasedPlanButtonDisabled}
            />
            {isAdultUserLoggedIn && <TransferTopCreditsContent />}
          </ScrollView>
        </View>
        <BottomSheet
          handleComponent={null}
          style={styles.bottomsheetView}
          snapPoints={snapPoints}
          animateOnMount
          keyboardBehavior="fillParent"
          ref={bottomSheetRef}
          enableContentPanningGesture={true}
        >
          <View style={styles.transactionHistoryView}>
            <View style={styles.bottomSheetInnerView}>
              <View style={styles.straightLineView}>
                <StraightLine />
              </View>
              <Text style={styles.myTransaction}>
                {TEXT.TRANSACTION_HISTORY}
              </Text>
            </View>
            {!transactionHistoryLoader && memoizedTranscationFlatList}
          </View>
        </BottomSheet>
        {(showLoader || transactionHistoryLoader) && (
          <View style={styles.walletLoader}>
            <ActivityIndicator
              size={GlobalStyleValues.LARGE}
              color={COLORS.PRIMARY_BLUE}
            />
          </View>
        )}
        {!!showLoader && (
          <PayPalModal
            totalTopsAmount={purchasedPlanAmount}
            modalVisible={true}
            onClose={() => {
              setShowLoader(false);
              setCheckuserPermission(true);
            }}
            onSuccessPayment={() => planPurchaseAPI()}
            onCancelPayment={() => {
              navigate(ROUTE_NAME.PAYMENT_SUCCESS, {
                isFailed: true,
                comingFromWallet: true,
                mainText: TEXT.TRANSACTION_CANCELED,
                subText: TEXT.WE_REGET_TO_INFORM_YOU_THAT,
              });
            }}
          />
        )}
      </>
    );
  }

  return <>{showSuccessScreen ? PaymentSuccess : walletScreen()}</>;
};

export default React.memo(MyWalletContent);
