import {takeLatest, put, call, select, delay} from 'redux-saga/effects';
import {AppConst, FixedValue} from '../../constants';
import {IActionType} from '../../types/constantFilesTypes';
import {
  ICreateNewTopResponse,
  IUploadTopImage,
} from '../../types/reducerTypes/editorStoreType';
import {
  _createNewTop,
  _updateExistingTop,
  _uploadTopImage,
} from '../../utils/apis';
import {authState} from '../reducers/authSlice';
import {
  onCreateTopSuccess,
  onCreateTopFailed,
  onCreateTopEnd,
  onUpdateTopSuccess,
  onUpdateTopFailed,
  onUpdateTopEnd,
  onTopImageUploadSuccess,
  onTopImageUploadFailed,
  onTopImageUploadEnd,
} from '../reducers/editorSlice';
import {isWebsite} from '../../utils/responsive';
import {_ToastHandler} from '../../utils/helperFunctions';

const {StoreConstants} = AppConst;

function* createNewTop(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ICreateNewTopResponse = yield call(
      _createNewTop,
      action.payload,
      userTokens
    );
    yield put(onCreateTopSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onCreateTopFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onCreateTopEnd());
  }
}

function* updateExistingTop(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ICreateNewTopResponse = yield call(
      _updateExistingTop,
      action.payload.data,
      action.payload.id,
      userTokens
    );
    yield put(onUpdateTopSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onUpdateTopFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onUpdateTopEnd());
  }
}

//upload top image function
function* uploadTopImageFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUploadTopImage = yield call(
      _uploadTopImage,
      action.payload,
      userTokens
    );
    yield put(onTopImageUploadSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onTopImageUploadFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onTopImageUploadEnd());
  }
}

export default function* editorSaga() {
  yield takeLatest(`${StoreConstants.EDITOR}/onCreateTopStart`, createNewTop);
  yield takeLatest(
    `${StoreConstants.EDITOR}/onUpdateTopStart`,
    updateExistingTop
  );
  yield takeLatest(
    `${StoreConstants.EDITOR}/onTopImageUploadStart`,
    uploadTopImageFunction
  );
}
