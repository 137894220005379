import React from 'react';
import {View} from 'react-native';
import ModalContainer from '../../components/layout/modalContainer';
import {CreateCollectionContext} from '../../contextAPI/createCollectionContext';
import {ThemeContext} from '../../contextAPI/themeContext';

import {createCollectionModalStyle} from './createCollectionModal.styles';
import CreateModalField from './createCollectionField';
import ModalHeaderWithCross from '../../components/header/modalHeaderWithCross';
import {TEXT} from '../../constants';

const CreateCollectionFormModal = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = createCollectionModalStyle(ColorTheme);
  const {openCreateCollectionModal, setOpenCreateCollectionModal, isEditMode} =
    React.useContext(CreateCollectionContext);
  const headerTitle = isEditMode
    ? TEXT.EDIT_COLLECTION_NAME
    : TEXT.CREATE_NEW_COLLECTION;
  return (
    <ModalContainer
      visible={openCreateCollectionModal}
      onPressOutside={setOpenCreateCollectionModal}
    >
      <View style={styles.subContainer}>
        <ModalHeaderWithCross
          onPress={setOpenCreateCollectionModal}
          headerTitle={headerTitle}
        />
        <CreateModalField isEditMode={isEditMode} />
      </View>
    </ModalContainer>
  );
};

export default CreateCollectionFormModal;
