import React from 'react';
import {View, Text} from 'react-native';

import {TEXT} from '../../constants';
import {styles} from './smallDesign.style';

const TradableLable = (): JSX.Element => {
  const style = styles();
  return (
    <View style={style.tradableView}>
      <Text style={style.tradableText}>{TEXT.TRADABLE}</Text>
    </View>
  );
};

export default React.memo(TradableLable);
