import React from 'react';
import {View, Text, Modal, Pressable} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {modalStyle} from './styles';
import {FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import {TopDetailContext} from '../../contextAPI/topDetailContext';
import CancelButton from '../../components/button/cancelButton';
import {HomeContext} from '../../contextAPI/homeContext';
import {TopModel} from '../../types/reducerTypes/searchTabStoreType';
import QunatitySelector from '../../components/smallDesigns/qunatitySelector';
import {TradeContext} from '../../contextAPI/tradeContext';
import {push} from '../../services/navigationService';

const ProposeTopCollectionsModal = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = modalStyle(ColorTheme);
  const {openProposeModal, setOpenProposeModal} =
    React.useContext(TopDetailContext);
  const {setSearchUserListText} = React.useContext(HomeContext);
  const {
    setIsOtherUserTopList,
    collectionSelectedTopsOther,
    updateTopQuantity,
    setIsTopEdit,
    setCollectionSelectedTops,
    setCollectionSelectedTopsOther,
    setCounterOffer,
    setFromProposeTrade,
  } = React.useContext(TradeContext);

  const handelCancelOption = () => {
    resetTradeData();
    setOpenProposeModal();
  };

  const resetTradeData = () => {
    setCollectionSelectedTops([]);
    setCounterOffer(false);
    setIsTopEdit(false);
    setSearchUserListText('');
    setCollectionSelectedTopsOther([]);
    setIsOtherUserTopList(false);
    setFromProposeTrade(false);
  };

  const handelNextOption = () => {
    setIsOtherUserTopList(false);
    setIsTopEdit(true);
    setOpenProposeModal();
    push(ROUTE_NAME.CREATE_TRADE, {isEdit: true, fromPropose: true});
  };

  const quantityModify = (item: TopModel, val: string) => {
    if (val === TEXT.MINNUS || item.quantity !== item.selectedQuantity) {
      updateTopQuantity(item, val);
    }
  };

  const quantityUpdate = (item: TopModel, val: string) => {
    if (item.selectedQuantity === FixedValue.CONSTANT_VALUE_1) {
      if (val !== TEXT.MINNUS) {
        quantityModify(item, val);
      }
    } else {
      quantityModify(item, val);
    }
  };

  return (
    <Modal
      visible={openProposeModal}
      transparent
      onRequestClose={handelCancelOption}
    >
      <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
        <Pressable onPress={handelCancelOption} style={styles.outterPart} />
        <View style={styles.subContainer}>
          <View style={styles.topView}>
            <Text style={styles.headingText}>Select Quantity</Text>
            <View style={styles.newCollectionView}>
              <QunatitySelector
                count={
                  collectionSelectedTopsOther[FixedValue.CONSTANT_VALUE_0]
                    ?.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0
                }
                onMinusPress={() => {
                  quantityUpdate(
                    collectionSelectedTopsOther[FixedValue.CONSTANT_VALUE_0],
                    TEXT.MINNUS
                  );
                }}
                onPlusPress={() => {
                  quantityUpdate(
                    collectionSelectedTopsOther[FixedValue.CONSTANT_VALUE_0],
                    TEXT.ADD
                  );
                }}
                qunatityTextStyle={styles.quantityText}
                quantityStyle={styles.quantitySelectorView}
                plusTextStyle={styles.plusText}
                plusStyle={styles.plusStyle}
                minusStyle={styles.minusStyle}
              />
            </View>
          </View>
          <View style={styles.buttonsView}>
            <View style={styles.buttonWidth}>
              <CancelButton
                label={TEXT.CANCEL}
                onPress={handelCancelOption}
                hideSeperator
              />
            </View>
            <View style={styles.buttonWidth}>
              <CancelButton
                label={'Next'}
                onPress={handelNextOption}
                buttonTextColor={styles.doneTextColor}
                hideSeperator
              />
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default React.memo(ProposeTopCollectionsModal);
