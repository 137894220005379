import React from 'react';
import {FlatList, View} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';

import {TopReceivedStyle} from './topReceived.style';
import {ThemeContext} from '../../../contextAPI/themeContext';
import ViewContainer from '../../../components/layout/viewContainer';
import ChildTradeComponent from '../../../components/childTradeComponent';
import CustomButton from '../../../components/button/customButton';
import TICKICON from '../../../components/renderSvgs/tickIcon';
import {FixedValue, TEXT} from '../../../constants';
import CROSSICON from '../../../components/renderSvgs/smallCrossIcon';
import useTradingApprovalHooks from '../../../hooks/componentHooks/useTradingApprovalHooks';
import {ChildProfileContext} from '../../../contextAPI/childProfileContext';
import TopsListEmptyView from '../../../components/emptyListView/topsListEmptyView';
import NoImage from '../../../components/renderSvgs/noImage';
import {OfferModel} from '../../../types/reducerTypes/tradeTabTypes';
import {childProfileStore} from '../../../redux/reducers/childProfileSlice';
import {useAppSelector} from '../../../hooks/reduxHooks';
import ReportImage from '../../../components/renderSvgs/reportImage';
import {TradeContext} from '../../../contextAPI/tradeContext';

const TopReceivedContent = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = TopReceivedStyle(ColorTheme);
  const {
    OnChildTradeReceivedListEndReach,
    pullDownToRefreshOnReceivedList,
    navigateToTopDetailScreen,
  } = useTradingApprovalHooks();
  const {
    initialLoader,
    tradeReceivedList,
    childProfileContextPullDownToRefresh,
    setIsTradeReceivedApproved,
    setSelectedTradeReceivedData,
    setShowTradeApproveConfirmModal,
  } = React.useContext(ChildProfileContext);
  const {onTradeApprovalReceivedListData} = useAppSelector(childProfileStore);
  const {setOpenReportModal} = React.useContext(TradeContext);

  const tradeApproveRejectButton = (data: OfferModel) => {
    return (
      <View style={styles.buttonView}>
        <CustomButton
          buttonLabel={TEXT.APPROVE}
          buttonwidth={styles.approveButton}
          labelStyle={styles.primaryButtonLabelstyle}
          buttonBackGroundColor={styles.noButtonBackGroundColor1}
          element={<TICKICON />}
          onPress={() => {
            setSelectedTradeReceivedData(data);
            setIsTradeReceivedApproved(true);
            setShowTradeApproveConfirmModal();
          }}
        />
        <CustomButton
          buttonLabel={TEXT.REJECT}
          buttonwidth={styles.rejectButton}
          labelStyle={styles.primaryButtonLabelstyle1}
          buttonBackGroundColor={styles.noButtonBackGroundColor}
          element={<CROSSICON />}
          onPress={() => {
            setSelectedTradeReceivedData(data);
            setIsTradeReceivedApproved(false);
            setShowTradeApproveConfirmModal();
          }}
        />
        <CustomButton
          buttonLabel={TEXT.REPORT}
          buttonwidth={styles.rejectButton}
          labelStyle={styles.primaryButtonLabelstyle1}
          buttonBackGroundColor={styles.noButtonBackGroundColor}
          element={
            <ReportImage
              width={FixedValue.CONSTANT_VALUE_12}
              height={FixedValue.CONSTANT_VALUE_12}
            />
          }
          onPress={() => {
            setSelectedTradeReceivedData(data);
            setOpenReportModal(true);
          }}
        />
      </View>
    );
  };

  const renderChildTradingReceivedList =
    React.useMemo((): JSX.Element | null => {
      return (
        <FlatList
          data={tradeReceivedList}
          keyExtractor={(_, index) =>
            `${TEXT.CHILD_TRADE_RECEIVED_LIST}-${index}`
          }
          showsVerticalScrollIndicator={false}
          scrollEnabled
          contentContainerStyle={styles.flatListContentContainerStyle}
          onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
          ListEmptyComponent={
            onTradeApprovalReceivedListData.isLoading || initialLoader ? (
              <></>
            ) : (
              <TopsListEmptyView
                emptyViewTitle={TEXT.NO_TRADING_APPROVAL_FOUND}
                emptyViewDesc={''}
                placeHolder={<NoImage />}
              />
            )
          }
          onEndReached={() => OnChildTradeReceivedListEndReach()}
          refreshControl={
            <RefreshControl
              refreshing={childProfileContextPullDownToRefresh}
              onRefresh={pullDownToRefreshOnReceivedList}
            />
          }
          renderItem={({item, index}) => (
            <>
              <ChildTradeComponent
                key={item.id}
                userDetail={item?.toUser}
                creationDate={item?.creationDate}
                imageArray={item?.toUserTops}
                showTopDetail={navigateToTopDetailScreen}
              />
              <ChildTradeComponent
                key={index}
                userDetail={item?.fromUser}
                imageArray={item?.fromUserTops}
                showTopDetail={navigateToTopDetailScreen}
              />
              {tradeApproveRejectButton(item)}
              <View style={styles.bottomLineStyle} />
            </>
          )}
        />
      );
    }, [tradeReceivedList, onTradeApprovalReceivedListData, initialLoader]);

  return (
    <View style={styles.receivedMainContainer}>
      <ViewContainer>{renderChildTradingReceivedList}</ViewContainer>
    </View>
  );
};

export default React.memo(TopReceivedContent);
