import React from 'react';
import PrimaryButton from '../../components/button/primaryButton';

import LabelInput from '../../components/input/labelInput';
import NoteInput from '../../components/input/noteInput';
import {FixedValue, TEXT} from '../../constants';
import useEditorHooks from '../../hooks/componentHooks/useEditorHooks';
import {RouteProp, useRoute} from '@react-navigation/native';
import {RootStackParamList} from '../../types/navigationType';

const EditorModalField = () => {
  const route = useRoute<RouteProp<RootStackParamList, 'EditorScreen'>>();
  const {
    values,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    NoteError,
    TopNameError,
    AddHashTagError,
    updateHashTagValue
  } = useEditorHooks();
  const createdTopData = route.params?.createdTopData;
  const isEdit = route.params?.isEdit ?? false;

  React.useEffect((): void => {
    if (createdTopData) {
      setValues({
        TopName: createdTopData?.name ?? '',
        AddHashTag: createdTopData?.hashTag ?? '',
        Note: createdTopData?.note ?? '',
      });
    }
  }, []);

  return (
    <>
      <LabelInput
        inputLabel={TEXT.TOP_NAME}
        value={values.TopName}
        onChangeText={handleChange(TEXT.TOP_NAME_)}
        onBlur={handleBlur(TEXT.TOP_NAME_)}
        haveError={TopNameError}
        maxLength={FixedValue.CONSTANT_VALUE_30}
      />
      <LabelInput
        inputLabel={TEXT.ADD_HASHTAG}
        value={values.AddHashTag}
        onChangeText={updateHashTagValue}
        onBlur={handleBlur(TEXT.ADD_HASHTAG_)}
        haveError={AddHashTagError}
        maxLength={FixedValue.CONSTANT_VALUE_30}
      />
      <NoteInput
        inputLabel={TEXT.NOTE}
        value={values.Note}
        onChangeText={handleChange(TEXT.NOTE)}
        onBlur={handleBlur(TEXT.NOTE)}
        haveError={NoteError}
      />
      <PrimaryButton
        buttonLabel={isEdit ? TEXT.UPDATE_TOP : TEXT.SAVE_TOP}
        onPress={handleSubmit}
        isDisabled={!isValid}
      />
    </>
  );
};

export default React.memo(EditorModalField);
