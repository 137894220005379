import React from 'react';
import {View} from 'react-native';
import ModalContainer from '../../components/layout/modalContainer';
import {ThemeContext} from '../../contextAPI/themeContext';
import {applyCouponCodeModalStyle} from './applyCouponCodeModal.styles';
import {FixedValue, TEXT} from '../../constants';
import ModalHeaderWithCross from '../../components/header/modalHeaderWithCross';
import {useAppSelector} from '../../hooks/reduxHooks';
import {groupManagementStore} from '../../redux/reducers/groupManagementSlice';
import PrimaryButton from '../../components/button/primaryButton';
import LabelInput from '../../components/input/labelInput';
import {ApplyCouponCodeProps} from '../../types/reducerTypes/searchTabStoreType';
import useInProgressTopsHooks from '../../hooks/componentHooks/useInProgressTopsHooks';

const ApplyCouponCodeFormModal = (props: ApplyCouponCodeProps) => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = applyCouponCodeModalStyle(ColorTheme);
  const {visble, onClose} = props;
  const {createNewGroup, updateGroup} = useAppSelector(groupManagementStore);
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    CouponCodeError,
    isValid,
  } = useInProgressTopsHooks();
  return (
    <ModalContainer visible={visble} onPressOutside={() => onClose(false)}>
      <View style={styles.subContainer}>
        <ModalHeaderWithCross
          onPress={() => onClose(false)}
          headerTitle={TEXT.APPLY_COUPON_CODE}
        />
        <>
          <View style={styles.labelInputStyle} />
          <LabelInput
            inputLabel={''}
            placeholder={TEXT.COUPON_PLACEHOLDER}
            value={values.couponCode}
            onChangeText={handleChange(TEXT.COUPON_CODE_)}
            onBlur={handleBlur(TEXT.COUPON_CODE_)}
            haveError={CouponCodeError}
            maxLength={FixedValue.CONSTANT_VALUE_10}
          />
          <PrimaryButton
            isLoading={createNewGroup?.isLoading || updateGroup?.isLoading}
            buttonLabel={TEXT.APPLY}
            onPress={() => {
              onClose(false), handleSubmit();
            }}
            isDisabled={!isValid}
          />
        </>
      </View>
    </ModalContainer>
  );
};

export default ApplyCouponCodeFormModal;
