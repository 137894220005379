import React from 'react';
import {View, Text} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import IconsWithText from '../../components/smallDesigns/iconsWithText';
import {IChildProfile} from '../../types/reducerTypes/searchTabStoreType';
import {getInitials} from '../../utils/helperFunctions';
import {FixedValue} from '../../constants';
import MailBlack from '../../components/renderSvgs/mail';
import ProfileIcon from '../../components/renderSvgs/profileIcon';
import SmartPhone from '../../components/renderSvgs/smartPhone';
import CalendarBlack from '../../components/renderSvgs/calendar';
import {timeformat} from '../../constants/enum';
import moment from 'moment-timezone';
import {ChildprofileStyle} from './child.style';
import ProfilePics from '../../components/smallDesigns/profilePics';

const ChildProfileUpperContent = (props: IChildProfile): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = ChildprofileStyle(ColorTheme);
  const fullName = props?.item?.firstName + ' ' + props?.item?.lastName ?? '';
  const dateOfBirth = moment(props?.item?.dob ?? undefined).format(
    timeformat.MMMDDYY
  );

  return (
    <>
      <View style={styles.headingView}></View>
      <View style={styles.profilePicView}>
        <ProfilePics
          image={props?.item?.profileImage ?? ''}
          text={getInitials(fullName)}
          mainViewStyle={styles.imageView}
          initialsTextStyle={styles.profileNameText}
        />
        <View style={styles.userDetailView}>
          <Text
            style={styles.userNameText}
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
          >
            {props.item?.firstName} {props?.item?.lastName}
          </Text>

          <IconsWithText
            text={`@${props?.item?.username}` ?? ''}
            Icon={
              <ProfileIcon
                width={FixedValue.CONSTANT_VALUE_12}
                height={FixedValue.CONSTANT_VALUE_12}
                stroke={ColorTheme.color}
              />
            }
          />

          {props?.item?.email && (
            <IconsWithText
              text={props?.item?.email ?? ''}
              Icon={
                <MailBlack
                  width={FixedValue.CONSTANT_VALUE_12}
                  height={FixedValue.CONSTANT_VALUE_12}
                  stroke={ColorTheme.color}
                />
              }
            />
          )}
          <IconsWithText
            text={props?.item?.countryCode + ' ' + props?.item?.mobile ?? ''}
            Icon={
              <SmartPhone
                width={FixedValue.CONSTANT_VALUE_12}
                height={FixedValue.CONSTANT_VALUE_12}
                stroke={ColorTheme.color}
              />
            }
          />

          <IconsWithText
            text={dateOfBirth}
            Icon={
              <CalendarBlack
                width={FixedValue.CONSTANT_VALUE_12}
                height={FixedValue.CONSTANT_VALUE_12}
                stroke={ColorTheme.color}
              />
            }
          />
        </View>
      </View>
    </>
  );
};

export default React.memo(ChildProfileUpperContent);
