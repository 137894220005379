import React from 'react';

import {FixedValue} from '../../../constants';
import {EditorContext} from '../../../contextAPI/editorContext';
import ImageFooter from './imageFooter';
import MainFooter from './mainFooter';
import {EditorImageContext} from '../../../contextAPI/editorImageContext';
import ShapeFooter from './shapeFooter';
import TextFooter from './textFooter';
import OrderFooter from './orderFooter';

const _EditorFooter = (): JSX.Element => {
  const {primaryTabSelectedIndex} = React.useContext(EditorContext);
  const {onSelectImage, selectedController} =
    React.useContext(EditorImageContext);

  return React.useMemo((): JSX.Element => {
    switch (primaryTabSelectedIndex) {
      case FixedValue.CONSTANT_VALUE_0:
        return <ImageFooter />;
      case FixedValue.CONSTANT_VALUE_1:
        return <TextFooter />;
      case FixedValue.CONSTANT_VALUE_2:
        return <ShapeFooter />;
      case FixedValue.CONSTANT_VALUE_3:
        return <OrderFooter />;
      case FixedValue.CONSTANT_VALUE_MIN_1:
      default:
        return <MainFooter />;
    }
  }, [primaryTabSelectedIndex, onSelectImage, selectedController]);
};

export default React.memo(_EditorFooter);
