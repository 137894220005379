import {StyleSheet} from 'react-native';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../../constants';
import {
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';
import {IColorTheme} from '../../../types/contextTypes/themeContext';

export const styles = (theme: IColorTheme) =>
  StyleSheet.create({
    container: {
      width: Percentage.PRECENTAGE_100,
      ...BASESTYLE.inRow,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    imageView: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_50),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_50),
      backgroundColor: COLORS.WHITE,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      borderRadius: FixedValue.CONSTANT_VALUE_6,
      borderWidth: FixedValue.CONSTANT_VALUE_1,
      borderColor: COLORS.BDBDBD,
      overflow:GlobalStyleValues.HIDDEN,
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.center,
    },
    middleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme.color,
      marginVertical: scaleHeight(FixedValue.CONSTANT_VALUE_4),
    },
    createTopText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_10),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: theme.color,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_6),
    },
    topTitleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: theme.color,
    },
  });
