import {createSlice} from '@reduxjs/toolkit';
import {AppConst} from '../../constants';
import {RootState} from '../store';
import {SendReminderStateType} from '../../types/reducerTypes/authStoreTypes';
import {ResetAllState} from './logoutslice';
import {SEND_REMINDER} from '../../constants/actionTypes';

const {StoreConstants} = AppConst;

const initialState: SendReminderStateType = {
  sendReminder: {
    isLoading: false,
    failed: null,
    data: [],
    status: SEND_REMINDER.END,
    success: false,
  },
};

const SendReminderSlice = createSlice({
  initialState,
  name: StoreConstants.SEND_REMINDER,
  reducers: {
    onSendReminderStart: (state, _) => {
      state.sendReminder.isLoading = true;
      state.sendReminder.status = SEND_REMINDER.START;
      state.sendReminder.success = false;
    },
    onSendReminderSuccess: (state, action) => {
      state.sendReminder.data = action.payload?.results;
      state.sendReminder.status = SEND_REMINDER.SUCCESS;
      state.sendReminder.success = true;
    },
    onSendReminderFailed: (state, action) => {
      state.sendReminder.failed = action.payload;
      state.sendReminder.status = SEND_REMINDER.FAILED;
      state.sendReminder.success = false;
    },
    onSendReminderEnd: state => {
      state.sendReminder.isLoading = false;
      state.sendReminder.status = SEND_REMINDER.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  onSendReminderStart,
  onSendReminderSuccess,
  onSendReminderFailed,
  onSendReminderEnd,
} = SendReminderSlice.actions;
export default SendReminderSlice.reducer;
export const sendReminderState = (state: RootState) => state.sendReminder;
