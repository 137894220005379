import React from 'react';
import {Alert} from 'react-native';
import {
  launchImageLibraryAsync,
  MediaTypeOptions,
  ImagePickerResult,
} from 'expo-image-picker';
import {useFormik} from 'formik';
import {RouteProp, useRoute} from '@react-navigation/native';

import {saveTopResultType} from '../../types/componentTypes/editorType';
import {ERROR_MESSAGES, FixedValue, TEXT} from '../../constants';
import {
  handleOpenSettings,
  iterateImages,
  _ToastHandler,
} from '../../utils/helperFunctions';
import {EditorImageContext} from '../../contextAPI/editorImageContext';
import {EditorContext} from '../../contextAPI/editorContext';
import {isWebsite} from '../../utils/responsive';
import {testId} from '../../constants/enum';
import EditorService from '../../services/editorService';
import {EditorSchema} from '../../utils/formikSchema';
import {useAppDispatch} from '../reduxHooks';
import {
  onCreateTopStart,
  onUpdateTopStart,
  startEditorLoader,
} from '../../redux/reducers/editorSlice';
import {goBack} from '../../services/navigationService';
import useAwsS3 from '../libraryHooks/useAwsS3';
import {RootStackParamList} from '../../types/navigationType';
import {askForMediaLibraryPermission} from '../../utils/imageUtils';
import {options} from '../../constants/environment';
import {captureRef} from 'react-native-view-shot';
export type EditorField = {TopName: string; AddHashTag: string; Note: string};

const useEditorHooks = () => {
  const dispatch = useAppDispatch();
  const {uploadFile} = useAwsS3();
  const {
    setUserImages,
    allImageSize,
    setAllImageSize,
    resetEditorImageContext,
    clearAllSelectedImages,
  } = React.useContext(EditorImageContext);
  const {
    viewShotRef,
    items,
    editorSvgDimensions,
    resultSvgImage,
    openEditorModal,
    setResultSvgImage,
    setOpenEditorModal,
    resetEditorContext,
    clearAllItems,
    setShowCopyrightModal,
    topsFieldData,
    setTopsFieldData,
    getThumbnailImage,
    setThumbnailImage,
  } = React.useContext(EditorContext);
  const route = useRoute<RouteProp<RootStackParamList, 'EditorScreen'>>();
  const createdTopData = route.params?.createdTopData ?? undefined;
  const isEdit = route.params?.isEdit ?? false;
  const _EditorService: EditorService = EditorService.getInstance();
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isValid,
    values,
    setValues,
    resetForm,
  } = useFormik<EditorField>({
    validationSchema: EditorSchema,
    initialErrors: {
      TopName: ERROR_MESSAGES.REQUIRED_TOP_NAME,
      AddHashTag: ERROR_MESSAGES.REQUIRED_ADD_HASHTAG,
      Note: ERROR_MESSAGES.REQUIRED_NOTE,
    },
    initialTouched: {TopName: false, AddHashTag: false, Note: false},
    initialValues: {TopName: '', AddHashTag: '', Note: ''},
    onSubmit: _val => {
      setOpenEditorModal(), setShowCopyrightModal(true), setTopsFieldData(_val);
    },
  });

  const SaveUpdateTop = React.useCallback(async (): Promise<void> => {
    try {
      if (resultSvgImage != null && getThumbnailImage) {
        dispatch(startEditorLoader());
        const imageName = `mantalTop-${new Date().getTime()}`;
        const fileName = `https://${options.bucket}.s3.amazonaws.com/${imageName}`;
        const data = {
          name: topsFieldData?.TopName,
          hashTag: topsFieldData?.AddHashTag,
          image: fileName,
          note: topsFieldData?.Note,
          noteId: createdTopData?.noteId ?? FixedValue.CONSTANT_VALUE_0,
        };
        if (isEdit) {
          dispatch(onUpdateTopStart({data, id: createdTopData?.id}));
        } else {
          dispatch(onCreateTopStart(data));
        }
        const uploadedSource: any = uploadFile(
          resultSvgImage,
          getThumbnailImage,
          imageName
        );
      }
    } catch (error: any) {
      _ToastHandler(error, false);
    }
  }, [topsFieldData, isEdit]);

  const removeAllItem = React.useCallback((): void => {
    clearAllItems();
    clearAllSelectedImages();
  }, [items]);

  const _openGallery = async (): Promise<void> => {
    try {
      await askForMediaLibraryPermission();
      const result: ImagePickerResult = await launchImageLibraryAsync({
        mediaTypes: MediaTypeOptions.Images,
        quality: FixedValue.CONSTANT_VALUE_1,
        base64: true,
        allowsEditing: false,
        allowsMultipleSelection: !isWebsite(),
      });
      if (!result.canceled) {
        const {imageSize: imageSizeResult, images: imageResult} =
          iterateImages(result);
        const totalSize: number = imageSizeResult + allImageSize;
        if (totalSize >= FixedValue.CONSTANT_VALUE_10) {
          Alert.alert(ERROR_MESSAGES.FILE_TOO_LARGE);
        } else {
          setAllImageSize(totalSize);
          setUserImages(imageResult);
        }
      }
    } catch (error: any) {
      console.log(error.message);
      if (error.message === 'Media library permission not granted') {
        Alert.alert(
          '',
          ERROR_MESSAGES.ALLOW_PERMISSION,
          [
            {text: TEXT.CANCEL},
            {
              text: TEXT.GO_TO_SETTING,
              onPress: () => handleOpenSettings(),
            },
          ],
          {cancelable: false}
        );
      }
    }
  };

  const getBBoxForSave = (): saveTopResultType => {
    // if (isWebsite()) {
    //   const svg = document.getElementById(
    //     testId.te_main_svg
    //   ) as unknown as SVGSVGElement;
    //   return svg.getBBox();
    // } else {
    return {
      x: 0,
      y: 0,
      height: editorSvgDimensions.height,
      width: editorSvgDimensions.width,
    };
    // }
  };
  const onClickSaveTop = React.useCallback(async (): Promise<void> => {
    if (viewShotRef != null) {
      const viewShotResult = await captureRef(viewShotRef, {
        format: 'png',
        quality: FixedValue.CONSTANT_VALUE_010,
      });
      setThumbnailImage(viewShotResult);
    }
    const bbox = getBBoxForSave();
    const result: string = _EditorService.saveTopFromEditor({items, bbox});
    if (result.includes('Error')) {
      _ToastHandler(result, false);
    } else {
      setResultSvgImage(result);
      setOpenEditorModal();
    }
  }, [items, editorSvgDimensions, openEditorModal]);

  const updateHashTagValue = React.useCallback(
    (AddHashTag: string): void => {
      if (!AddHashTag.startsWith('#') && AddHashTag.length > 0)
        setValues({...values, AddHashTag: `#${AddHashTag}`});
      else setValues({...values, AddHashTag});
    },
    [values]
  );

  const ResetEditor = React.useCallback((): void => {
    resetForm();
    goBack();
    resetEditorImageContext();
    resetEditorContext();
  }, []);

  const TopNameError: string =
    touched.TopName && errors.TopName ? errors.TopName : '';
  const AddHashTagError: string =
    touched.AddHashTag && errors.AddHashTag ? errors.AddHashTag : '';
  const NoteError: string = touched.Note && errors.Note ? errors.Note : '';

  return {
    updateHashTagValue,
    handleChange,
    handleSubmit,
    handleBlur,
    isValid,
    values,
    setValues,
    _openGallery,
    onClickSaveTop,
    TopNameError,
    AddHashTagError,
    NoteError,
    ResetEditor,
    removeAllItem,
    SaveUpdateTop,
  };
};
export default useEditorHooks;
