import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {isWebsite, scaleHeight, scaleWidth, normalizeFont,} from '../../utils/responsive';

export const style = (theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.selfCenter,
      justifyContent: GlobalStyleValues.FLEX_END,
      width: isWebsite()
        ? FixedValue.CONSTANT_VALUE_500
        : Percentage.PRECENTAGE_100,
      alignItems: GlobalStyleValues.CENTER,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    outterPart: {
      ...BASESTYLE.absolutePosition,
      zIndex: FixedValue.CONSTANT_VALUE_MIN_1,
      height: Percentage.PRECENTAGE_100,
      width: Percentage.PRECENTAGE_100,
      backgroundColor: COLORS.LIGHT_BLACK,
    },
    subContainer: {
      width: Percentage.PRECENTAGE_100,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      backgroundColor: theme.backgroundColor,
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_18,
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_18),
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_18,
      shadowColor: COLORS._333333,
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_0,
        height: -FixedValue.CONSTANT_VALUE_3,
      },
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
      elevation: FixedValue.CONSTANT_VALUE_3,
      alignItems: GlobalStyleValues.CENTER,
    },
    marginBottom20: {
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_15),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_5),
    },
    width100Percentage: {
      width: Percentage.PRECENTAGE_100,
    },
    modelHeaderText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_15),
      fontFamily: FONT_NAMES.SF_BOLD,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      color: theme.color,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
        }
  });
