import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  scaleHeight,
  scaleWidth,
  normalizeFont,
  SCREEN_HEIGHT,
} from '../../utils/responsive';

export const customModalForSelectUserStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    modalStyle: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.selfCenter,
      justifyContent: GlobalStyleValues.FLEX_END,
      width: isWebsite()
        ? FixedValue.CONSTANT_VALUE_500
        : Percentage.PRECENTAGE_100,
      alignItems: GlobalStyleValues.CENTER,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    innerView: {
      ...BASESTYLE.flex1,
      backgroundColor: theme?.backgroundColor,
      width: Percentage.PRECENTAGE_100,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      paddingHorizontal: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_16,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_16,
      maxHeight: SCREEN_HEIGHT - scaleHeight(FixedValue.CONSTANT_VALUE_140),
      shadowColor: theme?.color,
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
      shadowRadius: FixedValue.CONSTANT_VALUE_5,
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_2,
        height: FixedValue.CONSTANT_VALUE_2,
      },
      elevation: FixedValue.CONSTANT_VALUE_2,
    },
    outterPart: {
      width: Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      ...BASESTYLE.absolutePosition,
      zIndex: FixedValue.CONSTANT_VALUE_MIN_1,
      backgroundColor: theme?.loaderBackgroundColor,
    },
    userListStyle: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: Percentage.PRECENTAGE_100,
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
    },
    imageView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_58),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      marginBottom: scaleWidth(FixedValue.CONSTANT_VALUE_18),
    },
    backgroundColorPlaceholder: {
      backgroundColor: theme?.color,
    },
    profileNameText: {
      textAlign: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      color: theme?.backgroundColor,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      textTransform: GlobalStyleValues.UPPERCASE,
    },
    buttonStyle: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.absolutePosition,
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_21),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_179),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_47),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      backgroundColor: 'transparent',
    },
    buttonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
    },
    flatListContentStyle: {
      paddingVertical: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_80),
    },
    horizontalFlatListContentStyle: {
      ...BASESTYLE.flex1,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_18),
    },
    selectedUserMainContainer: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_70),
    },
    selectedGroupView: {
      ...BASESTYLE.inRow,
    },
    selectedGroupTextStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme.color,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_15),
    },
    userListContentStyle: {
      paddingVertical: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_80),
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    headerContainer: {
      flexDirection: GlobalStyleValues.ROW,
      alignItems: GlobalStyleValues.CENTER,
      justifyContent: GlobalStyleValues.SPACE_BETWEEN,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    leftContainer: {
      flexDirection: GlobalStyleValues.ROW,
      alignItems: GlobalStyleValues.CENTER,
    },
    selectAllButton: {
      flexDirection: GlobalStyleValues.ROW,
      alignItems: GlobalStyleValues.CENTER,
      backgroundColor: COLORS._E9E9F0,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_46),
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_16),
    },
    selectAllText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.PRIMARY_BLUE,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      paddingVertical: scaleHeight(FixedValue.CONSTANT_VALUE_8),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_5),
    },
  });
