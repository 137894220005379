import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  Percentage,
} from '../../../constants';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const headerStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    container: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_44),
      //   paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_22),
      //   paddingRight: scaleWidth(FixedValue.CONSTANT_VALUE_16),
      //   paddingVertical: scaleHeight(FixedValue.CONSTANT_VALUE_14),
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
      width: Percentage.PRECENTAGE_100,

      //   backgroundColor:'red'
    },
    crossView: {
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_24),
    },
    lowOpacity: {
      opacity: FixedValue.CONSTANT_VALUE_03,
      color: COLORS.WHITE,
    },
    titleText: {
      ...BASESTYLE.absolutePosition,
      ...BASESTYLE.selfCenter,
      ...BASESTYLE.textAlignCenter,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme.color,
      zIndex: FixedValue.CONSTANT_VALUE_MIN_1,
      width: Percentage.PRECENTAGE_100,
    },
  });
