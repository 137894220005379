import moment from 'moment-timezone';
import React from 'react';
import {FixedValue} from '../constants';
import {timeformat} from '../constants/enum';
import {
  extraErrorFeild,
  IExtraErrors,
  ISignUpContext,
  ISignUpProvider,
} from '../types/contextTypes/signUpContext';
import {ICountryType} from '../types/reducerTypes/authStoreTypes';
import {calculateAgeFromDate} from '../utils/helperFunctions';

export interface ICountryTileProps {
  country: ICountryType;
  onPress: (value: ICountryType) => void;
}

const initialCountryCode = {
  name: 'United States',
  flag: '🇺🇸',
  code: 'US',
  dial_code: '+1',
};
const SignUpContext = React.createContext<ISignUpContext>({
  havePersonalEmail: false,
  setHavePersonalEmail: () => {},
  haveParentsUsername: false,
  setHaveParentsUsername: () => {},
  agreeTNC: false,
  setAgreeTNC: () => {},
  selectedCountry: initialCountryCode,
  setSelectedCountry: () => {},
  showPassword: true,
  setShowPassword: () => {},
  showCountry: false,
  setShowCountry: _val => {},
  showCalender: false,
  setShowCalender: _val => {},
  dateString: '',
  setDateString: _val => {},
  userAge: FixedValue.CONSTANT_VALUE_MIN_1,
  setUserAge: _val => {},
  onDateChange: _val => {},
  extraError: {
    UsernameError: '',
    ParentUsernameError: '',
  },
  setExtraError: (_key, _value) => {},
  showBackButton: false,
  setShowBackButton: _val => {},
  isBtnLoading: false,
  setIsBtnLoading: _val => {},
  clearSignupState: () => {},
  agreeReceiveSms: false,
  setAgreeReceiveSms: () => {},
});

const SignUpProvider = (props: ISignUpProvider): JSX.Element => {
  const [havePersonalEmail, setHavePersonalEmail] =
    React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(true);
  const [haveParentsUsername, setHaveParentsUsername] =
    React.useState<boolean>(false);
  const [agreeTNC, setAgreeTNC] = React.useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] =
    React.useState<ICountryType>(initialCountryCode);
  const [showCountry, setShowCountry] = React.useState<boolean>(false);
  const [showCalender, setShowCalender] = React.useState<boolean>(false);
  const [dateString, setDateString] = React.useState<string>('');
  const [userAge, setUserAge] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_MIN_1
  );
  const [extraError, setExtraError] = React.useState<IExtraErrors>({
    UsernameError: '',
    ParentUsernameError: '',
  });
  const [showBackButton, setShowBackButton] = React.useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = React.useState<boolean>(false);
  const [agreeReceiveSms, setAgreeReceiveSms] = React.useState<boolean>(false);

  const _setShowBackButton = React.useCallback(
    (value: boolean): void => {
      setShowBackButton(value);
    },
    [showBackButton]
  );

  const _setHavePersonalEmail = React.useCallback((): void => {
    setHavePersonalEmail((prev: boolean) => !prev);
  }, [havePersonalEmail]);

  const _setHaveParentsUsername = React.useCallback((): void => {
    setHaveParentsUsername((prev: boolean) => {
      if (prev) {
        _setExtraError('ParentUsernameError', '');
      }
      return !prev;
    });
  }, [haveParentsUsername]);

  const _setShowPassword = React.useCallback((): void => {
    setShowPassword((prev: boolean) => !prev);
  }, [showPassword]);

  const _setAgreeTNC = React.useCallback((): void => {
    setAgreeTNC((prev: boolean) => !prev);
  }, [agreeTNC]);

  const _setShowCountry = React.useCallback(
    (value: boolean): void => setShowCountry(value),
    [showCountry]
  );

  const _setSelectedCountry = React.useCallback(
    (country: ICountryType): void => {
      _setShowCountry(false);
      setSelectedCountry(country);
    },
    [selectedCountry]
  );

  const _setShowCalender = React.useCallback(
    (value: boolean): void => {
      setShowCalender(value);
    },
    [showCalender]
  );

  const _setIsBtnLoading = React.useCallback(
    (value: boolean): void => {
      setIsBtnLoading(value);
    },
    [isBtnLoading]
  );

  const _setDateString = React.useCallback(
    (value: string): void => setDateString(value),
    [dateString]
  );

  const _setUserAge = React.useCallback(
    (value: number): void => setUserAge(value),
    [userAge]
  );

  const _onDateChange = React.useCallback(
    (value: string): void => {
      _setDateString(moment(value).format(timeformat.YYYY_MM_DD));
      const getAge: number = calculateAgeFromDate(value);
      setUserAge(getAge);
    },
    [userAge, showCalender, dateString]
  );

  const _setExtraError = React.useCallback(
    (key: extraErrorFeild, value: string): void => {
      setExtraError(prev => ({...prev, [key]: value}));
    },
    [extraError]
  );

  const _setAgreeReceiveSms = React.useCallback(
    (val: boolean): void => {
      setAgreeReceiveSms(val);
    },
    [agreeReceiveSms]
  );

  const _clearSignupState = React.useCallback((): void => {
    setDateString('');
    setHavePersonalEmail(false);
    setShowPassword(true);
    setHaveParentsUsername(false);
    setAgreeTNC(false);
    setSelectedCountry(initialCountryCode);
    setUserAge(FixedValue.CONSTANT_VALUE_MIN_1);
    setExtraError({
      UsernameError: '',
      ParentUsernameError: '',
    });
    setAgreeReceiveSms(false);
  }, [
    dateString,
    havePersonalEmail,
    showPassword,
    haveParentsUsername,
    agreeTNC,
    selectedCountry,
    userAge,
    agreeReceiveSms,
  ]);

  const values = React.useMemo(
    (): ISignUpContext => ({
      havePersonalEmail,
      haveParentsUsername,
      agreeTNC,
      selectedCountry,
      showPassword,
      showCountry,
      showCalender,
      dateString,
      userAge,
      extraError,
      showBackButton,
      isBtnLoading,
      agreeReceiveSms,
      setIsBtnLoading: _setIsBtnLoading,
      setExtraError: _setExtraError,
      onDateChange: _onDateChange,
      setUserAge: _setUserAge,
      setDateString: _setDateString,
      setShowCalender: _setShowCalender,
      setShowCountry: _setShowCountry,
      setSelectedCountry: _setSelectedCountry,
      setAgreeTNC: _setAgreeTNC,
      setHaveParentsUsername: _setHaveParentsUsername,
      setHavePersonalEmail: _setHavePersonalEmail,
      setShowPassword: _setShowPassword,
      setShowBackButton: _setShowBackButton,
      clearSignupState: _clearSignupState,
      setAgreeReceiveSms: _setAgreeReceiveSms,
    }),
    [
      havePersonalEmail,
      haveParentsUsername,
      agreeTNC,
      selectedCountry,
      showPassword,
      showCountry,
      showCalender,
      dateString,
      userAge,
      extraError,
      showBackButton,
      isBtnLoading,
      agreeReceiveSms,
      setIsBtnLoading,
      setExtraError,
      setUserAge,
      setDateString,
      setShowCalender,
      setShowCountry,
      setSelectedCountry,
      setAgreeTNC,
      setHaveParentsUsername,
      setHavePersonalEmail,
      setShowPassword,
      setShowBackButton,
      setAgreeReceiveSms,
    ]
  );
  return (
    <SignUpContext.Provider value={values}>
      {props.children}
    </SignUpContext.Provider>
  );
};

export {SignUpContext, SignUpProvider};
