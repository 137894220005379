import React from 'react';
import {FlatList, ScrollView, View} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';
import {useIsFocused} from '@react-navigation/native';

import PrimaryButton from '../../components/button/primaryButton';
import TopsEmptyList from '../../components/emptyListView/topsListEmptyView';
import ImageCard from '../../components/mantelTopTabComp/imageCard';
import AppLoader from '../../components/smallDesigns/appLoader';
import {BASESTYLE, FixedValue, TEXT} from '../../constants';
import {SeeAll_ScreenMode, TopType} from '../../constants/enum';
import {HomeContext} from '../../contextAPI/homeContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import {TopDetailContext} from '../../contextAPI/topDetailContext';
import useSeeAllTopsHooks from '../../hooks/componentHooks/useSeeAllTopsHooks';
import {useAppSelector} from '../../hooks/reduxHooks';
import TopOptionsInCollectionModal from '../../modals/collectionOptionsModal';
import DistributeTopModal from '../../modals/distributeTopModal';
import EditTopModal from '../../modals/editTopModal';
import MoveToCollectionsModal from '../../modals/moveToCollectionsModal';
import {HomeStore} from '../../redux/reducers/homeSlice';
import {AllTopListProps} from '../../types/reducerTypes/searchTabStoreType';
import {isAnyTopSelected} from '../../utils/helperFunctions';
import {scaleHeight} from '../../utils/responsive';
import {allTopsStyle} from './seeAll.styles';
import {DELETE_TOPS, DISTRIBUTE_SEARCH} from '../../constants/actionTypes';
import ConfirmationModal from '../../modals/confirmationModal/confirmationModal';

const AllTopsResultList = (props: AllTopListProps): JSX.Element => {
  const {collectionBasedTopList, distributeTop, deleteTops} =
    useAppSelector(HomeStore);
  const {
    collectionsTops,
    isRefreshing,
    seeAllScreenMode,
    selectedTopsToMove,
    selectAllClicked,
    isProfileSwitchToChild,
    setSelectedTopData,
    setIsRefreshing,
    setSelectedTopsToMove,
    selectOrUnSelectSingleTop,
    updateSelectedTopModelsToMove,
    clearSelectedTopsToMove,
    setSelectedFromCollectionData,
    setInitialLoader,
    initialLoader,
    clearTopsinCollection,
  } = React.useContext(HomeContext);
  const {ColorTheme} = React.useContext(ThemeContext);
  const {
    setOpenOptionsModal,
    setOpenMoveToCollectionModal,
    setOpenMoveTopConfirmationModal,
    openMoveTopConfirmationModal,
  } = React.useContext(TopDetailContext);
  const {styles} = allTopsStyle(ColorTheme);
  const {
    onReachedEnd,
    callAllTopsListAPI,
    setCollectionModel,
    callApiForAddTopsToCollection,
    navigateToDetailScreen,
  } = useSeeAllTopsHooks();
  const isFocused = useIsFocused();
  const hasPendingRequests = selectedTopsToMove.some(
    item =>
      item.pendingRequest &&
      item.pendingRequest.totalPendingRequests &&
      item.pendingRequest.totalPendingRequests >= FixedValue.CONSTANT_VALUE_1
  );
  const confirmText = `${TEXT.MOVE_TOPS_CONFIRM_3}? \n\n${TEXT.MOVE_TOPS_CONFIRM_2}`;

  const getCollectionID = () => {
    if (seeAllScreenMode === SeeAll_ScreenMode.VIEW) {
      return props?.collectionModel?.id ?? 0;
    } else if (
      seeAllScreenMode === SeeAll_ScreenMode.MOVE_TOPS_TO_THIS_COLLECTION
    ) {
      return props?.showUncategorizedListId ?? 0;
    } else {
      return props?.showUncategorizedListId ?? 0;
    }
  };
  const handleRefresh = React.useCallback(() => {
    setIsRefreshing(true);
    callAllTopsListAPI(getCollectionID(), FixedValue.CONSTANT_VALUE_0);
  }, []);

  const showNextOptionOnClick = () => {
    if (seeAllScreenMode === SeeAll_ScreenMode.MOVE_TOPS_TO_THIS_COLLECTION) {
      if (hasPendingRequests) {
        setOpenMoveTopConfirmationModal(true);
      } else {
        callAddToColectionAPI();
      }
    } else {
      setOpenMoveToCollectionModal();
    }
  };
  //call API for move uncategorized tops to collection tops
  const callAddToColectionAPI = () => {
    const topsDataIds = selectedTopsToMove.reduce((data: number[], {id}) => {
      data.push(id);
      return data;
    }, []);
    const params = {
      topIds: topsDataIds,
      collectionId: props?.collectionModel?.id,
    };
    callApiForAddTopsToCollection(params);
  };

  React.useEffect(() => {
    setInitialLoader(true);
    setCollectionModel(props.collectionModel);
    setSelectedFromCollectionData(props?.collectionModel);
    callAllTopsListAPI(getCollectionID(), FixedValue.CONSTANT_VALUE_0);

    return () => {
      clearTopsinCollection();
    };
  }, []);

  React.useEffect(() => {
    if (props.headerValue === TEXT.UNSELECT_ALL) {
      clearSelectedTopsToMove();
      const topsData = collectionsTops?.map(item => {
        return {
          ...item,
          isSelected: true,
          selectedQuantity: FixedValue.CONSTANT_VALUE_1,
        };
      });
      setSelectedTopsToMove(topsData);
    } else {
      clearSelectedTopsToMove();
    }
  }, [selectAllClicked]);

  React.useEffect(() => {
    if (distributeTop.status === DISTRIBUTE_SEARCH.SUCCESS) {
      setInitialLoader(true);
      callAllTopsListAPI(getCollectionID(), FixedValue.CONSTANT_VALUE_0);
    } else if (distributeTop.status === DISTRIBUTE_SEARCH.FAILED) {
      callAllTopsListAPI(getCollectionID(), FixedValue.CONSTANT_VALUE_0);
    }
  }, [distributeTop.status]);

  React.useEffect(() => {
    switch (deleteTops.status) {
      case DELETE_TOPS.SUCCESS:
        callAllTopsListAPI(getCollectionID(), FixedValue.CONSTANT_VALUE_0);
        break;
      default:
        break;
    }
  }, [deleteTops.status]);

  const RenderList = React.useMemo((): JSX.Element | null => {
    if (collectionsTops?.length > FixedValue.CONSTANT_VALUE_0) {
      return (
        <FlatList
          numColumns={FixedValue.CONSTANT_VALUE_2}
          data={collectionsTops}
          keyExtractor={item => `TopNumber-${item.id}`}
          onEndReached={() => onReachedEnd(getCollectionID())}
          onEndReachedThreshold={FixedValue.CONSTANT_VALUE_1}
          showsVerticalScrollIndicator={false}
          style={BASESTYLE.flex1}
          contentContainerStyle={{
            paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_35),
          }}
          refreshControl={
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={handleRefresh}
            />
          }
          renderItem={({item}) => (
            <ImageCard
              key={item.id}
              {...item}
              isProfileSwitchToChild={isProfileSwitchToChild}
              type={TopType.collections}
              showSelectIcon={
                props?.displayMode === SeeAll_ScreenMode.VIEW ? false : true
              }
              onSelectOrUnselectTops={() => {
                setSelectedFromCollectionData(props?.collectionModel);
                updateSelectedTopModelsToMove(item);
                selectOrUnSelectSingleTop(item);
              }}
              isSelected={item.isSelected}
              onMenuPress={() => {
                setSelectedFromCollectionData(props?.collectionModel);
                setSelectedTopData(item);
                setOpenOptionsModal();
              }}
              onShowDetailPress={() => {
                setSelectedTopData(item);
                setSelectedFromCollectionData(props?.collectionModel);
                navigateToDetailScreen(item, TopType.collections);
              }}
            />
          )}
        />
      );
    }
    return null;
  }, [collectionsTops, selectedTopsToMove, seeAllScreenMode]);

  const RenderEmptyUI = React.useMemo((): JSX.Element | null => {
    if (collectionsTops?.length === FixedValue.CONSTANT_VALUE_0) {
      return (
        <ScrollView
          style={BASESTYLE.flex1}
          contentContainerStyle={BASESTYLE.flex1}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={handleRefresh}
            />
          }
        >
          <TopsEmptyList
            emptyViewTitle={TEXT.YOU_HAVE_NO_TOPS}
            emptyViewDesc={TEXT.OPTIONS_TO_CREATE_TOP}
          />
        </ScrollView>
      );
    }
    return null;
  }, [collectionsTops]);

  return (
    <View style={styles.contentContainer}>
      {initialLoader && <AppLoader />}
      {!collectionBasedTopList.isLoading && !initialLoader && RenderEmptyUI}
      {!initialLoader && RenderList}
      {isFocused && (
        <>
          <EditTopModal />
          <TopOptionsInCollectionModal />
          <MoveToCollectionsModal />
          <DistributeTopModal />
        </>
      )}

      {collectionsTops.some(isAnyTopSelected) && !isProfileSwitchToChild && (
        <View style={BASESTYLE.inCenter}>
          <PrimaryButton
            buttonLabel={
              seeAllScreenMode ===
              SeeAll_ScreenMode.MOVE_TOPS_TO_OTHER_COLLECTION
                ? TEXT.MOVE_TO_COLLECTION
                : TEXT.ADD_TO_COLLECTION
            }
            buttonwidth={styles.primaryButtonStyle}
            labelStyle={styles.primaryButtonLabelstyle}
            onPress={() => showNextOptionOnClick()}
          />
        </View>
      )}
      {openMoveTopConfirmationModal && isFocused && (
        <ConfirmationModal
          title={confirmText}
          modalVisible={openMoveTopConfirmationModal}
          onPressYes={() => {
            setOpenMoveTopConfirmationModal(false);
            callAddToColectionAPI();
          }}
          onShowHide={() => {
            setOpenMoveTopConfirmationModal(false);
          }}
        />
      )}
    </View>
  );
};

export default React.memo(AllTopsResultList);
