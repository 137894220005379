import React from 'react';
import {View, Text} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {ChildEmptyListProps} from '../../types/reducerTypes/searchTabStoreType';
import NoChildData from '../renderSvgs/noChildDataPlaceholder';
import {styles} from './emptyListView.style';

const EmptyChildList = (props: ChildEmptyListProps): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);

  return (
    <View style={style.searchView}>
      <NoChildData />
      <Text style={style.noDataHeading}>{props.emptyViewTitle}</Text>
      <Text style={style.noDataSubHeading}>{props?.emptyViewSubTitle}</Text>
    </View>
  );
};

export default React.memo(EmptyChildList);
