import React from 'react';
import {View, Text, Pressable} from 'react-native';
import CreateCollectionButton from '../button/createCollectionButton';
import {CreateCollectionContext} from '../../contextAPI/createCollectionContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import CreateCollectionModal from '../../modals/createCollectionModal';
import {manteltabCompStyle} from './manteltabComp.style';
import ArrowDown from '../renderSvgs/arrowDown';
import {HomeContext} from '../../contextAPI/homeContext';
import {FixedValue, Percentage, ROUTE_NAME, TEXT} from '../../constants';
import {useAppSelector} from '../../hooks/reduxHooks';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import {USER_PROFILE_DATA} from '../../constants/actionTypes';
import {
  isAnyChildUserIsApproved,
  storeDataLocally,
} from '../../utils/helperFunctions';
import {StorageConstants} from '../../constants/appConst';
import {ProfileTabContext} from '../../contextAPI/profileTabContext';
import {IUserProfileData} from '../../types/reducerTypes/userProfileTypes';
import GiftIcon from '../renderSvgs/giftIcon';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import SearchIcon from '../renderSvgs/searchIcon';
import {navigate} from '../../services/navigationService';

const HomeHeaderContent = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = manteltabCompStyle(ColorTheme);
  const {setOpenCreateCollectionModal, setCollectionData} = React.useContext(
    CreateCollectionContext
  );
  const {userData} = useAppSelector(userProfileStore);
  const {parentChildListData} = React.useContext(ProfileTabContext);
  const {
    homeScreenUserData,
    isProfileSwitchToChild,
    setHomeScreenUserData,
    setProfileSwitchListModal,
    setOpenReedemModal,
  } = React.useContext(HomeContext);
  const UserDetail = userData?.success;
  const showProfileSwitchIcon = UserDetail?.role === TEXT.ROLE_PARENT;

  const saveSwitchProfileData = React.useCallback(
    async (userDetail: IUserProfileData): Promise<void> => {
      await storeDataLocally(StorageConstants.SWITCH_USER_DETAIL, userDetail);
      setHomeScreenUserData(userDetail);
    },
    [homeScreenUserData]
  );

  React.useEffect((): void => {
    switch (userData.status) {
      case USER_PROFILE_DATA.SUCCESS:
        if (
          userData?.success?.id === homeScreenUserData?.id &&
          JSON.stringify(userData?.success) !==
            JSON.stringify(homeScreenUserData) &&
          userData?.success !== null
        ) {
          saveSwitchProfileData(userData?.success);
        }
        break;
      case USER_PROFILE_DATA.FAILED:
        break;
      default:
        break;
    }
  }, [userData.status]);

  const fullName =
    homeScreenUserData?.firstName + ' ' + homeScreenUserData?.lastName ?? '';
  const showArrowIcon =
    parentChildListData !== null &&
    parentChildListData?.length > FixedValue.CONSTANT_VALUE_0 &&
    parentChildListData.some(isAnyChildUserIsApproved);
  return (
    <>
      <View
        style={[styles.headingView, {height: FixedValue.CONSTANT_VALUE_50}]}
        dataSet={{media: ids.headingView}}
      >
        <View
          style={[styles.flexDirectionRow, {width: Percentage.PRECENTAGE_60}]}
        >
          <View
            style={[
              styles.headerHeightWidth,
              {maxWidth: Percentage.PRECENTAGE_100},
            ]}
          >
            <Text
              numberOfLines={FixedValue.CONSTANT_VALUE_1}
              style={[styles.titleText, {width: Percentage.PRECENTAGE_100}]}
            >
              {fullName}
            </Text>
          </View>
          {showProfileSwitchIcon && showArrowIcon && (
            <Pressable
              onPress={() => {
                setProfileSwitchListModal(true);
              }}
              style={styles.marginLeft12}
            >
              <ArrowDown stroke={ColorTheme.color} />
            </Pressable>
          )}
        </View>
        {!isProfileSwitchToChild && (
          <View style={styles.headerIconContainer}>
            <Pressable
              onPress={() => {
                navigate(ROUTE_NAME.SEARCH_TAB);
              }}
              style={styles.marginRight18}
            >
              <SearchIcon
                height={scaleHeight(FixedValue.CONSTANT_VALUE_23)}
                width={scaleWidth(FixedValue.CONSTANT_VALUE_25)}
                stroke={ColorTheme.color}
              />
            </Pressable>

            <Pressable
              onPress={() => {
                setOpenReedemModal(true);
              }}
              style={styles.marginRight18}
            >
              <GiftIcon
                height={scaleHeight(FixedValue.CONSTANT_VALUE_23)}
                width={scaleWidth(FixedValue.CONSTANT_VALUE_25)}
                stroke={ColorTheme.color}
              />
            </Pressable>
            <CreateCollectionButton
              onPress={() => {
                setCollectionData(null);
                setOpenCreateCollectionModal();
              }}
            />
          </View>
        )}
      </View>
      <CreateCollectionModal />
    </>
  );
};

export default React.memo(HomeHeaderContent);
