import React from 'react';
import {Modal, Pressable, View, Text} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {BottomListWithCheckboxProps} from '../../types/reducerTypes/searchTabStoreType';
import {style} from './style';
import ListWithCheckbox from '../../components/smallDesigns/listWithCheckbox/listWithCheckbox';
import ModalCloseLine from '../../components/renderSvgs/modalCloseLine';
import {useAppSelector} from '../../hooks/reduxHooks';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import {HomeContext} from '../../contextAPI/homeContext';

const BottomListModalWithCheckbox = (
  props: BottomListWithCheckboxProps
): JSX.Element => {
  const {showLoggedInUser = true, headerText} = props.customModaldata;
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = style(ColorTheme);
  const {customModaldata} = props;
  const {userData} = useAppSelector(userProfileStore);
  const UserDetail = userData?.success;
  const {homeScreenUserData} = React.useContext(HomeContext);
  const isLoginUserSelected: boolean =
    UserDetail?.id === homeScreenUserData?.id;
  return (
    <Modal transparent visible={customModaldata?.modalVisible}>
      <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
        <Pressable
          style={styles.outterPart}
          onPress={() => customModaldata?.closeModal(false)}
        />
        <View style={styles.subContainer}>
          <Pressable
            onPress={() => customModaldata?.closeModal(false)}
            style={styles.marginBottom20}
          >
            <ModalCloseLine stroke={ColorTheme.color} />
          </Pressable>
          {headerText && (
            <View>
              <Text style={styles.modelHeaderText}>{headerText}</Text>
            </View>
          )}
          {showLoggedInUser && (
            <View key={UserDetail?.id} style={styles.width100Percentage}>
              <ListWithCheckbox
                key={UserDetail?.id}
                item={UserDetail}
                onPress={customModaldata.onPress}
                isSelected={isLoginUserSelected}
              />
            </View>
          )}
          {customModaldata.listData?.map(item => (
            <View key={item.id} style={styles.width100Percentage}>
              <ListWithCheckbox
                key={item.id}
                item={item}
                onPress={customModaldata.onPress}
                isSelected={homeScreenUserData?.id === item?.id}
              />
            </View>
          ))}
        </View>
      </View>
    </Modal>
  );
};

export default BottomListModalWithCheckbox;
