/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext} from 'react';
import {BackHandler, Text, View} from 'react-native';
import CreateTradeHeader from '../../components/header/createTradeHeader';
import LayoutContainer from '../../components/layout/layoutContainer';
import {TradeContext} from '../../contextAPI/tradeContext';
import SelectMyTops from './selectMyTops';
import {tradeStyle} from './offer.styles';
import {ThemeContext} from '../../contextAPI/themeContext';
import {FixedValue, TEXT} from '../../constants';
import {goBack} from '../../services/navigationService';
import {HomeContext} from '../../contextAPI/homeContext';
import TitleWithButton from '../../components/header/titleWithButton';
import {RouteProp, useIsFocused, useRoute} from '@react-navigation/native';
import PrimaryButton from '../../components/button/primaryButton';
import useCreateTradeHooks from '../../hooks/componentHooks/useCreateTradeHooks';
import {RootStackParamList} from '../../types/navigationType';
import {onClearFavoriteUserListEnd} from '../../redux/reducers/userProfileSlice';
import {useAppDispatch} from '../../hooks/reduxHooks';
import {FavoriteContext} from '../../contextAPI/favoriteContext';

let nextClicked = false;

const CreateOfferScreen: React.FC = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = tradeStyle(ColorTheme);
  const {
    setCollectionSelectedTops,
    setCollectionSelectedTopsOther,
    isTopEdit,
    setCounterOffer,
    collectionSelectedTops,
    setTotalTop,
    totalTops,
    clearCollectionsList,
    setIsTopEdit,
    setFromProposeTrade,
  } = React.useContext(TradeContext);
  const {setSearchUserListText, setSearchFavouriteUserListText} =
    React.useContext(HomeContext);
  const isFocused = useIsFocused();
  const {navigateSelectTradeUser, callCollectionsAPI} = useCreateTradeHooks();
  const route = useRoute<RouteProp<RootStackParamList, 'CreateTrade'>>();
  const isEdit = route?.params?.isEdit;
  const fromPropose = route?.params?.fromPropose;
  const dispatch = useAppDispatch();
  const {setFavoriteListData} = useContext(FavoriteContext);

  const onCrossClick = () => {
    setCollectionSelectedTops([]);
    setSearchUserListText('');
    setCollectionSelectedTopsOther([]);
    setSearchFavouriteUserListText('');
    setFavoriteListData([]);
    dispatch(onClearFavoriteUserListEnd());
    setCounterOffer(false);
    setIsTopEdit(false);
    setFromProposeTrade(false);
    goBack();
  };

  const onBackClick = () => {
    if (fromPropose) {
      onCrossClick();
    }
    goBack();
  };

  const onBackButtonClick = () => {
    if (isTopEdit) {
      onBackClick();
    } else {
      onCrossClick();
    }
    return true;
  };

  const navigateScreen = () => {
    if (!nextClicked) {
      nextClicked = true;
      navigateSelectTradeUser();
    }
  };

  React.useEffect(() => {
    if (isFocused) {
      clearCollectionsList();
      callCollectionsAPI(FixedValue.CONSTANT_VALUE_0);
      const totalTops = collectionSelectedTops.reduce(
        (total, data) => data.selectedQuantity! + total,
        FixedValue.CONSTANT_VALUE_0
      );
      setTotalTop(totalTops);
      if (isEdit) {
        setIsTopEdit(true);
      }
      // android back button handler.
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        onBackButtonClick
      );
      nextClicked = false;
      return () => backHandler.remove();
    }
  }, [isFocused]);

  const showCollections = () => {
    if (isFocused) {
      return <SelectMyTops />;
    }
  };

  return (
    <LayoutContainer>
      <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
        <View style={styles.headerView}>
          {isTopEdit ? (
            <TitleWithButton
              title={TEXT.CREATE_TRADE_OFFER}
              onPress={onBackClick}
            />
          ) : (
            <CreateTradeHeader onPress={onCrossClick} />
          )}
          <View style={styles.selectTopsView}>
            <Text style={styles.select_your_tops}>
              {TEXT.SELECT_YOUR_TOPS_FOR_TRADE}
            </Text>
          </View>
        </View>
        {showCollections()}
        {collectionSelectedTops.length > FixedValue.CONSTANT_VALUE_0 &&
          totalTops > FixedValue.CONSTANT_VALUE_0 && (
            <View style={styles.nextButtonView}>
              <PrimaryButton
                buttonwidth={styles.nextButtonStyle}
                labelStyle={styles.nextButtonTextStyle}
                buttonLabel={TEXT.NEXT}
                onPress={() => navigateScreen()}
                element={
                  <Text style={styles.nextButtonTextStyle}>
                    {`Total: ${totalTops}`}
                  </Text>
                }
              />
            </View>
          )}
      </View>
    </LayoutContainer>
  );
};

export default React.memo(CreateOfferScreen);
