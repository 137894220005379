import React from 'react';
import {FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import {SeeAll_ScreenMode, TopType} from '../../constants/enum';
import {HomeContext} from '../../contextAPI/homeContext';
import {
  onCollectionsSpecificTopListsStart,
  HomeStore,
  onAddTopsToCollectionStart,
  moveTopsToCollectionStart,
} from '../../redux/reducers/homeSlice';
import {goBack, navigate} from '../../services/navigationService';
import {
  CollectionModel,
  TopModel,
} from '../../types/reducerTypes/searchTabStoreType';
import {useAppDispatch, useAppSelector} from '../reduxHooks';

const useSeeAllTopsHooks = () => {
  const dispatch = useAppDispatch();
  const {collectionBasedTopList} = useAppSelector(HomeStore);
  const {
    seeAllScreenMode,
    selectAllClicked,
    isProfileSwitchToChild,
    setSeeAllScreenMode,
    setSelectAllClicked,
    collectionsTops,
  } = React.useContext(HomeContext);
  const [collectionModel, setCollectionModel] =
    React.useState<CollectionModel>();

  const callAllTopsListAPI = React.useCallback(
    (collectionId: number, page: number): void => {
      const params = {collectionId, page, size: FixedValue.CONSTANT_VALUE_10};
      dispatch(onCollectionsSpecificTopListsStart(params));
    },
    []
  );

  // Call API for moving uncategorized tops to some collection tops
  const callApiForAddTopsToCollection = React.useCallback(
    (params: any): void => {
      dispatch(onAddTopsToCollectionStart(params));
    },
    []
  );

  // Call API for moving uncategorized tops to some collection tops
  const callApiToMoveToCollection = React.useCallback((params: any): void => {
    dispatch(moveTopsToCollectionStart(params));
  }, []);

  const onReachedEnd = React.useCallback(
    (id: number): void => {
      if (
        collectionBasedTopList.success != null &&
        collectionBasedTopList.success.pageNumber <
          collectionBasedTopList.success.totalPages
      ) {
        callAllTopsListAPI(
          id ?? FixedValue.CONSTANT_VALUE_0,
          collectionBasedTopList.success.pageNumber +
            FixedValue.CONSTANT_VALUE_1
        );
      }
    },
    [collectionBasedTopList]
  );

  const navigateToDetailScreen = React.useCallback(
    (topDetails: TopModel, comingFrom: TopType): void => {
      navigate(ROUTE_NAME.TOP_DETAIL_SCREEN, {topDetails, comingFrom});
    },
    []
  );

  React.useEffect(() => {
    if (collectionModel) {
      setCollectionModel(collectionModel);
    }
  }, [collectionModel]);

  const changeViewToSelectOnSeeAll = React.useCallback((): void => {
    if (
      isProfileSwitchToChild ||
      (collectionsTops?.length === FixedValue.CONSTANT_VALUE_0 &&
        !isProfileSwitchToChild)
    ) {
      goBack();
    }
    if (seeAllScreenMode === SeeAll_ScreenMode.VIEW) {
      setSeeAllScreenMode(SeeAll_ScreenMode.MOVE_TOPS_TO_OTHER_COLLECTION);
    } else {
      setSeeAllScreenMode(SeeAll_ScreenMode.VIEW);
      setSelectAllClicked(TEXT.UNSELECT_ALL);
    }
  }, [seeAllScreenMode, selectAllClicked, collectionsTops, isProfileSwitchToChild]);
  return {
    onReachedEnd,
    navigateToDetailScreen,
    callAllTopsListAPI,
    setCollectionModel,
    callApiForAddTopsToCollection,
    changeViewToSelectOnSeeAll,
    callApiToMoveToCollection,
  };
};

export default useSeeAllTopsHooks;
