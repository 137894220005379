import React from 'react';
import {View} from 'react-native';

import {ThemeContext} from '../../../contextAPI/themeContext';
import SafeAreaContainer from '../../../components/layout/safeAreaContainer';
import {TEXT} from '../../../constants';
import {ShiftingSettingStyle} from './style';
import ShiftingSettingScreen from '../shiftingSetting/shiftingSettingContent';
import TitleWithButton from '../../../components/header/titleWithButton';

const ShiftingSetting = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = ShiftingSettingStyle(ColorTheme);
  return (
    <SafeAreaContainer>
      <View
        style={styles.settingMainContainer}
        dataSet={{media: ids.settingMainContainer}}
      >
        <TitleWithButton title={TEXT.SETTING} />
        <ShiftingSettingScreen />
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(ShiftingSetting);
