import {StyleSheet} from 'react-native';
import {
  BASESTYLE,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
} from '../../../constants';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const styles = (theme: IColorTheme) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    flagStyle: {
      height: normalizeFont(FixedValue.CONSTANT_VALUE_21),
      width: normalizeFont(FixedValue.CONSTANT_VALUE_28),
      resizeMode: GlobalStyleValues.CONTAIN,
    },
    middleText: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_36),
    },
    textStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: theme.color,
    },
  });
