import {StyleSheet} from 'react-native';
import {FixedValue, FONT_NAMES, GlobalStyleValues} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';

export const style = (_theme: IColorTheme) =>
  StyleSheet.create({
    tagContainer: {
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_52),
      paddingVertical: scaleHeight(FixedValue.CONSTANT_VALUE_6),
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_12),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_12),
      marginBottom: scaleWidth(FixedValue.CONSTANT_VALUE_12),
    },
    tagText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      textAlign: GlobalStyleValues.CENTER,
    },
  });
