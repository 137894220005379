import React from 'react';
import {View} from 'react-native';

import ModalContainer from '../../components/layout/modalContainer';
import {ThemeContext} from '../../contextAPI/themeContext';
import {HomeContext} from '../../contextAPI/homeContext';
import {ChildProfileApprovedModalStyle} from './childProfileApproved.style';
import ChildProfileApprovedContent from './childProfileApprovedContent';

const ChildProfileApprovedModal = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = ChildProfileApprovedModalStyle(ColorTheme);
  const {openChildProfileApproveModal, setOpenChildProfileApproveModal} =
    React.useContext(HomeContext);
  return (
    <ModalContainer
      visible={openChildProfileApproveModal}
      onPressOutside={setOpenChildProfileApproveModal}
    >
      <View style={styles.subContainer}>
        <ChildProfileApprovedContent />
      </View>
    </ModalContainer>
  );
};

export default ChildProfileApprovedModal;
