import React from 'react';
import {Svg} from 'react-native-svg';
import ReactDOMServer from 'react-dom/server';
import Parser from 'fast-xml-parser';

import {isWebsite} from '../utils/responsive';
import {
  TopSvgSaveObject,
  EditorItemRef,
  FontFamily,
  TextRefType,
  ImageInfo,
  ImageRefType,
  TopEditorItemType,
  ShapeInfo,
  ShapeRefType,
  TextInfo,
} from '../types/componentTypes/editorType';
import {FixedValue, Percentage} from '../constants';
import {
  NUMBER_WITH_DIGITS,
  ROTATION_INITIAL_MATCHER_REGEX,
  ROTATION_REGEX,
  SCALE_REGEX,
} from '../constants/regex';
import RenderUserImages from '../components/editorComp/images/renderUserImages';
import BasicCircle, {
  CIRCLE_RADIUS,
} from '../components/editorComp/shapes/circle/basicCircle';
import BasicRectangle, {
  RECTANGLE_SIZE,
} from '../components/editorComp/shapes/rectangle/basicRectangle';
import BasicSquare, {
  SQUARE_SIZE,
} from '../components/editorComp/shapes/square/basicSquare';
import {
  getFontImport,
  getFontsName,
  _ToastHandler,
} from '../utils/helperFunctions';
import BasicText from '../components/editorComp/texts/basicText';
import {View} from 'react-native';

const parserOptions = {
  attributeNamePrefix: '',
  ignoreAttributes: false,
  ignoreNameSpace: false,
  allowBooleanAttributes: true,
  parseAttributeValue: true,
  trimValues: false,
};

class EditorService {
  static isPlatformWeb: boolean = isWebsite();
  static serviceInstance: EditorService | null = null;

  static getInstance() {
    if (!EditorService.serviceInstance) {
      EditorService.serviceInstance = new EditorService();
    }
    return EditorService.serviceInstance;
  }

  /** Maps mobile SVG tags to their corresponding web SVG tags */
  private displayOnWeb(children: JSX.Element): unknown {
    const childToWeb = (child: JSX.Element): JSX.Element => {
      const {type, props} = child;
      const name = type && type.displayName;
      const webName = name && name[0].toLowerCase() + name.slice(1);
      const Tag = webName ? webName : type;
      if (Tag === 'text') {
        return <Tag {...props}>{props.children}</Tag>;
      } else {
        return <Tag {...props}>{this.displayOnWeb(props.children)}</Tag>;
      }
    };
    return React.Children.map(children, childToWeb);
  }

  /** Checks if the JSX.Element passed is a text item from the Editor */
  private itemIsText(item: JSX.Element): boolean {
    // @ts-ignore -- used to get item ref
    const ref = item.ref as EditorItemRef;
    return ref.current.getName() === 'Text';
  }

  public renderTopSvgToString({items, bbox}: TopSvgSaveObject): string {
    let xMin, yMin, xMax, yMax, width, height;
    if (EditorService.isPlatformWeb) {
      xMin = bbox.x - FixedValue.CONSTANT_VALUE_10;
      yMin = bbox.y - FixedValue.CONSTANT_VALUE_10;
      xMax = bbox.x + bbox.width + FixedValue.CONSTANT_VALUE_10;
      yMax = bbox.y + bbox.height + FixedValue.CONSTANT_VALUE_10;
      width = xMax - xMin;
      height = yMax - yMin;
    } else {
      xMin = bbox.x;
      yMin = bbox.y;
      height = bbox.height;
      width = bbox.width;
    }
    let openTag = `<svg xmlns="http://www.w3.org/2000/svg" height="${height}" width="${width}" viewBox="${xMin} ${yMin} ${width} ${height}">`;

    if (items.some(this.itemIsText)) {
      const fontImportString: string = items
        // @ts-ignore -- used to get item ref
        .map(item => item.ref.current.getPosition().font as FontFamily)
        .filter(item => !!item)
        .map(getFontImport)
        .join();
      openTag += `<defs><style>${fontImportString}</style></defs>`;
    }

    const itemStrings = items.map((item: JSX.Element) => {
      // @ts-ignore -- used to get item ref
      const ref = item.ref as EditorItemRef;
      if (ref.current.getName() === 'Text') {
        return (ref.current as TextRefType).renderElementToSave();
      } else {
        let element = ref.current.renderElementToSave();
        if (!EditorService.isPlatformWeb) {
          element = this.displayOnWeb(
            element as JSX.Element
          ) as React.ReactElement;
        }
        return ReactDOMServer.renderToStaticMarkup(
          element as React.ReactElement
        );
      }
    });
    /* Return complete svg string */
    return `${openTag + itemStrings.join('')}</svg>`;
  }

  public saveTopFromEditor(svg: TopSvgSaveObject): string {
    try {
      const svgString: string = this.renderTopSvgToString(svg);
      return encodeURIComponent(svgString);
    } catch (error: any) {
      return `Error: ${error.message}`;
    }
  }

  private getItemPositionOverride(itemName: string, json: any) {
    if (itemName === 'image') {
      let scale: number;
      let rotation: number;
      try {
        scale = +json.transform
          .match(SCALE_REGEX)[0]
          .replace(SCALE_REGEX, '$1');
        rotation = +json.transform
          .match(ROTATION_REGEX)[0]
          .replace(ROTATION_REGEX, '$1');
      } catch (error) {
        scale = json.scale;
        rotation = json.rotation;
      }

      return {
        itemType: TopEditorItemType.IMAGE,
        x: json.x as number,
        y: json.y as number,
        scale,
        rotation,
      };
    } else if (itemName === 'text') {
      let scale: number;
      let rotation: number;

      try {
        scale = +json.transform
          .match(SCALE_REGEX)[0]
          .replace(SCALE_REGEX, '$1');
        rotation = +json.transform
          .match(ROTATION_REGEX)[0]
          .replace(ROTATION_REGEX, '$1');
      } catch (error: unknown) {
        scale = json.scale;
        rotation = json.rotation;
      }

      const tempFont: string = json['font-family'].split(',')[0];
      const width: number = json.width as number;
      const height: number = json.height as number;
      const x: number = (json.x as number) + width / 2;
      const y: number = json.y as number;
      const font: string = getFontsName(tempFont);

      return {
        itemType: TopEditorItemType.TEXT,
        textValue: json['#text'] as string,
        x,
        y,
        height,
        width,
        fillColor: json.fill as string,
        strokeColor: json.stroke as string,
        strokeWidth: json['stroke-width'] as number,
        font,
        scale,
        rotation,
      };
    } else {
      const rotateInitialMatcher = ROTATION_INITIAL_MATCHER_REGEX;
      const shapeMatcher = NUMBER_WITH_DIGITS;
      switch (itemName) {
        case 'circle': {
          const rotation: number = +json.transform
            .match(rotateInitialMatcher)[0]
            .match(shapeMatcher)[0];

          return {
            itemType: 'TopEditorItemType.SHAPE',
            cx: json.cx as number,
            cy: json.cy as number,
            fillColor: json.fill as string,
            strokeColor: json.stroke as string,
            strokeWidth: json['stroke-width'] as number,
            scale: (json.r as number) / CIRCLE_RADIUS,
            rotation: rotation,
          };
        }
        case 'rectangle': {
          const scale: number = (json.height as number) / RECTANGLE_SIZE.height;
          const scaledX: number = json.x as number;
          const scaledY: number = json.y as number;
          const width: number = RECTANGLE_SIZE.width;
          const height: number = RECTANGLE_SIZE.height;
          const x: number = scaledX + (width * scale) / 2;
          const y: number = scaledY + (height * scale) / 2;
          const rotation: number = +json.transform.match(shapeMatcher)[0];
          return {
            itemType: 'TopEditorItemType.SHAPE',
            x,
            y,
            width,
            height,
            fillColor: json.fill as string,
            strokeColor: json.stroke as string,
            strokeWidth: json['stroke-width'] as number,
            rotation,
            scale,
          };
        }
        case 'square': {
          const scale: number = (json.height as number) / SQUARE_SIZE;
          const scaledX: number = json.x as number;
          const scaledY: number = json.y as number;
          const width: number = SQUARE_SIZE;
          const height: number = SQUARE_SIZE;
          const x: number = scaledX + (width * scale) / 2;
          const y: number = scaledY + (height * scale) / 2;
          const rotation: number = +json.transform.match(shapeMatcher)[0];
          return {
            itemType: 'TopEditorItemType.SHAPE',
            x,
            y,
            width,
            height,
            fillColor: json.fill as string,
            strokeColor: json.stroke as string,
            strokeWidth: json['stroke-width'] as number,
            rotation,
            scale,
          };
        }
        default: {
          console.error(
            `itemName does not match any known itemNames. itemName: ${itemName}`
          );
          break;
        }
      }
    }
  }

  private translateSvgXmlToJsxElements(
    svgXml: string,
    selectItemCallback: (itemIndex: number) => void,
    disableInteraction?: boolean,
    offset?: {x: number; y: number}
  ): JSX.Element[] {
    const noInteraction = !!disableInteraction;
    const unorderedItems: Array<ImageInfo | TextInfo | ShapeInfo> = [];
    const sortedItemsToAdd: JSX.Element[] = [];

    /** Based on the itemName(what item is it), add the correct JSX element to the array */
    const addItemToArray = (item: ImageInfo | ShapeInfo): void => {
      if (item.itemName === 'image') {
        const ref = React.createRef<ImageRefType>();
        sortedItemsToAdd.push(
          <RenderUserImages
            ref={ref}
            key={item.itemElementId}
            elementId={item.itemElementId}
            imageSource={(item as ImageInfo).imageSource}
            width={(item as ImageInfo).width}
            height={(item as ImageInfo).height}
            selectItemCallback={() => selectItemCallback(item.itemIndex)}
            itemPositionOverride={item.positionOverride}
            elementIndex={item.itemIndex}
            disableInteraction={noInteraction}
          />
        );
      } else if (item.itemName === 'text') {
        const ref = React.createRef<TextRefType>();
        sortedItemsToAdd.push(
          <BasicText
            ref={ref}
            key={item.itemElementId}
            elementId={item.itemElementId}
            selectItemCallback={() => selectItemCallback(item.itemIndex)}
            itemPositionOverride={item.positionOverride}
            elementIndex={item.itemIndex}
            disableInteraction={noInteraction}
          />
        );
        return;
      } else {
        const ref = React.createRef<ShapeRefType>();
        switch (item.itemName) {
          case 'circle': {
            sortedItemsToAdd.push(
              <BasicCircle
                ref={ref}
                key={item.itemElementId}
                elementId={item.itemElementId}
                selectItemCallback={() => selectItemCallback(item.itemIndex)}
                itemPositionOverride={item.positionOverride}
                elementIndex={item.itemIndex}
                disableInteraction={noInteraction}
              />
            );
            break;
          }
          case 'rectangle': {
            sortedItemsToAdd.push(
              <BasicRectangle
                ref={ref}
                key={item.itemElementId}
                elementId={item.itemElementId}
                selectItemCallback={() => selectItemCallback(item.itemIndex)}
                itemPositionOverride={item.positionOverride}
                elementIndex={item.itemIndex}
                disableInteraction={noInteraction}
              />
            );
            break;
          }
          case 'square': {
            sortedItemsToAdd.push(
              <BasicSquare
                ref={ref}
                key={item.itemElementId}
                elementId={item.itemElementId}
                selectItemCallback={() => selectItemCallback(item.itemIndex)}
                itemPositionOverride={item.positionOverride}
                elementIndex={item.itemIndex}
                disableInteraction={noInteraction}
              />
            );
            break;
          }
          default:
            _ToastHandler(
              `Something went wrong when calling addItemCallback; itemName: ${item.itemName}`,
              false
            );
            break;
        }
      }
    };

    const getItemInfo = (itemValue: any, subItemValue?: any) => {
      let itemElementId;
      if (itemValue.id === undefined) {
        itemElementId = subItemValue.id;
      } else {
        itemElementId = itemValue.id;
      }
      const itemName: string = itemElementId.match(/\w+(?=\-top)/g)[0];
      const itemIndex: number = itemElementId.match(/\d+$/g)[0];
      let positionOverride: any;

      if (itemValue.id === undefined) {
        positionOverride = this.getItemPositionOverride(itemName, subItemValue);
      } else {
        positionOverride = this.getItemPositionOverride(itemName, itemValue);
      }

      if (!!offset) {
        if (
          positionOverride.hasOwnProperty('cx') ||
          positionOverride.hasOwnProperty('cy')
        ) {
          positionOverride = {
            ...positionOverride,
            cx: positionOverride.cx - offset.x,
            cy: positionOverride.cy - offset.y,
          };
        } else {
          positionOverride = {
            ...positionOverride,
            x: positionOverride.x - offset.x,
            y: positionOverride.y - offset.y,
          };
        }
      }

      if (itemName === 'image') {
        let height: number;
        let width: number;
        let imageSource: string;

        if (itemValue.id === undefined) {
          height = subItemValue.height as number;
          width = subItemValue.width as number;
          imageSource = subItemValue.href as string;
        } else {
          height = itemValue.height as number;
          width = itemValue.width as number;
          imageSource = itemValue.href as string;
        }

        return {
          itemElementId,
          itemName,
          itemIndex,
          positionOverride,
          height,
          width,
          imageSource,
        };
      } else {
        return {
          itemElementId,
          itemName,
          itemIndex,
          positionOverride,
        };
      }
    };

    const json = Parser.parse(svgXml, parserOptions).svg;
    delete json.preserveAspectRatio;
    delete json.height;
    delete json.width;
    delete json.viewBox;
    delete json.defs;
    delete json.xmlns;

    for (const value of Object.values(json)) {
      if (Array.isArray(value)) {
        for (const subValue of value) {
          const info: any = getItemInfo(value, subValue);
          unorderedItems.push(info);
        }
      } else {
        const info: any = getItemInfo(value);
        unorderedItems.push(info);
      }
    }
    unorderedItems.sort((a, b) => (a.itemIndex > b.itemIndex ? 1 : -1));
    for (const item of unorderedItems) {
      addItemToArray(item);
    }
    return sortedItemsToAdd;
  }

  public loadTopSvgForEditor(
    image: string,
    selectItemCallback: (itemIndex: number) => void
  ): JSX.Element[] {
    image = decodeURIComponent(image);
    return this.translateSvgXmlToJsxElements(image, selectItemCallback);
  }

  public loadTopForApp(
    img: string,
    height: number,
    width: number,
    mode: boolean = false
  ): JSX.Element {
    const numberWithDigits = NUMBER_WITH_DIGITS;
    img = decodeURIComponent(img);
    const originalViewBox: string = Parser.parse(img, parserOptions).svg
      .viewBox;
    const [xMin, yMin, svgWidth, svgHeight] = originalViewBox.match(
      numberWithDigits
    ) as RegExpMatchArray;
    const viewBox = '0 0 ' + svgWidth + ' ' + svgHeight;
    const itemsToAdd: JSX.Element[] = this.translateSvgXmlToJsxElements(
      img,
      () => {},
      true,
      {x: Number(xMin), y: Number(yMin)}
    );

    return (
      <View style={{height, width}}>
        <Svg
          // @ts-ignore used to display svg properly on web
          xmlns={'http://www.w3.org/2000/svg'}
          height={Percentage.PRECENTAGE_100}
          width={Percentage.PRECENTAGE_100}
          viewBox={viewBox}
          preserveAspectRatio={mode ? 'xMidYMid meet' : 'xMidYMid'}
        >
          {itemsToAdd.map(item => item)}
        </Svg>
      </View>
    );
  }
}

export default EditorService;
