import React from 'react';
import ModalButton from '../../components/button/modalButton';
import {BASESTYLE, COLORS, FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import editIcon from '../../components/renderSvgs/editPencil';
import DeleteIcon from '../../components/renderSvgs/deleteBin';
import {ThemeContext} from '../../contextAPI/themeContext';
import {HomeContext} from '../../contextAPI/homeContext';
import useDeleteTopHooks from '../../hooks/componentHooks/useDeleteTopHooks';
import {Pressable, Text, View} from 'react-native';
import {editTopModalStyle} from './topModal.styles';
import CustomButton from '../../components/button/customButton';
import PrimaryButton from '../../components/button/primaryButton';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import {navigate} from '../../services/navigationService';
import {useAppSelector} from '../../hooks/reduxHooks';
import {editorState} from '../../redux/reducers/editorSlice';
import ModalBack from '../../components/renderSvgs/modalBack';

const EditTopModalField = () => {
  const {callDeleteTopAPI, deleteModalNo, updateDeleteModalNo} =
    useDeleteTopHooks();
  const {openForEdit} = useAppSelector(editorState);
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = editTopModalStyle(ColorTheme);

  const {setOpenEditTopModal, selectedTopData} = React.useContext(HomeContext);
  const handleEditTop = () => {
    setOpenEditTopModal();
    navigate(ROUTE_NAME.EDITOR_SCREEN, {
      createdTopData: selectedTopData,
      isEdit: openForEdit,
    });
  };

  const handleDeleteTop = () => {
    // handle deleting collection
    updateDeleteModalNo(FixedValue.CONSTANT_VALUE_1);
  };

  return (
    <>
      {deleteModalNo === FixedValue.CONSTANT_VALUE_0 && (
        <>
          <Pressable
            style={styles.alignEnd}
            onPress={setOpenEditTopModal}
          >
            <ModalBack />
          </Pressable>
          <ModalButton
            LeftIcon={editIcon}
            label={TEXT.EDIT_TOP}
            onPress={handleEditTop}
            hideSeperator={openForEdit}
          />
          {openForEdit && (
            <ModalButton
              LeftIcon={() => <DeleteIcon stroke={COLORS._FF3D3D} />}
              label={TEXT.DELETE_TOP}
              isDelete={true}
              onPress={handleDeleteTop}
            />
          )}
        </>
      )}
      {deleteModalNo === FixedValue.CONSTANT_VALUE_1 && (
        <>
          <View style={styles.deleteBinView}>
            <DeleteIcon
              stroke={COLORS._FF3D3D}
              width={scaleWidth(FixedValue.CONSTANT_VALUE_21)}
              height={scaleHeight(FixedValue.CONSTANT_VALUE_24)}
            />
          </View>
          <>
            <Text style={styles.sure}>
              {TEXT.ARE_YOU_SURE_WANT_TO} {` "${selectedTopData?.name}"?` ?? ''}
            </Text>
          </>
          <View style={styles.marginTop20}>
            <>
              <View
                style={[
                  BASESTYLE.inRow,
                  BASESTYLE.inCenter,
                  {marginBottom: FixedValue.CONSTANT_VALUE_20},
                ]}
              >
                <CustomButton
                  buttonLabel={TEXT.NO}
                  buttonwidth={styles.yesOrNoButtonView}
                  buttonBackGroundColor={styles.noButtonBackGroundColor}
                  labelStyle={styles.noButtonLabelstyle}
                  onPress={() => setOpenEditTopModal()}
                  isDisabled={false}
                />
                <View style={styles.marginLeft16}>
                  <PrimaryButton
                    buttonLabel={TEXT.YES}
                    buttonwidth={styles.yesOrNoButtonView}
                    labelStyle={styles.yesButtonLabelstyle}
                    onPress={() => callDeleteTopAPI(selectedTopData?.id)}
                    isDisabled={false}
                  />
                </View>
              </View>
            </>
          </View>
        </>
      )}
    </>
  );
};

export default React.memo(EditTopModalField);
