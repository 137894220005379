import React from 'react';

import ScrollableContainer from '../../components/layout/scrollableContainer';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import VerifyEmailContentView from './verifyEmailContentView';
import ViewContainer from '../../components/layout/viewContainer';

const VerifyEmail: React.FC = () => {
  return (
    <ViewContainer>
      <ScrollableContainer>
        <SafeAreaContainer>
          <VerifyEmailContentView />
        </SafeAreaContainer>
      </ScrollableContainer>
    </ViewContainer>
  );
};

export default React.memo(VerifyEmail);
