import React from 'react';
import {View} from 'react-native';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {ISingleTabProps} from '../../../types/componentTypes/layoutType';
import {FixedValue, ROUTE_NAME, TEXT} from '../../../constants';
import TopTabBarLayout from '../../../components/layout/topTabBarLayout';
import {tradeTabStyle} from './tradeTab.Style';
import tradeReceviedOffer from '../../tradeReceviedOffer/index';
import tradeSentOffer from '../../tradeSentOffer/index';
import {push} from '../../../services/navigationService';
import CreateTradeButton from '../../../components/button/createTradeButton';
import {useAppDispatch, useAppSelector} from '../../../hooks/reduxHooks';
import {
  activateInSentOfferTab,
  HomeStore,
} from '../../../redux/reducers/homeSlice';
import {useIsFocused} from '@react-navigation/native';

let buttonClicked = false;

const TradeTabScreen = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = tradeTabStyle(ColorTheme);
  const dispatch = useAppDispatch();
  const {moveToSentOffer} = useAppSelector(HomeStore);
  const tabBarList: ISingleTabProps[] = [
    {
      routeName: ROUTE_NAME.RECEIVED_OFFER,
      component: tradeReceviedOffer,
      tabBarName: TEXT.RECEIVED_OFFER,
    },
    {
      routeName: ROUTE_NAME.SENT_OFFER,
      component: tradeSentOffer,
      tabBarName: TEXT.SENT_OFFER,
    },
  ];
  const [tabIndex, setTabIndex] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_0
  );
  const isFocused = useIsFocused();

  const onPress = React.useCallback((): void => {
    if (!buttonClicked) {
      buttonClicked = true;
      push(ROUTE_NAME.CREATE_TRADE);
    }
  }, [buttonClicked]);

  React.useEffect((): void => {
    if (moveToSentOffer) {
      setTabIndex(FixedValue.CONSTANT_VALUE_1);
      dispatch(activateInSentOfferTab(false));
    }
  }, [moveToSentOffer]);

  React.useEffect(() => {
    if (isFocused) {
      buttonClicked = false;
    }
  }, [isFocused]);

  return (
    <View style={styles.container}>
      <TopTabBarLayout
        tabArray={tabBarList}
        setCurrentActiveTab={tabIndex}
        setTab={setTabIndex}
      />
      <CreateTradeButton onPress={onPress} />
    </View>
  );
};
export default TradeTabScreen;
