import React from 'react';
import {Pressable, View} from 'react-native';

import {editorStyle} from './editorHeader.style';
import {BASESTYLE, COLORS, FixedValue} from '../../../constants';

import ArrowLeftIcon from '../../renderSvgs/arrowLeft';
import GreenTick from '../../renderSvgs/greenTick';
import {EditorContext} from '../../../contextAPI/editorContext';
import EditorHeaderRightIcons from '../../smallDesigns/editorHeaderRightIcons';
import SelectedGradient from '../../renderSvgs/selectedGradient';
import {controllerName} from '../../../constants/enum';
import {EditorTextContext} from '../../../contextAPI/editorTextContext';
import EditFont from '../../renderSvgs/editFonts';
import useEditorText from '../../../hooks/componentHooks/useEditorTextHooks';
import EditPencil from '../../renderSvgs/editPencil';

const TextHeader = (): JSX.Element => {
  const {selectedItemIndex, items, onClickTick} =
    React.useContext(EditorContext);
  const {setSelectedController, selectedController, setShowChangeFont} =
    React.useContext(EditorTextContext);
  const {onDeleteText, makeTextEditable} = useEditorText();

  const StrockColor: string =
    selectedController === controllerName.EDIT_FONT
      ? COLORS._00B25B
      : COLORS.WHITE;

  React.useEffect((): void => {
    if (selectedItemIndex === FixedValue.CONSTANT_VALUE_MIN_1) {
      setSelectedController(null);
    }
  }, [selectedItemIndex]);

  const RenderBackBtn = React.useMemo((): JSX.Element => {
    if (items.length > FixedValue.CONSTANT_VALUE_0) {
      return <View />;
    }
    return (
      <Pressable onPress={onClickTick}>
        <ArrowLeftIcon />
      </Pressable>
    );
  }, [items]);

  return (
    <View style={editorStyle.container}>
      {RenderBackBtn}
      <View style={BASESTYLE.inRow}>
        {selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1 && (
          <>
            <Pressable onPress={makeTextEditable}>
              <EditPencil stroke={COLORS.WHITE} />
            </Pressable>
            <View style={editorStyle.crossView} />
            <Pressable
              onPress={() =>
                setSelectedController(controllerName.COLOR_PELETTE)
              }
            >
              <SelectedGradient />
            </Pressable>
            <View style={editorStyle.crossView} />
            <Pressable onPress={setShowChangeFont}>
              <EditFont stroke={StrockColor} />
            </Pressable>
            <View style={editorStyle.crossView} />
            <EditorHeaderRightIcons
              controllerType={selectedController}
              onPressRotate={() =>
                setSelectedController(controllerName.ROTATION)
              }
              onPressResize={() => setSelectedController(controllerName.SCALE)}
              onPressDelete={onDeleteText}
            />
          </>
        )}
        <View style={editorStyle.crossView} />
        <Pressable disabled={items.length <= 0} onPress={onClickTick}>
          <GreenTick
            stroke={items.length > 0 ? COLORS._00B25B : COLORS._7A7F85}
            width={FixedValue.CONSTANT_VALUE_29}
            height={FixedValue.CONSTANT_VALUE_29}
          />
        </Pressable>
      </View>
    </View>
  );
};

export default React.memo(TextHeader);
