import React from 'react';
import {View, Text, Pressable, FlatList} from 'react-native';
import {style} from './style';
import {ThemeContext} from '../../contextAPI/themeContext';
import {getInitials} from '../../utils/helperFunctions';
import {COLORS, FixedValue, TEXT} from '../../constants';
import {
  UserDetailModalProps,
  UserDetailModal,
} from '../../types/reducerTypes/groupMangementTypes';
import {useAppSelector} from '../../hooks/reduxHooks';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import useGroupManagementListHooks from '../../hooks/componentHooks/useGroupManagementListHooks';
import {GroupContext} from '../../contextAPI/groupManagementContext';
import CustomDeleteModal from '../../modals/customDeleteModal';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import DeleteIcon from '../../components/renderSvgs/deleteBin';
import ProfilePics from '../../components/smallDesigns/profilePics';
import AppLoader from '../../components/smallDesigns/appLoader';

const GroupDetailUserList = ({
  userDetail,
  showLeaveGroupButton,
}: UserDetailModalProps): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = style(ColorTheme);
  const {userData} = useAppSelector(userProfileStore);
  const userLoginDetail = userData?.success;
  const {onGroupUserListReachedEnd, removeUserFromGroupAPIFunction} =
    useGroupManagementListHooks();
  const {
    initialLoader,
    showRemoveUserFromGroupModal,
    setShowLeaveGroupModal,
    setShowRemoveUserFromGroupModal,
    setSelectedGroupUserId,
  } = React.useContext(GroupContext);
  const Icon = (
    <DeleteIcon
      stroke={COLORS._FF3D3D}
      width={scaleWidth(FixedValue.CONSTANT_VALUE_28)}
      height={scaleHeight(FixedValue.CONSTANT_VALUE_40)}
    />
  );

  const removeUserFromGroupModalData = {
    Icon: Icon,
    subHeader: TEXT.ARE_YOU_SURE_REMOVE,
    modalVisible: showRemoveUserFromGroupModal,
    closeModal: setShowRemoveUserFromGroupModal,
    onYesButton: removeUserFromGroupAPIFunction,
    onNoButton: setShowRemoveUserFromGroupModal,
  };

  const getImagePlaceholderText = (item: UserDetailModal) => {
    const fullName = item?.firstName + ' ' + item?.lastName ?? '';
    return getInitials(fullName);
  };

  const removeAndLeaveButton = (item: UserDetailModal) => {
    if (showLeaveGroupButton && item?.id !== userLoginDetail?.id) {
      return (
        <Pressable
          onPress={() => {
            setSelectedGroupUserId(item.id);
            setShowRemoveUserFromGroupModal();
          }}
          style={[styles.removeButtonView, styles.removeButtonWidth]}
        >
          <Text
            style={[styles.removeTextStyle, styles.removeTextColor]}
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
          >
            {TEXT.REMOVE}
          </Text>
        </Pressable>
      );
    } else if (!showLeaveGroupButton && item?.id === userLoginDetail?.id) {
      return (
        <Pressable
          onPress={() => setShowLeaveGroupModal()}
          style={[styles.removeButtonView, styles.leaveGroupButtonWidth]}
        >
          <Text
            style={[styles.removeTextStyle, styles.removeTextColor]}
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
          >
            {TEXT.LEAVE_GROUP}
          </Text>
        </Pressable>
      );
    } else if (item?.admin) {
      return (
        <View style={[styles.ownerButtonView, styles.removeButtonWidth]}>
          <Text
            style={[styles.removeTextStyle, styles.ownerTextColor]}
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
          >
            {TEXT.OWNER}
          </Text>
        </View>
      );
    } else {
      return null;
    }
  };

  const renderUserList = (item: UserDetailModal) => {
    return (
      <View style={styles.marginTop20}>
        <View style={[styles.inRowStyle, styles.spaceBetweenStyle]}>
          <View style={styles.inRowStyle}>
            <ProfilePics
              image={item?.profileImage ?? ''}
              text={getImagePlaceholderText(item)}
              mainViewStyle={styles.mainViewListStyle}
              initialsTextStyle={styles.placeholderNameText}
            />
            <View style={styles.columnStyle}>
              <Text
                style={styles.userNametext}
                numberOfLines={FixedValue.CONSTANT_VALUE_1}
              >
                {item?.firstName + ' ' + item?.lastName ?? ''}
              </Text>
              <Text
                numberOfLines={FixedValue.CONSTANT_VALUE_1}
                style={styles.userNameTextStyle}
              >
                {item?.username}
              </Text>
            </View>
          </View>
          {removeAndLeaveButton(item)}
        </View>
      </View>
    );
  };

  const userListFlatlist = React.useMemo((): JSX.Element | null => {
    return (
      <FlatList
        keyExtractor={(_, index) => `${TEXT.USER_LIST_2}-${index}`}
        data={userDetail}
        contentContainerStyle={styles.flatListContentContainerStyle}
        onEndReached={() => onGroupUserListReachedEnd()}
        onEndReachedThreshold={FixedValue.CONSTANT_VALUE_1}
        showsVerticalScrollIndicator={false}
        scrollEnabled
        renderItem={({item}) => renderUserList(item)}
      />
    );
  }, [userDetail]);

  return (
    <View>
      {initialLoader && <AppLoader />}
      {userListFlatlist}
      {showRemoveUserFromGroupModal && (
        <CustomDeleteModal
          customDeleteModalData={removeUserFromGroupModalData}
        />
      )}
    </View>
  );
};

export default React.memo(GroupDetailUserList);
