import {StyleSheet} from 'react-native';
import {
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';

export const styles = (theme: IColorTheme) =>
  StyleSheet.create({
    mainConatiner: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_342),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_142),
      borderWidth: FixedValue.CONSTANT_VALUE_2,
      borderStyle: GlobalStyleValues.DOTTED,
      alignItems: GlobalStyleValues.CENTER,
      borderColor: COLORS.RGB_121_152_229,
      backgroundColor: COLORS.RGB_233_238_251,
      alignSelf: GlobalStyleValues.CENTER,
      justifyContent: GlobalStyleValues.CENTER,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_12),
      borderRadius: FixedValue.CONSTANT_VALUE_8,
    },
    noDataText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: COLORS.RGB_31_83_211,
      textAlign: GlobalStyleValues.CENTER,
    },
  });
