/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {useFormik} from 'formik';

import {ERROR_MESSAGES, FixedValue, ROUTE_NAME} from '../../constants';
import {LostPwdSchema} from '../../utils/formikSchema';
import {goBack, navigate} from '../../services/navigationService';
import {useAppDispatch, useAppSelector} from '../reduxHooks';
import {
  forgotPasswordState,
  onForgotPasswordStart,
} from '../../redux/reducers/forgotPasswordSlice';
import {CHECK_USERNAME, FORGOT_PASSWORD} from '../../constants/actionTypes';
import {_ToastHandler} from '../../utils/helperFunctions';
import {authState, onUsernameStart} from '../../redux/reducers/authSlice';

type LostPwdFeilds = {Username: string};

const useLostPwdHooks = () => {
  const dispatch = useAppDispatch();
  const {forgotPassword} = useAppSelector(forgotPasswordState);
  const {userName} = useAppSelector(authState);
  const [isBtnLoading, setIsBtnLoading] = React.useState<boolean>(false);
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isValid,
    values,
    setFieldError,
    setFieldValue,
  } = useFormik<LostPwdFeilds>({
    validationSchema: LostPwdSchema,
    initialErrors: {
      Username: ERROR_MESSAGES.REQUIRED_USERNAME,
    },
    initialTouched: {Username: false},
    initialValues: {Username: ''},
    onSubmit: val => checkUserExist(val),
  });

  const checkUserExist = React.useCallback((val: LostPwdFeilds): void => {
    setIsBtnLoading(true);
    dispatch(onUsernameStart({username: val.Username}));
  }, []);

  const forgotPasswordInit = React.useCallback((val: LostPwdFeilds): void => {
    dispatch(onForgotPasswordStart({Username: val.Username}));
  }, []);

  const _moveToLogin = React.useCallback((): void => {
    goBack();
  }, []);

  React.useEffect((): void => {
    switch (userName.status) {
      case CHECK_USERNAME.SUCCESS:
        if (userName?.success?.available) {
          setIsBtnLoading(false);
          setFieldError('Username', ERROR_MESSAGES.USERNAME_DOESNOT_EXIST);
        } else forgotPasswordInit(values);
        break;
      case CHECK_USERNAME.FAILED:
        setIsBtnLoading(false);
        setFieldError('Username', ERROR_MESSAGES.USERNAME_DOESNOT_EXIST);
        break;
      default:
        break;
    }
  }, [userName.status, values]);

  React.useEffect((): void => {
    switch (forgotPassword.status) {
      case FORGOT_PASSWORD.SUCCESS:
        setIsBtnLoading(false);
        navigate(ROUTE_NAME.VERIFY_EMAIL, {
          emailId: values?.Username,
          mobileNo: values?.Username,
          navigateFrom: ROUTE_NAME.LOSS_PASSWORD,
        });
        break;
      case FORGOT_PASSWORD.FAILED:
        setIsBtnLoading(false);
        _ToastHandler(forgotPassword.failed ?? '', false);
        break;
      default:
        break;
    }
  }, [forgotPassword.status]);

  const UsernameError: string =
    touched.Username && errors.Username ? errors.Username : '';

  return {
    UsernameError,
    isBtnLoading,
    errors,
    touched,
    isValid,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    _moveToLogin,
  };
};

export default useLostPwdHooks;
