import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';

const GreenTick = (props: SvgProps) => (
  <Svg
    width={10}
    height={8}
    viewBox="0 0 10 8"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M1.5 4L4 6.5L9 1.5"
      stroke={props.stroke ?? '#00B35B'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default GreenTick;
