import StyleSheet from 'react-native-media-query';

import {
  FixedValue,
  Percentage,
  FONT_NAMES,
  BASESTYLE,
  COLORS,
} from '../constants';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  SCREEN_WIDTH,
} from '../utils/responsive';
import {IColorTheme} from '../types/contextTypes/themeContext';

export const navigationStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    mainViewWidth: {
      ...BASESTYLE.flex1,
      backgroundColor: theme.color,
    },
    innerContainer: {
      height: Percentage.PRECENTAGE_100,
      width: isWebsite()
        ? FixedValue.CONSTANT_VALUE_500
        : Percentage.PRECENTAGE_100,
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    labelText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_10),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
    },
    tabBarStyle: {
      backgroundColor: theme.tabBarBackgroundColor,
    },
    webTabBarStyle: {
      ...BASESTYLE.absolutePosition,
      bottom: FixedValue.CONSTANT_VALUE_9,
      width: FixedValue.CONSTANT_VALUE_375,
      backgroundColor: COLORS.E9EEFB,
      height: FixedValue.CONSTANT_VALUE_71,
      borderRadius: FixedValue.CONSTANT_VALUE_65,
      ...BASESTYLE.inRow,
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_90,
      },
    },
    mobileTabBarStyle: {
      ...BASESTYLE.absolutePosition,
      bottom: FixedValue.CONSTANT_VALUE_0,
      width: SCREEN_WIDTH,
      backgroundColor: theme.tabBarBackgroundColor,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_71),
      ...BASESTYLE.inRow,
    },
    singleTabView: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.inCenter,
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    iconView: {
      width: FixedValue.CONSTANT_VALUE_26,
      height: FixedValue.CONSTANT_VALUE_26,
      ...BASESTYLE.inCenter,
      marginBottom: FixedValue.CONSTANT_VALUE_8,
    },
    notificationUnReadIcon: {
      width: FixedValue.CONSTANT_VALUE_14,
      height: FixedValue.CONSTANT_VALUE_14,
      borderRadius: FixedValue.CONSTANT_VALUE_7,
      backgroundColor: COLORS.F54336,
      ...BASESTYLE.absolutePosition,
      zIndex: FixedValue.CONSTANT_VALUE_1,
      top: FixedValue.CONSTANT_VALUE_MIN_1,
      right: FixedValue.CONSTANT_VALUE_0,
      borderWidth: FixedValue.CONSTANT_VALUE_3,
      borderColor: COLORS.WHITE,
    },
  });
