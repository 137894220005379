import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
const Group = (props: SvgProps) => (
  <Svg
    width={24}
    height={22}
    viewBox="0 0 24 22"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M20.5358 11.812H18.6797C18.8689 12.33 18.9722 12.889 18.9722 13.4716V20.4864C18.9722 20.7293 18.93 20.9625 18.853 21.1793H21.9215C23.0678 21.1793 24.0002 20.2468 24.0002 19.1006V15.2765C24.0003 13.3662 22.4461 11.812 20.5358 11.812Z"
      fill={props?.fill ?? '#BCCBF2'}
    />
    <Path
      d="M5.02806 13.4716C5.02806 12.8889 5.13142 12.33 5.32061 11.812H3.46454C1.55419 11.812 0 13.3662 0 15.2765V19.1007C0 20.2469 0.932487 21.1794 2.07872 21.1794H5.14726C5.07034 20.9625 5.02806 20.7293 5.02806 20.4864V13.4716Z"
      fill={props?.fill ?? '#BCCBF2'}
    />
    <Path
      d="M14.1218 10.0071H9.8786C7.96825 10.0071 6.41406 11.5613 6.41406 13.4716V20.4865C6.41406 20.8691 6.72428 21.1794 7.10697 21.1794H16.8935C17.2761 21.1794 17.5864 20.8692 17.5864 20.4865V13.4716C17.5864 11.5613 16.0322 10.0071 14.1218 10.0071Z"
      fill={props?.fill ?? '#BCCBF2'}
    />
    <Path
      d="M12.0005 0.820557C9.70308 0.820557 7.83398 2.68965 7.83398 4.98714C7.83398 6.54551 8.6941 7.90652 9.96432 8.62109C10.5668 8.96 11.2614 9.15368 12.0005 9.15368C12.7397 9.15368 13.4342 8.96 14.0367 8.62109C15.307 7.90652 16.1671 6.54546 16.1671 4.98714C16.1671 2.6897 14.298 0.820557 12.0005 0.820557Z"
      fill={props?.fill ?? '#BCCBF2'}
    />
    <Path
      d="M4.6834 4.7041C2.9652 4.7041 1.56738 6.10192 1.56738 7.82012C1.56738 9.53833 2.9652 10.9361 4.6834 10.9361C5.11925 10.9361 5.53428 10.8459 5.9113 10.6836C6.56314 10.4029 7.10061 9.90616 7.43319 9.28389C7.66663 8.84716 7.79943 8.34892 7.79943 7.82012C7.79943 6.10196 6.40161 4.7041 4.6834 4.7041Z"
      fill={props?.fill ?? '#BCCBF2'}
    />
    <Path
      d="M19.3162 4.7041C17.598 4.7041 16.2002 6.10192 16.2002 7.82012C16.2002 8.34897 16.333 8.8472 16.5664 9.28389C16.899 9.90621 17.4365 10.403 18.0883 10.6836C18.4653 10.8459 18.8804 10.9361 19.3162 10.9361C21.0344 10.9361 22.4322 9.53833 22.4322 7.82012C22.4322 6.10192 21.0344 4.7041 19.3162 4.7041Z"
      fill={props?.fill ?? '#BCCBF2'}
    />
  </Svg>
);
export default Group;
