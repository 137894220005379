import React from 'react';
import {View, Text, Pressable} from 'react-native';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import {ThemeContext} from '../../contextAPI/themeContext';
import {helpAndSupportStyle} from './styles';
import TitleWithButton from '../../components/header/titleWithButton';
import {ROUTE_NAME, TEXT, getBaseUrl} from '../../constants';
import {ChildProfileBtnType} from '../../types/componentTypes/childProfileType';
import {navigate} from '../../services/navigationService';
import {HelpAndSupportTileData} from '../../constants/appConst';
import RightArrow from '../../components/renderSvgs/rightArrow';
import {ProfileBtnType} from '../../types/componentTypes/userProfileTypes';

const HelpAndSupport = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = helpAndSupportStyle(ColorTheme);

  // Handle different types of button presses
  const handlePress = (item: ChildProfileBtnType) => {
    if (item.label === TEXT.HELP_AND_SUPPORT) {
      navigateToWebView(item);
    } else {
      navigateToPressableItem(item);
    }
  };

  // Navigate to a WebView screen
  const navigateToWebView = (item: ChildProfileBtnType) => {
    const {label} = item;
    const headerTitle = label;
    const webUrl = null; // Set webUrl to null based on the original logic
    const showSupportInfo = true;
    navigate(ROUTE_NAME.WEB_VIEW_SCREEN, {
      headerTitle,
      webUrl,
      showSupportInfo,
    });
  };

  // Navigate to a Pressable item
  const navigateToPressableItem = (item: ChildProfileBtnType) => {
    const {webUrl, label, onPress} = item;
    const webViewProps = {webUrl, headerTitle: label, onPress};
    navigate(item?.onPress ?? '', webViewProps);
  };

  // Render each Help and Support tile using Pressable
  const renderHelpAndSupportTile = (item: ProfileBtnType) => (
    <Pressable
      style={styles.profileSingleBtnView}
      key={item.label}
      onPress={() => handlePress(item)}
    >
      <Text style={styles.profileBtnLabelText}>{item.label}</Text>
      <View style={styles.inRow}>
        <RightArrow stroke={ColorTheme.color} />
      </View>
    </Pressable>
  );

  return (
    <SafeAreaContainer>
      <View style={styles.container} dataSet={{media: ids.container}}>
        <TitleWithButton title={TEXT.HELP_AND_SUPPORT} />
        <View style={styles.extraTopMargin} />
        {HelpAndSupportTileData.map(renderHelpAndSupportTile)}
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(HelpAndSupport);
