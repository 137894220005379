import React from 'react';
import {Pressable, StyleSheet} from 'react-native';
import {View} from 'native-base';

import {BASESTYLE, COLORS, FixedValue} from '../../constants';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import CreateTrade from '../renderSvgs/createTrade';

const styles = StyleSheet.create({
  container: {
    ...BASESTYLE.absolutePosition,
    bottom: scaleHeight(FixedValue.CONSTANT_VALUE_94),
    right: scaleWidth(FixedValue.CONSTANT_VALUE_3),
  },
  viewStyle: {
    ...BASESTYLE.inCenter,
    width: scaleWidth(FixedValue.CONSTANT_VALUE_58),
    height: scaleWidth(FixedValue.CONSTANT_VALUE_58),
    backgroundColor: COLORS.PRIMARY_BLUE,
    borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_29),
    shadowColor: COLORS.PRIMARY_BLUE,
    shadowOpacity: FixedValue.CONSTANT_VALUE_03,
    shadowOffset: {
      width: FixedValue.CONSTANT_VALUE_5,
      height: FixedValue.CONSTANT_VALUE_5,
    },
    elevation: FixedValue.CONSTANT_VALUE_5,
  },
});
interface ICreateTradeButtonProps {
  onPress: () => void;
}
const CreateTradeButton = (props: ICreateTradeButtonProps): JSX.Element => {
  const {onPress} = props;

  return (
    <Pressable style={styles.container} onPress={onPress}>
      <View style={styles.viewStyle} shadow={FixedValue.CONSTANT_VALUE_3}>
        <CreateTrade stroke={COLORS.WHITE} />
      </View>
    </Pressable>
  );
};

export default React.memo(CreateTradeButton);
