import StyleSheet from 'react-native-media-query';
import {
  FixedValue,
  Percentage,
  FONT_NAMES,
  BASESTYLE,
  GlobalStyleValues,
} from '../../constants';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';
import {IColorTheme} from '../../types/contextTypes/themeContext';

export const passwordResetStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      backgroundColor: theme.backgroundColor,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      paddingHorizontal: isWebsite()
        ? 0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_194),
      ...BASESTYLE.selfCenter,
      alignItems: GlobalStyleValues.CENTER,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    titleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_24),
      fontFamily: FONT_NAMES.SF_BOLD,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_32),
      color: theme.color,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_27),
      textAlign: GlobalStyleValues.CENTER,
    },
    descText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_REGULAR,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_24),
      color: theme.resetPwdTextColor,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_8),
      textAlign: GlobalStyleValues.CENTER,
    },
    buttonView: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_35),
      width: Percentage.PRECENTAGE_100,
    },
  });
