import React, {useContext, useEffect} from 'react';

import {FixedValue} from '../../constants';
import {_ToastHandler} from '../../utils/helperFunctions';
import {
  onFavoriteUserListStart,
  onIsUserIsFavoriteStart,
  onUserMarkAndUnmarkFavoriteStart,
  userProfileStore,
} from '../../redux/reducers/userProfileSlice';
import {useAppDispatch, useAppSelector} from '../reduxHooks';
import {IMarkAndUnmarkFavorite} from '../../types/reducerTypes/notificationTypes';
import {
  USER_MARK_UNMARK_FAVORITE,
  VERIFY_IS_FAVORITE_USER,
} from '../../constants/actionTypes';
import {TradeContext} from '../../contextAPI/tradeContext';
import {useIsFocused} from '@react-navigation/native';
import {HomeContext} from '../../contextAPI/homeContext';

const useFavouriteHooks = () => {
  const {
    favoriteUserListData,
    userMarkAndUnmarkFavoriteData,
    userIsFavouriteData,
  } = useAppSelector(userProfileStore);
  const dispatch = useAppDispatch();
  const {otherTradeUser, setOtherTradeUser} = React.useContext(TradeContext);
  const [favoriteLoader, setFavoriteLoader] = React.useState<boolean>(false);
  const isFocused = useIsFocused();
  const {searchFavouriteUserListText} = useContext(HomeContext);

  const getfavoriteUserList = React.useCallback(
    (keyword: string, page: number): void => {
      const params = {page, keyword, size: FixedValue.CONSTANT_VALUE_15};
      dispatch(onFavoriteUserListStart(params));
    },
    []
  );

  const onFavoriteUserListReachedEnd = React.useCallback((): void => {
    if (
      favoriteUserListData?.success != null &&
      favoriteUserListData?.success?.pageNumber <
        favoriteUserListData?.success?.totalPages
    ) {
      getfavoriteUserList(
        searchFavouriteUserListText,
        favoriteUserListData?.success?.pageNumber + FixedValue.CONSTANT_VALUE_1
      );
    }
  }, [favoriteUserListData, searchFavouriteUserListText]);

  const fetchIsOtherUserFavoirteOrNot = React.useCallback(
    (userId: number): void => {
      dispatch(onIsUserIsFavoriteStart({userId}));
    },
    []
  );

  const addFavoriteAndUnFavorite = React.useCallback(
    (params: IMarkAndUnmarkFavorite): void => {
      dispatch(onUserMarkAndUnmarkFavoriteStart(params));
    },
    []
  );

  useEffect(() => {
    if (isFocused) {
      switch (userMarkAndUnmarkFavoriteData.status) {
        case USER_MARK_UNMARK_FAVORITE.SUCCESS:
          if (otherTradeUser?.id) {
            fetchIsOtherUserFavoirteOrNot(
              otherTradeUser?.id ?? FixedValue.CONSTANT_VALUE_0
            );
          } else {
            setTimeout(() => {
              setFavoriteLoader(false);
            }, 700);
          }
          break;
        case USER_MARK_UNMARK_FAVORITE.FAILED:
          setFavoriteLoader(false);
          break;
      }
    }
  }, [userMarkAndUnmarkFavoriteData?.status]);

  useEffect(() => {
    switch (userIsFavouriteData.status) {
      case VERIFY_IS_FAVORITE_USER.SUCCESS:
        const isFavorite = userIsFavouriteData?.success?.data ?? false;
        // @ts-ignore
        setOtherTradeUser(prevUser => ({
          ...prevUser,
          isFavorite,
        }));
        setFavoriteLoader(false);
        break;
      case VERIFY_IS_FAVORITE_USER.FAILED:
        setFavoriteLoader(false);
        break;
    }
  }, [userIsFavouriteData?.status]);

  return {
    getfavoriteUserList,
    onFavoriteUserListReachedEnd,
    fetchIsOtherUserFavoirteOrNot,
    addFavoriteAndUnFavorite,
    setFavoriteLoader,
    favoriteLoader,
  };
};

export default useFavouriteHooks;
