import React from 'react';
import {NavigationContainer, ParamListBase} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import {navigationRef} from '../services/navigationService';
import {ROUTE_NAME} from '../constants';
import welcomeScreen from '../screens/welcome/welcomeScreen';
import loginScreen from '../screens/loginScreen';
import BottomNavigation from './bottomNavigation';
import lostPassword from '../screens/lostPassword';
import verifyEmail from '../screens/verifyEmail';
import CreatePassword from '../screens/createPassword';
import passwordReset from '../screens/passwordReset';
import signUpScreen from '../screens/signUpScreen';
import editorScreen from '../screens/editorScreen';
import ParentConsentView from '../screens/parentConsent';
import ChangePassword from '../screens/changePassword';
import topDetailScreen from '../screens/topDetailScreen';
import ChildScreen from '../screens/childListScreen';
import InProgressTopsScreen from '../screens/inProgressTops';
import CollectionsScreen from '../screens/topCollections';
import AllTopsScreen from '../screens/seeAllTops';
import PurchasedTopsScreen from '../screens/purchasedTops';
import GroupManagementScreen from '../screens/groupManagementScreen/groupMainTabScreen';
import MyGroupListScreen from '../screens/myGroupsList/index';
import GroupInScreen from '../screens/groupInScreen/index';
import GroupInvitaions from '../screens/groupInvitationsScreen/index';
import childProfile from '../screens/childProfileScreen';
import profileApprovelReq from '../screens/childProfileScreen/profileApprovelReq';
import editProfile from '../screens/tabBar/profileTab/editProfile';
import distributeIndivitualScreen from '../components/distributeTopTab/distributeIndivitual';
import distributeGroupScreen from '../components/distributeTopTab/distributeGroup';
import ChildGroupInvitationsList from '../screens/childGroupInvitationsList';
import GroupInvitationSettings from '../screens/groupInvitationSettings';
import GroupDetailScreen from '../screens/groupDetail/index';
import CreateTrade from '../screens/createOffer/index';
import topTradingApproval from '../screens/childProfileScreen/topTradingApproval/topTradingIndex';
import topTradingReceived from '../screens/topTradingApprovel/topTradingReceived/topReceivedIndex';
import topTradingSent from '../screens/topTradingApprovel/topTradingSent/topSentIndex';
import topTradingApprovedList from '../screens/topTradingApprovel/topTradingApprovedList/approvelIndex';
import setting from '../screens/topTradingApprovel/setting/settingIndex';
import SelectTopsForOffer from '../screens/selectTopsForOffer/index';
import selectTradeUser from '../screens/selectTradeUser';
import requestSent from '../screens/requestSent/requestSent';
import createOfferOther from '../screens/createOfferOther';
import selectTopsForOfferOther from '../screens/selectTopsForOfferOther';
import sentTradeOfferRequest from '../screens/sentTradeOfferRequest';
import myCartTops from '../screens/myCartTops';
import distributingIndex from '../screens/distributingTopRequest/distributingIndex';
import distributingRequestIndex from '../screens/distributingTopRequest/distributingRequest/distributingRequestIndex';
import distributingApproveIndex from '../screens/distributingTopRequest/distributingTopApproveList/distributingApproveIndex';
import DistributingSettingIndex from '../screens/distributingTopRequest/distributingSetting/DistributingSettingIndex';
import topBuyingIndex from '../screens/topBuyingApprovel';
import topBuyingSetting from '../screens/topBuyingApprovel/topBuyingSetting';
import topDetailIndex from '../screens/topBuyingApprovel/topBuyingDetail/topDetailIndex';
import shiftingIndex from '../screens/shiftingToTradeable/shiftingIndex';
import shiftingSettingIndex from '../screens/shiftingToTradeable/shiftingSetting/shiftingSettingIndex';
import WebViewScreen from '../screens/webView';
import paymentSuccess from '../screens/paymentSuccess/paymentSuccess';
import distributingReceivedRequestIndex from '../screens/distributingTopRequest/distributingReceivedRequest/distributingReceivedRequestIndex';
import myWalletBalance from '../screens/myWalletBalance';
import orderSummary from '../screens/orderSummary';
import helpAndSupport from '../screens/helpAndSupport';
import blockUserList from '../screens/blockUser';
import FavoriteList from '../screens/Favorites/FavoriteList';
import ChildAllPermissionScreen from '../screens/childProfileScreen/childAllPermissionScreen';
import SearchTab from '../screens/tabBar/searchTab';
import Tutorials from '../screens/tutorialsVideo';
import FullscreenVideoPlayer from '../screens/tutorialsVideo/fullScreenVideoPlayer';
import forgotUserName from '../screens/forgotUserName';

const Stack = createStackNavigator<ParamListBase>();

type IMainNavigation = {
  initialRouteName: string;
};

const MainNavigation: React.FC<IMainNavigation> = (props: IMainNavigation) => {
  return (
    <NavigationContainer ref={navigationRef}>
      <Stack.Navigator
        initialRouteName={props.initialRouteName}
        screenOptions={{
          headerShown: false,
          gestureEnabled: false,
        }}
      >
        <Stack.Screen
          name={ROUTE_NAME.WELCOME_SCREEN}
          component={welcomeScreen}
        />
        <Stack.Screen name={ROUTE_NAME.LOGIN_SCREEN} component={loginScreen} />
        <Stack.Screen name={ROUTE_NAME.SIGN_UP} component={signUpScreen} />
        <Stack.Screen
          name={ROUTE_NAME.LOSS_PASSWORD}
          component={lostPassword}
        />
        <Stack.Screen name={ROUTE_NAME.VERIFY_EMAIL} component={verifyEmail} />
        <Stack.Screen
          name={ROUTE_NAME.BOTTOM_TAB}
          component={BottomNavigation}
        />
        <Stack.Screen
          name={ROUTE_NAME.EDITOR_SCREEN}
          component={editorScreen}
        />
        <Stack.Screen
          name={ROUTE_NAME.CREATE_PASSWORD}
          component={CreatePassword}
        />
        <Stack.Screen
          name={ROUTE_NAME.PASSWORD_RESET}
          component={passwordReset}
        />
        <Stack.Screen
          name={ROUTE_NAME.PARENT_CONSENT}
          component={ParentConsentView}
        />
        <Stack.Screen
          name={ROUTE_NAME.CHANGE_PASSWORD}
          component={ChangePassword}
        />
        <Stack.Screen
          name={ROUTE_NAME.TOP_DETAIL_SCREEN}
          component={topDetailScreen}
        />
        <Stack.Screen
          name={ROUTE_NAME.CHILD_LIST_SCREEN}
          component={ChildScreen}
        />
        <Stack.Screen
          name={ROUTE_NAME.IN_PROGRESS_TOPS_SCREEN}
          component={InProgressTopsScreen}
        />
        <Stack.Screen
          name={ROUTE_NAME.COLLECTIONS_SCREEN}
          component={CollectionsScreen}
        />
        <Stack.Screen
          name={ROUTE_NAME.SEE_ALL_TOPS_SCREEN}
          component={AllTopsScreen}
        />
        <Stack.Screen
          name={ROUTE_NAME.PURCHASED_TOPS_SCREEN}
          component={PurchasedTopsScreen}
        />
        <Stack.Screen
          name={ROUTE_NAME.CHILD_PROFILE}
          component={childProfile}
        />
        <Stack.Screen
          name={ROUTE_NAME.PROFILE_APPROVEL}
          component={profileApprovelReq}
        />
        <Stack.Screen
          name={ROUTE_NAME.GROUP_MANAGEMENT_SCREEN}
          component={GroupManagementScreen}
        />
        <Stack.Screen
          name={ROUTE_NAME.MY_GROUP_SCREEN}
          component={MyGroupListScreen}
        />
        <Stack.Screen name={ROUTE_NAME.GROUP_IN} component={GroupInScreen} />
        <Stack.Screen
          name={ROUTE_NAME.GROUP_INVITATIONS}
          component={GroupInvitaions}
        />
        <Stack.Screen name={ROUTE_NAME.EDIT_PROFILE} component={editProfile} />
        <Stack.Screen
          name={ROUTE_NAME.DISTRIBUTE_INDIVIDUAL}
          component={distributeIndivitualScreen}
        />
        <Stack.Screen
          name={ROUTE_NAME.DISTRIBUTE_GROUP}
          component={distributeGroupScreen}
        />
        <Stack.Screen
          name={ROUTE_NAME.CHILD_GROUP_INVITATION}
          component={ChildGroupInvitationsList}
        />
        <Stack.Screen
          name={ROUTE_NAME.GROUP_INVIATION_SETTINGS}
          component={GroupInvitationSettings}
        />
        <Stack.Screen
          name={ROUTE_NAME.GROUP_DETAIL_SCREEN}
          component={GroupDetailScreen}
        />
        <Stack.Screen name={ROUTE_NAME.CREATE_TRADE} component={CreateTrade} />
        <Stack.Screen
          name={ROUTE_NAME.SELECT_ALL_TOPS_FOR_OFFER}
          component={SelectTopsForOffer}
        />
        <Stack.Screen
          name={ROUTE_NAME.TOP_TRADING}
          component={topTradingApproval}
        />
        <Stack.Screen
          name={ROUTE_NAME.TOP_RECEIVED}
          component={topTradingReceived}
        />
        <Stack.Screen name={ROUTE_NAME.TOP_SENT} component={topTradingSent} />
        <Stack.Screen
          name={ROUTE_NAME.TOP_APPROVED_LIST}
          component={topTradingApprovedList}
        />
        <Stack.Screen name={ROUTE_NAME.TOP_SETTING} component={setting} />
        <Stack.Screen
          name={ROUTE_NAME.SELECT_TRADE_USER}
          component={selectTradeUser}
        />
        <Stack.Screen name={ROUTE_NAME.REQUEST_SENT} component={requestSent} />
        <Stack.Screen
          name={ROUTE_NAME.CREATE_OFFER_OTHER}
          component={createOfferOther}
        />
        <Stack.Screen
          name={ROUTE_NAME.SELECT_TOPS_FOR_OFFER_OTHER}
          component={selectTopsForOfferOther}
        />
        <Stack.Screen
          name={ROUTE_NAME.SENT_TRADE_OFFER_REQUEST}
          component={sentTradeOfferRequest}
        />
        <Stack.Screen name={ROUTE_NAME.MY_CART_TOPS} component={myCartTops} />
        <Stack.Screen
          name={ROUTE_NAME.DISTRIBUTING_TOP}
          component={distributingIndex}
        />
        <Stack.Screen
          name={ROUTE_NAME.DISTRIBUTING_TOP_RECEIVED_REQUEST}
          component={distributingReceivedRequestIndex}
        />
        <Stack.Screen
          name={ROUTE_NAME.DISTRIBUTING_TOP_REQUEST}
          component={distributingRequestIndex}
        />
        <Stack.Screen
          name={ROUTE_NAME.DISTRIBUTING_TOP_APPROVE_LIST}
          component={distributingApproveIndex}
        />
        <Stack.Screen
          name={ROUTE_NAME.DISTRIBUTING_SETTING}
          component={DistributingSettingIndex}
        />
        <Stack.Screen
          name={ROUTE_NAME.TOP_BUYING_APPROVAL}
          component={topBuyingIndex}
        />
        <Stack.Screen
          name={ROUTE_NAME.TOP_BUYING_SETTING}
          component={topBuyingSetting}
        />
        <Stack.Screen
          name={ROUTE_NAME.TOP_BUYING_DETAIL}
          component={topDetailIndex}
        />
        <Stack.Screen
          name={ROUTE_NAME.SHIFTING_TO_TRADEABLE}
          component={shiftingIndex}
        />
        <Stack.Screen
          name={ROUTE_NAME.SHIFTING_SETTING}
          component={shiftingSettingIndex}
        />
        <Stack.Screen
          name={ROUTE_NAME.WEB_VIEW_SCREEN}
          component={WebViewScreen}
        />
        <Stack.Screen
          name={ROUTE_NAME.PAYMENT_SUCCESS}
          component={paymentSuccess}
        />
        <Stack.Screen
          name={ROUTE_NAME.MY_WALLET_BALANCE}
          component={myWalletBalance}
        />
        <Stack.Screen
          name={ROUTE_NAME.ORDER_SUMMARY}
          component={orderSummary}
        />
        <Stack.Screen
          name={ROUTE_NAME.HELP_SUPPORT}
          component={helpAndSupport}
        />
        <Stack.Screen
          name={ROUTE_NAME.BLOCK_USER_LIST}
          component={blockUserList}
        />
        <Stack.Screen
          name={ROUTE_NAME.FAVORITES_LIST}
          component={FavoriteList}
        />
        <Stack.Screen
          name={ROUTE_NAME.CHILD_PROFILE_ALL_SETTING}
          component={ChildAllPermissionScreen}
        />
        <Stack.Screen name={ROUTE_NAME.SEARCH_TAB} component={SearchTab} />
        <Stack.Screen name={ROUTE_NAME.TUTORIAL_VIDEO} component={Tutorials} />
        <Stack.Screen
          name={ROUTE_NAME.FULLSCREEN_PLAYER}
          component={FullscreenVideoPlayer}
          options={{headerShown: false}}
        />
        <Stack.Screen
          name={ROUTE_NAME.FORGOT_USERNAME}
          component={forgotUserName}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default MainNavigation;
