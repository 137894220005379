import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const TopCollectionStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      ...BASESTYLE.selfCenter,
      height: Percentage.PRECENTAGE_100,
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_0),
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_0,
        width: Percentage.PRECENTAGE_100,
      },
    },
    collectionTitleStyle: {
      color: _theme.color,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_BOLD,
    },
    seeAllText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_BOLD,
    },
    marginRight20: {
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    paddingHorizontal20: {
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      width: Percentage.PRECENTAGE_70,
    },
    threeDotsView: {
      ...BASESTYLE.inRow,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      backgroundColor: COLORS.TRANSPARENT,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      justifyContent: GlobalStyleValues.CENTER,
      alignItems: GlobalStyleValues.CENTER,
    },
    collectionTitleView: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_21),
      ...BASESTYLE.spaceBetween,
      ...BASESTYLE.inRow,
    },
    paddingBottom90: {
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_90),
    },
    paddingLeft10: {
      paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
  });
