import React from 'react';
import {View} from 'react-native';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {DistributingApproveListStyle} from './distributingAppeove.style';
import DistributingApproveListScreen from '../distributingTopApproveList/distributingTopLIstScreen';
import CustomButton from '../../../components/button/customButton';
import {TEXT} from '../../../constants';
import {USER_LIST_TYPE} from '../../../constants/enum';
import {childProfileStore} from '../../../redux/reducers/childProfileSlice';
import {useAppSelector} from '../../../hooks/reduxHooks';
import useTradingApprovalHooks from '../../../hooks/componentHooks/useTradingApprovalHooks';
import {ChildProfileContext} from '../../../contextAPI/childProfileContext';
import CustomModalForSelectUser from '../../../modals/customModalForSelectUser';
import ConfirmationModal from '../../../modals/confirmationModal/confirmationModal';

const DistributingApproveList = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = DistributingApproveListStyle(ColorTheme);
  const {onTradeUserAdd, onDistributeUserAdd} =
    useAppSelector(childProfileStore);
  const {
    _onChildUserModalSearchText,
    onTradeUserListEndReach,
    callAddUserInDistributeApprovedAPI,
    openAddUserInTradeModal,
    userRemoveFromDistributeApprovedList,
  } = useTradingApprovalHooks();
  const {
    showDistributeUserListModal,
    addNewChildUserSearchText,
    tradeUserListData,
    selectedUserInTrade,
    showDistributeApproveUserRemoveAlert,
    setShowDistributeUserListModal,
    selectOrUnSelectTradeUser,
    setShowDistributeApproveUserRemoveAlert,
  } = React.useContext(ChildProfileContext);

  const customUserSelectModaldata = {
    modalHeaderName: TEXT.ADD_NEW_USER_ONLY,
    modalVisible: showDistributeUserListModal,
    value: addNewChildUserSearchText,
    searchPlaceholder: TEXT.SEARCH_USERS,
    listData: tradeUserListData,
    closeModal: setShowDistributeUserListModal,
    onChangeText: _onChildUserModalSearchText,
    onSelectOrUnSelectUser: selectOrUnSelectTradeUser,
    selectedUsers: selectedUserInTrade,
    showLogInUser: true,
    buttonLoader:
      (onTradeUserAdd?.isLoading || onDistributeUserAdd?.isLoading) ?? false,
    callAddUserToGroupAPI: callAddUserInDistributeApprovedAPI,
    onReachedEnd: onTradeUserListEndReach,
  };

  return (
    <View style={styles.mainContainer}>
      <DistributingApproveListScreen />
      <View style={styles.buttonView}>
        <CustomButton
          buttonLabel={TEXT.ADD_NEW_USER}
          buttonwidth={styles.buttonStyle}
          labelStyle={styles.buttonLabelstyle}
          onPress={() => openAddUserInTradeModal(USER_LIST_TYPE.DISTRIBUTE)}
        />
      </View>
      {showDistributeUserListModal && (
        <CustomModalForSelectUser customModaldata={customUserSelectModaldata} />
      )}
      {showDistributeApproveUserRemoveAlert && (
        <ConfirmationModal
          title={TEXT.USER_REMOVED_FROM_APPROVAL_LIST}
          modalVisible={showDistributeApproveUserRemoveAlert}
          onPressYes={() => userRemoveFromDistributeApprovedList()}
          onShowHide={() => setShowDistributeApproveUserRemoveAlert(false)}
        />
      )}
    </View>
  );
};

export default React.memo(DistributingApproveList);
