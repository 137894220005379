import React from 'react';
import {View, Text, ViewStyle, TextStyle} from 'react-native';

import {COLORS, FixedValue} from '../../constants';
import {IPwdValidationProps} from '../../types/componentTypes/smallDesignsType';
import SmallGreenTick from '../renderSvgs/greenTick';
import {styles} from './smallDesign.style';

const PwdValidationOption = (props: IPwdValidationProps) => {
  const style = styles();
  const bgColor: ViewStyle = {
    backgroundColor: props.isValid ? COLORS.DBF1E7 : COLORS.E9EEFB,
  };
  const TextColor: TextStyle = {
    color: props.isValid ? COLORS._00B25B : COLORS.B6BFC9,
  };
  return (
    <View style={[style.container, bgColor]}>
      {props.isValid && (
        <>
          <SmallGreenTick
            width={FixedValue.CONSTANT_VALUE_12}
            height={FixedValue.CONSTANT_VALUE_12}
          />
          <View style={style.rowGap} />
        </>
      )}
      <Text style={[style.textStyle, TextColor]}>
        {props.count} {props.label}
      </Text>
    </View>
  );
};

export default React.memo(PwdValidationOption);
