import {takeLatest, put, call, delay, select} from 'redux-saga/effects';
import {AppConst} from '../../constants';
import {trackingDetail} from '../../utils/apis';
import {_ToastHandler} from '../../utils/helperFunctions';
import {isWebsite} from '../../utils/responsive';
import {authState} from '../reducers/authSlice';
import {TrackingDetailResponse} from '../../types/reducerTypes/tradeTabTypes';
import {IActionType} from '../../types/constantFilesTypes';
import {
  ontrackingDetailEnd,
  ontrackingDetailFailed,
  ontrackingDetailSuccess,
} from '../reducers/trackingDetailSlice';

const {StoreConstants} = AppConst;

function* trackingDetailContact(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: TrackingDetailResponse = yield call(
      trackingDetail,
      userTokens,
      action.payload
    );
    yield put(ontrackingDetailSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.response?.data?.message || 'An error occurred', false);
    yield put(ontrackingDetailFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(ontrackingDetailEnd());
  }
}

export default function* trackingDetailSaga() {
  yield takeLatest(
    `${StoreConstants.TRACKING_DETAIL}/ontrackingDetailStart`,
    trackingDetailContact
  );
}
