import {StyleSheet} from 'react-native';
import {BASESTYLE, FixedValue, FONT_NAMES} from '../../../constants';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {normalizeFont, scaleHeight} from '../../../utils/responsive';

export const styles = (theme: IColorTheme) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_21),
    },
    titleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme.color,
    },
  });
