import React from 'react';
import {View, Pressable, ScrollView} from 'react-native';

import {ThemeContext} from '../../contextAPI/themeContext';
import {PayPalModalStyle} from './styles';
import {PayPalButtons} from '@paypal/react-paypal-js';
import {FixedValue} from '../../constants';
import CrossIcon from '../../components/renderSvgs/crossIcon';

interface PayPalProps {
  totalTopsAmount: number;
  modalVisible: boolean;
  onClose: () => void;
  onCancelPayment: () => void;
  onSuccessPayment: () => void;
}
const PayPalModal = (props: PayPalProps): JSX.Element => {
  const [hideCrossBtn, setHideCrossBtn] = React.useState<boolean>(false);
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = PayPalModalStyle(ColorTheme);
  const {onClose, totalTopsAmount, onCancelPayment, onSuccessPayment} = props;

  const onClickBtn = React.useCallback((): void => {
    setHideCrossBtn(true);
  }, []);

  const getError = React.useCallback((error: any): void => {
    console.log(JSON.stringify(error), 'getting error on paypal');
  }, []);

  const onCreateOrder = React.useCallback((_: any, actions: any) => {
    return actions.order.create({
      intent: 'CAPTURE',
      purchase_units: [
        {
          amount: {
            currency_code: 'USD',
            value: `${totalTopsAmount}`,
          },
        },
      ],
    });
  }, []);

  const onApprove = React.useCallback((_: any, actions: any) => {
    return actions?.order?.capture().then((_: any) => {
      setTimeout(() => {
        onSuccessPayment();
      }, 2000);
      onClose();
      setHideCrossBtn(false);
    });
  }, []);

  const onCancel = React.useCallback(() => {
    if (onCancelPayment) onCancelPayment();
    onClose();
    setHideCrossBtn(false);
  }, []);

  return (
    <View style={styles.paypalButtonView}>
      {!hideCrossBtn && (
        <Pressable onPress={onClose} style={styles.closeIconView}>
          <CrossIcon
            width={FixedValue.CONSTANT_VALUE_36}
            height={FixedValue.CONSTANT_VALUE_36}
          />
        </Pressable>
      )}
      <ScrollView contentContainerStyle={styles.contentContainerStyle}>
        <PayPalButtons
          onClick={onClickBtn}
          createOrder={onCreateOrder}
          onApprove={onApprove}
          onCancel={onCancel}
          onError={getError}
        />
      </ScrollView>
    </View>
  );
};

export default PayPalModal;
