import React from 'react';
import {HomeContext} from '../../contextAPI/homeContext';
import useAwsS3 from '../libraryHooks/useAwsS3';
import {getTopImage} from '../../utils/imageUtils';

const useDistributiveTopHooks = () => {
  const {loadSignedUrl} = useAwsS3();
  const [svgCode, setSvgCode] = React.useState<JSX.Element | undefined>(
    undefined
  );
  const {selectedTopData} = React.useContext(HomeContext);

  const loadSvgUrl = React.useCallback(async (): Promise<void | undefined> => {
    const imageUrl = await loadSignedUrl(selectedTopData?.image ?? '');
    if (imageUrl) {
      const topImage = await getTopImage(imageUrl ?? '');
      setSvgCode(topImage);
    }
  }, [selectedTopData?.image, svgCode]);

  return {svgCode, loadSvgUrl};
};

export default useDistributiveTopHooks;
