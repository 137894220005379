import React from 'react';
import {
  ADD_TOPS_TO_COLLECTION,
  BUY_TOPS,
  COLLECTION_TOP_LISTS,
  CREATE_COLLECTION,
  GET_IN_PROGRESS_TOPS,
  GET_PURCHASED_TOPS,
  GET_TOPS_BY_COLLECTION,
  UPDATE_COLLECTION,
  DELETE_TOPS,
  MARK_TRADABLE_TOPS,
  APPROVE_OR_REJECT,
  USER_LIST_SEARCH,
  DISTRIBUTE_SEARCH,
  GROUP_LIST_SEARCH,
  GROUP_USER_LIST_SEARCH,
  TOPS_MOVE_TO_COLLECTION,
  TOP_AMOUNT_AND_REPORT_ABUSE_REQUEST_DATA,
  VERIFY_COUPON_CODE,
  REPORT_ABUSE_REQUEST_DATA,
} from '../constants/actionTypes';
import {FixedValue, SeeAll_ScreenMode, TopType} from '../constants/enum';
import {useAppSelector} from '../hooks/reduxHooks';
import {HomeStore} from '../redux/reducers/homeSlice';
import {createCollectionStore} from '../redux/reducers/collectionSlice';
import {IHomeContext, IHomeProvider} from '../types/contextTypes/homeContext';
import {
  AbuseDataModel,
  CollectionModel,
  CouponCodeObject,
  CustomUserListSearchModalListData,
  GroupListSearchModalListData,
  TopModel,
} from '../types/reducerTypes/searchTabStoreType';
import {
  _ToastHandler,
  convertToIntegerForLargeValues,
  showAlertForExceedQuantity,
} from '../utils/helperFunctions';
import {ROUTE_NAME, TEXT} from '../constants';
import {goBack} from '../services/navigationService';
import {TradableStore} from '../redux/reducers/tradableTopsSlice';
import {childProfileApproveStore} from '../redux/reducers/childApproveSlice';
import {
  ChildListModel,
  IUserProfileData,
} from '../types/reducerTypes/userProfileTypes';
import {userProfileStore} from '../redux/reducers/userProfileSlice';

const HomeContext = React.createContext<IHomeContext>({
  currentActiveTab: ROUTE_NAME.COLLECTIONS_SCREEN,
  setCurrentActiveTab: _val => {},
  topsResult: [],
  collectionsResult: [],
  openCreateModal: false,
  openDeleteCollectionModal: false,
  openChildProfileApproveModal: false,
  purchasedTops: [],
  collectionsTops: [],
  selectedTopsToMove: [],
  isRefreshing: false,
  isApprove: false,
  onApprove: null,
  isStatus: '',
  openEditTopModal: false,
  selectedTopData: null,
  selectAllClicked: false,
  seeAllScreenMode: SeeAll_ScreenMode.VIEW,
  profileSwitchListModal: false,
  homeScreenUserData: null,
  homeScreenDataLoader: false,
  setProfileSwitchListModal: _val => {},
  setTopsResult: _val => {},
  setCollectionsResult: _val => {},
  setPurchasedTops: _val => {},
  setCollectionsTops: _val => {},
  setIsRefreshing: _val => {},
  setIsApprove: _val => {},
  setOnApprove: _val => {},
  setIsStatus: _val => {},
  setOpenCreateModal: () => {},
  setOpenDeleteCollectionModal: () => {},
  setOpenChildProfileApproveModal: () => {},
  clearTopsResult: () => {},
  clearCollectionsResult: () => {},
  clearPurchasedTops: () => {},
  clearTopsinCollection: () => {},
  setSelectAllClicked: _val => {},
  setOpenEditTopModal: () => {},
  setSelectedTopData: _val => {},
  showDistributiveModal: false,
  setShowDistributiveModal: _val => {},
  setSeeAllScreenMode: _val => {},
  setSelectedTopsToMove: _val => {},
  selectOrUnSelectSingleTop: _val => {},
  updateSelectedTopModelsToMove: _val => {},
  clearSelectedTopsToMove: () => {},
  selectedFromCollectionData: null,
  setSelectedFromCollectionData: _val => {},
  searchUserListText: '',
  setSearchUserListText: _val => {},
  userListSearchResult: [],
  setUserListSearchResult: _val => {},
  selectedUsersListSearch: [],
  setSelectedUsersListSearch: _val => {},
  remainingTop: null,
  setRemainingTop: _val => {},
  groupListSearchResult: [],
  setGroupListSearchResult: _val => {},
  searchGroupListText: '',
  setSearchGroupListText: _val => {},
  selectedGroupDetail: null,
  setSelectedGroupDetail: _val => {},
  _setUserListSearchResult: _val => {},
  isCloseGroupUser: false,
  setIsCloseGroupUser: _val => {},
  setHomeScreenUserData: _val => {},
  isProfileSwitchToChild: false,
  setIsProfileSwitchToChild: _val => {},
  updateTopQuantity: _val => {},
  setHomeScreenDataLoader: _val => {},
  selectedTopsResult: [],
  setSelectedTopsResult: _val => {},
  clearSelectedTopsResult: () => {},
  selectOrUnSelectResultTop: _val => {},
  updateTopResultQuantity: _val => {},
  totalTopsAmount: FixedValue.CONSTANT_VALUE_0,
  setTotalTopsAmount: _val => {},
  initialLoader: false,
  setInitialLoader: _val => {},
  topAmount: 0,
  setTopAmount: _val => {},
  applyCouponCodeData: null,
  setApplyCouponCodeData: _val => {},
  discountPrice: 0,
  setDiscountPrice: _val => {},
  abuseData: null,
  setAbuseData: _val => {},
  isPaymentFromCash: false,
  setIsPaymentFromCash: _val => {},
  setShowWelcomeTop: _val => {},
  showWelcomeTop: false,
  setOpenReedemModal: _val => {},
  openReedemModal: false,
  searchFavouriteUserListText: '',
  setSearchFavouriteUserListText: _val => {},
  selectAllUsers: () => {},
  unselectAllUsers: () => {},
  userListQuantityEdit: _val => {},
});

const HomeProvider = (props: IHomeProvider): JSX.Element => {
  const {
    inProgressTopList,
    purchasedTopLists,
    collectionTopLists,
    buyTop,
    collectionBasedTopList,
    addTopsToCollection,
    deleteTops,
    userListSearch,
    distributeTop,
    groupListSearch,
    groupUserListSearch,
    moveToCollection,
    topAmountAndReportAbuseData,
    couponCodeData,
    reportAbuseData,
  } = useAppSelector(HomeStore);
  const {createNewCollection, updateCollection} = useAppSelector(
    createCollectionStore
  );
  const [currentActiveTab, setCurrentActiveTab] = React.useState<string>(
    ROUTE_NAME.COLLECTIONS_SCREEN
  );
  const [selectedTopsToMove, setSelectedTopsToMove] = React.useState<
    TopModel[]
  >([]);
  const [topsResult, setTopsResult] = React.useState<TopModel[]>([]);
  const [collectionsResult, setCollectionsResult] = React.useState<
    CollectionModel[]
  >([]);
  const [purchasedTops, setPurchasedTops] = React.useState<TopModel[]>([]);
  const [collectionsTops, setCollectionsTopsResult] = React.useState<
    TopModel[]
  >([]);
  const [openCreateModal, setOpenCreateModal] = React.useState<boolean>(false);
  const [openDeleteCollectionModal, setOpenDeleteCollectionModal] =
    React.useState<boolean>(false);
  const [openChildProfileApproveModal, setOpenChildProfileApproveModal] =
    React.useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = React.useState<boolean>(false);
  const [selectAllClicked, setSelectAllClicked] =
    React.useState<boolean>(false);
  const [isApprove, setIsApprove] = React.useState<boolean>(false);
  const [onApprove, setOnApprove] = React.useState<null | number>(null);
  const [isStatus, setIsStatus] = React.useState<string>('');
  const [showDistributiveModal, setShowDistributiveModal] =
    React.useState<boolean>(false);
  const [selectedTopData, setSelectedTopData] = React.useState<TopModel | null>(
    null
  );
  const [openEditTopModal, setOpenEditTopModal] =
    React.useState<boolean>(false);
  const [_, selectOrUnSelectSingleTop] = React.useState<TopModel | null>(null);
  const {tradableTop} = useAppSelector(TradableStore);
  const [seeAllScreenMode, setSeeAllScreenMode] =
    React.useState<SeeAll_ScreenMode>(SeeAll_ScreenMode.VIEW);
  const {approveOrReject} = useAppSelector(childProfileApproveStore);

  const [selectedFromCollectionData, setSelectedFromCollectionData] =
    React.useState<CollectionModel | null>(null);
  const [searchUserListText, setSearchUserListText] =
    React.useState<string>('');
  const [userListSearchResult, setUserListSearchResult] = React.useState<
    CustomUserListSearchModalListData[]
  >([]);
  const [searchFavouriteUserListText, setSearchFavouriteUserListText] =
    React.useState<string>('');
  const [selectedUsersListSearch, setSelectedUsersListSearch] = React.useState<
    CustomUserListSearchModalListData[]
  >([]);
  const [remainingTop, setRemainingTop] = React.useState<number | null>(null);
  const [profileSwitchListModal, setProfileSwitchListModal] =
    React.useState<boolean>(false);
  const [groupListSearchResult, setGroupListSearchResult] = React.useState<
    GroupListSearchModalListData[]
  >([]);
  const [searchGroupListText, setSearchGroupListText] =
    React.useState<string>('');
  const [selectedGroupDetail, setSelectedGroupDetail] =
    React.useState<GroupListSearchModalListData | null>(null);
  const [isCloseGroupUser, setIsCloseGroupUser] =
    React.useState<boolean>(false);
  const [homeScreenUserData, setHomeScreenUserData] = React.useState<
    IUserProfileData | ChildListModel | null
  >(null);
  const [homeScreenDataLoader, setHomeScreenDataLoader] =
    React.useState<boolean>(false);
  const [isProfileSwitchToChild, setIsProfileSwitchToChild] =
    React.useState<boolean>(false);
  const [selectedTopsResult, setSelectedTopsResult] = React.useState<
    TopModel[]
  >([]);
  const [totalTopsAmount, setTotalTopsAmount] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_099
  );
  const [initialLoader, setInitialLoader] = React.useState<boolean>(false);
  const [topAmount, setTopAmount] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_1
  );
  const [applyCouponCodeData, setApplyCouponCodeData] =
    React.useState<CouponCodeObject | null>(null);
  const [discountPrice, setDiscountPrice] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_0
  );
  const [abuseData, setAbuseData] = React.useState<AbuseDataModel[] | null>([]);
  const [isPaymentFromCash, setIsPaymentFromCash] =
    React.useState<boolean>(false);
  const [showWelcomeTop, setShowWelcomeTop] = React.useState<boolean>(false);
  const [openReedemModal, setOpenReedemModal] = React.useState<boolean>(false);
  const {userData} = useAppSelector(userProfileStore);
  const UserDetail = userData.success;

  const _setSeeAllScreenMode = React.useCallback(
    (value: SeeAll_ScreenMode): void => {
      setSeeAllScreenMode(value);
    },
    [seeAllScreenMode]
  );

  //profile switch time show loader and hide when we get response from api
  const _setHomeScreenDataLoader = React.useCallback(
    (value: boolean): void => {
      setHomeScreenDataLoader(value);
    },
    [homeScreenDataLoader]
  );
  const _setCurrentActiveTab = React.useCallback(
    (value: string): void => {
      setCurrentActiveTab(value);
    },
    [currentActiveTab]
  );

  const _setHomeScreenUserData = React.useCallback(
    (value: ChildListModel | IUserProfileData | null): void => {
      setHomeScreenUserData(value);
    },
    [homeScreenUserData]
  );

  const _setIsProfileSwitchToChild = React.useCallback(
    (value: boolean): void => {
      setIsProfileSwitchToChild(value);
    },
    [isProfileSwitchToChild]
  );

  const _setOpenCreateModal = React.useCallback((): void => {
    setOpenCreateModal(prev => !prev);
  }, [openCreateModal]);
  //delete collection modal close and open function
  const _setOpenDeleteCollectionModal = React.useCallback((): void => {
    setOpenDeleteCollectionModal(prev => !prev);
  }, [openDeleteCollectionModal]);
  //child approvel modal open and close function
  const _setOpenChildProfileApproveModal = React.useCallback((): void => {
    setOpenChildProfileApproveModal(prev => !prev);
  }, [openChildProfileApproveModal]);
  // Set Edit Modal for editing / Delete top
  const _setOpenEditTopModal = React.useCallback((): void => {
    setOpenEditTopModal(prev => !prev);
  }, [openEditTopModal]);
  // Set InProgress tops, Collections, Purchased tops and Collection Specific tops
  const _setCollectionsTops = React.useCallback(
    (NewData: TopModel[]): void => {
      setCollectionsTopsResult(prev => [...prev, ...NewData]);
    },
    [collectionsTops]
  );
  const _setTopsResult = React.useCallback(
    (NewData: TopModel[]): void => {
      setTopsResult(prev => [...prev, ...NewData]);
    },
    [topsResult]
  );
  const _setPurchasedTops = React.useCallback(
    (NewData: TopModel[]): void => {
      setPurchasedTops(prev => [...prev, ...NewData]);
    },
    [purchasedTops]
  );
  const _setCollectionsResult = React.useCallback(
    (NewData: CollectionModel[]): void => {
      setCollectionsResult(prev => [...prev, ...NewData]);
    },
    [collectionsResult]
  );
  // Set Top Data for Edit and delete top
  const _setSelectedTopData = React.useCallback(
    (NewData: TopModel | null): void => {
      setSelectedTopData(NewData);
    },
    [selectedTopData]
  );
  // Set Collection Data from where you want to move top
  const _setSelectedFromCollectionData = React.useCallback(
    (NewData: CollectionModel | null): void => {
      setSelectedFromCollectionData(NewData);
    },
    [selectedFromCollectionData]
  );

  // Clear all tops array
  const _clearTopsResult = React.useCallback((): void => {
    setTopsResult([]);
  }, []);

  const _clearPurchasedTops = React.useCallback((): void => {
    setPurchasedTops([]);
  }, []);

  const _clearCollectionsResult = React.useCallback((): void => {
    setCollectionsResult([]);
  }, []);

  const _clearTopsInCollection = React.useCallback((): void => {
    setCollectionsTopsResult([]);
    setSelectAllClicked(false);
  }, []);

  const _clearSelectedTopsToMove = React.useCallback((): void => {
    setSelectedTopsToMove([]);
  }, []);

  const _setIsRefreshing = React.useCallback((val: boolean): void => {
    setIsRefreshing(val);
  }, []);

  const _setShowDistributiveModal = React.useCallback(
    (val: boolean): void => {
      setShowDistributiveModal(val);
    },
    [showDistributiveModal]
  );

  const _setIsApprove = React.useCallback(
    (val: boolean): void => {
      setIsApprove(val);
    },
    [isApprove]
  );

  const _setOnApprove = React.useCallback(
    (val: number | null): void => {
      setOnApprove(val);
    },
    [onApprove]
  );
  const _setIsStatus = React.useCallback(
    (val: string): void => {
      setIsStatus(val);
    },
    [isStatus]
  );

  // Update Inprogress Array if the top is buyed
  function removeTopModelById(idToRemove: number): void {
    const indexToRemove = topsResult.findIndex(
      model => model.id === idToRemove
    );
    if (indexToRemove !== -1) {
      const newTopsResult = [...topsResult];
      newTopsResult.splice(indexToRemove, 1);
      setTopsResult(newTopsResult);
    }
  }
  function removeTopModelByIdFromCollections(idToRemove: number): void {
    const newCollectionsResult = collectionsResult.map(collectionModel => {
      const newTopsArray = collectionModel?.tops?.filter(
        topModel => topModel.id !== idToRemove
      );
      return {...collectionModel, tops: newTopsArray};
    });
    setCollectionsResult(newCollectionsResult);
  }
  function removeTopFromCollectionTopList(idToRemove: number): void {
    const indexToRemove = collectionsTops.findIndex(
      model => model.id === idToRemove
    );
    if (indexToRemove !== -1) {
      const newTopsResult = [...collectionsTops];
      newTopsResult.splice(indexToRemove, 1);
      setCollectionsTopsResult(newTopsResult);
    }
  }
  //Update Tradable status
  function updateTopFromCollectionList(topId: number): void {
    const newCollectionsResult = collectionsResult.map(collectionModel => {
      const newTopsArray = collectionModel?.tops?.map(topModel => {
        if (topModel.id === topId) {
          return {...topModel, tradable: !topModel.tradable};
        }
        return topModel;
      });
      return {...collectionModel, tops: newTopsArray};
    });
    setCollectionsResult(newCollectionsResult);
  }
  function updateTopModelFromCollectionsTopList(topId: number): void {
    const newCollectionsTops = collectionsTops.map(topModel => {
      if (topModel.id === topId) {
        return {...topModel, tradable: !topModel.tradable};
      }
      return topModel;
    });
    setCollectionsTopsResult(newCollectionsTops);
  }
  const selectAndUnSelectAllTops = React.useCallback(
    (type: string): void => {
      setCollectionsTopsResult(prev =>
        prev.map(item => {
          return {
            ...item,
            isSelected: type === TEXT.SELECT_ALL ? true : false,
          };
        })
      );
    },
    [collectionsTops]
  );
  const _selectOrUnSelectSingleTop = React.useCallback(
    (data: TopModel | null): void => {
      setCollectionsTopsResult(prev => {
        return prev.map(item => {
          if (item.id === data?.id) {
            return {
              ...item,
              isSelected: !data.isSelected,
            };
          }
          return item;
        });
      });
    },
    [collectionsTops]
  );
  const _setSelectAllClicked = React.useCallback(
    (val: string): void => {
      setSelectAllClicked(prev => !prev);
      selectAndUnSelectAllTops(val);
    },
    [selectAllClicked]
  );

  const _setSelectedTopsToMove = React.useCallback(
    (data: TopModel[]): void => {
      setSelectedTopsToMove(data);
    },
    [selectedTopsToMove]
  );
  const _updateSelectedTopModelsToMove = React.useCallback(
    (newTopModel: TopModel): void => {
      const index = selectedTopsToMove.findIndex(
        topModel => topModel.id === newTopModel.id
      );
      if (index !== -1) {
        // If top model with same id exists, remove it
        const updatedTopsToMove = [...selectedTopsToMove];
        updatedTopsToMove.splice(index, 1);
        setSelectedTopsToMove(updatedTopsToMove);
      } else {
        // If top model with same id doesn't exist, add it
        setSelectedTopsToMove([
          ...selectedTopsToMove,
          {
            ...newTopModel,
            isSelected: true,
            selectedQuantity: FixedValue.CONSTANT_VALUE_1,
          },
        ]);
      }
    },
    [selectedTopsToMove]
  );
  // Update the Purchased array if the top is buyed
  const prependNewTopModel = React.useCallback(
    (newModel: TopModel, type: TopType): void => {
      if (type === TopType.inProgress) {
        setPurchasedTops([newModel, ...purchasedTops]);
      }
    },
    [purchasedTops]
  );
  // Update the Collections array if new collection created/ updated
  const appendCollectionModelAtPosition = React.useCallback(
    (position: number, newModel: CollectionModel): void => {
      const newResult = [...collectionsResult];
      newResult.splice(position, 0, newModel);
      setCollectionsResult(newResult);
    },
    [collectionsResult]
  );
  const updateCollectionModelById = React.useCallback(
    (idToUpdate: number, updatedModel: CollectionModel): void => {
      const indexToUpdate = collectionsResult.findIndex(
        model => model.id === idToUpdate
      );
      if (indexToUpdate !== -1) {
        const newResult = [...collectionsResult];
        newResult[indexToUpdate] = {
          ...newResult[indexToUpdate],
          ...updatedModel,
        };
        setCollectionsResult([...newResult]);
      }
    },
    [collectionsResult]
  );

  // set user search  text.
  const _setSearchUserListText = React.useCallback(
    (value: string): void => {
      setSearchUserListText(value);
    },
    [searchUserListText]
  );

  // set user favorite search  text.
  const _setSearchFavouriteUserListText = React.useCallback(
    (value: string): void => {
      setSearchFavouriteUserListText(value);
    },
    [searchFavouriteUserListText]
  );

  const _setUserListSearchResult = React.useCallback(
    (newData: CustomUserListSearchModalListData[], reset?: boolean): void => {
      if (reset) {
        setUserListSearchResult(newData);
      } else {
        setUserListSearchResult(prev => [...prev, ...newData]);
      }
    },
    [userListSearchResult]
  );

  const _selectOrUnSelectUser = React.useCallback(
    (
      data:
        | CustomUserListSearchModalListData
        | CustomUserListSearchModalListData[]
    ): void => {
      if (!(data as CustomUserListSearchModalListData).id) {
        setSelectedUsersListSearch(data as CustomUserListSearchModalListData[]);
        const updatedArray2 = userListSearch.success?.data?.results?.map(
          item1 => ({
            ...item1,
            alreadyPresentUser: false,
            quantity: FixedValue.CONSTANT_VALUE_0,
          })
        );
        setUserListSearchResult(updatedArray2 ?? []);
        return;
      }
      // Update the users list value in the users array
      const userData = data as CustomUserListSearchModalListData;
      setUserListSearchResult(prev => {
        return prev.map(item => {
          if (item.id === userData?.id) {
            return {
              ...item,
              alreadyPresentUser: !userData.alreadyPresentUser,
              quantity: !userData.alreadyPresentUser ? userData.quantity : 0,
            };
          }
          return item;
        });
      });

      let newSelectedList: CustomUserListSearchModalListData[] = [];
      // Check if the User is already selected
      const index = selectedUsersListSearch.findIndex(
        selected => selected?.id === userData?.id
      );
      if (
        index !== FixedValue.CONSTANT_VALUE_MIN_1 &&
        userData.isCounter === false
      ) {
        // User is already selected, remove it from the selected items array
        const newSelectedItems = [...selectedUsersListSearch];
        newSelectedItems.splice(index, FixedValue.CONSTANT_VALUE_1);
        setSelectedUsersListSearch(newSelectedItems);
        newSelectedList = newSelectedItems;
      } else {
        // User is not selected, add it to the selected items array
        if (userData.isCounter) {
          const newSelectedItems = [...selectedUsersListSearch];
          newSelectedItems[index].alreadyPresentUser =
            !userData.alreadyPresentUser;
          newSelectedItems[index].quantity = userData.quantity;
          setSelectedUsersListSearch(newSelectedItems);
          newSelectedList = newSelectedItems;
        } else {
          let item = {...userData};
          item.alreadyPresentUser = !item.alreadyPresentUser;
          setSelectedUsersListSearch([...selectedUsersListSearch, item]);
          newSelectedList = [...selectedUsersListSearch, item];
        }
      }

      // set remaining top count
      const totalValue = newSelectedList.reduce(
        (total, obj) => obj.quantity! + total,
        0
      );
      const count = selectedTopData?.quantity! - totalValue;
      _setRemainingTop(count);
    },
    [selectedUsersListSearch, selectedTopData, remainingTop]
  );

  const _setRemainingTop = React.useCallback(
    (value: number): void => {
      setRemainingTop(value);
    },
    [remainingTop]
  );

  const _setProfileSwitchListModal = React.useCallback(
    (value: boolean): void => {
      setProfileSwitchListModal(value);
    },
    [profileSwitchListModal]
  );
  const _setGroupListSearchResult = React.useCallback(
    (newData: GroupListSearchModalListData[]): void => {
      setGroupListSearchResult(prev => [...prev, ...newData]);
    },
    [groupListSearchResult]
  );

  // set group search  text.
  const _setSearchGroupListText = React.useCallback(
    (value: string): void => {
      setSearchGroupListText(value);
    },
    [searchGroupListText]
  );

  const _setSelectedGroupDetail = React.useCallback(
    (val: GroupListSearchModalListData | null): void => {
      setSelectedGroupDetail(val);
    },
    [selectedGroupDetail]
  );

  const _setIsCloseGroupUser = React.useCallback(
    (val: boolean): void => {
      setIsCloseGroupUser(val);
    },
    [isCloseGroupUser]
  );

  const _updateTopQuantity = React.useCallback(
    (data: TopModel | null, type: string): void => {
      setSelectedTopsToMove(prev => {
        const newUpdatedCollection = prev.map(topData => {
          if (topData.id === data?.id) {
            return {
              ...topData,
              selectedQuantity:
                type === TEXT.ADD
                  ? (topData.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0) +
                    FixedValue.CONSTANT_VALUE_1
                  : (topData.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0) -
                    FixedValue.CONSTANT_VALUE_1,
            };
          }
          return topData;
        });
        return newUpdatedCollection;
      });
    },
    [selectedTopsToMove]
  );

  const _setSelectedTopsResult = React.useCallback(
    (NewData: TopModel[]): void => {
      setSelectedTopsResult(NewData);
    },
    [selectedTopsResult]
  );

  const _clearSelectedTopsResult = React.useCallback((): void => {
    setSelectedTopsResult([]);
  }, []);

  const updateDiscountAmount = React.useCallback((): void => {
    // Check if applyCouponCodeData is defined
    if (applyCouponCodeData) {
      // Calculate the discount amount
      const discountAmount =
        totalTopsAmount * topAmount * (applyCouponCodeData?.value / 100);
      const disountAmountRoundOff =
        convertToIntegerForLargeValues(discountAmount);
      setDiscountPrice(disountAmountRoundOff);
    }
  }, [selectedTopsResult, totalTopsAmount, topAmount, applyCouponCodeData]);

  const _selectOrUnSelectResultTop = React.useCallback(
    (data: TopModel | null): void => {
      setTopsResult(prev => {
        return prev.map(value => {
          if (value.id === data?.id) {
            return {
              ...value,
              isSelected: !data.isSelected,
            };
          }
          return value;
        });
      });
      if (data) {
        const topData: TopModel = {...data};
        let newSelectedTops: TopModel[] = [];
        if (!topData?.isSelected) {
          topData.isSelected = !topData.isSelected;
          newSelectedTops = [...selectedTopsResult, topData];
          setSelectedTopsResult([...selectedTopsResult, topData]);
        } else {
          const index = selectedTopsResult.findIndex(
            top => top.id === topData.id
          );
          const updatedCollectionTops = [...selectedTopsResult];
          updatedCollectionTops.splice(index, FixedValue.CONSTANT_VALUE_1);
          newSelectedTops = updatedCollectionTops;
          setSelectedTopsResult(updatedCollectionTops);
        }
        // set total top amout
        const totalValue = newSelectedTops.reduce(
          (total, obj) => obj.selectedQuantity! + total,
          FixedValue.CONSTANT_VALUE_0
        );
        _setTotalTopsAmount(
          Number((totalValue * topAmount).toFixed(FixedValue.CONSTANT_VALUE_2))
        );
      }
    },
    [
      topsResult,
      selectedTopsResult,
      totalTopsAmount,
      topAmount,
      applyCouponCodeData,
    ]
  );

  React.useEffect(() => {
    if (applyCouponCodeData) {
      updateDiscountAmount();
    }
  }, [selectedTopsResult, applyCouponCodeData, totalTopsAmount]);

  const _updateTopResultQuantity = React.useCallback(
    (data: TopModel | null, type: string, inputQuantity?: number): void => {
      setTopsResult(prev =>
        prev.map(value => {
          if (value.id === data?.id) {
            return {
              ...value,
              selectedQuantity: inputQuantity
                ? inputQuantity
                : type === TEXT.ADD
                ? (value.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0) +
                  FixedValue.CONSTANT_VALUE_1
                : (value.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0) -
                  FixedValue.CONSTANT_VALUE_1,
            };
          }
          return value;
        })
      );
      setSelectedTopsResult(prev => {
        const newUpdatedCollection = prev.map(result => {
          if (result.id === data?.id) {
            return {
              ...result,
              selectedQuantity: inputQuantity
                ? inputQuantity
                : type === TEXT.ADD
                ? (result.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0) +
                  FixedValue.CONSTANT_VALUE_1
                : (result.selectedQuantity ?? FixedValue.CONSTANT_VALUE_0) -
                  FixedValue.CONSTANT_VALUE_1,
            };
          }
          return result;
        });
        // set total top amount
        const totalValue = newUpdatedCollection.reduce(
          (total, obj) => obj.selectedQuantity! + total,
          FixedValue.CONSTANT_VALUE_0
        );
        _setTotalTopsAmount(
          Number((totalValue * topAmount).toFixed(FixedValue.CONSTANT_VALUE_2))
        );
        return newUpdatedCollection;
      });
    },
    [topsResult, selectedTopsResult, totalTopsAmount, topAmount]
  );

  const _setTotalTopsAmount = React.useCallback(
    (value: number): void => {
      setTotalTopsAmount(value);
    },
    [totalTopsAmount]
  );

  const _setInitialLoader = React.useCallback(
    (val: boolean): void => {
      setInitialLoader(val);
    },
    [initialLoader]
  );

  // Handle Collections list response
  React.useEffect(() => {
    switch (collectionTopLists.status) {
      case COLLECTION_TOP_LISTS.SUCCESS:
        if (
          collectionTopLists.success?.pageNumber === FixedValue.CONSTANT_VALUE_0
        ) {
          setCollectionsResult(collectionTopLists.success?.data?.results ?? []);
          setIsRefreshing(false);
        } else {
          _setCollectionsResult(
            collectionTopLists.success?.data?.results ?? []
          );
          setIsRefreshing(false);
        }
        setInitialLoader(false);
        break;
      case COLLECTION_TOP_LISTS.FAILED:
        setIsRefreshing(false);
        setInitialLoader(false);
        break;
      default:
        break;
    }
  }, [collectionTopLists.status]);

  //get top amount data response handle
  React.useEffect(() => {
    if (
      topAmountAndReportAbuseData.status ===
      TOP_AMOUNT_AND_REPORT_ABUSE_REQUEST_DATA.SUCCESS
    ) {
      setTopAmount(
        topAmountAndReportAbuseData?.success?.data?.topPrice ??
          FixedValue.CONSTANT_VALUE_0
      );
    }
  }, [topAmountAndReportAbuseData.status]);

  //get report abuse data response handle
  React.useEffect(() => {
    if (reportAbuseData.status === REPORT_ABUSE_REQUEST_DATA.SUCCESS) {
      setAbuseData(reportAbuseData?.success?.data?.data?.results ?? []);
    }
  }, [reportAbuseData.status]);

  // Handle InProgress Tops response
  React.useEffect(() => {
    switch (inProgressTopList.status) {
      case GET_IN_PROGRESS_TOPS.SUCCESS:
        const topsData = inProgressTopList.success?.data?.results?.map(item => {
          const matchingItem = selectedTopsResult.find(
            item1 => item1.id === item.id
          );
          return matchingItem
            ? {
                ...item,
                selectedQuantity: matchingItem.selectedQuantity,
                isSelected: matchingItem.isSelected,
                quantity: item.quantity,
                amount: topAmount,
              }
            : {
                ...item,
                isSelected: false,
                selectedQuantity: FixedValue.CONSTANT_VALUE_1,
                amount: topAmount,
              };
        });
        if (
          inProgressTopList.success?.pageNumber === FixedValue.CONSTANT_VALUE_0
        ) {
          setTopsResult(topsData ?? []);
          setIsRefreshing(false);
        } else {
          _setTopsResult(topsData ?? []);
          setIsRefreshing(false);
        }
        setInitialLoader(false);
        break;
      case GET_IN_PROGRESS_TOPS.FAILED:
        setIsRefreshing(false);
        setInitialLoader(false);
        break;
      default:
        break;
    }
  }, [inProgressTopList.status, topAmount]);
  // Handle Purchased Top response
  React.useEffect(() => {
    switch (purchasedTopLists.status) {
      case GET_PURCHASED_TOPS.SUCCESS:
        if (
          purchasedTopLists.success?.pageNumber === FixedValue.CONSTANT_VALUE_0
        ) {
          setPurchasedTops(purchasedTopLists.success?.data?.results ?? []);
          setIsRefreshing(false);
        } else {
          _setPurchasedTops(purchasedTopLists.success?.data?.results ?? []);
          setIsRefreshing(false);
        }
        setInitialLoader(false);
        break;
      case GET_PURCHASED_TOPS.FAILED:
        setIsRefreshing(false);
        setInitialLoader(false);
        break;
      default:
        break;
    }
  }, [purchasedTopLists.status]);
  // Handled Buying Tops
  React.useEffect(() => {
    switch (buyTop.status) {
      case BUY_TOPS.SUCCESS:
        if (buyTop.success?.data?.id) {
          prependNewTopModel(buyTop.success?.data, TopType.inProgress);
          removeTopModelById(buyTop.success?.data?.id);
        }
        setIsRefreshing(false);
        break;
      case BUY_TOPS.FAILED:
        setIsRefreshing(false);
        break;
      default:
        break;
    }
  }, [buyTop.status]);
  // Handle Collection Specific Tops Response
  React.useEffect(() => {
    switch (collectionBasedTopList.status) {
      case GET_TOPS_BY_COLLECTION.SUCCESS:
        const topsData = collectionBasedTopList.success?.data?.results?.map(
          item => {
            return {
              ...item,
              isSelected: selectAllClicked,
            };
          }
        );
        if (
          collectionBasedTopList.success?.pageNumber ===
          FixedValue.CONSTANT_VALUE_0
        ) {
          setCollectionsTopsResult(topsData ?? []);
          setIsRefreshing(false);
        } else {
          _setCollectionsTops(topsData ?? []);
          setIsRefreshing(false);
        }
        setInitialLoader(false);
        break;
      case GET_TOPS_BY_COLLECTION.FAILED:
        setIsRefreshing(false);
        setInitialLoader(false);
        break;
      default:
        break;
    }
  }, [collectionBasedTopList.status]);
  // EDIT/ADD collection Model in Collection Array
  // Handle the create collection success/failure response using useEffect
  React.useEffect((): void => {
    if (createNewCollection.status === CREATE_COLLECTION.SUCCESS) {
      if (createNewCollection.success?.data) {
        appendCollectionModelAtPosition(
          FixedValue.CONSTANT_VALUE_1,
          createNewCollection.success?.data
        );
      }
    }
    if (updateCollection.status === UPDATE_COLLECTION.SUCCESS) {
      if (updateCollection.success?.data.id) {
        updateCollectionModelById(
          updateCollection.success?.data.id,
          updateCollection.success?.data
        );
      }
    }
  }, [createNewCollection.status, updateCollection.status]);

  React.useEffect(() => {
    switch (addTopsToCollection.status) {
      case ADD_TOPS_TO_COLLECTION.SUCCESS:
        _ToastHandler(
          `Top successfully moved to ${addTopsToCollection?.success?.data?.name} Collection`,
          true
        );
        goBack();
        break;
      default:
        break;
    }
  }, [addTopsToCollection.status]);

  React.useEffect(() => {
    switch (moveToCollection.status) {
      case TOPS_MOVE_TO_COLLECTION.SUCCESS:
        _ToastHandler(
          `Top successfully moved to ${moveToCollection?.success?.data?.name} Collection`,
          true
        );
        goBack();
        break;
      default:
        break;
    }
  }, [moveToCollection.status]);

  // Handle Delete Tops response
  React.useEffect(() => {
    switch (deleteTops.status) {
      case DELETE_TOPS.SUCCESS:
        if (selectedTopData?.id) {
          removeTopModelById(selectedTopData?.id);
          removeTopModelByIdFromCollections(selectedTopData?.id);
          removeTopFromCollectionTopList(selectedTopData?.id);
        }
        setOpenEditTopModal(false);
        setIsRefreshing(false);
        break;
      case DELETE_TOPS.FAILED:
        setIsRefreshing(false);
        setOpenEditTopModal(false);
        break;
      default:
        break;
    }
  }, [deleteTops.status]);

  // Handle Delete Tops response
  React.useEffect(() => {
    switch (tradableTop.status) {
      case MARK_TRADABLE_TOPS.SUCCESS:
        if (selectedTopData?.id) {
          updateTopModelFromCollectionsTopList(selectedTopData?.id);
          updateTopFromCollectionList(selectedTopData?.id);
        }
        setOpenEditTopModal(false);
        setIsRefreshing(false);
        break;
      case MARK_TRADABLE_TOPS.FAILED:
        setIsRefreshing(false);
        setOpenEditTopModal(false);
        break;
      default:
        break;
    }
  }, [tradableTop.status]);
  React.useEffect(() => {
    switch (approveOrReject.status) {
      case APPROVE_OR_REJECT.SUCCESS:
        setOpenChildProfileApproveModal(false);
        goBack();
        break;
      case APPROVE_OR_REJECT.FAILED:
        setOpenChildProfileApproveModal(false);
        break;
      default:
        break;
    }
  }, [approveOrReject.status]);

  React.useEffect(() => {
    switch (userListSearch.status) {
      case USER_LIST_SEARCH.SUCCESS:
        setIsCloseGroupUser(false);
        // Create a new array by mapping over userListArray and updating matching items from selectedUser
        const updatedArray2 = userListSearch.success?.data?.results?.map(
          item1 => {
            const matchingItem2 = selectedUsersListSearch.find(
              item2 => item2.id === item1.id
            );
            return matchingItem2
              ? {...item1, ...matchingItem2, alreadyPresentUser: true}
              : {...item1, quantity: 0, alreadyPresentUser: false};
          }
        );
        if (
          userListSearch.success?.pageNumber === FixedValue.CONSTANT_VALUE_0
        ) {
          setUserListSearchResult(updatedArray2 ?? []);
        } else {
          _setUserListSearchResult(updatedArray2 ?? []);
        }
        setInitialLoader(false);
        break;
      case USER_LIST_SEARCH.FAILED:
        setInitialLoader(false);
        break;
      default:
        break;
    }
  }, [userListSearch.status]);

  React.useEffect(() => {
    switch (distributeTop.status) {
      case DISTRIBUTE_SEARCH.SUCCESS:
        _ToastHandler(TEXT.SUCCESSFULLY_TOP_DISTRIBUTED, true);
        setShowDistributiveModal(false);
        _selectOrUnSelectUser([]);
        setSearchUserListText('');
        setSearchFavouriteUserListText('');
        setSearchGroupListText('');
        setSelectedGroupDetail(null);
        break;
      case DISTRIBUTE_SEARCH.FAILED:
        setShowDistributiveModal(false);
        _selectOrUnSelectUser([]);
        setSearchUserListText('');
        setSearchFavouriteUserListText('');
        setSearchGroupListText('');
        setSelectedGroupDetail(null);
        break;
      default:
        break;
    }
  }, [distributeTop.status]);

  React.useEffect(() => {
    switch (groupListSearch.status) {
      case GROUP_LIST_SEARCH.SUCCESS:
        const groupsData = groupListSearch.success?.data?.results;
        if (
          groupListSearch.success?.pageNumber === FixedValue.CONSTANT_VALUE_0
        ) {
          setGroupListSearchResult(groupsData ?? []);
        } else {
          _setGroupListSearchResult(groupsData ?? []);
        }
        setInitialLoader(false);
        break;
      case GROUP_LIST_SEARCH.FAILED:
        setInitialLoader(false);
        break;
      default:
        break;
    }
  }, [groupListSearch.status]);

  React.useEffect(() => {
    if (couponCodeData.status === VERIFY_COUPON_CODE.SUCCESS) {
      setApplyCouponCodeData(couponCodeData?.success?.data ?? null);
    }
  }, [couponCodeData.status]);

  React.useEffect(() => {
    switch (groupUserListSearch.status) {
      case GROUP_USER_LIST_SEARCH.SUCCESS:
        // Create a new array by mapping over userListArray and updating matching items from selectedUser
        const updatedArray2 = groupUserListSearch.success?.data?.results?.map(
          item1 => {
            const matchingItem = selectedUsersListSearch.find(
              item2 => item2.id === item1.id
            );
            return matchingItem
              ? {...item1, ...matchingItem, alreadyPresentUser: true}
              : {...item1, quantity: 0, alreadyPresentUser: false};
          }
        );
        if (
          groupUserListSearch.success?.pageNumber ===
          FixedValue.CONSTANT_VALUE_0
        ) {
          setUserListSearchResult(updatedArray2 ?? []);
        } else {
          _setUserListSearchResult(updatedArray2 ?? []);
        }
        setInitialLoader(false);
        break;
      case GROUP_USER_LIST_SEARCH.FAILED:
        setInitialLoader(false);
        break;
      default:
        break;
    }
  }, [groupUserListSearch.status]);

  const _setIsPaymentFromCash = React.useCallback(
    (value: boolean): void => {
      setIsPaymentFromCash(value);
    },
    [isPaymentFromCash]
  );

  //show and hide welcometop modal
  const _setShowWelcomeTop = React.useCallback(
    (value: boolean): void => {
      setShowWelcomeTop(value);
    },
    [showWelcomeTop]
  );

  //show and hide reedem modal
  const _setOpenReedemModal = React.useCallback(
    (value: boolean): void => {
      setOpenReedemModal(value);
    },
    [openReedemModal]
  );

  const selectAllUsers = React.useMemo(
    () => () => {
      const loggedInUser = userListSearchResult.find(
        user => user?.id === UserDetail?.id
      );
      const afterRemovedLoggedInUser = userListSearchResult.filter(
        user => user.id !== UserDetail?.id
      );
      // Filter out users from group array who exist in selected users
      const updatedGroupArray = afterRemovedLoggedInUser.filter(
        groupUser =>
          !selectedUsersListSearch.some(
            selectedUser => selectedUser?.id === groupUser?.id
          )
      );
      // Calculate total quantity of selected users plus 1 for each new group user
      let totalQuantity = selectedUsersListSearch.reduce(
        (total, user) =>
          total + (user?.quantity ?? FixedValue.CONSTANT_VALUE_0),
        FixedValue.CONSTANT_VALUE_0
      );
      totalQuantity += updatedGroupArray.length;

      // Check if total quantity is within the allowed limit
      const checkForDistribution =
        totalQuantity <=
        (selectedTopData?.quantity ?? FixedValue.CONSTANT_VALUE_0);

      if (checkForDistribution) {
        const newSelectedUsers = [...selectedUsersListSearch];

        const updatedGroupUsers = afterRemovedLoggedInUser.map(groupUser => {
          const selectedUser = selectedUsersListSearch.find(
            user => user?.id === groupUser?.id
          );
          if (selectedUser) {
            // Update group user's quantity from selected user
            groupUser.quantity = selectedUser?.quantity;
            groupUser.alreadyPresentUser = true;
          } else {
            // Set group user's quantity to 1 if not already present
            groupUser.quantity = FixedValue.CONSTANT_VALUE_1;
            groupUser.alreadyPresentUser = true;
            // Add the user to selected users list
            newSelectedUsers.push({...groupUser});
          }
          return groupUser;
        });

        const mergedGroupArray: any = [loggedInUser, ...updatedGroupUsers];
        _setUserListSearchResult(mergedGroupArray, true);
        setSelectedUsersListSearch(newSelectedUsers);

        const totalValue = newSelectedUsers.reduce(
          (total, user) =>
            total + (user?.quantity ?? FixedValue.CONSTANT_VALUE_0),
          FixedValue.CONSTANT_VALUE_0
        );
        const count =
          (selectedTopData?.quantity ?? FixedValue.CONSTANT_VALUE_0) -
          totalValue;
        setRemainingTop(count);
      } else {
        showAlertForExceedQuantity(
          selectedTopData?.quantity ?? FixedValue.CONSTANT_VALUE_0
        );
      }
    },
    [userListSearchResult, selectedUsersListSearch, UserDetail, selectedTopData]
  );

  const unselectAllUsers = React.useMemo(
    () => () => {
      // Create a copy of the selected users list
      let newSelectedUsers = [...selectedUsersListSearch];
      const updatedGroupUsers = userListSearchResult.map(groupUser => {
        const selectedUserIndex = newSelectedUsers.findIndex(
          user => user.id === groupUser.id
        );
        if (selectedUserIndex !== -1) {
          // Remove the user from the selected users list
          newSelectedUsers.splice(
            selectedUserIndex,
            FixedValue.CONSTANT_VALUE_1
          );
        }
        // Reset group user's quantity and alreadyPresentUser
        groupUser.quantity = FixedValue.CONSTANT_VALUE_0;
        groupUser.alreadyPresentUser = false;
        return groupUser;
      });
      setUserListSearchResult(updatedGroupUsers);
      setSelectedUsersListSearch(newSelectedUsers);
      const totalValue = newSelectedUsers.reduce(
        (total, obj) => obj?.quantity! + total,
        FixedValue.CONSTANT_VALUE_0
      );
      const count = selectedTopData?.quantity! - totalValue;
      setRemainingTop(count);
    },
    [
      userListSearchResult,
      selectedUsersListSearch,
      FixedValue.CONSTANT_VALUE_1,
      FixedValue.CONSTANT_VALUE_0,
      selectedTopData,
    ]
  );

  const userListQuantityEdit = React.useCallback(
    (data: CustomUserListSearchModalListData): void => {
      const userData = data as CustomUserListSearchModalListData;
      setUserListSearchResult(prev => {
        return prev.map(item => {
          if (item.id === userData?.id) {
            return {
              ...item,
              quantity: userData?.quantity,
              alreadyPresentUser: userData.alreadyPresentUser,
            };
          }
          return item;
        });
      });
      let newSelectedList: CustomUserListSearchModalListData[] = [];
      // Check if the User is already selected
      const index = selectedUsersListSearch.findIndex(
        selected => selected?.id === userData?.id
      );
      if (index !== FixedValue.CONSTANT_VALUE_MIN_1) {
        const newSelectedItems = [...selectedUsersListSearch];
        newSelectedItems[index].quantity = userData.quantity;
        newSelectedItems[index].alreadyPresentUser =
          userData.alreadyPresentUser;
        setSelectedUsersListSearch(newSelectedItems);
        newSelectedList = newSelectedItems;
      } else {
        let item = {...userData};
        setSelectedUsersListSearch([...selectedUsersListSearch, item]);
        newSelectedList = [...selectedUsersListSearch, item];
      }
      // set remaining Top count
      const totalValue = newSelectedList.reduce(
        (total, obj) => obj.quantity! + total,
        FixedValue.CONSTANT_VALUE_0
      );
      const count = selectedTopData?.quantity! - totalValue;
      _setRemainingTop(count);
    },
    [selectedUsersListSearch, selectedTopData, remainingTop]
  );

  const values = React.useMemo(
    (): IHomeContext => ({
      topsResult,
      collectionsResult,
      openCreateModal,
      openDeleteCollectionModal,
      openChildProfileApproveModal,
      isRefreshing,
      isApprove,
      onApprove,
      isStatus,
      purchasedTops,
      collectionsTops,
      currentActiveTab,
      openEditTopModal,
      selectAllClicked,
      selectedTopData,
      showDistributiveModal,
      seeAllScreenMode,
      selectedTopsToMove,
      selectedFromCollectionData,
      searchUserListText,
      userListSearchResult,
      selectedUsersListSearch,
      remainingTop,
      profileSwitchListModal,
      groupListSearchResult,
      searchGroupListText,
      selectedGroupDetail,
      isCloseGroupUser,
      homeScreenUserData,
      isProfileSwitchToChild,
      homeScreenDataLoader,
      selectedTopsResult,
      totalTopsAmount,
      topAmount,
      applyCouponCodeData,
      discountPrice,
      abuseData,
      isPaymentFromCash,
      showWelcomeTop,
      openReedemModal,
      searchFavouriteUserListText,
      setShowDistributiveModal: _setShowDistributiveModal,
      setCurrentActiveTab: _setCurrentActiveTab,
      setTopsResult: _setTopsResult,
      setCollectionsResult: _setCollectionsResult,
      clearTopsResult: _clearTopsResult,
      clearCollectionsResult: _clearCollectionsResult,
      setOpenCreateModal: _setOpenCreateModal,
      setOpenDeleteCollectionModal: _setOpenDeleteCollectionModal,
      setOpenChildProfileApproveModal: _setOpenChildProfileApproveModal,
      setIsRefreshing: _setIsRefreshing,
      setIsApprove: _setIsApprove,
      setOnApprove: _setOnApprove,
      setIsStatus: _setIsStatus,
      setPurchasedTops: _setPurchasedTops,
      clearPurchasedTops: _clearPurchasedTops,
      setCollectionsTops: _setCollectionsTops,
      clearTopsinCollection: _clearTopsInCollection,
      setSelectAllClicked: _setSelectAllClicked,
      setSelectedTopsToMove: _setSelectedTopsToMove,
      setOpenEditTopModal: _setOpenEditTopModal,
      setSelectedTopData: _setSelectedTopData,
      setSeeAllScreenMode: _setSeeAllScreenMode,
      selectOrUnSelectSingleTop: _selectOrUnSelectSingleTop,
      updateSelectedTopModelsToMove: _updateSelectedTopModelsToMove,
      clearSelectedTopsToMove: _clearSelectedTopsToMove,
      setSelectedFromCollectionData: _setSelectedFromCollectionData,
      setSearchUserListText: _setSearchUserListText,
      setUserListSearchResult: _setUserListSearchResult,
      setSelectedUsersListSearch: _selectOrUnSelectUser,
      setRemainingTop: _setRemainingTop,
      setProfileSwitchListModal: _setProfileSwitchListModal,
      setGroupListSearchResult: _setGroupListSearchResult,
      setSearchGroupListText: _setSearchGroupListText,
      setSelectedGroupDetail: _setSelectedGroupDetail,
      _setUserListSearchResult,
      setIsCloseGroupUser: _setIsCloseGroupUser,
      setHomeScreenUserData: _setHomeScreenUserData,
      setIsProfileSwitchToChild: _setIsProfileSwitchToChild,
      updateTopQuantity: _updateTopQuantity,
      setHomeScreenDataLoader: _setHomeScreenDataLoader,
      setSelectedTopsResult: _setSelectedTopsResult,
      clearSelectedTopsResult: _clearSelectedTopsResult,
      selectOrUnSelectResultTop: _selectOrUnSelectResultTop,
      updateTopResultQuantity: _updateTopResultQuantity,
      setTotalTopsAmount: _setTotalTopsAmount,
      initialLoader,
      setInitialLoader: _setInitialLoader,
      setTopAmount,
      setApplyCouponCodeData,
      setDiscountPrice,
      setAbuseData,
      setIsPaymentFromCash: _setIsPaymentFromCash,
      setShowWelcomeTop: _setShowWelcomeTop,
      setOpenReedemModal: _setOpenReedemModal,
      setSearchFavouriteUserListText: _setSearchFavouriteUserListText,
      selectAllUsers,
      unselectAllUsers,
      userListQuantityEdit,
    }),
    [
      topsResult,
      openCreateModal,
      openDeleteCollectionModal,
      openChildProfileApproveModal,
      collectionsResult,
      purchasedTops,
      collectionsTops,
      currentActiveTab,
      openEditTopModal,
      selectedTopData,
      showDistributiveModal,
      selectedTopsToMove,
      seeAllScreenMode,
      selectedFromCollectionData,
      searchUserListText,
      userListSearchResult,
      selectedUsersListSearch,
      remainingTop,
      profileSwitchListModal,
      groupListSearchResult,
      searchGroupListText,
      selectedGroupDetail,
      isCloseGroupUser,
      homeScreenUserData,
      isProfileSwitchToChild,
      homeScreenDataLoader,
      selectedTopsResult,
      totalTopsAmount,
      isRefreshing,
      topAmount,
      applyCouponCodeData,
      discountPrice,
      abuseData,
      isPaymentFromCash,
      showWelcomeTop,
      openReedemModal,
      searchFavouriteUserListText,
      setIsRefreshing,
      setIsProfileSwitchToChild,
      setShowDistributiveModal,
      setCurrentActiveTab,
      setOpenCreateModal,
      setCollectionsTopsResult,
      setOpenDeleteCollectionModal,
      setOpenChildProfileApproveModal,
      setSelectAllClicked,
      selectAndUnSelectAllTops,
      setOpenEditTopModal,
      setSeeAllScreenMode,
      selectOrUnSelectSingleTop,
      setSearchUserListText,
      setUserListSearchResult,
      setSelectedUsersListSearch,
      setRemainingTop,
      setProfileSwitchListModal,
      setGroupListSearchResult,
      setSearchGroupListText,
      setSelectedGroupDetail,
      _setUserListSearchResult,
      setIsCloseGroupUser,
      setHomeScreenUserData,
      setHomeScreenDataLoader,
      setSelectedTopsResult,
      setTotalTopsAmount,
      initialLoader,
      setInitialLoader,
      setTopAmount,
      setApplyCouponCodeData,
      setDiscountPrice,
      setAbuseData,
      setIsPaymentFromCash,
      setShowWelcomeTop,
      setOpenReedemModal,
      setSearchFavouriteUserListText,
      selectAllUsers,
      unselectAllUsers,
      userListQuantityEdit,
    ]
  );
  return (
    <HomeContext.Provider value={values}>{props.children}</HomeContext.Provider>
  );
};
export {HomeContext, HomeProvider};
