import React from 'react';
import {View} from 'react-native';
import {IUserEdit} from '../../../../types/componentTypes/userProfileTypes';
import {ThemeContext} from '../../../../contextAPI/themeContext';
import {EditprofileStyle} from './editProfile.style';
import LabelInput from '../../../../components/input/labelInput';
import moment from 'moment-timezone';
import {
  FixedValue,
  GlobalStyleValues,
  timeformat,
} from '../../../../constants/enum';
import ProfilePics from '../../../../components/smallDesigns/profilePics';
import {COLORS, TEXT} from '../../../../constants';
import Calendar from '../../../../components/renderSvgs/calendar';
import {getInitials, _InputType} from '../../../../utils/helperFunctions';
import SelectedCountry from '../../../../components/smallDesigns/selectedCountry';
import {useAppSelector} from '../../../../hooks/reduxHooks';
import {userProfileStore} from '../../../../redux/reducers/userProfileSlice';

const ProfileEditForm = (props: IUserEdit) => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = EditprofileStyle(ColorTheme);
  const {
    onPress,
    handleChange,
    values,
    handleBlur,
    firstNameError,
    lastNameError,
    emailError,
    phoneError,
  } = props;
  const dateOfBirth = moment(values.DateOfBirth ?? undefined).format(
    timeformat.MMMDDYY
  );
  const {userData} = useAppSelector(userProfileStore);
  const UserDetail = userData?.success;

  const _Calendar = (): JSX.Element => (
    <Calendar
      width={FixedValue.CONSTANT_VALUE_20}
      height={FixedValue.CONSTANT_VALUE_20}
      stroke={COLORS._8F90A6}
    />
  );
  return (
    <View style={styles.profilePicView}>
      <ProfilePics
        isUpdate
        image={UserDetail?.profileImage ?? ''}
        text={getInitials(UserDetail?.firstName + ' ' + UserDetail?.lastName)}
        mainViewStyle={styles.mainViewStyle}
        initialsTextStyle={styles.initialsTextStyle}
      />
      <View
        style={[styles.nameView, {alignItems: GlobalStyleValues.FLEX_START}]}
      >
        <View style={styles.nameLabelView}>
          <LabelInput
            inputLabel={TEXT.FIRST_NAME}
            value={values.Firstname}
            onChangeText={handleChange(TEXT.FIRST_NAME_)}
            onBlur={handleBlur(TEXT.FIRST_NAME_)}
            maxLength={FixedValue.CONSTANT_VALUE_25}
            haveError={`${firstNameError}`}
            placeholder={TEXT.ENTER_FIRSTNAME}
          />
        </View>
        <View style={styles.nameLabelView}>
          <LabelInput
            inputLabel={TEXT.LAST_NAME}
            value={values.Lastname}
            onChangeText={handleChange(TEXT.LAST_NAME_)}
            onBlur={handleBlur(TEXT.LAST_NAME_)}
            maxLength={FixedValue.CONSTANT_VALUE_25}
            haveError={`${lastNameError}`}
            placeholder={TEXT.ENTER_LASTNAME}
          />
        </View>
      </View>
      <LabelInput
        inputLabel={TEXT.EMAIL_ADDRESS + '*'}
        value={values.Email}
        onChangeText={handleChange(TEXT.EMAIL)}
        onBlur={handleBlur(TEXT.EMAIL)}
        placeholder={TEXT.ENTER_EMAIL_ID_2}
        maxLength={FixedValue.CONSTANT_VALUE_50}
        haveError={`${emailError}`}
      />
      <LabelInput
        inputLabel={TEXT.MOBILE_NUMBER_}
        value={values.MobileNumber}
        onChangeText={handleChange(TEXT.MOBILE_NUMBER)}
        onBlur={handleBlur(TEXT.MOBILE_NUMBER)}
        maxLength={FixedValue.CONSTANT_VALUE_10}
        keyboardType={_InputType.phoneNumber}
        LeftElement={<SelectedCountry isUpdate />}
        onPress={onPress}
        placeholder={TEXT.ENTER_MOBILE_NUMBER}
        haveError={`${phoneError}`}
      />
      <LabelInput
        inputLabel={TEXT.ACCOUT_HOLDER_DOB}
        value={dateOfBirth}
        onChangeText={handleChange(TEXT.DOB_)}
        maxLength={FixedValue.CONSTANT_VALUE_25}
        editable={false}
        Icon={_Calendar}
        placeholder={TEXT.ENTER_DOB}
        onBlur={handleBlur(TEXT.DOB_)}
        haveError={''}
        isDisabled
      />
    </View>
  );
};

export default React.memo(ProfileEditForm);
