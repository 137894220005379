import React from 'react';
import {
  NonNullRefObject,
  TextRefType,
} from '../types/componentTypes/editorType';

import {
  IEditorProvider,
  ImageController,
  ITextShapeContext,
} from './../types/contextTypes/editorContext';

const EditorTextContext = React.createContext<ITextShapeContext>({
  selectedText: null,
  setSelectedText: _val => {},
  textValue: '',
  setTextValue: _value => {},
  selectedController: null,
  setSelectedController: _value => {},
  showTextInput: true,
  setShowTextInput: _val => {},
  showChangeFont: true,
  setShowChangeFont: () => {},
  selectedFont: '',
  setSelectedFont: _val => {},
});

const EditorTextProvider = (props: IEditorProvider): JSX.Element => {
  const [selectedController, setSelectedController] =
    React.useState<ImageController | null>(null);
  const [textValue, setTextValue] = React.useState<string>('');
  const [selectedFont, setSelectedFont] = React.useState<string>('');
  const [showTextInput, setShowTextInput] = React.useState<boolean>(true);
  const [showChangeFont, setShowChangeFont] = React.useState<boolean>(false);
  const [selectedText, setSelectedText] =
    React.useState<NonNullRefObject<TextRefType> | null>(null);

  const _setTextValue = React.useCallback(
    (value: string): void => {
      setTextValue(value);
    },
    [textValue]
  );

  const _setSelectedFont = React.useCallback(
    (value: string): void => {
      setSelectedFont(value);
    },
    [selectedFont]
  );

  const _setSelectedController = React.useCallback(
    (value: ImageController | null): void => {
      setSelectedController(value);
    },
    [selectedController]
  );

  const _setShowTextInput = React.useCallback(
    (value: boolean): void => {
      setShowTextInput(value);
    },
    [showTextInput]
  );

  const _setShowChangeFont = React.useCallback((): void => {
    setShowChangeFont(prev => !prev);
  }, [showChangeFont]);

  const _setSelectedText = React.useCallback(
    (value: NonNullRefObject<TextRefType> | null): void => {
      setSelectedText(value);
    },
    [selectedText]
  );

  const values = React.useMemo(
    (): ITextShapeContext => ({
      selectedController,
      textValue,
      showTextInput,
      selectedText,
      showChangeFont,
      selectedFont,
      setSelectedFont: _setSelectedFont,
      setShowChangeFont: _setShowChangeFont,
      setSelectedText: _setSelectedText,
      setShowTextInput: _setShowTextInput,
      setTextValue: _setTextValue,
      setSelectedController: _setSelectedController,
    }),
    [
      selectedController,
      textValue,
      showTextInput,
      selectedText,
      showChangeFont,
      selectedFont,
      setSelectedFont,
      setShowChangeFont,
      setSelectedText,
      setShowTextInput,
      setTextValue,
      setSelectedController,
    ]
  );

  return (
    <EditorTextContext.Provider value={values}>
      {props.children}
    </EditorTextContext.Provider>
  );
};

export {EditorTextContext, EditorTextProvider};
