import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
const ArrowUp = (props: SvgProps) => (
  <Svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M13.3638 7.63623C13.195 7.63623 13.0331 7.70328 12.9138 7.82262C12.7944 7.94196 12.7274 8.10382 12.7274 8.2726V10.9536C12.7269 11.4238 12.5399 11.8747 12.2074 12.2072C11.8749 12.5397 11.4241 12.7267 10.9538 12.7272H3.0463C2.57608 12.7267 2.12526 12.5397 1.79276 12.2072C1.46026 11.8747 1.27324 11.4238 1.27274 10.9536V8.2726C1.27274 8.10382 1.20569 7.94196 1.08635 7.82262C0.967009 7.70328 0.805146 7.63623 0.63637 7.63623C0.467594 7.63623 0.305731 7.70328 0.186388 7.82262C0.0670459 7.94196 0 8.10382 0 8.2726V10.9536C0.00084229 11.7613 0.322062 12.5356 0.893172 13.1068C1.46428 13.6779 2.23863 13.9991 3.0463 13.9999H10.9538C11.7615 13.9991 12.5359 13.6779 13.107 13.1068C13.6781 12.5356 13.9993 11.7613 14.0001 10.9536V8.2726C14.0001 8.10382 13.9331 7.94196 13.8137 7.82262C13.6944 7.70328 13.5325 7.63623 13.3638 7.63623Z"
      fill="#E9EEFB"
    />
    <Path
      d="M3.63706 4.90437L6.36899 2.17243V10.1818C6.36899 10.3506 6.43604 10.5124 6.55538 10.6318C6.67472 10.7511 6.83659 10.8182 7.00536 10.8182C7.17414 10.8182 7.336 10.7511 7.45534 10.6318C7.57469 10.5124 7.64173 10.3506 7.64173 10.1818V2.17243L10.3737 4.90437C10.4937 5.02029 10.6544 5.08443 10.8213 5.08298C10.9881 5.08153 11.1478 5.0146 11.2657 4.89661C11.3837 4.77863 11.4507 4.61902 11.4521 4.45216C11.4536 4.28531 11.3894 4.12456 11.2735 4.00454L7.45528 0.18632C7.33594 0.0670195 7.1741 0 7.00536 0C6.83662 0 6.67479 0.0670195 6.55545 0.18632L2.73723 4.00454C2.62131 4.12456 2.55717 4.28531 2.55862 4.45216C2.56007 4.61902 2.62699 4.77863 2.74498 4.89661C2.86297 5.0146 3.02258 5.08153 3.18943 5.08298C3.35629 5.08443 3.51704 5.02029 3.63706 4.90437Z"
      fill="#E9EEFB"
    />
  </Svg>
);
export default ArrowUp;
