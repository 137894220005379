import React from 'react';
import {View, Pressable, Image} from 'react-native';

import {COLORS, FixedValue} from '../../../constants';
import {
  ImageRefType,
  UserImageControlsItemProps,
  TopEditorItemType,
} from '../../../types/componentTypes/editorType';
import RenderUserImages from './renderUserImages';
import WhiteCheck from '../../renderSvgs/greenTick';
import {styles} from './../editorComp.style';
import {scaleHeight, scaleWidth} from '../../../utils/responsive';

const ShowSvgImages = ({
  imageSource,
  index,
  selectItemCallback,
  height,
  width,
  isSelected,
  addItemCallback,
  newItemIndex,
  imageIndex,
}: UserImageControlsItemProps) => {

  const _selectItemCallback = React.useCallback((itemIndex: number): void => {
    if (selectItemCallback) {
      selectItemCallback(itemIndex);
    }
  }, []);

  const addEditorItem = React.useCallback((): void => {
    const ref = React.createRef<ImageRefType>();
    addItemCallback(
      <RenderUserImages
        ref={ref}
        key={`top-element-${newItemIndex}`}
        elementId={`top-element-${newItemIndex}`}
        selectItemCallback={_selectItemCallback}
        imageSource={imageSource}
        imageIndex={imageIndex}
        width={width}
        height={height}
        elementIndex={newItemIndex}
        itemPositionOverride={{itemType: TopEditorItemType.IMAGE}}
      />
    );
  }, [
    _selectItemCallback,
    addItemCallback,
    imageSource,
    width,
    height,
    newItemIndex,
  ]);

  return (
    <Pressable
      style={styles._imageStyle}
      key={'_i' + index}
      disabled={isSelected}
      onPress={addEditorItem}
    >
      <Image
        source={{uri: imageSource}}
        borderRadius={FixedValue.CONSTANT_VALUE_4}
        style={styles.imageSource}
      />
      {isSelected && (
        <View style={styles.whiteCheck}>
          <WhiteCheck
            stroke={COLORS.WHITE}
            width={scaleWidth(FixedValue.CONSTANT_VALUE_20)}
            height={scaleHeight(FixedValue.CONSTANT_VALUE_20)}
          />
        </View>
      )}
    </Pressable>
  );
};

export default React.memo(ShowSvgImages);
