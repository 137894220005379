import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";
const ArrowWithCircle = (props: SvgProps) => (
  <Svg
    width={34}
    height={34}
    viewBox="0 0 34 34"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M17 2.8335C14.1981 2.8335 11.4591 3.66436 9.12942 5.22101C6.79973 6.77766 4.98395 8.99019 3.91171 11.5788C2.83947 14.1674 2.55892 17.0159 3.10554 19.7639C3.65217 22.512 5.00141 25.0363 6.98266 27.0175C8.9639 28.9988 11.4882 30.348 14.2362 30.8946C16.9843 31.4412 19.8327 31.1607 22.4214 30.0885C25.01 29.0162 27.2225 27.2004 28.7792 24.8707C30.3358 22.541 31.1667 19.8021 31.1667 17.0002C31.1667 15.1398 30.8002 13.2976 30.0883 11.5788C29.3764 9.86004 28.3328 8.29831 27.0173 6.98282C25.7019 5.66732 24.1401 4.62381 22.4214 3.91187C20.7026 3.19993 18.8604 2.8335 17 2.8335ZM17 28.3335C14.7585 28.3335 12.5673 27.6688 10.7035 26.4235C8.83978 25.1782 7.38716 23.4081 6.52937 21.3372C5.67157 19.2663 5.44714 16.9876 5.88444 14.7891C6.32173 12.5907 7.40113 10.5713 8.98612 8.98629C10.5711 7.40129 12.5905 6.3219 14.789 5.8846C16.9874 5.4473 19.2662 5.67174 21.3371 6.52953C23.408 7.38732 25.178 8.83994 26.4233 10.7037C27.6686 12.5675 28.3333 14.7586 28.3333 17.0002C28.3333 20.006 27.1393 22.8886 25.0139 25.014C22.8885 27.1395 20.0058 28.3335 17 28.3335Z"
      fill="#1F53D3"
    />
    <Path
      d="M20.6488 15.394L23.675 18.4203L20.6488 21.4466"
      stroke="#1F53D3"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M16.6138 18.4193H23.675"
      stroke="#1F53D3"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M13.0263 18.5564L10 15.5302L13.0263 12.5039"
      stroke="#1F53D3"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10 15.5306H17.0613"
      stroke="#1F53D3"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default ArrowWithCircle;
