import React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  ViewStyle,
  ActivityIndicator,
} from 'react-native';

import {
  BASESTYLE,
  COLORS,
  FixedValue,
  Percentage,
  FONT_NAMES,
} from '../../constants';
import {IPrimaryButton} from '../../types/componentTypes/buttonType';
import {normalizeFont, scaleHeight} from '../../utils/responsive';

const styles = (isPressIn: boolean, isDisabled?: boolean) =>
  StyleSheet.create({
    mainView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_58),
      width: Percentage.PRECENTAGE_100,
      borderRadius: FixedValue.CONSTANT_VALUE_100,
    },
    labelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: isDisabled ? COLORS.BDBDBD : COLORS.WHITE,
    },
    enableBtn: {
      backgroundColor: isPressIn ? COLORS._1942A9 : COLORS.PRIMARY_BLUE,
      shadowColor: COLORS.PRIMARY_BLUE,
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
      shadowRadius: FixedValue.CONSTANT_VALUE_25,
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_2,
        height: FixedValue.CONSTANT_VALUE_2,
      },
      elevation: FixedValue.CONSTANT_VALUE_2,
    },
    disableBtn: {
      backgroundColor: COLORS.EAEAEA,
    },
  });

const PrimaryButton: React.FC<IPrimaryButton> = (props: IPrimaryButton) => {
  const [isPressIn, setPressIn] = React.useState<boolean>(false);
  const {onPress, isDisabled, labelStyle, buttonLabel, element,isLoading} = props;
  const [apiStatus, setApiStatus] = React.useState<boolean>(isLoading || false);
  const [_isDisabled, setIsDisabled] = React.useState<boolean>(isDisabled || false);

  const style = styles(isPressIn, _isDisabled);
  const buttonStyle: ViewStyle = !_isDisabled
    ? style.enableBtn
    : style.disableBtn;

  const PressOut = React.useCallback((): void => {
    setPressIn(false);
  }, []);

  const PressIn = React.useCallback(async (): Promise<void> => {
    setPressIn(true);
    if (!_isDisabled) {
      onPress();
    }
  }, [_isDisabled, isPressIn, onPress]);

  React.useEffect(() => {
    setApiStatus(isLoading || false);
    if(isLoading){
      setIsDisabled(true)
    } else {
      setIsDisabled(isDisabled || false)
    }
  }, [isLoading]);

  React.useEffect(() => {
    if(isDisabled){
      setIsDisabled(true)
    } else {
      setIsDisabled(isDisabled || false)
    }
  }, [isDisabled]);

  const buttonMainView: TextStyle = props?.buttonwidth
    ? props?.buttonwidth
    : style.mainView;

  return (
    <Pressable
      onPressOut={PressOut}
      onPressIn={PressIn}
      style={[buttonMainView, buttonStyle]}
      disabled={isLoading}
    >
      {apiStatus ? (
        <ActivityIndicator color={COLORS.PRIMARY_BLUE} size="small" />
      ) : (
        <>
          {element && element}
          <Text style={[style.labelstyle, labelStyle]}>{buttonLabel}</Text>
        </>
      )}
    </Pressable>
  );
};

export default React.memo(PrimaryButton);
