import React from 'react';
import {View, ActivityIndicator, Pressable, Text} from 'react-native';

import {COLORS, TEXT} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {CardProps} from '../../types/reducerTypes/searchTabStoreType';
import {manteltabCompStyle} from './manteltabComp.style';
import useAwsS3 from '../../hooks/libraryHooks/useAwsS3';
import QunatitySelector from '../smallDesigns/qunatitySelector';
import CircularView from '../CircularView';
import {getTopImage} from '../../utils/imageUtils';

const TopCard = (props: CardProps): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = manteltabCompStyle(ColorTheme);
  const {loadSignedUrl} = useAwsS3();
  const {
    topModel,
    onAddButtonTap,
    onUpdateQuantityTap,
    showQuantity,
    isInputQuantity,
    onChangeQuantity,
    setIskeyboardFocused,
    checkQuantityOnOnChange = false,
    callAddAndSubFunction = false,
  } = props;
  const [svgCode, setSvgCode] = React.useState<JSX.Element | undefined>(
    undefined
  );

  const getSvgUrl = React.useCallback(async (): Promise<void> => {
    const imageUrl = await loadSignedUrl(topModel?.image ?? '');
    if (imageUrl) {
      const topImage = await getTopImage(imageUrl ?? '');
      setSvgCode(topImage);
    }
  }, [topModel?.image, svgCode]);

  React.useEffect(() => {
    getSvgUrl();
  }, [topModel?.image]);

  return (
    <View style={styles.topCardContainer}>
      {/* Wrap the SVG in a fixed size container with hidden overflow */}
      {svgCode ? ( // conditionally render the SVG data
        <View style={styles.topImageContainer}>{svgCode}</View>
      ) : (
        <View style={styles.progressLoader}>
          <ActivityIndicator size={'small'} color={COLORS.PRIMARY_BLUE} />
        </View>
      )}
      {showQuantity && (
        <CircularView quantity={topModel.quantity?.toString() ?? ''} isSmall />
      )}
      <View style={{position: 'absolute', bottom: 5}}>
        {!topModel?.isSelected ? (
          <View style={styles.quantitySelectorViewStyle}>
            <Pressable style={styles.addNowButton} onPress={onAddButtonTap}>
              <Text style={styles.buyButtonText}>{TEXT.ADD_NOW}</Text>
            </Pressable>
          </View>
        ) : (
          <QunatitySelector
            count={topModel.selectedQuantity ?? 1}
            qunatityTextStyle={styles.quantityTextStyle}
            quantityStyle={styles.quantitySelectorViewStyle}
            plusStyle={styles.plusButtonStyle}
            minusStyle={styles.minuButtonStyle}
            plusTextStyle={styles.plusTextStyle}
            onMinusPress={() => onUpdateQuantityTap(TEXT.MINNUS)}
            onPlusPress={() => onUpdateQuantityTap(TEXT.ADD)}
            isInputQuantity={isInputQuantity}
            setIskeyboardFocused={setIskeyboardFocused}
            checkQuantityOnOnChange={checkQuantityOnOnChange}
            onChangeQuantity={(val: number) => {
              if (callAddAndSubFunction) {
                onUpdateQuantityTap(TEXT.MANUAL, val);
              } else {
                onChangeQuantity && onChangeQuantity(val);
              }
            }}
          />
        )}
      </View>
    </View>
  );
};

export default TopCard;
