/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import ScrollableContainer from '../../components/layout/scrollableContainer';
import LoginContentView from './loginContentView';

const LoginScreen: React.FC = () => {
  return (
      <ScrollableContainer>
        <LoginContentView />
      </ScrollableContainer>
  );
};

export default React.memo(LoginScreen);
