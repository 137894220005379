import React from 'react';
import {View, Text, ViewStyle, Pressable} from 'react-native';
import {useIsFocused} from '@react-navigation/native';

import {NotificationCardStyle} from './notificationCards.style';
import {ThemeContext} from '../../contextAPI/themeContext';
import {COLORS, ERROR_MESSAGES, ROUTE_NAME, TEXT} from '../../constants';
import {INotificationListItem} from '../../types/reducerTypes/notificationTypes';
import {_ToastHandler, getDateFormated} from '../../utils/helperFunctions';
import {
  ACCEPTED_OR_REJECTED,
  APPROVED_OR_REJECT,
  CHILD_PERMISSION,
  FixedValue,
  NOTIFICATION_SUB_TYPE,
  NOTIFICATION_TYPE,
} from '../../constants/enum';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import {NotificationContext} from '../../contextAPI/notificationContext';
import NotificationMessageText from '../smallDesigns/notificationMessageText';
import {
  APPROVE_RECEIVED_OFFER,
  GET_CHILD_PROFILE_DATA,
  REJECT_RECEIVED_OFFER,
  SENT_TRADE_APPROVE_OR_REJECT_API,
  USER_PROFILE_DATA,
} from '../../constants/actionTypes';
import {
  onUserDataStart,
  userProfileStore,
} from '../../redux/reducers/userProfileSlice';
import useNotificationHook from '../../hooks/componentHooks/useNotificationHooks';
import StatusButton from './statusButton';
import ImageDisplay from './imageDisplay';
import {TradeTabStore} from '../../redux/reducers/tradeTabSlice';
import ConfirmationModal from '../../modals/confirmationModal/confirmationModal';
import {childProfileStore} from '../../redux/reducers/childProfileSlice';
import CustomButton from '../button/customButton';
import {navigate} from '../../services/navigationService';
import {ChildProfileContext} from '../../contextAPI/childProfileContext';
import useTradeReceviedOrSentHooks from '../../hooks/componentHooks/useTradeReceviedOrSentHooks';
import {TradeContext} from '../../contextAPI/tradeContext';
import useTradingApprovalHooks from '../../hooks/componentHooks/useTradingApprovalHooks';

type groupNotificationType = {
  item: INotificationListItem;
  index: number;
};

const NotificationCard = (props: groupNotificationType): JSX.Element => {
  const {item, index} = props;
  const {ColorTheme, themeState} = React.useContext(ThemeContext);
  const {userData} = useAppSelector(userProfileStore);
  const {styles} = NotificationCardStyle(ColorTheme);
  const {
    notificationListData,
    acceptRejectAction,
    setConfirmationModal,
    showConfirmationModal,
    setShowDistributionModal,
    setIsForTradeReport,
    setSelectedNotificationCard,
    setSelectedNotificationCardIndex,
  } = React.useContext(NotificationContext);
  const {approveOffer, rejectOffer} = useAppSelector(TradeTabStore);
  const {onSentTradeApproveOrRejectData} = useAppSelector(childProfileStore);
  const dispatch = useAppDispatch();
  const {childDistributeRequestApproveOrRejectOrReportApi} =
    useTradingApprovalHooks();
  const isFocused = useIsFocused();

  const [selectType, setSelectedType] = React.useState<string>(TEXT.ACCEPT);
  const [isThisNotificationWork, setThisNotificationWork] =
    React.useState<boolean>(false);
  const {
    groupInvitaionAcceptRejectApiCall,
    childTradableApproveRejecApiCall,
    tradeOfferAcceptRejectApiCall,
    onPressNo,
    getModalRejectTitle,
    getModalSuccessTitle,
    callChildProfileAPI,
  } = useNotificationHook();
  const {childProfileData} = useAppSelector(userProfileStore);
  const {setChildUserData} = React.useContext(ChildProfileContext);
  const UserDetail = userData?.success;
  const {
    getTradeOfferDetail,
    getDistributeTopDetail,
    callTopTradeStatusUpdateAPI,
  } = useTradeReceviedOrSentHooks();
  const {setOpenReceviedOfferModal} = React.useContext(TradeContext);

  const getUserData = React.useCallback((): void => {
    setConfirmationModal({isVisible: false});
    dispatch(onUserDataStart());
  }, []);

  const onNotificationAction = React.useCallback((): void => {
    if (item.type === NOTIFICATION_TYPE.GROUP_PUSH) {
      setThisNotificationWork(false);
      groupInvitaionAcceptRejectApiCall(item, selectType, index);
    } else if (item.type === NOTIFICATION_TYPE.TRADABLE_PUSH) {
      setThisNotificationWork(false);
      const type: string =
        selectType === TEXT.ACCEPT
          ? APPROVED_OR_REJECT.APPROVED
          : APPROVED_OR_REJECT.REJECT;
      childTradableApproveRejecApiCall(item, type, index);
    } else if (item.type === NOTIFICATION_TYPE.TRADE_OFFER_PUSH) {
      const jsonData = item.json ? JSON.parse(item.json) : null;
      if (jsonData.isChild) {
        const status: string =
          selectType === TEXT.ACCEPT
            ? APPROVED_OR_REJECT.APPROVED
            : APPROVED_OR_REJECT.REJECT;
        const params = {
          status,
          offerId: Number(item.requestId),
        };
        callTopTradeStatusUpdateAPI(params);
      } else {
        const type: string =
          selectType === TEXT.ACCEPT ? TEXT.ACCEPT_TEXT : TEXT.REJECT;
        tradeOfferAcceptRejectApiCall(item, type, index);
      }
    } else if (
      item.type === NOTIFICATION_TYPE.DISTRIBUTION_PUSH ||
      item.type === NOTIFICATION_TYPE.DISTRIBUTION_PUSH_EMAIL
    ) {
      const type: string =
        selectType === TEXT.ACCEPT
          ? ACCEPTED_OR_REJECTED.ACCEPTED
          : ACCEPTED_OR_REJECTED.REJECTED;
      const minorId: number =
        JSON.parse(item?.json ?? '')?.minorId ?? FixedValue.CONSTANT_VALUE_0;
      let params: any = {
        distributeRequestId: item?.requestId,
        status: type,
        index,
        requestForUnRegisteredUser:
          item.type === NOTIFICATION_TYPE.DISTRIBUTION_PUSH_EMAIL,
      };
      if (minorId !== FixedValue.CONSTANT_VALUE_0) {
        params.minorId = minorId ?? FixedValue.CONSTANT_VALUE_0;
      }
      childDistributeRequestApproveOrRejectOrReportApi(params);
    }
  }, [notificationListData, selectType]);

  const onPressActionButton = React.useCallback((type: string): void => {
    setThisNotificationWork(true);
    setSelectedType(type);
    const title: string =
      type === TEXT.ACCEPT
        ? getModalSuccessTitle(item.type)
        : getModalRejectTitle(item.type);
    setConfirmationModal({isVisible: true, type, title});
  }, []);

  React.useEffect(() => {
    if (isThisNotificationWork && isFocused) {
      if (approveOffer.status === APPROVE_RECEIVED_OFFER.SUCCESS) {
        setThisNotificationWork(false);
        acceptRejectAction(index);
      }
      if (rejectOffer.status === REJECT_RECEIVED_OFFER.SUCCESS) {
        setThisNotificationWork(false);
        acceptRejectAction(index);
      }
      if (
        onSentTradeApproveOrRejectData.status ===
        SENT_TRADE_APPROVE_OR_REJECT_API.SUCCESS
      ) {
        setThisNotificationWork(false);
        acceptRejectAction(index);
      }
    }
  }, [
    approveOffer.status,
    rejectOffer.status,
    isFocused,
    isThisNotificationWork,
    onSentTradeApproveOrRejectData.status,
  ]);

  React.useEffect(() => {
    if (isThisNotificationWork && isFocused) {
      switch (userData.status) {
        case USER_PROFILE_DATA.SUCCESS:
          if (
            item.type === NOTIFICATION_TYPE.GROUP_PUSH &&
            userData?.success?.permissionsStatus?.groupPermissionStatus ===
              CHILD_PERMISSION.NOT_ALLOWED
          ) {
            setThisNotificationWork(false);
            _ToastHandler(ERROR_MESSAGES.ACTION_NOT_PERFORM, false);
          } else if (
            item.type === NOTIFICATION_TYPE.TRADABLE_PUSH &&
            userData?.success?.permissionsStatus?.tradablePermissionStatus ===
              CHILD_PERMISSION.NOT_ALLOWED
          ) {
            setThisNotificationWork(false);
            _ToastHandler(ERROR_MESSAGES.ACTION_NOT_PERFORM, false);
          } else onNotificationAction();
          break;
        case USER_PROFILE_DATA.FAILED:
          setThisNotificationWork(false);
          break;
        default:
          break;
      }
    }
  }, [
    userData.status,
    isFocused,
    isThisNotificationWork,
    notificationListData,
  ]);

  React.useEffect(() => {
    if (isFocused) {
      switch (childProfileData.status) {
        case GET_CHILD_PROFILE_DATA.SUCCESS:
          setChildUserData(childProfileData?.success?.data ?? null);
          navigate(ROUTE_NAME.TOP_BUYING_APPROVAL);
          break;
        default:
          break;
      }
    }
  }, [childProfileData.status, isFocused]);

  const onPressNotificationCard = React.useCallback((): void => {
    const checkTypeForTrade = item?.type === NOTIFICATION_TYPE.TRADE_OFFER_PUSH;
    const checkTypeForDistribute =
      item?.type === NOTIFICATION_TYPE.DISTRIBUTION_PUSH ||
      item?.type === NOTIFICATION_TYPE.DISTRIBUTION_PUSH_EMAIL;
    const checkSubtype =
      item?.subType === NOTIFICATION_SUB_TYPE.NOTIFICATION_INVITATION ||
      item?.subType === NOTIFICATION_SUB_TYPE.NOTIFICATION_REJECTED ||
      item?.subType === NOTIFICATION_SUB_TYPE.NOTIFICATION_ACCEPTED;
    setSelectedNotificationCard(item);
    setSelectedNotificationCardIndex(index);
    const openTradeModal = checkSubtype && checkTypeForTrade;
    const openDistributeModal = checkTypeForDistribute && checkSubtype;
    if (openTradeModal) {
      setIsForTradeReport(true);
      getTradeOfferDetail(item);
      setOpenReceviedOfferModal();
    } else if (openDistributeModal) {
      setIsForTradeReport(false);
      getDistributeTopDetail(item);
      setShowDistributionModal(true);
    }
  }, [item, UserDetail, index]);

  const bgColor: ViewStyle = !item.seen
    ? {backgroundColor: themeState === 'dark' ? COLORS._3A3B3C : COLORS.F3F7FF}
    : {};
  return (
    <Pressable
      style={[styles.container, bgColor]}
      onPress={onPressNotificationCard}
    >
      <ImageDisplay item={item} />
      <View style={styles.textView}>
        <NotificationMessageText
          message={item.title}
          textStyle={styles.titleText}
        />
        <NotificationMessageText
          message={item.message}
          textStyle={styles.titleText1}
        />
        <View style={styles.approveButton}>
          {(item.type === NOTIFICATION_TYPE.PURCHASE_PUSH &&
            UserDetail?.role === TEXT.ROLE_PARENT) ||
          (item.type === NOTIFICATION_TYPE.PURCHASE_PLAN_PUSH &&
            UserDetail?.role === TEXT.ROLE_PARENT) ? (
            item?.status === undefined ? (
              <CustomButton
                buttonLabel={
                  item.type === NOTIFICATION_TYPE.PURCHASE_PUSH
                    ? TEXT.PURCHASE_TOP
                    : TEXT.PURCHASE_TOPCREDITS
                }
                buttonwidth={styles.primaryButtonStyle1}
                labelStyle={styles.buyButtonLabelstyle}
                buttonBackGroundColor={styles.noButtonBackGroundColor}
                onPress={() =>
                  callChildProfileAPI(JSON.parse(item?.json ?? '')?.id)
                }
              />
            ) : (
              <StatusButton
                item={item}
                onPressAccept={() => onPressActionButton(TEXT.ACCEPT)}
                onPressReject={() => onPressActionButton(TEXT.REJECT)}
              />
            )
          ) : (
            <StatusButton
              item={item}
              onPressAccept={() => onPressActionButton(TEXT.ACCEPT)}
              onPressReject={() => onPressActionButton(TEXT.REJECT)}
            />
          )}
        </View>
        <Text style={styles.titleTextAge}>
          {getDateFormated(item.createdTime)}
        </Text>
      </View>
      {isThisNotificationWork && (
        <ConfirmationModal
          title={showConfirmationModal.title ?? ''}
          modalVisible={showConfirmationModal.isVisible ?? false}
          onPressYes={getUserData}
          onShowHide={() => {
            onPressNo();
            setThisNotificationWork(false);
          }}
        />
      )}
    </Pressable>
  );
};

export default React.memo(NotificationCard);
