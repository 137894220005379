import {useIsFocused} from '@react-navigation/native';
import React from 'react';
import {View} from 'react-native';
import ViewContainer from '../../components/layout/viewContainer';
import {TEXT} from '../../constants';
import {ProfileTabContext} from '../../contextAPI/profileTabContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import useChildListHooks from '../../hooks/componentHooks/useChildListHooks';
import {useAppSelector} from '../../hooks/reduxHooks';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import TopCollection from './topCollectionLists';
import {TopCollectionStyle} from './topCollections.style';

const CollectionScreen = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = TopCollectionStyle(ColorTheme);
  const isFocused = useIsFocused();
  const {callParentChildListAPI} = useChildListHooks();
  const {userData} = useAppSelector(userProfileStore);
  const UserDetail = userData?.success;
  const {parentChildListData} = React.useContext(ProfileTabContext);

  React.useEffect(() => {
    if (
      isFocused &&
      UserDetail?.role === TEXT.ROLE_PARENT &&
      parentChildListData === null
    ) {
      callParentChildListAPI();
    }
  }, [isFocused]);
  return (
    <ViewContainer>
      <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
        <TopCollection />
      </View>
    </ViewContainer>
  );
};

export default React.memo(CollectionScreen);
