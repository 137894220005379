import StyleSheet from 'react-native-media-query';
import {
  FixedValue,
  Percentage,
  FONT_NAMES,
  BASESTYLE,
  COLORS,
  GlobalStyleValues,
} from '../../constants';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';
import { IColorTheme } from '../../types/contextTypes/themeContext';

export const signUpStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.selfCenter,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    titleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_30),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: theme.color,
    },
    titleTextTwo: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: theme.color,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_36),
    },
    descText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme.color,
    },
    spaceContent: {
      marginTop: FixedValue.CONSTANT_VALUE_26,
    },
    nameView: {
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
    },
    nameLabelView: {
      width: Percentage.PRECENTAGE_48,
    },
    dividerLine: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_1),
      width: Percentage.PRECENTAGE_100,
      backgroundColor: theme.color,
      opacity: FixedValue.CONSTANT_VALUE_02,
      marginVertical: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    morefieldTerm: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: theme.color,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_MIN_1),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    bottomBtn: {
      marginVertical: scaleHeight(FixedValue.CONSTANT_VALUE_16),
    },
    alreadyText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_15),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: theme.color,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_25),
    },
    loginText: {
      color: COLORS.PRIMARY_BLUE,
    },
    bottomLine: {
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_45),
      ...BASESTYLE.selfCenter,
    },
    dobContainer: {
      width: Percentage.PRECENTAGE_100,
      height: FixedValue.CONSTANT_VALUE_90,
      alignSelf: GlobalStyleValues.FLEX_START,
    },
    marginTop10: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10)
    }
  });
