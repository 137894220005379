import React from 'react';
import {View} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import {ISingleTabProps} from '../../types/componentTypes/layoutType';
import TopTabBarLayout from '../../components/layout/topTabBarLayout';
import {DistributingTopStyle} from './distributingTop.style';
import distributingRequestIndex from './distributingRequest/distributingRequestIndex';
import distributingApproveIndex from './distributingTopApproveList/distributingApproveIndex';
import useTradingApprovalHooks from '../../hooks/componentHooks/useTradingApprovalHooks';
import {useIsFocused} from '@react-navigation/native';
import {ChildProfileContext} from '../../contextAPI/childProfileContext';
import {USER_LIST_TYPE} from '../../constants/enum';
import AppLoader from '../../components/smallDesigns/appLoader';
import {
  DISTRIBUTE_REQUEST_APPROVE_OR_REJECT_API,
  USER_REMOVE_FROM_DISTRIBUTE_APPROVED_LIST,
} from '../../constants/actionTypes';
import {useAppSelector} from '../../hooks/reduxHooks';
import {childProfileStore} from '../../redux/reducers/childProfileSlice';
import distributingReceivedRequestIndex from './distributingReceivedRequest/distributingReceivedRequestIndex';

const DistributingTopContent = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = DistributingTopStyle(ColorTheme);
  const isFocused = useIsFocused();
  const {
    callDistributeApproveListAPI,
    callDistributeRequestListAPI,
    callDistributeReceivedRequestListAPI,
  } = useTradingApprovalHooks();
  const {initialLoader, setInitialLoader, setSearchTypeForTradeAndDistribute} =
    React.useContext(ChildProfileContext);
  const {onSentDistributeApproveOrRejectData, onUserRemoveFromDistribute} =
    useAppSelector(childProfileStore);

  React.useEffect((): void => {
    if (isFocused) {
      setInitialLoader(true);
      callDistributeReceivedRequestListAPI(FixedValue.CONSTANT_VALUE_0);
      callDistributeRequestListAPI(FixedValue.CONSTANT_VALUE_0);
      callDistributeApproveListAPI(FixedValue.CONSTANT_VALUE_0);
      setSearchTypeForTradeAndDistribute(USER_LIST_TYPE.DISTRIBUTE);
    }
  }, [isFocused]);

  React.useEffect(() => {
    switch (onSentDistributeApproveOrRejectData.status) {
      case DISTRIBUTE_REQUEST_APPROVE_OR_REJECT_API.SUCCESS:
        callDistributeRequestListAPI(FixedValue.CONSTANT_VALUE_0);
        break;
      default:
        break;
    }
  }, [onSentDistributeApproveOrRejectData.status]);

  React.useEffect(() => {
    switch (onUserRemoveFromDistribute.status) {
      case USER_REMOVE_FROM_DISTRIBUTE_APPROVED_LIST.SUCCESS:
        callDistributeApproveListAPI(FixedValue.CONSTANT_VALUE_0);
        break;
      default:
        break;
    }
  }, [onUserRemoveFromDistribute.status]);

  const tabBarList: ISingleTabProps[] = [
    {
      routeName: ROUTE_NAME.DISTRIBUTING_TOP_RECEIVED_REQUEST,
      component: distributingReceivedRequestIndex,
      tabBarName: TEXT.RECEIVED,
    },
    {
      routeName: ROUTE_NAME.DISTRIBUTING_TOP_REQUEST,
      component: distributingRequestIndex,
      tabBarName: TEXT.TOP_SENT,
    },
    {
      routeName: ROUTE_NAME.DISTRIBUTING_TOP_APPROVE_LIST,
      component: distributingApproveIndex,
      tabBarName: TEXT.TOP_APPROVED_LIST,
    },
  ];

  return (
    <View style={styles.container}>
      {initialLoader && <AppLoader />}
      <TopTabBarLayout
        tabArray={tabBarList}
        setCurrentActiveTab={FixedValue.CONSTANT_VALUE_0}
      />
    </View>
  );
};

export default React.memo(DistributingTopContent);
