import React from 'react';
import {useAppDispatch, useAppSelector} from '../reduxHooks';
import {ERROR_MESSAGES, FixedValue, ROUTE_NAME} from '../../constants';
import {
  onReceivedOfferStart,
  onSentOfferStart,
  TradeTabStore,
  onApproveOfferStart,
  onRejectOfferStart,
  onCancelOfferStart,
  onTradeReportStart,
  onTradeOfferStart,
  onDistributeTopDetailStart,
  onTopTradeStatusUpdateStart,
} from '../../redux/reducers/tradeTabSlice';
import {TopModel} from '../../types/reducerTypes/searchTabStoreType';
import {NOTIFICATION_TYPE,TopType} from '../../constants/enum';
import {navigate} from '../../services/navigationService';
import {useFormik} from 'formik';
import {AbuseReportSchema} from '../../utils/formikSchema';
import {TradeContext} from '../../contextAPI/tradeContext';
import {ReportFormState} from '../../types/reducerTypes/tradeTabTypes';
import {INotificationListItem} from '../../types/reducerTypes/notificationTypes';
import {NotificationContext} from '../../contextAPI/notificationContext';

const useTradeReceviedOrSentHooks = () => {
  const dispatch = useAppDispatch();
  const {receivedOffers, sentOffers} = useAppSelector(TradeTabStore);
  const {setOpenReportModal} = React.useContext(TradeContext);
  const {selectedNotificationCard} = React.useContext(NotificationContext);
  const [reportState, setReportState] = React.useState<ReportFormState>({
    reportId: '',
    description: '',
  });
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isValid,
    values,
    resetForm,
    setValues,
  } = useFormik<ReportFormState>({
    validationSchema: AbuseReportSchema,
    initialErrors: {
      reportId: '',
      description: ERROR_MESSAGES.REQUIRED,
    },
    initialTouched: {reportId: false, description: false},
    initialValues: {reportId: '', description: ''},
    onSubmit: _val => console.log('onSubmit'),
  });

  const getOffersListAPI = React.useCallback(
    (offerType: string, page: number): void => {
      const params = {offerType, page, size: FixedValue.CONSTANT_VALUE_15};
      if (offerType === 'SENT') {
        dispatch(onSentOfferStart(params));
      } else {
        dispatch(onReceivedOfferStart(params));
      }
    },
    []
  );
  // Load More API call
  const onReachedEnd = React.useCallback(
    (offerType: string): void => {
      if (offerType === 'SENT') {
        if (
          sentOffers.success != null &&
          sentOffers.success.pageNumber < sentOffers.success.totalPages
        ) {
          getOffersListAPI(
            offerType,
            sentOffers.success.pageNumber + FixedValue.CONSTANT_VALUE_1
          );
        }
      } else {
        if (
          receivedOffers.success != null &&
          receivedOffers.success.pageNumber < receivedOffers.success.totalPages
        ) {
          getOffersListAPI(
            offerType,
            receivedOffers.success.pageNumber + FixedValue.CONSTANT_VALUE_1
          );
        }
      }
    },
    [receivedOffers, sentOffers]
  );
  // Approve API
  const callApproveOfferAPI = React.useCallback((offerID: number): void => {
    dispatch(onApproveOfferStart(offerID));
  }, []);
  //Reject API
  const callRejectOfferAPI = React.useCallback((offerID: number): void => {
    dispatch(onRejectOfferStart(offerID));
  }, []);
  // Call API FOr Cancel
  const callCancelOfferAPI = React.useCallback((offerID: number): void => {
    dispatch(onCancelOfferStart(offerID));
  }, []);
  const navigateToDetailScreen = React.useCallback(
    (topDetails: TopModel, comingFrom: TopType): void => {
      navigate(ROUTE_NAME.TOP_DETAIL_SCREEN, {topDetails, comingFrom});
    },
    []
  );
  const callTradeReportApi = React.useCallback(
    (abuseValue: ReportFormState): void => {
      setOpenReportModal(false);
      const jsonData = selectedNotificationCard?.json
        ? JSON.parse(selectedNotificationCard?.json)
        : null;
      if (jsonData?.isChild) {
        callTopTradeStatusUpdateAPI(abuseValue);
      } else {
        dispatch(onTradeReportStart(abuseValue));
      }
    },
    [selectedNotificationCard]
  );

  //get trade offer detail api
  const getTradeOfferDetail = React.useCallback(
    (item: INotificationListItem): void => {
      const isChild: boolean = JSON.parse(item?.json ?? '')?.isChild;
      const offerID: boolean = JSON.parse(item?.json ?? '')?.id;
      const params = {
        offerId: item?.requestId ?? offerID,
        // offerId: item?.requestId ?? item?.id ?? offerID,
        isChild,
      };
      dispatch(onTradeOfferStart(params));
    },
    []
  );

  //get distribute top detail api
  const getDistributeTopDetail = React.useCallback(
    (item: INotificationListItem): void => {
      const isChild: boolean = JSON.parse(item?.json ?? '')?.isChild ?? false;
      const offerID: boolean = JSON.parse(item?.json ?? '')?.id ?? false;
      const isRequestForUnRegisteredUser: boolean =
        item?.type === NOTIFICATION_TYPE.DISTRIBUTION_PUSH_EMAIL;
      const params = {
        requestId: offerID,
        isChild,
        isRequestForUnRegisteredUser,
      };
      dispatch(onDistributeTopDetailStart(params));
    },
    []
  );

  //call trade update API if its for child
  const callTopTradeStatusUpdateAPI = React.useCallback(
    (statusUpdateData: ReportFormState): void => {
      setOpenReportModal(false);
      dispatch(onTopTradeStatusUpdateStart(statusUpdateData));
    },
    []
  );

  return {
    getOffersListAPI,
    onReachedEnd,
    callApproveOfferAPI,
    callCancelOfferAPI,
    callRejectOfferAPI,
    navigateToDetailScreen,
    setReportState,
    reportState,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isValid,
    values,
    setValues,
    resetForm,
    callTradeReportApi,
    getTradeOfferDetail,
    getDistributeTopDetail,
    callTopTradeStatusUpdateAPI,
  };
};

export default useTradeReceviedOrSentHooks;
