import React from 'react';
import {View, StyleSheet} from 'react-native';
import CustomButton from './customButton';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';
import {BASESTYLE, COLORS, FixedValue, FONT_NAMES, TEXT} from '../../constants';
import {CustomApprovedRejectButtonProps} from '../../types/reducerTypes/groupMangementTypes';
import TICKICON from '../renderSvgs/tickIcon';
import CROSSICON from '../renderSvgs/smallCrossIcon';

export const styles = StyleSheet.create({
  primaryButtonStyle: {
    ...BASESTYLE.inCenter,
    ...BASESTYLE.row,
    height: scaleHeight(FixedValue.CONSTANT_VALUE_38),
    width: scaleHeight(FixedValue.CONSTANT_VALUE_121),
    borderRadius: FixedValue.CONSTANT_VALUE_70,
    marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
  },
  primaryButtonLabelstyle: {
    fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
    lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
    fontFamily: FONT_NAMES.SF_MEDIUM,
    color: COLORS.WHITE,
    marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
  },
  primaryButtonLabelstyle1: {
    fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
    lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
    fontFamily: FONT_NAMES.SF_MEDIUM,
    color: COLORS.F54336,
    marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
  },
  noButtonBackGroundColor: {
    backgroundColor: COLORS.FDE4E1,
  },
  buttonView: {
    ...BASESTYLE.row,
    marginTop: FixedValue.CONSTANT_VALUE_11,
  },
  noButtonBackGroundColor1: {
    backgroundColor: COLORS._4EAF51,
  },
});

const CustomApprovalButton = (props: CustomApprovedRejectButtonProps) => {
  const {onApproveButton, onRejectButton, item, acceptText} = props;
  return (
    <View style={styles.buttonView}>
      <CustomButton
        buttonLabel={acceptText ? acceptText : TEXT.APPROVE}
        buttonwidth={styles.primaryButtonStyle}
        labelStyle={styles.primaryButtonLabelstyle}
        buttonBackGroundColor={styles.noButtonBackGroundColor1}
        element={<TICKICON />}
        onPress={() => onApproveButton(item)}
      />
      <CustomButton
        buttonLabel={TEXT.REJECT}
        buttonwidth={styles.primaryButtonStyle}
        labelStyle={styles.primaryButtonLabelstyle1}
        buttonBackGroundColor={styles.noButtonBackGroundColor}
        element={<CROSSICON />}
        onPress={() => onRejectButton(item)}
      />
    </View>
  );
};

export default React.memo(CustomApprovalButton);
