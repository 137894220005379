/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {useFormik} from 'formik';
import {useIsFocused} from '@react-navigation/native';

import {ERROR_MESSAGES, ROUTE_NAME} from '../../constants';
import {LoginSchema} from '../../utils/formikSchema';
import {useAppDispatch, useAppSelector} from '../reduxHooks';
import {authState, onLoginStart} from '../../redux/reducers/authSlice';
import {navigate, reset} from '../../services/navigationService';
import {
  RESEND_APPROVAL_REQUEST_DATA,
  USER_LOGIN,
  USER_PROFILE_DATA,
} from '../../constants/actionTypes';
import {
  removeAllData,
  saveAllUserDataWhileLogin,
  _ToastHandler,
} from '../../utils/helperFunctions';
import {
  onResendApprovalRequestStart,
  onUserDataStart,
  userProfileStore,
} from '../../redux/reducers/userProfileSlice';
import {onResendCodeStart} from '../../redux/reducers/verifyOtp';
import {HomeContext} from '../../contextAPI/homeContext';
import {onUpdateTokenOnServerStart} from '../../redux/reducers/notificationSlice';
import {isWebsite} from '../../utils/responsive';

export type LoginFields = {Password: string; Username: string};

const useLoginHooks = () => {
  const dispatch = useAppDispatch();
  const isFocused = useIsFocused();
  const {userLogin} = useAppSelector(authState);
  const {userData, resendApprovalRequestData} =
    useAppSelector(userProfileStore);
  const {
    homeScreenUserData,
    setHomeScreenUserData,
    setShowWelcomeTop,
    showWelcomeTop,
  } = React.useContext(HomeContext);
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isValid,
    values,
    resetForm,
  } = useFormik<LoginFields>({
    validationSchema: LoginSchema,
    initialErrors: {
      Username: ERROR_MESSAGES.REQUIRED_USERNAME,
      Password: ERROR_MESSAGES.REQUIRED_PASSWORD,
    },
    initialTouched: {Password: false, Username: false},
    initialValues: {Password: '', Username: ''},
    onSubmit: val => onUserLogin(val),
  });

  const onUserLogin = React.useCallback((val: LoginFields): void => {
    dispatch(onLoginStart(val));
  }, []);

  const navigateLostPassword = React.useCallback(() => {
    navigate(ROUTE_NAME.LOSS_PASSWORD);
    resetForm();
  }, []);

  const navigateLostUserName = React.useCallback(() => {
    navigate(ROUTE_NAME.FORGOT_USERNAME);
    resetForm();
  }, []);

  const moveToSignUp = React.useCallback(() => {
    navigate(ROUTE_NAME.SIGN_UP);
    resetForm();
  }, []);

  const onSuccess = React.useCallback(async (): Promise<void> => {
    try {
      if (userLogin.success != null && userData.success != null) {
        setHomeScreenUserData(userData.success);
        setShowWelcomeTop(!userData?.success?.isLoggedBefore ?? false);
        await saveAllUserDataWhileLogin(userData.success);
        if (!isWebsite()) dispatch(onUpdateTokenOnServerStart());
        reset(ROUTE_NAME.BOTTOM_TAB);
      }
    } catch (error) {
      console.log(error, 'error on success function');
    }
  }, [userLogin.success, userData.success, homeScreenUserData, showWelcomeTop]);

  React.useEffect((): void => {
    if (isFocused) {
      switch (userLogin.status) {
        case USER_LOGIN.FAILED:
          if (userLogin.failed === ERROR_MESSAGES.USER_NOT_CONFIRM)
            dispatch(onResendCodeStart({Username: values.Username}));
          else _ToastHandler(ERROR_MESSAGES.INVALID_CREDENTIALS, false);
          break;
        case USER_LOGIN.SUCCESS:
          if (userLogin.success != null) {
            dispatch(onUserDataStart());
          }
          break;
        default:
          break;
      }
    }
  }, [userLogin.status, values, isFocused]);

  React.useEffect((): void => {
    if (isFocused) {
      switch (userData.status) {
        case USER_PROFILE_DATA.SUCCESS:
          onSuccess();
          break;
        case USER_PROFILE_DATA.FAILED:
          if (userData.failed === ERROR_MESSAGES.APPROVAL_PENDING) {
            dispatch(onResendApprovalRequestStart());
          } else {
            _ToastHandler(userData.failed ?? '', false);
          }
          break;
        default:
          break;
      }
    }
  }, [isFocused, userData.status]);

  React.useEffect((): void => {
    switch (resendApprovalRequestData.status) {
      case RESEND_APPROVAL_REQUEST_DATA.SUCCESS:
      case RESEND_APPROVAL_REQUEST_DATA.FAILED:
        resetForm();
        removeAllData(true);
        navigate(ROUTE_NAME.PARENT_CONSENT);
        break;
      default:
        break;
    }
  }, [resendApprovalRequestData.status]);

  React.useEffect((): void => {
    if (isFocused) {
      resetForm();
    }
  }, [isFocused]);

  const UsernameError: string =
    touched.Username && errors.Username ? errors.Username : '';
  const PasswordError: string =
    touched.Password && errors.Password ? errors.Password : '';

  return {
    handleChange,
    handleSubmit,
    handleBlur,
    isValid,
    values,
    navigateLostPassword,
    UsernameError,
    PasswordError,
    moveToSignUp,
    navigateLostUserName,
  };
};

export default useLoginHooks;
