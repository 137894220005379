import React from 'react';
import {View, Text, Pressable} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {IEmptyTopProps} from '../../types/reducerTypes/searchTabStoreType';
import {styles} from './emptyCollection.style';

const EmptyCollection: React.FC<IEmptyTopProps> = props => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const emptyEmtpyStyle = styles(ColorTheme);

  return (
    <View style={emptyEmtpyStyle.mainConatiner}>
      <Pressable onPress={()=>props?.onPressAction()}>
        <Text style={emptyEmtpyStyle.noDataText}>{props?.mainText}</Text>
      </Pressable>
    </View>
  );
};

export default React.memo(EmptyCollection);
