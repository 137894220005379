import React from 'react';
import {View} from 'react-native';

import SafeAreaContainer from '../../../components/layout/safeAreaContainer';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {mantelTabStyle} from './mantelTop.style';
import HomeHeaderContent from '../../../components/mantelTopTabComp/homeHeaderContent';
import AppLoader from '../../../components/smallDesigns/appLoader';
import {useAppDispatch, useAppSelector} from '../../../hooks/reduxHooks';
import {createCollectionStore} from '../../../redux/reducers/collectionSlice';
import {CREATE_COLLECTION} from '../../../constants/actionTypes';
import {HomeContext} from '../../../contextAPI/homeContext';
import MainTabScreen from './mainTabScreen';
import {onUpdateTokenOnServerStart} from '../../../redux/reducers/notificationSlice';
import {isWebsite} from '../../../utils/responsive';

const MantelTopTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = mantelTabStyle(ColorTheme);
  const {createNewCollection} = useAppSelector(createCollectionStore);
  const {homeScreenDataLoader, initialLoader} = React.useContext(HomeContext);

  React.useEffect((): void => {
    if (!isWebsite()) dispatch(onUpdateTokenOnServerStart());
  }, []);

  return (
    <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
      <HomeHeaderContent />
      <MainTabScreen />
      {(createNewCollection.status === CREATE_COLLECTION.START ||
        homeScreenDataLoader ||
        initialLoader) && <AppLoader />}
    </View>
  );
};

const HomeTab = () => (
  <SafeAreaContainer>
    <MantelTopTab />
  </SafeAreaContainer>
);

export default HomeTab;
