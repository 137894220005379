import React from 'react';
import {FixedValue} from '../../constants';
import {CreateCollectionContext} from '../../contextAPI/createCollectionContext';
import {onDeleteCollectionAndTopsStart} from '../../redux/reducers/homeSlice';
import {useAppDispatch} from '../reduxHooks';

const useDeleteCollectionHooks = () => {
  const dispatch = useAppDispatch();
  const [deleteModalNo, setDeleteModalNo] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_0
  );
  const [collectionDeleteType, setCollectionDeleteType] =
    React.useState<number>(FixedValue.CONSTANT_VALUE_0);
  const {collectionData} = React.useContext(CreateCollectionContext);

  const callDeleteCollectionAndTopsAPI = React.useCallback(
    (deleteCollection: number): void => {
      const deleteTops = deleteCollection === FixedValue.CONSTANT_VALUE_1;
      const params = {collectionId: collectionData?.id, deleteTops: deleteTops};
      dispatch(onDeleteCollectionAndTopsStart(params));
    },
    []
  );

  const forwardToNextModal = React.useCallback((): void => {
    setDeleteModalNo(prevModalNo => prevModalNo + FixedValue.CONSTANT_VALUE_1);
  }, []);

  const  forTopDelete =()=>{
    setCollectionDeleteType(FixedValue.CONSTANT_VALUE_1),
        setTimeout(() => {
          forwardToNextModal();
        }, FixedValue.CONSTANT_VALUE_300);
    }
    const  forCollectionDelete =()=>{
      setCollectionDeleteType(FixedValue.CONSTANT_VALUE_2),
      setTimeout(() => {
            forwardToNextModal();
          }, FixedValue.CONSTANT_VALUE_300);
      }
    
  return {
    forTopDelete,
    forCollectionDelete,
    deleteModalNo,
    collectionDeleteType,
    callDeleteCollectionAndTopsAPI,
    setDeleteModalNo,
    setCollectionDeleteType,
    forwardToNextModal,
  };
};

export default useDeleteCollectionHooks;
