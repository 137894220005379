import React from 'react';
import {View} from 'react-native';
import {ThemeContext} from '../../../contextAPI/themeContext';
import SafeAreaContainer from '../../../components/layout/safeAreaContainer';
import {FixedValue, TEXT} from '../../../constants';
import {DistributingSettingStyle} from './style';
import DistributingSettingScreen from '../distributingSetting/distributingSettingContent';
import {useAppSelector} from '../../../hooks/reduxHooks';
import {childProfileStore} from '../../../redux/reducers/childProfileSlice';
import {ChildProfileContext} from '../../../contextAPI/childProfileContext';
import useChildListHooks from '../../../hooks/componentHooks/useChildListHooks';
import {DISTRIBUTE_TOP_APPROVAL_SETTING} from '../../../constants/actionTypes';
import TitleWithButton from '../../../components/header/titleWithButton';
import {TOAST_TEXT} from '../../../constants/textMessage';
import {goBack} from '../../../services/navigationService';
import {_ToastHandler} from '../../../utils/helperFunctions';
import {useIsFocused} from '@react-navigation/native';

const DistributingSetting = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = DistributingSettingStyle(ColorTheme);
  const isFocused = useIsFocused();
  const {distributeTopSettingApproval} = useAppSelector(childProfileStore);
  const {childUserData, setShowDistributeTopSettingChangePopUp} =
    React.useContext(ChildProfileContext);
  const {callChildProfileAPI} = useChildListHooks();

  React.useEffect(() => {
    if (isFocused) {
      switch (distributeTopSettingApproval.status) {
        case DISTRIBUTE_TOP_APPROVAL_SETTING.SUCCESS:
          setShowDistributeTopSettingChangePopUp(false);
          _ToastHandler(
            TOAST_TEXT.DISTRIBUTE_TOP_APPROVAL_SETTING_CHANGE,
            true
          );
          goBack();
          callChildProfileAPI(childUserData?.id ?? FixedValue.CONSTANT_VALUE_0);
          break;
        default:
          break;
      }
    }
  }, [distributeTopSettingApproval.status]);
  return (
    <SafeAreaContainer>
      <View
        style={styles.settingMainContainer}
        dataSet={{media: ids.settingMainContainer}}
      >
        <TitleWithButton title={TEXT.SETTING} />
        <DistributingSettingScreen />
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(DistributingSetting);
