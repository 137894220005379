import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const manteltabCompStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    tabBar: {
      ...BASESTYLE.row,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      backgroundColor: COLORS.E9EEFB,
      borderBottomWidth: scaleWidth(FixedValue.CONSTANT_VALUE_1),
      borderBottomColor: COLORS.TRANSPARENT,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_25),
    },
    tabButton: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.inCenter,
    },
    activeTabButton: {
      backgroundColor: COLORS.PRIMARY_BLUE,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_25),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_40),
      margin: scaleHeight(FixedValue.CONSTANT_VALUE_4),
    },
    tabButtonText: {
      color: COLORS.PRIMARY_BLUE,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
    },
    activeTabButtonText: {
      color: COLORS.E9EEFB,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
    },
    headingView: {
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
      ...BASESTYLE.selfCenter,
      backgroundColor: _theme.backgroundColor,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_90,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_90,
        marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      },
    },
    titleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: _theme.color,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_24),
    },
    flexDirectionRow: {
      ...BASESTYLE.inRow,
    },
    headerHeightWidth: {
      maxWidth: scaleWidth(FixedValue.CONSTANT_VALUE_213),
    },
    marginLeft12: {
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_7),
    },
    cardContainer: {
      margin: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      backgroundColor: COLORS.WHITE,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_155),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_155),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      overflow: GlobalStyleValues.HIDDEN,
    },
    bottomCardContainer: {
      backgroundColor: COLORS.LGHT_BLCK_02,
      ...BASESTYLE.absolutePosition,
      ...BASESTYLE.center,
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_0),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      width: Percentage.PRECENTAGE_100,
      borderBottomLeftRadius: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      borderBottomRightRadius: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      zIndex: FixedValue.CONSTANT_VALUE_100,
    },
    topName: {
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_15),
      color: COLORS.WHITE,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_REGULAR,
      width: Percentage.PRECENTAGE_85,
    },
    topCreated: {
      top: scaleHeight(FixedValue.CONSTANT_VALUE_15),
      left: scaleWidth(FixedValue.CONSTANT_VALUE_15),
      color: COLORS.WHITE,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_10),
      fontFamily: FONT_NAMES.SF_REGULAR,
      width: Percentage.PRECENTAGE_90,
    },
    commonStyle: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      zIndex: FixedValue.CONSTANT_VALUE_10,
      right: scaleWidth(FixedValue.CONSTANT_VALUE_3),
    },
    numberContainer: {
      ...BASESTYLE.absolutePosition,
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      backgroundColor: COLORS._F8CC02,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      left: scaleWidth(FixedValue.CONSTANT_VALUE_15),
    },
    menuOrChecbox: {
      ...BASESTYLE.absolutePosition,
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      backgroundColor: COLORS.LGHT_BLCK_02,
      right: scaleWidth(FixedValue.CONSTANT_VALUE_15),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_4),
    },
    numberStyle: {
      color: COLORS.BLACK,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
    },
    imageContainer: {
      ...BASESTYLE.flex1,
      backgroundColor: COLORS.E9EEFB,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      overflow: GlobalStyleValues.HIDDEN,
    },
    buyNowButton: {
      ...BASESTYLE.selfCenter,
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.center,
      backgroundColor: COLORS._4EAF51,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_25),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_105),
    },
    buyButtonText: {
      color: COLORS.WHITE,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
    },
    imageLoader: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.center,
      ...BASESTYLE.alignCenter,
      backgroundColor: COLORS.E9EEFB,
      overflow: GlobalStyleValues.HIDDEN,
    },
    selectOrUnselectView: {
      ...BASESTYLE.absolutePosition,
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      right: scaleWidth(FixedValue.CONSTANT_VALUE_15),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_4),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      zIndex: FixedValue.CONSTANT_VALUE_10,
      top: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    gradient: {
      ...BASESTYLE.absolutePosition,
      height: Percentage.PRECENTAGE_100,
      width: Percentage.PRECENTAGE_100,
      backgroundColor: COLORS.LGHT_BLCK,
      zIndex: FixedValue.CONSTANT_VALUE_10,
    },
    blurViewStyle: {
      ...BASESTYLE.absolutePosition,
      height: Percentage.PRECENTAGE_100,
      zIndex: FixedValue.CONSTANT_VALUE_MIN_1,
      width: Percentage.PRECENTAGE_100,
    },
    topCardContainer: {
      margin: scaleHeight(FixedValue.CONSTANT_VALUE_5),
      backgroundColor: COLORS.TRANSPARENT,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_140),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_104),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      overflow: GlobalStyleValues.HIDDEN,
    },
    topImageContainer: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_104),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_104),
      backgroundColor: COLORS.E9EEFB,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.center,
      overflow: GlobalStyleValues.HIDDEN,
    },
    quantitySelectorViewStyle: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      ...BASESTYLE.spaceBetween,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_34),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_93),
      borderRadius: FixedValue.CONSTANT_VALUE_100,
      marginBottom: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      marginHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_5),
      backgroundColor: COLORS._4EAF51,
      overflow: GlobalStyleValues.HIDDEN,
    },
    plusButtonStyle: {
      ...BASESTYLE.inCenter,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_24),
      right: FixedValue.CONSTANT_VALUE_5,
      backgroundColor: COLORS.TRANSPARENT,
    },
    minuButtonStyle: {
      ...BASESTYLE.inCenter,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_24),
      backgroundColor: COLORS.TRANSPARENT,
      left: FixedValue.CONSTANT_VALUE_5,
    },
    zeroQuantityTextStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
    quantityTextStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
    plusTextStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
    progressLoader: {
      ...BASESTYLE.center,
      ...BASESTYLE.alignCenter,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_104),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_112),
      backgroundColor: COLORS.E9EEFB,
      overflow: GlobalStyleValues.HIDDEN,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    addNowButton: {
      ...BASESTYLE.selfCenter,
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.center,
      backgroundColor: COLORS._4EAF51,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_25),
      height: Percentage.PRECENTAGE_100,
      width: Percentage.PRECENTAGE_100,
      zIndex: FixedValue.CONSTANT_VALUE_10,
    },
    addTopView: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      ...BASESTYLE.spaceBetween,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_28),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_90),
      borderWidth: FixedValue.CONSTANT_VALUE_1,
      borderRadius: FixedValue.CONSTANT_VALUE_100,
      margin: FixedValue.CONSTANT_VALUE_10,
      borderColor: COLORS._B3B3B3,
      backgroundColor: _theme?.backgroundColor,
      overflow: GlobalStyleValues.HIDDEN,
    },
    topCardTile: {
      margin: scaleHeight(FixedValue.CONSTANT_VALUE_5),
      backgroundColor: COLORS.WHITE,
      height: scaleWidth(FixedValue.CONSTANT_VALUE_100),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_100),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      overflow: GlobalStyleValues.HIDDEN,
    },
    crossIconView: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.absolutePosition,
      top: scaleWidth(FixedValue.CONSTANT_VALUE_4),
      right: scaleWidth(FixedValue.CONSTANT_VALUE_4),
      zIndex: FixedValue.CONSTANT_VALUE_20,
    },
    addToCartView: {
      ...BASESTYLE.absolutePosition,
      width: Percentage.PRECENTAGE_100,
      ...BASESTYLE.inRow,
      ...BASESTYLE.inCenter,
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      zIndex: FixedValue.CONSTANT_VALUE_10,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_5),
    },
    amountView: {
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.center,
      backgroundColor: _theme.backgroundColor,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_74),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_35),
    },
    amountText: {
      color: _theme.color,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
    },
    quantityViewStyle: {
      ...BASESTYLE.row,
      ...BASESTYLE.spaceBetween,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_105),
      borderRadius: FixedValue.CONSTANT_VALUE_25,
      backgroundColor: COLORS._4EAF51,
    },
    headerIconContainer: {
      ...BASESTYLE.row,
      justifyContent: GlobalStyleValues.CENTER,
      alignItems: GlobalStyleValues.CENTER,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      width: Percentage.PRECENTAGE_30,
    },
    marginRight18: {
      marginRight: FixedValue.CONSTANT_VALUE_18,
    },
  });
