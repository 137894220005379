import StyleSheet from 'react-native-media-query';

import {
  BASESTYLE,
  FixedValue,
  FONT_NAMES,
  Percentage,
} from '../../../constants';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleWidth,
  SCREEN_WIDTH,
} from '../../../utils/responsive';

export const mantelTabStyle = (Theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      backgroundColor: Theme.backgroundColor,
    },
    container: {
      ...BASESTYLE.flex1,
    },
    tabBarStyle: {
      width:
        isWebsite() && SCREEN_WIDTH >= FixedValue.CONSTANT_VALUE_500
          ? Percentage.PRECENTAGE_75
          : Percentage.PRECENTAGE_90,
    },
    subContent: {
      ...BASESTYLE.flex1,
      backgroundColor: Theme.backgroundColor,
    },
    comingSoonText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_24),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: Theme.color,
    },
    paddingHorizontal20: {
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
  });
