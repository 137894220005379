import React from 'react';
import {View, Text} from 'react-native';

import {TabBarName} from '../../../constants';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {styles} from './styles';

const TrackingTabHeader = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);

  return (
    <View>
      <Text style={style.titleText}>{TabBarName.TRACKING}</Text>
    </View>
  );
};

export default React.memo(TrackingTabHeader);
