import React from 'react';
import {FixedValue, ROUTE_NAME} from '../../constants';
import {REMOVE_USER_FROM_GROUP} from '../../constants/actionTypes';
import {GroupContext} from '../../contextAPI/groupManagementContext';
import {
  groupManagementStore,
  onGroupInvitationsStart,
  onMyGroupsStart,
  onGroupInvitationsRejectStart,
  onGroupInvitationsApprovedStart,
  onGroupsInStart,
  onGroupUserListStart,
  onGroupDetailApiStart,
  onRemoveUserFromGroupStart,
  onGroupDeleteApiStart,
  onGroupLeaveApiStart,
} from '../../redux/reducers/groupManagementSlice';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import {navigate} from '../../services/navigationService';
import {
  IGroupListModel,
  MyGroupInvitationsModelData,
} from '../../types/reducerTypes/groupMangementTypes';
import {useAppDispatch, useAppSelector} from '../reduxHooks';

const useGroupManagementListHooks = () => {
  const {userData} = useAppSelector(userProfileStore);
  const UserDetail = userData?.success;
  const dispatch = useAppDispatch();
  const {
    myGroupList,
    groupInList,
    groupInvitaionsList,
    groupUserList,
    removeUserFromGroupAPI,
  } = useAppSelector(groupManagementStore);
  const {
    selectedGroupInvitaions,
    groupDetailData,
    isUserComingFromMyGroup,
    selectedGroupUserId,
    showDeleteGroupModal,
    openCloseGroupEditDeleteModal,
    groupInvitationApprovedModal,
    clickOnApprovedButton,
    setShowDeleteGroupModal,
    setOpenCloseGroupEditDeleteModal,
    setGroupInvitationApprovedModal,
    setSelectedGroupInvitaions,
    setClickOnApprovedButton,
  } = React.useContext(GroupContext);

  const callMyGroupListAPI = React.useCallback((page: number): void => {
    const params = {page, size: FixedValue.CONSTANT_VALUE_15};
    dispatch(onMyGroupsStart(params));
  }, []);

  const callGroupInListAPI = React.useCallback((page: number): void => {
    const params = {page, size: FixedValue.CONSTANT_VALUE_15};
    dispatch(onGroupsInStart(params));
  }, []);

  const callGroupInvitationListAPI = React.useCallback((page: number): void => {
    const params = {
      page,
      size: FixedValue.CONSTANT_VALUE_15,
      userId: UserDetail?.id,
    };
    dispatch(onGroupInvitationsStart(params));
  }, []);

  //delete group API
  const callDeleteGroupAPI = React.useCallback((): void => {
    dispatch(onGroupDeleteApiStart({groupId: groupDetailData?.id}));
  }, [groupDetailData]);

  const groupInvitationRejectApiCall = React.useCallback((): void => {
    const params = {
      groupId: selectedGroupInvitaions?.group?.id,
    };
    dispatch(onGroupInvitationsRejectStart(params));
  }, [selectedGroupInvitaions, clickOnApprovedButton]);

  const groupInvitaionAcceptApiCall = React.useCallback((): void => {
    const params = {
      groupId: selectedGroupInvitaions?.group?.id,
    };
    dispatch(onGroupInvitationsApprovedStart(params));
  }, [selectedGroupInvitaions, clickOnApprovedButton]);

  const onApproveButton = React.useCallback(
    (value: MyGroupInvitationsModelData): void => {
      setClickOnApprovedButton(FixedValue.CONSTANT_VALUE_1);
      setSelectedGroupInvitaions(value);
      setGroupInvitationApprovedModal();
    },
    [
      selectedGroupInvitaions,
      clickOnApprovedButton,
      groupInvitationApprovedModal,
    ]
  );
  const onRejectButton = React.useCallback(
    (value: MyGroupInvitationsModelData): void => {
      setClickOnApprovedButton(FixedValue.CONSTANT_VALUE_2);
      setSelectedGroupInvitaions(value);
      setGroupInvitationApprovedModal();
    },
    [
      selectedGroupInvitaions,
      clickOnApprovedButton,
      groupInvitationApprovedModal,
    ]
  );

  //remove user from group api
  const removeUserFromGroupAPIFunction = React.useCallback((): void => {
    const params = {
      groupId: groupDetailData?.id,
      removeUserId: selectedGroupUserId,
    };
    dispatch(onRemoveUserFromGroupStart(params));
  }, [selectedGroupUserId]);

  //leave group API
  const leaveGroupAPIFunction = React.useCallback((): void => {
    dispatch(onGroupLeaveApiStart({groupId: groupDetailData?.id}));
  }, [groupDetailData]);

  const callGroupInvitationsApproveRejectAPI = React.useCallback((): void => {
    if (clickOnApprovedButton === FixedValue.CONSTANT_VALUE_1) {
      groupInvitaionAcceptApiCall();
    } else {
      groupInvitationRejectApiCall();
    }
  }, [
    selectedGroupInvitaions,
    clickOnApprovedButton,
    groupInvitationApprovedModal,
  ]);

  //on group detail remove user response handle and call api
  React.useEffect(() => {
    switch (removeUserFromGroupAPI.status) {
      case REMOVE_USER_FROM_GROUP.SUCCESS:
        callGroupDetailAPI();
        callMyGroupListAPI(FixedValue.CONSTANT_VALUE_0);
        break;
      case REMOVE_USER_FROM_GROUP.FAILED:
        break;
      default:
        break;
    }
  }, [removeUserFromGroupAPI.status]);

  //group user list API
  const callGroupUserListAPI = React.useCallback(
    (page: number): void => {
      const params = {
        page,
        groupId: groupDetailData?.id,
        size: FixedValue.CONSTANT_VALUE_15,
        isUserComingFromMyGroup: isUserComingFromMyGroup,
      };
      dispatch(onGroupUserListStart(params));
    },
    [groupDetailData]
  );

  //group detail API
  const callGroupDetailAPI = React.useCallback((): void => {
    dispatch(onGroupDetailApiStart({groupId: groupDetailData?.id}));
  }, [groupDetailData]);

  const navigateToGroupDetailScreen = React.useCallback(
    (item: IGroupListModel): void => {
      navigate(ROUTE_NAME.GROUP_DETAIL_SCREEN, {groupDetail: item});
    },
    [groupDetailData]
  );

  const onGroupUserListReachedEnd = React.useCallback((): void => {
    if (
      groupUserList.success != null &&
      groupUserList.success.pageNumber < groupUserList.success.totalPages
    ) {
      callGroupUserListAPI(
        groupUserList.success.pageNumber + FixedValue.CONSTANT_VALUE_1
      );
    }
  }, [groupInvitaionsList]);

  const onMyGroupListReachedEnd = React.useCallback((): void => {
    if (
      myGroupList.success != null &&
      myGroupList.success.pageNumber < myGroupList.success.totalPages
    ) {
      callMyGroupListAPI(
        myGroupList.success.pageNumber + FixedValue.CONSTANT_VALUE_1
      );
    }
  }, [myGroupList]);

  const onGroupInListReachedEnd = React.useCallback((): void => {
    if (
      groupInList.success != null &&
      groupInList.success.pageNumber < groupInList.success.totalPages
    ) {
      callGroupInListAPI(
        groupInList.success.pageNumber + FixedValue.CONSTANT_VALUE_1
      );
    }
  }, [groupInList]);

  const onMyGroupInvitationsListReachedEnd = React.useCallback((): void => {
    if (
      groupInvitaionsList.success != null &&
      groupInvitaionsList.success.pageNumber <
        groupInvitaionsList.success.totalPages
    ) {
      callGroupInvitationListAPI(
        groupInvitaionsList.success.pageNumber + FixedValue.CONSTANT_VALUE_1
      );
    }
  }, [groupInvitaionsList]);

  const openGroupDeleteAlertModal = React.useCallback((): void => {
    setOpenCloseGroupEditDeleteModal(false);
    setShowDeleteGroupModal();
  }, [showDeleteGroupModal, openCloseGroupEditDeleteModal]);

  return {
    callMyGroupListAPI,
    callGroupInvitationListAPI,
    onMyGroupListReachedEnd,
    onGroupInListReachedEnd,
    onMyGroupInvitationsListReachedEnd,
    setClickOnApprovedButton,
    onApproveButton,
    onRejectButton,
    callGroupInvitationsApproveRejectAPI,
    callGroupInListAPI,
    callGroupDetailAPI,
    callGroupUserListAPI,
    navigateToGroupDetailScreen,
    onGroupUserListReachedEnd,
    removeUserFromGroupAPIFunction,
    openGroupDeleteAlertModal,
    callDeleteGroupAPI,
    leaveGroupAPIFunction,
  };
};

export default useGroupManagementListHooks;
