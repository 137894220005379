import React from 'react';
import {Text, View} from 'react-native';
import ModalContainer from '../../components/layout/modalContainer';
import {ThemeContext} from '../../contextAPI/themeContext';
import {FixedValue, TEXT} from '../../constants';
import ModalHeaderWithCross from '../../components/header/modalHeaderWithCross';
import TopCreditTransferContent from './topCreditTransferContent';
import {style} from './styles';
import {ITransferTopCreditModal} from '../../types/reducerTypes/userProfileTypes';
import {ProfileTabContext} from '../../contextAPI/profileTabContext';
import ListWithCheckbox from '../../components/smallDesigns/listWithCheckbox/listWithCheckbox';
import WalletImage from '../../components/renderSvgs/walletImage';
import {useAppSelector} from '../../hooks/reduxHooks';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import TransactionEmptyImage from '../../components/renderSvgs/transactionEmptyImage';
import TopsListEmptyView from '../../components/emptyListView/topsListEmptyView';
import ProfilePics from '../../components/smallDesigns/profilePics';
import {getInitials} from '../../utils/helperFunctions';
import {ScrollView} from 'react-native-gesture-handler';

const TopCreditTransferModal = (
  props: ITransferTopCreditModal
): JSX.Element => {
  const {modalHeaderName, modalVisible, closeModal} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = style(ColorTheme);
  const {
    parentChildListData,
    setSelectedChild,
    selectedChild,
    isUserWantToTransferTopCredit,
  } = React.useContext(ProfileTabContext);
  const onlyRequestApprovedChildUserChild = parentChildListData?.filter(
    list => list?.accountApprovalRequests === FixedValue.CONSTANT_VALUE_0
  );
  const isChildAvailable =
    (onlyRequestApprovedChildUserChild?.length || FixedValue.CONSTANT_VALUE_0) >
    FixedValue.CONSTANT_VALUE_0;
  const {userData} = useAppSelector(userProfileStore);
  const fullName =
    selectedChild?.firstName + ' ' + selectedChild?.lastName ?? '';

  const walletBallanceText = () => {
    if (isChildAvailable) {
      if (isUserWantToTransferTopCredit) {
        return userData?.success?.walletAmount ?? FixedValue.CONSTANT_VALUE_0;
      } else {
        return selectedChild?.walletAmount ?? FixedValue.CONSTANT_VALUE_0;
      }
    } else {
      return TEXT.MY_WALLET_BALANCE;
    }
  };

  const walletBallanceStartingText = () => {
    if (isChildAvailable) {
      if (isUserWantToTransferTopCredit) {
        return TEXT.MY_WALLET_BALANCE;
      } else {
        return `${fullName} ${TEXT.WALLET_BALANCE}`;
      }
    }
    return FixedValue.CONSTANT_VALUE_0;
  };

  return (
    <ModalContainer visible={modalVisible} onPressOutside={closeModal}>
      <View style={styles.subContainer}>
        <ModalHeaderWithCross
          onPress={closeModal}
          headerTitle={modalHeaderName}
        />
        {!isUserWantToTransferTopCredit && isChildAvailable && (
          <View style={styles.profileImageContainer}>
            <ProfilePics
              image={selectedChild?.profileImage ?? ''}
              text={getInitials(fullName)}
              mainViewStyle={styles.mainViewStyle}
              initialsTextStyle={styles.profileNameText}
            />
            <Text style={styles.selectedChildName}>{fullName}</Text>
          </View>
        )}
        <View style={styles.container}>
          <WalletImage
            height={FixedValue.CONSTANT_VALUE_28}
            width={FixedValue.CONSTANT_VALUE_28}
          />
          <View style={styles.content}>
            <Text style={styles.myWalletText}>
              {walletBallanceStartingText()} :{' '}
            </Text>
            <Text style={styles.topCreditText}>
              {walletBallanceText()}
              <Text style={styles.topcreditsText}>
                {walletBallanceText() > FixedValue.CONSTANT_VALUE_1
                  ? TEXT.TOPCREDITS
                  : TEXT.TOPCREDIT}
              </Text>
            </Text>
          </View>
        </View>
        {isChildAvailable ? (
          <>
            <ScrollView>
              {isUserWantToTransferTopCredit ? (
                onlyRequestApprovedChildUserChild?.map(item => (
                  <ListWithCheckbox
                    key={item.id}
                    item={item}
                    onPress={val => setSelectedChild(val)}
                    isSelected={selectedChild?.id === item?.id}
                  />
                ))
              ) : (
                <Text style={styles.howManyText}>
                  {TEXT.HOW_MANY_TOP_CREDIT}
                </Text>
              )}
            </ScrollView>
            {/* {isUserWantToTransferTopCredit ? (
              onlyRequestApprovedChildUserChild?.map(item => (
                <ListWithCheckbox
                  key={item.id}
                  item={item}
                  onPress={val => setSelectedChild(val)}
                  isSelected={selectedChild?.id === item?.id}
                />
              ))
            ) : (
              <Text style={styles.howManyText}>{TEXT.HOW_MANY_TOP_CREDIT}</Text>
             )} */}
            <TopCreditTransferContent />
          </>
        ) : (
          <TopsListEmptyView
            placeHolder={<TransactionEmptyImage />}
            emptyViewTitle={TEXT.NO_CHILD_ADDED}
            emptyViewDesc={''}
          />
        )}
      </View>
    </ModalContainer>
  );
};

export default TopCreditTransferModal;
