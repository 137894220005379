/* eslint-disable react-hooks/exhaustive-deps */
import {RouteProp, useIsFocused, useRoute} from '@react-navigation/native';
import React, {useEffect} from 'react';
import {Text, View} from 'react-native';
import LayoutContainer from '../../components/layout/layoutContainer';
import {TradeContext} from '../../contextAPI/tradeContext';
import {RootStackParamList} from '../../types/navigationType';
import SelectMyTops from '../createOffer/selectMyTops';
import {tradeStyle} from '../createOffer/offer.styles';
import {ThemeContext} from '../../contextAPI/themeContext';
import TitleWithImageHeader from '../../components/header/titleWithImageHeader';
import {goBack} from '../../services/navigationService';
import {FixedValue, TEXT} from '../../constants';
import PrimaryButton from '../../components/button/primaryButton';
import useCreateTradeHooks from '../../hooks/componentHooks/useCreateTradeHooks';
import useFavouriteHooks from '../../hooks/componentHooks/useFavouriteHooks';
import AppLoader from '../../components/smallDesigns/appLoader';
import {USER_MARK_UNMARK_FAVORITE} from '../../constants/actionTypes';
import {_ToastHandler} from '../../utils/helperFunctions';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import {useAppSelector} from '../../hooks/reduxHooks';

let createNextClicked = false;

const CreateOfferOther: React.FC = () => {
  const route = useRoute<RouteProp<RootStackParamList, 'CreateOfferOther'>>();
  const userId = route?.params?.userId;
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = tradeStyle(ColorTheme);
  const isFocused = useIsFocused();
  const {
    otherTradeUser,
    collectionSelectedTopsOther,
    totalTops,
    setTotalTop,
    clearCollectionsList,
  } = React.useContext(TradeContext);
  const {navigateSelectTradeUser, callCollectionsAPI} = useCreateTradeHooks();
  const {
    fetchIsOtherUserFavoirteOrNot,
    addFavoriteAndUnFavorite,
    setFavoriteLoader,
    favoriteLoader,
  } = useFavouriteHooks();
  const {userMarkAndUnmarkFavoriteData} = useAppSelector(userProfileStore);

  const onBackClick = () => {
    goBack();
  };

  const navigateScreen = () => {
    if (!createNextClicked) {
      createNextClicked = true;
      navigateSelectTradeUser(userId);
    }
  };

  React.useEffect(() => {
    if (isFocused) {
      clearCollectionsList();
      callCollectionsAPI(FixedValue.CONSTANT_VALUE_0, userId);
      const totalData = collectionSelectedTopsOther.reduce(
        (total, object) => object.selectedQuantity! + total,
        FixedValue.CONSTANT_VALUE_0
      );
      fetchIsOtherUserFavoirteOrNot(userId ?? FixedValue.CONSTANT_VALUE_0);
      setTotalTop(totalData);
      createNextClicked = false;
    }
  }, [isFocused]);

  const onPressRightIcon = React.useCallback(
    (favorite: boolean): void => {
      setFavoriteLoader(true);
      const params = {
        favorite,
        userIds: [otherTradeUser?.id ?? FixedValue.CONSTANT_VALUE_0],
      };
      addFavoriteAndUnFavorite(params);
    },
    [otherTradeUser]
  );

  useEffect(() => {
    if (isFocused) {
      switch (userMarkAndUnmarkFavoriteData.status) {
        case USER_MARK_UNMARK_FAVORITE.SUCCESS:
          fetchIsOtherUserFavoirteOrNot(
            otherTradeUser?.id ?? FixedValue.CONSTANT_VALUE_0
          );
          const firstText = otherTradeUser?.isFavorite
            ? 'You have removed'
            : 'You have added';
          const fullName =
            otherTradeUser?.firstName + ' ' + otherTradeUser?.lastName;
          const favText = otherTradeUser?.isFavorite
            ? 'From the favorites list.'
            : 'to the favorites list.';
          const toastMsg = `${firstText} "${fullName}" ${favText}`;
          _ToastHandler(toastMsg, true);
          break;
        case USER_MARK_UNMARK_FAVORITE.FAILED:
          break;
      }
    }
  }, [userMarkAndUnmarkFavoriteData?.status]);

  const showCollections = () => {
    if (isFocused) {
      return <SelectMyTops userId={userId} />;
    }
  };

  return (
    <LayoutContainer>
      <>
        {favoriteLoader && <AppLoader />}

        <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
          <TitleWithImageHeader
            title={otherTradeUser?.firstName + ' ' + otherTradeUser?.lastName}
            subTitle={otherTradeUser?.username}
            image={otherTradeUser?.profileImage}
            onPress={onBackClick}
            isFavorite={otherTradeUser?.isFavorite ?? false}
            onPressRightIcon={onPressRightIcon}
          />
          {showCollections()}
          {collectionSelectedTopsOther.length > FixedValue.CONSTANT_VALUE_0 &&
            totalTops > FixedValue.CONSTANT_VALUE_0 && (
              <View style={styles.nextButtonView}>
                <PrimaryButton
                  buttonLabel={TEXT.NEXT}
                  onPress={() => navigateScreen()}
                  buttonwidth={styles.nextButtonStyle}
                  labelStyle={styles.nextButtonTextStyle}
                  element={
                    <Text style={styles.nextButtonTextStyle}>
                      {`Total: ${totalTops}`}
                    </Text>
                  }
                />
              </View>
            )}
        </View>
      </>
    </LayoutContainer>
  );
};

export default React.memo(CreateOfferOther);
