import React from 'react';
import {View} from 'react-native';

import LabelInput from '../../components/input/labelInput';
import PrimaryButton from '../../components/button/primaryButton';
import {FixedValue, TEXT} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {changePasswordStyle} from './changePasswordStyle';
import useChangePwdHooks from '../../hooks/componentHooks/useChangePwdHooks';
import {ChangePasswordSettings} from '../../constants/enum';
import {_InputType} from '../../utils/helperFunctions';
import TitleWithButton from '../../components/header/titleWithButton';
import PwdValidation from '../../components/smallDesigns/pwdValidation';
import {useAppSelector} from '../../hooks/reduxHooks';
import {newPasswordState} from '../../redux/reducers/resetPasswordSlice';
import CloseEye from '../../components/renderSvgs/closeEye';
import OpenEye from '../../components/renderSvgs/openEye';

const ChangePasswordContent: React.FC = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    ConfirmPasswordError,
  } = useChangePwdHooks();
  const {styles, ids} = changePasswordStyle(ColorTheme);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const {changePassword} = useAppSelector(newPasswordState);
  const [showCurrentPassword, setShowCurrentPassword] =
    React.useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(changePassword.isLoading);
  }, [changePassword.isLoading]);

  return (
    <View style={styles.container} dataSet={{media: ids.container}}>
      <TitleWithButton title={TEXT.CHANGE_PASSWORD} />
      <View style={styles.extraTopMargin} />
      <LabelInput
        inputLabel={TEXT.CURRENT_PASSWORD}
        value={values.CurrentPassword}
        placeholder={TEXT.ENTER_CURRENT_PASSWORD}
        onChangeText={handleChange(ChangePasswordSettings.CURRENT_PASSWORD)}
        onBlur={handleBlur(ChangePasswordSettings.CURRENT_PASSWORD)}
        maxLength={FixedValue.CONSTANT_VALUE_25}
        secureTextEntry={!showCurrentPassword}
        keyboardType={_InputType.password}
        Icon={showCurrentPassword ? OpenEye : CloseEye}
        onPress={() => setShowCurrentPassword(prev => !prev)}
      />
      <LabelInput
        inputLabel={TEXT.NEW_PASSWORD}
        value={values.NewPassword}
        placeholder={TEXT.ENTER_NEW_PASSWORD}
        onChangeText={handleChange(ChangePasswordSettings.NEW_PASSWORD)}
        onBlur={handleBlur(ChangePasswordSettings.NEW_PASSWORD)}
        maxLength={FixedValue.CONSTANT_VALUE_25}
        secureTextEntry={!showNewPassword}
        keyboardType={_InputType.password}
        Icon={showNewPassword ? OpenEye : CloseEye}
        onPress={() => setShowNewPassword(prev => !prev)}
      />
      <LabelInput
        inputLabel={TEXT.CONFIRM_PASSWORD}
        value={values.ConfirmPassword}
        placeholder={TEXT.ENTER_CONFIRM_PASSWORD}
        onChangeText={handleChange(ChangePasswordSettings.COMFIRM_PASSWORD)}
        onBlur={handleBlur(ChangePasswordSettings.COMFIRM_PASSWORD)}
        maxLength={FixedValue.CONSTANT_VALUE_25}
        secureTextEntry={!showConfirmPassword}
        haveError={ConfirmPasswordError}
        keyboardType={_InputType.password}
        Icon={showConfirmPassword ? OpenEye : CloseEye}
        onPress={() => setShowConfirmPassword(prev => !prev)}
      />
      <PwdValidation Password={values.NewPassword} />
      <View style={styles.buttonStyle}>
        <PrimaryButton
          buttonLabel={TEXT.UPDATE}
          onPress={handleSubmit}
          isDisabled={!isValid}
          isLoading={isLoading}
        />
      </View>
    </View>
  );
};

export default React.memo(ChangePasswordContent);
