import React from 'react';
import {Pressable, Text, View} from 'react-native';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {TopSettingStyle} from './setting.style';
import Ellipse from '../../../components/renderSvgs/ellipse';
import SelectEllipse from '../../../components/renderSvgs/selectEllipse';
import {FixedValue, TEXT} from '../../../constants';
import CustomButton from '../../../components/button/customButton';
import {ChildProfileContext} from '../../../contextAPI/childProfileContext';
import {CHILD_PERMISSION, Percentage} from '../../../constants/enum';
import useTradingApprovalHooks from '../../../hooks/componentHooks/useTradingApprovalHooks';
import ConfirmationModal from '../../../modals/confirmationModal/confirmationModal';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { childProfileStore } from '../../../redux/reducers/childProfileSlice';

const TopSettingScreen = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = TopSettingStyle(ColorTheme);
  const {
    childUserData,
    showTradeSettingChangePopUp,
    setShowTradeSettingChangePopUp,
  } = React.useContext(ChildProfileContext);
  const tradePermission: string =
    childUserData?.permissionsStatus?.offerPermission?.permissionStatus ?? '';
  const [tradeSettingApprovalChange, setTradeSettingApproval] =
    React.useState<string>(tradePermission);
  const {callTradingApproveSettingAPI} = useTradingApprovalHooks();
  const {tradeSettingApproval} = useAppSelector(childProfileStore);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const SettingOptions = [
    {text: TEXT.APPROVE_ALL_TRADE, status: CHILD_PERMISSION.NOT_ALLOWED},
    {text: TEXT.ALLOW_CHILD_ACCOUNT, status: CHILD_PERMISSION.ALLOWED},
    {
      text: TEXT.TRADE_SETTING_FOR_PRE_APPROVAL,
      status: CHILD_PERMISSION.SPECIFIC_USERS,
    },
  ];

  React.useEffect(() => {
    setLoading(tradeSettingApproval.isLoading);
  }, [tradeSettingApproval.isLoading]);

  return (
    <View style={styles.settingContainer}>
      <View style={styles.selectView}>
        <Text style={styles.oneOption}>{TEXT.SELECT_ONE_OPTION}</Text>
      </View>
      {SettingOptions.map(item => (
        <Pressable
          onPress={() => setTradeSettingApproval(item.status)}
          key={item.status}
          style={styles.ellipseView}
        >
          <View style={{width: Percentage.PRECENTAGE_11_5}}>
            {item.status === tradeSettingApprovalChange ? (
              <SelectEllipse
                height={FixedValue.CONSTANT_VALUE_20}
                width={FixedValue.CONSTANT_VALUE_20}
              />
            ) : (
              <Ellipse
                height={FixedValue.CONSTANT_VALUE_20}
                width={FixedValue.CONSTANT_VALUE_20}
              />
            )}
          </View>
          <View style={{width: Percentage.PRECENTAGE_90}}>
            <Text style={styles.allTrade}>{item.text}</Text>
          </View>
        </Pressable>
      ))}
      <View style={styles.buttonView}>
        <CustomButton
          buttonLabel={TEXT.SAVE}
          buttonwidth={styles.buttonStyle}
          labelStyle={styles.buttonLabelstyle}
          onPress={() => setShowTradeSettingChangePopUp(true)}
        />
      </View>
      {showTradeSettingChangePopUp && (
        <ConfirmationModal
          title={TEXT.CHANGE_TRADE_SETTING}
          modalVisible={showTradeSettingChangePopUp}
          onPressYes={() => {
            callTradingApproveSettingAPI(tradeSettingApprovalChange);
          }}
          onShowHide={() => setShowTradeSettingChangePopUp(false)}
          isLoading={isLoading}
        />
      )}
    </View>
  );
};

export default React.memo(TopSettingScreen);
