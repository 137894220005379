import React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';

const SmartPhone = (props: SvgProps) => (
  <Svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M8.5 1H3.5C2.94772 1 2.5 1.44772 2.5 2V10C2.5 10.5523 2.94772 11 3.5 11H8.5C9.05228 11 9.5 10.5523 9.5 10V2C9.5 1.44772 9.05228 1 8.5 1Z"
      stroke={props.stroke ?? 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M6 9H6.005"
      stroke={props.stroke ?? 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default SmartPhone;
