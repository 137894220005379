import React from 'react';
import Svg, {Circle, SvgProps} from 'react-native-svg';

const Cursor = (props: SvgProps) => (
  <Svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle
      cx={16}
      cy={16}
      r={10}
      fill="white"
      stroke="#1F53D3"
      strokeWidth={12}
    />
  </Svg>
);

export default Cursor;
