import React from 'react';
import {Pressable, TextInput, Keyboard, View} from 'react-native';

import {FixedValue, TEXT} from '../../../constants';
import {EditorContext} from '../../../contextAPI/editorContext';
import {EditorTextContext} from '../../../contextAPI/editorTextContext';
import {
  TextControlsItemProps,
  TextRefType,
  TopEditorItemType,
} from '../../../types/componentTypes/editorType';
import {styles} from '../editorComp.style';
import BasicText from './basicText';
import {isWebsite} from '../../../utils/responsive';

const TextControlItem = ({
  elementId,
  newItemIndex,
  addItemCallback,
  selectItemCallback,
  font,
}: TextControlsItemProps): JSX.Element => {
  const {selectedItemIndex, items, setPrimaryTabSelectedIndex} =
    React.useContext(EditorContext);
  const {
    setTextValue,
    textValue,
    showTextInput,
    setShowTextInput,
    selectedFont,
  } = React.useContext(EditorTextContext);

  const _selectItemCallback = React.useCallback(
    (newItemIndex: number): void => {
      if (selectItemCallback) {
        selectItemCallback(newItemIndex);
      }
    },
    []
  );

  const addEditorItem = React.useCallback((): void => {
    const ref = React.createRef<TextRefType>();
    addItemCallback(
      <BasicText
        ref={ref}
        key={`top-element-${newItemIndex}`}
        elementId={`top-element-${newItemIndex}`}
        selectItemCallback={selectItemCallback}
        itemPositionOverride={{
          itemType: TopEditorItemType.TEXT,
          textValue,
          font,
        }}
        elementIndex={newItemIndex}
      />
    );
  }, [addItemCallback, newItemIndex, _selectItemCallback, textValue, font]);

  const addOrEditItem = React.useCallback((): void => {
    Keyboard.dismiss();
    if (selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1) {
      // @ts-ignore Used to access item ref
      const itemRef = items[selectedItemIndex].ref.current;
      const getFont: string = selectedFont !== '' ? selectedFont : font;
      itemRef.changeFont(getFont);
      setShowTextInput(false);
    } else if (textValue.length > FixedValue.CONSTANT_VALUE_0) {
      setTextValue('');
      setShowTextInput(false);
      addEditorItem();
    } else {
      setPrimaryTabSelectedIndex(FixedValue.CONSTANT_VALUE_MIN_1);
    }
  }, [items, textValue, selectedItemIndex, font]);

  if (showTextInput) {
    return (
      <Pressable onPress={addOrEditItem} style={styles.textInputContainer}>
        <TextInput
          testID={'TE-text-input'}
          accessibilityLabel={'TE-text-input'}
          placeholder={TEXT.ADD_NEW_TEXT}
          autoFocus
          returnKeyType="done"
          blurOnSubmit
          onSubmitEditing={addOrEditItem}
          onBlur={!isWebsite() ? addOrEditItem : undefined}
          value={textValue}
          onChangeText={setTextValue}
          style={[styles.textInput, {fontFamily: font}]}
        />
      </Pressable>
    );
  }
  return <View />;
};

export default React.memo(TextControlItem);
