import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  FixedValue,
  Percentage,
  GlobalStyleValues,
  COLORS,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {SCREEN_WIDTH, isWebsite} from '../../utils/responsive';

export const editorStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.flex1,
      width: Percentage.PRECENTAGE_100,
      backgroundColor: COLORS.BLACK,
    },
    containerStyle: {
      height: Percentage.PRECENTAGE_100,
      // ...BASESTYLE.selfCenter,
      ...BASESTYLE.spaceBetween,
    },
    svgOutterView: {
      ...BASESTYLE.inCenter,
    },
    svgBoardView: {
      borderRadius: FixedValue.CONSTANT_VALUE_16,
      ...BASESTYLE.spaceBetween,
      backgroundColor: COLORS.WHITE,
      overflow: GlobalStyleValues.HIDDEN,
      width: isWebsite() ? FixedValue.CONSTANT_VALUE_401 : SCREEN_WIDTH,
      height: isWebsite() ? FixedValue.CONSTANT_VALUE_401 : SCREEN_WIDTH,
      alignItems: GlobalStyleValues.CENTER,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
  });
