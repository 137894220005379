import StyleSheet from 'react-native-media-query';
import {BASESTYLE, FixedValue, GlobalStyleValues, Percentage} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {isWebsite, scaleHeight, scaleWidth} from '../../utils/responsive';

export const groupInListStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    listMainView: {
      ...BASESTYLE.alignCenter,
      backgroundColor: _theme.backgroundColor,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
      },
      height: Percentage.PRECENTAGE_100,
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    emptyView: {
      marginHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_28),
    },
    flatListContentContainerStyle: {
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_12),
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    scrollviewStyle: {
      ...BASESTYLE.flex1,
      justifyContent: GlobalStyleValues.CENTER,
    },
    mainList: {
      width: Percentage.PRECENTAGE_100,
    }
  });
