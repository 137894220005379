import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';
const UploadGalleryImage = (props: SvgProps) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M12 5H12.01"
      // stroke="#1E1F1F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1Z"
      // stroke="#1E1F1F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M1 12L5 7.99995C5.45606 7.56111 5.97339 7.33008 6.5 7.33008C7.02661 7.33008 7.54394 7.56111 8 7.99995L13 13"
      // stroke="#1E1F1F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M11 11L12 9.99995C12.4561 9.56111 12.9734 9.33008 13.5 9.33008C14.0266 9.33008 14.5439 9.56111 15 9.99995L17 12"
      // stroke="#1E1F1F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default UploadGalleryImage;
