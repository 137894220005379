import React, {useEffect, useState} from 'react';
import {View, FlatList, ActivityIndicator, Text} from 'react-native';
import SafeAreaContainer from '../../../components/layout/safeAreaContainer';
import ViewContainer from '../../../components/layout/viewContainer';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {trackingTabStyle} from './trackingTab.style';
import TrackingTabHeader from '../../../components/header/trackingTabHeader';
import TrackingTile from './TrackingTile';
import {alertPolyfill} from '../../../utils/helperFunctions';
import {useAppDispatch, useAppSelector} from '../../../hooks/reduxHooks';
import {
  ontrackingListStart,
  trackingListState,
} from '../../../redux/reducers/trackingListSlice';
import TrackingDetailModel from '../../../modals/trackingDetailModel';
import {
  ontrackingCancelStart,
  TrackingCancelState,
} from '../../../redux/reducers/trackingCancelSlice';
import {useIsFocused} from '@react-navigation/native';
import AppLoader from '../../../components/smallDesigns/appLoader';
import {REQUEST_TYPE, TEXT} from '../../../constants/textMessage';
import NoChildData from '../../../components/renderSvgs/noChildDataPlaceholder';
import {FixedValue} from '../../../constants';
import {TRACKING_CANCEL, TRACKING_LIST} from '../../../constants/actionTypes';
import {TrackingListResultsModel} from '../../../types/componentTypes/childProfileType';
import {onSendReminderAllStart} from '../../../redux/reducers/sendReminderAllSlice';

const TrackingTab = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = trackingTabStyle(ColorTheme);
  const dispatch = useAppDispatch();
  const isFocused = useIsFocused();
  const [apiLoader, setApiLoader] = useState(false);
  const [moreDataLoader, setMoreDataLoader] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const {trackingList} = useAppSelector(trackingListState);
  const {trackingCancel} = useAppSelector(TrackingCancelState);
  const [data, setData] = useState<TrackingListResultsModel[] | []>([]);
  const [isOpen, setIsOpen] = useState(false);

  const apiHit = (page: number) => {
    dispatch(ontrackingListStart(page));
  };

  const hitRemindAll = (item: any) => {
    const {requestId, top, group, requestForUnRegisteredUser} = item || {};
    let requestType = top
      ? REQUEST_TYPE.DISTRIBUTION
      : group
      ? REQUEST_TYPE.GROUP
      : REQUEST_TYPE.DISTRIBUTION_EMAIL;
    if (requestForUnRegisteredUser) {
      requestType = REQUEST_TYPE.DISTRIBUTION_EMAIL;
    }
    const message = `${TEXT.ARE_YOU_SURE_REMIND_ALL}`;
    alertPolyfill('', message, [
      {
        text: TEXT.YES,
        onPress: () => {
          const {requestId, top, group, requestForUnRegisteredUser} =
            item || {};
          let requestType = top
            ? REQUEST_TYPE.DISTRIBUTION
            : group
            ? REQUEST_TYPE.GROUP
            : REQUEST_TYPE.DISTRIBUTION_EMAIL;
          if (requestForUnRegisteredUser) {
            requestType = REQUEST_TYPE.DISTRIBUTION_EMAIL;
          }

          dispatch(
            onSendReminderAllStart({
              requestId: requestId,
              requestType: requestType,
            })
          );
        },
      },
      {
        text: TEXT.NO,
        onPress: () => {},
        style: TEXT.CANCEL,
      },
    ]);
  };

  useEffect(() => {
    if (!isOpen && isFocused) {
      setApiLoader(true);
      apiHit(FixedValue.CONSTANT_VALUE_0);
      setMoreDataLoader(false);
      setRefreshing(false);
    }
  }, [isOpen, isFocused]);

  const _setListResult = React.useCallback(
    (newData: TrackingListResultsModel[]): void => {
      setData(prev => [...prev, ...newData]);
    },
    [trackingList]
  );

  React.useEffect(() => {
    switch (trackingList.status) {
      case TRACKING_LIST.END:
        const listData = trackingList.data?.data?.results ?? [];
        if (trackingList.data.pageNumber === FixedValue.CONSTANT_VALUE_0) {
          setData(listData ?? []);
        } else {
          _setListResult(listData ?? []);
        }
        setApiLoader(false);
        setRefreshing(false);
        setMoreDataLoader(false);
        break;
      case TRACKING_LIST.FAILED:
        setData([]);
        setApiLoader(false);
        setRefreshing(false);
        setMoreDataLoader(false);
        break;
      default:
        break;
    }
  }, [trackingCancel.status, trackingList]);

  React.useEffect(() => {
    if (!isOpen && isFocused) {
      switch (trackingCancel.status) {
        case TRACKING_CANCEL.SUCCESS:
          apiHit(FixedValue.CONSTANT_VALUE_0);
          setApiLoader(true);
          setRefreshing(false);
          setMoreDataLoader(false);
          break;
        case TRACKING_LIST.FAILED:
          setApiLoader(false);
          setRefreshing(false);
          setMoreDataLoader(false);
          break;
        default:
          break;
      }
    }
  }, [trackingCancel.status, trackingCancel]);
  const onReachedEndList = React.useCallback((): void => {
    if (
      trackingList.data != null &&
      trackingList.data.pageNumber < trackingList.data.totalPages
    ) {
      setMoreDataLoader(true);
      apiHit(trackingList.data.pageNumber + FixedValue.CONSTANT_VALUE_1);
    }
  }, [trackingList]);

  const onPressDetail = (item: any) => {
    setSelectedItem(item);
    setIsOpen(!isOpen);
  };

  const showConfirmationDialog = (item: any) => {
    const title = item.group ? item.group.groupName : item.top?.name;
    const subText = item.group
      ? TEXT.GROUP_INVITATION + ' for'
      : TEXT.TOP_DISTRIBUTION + ' for';
    const message = `${TEXT.ARE_YOU_SURE_CANCEL} ${subText} "${title}"`;
    const headerTitle = item.group
      ? TEXT.GROUP_INVITATION
      : TEXT.TOP_DISTRIBUTION;
    alertPolyfill(headerTitle, message, [
      {
        text: 'Yes',
        onPress: () => {
          const {requestId, top, group, requestForUnRegisteredUser} =
            item || {};
          let requestType = top
            ? REQUEST_TYPE.DISTRIBUTION
            : group
            ? REQUEST_TYPE.GROUP
            : REQUEST_TYPE.DISTRIBUTION_EMAIL;
          if (requestForUnRegisteredUser) {
            requestType = REQUEST_TYPE.DISTRIBUTION_EMAIL;
          }

          dispatch(
            ontrackingCancelStart({
              requestId: requestId,
              requestType: requestType,
              detail: false,
            })
          );
        },
      },
      {
        text: 'No',
        onPress: () => {},
        style: 'cancel',
      },
    ]);
  };
  const handleRefresh = () => {
    apiHit(0);
    setRefreshing(true);
  };

  const renderItem = ({item}: {item: TrackingListResultsModel}) => {
    return (
      <TrackingTile
        item={item}
        onCancelPress={() => showConfirmationDialog(item)}
        onPress={() => onPressDetail(item)}
        onRemindAllPress={() => hitRemindAll(item)}
      />
    );
  };

  const RenderTrackingListEmptyUI = () => {
    if (trackingList.success) {
      return (
        <View style={styles.container}>
          <NoChildData />
          <Text style={styles.text}>{TEXT.NO_TRACKING_DATA}</Text>
        </View>
      );
    }
    return null;
  };
  return (
    <SafeAreaContainer>
      <ViewContainer>
        {apiLoader && !refreshing && !moreDataLoader && <AppLoader />}
        <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
          <TrackingTabHeader />
          {!apiLoader && (
            <FlatList
              data={data ?? []}
              renderItem={renderItem}
              ListEmptyComponent={() => <RenderTrackingListEmptyUI />}
              keyExtractor={(item, index) => index?.toString() ?? 0}
              style={styles.list}
              showsVerticalScrollIndicator={false}
              onEndReached={onReachedEndList}
              // @ts-ignore
              ListFooterComponent={
                moreDataLoader && <ActivityIndicator size="large" />
              }
              onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
              refreshing={refreshing}
              onRefresh={handleRefresh}
            />
          )}
        </View>
        {isFocused && isOpen && (
          <TrackingDetailModel
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            // @ts-ignore
            item={selectedItem}
            setSelectedItem2={setSelectedItem}
          />
        )}
      </ViewContainer>
    </SafeAreaContainer>
  );
};

export default TrackingTab;
