import React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';

const ScaleIcon = (props: SvgProps) => (
  <Svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M9 21.6968H3V15.6968"
      stroke={props.stroke ?? '#4EAF51'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M3 21.6968L10 14.6968"
      stroke={props.stroke ?? '#4EAF51'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M15 3.69678H21V9.69678"
      stroke={props.stroke ?? '#4EAF51'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M21 3.69678L14 10.6968"
      stroke={props.stroke ?? '#4EAF51'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default ScaleIcon;
