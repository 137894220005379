import React from 'react';
import {Platform, View} from 'react-native';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {ISingleTabProps} from '../../../types/componentTypes/layoutType';
import {FixedValue, ROUTE_NAME, TEXT} from '../../../constants';
import TopTabBarLayout from '../../../components/layout/topTabBarLayout';
import {tradeTabStyle} from '../tradeTab/tradeTab.Style';
import NotificationScreenForActionNeeded from './notificationScreenForActionNeeded';
import NotificationScreenForCompleted from './notificationScreenForCompleted';
import {useIsFocused} from '@react-navigation/native';
import {
  ACCEPTED_OR_REJECTED,
  NOTIFICATION_LIST_TYPE,
} from '../../../constants/enum';
import useNotificationHook from '../../../hooks/componentHooks/useNotificationHooks';
import TradeModal from '../../../modals/tradeModal';
import DistributeModal from '../../../modals/distributeModal';
import AbuseReportModal from '../../../modals/abuseReportModal';
import {useAppDispatch, useAppSelector} from '../../../hooks/reduxHooks';
import {childProfileStore} from '../../../redux/reducers/childProfileSlice';
import {NotificationContext} from '../../../contextAPI/notificationContext';
import {onNotificationCountStart} from '../../../redux/reducers/notificationSlice';
import {TradeContext} from '../../../contextAPI/tradeContext';
import {TradeTabStore} from '../../../redux/reducers/tradeTabSlice';
import useTradeReceviedOrSentHooks from '../../../hooks/componentHooks/useTradeReceviedOrSentHooks';
import useTradingApprovalHooks from '../../../hooks/componentHooks/useTradingApprovalHooks';
import {ReportFormState} from '../../../types/reducerTypes/tradeTabTypes';
import {
  APPROVE_RECEIVED_OFFER,
  DISTRIBUTE_RECEIVED_REQUEST_APPROVE_OR_REJECTOR_REPORT_API,
  DISTRIBUTE_TOP_OFFER_DETAIL,
  REJECT_RECEIVED_OFFER,
  TOP_TRADE_STATUS_UPDATE,
  TRADE_OFFER_DETAIL,
  TRADE_OFFER_REPORT,
} from '../../../constants/actionTypes';
import {showAlert} from '../../../utils/helperFunctions';

const NotificationTabScreen = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = tradeTabStyle(ColorTheme);
  const tabBarList: ISingleTabProps[] = [
    {
      routeName: ROUTE_NAME.ACTION_NEEDED,
      component: NotificationScreenForActionNeeded,
      tabBarName: TEXT.ACTION_NEEDED,
    },
    {
      routeName: ROUTE_NAME.COMPLETED,
      component: NotificationScreenForCompleted,
      tabBarName: TEXT.COMPLETED,
    },
  ];
  const [tabIndex, setTabIndex] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_0
  );
  const isFocused = useIsFocused();
  const {fetchNotificationList} = useNotificationHook();
  const {onReceivedDistributeApproveOrRejectOrReportData} =
    useAppSelector(childProfileStore);
  const dispatch = useAppDispatch();
  const {callTradeReportApi, callTopTradeStatusUpdateAPI} =
    useTradeReceviedOrSentHooks();
  const {childDistributeRequestApproveOrRejectOrReportApi} =
    useTradingApprovalHooks();
  const {
    setShowDistributionModal,
    showDistributionModal,
    isForTradeReport,
    selectedNotificationCard,
    selectedNotificationCardIndex,
  } = React.useContext(NotificationContext);
  const {
    setOpenReceviedOfferModal,
    openReceviedOfferModal,
    setSelectedItem,
    openReportModal,
    setOpenReportModal,
    setCurrentActiveTab,
  } = React.useContext(TradeContext);
  const {
    tradeOfferDetailWork,
    tradeAbuseReport,
    distributeTopDetailWork,
    topTradeStatusUpdate,
    approveOffer,
    rejectOffer,
  } = useAppSelector(TradeTabStore);
  const jsonData = selectedNotificationCard?.json
    ? JSON.parse(selectedNotificationCard?.json)
    : null;

  React.useEffect((): void => {
    if (isFocused) {
      fetchNotificationList(
        FixedValue.CONSTANT_VALUE_0,
        NOTIFICATION_LIST_TYPE.ACTION_NEEDED
      );
      fetchNotificationList(
        FixedValue.CONSTANT_VALUE_0,
        NOTIFICATION_LIST_TYPE.COMPLETED
      );
    }
  }, [isFocused]);

  const distrbuteTopReport = React.useCallback(
    (reportData: ReportFormState): void => {
      const minorId: number =
        JSON.parse(selectedNotificationCard?.json ?? '')?.minorId ??
        FixedValue.CONSTANT_VALUE_0;
      let approveRejectParams: any = {
        reportId: reportData?.reportId,
        description: reportData?.description,
        distributeRequestId: selectedNotificationCard?.requestId,
        status: ACCEPTED_OR_REJECTED.REPORTED,
        index: selectedNotificationCardIndex,
        userBlocked: reportData?.userBlocked ?? false,
      };
      if (minorId) {
        approveRejectParams.minorId = minorId ?? 0;
      }
      childDistributeRequestApproveOrRejectOrReportApi(approveRejectParams);
      setOpenReportModal(false);
    },
    [selectedNotificationCard, selectedNotificationCardIndex]
  );

  const onTradeReportSubmitButtonAction = React.useCallback(
    (reportData: ReportFormState): void => {
      if (isForTradeReport) {
        if (jsonData?.isChild) {
          const params = {...reportData, status: ACCEPTED_OR_REJECTED.REPORT};
          callTopTradeStatusUpdateAPI(params);
        } else {
          callTradeReportApi(reportData);
        }
      } else {
        distrbuteTopReport(reportData);
      }
    },
    [isForTradeReport, jsonData]
  );

  const callNofificationListAndCountAPI = React.useCallback((): void => {
    fetchNotificationList(
      FixedValue.CONSTANT_VALUE_0,
      NOTIFICATION_LIST_TYPE.ACTION_NEEDED
    );
    fetchNotificationList(
      FixedValue.CONSTANT_VALUE_0,
      NOTIFICATION_LIST_TYPE.COMPLETED
    );
    dispatch(onNotificationCountStart());
  }, []);

  React.useEffect(() => {
    switch (tradeOfferDetailWork.status) {
      case TRADE_OFFER_DETAIL.SUCCESS:
        setSelectedItem(tradeOfferDetailWork?.success?.data ?? null);
        break;
      case TRADE_OFFER_DETAIL.FAILED:
        setOpenReceviedOfferModal();
        break;
      default:
        break;
    }
  }, [tradeOfferDetailWork.status]);

  React.useEffect(() => {
    switch (distributeTopDetailWork.status) {
      case DISTRIBUTE_TOP_OFFER_DETAIL.SUCCESS:
        const disbritubeObject = distributeTopDetailWork?.success?.data;
        // Transform the 'top' object into an array
        const modifiedJsonObject = {
          ...disbritubeObject,
          fromUserTops: [disbritubeObject?.top],
          fromUser: disbritubeObject?.fromUser ?? undefined,
        };
        //@ts-ignore
        setSelectedItem(modifiedJsonObject ?? null);
        break;
      case DISTRIBUTE_TOP_OFFER_DETAIL.FAILED:
        setOpenReceviedOfferModal();
        break;
      default:
        break;
    }
  }, [distributeTopDetailWork.status]);

  React.useEffect(() => {
    if (isFocused) {
      switch (tradeAbuseReport.status) {
        case TRADE_OFFER_REPORT.SUCCESS:
          showAlert();
          callNofificationListAndCountAPI();
          break;
        case TRADE_OFFER_REPORT.FAILED:
          break;
        default:
          break;
      }
    }
  }, [tradeAbuseReport.status]);

  React.useEffect(() => {
    switch (onReceivedDistributeApproveOrRejectOrReportData.status) {
      case DISTRIBUTE_RECEIVED_REQUEST_APPROVE_OR_REJECTOR_REPORT_API.SUCCESS:
        callNofificationListAndCountAPI();
        break;
      case TRADE_OFFER_REPORT.FAILED:
        break;
      default:
        break;
    }
  }, [onReceivedDistributeApproveOrRejectOrReportData.status]);

  React.useEffect(() => {
    switch (approveOffer.status) {
      case APPROVE_RECEIVED_OFFER.SUCCESS:
        callNofificationListAndCountAPI();
        break;
      case APPROVE_RECEIVED_OFFER.FAILED:
        break;
      default:
        break;
    }
    switch (rejectOffer.status) {
      case REJECT_RECEIVED_OFFER.SUCCESS:
        callNofificationListAndCountAPI();
        break;
      case REJECT_RECEIVED_OFFER.FAILED:
        break;
      default:
        break;
    }
  }, [approveOffer.status, rejectOffer.status]);

  React.useEffect(() => {
    switch (topTradeStatusUpdate.status) {
      case TOP_TRADE_STATUS_UPDATE.SUCCESS:
        callNofificationListAndCountAPI();
        break;
      default:
        break;
    }
  }, [topTradeStatusUpdate.status]);

  return (
    <View style={styles.container}>
      <TopTabBarLayout
        tabArray={tabBarList}
        setCurrentActiveTab={tabIndex}
        setTab={setTabIndex}
      />
      {openReceviedOfferModal && isFocused && (
        <TradeModal
          isSent={
            selectedNotificationCard?.message.includes('received') ?? true
          }
          showCancelButton={false}
          modalVisible={openReceviedOfferModal}
          onClose={() => {
            setOpenReceviedOfferModal();
            setCurrentActiveTab(ROUTE_NAME.RECEIVED_OFFER);
          }}
          showLoader={tradeOfferDetailWork?.isLoading}
          isChild={jsonData?.isChild ?? false}
          isTradeReceivedRequest={
            selectedNotificationCard?.message.includes('received') ?? false
          }
          isTradeSentRequest={
            selectedNotificationCard?.message.includes('sent') ?? false
          }
          showActionButton={tabIndex === FixedValue.CONSTANT_VALUE_0}
          showPendingRequestCount={tabIndex === FixedValue.CONSTANT_VALUE_0}
          showFavIcon={true}
        />
      )}
      {showDistributionModal && isFocused && (
        <DistributeModal
          isSent={true}
          modalVisible={showDistributionModal}
          onClose={() => setShowDistributionModal(false)}
          showLoader={distributeTopDetailWork?.isLoading}
          isChild={jsonData?.isChild ?? false}
          showActionButton={tabIndex === FixedValue.CONSTANT_VALUE_0}
        />
      )}
      {openReportModal && isFocused && (
        <AbuseReportModal
          modalVisible={openReportModal}
          onClose={setOpenReportModal}
          onSubmit={onTradeReportSubmitButtonAction}
          offerId={Number(selectedNotificationCard?.requestId) ?? -1}
        />
      )}
    </View>
  );
};
export default NotificationTabScreen;
