import {
  launchImageLibraryAsync,
  MediaTypeOptions,
  requestMediaLibraryPermissionsAsync,
  launchCameraAsync,
  requestCameraPermissionsAsync,
  ImagePickerAsset,
} from 'expo-image-picker';
import {Alert, Image, PermissionsAndroid, Platform} from 'react-native';
import {manipulateAsync, SaveFormat} from 'expo-image-manipulator';
import {
  ERROR_MESSAGES,
  FixedValue,
  GlobalStyleValues,
  Percentage,
  TEXT,
} from '../constants';
import {getDataLocally, handleOpenSettings} from './helperFunctions';
import {isWebsite} from './responsive';
import {StorageConstants} from '../constants/appConst';

const AndroidPermissionsGallery = async (): Promise<void> => {
  await PermissionsAndroid.requestMultiple([
    PermissionsAndroid.PERMISSIONS.READ_MEDIA_IMAGES,
    PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
    PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
  ]);
};

const AndroidPermissionsCamera = async (): Promise<void> => {
  await PermissionsAndroid.requestMultiple([
    PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
    PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
    PermissionsAndroid.PERMISSIONS.CAMERA,
  ]);
};

const askForCameraPermission = async () => {
  if (Platform.OS === 'android') await AndroidPermissionsCamera();
  const {status} = await requestCameraPermissionsAsync();
  if (status !== TEXT.GRANTED) {
    throw new Error('Camera permission not granted');
  }
};

export const askForMediaLibraryPermission = async () => {
  if (Platform.OS === 'android') await AndroidPermissionsGallery();
  const {status} = await requestMediaLibraryPermissionsAsync();
  if (status !== TEXT.GRANTED) {
    throw new Error('Media library permission not granted');
  }
};

const resizeImage = async (uri: string, format: string) => {
  const resizedImage = await manipulateAsync(
    uri,
    [
      {
        resize: {
          width: FixedValue.CONSTANT_VALUE_300,
          height: FixedValue.CONSTANT_VALUE_300,
        },
      },
    ],
    {format: format as SaveFormat, compress: FixedValue.CONSTANT_VALUE_05}
  );
  return resizedImage.uri;
};

export const singleImageFormated = async (assetsObject: ImagePickerAsset) => {
  const {uri, fileSize} = assetsObject;
  let imageExtension;
  if (!isWebsite()) {
    imageExtension = uri.split('.').pop();
  } else {
    imageExtension = uri
      .split(';')
      [FixedValue.CONSTANT_VALUE_0].split('/')
      .pop();
  }
  imageExtension = imageExtension === 'jpg' ? 'jpeg' : imageExtension;
  const imageFileName = `IMAGE_NAME-${new Date().getTime()}.${imageExtension}`;
  const imageUri = await resizeImage(uri, imageExtension ?? 'jpeg');
  const image = {
    uri: imageUri,
    type: `image/${imageExtension}`,
    name: imageFileName,
    fileSize,
  };
  return image;
};

const pickImageFromGallery = async () => {
  try {
    await askForMediaLibraryPermission();
    const {canceled, assets} = await launchImageLibraryAsync({
      mediaTypes: MediaTypeOptions.Images,
      quality: FixedValue.CONSTANT_VALUE_05,
      allowsEditing: true,
      allowsMultipleSelection: false,
    });
    if (canceled) {
      throw new Error('Image picking cancelled');
    }
    const image = await singleImageFormated(
      assets[FixedValue.CONSTANT_VALUE_0]
    );
    return image;
  } catch (error: any) {
    if (error.message === 'Media library permission not granted') {
      Alert.alert(
        '',
        ERROR_MESSAGES.ALLOW_PERMISSION,
        [
          {text: TEXT.CANCEL},
          {
            text: TEXT.GO_TO_SETTING,
            onPress: () => handleOpenSettings(),
          },
        ],
        {cancelable: false}
      );
    }
  }
};

const captureImageFromCamera = async () => {
  try {
    await askForCameraPermission();
    const {canceled, assets} = await launchCameraAsync({
      allowsEditing: true,
      quality: FixedValue.CONSTANT_VALUE_05,
    });
    if (canceled) {
      throw new Error('Camera capture cancelled');
    }
    const image = await singleImageFormated(
      assets[FixedValue.CONSTANT_VALUE_0]
    );
    return image;
  } catch (error: any) {
    if (error.message === 'Camera permission not granted') {
      Alert.alert(
        '',
        ERROR_MESSAGES.ALLOW_PERMISSION,
        [
          {text: TEXT.CANCEL},
          {
            text: TEXT.GO_TO_SETTING,
            onPress: () => handleOpenSettings(),
          },
        ],
        {cancelable: false}
      );
    }
  }
};

export const getTopImage = async (
  imageUrl: string,
  style?: any
): Promise<JSX.Element | undefined> => {
  try {
    const {idToken} = await getDataLocally(StorageConstants.USER_TOKENS);
    const headers = {
      Authorization: `Bearer ${idToken}`,
    };
    // Fetch the image manually with custom headers
    const response = await fetch(imageUrl, {
      headers: headers,
    });
    const styles = style
      ? style
      : {
          height: Percentage.PRECENTAGE_100,
          width: Percentage.PRECENTAGE_100,
        };
    // Check if the request was successful (status code 2xx)
    if (response.ok) {
      // Convert the image data to a data URL
      const blob = await response.blob();
      const dataUrl = URL.createObjectURL(blob);

      // Return an Image component with the data URL
      return (
        <Image
          source={{uri: dataUrl}}
          style={styles}
          resizeMode={GlobalStyleValues.CONTAIN}
        />
      );
    } else {
      console.error('Failed to fetch image:', response.statusText);
      return undefined;
    }
  } catch (error) {
    console.error('Error in getTopImage:', error);
    // You can handle the error or return a default value here
    return undefined;
  }
};

export {pickImageFromGallery, captureImageFromCamera, resizeImage};
