import {takeLatest, put, call, select, delay} from 'redux-saga/effects';
import {AppConst} from '../../constants';
import {IActionType} from '../../types/constantFilesTypes';
import {ICreateNewCollectionResponse} from '../../types/reducerTypes/collectionStoreTypes';
import {_createNewCollection, _updateCollection} from '../../utils/apis';
import {authState} from '../reducers/authSlice';
import {
  onCreateCollectionEnd,
  onCreateCollectionFailed,
  onCreateCollectionSuccess,
  onUpdateCollectionEnd,
  onUpdateCollectionFailed,
  onUpdateCollectionSuccess,
} from '../reducers/collectionSlice';
import {isWebsite} from '../../utils/responsive';
import {_ToastHandler} from '../../utils/helperFunctions';

const {StoreConstants} = AppConst;

// Call Create COllection API
function* createNewCollection(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ICreateNewCollectionResponse = yield call(
      _createNewCollection,
      action.payload,
      userTokens
    );
    yield put(onCreateCollectionSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onCreateCollectionFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onCreateCollectionEnd());
  }
}

// Call Update Collection API
function* updateCollection(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ICreateNewCollectionResponse = yield call(
      _updateCollection,
      action.payload,
      userTokens
    );
    yield put(onUpdateCollectionSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onUpdateCollectionFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onUpdateCollectionEnd());
  }
}

export default function* createCollectionSaga() {
  yield takeLatest(
    `${StoreConstants.CREATE_COLLECTION}/onCreateCollectionStart`,
    createNewCollection
  );
  yield takeLatest(
    `${StoreConstants.CREATE_COLLECTION}/onUpdateCollectionStart`,
    updateCollection
  );
}
