import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';
const ReportImage = (props: SvgProps) => (
  <Svg
    width={20}
    height={24}
    viewBox="0 0 20 24"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M2 15C2 15 3 14 6 14C9 14 11 16 14 16C17 16 18 15 18 15V3C18 3 17 4 14 4C11 4 9 2 6 2C3 2 2 3 2 3V15Z"
      stroke="#FF0000"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M2 22V15"
      stroke="#FF0000"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default ReportImage;
