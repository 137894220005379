import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {TrackingDetailStateType} from '../../types/reducerTypes/authStoreTypes';
import {ResetAllState} from './logoutslice';

const {StoreConstants} = AppConst;
const {RESET_STORE, TRACKING_DETAIL} = ActionType;

const initialState: TrackingDetailStateType = {
  trackingDetail: {
    isLoading: false,
    totalPage: 0,
    failed: null,
    data: {
      data: {
        results: [],
      },
      pageSize: 0,
      pageNumber: 0,
      totalPages: 0,
    },
    status: TRACKING_DETAIL.END,
    success: false,
  },
};

const TrackingDetailSlice = createSlice({
  initialState,
  name: StoreConstants.TRACKING_DETAIL,
  reducers: {
    ontrackingDetailStart: (state, _) => {
      state.trackingDetail.isLoading = true;
      state.trackingDetail.status = TRACKING_DETAIL.START;
      state.trackingDetail.success = false;
    },
    ontrackingDetailSuccess: (state, action) => {
      state.trackingDetail.data = action.payload;
      state.trackingDetail.status = TRACKING_DETAIL.SUCCESS;
      state.trackingDetail.success = true;
    },
    ontrackingDetailFailed: (state, action) => {
      state.trackingDetail.failed = action.payload;
      state.trackingDetail.status = TRACKING_DETAIL.FAILED;
      state.trackingDetail.success = false;
    },
    ontrackingDetailEnd: state => {
      state.trackingDetail.isLoading = false;
      state.trackingDetail.status = TRACKING_DETAIL.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  ontrackingDetailEnd,
  ontrackingDetailFailed,
  ontrackingDetailStart,
  ontrackingDetailSuccess,
} = TrackingDetailSlice.actions;
export default TrackingDetailSlice.reducer;
export const trackingDetailState = (state: RootState) => state.trackingDetail;
