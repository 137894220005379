/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import WelcomeBg from '../../components/bgImages/backgroundImage';
import ViewContainer from '../../components/layout/viewContainer';
import ContentView from './contentView';

const WelcomeScreen: React.FC = () => {
  return (
    <ViewContainer>
      <WelcomeBg />
      <ContentView />
    </ViewContainer>
  );
};

export default React.memo(WelcomeScreen);
