import {StyleSheet} from 'react-native';
import {
  BASESTYLE,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
} from '../../../constants';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const styles = (theme: IColorTheme) =>
  StyleSheet.create({
    groupListView: {
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_60),
    },
    groupNameText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme?.color,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    imageView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_65),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    placeHolderBackgroundColor: {
      backgroundColor: theme?.color,
    },
    countUserStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: theme?.color,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_14),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_7),
    },
    groupNameStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme?.color,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_161),
      textTransform: GlobalStyleValues.CAPITALIZE,
    },
    groupProfileText: {
      textAlign: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      color: theme?.backgroundColor,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      textTransform: GlobalStyleValues.UPPERCASE,
    },
    imageStyle: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_65),
    },
    mainViewStyle: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      backgroundColor: theme?.color,
      ...BASESTYLE.inCenter,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_65),
      overflow: 'hidden',
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
  });
