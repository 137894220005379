import React, {useContext} from 'react';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import OrderSummaryContenet from './orderSummaryContent';
import {COLORS, FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import TopBarWithRightButton from '../../components/header/topBarWithRightButton';
import {View} from 'react-native';
import {allTopsStyle} from '../seeAllTops/seeAll.styles';
import {ThemeContext} from '../../contextAPI/themeContext';
import {RootStackParamList} from '../../types/navigationType';
import {RouteProp, useRoute} from '@react-navigation/native';
import OrderSummaryForSingleTop from './orderSummaryForSingleTop';
import {navigate} from '../../services/navigationService';

const OrderSummary = (): JSX.Element => {
  const {ColorTheme} = useContext(ThemeContext);
  const {styles, ids} = allTopsStyle(ColorTheme);
  const route = useRoute<RouteProp<RootStackParamList, 'OrderSummary'>>();
  const isSingleTopSelected =
    route?.params?.totalTopQuantity === FixedValue.CONSTANT_VALUE_1;

  return (
    <SafeAreaContainer>
      <View style={styles.container} dataSet={{media: ids.container}}>
        <View style={styles.horizontalPaddding}>
          <TopBarWithRightButton
            onPress={() => navigate(ROUTE_NAME.MY_CART_TOPS)}
            headerLabel={TEXT.ORDER_SUMMARY}
            headerColor={ColorTheme.color}
          />
          {isSingleTopSelected ? (
            <OrderSummaryForSingleTop />
          ) : (
            <OrderSummaryContenet />
          )}
        </View>
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(OrderSummary);
