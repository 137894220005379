import {StyleSheet} from 'react-native';
import {
  BASESTYLE,
  FixedValue,
  FONT_NAMES,
  Percentage,
} from '../../../constants';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const styles = (theme: IColorTheme) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_44),
      width: Percentage.PRECENTAGE_100,
    },
    titleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: theme.color,
      ...BASESTYLE.absolutePosition,
      ...BASESTYLE.selfCenter,
      ...BASESTYLE.textAlignCenter,
      zIndex: FixedValue.CONSTANT_VALUE_MIN_1,
      width: Percentage.PRECENTAGE_100,
    },
    rightTitleWidth: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
  });
