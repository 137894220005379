import React from 'react';
import {Pressable, Text, View} from 'react-native';
import {FixedValue} from '../../../constants';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {getInitials} from '../../../utils/helperFunctions';
import BackButton from '../../button/backButton';
import ProfilePics from '../../smallDesigns/profilePics';
import {styles} from './styles';
import HeartIcon from '../../renderSvgs/heartIcon';
import {scaleHeight, scaleWidth} from '../../../utils/responsive';
import HeartFavoriteIcon from '../../renderSvgs/heartFavoriteIcon';

interface ITitleWithImageHeaderProps {
  title?: string;
  subTitle?: string;
  image?: string;
  onPress?: () => void;
  isFavorite?: boolean;
  onPressRightIcon?: (val: boolean) => void;
}

const TitleWithImageHeader = (props: ITitleWithImageHeaderProps) => {
  const {
    image,
    onPress,
    subTitle,
    title,
    isFavorite = false,
    onPressRightIcon,
  } = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);

  return (
    <View style={style.container}>
      <View style={style.blackButton}>
        <BackButton onPress={onPress} />
      </View>
      <View style={style.imageView}>
        <ProfilePics
          image={image ?? ''}
          text={getInitials(title ?? '')}
          mainViewStyle={style.mainViewStyle}
          initialsTextStyle={style.initialsTextStyle}
        />
        <Text
          numberOfLines={FixedValue.CONSTANT_VALUE_1}
          style={style.titleText}
        >
          {title}
        </Text>
        <Text
          numberOfLines={FixedValue.CONSTANT_VALUE_1}
          style={style.subTitleText}
        >
          {subTitle}
        </Text>
      </View>
      {isFavorite ? (
        <Pressable
          onPress={() => onPressRightIcon && onPressRightIcon(!isFavorite)}
          style={style.heartContainer}
        >
          <HeartFavoriteIcon
            height={scaleHeight(FixedValue.CONSTANT_VALUE_32)}
            width={scaleWidth(FixedValue.CONSTANT_VALUE_32)}
          />
        </Pressable>
      ) : (
        <Pressable
          onPress={() => onPressRightIcon && onPressRightIcon(!isFavorite)}
          style={style.heartContainer}
        >
          <HeartIcon
            height={scaleHeight(FixedValue.CONSTANT_VALUE_46)}
            width={scaleWidth(FixedValue.CONSTANT_VALUE_46)}
          />
        </Pressable>
      )}
    </View>
  );
};

export default React.memo(TitleWithImageHeader);
