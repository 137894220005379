import React from 'react';
import AWS from 'aws-sdk';

import {FixedValue} from '../../constants';
import {getBaseUrl, options} from '../../constants/environment';
import {extractUrlPath, _ToastHandler} from '../../utils/helperFunctions';
import {ICameraClickProps} from '../../types/reducerTypes/groupMangementTypes';
import {apiCall} from '../../utils/axiosHelper';
import {METHODS} from '../../constants/enum';
import {useAppDispatch, useAppSelector} from '../reduxHooks';
import {appTopState, updateTopInObject} from '../../redux/reducers/appTopSlice';

const useAwsS3 = () => {
  const [percentage, setPercentage] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_0
  );
  const s3 = new AWS.S3({
    accessKeyId: options.accessKey,
    secretAccessKey: options.secretKey,
    region: options.region,
  });
  const {appTopsObject} = useAppSelector(appTopState);
  const dispatch = useAppDispatch();

  const clearAllData = React.useCallback((): void => {
    setPercentage(FixedValue.CONSTANT_VALUE_0);
  }, []);

  const uploadFile = React.useCallback(
    async (
      btoaData: string,
      thumbnailImage: string,
      fileName?: string
    ): Promise<any> => {
      const imageUpload = await uploadImage({
        uri: thumbnailImage,
        type: 'image/png',
        name: `${fileName}_img`,
      });
      const uploadSvg = new Promise((resolve, _reject) => {
        const filePath = `${fileName}`;
        const apiInfo = {
          name: `upload-s3-file/${options.uploadS3Bucket}`,
          type: METHODS.PUT,
          contType: 'multipart/form-data',
        };
        const formData = new FormData();
        const svgBlob = new Blob([btoaData], {type: 'image/svg+xml'});
        formData.append('file', svgBlob, filePath);
        apiCall(apiInfo, formData, {filePath}, null)
          .then((result: any) => {
            resolve(result.data.url);
          })
          .catch(error => console.log(error, 'error while uploading top'));
      });
      return await Promise.all([imageUpload, uploadSvg]);
    },
    [percentage]
  );

  const uploadImage = React.useCallback(
    (files: ICameraClickProps): Promise<string> => {
      return new Promise(async (resolve, _reject) => {
        const apiInfo = {
          name: `upload-s3-file/${options.uploadS3Bucket}`,
          type: METHODS.PUT,
          contType: 'multipart/form-data',
        };
        const formData = new FormData();
        const response = await fetch(files.uri);
        const file = await response.blob();
        const fileBlob = new Blob([file], {type: files.type});
        formData.append('file', fileBlob, files?.name);
        apiCall(apiInfo, formData, {filePath: files.name}, null)
          .then((result: any) => {
            resolve(result.data.url);
          })
          .catch(error => console.log(error, 'error while uploading image'));
      });
    },
    []
  );

  const getSignedUrl = React.useCallback(
    async (key: string): Promise<string | undefined> => {
      try {
        const params = {
          Bucket: options.bucket,
          Key: key,
          Expires: FixedValue.CONSTANT_VALUE_60 * FixedValue.CONSTANT_VALUE_60,
        };
        return s3.getSignedUrl('getObject', params);
      } catch (error: any) {
        _ToastHandler(error.message, false);
      }
    },
    []
  );

  const loadSignedUrlForEditTop = async (
    image: string
  ): Promise<string | undefined> => {
    try {
      if (image) {
        const filePath = image.substring(image.lastIndexOf('/') + 1);
        if (appTopsObject != null) {
          const topKeys: string[] = Object.keys(appTopsObject);
          const index = topKeys.findIndex(topKey => topKey === filePath);
          if (index !== FixedValue.CONSTANT_VALUE_MIN_1) {
            const key = topKeys[index];
            return appTopsObject[key];
          }
        }
        const apiInfo = {name: '', type: ''};
        apiInfo.name = 'download-s3-file/' + `${options.bucket}`;
        apiInfo.type = METHODS.GET;
        const result: any = await apiCall(apiInfo, null, {filePath}, null);
        if (result) dispatch(updateTopInObject({[filePath]: result}));
        return result;
      }
    } catch (error) {
      console.error('Error occurred while loading signed URL:', error);
    }
    return undefined;
  };

  const loadSignedUrl = async (
    image: string,
    isSvgCode?: boolean
  ): Promise<string | undefined> => {
    try {
      if (image) {
        const imageName = image.substring(image.lastIndexOf('/') + 1);
        const apiInfo = {name: '', type: ''};
        apiInfo.name = 'download-s3-file/' + `${options.bucket}`;
        apiInfo.type = METHODS.GET;
        if (isSvgCode) {
          const filePath = `${imageName}`;
          const result: any = await apiCall(apiInfo, null, {filePath}, null);
          return result;
        } else {
          const filePath = `${imageName}_img`;
          const imageUrl = `${getBaseUrl()}download-s3-file/${
            options.bucket
          }?filePath=${filePath}`;
          return imageUrl;
        }
      }
    } catch (error) {
      console.error('Error occurred while loading signed URL:', error);
    }
    return undefined;
  };
  const getCSVDownloadURL = (
    imageName: string
  ): Promise<string | undefined> => {
    return new Promise(async (resolve, reject) => {
      try {
        if (imageName) {
          const apiInfo = {name: '', type: ''};
          apiInfo.name = 'download-s3-file-csv/' + `${options.bucket}`;
          apiInfo.type = METHODS.GET;
          const filePath = `${imageName}`;
          const result: any = await apiCall(apiInfo, null, {filePath}, null);
          // Check if the result is valid
          if (result) {
            // Create a Blob from the CSV data
            const blob = new Blob([result], {type: 'text/csv'});
            // Create a temporary URL for the Blob
            const url = URL.createObjectURL(blob);
            // Trigger a download of the CSV file
            const link = document.createElement('a');
            link.href = url;
            link.download = `${imageName}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            resolve(result);
          } else {
            reject('Empty response received');
          }
        }
      } catch (error) {
        console.error('Error occurred while loading signed URL:', error);
        reject(error);
      }
    });
  };

  const loadSignedImageUrl = async (
    image: string
  ): Promise<string | undefined> => {
    if (image) {
      const url: string | undefined = await getSignedUrl(extractUrlPath(image));
      return url;
    }
  };

  const uploadCSV = React.useCallback(
    async (csvData: string, fileName?: string): Promise<string> => {
      try {
        const apiInfo = {
          name: `upload-s3-file/${options.uploadS3Bucket}`,
          type: METHODS.PUT,
          contType: 'multipart/form-data',
        };
        const formData = new FormData();
        const response = await fetch(csvData);
        const file = await response.blob();
        const fileBlob = new Blob([file], {type: 'text/csv'});
        formData.append('file', fileBlob, fileName);
        const result: any = await apiCall(
          apiInfo,
          formData,
          {filePath: `${fileName}.csv`},
          null
        );
        return result?.data?.url;
      } catch (error) {
        throw new Error('Failed to upload CSV to S3');
      }
    },
    []
  );

  return {
    percentage,
    uploadFile,
    clearAllData,
    getSignedUrl,
    loadSignedUrl,
    uploadImage,
    loadSignedImageUrl,
    loadSignedUrlForEditTop,
    uploadCSV,
    getCSVDownloadURL,
  };
};

export default useAwsS3;
