import React from 'react';
import {FlatList, View} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';

import {ThemeContext} from '../../../contextAPI/themeContext';
import ViewContainer from '../../../components/layout/viewContainer';
import {FixedValue, TEXT} from '../../../constants';
import TopsListEmptyView from '../../../components/emptyListView/topsListEmptyView';
import ChildTradeComponent from '../../../components/childTradeComponent';
import {ChildProfileContext} from '../../../contextAPI/childProfileContext';
import useTradingApprovalHooks from '../../../hooks/componentHooks/useTradingApprovalHooks';
import CustomButton from '../../../components/button/customButton';
import TICKICON from '../../../components/renderSvgs/tickIcon';
import {OfferModel} from '../../../types/reducerTypes/tradeTabTypes';
import {TopReceivedStyle} from '../topTradingReceived/topReceived.style';
import CROSSICON from '../../../components/renderSvgs/smallCrossIcon';
import NoSentOffer from '../../../components/renderSvgs/noSentOffer';
import {childProfileStore} from '../../../redux/reducers/childProfileSlice';
import {useAppSelector} from '../../../hooks/reduxHooks';

const TopSentContent = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = TopReceivedStyle(ColorTheme);
  const {
    OnChildTradeSentListEndReach,
    pullDownToRefreshOnTradeSentList,
    navigateToTopDetailScreen,
  } = useTradingApprovalHooks();
  const {
    tradeSentList,
    childProfileContextPullDownToRefresh,
    initialLoader,
    setSelectedTradeReceivedData,
    setShowTradeSentApproveConfirmModal,
    setIsTradeReceivedApproved,
  } = React.useContext(ChildProfileContext);
  const {onTradeApprovalSentListData} = useAppSelector(childProfileStore);

  const tradeApproveRejectButton = (data: OfferModel) => {
    return (
      <View style={styles.buttonView}>
        <CustomButton
          buttonLabel={TEXT.APPROVE}
          buttonwidth={styles.primaryButtonStyle}
          labelStyle={styles.primaryButtonLabelstyle}
          buttonBackGroundColor={styles.noButtonBackGroundColor1}
          element={<TICKICON />}
          onPress={() => {
            setSelectedTradeReceivedData(data);
            setIsTradeReceivedApproved(true);
            setShowTradeSentApproveConfirmModal();
          }}
        />
        <CustomButton
          buttonLabel={TEXT.REJECT}
          buttonwidth={styles.primaryButtonStyle2}
          labelStyle={styles.primaryButtonLabelstyle1}
          buttonBackGroundColor={styles.noButtonBackGroundColor}
          element={<CROSSICON />}
          onPress={() => {
            setSelectedTradeReceivedData(data);
            setIsTradeReceivedApproved(false);
            setShowTradeSentApproveConfirmModal();
          }}
        />
      </View>
    );
  };

  const renderChildTradingReceivedList =
    React.useMemo((): JSX.Element | null => {
      return (
        <FlatList
          data={tradeSentList}
          keyExtractor={(_, index) => `${TEXT.CHILD_TRADE_SENT_LIST}-${index}`}
          showsVerticalScrollIndicator={false}
          scrollEnabled
          contentContainerStyle={styles.flatListContentContainerStyle}
          onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
          ListEmptyComponent={
            onTradeApprovalSentListData.isLoading || initialLoader ? (
              <></>
            ) : (
              <TopsListEmptyView
                emptyViewTitle={TEXT.NO_TRADING_SENT_FOUND}
                emptyViewDesc={''}
                placeHolder={<NoSentOffer />}
              />
            )
          }
          onEndReached={() => OnChildTradeSentListEndReach()}
          refreshControl={
            <RefreshControl
              refreshing={childProfileContextPullDownToRefresh}
              onRefresh={pullDownToRefreshOnTradeSentList}
            />
          }
          renderItem={({item, index}) => (
            <>
              <ChildTradeComponent
                key={item.id}
                userDetail={item?.toUser}
                creationDate={item?.creationDate}
                imageArray={item?.toUserTops}
                showTopDetail={navigateToTopDetailScreen}
              />
              <ChildTradeComponent
                key={index}
                userDetail={item?.fromUser}
                imageArray={item?.fromUserTops}
                showTopDetail={navigateToTopDetailScreen}
              />
              {tradeApproveRejectButton(item)}
              <View style={styles.bottomLineStyle} />
            </>
          )}
        />
      );
    }, [tradeSentList, onTradeApprovalSentListData, initialLoader]);

  return (
    <View style={styles.receivedMainContainer}>
      <ViewContainer>
        <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
          {renderChildTradingReceivedList}
        </View>
      </ViewContainer>
    </View>
  );
};

export default React.memo(TopSentContent);
