import React from 'react';
import {View, Text, Pressable, ViewStyle} from 'react-native';

import {editorStyle} from './editorHeader.style';

import CrossCircle from '../../renderSvgs/crossCircle';
import ClearAll from '../../renderSvgs/clearAll';
import {BASESTYLE, FixedValue, TEXT} from '../../../constants';
import {EditorContext} from '../../../contextAPI/editorContext';
import useEditorHooks from '../../../hooks/componentHooks/useEditorHooks';
import {RouteProp, useRoute} from '@react-navigation/native';
import {RootStackParamList} from '../../../types/navigationType';

const MainHeader = (): JSX.Element => {
  const route = useRoute<RouteProp<RootStackParamList, 'EditorScreen'>>();
  const isEdit = route.params?.isEdit ?? false;
  const {items} = React.useContext(EditorContext);
  const haveData: boolean = items.length > FixedValue.CONSTANT_VALUE_0;
  const {onClickSaveTop, ResetEditor, removeAllItem} = useEditorHooks();

  const lowOpacity: ViewStyle | boolean = !haveData && editorStyle.lowOpacity;

  return (
    <View style={editorStyle.container}>
      <View style={BASESTYLE.inRow}>
        <Pressable onPress={ResetEditor}>
          <CrossCircle />
        </Pressable>
        <Pressable
          disabled={!haveData}
          onPress={removeAllItem}
          style={[editorStyle.crossView, lowOpacity]}
        >
          <ClearAll />
        </Pressable>
      </View>
      <Pressable disabled={!haveData} onPress={onClickSaveTop}>
        <Text style={[editorStyle.saveTop, lowOpacity]}>
          {isEdit ? TEXT.UPDATE_TOP : TEXT.SAVE_TOP}
        </Text>
      </Pressable>
    </View>
  );
};

export default React.memo(MainHeader);
