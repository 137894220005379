import React from 'react';
import {View} from 'react-native';
import {styles} from './editorFooter.style';
import OrderItemModal from '../../../modals/orderItemModal';

const OrderFooter = (): JSX.Element => {
  return (
    <>
      <View style={styles.shapefooter} />
      <OrderItemModal />
    </>
  );
};

export default React.memo(OrderFooter);
