import React from 'react';
import {View} from 'react-native';
import ProfileBg from '../../../components/bgImages/profileBg';
import SafeAreaContainer from '../../../components/layout/safeAreaContainer';
import {profileStyle} from './profile.style';
import ProfileBottomBtn from './profileBottomBtn';
import ProfileUpperContent from './profileUpperContent';
import {useIsFocused} from '@react-navigation/native';
import {useAppDispatch, useAppSelector} from '../../../hooks/reduxHooks';
import {
  onUserDataStart,
  userProfileStore,
} from '../../../redux/reducers/userProfileSlice';
import {Auth} from 'aws-amplify';
import ConfirmationModal from '../../../modals/confirmationModal/confirmationModal';
import {TEXT} from '../../../constants';
import {ProfileTabContext} from '../../../contextAPI/profileTabContext';
import {
  logoutdState,
  onDeleteStart,
  onLogoutStart,
} from '../../../redux/reducers/logoutslice';
import {HomeContext} from '../../../contextAPI/homeContext';
import AppLoader from '../../../components/smallDesigns/appLoader';

const ProfileTab = (): JSX.Element => {
  const {styles, ids} = profileStyle();
  const isFocused = useIsFocused();
  const dispatch = useAppDispatch();
  const [emailVerified, setEmailVerified] = React.useState<boolean>(true);
  const [mobileVerified, setMobileVerified] = React.useState<boolean>(true);
  const {
    modalVisibleLogout,
    setModalVisibleLogout,
    setParentChildListData,
    modalVisibleDeleteUser,
    setModalVisibleDeleteUser,
    setTransactionHistory,
  } = React.useContext(ProfileTabContext);
  const {
    setIsProfileSwitchToChild,
    clearSelectedTopsResult,
    clearCollectionsResult,
    collectionsResult,
  } = React.useContext(HomeContext);
  const {logout, deleteAccount} = useAppSelector(logoutdState);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const {userData} = useAppSelector(userProfileStore);

  const checkEmailVerified = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const emailAttribute = (await Auth.userAttributes(cognitoUser)).find(
      att => att.Name === 'email_verified'
    );
    setEmailVerified(Boolean(emailAttribute?.Value === 'true'));
  };

  const checkMobileVerified = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const mobileAttribute = (await Auth.userAttributes(cognitoUser)).find(
      att => att.Name === 'phone_number_verified'
    );
    setMobileVerified(Boolean(mobileAttribute?.Value === 'true'));
  };

  const onClickYes = React.useCallback(async () => {
    dispatch(onLogoutStart());
    setIsProfileSwitchToChild(false);
    setParentChildListData(null);
    clearSelectedTopsResult();
    clearCollectionsResult();
    checkMobileVerified();
  }, [collectionsResult]);

  const onClickYesForDelete = React.useCallback(async () => {
    dispatch(onDeleteStart());
  }, []);

  React.useEffect(() => {
    setLoading(logout.isLoading || deleteAccount.isLoading);
    if (!logout.isLoading) setModalVisibleLogout(false);
    if (!deleteAccount.isLoading) setModalVisibleDeleteUser(false);
  }, [logout.isLoading, deleteAccount.isLoading]);

  React.useEffect(() => {
    if (isFocused) {
      setTransactionHistory([]);
      dispatch(onUserDataStart());
      checkEmailVerified();
      checkMobileVerified();
    }
  }, [isFocused]);

  return (
    <SafeAreaContainer>
      <>
        {userData?.isLoading && <AppLoader />}
        <ProfileBg />
        <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
          <ProfileUpperContent
            emailVerified={emailVerified}
            mobileVerified={mobileVerified}
          />
          <ProfileBottomBtn />
          <ConfirmationModal
            title={TEXT.CONFIRM_LOGOUT}
            modalVisible={modalVisibleLogout}
            onPressYes={onClickYes}
            onShowHide={() => setModalVisibleLogout(false)}
            isLoading={isLoading}
          />
          <ConfirmationModal
            title={TEXT.DELETE_USER}
            modalVisible={modalVisibleDeleteUser}
            onPressYes={onClickYesForDelete}
            onShowHide={() => setModalVisibleDeleteUser(false)}
            isLoading={isLoading}
          />
        </View>
      </>
    </SafeAreaContainer>
  );
};

export default ProfileTab;
