import React from 'react';
import Svg, {SvgProps, Rect, Path} from 'react-native-svg';
const GroupText = (props: SvgProps) => (
  <Svg
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={64}
    viewBox="0 0 48 64"
    fill="none"
    {...props}
  >
    <Rect x={0.5} y={0.5} width={47} height={47} rx={7.5} stroke="#333333" />
    <Path
      d="M16 19V16H32V19"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M21 32H27"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M24 16V32"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M17.8016 62H18.6805V55.7451H20.951V54.9541H15.5311V55.7451H17.8016V62ZM23.0729 62.0928C24.3131 62.0928 25.065 61.3896 25.2457 60.6768L25.2555 60.6377H24.4059L24.3863 60.6816C24.2447 60.999 23.8053 61.3359 23.0924 61.3359C22.1549 61.3359 21.5543 60.7012 21.5299 59.6123H25.3189V59.2803C25.3189 57.708 24.4498 56.6436 23.0191 56.6436C21.5885 56.6436 20.6607 57.7568 20.6607 59.3828V59.3877C20.6607 61.0381 21.5689 62.0928 23.0729 62.0928ZM23.0143 57.4004C23.7906 57.4004 24.3668 57.8936 24.4547 58.9336H21.5445C21.6373 57.9326 22.233 57.4004 23.0143 57.4004ZM25.7221 62H26.6742L27.9242 60.0127H28.0023L29.2475 62H30.2436L28.432 59.334L30.2191 56.7363H29.267L28.0316 58.6943H27.9535L26.7035 56.7363H25.7025L27.5238 59.3682L25.7221 62ZM32.9563 62.0391C33.1223 62.0391 33.2834 62.0195 33.4494 61.9902V61.2676C33.2932 61.2822 33.2102 61.2871 33.0588 61.2871C32.5119 61.2871 32.2971 61.0381 32.2971 60.418V57.4395H33.4494V56.7363H32.2971V55.374H31.4182V56.7363H30.5881V57.4395H31.4182V60.6328C31.4182 61.6387 31.8723 62.0391 32.9563 62.0391Z"
      fill="white"
    />
  </Svg>
);
export default GroupText;
