import React from 'react';
import {
  ADD_TOPS_TO_COLLECTION,
  DELETE_TOPS,
  NOTES_LIST,
  TOPS_MOVE_TO_COLLECTION,
  TOP_DETAIL,
} from '../constants/actionTypes';
import {useAppSelector} from '../hooks/reduxHooks';
import {HomeStore} from '../redux/reducers/homeSlice';
import {searchTabStore} from '../redux/reducers/searchTabSlice';
import {
  ITopDetailContext,
  ITopDetailProvider,
} from '../types/contextTypes/topDetailContext';
import {NotesModel, TopModel} from '../types/reducerTypes/searchTabStoreType';
import {FixedValue} from '../constants';

const TopDetailContext = React.createContext<ITopDetailContext>({
  topDetail: null,
  setTopDetail: _val => {},
  svgCode: null,
  setSvgCode: _val => {},
  openOptionsModal: false,
  setOpenOptionsModal: () => {},
  openMoveToCollectionModal: false,
  setOpenMoveToCollectionModal: () => {},
  openProposeModal: false,
  setOpenProposeModal: () => {},
  openMoveTopConfirmationModal: false,
  setOpenMoveTopConfirmationModal: () => {},
  notesListData: [],
  setNotesListData: _val => {},
  openNotesAddEditModal: false,
  setOpenNotesAddEditModal: _val => {},
  isNotesEditMode: false,
  setIsNotesEditMode: _val => {},
  notesData: null,
  setNotesData: _val => {},
  showFavoriteIcon: false,
  setShowFavoriteIcon: _val => {},
});

const TopDetailProvider = (props: ITopDetailProvider): JSX.Element => {
  const {deleteTops, addTopsToCollection, moveToCollection} =
    useAppSelector(HomeStore);
  const {topDetailData, notesListResponse} = useAppSelector(searchTabStore);
  const [svgCode, setSvgCode] = React.useState<string | null>(null);
  const [openOptionsModal, setOpenOptionsModal] =
    React.useState<boolean>(false);
  const [openMoveToCollectionModal, setOpenMoveToCollectionModal] =
    React.useState<boolean>(false);
  const [topDetail, setTopDetail] = React.useState<TopModel | null>(null);
  const [openProposeModal, setOpenProposeModal] =
    React.useState<boolean>(false);
  const [openMoveTopConfirmationModal, setOpenMoveTopConfirmationModal] =
    React.useState<boolean>(false);
  const [notesListData, setNotesListData] = React.useState<NotesModel[]>([]);
  const [openNotesAddEditModal, setOpenNotesAddEditModal] =
    React.useState<boolean>(false);
  const [isNotesEditMode, setIsNotesEditMode] = React.useState<boolean>(false);
  const [notesData, setNotesData] = React.useState<NotesModel | null>(null);
  const [showFavoriteIcon, setShowFavoriteIcon] =
    React.useState<boolean>(false);

  const _setOpenOptionsModal = React.useCallback((): void => {
    setOpenOptionsModal(prev => !prev);
  }, [openOptionsModal]);

  const _setOpenMoveToCollectionModal = React.useCallback((): void => {
    setOpenMoveToCollectionModal(prev => !prev);
  }, [openMoveToCollectionModal]);

  const _setTopDetail = React.useCallback(
    (value: TopModel | null): void => {
      setTopDetail(value);
    },
    [topDetail]
  );

  const _setOpenMoveTopConfirmationModal = React.useCallback(
    (value: boolean): void => {
      setOpenMoveTopConfirmationModal(value);
    },
    [openMoveTopConfirmationModal]
  );

  const _setSvgCode = React.useCallback(
    (value: string | null): void => {
      setSvgCode(value);
    },
    [svgCode]
  );

  const _setOpenProposeModal = React.useCallback((): void => {
    setOpenProposeModal(prev => !prev);
  }, [openProposeModal]);

  React.useEffect(() => {
    if (
      topDetailData.status === TOP_DETAIL.SUCCESS &&
      topDetailData.success != null
    ) {
      setTopDetail({...topDetailData.success?.data});
      setShowFavoriteIcon(true);
    }
  }, [topDetailData.status]);

  React.useEffect(() => {
    switch (deleteTops.status) {
      case DELETE_TOPS.SUCCESS:
        setOpenOptionsModal(false);
        break;
      case DELETE_TOPS.FAILED:
        break;
      default:
        break;
    }
  }, [deleteTops.status]);

  React.useEffect(() => {
    switch (addTopsToCollection.status) {
      case ADD_TOPS_TO_COLLECTION.SUCCESS:
        setOpenMoveToCollectionModal(false);
        break;
      case ADD_TOPS_TO_COLLECTION.FAILED:
        break;
      default:
        break;
    }
  }, [addTopsToCollection.status]);

  React.useEffect(() => {
    switch (moveToCollection.status) {
      case TOPS_MOVE_TO_COLLECTION.SUCCESS:
        setOpenMoveToCollectionModal(false);
        break;
      case TOPS_MOVE_TO_COLLECTION.FAILED:
        break;
      default:
        break;
    }
  }, [moveToCollection.status]);

  React.useEffect(() => {
    switch (notesListResponse.status) {
      case NOTES_LIST.SUCCESS:
        if (
          notesListResponse.success?.pageNumber === FixedValue.CONSTANT_VALUE_0
        ) {
          setNotesListData(notesListResponse.success?.data.results ?? []);
        } else {
          setNotesListData(prev => [
            ...prev,
            ...(notesListResponse.success?.data.results ?? []),
          ]);
        }
        break;
      case NOTES_LIST.FAILED:
        break;
      default:
        break;
    }
  }, [notesListResponse.status]);

  const _setNotesListData = React.useCallback(
    (NewData: NotesModel[]): void => {
      setNotesListData(prev => [...prev, ...NewData]);
    },
    [notesListData]
  );

  React.useEffect(() => {
    switch (notesListResponse.status) {
      case NOTES_LIST.SUCCESS:
        if (
          notesListResponse.success?.pageNumber === FixedValue.CONSTANT_VALUE_0
        ) {
          setNotesListData(notesListResponse.success?.data.results ?? []);
        } else {
          setNotesListData(prev => [
            ...prev,
            ...(notesListResponse.success?.data.results ?? []),
          ]);
        }
        break;
      case NOTES_LIST.FAILED:
        break;
      default:
        break;
    }
  }, [notesListResponse.status]);

  const _setOpenNotesAddEditModal = React.useCallback(
    (value: boolean): void => {
      setOpenNotesAddEditModal(value);
    },
    [openNotesAddEditModal]
  );

  const _setIsNotesEditMode = React.useCallback(
    (value: boolean): void => {
      setIsNotesEditMode(value);
    },
    [isNotesEditMode]
  );

  const _setNotesData = React.useCallback(
    (value: NotesModel | null): void => {
      setNotesData(value);
    },
    [notesData]
  );

  const _setShowFavoriteIcon = React.useCallback(
    (value: boolean): void => {
      setShowFavoriteIcon(value);
    },
    [showFavoriteIcon]
  );

  const values = React.useMemo(
    (): ITopDetailContext => ({
      topDetail,
      svgCode,
      openOptionsModal,
      openMoveToCollectionModal,
      openProposeModal,
      openMoveTopConfirmationModal,
      notesListData,
      openNotesAddEditModal,
      isNotesEditMode,
      notesData,
      showFavoriteIcon,
      setSvgCode: _setSvgCode,
      setTopDetail: _setTopDetail,
      setOpenOptionsModal: _setOpenOptionsModal,
      setOpenMoveToCollectionModal: _setOpenMoveToCollectionModal,
      setOpenProposeModal: _setOpenProposeModal,
      setOpenMoveTopConfirmationModal: _setOpenMoveTopConfirmationModal,
      setNotesListData: _setNotesListData,
      setOpenNotesAddEditModal: _setOpenNotesAddEditModal,
      setIsNotesEditMode: _setIsNotesEditMode,
      setNotesData: _setNotesData,
      setShowFavoriteIcon: _setShowFavoriteIcon,
    }),
    [
      topDetail,
      svgCode,
      openOptionsModal,
      openMoveToCollectionModal,
      openProposeModal,
      openMoveTopConfirmationModal,
      notesListData,
      openNotesAddEditModal,
      isNotesEditMode,
      notesData,
      showFavoriteIcon,
      setSvgCode,
      setTopDetail,
      setOpenOptionsModal,
      setOpenMoveToCollectionModal,
      setOpenProposeModal,
      setOpenMoveTopConfirmationModal,
      setNotesListData,
      setOpenNotesAddEditModal,
      setIsNotesEditMode,
      setNotesData,
      setShowFavoriteIcon,
    ]
  );

  return (
    <TopDetailContext.Provider value={values}>
      {props.children}
    </TopDetailContext.Provider>
  );
};

export {TopDetailContext, TopDetailProvider};
