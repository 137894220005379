import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {isWebsite, scaleHeight, scaleWidth} from '../../utils/responsive';

export const childListstyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.flex1,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    contentContainer: {
      ...BASESTYLE.flex1,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_15),
      backgroundColor: _theme.backgroundColor,
    },
    scrollviewStyle: {
      ...BASESTYLE.flex1,
      justifyContent: GlobalStyleValues.CENTER,
    },
  });
