import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
const ApproveListEmpty = (props: SvgProps) => (
  <Svg
    width={250}
    height={200}
    viewBox="0 0 250 200"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63 134H154C154.515 134 155.017 133.944 155.5 133.839C155.983 133.944 156.485 134 157 134H209C212.866 134 216 130.866 216 127C216 123.134 212.866 120 209 120H203C199.134 120 196 116.866 196 113C196 109.134 199.134 106 203 106H222C225.866 106 229 102.866 229 99C229 95.134 225.866 92 222 92H200C203.866 92 207 88.866 207 85C207 81.134 203.866 78 200 78H136C139.866 78 143 74.866 143 71C143 67.134 139.866 64 136 64H79C75.134 64 72 67.134 72 71C72 74.866 75.134 78 79 78H39C35.134 78 32 81.134 32 85C32 88.866 35.134 92 39 92H64C67.866 92 71 95.134 71 99C71 102.866 67.866 106 64 106H24C20.134 106 17 109.134 17 113C17 116.866 20.134 120 24 120H63C59.134 120 56 123.134 56 127C56 130.866 59.134 134 63 134ZM226 134C229.866 134 233 130.866 233 127C233 123.134 229.866 120 226 120C222.134 120 219 123.134 219 127C219 130.866 222.134 134 226 134Z"
      fill="#F3F7FF"
    />
    <Path
      d="M172.5 45V51"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M172.5 59V65"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M162.5 55H168.5"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M176.5 55H182.5"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M89.5 148V152"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M89.5 160V164"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M81.5 156H85.5"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M93.5 156H97.5"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.711 131.126C162.102 129.423 163.352 127.642 164.461 125.799C166.901 121.744 168.66 117.388 169.734 112.897C170.821 108.356 171.209 103.676 170.894 99.0296C170.62 94.9756 169.811 90.9475 168.465 87.0598C167.131 83.2028 165.268 79.484 162.875 76.015C161.45 73.9479 159.836 71.9696 158.034 70.1036C155.388 67.364 152.486 65.0219 149.402 63.0787C146.089 60.9908 142.566 59.3634 138.926 58.1984C134.368 56.7393 129.628 56.0054 124.886 56C121.197 55.9959 117.507 56.4328 113.902 57.3125C109.845 58.3028 105.895 59.8543 102.177 61.9692C98.9076 63.8284 95.817 66.123 92.988 68.8547C89.4328 72.2875 86.553 76.1572 84.3512 80.2985C82.1956 84.3531 80.6899 88.6682 79.8366 93.0887C78.9574 97.6429 78.7706 102.309 79.2789 106.918C79.9378 112.891 81.7643 118.768 84.7644 124.179C86.684 127.641 89.0842 130.912 91.9663 133.896C96.5416 138.634 101.883 142.183 107.604 144.535C114.562 147.397 122.081 148.489 129.465 147.8C135.662 147.221 141.765 145.387 147.361 142.29"
      fill="white"
    />
    <Path
      d="M160.711 131.126C162.102 129.423 163.352 127.642 164.461 125.799C166.901 121.744 168.66 117.388 169.734 112.897C170.821 108.356 171.209 103.676 170.894 99.0296C170.62 94.9756 169.811 90.9475 168.465 87.0598C167.131 83.2028 165.268 79.484 162.875 76.015C161.45 73.9479 159.836 71.9696 158.034 70.1036C155.388 67.364 152.486 65.0219 149.402 63.0787C146.089 60.9908 142.566 59.3634 138.926 58.1984C134.368 56.7393 129.628 56.0054 124.886 56C121.197 55.9959 117.507 56.4328 113.902 57.3125C109.845 58.3028 105.895 59.8543 102.177 61.9692C98.9076 63.8284 95.817 66.123 92.988 68.8547C89.4328 72.2875 86.553 76.1572 84.3512 80.2985C82.1956 84.3531 80.6899 88.6682 79.8366 93.0887C78.9574 97.6429 78.7706 102.309 79.2789 106.918C79.9378 112.891 81.7643 118.768 84.7644 124.179C86.684 127.641 89.0842 130.912 91.9663 133.896C96.5416 138.634 101.883 142.183 107.604 144.535C114.562 147.397 122.081 148.489 129.465 147.8C135.662 147.221 141.765 145.387 147.361 142.29"
      stroke="#1F64E7"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.701 140.378C153.607 138.508 156.191 136.174 157.872 134.291L150.701 140.378Z"
      fill="white"
    />
    <Path
      d="M150.701 140.378C153.607 138.508 156.191 136.174 157.872 134.291"
      stroke="#1F64E7"
      strokeWidth={2.5}
      strokeLinecap="round"
    />
    <Path
      d="M167 104.617C166.714 104.622 166.427 104.625 166.14 104.625C140.886 104.625 120.209 84.9362 118.476 60C98.3752 63.1706 83 80.6526 83 101.743C83 125.081 101.825 144 125.048 144C147.309 144 165.53 126.615 167 104.617Z"
      fill="#E8F0FE"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M109.023 100.373C107.8 99.2433 105.903 99.33 104.785 100.567C103.668 101.803 103.753 103.721 104.977 104.851L119.096 117.889C120.808 119.47 123.465 119.349 125.029 117.618C125.095 117.544 125.095 117.544 125.158 117.469L145.307 92.9716C146.366 91.6837 146.192 89.7716 144.918 88.7008C143.644 87.63 141.752 87.806 140.693 89.0939L121.75 112.125L109.023 100.373Z"
      fill="white"
      stroke="#1F64E7"
      strokeWidth={2.5}
      strokeLinecap="round"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110.824 64.4131C108.874 65.1447 106.969 66.0338 105.128 67.0806C102.269 68.7072 99.5648 70.7149 97.0899 73.1049C96.0754 74.0846 95.1237 75.105 94.235 76.161M91.4851 79.8378C90.7785 80.9057 90.1281 82.0004 89.5342 83.1176C89.0956 83.9428 88.6877 84.7803 88.3106 85.6285"
      fill="white"
    />
    <Path
      d="M111.263 65.5835C111.909 65.341 112.237 64.6204 111.994 63.974C111.752 63.3277 111.031 63.0003 110.385 63.2428L111.263 65.5835ZM105.128 67.0806L105.746 68.1671L105.128 67.0806ZM97.0899 73.1049L96.2216 72.2057L97.0899 73.1049ZM93.2786 75.3562C92.8341 75.8844 92.902 76.6729 93.4302 77.1174C93.9584 77.5619 94.7469 77.4941 95.1914 76.9659L93.2786 75.3562ZM92.5275 80.5277C92.9085 79.9519 92.7507 79.1764 92.1749 78.7954C91.5992 78.4144 90.8237 78.5723 90.4427 79.148L92.5275 80.5277ZM89.5342 83.1176L90.638 83.7044L90.638 83.7044L89.5342 83.1176ZM87.1684 85.1206C86.8879 85.7515 87.1719 86.4902 87.8027 86.7707C88.4335 87.0511 89.1723 86.7671 89.4528 86.1363L87.1684 85.1206ZM110.385 63.2428C108.373 63.9973 106.409 64.9143 104.51 65.994L105.746 68.1671C107.529 67.1532 109.374 66.2921 111.263 65.5835L110.385 63.2428ZM104.51 65.994C101.56 67.672 98.7724 69.7424 96.2216 72.2057L97.9582 74.0041C100.357 71.6874 102.977 69.7425 105.746 68.1671L104.51 65.994ZM96.2216 72.2057C95.176 73.2154 94.195 74.2673 93.2786 75.3562L95.1914 76.9659C96.0525 75.9427 96.9747 74.9538 97.9582 74.0041L96.2216 72.2057ZM90.4427 79.148C89.7138 80.2494 89.0431 81.3785 88.4305 82.5309L90.638 83.7044C91.2132 82.6222 91.8431 81.562 92.5275 80.5277L90.4427 79.148ZM88.4305 82.5309C87.978 83.382 87.5573 84.2459 87.1684 85.1206L89.4528 86.1363C89.818 85.3148 90.2131 84.5036 90.638 83.7044L88.4305 82.5309Z"
      fill="#75A4FE"
    />
  </Svg>
);
export default ApproveListEmpty;
