import React from 'react';
import {Pressable, View} from 'react-native';
import ModalContainer from '../../components/layout/modalContainer';
import {ThemeContext} from '../../contextAPI/themeContext';
import {CustomModalProps} from '../../types/reducerTypes/searchTabStoreType';
import {customBottomListModalStyle} from './customBottomListModal.style';
import ModalButton from '../../components/button/modalButton';
import ModalBack from '../../components/renderSvgs/modalBack';

const CustomBottomListModal = (props: CustomModalProps): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = customBottomListModalStyle(ColorTheme);
  const {customModaldata} = props;
  const cancelButtonOnPress = customModaldata?.cancelButton
    ? customModaldata?.cancelButton
    : customModaldata?.closeModal;
  return (
    <ModalContainer
      visible={customModaldata?.modalVisible}
      onPressOutside={customModaldata?.closeModal}
    >
      <View style={styles.subContainer}>
        <Pressable
          style={styles.alignEnd}
          onPress={cancelButtonOnPress}
        >
          <ModalBack />
        </Pressable>
        {customModaldata.listData?.map((item, index) => (
          <View style={styles.width100Percentage} key={index}>
            <ModalButton
              LeftIcon={item?.LeftIcon}
              label={item?.label}
              onPress={item?.onPress}
              isDelete={item?.isDelete}
              height={customModaldata?.height}
              width={customModaldata?.height}
            />
          </View>
        ))}
      </View>
    </ModalContainer>
  );
};

export default CustomBottomListModal;
