import {createSlice} from '@reduxjs/toolkit';
import {AppConst} from '../../constants';
import {RootState} from '../store';
import {ResetAllState} from './logoutslice';

const {StoreConstants} = AppConst;

export interface IAppTopSlice {
  appTopsObject: any;
}

const initialState: IAppTopSlice = {
  appTopsObject: null,
};

const AppTopSlice = createSlice({
  initialState,
  name: StoreConstants.APP_TOPS_OBJECT,
  reducers: {
    updateTopInObject: (state, action) => {
      state.appTopsObject = {...state.appTopsObject, ...action.payload};
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {updateTopInObject} = AppTopSlice.actions;
export default AppTopSlice.reducer;
export const appTopState = (state: RootState) => state.appTops;
