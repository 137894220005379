import React from 'react';
import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  ViewStyle,
} from 'react-native';

import {
  BASESTYLE,
  COLORS,
  FixedValue,
  Percentage,
  FONT_NAMES,
} from '../../constants';
import {IPrimaryButton} from '../../types/componentTypes/buttonType';
import {normalizeFont, scaleHeight} from '../../utils/responsive';

const styles = (isDisabled?: boolean) =>
  StyleSheet.create({
    mainView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_58),
      width: Percentage.PRECENTAGE_100,
      borderRadius: FixedValue.CONSTANT_VALUE_100,
    },
    labelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: isDisabled ? COLORS.BDBDBD : COLORS.WHITE,
    },
    backgroundPrimaryColor: {
      backgroundColor: COLORS.PRIMARY_BLUE,
    },
    disableBtn: {
      backgroundColor: COLORS.EAEAEA,
    },
  });

const CustomButton: React.FC<IPrimaryButton> = (props: IPrimaryButton) => {
  const {
    onPress,
    isDisabled,
    labelStyle,
    buttonLabel,
    element,
    isLoading,
    firstElement,
  } = props;
  const [apiStatus, setApiStatus] = React.useState<boolean>(isLoading || false);
  const [_isDisabled, setIsDisabled] = React.useState<boolean>(
    isDisabled || false
  );
  const style = styles(_isDisabled);

  const PressIn = React.useCallback(async (): Promise<void> => {
    if (!_isDisabled && onPress) {
      onPress();
    }
  }, [_isDisabled, onPress]);

  React.useEffect(() => {
    setApiStatus(isLoading || false);
    if (isLoading) {
      setIsDisabled(true);
    } else {
      setIsDisabled(isDisabled || false);
    }
  }, [isLoading]);

  React.useEffect(() => {
    if (isDisabled) {
      setIsDisabled(true);
    } else {
      setIsDisabled(isDisabled || false);
    }
  }, [isDisabled]);

  const buttonMainView: TextStyle = props?.buttonwidth
    ? props?.buttonwidth
    : style.mainView;

  const buttonStyle: ViewStyle = !_isDisabled
    ? {...style.backgroundPrimaryColor, ...props?.buttonBackGroundColor}
    : style.disableBtn;

  return (
    <Pressable
      onPress={PressIn}
      style={[buttonMainView, buttonStyle]}
      disabled={isLoading}
    >
      {apiStatus ? (
        <ActivityIndicator color={COLORS.PRIMARY_BLUE} size="small" />
      ) : (
        <>
          {firstElement && firstElement}
          <Text style={[style.labelstyle, labelStyle]}>{buttonLabel}</Text>
          {element && element}
        </>
      )}
    </Pressable>
  );
};

export default React.memo(CustomButton);
