import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const style = (_theme?: IColorTheme) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.flex1,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    iconView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_44),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_44),
      backgroundColor: _theme?.backgroundColor,
    },
    centerImageView: {
      ...BASESTYLE.selfCenter,
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_32),
      marginHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_25),
    },
    centerImageStyle: {
      ...BASESTYLE.center,
      ...BASESTYLE.inCenter,
      ...BASESTYLE.selfCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_88),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_88),
      borderRadius: FixedValue.CONSTANT_VALUE_65,
    },
    placeHolderBackgroundColor: {
      backgroundColor: _theme?.color,
    },
    groupProfileText: {
      textAlign: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_36),
      color: _theme?.backgroundColor,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      textTransform: GlobalStyleValues.UPPERCASE,
    },
    groupNameStyle: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      textAlign: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_24),
      color: _theme?.color,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      textTransform: GlobalStyleValues.CAPITALIZE,
    },
    userStyleView: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_24),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_72),
      backgroundColor: COLORS._E9E9F0,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      borderRadius: FixedValue.CONSTANT_VALUE_0125,
      ...BASESTYLE.selfCenter,
      justifyContent: GlobalStyleValues.CENTER,
    },
    userCountStyle: {
      textAlign: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      color: COLORS.BLACK,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      textTransform: GlobalStyleValues.CAPITALIZE,
    },
    horizontalLineStyle: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_1),
      backgroundColor: _theme?.color,
      opacity: FixedValue.CONSTANT_VALUE_01,
    },
    listImageView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_65),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    removeButtonView: {
      ...BASESTYLE.selfCenter,
      ...BASESTYLE.inRow,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_32),
      backgroundColor: COLORS.FDE4E1,
      borderRadius: FixedValue.CONSTANT_VALUE_100,
      justifyContent: GlobalStyleValues.CENTER,
    },
    ownerButtonView: {
      ...BASESTYLE.selfCenter,
      ...BASESTYLE.inRow,
      justifyContent: GlobalStyleValues.CENTER,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_32),
    },
    removeButtonWidth: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_86),
    },
    leaveGroupButtonWidth: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_105),
    },
    removeTextStyle: {
      textAlign: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      textTransform: GlobalStyleValues.CAPITALIZE,
    },
    removeTextColor: {
      color: COLORS.F54336,
    },
    ownerTextColor: {
      color: _theme?.color,
    },
    userNametext: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      color: _theme?.color,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      textTransform: GlobalStyleValues.CAPITALIZE,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_165),
    },
    placeholderNameText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      color: _theme?.backgroundColor,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      textTransform: GlobalStyleValues.UPPERCASE,
    },
    marginTop20: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    flatListContentContainerStyle: {
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_50),
    },
    buttonStyle: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.selfCenter,
      ...BASESTYLE.absolutePosition,
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_34),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_179),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_47),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      backgroundColor: 'transparent',
    },
    buttonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
    },
    mainViewStyle: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_88),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_88),
      backgroundColor: _theme?.color,
      ...BASESTYLE.inCenter,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_44),
      overflow: GlobalStyleValues.HIDDEN,
    },
    mainViewListStyle: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_48),
      backgroundColor: _theme?.color,
      ...BASESTYLE.inCenter,
      borderRadius: FixedValue.CONSTANT_VALUE_65,
      overflow: GlobalStyleValues.HIDDEN,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    topView: {
      ...BASESTYLE.alignCenter,
    },
    inRowStyle: {
      ...BASESTYLE.inRow,
    },
    spaceBetweenStyle: {
      ...BASESTYLE.spaceBetween,
    },
    columnStyle: {
      ...BASESTYLE.column,
    },
    userNameTextStyle: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_4),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS._GREY,
    },
  });
