import React from 'react';
import {Pressable, ScrollView, Text, View, FlatList} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';

import useTopCollectionHooks from '../../hooks/componentHooks/useTopCollectionListsHooks';
import {useAppSelector} from '../../hooks/reduxHooks';
import {HomeStore} from '../../redux/reducers/homeSlice';
import {HomeContext} from '../../contextAPI/homeContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import {TopCollectionStyle} from './topCollections.style';
import {BASESTYLE, FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import TopsListEmptyView from '../../components/emptyListView/topsListEmptyView';
import {
  CollectionModel,
  TopModel,
} from '../../types/reducerTypes/searchTabStoreType';
import ImageCard from '../../components/mantelTopTabComp/imageCard';
import EmptyCollection from '../../components/emptyListView/emptyCollection';
import {Percentage, SeeAll_ScreenMode, TopType} from '../../constants/enum';
import EditCollectionModal from '../../modals/EditCollectionModal';
import {CreateCollectionContext} from '../../contextAPI/createCollectionContext';
import {navigate} from '../../services/navigationService';
import {
  capitalizeFirstLetter,
  isUncategorizedOrNot,
  seeAllButtonDisabled,
  seeAllTextColor,
  _ToastHandler,
} from '../../utils/helperFunctions';
import ThreeDotsBlack from '../../components/renderSvgs/threeDots';
import DeleteCollectionModal from '../../modals/deleteCollectionModal';
import {
  ADD_TOPS_TO_COLLECTION,
  BUY_TOPS,
  DELETE_COLLECTIONS_TOPS_DATA,
  DISTRIBUTE_SEARCH,
  TOPS_MOVE_TO_COLLECTION,
} from '../../constants/actionTypes';
import {TopDetailContext} from '../../contextAPI/topDetailContext';
import TopOptionsInCollectionModal from '../../modals/collectionOptionsModal';
import MoveToCollectionsModal from '../../modals/moveToCollectionsModal';
import {useIsFocused} from '@react-navigation/native';
import WelcomeTopModal from '../../modals/welcomeTopModal';

const TopCollection = (): JSX.Element => {
  const {
    collectionTopLists,
    deleteCollectionAndTops,
    addTopsToCollection,
    buyTop,
    distributeTop,
    moveToCollection,
  } = useAppSelector(HomeStore);
  const {
    collectionsResult,
    isRefreshing,
    openCreateModal,
    openDeleteCollectionModal,
    homeScreenUserData,
    isProfileSwitchToChild,
    setIsRefreshing,
    setOpenCreateModal,
    setOpenDeleteCollectionModal,
    setSelectedTopData,
    setSeeAllScreenMode,
    clearTopsinCollection,
    setSelectedFromCollectionData,
    initialLoader,
    showWelcomeTop,
    setShowWelcomeTop,
  } = React.useContext(HomeContext);
  const {
    onReachedEnd,
    callInToCollectionListAPI,
    checkIndexIsZero,
    screenNavigateTo,
    navigateToDetailScreen,
  } = useTopCollectionHooks();
  const isFocused = useIsFocused();
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = TopCollectionStyle(ColorTheme);
  const {setCollectionData} = React.useContext(CreateCollectionContext);
  const {setOpenOptionsModal, openOptionsModal} =
    React.useContext(TopDetailContext);
  const getUncategorizedCollectionId = () =>
    collectionsResult[FixedValue.CONSTANT_VALUE_0].id;

  const navigateToSeeAll = (item: CollectionModel) => {
    setSeeAllScreenMode(SeeAll_ScreenMode.VIEW);
    const totalTopsInUncategorizedCollections =
      item.totalTopsCount && item.totalTopsCount !== FixedValue.CONSTANT_VALUE_0
        ? item.totalTopsCount
        : collectionsResult[FixedValue.CONSTANT_VALUE_0].totalTopsCount;
    navigate(ROUTE_NAME.SEE_ALL_TOPS_SCREEN, {
      collectionModel: item,
      showUncategorizedListId: getUncategorizedCollectionId(),
      totalTopsInUncategorizedCollections,
      isEmptyCollection:
        item.totalTopsCount && item.totalTopsCount > 0 ? false : true,
    });
  };
  const navigateToDetail = (mainIndex: number, parentItem: CollectionModel) => {
    setSeeAllScreenMode(SeeAll_ScreenMode.MOVE_TOPS_TO_THIS_COLLECTION);
    const totalTopsInUncategorizedCollections =
      parentItem.totalTopsCount &&
      parentItem.totalTopsCount !== FixedValue.CONSTANT_VALUE_0
        ? parentItem.totalTopsCount
        : collectionsResult[FixedValue.CONSTANT_VALUE_0].totalTopsCount;
    screenNavigateTo(
      mainIndex,
      parentItem,
      getUncategorizedCollectionId(),
      totalTopsInUncategorizedCollections ?? FixedValue.CONSTANT_VALUE_0
    );
  };
  const openCreateCollectionModal = (item: CollectionModel) => {
    setCollectionData(item);
    setOpenCreateModal();
  };
  const handleRefresh = React.useCallback(() => {
    setIsRefreshing(true);
    callInToCollectionListAPI(FixedValue.CONSTANT_VALUE_0);
  }, [homeScreenUserData]);

  //render Tops function
  const renderTops = (
    item: TopModel[],
    mainIndex: number,
    parentItem: CollectionModel
  ) => {
    if (item?.length > FixedValue.CONSTANT_VALUE_0) {
      return (
        <FlatList
          horizontal
          data={item}
          contentContainerStyle={styles.paddingLeft10}
          showsHorizontalScrollIndicator={false}
          keyExtractor={item => `${TEXT.COLLECTIONS + 'Nested'}-${item.id}`}
          renderItem={({item}) => (
            <ImageCard
              key={item?.id}
              {...item}
              type={TopType.collections}
              isProfileSwitchToChild={isProfileSwitchToChild}
              onShowDetailPress={() => {
                setSelectedTopData(item);
                setSelectedFromCollectionData(parentItem);
                navigateToDetailScreen(item, TopType.collections);
              }}
              onMenuPress={() => {
                setSelectedFromCollectionData(parentItem);
                setSelectedTopData(item);
                setOpenOptionsModal();
              }}
            />
          )}
        />
      );
    }
    return (
      <EmptyCollection
        mainText={
          checkIndexIsZero(mainIndex)
            ? TEXT.MOVE_TOPS
            : TEXT.YOU_HAVE_NO_TOPS_COLLECTION
        }
        onPressAction={() => {
          !isProfileSwitchToChild && navigateToDetail(mainIndex, parentItem);
        }}
      />
    );
  };

  React.useEffect(() => {
    if (distributeTop.status === DISTRIBUTE_SEARCH.SUCCESS) {
      clearTopsinCollection();
      callInToCollectionListAPI(FixedValue.CONSTANT_VALUE_0);
    } else if (distributeTop.status === DISTRIBUTE_SEARCH.FAILED) {
      clearTopsinCollection();
      callInToCollectionListAPI(FixedValue.CONSTANT_VALUE_0);
    }
  }, [distributeTop.status]);
  React.useEffect(() => {
    switch (addTopsToCollection.status) {
      case ADD_TOPS_TO_COLLECTION.SUCCESS:
        callInToCollectionListAPI(FixedValue.CONSTANT_VALUE_0);
        break;
      case ADD_TOPS_TO_COLLECTION.FAILED:
        break;
      default:
        break;
    }
  }, [addTopsToCollection.status]);

  React.useEffect(() => {
    switch (moveToCollection.status) {
      case TOPS_MOVE_TO_COLLECTION.SUCCESS:
        callInToCollectionListAPI(FixedValue.CONSTANT_VALUE_0);
        break;
      case TOPS_MOVE_TO_COLLECTION.FAILED:
        break;
      default:
        break;
    }
  }, [moveToCollection.status]);

  //delete collection and tops response handle
  React.useEffect(() => {
    switch (deleteCollectionAndTops.status) {
      case DELETE_COLLECTIONS_TOPS_DATA.SUCCESS:
        callInToCollectionListAPI(FixedValue.CONSTANT_VALUE_0);
        _ToastHandler(TEXT.COLLECTION_DELETED, true);
        setOpenDeleteCollectionModal();
        break;
      case DELETE_COLLECTIONS_TOPS_DATA.FAILED:
        setOpenDeleteCollectionModal();
        break;
      default:
        break;
    }
  }, [deleteCollectionAndTops.status]);

  //Buy top response handle
  React.useEffect(() => {
    switch (buyTop.status) {
      case BUY_TOPS.SUCCESS:
        callInToCollectionListAPI(FixedValue.CONSTANT_VALUE_0);
        break;
      case BUY_TOPS.FAILED:
        break;
      default:
        break;
    }
  }, [buyTop.status]);

  //render collection list function
  const renderCollectionList = (item: CollectionModel, index: number) => {
    return (
      <>
        <View style={styles.collectionTitleView}>
          <View style={[BASESTYLE.inRow, styles.paddingHorizontal20]}>
            <Text
              style={styles.collectionTitleStyle}
              numberOfLines={FixedValue.CONSTANT_VALUE_1}
            >
              {capitalizeFirstLetter(item?.name ?? '')} (
              {item.totalTopsCount ?? FixedValue.CONSTANT_VALUE_0})
            </Text>
          </View>
          <View style={BASESTYLE.inRow}>
            {!seeAllButtonDisabled(
              item?.totalTopsCount ?? FixedValue.CONSTANT_VALUE_0
            ) && (
              <Pressable
                style={styles.marginRight20}
                onPress={() => navigateToSeeAll(item)}
                disabled={seeAllButtonDisabled(
                  item?.totalTopsCount ?? FixedValue.CONSTANT_VALUE_0
                )}
              >
                <Text
                  style={
                    (styles.seeAllText,
                    {
                      color: seeAllTextColor(
                        item?.totalTopsCount ?? FixedValue.CONSTANT_VALUE_0
                      ),
                    })
                  }
                >
                  {TEXT.SEE_ALL}
                </Text>
              </Pressable>
            )}
            {!isUncategorizedOrNot(item?.name ?? '') &&
              !isProfileSwitchToChild && (
                <Pressable
                  style={styles.threeDotsView}
                  onPress={() => openCreateCollectionModal(item)}
                >
                  <ThreeDotsBlack stroke={ColorTheme.color} />
                </Pressable>
              )}
          </View>
        </View>
        {renderTops(item?.tops || [], index, item)}
      </>
    );
  };
  //show empty view when there is no data available
  const renderEmptyUI = React.useMemo((): JSX.Element | null => {
    if (collectionsResult?.length === FixedValue.CONSTANT_VALUE_0) {
      return (
        <ScrollView
          style={{height: Percentage.PRECENTAGE_100}}
          contentContainerStyle={BASESTYLE.flex1}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={handleRefresh}
            />
          }
        >
          <TopsListEmptyView
            emptyViewTitle={TEXT.YOU_HAVE_NO_COLLECTION}
            emptyViewDesc={TEXT.SUBTEXT_FOR_NO_COLLECTIONS}
            tabType={FixedValue.CONSTANT_VALUE_0}
          />
        </ScrollView>
      );
    }
    return null;
  }, [collectionsResult]);

  return (
    <>
      {!collectionTopLists?.isLoading && !initialLoader && renderEmptyUI}
      <FlatList
        data={collectionsResult}
        keyExtractor={(_, index) => `${TEXT.COLLECTIONS}-${index}`}
        onEndReached={() => !isRefreshing && onReachedEnd}
        onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
        refreshControl={
          <RefreshControl refreshing={isRefreshing} onRefresh={handleRefresh} />
        }
        contentContainerStyle={styles.paddingBottom90}
        showsVerticalScrollIndicator={false}
        renderItem={({item, index}) => renderCollectionList(item, index)}
      />
      {openCreateModal && (
        <EditCollectionModal
          showUncategorizedListId={
            collectionsResult?.[FixedValue.CONSTANT_VALUE_0]?.id
          }
          totalTopsInUncategorizedCollections={
            collectionsResult?.[FixedValue.CONSTANT_VALUE_0]?.totalTopsCount ??
            FixedValue.CONSTANT_VALUE_0
          }
        />
      )}
      {openDeleteCollectionModal && <DeleteCollectionModal />}
      {openOptionsModal && isFocused && <TopOptionsInCollectionModal />}
      {isFocused && <MoveToCollectionsModal />}
      {showWelcomeTop && (
        <WelcomeTopModal
          visible={showWelcomeTop}
          onClose={() => setShowWelcomeTop(false)}
        />
      )}
    </>
  );
};

export default React.memo(TopCollection);
