import React from 'react';
import {FlatList, ScrollView, View} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';

import EmptyChildListView from '../../components/emptyListView/emptyChildListView';
import GroupListTile from '../../components/RenderItemTile/groupListTile';
import {FixedValue, GlobalStyleValues, TEXT} from '../../constants';
import {GroupContext} from '../../contextAPI/groupManagementContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import useGroupManagementListHooks from '../../hooks/componentHooks/useGroupManagementListHooks';
import {useAppSelector} from '../../hooks/reduxHooks';
import CreateEditGroupFormModal from '../../modals/createEditGroupModal';
import {groupManagementStore} from '../../redux/reducers/groupManagementSlice';
import {groupInListStyle} from './groupInScreen.style';

const GroupInList = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = groupInListStyle(ColorTheme);
  const {
    groupInListResult,
    groupMangementPullDownRefresh,
    setGroupMangementPullDownRefresh,
  } = React.useContext(GroupContext);
  const {groupInList} = useAppSelector(groupManagementStore);
  const {onGroupInListReachedEnd, callGroupInListAPI} =
    useGroupManagementListHooks();

  const handleRefresh = React.useCallback(() => {
    setGroupMangementPullDownRefresh(true);
    callGroupInListAPI(FixedValue.CONSTANT_VALUE_0);
  }, [groupMangementPullDownRefresh]);

  //show empty view when there is no data available
  const renderGroupInEmptyUI = React.useMemo((): JSX.Element | null => {
    if (groupInListResult?.length === FixedValue.CONSTANT_VALUE_0) {
      return (
        <ScrollView
          contentContainerStyle={styles.scrollviewStyle}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={groupMangementPullDownRefresh}
              onRefresh={handleRefresh}
            />
          }
        >
          <View style={styles.emptyView}>
            <EmptyChildListView emptyViewTitle={TEXT.SORRY_NO_GROUP_IN} />
          </View>
        </ScrollView>
      );
    }
    return null;
  }, [groupInListResult, groupMangementPullDownRefresh]);

  const renderMyGroupFlatList = React.useMemo((): JSX.Element | null => {
    return (
      <FlatList
        keyExtractor={(_, index) => `${TEXT.GROUP_LIST}-${index}`}
        keyboardShouldPersistTaps={GlobalStyleValues.HANDLED}
        data={groupInListResult}
        contentContainerStyle={styles.flatListContentContainerStyle}
        onEndReached={() => onGroupInListReachedEnd()}
        onEndReachedThreshold={FixedValue.CONSTANT_VALUE_1}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={groupMangementPullDownRefresh}
            onRefresh={handleRefresh}
          />
        }
        scrollEnabled
        renderItem={({item}) => (
          <GroupListTile
            key={item.id}
            item={item}
            isComingFromMyGroup={false}
          />
        )}
      />
    );
  }, [groupInListResult, groupMangementPullDownRefresh]);

  return (
    <>
      {!groupInList?.isLoading && renderGroupInEmptyUI}
      {groupInListResult.length > FixedValue.CONSTANT_VALUE_0 && (
        <View style={styles.mainList}>{renderMyGroupFlatList}</View>
      )}
      <CreateEditGroupFormModal />
    </>
  );
};
export default React.memo(GroupInList);
