import React from 'react';
import {View} from 'react-native';

import {ThemeContext} from '../../../contextAPI/themeContext';
import SafeAreaContainer from '../../../components/layout/safeAreaContainer';
import {TEXT} from '../../../constants';
import {TopBuyingSettingStyle} from './setting.style';
import TopBuyingScreen from './settingContent';
import TitleWithButton from '../../../components/header/titleWithButton';

const TopBuyingSetting = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = TopBuyingSettingStyle(ColorTheme);
  return (
    <SafeAreaContainer>
      <View
        style={styles.settingMainContainer}
        dataSet={{media: ids.settingMainContainer}}
      >
        <TitleWithButton title={TEXT.SETTING} />
        <TopBuyingScreen />
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(TopBuyingSetting);
