import React from 'react';
import {ImageBackground, Text, View} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {RequestSentStyle} from './style';
import Done from '../../components/renderSvgs/done';
import BACKGROUND from '../../components/renderSvgs/background';
import CustomButton from '../../components/button/customButton';
import {BASESTYLE, FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import {scaleHeight} from '../../utils/responsive';
import {navigate} from '../../services/navigationService';
import { RouteProp, useRoute } from '@react-navigation/native';
import { RootStackParamList } from '../../types/navigationType';

const RequestSent = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = RequestSentStyle(ColorTheme);
  const route = useRoute<RouteProp<RootStackParamList, 'RequestSent'>>();
  const isCounter = route?.params?.isCounter;

  return (
    <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
      <ImageBackground style={styles.mainContainer}>
        <BACKGROUND />
        <View style={styles.requestView}>
          <Done />
          <Text style={styles.request}>{isCounter ? TEXT.COUNTER_OFFER_SENT : TEXT.REQUEST_SENT}</Text>
          <Text style={styles.wait}>{TEXT.PLEASE_WAIT}</Text>
          <View
            style={[
              BASESTYLE.inRow,
              BASESTYLE.inCenter,
              {marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_34)},
            ]}
          >
            <CustomButton
              buttonLabel={TEXT.GO_TO_TRADE}
              buttonwidth={styles.button}
              labelStyle={styles.yesButtonLabelstyle}
              onPress={() => navigate(ROUTE_NAME.TRADE_TAB)}
            />
          </View>
        </View>
      </ImageBackground>
    </View>
  );
};
export default React.memo(RequestSent);
