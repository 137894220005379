import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
const Invite = (props: SvgProps) => (
  <Svg
    width={24}
    height={19}
    viewBox="0 0 24 19"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M23.7755 7.33159L14.7786 0.134001C14.6904 0.0624932 14.5838 0.0174639 14.4711 0.00414446C14.3584 -0.00917496 14.2442 0.00976228 14.1418 0.0587562C14.0394 0.10775 13.953 0.184789 13.8927 0.280924C13.8323 0.377058 13.8005 0.488341 13.8009 0.601844V4.20064C10.4406 4.35238 7.23956 5.67607 4.7535 7.9419C2.26744 10.2077 0.653346 13.2726 0.191457 16.6045L0.00551968 17.912C-0.0124463 18.0436 0.0137261 18.1773 0.0799362 18.2924C0.146146 18.4074 0.248654 18.4973 0.371397 18.5478C0.444399 18.5823 0.524626 18.5988 0.605318 18.5958C0.698434 18.5958 0.790271 18.5741 0.873557 18.5325C0.956842 18.4908 1.02929 18.4304 1.08516 18.3559L1.44504 17.876C2.89638 15.9497 4.75794 14.3702 6.89493 13.2519C9.03191 12.1336 11.3908 11.5045 13.8009 11.4102V15.009C13.8005 15.1225 13.8323 15.2338 13.8927 15.3299C13.953 15.4261 14.0394 15.5031 14.1418 15.5521C14.2442 15.6011 14.3584 15.62 14.4711 15.6067C14.5838 15.5934 14.6904 15.5484 14.7786 15.4769L23.7755 8.27927C23.8456 8.22307 23.9021 8.15185 23.941 8.07088C23.9798 7.9899 24 7.90124 24 7.81143C24 7.72161 23.9798 7.63295 23.941 7.55197C23.9021 7.471 23.8456 7.39978 23.7755 7.34358V7.33159Z"
      fill={props?.fill ?? '#BCCBF2'}
    />
  </Svg>
);
export default Invite;
