import React from 'react';
import {View} from 'react-native';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {FixedValue, ROUTE_NAME, TEXT} from '../../../constants';
import {TopTradinglStyle} from './topTrading.style';
import {ISingleTabProps} from '../../../types/componentTypes/layoutType';
import TopTabBarLayout from '../../../components/layout/topTabBarLayout';
import topTradingReceived from '../../topTradingApprovel/topTradingReceived/topReceivedIndex';
import topTradingSent from '../../topTradingApprovel/topTradingSent/topSentIndex';
import topTradingApprovedList from '../../topTradingApprovel/topTradingApprovedList/approvelIndex';
import {useIsFocused} from '@react-navigation/native';
import useTradingApprovalHooks from '../../../hooks/componentHooks/useTradingApprovalHooks';
import {SENT_OR_RECEIVED, USER_LIST_TYPE} from '../../../constants/enum';
import {ChildProfileContext} from '../../../contextAPI/childProfileContext';
import AppLoader from '../../../components/smallDesigns/appLoader';
import {TOP_TRADE_STATUS_UPDATE} from '../../../constants/actionTypes';
import {TradeTabStore} from '../../../redux/reducers/tradeTabSlice';
import {useAppSelector} from '../../../hooks/reduxHooks';

const TopTradingScreen = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = TopTradinglStyle(ColorTheme);
  const isFocused = useIsFocused();
  const {callTradingApproveListAPI, getTradeReceviedOrSentList} =
    useTradingApprovalHooks();
  const {
    initialLoader,
    setInitialLoader,
    setSearchTypeForTradeAndDistribute,
    setSelectedTradeReceivedData,
  } = React.useContext(ChildProfileContext);
  const {topTradeStatusUpdate} = useAppSelector(TradeTabStore);

  React.useEffect((): void => {
    if (isFocused) {
      setInitialLoader(true);
      getTradeReceviedOrSentList(
        SENT_OR_RECEIVED.RECEIVED,
        FixedValue.CONSTANT_VALUE_0
      );
      getTradeReceviedOrSentList(
        SENT_OR_RECEIVED.SENT,
        FixedValue.CONSTANT_VALUE_0
      );
      callTradingApproveListAPI(FixedValue.CONSTANT_VALUE_0);
      setSearchTypeForTradeAndDistribute(USER_LIST_TYPE.OFFER);
    }
  }, [isFocused]);

  React.useEffect(() => {
    switch (topTradeStatusUpdate.status) {
      case TOP_TRADE_STATUS_UPDATE.SUCCESS:
        getTradeReceviedOrSentList(
          SENT_OR_RECEIVED.RECEIVED,
          FixedValue.CONSTANT_VALUE_0
        );
        setSelectedTradeReceivedData(null);
        getTradeReceviedOrSentList(
          SENT_OR_RECEIVED.SENT,
          FixedValue.CONSTANT_VALUE_0
        );
        break;
      default:
        break;
    }
  }, [topTradeStatusUpdate.status]);

  const tabBarList: ISingleTabProps[] = [
    {
      routeName: ROUTE_NAME.TOP_RECEIVED,
      component: topTradingReceived,
      tabBarName: TEXT.TOP_RECEIVED,
    },
    {
      routeName: ROUTE_NAME.TOP_SENT,
      component: topTradingSent,
      tabBarName: TEXT.TOP_SENT,
    },
    {
      routeName: ROUTE_NAME.TOP_APPROVED_LIST,
      component: topTradingApprovedList,
      tabBarName: TEXT.TOP_APPROVED_LIST,
    },
  ];

  return (
    <View style={styles.topTradingContainer}>
      {initialLoader && <AppLoader />}
      <TopTabBarLayout
        tabArray={tabBarList}
        setCurrentActiveTab={FixedValue.CONSTANT_VALUE_0}
      />
    </View>
  );
};

export default React.memo(TopTradingScreen);
