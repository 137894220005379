import React from 'react';
import {View, Text, ActivityIndicator, Pressable} from 'react-native';
import {BlurView} from 'expo-blur';

import {COLORS, FixedValue, TEXT} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {TopModel} from '../../types/reducerTypes/searchTabStoreType';
import ThreeDots from '../renderSvgs/threeDots';
import {manteltabCompStyle} from './manteltabComp.style';
import {TopType} from '../../constants/enum';
import useAwsS3 from '../../hooks/libraryHooks/useAwsS3';
import SelectCheckBox from '../renderSvgs/selectCheckbox';
import UncheckSelectBox from '../renderSvgs/uncheckSelectBox';
import CircularView from '../CircularView';
import QunatitySelector from '../smallDesigns/qunatitySelector';
import {getTopImage} from '../../utils/imageUtils';

const ImageCard: React.FC<TopModel> = props => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = manteltabCompStyle(ColorTheme);
  const {loadSignedUrl} = useAwsS3();
  const {
    onMenuPress,
    name,
    type,
    image,
    onBuyPressed,
    showSelectIcon,
    onSelectOrUnselectTops,
    isSelected,
    onShowDetailPress,
    quantity,
    isProfileSwitchToChild,
    selectedQuantity,
    onUpdateQuantityTap,
    isInputQuantity,
    onChangeQuantity,
    pendingRequest,
  } = props;
  const [svgCode, setSvgCode] = React.useState<JSX.Element | undefined>(
    undefined
  );
  //list type means show collection type, inprogress type or purchased type
  const listType = type === TopType.inProgress;
  const showSecondaryCircle = !listType && type === TopType.collections;
  // Load the SVG image using the editor service
  const getSvgUrl = React.useCallback(async (): Promise<void> => {
    const imageUrl = await loadSignedUrl(image ?? '');
    if (imageUrl) {
      const topImage = await getTopImage(imageUrl ?? '');
      setSvgCode(topImage);
    }
  }, [image, svgCode]);

  React.useEffect(() => {
    getSvgUrl();
  }, [image]);

  const onPressCommonFunction = React.useCallback(
    (callingFunction: any): void => {
      if (!isProfileSwitchToChild && onMenuPress !== undefined) {
        callingFunction();
      }
    },
    []
  );

  const getOptions = () => {
    if (type === TopType.tradeOffer) {
      return <></>;
    } else if (!showSelectIcon && !isProfileSwitchToChild) {
      return (
        <Pressable
          style={[styles.menuOrChecbox, styles.commonStyle]}
          onPress={() => onPressCommonFunction(onMenuPress)}
        >
          <ThreeDots stroke={COLORS.WHITE} />
        </Pressable>
      );
    } else if (!isProfileSwitchToChild) {
      return (
        <Pressable
          onPress={onSelectOrUnselectTops}
          style={[styles.selectOrUnselectView]}
        >
          {isSelected ? <SelectCheckBox /> : <UncheckSelectBox />}
        </Pressable>
      );
    } else {
      return null;
    }
  };
  return (
    <Pressable style={styles.cardContainer} onPress={onShowDetailPress}>
      <View style={styles.gradient} />
      {/* Wrap the SVG in a fixed size container with hidden overflow */}
      {svgCode ? (
        <View style={styles.imageContainer}>{svgCode}</View>
      ) : (
        <View style={styles.imageLoader}>
          <ActivityIndicator size={'small'} color={COLORS.PRIMARY_BLUE} />
        </View>
      )}
      {getOptions()}
      {!listType && (
        <View style={styles.bottomCardContainer}>
          <BlurView style={styles.blurViewStyle} />
          <Text
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
            style={styles.topName}
          >
            {name}
          </Text>
        </View>
      )}
      {listType && !isProfileSwitchToChild && (
        <View style={styles.addToCartView}>
          {!isSelected ? (
            <Pressable
              style={styles.buyNowButton}
              onPress={() => onPressCommonFunction(onBuyPressed)}
            >
              <Text style={styles.buyButtonText}>{TEXT.ADD_TO_CART}</Text>
            </Pressable>
          ) : (
            <QunatitySelector
              count={selectedQuantity ?? 1}
              qunatityTextStyle={styles.quantityTextStyle}
              quantityStyle={styles.quantityViewStyle}
              plusStyle={styles.plusButtonStyle}
              minusStyle={styles.minuButtonStyle}
              plusTextStyle={styles.plusTextStyle}
              onMinusPress={() =>
                onUpdateQuantityTap && onUpdateQuantityTap(TEXT.MINNUS)
              }
              onPlusPress={() =>
                onUpdateQuantityTap && onUpdateQuantityTap(TEXT.ADD)
              }
              isInputQuantity={isInputQuantity}
              onChangeQuantity={onChangeQuantity}
            />
          )}
        </View>
      )}
      {!listType && (
        <CircularView
          quantity={quantity?.toString() ?? ''}
          showSecondaryCircle={showSecondaryCircle}
          totalPendingRequests={
            pendingRequest?.totalPendingRequests ?? FixedValue.CONSTANT_VALUE_0
          }
        />
      )}
    </Pressable>
  );
};

export default ImageCard;
