import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';
const unSelectCheckboxDisabled = (props: SvgProps) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      opacity={0.2}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4ZM6 2C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V6C22 3.79086 20.2091 2 18 2H6Z"
      fill="#CCCCCC"
    />
  </Svg>
);
export default unSelectCheckboxDisabled;
