import React from 'react';
import {View} from 'react-native';
import {ThemeContext} from '../../../contextAPI/themeContext';
import SafeAreaContainer from '../../../components/layout/safeAreaContainer';
import {DistributingTopStyle} from '../../distributingTopRequest/distributingTop.style';
import TopBarWithRightButton from '../../../components/header/topBarWithRightButton';
import {TEXT} from '../../../constants';
import ChildAllPermissionContentScreen from './ChildAllPermissionContentScreen';

const ChildAllPermissionScreen = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = DistributingTopStyle(ColorTheme);

  return (
    <SafeAreaContainer>
      <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
        <TopBarWithRightButton
          headerLabel={TEXT.SETTING}
          headerColor={ColorTheme.color}
        />
        <ChildAllPermissionContentScreen />
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(ChildAllPermissionScreen);
