import {StyleSheet} from 'react-native';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  GlobalStyleValues,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {scaleHeight, scaleWidth} from '../../utils/responsive';

export const style = (theme?: IColorTheme) =>
  StyleSheet.create({
    imageMainContainer: {
      paddingRight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_65),
    },
    quantityView: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.absolutePosition,
      top: scaleHeight(FixedValue.CONSTANT_VALUE_MIN_2),
      zIndex: FixedValue.CONSTANT_VALUE_2,
      right: scaleWidth(FixedValue.CONSTANT_VALUE_MIN_5),
    },
    progressLoader: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_65),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_65),
      ...BASESTYLE.center,
      ...BASESTYLE.alignCenter,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      backgroundColor: COLORS.E9EEFB,
      overflow: GlobalStyleValues.HIDDEN,
    },
    topImageContainer: {
      backgroundColor: COLORS.E9EEFB,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.center,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_65),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_65),
      overflow: GlobalStyleValues.HIDDEN,
    },
  });
