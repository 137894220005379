import {StyleSheet} from 'react-native';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../../constants';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const styles = (theme: IColorTheme) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.row,
      ...BASESTYLE.spaceBetween,
      width: Percentage.PRECENTAGE_100,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    titleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: theme.color,
      marginTop: FixedValue.CONSTANT_VALUE_10,
    },
    subTitleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: theme.color,
      marginTop: FixedValue.CONSTANT_VALUE_3,
    },
    rightTitleWidth: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    imageView: {
      ...BASESTYLE.alignCenter,
    },
    blackButton: {
      marginTop: FixedValue.CONSTANT_VALUE_10,
    },
    mainViewStyle: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_100),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_100),
      ...BASESTYLE.inCenter,
      backgroundColor: theme?.color,
      borderRadius: FixedValue.CONSTANT_VALUE_16,
      overflow: GlobalStyleValues.HIDDEN,
    },
    initialsTextStyle: {
      color: theme?.backgroundColor,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_48),
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    heartContainer: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_46),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_46),
      backgroundColor: COLORS.E9EEFB,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_46),
      justifyContent: GlobalStyleValues.CENTER,
      alignItems: GlobalStyleValues.CENTER,
    },
  });
