import StyleSheet from 'react-native-media-query';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';
import { IColorTheme } from '../../types/contextTypes/themeContext';
import {
  FONT_NAMES,
  COLORS,
  FixedValue,
  Percentage,
  BASESTYLE,
} from '../../constants';

export const verifyEmailStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      paddingHorizontal: isWebsite()
        ? 0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_12),
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    titleText: {
      color: theme.color,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_30),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_32),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_BOLD,
    },
    descText: {
      color: theme.color,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      marginBottom: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    emailText: {
      color: COLORS.PRIMARY_BLUE,
    },
    buttonStyle: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_40),
    },
    resentCode: {
      color: COLORS.PRIMARY_BLUE,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      ...BASESTYLE.selfCenter,
    },
  });
