import {takeLatest, put, call, select, delay} from 'redux-saga/effects';
import {AppConst} from '../../constants';
import {IActionType} from '../../types/constantFilesTypes';
import {
  _createNewTop,
  _uploadUserProfile,
  _userProfileUpdate,
  updateUserAttributes,
} from '../../utils/apis';
import {authState} from '../reducers/authSlice';
import {
  onUpdateUserEnd,
  onUpdateUserFailed,
  onUpdateUserSuccess,
  onUploadProfileImageEnd,
  onUploadProfileImageFailed,
  onUploadProfileImageSuccess,
} from '../reducers/updateProfileSlice';
import {
  IUpdateProfileResponse,
  IUploadProfileResponse,
} from '../../types/reducerTypes/userProfileTypes';
import {isWebsite} from '../../utils/responsive';
import {_ToastHandler} from '../../utils/helperFunctions';

const {StoreConstants} = AppConst;
function* userProfileUpdate(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUpdateProfileResponse = yield call(
      _userProfileUpdate,
      action.payload,
      userTokens
    );
    yield call(updateUserAttributes, action.payload);
    yield put(onUpdateUserSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onUpdateUserFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onUpdateUserEnd());
  }
}

function* uploadProfileImage(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUploadProfileResponse = yield call(
      _uploadUserProfile,
      action.payload,
      userTokens
    );
    yield put(onUploadProfileImageSuccess(data.data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onUploadProfileImageFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onUploadProfileImageEnd());
  }
}

export default function* updateProfileSaga() {
  yield takeLatest(
    `${StoreConstants.UPDATE_PROFILE}/onUpdateUserStart`,
    userProfileUpdate
  );
  yield takeLatest(
    `${StoreConstants.UPDATE_PROFILE}/onUploadProfileImageStart`,
    uploadProfileImage
  );
}
