import React from 'react';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import {COLORS} from '../../constants';
import MyWalletContent from './myWalletContent';

const MyWalletBalance = (): JSX.Element => {
  return (
    <SafeAreaContainer backgroundColor={COLORS._DAE6FC}>
      <MyWalletContent />
    </SafeAreaContainer>
  );
};

export default React.memo(MyWalletBalance);
