import React, {useEffect, useState} from 'react';
import {
  IFavoriteContextType,
  IFavoriteProvider,
} from '../types/contextTypes/favoriteContexttype';
import {FAVORITE_USER_LIST_ACTION_API} from '../constants/actionTypes';
import {FixedValue} from '../constants';
import {userProfileStore} from '../redux/reducers/userProfileSlice';
import {useAppSelector} from '../hooks/reduxHooks';
import {CustomUserListSearchModalListData} from '../types/reducerTypes/searchTabStoreType';
import {HomeContext} from './homeContext';

const FavoriteContext = React.createContext<IFavoriteContextType>({
  favoriteListData: [],
  setFavoriteListData: _val => {},
  favoriteListLoader: false,
  setFavoriteListLoader: _val => {},
  favoriteListPullDownRefresh: false,
  setFavoriteListPullDownRefresh: _val => {},
  remainingTopForFavourite: null,
  setRemainingTopForFavourite: _val => {},
  selectedUsersFavouriteListSearch: [],
  setSelectedUsersFavouriteListSearch: _val => {},
});

const FavoriteProvider = (props: IFavoriteProvider): JSX.Element => {
  const {favoriteUserListData} = useAppSelector(userProfileStore);
  const [favoriteListPullDownRefresh, setFavoriteListPullDownRefresh] =
    React.useState<boolean>(false);
  const [favoriteListData, setFavoriteListData] = useState<
    CustomUserListSearchModalListData[] | []
  >([]);
  const [favoriteListLoader, setFavoriteListLoader] =
    React.useState<boolean>(false);
  const [remainingTopForFavourite, setRemainingTopForFavourite] =
    React.useState<number>(FixedValue.CONSTANT_VALUE_0);
  const [
    selectedUsersFavouriteListSearch,
    setSelectedUsersFavouriteListSearch,
  ] = React.useState<CustomUserListSearchModalListData[]>([]);
  const {
    selectedTopData,
    selectedUsersListSearch,
    setRemainingTop,
    setSelectedUsersListSearch,
  } = React.useContext(HomeContext);
  const _setFavoriteListData = React.useCallback(
    (val: CustomUserListSearchModalListData[]): void => {
      setFavoriteListData(val);
    },
    [favoriteListData]
  );
  //favorite user list api response handle
  useEffect(() => {
    switch (favoriteUserListData.status) {
      case FAVORITE_USER_LIST_ACTION_API.SUCCESS:
        const updatedListData =
          favoriteUserListData?.success?.data?.results?.map(item1 => {
            const matchingItem2 = selectedUsersListSearch?.find(
              item2 => item2?.id === item1?.id
            );
            return matchingItem2
              ? {...item1, ...matchingItem2, alreadyPresentUser: true}
              : {
                  ...item1,
                  quantity: FixedValue.CONSTANT_VALUE_0,
                  alreadyPresentUser: false,
                };
          });
        if (
          favoriteUserListData?.success?.pageNumber ===
          FixedValue.CONSTANT_VALUE_0
        ) {
          setFavoriteListData([...(updatedListData ?? [])]);
        } else {
          setFavoriteListData(prev => [...prev, ...(updatedListData ?? [])]);
        }
        setFavoriteListLoader(false);
        setFavoriteListPullDownRefresh(false);
        break;
      case FAVORITE_USER_LIST_ACTION_API.FAILED:
        setFavoriteListLoader(false);
        setFavoriteListPullDownRefresh(false);
        break;
    }
  }, [favoriteUserListData?.status]);

  const _setFavoriteListLoader = React.useCallback(
    (val: boolean): void => {
      setFavoriteListLoader(val);
    },
    [favoriteListLoader]
  );

  const _setFavoriteListPullDownRefresh = React.useCallback(
    (val: boolean): void => {
      setFavoriteListPullDownRefresh(val);
    },
    [favoriteListPullDownRefresh]
  );

  const _setRemainingTopForFavourite = React.useCallback(
    (value: number): void => {
      setRemainingTopForFavourite(value);
    },
    [remainingTopForFavourite]
  );

  const _selectOrUnSelectFavouriteUser = React.useCallback(
    (
      data:
        | CustomUserListSearchModalListData
        | CustomUserListSearchModalListData[]
    ): void => {
      if (!(data as CustomUserListSearchModalListData).id) {
        setSelectedUsersFavouriteListSearch([]);
        return;
      }
      const userData = data as CustomUserListSearchModalListData;
      setFavoriteListData(prev => {
        return prev.map(item => {
          if (item.id === userData?.id) {
            return {
              ...item,
              alreadyPresentUser: !userData.alreadyPresentUser,
              quantity: !userData.alreadyPresentUser
                ? userData.quantity
                : FixedValue.CONSTANT_VALUE_0,
            };
          }
          return item;
        });
      });

      let newSelectedList: CustomUserListSearchModalListData[] = [];
      // Check if the User is already selected
      const index = selectedUsersListSearch.findIndex(
        selected => selected?.id === userData?.id
      );
      if (
        index !== FixedValue.CONSTANT_VALUE_MIN_1 &&
        userData.isCounter === false
      ) {
        // User is already selected, remove it from the selected items array
        const newSelectedItems = [...selectedUsersListSearch];
        newSelectedItems.splice(index, FixedValue.CONSTANT_VALUE_1);
        setSelectedUsersListSearch(newSelectedItems);
        newSelectedList = newSelectedItems;
      } else {
        // User is not selected, add it to the selected items array
        if (userData.isCounter) {
          const newSelectedItems = [...selectedUsersListSearch];
          newSelectedItems[index].alreadyPresentUser =
            !userData.alreadyPresentUser;
          newSelectedItems[index].quantity = userData.quantity;
          setSelectedUsersListSearch(newSelectedItems);
          newSelectedList = newSelectedItems;
        } else {
          let item = {...userData};
          item.alreadyPresentUser = !item.alreadyPresentUser;
          setSelectedUsersListSearch([...selectedUsersListSearch, item]);
          newSelectedList = [...selectedUsersListSearch, item];
        }
      }

      // set remaining Top count
      const totalValue = newSelectedList.reduce(
        (total, obj) => obj.quantity! + total,
        FixedValue.CONSTANT_VALUE_0
      );
      const count = selectedTopData?.quantity! - totalValue;
      setRemainingTop(count);
    },
    [
      selectedUsersListSearch,
      selectedTopData,
      remainingTopForFavourite,
      favoriteListData,
    ]
  );

  // Memoize the context values using useMemo
  const values = React.useMemo(
    (): IFavoriteContextType => ({
      favoriteListData,
      setFavoriteListData: _setFavoriteListData,
      favoriteListLoader,
      setFavoriteListLoader: _setFavoriteListLoader,
      favoriteListPullDownRefresh,
      setFavoriteListPullDownRefresh: _setFavoriteListPullDownRefresh,
      remainingTopForFavourite,
      setRemainingTopForFavourite: _setRemainingTopForFavourite,
      selectedUsersFavouriteListSearch,
      setSelectedUsersFavouriteListSearch: _selectOrUnSelectFavouriteUser,
    }),
    [
      favoriteListData,
      setFavoriteListData,
      favoriteListLoader,
      setFavoriteListLoader,
      favoriteListPullDownRefresh,
      setFavoriteListPullDownRefresh,
      remainingTopForFavourite,
      setRemainingTopForFavourite,
      selectedUsersFavouriteListSearch,
      setSelectedUsersFavouriteListSearch,
    ]
  );

  return (
    <FavoriteContext.Provider value={values}>
      {props.children}
    </FavoriteContext.Provider>
  );
};

export {FavoriteContext, FavoriteProvider};
