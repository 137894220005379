import {takeLatest, put, call, delay} from 'redux-saga/effects';
import {AppConst} from '../../constants';
import {IActionType} from '../../types/constantFilesTypes';
import {IResendResponse} from '../../types/reducerTypes/authStoreTypes';
import {otpVerify, resendCode} from '../../utils/apis';
import {
  onResendCodeEnd,
  onResendCodeFailed,
  onResendCodeSuccess,
  onVerifyOtpEnd,
  onVerifyOtpFailed,
  onVerifyOtpSuccess,
} from '../reducers/verifyOtp';
import {isWebsite} from '../../utils/responsive';

const {StoreConstants} = AppConst;

function* verifyOtp(action: IActionType) {
  try {
    const data: string = yield call(otpVerify, action.payload);
    yield put(onVerifyOtpSuccess(data));
  } catch (error: any) {
    yield put(onVerifyOtpFailed(error.message));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onVerifyOtpEnd());
  }
}

function* resendVerificationCode(action: IActionType) {
  try {
    const data: IResendResponse = yield call(resendCode, action.payload);
    yield put(onResendCodeSuccess(data));
  } catch (error: any) {
    yield put(onResendCodeFailed(error.message));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onResendCodeEnd());
  }
}

export default function* verifyOtpSaga() {
  yield takeLatest(
    `${StoreConstants.VERIFY_OTP_STORE}/onVerifyOtpStart`,
    verifyOtp
  );
  yield takeLatest(
    `${StoreConstants.VERIFY_OTP_STORE}/onResendCodeStart`,
    resendVerificationCode
  );
}
