import {API_URL} from '../constants';
import {apiCall} from './axiosHelper';
import {Auth} from 'aws-amplify';

import {LoginFields} from '../hooks/componentHooks/useLoginHooks';
import {verifyOtpFields} from '../screens/verifyEmail/verifyEmailContentView';
import {
  ICreatePasswordFields,
  IResendRequest,
  IChangePasswordFields,
} from '../types/componentTypes/countryType';
import {ICreateNewTop} from '../types/reducerTypes/editorStoreType';
import {
  IAuthSignUp,
  IUserToken,
  IRegisterRequest,
} from '../types/reducerTypes/authStoreTypes';
import {
  BuyPlanParams,
  CartCheckoutRequestPayload,
  CounterOfferPayload,
  CreateTradeOfferPayload,
  DistributeTopPayload,
  EmailMobileType,
  IBuyTopParams,
  IChildProfileParams,
  ICollectionParams,
  IDeleteCollectionTopParams,
  IDistributeViaCSV,
  IDistributeViaEmailParams,
  IGroupInviationApproveRejectParams,
  IGroupInvitationsListParams,
  IGroupUserListParams,
  IGroupUserListSearchParams,
  IInProgressTopParams,
  IMyGroupListParams,
  IReedemCodeParams,
  IRemoveUserFromGroupParams,
  ISearchTopParams,
  IShowToOnRecentParams,
  ITradableParams,
  IUserListParams,
  IUserListSearchParams,
  RequestDataForParentRequest,
} from '../types/reducerTypes/searchTabStoreType';
import {FixedValue, METHODS, timeformat} from '../constants/enum';
import {
  ICreateCollectionType,
  MoveToCollectionRequestPayload,
} from '../types/reducerTypes/collectionStoreTypes';
import {UsernameFields} from '../hooks/componentHooks/useSignUpHooks';
import {
  IApproveRejectParams,
  IChildApproveListParams,
  ITopBuyApprovalParams,
  ITopImageUploadParams,
  ITopCreditsRequestParams,
  IUnblockUserParams,
} from '../types/reducerTypes/childApproveRequestStoreTypes';
import {
  ICreateGroupParams,
  IUserAddOnGroupParams,
  UpdateGroupParams,
} from '../types/reducerTypes/groupMangementTypes';
import {
  IProfileImagePayload,
  IUserProfileUpdateParams,
} from '../types/reducerTypes/userProfileTypes';
import {
  DistributeDetialParams,
  IOfferAPIParams,
  ITradeTabParams,
  TradableAcceptReject,
  ReportFormState,
} from '../types/reducerTypes/tradeTabTypes';
import {
  IChildGroupSettingApproval,
  IChildSentTradeApprovalParams,
  IChildSettingApproval,
  IChildTradableSetting,
  IDistributeRequestParams,
  IGroupInvitationChildParams,
  ITradeApprovalAdd,
  IUserApproveListApiParams,
  IUserRemoveFromApproveListApiParams,
} from '../types/reducerTypes/childProfileTypes';
import {
  IGetNotificationType,
  IMarkAndUnmarkFavorite,
  INotesAddParams,
  IPushTokenUpdateParams,
  ISendReminderParams,
  ITrackingCancelParams,
  ITransferTopCredit,
  ITransferTopCreditPullBack,
  IUserId,
} from '../types/reducerTypes/notificationTypes';
import {ISaveSetting} from '../hooks/componentHooks/useTopBuyingSetting';
import {Platform} from 'react-native';
import moment from 'moment-timezone';

export const userLogin = (params: LoginFields) =>
  Auth.signIn(params.Username.trim(), params.Password);

export const userSignUp = (params: IAuthSignUp) => {
  const {values, selectedCountry, userAge, havePersonalEmail} = params;
  return Auth.signUp({
    username: values.Username.trim(),
    password: values.Password,
    attributes: {
      email:
        (havePersonalEmail &&
          values?.Email &&
          userAge <= FixedValue.CONSTANT_VALUE_12) ||
        (values?.Email && userAge > FixedValue.CONSTANT_VALUE_12)
          ? values?.Email.trim()
          : values?.ParentEmail.trim(),
      given_name: values.Firstname.trim(),
      family_name: values.Lastname.trim(),
      phone_number: `${selectedCountry.dial_code}${values.MobileNumber}`,
      birthdate:
        Platform.OS === 'web'
          ? moment(values.DateOfBirth).format(timeformat.YYYY_MM_DD)
          : values.DateOfBirth,
      preferred_username: values.Username.trim(),
    },
  });
};

export const checkUserAvailibility = async (params: UsernameFields) => {
  return await apiCall(API_URL.USER_AVAILABILITY, null, params, null);
};

export const otpVerify = (params: verifyOtpFields) => {
  if (params.isVerifyMobile) {
    return Auth.verifyCurrentUserAttributeSubmit('phone_number', params?.Code);
  } else if (params.isVerifyEmail) {
    return Auth.verifyCurrentUserAttributeSubmit('email', params?.Code);
  } else {
    return Auth.confirmSignUp(params?.Username.trim(), params?.Code);
  }
};

export const parentInfo = (params: UsernameFields) =>
  apiCall(API_URL.PARENT_INFO, null, params, null);

export const createNewPassword = (params: ICreatePasswordFields) =>
  Auth.forgotPasswordSubmit(params.Email, params.Code, params.Password);

export const resendCode = (params: IResendRequest) => {
  if (params?.isVerifyMobile) {
    return Auth.verifyCurrentUserAttribute('phone_number');
  } else if (params?.isVerifyEmail) {
    return Auth.verifyCurrentUserAttribute('email');
  } else {
    return Auth.resendSignUp(params?.Username.trim());
  }
};

export const forgotPasswordInit = (params: IResendRequest) =>
  Auth.forgotPassword(params?.Username.trim());

export const _createNewTop = (data: ICreateNewTop, userInfo: IUserToken) =>
  apiCall(API_URL.CREATE_NEW_TOP, data, null, userInfo);

export const _updateExistingTop = (
  data: ICreateNewTop,
  id: string,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.CREATE_NEW_TOP};
  apiInfo.name = apiInfo.name + `/${id}`;
  apiInfo.type = METHODS.PUT;
  return apiCall(apiInfo, data, null, userInfo);
};

export const _recentSearchTopList = (userInfo: IUserToken) => {
  const apiInfo = {...API_URL.CREATE_NEW_TOP};
  apiInfo.name = apiInfo.name + '/recent-search';
  apiInfo.type = METHODS.GET;
  return apiCall(apiInfo, null, null, userInfo);
};

export const _searchTopByText = (
  params: ISearchTopParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.CREATE_NEW_TOP};
  apiInfo.name = apiInfo.name + '/search';
  apiInfo.type = METHODS.GET;
  return apiCall(apiInfo, null, params, userInfo);
};

export const _showTopToRecentList = (
  params: IShowToOnRecentParams,
  userInfo: IUserToken
) => {
  const {topId, fromSearch} = params;
  const apiInfo = {...API_URL.CREATE_NEW_TOP};
  apiInfo.name = apiInfo.name + `/${topId}`;
  apiInfo.type = METHODS.GET;
  return apiCall(apiInfo, null, {fromSearch}, userInfo);
};

export const changePassword = async (params: IChangePasswordFields) => {
  const user = await Auth.currentAuthenticatedUser();
  return Auth.changePassword(user, params?.oldPassword, params?.newPassword);
};
export const userLogOut = async () => {
  await apiCall(API_URL.LOGOUT, null, null, null);
  return Auth.signOut();
};

export const deleteUserAccount = async () => {
  await apiCall(API_URL.DELETE_ACCOUNT, null, null, null);
  return await Auth.deleteUser();
};

export const register = (params: IRegisterRequest) =>
  apiCall(API_URL.USER_REGISTER, params, null, null);

export const _createNewCollection = (
  data: ICreateCollectionType,
  userInfo: IUserToken
) => apiCall(API_URL.CREATE_NEW_COLLECTION, data, null, userInfo);

export const _getUserProfileData = (userInfo: IUserToken) => {
  const apiInfo = {...API_URL.USER_PROFILE};
  apiInfo.name = apiInfo.name + '/profile';
  return apiCall(apiInfo, null, null, userInfo);
};
export const _getInProgressTopList = (
  _params: IInProgressTopParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.GET_IN_PROGRESS_TOPS};
  apiInfo.name = apiInfo.name + 'IN_PROGRESS';
  apiInfo.type = METHODS.GET;
  return apiCall(apiInfo, null, _params, userInfo);
};
export const _getCollectionTopLists = async (
  _params: IInProgressTopParams,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.COLLECTION_TOP_LISTS, null, _params, userInfo);
};

export const _updateCollection = (
  data: ICollectionParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.CREATE_NEW_COLLECTION};
  apiInfo.name = apiInfo.name + `/${data.id}`;
  apiInfo.type = METHODS.PUT;
  return apiCall(apiInfo, data, null, userInfo);
};
export const _getPurchasedTopList = (
  _params: IInProgressTopParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.GET_IN_PROGRESS_TOPS};
  apiInfo.name = apiInfo.name + 'PURCHASED';
  apiInfo.type = METHODS.GET;
  return apiCall(apiInfo, null, _params, userInfo);
};

export const _buyTop = (_params: IBuyTopParams, userInfo: IUserToken) => {
  const apiInfo = {...API_URL.BUY_TOPS};
  apiInfo.name = apiInfo.name + `${_params.topId}`;
  apiInfo.type = METHODS.PUT;
  return apiCall(apiInfo, null, null, userInfo);
};

export const _getTopListsByCollectionID = async (
  _params: IInProgressTopParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.CREATE_NEW_TOP};
  apiInfo.name = apiInfo.name + `/${_params.collectionId}/list`;
  apiInfo.type = METHODS.GET;
  return apiCall(apiInfo, null, _params, userInfo);
};

export const _moveTopToCollection = (
  data: ICreateCollectionType,
  userInfo: IUserToken
) => apiCall(API_URL.ADD_TOPS_TO_COLLECTION, data, null, userInfo);

export const _getParentChildList = async (userInfo: IUserToken) => {
  return apiCall(API_URL.ADD_PARENT_CHILD_LIST, null, null, userInfo);
};

export const _approvelRequestList = async (
  _params: IChildApproveListParams,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.CHILD_APPROVEL_REQUEST_LIST, null, _params, userInfo);
};

export const _approveOrReject = (
  data: {requestId: number; status: string},
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.APPROVE_OR_REJECT};
  apiInfo.name = apiInfo.name + `/${data.status}/${data.requestId}`;
  apiInfo.type = METHODS.PUT;
  return apiCall(apiInfo, null, null, userInfo);
};
export const _deleteCollectionAndTops = async (
  _params: IDeleteCollectionTopParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.DELETE_COLLECTION_AND_TOPS};
  apiInfo.name = apiInfo.name + `/${_params.collectionId}`;
  return apiCall(apiInfo, null, _params, userInfo);
};

export const _deleteTop = (_params: IBuyTopParams, userInfo: IUserToken) => {
  const apiInfo = {...API_URL.DELETE_TOPS};
  apiInfo.name = apiInfo.name + `/${_params.topId}`;
  apiInfo.type = METHODS.DELETE;
  return apiCall(apiInfo, null, null, userInfo);
};

//create new group
export const _createNewGroup = (
  data: ICreateGroupParams,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.CREATE_NEW_GROUP, data, null, userInfo);
};

//update group name and image
export const _updateGroup = (
  _params: UpdateGroupParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.UPDATE_GROUP_END_POINT};
  apiInfo.name = apiInfo.name + `/${_params.groupId}`;
  return apiCall(apiInfo, _params, null, userInfo);
};

// Mark To Tradeable/Untradeable
export const _markTop = (_params: ITradableParams, userInfo: IUserToken) => {
  const apiInfo = {...API_URL.CREATE_NEW_TOP};
  ///api/v1/top/159/mark-tradable/true
  apiInfo.name =
    apiInfo.name + `/${_params.topId}` + `/mark-tradable/${_params.type}`;
  apiInfo.type = METHODS.PUT;
  return apiCall(apiInfo, null, null, userInfo);
};

export const _getUserList = (
  _params: IUserListParams,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.USER_LIST_END_POINTS, null, _params, userInfo);
};

export const _onUserAddInGroup = (
  _params: IUserAddOnGroupParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.USER_ADD_IN_GROUP};
  apiInfo.name = apiInfo.name + `/${_params.groupId}`;
  return apiCall(apiInfo, _params.usersId, null, userInfo);
};

export const _onMyGroupListAPI = (
  _params: IMyGroupListParams,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.MY_GROUP_LIST_END_POINTS, null, _params, userInfo);
};

export const _onGroupInListAPI = (
  _params: IMyGroupListParams,
  userInfo: IUserToken
) => {
  return apiCall(
    API_URL.GROUP_I_AM_IN_LIST_END_POINTS,
    null,
    _params,
    userInfo
  );
};

export const _onMyGroupInvitations = (
  _params: IGroupInvitationsListParams,
  userInfo: IUserToken
) => {
  return apiCall(
    API_URL.GROUP_INVITATIONS_LIST_END_POINTS,
    null,
    _params,
    userInfo
  );
};
export const _getChildProfile = (
  _params: IChildProfileParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.GET_CHILD_PROFILE};
  apiInfo.name = apiInfo.name + `/${_params}`;
  apiInfo.type = METHODS.GET;
  return apiCall(apiInfo, null, null, userInfo);
};

export const _onMyGroupInvitationsApprove = (
  data: IGroupInviationApproveRejectParams,
  userInfo: IUserToken
) => {
  return apiCall(
    API_URL.GROUP_INVITATIONS_APPROVE_END_POINTS,
    data,
    data,
    userInfo
  );
};

export const _onMyGroupInvitationsReject = (
  data: IGroupInviationApproveRejectParams,
  userInfo: IUserToken
) => {
  return apiCall(
    API_URL.GROUP_INVITATIONS_REJECT_END_POINTS,
    data,
    data,
    userInfo
  );
};

export const _userProfileUpdate = (
  data: IUserProfileUpdateParams,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.UPDATE_PROFILE, data, null, userInfo);
};

export const updateUserAttributes = async (data: IUserProfileUpdateParams) => {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(user, {
    email: data.email,
    given_name: data.firstName,
    family_name: data.lastName,
    phone_number: `${data.countryCode}${data.mobile}`,
  });
  return;
};

export const _getGroupUserList = (
  _params: IGroupUserListParams,
  userInfo: IUserToken
) => {
  if (_params.isUserComingFromMyGroup) {
    return apiCall(
      API_URL.MY_GROUP_USER_LIST_END_POINTS,
      null,
      _params,
      userInfo
    );
  } else {
    return apiCall(
      API_URL.GROUP_I_AM_IN_USER_LIST_END_POINTS,
      null,
      _params,
      userInfo
    );
  }
};

export const _onGroupDetailAPI = (
  _params: IGroupInviationApproveRejectParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.GET_GROUP_DETAIL};
  apiInfo.name = apiInfo.name + `/${_params.groupId}`;
  return apiCall(apiInfo, null, null, userInfo);
};

//remove user from group
export const _onRemoveUserFromGroupAPI = (
  _params: IRemoveUserFromGroupParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.GROUP_USER_REMOVE_END_POINTS};
  apiInfo.name =
    apiInfo.name + `/${_params.groupId}` + `/${_params.removeUserId}`;
  return apiCall(apiInfo, null, null, userInfo);
};

//delete group
export const _onDeleteGroupAPI = (
  _params: IGroupInviationApproveRejectParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.DELETE_GROUP_END_POINTS};
  apiInfo.name = apiInfo.name + `/${_params.groupId}`;
  return apiCall(apiInfo, null, null, userInfo);
};

//leave group
export const _onLeaveGroupAPI = (
  _params: IGroupInviationApproveRejectParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.LEAVE_GROUP_END_POINTS};
  apiInfo.name = apiInfo.name + `/${_params.groupId}`;
  return apiCall(apiInfo, null, null, userInfo);
};

export const _uploadUserProfile = (
  data: IProfileImagePayload,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.UPDATE_PROFILE_IMAGE, data, null, userInfo);
};

export const _getTradeReceviedOrSentList = (
  _params: ITradeTabParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.TRADE_RECEVIED_SEND_OFFER};
  apiInfo.name = apiInfo.name + `/${_params.offerType}` + '/list';
  apiInfo.type = METHODS.GET;
  let extraParams = {
    page: _params.page,
    size: _params.size,
  };
  return apiCall(apiInfo, null, extraParams, userInfo);
};

export const _getUserListSearch = (
  _params: IUserListSearchParams,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.USER_LIST_SEARCH_END_POINTS, null, _params, userInfo);
};

export const _distributeTop = (
  data: DistributeTopPayload,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.DISTRIBUTE_TOP, data, null, userInfo);
};

export const _getGroupListSearch = (
  _params: IUserListSearchParams,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.GROUP_LIST_SEARCH_END_POINTS, null, _params, userInfo);
};

export const _getGroupUserListSearch = (
  _params: IGroupUserListSearchParams,
  userInfo: IUserToken
) => {
  return apiCall(
    API_URL.MY_GROUP_USER_LIST_END_POINTS,
    null,
    _params,
    userInfo
  );
};

export const _cancelOffer = (
  _params: IOfferAPIParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.TRADE_RECEVIED_SEND_OFFER};
  apiInfo.name = apiInfo.name + `/${_params}` + '/cancel';
  apiInfo.type = METHODS.PUT;
  return apiCall(apiInfo, null, null, userInfo);
};
export const _approveOffer = (_params: number, userInfo: IUserToken) => {
  const apiInfo = {...API_URL.TRADE_RECEVIED_SEND_OFFER};
  apiInfo.name = apiInfo.name + `/${_params}` + '/accept';
  apiInfo.type = METHODS.PUT;
  return apiCall(apiInfo, null, null, userInfo);
};
export const _rejectOffer = (
  _params: IOfferAPIParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.TRADE_RECEVIED_SEND_OFFER};
  apiInfo.name = apiInfo.name + `/${_params}` + '/reject';
  apiInfo.type = METHODS.PUT;
  return apiCall(apiInfo, null, null, userInfo);
};

export const _createTradeOffer = (
  data: CreateTradeOfferPayload,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.CREATE_TRADE_OFFER, data, null, userInfo);
};

export const _moveTopToCollections = (
  data: MoveToCollectionRequestPayload,
  userInfo: IUserToken
) => {
  const {collectionId, topsData} = data;
  const apiInfo = {...API_URL.ADD_TOPS_TO_COLLECTION_WITH_QUANTITY};
  apiInfo.name = apiInfo.name + `/${collectionId}`;
  return apiCall(apiInfo, topsData, null, userInfo);
};

export const _counterOffer = (
  data: CounterOfferPayload,
  userInfo: IUserToken
) => {
  const {offerId, topsData} = data;
  const apiInfo = {...API_URL.TRADE_RECEVIED_SEND_OFFER};
  apiInfo.name = apiInfo.name + `/${offerId}` + '/counter';
  apiInfo.type = METHODS.PUT;
  return apiCall(apiInfo, topsData, null, userInfo);
};

export const _cartCheckout = (
  data: CartCheckoutRequestPayload[],
  userInfo: IUserToken
) => {
  return apiCall(API_URL.CART_CHECKOUT_API, data, null, userInfo);
};

export const _tradeSettingApprovalAPI = (
  _params: IChildSettingApproval,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.TRADE_APPROVAL_REQUEST, null, _params, userInfo);
};

export const _tradeNotApproveUserListAPI = (
  _params: IChildSettingApproval,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.TRADE_NOT_APPROVED_USER_LIST, null, _params, userInfo);
};

export const _userAddForTradeApproval = (
  _params: ITradeApprovalAdd,
  userInfo: IUserToken
) => {
  const {usersId, minorId} = _params;
  return apiCall(
    API_URL.TRADE_USER_ADD_FOR_APPROVAL,
    usersId,
    {minorId},
    userInfo
  );
};

export const _tradeApproveUserListAPI = (
  _params: IUserApproveListApiParams,
  userInfo: IUserToken
) => {
  return apiCall(
    API_URL.TRADE_APPROVED_USER_LIST_END_POINT,
    null,
    _params,
    userInfo
  );
};

export const _groupInvitationChildAPI = (
  _params: IGroupInvitationChildParams,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.INVITATIONS_FOR_CHILD, null, _params, userInfo);
};

export const _userRemoveFromTradeApprove = (
  _params: IUserRemoveFromApproveListApiParams,
  userInfo: IUserToken
) => {
  return apiCall(
    API_URL.USER_REMOVE_FROM_TRADE_APPROVE_LIST,
    null,
    _params,
    userInfo
  );
};

export const _userTradeApprovalListApiFunction = (
  _params: ITradeTabParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.TRADE_RECEVIED_SEND_OFFER};
  apiInfo.name = apiInfo.name + `/${_params.offerType}` + '/list';
  apiInfo.type = METHODS.GET;
  const extraParams = {
    page: _params.page,
    size: _params.size,
    minorId: _params?.minorId,
  };
  return apiCall(apiInfo, null, extraParams, userInfo);
};

export const _childGroupSettingApprovalAPI = (
  _params: IChildGroupSettingApproval,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.CHILD_GROUP_APPROVAL_REQUEST, null, _params, userInfo);
};

export const _childSentTradeApproveOrReject = (
  _params: IChildSentTradeApprovalParams,
  userInfo: IUserToken
) => {
  const {offerId, status} = _params;
  const apiInfo = {...API_URL.TRADE_RECEVIED_SEND_OFFER};
  apiInfo.name = apiInfo.name + `/${offerId}` + '/update-status' + `/${status}`;
  apiInfo.type = METHODS.PUT;
  return apiCall(apiInfo, null, null, userInfo);
};

export const _childTradableRequestListApiFunction = (
  _params: ITradeTabParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.CHILD_TRADABLE_REQUEST};
  apiInfo.name = apiInfo.name + `/${_params.minorId}` + '/requests';
  const extraParams = {
    page: _params.page,
    size: _params.size,
  };
  return apiCall(apiInfo, null, extraParams, userInfo);
};

export const _childTradableSettingAPI = (
  _params: IChildTradableSetting,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.CHILD_TRADABLE_SETTING, null, _params, userInfo);
};

export const _onChildTradaleApproveReject = (
  data: TradableAcceptReject,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.CHILD_TRADABLE_REQUEST};
  apiInfo.name = apiInfo.name + `/${data.requestId}` + `/${data.status}`;
  apiInfo.type = METHODS.PUT;
  return apiCall(apiInfo, null, null, userInfo);
};

export const _topDistributeSettingApprovalAPI = (
  _params: IChildSettingApproval,
  userInfo: IUserToken
) => {
  return apiCall(
    API_URL.DISTRIBUTE_TOP_APPROVAL_REQUEST,
    null,
    _params,
    userInfo
  );
};

export const NotificationDataList = (
  params: IGetNotificationType,
  userInfo: IUserToken
) => apiCall(API_URL.NOTIFICATION_LIST, null, params, userInfo);

export const NotificationGetCount = (userInfo: IUserToken) => {
  const apiInfo = {...API_URL.NOTIFICATION_LIST};
  apiInfo.name = apiInfo.name + '/notification-count';
  return apiCall(apiInfo, null, null, userInfo);
};

export const _updatePushTokenOnServer = (
  params: IPushTokenUpdateParams,
  userInfo: IUserToken
) => apiCall(API_URL.DEVICE, params, null, userInfo);

export const topBuyingApprovalData = (
  params: ITopBuyApprovalParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.BUY_TOPS};
  apiInfo.name = apiInfo.name + `${params.id}/requests`;
  return apiCall(apiInfo, null, params.data, userInfo);
};

export const approveOrRejectByParent = (
  params: IApproveRejectParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.BUY_TOPS};
  apiInfo.name = apiInfo.name + `${params.id}/${params.status}`;
  apiInfo.type = METHODS.PUT;
  return apiCall(apiInfo, null, null, userInfo);
};

export const updateTopBuyingSettings = (
  params: ISaveSetting,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.PURCHASE_PERMISSION, null, params, userInfo);
};

export const _userAddForDistributeApproval = (
  _params: ITradeApprovalAdd,
  userInfo: IUserToken
) => {
  const {usersId, minorId} = _params;
  return apiCall(
    API_URL.DISTRIBUTE_USER_ADD_FOR_APPROVAL,
    usersId,
    {minorId},
    userInfo
  );
};

export const _distributeApproveUserListAPI = (
  _params: IUserApproveListApiParams,
  userInfo: IUserToken
) => {
  return apiCall(
    API_URL.DISTRIBUTE_APPROVED_USER_LIST_END_POINT,
    null,
    _params,
    userInfo
  );
};

export const _userRemoveFromDistributeApprove = (
  _params: IUserRemoveFromApproveListApiParams,
  userInfo: IUserToken
) => {
  return apiCall(
    API_URL.USER_REMOVE_FROM_DISTRIBUTE_APPROVE_LIST,
    null,
    _params,
    userInfo
  );
};

export const _distributeRequestListAPIFunction = (
  _params: ITradeTabParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.DISTRIBUTE_REQUEST_LIST_END_POINT};
  apiInfo.name = apiInfo.name + `/${_params.offerType}` + '/list';
  apiInfo.type = METHODS.GET;
  const extraParams = {
    page: _params.page,
    size: _params.size,
    minorId: _params?.minorId,
  };
  return apiCall(apiInfo, null, extraParams, userInfo);
};

export const _childSentDistributeApproveOrReject = (
  _params: IDistributeRequestParams,
  userInfo: IUserToken
) => {
  const {requestId, status} = _params;
  const apiInfo = {...API_URL.DISTRIBUTE_REQUEST_LIST_END_POINT};
  apiInfo.name = apiInfo.name + `/${requestId}` + `/${status}`;
  apiInfo.type = METHODS.PUT;
  return apiCall(apiInfo, null, null, userInfo);
};

export const _cartBuyingRequestToParent = (
  data: RequestDataForParentRequest,
  userInfo: IUserToken
) => {
  return apiCall(
    API_URL.CART_BUYING_REQUEST_TO_PARENT_API,
    data?.quantityData,
    {cash: data?.cash},
    userInfo
  );
};

export const _resendApprovalRequest = (userInfo: IUserToken) => {
  return apiCall(API_URL.RESEND_APPROVAL_REQUEST, null, null, userInfo);
};

export const _getCartAmountAndReportAbuseData = (userInfo: IUserToken) => {
  return apiCall(API_URL.GET_CART_AMOUNT_AND_ABUSE_DATA, null, null, userInfo);
};

export const _verifyCouponCode = (
  _params: IInProgressTopParams,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.COUPON_CODE_END_POINT, null, _params, userInfo);
};
export const _getReportAbuseData = (userInfo: IUserToken) => {
  return apiCall(API_URL.GET_ABUSE_DATA_END_POINT, null, null, userInfo);
};

export const _tradeRequestReport = (
  _params: ReportFormState,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.TRADE_REPORT_END_POINT, _params, null, userInfo);
};

export const _tradeOfferDetail = (
  _params: IOfferAPIParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.TRADE_RECEVIED_SEND_OFFER};
  apiInfo.name = apiInfo.name + `/${_params?.offerId}`;
  apiInfo.type = METHODS.GET;
  return apiCall(apiInfo, null, {isChild: _params?.isChild}, userInfo);
};

export const _distributeRequestReceivedListAPIFunction = (
  _params: ITradeTabParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.DISTRIBUTE_REQUEST_LIST_END_POINT};
  apiInfo.name = apiInfo.name + `/${_params.offerType}` + '/list';
  apiInfo.type = METHODS.GET;
  const extraParams = {
    page: _params.page,
    size: _params.size,
    minorId: _params?.minorId,
  };
  return apiCall(apiInfo, null, extraParams, userInfo);
};

export const _distributeReceivedRequestApproveOrRejectOrReport = (
  _params: ReportFormState,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.DISTRIBUTE_REQUEST_LIST_END_POINT};
  apiInfo.type = METHODS.PUT;
  return apiCall(apiInfo, _params, null, userInfo);
};

export const _distributeTopDetail = (
  _params: DistributeDetialParams,
  userInfo: IUserToken
) => {
  const {isRequestForUnRegisteredUser, requestId} = _params;
  const apiInfo = {...API_URL.DISTRIBUTE_REQUEST_LIST_END_POINT};
  apiInfo.name = apiInfo.name + `/${requestId}`;
  apiInfo.type = METHODS.GET;
  return apiCall(
    apiInfo,
    null,
    {isChild: _params?.isChild, isRequestForUnRegisteredUser},
    userInfo
  );
};

export const _uploadTopImage = async (
  _params: ITopImageUploadParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.UPLOAD_TOP_IMAGE_END_POINT};
  const {bucketName, filePath, file} = _params;
  apiInfo.name = apiInfo.name + `/${bucketName}`;
  return apiCall(apiInfo, {file}, {filePath}, userInfo);
};

export const _tradeStatusUpdate = (
  _params: ReportFormState,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.TOP_TRADE_STATUS_UPDATE, _params, null, userInfo);
};

export const _butPlanAPICall = (
  _params: BuyPlanParams,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.BUY_PLAN_END_POINT, _params, null, userInfo);
};

export const _myTransactionAPICall = (
  _params: ReportFormState,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.MY_TRANSACTION_END_POINT, null, _params, userInfo);
};

export const _topCreditsAPI = (
  params: ITopCreditsRequestParams,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.TOPCREDITS_REQUEST_ACTION_END_POINT};
  apiInfo.name = apiInfo.name + `${params.requestId}/${params.status}`;
  return apiCall(apiInfo, null, null, userInfo);
};

export const _blockUserList = (
  params: IGetNotificationType,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.BLOCK_USER_LIST_END_POINT, null, params, userInfo);
};

export const _unBlockUser = (
  params: IUnblockUserParams,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.UNBLOCK_USER_END_POINTS, null, params, userInfo);
};

export const _distributeViaEmailOrPhone = (
  _params: IDistributeViaEmailParams,
  userInfo: IUserToken
) => {
  return apiCall(
    API_URL.DISTRIBUTE_TOP_VIA_EMAIL_TEXT,
    _params,
    null,
    userInfo
  );
};

export const _distributeViaCSV = (
  _params: IDistributeViaCSV,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.DISTRIBUTE_TOP_CSV, null, _params, userInfo);
};

export const _verifyReedemCode = (
  _params: IReedemCodeParams,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.REEDEM_CODE_END_POINT, null, _params, userInfo);
};

export const _favoriteUserList = (
  params: IGetNotificationType,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.FAVORITE_USER_LIST_END_POINT, null, params, userInfo);
};

export const _verifyFavoriteUser = (params: IUserId, userInfo: IUserToken) => {
  return apiCall(API_URL.FAVORITE_USER_CHECK, null, params, userInfo);
};

export const _markAndUnmarkUser = (
  params: IMarkAndUnmarkFavorite,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.MARK_UNMARK_FAVORITE_USER, params, null, userInfo);
};

export const _notesAddAPI = (params: INotesAddParams, userInfo: IUserToken) => {
  return apiCall(API_URL.NOTES_ADD_END_POINT, params, null, userInfo);
};

export const _notesUpdateAPI = (
  params: INotesAddParams,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.NOTES_UPDATE_END_POINT, null, params, userInfo);
};

export const _notesListAPI = (
  params: IGetNotificationType,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.NOTES_LIST_END_POINT, null, params, userInfo);
};

export const _notesDeleteAPI = (
  params: INotesAddParams,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.NOTES_DELETE_END_POINT, null, params, userInfo);
};
export const _tranferTopCredit = (
  params: ITransferTopCredit,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.TRANSFER_TOP_CREDIT_END_POINT, null, params, userInfo);
};

export const _pullBackTopCredit = (
  params: ITransferTopCredit,
  userInfo: IUserToken
) => {
  const apiInfo = {...API_URL.TOP_CREDITS_PULL_REQUEST};
  // apiInfo.name = apiInfo.name + `/${params.childId}`;
  return apiCall(apiInfo, null, params, userInfo);
};
export const trackingList = (userInfo: IUserToken, page: number = 0) => {
  const apiUrl = `${API_URL.TRACKING_LIST.name}?page=${page}&size=10`;
  return apiCall(
    {name: apiUrl, type: API_URL.TRACKING_LIST.type},
    null,
    null,
    userInfo
  );
};

export const _searchUserByText = (
  params: ISearchTopParams,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.USER_SEARCH_END_POINT, null, params, userInfo);
};
export const trackingCancel = (
  params: ITrackingCancelParams,
  userInfo: IUserToken
) => {
  console.log('params:', params);
  return apiCall(API_URL.TRACKING_CANCEL, null, params, userInfo);
};
export const trackingDetail = (userInfo: IUserToken, data: any) => {
  const apiInfo = {...API_URL.TRACKING_DETAIL};
  const search = data?.search ? `&keyword=${data?.search}` : '';
  apiInfo.name =
    apiInfo.name +
    `?requestId=${data?.requestId}&type=${data?.type}&page=${
      data?.page ?? 0
    }&size=10` +
    search;
  apiInfo.type = METHODS.GET;
  return apiCall(apiInfo, null, null, userInfo);
};
export const sendReminder = (
  params: ISendReminderParams,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.SEND_REMINDER, null, params, userInfo);
};

export const _checkUserMultipleAccount = (
  params: ISearchTopParams,
  userInfo: IUserToken
) => {
  return apiCall(
    API_URL.MULTIPLE_ACCOUNT_SEARCH_END_POINTS,
    null,
    params,
    userInfo
  );
};

export const tutorialList = (userInfo: IUserToken, page: number = 0) => {
  const apiUrl = `${API_URL.TUTORIAL_LIST.name}`;
  return apiCall(
    {name: apiUrl, type: API_URL.TUTORIAL_LIST.type},
    null,
    null,
    userInfo
  );
};

export const _forgotUserName = (params: EmailMobileType) => {
  return apiCall(API_URL.FORGOT_USERNAME_END_POINTS, null, params, null);
};
export const sendReminderAll = (
  params: ISendReminderParams,
  userInfo: IUserToken
) => {
  return apiCall(API_URL.SEND_REMINDER_ALL, null, params, userInfo);
};
