import React from 'react';
import {Keyboard, Pressable, ScrollView, Text, View} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {styles} from './styles';
import {COLORS, FixedValue, TEXT} from '../../constants';
import LabelInput from '../input/labelInput';
import useDistributeHooks from '../../hooks/componentHooks/useDistributeHooks';
import CustomButton from '../button/customButton';
import ArrowUp from '../renderSvgs/arrowUp';
import ArrowDownCSV from '../renderSvgs/arrowDownCSV';
import PrimaryButton from '../button/primaryButton';
import SelectedCountry from '../smallDesigns/selectedCountry';
import RadioSelectButton from '../renderSvgs/radioSelectButton';
import RadioUnselectButton from '../renderSvgs/radioUnselectButton';
import Plus from '../renderSvgs/plus';
import {SignUpContext} from '../../contextAPI/signUpContext';
import CountriesList from '../../modals/allCountriesList/countriesList';
import {
  _InputType,
  _ToastHandler,
  showAlertForExceedQuantity,
} from '../../utils/helperFunctions';
import QunatitySelector from '../smallDesigns/qunatitySelector';
import CrossCircle from '../renderSvgs/crossCircle';
import {
  CHECK_FOR_MULTIPLE_ACCOUNT,
  DISTRIBUTE_TOP_VIA_CSV,
  DISTRIBUTE_TO_EMAIL_TEXT,
} from '../../constants/actionTypes';
import {HomeStore} from '../../redux/reducers/homeSlice';
import {useAppSelector} from '../../hooks/reduxHooks';
import {TOAST_TEXT} from '../../constants/textMessage';
import {HomeContext} from '../../contextAPI/homeContext';
import CSVIcon from '../renderSvgs/CSVIcon';
import ModalBack from '../renderSvgs/modalBack';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import AppLoader from '../smallDesigns/appLoader';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import BottomListModalWithCheckbox from '../../modals/bottomListWithCheckbox';
import {useIsFocused} from '@react-navigation/native';

const InviteByEmailOrPhone = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  const {
    values,
    handleChange,
    handleBlur,
    InviteViaEmailOrPhoneError,
    handleSubmit,
    topDistributorList,
    isEmailSelected,
    onSelectEmailOrText,
    updateQuantity,
    removeItemAndUpdateTotal,
    inviteTopDistribute,
    totalDistributeTop,
    selectCSVFile,
    uploadCSVToS3,
    downloadSampleCSV,
    selectedFile,
    setSelectedFile,
    uploadProgress,
    setUploadProgress,
    csvUploadLoader,
    setFieldTouched,
    setTopDistributorList,
    showMultipleUserModal,
    setShowMultipleUserModal,
    multipleUserListData,
    setMultipleUserListData,
    onUserSelect,
    setFieldValue,
    updateTotalQuantity,
  } = useDistributeHooks();
  const {selectedCountry, setSelectedCountry, showCountry, setShowCountry} =
    React.useContext(SignUpContext);
  const {disributeViaEmailData, disributeViaCSV, userDuplicateData} =
    useAppSelector(HomeStore);
  const {setShowDistributiveModal, selectedTopData} =
    React.useContext(HomeContext);
  const isEmailAdded = totalDistributeTop === FixedValue.CONSTANT_VALUE_0;
  const isFiveUserAdded =
    topDistributorList.length === FixedValue.CONSTANT_VALUE_5;
  const {userData} = useAppSelector(userProfileStore);
  const UserDetail = userData.success;
  const isAdultUserLoggedIn = UserDetail?.role === TEXT.ROLE_PARENT;
  const isFocused = useIsFocused();

  React.useEffect(() => {
    switch (disributeViaEmailData.status) {
      case DISTRIBUTE_TO_EMAIL_TEXT.SUCCESS:
        _ToastHandler(TOAST_TEXT.INVITATION_HAS_BEEN_SENT, true);
        setShowDistributiveModal(false);
        break;
      case DISTRIBUTE_TO_EMAIL_TEXT.FAILED:
        setShowDistributiveModal(false);
        break;
      default:
        break;
    }
  }, [disributeViaEmailData.status]);

  React.useEffect(() => {
    if (isFocused) {
      switch (userDuplicateData.status) {
        case CHECK_FOR_MULTIPLE_ACCOUNT.SUCCESS:
          const isMultipleAccountExits =
            // @ts-ignore
            userDuplicateData.success?.data?.results?.length >
            FixedValue.CONSTANT_VALUE_1;
          let data: any = {};
          if (isMultipleAccountExits) {
            setMultipleUserListData(
              userDuplicateData.success?.data?.results ?? []
            );
            setShowMultipleUserModal(true);
          } else {
            data.countryCode = selectedCountry.dial_code || '+1';
            data.emailPhoneNumber = values?.email_mobile?.trim().toLowerCase();
            data.quantity = FixedValue.CONSTANT_VALUE_1;
            data.isEmailSelected = isEmailSelected;
            setTopDistributorList(prevState => [...prevState, data]);
            setFieldValue('email_mobile', '');
            setFieldTouched('email_mobile', false);
            setShowMultipleUserModal(false);
            updateTotalQuantity();
          }

          break;
        default:
          break;
      }
    }
  }, [userDuplicateData.status]);

  const onChangeUpdateQuantity = (item: any, quantity: number) => {
    let tempSelectedUser = [...topDistributorList];
    tempSelectedUser = tempSelectedUser?.map(user =>
      user?.emailPhoneNumber === item?.emailPhoneNumber
        ? {...user, quantity}
        : {...user}
    );
    const totalValue = tempSelectedUser?.reduce(
      (total, obj) => obj?.quantity! + total,
      FixedValue.CONSTANT_VALUE_0
    );
    if (
      totalValue <= (selectedTopData?.quantity ?? FixedValue.CONSTANT_VALUE_0)
    ) {
      setTopDistributorList(tempSelectedUser);
    } else {
      showAlertForExceedQuantity(
        selectedTopData?.quantity ?? FixedValue.CONSTANT_VALUE_0
      );
    }
  };

  React.useEffect(() => {
    switch (disributeViaCSV.status) {
      case DISTRIBUTE_TOP_VIA_CSV.SUCCESS:
        _ToastHandler(TOAST_TEXT.INVITATION_HAS_BEEN_SENT, true);
        setShowDistributiveModal(false);
        break;
      case DISTRIBUTE_TOP_VIA_CSV.FAILED:
        setShowDistributiveModal(false);
        break;
      default:
        break;
    }
  }, [disributeViaCSV.status]);

  const horizontalLineWithText = () => {
    return (
      <View style={style.container}>
        <View style={style.lineContainer}>
          <View style={style.line} />
          <Text style={style.orText}>{TEXT.OR}</Text>
          <View style={style.line} />
        </View>
      </View>
    );
  };

  const _PlusIcon = (): JSX.Element => (
    <Pressable
      style={style.inputSecondElement}
      onPress={() => !isFiveUserAdded && handleSubmit()}
      hitSlop={{
        top: FixedValue.CONSTANT_VALUE_10,
        bottom: FixedValue.CONSTANT_VALUE_10,
        left: FixedValue.CONSTANT_VALUE_10,
        right: FixedValue.CONSTANT_VALUE_10,
      }}
    >
      <Plus
        width={FixedValue.CONSTANT_VALUE_20}
        height={FixedValue.CONSTANT_VALUE_20}
        stroke={isFiveUserAdded ? COLORS._666666 : ColorTheme.color}
      />
    </Pressable>
  );

  const _RadioSelectButton = (): JSX.Element => (
    <RadioSelectButton
      width={FixedValue.CONSTANT_VALUE_28}
      height={FixedValue.CONSTANT_VALUE_28}
    />
  );

  const _RadioUnselectButton = (): JSX.Element => (
    <RadioUnselectButton
      width={FixedValue.CONSTANT_VALUE_28}
      height={FixedValue.CONSTANT_VALUE_28}
    />
  );

  const ProgressBar = ({progress}: {progress: number}) => {
    return (
      <View style={style.progreeContainer}>
        <View style={[style.progressBar, {width: `${progress}%`}]} />
      </View>
    );
  };

  const GetUploadCSVData = () => {
    if (isAdultUserLoggedIn) {
      return (
        <View style={style.csvContainer}>
          <View style={style.containerCSV}>
            <View style={style.leftColumn}>
              <CSVIcon
                height={scaleHeight(FixedValue.CONSTANT_VALUE_26)}
                width={scaleWidth(FixedValue.CONSTANT_VALUE_26)}
              />
              <Text
                numberOfLines={FixedValue.CONSTANT_VALUE_1}
                style={style.fileNameStyle}
              >
                {selectedFile?.name}
              </Text>
              <Text
                numberOfLines={FixedValue.CONSTANT_VALUE_1}
                style={style.kbText}
              >
                {selectedFile?.size}kb
              </Text>
            </View>
            <View style={style.rightColumn}>
              <Text
                numberOfLines={FixedValue.CONSTANT_VALUE_1}
                style={[style.fileNameStyle, style.marginRight16]}
              >
                {uploadProgress}%
              </Text>
              <Pressable
                onPress={() => {
                  setSelectedFile(null);
                  setUploadProgress(FixedValue.CONSTANT_VALUE_0);
                }}
              >
                <ModalBack
                  height={scaleHeight(FixedValue.CONSTANT_VALUE_32)}
                  width={scaleWidth(FixedValue.CONSTANT_VALUE_32)}
                />
              </Pressable>
            </View>
          </View>
          {ProgressBar({progress: uploadProgress})}
        </View>
      );
    } else {
      return <></>;
    }
  };

  const customModaldata = {
    closeModal: setShowMultipleUserModal,
    modalVisible: showMultipleUserModal,
    onPress: onUserSelect,
    listData: multipleUserListData ?? [],
    showLoggedInUser: false,
    headerText: TEXT.DISTRIBUTE_USER_NAME,
  };

  const multipleChildListModal = React.useMemo((): JSX.Element | null => {
    return <BottomListModalWithCheckbox customModaldata={customModaldata} />;
  }, [multipleUserListData, showMultipleUserModal, setShowMultipleUserModal]);

  React.useEffect(() => {
    const initialCountryCode = {
      name: 'United States',
      flag: '🇺🇸',
      code: 'US',
      dial_code: '+1',
    };
    if (isFocused) {
      setSelectedCountry(initialCountryCode);
    }
  }, [isFocused]);

  return (
    <>
      {(csvUploadLoader || userDuplicateData.isLoading) && <AppLoader />}
      <ScrollView
        contentContainerStyle={style.textInputContainer}
        showsVerticalScrollIndicator={false}
        onScroll={() => Keyboard.dismiss()}
      >
        {selectedFile === null ? (
          <>
            <View style={style.emailOrPhoneContainer}>
              <Pressable
                style={style.radioButtonAndTextContainer}
                onPress={() => onSelectEmailOrText(true)}
              >
                {isEmailSelected ? (
                  <_RadioSelectButton />
                ) : (
                  <_RadioUnselectButton />
                )}
                <Text style={style.selectEmailOrNumber}>{TEXT.EMAIL}</Text>
              </Pressable>
              {/* temporary comment for invite via text */}

              <Pressable
                style={style.radioButtonAndTextContainer}
                onPress={() => onSelectEmailOrText(false)}
              >
                {!isEmailSelected ? (
                  <_RadioSelectButton />
                ) : (
                  <_RadioUnselectButton />
                )}
                <Text style={style.selectEmailOrNumber}>{TEXT.TEXT}</Text>
              </Pressable>
            </View>
            <View>
              <LabelInput
                inputLabel={
                  isEmailSelected
                    ? TEXT.DISTRIBUTE_VIA_EMAIL
                    : TEXT.DISTRIBUTE_VIA_PHONE
                }
                value={values.email_mobile}
                onChangeText={handleChange(TEXT.EMAIL_OR_MOBILE)}
                onBlur={handleBlur(TEXT.EMAIL_OR_MOBILE)}
                haveError={InviteViaEmailOrPhoneError}
                maxLength={
                  isEmailSelected
                    ? FixedValue.CONSTANT_VALUE_200
                    : FixedValue.CONSTANT_VALUE_10
                }
                placeholder={
                  isEmailSelected
                    ? TEXT.ENTER_EMAIL_ID_2
                    : TEXT.ENTER_MOBILE_NUMBER
                }
                SecondElement={_PlusIcon}
                LeftElement={!isEmailSelected ? <SelectedCountry /> : null}
                onPress={() => setShowCountry(true)}
                keyboardType={
                  !isEmailSelected ? _InputType.phoneNumber : _InputType.email
                }
                extraRightPadding={true}
              />
            </View>
            {topDistributorList?.map(item => (
              <View
                style={style.distributeUserListContainer}
                key={item.emailPhoneNumber}
              >
                <Text
                  numberOfLines={FixedValue.CONSTANT_VALUE_1}
                  style={style.emailPhoneText}
                >
                  {!item?.isEmailSelected && item?.countryCode}{' '}
                  {item?.emailPhoneNumber}
                </Text>
                <QunatitySelector
                  count={item?.quantity ?? FixedValue.CONSTANT_VALUE_0}
                  onMinusPress={() => updateQuantity(item, TEXT.MINNUS)}
                  onPlusPress={() => updateQuantity(item, TEXT.ADD)}
                  quantityStyle={style.quantitySelectorViewStyle}
                  isInputQuantity
                  onChangeQuantity={(val: number) =>
                    onChangeUpdateQuantity(item, val)
                  }
                  checkQuantityOnOnChange={true}
                  textInputSupportDarkTheme={true}
                />
                <Pressable
                  onPress={() => removeItemAndUpdateTotal(item)}
                  style={{right: scaleWidth(FixedValue.CONSTANT_VALUE_2)}}
                >
                  <CrossCircle
                    height={FixedValue.CONSTANT_VALUE_24}
                    width={FixedValue.CONSTANT_VALUE_24}
                    stroke={ColorTheme.color}
                  />
                </Pressable>
              </View>
            ))}
            {topDistributorList.length === FixedValue.CONSTANT_VALUE_0 && (
              <>
                {isAdultUserLoggedIn && (
                  <>
                    {horizontalLineWithText()}
                    <Text style={style.ifYouDont}>{TEXT.IF_YOU_DONT_HAVE}</Text>
                    <View style={style.buttonView}>
                      <CustomButton
                        buttonLabel={TEXT.UPLAOD_CSV}
                        buttonwidth={style.uploadButtonStyle}
                        labelStyle={style.primaryButtonLabelstyle}
                        buttonBackGroundColor={style.uploadButtonColor}
                        onPress={() => selectCSVFile()}
                        firstElement={
                          <ArrowUp
                            width={FixedValue.CONSTANT_VALUE_14}
                            height={FixedValue.CONSTANT_VALUE_14}
                          />
                        }
                      />
                      <CustomButton
                        buttonLabel={TEXT.SAMPLE_CSV}
                        buttonwidth={style.downloadButtonStyle}
                        labelStyle={style.downloadButtonLabelstyle}
                        buttonBackGroundColor={style.downloadButtonColor}
                        onPress={() => downloadSampleCSV()}
                        firstElement={
                          <ArrowDownCSV
                            width={FixedValue.CONSTANT_VALUE_14}
                            height={FixedValue.CONSTANT_VALUE_14}
                          />
                        }
                      />
                    </View>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <GetUploadCSVData />
        )}
        <View style={style.marginTop30}>
          <PrimaryButton
            buttonLabel={selectedFile !== null ? TEXT.SUBMIT : TEXT.SENT_INVITE}
            onPress={isEmailAdded ? uploadCSVToS3 : inviteTopDistribute}
            isDisabled={
              totalDistributeTop === FixedValue.CONSTANT_VALUE_0 &&
              selectedFile === null
            }
          />
        </View>
      </ScrollView>
      <CountriesList
        onPress={() => setShowCountry(false)}
        showCountry={showCountry && !isEmailSelected}
        selectCountryCode={selectedCountry?.dial_code}
        selectedCountryFunction={setSelectedCountry}
      />
      {showMultipleUserModal && multipleChildListModal}
    </>
  );
};

export default React.memo(InviteByEmailOrPhone);
