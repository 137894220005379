import React from 'react';
import Svg, {
  SvgProps,
  Path,
  Defs,
  LinearGradient,
  Stop,
} from 'react-native-svg';
const SelectedGradient = (props: SvgProps) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M23.9999 12C23.9999 18.6274 18.6274 24 12 24C5.37257 24 0 18.6274 0 12C0 5.37258 5.37257 0 12 0C18.6274 0 23.9999 5.37258 23.9999 12Z"
      fill="white"
    />
    <Path
      d="M12 3.26871e-06C18.6274 2.96717e-06 23.9999 5.37259 23.9999 12C23.9999 18.6274 18.6274 24 12 24C5.37257 24 -2.25617e-07 18.6274 -5.0393e-07 12C-7.82243e-07 5.37259 5.37257 3.57025e-06 12 3.26871e-06Z"
      fill="url(#paint0_linear_2545_11925)"
      // @ts-ignore
      style={{
        mixBlendMode: 'multiply',
      }}
    />
    <Path
      d="M22.9999 12C22.9999 18.0751 18.0751 23 12 23C5.92485 23 1 18.0751 1 12C1 5.92486 5.92485 1 12 1C18.0751 1 22.9999 5.92486 22.9999 12Z"
      fill="url(#paint1_linear_2545_11925)"
      // @ts-ignore
      style={{
        mixBlendMode: 'multiply',
      }}
    />
    <Path
      d="M22.9999 12C22.9999 18.0751 18.0751 23 12 23C5.92485 23 1 18.0751 1 12C1 5.92486 5.92485 1 12 1C18.0751 1 22.9999 5.92486 22.9999 12Z"
      stroke="white"
      strokeWidth={2}
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_2545_11925"
        x1={-5.04679e-7}
        y1={11.9836}
        x2={23.9999}
        y2={12}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#FF0000" />
        <Stop offset={0.0001} stopColor="#7D00FF" />
        <Stop offset={0.489583} stopColor="#FEFFFE" />
        <Stop offset={1} stopColor="#7DFF00" />
      </LinearGradient>
      <LinearGradient
        id="paint1_linear_2545_11925"
        x1={12.0163}
        y1={0}
        x2={12}
        y2={24}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#FF0000" />
        <Stop offset={0.489583} stopColor="#FEFFFE" />
        <Stop offset={1} stopColor="#00FFFF" />
      </LinearGradient>
    </Defs>
  </Svg>
);
export default SelectedGradient;
