import React, {useState} from 'react';
import {View} from 'react-native';
import SearchTabEmptyList from '../../../components/emptyListView/searchTabEmptyList';
import AppLoader from '../../../components/smallDesigns/appLoader';
import {FixedValue, ROUTE_NAME, TEXT} from '../../../constants';
import {SearchTabContext} from '../../../contextAPI/SearchTabBarContext';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {useAppSelector} from '../../../hooks/reduxHooks';
import {searchTabStore} from '../../../redux/reducers/searchTabSlice';
import {searchTabStyle} from './searchTab.style';
import TopTabBarLayout from '../../../components/layout/topTabBarLayout';
import {ISingleTabProps} from '../../../types/componentTypes/layoutType';
import topsListComponent from '../../../components/topsListComponent';
import searchUserListComponent from '../../../components/searchUserListComponent';
import useFavouriteHooks from '../../../hooks/componentHooks/useFavouriteHooks';

const SearchResultList = (): JSX.Element => {
  const {searchTopList} = useAppSelector(searchTabStore);
  const {searchText, searchResult, isSearchText, userSearchResult} =
    React.useContext(SearchTabContext);
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = searchTabStyle(ColorTheme);
  const [tabIndex, setTabIndex] = useState<number>(FixedValue.CONSTANT_VALUE_0);
  const {favoriteLoader} = useFavouriteHooks();
  const showEmptyBox =
    (searchResult.length === FixedValue.CONSTANT_VALUE_0 &&
      tabIndex === FixedValue.CONSTANT_VALUE_0) ||
    (userSearchResult.length === FixedValue.CONSTANT_VALUE_0 &&
      tabIndex === FixedValue.CONSTANT_VALUE_1);

  const RenderEmptyUI = React.useMemo((): JSX.Element | null => {
    const showEmptyResult =
      searchText.length <= FixedValue.CONSTANT_VALUE_2 ||
      (searchText.length > FixedValue.CONSTANT_VALUE_2 &&
        showEmptyBox &&
        !isSearchText);
    return showEmptyResult ? (
      <SearchTabEmptyList
        showEmptyResutText={true}
        emptyViewContainer={styles.emptyViewContainer}
      />
    ) : null;
  }, [searchText, searchResult, isSearchText, showEmptyBox, userSearchResult]);

  const tabBarList: ISingleTabProps[] = [
    {
      routeName: ROUTE_NAME.RECEIVED_OFFER,
      component: topsListComponent,
      tabBarName: TEXT.TOPS,
    },
    {
      routeName: ROUTE_NAME.SENT_OFFER,
      component: searchUserListComponent,
      tabBarName: TEXT.USERS,
    },
  ];

  return (
    <View style={styles.contentContainer}>
      {(searchTopList.isLoading || favoriteLoader) && <AppLoader />}
      <TopTabBarLayout
        customTabStyle={styles.tabBarStyle}
        tabArray={tabBarList}
        setCurrentActiveTab={tabIndex}
        setTab={setTabIndex}
      />
      {!searchTopList.isLoading && RenderEmptyUI}
    </View>
  );
};

export default React.memo(SearchResultList);
