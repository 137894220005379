import {createSlice} from '@reduxjs/toolkit';

import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {ResetAllState} from './logoutslice';
import {NotificationState} from '../../types/reducerTypes/notificationTypes';

const {StoreConstants} = AppConst;
const {
  NOTIFICATION_LIST,
  NOTIF_TOKEN_UPDATE_ON_SERVER,
  NOTIFICATION_UNREAD_COUNT,
  NOTIFICATION_COMPLETED_LIST
} = ActionType;

const initialState: NotificationState = {
  notificationToken: null,
  tokenUpdateOnServerData: {
    isLoading: false,
    success: null,
    failed: null,
    status: NOTIF_TOKEN_UPDATE_ON_SERVER.END,
  },
  notificationData: {
    isLoading: true,
    success: null,
    failed: null,
    status: NOTIFICATION_LIST.END,
  },
  notificationCompletedData:{
    isLoading: true,
    success: null,
    failed: null,
    status: NOTIFICATION_COMPLETED_LIST.END,
  },
  notificationUnReadCount: {
    isLoading: false,
    success: null,
    failed: null,
    status: NOTIFICATION_UNREAD_COUNT.END,
  },
};

const NotificationSlice = createSlice({
  initialState,
  name: StoreConstants.NOTIFICATION,
  reducers: {
    updateNotificationToken: (state, action) => {
      state.notificationToken = action.payload;
    },
    onUpdateTokenOnServerStart: state => {
      state.tokenUpdateOnServerData.isLoading = true;
      state.tokenUpdateOnServerData.status = NOTIF_TOKEN_UPDATE_ON_SERVER.START;
    },
    onUpdateTokenOnServerSuccess: (state, action) => {
      state.tokenUpdateOnServerData.success = action.payload;
      state.tokenUpdateOnServerData.status =
        NOTIF_TOKEN_UPDATE_ON_SERVER.SUCCESS;
    },
    onUpdateTokenOnServerFailed: (state, action) => {
      state.tokenUpdateOnServerData.failed = action.payload;
      state.tokenUpdateOnServerData.status =
        NOTIF_TOKEN_UPDATE_ON_SERVER.FAILED;
    },
    onUpdateTokenOnServerEnd: state => {
      state.tokenUpdateOnServerData.isLoading = false;
      state.tokenUpdateOnServerData.status = NOTIF_TOKEN_UPDATE_ON_SERVER.END;
    },
    onNotificationListStart: (state, _) => {
      state.notificationData.isLoading = true;
      state.notificationData.status = NOTIFICATION_LIST.START;
    },
    onNotificationListSuccess: (state, action) => {
      state.notificationData.success = action.payload;
      state.notificationData.status = NOTIFICATION_LIST.SUCCESS;
    },
    onNotificationListFailed: (state, action) => {
      state.notificationData.failed = action.payload;
      state.notificationData.status = NOTIFICATION_LIST.FAILED;
    },
    onNotificationListEnd: state => {
      state.notificationData.isLoading = false;
      state.notificationData.status = NOTIFICATION_LIST.END;
    },
    onNotificationCompletedListStart: (state, _) => {
      state.notificationCompletedData.isLoading = true;
      state.notificationCompletedData.status = NOTIFICATION_COMPLETED_LIST.START;
    },
    onNotificationCompletedListSuccess: (state, action) => {
      state.notificationCompletedData.success = action.payload;
      state.notificationCompletedData.status = NOTIFICATION_COMPLETED_LIST.SUCCESS;
    },
    onNotificationCompletedListFailed: (state, action) => {
      state.notificationCompletedData.failed = action.payload;
      state.notificationCompletedData.status = NOTIFICATION_COMPLETED_LIST.FAILED;
    },
    onNotificationCompletedListEnd: state => {
      state.notificationCompletedData.isLoading = false;
      state.notificationCompletedData.status = NOTIFICATION_COMPLETED_LIST.END;
    },
    onNotificationCountStart: state => {
      state.notificationUnReadCount.isLoading = true;
      state.notificationUnReadCount.status = NOTIFICATION_UNREAD_COUNT.START;
    },
    onNotificationCountSuccess: (state, action) => {
      state.notificationUnReadCount.success = action.payload;
      state.notificationUnReadCount.status = NOTIFICATION_UNREAD_COUNT.SUCCESS;
    },
    onNotificationCountFailed: (state, action) => {
      state.notificationUnReadCount.failed = action.payload;
      state.notificationUnReadCount.status = NOTIFICATION_UNREAD_COUNT.FAILED;
    },
    onNotificationCountEnd: state => {
      state.notificationUnReadCount.isLoading = false;
      state.notificationUnReadCount.status = NOTIFICATION_UNREAD_COUNT.END;
    },
  },
  extraReducers: builder =>
    builder.addCase(ResetAllState, state => {
      state.notificationData = initialState.notificationData;
      state.tokenUpdateOnServerData = initialState.tokenUpdateOnServerData;
      state.notificationUnReadCount = initialState.notificationUnReadCount;
    }),
});

export const {
  updateNotificationToken,
  onNotificationListStart,
  onNotificationListSuccess,
  onNotificationListFailed,
  onNotificationListEnd,
  onNotificationCompletedListStart,
  onNotificationCompletedListSuccess,
  onNotificationCompletedListFailed,
  onNotificationCompletedListEnd,
  onUpdateTokenOnServerEnd,
  onUpdateTokenOnServerFailed,
  onUpdateTokenOnServerStart,
  onUpdateTokenOnServerSuccess,
  onNotificationCountEnd,
  onNotificationCountFailed,
  onNotificationCountStart,
  onNotificationCountSuccess,
} = NotificationSlice.actions;
export default NotificationSlice.reducer;
export const notificationState = (state: RootState) => state.notificationSlice;
