import React from 'react';
import {View, Pressable} from 'react-native';

import useEditorHooks from '../../../hooks/componentHooks/useEditorHooks';
import RenderEmptyImages from '../../editorComp/images/renderEmptyImages';
import AddImage from '../../renderSvgs/addImage';
import {styles} from './editorFooter.style';
import {TopEditorItemType} from '../../../types/componentTypes/editorType';
import {FixedValue} from '../../../constants';
import {EditorContext} from '../../../contextAPI/editorContext';
import {EditorImageContext} from '../../../contextAPI/editorImageContext';
import {controllerName} from '../../../constants/enum';
import ImageSizeController from '../../smallDesigns/sizeController';
import ImageRotationController from '../../smallDesigns/rotationController';
import {numberToArray} from '../../../utils/helperFunctions';

const ImageFooter = () => {
  const {_openGallery} = useEditorHooks();
  const {selectedItemIndex, items} = React.useContext(EditorContext);
  const {setOnSelectImage, selectedController, onSelectImage} =
    React.useContext(EditorImageContext);

  const sizeResult: number[] = numberToArray(
    onSelectImage?.current?.getPosition()?.scale
  );

  const rotationResult: number[] = numberToArray(
    onSelectImage?.current?.getPosition()?.rotation
  );

  const onChangeSizeValue = React.useCallback(
    (val: number[]): void => {
      onSelectImage?.current?.changeScale(val[0]);
    },
    [onSelectImage]
  );

  const onChangeRotationValue = React.useCallback(
    (val: number[]): void => {
      onSelectImage?.current?.changeRotation(val[0]);
    },
    [onSelectImage]
  );

  React.useEffect((): void => {
    if (selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1) {
      const item = // @ts-ignore -- used to get item ref
        items[selectedItemIndex]?.ref as NonNullRefObject<ImageRefType>;
      if (item?.current?.getTopEditorItemType() === TopEditorItemType.IMAGE) {
        setOnSelectImage(item);
      }
    } else {
      setOnSelectImage(null);
    }
  }, [selectedItemIndex]);

  return (
    <>
      {selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1 &&
        selectedController === controllerName.SCALE && (
          <ImageSizeController
            key={selectedItemIndex}
            onChangeValue={onChangeSizeValue}
            result={sizeResult}
            minValue={FixedValue.CONSTANT_VALUE_001}
            maxValue={FixedValue.CONSTANT_VALUE_1}
          />
        )}
      {selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1 &&
        selectedController === controllerName.ROTATION && (
          <ImageRotationController
            key={selectedItemIndex}
            onChangeValue={onChangeRotationValue}
            result={rotationResult}
          />
        )}
      <View style={styles.imagefooter}>
        <Pressable style={styles.addImageView} onPress={_openGallery}>
          <AddImage />
        </Pressable>
        <RenderEmptyImages />
      </View>
    </>
  );
};

export default React.memo(ImageFooter);
