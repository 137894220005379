import React from 'react';
import { View, Text, Pressable } from 'react-native';

import { ThemeContext } from '../../contextAPI/themeContext';
import { ICheckoxWithText } from '../../types/componentTypes/smallDesignsType';
import BlueCheck from '../renderSvgs/blueCheck';
import UncheckBox from '../renderSvgs/uncheckBox';
import { styles } from './smallDesign.style';

const CheckboxWithText = (props: ICheckoxWithText) => {
  const { termText, isAccept, onPress } = props;
  const { ColorTheme } = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  return (
    <View style={style.termView}>
      <Pressable onPress={onPress}>
        {isAccept ? <BlueCheck /> : <UncheckBox />}
      </Pressable>
      <Text style={style.termText}>{termText}</Text>
    </View>
  );
};

export default React.memo(CheckboxWithText);
