import * as React from 'react';
import Svg, {SvgProps, G, Path, Defs, ClipPath, Rect} from 'react-native-svg';
const NoInternet = (props: SvgProps) => (
  <Svg
    width={44}
    height={39}
    viewBox="0 0 44 39"
    fill="none"
    //@ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G clipPath="url(#clip0_6471_14504)">
      <Path
        d="M34.1129 10.183C32.8246 4.30076 27.608 0 21.5172 0C14.3991 0 8.6069 5.83252 8.6069 13.0004C3.85966 13.0004 0 16.887 0 21.6665C0 26.446 3.85966 30.3339 8.6069 30.3339V27.4439C5.44252 27.4439 2.86852 24.8524 2.86852 21.6665C2.86852 18.4806 5.44252 15.8891 8.6069 15.8891C8.9969 15.8891 9.41514 15.9402 9.84952 16.0398C10.3068 16.1473 10.7896 16.0182 11.1365 15.6995C11.4862 15.3794 11.6543 14.9088 11.5924 14.4394C11.5386 14.036 11.4754 13.5061 11.4754 13.0004C11.4754 7.42479 15.9806 2.88869 21.5172 2.88869C26.5684 2.88869 30.8463 6.69052 31.469 11.7336C31.5591 12.4571 32.2691 13.0004 32.9927 13.0004C36.9478 13.0004 40.166 16.2401 40.166 20.2222C40.166 23.7658 37.6538 26.758 34.1936 27.335L34.6616 30.1833C39.5137 29.3764 43.0345 25.1859 43.0345 20.2222C43.0357 17.7464 42.1278 15.3563 40.4832 13.5057C38.8386 11.6551 36.5717 10.4727 34.1129 10.183Z"
        fill="#1F53D3"
      />
      <Path
        d="M21.5175 39C24.1991 39 26.722 37.9484 28.6182 36.0387C29.5549 35.0991 30.2969 33.9838 30.8016 32.7567C31.3063 31.5297 31.5638 30.2151 31.5593 28.8883C31.5635 27.5617 31.3059 26.2474 30.8012 25.0206C30.2965 23.7938 29.5547 22.6787 28.6182 21.7392C27.6907 20.7986 26.5851 20.0522 25.3659 19.5438C24.1467 19.0353 22.8384 18.7749 21.5175 18.7779C18.8359 18.7779 16.313 19.8295 14.4168 21.7392C13.4802 22.6786 12.7383 23.7937 12.2336 25.0205C11.7289 26.2473 11.4713 27.5617 11.4756 28.8883C11.4756 31.59 12.5206 34.1291 14.4168 36.0387C15.3442 36.9793 16.4499 37.7257 17.669 38.2341C18.8882 38.7426 20.1965 39.003 21.5175 39ZM26.5888 23.782C27.2577 24.453 27.7876 25.2495 28.148 26.1258C28.5085 27.002 28.6925 27.9408 28.6894 28.8883C28.6894 30.3272 28.2725 31.699 27.5006 32.8717L17.5637 22.8621C18.7339 22.0791 20.1108 21.6624 21.5188 21.6652C22.4623 21.6632 23.3967 21.8493 24.2674 22.2125C25.1381 22.5757 25.9278 23.1088 26.5902 23.7806L26.5888 23.782ZM15.5343 24.9062L25.4726 34.9144C24.3024 35.6974 22.9255 36.1141 21.5175 36.1113C20.574 36.1133 19.6396 35.9273 18.7689 35.5641C17.8981 35.2009 17.1085 34.6678 16.4461 33.9959C15.7771 33.3247 15.2472 32.5279 14.8867 31.6515C14.5263 30.775 14.3423 29.836 14.3455 28.8883C14.3455 27.4507 14.7624 26.0762 15.5343 24.9062Z"
        fill="#1F53D3"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_6471_14504">
        <Rect width={43.0345} height={39} fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default React.memo(NoInternet);
