import React from 'react';
import {StyleSheet} from 'react-native';
import {
  KeyboardAwareScrollView,
  KeyboardAwareScrollViewProps,
} from 'react-native-keyboard-aware-scroll-view';

import {BASESTYLE} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {IScrollableViewProps} from '../../types/componentTypes/layoutType';
import {IColorTheme} from '../../types/contextTypes/themeContext';

// Define an interface that extends KeyboardAwareScrollViewProps and includes showsVerticalScrollIndicator property
interface CustomKeyboardAwareScrollViewProps
  extends KeyboardAwareScrollViewProps {
  showsVerticalScrollIndicator?: boolean;
}

const styles = (theme: IColorTheme) =>
  StyleSheet.create({
    scrollView: {
      ...BASESTYLE.flex1,
      backgroundColor: theme.backgroundColor,
    },
  });

const ScrollableContainer = (
  props: IScrollableViewProps & {
    KeyboardAwareProps?: CustomKeyboardAwareScrollViewProps;
  }
): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {children, KeyboardAwareProps} = props;
  const style = styles(ColorTheme);

  return (
    <KeyboardAwareScrollView
      style={style.scrollView}
      {...KeyboardAwareProps}
      keyboardShouldPersistTaps="handled"
    >
      {children}
    </KeyboardAwareScrollView>
  );
};

export default React.memo(ScrollableContainer);
