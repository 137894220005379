import {takeLatest, put, call, select, delay} from 'redux-saga/effects';
import {AppConst, FixedValue, TEXT} from '../../constants';
import {IActionType} from '../../types/constantFilesTypes';
import {
  IChildListResponse,
  IChildProfileResponse,
  IResendRequestResponse,
  IUserBlockedResponse,
  IUserData,
  IUserFavoriteListResponse,
} from '../../types/reducerTypes/userProfileTypes';
import {
  _getUserProfileData,
  _getParentChildList,
  _getChildProfile,
  _resendApprovalRequest,
  _myTransactionAPICall,
  _butPlanAPICall,
  _blockUserList,
  _unBlockUser,
  _favoriteUserList,
  _verifyFavoriteUser,
  _markAndUnmarkUser,
  _tranferTopCredit,
  _pullBackTopCredit,
} from '../../utils/apis';
import {authState} from '../reducers/authSlice';
import {
  onUserDataEnd,
  onUserDataFailed,
  onUserDataSuccess,
  onUserChildListSuccess,
  onUserChildListFailed,
  onUserChildListEnd,
  onUserChildProfileSuccess,
  onUserChildProfileFailed,
  onUserChildProfileEnd,
  onResendApprovalRequestSuccess,
  onResendApprovalRequestFailed,
  onResendApprovalRequestEnd,
  onUserPlanPurchaseSuccess,
  onUserPlanPurchaseFailed,
  onUserPlanPurchaseEnd,
  onWalletTransactionHistorySuccess,
  onWalletTransactionHistoryFailed,
  onWalletTransactionHistoryEnd,
  onUserBlockListSuccess,
  onUserBlockListFailed,
  onUserBlockListEnd,
  onUserUnBlockSuccess,
  onUserUnBlockFailed,
  onUserUnBlockEnd,
  onFavoriteUserListSuccess,
  onFavoriteUserListFailed,
  onFavoriteUserListEnd,
  onIsUserIsFavoriteSuccess,
  onIsUserIsFavoriteFailed,
  onIsUserIsFavoriteEnd,
  onUserMarkAndUnmarkFavoriteSuccess,
  onUserMarkAndUnmarkFavoriteFailed,
  onUserMarkAndUnmarkFavoriteEnd,
  onTransferTopCreditSuccess,
  onTransferTopCreditFailed,
  onTransferTopCreditEnd,
  onTopCreditPullbackSuccess,
  onTopCreditPullbackFailed,
  onTopCreditPullbackEnd,
} from '../reducers/userProfileSlice';
import {isWebsite} from '../../utils/responsive';
import {_ToastHandler} from '../../utils/helperFunctions';
import {ITransactionHistoryAPIResponse} from '../../types/componentTypes/userProfileTypes';

const {StoreConstants} = AppConst;

function* getUserData() {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserData = yield call(_getUserProfileData, userTokens);
    yield put(onUserDataSuccess(data.data));
  } catch (error: any) {
    error?.errors[0]?.code !== 'APPROVAL_PENDING' &&
      _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onUserDataFailed(error?.errors[0]?.msg ?? ''));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onUserDataEnd());
  }
}

function* getChildList() {
  try {
    const {userTokens} = yield select(authState);
    const data: IChildListResponse = yield call(
      _getParentChildList,
      userTokens
    );
    yield put(onUserChildListSuccess(data.data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onUserChildListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onUserChildListEnd());
  }
}
function* getChildProfile(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IChildProfileResponse = yield call(
      _getChildProfile,
      action.payload,
      userTokens
    );
    yield put(onUserChildProfileSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onUserChildProfileFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onUserChildProfileEnd());
  }
}

function* resendApprovalRequestData() {
  try {
    const {userTokens} = yield select(authState);
    const data: IResendRequestResponse = yield call(
      _resendApprovalRequest,
      userTokens
    );
    yield put(onResendApprovalRequestSuccess(data.data));
  } catch (error: any) {
    yield put(onResendApprovalRequestFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onResendApprovalRequestEnd());
  }
}

//purchase plan api
function* userPlanPurchaseAPIFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ITransactionHistoryAPIResponse = yield call(
      _butPlanAPICall,
      action.payload,
      userTokens
    );
    yield put(onUserPlanPurchaseSuccess(data.data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onUserPlanPurchaseFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onUserPlanPurchaseEnd());
  }
}

//my transaction history list api
function* walletTransactionListFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ITransactionHistoryAPIResponse = yield call(
      _myTransactionAPICall,
      action.payload,
      userTokens
    );
    yield put(onWalletTransactionHistorySuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onWalletTransactionHistoryFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onWalletTransactionHistoryEnd());
  }
}

function* blockUserListFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserBlockedResponse = yield call(
      _blockUserList,
      action.payload,
      userTokens
    );
    yield put(onUserBlockListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onUserBlockListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onUserBlockListEnd());
  }
}

function* unBlockUserFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserBlockedResponse = yield call(
      _unBlockUser,
      action.payload,
      userTokens
    );
    yield put(onUserUnBlockSuccess(data.data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onUserUnBlockFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onUserUnBlockEnd());
  }
}

function* favoriteUserListFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserFavoriteListResponse = yield call(
      _favoriteUserList,
      action.payload,
      userTokens
    );
    yield put(onFavoriteUserListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onFavoriteUserListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onFavoriteUserListEnd());
  }
}

function* verifyUserIsfavorite(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IResendRequestResponse = yield call(
      _verifyFavoriteUser,
      action.payload,
      userTokens
    );
    yield put(onIsUserIsFavoriteSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onIsUserIsFavoriteFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onIsUserIsFavoriteEnd());
  }
}

function* markAndUnmarkFavorite(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IResendRequestResponse = yield call(
      _markAndUnmarkUser,
      action.payload,
      userTokens
    );
    yield put(onUserMarkAndUnmarkFavoriteSuccess(data));
  } catch (error: any) {
    alert(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onUserMarkAndUnmarkFavoriteFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onUserMarkAndUnmarkFavoriteEnd());
  }
}
function* transferTopCreditFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IResendRequestResponse = yield call(
      _tranferTopCredit,
      action.payload,
      userTokens
    );
    yield put(onTransferTopCreditSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(onTransferTopCreditFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onTransferTopCreditEnd());
  }
}

function* topCreditPullBackFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IResendRequestResponse = yield call(
      _pullBackTopCredit,
      action.payload,
      userTokens
    );
    yield put(onTopCreditPullbackSuccess(data));
  } catch (error: any) {
    alert(
      error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ??
        TEXT.SOMETHING_WENT_WRONG
    );
    yield put(onTopCreditPullbackFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onTopCreditPullbackEnd());
  }
}
export default function* userProfileSaga() {
  yield takeLatest(
    `${StoreConstants.USER_PROFILE}/onUserDataStart`,
    getUserData
  );
  yield takeLatest(
    `${StoreConstants.USER_PROFILE}/onUserChildListStart`,
    getChildList
  );
  yield takeLatest(
    `${StoreConstants.USER_PROFILE}/onUserChildProfileStart`,
    getChildProfile
  );
  yield takeLatest(
    `${StoreConstants.USER_PROFILE}/onResendApprovalRequestStart`,
    resendApprovalRequestData
  );
  yield takeLatest(
    `${StoreConstants.USER_PROFILE}/onUserPlanPurchaseStart`,
    userPlanPurchaseAPIFunction
  );
  yield takeLatest(
    `${StoreConstants.USER_PROFILE}/onWalletTransactionHistoryStart`,
    walletTransactionListFunction
  );
  yield takeLatest(
    `${StoreConstants.USER_PROFILE}/onUserBlockListStart`,
    blockUserListFunction
  );
  yield takeLatest(
    `${StoreConstants.USER_PROFILE}/onUserUnBlockStart`,
    unBlockUserFunction
  );
  yield takeLatest(
    `${StoreConstants.USER_PROFILE}/onFavoriteUserListStart`,
    favoriteUserListFunction
  );
  yield takeLatest(
    `${StoreConstants.USER_PROFILE}/onIsUserIsFavoriteStart`,
    verifyUserIsfavorite
  );
  yield takeLatest(
    `${StoreConstants.USER_PROFILE}/onUserMarkAndUnmarkFavoriteStart`,
    markAndUnmarkFavorite
  );
  yield takeLatest(
    `${StoreConstants.USER_PROFILE}/onTransferTopCreditStart`,
    transferTopCreditFunction
  );
  yield takeLatest(
    `${StoreConstants.USER_PROFILE}/onTopCreditPullbackStart`,
    topCreditPullBackFunction
  );
}
