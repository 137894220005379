import {takeLatest, put, call, select, delay} from 'redux-saga/effects';
import {AppConst, FixedValue} from '../../constants';
import {IActionType} from '../../types/constantFilesTypes';
import {
  ICreateGroupSagaRespones,
  IGroupDetailAPIRespones,
  IGroupUserListSuccessResponse,
  IUserAddOnGroupRespones,
  IUserListSuccessRespones,
} from '../../types/reducerTypes/groupMangementTypes';
import {
  _createNewGroup,
  _getGroupUserList,
  _getUserList,
  _onDeleteGroupAPI,
  _onGroupDetailAPI,
  _onGroupInListAPI,
  _onLeaveGroupAPI,
  _onMyGroupInvitations,
  _onMyGroupInvitationsApprove,
  _onMyGroupInvitationsReject,
  _onMyGroupListAPI,
  _onRemoveUserFromGroupAPI,
  _onUserAddInGroup,
  _updateGroup,
} from '../../utils/apis';
import {authState} from '../reducers/authSlice';
import {
  onCreateGroupEnd,
  onCreateGroupFailed,
  onCreateGroupSuccess,
  onUserListFailed,
  onUpdateGroupSuccess,
  onUpdateGroupFailed,
  onUpdateGroupEnd,
  onUserListSuccess,
  onUserListEnd,
  onUserAddOnGroupSuccess,
  onUserAddOnGroupFailed,
  onUserAddOnGroupEnd,
  onMyGroupsSuccess,
  onMyGroupsFailed,
  onMyGroupsEnd,
  onGroupsInSuccess,
  onGroupsInFailed,
  onGroupsInEnd,
  onGroupInvitationsSuccess,
  onGroupInvitationsFailed,
  onGroupInvitationsEnd,
  onGroupInvitationsApprovedSuccess,
  onGroupInvitationsApprovedFailed,
  onGroupInvitationsApprovedEnd,
  onGroupInvitationsRejectSuccess,
  onGroupInvitationsRejectFailed,
  onGroupInvitationsRejectEnd,
  onGroupUserListSuccess,
  onGroupUserListFailed,
  onGroupUserListEnd,
  onGroupDetailApiSuccess,
  onGroupDetailApiFailed,
  onGroupDetailApiEnd,
  onRemoveUserFromGroupSuccess,
  onRemoveUserFromGroupFailed,
  onRemoveUserFromGroupEnd,
  onGroupDeleteApiSuccess,
  onGroupDeleteApiFailed,
  onGroupDeleteApiEnd,
  onGroupLeaveApiSuccess,
  onGroupLeaveApiFailed,
  onGroupLeaveApiEnd,
} from '../reducers/groupManagementSlice';
import {isWebsite} from '../../utils/responsive';
import {_ToastHandler} from '../../utils/helperFunctions';
import {TOAST_TEXT} from '../../constants/textMessage';

const {StoreConstants} = AppConst;

// Create Group By name and group image API
function* createNewGroup(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ICreateGroupSagaRespones = yield call(
      _createNewGroup,
      action.payload,
      userTokens
    );
    yield put(onCreateGroupSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onCreateGroupFailed(error));
  } finally {
    if (isWebsite()) {
      yield delay(FixedValue.CONSTANT_VALUE_1);
    }
    yield put(onCreateGroupEnd());
  }
}

// update Group By name and group image API
function* updateGroupSagaFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserAddOnGroupRespones = yield call(
      _updateGroup,
      action.payload,
      userTokens
    );
    yield put(onUpdateGroupSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onUpdateGroupFailed(error));
  } finally {
    if (isWebsite()) {
      yield delay(FixedValue.CONSTANT_VALUE_1);
    }
    yield put(onUpdateGroupEnd());
  }
}

// Call get User List API
function* getUserList(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserListSuccessRespones = yield call(
      _getUserList,
      action.payload,
      userTokens
    );
    yield put(onUserListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onUserListFailed(error));
  } finally {
    if (isWebsite()) {
      yield delay(FixedValue.CONSTANT_VALUE_1);
    }
    yield put(onUserListEnd());
  }
}

//On User ADD In Group API
function* onUserAddInGroup(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserAddOnGroupRespones = yield call(
      _onUserAddInGroup,
      action.payload,
      userTokens
    );
    yield put(onUserAddOnGroupSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onUserAddOnGroupFailed(error));
  } finally {
    if (isWebsite()) {
      yield delay(FixedValue.CONSTANT_VALUE_1);
    }
    yield put(onUserAddOnGroupEnd());
  }
}

//On My Group List API
function* onMyGroupListAPI(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserAddOnGroupRespones = yield call(
      _onMyGroupListAPI,
      action.payload,
      userTokens
    );
    yield put(onMyGroupsSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onMyGroupsFailed(error));
  } finally {
    if (isWebsite()) {
      yield delay(FixedValue.CONSTANT_VALUE_1);
    }
    yield put(onMyGroupsEnd());
  }
}

//On Group i am in List API
function* onGroupInListAPI(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserAddOnGroupRespones = yield call(
      _onGroupInListAPI,
      action.payload,
      userTokens
    );
    yield put(onGroupsInSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onGroupsInFailed(error));
  } finally {
    if (isWebsite()) {
      yield delay(FixedValue.CONSTANT_VALUE_1);
    }
    yield put(onGroupsInEnd());
  }
}

//On Group Invitations List API
function* onMyGroupInvitations(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserAddOnGroupRespones = yield call(
      _onMyGroupInvitations,
      action.payload,
      userTokens
    );
    yield put(onGroupInvitationsSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onGroupInvitationsFailed(error));
  } finally {
    if (isWebsite()) {
      yield delay(FixedValue.CONSTANT_VALUE_1);
    }
    yield put(onGroupInvitationsEnd());
  }
}

//On Group Invitations Approve API
function* onGroupInvitationsApprove(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserAddOnGroupRespones = yield call(
      _onMyGroupInvitationsApprove,
      action.payload,
      userTokens
    );
    _ToastHandler(TOAST_TEXT.GROUP_INVITATION_ACCEPTED, true);
    yield put(onGroupInvitationsApprovedSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onGroupInvitationsApprovedFailed(error));
  } finally {
    if (isWebsite()) {
      yield delay(FixedValue.CONSTANT_VALUE_1);
    }
    yield put(onGroupInvitationsApprovedEnd());
  }
}

//On Group Invitations Reject API
function* onGroupInvitationsReject(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserAddOnGroupRespones = yield call(
      _onMyGroupInvitationsReject,
      action.payload,
      userTokens
    );
    _ToastHandler(TOAST_TEXT.GROUP_INVITATION_REJECTED, true);
    yield put(onGroupInvitationsRejectSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onGroupInvitationsRejectFailed(error));
  } finally {
    if (isWebsite()) {
      yield delay(FixedValue.CONSTANT_VALUE_1);
    }
    yield put(onGroupInvitationsRejectEnd());
  }
}

// Call get group User List API
function* getGroupUserList(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IGroupUserListSuccessResponse = yield call(
      _getGroupUserList,
      action.payload,
      userTokens
    );
    yield put(onGroupUserListSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onGroupUserListFailed(error));
  } finally {
    if (isWebsite()) {
      yield delay(FixedValue.CONSTANT_VALUE_1);
    }
    yield put(onGroupUserListEnd());
  }
}

//On Group Detail API
function* onGroupDetailAPI(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IGroupDetailAPIRespones = yield call(
      _onGroupDetailAPI,
      action.payload,
      userTokens
    );
    yield put(onGroupDetailApiSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onGroupDetailApiFailed(error));
  } finally {
    if (isWebsite()) {
      yield delay(FixedValue.CONSTANT_VALUE_1);
    }
    yield put(onGroupDetailApiEnd());
  }
}

//On remove user from group API
function* onRemoveUserFromGroupAPI(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserAddOnGroupRespones = yield call(
      _onRemoveUserFromGroupAPI,
      action.payload,
      userTokens
    );
    yield put(onRemoveUserFromGroupSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onRemoveUserFromGroupFailed(error));
  } finally {
    if (isWebsite()) {
      yield delay(FixedValue.CONSTANT_VALUE_1);
    }
    yield put(onRemoveUserFromGroupEnd());
  }
}

//On delete group API
function* onDeleteGroupAPIFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserAddOnGroupRespones = yield call(
      _onDeleteGroupAPI,
      action.payload,
      userTokens
    );
    yield put(onGroupDeleteApiSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onGroupDeleteApiFailed(error));
  } finally {
    if (isWebsite()) {
      yield delay(FixedValue.CONSTANT_VALUE_1);
    }
    yield put(onGroupDeleteApiEnd());
  }
}

//On leave group API
function* onLeaveGroupAPIFunction(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserAddOnGroupRespones = yield call(
      _onLeaveGroupAPI,
      action.payload,
      userTokens
    );
    yield put(onGroupLeaveApiSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onGroupLeaveApiFailed(error));
  } finally {
    if (isWebsite()) {
      yield delay(FixedValue.CONSTANT_VALUE_1);
    }
    yield put(onGroupLeaveApiEnd());
  }
}

export default function* groupManagementSaga() {
  yield takeLatest(
    `${StoreConstants.GROUP_MANAGEMENT}/onCreateGroupStart`,
    createNewGroup
  );
  yield takeLatest(
    `${StoreConstants.GROUP_MANAGEMENT}/onUpdateGroupStart`,
    updateGroupSagaFunction
  );
  yield takeLatest(
    `${StoreConstants.GROUP_MANAGEMENT}/onUserListStart`,
    getUserList
  );
  yield takeLatest(
    `${StoreConstants.GROUP_MANAGEMENT}/onUserAddOnGroupStart`,
    onUserAddInGroup
  );
  yield takeLatest(
    `${StoreConstants.GROUP_MANAGEMENT}/onMyGroupsStart`,
    onMyGroupListAPI
  );
  yield takeLatest(
    `${StoreConstants.GROUP_MANAGEMENT}/onGroupsInStart`,
    onGroupInListAPI
  );
  yield takeLatest(
    `${StoreConstants.GROUP_MANAGEMENT}/onGroupInvitationsStart`,
    onMyGroupInvitations
  );
  yield takeLatest(
    `${StoreConstants.GROUP_MANAGEMENT}/onGroupInvitationsApprovedStart`,
    onGroupInvitationsApprove
  );
  yield takeLatest(
    `${StoreConstants.GROUP_MANAGEMENT}/onGroupInvitationsRejectStart`,
    onGroupInvitationsReject
  );
  yield takeLatest(
    `${StoreConstants.GROUP_MANAGEMENT}/onGroupUserListStart`,
    getGroupUserList
  );
  yield takeLatest(
    `${StoreConstants.GROUP_MANAGEMENT}/onGroupDetailApiStart`,
    onGroupDetailAPI
  );
  yield takeLatest(
    `${StoreConstants.GROUP_MANAGEMENT}/onRemoveUserFromGroupStart`,
    onRemoveUserFromGroupAPI
  );
  yield takeLatest(
    `${StoreConstants.GROUP_MANAGEMENT}/onGroupDeleteApiStart`,
    onDeleteGroupAPIFunction
  );
  yield takeLatest(
    `${StoreConstants.GROUP_MANAGEMENT}/onGroupLeaveApiStart`,
    onLeaveGroupAPIFunction
  );
}
