import React from 'react';
import {FlatList, View} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';
import {ThemeContext} from '../../../contextAPI/themeContext';
import useTradingApprovalHooks from '../../../hooks/componentHooks/useTradingApprovalHooks';
import {ChildProfileContext} from '../../../contextAPI/childProfileContext';
import ViewContainer from '../../../components/layout/viewContainer';
import {TopReceivedStyle} from '../../topTradingApprovel/topTradingReceived/topReceived.style';
import CustomButton from '../../../components/button/customButton';
import {FixedValue, TEXT} from '../../../constants';
import TICKICON from '../../../components/renderSvgs/tickIcon';
import CROSSICON from '../../../components/renderSvgs/smallCrossIcon';
import ReportImage from '../../../components/renderSvgs/reportImage';
import TopsListEmptyView from '../../../components/emptyListView/topsListEmptyView';
import ChildTradeComponent from '../../../components/childTradeComponent';
import {DistributeRequestModel} from '../../../types/componentTypes/childProfileType';
import RenderTopImage from '../../../components/RenderItemTile/renderTopImage';
import NoImage from '../../../components/renderSvgs/noImage';
import {childProfileStore} from '../../../redux/reducers/childProfileSlice';
import {useAppSelector} from '../../../hooks/reduxHooks';
import {TradeContext} from '../../../contextAPI/tradeContext';

const DistributingReceivedRequestContent = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = TopReceivedStyle(ColorTheme);
  const {
    OnChildDistributeReceivedListEndReach,
    pullDownToRefreshOnDistributeReceivedList,
    navigateToTopDetailScreen,
  } = useTradingApprovalHooks();
  const {
    distributeReceivedRequestListArray,
    childProfileContextPullDownToRefresh,
    initialLoader,
    setSelectedTradeReceivedData,
    setShowDistributeRequestConfirmModal,
    setIsTradeReceivedApproved,
  } = React.useContext(ChildProfileContext);
  const {setOpenReportModal} = React.useContext(TradeContext);
  const {onDistributeReceivedRequestData} = useAppSelector(childProfileStore);

  const distributeApproveRejectButton = (data: DistributeRequestModel) => {
    return (
      <View style={styles.buttonView}>
        <CustomButton
          buttonLabel={TEXT.APPROVE}
          buttonwidth={styles.approveButton}
          labelStyle={styles.primaryButtonLabelstyle}
          buttonBackGroundColor={styles.noButtonBackGroundColor1}
          element={<TICKICON />}
          onPress={() => {
            setSelectedTradeReceivedData(data);
            setIsTradeReceivedApproved(true);
            setShowDistributeRequestConfirmModal();
          }}
        />
        <CustomButton
          buttonLabel={TEXT.REJECT}
          buttonwidth={styles.rejectButton}
          labelStyle={styles.primaryButtonLabelstyle1}
          buttonBackGroundColor={styles.noButtonBackGroundColor}
          element={<CROSSICON />}
          onPress={() => {
            setSelectedTradeReceivedData(data);
            setIsTradeReceivedApproved(false);
            setShowDistributeRequestConfirmModal();
          }}
        />
        <CustomButton
          buttonLabel={TEXT.REPORT}
          buttonwidth={styles.rejectButton}
          labelStyle={styles.primaryButtonLabelstyle1}
          buttonBackGroundColor={styles.noButtonBackGroundColor}
          element={
            <ReportImage
              width={FixedValue.CONSTANT_VALUE_12}
              height={FixedValue.CONSTANT_VALUE_12}
            />
          }
          onPress={() => {
            setSelectedTradeReceivedData(data);
            setOpenReportModal(true);
          }}
        />
      </View>
    );
  };

  const renderChildDistributeRequestList =
    React.useMemo((): JSX.Element | null => {
      return (
        <FlatList
          data={distributeReceivedRequestListArray}
          keyExtractor={(_, index) =>
            `${TEXT.CHILD_DISTRIBUTE_RECEIVED_REQUEST_LIST}-${index}`
          }
          showsVerticalScrollIndicator={false}
          scrollEnabled
          contentContainerStyle={styles.flatListContentContainerStyle}
          onEndReachedThreshold={FixedValue.CONSTANT_VALUE_1}
          ListEmptyComponent={
            onDistributeReceivedRequestData.isLoading || initialLoader ? (
              <></>
            ) : (
              <TopsListEmptyView
                emptyViewTitle={TEXT.NO_REQUEST_TO_SHOW}
                emptyViewDesc={''}
                placeHolder={<NoImage />}
              />
            )
          }
          onEndReached={OnChildDistributeReceivedListEndReach}
          refreshControl={
            <RefreshControl
              refreshing={childProfileContextPullDownToRefresh}
              onRefresh={pullDownToRefreshOnDistributeReceivedList}
            />
          }
          renderItem={({item}) => (
            <>
              <ChildTradeComponent
                key={item.requestId}
                userDetail={item?.fromUser}
                creationDate={item?.creationDate}
                showDistributeTopTitle={true}
              />
              <RenderTopImage
                item={item.top}
                key={item?.top.id}
                showTopDetail={navigateToTopDetailScreen}
              />
              {distributeApproveRejectButton(item)}
              <View style={styles.bottomLineStyle} />
            </>
          )}
        />
      );
    }, [distributeReceivedRequestListArray, onDistributeReceivedRequestData]);

  return (
    <View style={styles.receivedMainContainer}>
      <ViewContainer>{renderChildDistributeRequestList}</ViewContainer>
    </View>
  );
};

export default React.memo(DistributingReceivedRequestContent);
