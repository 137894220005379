import React from 'react';
import {Pressable, View} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {ShiftingStyle} from './shifting.style';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import Settings from '../../components/renderSvgs/settings';
import {FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import ShiftingTradeableContent from '../shiftingToTradeable/shitingScreen';
import {navigate} from '../../services/navigationService';
import {useIsFocused} from '@react-navigation/native';
import useTradingApprovalHooks from '../../hooks/componentHooks/useTradingApprovalHooks';
import ConfirmationModal from '../../modals/confirmationModal/confirmationModal';
import {ChildProfileContext} from '../../contextAPI/childProfileContext';
import {_ToastHandler} from '../../utils/helperFunctions';
import {CHILD_TRADABLE_APPROVED_REJECT} from '../../constants/actionTypes';
import {useAppSelector} from '../../hooks/reduxHooks';
import {childProfileStore} from '../../redux/reducers/childProfileSlice';
import TopBarWithRightButton from '../../components/header/topBarWithRightButton';

const ShiftingTradeable = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = ShiftingStyle(ColorTheme);
  const isFocused = useIsFocused();
  const {getChildTradableRequestList, childTradableApproveRejecApiCall} =
    useTradingApprovalHooks();
  const {
    isApproveChildTradable,
    modalVisibleChildTradable,
    setModalVisibleChildTradable,
    setInitialLoader,
  } = React.useContext(ChildProfileContext);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const {childTradableApproveReject} = useAppSelector(childProfileStore);

  React.useEffect(() => {
    if (isFocused) {
      setInitialLoader(true);
      getChildTradableRequestList(FixedValue.CONSTANT_VALUE_0);
    }
  }, [isFocused]);

  React.useEffect(() => {
    switch (childTradableApproveReject.status) {
      case CHILD_TRADABLE_APPROVED_REJECT.SUCCESS:
        getChildTradableRequestList(FixedValue.CONSTANT_VALUE_0);
        setModalVisibleChildTradable(false);
        break;
      case CHILD_TRADABLE_APPROVED_REJECT.FAILED:
        setModalVisibleChildTradable(false);
        break;
      default:
        break;
    }
  }, [childTradableApproveReject.status]);

  React.useEffect(() => {
    setLoading(childTradableApproveReject.isLoading);
  }, [childTradableApproveReject.isLoading]);

  const onPress = React.useCallback((): void => {
    navigate(ROUTE_NAME.SHIFTING_SETTING);
  }, []);

  return (
    <SafeAreaContainer>
      <View
        style={styles.topTradingMainContainer}
        dataSet={{media: ids.topTradingMainContainer}}
      >
        <TopBarWithRightButton
          headerLabel={TEXT.SHIFTING_TO_TRADEABLE}
          Icon={
            <Pressable onPress={onPress}>
              <Settings stroke={ColorTheme.color} />
            </Pressable>
          }
          headerColor={ColorTheme.color}
        />
        <ShiftingTradeableContent />
        <ConfirmationModal
          title={
            isApproveChildTradable
              ? TEXT.APPROVE_TRADABLE_REQUEST
              : TEXT.REJECT_TRADABLE_REQUEST
          }
          modalVisible={modalVisibleChildTradable}
          onPressYes={childTradableApproveRejecApiCall}
          onShowHide={() => setModalVisibleChildTradable(false)}
          isLoading={isLoading}
        />
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(ShiftingTradeable);
