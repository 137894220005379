import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";
const DeleteAlertcircle = (props:SvgProps) => (
  <Svg
    width={49}
    height={48}
    viewBox="0 0 49 48"
    fill="none"
  // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M24.5 44C35.5457 44 44.5 35.0457 44.5 24C44.5 12.9543 35.5457 4 24.5 4C13.4543 4 4.5 12.9543 4.5 24C4.5 35.0457 13.4543 44 24.5 44Z"
      stroke="#F54336"
      strokeWidth={6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M24.5 32H24.52"
      stroke="#F54336"
      strokeWidth={6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M24.5 16V24"
      stroke="#F54336"
      strokeWidth={6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default DeleteAlertcircle;
