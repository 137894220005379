import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const modalStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
      justifyContent: GlobalStyleValues.FLEX_END,
      width: isWebsite()
        ? FixedValue.CONSTANT_VALUE_500
        : Percentage.PRECENTAGE_100,
      alignItems: GlobalStyleValues.CENTER,
    },
    subContainer: {
      width: Percentage.PRECENTAGE_100,
      backgroundColor: _theme?.backgroundColor,
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_18,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_18,
      shadowColor: _theme?.color,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_15),
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_18),
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_0,
        height: -FixedValue.CONSTANT_VALUE_3,
      },
      alignItems: GlobalStyleValues.CENTER,
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
      elevation: FixedValue.CONSTANT_VALUE_3,
      ...BASESTYLE.center,
      shadowRadius: FixedValue.CONSTANT_VALUE_5,
    },
    topView: {
      width: Percentage.PRECENTAGE_100,
      ...BASESTYLE.center,
      ...BASESTYLE.alignCenter,
    },
    headingText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_15),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: _theme.color,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_20),
    },
    newCollectionView: {
      ...BASESTYLE.row,
      ...BASESTYLE.alignCenter,
      marginVertical: FixedValue.CONSTANT_VALUE_10,
    },
    buttonsView: {
      ...BASESTYLE.row,
      ...BASESTYLE.spaceBetween,
      ...BASESTYLE.alignCenter,
      width: Percentage.PRECENTAGE_100,
    },
    buttonWidth: {
      width: Percentage.PRECENTAGE_50,
    },
    doneTextColor: {
      color: COLORS._4EAF51,
    },
    outterPart: {
      ...BASESTYLE.absolutePosition,
      zIndex: FixedValue.CONSTANT_VALUE_MIN_1,
      backgroundColor: COLORS.LGHT_BLCK,
      width: Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
    },
    quantitySelectorView: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      ...BASESTYLE.spaceBetween,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_58),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_168),
      margin: FixedValue.CONSTANT_VALUE_10,
      backgroundColor: COLORS._4EAF51,
      overflow: GlobalStyleValues.HIDDEN,
      borderRadius: FixedValue.CONSTANT_VALUE_58,
    },
    quantityText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_25),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_30),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
    plusText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_34),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_40),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_4),
    },
    plusStyle: {
      ...BASESTYLE.inCenter,
      right: FixedValue.CONSTANT_VALUE_15,
      backgroundColor: COLORS.TRANSPARENT,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_24),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_58),
    },
    minusStyle: {
      backgroundColor: COLORS.TRANSPARENT,
      left: FixedValue.CONSTANT_VALUE_15,
      ...BASESTYLE.inCenter,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_24),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_58),
    },
  });
