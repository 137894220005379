import StyleSheet from 'react-native-media-query';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {BASESTYLE, FONT_NAMES, FixedValue, Percentage} from '../../constants';
import {
  SCREEN_WIDTH,
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const ShiftingStyle = (_theme?: IColorTheme) =>
  StyleSheet.create({
    topTradingMainContainer: {
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
      height: Percentage.PRECENTAGE_100,
    },
    topTradingHeaderstyle: {
      ...BASESTYLE.inRow,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    topTradingHeadertext: {
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.flex1,
    },
    topTradingTitleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: _theme?.color,
    },
    headerText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_24),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: _theme?.color,
    },
    day: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_10),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: _theme?.color,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_24),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_1),
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_36),
    },
    flatListContentContainerStyle: {
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_70),
    },
    noChildData:{
      marginTop:scaleHeight(FixedValue.CONSTANT_VALUE_100)
    }
  });
