import {StyleSheet} from 'react-native';
import {BASESTYLE, COLORS, FixedValue, FONT_NAMES} from '../../constants';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';

export const tagStyle = StyleSheet.create({
  container: {
    ...BASESTYLE.inCenter,
    height: scaleHeight(FixedValue.CONSTANT_VALUE_32),
    backgroundColor: COLORS.E9EEFB,
    borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_59),
    marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_11),
    paddingRight: scaleWidth(FixedValue.CONSTANT_VALUE_11),
  },
  tagText: {
    fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
    color: COLORS.PRIMARY_BLUE,
    fontFamily: FONT_NAMES.SF_MEDIUM,
  },
});
