import React, {useEffect, useMemo, useState} from 'react';
import {
  View,
  Text,
  Image,
  FlatList,
  ActivityIndicator,
  Alert,
  Pressable,
} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {trackingTabDetailStyle} from './trackingDetail.style';
import ModalContainer from '../../components/layout/modalContainer';
import ModalHeaderWithCross from '../../components/header/modalHeaderWithCross';
import {
  COLORS,
  FixedValue,
  FONT_NAMES,
  Percentage,
  TEXT,
} from '../../constants';
import SearchInput from '../../components/input/searchInput';
import {
  alertPolyfill,
  getDateTimeUtil,
  getInitials,
} from '../../utils/helperFunctions';
import TrackingUserTile from './trackingUserTile';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import {
  ontrackingDetailStart,
  trackingDetailState,
} from '../../redux/reducers/trackingDetailSlice';
import {TrackingDetailResultsModel} from '../../types/componentTypes/childProfileType';
import {REQUEST_TYPE} from '../../constants/textMessage';
import {
  ontrackingCancelStart,
  TrackingCancelState,
} from '../../redux/reducers/trackingCancelSlice';
import AppLoader from '../../components/smallDesigns/appLoader';
import {onSendReminderStart} from '../../redux/reducers/sendReminderSlice';
import {
  TRACKING_CANCEL,
  TRACKING_DETAIL,
  USER_MARK_UNMARK_FAVORITE,
} from '../../constants/actionTypes';
import NoChildData from '../../components/renderSvgs/noChildDataPlaceholder';
import useAwsS3 from '../../hooks/libraryHooks/useAwsS3';
import {getTopImage} from '../../utils/imageUtils';
import useFavouriteHooks from '../../hooks/componentHooks/useFavouriteHooks';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import {useIsFocused} from '@react-navigation/native';
import CloseRed from '../../components/renderSvgs/closeRed';
import RightArrowBlue from '../../components/renderSvgs/RightArrowBlue';
import {onSendReminderAllStart} from '../../redux/reducers/sendReminderAllSlice';
import {BadgeProps} from '../../types/reducerTypes/tradeTabTypes';

interface RenderContentForImageProps {
  isForDistribution: boolean;
  svgCode: JSX.Element | null;
  profileImageUrl: string | null;
  name: string | null;
  COLORS: {
    PRIMARY_BLUE: string;
  };
  getInitials: (name: string) => string;
}

const TrackingDetailModel = ({
  isOpen,
  setIsOpen,
  // @ts-ignore
  item,
  // @ts-ignore
  setSelectedItem2,
}: {
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
}): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = trackingTabDetailStyle(ColorTheme);
  const [searchText, setSearchText] = useState('');
  const title = item?.group
    ? TEXT.GROUP_INVITATION_PENDING
    : TEXT.TOP_DISTRIBUTION_PENDING;
  const {trackingDetail} = useAppSelector(trackingDetailState);
  const [refreshing, setRefreshing] = useState(false);
  const [moreDataLoader, setMoreDataLoader] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const dispatch = useAppDispatch();
  const [DetailData, setDetailData] = useState<TrackingDetailResultsModel[]>(
    []
  );
  const [selectedItem, setSelectedItem] =
    useState<TrackingDetailResultsModel>();
  const {trackingCancel} = useAppSelector(TrackingCancelState);
  const {loadSignedImageUrl, loadSignedUrl} = useAwsS3();
  const [svgCode, setSvgCode] = React.useState<JSX.Element | undefined>(
    undefined
  );
  const [profileImageUrl, setProfileImageUrl] = React.useState<
    string | undefined
  >();
  const [_, setErrorInDecoding] = React.useState<boolean>();
  const handleRefresh = async () => {
    setRefreshing(true);
    hitApi(FixedValue.CONSTANT_VALUE_0);
  };
  const {addFavoriteAndUnFavorite, setFavoriteLoader} = useFavouriteHooks();
  const {userMarkAndUnmarkFavoriteData} = useAppSelector(userProfileStore);
  const isFocused = useIsFocused();
  const [isCancelAll, setIsCancelAll] = useState(false);
  const [showImageLoader, setShowImageLoader] = useState(true);
  const globalItem = item;

  React.useEffect(() => {
    if (isFocused) {
      setTimeout(() => {
        setShowImageLoader(false);
      }, 2500);
    }
  }, [isFocused]);

  console.log('item?>>>>', item);
  const hitApi = (page: number) => {
    const {
      requestId = '',
      top = null,
      group = null,
      requestForUnRegisteredUser = false,
    } = item || {};

    let requestType = top
      ? REQUEST_TYPE.DISTRIBUTION
      : group
      ? REQUEST_TYPE.GROUP
      : REQUEST_TYPE.DISTRIBUTION_EMAIL;

    if (requestForUnRegisteredUser) {
      requestType = REQUEST_TYPE.DISTRIBUTION_EMAIL;
    }

    const parameter = {
      requestId,
      type: requestType,
      page: page,
      ...(searchText && {search: searchText}),
    };
    dispatch(ontrackingDetailStart(parameter));
  };

  const onReachedEndList = React.useCallback((): void => {
    if (
      trackingDetail.data != null &&
      trackingDetail.data.pageNumber < trackingDetail.data.totalPages
    ) {
      setMoreDataLoader(true);
      hitApi(trackingDetail.data.pageNumber + FixedValue.CONSTANT_VALUE_1);
    }
  }, [trackingDetail]);
  useEffect(() => {
    if (isOpen && item) {
      setApiLoader(true);
      setRefreshing(false);
      hitApi(FixedValue.CONSTANT_VALUE_0);
    }
  }, [isOpen, searchText, item]);

  const _setListResult = React.useCallback(
    (newData: TrackingDetailResultsModel[]): void => {
      setDetailData(prev => [...prev, ...newData]);
    },
    [trackingDetail]
  );
  React.useEffect(() => {
    switch (trackingDetail.status) {
      case TRACKING_DETAIL.SUCCESS:
        const listData = trackingDetail.data?.data?.results ?? [];
        if (trackingDetail.data?.pageNumber === FixedValue.CONSTANT_VALUE_0) {
          setDetailData(listData ?? []);
        } else {
          _setListResult(listData ?? []);
        }
        setApiLoader(false);
        setRefreshing(false);
        setMoreDataLoader(false);
        break;
      case TRACKING_DETAIL.FAILED:
        setDetailData([]);
        setApiLoader(false);
        setRefreshing(false);
        setMoreDataLoader(false);
        break;
      default:
        break;
    }
  }, [trackingDetail.status, trackingDetail]);
  React.useEffect(() => {
    if (isOpen) {
      switch (trackingCancel.status) {
        case TRACKING_CANCEL.SUCCESS:
          if (
            DetailData.length === FixedValue.CONSTANT_VALUE_1 ||
            isCancelAll
          ) {
            setIsOpen(false);
            setSearchText('');
          } else {
            hitApi(FixedValue.CONSTANT_VALUE_0);
            setApiLoader(true);
            setRefreshing(false);
            setMoreDataLoader(false);
            Alert.alert(TEXT.SUCCESS, TEXT.REQUEST_CANCLED);
          }
          break;
        case TRACKING_CANCEL.FAILED:
          setApiLoader(false);
          setRefreshing(false);
          setMoreDataLoader(false);
          Alert.alert(TEXT.FAILED, TEXT.ERROR_ALERT);
          break;
        default:
          break;
      }
    }
  }, [trackingCancel.status, trackingCancel, isOpen]);

  const onPressFavAndUnFav = React.useCallback((item: any): void => {
    setSelectedItem(item);
    setFavoriteLoader(true);
    const params = {
      favorite: !item?.favorite,
      userIds: [item.userId ?? FixedValue.CONSTANT_VALUE_0],
    };
    addFavoriteAndUnFavorite(params);
  }, []);

  useEffect(() => {
    if (isFocused) {
      if (isOpen) {
        switch (userMarkAndUnmarkFavoriteData.status) {
          case USER_MARK_UNMARK_FAVORITE.SUCCESS:
            const {firstName, lastName, favorite} = selectedItem ?? {};
            const firstText = favorite
              ? TEXT.YOU_HAVE_REMOVED
              : TEXT.YOU_HAVE_ADDED;
            const fullName = firstName + ' ' + lastName;
            const favText = favorite ? TEXT.FROM_THE_LIST : TEXT.TO_THE_LIST;
            const msg = `${firstText} "${fullName}" ${favText}`;
            alert(msg);
            hitApi(FixedValue.CONSTANT_VALUE_0);
            setApiLoader(true);
            break;
          case USER_MARK_UNMARK_FAVORITE.FAILED:
            break;
        }
      }
    }
  }, [userMarkAndUnmarkFavoriteData?.status, isOpen]);

  const RenderTrackingListEmptyUI = () => {
    if (trackingDetail.success) {
      return (
        <View style={styles.container}>
          <NoChildData />
          <Text style={styles.text}>{TEXT.NO_TRACKING_USER}</Text>
        </View>
      );
    }
    return null;
  };

  const showSendReminderDialog = (item: any) => {
    const requestId = item.requestId;
    const requestType = item?.type;
    dispatch(
      onSendReminderStart({
        requestId: requestId,
        requestType: requestType,
      })
    );
  };

  const hitRemindAll = (item: any) => {
    const {top, group, requestForUnRegisteredUser} = item || {};
    let requestType = top
      ? REQUEST_TYPE.DISTRIBUTION
      : group
      ? REQUEST_TYPE.GROUP
      : REQUEST_TYPE.DISTRIBUTION_EMAIL;
    if (requestForUnRegisteredUser) {
      requestType = REQUEST_TYPE.DISTRIBUTION_EMAIL;
    }
    const message = `${TEXT.ARE_YOU_SURE_REMIND_ALL}`;

    const headerTitle = item.group
      ? TEXT.GROUP_INVITATION
      : TEXT.TOP_DISTRIBUTION;

    alertPolyfill(headerTitle, message, [
      {
        text: TEXT.YES,
        onPress: () => {
          const {requestId, top, group, requestForUnRegisteredUser} =
            item || {};
          let requestType = top
            ? REQUEST_TYPE.DISTRIBUTION
            : group
            ? REQUEST_TYPE.GROUP
            : REQUEST_TYPE.DISTRIBUTION_EMAIL;
          if (requestForUnRegisteredUser) {
            requestType = REQUEST_TYPE.DISTRIBUTION_EMAIL;
          }

          dispatch(
            onSendReminderAllStart({
              requestId: requestId,
              requestType: requestType,
            })
          );
        },
      },
      {
        text: TEXT.NO,
        onPress: () => {},
        style: TEXT.CANCEL,
      },
    ]);
  };

  const showConfirmationDialog = (item: any, cancelAll: boolean) => {
    item = cancelAll ? globalItem : item;
    console.log('item', JSON.stringify(item));
    console.log('global item', JSON.stringify(globalItem));

    const fullName = item.firstName + ' ' + item.lastName;
    const title = item.requestForUnRegisteredUser ? item.email : fullName;
    const texxt = item?.type === REQUEST_TYPE.GROUP ? 'from Group' : 'from Top';

    const cancelMessage = `${TEXT.ARE_YOU_SURE_CANCEL} "${title}" ${texxt} "${objectName}"`;

    let finalMSG = '';
    if (cancelAll) {
      const title = item?.group ? item.group.groupName : item?.top?.name;
      const subText = item?.group
        ? TEXT.GROUP_INVITATION + ' for'
        : TEXT.TOP_DISTRIBUTION + ' for';
      const cancelAllMessage = `${TEXT.ARE_YOU_SURE_CANCEL} ${subText} "${title}"`;
      finalMSG = cancelAllMessage;
    } else {
      finalMSG = cancelMessage;
    }
    alertPolyfill(TEXT.CANCEL_USER, finalMSG, [
      {
        text: TEXT.YES,
        onPress: () => {
          const requestId = item.requestId;
          let requestType = item?.type;
          if (cancelAll) {
            const {requestId, top, group, requestForUnRegisteredUser} =
              item || {};
            requestType = top
              ? REQUEST_TYPE.DISTRIBUTION
              : group
              ? REQUEST_TYPE.GROUP
              : REQUEST_TYPE.DISTRIBUTION_EMAIL;
            if (requestForUnRegisteredUser) {
              requestType = REQUEST_TYPE.DISTRIBUTION_EMAIL;
            }
          } else {
            requestType = item?.type;
          }

          const userId = item.requestForUnRegisteredUser ? null : item.userId;
          const email = item.requestForUnRegisteredUser ? item.email : null;
          let requestData: any = {
            requestId,
            requestType,
            detail: true,
          };
          if (!cancelAll) {
            if (item.requestForUnRegisteredUser) {
              requestData.email = email;
            } else {
              requestData.userId = userId;
            }
          }
          setIsCancelAll(cancelAll);
          dispatch(ontrackingCancelStart(requestData));
        },
      },
      {
        text: TEXT.NO,
        onPress: () => {},
        style: TEXT.CANCEL,
      },
    ]);
  };
  const renderItem = ({item}: {item: TrackingDetailResultsModel}) => (
    <TrackingUserTile
      item={item}
      onCancelPress={() => showConfirmationDialog(item, false)}
      onSendReminderPress={() => showSendReminderDialog(item)}
      onPressFavAndUnFav={() => onPressFavAndUnFav(item)}
    />
  );

  const _imageUrl = item?.group ? item?.group.groupImage : item?.top?.image;
  const name = item?.group ? item?.group.groupName : item?.top?.name;

  const {
    top = null,
    group = null,
    requestForUnRegisteredUser = false,
  } = item || {};
  let trackingType = top
    ? REQUEST_TYPE.DISTRIBUTION
    : group
    ? REQUEST_TYPE.GROUP
    : REQUEST_TYPE.DISTRIBUTION_EMAIL;

  if (requestForUnRegisteredUser) {
    trackingType = REQUEST_TYPE.DISTRIBUTION_EMAIL;
  }

  const isForDistribution =
    trackingType === REQUEST_TYPE.DISTRIBUTION ||
    trackingType === REQUEST_TYPE.DISTRIBUTION_EMAIL;

  React.useEffect(() => {
    if (isOpen) {
      if (isForDistribution) {
        getSvgUrl();
      } else {
        getSvgUrlGroup();
      }
    }
  }, [_imageUrl, isOpen]);

  const getSvgUrl = React.useCallback(async (): Promise<void> => {
    const imageUrl = await loadSignedUrl(_imageUrl ?? '');
    if (imageUrl) {
      const topImage = await getTopImage(imageUrl ?? '');
      setSvgCode(topImage);
    }
  }, [_imageUrl, svgCode]);

  const getSvgUrlGroup = React.useCallback(async (): Promise<void> => {
    try {
      const profileImageUrl = await loadSignedImageUrl(_imageUrl ?? '');
      if (profileImageUrl) {
        setProfileImageUrl(profileImageUrl);
      } else {
        setProfileImageUrl(undefined);
      }
    } catch (error) {
      setErrorInDecoding(true);
    }
  }, [_imageUrl]);

  const renderContentForImage = useMemo(() => {
    if (!showImageLoader) {
      if (isForDistribution) {
        return svgCode ? (
          <View style={styles.topImage}>{svgCode}</View>
        ) : (
          <View style={styles.topImage}>
            <ActivityIndicator size={'small'} color={COLORS.PRIMARY_BLUE} />
          </View>
        );
      } else {
        return (
          <View style={styles.detailPlaceholder}>
            {profileImageUrl ? (
              <Image source={{uri: profileImageUrl}} style={styles.topImage} />
            ) : (
              <Text style={styles.initials}>
                {getInitials(name || 'Unknown')}
              </Text>
            )}
          </View>
        );
      }
    } else {
      return (
        <View style={styles.topImage}>
          <ActivityIndicator size={'small'} color={COLORS.PRIMARY_BLUE} />
        </View>
      );
    }
  }, [
    isForDistribution,
    svgCode,
    profileImageUrl,
    name,
    COLORS.PRIMARY_BLUE,
    getInitials,
    showImageLoader,
  ]);
  const objectName = item?.group ? item.group.groupName : item?.top?.name;

  const Badge: React.FC<BadgeProps> = ({status, count}) => {
    return (
      <View style={[styles.badge, styles[status]]}>
        <Text style={styles.badgeText}>{`${status}: ${count}`}</Text>
      </View>
    );
  };

  const onCloseModal = () => {
    setIsOpen(false);
    setSearchText('');
    setSelectedItem2(null);
  };

  return (
    <ModalContainer visible={isOpen} onPressOutside={onCloseModal}>
      <View style={styles.modalContainer}>
        <ModalHeaderWithCross onPress={onCloseModal} headerTitle={title} />
        <View style={styles.rowContainer}>
          {renderContentForImage}
          <View
            style={{
              width: Percentage.PRECENTAGE_100,
              paddingHorizontal: FixedValue.CONSTANT_VALUE_10,
            }}
          >
            <Text
              style={styles.title}
              numberOfLines={FixedValue.CONSTANT_VALUE_1}
            >
              {objectName}
            </Text>
            <View style={styles.rowContainer}>
              <Text style={styles.quantity}>{TEXT.CREATED_ON}</Text>
              <Text style={[styles.quantity, {fontFamily: FONT_NAMES.SF_BOLD}]}>
                {' ' + getDateTimeUtil(item?.creationDate)}
              </Text>
            </View>
            <View style={styles.container2}>
              <Badge
                status="Accepted"
                count={item?.acceptedCount ?? FixedValue.CONSTANT_VALUE_0}
              />
              <Badge
                status="Pending"
                count={item?.pendingCount ?? FixedValue.CONSTANT_VALUE_0}
              />
              <Badge
                status="Rejected"
                count={item?.rejectedCount ?? FixedValue.CONSTANT_VALUE_0}
              />
            </View>
            <View style={styles.containerCanccelAll}>
              <Pressable
                style={styles.cancelButton1}
                onPress={() => showConfirmationDialog(item, true)}
              >
                <View style={styles.statusButtonContainer}>
                  <Text style={styles.cancelButtonText}>{TEXT.CANCEL_ALL}</Text>
                  <CloseRed />
                </View>
              </Pressable>
              <Pressable
                style={styles.remindAllBtn}
                onPress={() => hitRemindAll(item)}
              >
                <View style={styles.buttonContainer}>
                  <Text style={styles.sendReminderBtnText}>
                    {TEXT.REMIND_ALL}
                  </Text>
                  <RightArrowBlue />
                </View>
              </Pressable>
            </View>
          </View>
        </View>
        <View style={styles.seaerchContainer}>
          <SearchInput
            value={searchText}
            onChangeText={setSearchText}
            placeholder={TEXT.SEARCH_FRIENDS}
          />
        </View>
        {apiLoader && (!refreshing || !moreDataLoader) ? (
          <AppLoader />
        ) : (
          <FlatList
            data={DetailData ?? []}
            renderItem={renderItem}
            ListEmptyComponent={() => <RenderTrackingListEmptyUI />}
            keyExtractor={index => index?.toString() ?? 0}
            onEndReached={onReachedEndList}
            onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
            style={styles.list}
            showsVerticalScrollIndicator={false}
            refreshing={refreshing}
            // @ts-ignore
            ListFooterComponent={
              moreDataLoader && <ActivityIndicator size="large" />
            }
            onRefresh={handleRefresh}
          />
        )}
      </View>
    </ModalContainer>
  );
};

export default React.memo(TrackingDetailModel);
