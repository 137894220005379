import React from 'react';
import {View, ActivityIndicator, Pressable} from 'react-native';
import {COLORS} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {TopModel} from '../../types/reducerTypes/searchTabStoreType';
import {manteltabCompStyle} from './manteltabComp.style';
import useAwsS3 from '../../hooks/libraryHooks/useAwsS3';
import CircularView from '../CircularView';
import CrossRedDarkIcon from '../renderSvgs/crossRedDarkIcon';
import {getTopImage} from '../../utils/imageUtils';

const TopCardTile: React.FC<TopModel> = props => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = manteltabCompStyle(ColorTheme);
  const {loadSignedUrl} = useAwsS3();
  const {
    type,
    image,
    onShowDetailPress,
    selectedQuantity,
    quantity,
    ismodal,
    isCross,
    onCrossPress,
  } = props;
  const [svgCode, setSvgCode] = React.useState<JSX.Element | undefined>(
    undefined
  );

  const getSvgUrl = React.useCallback(async (): Promise<void> => {
    const imageUrl = await loadSignedUrl(image ?? '');
    if (imageUrl) {
      const topImage = await getTopImage(imageUrl ?? '');
      setSvgCode(topImage);
    }
  }, [image, svgCode]);

  React.useEffect(() => {
    getSvgUrl();
  }, [image]);

  return (
    <View>
      <Pressable style={styles.topCardTile} onPress={onShowDetailPress}>
        <View style={styles.gradient} />
        {/* Wrap the SVG in a fixed size container with hidden overflow */}
        {svgCode ? ( // conditionally render the SVG data
          <View style={styles.imageContainer}>{svgCode}</View>
        ) : (
          <View style={styles.imageLoader}>
            <ActivityIndicator size={'small'} color={COLORS.PRIMARY_BLUE} />
          </View>
        )}
        {selectedQuantity && (
          <CircularView quantity={selectedQuantity?.toString() ?? ''} isSmall />
        )}
        {ismodal && (
          <CircularView quantity={quantity?.toString() ?? ''} isSmall />
        )}
      </Pressable>
      {isCross && (
        <Pressable style={styles.crossIconView} onPress={onCrossPress}>
          <CrossRedDarkIcon />
        </Pressable>
      )}
    </View>
  );
};

export default TopCardTile;
