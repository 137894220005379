import React from 'react';
import {Keyboard} from 'react-native';
import {ERROR_MESSAGES, FixedValue, ROUTE_NAME, TEXT} from '../constants';
import {CREATE_NEW_TOP, UPDATE_TOP} from '../constants/actionTypes';
import {useAppDispatch, useAppSelector} from '../hooks/reduxHooks';
import {activateInProgressTab} from '../redux/reducers/collectionSlice';
import {editorState} from '../redux/reducers/editorSlice';
import {navigate} from '../services/navigationService';
import {
  IEditorContext,
  IEditorProvider,
} from '../types/contextTypes/editorContext';
import {deleteItemAtIndex, _ToastHandler} from '../utils/helperFunctions';
import {EditorField} from '../hooks/componentHooks/useEditorHooks';

const EditorContext = React.createContext<IEditorContext>({
  viewShotRef: null,
  items: [],
  setItems: _ => {},
  clearAllItems: () => {},
  editorControlItemDimensions: {
    height: FixedValue.CONSTANT_VALUE_100,
    width: FixedValue.CONSTANT_VALUE_100,
  },
  editorSvgDimensions: {
    height: FixedValue.CONSTANT_VALUE_0,
    width: FixedValue.CONSTANT_VALUE_0,
  },
  updateEditorSvgDimensions: (_h, _w) => {},
  primaryTabSelectedIndex: FixedValue.CONSTANT_VALUE_MIN_1,
  setPrimaryTabSelectedIndex: _ => {},
  selectedItemIndex: FixedValue.CONSTANT_VALUE_MIN_1,
  setSelectedItemIndex: _ => {},
  deleteSelectedItem: () => {},
  dismissKeyboardAndDeselectItem: () => {},
  openEditorModal: false,
  resetEditorContext: () => {},
  setOpenEditorModal: () => {},
  resultSvgImage: null,
  setResultSvgImage: _val => {},
  onClickTick: () => {},
  showOrderModal: false,
  setShowOrderModal: _val => {},
  isScroll: false,
  setIsScroll: _val => {},
  updateItemOrder: _val => {},
  showCopyrightModal: false,
  setShowCopyrightModal: _val => {},
  topsFieldData: null,
  setTopsFieldData: _val => {},
  getThumbnailImage: undefined,
  setThumbnailImage: _val => {},

});

const EditorProvider = (props: IEditorProvider): JSX.Element => {
  const dispatch = useAppDispatch();
  const viewShotRef = React.useRef(null);
  const {createNewTop, updateExistingTop} = useAppSelector(editorState);
  const [items, setItems] = React.useState<JSX.Element[]>([]);
  const editorControlItemDimensions = {
    height: FixedValue.CONSTANT_VALUE_100,
    width: FixedValue.CONSTANT_VALUE_100,
  };
  const [isScroll, setIsScroll] = React.useState<boolean>(false);
  const [editorSvgDimensions, updateEditorSvgDimensions] = React.useState<{
    height: number;
    width: number;
  }>({height: FixedValue.CONSTANT_VALUE_0, width: FixedValue.CONSTANT_VALUE_0});
  const [primaryTabSelectedIndex, setPrimaryTabSelectedIndex] =
    React.useState<number>(FixedValue.CONSTANT_VALUE_MIN_1);
  const [selectedItemIndex, setSelectedItemIndex] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_MIN_1
  );
  const [openEditorModal, setOpenEditorModal] = React.useState<boolean>(false);
  const [showOrderModal, setShowOrderModal] = React.useState<boolean>(false);
  const [resultSvgImage, setResultSvgImage] = React.useState<string | null>(
    null
  );
  const [showCopyrightModal, setShowCopyrightModal] =
    React.useState<boolean>(false);
  const [topsFieldData, setTopsFieldData] = React.useState<EditorField | null>(
    null
  );
  const [getThumbnailImage, setThumbnailImage] = React.useState<
    string | undefined
  >();

  const _setItems = React.useCallback(
    (el: JSX.Element): void => {
      setItems((prev: JSX.Element[]) => [...prev, el]);
    },
    [items]
  );

  const _updateItemOrder = React.useCallback((items: JSX.Element[]): void => {
    setItems(items);
  }, []);

  const _clearAllItems = React.useCallback((): void => setItems([]), []);

  const _updateEditorSvgDimensions = React.useCallback(
    (height: number, width: number): void => {
      updateEditorSvgDimensions({height, width});
    },
    [editorSvgDimensions]
  );

  const _setPrimaryTabSelectedIndex = React.useCallback(
    (index: number): void => {
      setPrimaryTabSelectedIndex(index);
    },
    [primaryTabSelectedIndex]
  );

  const _setSelectedItemIndex = React.useCallback(
    (index: number): void => {
      setSelectedItemIndex(index);
    },
    [selectedItemIndex]
  );

  const _deleteSelectedItem = React.useCallback((): void => {
    if (selectedItemIndex !== FixedValue.CONSTANT_VALUE_MIN_1) {
      // @ts-ignore Used to access item ref
      items[selectedItemIndex]?.ref?.current?.deselect();
      setItems(deleteItemAtIndex(items, selectedItemIndex));
      setSelectedItemIndex(FixedValue.CONSTANT_VALUE_MIN_1);
    } else {
      _ToastHandler(ERROR_MESSAGES.DELETE_SELECTED_ITEM, false);
    }
  }, [selectedItemIndex, items]);

  const _dismissKeyboardAndDeselectItem = React.useCallback((): void => {
    Keyboard.dismiss();
    setSelectedItemIndex(FixedValue.CONSTANT_VALUE_MIN_1);
    setIsScroll(false);
  }, []);

  const _setOpenEditorModal = React.useCallback((): void => {
    setOpenEditorModal(prev => !prev);
  }, [openEditorModal]);

  const _setResultSvgImage = React.useCallback(
    (value: string): void => {
      setResultSvgImage(value);
    },
    [resultSvgImage]
  );

  const _onClickTick = React.useCallback((): void => {
    setPrimaryTabSelectedIndex(FixedValue.CONSTANT_VALUE_MIN_1);
    setSelectedItemIndex(FixedValue.CONSTANT_VALUE_MIN_1);
  }, []);

  const _setShowOrderModal = React.useCallback(
    (value: boolean): void => {
      setShowOrderModal(value);
    },
    [showOrderModal]
  );

  React.useEffect((): void => {
    if (
      createNewTop.status === CREATE_NEW_TOP.SUCCESS ||
      updateExistingTop.status === UPDATE_TOP.SUCCESS
    ) {
      dispatch(activateInProgressTab(true));
      _resetEditorContext();
      navigate(ROUTE_NAME.BOTTOM_TAB);
      if (createNewTop.status === CREATE_NEW_TOP.SUCCESS)
        _ToastHandler(TEXT.SUCCESSFULLY_CREATED, true);
      if (updateExistingTop.status === UPDATE_TOP.SUCCESS)
        _ToastHandler(TEXT.SUCCESSFULLY_UPDATED, true);
    }
  }, [createNewTop.status, updateExistingTop.status]);

  React.useEffect((): void => {
    if (selectedItemIndex === FixedValue.CONSTANT_VALUE_MIN_1) {
      for (const item of items) {
        // @ts-ignore Used to access item ref
        const itemRef = item.ref.current;
        itemRef.deselect();
      }
    } else {
      if (items.length > 1) {
        const itemsToBeDeselected = items.filter(
          (_, i) => Number(i) !== Number(selectedItemIndex)
        );
        for (const item of itemsToBeDeselected) {
          // @ts-ignore Used to access item ref
          const itemRef = item.ref.current;
          itemRef.deselect();
        }
      }
    }
  }, [selectedItemIndex]);

  const _resetEditorContext = React.useCallback(() => {
    setItems([]);
    updateEditorSvgDimensions({
      height: FixedValue.CONSTANT_VALUE_0,
      width: FixedValue.CONSTANT_VALUE_0,
    });
    setSelectedItemIndex(FixedValue.CONSTANT_VALUE_MIN_1);
    setPrimaryTabSelectedIndex(FixedValue.CONSTANT_VALUE_MIN_1);
    setOpenEditorModal(false);
    setResultSvgImage(null);
  }, []);

  const _setShowCopyrightModal = React.useCallback(
    (value: boolean): void => {
      setShowCopyrightModal(value);
    },
    [showCopyrightModal]
  );

  const _setTopsFieldData = React.useCallback(
    (value: EditorField | null): void => {
      setTopsFieldData(value);
    },
    [topsFieldData]
  );

  const values = React.useMemo(
    (): IEditorContext => ({
      viewShotRef,
      items,
      editorSvgDimensions,
      editorControlItemDimensions,
      primaryTabSelectedIndex,
      selectedItemIndex,
      openEditorModal,
      resultSvgImage,
      showOrderModal,
      isScroll,
      showCopyrightModal,
      topsFieldData,
      getThumbnailImage,
      setThumbnailImage,
      setIsScroll,
      updateItemOrder: _updateItemOrder,
      setItems: _setItems,
      setShowOrderModal: _setShowOrderModal,
      updateEditorSvgDimensions: _updateEditorSvgDimensions,
      setPrimaryTabSelectedIndex: _setPrimaryTabSelectedIndex,
      setSelectedItemIndex: _setSelectedItemIndex,
      deleteSelectedItem: _deleteSelectedItem,
      dismissKeyboardAndDeselectItem: _dismissKeyboardAndDeselectItem,
      clearAllItems: _clearAllItems,
      setOpenEditorModal: _setOpenEditorModal,
      setResultSvgImage: _setResultSvgImage,
      resetEditorContext: _resetEditorContext,
      onClickTick: _onClickTick,
      setShowCopyrightModal: _setShowCopyrightModal,
      setTopsFieldData: _setTopsFieldData,
    }),
    [
      items,
      setItems,
      editorSvgDimensions,
      updateEditorSvgDimensions,
      primaryTabSelectedIndex,
      setPrimaryTabSelectedIndex,
      selectedItemIndex,
      setSelectedItemIndex,
      openEditorModal,
      setOpenEditorModal,
      resultSvgImage,
      setResultSvgImage,
      showOrderModal,
      setShowOrderModal,
      isScroll,
      setIsScroll,
      showCopyrightModal,
      setShowCopyrightModal,
      topsFieldData,
      setTopsFieldData,
      getThumbnailImage,
      setThumbnailImage,

    ]
  );
  return (
    <EditorContext.Provider value={values}>
      {props.children}
    </EditorContext.Provider>
  );
};

export {EditorContext, EditorProvider};
