import React from 'react';
import {View, Text} from 'react-native';
import {FixedValue} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {IIconWithText} from '../../types/componentTypes/smallDesignsType';
import {styles} from './smallDesign.style';

const IconWithText = (props: IIconWithText): JSX.Element => {
  const {text, Icon} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  return (
    <View style={style.usesDetailView}>
      <>
        {Icon}
        <Text
          numberOfLines={FixedValue.CONSTANT_VALUE_1}
          style={style.userDetailText}
        >
          {text}
        </Text>
      </>
    </View>
  );
};

export default React.memo(IconWithText);
