import moment from 'moment-timezone';
import React from 'react';
import {Platform, View} from 'react-native';

import LabelInput from '../../components/input/labelInput';
import closeEye from '../../components/renderSvgs/closeEye';
import Calendar from '../../components/renderSvgs/calendar';
import openEye from '../../components/renderSvgs/openEye';
import {COLORS, FixedValue, TEXT} from '../../constants';
import {SignUpContext} from '../../contextAPI/signUpContext';
import {ThemeContext} from '../../contextAPI/themeContext';
import {IBasicForm} from '../../types/componentTypes/signUpType';
import {_InputType} from '../../utils/helperFunctions';
import PwdValidation from '../../components/smallDesigns/pwdValidation';
import {signUpStyle} from './signUp.style';
import {GlobalStyleValues, timeformat} from '../../constants/enum';

const Basicform = (props: IBasicForm) => {
  const {
    values,
    handleBlur,
    handleChange,
    passwordError,
    usernameError,
    firstNameError,
    lastNameError,
    dobError,
  } = props;

  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = signUpStyle(ColorTheme);
  const {
    showPassword,
    setShowPassword,
    setShowCalender,
    dateString,
  } = React.useContext(SignUpContext);
  const dateValue: string =
    dateString.length > FixedValue.CONSTANT_VALUE_0
      ? moment(dateString).format(timeformat.MMMDDYY)
      : '';
  const _Calendar = (): JSX.Element => (
    <Calendar
      width={FixedValue.CONSTANT_VALUE_20}
      height={FixedValue.CONSTANT_VALUE_20}
      stroke={COLORS._8F90A6}
    />
  );

  return (
    <>
      <View
        style={[styles.nameView, {alignItems: GlobalStyleValues.FLEX_START}]}
      >
        <View style={styles.nameLabelView}>
          <View style={styles.spaceContent}></View>
          <LabelInput
            inputLabel={TEXT.FIRST_NAME}
            value={values.Firstname}
            placeholder={TEXT.ENTER_FIRSTNAME}
            onChangeText={handleChange(TEXT.FIRST_NAME_)}
            onBlur={handleBlur(TEXT.FIRST_NAME_)}
            maxLength={FixedValue.CONSTANT_VALUE_25}
            haveError={`${firstNameError}`}
          />
        </View>
        <View style={styles.nameLabelView}>
          <View style={styles.spaceContent}></View>
          <LabelInput
            inputLabel={TEXT.LAST_NAME}
            value={values.Lastname}
            placeholder={TEXT.ENTER_LASTNAME}
            onChangeText={handleChange(TEXT.LAST_NAME_)}
            onBlur={handleBlur(TEXT.LAST_NAME_)}
            maxLength={FixedValue.CONSTANT_VALUE_25}
            haveError={`${lastNameError}`}
          />
        </View>
      </View>
      <LabelInput
        inputLabel={TEXT.USERNAME}
        value={values.Username}
        placeholder={TEXT.ENTER_USERNAME_2}
        onChangeText={handleChange(TEXT.USERNAME_)}
        onBlur={handleBlur(TEXT.USERNAME_)}
        maxLength={FixedValue.CONSTANT_VALUE_30}
        haveError={`${usernameError}`}
      />
      <LabelInput
        inputLabel={TEXT.PASSWORD}
        value={values.Password}
        placeholder={TEXT.ENTER_PASSWORD_STAR}
        onChangeText={handleChange(TEXT.PASSWORD_)}
        onBlur={handleBlur(TEXT.PASSWORD_)}
        maxLength={FixedValue.CONSTANT_VALUE_25}
        secureTextEntry={showPassword}
        keyboardType={_InputType.password}
        onPress={setShowPassword}
        Icon={!showPassword ? openEye : closeEye}
        haveError={`${passwordError}`}
      />
      <PwdValidation Password={values.Password} />
      <View style={styles.spaceContent} />
      <LabelInput
        inputLabel={TEXT.ACCOUT_HOLDER_DOB}
        value={Platform.OS === 'web' ? values.DateOfBirth : dateValue}
        placeholder={TEXT.ENTER_DOB}
        onChangeText={handleChange(TEXT.DOB_)}
        onBlur={handleBlur(TEXT.DOB_)}
        maxLength={FixedValue.CONSTANT_VALUE_25}
        haveError={`${dobError}`}
        Icon={_Calendar}
        onPress={() => setShowCalender(true)}
        editable={Boolean(Platform.OS === 'web')}
      />
    </>
  );
};

export default React.memo(Basicform);
