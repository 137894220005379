import React from 'react';
import {View, Text, Pressable} from 'react-native';
import {FixedValue, TEXT} from '../../../constants';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {IGroupFlatlistComponentProps} from '../../../types/reducerTypes/groupMangementTypes';
import {
  formatUTCTimeToLocalTime,
  getGroupInitials,
} from '../../../utils/helperFunctions';
import RightArrow from '../../renderSvgs/rightArrow';
import {styles} from './styles';
import useGroupManagementListHooks from '../../../hooks/componentHooks/useGroupManagementListHooks';
import {GroupContext} from '../../../contextAPI/groupManagementContext';
import ProfilePics from '../../smallDesigns/profilePics';
import {timeformat} from '../../../constants/enum';
import {useAppDispatch} from '../../../hooks/reduxHooks';
import {onClearGroupUserListEnd} from '../../../redux/reducers/groupManagementSlice';

const GroupListTile = (props: IGroupFlatlistComponentProps): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {navigateToGroupDetailScreen} = useGroupManagementListHooks();
  const style = styles(ColorTheme);
  const {item, isComingFromMyGroup} = props;
  const multipleUserExits: boolean =
    item.userCount > FixedValue.CONSTANT_VALUE_1;
  const {setGroupDetailData, setIsUserComingFromMyGroup, setSelectedGroupId} =
    React.useContext(GroupContext);
  const dispatch = useAppDispatch();

  return (
    <Pressable
      style={style.groupListView}
      onPress={() => {
        dispatch(onClearGroupUserListEnd());
        setIsUserComingFromMyGroup(isComingFromMyGroup);
        setGroupDetailData(item);
        setSelectedGroupId(item?.id);
        navigateToGroupDetailScreen(item);
      }}
    >
      <View style={style.inRowStyle}>
        <ProfilePics
          image={item?.groupImage ?? ''}
          text={getGroupInitials(item?.groupName)}
          mainViewStyle={style.mainViewStyle}
          initialsTextStyle={style.groupProfileText}
        />
        <View style={style.columnStyle}>
          <Text
            style={style.groupNameStyle}
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
          >
            {item.groupName}
          </Text>
          <Text
            style={style.dateTextStyle}
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
          >
            {formatUTCTimeToLocalTime(
              item.creationDate,
              timeformat.DD_MMM_YYYY
            )}
          </Text>
        </View>
      </View>
      <View style={style.inRowStyle}>
        <Text style={style.countUserStyle}>
          {item.userCount} {multipleUserExits ? TEXT.USERS : TEXT.USER}
        </Text>
        <RightArrow
          stroke={ColorTheme.color}
          height={FixedValue.CONSTANT_VALUE_20}
          width={FixedValue.CONSTANT_VALUE_20}
        />
      </View>
    </Pressable>
  );
};

export default React.memo(GroupListTile);
