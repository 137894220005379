import React from 'react';
import {View, Text, Pressable} from 'react-native';
import {ThemeContext} from '../../contextAPI/themeContext';
import {childListComponentStyles} from './style';
import RightArrow from '../renderSvgs/rightArrow';
import {FixedValue, Percentage, ROUTE_NAME} from '../../constants';
import BadgeCount from '../smallDesigns/badgeCount';
import {
  ChildListModel,
  IChildListProps,
} from '../../types/reducerTypes/userProfileTypes';
import {_ToastHandler, getInitials} from '../../utils/helperFunctions';
import {navigate} from '../../services/navigationService';
import ProfilePics from '../smallDesigns/profilePics';
import {ChildProfileContext} from '../../contextAPI/childProfileContext';
import FavoriteButton from '../button/favouriteBtn';

const ChildListComponent = (props: IChildListProps): JSX.Element => {
  const {item, onPressFavAndUnFav, showFavouriteIcon = true} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const styles = childListComponentStyles(ColorTheme);
  const fullName = item?.firstName + ' ' + item?.lastName ?? '';
  const {setChildUserData} = React.useContext(ChildProfileContext);
  const onPress = React.useCallback((): void => {
    setChildUserData(item);
    navigate(ROUTE_NAME.CHILD_PROFILE, {item: item});
  }, [item, setChildUserData]);

  const getBadgeCountForChildUserList = (item: ChildListModel) => {
    const sum = Object.values(item?.pendingRequestsCount ?? {}).reduce(
      (total, value) => total + value,
      FixedValue.CONSTANT_VALUE_0
    );
    return item?.accountApprovalRequests
      ? item?.accountApprovalRequests + Number(sum)
      : Number(sum);
  };
  const getCount = getBadgeCountForChildUserList(item);
  const countIsGreaterThanZero = getCount > FixedValue.CONSTANT_VALUE_0;

  const getViewWidth = () => {
    if (countIsGreaterThanZero && showFavouriteIcon) {
      return Percentage.PRECENTAGE_65;
    } else if (showFavouriteIcon || countIsGreaterThanZero) {
      return Percentage.PRECENTAGE_77;
    } else {
      return Percentage.PRECENTAGE_90;
    }
  };

  return (
    <Pressable onPress={onPress} style={styles.childListStyle}>
      <View style={[styles.flexDirectionRow, {width: getViewWidth()}]}>
        <ProfilePics
          image={item?.profileImage ?? ''}
          text={getInitials(fullName)}
          mainViewStyle={styles.imageView}
          initialsTextStyle={styles.profileNameText}
          initialsTextViewStyle={styles.backgroundColorPlaceholder}
        />
        <Text
          numberOfLines={FixedValue.CONSTANT_VALUE_1}
          style={styles.childNameText}
        >
          {item?.firstName} {item?.lastName}
        </Text>
      </View>
      <View style={styles.flexDirectionRow}>
        {countIsGreaterThanZero && (
          <BadgeCount count={getCount} badgeStyle={styles.badgeStyle} />
        )}
        {showFavouriteIcon && (
          <FavoriteButton
            isFavorite={item?.favorite ?? false}
            onFavOrUnFavUser={() => onPressFavAndUnFav?.()}
          />
        )}
        <RightArrow stroke={ColorTheme.color} />
      </View>
    </Pressable>
  );
};

export default React.memo(ChildListComponent);
