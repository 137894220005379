import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {scaleHeight, scaleWidth, normalizeFont} from '../../utils/responsive';

export const editTopModalStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    subContainer: {
      width: Percentage.PRECENTAGE_100,
      backgroundColor: theme.backgroundColor,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_15),
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_18),
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_18,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_18,
      shadowColor: COLORS._333333,
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_0,
        height: -FixedValue.CONSTANT_VALUE_3,
      },
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
      elevation: FixedValue.CONSTANT_VALUE_3,
      alignItems: GlobalStyleValues.CENTER,
    },
    headingText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: COLORS._7A7F85,
    },
    sure: {
      fontFamily: FONT_NAMES.SF_MEDIUM,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      color: theme.color,
      textAlign: GlobalStyleValues.CENTER,
    },
    wantDelete: {
      color: theme.color,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    marginTop20: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    yesOrNoButtonView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_105),
      borderRadius: FixedValue.CONSTANT_VALUE_100,
    },
    yesButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_17),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
    marginLeft16: {
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_16),
    },
    noButtonBackGroundColor: {
      backgroundColor: COLORS.E9EEFB,
    },
    noButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_17),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.PRIMARY_BLUE,
    },
    deleteBinView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_59),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_59),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_59),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      backgroundColor: COLORS._FFEBEB,
    },
    alignEnd: {
      alignSelf: GlobalStyleValues.FLEX_END,
    },
  });
