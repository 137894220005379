import React from 'react';
import {View} from 'react-native';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {ChildProfileContext} from '../../../contextAPI/childProfileContext';
import useTradingApprovalHooks from '../../../hooks/componentHooks/useTradingApprovalHooks';
import ConfirmationModal from '../../../modals/confirmationModal/confirmationModal';
import {TEXT} from '../../../constants';
import {ACCEPTED_OR_REJECTED, FixedValue} from '../../../constants/enum';
import {DistributingReceivedRequestStyle} from './distributingReceivedRequest.style';
import DistributingReceivedRequestContent from './distributingReceivedRequestContent';
import AbuseReportModal from '../../../modals/abuseReportModal';
import {TradeContext} from '../../../contextAPI/tradeContext';
import {DistributeRequestModel} from '../../../types/componentTypes/childProfileType';
import {DISTRIBUTE_RECEIVED_REQUEST_APPROVE_OR_REJECTOR_REPORT_API} from '../../../constants/actionTypes';
import {childProfileStore} from '../../../redux/reducers/childProfileSlice';
import {useAppSelector} from '../../../hooks/reduxHooks';
import {ReportFormState} from '../../../types/reducerTypes/tradeTabTypes';

const DistributingReceivedRequest = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = DistributingReceivedRequestStyle(ColorTheme);
  const {
    isTradeReceivedApproved,
    showDistributeRequestConfirmModal,
    setShowDistributeRequestConfirmModal,
    selectedTradeReceivedData,
    childUserData,
  } = React.useContext(ChildProfileContext);
  const {
    childDistributeRequestApproveOrRejectOrReportApi,
    callDistributeReceivedRequestListAPI,
    callDistributeRequestListAPI,
  } = useTradingApprovalHooks();
  const {openReportModal, setOpenReportModal} = React.useContext(TradeContext);
  const {onReceivedDistributeApproveOrRejectOrReportData} =
    useAppSelector(childProfileStore);

  React.useEffect(() => {
    switch (onReceivedDistributeApproveOrRejectOrReportData.status) {
      case DISTRIBUTE_RECEIVED_REQUEST_APPROVE_OR_REJECTOR_REPORT_API.SUCCESS:
        callDistributeReceivedRequestListAPI(FixedValue.CONSTANT_VALUE_0);
        callDistributeRequestListAPI(FixedValue.CONSTANT_VALUE_0);
        break;
      default:
        break;
    }
  }, [onReceivedDistributeApproveOrRejectOrReportData.status]);

  //distribute top request approve reject and report
  const distrbuteTopReport = React.useCallback(
    (reportData: ReportFormState): void => {
      let approveRejectParams: any = {
        distributeRequestId:
          (selectedTradeReceivedData as DistributeRequestModel)?.requestId ?? 0,
        status: reportData?.status,
        minorId: childUserData?.id,
        userBlocked: reportData?.userBlocked ?? false,
      };
      if (reportData?.reportId) {
        approveRejectParams.reportId = reportData?.reportId;
        approveRejectParams.description = reportData?.description;
      }
      childDistributeRequestApproveOrRejectOrReportApi(approveRejectParams);
      setOpenReportModal(false);
    },
    [selectedTradeReceivedData, childUserData]
  );

  return (
    <View style={styles.mainContainer}>
      <DistributingReceivedRequestContent />
      {showDistributeRequestConfirmModal && (
        <ConfirmationModal
          title={
            isTradeReceivedApproved
              ? TEXT.DISTRIBUTE_REQUEST_APPROVE
              : TEXT.DISTRIBUTE_REQUEST_REJECT
          }
          modalVisible={showDistributeRequestConfirmModal}
          onPressYes={() => {
            distrbuteTopReport({
              status: isTradeReceivedApproved
                ? ACCEPTED_OR_REJECTED.ACCEPTED
                : ACCEPTED_OR_REJECTED.REJECTED,
            });
            setShowDistributeRequestConfirmModal();
          }}
          onShowHide={setShowDistributeRequestConfirmModal}
        />
      )}
      {openReportModal && (
        <AbuseReportModal
          modalVisible={openReportModal}
          onClose={setOpenReportModal}
          onSubmit={distrbuteTopReport}
          offerId={
            (selectedTradeReceivedData as DistributeRequestModel)?.requestId ??
            -1
          }
          isForChild={true}
        />
      )}
    </View>
  );
};

export default React.memo(DistributingReceivedRequest);
