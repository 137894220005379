export const COLORS = {
  PRIMARY_BLUE: '#1F53D3',
  _1942A9: '#1942A9',
  RGBA_31_83_211_02: 'rgba(31, 83, 211, 0.2)',
  _28293D: '#28293D',
  E9EEFB: '#E9EEFB',
  EAEAEA: '#EAEAEA',
  BDBDBD: '#BDBDBD',
  BLACK: '#000000',
  LIGHT_BLACK: 'rgba(0, 0, 0, 0.58)',
  LGHT_BLCK: 'rgba(0, 0, 0, 0.2)',
  LGHT_BLCK_01: 'rgba(0, 0, 0, 0.1)',
  LGHT_BLCK_02: 'rgba(0, 0, 0, 0.4)',
  LGHT_BLUE_01: 'rgba(31, 83, 211, 0.1)',
  WHITE: '#FFFFFF',
  _8F90A6: '#8F90A6',
  C7C9D9: '#C7C9D9',
  TRANSPARENT: 'transparent',
  F54336: '#F54336',
  FF6666: '#FF6666',
  FAFAFA: '#FAFAFA',
  _1E1F1F: '#1E1F1F',
  _00B25B: '#00B25B',
  DBF1E7: '#DBF1E7',
  B6BFC9: '#B6BFC9',
  RGB_86_125_224: 'rgb(86, 125, 224)',
  RGB_121_152_229: 'rgb(121,152,229)',
  RGB_233_238_251: 'rgb(233,238,251)',
  RGB_31_83_211: 'rgb(31, 83, 211)',
  C2D2ED: '#C2D2ED',
  FDE4E1: '#FDE4E1',
  _555770: '#555770',
  _7A7F85: '#7A7F85',
  _333333: '#333333',
  F3F7FF: '#F3F7FF',
  _3A3B3C: '#3A3B3C',
  RGBA_255_255_255_5: 'rgba(255, 255, 255, 0.5)',
  RGBA_255_255_255_2: 'rgba(255, 255, 255, 0.2)',
  _F8CC02: '#F8CC02',
  _F1F2F4: ' #F1F2F4',
  _FF3D3D: '#FF3D3D', //red
  _FFEBEB: '#FFEBEB', // light red
  _4EAF51: '#4EAF51', //light green
  _F5F8FF: '#F5F8FF',
  _4A4A4A: '#4A4A4A',
  _009BF6: '#009BF6',
  FF8600: '#FF8600',
  FF3351: '#FF3351',
  E5006A: '#E5006A',
  B300C0: '#B300C0',
  _1C4A29: '#1C4A29',
  ED858E: '#ED858E',
  _GREY: '#999999',
  _777B84: ' #777B84',
  _E6F3E5: '#E6F3E5',
  _B3B3B3: '#B3B3B3',
  _E6E6E6: '#E6E6E6',
  _FEFAE6: '#FEFAE6',
  F5F6F8: '#F5F6F8',
  _E9E9F0: '#E9E9F0',
  _FEF5CC: '#FEF5CC',
  _A07608: '#A07608',
  _A38500: '#A38500',
  _FF0000: '#FF0000',
  FADE_BLACK: 'rgba(0, 0, 0, 0.8)',
  FADE_WHITE: 'rgba(255,255, 255, 0.8)',
  _C5C5C5: '#C5C5C5',
  _666666: '#666666',
  _DAE6FC: '#DAE6FC',
  RGBA_31_83_211: 'rgb(31, 83, 211)',
  RGBA_0_0_0_1: 'rgba(0, 0, 0,0.1)',
  RGBA_0_0_0_7: 'rgba(0, 0, 0,0.7)',
  _1F53D3: '#1F53D3',
  _FFFAED:'#FFFAED',
  _5E6272: '#5E6272',
  LIGHTGRAY: 'lightgray',
};

export const lightTheme = {
  theme: {
    backgroundColor: COLORS.WHITE,
    color: COLORS.BLACK,
    labelColor: COLORS._28293D,
    placeHolderColor: COLORS._8F90A6,
    tabBarBackgroundColor: COLORS.FAFAFA,
    resetPwdTextColor: COLORS._555770,
    loaderBackgroundColor: COLORS.LIGHT_BLACK,
    disabledBackgroundColor: COLORS.F5F6F8,
    disabledTextColor: COLORS._7A7F85,
  },
};

export const darkTheme = {
  theme: {
    backgroundColor: COLORS.BLACK,
    color: COLORS.WHITE,
    labelColor: COLORS.WHITE,
    placeHolderColor: COLORS._8F90A6,
    tabBarBackgroundColor: COLORS.BLACK,
    resetPwdTextColor: COLORS.WHITE,
    loaderBackgroundColor: COLORS.RGBA_255_255_255_5,
    disabledBackgroundColor: COLORS.BLACK,
    disabledTextColor: COLORS.WHITE,
  },
};

export const PELETTE_COLOR = [
  COLORS.BLACK,
  COLORS.WHITE,
  COLORS._009BF6,
  COLORS._00B25B,
  COLORS.FF8600,
  COLORS.FF3351,
  COLORS.E5006A,
  COLORS.B300C0,
  COLORS._1C4A29,
  COLORS.ED858E,
];
