import React from 'react';
import {TEXT} from '../constants';
import {CREATE_COLLECTION, UPDATE_COLLECTION} from '../constants/actionTypes';
import {useAppSelector} from '../hooks/reduxHooks';
import {createCollectionStore} from '../redux/reducers/collectionSlice';
import {
  ICollectionContext,
  ICollectionProvider,
} from '../types/contextTypes/collectionContext';
import {CollectionModel} from '../types/reducerTypes/searchTabStoreType';
import {_ToastHandler} from '../utils/helperFunctions';
// Create a context with initial values
const CreateCollectionContext = React.createContext<ICollectionContext>({
  openCreateCollectionModal: false,
  setOpenCreateCollectionModal: () => {},
  collectionData: null,
  setCollectionData: _val => {},
  isEditMode: false,
  setIsEditMode: () => {},
});
// Define a provider for the context
const CreateCollectionProvider = (props: ICollectionProvider): JSX.Element => {
  // Get the create and update collection actions from the redux store

  const {createNewCollection, updateCollection} = useAppSelector(
    createCollectionStore
  );
  // Initialize the state variables

  const [openCreateCollectionModal, setOpenCreateCollectionModal] =
    React.useState<boolean>(false);
  const [collectionData, setCollectionData] =
    React.useState<CollectionModel | null>(null);
  // Add a state variable for the Add/Edit Mode state of collection
  const [isEditMode, setIsEditMode] = React.useState(false); // Update initial value of isButtonDisabled
  // Define a callback function to toggle the modal state
  const _setOpenCreateModal = React.useCallback((): void => {
    setOpenCreateCollectionModal(prev => !prev);
  }, [openCreateCollectionModal]);

  // Define a callback function to set the collection data and toggle the edit mode

  const _setCollectionData = React.useCallback(
    (NewData: CollectionModel | null): void => {
      setCollectionData(NewData);
      if (NewData === null) {
        setIsEditMode(false);
      }
    },
    [collectionData]
  );

  // Define a callback function to toggle the edit mode
  const _setIsEditMode = React.useCallback((): void => {
    setIsEditMode(prev => !prev);
  }, [isEditMode]);

  // Handle the create collection success/failure response using useEffect
  React.useEffect((): void => {
    switch (createNewCollection.status) {
      case CREATE_COLLECTION.SUCCESS:
        _ToastHandler(TEXT.COLLECTION_SUCCESSFULLY_CREATED, true);
        break;
      case CREATE_COLLECTION.FAILED:
        _ToastHandler(TEXT.SOMETHING_WENT_WRONG, false);
        break;
      default:
        break;
    }
  }, [createNewCollection.status]);

  // Handle the update collection success/failure response using useEffect

  React.useEffect((): void => {
    switch (updateCollection.status) {
      case UPDATE_COLLECTION.SUCCESS:
        _ToastHandler(TEXT.COLLECTION_SUCCESSFULLY_UPDATED, true);
        break;
      case UPDATE_COLLECTION.FAILED:
        _ToastHandler(TEXT.SOMETHING_WENT_WRONG, false);
        break;
      default:
        break;
    }
  }, [updateCollection.status]);

  // Toggle the edit mode based on the collection data using useEffect

  React.useEffect(() => {
    if (collectionData) {
      // Set the EditMode on when there is collectionData
      setIsEditMode(true);
    } else {
      // Set the EditMode off when there is collectionData
      setIsEditMode(false);
    }
  }, [collectionData]);

  // Memoize the context values using useMemo
  const values = React.useMemo(
    (): ICollectionContext => ({
      openCreateCollectionModal,
      setOpenCreateCollectionModal: _setOpenCreateModal,
      collectionData,
      setCollectionData: _setCollectionData,
      isEditMode,
      setIsEditMode: _setIsEditMode,
    }),
    [
      openCreateCollectionModal,
      setOpenCreateCollectionModal,
      collectionData,
      setCollectionData,
    ]
  );
  return (
    <CreateCollectionContext.Provider value={values}>
      {props.children}
    </CreateCollectionContext.Provider>
  );
};

export {CreateCollectionContext, CreateCollectionProvider};
