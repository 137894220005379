import * as React from 'react';
import Svg, {Rect, Path, SvgProps} from 'react-native-svg';
const NoItemOffer = (props: SvgProps) => (
  <Svg
    width={250}
    height={200}
    viewBox="0 0 250 200"
    fill="none"
    //@ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63 134H154C154.515 134 155.017 133.944 155.5 133.839C155.983 133.944 156.485 134 157 134H209C212.866 134 216 130.866 216 127C216 123.134 212.866 120 209 120H203C199.134 120 196 116.866 196 113C196 109.134 199.134 106 203 106H222C225.866 106 229 102.866 229 99C229 95.134 225.866 92 222 92H200C203.866 92 207 88.866 207 85C207 81.134 203.866 78 200 78H136C139.866 78 143 74.866 143 71C143 67.134 139.866 64 136 64H79C75.134 64 72 67.134 72 71C72 74.866 75.134 78 79 78H39C35.134 78 32 81.134 32 85C32 88.866 35.134 92 39 92H64C67.866 92 71 95.134 71 99C71 102.866 67.866 106 64 106H24C20.134 106 17 109.134 17 113C17 116.866 20.134 120 24 120H63C59.134 120 56 123.134 56 127C56 130.866 59.134 134 63 134ZM226 134C229.866 134 233 130.866 233 127C233 123.134 229.866 120 226 120C222.134 120 219 123.134 219 127C219 130.866 222.134 134 226 134Z"
      fill="#F3F7FF"
    />
    <Path
      d="M167 148H189.428M64 148H85.4541H64ZM51.1279 148H58H51.1279ZM194.128 148H197H194.128Z"
      stroke="#1F64E7"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M98 43.3027L109.1 55.7559M150.1 43.3027L139 55.7559L150.1 43.3027ZM124 39V55.7559V39Z"
      stroke="#75A4FE"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.7976 71H155.404L149.798 79.4098L157.273 85.0164H90.9287L99.3385 79.4098L92.7976 71Z"
      fill="#E8F0FE"
    />
    <Rect x={89} y={83} width={71} height={75} rx={2} fill="white" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.5852 124.006V89.6453C93.5852 88.2297 94.7454 87.082 96.1767 87.082L157.153 151.164C157.153 153.052 155.642 154.582 153.778 154.582H96.9601C95.0962 154.582 93.5852 153.052 93.5852 151.164V138.395V134.93V124.006ZM93.5852 131.425V127.577V131.425Z"
      fill="#E8F0FE"
    />
    <Path
      d="M90 124.463V86.8853C90 85.3371 91.2705 84.082 92.8377 84.082H157.761C158.782 84.082 159.609 84.9187 159.609 85.9509V154.164C159.609 156.228 157.954 157.902 155.913 157.902H93.6956C91.6546 157.902 90 156.228 90 154.164V140.199V136.41M90 132.576V128.368"
      stroke="#1F64E7"
      strokeWidth={2.5}
      strokeLinecap="round"
    />
    <Path
      d="M91.8631 84.082V72.8689C91.8631 71.8368 92.614 71.0001 93.5403 71.0001H155.596C156.522 71.0001 157.273 71.8368 157.273 72.8689V84.082"
      stroke="#1F64E7"
      strokeWidth={2.5}
    />
    <Path
      d="M109.15 103.705C110.956 103.705 112.42 102.241 112.42 100.435C112.42 98.6283 110.956 97.1641 109.15 97.1641C107.344 97.1641 105.88 98.6283 105.88 100.435C105.88 102.241 107.344 103.705 109.15 103.705Z"
      fill="#E8F0FE"
      stroke="#1F64E7"
      strokeWidth={2.5}
      strokeLinecap="round"
    />
    <Path
      d="M139.986 103.705C141.792 103.705 143.257 102.241 143.257 100.435C143.257 98.6283 141.792 97.1641 139.986 97.1641C138.18 97.1641 136.716 98.6283 136.716 100.435C136.716 102.241 138.18 103.705 139.986 103.705Z"
      fill="#E8F0FE"
      stroke="#1F64E7"
      strokeWidth={2.5}
      strokeLinecap="round"
    />
    <Path
      d="M139.519 103.705C139.519 111.962 132.825 118.656 124.568 118.656C116.311 118.656 109.617 111.962 109.617 103.705"
      stroke="#1F64E7"
      strokeWidth={2.5}
      strokeLinecap="round"
    />
    <Path
      d="M92.8546 71.9316L99.433 78.4253C99.826 78.8133 99.8301 79.4464 99.4421 79.8395C99.375 79.9075 99.2984 79.9656 99.2148 80.0118L91.8631 84.0821"
      stroke="#1F64E7"
      strokeWidth={2.5}
      strokeLinecap="round"
    />
    <Path
      d="M156.44 71.999L150.145 78.4195C149.758 78.8138 149.765 79.447 150.159 79.8336C150.225 79.8982 150.299 79.9533 150.38 79.9975L157.867 84.0818"
      stroke="#1F64E7"
      strokeWidth={2.5}
      strokeLinecap="round"
    />
  </Svg>
);
export default NoItemOffer;
