import React from 'react';
import {View} from 'react-native';
import ModalContainer from '../../components/layout/modalContainer';
import {ThemeContext} from '../../contextAPI/themeContext';

import {offerCancelModalStyle} from './styles';
import OfferCancelModalContent from './offerCancelModalContent';
import {TradeContext} from '../../contextAPI/tradeContext';
const OfferCancelModal = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = offerCancelModalStyle(ColorTheme);
  const {openCancelModal, setOpenCancelModal} = React.useContext(TradeContext);
  return (
    <ModalContainer
      visible={openCancelModal}
      onPressOutside={() => setOpenCancelModal(false)}
    >
      <View style={styles.subContainer}>
        <OfferCancelModalContent />
      </View>
    </ModalContainer>
  );
};

export default OfferCancelModal;
