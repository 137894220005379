/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {Text, View} from 'react-native';

import {loginStyle} from './login.style';
import AppLogo from '../../components/renderSvgs/appIcon';
import {TEXT, FixedValue, ROUTE_NAME} from '../../constants';
import LabelInput from '../../components/input/labelInput';
import useLoginHooks from '../../hooks/componentHooks/useLoginHooks';
import PrimaryButton from '../../components/button/primaryButton';
import {ThemeContext} from '../../contextAPI/themeContext';
import {LoginContext} from '../../contextAPI/loginContext';

import username from '../../components/renderSvgs/username';
import openEye from '../../components/renderSvgs/openEye';
import closeEye from '../../components/renderSvgs/closeEye';
import {_InputType, _ToastHandler} from '../../utils/helperFunctions';
import {useAppSelector} from '../../hooks/reduxHooks';
import {verifyOtpState} from '../../redux/reducers/verifyOtp';
import {RESEND_CODE} from '../../constants/actionTypes';
import {navigate} from '../../services/navigationService';
import {useIsFocused} from '@react-navigation/native';
import {authState} from '../../redux/reducers/authSlice';
import {SignUpContext} from '../../contextAPI/signUpContext';

const LoginContentView: React.FC = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {
    values,
    isValid,
    UsernameError,
    PasswordError,
    handleChange,
    handleBlur,
    handleSubmit,
    navigateLostPassword,
    moveToSignUp,
    navigateLostUserName,
  } = useLoginHooks();
  const {styles, ids} = loginStyle(ColorTheme);
  const {showPassword, setShowPassword} = React.useContext(LoginContext);
  const {resendCode} = useAppSelector(verifyOtpState);
  const isFocused = useIsFocused();
  const {userLogin} = useAppSelector(authState);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const {setShowBackButton} = React.useContext(SignUpContext);

  React.useEffect(() => {
    switch (resendCode.status) {
      case RESEND_CODE.SUCCESS:
        if (isFocused) {
          navigate(ROUTE_NAME.VERIFY_EMAIL, {
            emailId: values.Username,
            mobileNo: values?.Username,
            navigateFrom: ROUTE_NAME.LOGIN_SCREEN,
            userCredentials: {
              Password: values.Password,
              Username: values.Username,
            },
          });
        }
        break;
      case RESEND_CODE.FAILED:
        _ToastHandler(resendCode.failed ?? '', false);
        break;
      default:
        break;
    }
  }, [resendCode.status, values, isFocused]);

  React.useEffect(() => {
    setLoading(userLogin.isLoading);
  }, [userLogin.isLoading]);

  return (
    <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
      <View style={styles.logoView}>
        <AppLogo />
      </View>
      <Text style={styles.welcomeText}>{TEXT.WELCOME}</Text>
      <Text style={styles.welcomeDescText}>{TEXT.WELCOME_DESC}</Text>
      <LabelInput
        inputLabel={TEXT.USERNAME}
        value={values.Username}
        placeholder={TEXT.ENTER_USERNAME}
        onChangeText={handleChange(TEXT.USERNAME_)}
        onBlur={handleBlur(TEXT.USERNAME_)}
        Icon={username}
        haveError={UsernameError}
        maxLength={FixedValue.CONSTANT_VALUE_30}
      />
      <LabelInput
        inputLabel={TEXT.PASSWORD}
        value={values.Password}
        secureTextEntry={showPassword}
        keyboardType={_InputType.password}
        onPress={setShowPassword}
        placeholder={TEXT.ENTER_PASSWORD}
        onChangeText={handleChange(TEXT.PASSWORD_)}
        onBlur={handleBlur(TEXT.PASSWORD_)}
        maxLength={FixedValue.CONSTANT_VALUE_25}
        haveError={PasswordError}
        Icon={!showPassword ? openEye : closeEye}
      />
      <View style={styles.buttonContainer}>
        <Text onPress={navigateLostPassword} style={styles.lostPassword}>
          {TEXT.LOST_PASSWORD}
        </Text>
        <Text onPress={navigateLostUserName} style={styles.lostPassword}>
          {TEXT.LOST_USERNAME}
        </Text>
      </View>
      <PrimaryButton
        buttonLabel={TEXT.LOGIN}
        onPress={handleSubmit}
        isDisabled={!isValid}
        isLoading={isLoading}
      />
      <Text style={[styles.lostPassword, styles.dontHaveAccount]}>
        {TEXT.DONT_HAVE_ACCOUNT}{' '}
        <Text
          onPress={() => {
            setShowBackButton(true);
            moveToSignUp();
          }}
          style={styles.signUpText}
        >
          {TEXT.SIGN_UP}
        </Text>
      </Text>
      <Text style={styles.sloganText}>{TEXT.APP_SLOGAN}</Text>
    </View>
  );
};

export default React.memo(LoginContentView);
