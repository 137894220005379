import React from 'react';
import {View, StyleSheet, Image} from 'react-native';
import {
  BASESTYLE,
  FixedValue,
  IMAGES,
  Percentage,
  themeTypes,
} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {isWebsite} from '../../utils/responsive';

const styles = StyleSheet.create({
  container: {
    ...BASESTYLE.flex1,
    ...BASESTYLE.absolutePosition,
    zIndex: FixedValue.CONSTANT_VALUE_MIN_1,
    width: Percentage.PRECENTAGE_100,
    height: Percentage.PRECENTAGE_100,
    resizeMode: !isWebsite() ? 'cover' : 'stretch',
  },
});

const WelcomeBg = (): JSX.Element => {
  const {themeState} = React.useContext(ThemeContext);
  const Source =
    themeState === themeTypes.LIGHT
      ? IMAGES.WELCOME_LIGHT
      : IMAGES.WELCOME_DARK;

  return (
    <View style={styles.container}>
      <Image source={Source} style={styles.container} />
    </View>
  );
};

export default React.memo(WelcomeBg);
