import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
const HeartFavoriteIcon = (props: SvgProps) => (
  <Svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M27.4191 8.03824L27.4195 8.03876C28.3553 9.26494 28.8578 10.8068 28.8578 12.5087C28.8578 14.9032 27.4238 17.6274 24.4105 20.6112C21.9626 23.035 19.0594 25.0277 17.5429 26.0025C17.0849 26.2956 16.5525 26.4513 16.0088 26.4513C15.4649 26.4513 14.9323 26.2955 14.4742 26.0023C12.9566 25.0275 10.0549 23.0349 7.60714 20.6113C4.59365 17.6262 3.15979 14.9031 3.15979 12.5087C3.15979 10.8068 3.66224 9.26494 4.59805 8.03876L4.59844 8.03824C5.48644 6.87153 6.74084 6.03718 8.16012 5.66921C9.5794 5.30124 11.0812 5.42102 12.4242 6.0093L12.4242 6.00932L12.4276 6.01078C13.6313 6.5274 14.7182 7.40887 15.6083 8.59984L16.0083 9.13513L16.4089 8.60031C17.299 7.41215 18.3863 6.5302 19.5909 6.0104L19.5934 6.0093C20.9364 5.42102 22.4382 5.30124 23.8574 5.66921C25.2767 6.03718 26.5311 6.87153 27.4191 8.03824Z"
      fill={'#1F53D3'}
      stroke={props?.stroke ?? '#1F53D3'}
    />
  </Svg>
);
export default HeartFavoriteIcon;
