import React from 'react';
import {Image, View, ViewStyle} from 'react-native';
import {NotificationCardStyle} from './notificationCards.style';
import ProfilePics from '../smallDesigns/profilePics';
import {getInitials} from '../../utils/helperFunctions';
import GiftImage from '../renderSvgs/giftImage';
import {COLORS, FixedValue} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import useAwsS3 from '../../hooks/libraryHooks/useAwsS3';
import {INotificationListItem} from '../../types/reducerTypes/notificationTypes';

interface IImageDisplay {
  item: INotificationListItem;
}

const ImageDisplay = ({item}: IImageDisplay): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = NotificationCardStyle(ColorTheme);
  const {loadSignedImageUrl} = useAwsS3();
  const [userName, setUserName] = React.useState<string>('Mantel Top');
  const [profilePic, setProfilePic] = React.useState<string | null>(null);

  React.useEffect((): void => {
    if (item.json) {
      const jsonData = JSON.parse(item.json);
      if (jsonData.name) setUserName(jsonData.name);
      if (jsonData.image)
        loadSignedImageUrl(jsonData.image)
          .then(imageUrl => {
            if (imageUrl) setProfilePic(imageUrl);
          })
          .catch(error => console.log(error, 'error to load data'));
    }
  }, []);

  const RenderImages = React.useMemo((): JSX.Element => {
    if (profilePic != null) {
      return <Image source={{uri: profilePic}} style={styles.imageView} />;
    } else if (userName.length > FixedValue.CONSTANT_VALUE_0) {
      return (
        <ProfilePics
          text={getInitials(userName)}
          mainViewStyle={styles.imageView}
          initialsTextStyle={styles.profileNameText}
          initialsTextViewStyle={styles.backgroundColorPlaceholder}
        />
      );
    }
    return (
      <GiftImage
        width={FixedValue.CONSTANT_VALUE_20}
        height={FixedValue.CONSTANT_VALUE_20}
        stroke={ColorTheme.backgroundColor}
      />
    );
  }, [profilePic, userName, ColorTheme]);

  const bgColor: ViewStyle = !item.seen ? {backgroundColor: COLORS.BLACK} : {};

  return <View style={[styles.imageView, bgColor]}>{RenderImages}</View>;
};

export default React.memo(ImageDisplay);
