import {takeLatest, put, call, delay} from 'redux-saga/effects';
import {ISignUpResult} from 'amazon-cognito-identity-js';
import {AppConst} from '../../constants';
import {IActionType} from '../../types/constantFilesTypes';
import {
  checkUserAvailibility,
  parentInfo,
  register,
  userLogin,
  userSignUp,
} from '../../utils/apis';
import {
  getUserTokens,
  onLoginEnd,
  onLoginFailed,
  onLoginSuccess,
  onParentUsernameEnd,
  onParentUsernameFailed,
  onParentUsernameSuccess,
  onRegisterEnd,
  onRegisterFailed,
  onRegisterSuccess,
  onSignUpEnd,
  onSignUpFailed,
  onSignUpSuccess,
  onUsernameEnd,
  onUsernameFailed,
  onUsernameSuccess,
} from '../reducers/authSlice';
import {
  ICheckUserNameResponse,
  ILoginResponse,
  IParentUsernameResponse,
  IRegisterResponse,
  IUserToken,
} from '../../types/reducerTypes/authStoreTypes';
import {
  getUserTokenFromUserObj,
  storeDataLocally,
} from '../../utils/helperFunctions';
import {isWebsite} from '../../utils/responsive';

const {StoreConstants, StorageConstants} = AppConst;

function* loginUser(action: IActionType) {
  try {
    const data: ILoginResponse = yield call(userLogin, action.payload);
    const params: IUserToken = getUserTokenFromUserObj(data);
    yield put(getUserTokens(params));
    yield put(onLoginSuccess(data));
    yield storeDataLocally(StorageConstants.USER_TOKENS, params);
  } catch (error: any) {
    yield put(onLoginFailed(error.message));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onLoginEnd());
  }
}

function* checkUserAvailable(action: IActionType) {
  try {
    const {data}: ICheckUserNameResponse = yield call(
      checkUserAvailibility,
      action.payload
    );
    yield put(onUsernameSuccess(data));
  } catch (error: any) {
    yield put(onUsernameFailed(error?.data?.errors[0].msg));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onUsernameEnd());
  }
}

function* parentUsername(action: IActionType) {
  try {
    const data: IParentUsernameResponse = yield call(
      parentInfo,
      action.payload
    );
    yield put(onParentUsernameSuccess(data));
  } catch (error: any) {
    yield put(onParentUsernameFailed(error?.errors[0]?.msg));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onParentUsernameEnd());
  }
}

function* signUpUser(action: IActionType) {
  try {
    const data: ISignUpResult = yield call(userSignUp, action.payload);
    yield put(onSignUpSuccess(data));
  } catch (error: any) {
    yield put(onSignUpFailed(error.message));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onSignUpEnd());
  }
}

function* userRgister(action: IActionType) {
  try {
    const data: IRegisterResponse = yield call(register, action.payload);
    yield put(onRegisterSuccess(data));
  } catch (error: any) {
    yield put(onRegisterFailed(error?.errors[0]?.msg));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onRegisterEnd());
  }
}

export default function* authSaga() {
  yield takeLatest(`${StoreConstants.AUTH_STORE}/onLoginStart`, loginUser);
  yield takeLatest(
    `${StoreConstants.AUTH_STORE}/onUsernameStart`,
    checkUserAvailable
  );
  yield takeLatest(
    `${StoreConstants.AUTH_STORE}/onParentUsernameStart`,
    parentUsername
  );
  yield takeLatest(`${StoreConstants.AUTH_STORE}/onSignUpStart`, signUpUser);
  yield takeLatest(`${StoreConstants.AUTH_STORE}/onRegisterStart`, userRgister);
}
