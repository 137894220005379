import React from 'react';
import {StyleSheet, View, Text} from 'react-native';
import {useNetInfo} from '@react-native-community/netinfo';

import ModalContainer from '../../components/layout/modalContainer';
import ViewContainer from '../../components/layout/viewContainer';
import {
  BASESTYLE,
  FONT_NAMES,
  FixedValue,
  GlobalStyleValues,
  Percentage,
  TEXT,
} from '../../constants';
import {normalizeFont, scaleHeight} from '../../utils/responsive';
import {ThemeContext} from '../../contextAPI/themeContext';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import NoInternet from '../../components/renderSvgs/noInternet';

const CompStyle = (themeColor: IColorTheme) =>
  StyleSheet.create({
    containerView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_229),
      backgroundColor: themeColor.backgroundColor,
      width: Percentage.PRECENTAGE_100,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_24,
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_24,
    },
    titleText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_BOLD,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_24),
      color: themeColor.color,
      textAlign: GlobalStyleValues.CENTER,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    descText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_REGULAR,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_17),
      color: themeColor.color,
      textAlign: GlobalStyleValues.CENTER,
    },
  });

const NoInternetModal = (): JSX.Element => {
  const {isConnected} = useNetInfo();
  const {ColorTheme} = React.useContext(ThemeContext);
  const styles = CompStyle(ColorTheme);
  return (
    <ModalContainer visible={isConnected === null ? false : !isConnected}>
      <View style={styles.containerView}>
        <NoInternet />
        <Text style={styles.titleText}>{TEXT.NO_INTERNET_TITLE}</Text>
        <Text style={styles.descText}>{TEXT.NO_INTERNET_DESC}</Text>
      </View>
    </ModalContainer>
  );
};

export default NoInternetModal;
