import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const CSVIcon = (props: SvgProps) => (
  <Svg
    width={27}
    height={26}
    viewBox="0 0 27 26"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.86735 0H15.5688L22.9323 7.68613V22.6124C22.9323 24.4818 21.4236 26 19.5542 26H6.86735C5.0075 26 3.48926 24.4818 3.48926 22.6124V3.37809C3.48922 1.51824 5.0075 0 6.86735 0Z"
      fill="#4EAF51"
    />
    <Path
      opacity={0.302}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5596 0V7.61971H22.9326L15.5596 0Z"
      fill="#E9E9F0"
    />
    <Path
      d="M11.574 18.9307H10.312L9.44852 17.4789L8.585 18.9307H7.31348L8.81276 16.4066L7.4938 14.2146H8.76529L9.44849 15.3533L10.1222 14.2146H11.3937L10.0842 16.4161L11.574 18.9307ZM12.02 18.9307V14.2146H13.2251V17.9058H15.2748V18.9307H12.02V18.9307ZM17.2675 18.9876C16.793 18.9876 16.3755 18.8358 16.0149 18.5416C15.6638 18.257 15.4646 17.8964 15.4361 17.4694L16.4704 17.1657C16.4989 17.3935 16.5938 17.5927 16.7551 17.7446C16.9259 17.8964 17.1157 17.9723 17.3244 17.9723C17.4952 17.9723 17.6376 17.9344 17.7514 17.8584C17.8558 17.7825 17.9127 17.6782 17.9127 17.5548C17.9127 17.4504 17.8653 17.365 17.7799 17.2891C17.6945 17.2227 17.5806 17.1657 17.4478 17.1183C17.3149 17.0709 17.1536 17.0234 16.9923 16.9855C16.8215 16.938 16.6507 16.8811 16.4894 16.8052C16.3186 16.7387 16.1667 16.6533 16.0339 16.549C15.8915 16.4541 15.7872 16.3118 15.7018 16.1314C15.6164 15.9606 15.5689 15.7519 15.5689 15.5147C15.5689 15.1256 15.7302 14.803 16.0623 14.5468C16.3945 14.2811 16.793 14.1577 17.2579 14.1577C17.7229 14.1577 18.1309 14.2716 18.4725 14.4898C18.8142 14.7176 19.0324 15.0117 19.1178 15.3723L18.0361 15.8278C17.9886 15.6285 17.8937 15.4672 17.7609 15.3439C17.628 15.23 17.4572 15.1636 17.2579 15.1636C17.1061 15.1636 16.9922 15.2015 16.8974 15.2585C16.812 15.3154 16.774 15.4008 16.774 15.5052C16.774 15.6001 16.8309 15.6855 16.9448 15.7519C17.0587 15.8088 17.2105 15.8563 17.3813 15.8848C17.5521 15.9132 17.7419 15.9607 17.9411 16.0271C18.1499 16.103 18.3302 16.1789 18.5105 16.2833C18.6813 16.3782 18.8236 16.5395 18.9375 16.7483C19.0608 16.9665 19.1178 17.2227 19.1178 17.5169C19.1178 17.9534 18.947 18.314 18.6054 18.5796C18.2637 18.8453 17.8179 18.9876 17.2675 18.9876Z"
      fill="white"
    />
  </Svg>
);
export default CSVIcon;
