import React from 'react';
import {Pressable, Text} from 'react-native';

import {COLORS, FixedValue} from '../../../constants';
import {IFontProps} from '../../../types/componentTypes/editorType';
import GreenTick from '../../renderSvgs/greenTick';
import {styles} from './styles';

const FontTile = (props: IFontProps): JSX.Element => {
  const {font, label, isSelected, onPress} = props;
  const style = styles(font, isSelected);

  return (
    <Pressable onPress={() => onPress(font)} style={style.container}>
      <Text style={style.fontText}>{label}</Text>
      {isSelected && (
        <GreenTick
          stroke={COLORS.BLACK}
          width={FixedValue.CONSTANT_VALUE_18}
          height={FixedValue.CONSTANT_VALUE_13}
        />
      )}
    </Pressable>
  );
};

export default React.memo(FontTile);
