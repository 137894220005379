import React from 'react';
import {View, Text, Pressable} from 'react-native';
import {BASESTYLE, FixedValue, TEXT} from '../../../constants';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {getGroupInitials} from '../../../utils/helperFunctions';
import RightArrow from '../../renderSvgs/rightArrow';
import {styles} from './styles';
import ProfilePics from '../../smallDesigns/profilePics';
import {GroupListSearchModalListData} from '../../../types/reducerTypes/searchTabStoreType';
import {HomeContext} from '../../../contextAPI/homeContext';
import useHomeHooks from '../../../hooks/componentHooks/useHomeHooks';

interface ISearchGroupListTileProps {
  item: GroupListSearchModalListData;
}

const SearchGroupListTile = (props: ISearchGroupListTileProps): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  const {item} = props;
  const multipleUserExits: boolean =
    item.userCount > FixedValue.CONSTANT_VALUE_1;
  const {setSelectedGroupDetail, _setUserListSearchResult, setInitialLoader} =
    React.useContext(HomeContext);
  const {callGroupUserListSearchAPI} = useHomeHooks();

  return (
    <Pressable
      style={style.groupListView}
      onPress={() => {
        callGroupUserListSearchAPI(
          FixedValue.CONSTANT_VALUE_0,
          item.id,
          FixedValue.CONSTANT_VALUE_1024
        );
        _setUserListSearchResult([], true);
        setSelectedGroupDetail(item);
        setInitialLoader(true);
      }}
    >
      <View style={BASESTYLE.inRow}>
        <ProfilePics
          image={item?.groupImage}
          text={getGroupInitials(item?.groupName)}
          mainViewStyle={style.mainViewStyle}
          initialsTextStyle={style.groupProfileText}
        />
        <View style={BASESTYLE.column}>
          <Text
            style={style.groupNameStyle}
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
          >
            {item.groupName}
          </Text>
        </View>
      </View>
      <View style={BASESTYLE.inRow}>
        <Text style={style.countUserStyle}>
          {item.userCount} {multipleUserExits ? TEXT.USERS : TEXT.USER}
        </Text>
        <RightArrow
          stroke={ColorTheme.color}
          height={FixedValue.CONSTANT_VALUE_20}
          width={FixedValue.CONSTANT_VALUE_20}
        />
      </View>
    </Pressable>
  );
};

export default React.memo(SearchGroupListTile);
