import React from 'react';
import {View, FlatList} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';

import SentOfferRendercard from './sentOfferRendercard';
import {BASESTYLE, FixedValue} from '../../constants';
import {OfferModel} from '../../types/reducerTypes/tradeTabTypes';
import useTradeReceviedOrSentHooks from '../../hooks/componentHooks/useTradeReceviedOrSentHooks';
import {TradeContext} from '../../contextAPI/tradeContext';
import EmptySentView from './emptySentView';
import OfferCancelModal from '../../modals/offerCancelModal';
import {scaleHeight} from '../../utils/responsive';
import { useAppSelector } from '../../hooks/reduxHooks';
import { TradeTabStore } from '../../redux/reducers/tradeTabSlice';

interface OfferProps {
  data: OfferModel[];
}

const SentOfferContent = (props: OfferProps): JSX.Element => {
  const {getOffersListAPI, onReachedEnd} = useTradeReceviedOrSentHooks();
  const {isRefreshing, setIsRefreshing, initialLoader} = React.useContext(TradeContext);
  const {sentOffers} = useAppSelector(TradeTabStore);

  const handleRefresh = React.useCallback(() => {
    setIsRefreshing(true);
    getOffersListAPI('SENT', FixedValue.CONSTANT_VALUE_0);
  }, [isRefreshing]);

  return (
    <View style={{...BASESTYLE.flex1}}>
      <FlatList
        keyExtractor={item => `${'SentOffer'}-${item.id}`}
        data={props.data}
        refreshControl={
          <RefreshControl refreshing={isRefreshing} onRefresh={handleRefresh} />
        }
        renderItem={({item}) => <SentOfferRendercard key={item.id} {...item} />}
        onEndReached={() => onReachedEnd('SENT')}
        onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
        ListEmptyComponent={() =>
          sentOffers?.isLoading || initialLoader ? <></> : <EmptySentView />
        }
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_200),
        }}
      />
      <OfferCancelModal />
    </View>
  );
};

export default React.memo(SentOfferContent);
