import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';
const LeftArrow = (props: SvgProps) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M4.25 12.2743L19.25 12.2743"
      stroke={props.stroke ?? '#200E32'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10.3 18.2986L4.25005 12.2746L10.3 6.24963"
      stroke={props.stroke ?? '#200E32'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default LeftArrow;
