import React, {useEffect, useState} from 'react';
import {View, FlatList, Text, Keyboard, Alert, Pressable} from 'react-native';
import {FixedValue, GlobalStyleValues, TEXT} from '../../../constants';
import {HomeContext} from '../../../contextAPI/homeContext';
import {ThemeContext} from '../../../contextAPI/themeContext';
import useHomeHooks from '../../../hooks/componentHooks/useHomeHooks';
import {useAppSelector} from '../../../hooks/reduxHooks';
import {customModalForSelectUserStyle} from '../../../modals/customModalForSelectUser/customModalForSelectUser.style';
import {HomeStore} from '../../../redux/reducers/homeSlice';
import {CustomUserListSearchModalListData} from '../../../types/reducerTypes/searchTabStoreType';
import BackButton from '../../button/backButton';
import DistributeTopFooter from '../../footer/distributiveTopFooter';
import ViewContainer from '../../layout/viewContainer';
import AppLoader from '../../smallDesigns/appLoader';
import UserListComponent from '../../userListComponent';
import {
  okAlertPolyfill,
  showAlertForExceedQuantity,
} from '../../../utils/helperFunctions';
import {FavoriteContext} from '../../../contextAPI/favoriteContext';
import useFavouriteHooks from '../../../hooks/componentHooks/useFavouriteHooks';
import {useIsFocused} from '@react-navigation/native';
import {USER_MARK_UNMARK_FAVORITE} from '../../../constants/actionTypes';
import {userProfileStore} from '../../../redux/reducers/userProfileSlice';
import RightArrowBlue from '../../renderSvgs/RightArrowBlue';

const DistributeIndivitual = (): JSX.Element => {
  const {
    userListSearchResult,
    setSelectedUsersListSearch,
    selectedUsersListSearch,
    selectedTopData,
    remainingTop,
    setRemainingTop,
    setSelectedGroupDetail,
    selectedGroupDetail,
    searchUserListText,
    setIsCloseGroupUser,
    initialLoader,
    _setUserListSearchResult,
    selectAllUsers,
    unselectAllUsers,
    userListQuantityEdit,
  } = React.useContext(HomeContext);
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = customModalForSelectUserStyle(ColorTheme);
  const {
    onReachedEnd,
    callDistributeTopAPI,
    onReachedEndGroupUserListSearch,
    callUserListSearchAPI,
  } = useHomeHooks();
  const {distributeTop} = useAppSelector(HomeStore);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const {selectedUsersFavouriteListSearch} = React.useContext(FavoriteContext);
  const [favLoading, setFavLoading] = React.useState<boolean>(false);
  const {addFavoriteAndUnFavorite, setFavoriteLoader} = useFavouriteHooks();
  const [selectedItem, setSelectedItem] =
    useState<CustomUserListSearchModalListData>();
  const isFocused = useIsFocused();
  const {userMarkAndUnmarkFavoriteData} = useAppSelector(userProfileStore);
  const {callGroupUserListSearchAPI} = useHomeHooks();
  const {userData} = useAppSelector(userProfileStore);
  const UserDetail = userData.success;
  const afterRemovedLoggedInUser = userListSearchResult.filter(
    user => user.id !== UserDetail?.id
  );
  const allUsersAlreadyPresent = afterRemovedLoggedInUser.every(
    user => user?.alreadyPresentUser
  );

  useEffect(() => {
    if (isFocused) {
      setFavLoading(false);
      switch (userMarkAndUnmarkFavoriteData.status) {
        case USER_MARK_UNMARK_FAVORITE.SUCCESS:
          const finalKey = selectedGroupDetail
            ? selectedItem?.favorite
            : selectedItem?.isFavorite;
          const {firstName, lastName} = selectedItem ?? {};
          const firstText = finalKey
            ? TEXT.YOU_HAVE_REMOVED
            : TEXT.YOU_HAVE_ADDED;
          const fullName = firstName + ' ' + lastName;
          const favText = finalKey ? TEXT.FROM_THE_LIST : TEXT.TO_THE_LIST;
          const msg = `${firstText} "${fullName}" ${favText}`;
          okAlertPolyfill('', msg, () => {});

          if (selectedGroupDetail) {
            callGroupUserListSearchAPI(
              FixedValue.CONSTANT_VALUE_0,
              selectedGroupDetail?.id,
              FixedValue.CONSTANT_VALUE_1024
            );
          } else {
            callUserListSearchAPI(
              searchUserListText,
              FixedValue.CONSTANT_VALUE_0
            );
          }
          break;
        case USER_MARK_UNMARK_FAVORITE.FAILED:
          break;
      }
    }
  }, [userMarkAndUnmarkFavoriteData?.status]);

  useEffect(() => {
    const userListSearchResultListData = userListSearchResult.map(item => {
      const userToUpdate = selectedUsersListSearch.find(
        user => user.id === item.id
      );
      if (userToUpdate) {
        return {
          ...item,
          quantity: userToUpdate.quantity,
          alreadyPresentUser: true,
        };
      }
      return item;
    });
    _setUserListSearchResult(userListSearchResultListData, true);
  }, [selectedUsersFavouriteListSearch]);

  const updateQuantity = (
    item: CustomUserListSearchModalListData,
    val: string
  ) => {
    if (val === TEXT.MINNUS) {
      if (
        item.quantity !== FixedValue.CONSTANT_VALUE_1 &&
        item.quantity !== FixedValue.CONSTANT_VALUE_0
      ) {
        setSelectedUsersListSearch({
          ...item,
          quantity: item.quantity! - FixedValue.CONSTANT_VALUE_1,
          alreadyPresentUser: !item.alreadyPresentUser,
          isCounter: true,
        });
      } else if (item.quantity === FixedValue.CONSTANT_VALUE_1) {
        setSelectedUsersListSearch({
          ...item,
          isCounter: false,
          quantity: FixedValue.CONSTANT_VALUE_1,
        });
      }
    } else {
      const totalValue = selectedUsersListSearch.reduce(
        (total, obj) => obj.quantity! + total,
        0
      );
      if (
        totalValue < (selectedTopData?.quantity ?? FixedValue.CONSTANT_VALUE_0)
      ) {
        if (item.quantity === FixedValue.CONSTANT_VALUE_0) {
          setSelectedUsersListSearch({
            ...item,
            quantity: FixedValue.CONSTANT_VALUE_1,
            isCounter: false,
          });
        } else {
          setSelectedUsersListSearch({
            ...item,
            quantity: item.quantity! + FixedValue.CONSTANT_VALUE_1,
            alreadyPresentUser: !item.alreadyPresentUser,
            isCounter: true,
          });
        }
      } else {
        showAlertForExceedQuantity(
          selectedTopData?.quantity ?? FixedValue.CONSTANT_VALUE_0
        );
      }
    }
  };

  const onChangeUpdateQuantity = (
    item: CustomUserListSearchModalListData,
    quantity: number
  ) => {
    let tempSelectedUser = [...selectedUsersListSearch];
    tempSelectedUser = tempSelectedUser?.filter(user => user.id !== item.id);
    const totalValue = tempSelectedUser?.reduce(
      (total, obj) => obj?.quantity! + total,
      FixedValue.CONSTANT_VALUE_0
    );
    const totalQuantityAfterChange = totalValue + quantity;
    if (
      totalQuantityAfterChange <=
      (selectedTopData?.quantity ?? FixedValue.CONSTANT_VALUE_0)
    ) {
      userListQuantityEdit({
        ...item,
        quantity: quantity,
        alreadyPresentUser: true,
      });
    } else {
      showAlertForExceedQuantity(
        selectedTopData?.quantity ?? FixedValue.CONSTANT_VALUE_0
      );
    }
  };

  useEffect(() => {
    const totalValue = selectedUsersListSearch.reduce(
      (total, obj) => obj.quantity! + total,
      FixedValue.CONSTANT_VALUE_0
    );
    const count = selectedTopData?.quantity! - totalValue;
    setRemainingTop(count);
  }, []);

  const onBackPress = () => {
    setIsCloseGroupUser(true);
    _setUserListSearchResult([], true);
    callUserListSearchAPI(searchUserListText, FixedValue.CONSTANT_VALUE_0);
    setSelectedGroupDetail(null);
  };

  React.useEffect(() => {
    setLoading(distributeTop.isLoading);
  }, [distributeTop.isLoading]);

  const onPressFavAndUnFav = React.useCallback(
    (item: any): void => {
      setFavLoading(true);
      setSelectedItem(item);
      setFavoriteLoader(true);
      let params: any = {};
      if (selectedGroupDetail) {
        params.favorite = item?.favorite ? !item?.favorite : true;
        params.userIds = [item?.id ?? FixedValue.CONSTANT_VALUE_0];
      } else {
        params.favorite = item?.isFavorite ? !item?.isFavorite : true;
        params.userIds = [item?.id ?? FixedValue.CONSTANT_VALUE_0];
      }
      addFavoriteAndUnFavorite(params);
    },
    [selectedGroupDetail]
  );

  const onSelectOrUnselelctUser = React.useCallback(
    (item: CustomUserListSearchModalListData): void => {
      const checkTotalQuantity = selectedUsersListSearch?.reduce(
        (total, obj) => obj?.quantity! + total,
        FixedValue.CONSTANT_VALUE_0
      );
      if (
        checkTotalQuantity <
          (selectedTopData?.quantity ?? FixedValue.CONSTANT_VALUE_0) ||
        item?.alreadyPresentUser
      ) {
        const groupUserIndex = userListSearchResult?.findIndex(
          groupUser => groupUser?.id === item?.id
        );
        const selectedUserIndex = selectedUsersListSearch?.findIndex(
          user => user?.id === item?.id
        );
        let tempSelectedUser = [...selectedUsersListSearch];
        if (groupUserIndex !== FixedValue.CONSTANT_VALUE_MIN_1) {
          if (item?.alreadyPresentUser) {
            // User is already selected, remove it from the selected items array
            tempSelectedUser.splice(
              selectedUserIndex,
              FixedValue.CONSTANT_VALUE_1
            );
            setSelectedUsersListSearch(tempSelectedUser);
            const newSelectedUserItems = [...userListSearchResult];
            if (newSelectedUserItems[groupUserIndex]) {
              newSelectedUserItems[groupUserIndex].alreadyPresentUser = false;
              newSelectedUserItems[groupUserIndex].quantity =
                FixedValue.CONSTANT_VALUE_0;
            }
            _setUserListSearchResult(newSelectedUserItems, true);
          } else {
            let userData = {
              ...item,
              alreadyPresentUser: false,
              quantity: FixedValue.CONSTANT_VALUE_1,
            };
            tempSelectedUser = [...tempSelectedUser, userData];
            setSelectedUsersListSearch(tempSelectedUser);
            const newSelectedUserItems = [...userListSearchResult];
            if (newSelectedUserItems[groupUserIndex]) {
              newSelectedUserItems[groupUserIndex].alreadyPresentUser = true;
              newSelectedUserItems[groupUserIndex].quantity =
                FixedValue.CONSTANT_VALUE_1;
            }
            _setUserListSearchResult(newSelectedUserItems, true);
          }
        }
        const totalValue = tempSelectedUser?.reduce(
          (total, obj) => obj?.quantity! + total,
          FixedValue.CONSTANT_VALUE_0
        );
        const count = selectedTopData?.quantity! - totalValue;
        setRemainingTop(count);
      } else {
        showAlertForExceedQuantity(
          selectedTopData?.quantity ?? FixedValue.CONSTANT_VALUE_0
        );
      }
    },
    [userListSearchResult, selectedUsersListSearch, selectedTopData]
  );

  return (
    <>
      <ViewContainer>
        {initialLoader && <AppLoader />}
        {selectedGroupDetail && (
          <View style={styles.headerContainer}>
            <View style={styles.leftContainer}>
              <BackButton onPress={onBackPress} />
              <Text
                numberOfLines={FixedValue.CONSTANT_VALUE_1}
                style={styles.selectedGroupTextStyle}
              >
                {selectedGroupDetail.groupName}
              </Text>
            </View>
            {afterRemovedLoggedInUser?.length > FixedValue.CONSTANT_VALUE_0 && (
              <Pressable
                style={styles.selectAllButton}
                onPress={
                  allUsersAlreadyPresent ? unselectAllUsers : selectAllUsers
                }
              >
                <Text style={styles.selectAllText}>
                  {allUsersAlreadyPresent ? TEXT.UNSELECT_ALL : TEXT.SELECT_ALL}
                </Text>
                <RightArrowBlue />
              </Pressable>
            )}
          </View>
        )}
        <FlatList
          keyExtractor={(_, index) => `${TEXT.USER_LIST}-${index}`}
          keyboardShouldPersistTaps={GlobalStyleValues.HANDLED}
          data={userListSearchResult}
          contentContainerStyle={styles.userListContentStyle}
          onEndReached={
            selectedGroupDetail ? onReachedEndGroupUserListSearch : onReachedEnd
          }
          onEndReachedThreshold={FixedValue.CONSTANT_VALUE_1}
          showsVerticalScrollIndicator={false}
          scrollEnabled
          renderItem={({item}) => (
            <UserListComponent
              item={item}
              onSelectOrUnSelectUser={val => {}}
              onUpdateQuantityTap={val => {
                updateQuantity(item, val);
              }}
              quantity
              showFavourite={!selectedGroupDetail}
              onPressFavAndUnFav={() => onPressFavAndUnFav(item)}
              isInputQuantity
              onChangeQuantity={(val: number) =>
                onChangeUpdateQuantity(item, val)
              }
              checkQuantityOnOnChange={true}
              textInputSupportDarkTheme={true}
              showSelelctIcon={selectedGroupDetail ? true : false}
              onSelectOrUnselelctUser={() => onSelectOrUnselelctUser(item)}
            />
          )}
          onScrollBeginDrag={() => Keyboard.dismiss()}
        />
      </ViewContainer>
      <DistributeTopFooter
        onClickDistributeTop={() =>
          callDistributeTopAPI(selectedUsersListSearch)
        }
        remaining={remainingTop}
        topQuantity={selectedTopData?.quantity}
        isLoading={isLoading}
      />
    </>
  );
};

export default React.memo(DistributeIndivitual);
