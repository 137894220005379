import {showMessage} from 'react-native-flash-message';
import {Alert, KeyboardTypeOptions, Linking, Platform} from 'react-native';
import {cloneDeep} from 'lodash';
import {ImagePickerResult} from 'expo-image-picker';
import moment from 'moment-timezone';
import {Base64} from 'js-base64';

import {
  FixedValue,
  GlobalStyleValues,
  NUMERIC_REGEX,
  UPPER_CASE_REGEX,
  COLORS,
  FONT_NAMES,
  TEXT,
  ROUTE_NAME,
  JOSEFIN_SANS,
  ROBOTO,
  ITIM,
  MONTSERRAT,
  BEBAS_NEUE,
  PACIFICO,
  MONTSERRAT_B64,
  JOSEFIN_SANS_B64,
  ROBOTO_B64,
  PACIFICO_B64,
  BEBAS_NEUE_B64,
  ITIM_B64,
  LOWER_CASE_REGEX,
} from '../constants';
import {normalizeFont, scaleHeight} from './responsive';
import {
  appStateName,
  editorType,
  IconType,
  timeformat,
  webFonts,
} from '../constants/enum';
import {
  CircleItemPositionOverride,
  ImageItemPositionOverride,
  ImageRefType,
  ShapeItemPositionOverride,
  TextItemPositionOverride,
  UserImageType,
  TopEditorItemType,
  FontFamily,
} from '../types/componentTypes/editorType';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {SignUpFields} from '../hooks/componentHooks/useSignUpHooks';
import {FormikErrors} from 'formik';
import {ILoginResponse, IUserToken} from '../types/reducerTypes/authStoreTypes';
import {FONT_B64_PRE, StorageConstants} from '../constants/appConst';
import {
  ChildListModel,
  IUserProfileData,
} from '../types/reducerTypes/userProfileTypes';
import {reset} from '../services/navigationService';
import {extraFontOriginalNames} from '../constants/fonts';
import {TOAST_TEXT} from '../constants/textMessage';
import {
  ITransactionHistoryCustomizeData,
  ITransactionHistoryObject,
} from '../types/componentTypes/userProfileTypes';

export const has8Character = (str: string): boolean =>
  str?.length >= FixedValue.CONSTANT_VALUE_8;

export const hasUpperCase = (str: string): boolean =>
  UPPER_CASE_REGEX.test(str);

export const hasLowerCase = (str: string): boolean =>
  LOWER_CASE_REGEX.test(str);

export const hasNumber = (str: string): boolean => NUMERIC_REGEX.test(str);

export const handleOpenSettings = () => {
  Linking.openSettings();
};

export const _ToastHandler = (message: string, isSuccess: boolean): void => {
  return showMessage({
    message,
    backgroundColor: isSuccess ? COLORS._00B25B : COLORS.FF6666,
    color: COLORS.WHITE,
    style: {
      alignItems: GlobalStyleValues.CENTER,
      elevation: FixedValue.CONSTANT_VALUE_3,
      shadowColor: isSuccess ? COLORS.DBF1E7 : COLORS.FF6666,
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_0,
        height: FixedValue.CONSTANT_VALUE_5,
      },
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
    },
    titleStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_15),
      fontFamily: FONT_NAMES.SF_REGULAR,
      lineHeight:
        Platform.OS === 'ios' && Platform.isPad
          ? scaleHeight(FixedValue.CONSTANT_VALUE_30)
          : undefined,
    },
    duration: FixedValue.CONSTANT_VALUE_5000,
    icon: isSuccess ? IconType.SUCCESS : IconType.FAILED,
  });
};

export const getUserTokenFromUserObj = (
  userObject: ILoginResponse
): IUserToken => {
  return {
    CognitoUser: userObject,
    accessToken: userObject?.signInUserSession?.accessToken?.jwtToken,
    idToken: userObject?.signInUserSession?.idToken?.jwtToken,
    refreshToken: userObject?.signInUserSession?.refreshToken?.token,
  };
};

export function deleteItemAtIndex(
  items: JSX.Element[],
  selectedItemIndex: number
): JSX.Element[] {
  const itemsCopy = cloneDeep<JSX.Element[]>(items);
  itemsCopy.splice(selectedItemIndex, 1);

  return itemsCopy.map((item: JSX.Element, index: number) => {
    const override = getItemPositionOverride(item);
    item.key = `top-element-${index}`;
    item.props.elementId = `top-element-${index}`;
    item.props.elementIndex = index;
    item.props.itemPositionOverride = {
      ...item.props.itemPositionOverride,
      ...override,
    };
    return item;
  });
}

function getItemPositionOverride(
  item: JSX.Element
):
  | TextItemPositionOverride
  | ImageItemPositionOverride
  | CircleItemPositionOverride
  | ShapeItemPositionOverride {
  // @ts-ignore -- used to access item ref
  const itemRef: ShapeRefType | TextRefType | ImageRefType = item.ref.current;

  const name = itemRef.getName();

  switch (name) {
    case editorType.TEXT: {
      const position: any = cloneDeep(itemRef.getPosition());
      delete position.isSelected;
      delete position.offset;

      return {
        itemType: editorType.TEXT,
        ...position,
      } as TextItemPositionOverride;
    }
    case editorType.IMAGE: {
      const position: any = cloneDeep((itemRef as ImageRefType).getPosition());
      delete position.source;
      delete position.isSelected;
      delete position.offset;

      return {
        itemType: editorType.IMAGE,
        ...position,
      } as ImageItemPositionOverride;
    }
    case editorType.CIRCLE: {
      const position: any = cloneDeep(itemRef.getPosition());
      delete position.isSelected;
      delete position.offset;

      return {
        itemType: editorType.CIRCLE,
        ...position,
      } as CircleItemPositionOverride;
    }
    case editorType.SHAPE:
    default:
      const position: any = cloneDeep(itemRef.getPosition());
      delete position.isSelected;
      delete position.offset;

      return {
        itemType: editorType.SHAPE,
        ...position,
      } as ShapeItemPositionOverride;
  }
}
export const _InputType = {
  email: 'email',
  password: 'password',
  phoneNumber: 'phoneNumber',
};
export const numberToArray = (value?: number): number[] =>
  value ? [value] : [0];

export const allMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const getNumericKeyPad = () =>
  Platform.OS === 'ios' ? 'number-pad' : 'numeric';

export const getKeyboardType = (inputType: string): KeyboardTypeOptions => {
  switch (inputType) {
    case _InputType.email:
      return 'email-address';
    case _InputType.phoneNumber:
      return getNumericKeyPad();
    case _InputType.password:
      return 'default';
    default:
      return 'email-address';
  }
};

type iterateImageType = {images: UserImageType[]; imageSize: number};

/**
 * This function used to get image object.
 * @param uri
 * @returns
 */
export const singleImageFormated = (uri: string) => {
  const imageExtension = uri?.split('.').pop();
  const imageFileName = `IMAGE_NAME-${new Date().getTime()}.${imageExtension}`;
  const image = {
    uri: uri,
    type: `image/${imageExtension}`,
    name: imageFileName,
  };
  return {image};
};

export const iterateImages = (result: ImagePickerResult): iterateImageType => {
  if (result?.assets != null) {
    const images: UserImageType[] = [];
    let imageSize: number = FixedValue.CONSTANT_VALUE_0;
    for (const item of result?.assets) {
      const imageType = item?.uri?.substring(
        item.uri.length - FixedValue.CONSTANT_VALUE_3
      );
      const userImageB64 = `data:image/${imageType};base64,${item.base64}`;
      const binaryImg = Base64.atob(userImageB64.split(',')[1]);
      const fileSize = binaryImg.length;
      const mb: number = fileSize / FixedValue.CONSTANT_VALUE_1048576;
      if (mb > FixedValue.CONSTANT_VALUE_10) continue;
      imageSize = imageSize + Number(mb.toFixed(2));
      const image: UserImageType = {
        source: userImageB64,
        width: item.width,
        height: item.height,
        imageSource: userImageB64,
        itemPositionOverride: {itemType: TopEditorItemType.IMAGE},
        isSelected: false,
      };
      images.push(image);
    }
    return {images, imageSize};
  }
  return {images: [], imageSize: FixedValue.CONSTANT_VALUE_0};
};

export const storeDataLocally = async (
  key: string,
  value: object | string
): Promise<void> => {
  await AsyncStorage.setItem(key, JSON.stringify(value));
};

export const getDataLocally = async (key: string) => {
  const result = await AsyncStorage.getItem(key);
  return result != null ? JSON.parse(result) : null;
};

export const removeDataFromLocally = async (key: string) => {
  await AsyncStorage.removeItem(key);
};

export const calculateAgeFromDate = (dateString: string): number => {
  const today = new Date();
  const birthDateObj = new Date(dateString);
  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();
  if (
    monthDiff < FixedValue.CONSTANT_VALUE_0 ||
    (monthDiff === FixedValue.CONSTANT_VALUE_0 &&
      today.getDate() < birthDateObj.getDate())
  ) {
    age--;
  }
  return age;
};

export const isAllFieldValids = (
  error: FormikErrors<SignUpFields>
): boolean => {
  const isNamefieldsValid: boolean =
    error.Firstname === undefined &&
    error.Lastname === undefined &&
    error.Username === undefined;
  const isOtherfieldsValid: boolean =
    error.Password === undefined &&
    error.DateOfBirth === undefined &&
    error.Email === undefined;
  return (
    isNamefieldsValid && isOtherfieldsValid && error.MobileNumber === undefined
  );
};

export const isAllFieldValids2 = (
  error: FormikErrors<SignUpFields>,
  hasPersonalEmail: boolean,
  hasParentUsername: boolean
): boolean => {
  const isNamefieldsValid: boolean =
    error.Firstname === undefined &&
    error.Lastname === undefined &&
    error.Username === undefined;
  const isOtherfieldsValid: boolean =
    error.Password === undefined &&
    error.DateOfBirth === undefined &&
    error.MobileNumber === undefined;
  const isPersonalEmailAdded: boolean = hasPersonalEmail
    ? error.Email === undefined
    : true;
  const isPersonalUsernameAdded: boolean = hasParentUsername
    ? error.ParentUsername === undefined
    : true;
  const isSecondPersonalUsernameAdded: boolean = hasParentUsername
    ? error?.ParentUsername2 === undefined
    : true;
  return (
    error.ParentEmail === undefined &&
    isNamefieldsValid &&
    isOtherfieldsValid &&
    isPersonalUsernameAdded &&
    isPersonalEmailAdded &&
    isSecondPersonalUsernameAdded
  );
};

export const saveAllUserDataWhileLogin = async (
  userProfileData: IUserProfileData
): Promise<void> => {
  await storeDataLocally(StorageConstants.USER_PROFILE_DATA, userProfileData);
  await storeDataLocally(StorageConstants.SWITCH_USER_DETAIL, userProfileData);
  await storeDataLocally(
    StorageConstants.USER_APP_STATE,
    appStateName.INSIDE_APP
  );
};

export const convertUTCToLocalTime = (dateString: string) => {
  let date = new Date(dateString);
  const milliseconds = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );
  return new Date(milliseconds);
};

//Format date
export const getDateTimeUtil = (date: string) => {
  return moment(new Date(date)).format('MMM DD, YYYY');
};
export const getDateTimeUtil2 = (date: string) => {
  return moment(new Date(date)).format('DD MMM, YYYY');
};
//format UTC time To LocalTime
export const formatUTCTimeToLocalTime = (
  date: string | Date,
  format: string
) => {
  const givenDate = moment.utc(date);
  const userTimezone = moment.tz.guess();
  // convert the given date to the user's timezone
  const givenDateInUserTz = givenDate.tz(userTimezone);
  return givenDateInUserTz.format(format);
};

export const extractUrlPath = (str: string | undefined | null): string => {
  if (!str) {
    return '';
  }
  const match = str.split('.com/')[1].split('?')[0];
  return match ? match : '';
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const getFirstLetterOfString = (str: string): string => {
  return str.charAt(0).toUpperCase();
};

export const getInitials = (fullName: string): string => {
  if (fullName !== '') {
    const names = fullName.split(' ');
    const firstNameInitial = names[0].charAt(0).toUpperCase();
    const lastNameInitial = names[names.length - 1].charAt(0).toUpperCase();
    return `${firstNameInitial}${lastNameInitial}`;
  } else {
    return '';
  }
};

//check it is uncategorised top or not
export const isUncategorizedOrNot = (name: string): boolean => {
  return name?.toLocaleLowerCase() === TEXT.UNCATEGORIZED.toLocaleLowerCase();
};

//show add collection Button if any top is selected
export const isAnyTopSelected = (element: any): boolean => {
  return element?.isSelected;
};

//show profile switch button if any child is approved
export const isAnyChildUserIsApproved = (element: ChildListModel): boolean => {
  return element?.accountApprovalRequests === FixedValue.CONSTANT_VALUE_0;
};

//show right side header button name on behalf of top select
export const getRightSideHeaderName = (element: any): boolean => {
  return element?.isSelected;
};

//seeAll button disabled if data is less than 10
export const seeAllButtonDisabled = (data: number): boolean => {
  return data < FixedValue.CONSTANT_VALUE_2;
};

//get colorstyle for see all
export const seeAllTextColor = (data: number): string => {
  return data > FixedValue.CONSTANT_VALUE_1
    ? COLORS.PRIMARY_BLUE
    : COLORS.LGHT_BLUE_01;
};

export const removeAllData = async (isNotNavigate?: boolean): Promise<void> => {
  const allKeys = await AsyncStorage.getAllKeys();
  await AsyncStorage.multiRemove(allKeys);
  await storeDataLocally(
    StorageConstants.USER_APP_STATE,
    appStateName.AUTHENTICATION
  );
  if (!isNotNavigate) {
    reset(ROUTE_NAME.LOGIN_SCREEN);
  }
};

export const getHeightConstant = (fontFamily: FontFamily): number => {
  switch (fontFamily) {
    case MONTSERRAT:
    case ITIM:
      return FixedValue.CONSTANT_VALUE_01234;
    case JOSEFIN_SANS:
      return FixedValue.CONSTANT_VALUE_0117;
    case ROBOTO:
    case BEBAS_NEUE:
      return FixedValue.CONSTANT_VALUE_012;
    case PACIFICO:
      return FixedValue.CONSTANT_VALUE_0177;
    case undefined:
    default:
      return FixedValue.CONSTANT_VALUE_1;
  }
};

export const getFontFamilyForSave = (font: FontFamily): string => {
  switch (font) {
    case MONTSERRAT:
      return 'Montserrat, sans-serif';
    case JOSEFIN_SANS:
      return 'Josefin Sans, sans-serif';
    case ROBOTO:
      return 'Roboto, sans-serif';
    case PACIFICO:
      return 'Pacifico, cursive';
    case BEBAS_NEUE:
      return 'Bebas Neue, cursive';
    case ITIM:
      return 'Itim, cursive';
    default:
      return '';
  }
};

export const getFontImport = (font: FontFamily): string => {
  switch (font) {
    case MONTSERRAT:
      return `@font-face{font-family:"Montserrat";src:url(${FONT_B64_PRE}${MONTSERRAT_B64}) format("woff"); font-weight:normal;font-style:normal;}`;
    case JOSEFIN_SANS:
      return `@font-face{font-family:"Josefin Sans";src:url(${FONT_B64_PRE}${JOSEFIN_SANS_B64}) format("woff"); font-weight:normal;font-style:normal;}`;
    case ROBOTO:
      return `@font-face{font-family:"Roboto";src:url(${FONT_B64_PRE}${ROBOTO_B64}) format("woff"); font-weight:normal;font-style:normal;}`;
    case PACIFICO:
      return `@font-face{font-family:"Pacifico";src:url(${FONT_B64_PRE}${PACIFICO_B64}) format("woff"); font-weight:normal;font-style:normal;}`;
    case BEBAS_NEUE:
      return `@font-face{font-family:"Bebas Neue";src:url(${FONT_B64_PRE}${BEBAS_NEUE_B64}) format("woff"); font-weight:normal;font-style:normal;}`;
    case ITIM:
      return `@font-face{font-family:"Itim";src:url(${FONT_B64_PRE}${ITIM_B64}) format("woff"); font-weight:normal;font-style:normal;}`;
    default:
      return '';
  }
};

export const getFontsName = (tempFont: string): string => {
  switch (tempFont) {
    case extraFontOriginalNames.Montserrat:
      return webFonts.MONTSERRAT_REGULAR;
    case extraFontOriginalNames.JosefinSans:
      return webFonts.JOSEFINESANS_REGULAR;
    case extraFontOriginalNames.Roboto:
      return webFonts.ROBOTO_REGULAR;
    case extraFontOriginalNames.Pacifico:
      return webFonts.PACIFICO_REGULAR;
    case extraFontOriginalNames.BebasNeue:
      return webFonts.BEBASNEUE_REGULAR;
    case extraFontOriginalNames.Itim:
      return webFonts.ITIM_REGULAR;
    default:
      return webFonts.JOSEFINESANS_REGULAR;
  }
};

//get days ago if creation date is more than 2 days ago
//otherwise show today or yesterday
export const getDaysAgo = (date: string) => {
  const now = moment.utc();
  const givenDate = moment.utc(date);
  const userTimezone = moment.tz.guess();
  // convert the given date to the user's timezone
  const givenDateInUserTz = givenDate.tz(userTimezone);
  if (now.diff(givenDateInUserTz, 'days') === FixedValue.CONSTANT_VALUE_0) {
    // date is today
    return 'Today';
  } else if (
    now.diff(givenDateInUserTz, 'days') === FixedValue.CONSTANT_VALUE_1
  ) {
    // date is yesterday
    return 'Yesterday';
  } else if (
    now.diff(givenDateInUserTz, 'hours') < FixedValue.CONSTANT_VALUE_1
  ) {
    // date is less than an hour ago
    const minutesAgo = now.diff(givenDateInUserTz, 'minutes');
    return `${minutesAgo} minutes ago`;
  } else if (
    now.diff(givenDateInUserTz, 'days') <= FixedValue.CONSTANT_VALUE_2
  ) {
    // date is within last two days
    const daysAgo = now.diff(givenDateInUserTz, 'days');
    return `${daysAgo} days ago`;
  } else {
    return givenDateInUserTz.format('MMM D, YYYY');
  }
};

//get days ago if creation date is more than 2 days ago
//otherwise show today or yesterday
export const getDateFormated = (date: string) => {
  const now = moment();
  const formattedCOnvert = formatDateTimeToLocalTime(date);
  const givenDate = moment(formattedCOnvert);
  const userTimezone = moment.tz.guess();
  // convert the given date to the user's timezone
  const givenDateInUserTz = givenDate.tz(userTimezone);
  const nowStartOfDay = now.startOf('day');
  const givenDateStartOfDay = givenDateInUserTz.startOf('day');
  if (
    nowStartOfDay.diff(givenDateStartOfDay, 'days') ===
    FixedValue.CONSTANT_VALUE_0
  ) {
    // date is today
    return 'Today';
  } else if (
    nowStartOfDay.diff(givenDateStartOfDay, 'days') ===
    FixedValue.CONSTANT_VALUE_1
  ) {
    // date is yesterday
    return 'Yesterday';
  } else if (
    now.diff(givenDateInUserTz, 'hours') < FixedValue.CONSTANT_VALUE_1
  ) {
    // date is less than an hour ago
    const minutesAgo = now.diff(givenDateInUserTz, 'minutes');
    return `${minutesAgo} minutes ago`;
  } else if (
    now.diff(givenDateInUserTz, 'days') <= FixedValue.CONSTANT_VALUE_2
  ) {
    // date is within last two days
    const daysAgo = nowStartOfDay.diff(givenDateStartOfDay, 'days');
    return `${daysAgo} days ago`;
  } else {
    // date is older than two days
    return givenDateInUserTz.format('MMM D, YYYY');
  }
};

export const getGroupInitials = (groupName?: string): string => {
  const words = groupName?.split(' ');
  const initials = words?.map(word => word.charAt(0));
  return initials?.join('') ?? '';
};
export const productsIds = Platform.select({
  ios: ['Mantel000001'],
  android: ['Mantel000001'],
});
export const creditProductsIds = Platform.select({
  ios: ['Mantel000001'],
  android: ['Mantel000001'],
});

export const formatDecimalNumber = (value: number) => {
  if (Number.isInteger(value)) {
    // If it's an integer, display without decimal places
    return value.toString();
  } else {
    // If it has decimal places, display with 2 decimal places
    return value.toFixed(2);
  }
};
export const uTCTimeToLocalTime = () => {
  const date = new Date();
  const givenDate = moment.utc(date);
  // Convert the given date to the user's timezone and format it as a string
  return givenDate.format(timeformat.YYYY_MM_DD_HH_MM_SS);
};

export const showAlert = () => {
  Alert.alert(
    TOAST_TEXT.THANKS_FOR_REPORTING_ONLY,
    TOAST_TEXT.THANKS_FOR_REPORTING,
    [
      {
        text: 'Ok',
      },
    ]
  );
};

export const formatDateTimeToLocalTime = (
  dateTime: string | Date,
  format?: string
) => {
  const givenDateTime = moment.utc(dateTime);
  const userTimezone = moment.tz.guess();
  // convert the given dateTime to the user's timezone
  const givenDateTimeInUserTz = givenDateTime.tz(userTimezone);
  return givenDateTimeInUserTz.format(format);
};

export const convertToIntegerForSmallValues = (amount: number) => {
  if (amount > FixedValue.CONSTANT_VALUE_0 && amount < 0.99) {
    return FixedValue.CONSTANT_VALUE_1;
  } else if (
    amount > FixedValue.CONSTANT_VALUE_1 &&
    amount % FixedValue.CONSTANT_VALUE_1 !== FixedValue.CONSTANT_VALUE_0
  ) {
    return Math.floor(amount);
  } else {
    return amount;
  }
};

export const convertToIntegerForLargeValues = (amount: number) => {
  if (amount > FixedValue.CONSTANT_VALUE_0 && amount < 0.99) {
    return FixedValue.CONSTANT_VALUE_1;
  } else if (
    amount > FixedValue.CONSTANT_VALUE_1 &&
    amount % FixedValue.CONSTANT_VALUE_1 !== FixedValue.CONSTANT_VALUE_0
  ) {
    return Math.ceil(amount);
  } else {
    return amount;
  }
};

export const checkTopCreditGreaterThanOne = (amount?: number) => {
  const amountValue = amount ?? FixedValue.CONSTANT_VALUE_0;
  const concatenatedText =
    amountValue > FixedValue.CONSTANT_VALUE_1
      ? amountValue.toString() + TEXT.TOPCREDITS
      : amountValue.toString() + TEXT.TOPCREDIT;

  return concatenatedText;
};

interface Option {
  text: string;
  style?: string; // You can adjust the type based on your specific needs
  onPress: () => void;
}

export const alertPolyfill = (
  title: string,
  description: string,
  options: Option[]
) => {
  const result = window.confirm(
    [title, description].filter(Boolean).join('\n')
  );

  if (result) {
    const confirmOption = options.find(({style}) => style !== 'cancel');
    confirmOption && confirmOption.onPress();
  } else {
    const cancelOption = options.find(({style}) => style === 'cancel');
    cancelOption && cancelOption.onPress();
  }
};

export const okAlertPolyfill = (
  title: string,
  description: string,
  onPress: () => void
) => {
  window.alert([title, description].filter(Boolean).join('\n'));
  onPress();
};

export function showDoubleActionAlertDialog(
  title: string,
  message: string,
  positiveTitle: string,
  positiveCallback: Function,
  negativeTitle: string,
  negativeCallback: Function
) {
  Alert.alert(
    title,
    message,
    [
      {
        text: negativeTitle,
        style: 'cancel',
        onPress: () => negativeCallback?.(),
      },
      {
        text: positiveTitle,
        style: 'destructive',
        onPress: () => positiveCallback?.(),
      },
    ],
    {cancelable: false}
  );
}

//show alert when user try to select more than top total quanity
export const showAlertForExceedQuantity = (qty: number) => {
  const alertMessage = `You have selected an amount that exceeds the ${qty} tops you own. Please purchase more Tops or reduce the amount.`;
  alert(alertMessage);
};

export const formatTutorialVideoTitle = (title: string) => {
  if (!title) return '';
  // Replace underscores with spaces and convert to sentence case
  return title
    .replace(/_/g, ' ')
    .replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
};
