import {StyleSheet} from 'react-native';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  Percentage,
} from '../../constants';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';

export const layoutStyle = StyleSheet.create({
  container: {
    width: Percentage.PRECENTAGE_100,
    height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
    borderRadius: FixedValue.CONSTANT_VALUE_48,
    ...BASESTYLE.inRow,
    ...BASESTYLE.selfCenter,
    backgroundColor: COLORS.E9EEFB,
    padding: scaleWidth(FixedValue.CONSTANT_VALUE_4),
  },
  activeTabStyle: {
    ...BASESTYLE.flex1,
    ...BASESTYLE.inCenter,
    backgroundColor: COLORS.PRIMARY_BLUE,
    height: Percentage.PRECENTAGE_100,
    width: Percentage.PRECENTAGE_100,
    borderRadius: FixedValue.CONSTANT_VALUE_48,
    shadowColor: COLORS.PRIMARY_BLUE,
    shadowOpacity: FixedValue.CONSTANT_VALUE_03,
    shadowOffset: {
      width: FixedValue.CONSTANT_VALUE_2,
      height: FixedValue.CONSTANT_VALUE_2,
    },
    elevation: FixedValue.CONSTANT_VALUE_2,
  },
  inActiveTabStyle: {
    ...BASESTYLE.flex1,
    ...BASESTYLE.inCenter,
    height: Percentage.PRECENTAGE_100,
    width: Percentage.PRECENTAGE_100,
  },
  activeTextStyle: {
    fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
    fontFamily: FONT_NAMES.SF_SEMIBOLD,
    color: COLORS.WHITE,
  },
  inActiveTextStyle: {
    fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
    fontFamily: FONT_NAMES.SF_SEMIBOLD,
    color: COLORS.PRIMARY_BLUE,
  },
});
