import React from 'react';
import {Svg, Rect} from 'react-native-svg';

import {FixedValue, Percentage, testId} from '../../constants/enum';
import {EditorContext} from '../../contextAPI/editorContext';
import {SCREEN_WIDTH, isWebsite} from '../../utils/responsive';

const RenderSvgItems = () => {
  const {items, dismissKeyboardAndDeselectItem, setIsScroll} =
    React.useContext(EditorContext);

  const scrollEnabled = React.useCallback((): void => {
    setIsScroll(true);
  }, []);

  return (
    <Svg
      id={testId.te_main_svg}
      testID={testId.te_main_svg}
      accessibilityLabel={testId.te_main_svg}
      height={isWebsite() ? Percentage.PRECENTAGE_100 : SCREEN_WIDTH}
      width={isWebsite() ? Percentage.PRECENTAGE_100 : SCREEN_WIDTH}
      // @ts-ignore Used to handle onPointerDown
      onPointerDown={isWebsite() ? dismissKeyboardAndDeselectItem : undefined}
      onPointerUp={isWebsite() ? scrollEnabled : undefined}
      onPressIn={dismissKeyboardAndDeselectItem}
      onPressOut={scrollEnabled}
    >
      {!isWebsite() && (
        <Rect
          x={FixedValue.CONSTANT_VALUE_0}
          y={FixedValue.CONSTANT_VALUE_0}
          width={Percentage.PRECENTAGE_100}
          height={Percentage.PRECENTAGE_100}
          fillOpacity={FixedValue.CONSTANT_VALUE_0}
        />
      )}

      {items.map((item: JSX.Element) => item)}
    </Svg>
  );
};

export default React.memo(RenderSvgItems);
