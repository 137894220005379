import React from 'react';
import {ActivityIndicator, FlatList, View} from 'react-native';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  GlobalStyleValues,
  ROUTE_NAME,
  TEXT,
} from '../../constants';
import useTopBuyingApproval from '../../hooks/componentHooks/useTopBuyingApproval';
import TopbuyingApprovalTile from '../../components/RenderItemTile/topbuyingApprovalTile';
import ConfirmationModal from '../../modals/confirmationModal/confirmationModal';
import {ChildProfileContext} from '../../contextAPI/childProfileContext';
import PayPalModal from '../../modals/paypalModal';
import {TopBuyingStyle} from './topBuying.style';
import TopsListEmptyView from '../../components/emptyListView/topsListEmptyView';
import NoImage from '../../components/renderSvgs/noImage';
import {navigate} from '../../services/navigationService';

const TopBuyingContent = (): JSX.Element => {
  const {
    fetchList,
    topBuyingApprovalData,
    showLoader,
    totalTopsAmount,
    isLoading,
    setShowLoader,
    callAPIForAcceptReject,
    onEndReached,
    callBuyPackTopCreditsPaymentAcceptAndReject,
  } = useTopBuyingApproval();
  const {styles} = TopBuyingStyle();
  const {
    showTopBuyingApprovalModal,
    setShowTopBuyingApprovalModal,
    selectDataForApproveOrReject,
    setSelectDataForApproveOrReject,
    initialLoader,
    setInitialLoader,
    isPaymentTypeIsCard,
  } = React.useContext(ChildProfileContext);
  const isApprove: boolean = selectDataForApproveOrReject.isApprove;
  const selectedData = selectDataForApproveOrReject.selectedData;
  const isRequestForTop = selectedData?.cartId !== undefined;
  const paypalAmountPaid = isRequestForTop
    ? totalTopsAmount
    : selectedData?.walletRequestResponse?.amount ??
      FixedValue.CONSTANT_VALUE_1;
  React.useEffect((): void => {
    setInitialLoader(true);
    fetchList(FixedValue.CONSTANT_VALUE_0);
  }, []);

  const onCloseModal = React.useCallback((): void => {
    setShowTopBuyingApprovalModal(false);
    setSelectDataForApproveOrReject({isApprove: false, selectedData: null});
  }, []);

  const onRejectAPICall = React.useCallback((): void => {
    const isRequestForTop =
      selectDataForApproveOrReject?.selectedData?.cartId !== undefined;
    if (isRequestForTop) {
      callAPIForAcceptReject();
    } else {
      callBuyPackTopCreditsPaymentAcceptAndReject();
    }
  }, [isApprove, selectDataForApproveOrReject]);

  const onApproveAction = React.useCallback(async (): Promise<void> => {
    setShowTopBuyingApprovalModal(false);
    if (isRequestForTop) {
      let totalPrice = FixedValue.CONSTANT_VALUE_0;
      selectedData?.tops?.forEach(top => {
        totalPrice += top.price;
      });
      if (totalPrice > FixedValue.CONSTANT_VALUE_0) {
        if (isPaymentTypeIsCard) {
          setShowLoader(true);
        } else {
          callAPIForAcceptReject();
        }
      } else {
        callAPIForAcceptReject();
      }
    } else {
      if (isApprove) {
        setShowLoader(true);
      } else {
        callBuyPackTopCreditsPaymentAcceptAndReject();
      }
    }
  }, [selectedData, isApprove, isPaymentTypeIsCard, isRequestForTop]);

  return (
    <>
      <FlatList
        data={topBuyingApprovalData}
        keyExtractor={(item, index) =>
          `topid-${item.creationDate}withIndex-${index}`
        }
        contentContainerStyle={styles.contentContainerStyle}
        showsVerticalScrollIndicator={false}
        style={BASESTYLE.flex1}
        ListEmptyComponent={
          isLoading || initialLoader ? (
            <></>
          ) : (
            <TopsListEmptyView
              emptyViewTitle={TEXT.NO_TOP_BUYING_APPROVAL_FOUND}
              emptyViewDesc={''}
              placeHolder={<NoImage />}
            />
          )
        }
        onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
        onEndReached={onEndReached}
        renderItem={({item}) => (
          <TopbuyingApprovalTile {...item} cartId={item?.cartId} />
        )}
        ItemSeparatorComponent={() => (
          <View style={styles.topBuyingUnderline} />
        )}
      />
      {showTopBuyingApprovalModal && (
        <ConfirmationModal
          modalVisible={showTopBuyingApprovalModal}
          title={isApprove ? TEXT.REQUEST_APPROVE : TEXT.REQUEST_REJECT}
          onPressYes={
            isApprove ? () => onApproveAction() : () => onRejectAPICall()
          }
          onShowHide={onCloseModal}
        />
      )}
      {showLoader && (
        <View style={styles.loaderView}>
          <ActivityIndicator
            size={GlobalStyleValues.SMALL}
            color={COLORS.PRIMARY_BLUE}
          />
        </View>
      )}
      {!!showLoader && (
        <PayPalModal
          totalTopsAmount={paypalAmountPaid}
          modalVisible={true}
          onClose={() => setShowLoader(false)}
          onSuccessPayment={() =>
            isRequestForTop
              ? callAPIForAcceptReject()
              : callBuyPackTopCreditsPaymentAcceptAndReject()
          }
          onCancelPayment={() =>
            navigate(ROUTE_NAME.PAYMENT_SUCCESS, {
              isFailed: true,
              isBack: true,
              mainText: TEXT.TRANSACTION_CANCELED,
              subText: TEXT.WE_REGET_TO_INFORM_YOU_THAT,
            })
          }
        />
      )}
    </>
  );
};

export default React.memo(TopBuyingContent);
