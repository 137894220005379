import React from 'react';
import {View, Pressable} from 'react-native';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import {style} from './style';
import {GroupContext} from '../../contextAPI/groupManagementContext';
import TopBarWithRightButton from '../../components/header/topBarWithRightButton';
import ThreeDots from '../../components/renderSvgs/threeDots';
import {ThemeContext} from '../../contextAPI/themeContext';
import {useIsFocused} from '@react-navigation/native';
import GroupDetailUpperContent from './groupDetailUpperContent';
import GroupDetailUserList from './groupDetailUserList';
import useGroupManagementListHooks from '../../hooks/componentHooks/useGroupManagementListHooks';
import {BASESTYLE, COLORS, FixedValue, TEXT} from '../../constants';
import {goBack} from '../../services/navigationService';
import PrimaryButton from '../../components/button/primaryButton';
import CustomBottomListModal from '../../modals/customBottomListModal';
import EditPencil from '../../components/renderSvgs/editPencil';
import {isWebsite, scaleHeight, scaleWidth} from '../../utils/responsive';
import DeleteIcon from '../../components/renderSvgs/deleteBin';
import CustomDeleteModal from '../../modals/customDeleteModal';
import CreateEditGroupFormModal from '../../modals/createEditGroupModal';
import useCreateEditGroupHooks from '../../hooks/componentHooks/useCreateEditGroupHooks';
import UploadGalleryImage from '../../components/renderSvgs/uploadGalleryImage';
import CameraImage from '../../components/renderSvgs/cameraImage';
import {useAppSelector} from '../../hooks/reduxHooks';
import {groupManagementStore} from '../../redux/reducers/groupManagementSlice';
import {UPDATE_GROUP} from '../../constants/actionTypes';
import CustomModalForSelectUser from '../../modals/customModalForSelectUser';

const GroupDetailScreen = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = style(ColorTheme);
  const isFocused = useIsFocused();
  const {
    callGroupUserListAPI,
    callGroupDetailAPI,
    openGroupDeleteAlertModal,
    callDeleteGroupAPI,
    leaveGroupAPIFunction,
    callMyGroupListAPI,
  } = useGroupManagementListHooks();
  const {
    openGroupEditModal,
    _removeImage,
    _takePicture,
    _cancelButtonAction,
    _onChangeSearchText,
    onReachedEnd,
    callAddUserToGroupAPI,
    openUserAddModal,
    _openGallery,
  } = useCreateEditGroupHooks();
  const {
    groupUserListResult,
    groupDetailData,
    isUserComingFromMyGroup,
    openCloseGroupEditDeleteModal,
    showDeleteGroupModal,
    openCreateGroupModal,
    groupProfileImages,
    groupInitialImage,
    openCloseProfileModal,
    showLeaveGroupModal,
    selectUserListModal,
    searchUserListText,
    userListResult,
    selectedUsers,
    initialLoader,
    selectOrUnSelectUser,
    setSelectUserListModal,
    setShowLeaveGroupModal,
    setOpenCloseProfileModal,
    setShowDeleteGroupModal,
    clearGroupUserListResult,
    setOpenCloseGroupEditDeleteModal,
    setOpenCreateGroupModal,
    removeProfileImage,
    setInitialLoader,
  } = React.useContext(GroupContext);
  const {updateGroup, userAddInGroup} = useAppSelector(groupManagementStore);
  React.useEffect(() => {
    if (isFocused) {
      setInitialLoader(true);
      callGroupUserListAPI(FixedValue.CONSTANT_VALUE_0);
      callGroupDetailAPI();
    }
  }, [isFocused]);
  const iconHeight = scaleHeight(FixedValue.CONSTANT_VALUE_16);
  const iconHWidth = scaleWidth(FixedValue.CONSTANT_VALUE_16);
  const subHeaderFullText =
    TEXT.ARE_YOU_SURE_DELETE + `"${groupDetailData?.groupName}"?`;

  const Icon = (
    <DeleteIcon
      stroke={COLORS._FF3D3D}
      width={scaleWidth(FixedValue.CONSTANT_VALUE_28)}
      height={scaleHeight(FixedValue.CONSTANT_VALUE_40)}
    />
  );
  const customDeleteModalData = {
    Icon: Icon,
    subHeader: subHeaderFullText,
    modalVisible: showDeleteGroupModal,
    closeModal: setShowDeleteGroupModal,
    onYesButton: callDeleteGroupAPI,
    onNoButton: setShowDeleteGroupModal,
  };

  const leaveGroupModalData = {
    Icon: Icon,
    subHeader: TEXT.ARE_YOU_SURE_LEAVE,
    modalVisible: showLeaveGroupModal,
    closeModal: setShowLeaveGroupModal,
    onYesButton: leaveGroupAPIFunction,
    onNoButton: setShowLeaveGroupModal,
  };

  const listData = [
    {
      LeftIcon: UploadGalleryImage,
      label: TEXT.UPLOAD_A_PHOTO,
      onPress: _openGallery,
      isDelete: false,
    },
    {
      LeftIcon: CameraImage,
      label: TEXT.TAKE_A_PHOTO,
      onPress: _takePicture,
      isDelete: false,
    },
  ];

  if (isWebsite()) {
    listData.splice(FixedValue.CONSTANT_VALUE_1, FixedValue.CONSTANT_VALUE_1);
  }

  const listDataWithDelete = [
    ...listData,
    {
      LeftIcon: DeleteIcon,
      label: TEXT.REMOVE,
      onPress: _removeImage,
      isDelete: true,
    },
  ];

  const customUserSelectModaldata = {
    modalHeaderName: TEXT.ADD_USERS,
    modalVisible: selectUserListModal,
    value: searchUserListText,
    searchPlaceholder: TEXT.SEARCH_USERS,
    listData: userListResult,
    showInvitationPending: true,
    closeModal: setSelectUserListModal,
    onChangeText: _onChangeSearchText,
    onSelectOrUnSelectUser: selectOrUnSelectUser,
    buttonLoader: userAddInGroup?.isLoading ?? false,
    callAddUserToGroupAPI,
    selectedUsers,
    onReachedEnd,
    initialLoader,
  };

  const customModalDataForImage = {
    modalHeaderName: TEXT.PROFILE_IMAGE,
    height: iconHeight,
    width: iconHWidth,
    cancelButtonBlue: true,
    closeModal: setOpenCloseProfileModal,
    modalVisible: openCloseProfileModal,
    cancelButton: _cancelButtonAction,
    listData:
      groupProfileImages || groupInitialImage ? listDataWithDelete : listData,
  };

  const customModaldata = {
    modalHeaderName: TEXT.MORE,
    height: iconHeight,
    width: iconHWidth,
    closeModal: () => setOpenCloseGroupEditDeleteModal(false),
    modalVisible: openCloseGroupEditDeleteModal,
    cancelButtonBlue: true,
    listData: [
      {
        LeftIcon: EditPencil,
        label: TEXT.EDIT_GROUP,
        onPress: openGroupEditModal,
        isDelete: false,
      },
      {
        LeftIcon: DeleteIcon,
        label: TEXT.DELETE_GROUP,
        onPress: openGroupDeleteAlertModal,
        isDelete: true,
      },
    ],
  };

  const RenderGroupUpperContent = React.useMemo((): JSX.Element | undefined => {
    return (
      <GroupDetailUpperContent
        // @ts-ignore
        item={groupDetailData}
      />
    );
  }, [groupDetailData]);

  const RenderGroupUserListContent = React.useMemo(():
    | JSX.Element
    | undefined => {
    return (
      <GroupDetailUserList
        userDetail={groupUserListResult}
        showLeaveGroupButton={isUserComingFromMyGroup}
      />
    );
  }, [groupUserListResult]);

  //after calling group Update api response handle
  React.useEffect(() => {
    switch (updateGroup.status) {
      case UPDATE_GROUP.SUCCESS:
        callGroupDetailAPI();
        setOpenCreateGroupModal();
        removeProfileImage();
        callMyGroupListAPI(FixedValue.CONSTANT_VALUE_0);
        break;
      case UPDATE_GROUP.FAILED:
        break;
      default:
        break;
    }
  }, [updateGroup.status]);
  return (
    <SafeAreaContainer>
      <View style={styles.container} dataSet={{media: ids.container}}>
        <TopBarWithRightButton
          headerLabel=""
          Icon={
            <Pressable
              onPress={() =>
                isUserComingFromMyGroup &&
                setOpenCloseGroupEditDeleteModal(true)
              }
              style={styles.iconView}
            >
              {isUserComingFromMyGroup && (
                <ThreeDots stroke={ColorTheme.color} />
              )}
            </Pressable>
          }
          onPress={() => {
            clearGroupUserListResult();
            goBack();
          }}
        />
        {RenderGroupUpperContent}
        <View style={BASESTYLE.flex1}>
          {RenderGroupUserListContent}
          {isUserComingFromMyGroup && (
            <PrimaryButton
              buttonLabel={TEXT.ADD_NEW_USER}
              buttonwidth={styles.buttonStyle}
              labelStyle={styles.buttonLabelstyle}
              onPress={() => openUserAddModal()}
            />
          )}
        </View>
        {selectUserListModal && (
          <CustomModalForSelectUser
            // @ts-ignore
            customModaldata={customUserSelectModaldata}
          />
        )}
        {openCloseGroupEditDeleteModal && (
          <CustomBottomListModal
            // @ts-ignore
            customModaldata={customModaldata}
          />
        )}
        {showDeleteGroupModal && (
          <CustomDeleteModal customDeleteModalData={customDeleteModalData} />
        )}
        {openCreateGroupModal && <CreateEditGroupFormModal />}

        {openCloseProfileModal && (
          <CustomBottomListModal customModaldata={customModalDataForImage} />
        )}
        {showLeaveGroupModal && (
          <CustomDeleteModal customDeleteModalData={leaveGroupModalData} />
        )}
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(GroupDetailScreen);
