import React from 'react';
import {View, FlatList} from 'react-native';
import {RefreshControl} from 'react-native-web-refresh-control';
import {useIsFocused} from '@react-navigation/native';

import {NotificationTabStyle} from './notification.style';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {NotificationContext} from '../../../contextAPI/notificationContext';
import EmptyNotification from '../../../components/emptyListView/emptyNotification';
import useNotificationHook from '../../../hooks/componentHooks/useNotificationHooks';
import {FixedValue, NOTIFICATION_LIST_TYPE} from '../../../constants/enum';
import {BASESTYLE} from '../../../constants';
import {notificationState} from '../../../redux/reducers/notificationSlice';
import {useAppSelector} from '../../../hooks/reduxHooks';
import AppLoader from '../../../components/smallDesigns/appLoader';
import NotificationCards from '../../../components/notificationCards';
import {_ToastHandler} from '../../../utils/helperFunctions';

const NotificationScreenForCompleted = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const isFocused = useIsFocused();
  const {styles} = NotificationTabStyle(ColorTheme);
  const {isRefreshing, notificationCompletedListData} =
    React.useContext(NotificationContext);
  const {handleRefresh, onNotificationCompletedListReachedEnd, FlatlistRef} =
    useNotificationHook();
  const {notificationCompletedData} = useAppSelector(notificationState);

  React.useEffect((): void => {
    if (isFocused) {
      if (notificationCompletedListData.length > FixedValue.CONSTANT_VALUE_0)
        //@ts-ignore
        FlatlistRef.current?.scrollToIndex({
          index: FixedValue.CONSTANT_VALUE_0,
        });
    }
  }, [isFocused]);

  return (
    <>
      <View style={styles.container}>
        {!isRefreshing && notificationCompletedData.isLoading && <AppLoader />}
        <FlatList
          //@ts-ignore
          ref={FlatlistRef}
          data={notificationCompletedListData}
          refreshControl={
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={() => handleRefresh(NOTIFICATION_LIST_TYPE.COMPLETED)}
            />
          }
          onEndReached={onNotificationCompletedListReachedEnd}
          onEndReachedThreshold={FixedValue.CONSTANT_VALUE_05}
          style={BASESTYLE.flex1}
          keyExtractor={(item, i) => `${item.message}-index${i}`}
          showsVerticalScrollIndicator={false}
          ItemSeparatorComponent={() => <View style={styles.bottomLineStyle} />}
          //@ts-ignore
          renderItem={({item, index}) => (
            <NotificationCards item={item} index={index} />
          )}
          ListEmptyComponent={
            notificationCompletedData.isLoading ? (
              <View />
            ) : (
              <EmptyNotification />
            )
          }
        />
      </View>
    </>
  );
};

export default React.memo(NotificationScreenForCompleted);
