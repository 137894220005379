import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FONTS,
  FONT_NAMES,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const MyWalletStyle = (_theme?: IColorTheme) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.flex1,
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    walletBackgroundImage: {
      position: GlobalStyleValues.ABSOLUTE,
      top: scaleHeight(FixedValue.CONSTANT_VALUE_130),
    },
    walletAmountText: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_22),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_64),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: COLORS.RGBA_31_83_211,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_76),
      textAlign: GlobalStyleValues.CENTER,
    },
    topCreditText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: COLORS.RGBA_31_83_211,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_25),
      textAlign: GlobalStyleValues.CENTER,
    },
    exchangeAmount: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_11),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_13),
      fontFamily: FONT_NAMES.SFUIText_MediumItalic,
      color: COLORS.RGBA_31_83_211,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_15),
      textAlign: GlobalStyleValues.CENTER,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_8),
    },
    addTopCredit: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_61),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      backgroundColor: GlobalStyleValues.TRANSPARENT,
      justifyContent: GlobalStyleValues.CENTER,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_30),
    },
    addTopCreditStyle: {
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.BDBDBD,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_25),
    },
    addTopCreditStyleButtonDisaled: {
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_25),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
    },
    tagContainer: {
      backgroundColor: COLORS.WHITE,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_52),
      paddingVertical: scaleHeight(FixedValue.CONSTANT_VALUE_6),
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_12),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_12),
      marginBottom: scaleWidth(FixedValue.CONSTANT_VALUE_12),
    },
    tagText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      textAlign: GlobalStyleValues.CENTER,
      color: COLORS.BLACK,
    },
    planMainView: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_18),
      marginHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_30),
      alignItems: GlobalStyleValues.CENTER,
    },
    transactionHistoryView: {
      ...BASESTYLE.flex1,
      backgroundColor: _theme?.color,
    },
    straightLineView: {
      alignSelf: GlobalStyleValues.CENTER,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    addCreditText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_24),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      color: COLORS.BLACK,
      fontFamily: FONTS.SF_BOLD,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_5),
    },
    myWalletText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      color: COLORS.BLACK,
      fontFamily: FONTS.SF_MEDIUM,
    },
    myTransaction: {
      alignSelf: GlobalStyleValues.CENTER,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_18),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      color: COLORS._1E1F1F,
      fontFamily: FONTS.SF_BOLD,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      width: isWebsite()
        ? FixedValue.CONSTANT_VALUE_500
        : Percentage.PRECENTAGE_100,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_30),
    },
    dateText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      color: COLORS._1E1F1F,
      fontFamily: FONTS.SF_LIGHT,
      marginVertical: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    amountText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      color: COLORS._4EAF51,
      fontFamily: FONTS.SF_MEDIUM,
    },
    underline: {
      borderBottomWidth: scaleWidth(FixedValue.CONSTANT_VALUE_1),
      borderColor: COLORS._E6E6E6,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      marginHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      width: GlobalStyleValues.AUTO,
    },
    sectionListPaddingBottom: {
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_60),
    },
    walletLoader: {
      position: GlobalStyleValues.ABSOLUTE,
      right: FixedValue.CONSTANT_VALUE_0,
      left: FixedValue.CONSTANT_VALUE_0,
      top: FixedValue.CONSTANT_VALUE_0,
      bottom: FixedValue.CONSTANT_VALUE_0,
      alignItems: GlobalStyleValues.CENTER,
      justifyContent: GlobalStyleValues.CENTER,
      backgroundColor: COLORS.WHITE
    },
    mainContainer: {
      ...BASESTYLE.flex1,
      backgroundColor: _theme?.backgroundColor,
      ...BASESTYLE.alignCenter,
    },
    requestView: {
      position: GlobalStyleValues.ABSOLUTE,
      ...BASESTYLE.alignCenter,
      zIndex: FixedValue.CONSTANT_VALUE_10,
      ...BASESTYLE.flex1,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_229),
    },
    button: {
      width: scaleHeight(FixedValue.CONSTANT_VALUE_250),
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      borderRadius: FixedValue.CONSTANT_VALUE_100,
    },
    yesButtonLabelstyle: {
      fontFamily: FONT_NAMES.SF_MEDIUM,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_17),
      color: COLORS.WHITE,
    },
    request: {
      fontFamily: FONT_NAMES.SF_MEDIUM,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_32),
      ...BASESTYLE.textAlignCenter,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_24),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_25),
      color: _theme?.color,
    },
    wait: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_22),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_LIGHT,
      ...BASESTYLE.textAlignCenter,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_17),
      color: _theme?.color,
    },
    transferTopCredit: {
      ...BASESTYLE.inRow,
      justifyContent: GlobalStyleValues.CENTER,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_14),
      paddingVertical: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    transferText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_26),
      color: COLORS.PRIMARY_BLUE,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_4),
    },
    bottomsheetView: {
      backgroundColor: _theme?.color,
    },
    bottomPadding100: {
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_100),
    },
    innerFlat: {
      ...BASESTYLE.flex1,
      backgroundColor: COLORS.WHITE,
    },
    flatlistView: {
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
      width: isWebsite()
        ? FixedValue.CONSTANT_VALUE_500
        : Percentage.PRECENTAGE_100,
      alignSelf: GlobalStyleValues.CENTER,
      backgroundColor: COLORS.WHITE,
    },
    scrollContainer: {
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_600),
    },
    bottomSheetInnerView: {
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
      width: isWebsite()
        ? FixedValue.CONSTANT_VALUE_500
        : Percentage.PRECENTAGE_100,
      alignSelf: GlobalStyleValues.CENTER,
      backgroundColor: COLORS.WHITE,
    },
  });
