import React from 'react';
import {View, Text} from 'react-native';

import {TabBarName, TEXT} from '../../../constants';
import {SearchTabContext} from '../../../contextAPI/SearchTabBarContext';
import {ThemeContext} from '../../../contextAPI/themeContext';
import useSearchTabHooks from '../../../hooks/componentHooks/useSearchTabHooks';
import SearchInput from '../../input/searchInput';
import {styles} from './styles';
import TitleWithButton from '../titleWithButton';

const SearchTabHeader = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {searchText} = React.useContext(SearchTabContext);
  const style = styles(ColorTheme);
  const {onChangeSearchText} = useSearchTabHooks();

  return (
    <View>
      <TitleWithButton title={TabBarName.SEARCH} />
      <SearchInput
        value={searchText}
        onChangeText={onChangeSearchText}
        placeholder={TEXT.SEARCH_BY_TOPS}
      />
    </View>
  );
};

export default React.memo(SearchTabHeader);
