import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

import NotificationBell from '../renderSvgs/notificationBell';
import {BASESTYLE, FONT_NAMES, FixedValue} from '../../constants';
import {normalizeFont, scaleHeight} from '../../utils/responsive';
import {ThemeContext} from '../../contextAPI/themeContext';
import {IColorTheme} from '../../types/contextTypes/themeContext';

const styles = (theme: IColorTheme) =>
  StyleSheet.create({
    emptyView: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.alignCenter,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_90),
    },
    emptyText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: theme.color,
    },
  });

const EmptyNotification = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  return (
    <View style={style.emptyView}>
      <NotificationBell fill={'red'}/>
      <Text style={style.emptyText}>No new notifications</Text>
    </View>
  );
};

export default EmptyNotification;
