import React from 'react';
import {Pressable, View} from 'react-native';
import ScrollableContainer from '../../components/layout/scrollableContainer';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import {RouteProp, useIsFocused, useRoute} from '@react-navigation/native';
import {RootStackParamList} from '../../types/navigationType';
import {ChildprofileStyle} from './child.style';
import ChildprofileUpperContent from './childprofileUpperContent';
import ChildProfileBottomBtn from './childProfileBottomBtn';
import {FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import useChildListHooks from '../../hooks/componentHooks/useChildListHooks';
import {useAppSelector} from '../../hooks/reduxHooks';
import {userProfileStore} from '../../redux/reducers/userProfileSlice';
import {GET_CHILD_PROFILE_DATA} from '../../constants/actionTypes';
import {ChildProfileContext} from '../../contextAPI/childProfileContext';
import TopBarWithRightButton from '../../components/header/topBarWithRightButton';
import Settings from '../../components/renderSvgs/settings';
import {navigate} from '../../services/navigationService';
import {ThemeContext} from '../../contextAPI/themeContext';
import {TOAST_TEXT} from '../../constants/textMessage';
import {_ToastHandler} from '../../utils/helperFunctions';

const ChildProfile = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = ChildprofileStyle();
  const route = useRoute<RouteProp<RootStackParamList, 'ChildProfile'>>();
  const {callChildProfileAPI} = useChildListHooks();
  const isFocused = useIsFocused();
  const {childProfileData} = useAppSelector(userProfileStore);
  const {setChildUserData, childUserData} =
    React.useContext(ChildProfileContext);

  React.useEffect(() => {
    if (isFocused) {
      callChildProfileAPI(route?.params?.item?.id);
    }
  }, [isFocused]);

  React.useEffect(() => {
    switch (childProfileData.status) {
      case GET_CHILD_PROFILE_DATA.SUCCESS:
        setChildUserData(childProfileData?.success?.data ?? route?.params.item);
        break;
      case GET_CHILD_PROFILE_DATA.FAILED:
        setChildUserData(route?.params.item);
        break;
      default:
        break;
    }
  }, [childProfileData.status]);

  const RenderChildProfileUpperContent = React.useMemo(():
    | JSX.Element
    | undefined => {
    return <ChildprofileUpperContent item={childUserData} />;
  }, [childUserData]);

  const RenderChildProfileBottomContent = React.useMemo(():
    | JSX.Element
    | undefined => {
    return <ChildProfileBottomBtn childObj={childUserData} />;
  }, [childUserData]);

  const onPressChildSettingIcon = React.useCallback((): void => {
    if (
      childUserData?.accountApprovalRequests === FixedValue.CONSTANT_VALUE_0
    ) {
      navigate(ROUTE_NAME.CHILD_PROFILE_ALL_SETTING);
    } else {
      _ToastHandler(TOAST_TEXT.CHILD_REQUEST_NOT_APPOVED, false);
    }
  }, []);

  return (
    <ScrollableContainer>
      <SafeAreaContainer>
        <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
          <TopBarWithRightButton
            headerLabel={TEXT.KID}
            Icon={
              <Pressable onPress={onPressChildSettingIcon}>
                <Settings stroke={ColorTheme.color} />
              </Pressable>
            }
            headerColor={ColorTheme.color}
          />
          {RenderChildProfileUpperContent}
          {RenderChildProfileBottomContent}
        </View>
      </SafeAreaContainer>
    </ScrollableContainer>
  );
};

export default React.memo(ChildProfile);
