import {StyleSheet} from 'react-native';
import {BASESTYLE, COLORS, FONT_NAMES, FixedValue} from '../../../constants';
import {
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';
import {IColorTheme} from '../../../types/contextTypes/themeContext';

export const topBuyingApprovalTileStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    mainView: {
      ...BASESTYLE.row,
      ...BASESTYLE.spaceBetween,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_16),
    },
    firstName: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_24),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: theme?.color,
    },
    day: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_10),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: theme?.color,
    },
    amount: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_REGULAR,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_24),
      color: theme?.color,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_4),
    },
    imageView: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_78),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_71),
      backgroundColor: theme?.color,
      ...BASESTYLE.inCenter,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_8),
    },
    buttonView: {
      ...BASESTYLE.row,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_11),
    },
    primaryButtonStyle: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_38),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_121),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      marginRight: FixedValue.CONSTANT_VALUE_10,
    },
    primaryButtonStyle2: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_38),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_118),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
    },
    primaryButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    primaryButtonLabelstyle1: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.F54336,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    noButtonBackGroundColor: {
      backgroundColor: COLORS.FDE4E1,
    },
    noButtonBackGroundColor1: {
      backgroundColor: COLORS._4EAF51,
    },
  });
