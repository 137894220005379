import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  FONTS,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const allTopsStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.alignCenter,
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_0),
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      ...BASESTYLE.selfCenter,
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_0,
        width: Percentage.PRECENTAGE_100,
      },
    },
    indicator: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_21),
    },
    contentContainer: {
      ...BASESTYLE.flex1,
      width: Percentage.PRECENTAGE_100,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_5),
      backgroundColor: COLORS.TRANSPARENT,
    },
    primaryButtonStyle: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.absolutePosition,
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_205),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
    },
    primaryButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
    topHeader: {
      paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
      width: Percentage.PRECENTAGE_100,
    },
    nextButtonView: {
      ...BASESTYLE.selfCenter,
      width: Percentage.PRECENTAGE_100,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_15),
      ...BASESTYLE.absolutePosition,
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_21),
    },
    nextButtonStyle: {
      ...BASESTYLE.inRow,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_61),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      backgroundColor: 'transparent',
      justifyContent: GlobalStyleValues.SPACE_BETWEEN,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_30),
    },
    nextButtonTextStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_25),
    },
    applyCouponButton: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_61),
      borderRadius: FixedValue.CONSTANT_VALUE_8,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_13),
    },
    applyCouponTextStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: COLORS.PRIMARY_BLUE,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
    },
    applyCouponBackgroundColor: {
      backgroundColor: COLORS.E9EEFB,
    },
    appliedCouponButton: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_61),
      borderRadius: FixedValue.CONSTANT_VALUE_8,
      borderWidth: FixedValue.CONSTANT_VALUE_1,
      borderColor: COLORS.C2D2ED,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_13),
    },
    appliedCouponTextStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_11),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: COLORS.BLACK,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_14),
    },
    appliedCouponSecondTextStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: COLORS._666666,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_3),
    },
    appliedCouponBackgroundColor: {
      backgroundColor: COLORS.WHITE,
    },
    butTopButton: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_61),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      backgroundColor: GlobalStyleValues.TRANSPARENT,
      justifyContent: GlobalStyleValues.CENTER,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_30),
      width: Percentage.PRECENTAGE_100,
    },
    totalTopstext: {
      ...BASESTYLE.absolutePosition,
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_50),
      width: Percentage.PRECENTAGE_100,
      textAlign: GlobalStyleValues.CENTER,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_7),
      color: _theme.color,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONTS.SF_MEDIUM,
    },
    walletContainer: {
      borderColor: COLORS.C2D2ED,
      borderWidth: FixedValue.CONSTANT_VALUE_1,
      flexDirection: GlobalStyleValues.ROW,
      alignItems: GlobalStyleValues.CENTER,
      justifyContent: GlobalStyleValues.CENTER,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_17),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_16),
      paddingRight: scaleWidth(FixedValue.CONSTANT_VALUE_17),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_77),
    },
    content: {
      ...BASESTYLE.flex1,
      paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    myWalletText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      color: _theme.color,
      fontFamily: FONTS.SF_MEDIUM,
    },
    topCreditText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      color: _theme.color,
      fontFamily: FONTS.SF_BOLD,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_5),
    },
    topcreditsTextStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
    },
    addTopCreditButton: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_50),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_95),
    },
    addTopCreditTextStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_10),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: COLORS.WHITE,
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_16),
    },
    addTopCreditBackgroundColor: {
      backgroundColor: COLORS._4EAF51,
    },
    summaryContainer: {
      borderColor: COLORS.C2D2ED,
      borderWidth: FixedValue.CONSTANT_VALUE_1,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_13),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      width: Percentage.PRECENTAGE_100,
    },
    commonText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      color: COLORS._GREY,
      fontFamily: FONTS.SF_MEDIUM,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_16),
    },
    commonText2: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      color: _theme.color,
      fontFamily: FONTS.SF_BOLD,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_5),
    },
    paddingTop9: {
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_9),
    },
    underLine: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_1),
      backgroundColor: COLORS.RGBA_0_0_0_1,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    couponButtonView: {
      ...BASESTYLE.selfCenter,
      width: Percentage.PRECENTAGE_100,
    },
    buyButtonView: {
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      width: Percentage.PRECENTAGE_100,
    },
    horizontalPaddding: {
      width: Percentage.PRECENTAGE_100,
      paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_15),
      paddingRight: scaleWidth(FixedValue.CONSTANT_VALUE_15),
    },
    totalOrderContainer: {
      borderColor: COLORS.C2D2ED,
      borderWidth: FixedValue.CONSTANT_VALUE_1,
      flexDirection: GlobalStyleValues.ROW,
      alignItems: GlobalStyleValues.CENTER,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_14),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_16),
      paddingRight: scaleWidth(FixedValue.CONSTANT_VALUE_17),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_77),
    },
    totalOrder: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      color: COLORS._GREY,
      fontFamily: FONTS.SF_MEDIUM,
    },
    balanceText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_19),
      color: _theme.color,
      fontFamily: FONTS.SF_REGULAR,
    },
    marginTopdynamic: {
      marginTop: scaleHeight(-FixedValue.CONSTANT_VALUE_16),
    },
    loaderStyle: {
      position: GlobalStyleValues.ABSOLUTE,
      right: FixedValue.CONSTANT_VALUE_0,
      left: FixedValue.CONSTANT_VALUE_0,
      top: FixedValue.CONSTANT_VALUE_0,
      bottom: FixedValue.CONSTANT_VALUE_0,
      alignItems: GlobalStyleValues.CENTER,
      justifyContent: GlobalStyleValues.CENTER,
    },
    deleteBinView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_29),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_29),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_29),
      backgroundColor: COLORS._FFEBEB,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_4),
    },
    grandTotalText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_16),
      color: _theme.color,
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      fontFamily: FONTS.SF_MEDIUM,
    },
    marginTop13: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_13),
    },
  });
