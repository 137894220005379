import React from 'react';

import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import ScrollableContainer from '../../components/layout/scrollableContainer';
import ChangePasswordContent from './changePasswordContent';

const ChangePassword = (): JSX.Element => {
  return (
    <ScrollableContainer>
      <SafeAreaContainer>
        <ChangePasswordContent />
      </SafeAreaContainer>
    </ScrollableContainer>
  );
};

export default React.memo(ChangePassword);
