import React from 'react';
import {StyleSheet} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';

import {BASESTYLE} from '../../constants';
import {edges} from '../../constants/enum';
import {ThemeContext} from '../../contextAPI/themeContext';
import {ISafeAreaProps} from '../../types/componentTypes/layoutType';
import {IColorTheme} from '../../types/contextTypes/themeContext';

const styles = (theme: IColorTheme) =>
  StyleSheet.create({
    viewStyle: {
      ...BASESTYLE.flex1,
    },
  });

const SafeAreaContainer = (props: ISafeAreaProps): JSX.Element => {
  const {children, SafeAreaProps} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);
  const backgroundColor = props?.backgroundColor
    ? props?.backgroundColor
    : ColorTheme.backgroundColor;
  return (
    <SafeAreaView
      edges={[edges.TOP, edges.RIGHT, edges.LEFT]}
      style={[style.viewStyle, {backgroundColor}]}
      {...SafeAreaProps}
    >
      {children}
    </SafeAreaView>
  );
};

export default SafeAreaContainer;
