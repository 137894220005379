import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FONTS,
  FixedValue,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const inProgressTopsStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.selfCenter,
      ...BASESTYLE.alignCenter,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      backgroundColor: COLORS.TRANSPARENT,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_15),
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    indicator: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_21),
    },
    contentContainer: {
      ...BASESTYLE.flex1,
      width: Percentage.PRECENTAGE_100,
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_75),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_5),
      backgroundColor: COLORS.TRANSPARENT,
    },
    eachTopCost: {
      backgroundColor: COLORS._FEF5CC,
      alignSelf: GlobalStyleValues.CENTER,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_5),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONTS.SFUIText_LightItalic,
      color: COLORS.BLACK,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_14),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_5),
    },
    topCreditFont: {
      fontFamily: FONTS.SF_MEDIUM,
      fontStyle: GlobalStyleValues.ITALIC,
    },
  });
