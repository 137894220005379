import {createSlice} from '@reduxjs/toolkit';
import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {TutorialListStateType} from '../../types/reducerTypes/authStoreTypes';
import {ResetAllState} from './logoutslice';

const {StoreConstants} = AppConst;
const {TUTORIAL_LIST} = ActionType;

const initialState: TutorialListStateType = {
  tutorialList: {
    isLoading: false,
    failed: null,
    data: null,
    status: TUTORIAL_LIST.END,
    success: false,
  },
};

const TutorialListSlice = createSlice({
  initialState,
  name: StoreConstants.TUTORIALS_VIDEO,
  reducers: {
    onTutorialListStart: (state, _) => {
      state.tutorialList.isLoading = true;
      state.tutorialList.status = TUTORIAL_LIST.START;
      state.tutorialList.success = false;
    },
    onTutorialListSuccess: (state, action) => {
      state.tutorialList.data = action.payload ?? [];
      state.tutorialList.status = TUTORIAL_LIST.SUCCESS;
      state.tutorialList.success = true;
    },
    onTutorialListFailed: (state, action) => {
      state.tutorialList.failed = action.payload;
      state.tutorialList.status = TUTORIAL_LIST.FAILED;
      state.tutorialList.success = false;
    },
    onTutorialListEnd: state => {
      state.tutorialList.isLoading = false;
      state.tutorialList.status = TUTORIAL_LIST.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  onTutorialListStart,
  onTutorialListSuccess,
  onTutorialListFailed,
  onTutorialListEnd,
} = TutorialListSlice.actions;
export default TutorialListSlice.reducer;
export const tutorialListState = (state: RootState) => state.tutorialList;
