import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  COLORS,
  FONT_NAMES,
  FixedValue,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';

export const cancleModalStyle = (_theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.center,
      width: Percentage.PRECENTAGE_100,
      backgroundColor: _theme.backgroundColor,
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_15),
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_18),
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_18,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_18,
      shadowColor: COLORS._333333,
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_0,
        height: -FixedValue.CONSTANT_VALUE_3,
      },
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
      elevation: FixedValue.CONSTANT_VALUE_3,
    },
    wantDelete: {
      ...BASESTYLE.textAlignCenter,
      color: _theme.color,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_55),
    },

    primaryButtonStyle: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_159_5),
      borderRadius: FixedValue.CONSTANT_VALUE_100,
    },
    primaryButtonStyle2: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_159_5),
      borderRadius: FixedValue.CONSTANT_VALUE_100,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_8),
    },
    primaryButtonStyle3: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_48),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_335),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
    },
    primaryButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
    primaryButtonLabelstyle1: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_17),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.PRIMARY_BLUE,
    },
    noButtonBackGroundColor: {
      backgroundColor: COLORS.E9EEFB,
    },
    noButtonBackGroundColor1: {
      backgroundColor: COLORS._4EAF51,
    },
    approveButton: {
      ...BASESTYLE.inRow,
      ...BASESTYLE.center,
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
  });
