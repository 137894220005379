import React from 'react';
import {
  INotificationContext,
  INotificationProvider,
  confirmationData,
} from '../types/contextTypes/notification';
import {useAppSelector} from '../hooks/reduxHooks';
import {notificationState} from '../redux/reducers/notificationSlice';
import {
  NOTIFICATION_COMPLETED_LIST,
  NOTIFICATION_LIST,
  NOTIFICATION_UNREAD_COUNT,
} from '../constants/actionTypes';
import {_ToastHandler} from '../utils/helperFunctions';
import {INotificationListItem} from '../types/reducerTypes/notificationTypes';
import {FixedValue} from '../constants';

const NotificationContext = React.createContext<INotificationContext>({
  showConfirmationModal: {isVisible: false, title: '', type: ''},
  setConfirmationModal: _val => {},
  isRefreshing: false,
  setIsRefreshing: _val => {},
  notificationListData: [],
  setNotificationListData: _val => {},
  notificationCompletedListData: [],
  setNotificationCompletedListData: _val => {},
  acceptRejectAction: _index => {},
  getUnReadCount: FixedValue.CONSTANT_VALUE_0,
  setUnReadCount: _val => {},
  showDistributionModal: false,
  setShowDistributionModal: _val => {},
  isForTradeReport: false,
  setIsForTradeReport: _val => {},
  selectedNotificationCard: null,
  setSelectedNotificationCard: _val => {},
  selectedNotificationCardIndex: FixedValue.CONSTANT_VALUE_0,
  setSelectedNotificationCardIndex: _val => {},
});

const NotificationProvider = (props: INotificationProvider): JSX.Element => {
  const {notificationData, notificationUnReadCount, notificationCompletedData} =
    useAppSelector(notificationState);
  const [isRefreshing, setIsRefreshing] = React.useState<boolean>(false);
  const [showConfirmationModal, setConfirmationModal] =
    React.useState<confirmationData>({
      isVisible: false,
      title: '',
      type: '',
    });
  const [notificationListData, setNotificationListData] = React.useState<
    INotificationListItem[]
  >([]);
  const [notificationCompletedListData, setNotificationCompletedListData] =
    React.useState<INotificationListItem[]>([]);
  const [getUnReadCount, setUnReadCount] = React.useState<number>(
    FixedValue.CONSTANT_VALUE_0
  );
  const [isForTradeReport, setIsForTradeReport] =
    React.useState<boolean>(false);
  const [showDistributionModal, setShowDistributionModal] =
    React.useState<boolean>(false);
  const [selectedNotificationCard, setSelectedNotificationCard] =
    React.useState<INotificationListItem | null>(null);
  const [selectedNotificationCardIndex, setSelectedNotificationCardIndex] =
    React.useState<number>(FixedValue.CONSTANT_VALUE_0);

  const _setIsRefreshing = React.useCallback((val: boolean): void => {
    setIsRefreshing(val);
  }, []);

  const _acceptRejectAction = React.useCallback(
    (index: number): void => {
      const getList = [...notificationListData];
      getList.splice(index, FixedValue.CONSTANT_VALUE_1);
      setNotificationListData(getList);
    },
    [notificationListData]
  );

  const _setConfirmationModal = React.useCallback(
    (value: confirmationData): void => {
      setConfirmationModal(prev => ({...prev, ...value}));
    },
    []
  );

  React.useEffect((): void => {
    switch (notificationData.status) {
      case NOTIFICATION_LIST.SUCCESS:
        setIsRefreshing(false);
        if (notificationData.success != null) {
          const listData = notificationData.success.data.results;
          if (
            notificationData.success.pageNumber === FixedValue.CONSTANT_VALUE_0
          ) {
            setNotificationListData([...listData]);
          } else {
            setNotificationListData(prev => [...prev, ...listData]);
          }
        }
        break;
      case NOTIFICATION_LIST.FAILED:
        setIsRefreshing(false);
        if (notificationData.failed)
          _ToastHandler(notificationData.failed, false);
        break;
    }
  }, [notificationData.status]);

  React.useEffect((): void => {
    if (
      notificationUnReadCount.status === NOTIFICATION_UNREAD_COUNT.SUCCESS &&
      notificationUnReadCount.success != null
    ) {
      setUnReadCount(notificationUnReadCount.success.data);
    } else if (
      notificationUnReadCount.status === NOTIFICATION_UNREAD_COUNT.FAILED ||
      notificationUnReadCount.success == null
    ) {
      setUnReadCount(FixedValue.CONSTANT_VALUE_0);
    }
  }, [notificationUnReadCount.status]);

  const _setShowDistributionModal = React.useCallback(
    (val: boolean): void => {
      setShowDistributionModal(val);
    },
    [showDistributionModal]
  );

  const _setIsForTradeReport = React.useCallback(
    (val: boolean): void => {
      setIsForTradeReport(val);
    },
    [isForTradeReport]
  );

  const _setSelectedNotificationCard = React.useCallback(
    (val: INotificationListItem | null): void => {
      setSelectedNotificationCard(val);
    },
    [selectedNotificationCard]
  );

  const _setSelectedNotificationCardIndex = React.useCallback(
    (val: number): void => {
      setSelectedNotificationCardIndex(val);
    },
    [selectedNotificationCardIndex]
  );

  React.useEffect((): void => {
    switch (notificationCompletedData.status) {
      case NOTIFICATION_COMPLETED_LIST.SUCCESS:
        setIsRefreshing(false);
        if (notificationCompletedData.success != null) {
          const listData = notificationCompletedData.success.data.results;
          if (
            notificationCompletedData.success.pageNumber ===
            FixedValue.CONSTANT_VALUE_0
          ) {
            setNotificationCompletedListData([...listData]);
          } else {
            setNotificationCompletedListData(prev => [...prev, ...listData]);
          }
        }
        break;
      case NOTIFICATION_COMPLETED_LIST.FAILED:
        setIsRefreshing(false);
        break;
    }
  }, [notificationCompletedData.status]);

  const values = React.useMemo(
    (): INotificationContext => ({
      isRefreshing,
      notificationListData,
      getUnReadCount,
      showConfirmationModal,
      setConfirmationModal: _setConfirmationModal,
      setUnReadCount,
      setNotificationListData,
      acceptRejectAction: _acceptRejectAction,
      setIsRefreshing: _setIsRefreshing,
      showDistributionModal,
      setShowDistributionModal: _setShowDistributionModal,
      isForTradeReport,
      setIsForTradeReport: _setIsForTradeReport,
      selectedNotificationCard,
      setSelectedNotificationCard: _setSelectedNotificationCard,
      selectedNotificationCardIndex,
      setSelectedNotificationCardIndex: _setSelectedNotificationCardIndex,
      notificationCompletedListData,
      setNotificationCompletedListData,
    }),
    [
      isRefreshing,
      notificationListData,
      getUnReadCount,
      showConfirmationModal,
      setConfirmationModal,
      setUnReadCount,
      setNotificationListData,
      setIsRefreshing,
      showDistributionModal,
      setShowDistributionModal,
      isForTradeReport,
      setIsForTradeReport,
      selectedNotificationCard,
      setSelectedNotificationCard,
      selectedNotificationCardIndex,
      setSelectedNotificationCardIndex,
      notificationCompletedListData,
      setNotificationCompletedListData,
    ]
  );

  return (
    <NotificationContext.Provider value={values}>
      {props.children}
    </NotificationContext.Provider>
  );
};

export {NotificationContext, NotificationProvider};
