/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {useFormik} from 'formik';
import {useAppDispatch, useAppSelector} from '../reduxHooks';

import {ERROR_MESSAGES, TEXT} from '../../constants';
import {goBack} from '../../services/navigationService';
import {ChangePwdScheme} from '../../utils/formikSchema';
import {CHANGE_PASSWORD} from '../../constants/actionTypes';
import {
  newPasswordState,
  onChangePasswordStart,
} from '../../redux/reducers/resetPasswordSlice';
import {_ToastHandler} from '../../utils/helperFunctions';
import {authState} from '../../redux/reducers/authSlice';

export type ChangePwdFields = {
  CurrentPassword: string;
  NewPassword: string;
  ConfirmPassword: string;
};
const useChangePwdHooks = () => {
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isValid,
    values,
    setFieldValue,
  } = useFormik<ChangePwdFields>({
    validationSchema: ChangePwdScheme,
    initialErrors: {
      CurrentPassword: ERROR_MESSAGES.REQUIRED_CURRENT_PASSWORD,
      NewPassword: ERROR_MESSAGES.REQUIRED_USERNAME,
      ConfirmPassword: ERROR_MESSAGES.REQUIRED_PASSWORD,
    },
    initialTouched: {
      CurrentPassword: false,
      NewPassword: false,
      ConfirmPassword: false,
    },
    initialValues: {CurrentPassword: '', NewPassword: '', ConfirmPassword: ''},
    onSubmit: _val => resetUserPassword(_val),
  });
  const dispatch = useAppDispatch();
  const {changePassword} = useAppSelector(newPasswordState);
  const {userTokens} = useAppSelector(authState);

  const resetUserPassword = React.useCallback((val: ChangePwdFields): void => {
    const _val = {
      user: userTokens.CognitoUser,
      oldPassword: val.CurrentPassword,
      newPassword: val.NewPassword,
    };
    dispatch(onChangePasswordStart(_val));
  }, []);

  React.useEffect(() => {
    switch (changePassword.status) {
      case CHANGE_PASSWORD.SUCCESS:
        _ToastHandler(TEXT.CHANGE_PASSWORD_SUCCESS, true);
        goBack();
        break;
      case CHANGE_PASSWORD.FAILED:
        _ToastHandler(
          changePassword.failed ?? TEXT.CHANGE_PASSWORD_FAILED,
          false
        );
        break;
      default:
        break;
    }
  }, [changePassword.status]);

  const CurrentPasswordError =
    touched.CurrentPassword && errors.CurrentPassword
      ? errors.CurrentPassword
      : '';
  const NewPasswordError =
    touched.NewPassword && errors.NewPassword ? errors.NewPassword : '';
  const ConfirmPasswordError =
    touched.ConfirmPassword && errors.ConfirmPassword
      ? errors.ConfirmPassword
      : '';

  return {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isValid,
    values,
    setFieldValue,
    CurrentPasswordError,
    NewPasswordError,
    ConfirmPasswordError,
  };
};

export default useChangePwdHooks;
