import {StyleSheet} from 'react-native';
import {
  COLORS,
  FixedValue,
  GlobalStyleValues,
  Percentage,
  BASESTYLE,
  FONTS,
  FONT_NAMES,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {normalizeFont, scaleHeight, scaleWidth} from '../../utils/responsive';

export const style = (_theme: IColorTheme) =>
  StyleSheet.create({
    historyListView: {
      flexDirection: GlobalStyleValues.ROW,
      marginHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_19),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_13),
    },
    textContainer: {
      ...BASESTYLE.flex1,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    horizontalTextContainer: {
      flexDirection: GlobalStyleValues.ROW,
      justifyContent: GlobalStyleValues.SPACE_BETWEEN,
    },
    myTransaction: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_18),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      color: COLORS._1E1F1F,
      fontFamily: FONTS.SF_BOLD,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    },
    dateText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      color: COLORS._1E1F1F,
      fontFamily: FONTS.SF_LIGHT,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      width: Percentage.PRECENTAGE_68,
    },
    descText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_15),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      color: COLORS.BLACK,
      fontFamily: FONTS.SF_SEMIBOLD,
      width: Percentage.PRECENTAGE_67,
    },
    amountText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      color: COLORS._4EAF51,
      fontFamily: FONTS.SF_MEDIUM,
    },
    timeText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      color: COLORS._666666,
      fontFamily: FONTS.SF_LIGHT,
    },
    amountSubtextFont: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
    },
    primaryButtonStyle: {
      ...BASESTYLE.inCenter,
      ...BASESTYLE.row,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_28),
      width: scaleHeight(FixedValue.CONSTANT_VALUE_105),
      borderRadius: FixedValue.CONSTANT_VALUE_70,
      marginVertical: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    primaryButtonLabelstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
    },
  });
