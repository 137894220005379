import StyleSheet from 'react-native-media-query';
import {
  BASESTYLE,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../../constants';
import {IColorTheme} from '../../../types/contextTypes/themeContext';
import {
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const tradeHeaderStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    container: {
      ...BASESTYLE.selfCenter,
      width: Percentage.PRECENTAGE_100,
    },
    titleText: {
      fontSize: normalizeFont(30),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: theme.color,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    headerConntainer: {
      flexDirection: GlobalStyleValues.ROW,
      justifyContent: GlobalStyleValues.SPACE_BETWEEN,
    },
    marginRight18: {
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_18),
    },
  });
