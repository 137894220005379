import React, {useEffect} from 'react';
import ScrollableContainer from '../../../../components/layout/scrollableContainer';
import SafeAreaContainer from '../../../../components/layout/safeAreaContainer';
import EditProfileContent from './editProfileContent';
import {useIsFocused} from '@react-navigation/native';
import {userProfileContext} from '../../../../contextAPI/userProfileContext';
import {userProfileStore} from '../../../../redux/reducers/userProfileSlice';
import {useAppSelector} from '../../../../hooks/reduxHooks';

const EditProfile = (): JSX.Element => {
  const isFocused = useIsFocused();
  const {selectedCountry, setSelectedCountry} =
    React.useContext(userProfileContext);
  const {userData} = useAppSelector(userProfileStore);
  const UserDetail = userData?.success;
  
  useEffect(() => {
    if (isFocused) {
      setSelectedCountry({
        ...selectedCountry,
        dial_code: UserDetail?.countryCode ?? '+1',
      });
    }
  }, [isFocused]);

  return (
    <ScrollableContainer>
      <SafeAreaContainer>
        <EditProfileContent />
      </SafeAreaContainer>
    </ScrollableContainer>
  );
};

export default React.memo(EditProfile);
