export enum FixedValue {
  CONSTANT_VALUE_MIN_1 = -1,
  CONSTANT_VALUE_0 = 0,
  CONSTANT_VALUE_001 = 0.01,
  CONSTANT_VALUE_0075 = 0.075,
  CONSTANT_VALUE_01 = 0.1,
  CONSTANT_VALUE_02 = 0.2,
  CONSTANT_VALUE_025 = 0.25,
  CONSTANT_VALUE_03 = 0.3,
  CONSTANT_VALUE_05 = 0.5,
  CONSTANT_VALUE_056 = 0.56,
  CONSTANT_VALUE_1 = 1,
  CONSTANT_VALUE_010 = 1.0,
  CONSTANT_VALUE_0117 = 1.17,
  CONSTANT_VALUE_012 = 1.2,
  CONSTANT_VALUE_01234 = 1.234,
  CONSTANT_VALUE_015 = 1.5,
  CONSTANT_VALUE_0177 = 1.77,
  CONSTANT_VALUE_2 = 2,
  CONSTANT_VALUE_025_ = 2.5,
  CONSTANT_VALUE_3 = 3,
  CONSTANT_VALUE_035 = 3.5,
  CONSTANT_VALUE_4 = 4,
  CONSTANT_VALUE_5 = 5,
  CONSTANT_VALUE_6 = 6,
  CONSTANT_VALUE_7 = 7,
  CONSTANT_VALUE_075 = 7.5,
  CONSTANT_VALUE_8 = 8,
  CONSTANT_VALUE_9 = 9,
  CONSTANT_VALUE_10 = 10,
  CONSTANT_VALUE_11 = 11,
  CONSTANT_VALUE_12 = 12,
  CONSTANT_VALUE_0125 = 12.5,
  CONSTANT_VALUE_13 = 13,
  CONSTANT_VALUE_14 = 14,
  CONSTANT_VALUE_15 = 15,
  CONSTANT_VALUE_16 = 16,
  CONSTANT_VALUE_17 = 17,
  CONSTANT_VALUE_1733 = 17.33,
  CONSTANT_VALUE_18 = 18,
  CONSTANT_VALUE_19 = 19,
  CONSTANT_VALUE_20 = 20,
  CONSTANT_VALUE_21 = 21,
  CONSTANT_VALUE_22 = 22,
  CONSTANT_VALUE_23 = 23,
  CONSTANT_VALUE_24 = 24,
  CONSTANT_VALUE_25 = 25,
  CONSTANT_VALUE_26 = 26,
  CONSTANT_VALUE_27 = 27,
  CONSTANT_VALUE_28 = 28,
  CONSTANT_VALUE_29 = 29,
  CONSTANT_VALUE_30 = 30,
  CONSTANT_VALUE_31 = 31,
  CONSTANT_VALUE_32 = 32,
  CONSTANT_VALUE_33 = 33,
  CONSTANT_VALUE_34 = 34,
  CONSTANT_VALUE_35 = 35,
  CONSTANT_VALUE_36 = 36,
  CONSTANT_VALUE_37 = 37,
  CONSTANT_VALUE_38 = 38,
  CONSTANT_VALUE_40 = 40,
  CONSTANT_VALUE_42 = 42,
  CONSTANT_VALUE_43 = 43,
  CONSTANT_VALUE_44 = 44,
  CONSTANT_VALUE_45 = 45,
  CONSTANT_VALUE_46 = 46,
  CONSTANT_VALUE_47 = 47,
  CONSTANT_VALUE_48 = 48,
  CONSTANT_VALUE_49 = 49,
  CONSTANT_VALUE_50 = 50,
  CONSTANT_VALUE_51 = 51,
  CONSTANT_VALUE_52 = 52,
  CONSTANT_VALUE_55 = 55,
  CONSTANT_VALUE_57 = 57,
  CONSTANT_VALUE_58 = 58,
  CONSTANT_VALUE_59 = 59,
  CONSTANT_VALUE_60 = 60,
  CONSTANT_VALUE_61 = 61,
  CONSTANT_VALUE_63 = 63,
  CONSTANT_VALUE_64 = 64,
  CONSTANT_VALUE_65 = 65,
  CONSTANT_VALUE_66 = 66,
  CONSTANT_VALUE_67 = 67,
  CONSTANT_VALUE_68 = 68,
  CONSTANT_VALUE_69 = 69,
  CONSTANT_VALUE_70 = 70,
  CONSTANT_VALUE_71 = 71,
  CONSTANT_VALUE_72 = 72,
  CONSTANT_VALUE_74 = 74,
  CONSTANT_VALUE_75 = 75,
  CONSTANT_VALUE_76 = 76,
  CONSTANT_VALUE_77 = 77,
  CONSTANT_VALUE_78 = 78,
  CONSTANT_VALUE_80 = 80,
  CONSTANT_VALUE_81 = 81,
  CONSTANT_VALUE_84 = 84,
  CONSTANT_VALUE_85 = 85,
  CONSTANT_VALUE_86 = 86,
  CONSTANT_VALUE_87 = 87,
  CONSTANT_VALUE_88 = 88,
  CONSTANT_VALUE_89 = 89,
  CONSTANT_VALUE_90 = 90,
  CONSTANT_VALUE_93 = 93,
  CONSTANT_VALUE_94 = 94,
  CONSTANT_VALUE_95 = 95,
  CONSTANT_VALUE_98 = 98,
  CONSTANT_VALUE_100 = 100,
  CONSTANT_VALUE_102 = 102,
  CONSTANT_VALUE_105 = 105,
  CONSTANT_VALUE_106 = 106,
  CONSTANT_VALUE_108 = 108,
  CONSTANT_VALUE_110 = 110,
  CONSTANT_VALUE_111 = 111,
  CONSTANT_VALUE_114 = 114,
  CONSTANT_VALUE_115 = 115,
  CONSTANT_VALUE_118 = 118,
  CONSTANT_VALUE_120 = 120,
  CONSTANT_VALUE_121 = 121,
  CONSTANT_VALUE_125 = 125,
  CONSTANT_VALUE_128 = 128,
  CONSTANT_VALUE_130 = 130,
  CONSTANT_VALUE_134 = 134,
  CONSTANT_VALUE_136 = 136,
  CONSTANT_VALUE_140 = 140,
  CONSTANT_VALUE_141 = 141,
  CONSTANT_VALUE_142 = 142,
  CONSTANT_VALUE_145 = 145,
  CONSTANT_VALUE_150 = 150,
  CONSTANT_VALUE_154 = 154,
  CONSTANT_VALUE_155 = 155,
  CONSTANT_VALUE_159_5 = 159.5,
  CONSTANT_VALUE_160 = 160,
  CONSTANT_VALUE_161 = 161,
  CONSTANT_VALUE_165 = 165,
  CONSTANT_VALUE_168 = 168,
  CONSTANT_VALUE_170 = 170,
  CONSTANT_VALUE_174 = 174,
  CONSTANT_VALUE_176 = 176,
  CONSTANT_VALUE_180 = 180,
  CONSTANT_VALUE_183 = 183,
  CONSTANT_VALUE_184 = 184,
  CONSTANT_VALUE_190 = 190,
  CONSTANT_VALUE_194 = 194,
  CONSTANT_VALUE_200 = 200,
  CONSTANT_VALUE_201 = 201,
  CONSTANT_VALUE_202 = 202,
  CONSTANT_VALUE_205 = 205,
  CONSTANT_VALUE_213 = 213,
  CONSTANT_VALUE_220 = 220,
  CONSTANT_VALUE_230 = 230,
  CONSTANT_VALUE_244 = 244,
  CONSTANT_VALUE_250 = 250,
  CONSTANT_VALUE_229 = 229,
  CONSTANT_VALUE_265 = 265,
  CONSTANT_VALUE_268 = 268,
  CONSTANT_VALUE_287 = 287,
  CONSTANT_VALUE_300 = 300,
  CONSTANT_VALUE_309 = 309,
  CONSTANT_VALUE_326 = 326,
  CONSTANT_VALUE_334 = 334,
  CONSTANT_VALUE_335 = 335,
  CONSTANT_VALUE_305 = 305,
  CONSTANT_VALUE_360 = 360,
  CONSTANT_VALUE_375 = 375,
  CONSTANT_VALUE_342 = 342,
  CONSTANT_VALUE_401 = 401,
  CONSTANT_VALUE_409 = 409,
  CONSTANT_VALUE_420 = 420,
  CONSTANT_VALUE_450 = 450,
  CONSTANT_VALUE_500 = 500,
  CONSTANT_VALUE_502 = 502,
  CONSTANT_VALUE_600 = 600,
  CONSTANT_VALUE_1024 = 1024,
  CONSTANT_VALUE_812 = 812,
  CONSTANT_VALUE_5000 = 5000,
  CONSTANT_VALUE_1970 = 1970,
  CONSTANT_VALUE_1940 = 1940,
  CONSTANT_VALUE_179 = 179,
  CONSTANT_VALUE_104 = 104,
  CONSTANT_VALUE_103 = 103,
  CONSTANT_VALUE_112 = 112,
  CONSTANT_VALUE_07 = 0.7,
  CONSTANT_VALUE_208 = 208,
  CONSTANT_VALUE_79 = 79,
  CONSTANT_VALUE_006 = 0.06,
  CONSTANT_VALUE_011 = 0.11,
  CONSTANT_VALUE_085 = 0.85,
  CONSTANT_VALUE_MIN_2 = -2,
  CONSTANT_VALUE_MIN_5 = -5,
  CONSTANT_VALUE_MIN_10 = -10,
  CONSTANT_VALUE_1048576 = 1048576,
  CONSTANT_VALUE_099 = 0.99,
}

export enum GlobalStyleValues {
  CENTER = 'center',
  ROW = 'row',
  SPACE_BETWEEN = 'space-between',
  SPACE_AROUND = 'space-around',
  SPACE_EVENLY = 'space-evenly',
  ABSOLUTE = 'absolute',
  FLEX_START = 'flex-start',
  FLEX_END = 'flex-end',
  TOP = 'top',
  CONTAIN = 'contain',
  UNDERLINE = 'underline',
  HIDDEN = 'hidden',
  DASHED = 'dashed',
  COVER = 'cover',
  HANDLED = 'handled',
  LARGE = 'large',
  SMALL = 'small',
  NONE = 'none',
  COLUMN = 'column',
  DOTTED = 'dotted',
  UPPERCASE = 'uppercase',
  CAPITALIZE = 'capitalize',
  TRANSPARENT = 'transparent',
  ITALIC = 'italic',
  AUTO = 'auto',
}

export enum Percentage {
  PRECENTAGE_10 = '10%',
  PRECENTAGE_11_5 = '11.5%',
  PRECENTAGE_15 = '15%',
  PRECENTAGE_20 = '20%',
  PRECENTAGE_25 = '25%',
  PRECENTAGE_30 = '30%',
  PRECENTAGE_33 = '33%',
  PRECENTAGE_40 = '40%',
  PRECENTAGE_42 = '42%',
  PRECENTAGE_45 = '45%',
  PRECENTAGE_48 = '48%',
  PRECENTAGE_50 = '50%',
  PRECENTAGE_55 = '55%',
  PRECENTAGE_60 = '60%',
  PRECENTAGE_64 = '64%',
  PRECENTAGE_65 = '65%',
  PRECENTAGE_66 = '66%',
  PRECENTAGE_67 = '67%',
  PRECENTAGE_68 = '68%',
  PRECENTAGE_69 = '69%',
  PRECENTAGE_80 = '80%',
  PRECENTAGE_82 = '82%',
  PRECENTAGE_70 = '70%',
  PRECENTAGE_74 = '74%',
  PRECENTAGE_75 = '75%',
  PRECENTAGE_76 = '76%',
  PRECENTAGE_77 = '77%',
  PRECENTAGE_100 = '100%',
  PRECENTAGE_99 = '99%',
  PRECENTAGE_90 = '90%',
  PRECENTAGE_35 = '35%',
  PRECENTAGE_94 = '94%',
  PRECENTAGE_95 = '95%',
  PRECENTAGE_96 = '96%',
  PRECENTAGE_85 = '85%',
}

export enum themeTypes {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum statusBarContentType {
  DARK = 'dark-content',
  LIGHT = 'light-content',
}

export enum TabBarName {
  MENTAL = 'Mantel',
  TRADE = 'Trade',
  SEARCH = 'Search',
  TRACKING = 'Tracking',
  NOTIFICATION = 'Notifications',
  PROFILE = 'My Account',
}

export enum ResetPasswordSettings {
  NEW_PASSWORD = 'NewPassword',
  COMFIRM_PASSWORD = 'ConfirmPassword',
}
export enum ChangePasswordSettings {
  CURRENT_PASSWORD = 'CurrentPassword',
  NEW_PASSWORD = 'NewPassword',
  COMFIRM_PASSWORD = 'ConfirmPassword',
}

export enum navigationEmitType {
  TAB_LONG_PRESS = 'tabLongPress',
  TAB_PRESS = 'tabPress',
}

export enum IconType {
  SUCCESS = 'success',
  FAILED = 'danger',
}

export enum nativeBaseEnums {
  FULL = 'full',
}

export enum testId {
  te_main_svg = 'TE-main-svg',
}

export enum edges {
  TOP = 'top',
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom',
}

export enum editorType {
  CIRCLE = 'Circle',
  TEXT = 'Text',
  IMAGE = 'Image',
  SHAPE = 'Shape',
}

export enum controllerName {
  ROTATION = 'rotation',
  SCALE = 'scale',
  COLOR_PELETTE = 'color pelette',
  STROKE_WIDTH = 'Stroke width',
  EDIT_FONT = 'Edit font',
}

export enum timeformat {
  MMMDDYY = 'MMMM DD, YYYY',
  YYYY_MM_DD = 'YYYY-MM-DD',
  YYYY_MM_DD_ = 'YYYY/MM/DD',
  DD_MMM_YYYY = 'DD MMM, YYYY',
  MMM_DD_YYYY = 'MMM DD, YYYY',
  YYYY_MM_DD_HH_MM_SS = 'YYYY-MM-DD HH:mm:ss',
  HH_MM_A = 'hh:mm A',
  MMDDYY = 'MMM DD, YYYY',
  MMM_DD_YYYY_H_MM_A = 'MMM DD, YYYY | h:mm A',
}

export enum webFonts {
  MONTSERRAT_REGULAR = 'Montserrat_400Regular',
  JOSEFINESANS_REGULAR = 'JosefinSans_400Regular',
  ROBOTO_REGULAR = 'Roboto_400Regular',
  PACIFICO_REGULAR = 'Pacifico_400Regular',
  BEBASNEUE_REGULAR = 'BebasNeue_400Regular',
  ITIM_REGULAR = 'Itim_400Regular',
}

export enum METHODS {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum appStateName {
  INITIAL = 'initial',
  AUTHENTICATION = 'authentication',
  INSIDE_APP = 'insideApp',
}

export enum TopType {
  collections = 1,
  inProgress,
  purchased,
  uncategorised,
  otherUserTop,
  tradeOffer,
  viewOffer,
  seeOnlyDetails,
}

export enum MantelTopType {
  COLLECTION = FixedValue.CONSTANT_VALUE_0,
  IN_PROGRESS = FixedValue.CONSTANT_VALUE_1,
  PURCHASED = FixedValue.CONSTANT_VALUE_2,
}

export enum SeeAll_ScreenMode {
  VIEW = FixedValue.CONSTANT_VALUE_0,
  MOVE_TOPS_TO_THIS_COLLECTION = FixedValue.CONSTANT_VALUE_1,
  MOVE_TOPS_TO_OTHER_COLLECTION = FixedValue.CONSTANT_VALUE_2,
}

//child permission for Trade, Group and Purchase
export enum CHILD_PERMISSION {
  ALLOWED = 'ALLOWED',
  NOT_ALLOWED = 'NOT_ALLOWED',
  SPECIFIC_USERS = 'SPECIFIC_USERS',
  HAVE_LIMIT = 'HAVE_LIMIT',
}

//user list type for define type
export enum USER_LIST_TYPE {
  OFFER = 'OFFER',
  DISTRIBUTE = 'DISTRIBUTE',
}

//user list type for define type
export enum SENT_OR_RECEIVED {
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
}

export enum APPROVED_OR_REJECT {
  APPROVED = 'APPROVED',
  REJECT = 'REJECT',
}

export enum ACCEPTED_OR_REJECTED {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  REPORTED = 'REPORTED',
  REPORT = 'REPORT',
}

export enum NOTIFICATION_TYPE {
  CHILD_REGISTRATION_PUSH = 'CHILD_REGISTRATION_PUSH',
  GROUP_PUSH = 'GROUP_PUSH',
  DISTRIBUTION_PUSH = 'DISTRIBUTION_PUSH',
  PURCHASE_PUSH = 'PURCHASE_PUSH',
  TRADE_OFFER_PUSH = 'TRADE_OFFER_PUSH',
  TRADABLE_PUSH = 'TRADABLE_PUSH',
  PURCHASE_PLAN_PUSH = 'PURCHASE_PLAN_PUSH',
  DISTRIBUTION_PUSH_EMAIL = 'DISTRIBUTION_PUSH_EMAIL',
}

export enum NOTIFICATION_SUB_TYPE {
  NOTIFICATION_INVITATION = 'NOTIFICATION_INVITATION',
  NOTIFICATION_ACCEPTED = 'NOTIFICATION_ACCEPTED',
  NOTIFICATION_REJECTED = 'NOTIFICATION_REJECTED',
  NOTIFICATION_RESPONSE = 'NOTIFICATION_RESPONSE',
}

//for notification list type
export enum NOTIFICATION_LIST_TYPE {
  ACTION_NEEDED = 'ActionNeeded',
  COMPLETED = 'completed',
}
