import React from 'react';
import {Pressable, Text, View} from 'react-native';
import TitleWithButton from '../../components/header/titleWithButton';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';
import {TEXT} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {style} from './style';
import CustomButton from '../../components/button/customButton';
import {ChildProfileContext} from '../../contextAPI/childProfileContext';
import {CHILD_PERMISSION, FixedValue} from '../../constants/enum';
import useTradingApprovalHooks from '../../hooks/componentHooks/useTradingApprovalHooks';
import SelectEllipse from '../../components/renderSvgs/selectEllipse';
import Ellipse from '../../components/renderSvgs/ellipse';
import {CHILD_GROUP_APPROVAL_SETTING} from '../../constants/actionTypes';
import {goBack} from '../../services/navigationService';
import {useAppSelector} from '../../hooks/reduxHooks';
import {childProfileStore} from '../../redux/reducers/childProfileSlice';
import useChildListHooks from '../../hooks/componentHooks/useChildListHooks';
import {_ToastHandler} from '../../utils/helperFunctions';
import ConfirmationModal from '../../modals/confirmationModal/confirmationModal';
import {TOAST_TEXT} from '../../constants/textMessage';
import {useIsFocused} from '@react-navigation/native';

const GroupInvitationSettings = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles, ids} = style(ColorTheme);
  const isFocused = useIsFocused();
  const {
    childUserData,
    modalVisibleGroupInvitation,
    setModalVisibleGroupInvitation,
  } = React.useContext(ChildProfileContext);
  const groupInvitationPermission: string =
    childUserData?.permissionsStatus?.groupPermissionStatus ?? '';
  const [groupSettingApproval, setGroupSettingApproval] =
    React.useState<boolean>(
      groupInvitationPermission === CHILD_PERMISSION.ALLOWED
    );
  const {callChildGroupApproveSettingAPI} = useTradingApprovalHooks();
  const {childGroupSettingApproval} = useAppSelector(childProfileStore);
  const {callChildProfileAPI} = useChildListHooks();
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const onPress = React.useCallback(
    (item: string): void => {
      setGroupSettingApproval(CHILD_PERMISSION.ALLOWED === item);
    },
    [groupSettingApproval]
  );

  React.useEffect(() => {
    if (isFocused) {
      switch (childGroupSettingApproval.status) {
        case CHILD_GROUP_APPROVAL_SETTING.SUCCESS:
          setModalVisibleGroupInvitation(false);
          _ToastHandler(TOAST_TEXT.GROUP_INVITATION_SETTING, true);
          callChildProfileAPI(childUserData?.id ?? FixedValue.CONSTANT_VALUE_0);
          goBack();
          break;
        case CHILD_GROUP_APPROVAL_SETTING.FAILED:
          setModalVisibleGroupInvitation(false);
          break;
        default:
          break;
      }
    }
  }, [childGroupSettingApproval.status]);

  React.useEffect(() => {
    setLoading(childGroupSettingApproval.isLoading);
  }, [childGroupSettingApproval.isLoading]);

  return (
    <SafeAreaContainer>
      <View style={styles.mainContainer} dataSet={{media: ids.mainContainer}}>
        <View style={styles.headerView}>
          <TitleWithButton title={TEXT.SETTING} />
        </View>
        <View style={styles.optionView}>
          <Text style={styles.selectTextStyle}>{TEXT.YOU_CAN_SELECT_ONE}</Text>
        </View>
        <View style={styles.listView}>
          <Pressable onPress={() => onPress(CHILD_PERMISSION.NOT_ALLOWED)}>
            {!groupSettingApproval ? (
              <SelectEllipse
                width={FixedValue.CONSTANT_VALUE_20}
                height={FixedValue.CONSTANT_VALUE_20}
              />
            ) : (
              <Ellipse
                width={FixedValue.CONSTANT_VALUE_20}
                height={FixedValue.CONSTANT_VALUE_20}
              />
            )}
          </Pressable>
          <Text
            style={styles.textStyle}
            onPress={() => onPress(CHILD_PERMISSION.NOT_ALLOWED)}
            suppressHighlighting
          >
            {TEXT.PARENT_GUARDIAN_MUST}
          </Text>
        </View>
        <View style={styles.listView1}>
          <Pressable onPress={() => onPress(CHILD_PERMISSION.ALLOWED)}>
            {groupSettingApproval ? (
              <SelectEllipse
                width={FixedValue.CONSTANT_VALUE_20}
                height={FixedValue.CONSTANT_VALUE_20}
              />
            ) : (
              <Ellipse
                width={FixedValue.CONSTANT_VALUE_20}
                height={FixedValue.CONSTANT_VALUE_20}
              />
            )}
          </Pressable>
          <Text
            style={styles.guardianAllow}
            onPress={() => onPress(CHILD_PERMISSION.ALLOWED)}
            suppressHighlighting
          >
            {TEXT.PARENT_GUARDIAN_ALLOW}
          </Text>
        </View>
        <View style={styles.buttonView}>
          <CustomButton
            buttonLabel={TEXT.SAVE}
            buttonwidth={styles.buttonStyle}
            labelStyle={styles.buttonLabelstyle}
            onPress={() => setModalVisibleGroupInvitation(true)}
          />
        </View>
        <ConfirmationModal
          title={TEXT.CHANGE_GROUP_INVITATION_SETTING}
          modalVisible={modalVisibleGroupInvitation}
          onPressYes={() => {
            callChildGroupApproveSettingAPI(
              groupSettingApproval
                ? CHILD_PERMISSION.ALLOWED
                : CHILD_PERMISSION.NOT_ALLOWED
            );
          }}
          onShowHide={() => setModalVisibleGroupInvitation(false)}
          isLoading={isLoading}
        />
      </View>
    </SafeAreaContainer>
  );
};

export default React.memo(GroupInvitationSettings);
