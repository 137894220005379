import {StyleSheet} from 'react-native';
import {
  BASESTYLE,
  COLORS,
  FixedValue,
  FONT_NAMES,
  GlobalStyleValues,
  Percentage,
} from '../../constants';
import {IColorTheme} from '../../types/contextTypes/themeContext';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';

export const styles = (_theme?: IColorTheme) =>
  StyleSheet.create({
    container: {
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_8),
      paddingVertical: scaleHeight(FixedValue.CONSTANT_VALUE_4),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_12),
      ...BASESTYLE.inRow,
    },
    rowGap: {
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_6),
    },
    textStyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_11),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    profilePicView: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_100),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_100),
      backgroundColor: _theme?.color,
      ...BASESTYLE.inCenter,
      borderRadius: FixedValue.CONSTANT_VALUE_16,
      overflow: GlobalStyleValues.HIDDEN,
    },
    nameText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_48),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: _theme?.backgroundColor,
    },
    usesDetailView: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_5),
      ...BASESTYLE.inRow,
    },
    userDetailText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: _theme?.color,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      maxWidth: FixedValue.CONSTANT_VALUE_213,
    },
    badgeText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: _theme?.backgroundColor,
    },
    termView: {
      ...BASESTYLE.inRow,
    },
    termText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: _theme?.color,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_9),
    },
    termCondText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: COLORS._7A7F85,
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_9),
    },
    decorateTerm: {
      color: COLORS.PRIMARY_BLUE,
      textDecorationLine: GlobalStyleValues.UNDERLINE,
    },
    coutnryCode: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_REGULAR,
      color: _theme?.color,
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_2),
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_10),
    },
    crossView: {
      marginLeft: scaleWidth(FixedValue.CONSTANT_VALUE_24),
    },
    recentSearchView: {
      width: Percentage.PRECENTAGE_100,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_20),
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_5),
      borderBottomColor: COLORS.B6BFC9,
      borderBottomWidth: FixedValue.CONSTANT_VALUE_1,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_25),
    },
    recentSearchText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      fontFamily: FONT_NAMES.SF_BOLD,
      color: _theme?.color,
    },
    tradableView: {
      ...BASESTYLE.inCenter,
      zIndex: FixedValue.CONSTANT_VALUE_1,
      backgroundColor: COLORS._00B25B,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_93),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_38),
      borderRadius: FixedValue.CONSTANT_VALUE_38,
    },
    tradableText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: COLORS.WHITE,
    },
    loaderView: {
      ...BASESTYLE.absolutePosition,
      ...BASESTYLE.inCenter,
      zIndex: FixedValue.CONSTANT_VALUE_1,
      width: Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
    },
    datePickerStyle: {
      backgroundColor: _theme?.backgroundColor,
      textHeaderColor: _theme?.color,
      textDefaultColor: _theme?.color,
      selectedTextColor: _theme?.backgroundColor,
      mainColor: COLORS.PRIMARY_BLUE,
      textSecondaryColor: _theme?.color,
      borderColor: COLORS.LGHT_BLCK,
    },
    datePickerContainer: {
      height: scaleHeight(FixedValue.CONSTANT_VALUE_300),
      width: Percentage.PRECENTAGE_100,
      bottom: scaleHeight(FixedValue.CONSTANT_VALUE_4),
    },
    peletteView: {
      paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_4),
    },
    peletteText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      color: COLORS.WHITE,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_17),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_8),
    },
    colorButton: {
      ...BASESTYLE.inRow,
      ...BASESTYLE.spaceBetween,
    },
    colorView: {
      width: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_20),
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      borderColor: COLORS.WHITE,
      borderWidth: FixedValue.CONSTANT_VALUE_1,
    },
    distributeTopDetailContainer: {
      ...BASESTYLE.inRow,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_20),
    },
    topImageView: {
      ...BASESTYLE.alignCenter,
      ...BASESTYLE.center,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_70),
      height: scaleWidth(FixedValue.CONSTANT_VALUE_70),
      marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_9),
      backgroundColor: COLORS.E9EEFB,
      borderRadius: scaleWidth(FixedValue.CONSTANT_VALUE_10),
      overflow: GlobalStyleValues.HIDDEN,
    },
    topTitle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      color: _theme?.color,
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_4),
    },
    topDescription: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_3),
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      color: _theme?.color,
      fontFamily: FONT_NAMES.SF_SEMIBOLD,
    },
    addButtonView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_28),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_60),
      borderRadius: FixedValue.CONSTANT_VALUE_100,
      backgroundColor: COLORS.E9EEFB,
    },
    addedButtonView: {
      ...BASESTYLE.inCenter,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_28),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_60),
      borderRadius: FixedValue.CONSTANT_VALUE_100,
      backgroundColor: COLORS._E6F3E5,
    },
    addTextstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.PRIMARY_BLUE,
    },
    addedTextstyle: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS._4EAF51,
    },
    quantitySelectorView: {
      ...BASESTYLE.row,
      ...BASESTYLE.spaceBetween,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_28),
      width: scaleWidth(FixedValue.CONSTANT_VALUE_90),
      borderWidth: FixedValue.CONSTANT_VALUE_1,
      borderRadius: FixedValue.CONSTANT_VALUE_100,
      margin: FixedValue.CONSTANT_VALUE_10,
      borderColor: COLORS._B3B3B3,
      backgroundColor: _theme?.backgroundColor,
      overflow: GlobalStyleValues.HIDDEN,
    },
    plusButton: {
      ...BASESTYLE.inCenter,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_24),
      right: scaleWidth(FixedValue.CONSTANT_VALUE_5),
      backgroundColor: _theme?.backgroundColor,
    },
    minuButton: {
      ...BASESTYLE.inCenter,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_24),
      backgroundColor: _theme?.backgroundColor,
      left: scaleWidth(FixedValue.CONSTANT_VALUE_5),
    },
    zeroQuantityText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.LGHT_BLCK,
    },
    quantityText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: _theme?.color,
    },
    plusText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: _theme?.color,
    },
    profilePicEditView: {
      ...BASESTYLE.absolutePosition,
      bottom: FixedValue.CONSTANT_VALUE_8,
      right: FixedValue.CONSTANT_VALUE_8,
    },
    profileImage: {
      height: Percentage.PRECENTAGE_100,
      width: Percentage.PRECENTAGE_100,
    },
    profileAbsolutePosition: {
      ...BASESTYLE.absolutePosition,
      zIndex: FixedValue.CONSTANT_VALUE_1,
    },
    absoluteTextView: {
      height: Percentage.PRECENTAGE_100,
      width: Percentage.PRECENTAGE_100,
      ...BASESTYLE.absolutePosition,
      backgroundColor: _theme?.color,
      ...BASESTYLE.inCenter,
    },
    modalStyle: {
      ...BASESTYLE.flex1,
      ...BASESTYLE.selfCenter,
      justifyContent: GlobalStyleValues.FLEX_END,
      width: isWebsite()
        ? FixedValue.CONSTANT_VALUE_500
        : Percentage.PRECENTAGE_100,
      alignItems: GlobalStyleValues.CENTER,
      '@media (max-width: 500px)': {
        width: Percentage.PRECENTAGE_100,
      },
    },
    innerView: {
      backgroundColor: _theme?.backgroundColor,
      width: Percentage.PRECENTAGE_100,
      paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_0),
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_16,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_16,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_300),
      shadowColor: _theme?.color,
      shadowOpacity: FixedValue.CONSTANT_VALUE_03,
      shadowRadius: FixedValue.CONSTANT_VALUE_5,
      shadowOffset: {
        width: FixedValue.CONSTANT_VALUE_2,
        height: FixedValue.CONSTANT_VALUE_2,
      },
      elevation: FixedValue.CONSTANT_VALUE_2,
      paddingBottom: scaleHeight(FixedValue.CONSTANT_VALUE_10),
    },
    outterPart: {
      width: Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      ...BASESTYLE.absolutePosition,
      zIndex: FixedValue.CONSTANT_VALUE_MIN_1,
      backgroundColor: COLORS.LIGHT_BLACK,
    },
    cancelView: {
      borderTopLeftRadius: FixedValue.CONSTANT_VALUE_16,
      borderTopRightRadius: FixedValue.CONSTANT_VALUE_16,
      height: scaleHeight(FixedValue.CONSTANT_VALUE_50),
      backgroundColor: _theme?.backgroundColor,
      width: Percentage.PRECENTAGE_100,
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderColor: COLORS.LGHT_BLCK,
      justifyContent: 'space-between',
    },
    cancelButton: {
      ...BASESTYLE.inCenter,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_100),
      height: Percentage.PRECENTAGE_100,
      backgroundColor: 'transparent',
      alignSelf: GlobalStyleValues.FLEX_END,
    },
    cancelText: {
      color: _theme?.color,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_REGULAR,
    },
    doneText: {
      color: COLORS.PRIMARY_BLUE,
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_19),
      fontFamily: FONT_NAMES.SF_REGULAR,
    },
    marginTopFive: {
      marginTop: FixedValue.CONSTANT_VALUE_5,
    },
    quantityInput: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_12),
      lineHeight: normalizeFont(FixedValue.CONSTANT_VALUE_14),
      fontFamily: FONT_NAMES.SF_MEDIUM,
      color: COLORS.WHITE,
      width: scaleWidth(FixedValue.CONSTANT_VALUE_30),
      height: scaleHeight(FixedValue.CONSTANT_VALUE_30),
      textAlign: GlobalStyleValues.CENTER,
    },
  });
