/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import SafeAreaContainer from '../../components/layout/safeAreaContainer';

import ScrollableContainer from '../../components/layout/scrollableContainer';
import SignUpContent from './signUpContent';

const LoginScreen: React.FC = () => {
  return (
      <ScrollableContainer>
        <SafeAreaContainer>
          <SignUpContent />
        </SafeAreaContainer>
      </ScrollableContainer>
  );
};

export default React.memo(LoginScreen);
