import React from 'react';
import { StyleSheet } from 'react-native';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import { BASESTYLE, COLORS } from '../../constants';
import { ThemeContext } from '../../contextAPI/themeContext';
import { ILayoutProps } from '../../types/componentTypes/layoutType';
import { IColorTheme } from '../../types/contextTypes/themeContext';

const styles = (theme: IColorTheme) =>
  StyleSheet.create({
    safeAreaView: {
      ...BASESTYLE.flex1,
      backgroundColor: theme.backgroundColor,
    },
  });

const LayoutContainer = (props: ILayoutProps) => {
  const { children } = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const style = styles(ColorTheme);

  return (
    <SafeAreaProvider>
      <SafeAreaView
        style={style.safeAreaView}
        edges={['top', 'left', 'right']}
      >
        {children}
      </SafeAreaView>
    </SafeAreaProvider>
  );
};

export default LayoutContainer;
