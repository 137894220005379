import React from 'react';
import {View, Text, Pressable} from 'react-native';
import moment from 'moment-timezone';
import CalendarBlack from '../../../components/renderSvgs/calendar';
import EditPencil from '../../../components/renderSvgs/editPencil';
import MailBlack from '../../../components/renderSvgs/mail';
import SmartPhone from '../../../components/renderSvgs/smartPhone';
import IconsWithText from '../../../components/smallDesigns/iconsWithText';
import ProfilePics from '../../../components/smallDesigns/profilePics';
import {ROUTE_NAME, TEXT, TabBarName} from '../../../constants';
import {FixedValue, timeformat} from '../../../constants/enum';
import {ThemeContext} from '../../../contextAPI/themeContext';
import {useAppDispatch, useAppSelector} from '../../../hooks/reduxHooks';
import {userProfileStore} from '../../../redux/reducers/userProfileSlice';
import {profileStyle} from './profile.style';
import ProfileIcon from '../../../components/renderSvgs/profileIcon';
import {navigate} from '../../../services/navigationService';
import {getInitials} from '../../../utils/helperFunctions';
import {onResendCodeStart} from '../../../redux/reducers/verifyOtp';
import WalletImage from '../../../components/renderSvgs/walletImage';
import RightArrow from '../../../components/renderSvgs/rightArrow';

interface IProfileUpperContent {
  emailVerified: boolean;
  mobileVerified: boolean;
}

const ProfileUpperContent = (props: IProfileUpperContent): JSX.Element => {
  const {emailVerified, mobileVerified} = props;
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = profileStyle(ColorTheme);
  const {userData} = useAppSelector(userProfileStore);
  const UserDetail = userData?.success;
  const dateOfBirth = moment(UserDetail?.dob ?? undefined).format(
    timeformat.MMMDDYY
  );
  const dispatch = useAppDispatch();

  const onPress = React.useCallback((): void => {
    navigate(ROUTE_NAME.EDIT_PROFILE);
  }, []);

  const onPresVerifyEmail = () => {
    dispatch(
      onResendCodeStart({
        Username: UserDetail?.username ?? '',
        isVerifyEmail: true,
      })
    );
    navigate(ROUTE_NAME.VERIFY_EMAIL, {
      emailId: UserDetail?.email,
      mobile: UserDetail?.mobile,
      navigateFrom: ROUTE_NAME.EDIT_PROFILE,
    });
  };

  const onPresVerifyMobile = () => {
    dispatch(
      onResendCodeStart({
        Username: UserDetail?.username ?? '',
        isVerifyMobile: true,
      })
    );
    navigate(ROUTE_NAME.VERIFY_EMAIL, {
      emailId: UserDetail?.email,
      mobileNo: UserDetail?.mobile,
      navigateFrom: ROUTE_NAME.EDIT_PROFILE_MOBILE,
    });
  };

  return (
    <>
      <View style={styles.headingView}>
        <Text style={styles.titleText}>{TabBarName.PROFILE}</Text>
        <Pressable onPress={onPress}>
          <EditPencil stroke={ColorTheme.color} />
        </Pressable>
      </View>
      <View style={styles.profilePicView}>
        <ProfilePics
          image={UserDetail?.profileImage ?? ''}
          text={getInitials(UserDetail?.firstName + ' ' + UserDetail?.lastName)}
          tempImage={UserDetail?.tempProfilePicture}
          mainViewStyle={styles.mainViewStyle}
          initialsTextStyle={styles.initialsTextStyle}
        />
        <View style={styles.userDetailView}>
          <Text
            numberOfLines={FixedValue.CONSTANT_VALUE_1}
            style={styles.userNameText}
          >
            {UserDetail?.firstName} {UserDetail?.lastName}
          </Text>
          <IconsWithText
            text={`${UserDetail?.username}` ?? ''}
            Icon={
              <ProfileIcon
                width={FixedValue.CONSTANT_VALUE_12}
                height={FixedValue.CONSTANT_VALUE_12}
                stroke={ColorTheme.color}
              />
            }
          />
          {UserDetail?.email && (
            <IconsWithText
              text={UserDetail?.email ?? ''}
              Icon={
                <MailBlack
                  width={FixedValue.CONSTANT_VALUE_12}
                  height={FixedValue.CONSTANT_VALUE_12}
                  stroke={ColorTheme.color}
                />
              }
            />
          )}
          {UserDetail?.email && !emailVerified && (
            <Text style={styles.verifyEmailText} onPress={onPresVerifyEmail}>
              Verify email
            </Text>
          )}
          <IconsWithText
            text={UserDetail?.countryCode + '-' + UserDetail?.mobile ?? ''}
            Icon={
              <SmartPhone
                width={FixedValue.CONSTANT_VALUE_12}
                height={FixedValue.CONSTANT_VALUE_12}
                stroke={ColorTheme.color}
              />
            }
          />
          {UserDetail?.mobile && !mobileVerified && (
            <Text style={styles.verifyEmailText} onPress={onPresVerifyMobile}>
              Verify mobile
            </Text>
          )}
          <IconsWithText
            text={dateOfBirth}
            Icon={
              <CalendarBlack
                width={FixedValue.CONSTANT_VALUE_12}
                height={FixedValue.CONSTANT_VALUE_12}
                stroke={ColorTheme.color}
              />
            }
          />
        </View>
      </View>
      <Pressable
        onPress={() => navigate(ROUTE_NAME.MY_WALLET_BALANCE)}
        style={styles.container}
      >
        <WalletImage
          height={FixedValue.CONSTANT_VALUE_48}
          width={FixedValue.CONSTANT_VALUE_48}
        />
        <View style={styles.content}>
          <Text style={styles.myWalletText}>{TEXT.MY_WALLET_BALANCE}</Text>
          <Text style={styles.topCreditText}>
            {userData?.success?.walletAmount}
            <Text style={styles.topcreditsText}>
              {(userData?.success?.walletAmount ??
                FixedValue.CONSTANT_VALUE_0) > FixedValue.CONSTANT_VALUE_1
                ? TEXT.TOPCREDITS
                : TEXT.TOPCREDIT}
            </Text>
          </Text>
        </View>
        <RightArrow />
      </Pressable>
    </>
  );
};

export default React.memo(ProfileUpperContent);
