import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import NetInfo from '@react-native-community/netinfo';
import {Auth} from 'aws-amplify';

import {IApiInfo, IErrorResponse} from '../types/constantFilesTypes';
import {ERROR_MESSAGES, getBaseUrl, TEXT} from '../constants';
import {IUserToken} from '../types/reducerTypes/authStoreTypes';
import {METHODS} from '../constants/enum';
import {
  getDataLocally,
  removeAllData,
  storeDataLocally,
  _ToastHandler,
} from './helperFunctions';
import {
  HttpStatusCode,
  StorageConstants,
  TIMEOUT_MS,
} from '../constants/appConst';

const refreshAccessToken = async (): Promise<any> => {
  return new Promise(async resolve => {
    const result = await getDataLocally(StorageConstants.USER_TOKENS);
    if (result != null && typeof result === 'object') {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const {exp: expirationTime} =
          result.CognitoUser.signInUserSession.accessToken.payload;
        const currentTime = new Date().getTime() / 1000;
        if (expirationTime <= currentTime) {
          const currentSession = await Auth.currentSession();
          cognitoUser.refreshSession(
            currentSession.getRefreshToken(),
            async (err: any, session: any) => {
              const {idToken, refreshToken, accessToken} = session;
              const newData = {
                CognitoUser: {
                  ...result.CognitoUser,
                  signInUserSession: {
                    ...result.CognitoUser.signInUserSession,
                    ...session,
                  },
                },
                accessToken: accessToken?.jwtToken,
                idToken: idToken?.jwtToken,
                refreshToken: refreshToken?.token,
              };
              await storeDataLocally(StorageConstants.USER_TOKENS, newData);
              resolve(newData);
            }
          );
        } else {
          resolve(result);
        }
      } catch (e) {
        resolve({});
      }
    } else {
      resolve({});
    }
  });
};

const createAxiosConfig = async (apiInfo: IApiInfo, data: object | null) => {
  const {idToken} = await refreshAccessToken();
  let contType = 'application/json;charset=utf-8';
  let obj: any = {};
  if (apiInfo.contType) {
    contType = apiInfo.contType;
  }
  const configObj = {
    method: apiInfo.type,
    baseURL: getBaseUrl(),
    url: apiInfo.name,
    timeout: TIMEOUT_MS,
    headers: {
      'Content-Type': contType,
    },
  };
  if (apiInfo.type !== METHODS.GET) {
    obj = {...configObj, data};
  } else {
    obj = {...configObj};
  }
  if (idToken) {
    obj.headers.Authorization = 'Bearer ' + idToken;
  }
  return obj;
};

export const apiCall = async (
  apiInfo: IApiInfo,
  data: object | null,
  params: any | null,
  userInfo?: IUserToken | null
): Promise<unknown> => {
  return new Promise(async (resolve, reject) => {
    const axiosObj: AxiosRequestConfig = await createAxiosConfig(apiInfo, data);
    if (params) {
      const queryStringArr = [];
      for (const key of Object.keys(params)) {
        queryStringArr.push(`${key}=${params[key]}`);
      }
      axiosObj.url = `${axiosObj.url}?${queryStringArr.join('&')}`;
    }
    const {isConnected} = await NetInfo.fetch();
    if (isConnected) {
      axios(axiosObj)
        .then((res: AxiosResponse) => {
          if (res.status === HttpStatusCode.SUCCESS_CODE_202) {
            if (res.config.url?.includes(TEXT.MARK_TRADABLE)) {
              _ToastHandler(TEXT.SHIFTING_REQUEST_SENT, true);
              reject();
            } else if (res.config.url?.includes(TEXT.TOP_DISTRIBUTE)) {
              _ToastHandler(TEXT.DISTRIBUTE_REQUEST_SENT, true);
              reject();
            } else {
              resolve(res.data);
            }
          } else {
            resolve(res.data);
          }
        })
        .catch(async (error: AxiosError<IErrorResponse>) => {
          if (error.response?.status === HttpStatusCode.DELETE_ACCOUNT) {
            _ToastHandler(ERROR_MESSAGES.ACCOUNT_DELETED, false);
            await Auth.signOut();
            await removeAllData();
          } else if (error.response?.status === HttpStatusCode.UNAUTHORISED) {
            _ToastHandler(ERROR_MESSAGES.TOKEN_EXPIRE, false);
            await Auth.signOut();
            await removeAllData();
          } else {
            if (
              error.response?.status === HttpStatusCode.UNAUTHORISED_409 &&
              error?.config?.url?.includes(TEXT.MARK_TRADABLE)
            ) {
              _ToastHandler(TEXT.SHIFTING_REQUEST_SENT_ALREADY, false);
            }
            reject(error.response?.data ?? error.response);
          }
        });
    } else {
      const errorResponse: IErrorResponse = {
        data: ERROR_MESSAGES.NO_INTERNET,
        errors: [
          {
            msg: ERROR_MESSAGES.NO_INTERNET,
            code: `${HttpStatusCode.SERVER_CODE_502}`,
            rejectedValue: {},
            stackTrace: '',
          },
        ],
        error: true,
      };
      reject(errorResponse);
    }
  });
};
