import {takeLatest, put, call, delay, select} from 'redux-saga/effects';
import {AppConst, TEXT} from '../../constants';
import {trackingCancel} from '../../utils/apis';
import {_ToastHandler} from '../../utils/helperFunctions';
import {isWebsite} from '../../utils/responsive';
import {authState} from '../reducers/authSlice';
import {ITrackingCancelResponse} from '../../types/reducerTypes/tradeTabTypes';
import {IActionType} from '../../types/constantFilesTypes';
import {
  ontrackingCancelEnd,
  ontrackingCancelFailed,
  ontrackingCancelSuccess,
} from '../reducers/trackingCancelSlice';
import { Alert } from 'react-native';

const {StoreConstants} = AppConst;

function* trackingCancelContact(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const {detail, ...newPayload} = action.payload;
    const data: ITrackingCancelResponse = yield call(
      trackingCancel,
      newPayload,
      userTokens
    );
    const payload = {
      results: data ?? '',
    };
    alert(data?.data || TEXT.REQUEST_CANCLED);
    yield put(ontrackingCancelSuccess(payload ?? {}));
  } catch (error: any) {
    alert(error?.response?.data?.message || 'An error occurred');
    yield put(ontrackingCancelFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(ontrackingCancelEnd());
  }
}

export default function* trackingCancelSaga() {
  yield takeLatest(
    `${StoreConstants.TRACKING_CANCEL}/ontrackingCancelStart`,
    trackingCancelContact
  );
}
