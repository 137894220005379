import StyleSheet from 'react-native-media-query';
import {
  FixedValue,
  GlobalStyleValues,
  Percentage,
  FONT_NAMES,
  BASESTYLE,
  COLORS,
} from '../../constants';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../utils/responsive';
import {IColorTheme} from '../../types/contextTypes/themeContext';

export const loginStyle = (theme: IColorTheme) =>
  StyleSheet.create({
    mainContainer: {
      ...BASESTYLE.selfCenter,
      alignItems: GlobalStyleValues.CENTER,
      width: isWebsite() ? Percentage.PRECENTAGE_75 : Percentage.PRECENTAGE_100,
      height: Percentage.PRECENTAGE_100,
      paddingHorizontal: isWebsite()
        ? FixedValue.CONSTANT_VALUE_0
        : scaleWidth(FixedValue.CONSTANT_VALUE_20),
      '@media (max-width: 500px)': {
        paddingHorizontal: FixedValue.CONSTANT_VALUE_20,
        width: Percentage.PRECENTAGE_100,
      },
    },
    logoView: {
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_88),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_16),
    },
    welcomeText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_40),
      color: theme.color,
      fontFamily: FONT_NAMES.SF_BOLD,
    },
    welcomeDescText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_16),
      color: theme.color,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_10),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_26),
    },
    lostPassword: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_15),
      lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_25),
      color: COLORS.PRIMARY_BLUE,
      fontFamily: FONT_NAMES.SF_REGULAR,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_16),
      marginBottom: scaleHeight(FixedValue.CONSTANT_VALUE_19),
    },
    dontHaveAccount: {
      color: theme.color,
      marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_40),
    },
    signUpText: {
      color: COLORS.PRIMARY_BLUE,
      fontFamily: FONT_NAMES.SF_MEDIUM,
    },
    sloganText: {
      fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
      color: theme.color,
      fontFamily: FONT_NAMES.SF_MEDIUM,
      textAlign: GlobalStyleValues.CENTER,
      marginTop: FixedValue.CONSTANT_VALUE_40,
    },
    buttonContainer: {
      justifyContent: GlobalStyleValues.SPACE_BETWEEN,
      width: Percentage.PRECENTAGE_100,
      flexDirection: GlobalStyleValues.ROW,
    },
  });
