import React from 'react';
import {Pressable, Text, TextInput, View} from 'react-native';

import {ThemeContext} from '../../../contextAPI/themeContext';
import {TopBuyingSettingStyle} from './setting.style';
import Ellipse from '../../../components/renderSvgs/ellipse';
import SelectEllipse from '../../../components/renderSvgs/selectEllipse';
import {FixedValue, TEXT} from '../../../constants';
import CustomButton from '../../../components/button/customButton';
import {CHILD_PERMISSION, Percentage} from '../../../constants/enum';
import useTopBuyingSetting from '../../../hooks/componentHooks/useTopBuyingSetting';
import KeyboardAvoid from '../../../components/layout/keyboardAvoid';
import ConfirmationModal from '../../../modals/confirmationModal/confirmationModal';
import {ChildProfileContext} from '../../../contextAPI/childProfileContext';
import {useAppSelector} from '../../../hooks/reduxHooks';
import {childProfileApproveStore} from '../../../redux/reducers/childApproveSlice';
import {TOP_BUYING_SETTING} from '../../../constants/actionTypes';
import {TOAST_TEXT} from '../../../constants/textMessage';
import {_ToastHandler} from '../../../utils/helperFunctions';
import {useIsFocused} from '@react-navigation/native';
import {goBack} from '../../../services/navigationService';
import useChildListHooks from '../../../hooks/componentHooks/useChildListHooks';
import {handleTopBuyingLimitChangeRegex} from '../../../utils/validation';

const TopBuyingScreen = () => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = TopBuyingSettingStyle(ColorTheme);
  const isFocused = useIsFocused();
  const {
    SettingOptions,
    selectedOption,
    limitCount,
    isDisabled,
    TextInputRef,
    saveSettings,
    setLimitCount,
    updateSelectedOption,
  } = useTopBuyingSetting();
  const {
    showBuySettingChangePopUp,
    childUserData,
    setShowBuySettingChangePopUp,
  } = React.useContext(ChildProfileContext);
  const {topBuyingSettingData} = useAppSelector(childProfileApproveStore);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const {callChildProfileAPI} = useChildListHooks();

  React.useEffect(() => {
    // @ts-ignore
    setLoading(topBuyingSettingData.isLoading);
  }, [topBuyingSettingData.isLoading]);

  React.useEffect((): void => {
    if (isFocused) {
      if (topBuyingSettingData.status === TOP_BUYING_SETTING.SUCCESS) {
        _ToastHandler(TOAST_TEXT.TOP_BUYING_APPROVAL, true);
        setShowBuySettingChangePopUp(false);
        callChildProfileAPI(childUserData?.id ?? FixedValue.CONSTANT_VALUE_0);
        goBack();
      }
    }
  }, [topBuyingSettingData.status]);

  // Function to handle input change
  const handleTopBuyingLimitChange = (value: string) => {
    if (handleTopBuyingLimitChangeRegex(value)) {
      setLimitCount(value);
    }
  };

  return (
    <KeyboardAvoid>
      <View style={styles.settingContainer}>
        <View style={styles.selectView}>
          <Text style={styles.oneOption}>{TEXT.SELECT_ONE_OPTION}</Text>
        </View>
        {SettingOptions.map(item => (
          <Pressable
            key={item.text}
            onPress={() => updateSelectedOption(item.status)}
          >
            <View style={styles.ellipseView}>
              <View style={{width: Percentage.PRECENTAGE_11_5}}>
                {item.status === selectedOption ? (
                  <SelectEllipse
                    height={FixedValue.CONSTANT_VALUE_20}
                    width={FixedValue.CONSTANT_VALUE_20}
                  />
                ) : (
                  <Ellipse
                    height={FixedValue.CONSTANT_VALUE_20}
                    width={FixedValue.CONSTANT_VALUE_20}
                  />
                )}
              </View>
              <View style={{width: Percentage.PRECENTAGE_90}}>
                <Text style={styles.allBuying}>{item.text}</Text>
              </View>
            </View>
            {item.status === CHILD_PERMISSION.HAVE_LIMIT &&
              selectedOption === CHILD_PERMISSION.HAVE_LIMIT && (
                <View style={styles.limitView}>
                  <Text style={styles.limitText}>{TEXT.SET_THE_MONTHLY}</Text>
                  <TextInput
                    // @ts-ignore
                    ref={TextInputRef}
                    editable={selectedOption === CHILD_PERMISSION.HAVE_LIMIT}
                    style={styles.limitInput}
                    keyboardType="numeric"
                    maxLength={FixedValue.CONSTANT_VALUE_10}
                    placeholder="00.00"
                    value={
                      selectedOption === CHILD_PERMISSION.HAVE_LIMIT
                        ? limitCount
                        : ''
                    }
                    onChangeText={handleTopBuyingLimitChange}
                  />
                </View>
              )}
          </Pressable>
        ))}
        <View style={styles.buttonView}>
          <CustomButton
            isDisabled={isDisabled}
            buttonLabel={TEXT.SAVE_NOW}
            buttonwidth={styles.buttonStyle}
            labelStyle={styles.buttonLabelstyle}
            onPress={() => setShowBuySettingChangePopUp(true)}
          />
        </View>
        {showBuySettingChangePopUp && (
          <ConfirmationModal
            title={TEXT.CHANGE_BUYING_SETTING}
            modalVisible={showBuySettingChangePopUp}
            onPressYes={saveSettings}
            onShowHide={() => setShowBuySettingChangePopUp(false)}
            isLoading={isLoading}
          />
        )}
      </View>
    </KeyboardAvoid>
  );
};

export default React.memo(TopBuyingScreen);
