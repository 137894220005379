import {takeLatest, put, call, select, delay} from 'redux-saga/effects';
import {AppConst, FixedValue} from '../../constants';
import {
  IActionType,
  IDeleteApiResponse,
  IMoveCollectionApiResponse,
} from '../../types/constantFilesTypes';
import {
  CartCheckoutRequestPayload,
  CounterOfferResponsePayload,
  CouponCodeObject,
  DistributeTopResponsePayload,
  ICreateOfferResponse,
  IGroupListSearchSuccessRespones,
  IInProgressTopListResponse,
  ITopAmountAndReportAbuseResponse,
  IUserListSearchSuccessRespones,
  TopAmountAndReportAbuseResponse,
} from '../../types/reducerTypes/searchTabStoreType';
import {
  _getCollectionTopLists,
  _buyTop,
  _getInProgressTopList,
  _getPurchasedTopList,
  _getTopListsByCollectionID,
  _moveTopToCollection,
  _deleteCollectionAndTops,
  _deleteTop,
  _markTop,
  _getUserListSearch,
  _distributeTop,
  _getGroupListSearch,
  _getGroupUserListSearch,
  _createTradeOffer,
  _moveTopToCollections,
  _counterOffer,
  _cartCheckout,
  _cartBuyingRequestToParent,
  _getCartAmountAndReportAbuseData,
  _verifyCouponCode,
  _getReportAbuseData,
  _distributeViaEmailOrPhone,
  _distributeViaCSV,
  _verifyReedemCode,
  _checkUserMultipleAccount,
} from '../../utils/apis';
import {_ToastHandler} from '../../utils/helperFunctions';
import {authState} from '../reducers/authSlice';
import {
  onCollectionTopListsEnd,
  onCollectionTopListsFailed,
  onCollectionTopListsSuccess,
  onInProgressTopsListEnd,
  onInProgressTopsListFailed,
  onInProgressTopsSuccess,
  onPurchasedTopListsSuccess,
  onPurchasedTopListsFailed,
  onPurchasedTopListsEnd,
  onBuyTopSuccess,
  onBuyTopFailed,
  onBuyTopEnd,
  onCollectionsSpecificTopListsEnd,
  onCollectionsSpecificTopListsSuccess,
  onCollectionsSpecificTopListsFailed,
  onAddTopsToCollectionSuccess,
  onAddTopsToCollectionFailed,
  onAddTopsToCollectionEnd,
  onDeleteCollectionAndTopsSuccess,
  onDeleteCollectionAndTopsFailed,
  onDeleteCollectionAndTopsEnd,
  onDeleteTopSuccess,
  onDeleteTopFailed,
  onDeleteTopEnd,
  onUserListSearchSuccess,
  onUserListSearchFailed,
  onUserListSearchEnd,
  onDistributeTopSuccess,
  onDistributeTopFailed,
  onDistributeTopEnd,
  onGroupListSearchSuccess,
  onGroupListSearchFailed,
  onGroupListSearchEnd,
  onGroupUserListSearchSuccess,
  onGroupUserListSearchFailed,
  onGroupUserListSearchEnd,
  onCreateOfferSuccess,
  onCreateOfferFailed,
  onCreateOfferEnd,
  moveTopsToCollectionSuccess,
  moveTopsToCollectionFailed,
  moveTopsToCollectionEnd,
  onCounterOfferSuccess,
  onCounterOfferFailed,
  onCounterOfferEnd,
  onCartCheckoutEnd,
  onCartCheckoutFailed,
  onCartCheckoutSuccess,
  onBuyingRequestSuccess,
  onBuyingRequestEnd,
  onBuyingRequestFailed,
  topAmountAndReportAbuseDataSuccess,
  topAmountAndReportAbuseDataEnd,
  topAmountAndReportAbuseDataFailed,
  verifyCouponCodeSuccess,
  verifyCouponCodeFailed,
  verifyCouponCodeEnd,
  reportAbuseDataSuccess,
  reportAbuseDataFailed,
  reportAbuseDataEnd,
  distributeViaEmailOrPhoneSuccess,
  distributeViaEmailOrPhoneFailed,
  distributeViaEmailOrPhoneEnd,
  distributeViaCSVSuccess,
  distributeViaCSVFailed,
  distributeViaCSVEnd,
  verifyReedemCodeSuccess,
  verifyReedemCodeFailed,
  verifyReedemCodeEnd,
  checkForMultipleAccountSuccess,
  checkForMultipleAccountFailed,
  checkForMultipleAccountEnd,
} from '../reducers/homeSlice';
import {
  onTradableTopSuccess,
  onTradableTopFailed,
  onTradableTopEnd,
} from '../reducers/tradableTopsSlice';
import {isWebsite} from '../../utils/responsive';

const {StoreConstants} = AppConst;

function* getInProgressTopList(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IInProgressTopListResponse = yield call(
      _getInProgressTopList,
      action.payload,
      userTokens
    );
    yield put(onInProgressTopsSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onInProgressTopsListFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onInProgressTopsListEnd());
  }
}

function* collectionTopList(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: object = yield call(
      _getCollectionTopLists,
      action.payload,
      userTokens
    );
    yield put(onCollectionTopListsSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onCollectionTopListsFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onCollectionTopListsEnd());
  }
}

function* getPurchasedTopList(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IInProgressTopListResponse = yield call(
      _getPurchasedTopList,
      action.payload,
      userTokens
    );
    yield put(onPurchasedTopListsSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onPurchasedTopListsFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onPurchasedTopListsEnd());
  }
}

function* buyTop(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: object = yield call(_buyTop, action.payload, userTokens);
    yield put(onBuyTopSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onBuyTopFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onBuyTopEnd());
  }
}

function* getTopsByCollectionId(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: object = yield call(
      _getTopListsByCollectionID,
      action.payload,
      userTokens
    );
    yield put(onCollectionsSpecificTopListsSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onCollectionsSpecificTopListsFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onCollectionsSpecificTopListsEnd());
  }
}

function* moveTopToCollection(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IMoveCollectionApiResponse = yield call(
      _moveTopToCollection,
      action.payload,
      userTokens
    );
    yield put(onAddTopsToCollectionSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onAddTopsToCollectionFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onAddTopsToCollectionEnd());
  }
}
function* deleteTop(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: object = yield call(_deleteTop, action.payload, userTokens);
    yield put(onDeleteTopSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onDeleteTopFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onDeleteTopEnd());
  }
}

function* deleteCollectionAndTops(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IDeleteApiResponse = yield call(
      _deleteCollectionAndTops,
      action.payload,
      userTokens
    );
    yield put(onDeleteCollectionAndTopsSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onDeleteCollectionAndTopsFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onDeleteCollectionAndTopsEnd());
  }
}

// Mark Top as Tradable/UnTradable
function* markTopAsTradable(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: object = yield call(_markTop, action.payload, userTokens);
    yield put(onTradableTopSuccess(data));
  } catch (error: any) {
    error?.errors[0]?.msg && _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onTradableTopFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onTradableTopEnd());
  }
}

// Call get User List Search API
function* getUserListSearch(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserListSearchSuccessRespones = yield call(
      _getUserListSearch,
      action.payload,
      userTokens
    );
    yield put(onUserListSearchSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onUserListSearchFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onUserListSearchEnd());
  }
}

// Call distribute top API
function* distributeTop(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: DistributeTopResponsePayload = yield call(
      _distributeTop,
      action.payload,
      userTokens
    );
    yield put(onDistributeTopSuccess(data));
  } catch (error: any) {
    error?.errors[0]?.msg && _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onDistributeTopFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onDistributeTopEnd());
  }
}

// Call get group List Search API
function* getGroupListSearch(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IGroupListSearchSuccessRespones = yield call(
      _getGroupListSearch,
      action.payload,
      userTokens
    );
    yield put(onGroupListSearchSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onGroupListSearchFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onGroupListSearchEnd());
  }
}

// Call get group List Search API
function* getGroupUserListSearch(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IUserListSearchSuccessRespones = yield call(
      _getGroupUserListSearch,
      action.payload,
      userTokens
    );
    yield put(onGroupUserListSearchSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onGroupUserListSearchFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onGroupUserListSearchEnd());
  }
}

// Call create trade offer API
function* createTradeOffer(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: ICreateOfferResponse = yield call(
      _createTradeOffer,
      action.payload,
      userTokens
    );
    yield put(onCreateOfferSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onCreateOfferFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onCreateOfferEnd());
  }
}

function* moveTopToCollections(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: IMoveCollectionApiResponse = yield call(
      _moveTopToCollections,
      action.payload,
      userTokens
    );
    yield put(moveTopsToCollectionSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(moveTopsToCollectionFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(moveTopsToCollectionEnd());
  }
}

// Call counter offer API
function* counterOffer(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: CounterOfferResponsePayload = yield call(
      _counterOffer,
      action.payload,
      userTokens
    );
    yield put(onCounterOfferSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onCounterOfferFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onCounterOfferEnd());
  }
}

// Call cart checkout API
function* cartCheckout(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: CartCheckoutRequestPayload = yield call(
      _cartCheckout,
      action.payload,
      userTokens
    );
    yield put(onCartCheckoutSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onCartCheckoutFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onCartCheckoutEnd());
  }
}
// Call cart buying request to Parent API
function* cartBuyingRequestToParent(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: CartCheckoutRequestPayload = yield call(
      _cartBuyingRequestToParent,
      action.payload,
      userTokens
    );
    yield put(onBuyingRequestSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[0]?.msg ?? '', false);
    yield put(onBuyingRequestFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(onBuyingRequestEnd());
  }
}

// get top amount
function* getCartAmountAndReportAbuseData() {
  try {
    const {userTokens} = yield select(authState);
    const data: TopAmountAndReportAbuseResponse = yield call(
      _getCartAmountAndReportAbuseData,
      userTokens
    );
    yield put(topAmountAndReportAbuseDataSuccess({data}));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(topAmountAndReportAbuseDataFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(topAmountAndReportAbuseDataEnd());
  }
}

//verify coupon code api call
function* verifyCouponCodeSaga(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: CouponCodeObject = yield call(
      _verifyCouponCode,
      action.payload,
      userTokens
    );
    yield put(verifyCouponCodeSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(verifyCouponCodeFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(verifyCouponCodeEnd());
  }
}

// get report abuse data API
function* getReportAbuseData() {
  try {
    const {userTokens} = yield select(authState);
    const data: ITopAmountAndReportAbuseResponse = yield call(
      _getReportAbuseData,
      userTokens
    );
    yield put(reportAbuseDataSuccess({data}));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(reportAbuseDataFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(reportAbuseDataEnd());
  }
}

// distribute top via text or email
function* distributeTopViaEmail(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: CouponCodeObject = yield call(
      _distributeViaEmailOrPhone,
      action.payload,
      userTokens
    );
    yield put(distributeViaEmailOrPhoneSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(distributeViaEmailOrPhoneFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(distributeViaEmailOrPhoneEnd());
  }
}

// distribute top via csv
function* distributeTopViaCSV(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: CouponCodeObject = yield call(
      _distributeViaCSV,
      action.payload,
      userTokens
    );
    yield put(distributeViaCSVSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(distributeViaCSVFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(distributeViaCSVEnd());
  }
}

//verify coupon code api call
function* verifyReedemCodeSaga(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: CouponCodeObject = yield call(
      _verifyReedemCode,
      action.payload,
      userTokens
    );
    yield put(verifyReedemCodeSuccess(data));
  } catch (error: any) {
    _ToastHandler(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '', false);
    yield put(verifyReedemCodeFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(verifyReedemCodeEnd());
  }
}

function* checkForMultipleAccountSaga(action: IActionType) {
  try {
    const {userTokens} = yield select(authState);
    const data: CouponCodeObject = yield call(
      _checkUserMultipleAccount,
      action.payload,
      userTokens
    );
    yield put(checkForMultipleAccountSuccess(data));
  } catch (error: any) {
    alert(error?.errors[FixedValue.CONSTANT_VALUE_0]?.msg ?? '');
    yield put(checkForMultipleAccountFailed(error));
  } finally {
    if (isWebsite()) yield delay(1);
    yield put(checkForMultipleAccountEnd());
  }
}

export default function* topsSaga() {
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/onInProgressTopsListStart`,
    getInProgressTopList
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/onCollectionTopListsStart`,
    collectionTopList
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/onPurchasedTopListsStart`,
    getPurchasedTopList
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/onBuyTopStart`,
    buyTop
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/onCollectionsSpecificTopListsStart`,
    getTopsByCollectionId
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/onAddTopsToCollectionStart`,
    moveTopToCollection
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/onDeleteCollectionAndTopsStart`,
    deleteCollectionAndTops
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/onDeleteTopStart`,
    deleteTop
  );
  yield takeLatest(
    `${StoreConstants.TRADABLE_TOPS}/onTradableTopStart`,
    markTopAsTradable
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/onUserListSearchStart`,
    getUserListSearch
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/onDistributeTopStart`,
    distributeTop
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/onGroupListSearchStart`,
    getGroupListSearch
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/onGroupUserListSearchStart`,
    getGroupUserListSearch
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/onCreateOfferStart`,
    createTradeOffer
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/moveTopsToCollectionStart`,
    moveTopToCollections
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/onCounterOfferStart`,
    counterOffer
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/onCartCheckoutStart`,
    cartCheckout
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/onBuyingRequestStart`,
    cartBuyingRequestToParent
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/topAmountAndReportAbuseDataStart`,
    getCartAmountAndReportAbuseData
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/verifyCouponCodeStart`,
    verifyCouponCodeSaga
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/reportAbuseDataStart`,
    getReportAbuseData
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/distributeViaEmailOrPhoneStart`,
    distributeTopViaEmail
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/distributeViaCSVStart`,
    distributeTopViaCSV
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/verifyReedemCodeStart`,
    verifyReedemCodeSaga
  );
  yield takeLatest(
    `${StoreConstants.GET_IN_PROGRESS_TOPS}/checkForMultipleAccountStart`,
    checkForMultipleAccountSaga
  );
}
