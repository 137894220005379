import {createSlice} from '@reduxjs/toolkit';
import {AppConst, ActionType} from '../../constants';
import {RootState} from '../store';
import {ResetAllState} from './logoutslice';
import {ICreateGroupManagement} from '../../types/reducerTypes/groupMangementTypes';
import {
  GROUP_DELETE,
  GROUP_DETAIL,
  GROUP_INVITATION_APPROVE,
  GROUP_INVITATION_LIST,
  GROUP_INVITATION_REJECT,
  GROUP_IN_LIST,
  GROUP_USER_LIST,
  LEAVE_GROUP_ACTION,
  MY_GROUP_LIST,
  REMOVE_USER_FROM_GROUP,
  UPDATE_GROUP,
} from '../../constants/actionTypes';

const {StoreConstants} = AppConst;
const {CREATE_GROUP_MANAGEMENT, USER_LIST, USER_ADD_ON_GROUP} = ActionType;

const initialState: ICreateGroupManagement = {
  createNewGroup: {
    isLoading: false,
    success: null,
    failed: null,
    status: CREATE_GROUP_MANAGEMENT.END,
  },
  updateGroup: {
    isLoading: false,
    success: null,
    failed: null,
    status: UPDATE_GROUP.END,
  },
  userList: {
    isLoading: false,
    success: null,
    failed: null,
    status: USER_LIST.END,
  },
  userAddInGroup: {
    isLoading: false,
    success: null,
    failed: null,
    status: USER_ADD_ON_GROUP.END,
  },
  myGroupList: {
    isLoading: false,
    success: null,
    failed: null,
    status: MY_GROUP_LIST.END,
  },
  groupInList: {
    isLoading: false,
    success: null,
    failed: null,
    status: MY_GROUP_LIST.END,
  },
  groupInvitaionsList: {
    isLoading: false,
    success: null,
    failed: null,
    status: GROUP_INVITATION_LIST.END,
  },
  groupInvitaionApprove: {
    isLoading: false,
    success: null,
    failed: null,
    status: GROUP_INVITATION_APPROVE.END,
  },
  groupInvitaionReject: {
    isLoading: false,
    success: null,
    failed: null,
    status: GROUP_INVITATION_REJECT.END,
  },
  groupUserList: {
    isLoading: false,
    success: null,
    failed: null,
    status: GROUP_USER_LIST.END,
  },
  groupDetailAPIData: {
    isLoading: false,
    success: null,
    failed: null,
    status: GROUP_DETAIL.END,
  },
  removeUserFromGroupAPI: {
    isLoading: false,
    success: null,
    failed: null,
    status: REMOVE_USER_FROM_GROUP.END,
  },
  groupDeleteAPIData: {
    isLoading: false,
    success: null,
    failed: null,
    status: GROUP_DELETE.END,
  },
  groupLeaveAPIData: {
    isLoading: false,
    success: null,
    failed: null,
    status: LEAVE_GROUP_ACTION.END,
  },
};

const groupManagementSlice = createSlice({
  initialState,
  name: StoreConstants.GROUP_MANAGEMENT,
  reducers: {
    onCreateGroupStart: (state, _) => {
      state.createNewGroup.isLoading = true;
      state.createNewGroup.status = CREATE_GROUP_MANAGEMENT.START;
    },
    onCreateGroupSuccess: (state, action) => {
      state.createNewGroup.success = action.payload;
      state.createNewGroup.status = CREATE_GROUP_MANAGEMENT.SUCCESS;
    },
    onCreateGroupFailed: (state, action) => {
      state.createNewGroup.failed = action.payload;
      state.createNewGroup.status = CREATE_GROUP_MANAGEMENT.FAILED;
    },
    onCreateGroupEnd: state => {
      state.createNewGroup.isLoading = false;
      state.createNewGroup.status = CREATE_GROUP_MANAGEMENT.END;
    },
    onUpdateGroupStart: (state, _) => {
      state.updateGroup.isLoading = true;
      state.updateGroup.status = UPDATE_GROUP.START;
    },
    onUpdateGroupSuccess: (state, action) => {
      state.updateGroup.success = action.payload;
      state.updateGroup.status = UPDATE_GROUP.SUCCESS;
    },
    onUpdateGroupFailed: (state, action) => {
      state.updateGroup.failed = action.payload;
      state.updateGroup.status = UPDATE_GROUP.FAILED;
    },
    onUpdateGroupEnd: state => {
      state.updateGroup.isLoading = false;
      state.updateGroup.status = UPDATE_GROUP.END;
    },
    onUserListStart: (state, _) => {
      state.userList.isLoading = true;
      state.userList.status = USER_LIST.START;
    },
    onUserListSuccess: (state, action) => {
      state.userList.success = action.payload;
      state.userList.status = USER_LIST.SUCCESS;
    },
    onUserListFailed: (state, action) => {
      state.userList.failed = action.payload;
      state.userList.status = USER_LIST.FAILED;
    },
    onUserListEnd: state => {
      state.userList.isLoading = false;
      state.userList.status = USER_LIST.END;
    },
    onUserAddOnGroupStart: (state, _) => {
      state.userAddInGroup.isLoading = true;
      state.userAddInGroup.status = USER_ADD_ON_GROUP.START;
    },
    onUserAddOnGroupSuccess: (state, action) => {
      state.userAddInGroup.success = action.payload;
      state.userAddInGroup.status = USER_ADD_ON_GROUP.SUCCESS;
    },
    onUserAddOnGroupFailed: (state, action) => {
      state.userAddInGroup.failed = action.payload;
      state.userAddInGroup.status = USER_ADD_ON_GROUP.FAILED;
    },
    onUserAddOnGroupEnd: state => {
      state.userAddInGroup.isLoading = false;
      state.userAddInGroup.status = USER_ADD_ON_GROUP.END;
    },
    onMyGroupsStart: (state, _) => {
      state.myGroupList.isLoading = true;
      state.myGroupList.status = MY_GROUP_LIST.START;
    },
    onMyGroupsSuccess: (state, action) => {
      state.myGroupList.success = action.payload;
      state.myGroupList.status = MY_GROUP_LIST.SUCCESS;
    },
    onMyGroupsFailed: (state, action) => {
      state.myGroupList.failed = action.payload;
      state.myGroupList.status = MY_GROUP_LIST.FAILED;
    },
    onMyGroupsEnd: state => {
      state.myGroupList.isLoading = false;
      state.myGroupList.status = MY_GROUP_LIST.END;
    },
    onGroupsInStart: (state, _) => {
      state.groupInList.isLoading = true;
      state.groupInList.status = GROUP_IN_LIST.START;
    },
    onGroupsInSuccess: (state, action) => {
      state.groupInList.success = action.payload;
      state.groupInList.status = GROUP_IN_LIST.SUCCESS;
    },
    onGroupsInFailed: (state, action) => {
      state.groupInList.failed = action.payload;
      state.groupInList.status = GROUP_IN_LIST.FAILED;
    },
    onGroupsInEnd: state => {
      state.groupInList.isLoading = false;
      state.groupInList.status = GROUP_IN_LIST.END;
    },
    onGroupInvitationsStart: (state, _) => {
      state.groupInvitaionsList.isLoading = true;
      state.groupInvitaionsList.status = GROUP_INVITATION_LIST.START;
    },
    onGroupInvitationsSuccess: (state, action) => {
      state.groupInvitaionsList.success = action.payload;
      state.groupInvitaionsList.status = GROUP_INVITATION_LIST.SUCCESS;
    },
    onGroupInvitationsFailed: (state, action) => {
      state.groupInvitaionsList.failed = action.payload;
      state.groupInvitaionsList.status = GROUP_INVITATION_LIST.FAILED;
    },
    onGroupInvitationsEnd: state => {
      state.groupInvitaionsList.isLoading = false;
      state.groupInvitaionsList.status = GROUP_INVITATION_LIST.END;
    },
    onGroupInvitationsApprovedStart: (state, _) => {
      state.groupInvitaionApprove.isLoading = true;
      state.groupInvitaionApprove.status = GROUP_INVITATION_APPROVE.START;
    },
    onGroupInvitationsApprovedSuccess: (state, action) => {
      state.groupInvitaionApprove.success = action.payload;
      state.groupInvitaionApprove.status = GROUP_INVITATION_APPROVE.SUCCESS;
    },
    onGroupInvitationsApprovedFailed: (state, action) => {
      state.groupInvitaionApprove.failed = action.payload;
      state.groupInvitaionApprove.status = GROUP_INVITATION_APPROVE.FAILED;
    },
    onGroupInvitationsApprovedEnd: state => {
      state.groupInvitaionApprove.isLoading = false;
      state.groupInvitaionApprove.status = GROUP_INVITATION_APPROVE.END;
    },
    onGroupInvitationsRejectStart: (state, _) => {
      state.groupInvitaionReject.isLoading = true;
      state.groupInvitaionReject.status = GROUP_INVITATION_REJECT.START;
    },
    onGroupInvitationsRejectSuccess: (state, action) => {
      state.groupInvitaionReject.success = action.payload;
      state.groupInvitaionReject.status = GROUP_INVITATION_REJECT.SUCCESS;
    },
    onGroupInvitationsRejectFailed: (state, action) => {
      state.groupInvitaionReject.failed = action.payload;
      state.groupInvitaionReject.status = GROUP_INVITATION_REJECT.FAILED;
    },
    onGroupInvitationsRejectEnd: state => {
      state.groupInvitaionReject.isLoading = false;
      state.groupInvitaionReject.status = GROUP_INVITATION_REJECT.END;
    },
    onGroupUserListStart: (state, _) => {
      state.groupUserList.isLoading = true;
      state.groupUserList.status = GROUP_USER_LIST.START;
    },
    onGroupUserListSuccess: (state, action) => {
      state.groupUserList.success = action.payload;
      state.groupUserList.status = GROUP_USER_LIST.SUCCESS;
    },
    onGroupUserListFailed: (state, action) => {
      state.groupUserList.failed = action.payload;
      state.groupUserList.status = GROUP_USER_LIST.FAILED;
    },
    onGroupUserListEnd: state => {
      state.groupUserList.isLoading = false;
      state.groupUserList.status = GROUP_USER_LIST.END;
    },
    onClearGroupUserListEnd: state => {
      state.groupUserList.isLoading = false;
      state.groupUserList.success = null;
      state.groupUserList.failed = null;
      state.groupUserList.status = GROUP_USER_LIST.END;
    },
    onGroupDetailApiStart: (state, _) => {
      state.groupDetailAPIData.isLoading = true;
      state.groupDetailAPIData.status = GROUP_DETAIL.START;
    },
    onGroupDetailApiSuccess: (state, action) => {
      state.groupDetailAPIData.success = action.payload;
      state.groupDetailAPIData.status = GROUP_DETAIL.SUCCESS;
    },
    onGroupDetailApiFailed: (state, action) => {
      state.groupDetailAPIData.failed = action.payload;
      state.groupDetailAPIData.status = GROUP_DETAIL.FAILED;
    },
    onGroupDetailApiEnd: state => {
      state.groupDetailAPIData.isLoading = false;
      state.groupDetailAPIData.status = GROUP_DETAIL.END;
    },
    onRemoveUserFromGroupStart: (state, _) => {
      state.removeUserFromGroupAPI.isLoading = true;
      state.removeUserFromGroupAPI.status = REMOVE_USER_FROM_GROUP.START;
    },
    onRemoveUserFromGroupSuccess: (state, action) => {
      state.removeUserFromGroupAPI.success = action.payload;
      state.removeUserFromGroupAPI.status = REMOVE_USER_FROM_GROUP.SUCCESS;
    },
    onRemoveUserFromGroupFailed: (state, action) => {
      state.removeUserFromGroupAPI.failed = action.payload;
      state.removeUserFromGroupAPI.status = REMOVE_USER_FROM_GROUP.FAILED;
    },
    onRemoveUserFromGroupEnd: state => {
      state.removeUserFromGroupAPI.isLoading = false;
      state.removeUserFromGroupAPI.status = REMOVE_USER_FROM_GROUP.END;
    },
    onGroupDeleteApiStart: (state, _) => {
      state.groupDeleteAPIData.isLoading = true;
      state.groupDeleteAPIData.status = GROUP_DELETE.START;
    },
    onGroupDeleteApiSuccess: (state, action) => {
      state.groupDeleteAPIData.success = action.payload;
      state.groupDeleteAPIData.status = GROUP_DELETE.SUCCESS;
    },
    onGroupDeleteApiFailed: (state, action) => {
      state.groupDeleteAPIData.failed = action.payload;
      state.groupDeleteAPIData.status = GROUP_DELETE.FAILED;
    },
    onGroupDeleteApiEnd: state => {
      state.groupDeleteAPIData.isLoading = false;
      state.groupDeleteAPIData.status = GROUP_DELETE.END;
    },
    onGroupLeaveApiStart: (state, _) => {
      state.groupLeaveAPIData.isLoading = true;
      state.groupLeaveAPIData.status = LEAVE_GROUP_ACTION.START;
    },
    onGroupLeaveApiSuccess: (state, action) => {
      state.groupLeaveAPIData.success = action.payload;
      state.groupLeaveAPIData.status = LEAVE_GROUP_ACTION.SUCCESS;
    },
    onGroupLeaveApiFailed: (state, action) => {
      state.groupLeaveAPIData.failed = action.payload;
      state.groupLeaveAPIData.status = LEAVE_GROUP_ACTION.FAILED;
    },
    onGroupLeaveApiEnd: state => {
      state.groupLeaveAPIData.isLoading = false;
      state.groupLeaveAPIData.status = LEAVE_GROUP_ACTION.END;
    },
  },
  extraReducers: builder => builder.addCase(ResetAllState, () => initialState),
});

export const {
  onCreateGroupStart,
  onCreateGroupSuccess,
  onCreateGroupFailed,
  onCreateGroupEnd,
  onUpdateGroupStart,
  onUpdateGroupSuccess,
  onUpdateGroupFailed,
  onUpdateGroupEnd,
  onUserListStart,
  onUserListSuccess,
  onUserListFailed,
  onUserListEnd,
  onUserAddOnGroupStart,
  onUserAddOnGroupSuccess,
  onUserAddOnGroupFailed,
  onUserAddOnGroupEnd,
  onMyGroupsStart,
  onMyGroupsSuccess,
  onMyGroupsFailed,
  onMyGroupsEnd,
  onGroupsInStart,
  onGroupsInSuccess,
  onGroupsInFailed,
  onGroupsInEnd,
  onGroupInvitationsStart,
  onGroupInvitationsSuccess,
  onGroupInvitationsFailed,
  onGroupInvitationsEnd,
  onGroupInvitationsApprovedStart,
  onGroupInvitationsApprovedSuccess,
  onGroupInvitationsApprovedFailed,
  onGroupInvitationsApprovedEnd,
  onGroupInvitationsRejectStart,
  onGroupInvitationsRejectSuccess,
  onGroupInvitationsRejectFailed,
  onGroupInvitationsRejectEnd,
  onGroupUserListStart,
  onGroupUserListSuccess,
  onGroupUserListFailed,
  onGroupUserListEnd,
  onClearGroupUserListEnd,
  onGroupDetailApiStart,
  onGroupDetailApiSuccess,
  onGroupDetailApiFailed,
  onGroupDetailApiEnd,
  onRemoveUserFromGroupStart,
  onRemoveUserFromGroupSuccess,
  onRemoveUserFromGroupFailed,
  onRemoveUserFromGroupEnd,
  onGroupDeleteApiStart,
  onGroupDeleteApiSuccess,
  onGroupDeleteApiFailed,
  onGroupDeleteApiEnd,
  onGroupLeaveApiStart,
  onGroupLeaveApiSuccess,
  onGroupLeaveApiFailed,
  onGroupLeaveApiEnd,
} = groupManagementSlice.actions;
export default groupManagementSlice.reducer;
export const groupManagementStore = (state: RootState) => state.groupManagement;
