import React from 'react';
import {View} from 'react-native';
import {TopApprovedListStyle} from './approvedList.style';
import {ThemeContext} from '../../../contextAPI/themeContext';
import TopApproveListScreen from '../topTradingApprovedList/approvedList';
import CustomButton from '../../../components/button/customButton';
import {TEXT} from '../../../constants';
import useTradingApprovalHooks from '../../../hooks/componentHooks/useTradingApprovalHooks';
import CustomModalForSelectUser from '../../../modals/customModalForSelectUser';
import {ChildProfileContext} from '../../../contextAPI/childProfileContext';
import {childProfileStore} from '../../../redux/reducers/childProfileSlice';
import {useAppSelector} from '../../../hooks/reduxHooks';
import {USER_LIST_TYPE} from '../../../constants/enum';
import ConfirmationModal from '../../../modals/confirmationModal/confirmationModal';

const TopApprovedList = (): JSX.Element => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = TopApprovedListStyle(ColorTheme);
  const {onTradeUserAdd} = useAppSelector(childProfileStore);
  const {
    _onChildUserModalSearchText,
    onTradeUserListEndReach,
    callAddUserInTradeApprovedAPI,
    openAddUserInTradeModal,
    userRemoveFromTradeApprovedList,
  } = useTradingApprovalHooks();
  const {
    addNewChildUserModal,
    addNewChildUserSearchText,
    tradeUserListData,
    selectedUserInTrade,
    showTradeApproveUserRemoveAlert,
    setAddNewChildUserModal,
    selectOrUnSelectTradeUser,
    setShowTradeApproveUserRemoveAlert,
  } = React.useContext(ChildProfileContext);

  const customUserSelectModaldata = {
    modalHeaderName: TEXT.ADD_NEW_USER_ONLY,
    modalVisible: addNewChildUserModal,
    value: addNewChildUserSearchText,
    searchPlaceholder: TEXT.SEARCH_USERS,
    listData: tradeUserListData,
    closeModal: setAddNewChildUserModal,
    onChangeText: _onChildUserModalSearchText,
    onSelectOrUnSelectUser: selectOrUnSelectTradeUser,
    selectedUsers: selectedUserInTrade,
    showLogInUser: true,
    buttonLoader: onTradeUserAdd?.isLoading ?? false,
    callAddUserToGroupAPI: callAddUserInTradeApprovedAPI,
    onReachedEnd: onTradeUserListEndReach,
  };

  return (
    <View style={styles.listMainContainer}>
      <TopApproveListScreen />
      <View style={styles.buttonView}>
        <CustomButton
          buttonLabel={TEXT.ADD_NEW_USER}
          buttonwidth={styles.buttonStyle}
          labelStyle={styles.buttonLabelstyle}
          onPress={() => openAddUserInTradeModal(USER_LIST_TYPE.OFFER)}
        />
      </View>
      {addNewChildUserModal && (
        <CustomModalForSelectUser customModaldata={customUserSelectModaldata} />
      )}
      {showTradeApproveUserRemoveAlert && (
        <ConfirmationModal
          title={TEXT.USER_REMOVED_FROM_APPROVAL_LIST}
          modalVisible={showTradeApproveUserRemoveAlert}
          onPressYes={() => userRemoveFromTradeApprovedList()}
          onShowHide={() => setShowTradeApproveUserRemoveAlert(false)}
        />
      )}
    </View>
  );
};

export default React.memo(TopApprovedList);
