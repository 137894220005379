import React from 'react';
import {View, Text} from 'react-native';
import {TEXT} from '../../constants';
import {ThemeContext} from '../../contextAPI/themeContext';
import {GroupInvitationComponentProps} from '../../types/reducerTypes/groupMangementTypes';
import {getDaysAgo, getInitials} from '../../utils/helperFunctions';
import CustomApprovalButton from '../button/customApprovalButton';
import ProfilePics from '../smallDesigns/profilePics';
import {style} from './style';

const GroupInvitationListComponent = (props: GroupInvitationComponentProps) => {
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = style(ColorTheme);
  const {item, onApproveButton, onRejectButton, showParentGuardianManagement} =
    props;

  return (
    <View style={[styles.listMainView]}>
      <View style={styles.mainView}>
        <ProfilePics
          image={item?.user?.profileImage ?? ''}
          text={getInitials(`${item?.user?.firstName} ${item?.user?.lastName}`)}
          mainViewStyle={styles.mainViewStyle}
          initialsTextStyle={styles.groupProfileText}
        />
        <View style={styles.textView}>
          <View style={[styles.mainView, styles.maxWidthForText]}>
            <Text style={styles.titleText}>
              {item?.user?.firstName} {item?.user?.lastName}
              <Text style={styles.titleTextan}>
                {TEXT.INVITE_YOU_TO_JOIN}
              </Text>{' '}
              {item?.group?.groupName}
              <Text style={styles.titleTextan}>{TEXT.GROUP_WITH_DOT}</Text>
            </Text>
          </View>
          <Text style={styles.titleText1}>{TEXT.INVITATION_FOR_JOINING}</Text>
          <Text style={styles.titleTextDay}>{getDaysAgo(item.createdAt)}</Text>
          {showParentGuardianManagement && (
            <CustomApprovalButton
              item={item}
              onApproveButton={onApproveButton}
              onRejectButton={onRejectButton}
              acceptText={TEXT.ACCEPT_TEXT}
            />
          )}
        </View>
      </View>
      <View style={styles.bottomLineStyle} />
    </View>
  );
};

export default React.memo(GroupInvitationListComponent);
