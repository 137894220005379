import React from 'react';
import Svg, {SvgProps, Rect} from 'react-native-svg';
const RectangleImage = (props: SvgProps) => (
  <Svg
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    width={67}
    height={67}
    viewBox="0 0 67 67"
    fill="none"
    bottom={10}
    marginHorizontal={8}
    {...props}
  >
    <Rect
      x={0.5}
      y={0.5}
      width={66}
      height={66}
      rx={7.5}
      fill="black"
      stroke="#333333"
      strokeDasharray="2 2"
    />
  </Svg>
);
export default RectangleImage;
