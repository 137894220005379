import React from 'react';
import ModalButton from '../../components/button/modalButton';
import {COLORS, FixedValue, ROUTE_NAME, TEXT} from '../../constants';
import moveIcon from '../../components/renderSvgs/moveArrow';
import plus from '../../components/renderSvgs/plus';
import distributeIcon from '../../components/renderSvgs/distributeIcon';

import DeleteIcon from '../../components/renderSvgs/deleteBin';
import {ThemeContext} from '../../contextAPI/themeContext';
import {HomeContext} from '../../contextAPI/homeContext';
import {TopDetailContext} from '../../contextAPI/topDetailContext';

import useDeleteTopHooks from '../../hooks/componentHooks/useDeleteTopHooks';
import {Pressable, Text, View} from 'react-native';
import {optionsModalStyle} from './styles';
import CustomButton from '../../components/button/customButton';
import {scaleHeight, scaleWidth} from '../../utils/responsive';
import useHomeHooks from '../../hooks/componentHooks/useHomeHooks';
import {push} from '../../services/navigationService';
import {TradeContext} from '../../contextAPI/tradeContext';
import ModalBack from '../../components/renderSvgs/modalBack';
import useFavouriteHooks from '../../hooks/componentHooks/useFavouriteHooks';

const OptionsModalField = () => {
  const {callDeleteTopAPI, deleteModalNo, updateDeleteModalNo} =
    useDeleteTopHooks();
  const {ColorTheme} = React.useContext(ThemeContext);
  const {styles} = optionsModalStyle(ColorTheme);

  const {
    selectedTopData,
    setSelectedTopsToMove,
    setShowDistributiveModal,
    searchUserListText,
    searchGroupListText,
    selectedFromCollectionData,
    setInitialLoader,
  } = React.useContext(HomeContext);
  const {setOpenOptionsModal, setOpenMoveToCollectionModal} =
    React.useContext(TopDetailContext);
  const {callUserListSearchAPI, callGroupListSearchAPI} = useHomeHooks();
  const {selectOrUnSelectSingleTop} = React.useContext(TradeContext);
  const {getfavoriteUserList} = useFavouriteHooks();

  const handleEditTop = () => {
    // handle adding new tops to collection
    setOpenOptionsModal();
    selectOrUnSelectSingleTop(
      selectedTopData
        ? {
            ...selectedTopData,
            isSelected: false,
            selectedQuantity: FixedValue.CONSTANT_VALUE_1,
          }
        : null
    );
    push(ROUTE_NAME.SELECT_ALL_TOPS_FOR_OFFER, {
      collectionModel: selectedFromCollectionData,
      fromTopToCreateTrade: true,
    });
  };

  const handleDistributeTop = () => {
    // handle distribute tops to users
    setOpenOptionsModal();
    setTimeout(() => {
      setInitialLoader(true);
      setShowDistributiveModal(true);
      getfavoriteUserList('', FixedValue.CONSTANT_VALUE_0);
      callUserListSearchAPI(searchUserListText, FixedValue.CONSTANT_VALUE_0);
      callGroupListSearchAPI(searchGroupListText, FixedValue.CONSTANT_VALUE_0);
    }, 250);
  };

  const handleMoveToCollection = () => {
    // handle adding new tops to collection
    if (selectedTopData) {
      setSelectedTopsToMove([
        {
          ...selectedTopData,
          isSelected: true,
          selectedQuantity: FixedValue.CONSTANT_VALUE_1,
        },
      ]);
    }
    setOpenOptionsModal();
    setTimeout(() => {
      setOpenMoveToCollectionModal();
    }, 250);
  };
  const handleDeleteTop = () => {
    // handle deleting collection
    updateDeleteModalNo(FixedValue.CONSTANT_VALUE_1);
  };
  const handelCancelOption = () => {
    setOpenOptionsModal();
  };

  return (
    <>
      {deleteModalNo === FixedValue.CONSTANT_VALUE_0 && (
        <>
          <Pressable style={styles.alignEnd} onPress={handelCancelOption}>
            <ModalBack />
          </Pressable>
          {selectedTopData?.tradable && (
            <ModalButton
              LeftIcon={plus}
              label={TEXT.CREATE_TRADE_OFFER}
              onPress={handleEditTop}
            />
          )}
          <ModalButton
            LeftIcon={distributeIcon}
            label={TEXT.DISTRIBUTE_TOP}
            onPress={handleDistributeTop}
          />
          <ModalButton
            LeftIcon={moveIcon}
            label={TEXT.MOVE_TO_COLLECTION}
            onPress={handleMoveToCollection}
          />
          <ModalButton
            LeftIcon={() => <DeleteIcon stroke={COLORS._FF3D3D} />}
            label={TEXT.DELETE_TOP}
            isDelete={true}
            onPress={handleDeleteTop}
          />
        </>
      )}
      {deleteModalNo === FixedValue.CONSTANT_VALUE_1 && (
        <>
          <View style={styles.deleteBinView}>
            <DeleteIcon
              stroke={COLORS._FF3D3D}
              width={scaleWidth(FixedValue.CONSTANT_VALUE_21)}
              height={scaleHeight(FixedValue.CONSTANT_VALUE_24)}
            />
          </View>
          <>
            <Text style={styles.sure}>
              {TEXT.ARE_YOU_SURE_WANT_TO_DELETE}
              {` "${selectedTopData?.name}"?` ?? ''}
            </Text>
          </>
          <View style={styles.marginTop20}>
            <>
              <View style={styles.bottomButtonsView}>
                <CustomButton
                  buttonLabel={TEXT.NO}
                  buttonwidth={styles.primaryButtonStyle2}
                  buttonBackGroundColor={styles.noButtonBackGroundColor}
                  labelStyle={styles.noButtonLabelstyle}
                  onPress={() => handelCancelOption()}
                  isDisabled={false}
                />
                <CustomButton
                  buttonLabel={TEXT.YES}
                  buttonwidth={styles.primaryButtonStyle}
                  labelStyle={styles.yesButtonLabelstyle}
                  onPress={() => callDeleteTopAPI(selectedTopData?.id)}
                  isDisabled={false}
                />
              </View>
            </>
          </View>
        </>
      )}
    </>
  );
};

export default React.memo(OptionsModalField);
