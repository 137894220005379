import {StyleSheet} from 'react-native';
import {BASESTYLE, COLORS, FixedValue, FONT_NAMES} from '../../../constants';
import {
  isWebsite,
  normalizeFont,
  scaleHeight,
  scaleWidth,
} from '../../../utils/responsive';

export const styles = StyleSheet.create({
  container: {
    height: scaleHeight(FixedValue.CONSTANT_VALUE_136),
    paddingHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_30),
    ...BASESTYLE.inRow,
    ...BASESTYLE.spaceBetween,
  },
  imagefooter: {
    ...BASESTYLE.inRow,
    height: scaleHeight(FixedValue.CONSTANT_VALUE_136),
    paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_20),
  },
  shapefooter: {
    height: scaleHeight(FixedValue.CONSTANT_VALUE_136),
    paddingTop: scaleHeight(FixedValue.CONSTANT_VALUE_22),
  },
  Textfooter: {
    height: scaleHeight(FixedValue.CONSTANT_VALUE_136),
    ...BASESTYLE.inCenter,
  },
  addImageView: {
    marginRight: scaleWidth(FixedValue.CONSTANT_VALUE_10),
  },
  emtpyImageView: {
    marginTop: isWebsite()
      ? FixedValue.CONSTANT_VALUE_20
      : FixedValue.CONSTANT_VALUE_0,
  },
  sliderView: {
    ...BASESTYLE.inRow,
    ...BASESTYLE.selfCenter,
    marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_12),
  },
  slider: {
    marginHorizontal: scaleWidth(FixedValue.CONSTANT_VALUE_18),
  },
  trackStyle: {
    backgroundColor: COLORS.WHITE,
  },
  titleText: {
    fontSize: normalizeFont(FixedValue.CONSTANT_VALUE_20),
    fontFamily: FONT_NAMES.SF_SEMIBOLD,
    lineHeight: scaleHeight(FixedValue.CONSTANT_VALUE_28),
    color: COLORS.WHITE,
    paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_20),
  },
  shapeListContentstyle: {
    ...BASESTYLE.alignCenter,
    paddingLeft: scaleWidth(FixedValue.CONSTANT_VALUE_20),
    height: scaleHeight(FixedValue.CONSTANT_VALUE_55),
    marginTop: scaleHeight(FixedValue.CONSTANT_VALUE_7),
  },
  itemSeperator: {
    width: scaleWidth(FixedValue.CONSTANT_VALUE_14),
    height: scaleWidth(FixedValue.CONSTANT_VALUE_5),
  },
  extraHeight: {
    height: scaleHeight(FixedValue.CONSTANT_VALUE_30),
  },
  bottomView: {
    height: scaleHeight(FixedValue.CONSTANT_VALUE_136),
  },
});
