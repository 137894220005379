import React from 'react';
import {useFormik} from 'formik';
import {ERROR_MESSAGES, FixedValue} from '../../constants';
import {UpdateProfileScheme} from '../../utils/formikSchema';
import {useAppDispatch, useAppSelector} from '../reduxHooks';
import {_ToastHandler} from '../../utils/helperFunctions';
import {UserProfileKeys} from '../../types/componentTypes/userProfileTypes';
import {
  onUpdateProfilePicture,
  userProfileStore,
  onUserBlockListStart,
  onUserUnBlockStart,
} from '../../redux/reducers/userProfileSlice';
import {userProfileContext} from '../../contextAPI/userProfileContext';
import {
  IProfileImagePayload,
  IUpdateUserProfile,
} from '../../types/reducerTypes/userProfileTypes';
import {
  onUpdateUserStart,
  onUploadProfileImageStart,
} from '../../redux/reducers/updateProfileSlice';
import useAwsS3 from '../libraryHooks/useAwsS3';
import {
  captureImageFromCamera,
  pickImageFromGallery,
} from '../../utils/imageUtils';

export type UserProfileFields = {
  Firstname?: string;
  Lastname?: string;
  DateOfBirth?: string;
  Email?: string;
  MobileNumber?: string;
};

export interface IProfileImagePick {
  uri: string;
  type: string;
  name: string;
  fileSize?: number;
}

const useUserProfileHooks = () => {
  const {
    selectedCountry,
    openCloseUploadProfileModal,
    setOpenCloseUploadProfileModal,
  } = React.useContext(userProfileContext);
  const {uploadImage} = useAwsS3();
  const {blockUserData} = useAppSelector(userProfileStore);

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    isValid,
    setValues,
    handleSubmit,
  } = useFormik<UserProfileFields>({
    validationSchema: UpdateProfileScheme,
    initialErrors: {
      Firstname: ERROR_MESSAGES.REQUIRED_THIS,
      Lastname: ERROR_MESSAGES.REQUIRED_THIS,
      DateOfBirth: ERROR_MESSAGES.REQUIRED_THIS,
      Email: ERROR_MESSAGES.REQUIRED_THIS,
      MobileNumber: ERROR_MESSAGES.REQUIRED_THIS,
    },
    initialTouched: {
      Firstname: false,
      Lastname: false,
      DateOfBirth: false,
      Email: false,
      MobileNumber: false,
    },
    initialValues: {
      Firstname: '',
      Lastname: '',
      DateOfBirth: '',
      Email: '',
      MobileNumber: '',
    },
    onSubmit: _val => submitForm(_val),
    validateOnMount: true,
  });
  const dispatch = useAppDispatch();
  const submitForm = React.useCallback(
    (_val: UserProfileFields): void => {
      const payload: IUpdateUserProfile = {
        firstName: _val?.Firstname?.trim(),
        lastName: _val?.Lastname?.trim(),
        email: _val?.Email?.trim(),
        countryCode: selectedCountry.dial_code || '+1',
        mobile: _val?.MobileNumber,
      };
      dispatch(onUpdateUserStart(payload));
    },
    [selectedCountry]
  );

  const openUploadProfileModal = React.useCallback((): void => {
    setOpenCloseUploadProfileModal();
  }, [openCloseUploadProfileModal]);

  //take picture from camera
  const _takePicture = async () => {
    try {
      const image = await captureImageFromCamera();
      if (image) {
        const profilePicture = {
          tempProfilePicture: image.uri,
        };
        dispatch(onUpdateProfilePicture(profilePicture));
        uploadUserProfileImage(image);
        setOpenCloseUploadProfileModal();
      }
    } catch (error) {
      console.log(error, 'error while capture Image');
    }
  };

  //open phone and web gallery
  const _openGallery = async (): Promise<void> => {
    try {
      const image = await pickImageFromGallery();
      if (image) {
        const profilePicture = {
          tempProfilePicture: image.uri,
        };
        dispatch(onUpdateProfilePicture(profilePicture));
        uploadUserProfileImage(image);
        setOpenCloseUploadProfileModal();
      }
    } catch (error) {
      console.log(error, 'error while gallery Image');
    }
  };

  const uploadUserProfileImage = React.useCallback(
    async (image: IProfileImagePick): Promise<void> => {
      try {
        const uploadedSource = await uploadImage(image);
        const payload: IProfileImagePayload = {
          profileImage: uploadedSource,
        };
        dispatch(onUploadProfileImageStart(payload));
      } catch (error: any) {
        _ToastHandler(error, false);
      }
    },
    [dispatch]
  );

  //remove profile image
  const _removeImage = React.useCallback((): void => {
    const profilePicture = {
      tempProfilePicture: '',
      profileImage: '',
    };
    dispatch(onUpdateProfilePicture(profilePicture));
    const payload: IProfileImagePayload = {
      profileImage: '',
    };
    dispatch(onUploadProfileImageStart(payload));
    setOpenCloseUploadProfileModal();
  }, [openCloseUploadProfileModal, dispatch]);

  const getFieldError = React.useCallback(
    (key: UserProfileKeys): string => {
      if (touched[key] && errors[key]) {
        return errors[key] ?? '';
      } else {
        return '';
      }
    },
    [touched, errors]
  );
  const firstNameError: string = getFieldError('Firstname');
  const lastNameError: string = getFieldError('Lastname');
  const emailError: string = getFieldError('Email');
  const phoneError: string = getFieldError('MobileNumber');

  const getUserBlockList = React.useCallback((page: number): void => {
    const params = {page, size: FixedValue.CONSTANT_VALUE_15};
    dispatch(onUserBlockListStart(params));
  }, []);

  const onUserBlockedListReachedEnd = React.useCallback((): void => {
    if (
      blockUserData?.success != null &&
      blockUserData?.success?.pageNumber < blockUserData?.success?.totalPages
    ) {
      getUserBlockList(
        blockUserData?.success?.pageNumber + FixedValue.CONSTANT_VALUE_1
      );
    }
  }, [blockUserData]);

  const onUserUnBlockedFunction = React.useCallback(
    (blockUserId: number): void => {
      dispatch(onUserUnBlockStart({blockUserId}));
    },
    []
  );

  return {
    handleChange,
    submitForm,
    handleBlur,
    values,
    firstNameError,
    lastNameError,
    emailError,
    phoneError,
    isValid,
    setValues,
    openUploadProfileModal,
    _takePicture,
    _openGallery,
    _removeImage,
    handleSubmit,
    getUserBlockList,
    onUserBlockedListReachedEnd,
    onUserUnBlockedFunction,
  };
};

export default useUserProfileHooks;
